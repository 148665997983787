import { StyleRules } from "@material-ui/core";

const styles = (): StyleRules => ({
    root: {
        flexGrow: 1,
    },
    drawerPaper: {
        width: 300,
        top: 230,
        height: 'calc(100% - 230px)',
    },
    main: {
        padding: 20
    },
    title: {
        marginBottom: 0
    },
    center: {
        textAlign: 'center'
    }
});

export default styles;