import React, { FC, useCallback, useContext } from 'react';
import { StyleRules, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles, WithStyles } from '@material-ui/core';
import getAllKeys from 'utils/wizard';
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import Term from './term';

const styles = (): StyleRules => ({
    header: {
        backgroundColor: '#dcdcdc'
    },
    rowError: {
        backgroundColor: '#f5c9c6'
    },
    nowrap: {
        whiteSpace: 'nowrap',
    },
    bold: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        width: 150,
    },
    totalNumLine: {
        fontWeight: "bold",
        marginBottom: 10
    },
    errorLine: {
        fontWeight: "bold",
        marginBottom: 10,
        color: "#994343"
    },
    errorMessage: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    }
});

interface IProps extends WithStyles<typeof styles> {
    data: Record<string, any>,
    hiddenCells: string[],
}

const TableWizard: FC<IProps> = ({ data, hiddenCells, classes }) => {
    const terms = useContext(LiteralsContext);
    
    const getCellValueTitle = (index: number, key: string): any => {        
        if (key !== 'errors' && !hiddenCells.some(c => c === key)) {
            return (
                <TableCell key={`${key}-${index}`} className={classes.bold}>
                    <Term component={"Wizard"} text={key} />
                </TableCell>
            );
        }
    }

    const getRowValue = (item: any, index: number): any => {
        const hasErrors = item.errors && item.errors.length > 0;
        
        return (
            <TableRow className={hasErrors ? classes.rowError : ''} key={`columnWizard${index}`}>
                {
                    getAllKeys(data as any[]).map(key => (getCellValue(item, key, item.errors, hasErrors)))
                }
            </TableRow>
        );
    }

    const getCellValue = (item: any, key: string, errors: any[], hasErrors: boolean): any => {
        if (key !== 'errors' && !hiddenCells.some(c => c === key)) {
            const error = errors.find(e => e.key === key);
            const value = key in item ? item[key as keyof typeof item] : '';

            if (!error) {
                return (
                    <TableCell>
                        {value}
                    </TableCell>
                );
            } else if (value && error) {
                return (
                    <TableCell>
                        <Typography>{value}</Typography>
                        <Typography className={classes.errorMessage}>
                            {translate('Wizard', error.message, terms)}
                        </Typography>
                    </TableCell>
                );
            } else {
                return (
                    <TableCell className={classes.errorMessage}>
                        {translate('Wizard', error.message, terms)}
                    </TableCell>
                );
            }
        }
    }

    const getFileDataErrorNumber = useCallback((fileData: any[]) => {
        const errorList = fileData.filter((item) => item.errors && item.errors.length>0);
        return (
            <div>
                <span className={classes.totalNumLine}>{translate('Wizard', 'Total', terms)} {fileData.length} {translate('Wizard', 'Registros', terms)}{' /'}</span>  
                <span className={classes.errorLine}> {translate('Wizard', 'con_error', terms)} {errorList.length} {translate('Wizard', 'Registros', terms)}</span>
            </div>
        )
    },[]);

    return (
        <>
            {getFileDataErrorNumber(data as any[])}
            <Table>
                <TableHead>
                    <TableRow className={classes.header}>
                        {
                            data && getAllKeys(data as any[]).map((key, index) => getCellValueTitle(index, key))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data && data.sort((a:any, b:any) => (a.errors.length > b.errors.length ? -1 : 1)).map((item: any, index: any) => (getRowValue(item, index)))
                    }
                </TableBody>
            </Table>
        </>
    );
};

export default withStyles(styles)(TableWizard);