import { StyleRules } from "@material-ui/core";

const styles = (): StyleRules => ({
    // GENERIC STYLES
    flexRow: {
        display: "flex",
        flexDirection: "row",
    },
    flexCol: {
        display: "flex",
        flexDirection: "column",
    },
    gap5: {
        gap: '5px'
    },
    p20: {
        padding: 20,
    },
    bold: {
        fontWeight: "bold",
    },
    fontS: {
        fontSize: 13,
    },
    // Specific styles
    centerContent: {
        textAlign: "center",
        padding: 50,
    },
    row: {
        display:'flex', 
        flexDirection:'row', 
        alignItems:'center'
    },
    column: {
        display:'flex', 
        flexDirection:'column', 
        alignItems:'flex-start',
    },
    iconConcepto:{
        width:80, height:80, marginRight: 30
    },
    // Pago a cuenta
    pcBlueRim: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #2196f3",
        borderRadius: 5,
        marginBottom: 20,
        padding: 10
      },
      text: {
        marginTop: 5,
        marginBottom: 5,
      },
      containerToggleExpand: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      },
      pcBlueTitle: {
        cursor: "pointer",
        color: "#006993",
      },
      iconPC: {
        marginBottom: "-3px",
        marginLeft: 10,
      },
      containerPeriodosPagoCuenta: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "center",
        marginRight: 30,
        marginLeft: 30,
      },
      containerImporteIcon: {
        display: "flex",
        alignItems: "center",
        gap: '10px'
      }
});

export default styles;