import React, { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent} from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import styles from './styles';
import { formatXMLtoObject, GenericInitialObject, getEmptyObject, getKeysRequiredCampos, renderCampos, formatOptions, SelectoresInfo, GenericObject, getInitialObject, isSameData, mixData} from '../utils';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { GeoGateway } from 'gateways/geo.gateway';
import { Municipio, Provincia , Sigla} from 'gateways/geo.interface';
import moment from 'moment';



const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXmlApartadosPrevios: GenericObject,
    datosXml: string | null,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
}

const Liquidacion621: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXmlApartadosPrevios,
        datosXml,
        setFinished,
        handleSave
    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes, setChanges] = useState(false);
    const [campos, setCampos] = useState<ICampo[] | null>(null);
    const [EMPTY_SUJETO,setEmpty] = useState<GenericInitialObject| null>({});
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const evaluateBool = (value : string | boolean) => {
        if(typeof value === "string"){
            return value === "true"
        }
        else{
            return value
        }
    }
    
    const updateData = ( name: string, value: any) => {
        setChanges(true)
        let newData=null;
        if(EMPTY_SUJETO){
            newData= data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY_SUJETO,[name]: {...EMPTY_SUJETO[name], value: value }}
            
            if(name === "caucional" && data){
                if(evaluateBool(value)){
                    newData['fundaLegal'].value = "CAUCIONAL";
                    newData["exentos"].value = true;
                    newData["noSujetos"].value = false; 
                    if(campos !== undefined && campos !== null){
                        (campos.find((campo) => campo.codigo === "exentos")as ICampo).soloLectura = true;
                        (campos.find((campo) => campo.codigo === "noSujetos")as ICampo).soloLectura = true;
                        (campos.find((campo) => campo.codigo === "fundaLegal")as ICampo).soloLectura = true;
                    }
                }
                else{
                    newData['fundaLegal'].value = undefined;
                    newData["exentos"].value = false;
                    if(campos !== undefined && campos !== null){
                        (campos.find((campo) => campo.codigo === "exentos")as ICampo).soloLectura = false;
                        (campos.find((campo) => campo.codigo === "noSujetos")as ICampo).soloLectura = false;
                        (campos.find((campo) => campo.codigo === "fundaLegal")as ICampo).soloLectura = false;
                    }                
                }
            }
            if((name === "exentos" || name === "noSujetos" || name === "caucional" )&& data){
                newData['exentosValor'].value = evaluateBool(newData["exentos"].value) ? 0 : 1
                newData['nosujetosValor'].value = evaluateBool(newData["noSujetos"].value) ? 0 : 1
                if((name !== "caucional") && campos !== null){
                    (campos.find((campo) => campo.codigo === "fundaLegal")as ICampo).obligatorio = newData["exentosValor"].value === 0 || newData["nosujetosValor"].value === 0;
                }
            }
        }
        setData(newData)     
    }
    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            //console.log('on save camposRequiredKeys', camposRequiredKeys)
            if(data && campos && (campos.find((campo) => campo.codigo === "fundaLegal")as ICampo).obligatorio){
                if(!data["fundaLegal"].value || data["fundaLegal"].value === undefined || data["fundaLegal"].value === ""){
                    valid=false
                }
            }
            if(camposRequiredKeys && camposRequiredKeys.length>0 ){
                if(data){
                    camposRequiredKeys.forEach( element => {
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal' || 'decimal2'|| 'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })                    
                } else {
                    valid = false;
                }
            }
            // console.log('valid', valid)

            if(valid){
                const saved = await handleSave(data); 
                //setFinished(saved);
                setChanges(false);
                const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                if(camposApartado !== undefined && camposApartado !== null && data && data['caucional'].value === true){
                    (camposApartado.find((campo) => campo.codigo === "exentos")as ICampo).soloLectura = true;
                    (camposApartado.find((campo) => campo.codigo === "noSujetos")as ICampo).soloLectura = true;
                    (camposApartado.find((campo) => campo.codigo === "fundaLegal")as ICampo).soloLectura = true;
                }
                setCampos(camposApartado);
            } else {
                //setFinished(false);
                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos','FormularioError', terms),
                        variant: 'error',
                    }
                });

            }
            setLoading(false);

        })();
    }
    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                if(camposApartado !== undefined && camposApartado !== null && data && data['caucional'].value === true){
                    (camposApartado.find((campo) => campo.codigo === "exentos")as ICampo).soloLectura = true;
                    (camposApartado.find((campo) => campo.codigo === "noSujetos")as ICampo).soloLectura = true;
                    (camposApartado.find((campo) => campo.codigo === "fundaLegal")as ICampo).soloLectura = true;
                }
                setCampos(camposApartado);
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                let dataXML: GenericInitialObject | null = null;
                let dataPrevios: GenericInitialObject | null = null;
                let changes = true;

                if(camposApartado && camposApartado?.length>0){
                    emptyObjt= getEmptyObject(camposApartado);
                    setEmpty(emptyObjt);
                    const keys = getKeysRequiredCampos(camposApartado);
                    setCamposRequiredKeys(keys);
                    const datosPrevString = Object.values(datosXmlApartadosPrevios).join('');
                    
                    if(datosXml){
                        dataXML = formatXMLtoObject(datosXml,emptyObjt);
                    } 
                    if(datosPrevString){
                        dataPrevios = getInitialObject(camposApartado, datosPrevString, idTipoApartado);
                    }
                    
                    if(isSameData(dataXML, dataPrevios) ){
                        changes = false;
                        newData= dataXML;
                    } else {
                        const mixDataObjt = mixData(newData, dataPrevios, idTipoApartado);
                        changes = !isSameData(newData, mixDataObjt);
                        newData = mixDataObjt ?? newData;
                    }
                }
                //console.log('newData', newData)
                setLoading(false);
                setData(newData || emptyObjt);
                setChanges(changes);
            } catch (error) {
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);

    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
    },[changes])

    useEffect(() => {
        if(data ){
        let newDataTemp = data;
        newDataTemp['exentosValor'].value = evaluateBool(newDataTemp["exentos"].value) ? 0 : 1
        newDataTemp['nosujetosValor'].value = evaluateBool(newDataTemp["noSujetos"].value) ? 0 : 1
        setData(newDataTemp)
    }
   }, [data])
    
    return(
        <div> 
            <div className={classes.rowAlignLeft}>
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    variant='contained' 
                    disabled={ data === null || data === EMPTY_SUJETO }
                    onClick={() => {
                        setData(EMPTY_SUJETO)
                        setChanges(true)
                    }}
                > 
                    {translate('Tributos','btnClean',terms)} 
                </Button>
            </div>
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
            {campos && campos.length>0 && camposRequiredKeys !== null && data &&
                renderCampos(campos, data, updateData, Object.values(datosXmlApartadosPrevios).join(''), idTipoApartado, undefined, undefined, classes )
            }

            <div className={classes.rowAlignRight}>
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    disabled={!changes}
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnSave',terms)} 
                </Button>
            </div>
        </div>
    )
}
export default withLiterals(['Tributos'])(Liquidacion621);
