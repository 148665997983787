import { FC, useContext, useMemo } from 'react';

import { Dialog, DialogActions, Grid, Typography, WithStyles, withStyles, Button, Paper, DialogTitle } from '@material-ui/core';
import { TPasoDatosTransmision } from 'containers/DUTI/steps/types';
import duti_styles from 'containers/DUTI/styles';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { AlertsContext } from 'contexts/alerts.context';
import { translate } from 'utils/i18n';

import { extraerBorradorM600, extraerBorradorM081, extraerBorradorM080, extraerBorradorM082 } from 'components/BorradorModelos/extraerModeloFromDUSTI';
import { renderM08x } from 'components/BorradorModelos/modelo_08x';
import { renderM600 } from 'components/BorradorModelos/modelo_600';

interface IProps extends WithStyles {
    show: boolean,
    onClose: () => void,
    modelo: '600' | '080' | '081' | '082'
    datos: TPasoDatosTransmision,
}

const ModalModeloBorrador: FC<IProps> = ({
    classes, show, onClose, datos, modelo
}) => {
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext)
    // Gateways
    // const ioc = useContext(IoC);
    
    const M600 = useMemo(() => modelo === '600' && datos ? extraerBorradorM600(datos, terms): null,[datos, modelo])
    const M080 = useMemo(() => modelo === '080' && datos ? extraerBorradorM080(datos, terms): null,[datos, modelo])
    const M081 = useMemo(() => modelo === '081' && datos ? extraerBorradorM081(datos, terms): null,[datos, modelo])
    const M082 = useMemo(() => modelo === '082' && datos ? extraerBorradorM082(datos, terms): null,[datos, modelo])
    const condSujtPas = useMemo(() => {
       if(datos && datos.datosPrevios && datos.datosPrevios.tramitesGest.PLUSVAL && datos.datosPrevios.tramitesGest.PLUSVAL.sujetoPasivo){
            return datos.datosPrevios.tramitesGest.PLUSVAL.sujetoPasivo ?? true
       } else {
        return true
       }
    },[datos])


    // const renderApartado = (data: {term: string, value:any, casilla: number, fullWidth?:boolean}[] | null) => {
    //     return (
    //         data?.map( field => { 
    //             return(
    //                 <div className={classes.row} 
    //                     style={{
    //                         flexWrap: field.fullWidth === true ? 'wrap': undefined,
    //                         minWidth: field.fullWidth === true ? '100%': 220,
    //                         marginTop :5, border:'solid', borderColor: '#8fbae1', borderWidth: '0px 1px 1px 0px', backgroundColor: 'white' 
    //                     }}
    //                 >
    //                     <Typography style={{marginLeft:5,marginRight: 5, color: '#8fbae1'}}>
    //                         {translate('DUTI', (field.term.includes('MXX_') || field.term.includes('M08x_')) ? field.term : `${modelo}_${field.term}` ,terms)+ ` [${field.casilla}]`} 
    //                     </Typography>
    //                     <Typography style={{marginLeft:5,marginRight:25}}>{field.value}</Typography>
    //                 </div>
    //             )
    //         })

    //     )
    // }

    return(
        <Dialog open={show} maxWidth='lg' PaperProps={{style: {backgroundColor: '#e0eaf4'}}}>
            <DialogTitle>
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    <Typography style={{fontSize: 25, color: "#2196f3", fontWeight: 450, fontFamily: 'Roboto'}}>{translate('DUTI', 'modal_borrador_title', terms).toUpperCase().concat(modelo==='600' ? ` ${modelo}`: '')}</Typography>
                </div>
            </DialogTitle>   

                    { modelo === '080' && M080 && renderM08x<'080'>(M080, modelo ,terms,condSujtPas, 'DUTI')}
                    { modelo === '081' && M081 && renderM08x<'081'>(M081, modelo ,terms,condSujtPas, 'DUTI')}
                    { modelo === '082' && M082 && renderM08x<'082'>(M082, modelo ,terms,condSujtPas, 'DUTI')}
                    { modelo === '600' && M600 && renderM600(M600,terms, 'DUTI')}


            <DialogActions style={{margin: 10}} >
                <Button color='primary' variant='contained' onClick={onClose}>{translate('DUTI', 'btn_close', terms).toUpperCase()}</Button>
            </DialogActions>
        </Dialog>
        
    )
}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(ModalModeloBorrador));
