// LIBRARY IMPORTS
import { FC, useEffect, useContext, useMemo, useState } from 'react';
import { WithStyles, withStyles, Button, Card, CardContent, Grid, CircularProgress } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { mdiBank } from '@mdi/js';
// LOCAL IMPORTS
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import { TributosGateway } from 'gateways/tributos.gateway';
import { download } from 'utils/download';
import { IResultadoDomiciliacion, ITributo } from 'gateways/tributos.interfaces';
import { AlertsContext } from 'contexts/alerts.context';
import { DialogInfoDomiciliacionTodosRecibos } from './components/dialogs/ dialog-info-domiciliacion-todos-recibos';
import { TributoCard } from './components/cards/tributo-card';
import Term from 'components/term';
import usePage from 'hooks/page.hook';
import IoC from 'contexts/ioc.context';
import useLoadContentHook from 'hooks/load-content.hook';
import useAltasDomicilaicion, { IStateHistory } from 'hooks/domiciliaciones/altas-domiciliacion.hook';
import useSetHeader from 'hooks/set-header.hook';
// STYLES IMPORTS
import styles from './domiciliacion.styles';
import { StaticContext } from 'react-router';

const DomiciliacionConfirmacion: FC<WithStyles<typeof styles> & RouteComponentProps<{},StaticContext,IStateHistory>> = ({ classes, location, history }) => {
    // PROPS
    const resultadoDomiciliacion = location.state;
    // HOOKS
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    const [pageState,] = usePage();
    const { body } = useLoadContentHook({ contentName: 'mas_info_domiciliacion_todos_recibos', lang: pageState.lang });
    const { onAltaDomiciliaciones, onModificacionFechaCargo } = useAltasDomicilaicion({ history, domiciliables: false });
    // SERVICES
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(TributosGateway) as TributosGateway, [ioc]);
    // LOCAL STATES
    const [loadingPrint, setLoadingPrint] = useState(false);
    const [isSelectedAllActivated, setIsSelectedAllActivated] = useState(false);
    // HEADER
    useSetHeader({ title: <Term component="Domiciliaciones" text="Confirmación domiciliación" />, icon: mdiBank, menu: true });

    useEffect(() => {
        setIsSelectedAllActivated(resultadoDomiciliacion.isSelectedAllActivated);
    }, [resultadoDomiciliacion]);

    const handleOpenCloseDialog = () => {
        setIsSelectedAllActivated(!isSelectedAllActivated);
    }

    // TODO: refactor into a hook
    const handlePrint = async () => {
        setLoadingPrint(true);
        const result = await gateway.getJustificanteDomiciliacion(resultadoDomiciliacion.idOrden || 0);

        download(
            result,
            alertsDispatch,
            translate('Domiciliaciones', 'Ha ocurrido un error al generar el justificante de domiciliación', terms),
            translate('Global', 'BloqueoPantalla', terms)
        );
        setLoadingPrint(false);
    };

    return (
        <>
            <div className={classes.container}>
                <Card className={classes.cardContador}>
                    <CardContent className={classes.cardContadorContainer} >

                        <span className={classes.titleCardContador}>
                            {resultadoDomiciliacion.tributos.length} <Term component="Domiciliaciones" text="tributos domiciliados" />
                        </span>
                        <Button variant="contained" color="primary" size="small" className={classes.buttonPagarTodos} onClick={handlePrint} disabled={loadingPrint}>
                            <Term component="Domiciliaciones" text="IMPRIMIR JUSTIFICANTE" />
                            {
                                loadingPrint &&
                                <CircularProgress size={24} className={[classes.buttonProgress, classes.loaderPrint, classes.centerTopBottom].join(' ')} />
                            }
                        </Button>

                    </CardContent>
                </Card>

                <Grid container>
                    {resultadoDomiciliacion.tributos.map(tributo => (
                        <TributoCard
                            key={tributo.idRecibo}
                            tributo={tributo}
                            screen='domiciliaciones'
                            selectable={false}
                            visibleButton={false}
                            isConfirmacionScreen={resultadoDomiciliacion.idOrden ? true : false}
                            altaDomiciliacion={(tributo: ITributo) => onAltaDomiciliaciones([tributo])}
                            handleFechaCargo={(tributo: ITributo) => onModificacionFechaCargo([tributo])}
                        />
                    ))}
                </Grid>
            </div>

            <DialogInfoDomiciliacionTodosRecibos
                open={isSelectedAllActivated}
                body={body}
                onClose={handleOpenCloseDialog}
            />
        </>
    )
}

export default withStyles(styles)(DomiciliacionConfirmacion);
