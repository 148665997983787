import moment from "moment";
import { formatNumStr } from "containers/DUTI/utils";
import { IAsistentePLVObj } from "gateways/model.new.interface";
import { TCalcPlusvalia, TComprador, TVendedor } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types";
import { TBorradorModelo08x } from "./modelo_08x_asistente";
import { useMemo } from "react";
import calcPlusvalia from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/calcPlusvalia";

const getComprador = (datosPlusv: TCalcPlusvalia, compradores?: TComprador[] | undefined): TComprador | undefined => {
    if (compradores) {
        for (const calcPLV of datosPlusv.calcPLV) {
            const comprador = compradores.find(c => c.nif === calcPLV.nifSujetoPasivo);
            if (comprador) {
                return comprador;
            }
        }
    }
    
}

const getTotalIngresarFinal = (datos: IAsistentePLVObj): number => {
    if (datos.calcPlusvalia) {
        const values : number[] = datos.calcPlusvalia.map(item => {
            const t = item.calcPLV.length>0 ? item.calcPLV.map(c => {
                const all = c.calculos.map(cc => {
                    if (cc.modelo && cc.modelo.totalIngresar) {
                        const totalIngresar = parseFloat(cc.modelo.totalIngresar.toString());
                        return isNaN(totalIngresar) ? 0 : +totalIngresar.toFixed();
                    }
                    return 0;
                });
                const allSum = all.reduce((a,b) => (a+b), 0);
                return allSum;
            }) : [0];
            const sum = t.reduce((a,b) => (a+b), 0);
            return sum;
        });
        const val = values.reduce((a,b) => (a+b), 0)

        return parseFloat(val.toFixed(2))
    } else {
        return 0
    }
}

export const extraerBorradorM08x = (datosAll: IAsistentePLVObj[], terms: any) => {    
    //Por cada sujetos de plusvalia
    const out = datosAll.map( datos => (
        datos.calcPlusvalia?.map((datosPlusv) => {
            const sujetoPasivo: TComprador | TVendedor | undefined = datos.preguntasPlusvalia && datos.preguntasPlusvalia.sujetoPasivo
                ? datos.vendedores?.find(v => v.nif === datosPlusv.nifVendedor)
                : getComprador(datosPlusv, datos.compradores);
            const sujetoAdquiriente: TComprador | TVendedor | undefined =  datos.preguntasPlusvalia && datos.preguntasPlusvalia.sujetoPasivo && datos.compradores
                ? datos.compradores[0]
                : datos.vendedores?.find(v => v.nif === datosPlusv.nifVendedor);
            const representante = datos.preguntasPlusvalia?.representante;
            const totalIngresarFinal = getTotalIngresarFinal (datos); 

            const tipoM08x =( datos.preguntasPlusvalia?.tipoPlusvaliaModelo?.id ?? "081") as '080'| '081'| '082';
            const Apartados = {
            '080': ['M080_A','M080_B','M08X_C','M08x_D_PLUSV','M08X_D','M08X_E_OP','M080_E'],
            '081': ['M08X_A','M08X_B','M08X_C','M081_D_PLUSV','M08X_D','M08X_E_OP','M081_E'],
            '082': ['M08X_A','M08X_B','M08X_C','M08x_D_PLUSV','M08X_D'],
            }
            const infoApartados = Apartados[tipoM08x].map((aprt: any) => {
                switch (aprt) {
                    case "M08X_A" :
                        return({
                            M08X_A: [
                                {term: 'MXX_nif',value: sujetoPasivo ? sujetoPasivo.nif :"" ,casilla:null }, //5 },
                                {term: 'MXX_nomApeRazonSoc', value: sujetoPasivo ? sujetoPasivo.nomApe :"" ,casilla: null }, //6},
                                {term: 'MXX_siglas', value: sujetoPasivo ? sujetoPasivo.direccion.tipoVia?.id :""  ,casilla: null }, //7},
                                {term: 'MXX_nombreVia', value: sujetoPasivo ? sujetoPasivo.direccion.nombreVia :"" ,casilla:null }, // 8},
                                {term: 'MXX_numero', value: sujetoPasivo ? sujetoPasivo.direccion.numero :"" ,casilla: null }, //9},
                                {term: 'MXX_letra', value: sujetoPasivo ? sujetoPasivo.direccion.letra :"" ,casilla: null }, //10},
                                {term: 'MXX_escalera', value: sujetoPasivo ? sujetoPasivo.direccion.escalera :"" ,casilla: null }, //11},
                                {term: 'MXX_piso', value: sujetoPasivo ? sujetoPasivo.direccion.piso :"" ,casilla: null }, //12},
                                {term: 'MXX_puerta', value: sujetoPasivo ? sujetoPasivo.direccion.puerta :""  ,casilla:null }, //13},
                                {term: 'MXX_telefono', value: sujetoPasivo ? sujetoPasivo.contacto.telefono  :"" ,casilla: null }, //14},
                                {term: 'MXX_email', value: sujetoPasivo ? sujetoPasivo.contacto.email :""  ,casilla: null }, //15},
                                {term: 'MXX_municipio', value: sujetoPasivo ? sujetoPasivo.direccion.municipio?.nombre :""  ,casilla: null }, //16},
                                {term: 'MXX_provincia', value: sujetoPasivo ? sujetoPasivo.direccion.provincia?.nombre :""  ,casilla: null }, //17},
                                {term: 'MXX_cp', value: sujetoPasivo ? sujetoPasivo.direccion.cp :"" ,casilla: null }, //18}
                            ]})
                    case "M080_A":
                        return({
                            M080_A: [
                                {term: 'MXX_nif',value: sujetoPasivo ? sujetoPasivo.nif :"" ,casilla:null }, //5 },
                                {term: 'MXX_nomApeRazonSoc', value: sujetoPasivo ? sujetoPasivo.nomApe :"" ,casilla: null }, //6},
                                {term: 'MXX_siglas', value: sujetoPasivo ? sujetoPasivo.direccion.tipoVia?.id :""  ,casilla: null }, //7},
                                {term: 'MXX_nombreVia', value: sujetoPasivo ? sujetoPasivo.direccion.nombreVia :"" ,casilla:null }, // 8},
                                {term: 'MXX_numero', value: sujetoPasivo ? sujetoPasivo.direccion.numero :"" ,casilla: null }, //9},
                                {term: 'MXX_letra', value: sujetoPasivo ? sujetoPasivo.direccion.letra :"" ,casilla: null }, //10},
                                {term: 'MXX_escalera', value: sujetoPasivo ? sujetoPasivo.direccion.escalera :"" ,casilla: null }, //11},
                                {term: 'MXX_piso', value: sujetoPasivo ? sujetoPasivo.direccion.piso :"" ,casilla: null }, //12},
                                {term: 'MXX_puerta', value: sujetoPasivo ? sujetoPasivo.direccion.puerta :""  ,casilla:null }, //13},
                                {term: 'MXX_telefono', value: sujetoPasivo ? sujetoPasivo.contacto.telefono  :"" ,casilla: null }, //14},
                                {term: 'MXX_email', value: sujetoPasivo ? sujetoPasivo.contacto.email :""  ,casilla: null }, //15},
                                {term: 'MXX_municipio', value: sujetoPasivo ? sujetoPasivo.direccion.municipio?.nombre :""  ,casilla: null }, //16},
                                {term: 'MXX_provincia', value: sujetoPasivo ? sujetoPasivo.direccion.provincia?.nombre :""  ,casilla: null }, //17},
                                {term: 'MXX_cp', value: sujetoPasivo ? sujetoPasivo.direccion.cp :"" ,casilla: null }, //18}
                            ]})
                    case "M08X_B" :
                        return({
                            M08X_B: [
                                {term: 'MXX_nif', value: representante? representante.nif: '' ,casilla: null }, //19},
                                {term: 'MXX_nomApeRazonSoc', value: representante ? representante.nomApe: '' ,casilla: null }, //20},
                                {term: 'MXX_siglas', value: representante ? representante.direccion.tipoVia?.id: '' ,casilla: null }, //21},
                                {term: 'MXX_nombreVia', value:representante ? representante.direccion.nombreVia: '' ,casilla: null }, //22},
                                {term: 'MXX_numero', value: representante ? representante.direccion.numero : '', casilla: null }, //23},
                                {term: 'MXX_letra', value:representante? representante.direccion.letra: '' ,casilla: null }, //24},
                                {term: 'MXX_escalera', value: representante ? representante.direccion.escalera: '' ,casilla: null }, //25},
                                {term: 'MXX_piso', value: representante ? representante.direccion.piso: '' ,casilla: null }, //26},
                                {term: 'MXX_puerta', value: representante ? representante.direccion.puerta: '' ,casilla: null }, //27},
                                {term: 'MXX_telefono', value:representante ? representante.contacto.telefono: ''  ,casilla: null }, //28},
                                {term: 'MXX_email', value:representante ? representante.contacto.email: ''  ,casilla: null }, //29},
                                {term: 'MXX_municipio', value:representante ? representante.direccion.municipio?.nombre: ''  ,casilla:null }, //30 },
                                {term: 'MXX_provincia', value:representante ? representante.direccion.provincia?.nombre: ''  ,casilla: null }, //31},
                                {term: 'MXX_cp', value: representante ? representante.direccion.cp: '' ,casilla: null }, //32}      
                            ]
                        })
                    case "M080_B":
                        return({
                            M080_B: [
                                {term: 'MXX_nif', value: representante? representante.nif: '' ,casilla: null }, //19},
                                {term: 'MXX_nomApeRazonSoc', value: representante ? representante.nomApe: '' ,casilla: null }, //20},
                                {term: 'MXX_siglas', value: representante ? representante.direccion.tipoVia?.id: '' ,casilla: null }, //21},
                                {term: 'MXX_nombreVia', value:representante ? representante.direccion.nombreVia: '' ,casilla: null }, //22},
                                {term: 'MXX_numero', value: representante ? representante.direccion.numero : '', casilla: null }, //23},
                                {term: 'MXX_letra', value:representante? representante.direccion.letra: '' ,casilla: null }, //24},
                                {term: 'MXX_escalera', value: representante ? representante.direccion.escalera: '' ,casilla: null }, //25},
                                {term: 'MXX_piso', value: representante ? representante.direccion.piso: '' ,casilla: null }, //26},
                                {term: 'MXX_puerta', value: representante ? representante.direccion.puerta: '' ,casilla: null }, //27},
                                {term: 'MXX_telefono', value:representante ? representante.contacto.telefono: ''  ,casilla: null }, //28},
                                {term: 'MXX_email', value:representante ? representante.contacto.email: ''  ,casilla: null }, //29},
                                {term: 'MXX_municipio', value:representante ? representante.direccion.municipio?.nombre: ''  ,casilla:null }, //30 },
                                {term: 'MXX_provincia', value:representante ? representante.direccion.provincia?.nombre: ''  ,casilla: null }, //31},
                                {term: 'MXX_cp', value: representante ? representante.direccion.cp: '' ,casilla: null }, //32}      
                            ]
                        })
                    case "M08X_C":
                        return({
                            M08X_C: [
                                {term: 'MXX_nif', value: sujetoAdquiriente? sujetoAdquiriente.nif: "" ,casilla: null }, //33},
                                {term: 'MXX_nomApeRazonSoc', value: sujetoAdquiriente? sujetoAdquiriente.nomApe: "",casilla: null }, //34},
                                {term: 'MXX_siglas', value: sujetoAdquiriente? sujetoAdquiriente.direccion.tipoVia?.id: "",casilla: null }, //35},
                                {term: 'MXX_nombreVia', value:sujetoAdquiriente? sujetoAdquiriente.direccion.nombreVia: "" ,casilla: null }, //36},
                                {term: 'MXX_numero', value: sujetoAdquiriente? sujetoAdquiriente.direccion.numero: "", casilla: null }, //37},
                                {term: 'MXX_letra', value: sujetoAdquiriente? sujetoAdquiriente.direccion.letra: "",casilla: null }, //38},
                                {term: 'MXX_escalera', value: sujetoAdquiriente? sujetoAdquiriente.direccion.escalera: "",casilla: null }, //39},
                                {term: 'MXX_piso', value: sujetoAdquiriente? sujetoAdquiriente.direccion.piso: "",casilla: null }, //40},
                                {term: 'MXX_telefono', value: sujetoAdquiriente? sujetoAdquiriente.contacto.telefono: "",casilla: null }, //41},
                                {term: 'MXX_email', value: sujetoAdquiriente? sujetoAdquiriente.contacto.email: "",casilla: null }, //42},
                                {term: 'MXX_municipio', value: sujetoAdquiriente? sujetoAdquiriente.direccion.municipio?.nombre: "" ,casilla:null }, //43 },
                                {term: 'MXX_provincia', value: sujetoAdquiriente? sujetoAdquiriente.direccion.provincia?.nombre: "" ,casilla: null }, //44},
                            ]
                        })
                    case "M081_D_PLUSV":
                        return({
                            MXX_D_PLUSV: [ 
                                {term: 'MXX_municipio', value: datos.preguntasPlusvalia? datos.preguntasPlusvalia.municipio?.nombre : ""  ,casilla:null }, //62 },
                                {term: 'M08x_tipoTransmision', value: datos.preguntasPlusvalia?.tipoTransmision.tipo?.nombre  ,casilla:null }, //63 },
                                {term: 'M08x_otrosSupuestos', value: ''  , casilla: null }, //64},
                                {term: 'MXX_refCatastral', value: datos.preguntasPlusvalia?.inmueble?.refCatastral ?? "" ,casilla: null }, //65},
                                {   term: 'valorSuelo', 
                                    value: datos.preguntasPlusvalia?.inmueble?.datosEconomicos?.valorCatastralSuelo 
                                        ? formatNumStr(parseFloat(datos.preguntasPlusvalia.inmueble.datosEconomicos.valorCatastralSuelo.replace(',','.')))+"€" 
                                        :""  ,
                                    casilla: null  //66
                                },
                                {term: 'M08x_fechaDevengo', value:  datos.infoTransmPlusvalia?.fecha ?moment(datos.infoTransmPlusvalia.fecha).format('DD/MM/YYYY') :"",casilla: null }, //67 },
                                {term: 'M08x_fechaPresentacion', value: moment().format('DD/MM/YYYY') ,casilla: null }, //68 },
                                {term: 'M08x_exento', value: datos.infoTransmPlusvalia?.exento ? 'X': '', casilla: null }, //'XX'},
                                {term: 'M08x_noSujeto', value: datos.infoTransmPlusvalia?.noSujeto ? 'X': '',casilla: null }, //'XX'},
                                {term: 'M08x_motivo', value: datos.infoTransmPlusvalia?.motivo ? datos.infoTransmPlusvalia?.motivo.nombre: '',casilla: null }, //'XX'},
                                {term: 'M08x_observaciones', value: datos.infoTransmPlusvalia?.observaciones ? datos.infoTransmPlusvalia?.observaciones : '',casilla: null }, //'XX'},


                                // {term: }
                                // {term: 'MXX_reduccion', value: datosPlusv.modelo?.reduccion ? formatNumStr(datosPlusv.modelo.reduccion)+"%" :"" ,casilla:69 },
                                // {term: 'MXX_bonificacion', value: datosPlusv.modelo?.bonificacion ? formatNumStr(datosPlusv.modelo.bonificacion)+"%" :"" ,casilla:70 },
                                // {term: 'MXX_recargo', value: datosPlusv.modelo?.recargo ? datosPlusv.modelo.recargo+"%" : "" ,casilla:71 },
                                // {term: 'valorSueloReducido', value: "",casilla: 72},
                                // {term: 'porcentajeTransm', value: datos.infoTransmPlusvalia?.porcentaje ?  datos.infoTransmPlusvalia.porcentaje+"%" :""  ,casilla:73 },
                                // {term: 'tipoDerecho', value: datosPlusv.tipoDerecho?.nombre ?? "" ,casilla:74 },
                                // {term: 'edadUsufructuaria', value: datosPlusv.edadUsufructuaria ?? "",casilla:75 },
                                // {term: 'fechaAdquisicionBien', value: datos.infoTransmPlusvalia?.fecha ? moment(datos.infoTransmPlusvalia?.fecha).format('DD/MM/YYYY') : "" , casilla:76 },
                            ]
                        })
                    case "M08x_D_PLUSV":
                        return({
                            MXX_D_PLUSV: [
                                {term: 'MXX_municipio', value: datos.preguntasPlusvalia? datos.preguntasPlusvalia.municipio?.nombre : ""  ,casilla: null }, //62 },
                                {term: 'M08x_tipoTransmision', value: datos.infoTransmPlusvalia?.tipoDocTransmision?.nombre  ,casilla: null }, //63 },
                                {term: 'M08x_otrosSupuestos', value: ''  ,casilla: null},
                                {term: 'MXX_refCatastral', value: datos.preguntasPlusvalia?.inmueble?.refCatastral ?? "" ,casilla: null }, //65},
                                {term: 'M08x_fechaPresentacion', value: moment().format('DD/MM/YYYY') ,casilla: null }, //68 },
                                {term: 'M08x_importeTransmision', value: datos.infoTransmPlusvalia?.valorDeclarado ? formatNumStr(datos.infoTransmPlusvalia.valorDeclarado)+"€" : "" ,casilla: null }, //68 },

                                ]
                        })
                    case "M08X_D":
                        return({
                            M08X_D: [
                                {term: 'M08x_fechaDoc', value: datos.infoTransmPlusvalia?.fechaDoc ? moment(datos.infoTransmPlusvalia.fechaDoc).format('DD/MM/YYYY'): "", casilla: null }, //50},
                                {term: 'M08x_tipoDoc', value: datos.infoTransmPlusvalia? datos.infoTransmPlusvalia.tipoDocTransmision?.nombre :"", casilla:null }, //51 },
                                {term: 'M08x_oficina', value: datos.infoTransmPlusvalia? datos.infoTransmPlusvalia.infoRegistral?.oficina :"", casilla: null }, //52},
                                {term: 'M08x_numProtocolo', value: datos.infoTransmPlusvalia? datos.infoTransmPlusvalia.numeroProtocolo :"", casilla: null }, //53},
                                {term: 'MXX_nifNotario', value: datos.infoTransmPlusvalia?.notario?.nif ? datos.infoTransmPlusvalia.notario.nif :"", casilla: null }, //47},
                                {term: 'MXX_nomApe', value:  datos.infoTransmPlusvalia?.notario?.nomApe ? datos.infoTransmPlusvalia.notario.nomApe :"", casilla: null }, //48 },
                                {term: 'MXX_direccion', value: '', casilla: null }, //49 },
                                {term: 'M08x_tomo', value: datos.infoTransmPlusvalia?.infoRegistral?.tomo ? datos.infoTransmPlusvalia.infoRegistral.tomo :"", casilla: null }, //54},
                                {term: 'M08x_libro', value: datos.infoTransmPlusvalia?.infoRegistral?.libro ? datos.infoTransmPlusvalia.infoRegistral.libro :"", casilla: null }, //55},
                                {term: 'M08x_folio', value: datos.infoTransmPlusvalia?.infoRegistral?.folio ? datos.infoTransmPlusvalia.infoRegistral.folio :"", casilla: null }, //56},
                                {term: 'M08x_finca', value: datos.infoTransmPlusvalia?.infoRegistral?.finca ? datos.infoTransmPlusvalia.infoRegistral.finca :"", casilla: null }, //57},
                                // Solo en 081 ?¿ ->{term: 'inscripcion', value: '', casilla: 58},
                            ]
                        })
                    case "M08X_E_OP":
                        const operaciones: any[] = [];
                        datosPlusv.calcPLV.map(calcPLV => {
                            calcPLV.calculos.map(calculo =>{ 
                                operaciones.push({
                                    calculo: [
                                        {term: 'M08x_tipoDerecho', value: calculo.tipoDerecho?.nombre ?? "" , casilla: null },
                                        {term: 'M08x_edadUsufructuaria', value: calculo.edadUsufructuaria ?? "", casilla: null },
                                        {term: 'M08x_numOperaciones', value: calculo.numOperaciones ?? "", casilla: null },
                                        {term: 'M08x_porcentajeTitularidad', value: calculo.porcentaje ? calculo.porcentaje + '%' : "", casilla: null },
                                    ],
                                    
                                    desglose: calculo.modelo && calculo.modelo.adquisiciones 
                                        ? calculo.modelo.adquisiciones.map(op => { 
                                            return ({
                                                operacion: [
                                                    {term: 'M08x_fechaAnteriorAdquisicion', value: op.metodoObjetivo.fechaAdquisicion ? moment(op.metodoObjetivo.fechaAdquisicion).format('DD/MM/YYYY') : "", casilla: null},
                                                    {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.metodoObjetivo.porcentaje + "%", casilla: null},
                                                    {term: 'M08x_importeAnteriorAdquisicion', value: op.metodoObjetivo.importeAnteriorAdquisicion ? formatNumStr(Number(op.metodoObjetivo.importeAnteriorAdquisicion ))+"€" : "", casilla: null},
                                                    
                                                ],
                                                objetivo:[
                                                    // {term: 'M08x_fechaAnteriorAdquisicion', value: op.metodoObjetivo.fechaAdquisicion ? moment(op.metodoObjetivo.fechaAdquisicion).format('DD/MM/YYYY') : "", casilla: null},
                                                    // {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.metodoObjetivo.porcentaje +"%", casilla: null},
                                                    {term: 'M08x_valorParcial', value: op.metodoObjetivo.valorParcial ? formatNumStr(Number(op.metodoObjetivo.valorParcial))+"€" : "", casilla: null},
                                                    {term: 'M08x_anyosTenencia', value: op.metodoObjetivo.anyosTenencia ?? "", casilla: null},
                                                    {term: 'baseImponibleParcial', value: op.metodoObjetivo.baseImponible ? formatNumStr(Number(op.metodoObjetivo.baseImponible))+"€" : "", casilla: null},
                                                    {term: 'coeficienteAplicado', value: op.metodoObjetivo.tipoAnual  ? op.metodoObjetivo.tipoAnual +"%" : "", casilla: null},
                                                    {term: 'MXX_tipoGravamen', value: op.metodoObjetivo.tipoGravamen ? op.metodoObjetivo.tipoGravamen+"%" : "", casilla: null},
                                                    {term: 'MXX_cuotaIntegraParcial', value: op.metodoObjetivo.cuotaIntegra ? formatNumStr(Number(op.metodoObjetivo.cuotaIntegra))+"€"  : "", casilla: null},
                                                ],
                                                real: op.metodoReal 
                                                    ? [
                                                        // {term: 'M08x_fechaAnteriorAdquisicion', value: op.metodoReal.fechaAdquisicion ? moment(op.metodoReal.fechaAdquisicion).format('DD/MM/YYYY') : "", casilla: null},
                                                        // {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.metodoReal.porcentaje +"%", casilla: null},
                                                        {term: 'M08x_valorParcial', value: op.metodoReal.valorParcial ? formatNumStr(Number(op.metodoReal.valorParcial))+"€" : "", casilla: null},
                                                        {term: 'M08x_anyosTenencia', value: op.metodoReal.anyosTenencia ?? "", casilla: null},
                                                        {term: 'baseImponibleParcial', value: op.metodoReal.baseImponible ? formatNumStr(Number(op.metodoReal.baseImponible))+"€" : "", casilla: null},
                                                        {term: 'coeficienteAplicado', value: op.metodoReal.tipoAnual  ? op.metodoReal.tipoAnual +"%" : "", casilla: null},
                                                        {term: 'MXX_tipoGravamen', value: op.metodoReal.tipoGravamen ? op.metodoReal.tipoGravamen+"%" : "", casilla: null},
                                                        {term: 'MXX_cuotaIntegraParcial', value: op.metodoReal.cuotaIntegra ? formatNumStr(Number(op.metodoReal.cuotaIntegra))+"€"  : "", casilla: null},
                                                    ]
                                                    : []
                                            })
                                        })
                                        : calculo.operaciones.map(op => ({
                                            operacion: [
                                                {term: 'M08x_fechaAnteriorAdquisicion', value: op.fechaAnteriorAdquisicion ? moment(op.fechaAnteriorAdquisicion).format('DD/MM/YYYY') : "", casilla: null},
                                                {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.porcentajeAnteriorAdquisicion +"%", casilla: null},
                                                {term: 'M08x_importeAnteriorAdquisicion', value: op.importeAnteriorAdquisicion ? formatNumStr(op.importeAnteriorAdquisicion)+"€" : "", casilla: null},
                                            ]
                                        })),

                                    liquidacion:  calculo.modelo  ? [
                                        {term: 'importeMetodoObjetivo', value:  calculo.modelo?.importeMetodoObjetivo ? formatNumStr(calculo.modelo.importeMetodoObjetivo)+"€": "", casilla: null },
                                        {term: 'importeMetodoReal', value:  calculo.modelo?.importeMetodoReal ? formatNumStr(calculo.modelo.importeMetodoReal)+"€": "", casilla: null },
                                        {term: 'MXX_cuotaIntegra', value: calculo.modelo?.cuotaIntegra ? formatNumStr(calculo.modelo.cuotaIntegra) +"€" :"", casilla: null }, //84 },
                                        {term: 'baseImponible', value: calculo.modelo.baseImponible? formatNumStr(calculo.modelo.baseImponible)+"€" : "", casilla: null }, //80 },
                                        {term: 'MXX_tipoGravamen', value: calculo.modelo?.tipoGravamen ? calculo.modelo.tipoGravamen+"%": "", casilla: null }, //82 },
                                        {term: 'MXX_bonificacion', value:calculo.modelo?.bonificacion? calculo.modelo.bonificacion+"%" :"", casilla: null }, //85 },
                                        {term: 'MXX_cuotaLiq', value: calculo.modelo?.cuotaLiquida ? formatNumStr(calculo.modelo.cuotaLiquida)+"€" :"", casilla: null }, //86 },
                                        {term: 'MXX_recargo', value: calculo.modelo?.recargo ? calculo.modelo.recargo+"%" :"", casilla: null }, //87 },
                                        {term: 'MXX_interesesDemora', value: calculo.modelo?.interesesDemora ? calculo.modelo.interesesDemora+"%":"", casilla: null }, //88 },
                                        {term: 'importeAdquisicion081', value: calculo.modelo?.importeAdquisicion ? formatNumStr(calculo.modelo.importeAdquisicion)+"€" :"", casilla: null }, //91 },
                                        {term: 'importeTransmision081', value: calculo.modelo?.importeTransmision ? formatNumStr(calculo.modelo.importeTransmision)+"€":"", casilla: null }, //92 },
                                        {term: 'MXX_totalIngresar', value:  calculo.modelo?.totalIngresar ? formatNumStr(calculo.modelo?.totalIngresar)+"€": "", casilla: null }, //61 },

                                    ]: []
                                });                                
                        })});
                        return({ M08X_E_OP: operaciones });
                    case "M081_E":
                        return({
                            M081_E: [
                                // {term: 'baseImponibleReal', value: /*datosPlusv.modelo?.baseImponibleReal ? formatNumStr(datosPlusv.modelo.baseImponibleReal)+"€" : */"??", casilla: 80 },
                                // {term: 'baseImponibleObjetivo', value: /*datosPlusv.modelo?.baseImponibleObjetivo? formatNumStr(datosPlusv.modelo.baseImponibleObjetivo)+"€" :*/"??", casilla: 81 },
                                // {term: 'MXX_tipoGravamen', value: /*datosPlusv.modelo?.tipoGravamen ? datosPlusv.modelo.tipoGravamen+"%": */"??", casilla: 82 },
                                // {term: 'MXX_cuotaIntegra', value: /*datosPlusv.modelo?.cuotaIntegra ? formatNumStr(datosPlusv.modelo.cuotaIntegra) +"€" :*/"??", casilla: 84 },
                                // {term: 'MXX_bonificacion', value: /*datosPlusv.modelo?.bonificacion? datosPlusv.modelo.bonificacion+"%" :*/"??", casilla: 85 },
                                // {term: 'MXX_cuotaLiq', value: /*datosPlusv.modelo?.cuotaLiquida ? formatNumStr(datosPlusv.modelo.cuotaLiquida)+"€" :*/"??", casilla: 86 },
                                // {term: 'MXX_recargo', value: /*datosPlusv.modelo?.recargo ? datosPlusv.modelo.recargo+"%" :*/"??", casilla: 87 },
                                // {term: 'MXX_interesesDemora', value: /*datosPlusv.modelo?.interesesDemora ? datosPlusv.modelo.interesesDemora+"%":*/"??", casilla: 88 },
                                // {term: 'importeAdquisicion081', value: /*datosPlusv.modelo?.importeAdquisicion ? formatNumStr(datosPlusv.modelo.importeAdquisicion)+"€" :*/"??", casilla: 91 },
                                // {term: 'importeTransmision081', value: /*datosPlusv.modelo?.importeTransmision ? formatNumStr(datosPlusv.modelo.importeTransmision)+"€":*/"??", casilla: 92 },
                                //{term: 'MXX_totalIngresar', value: /* datosPlusv.modelo?.totalIngresar ? formatNumStr(datosPlusv.modelo?.totalIngresar)+"€":*/ "", casilla: 61 },
                                {term: 'MXX_totalIngresar', value:  totalIngresarFinal ? formatNumStr(totalIngresarFinal) + "€": "", casilla: null },
                            ]
                        })
                    case "M080_E":
                        return({
                            M080_E: [
                                {term: 'MXX_fechaDocAdquisicion', value:   datos.infoTransmPlusvalia?.fechaDoc ? moment(datos.infoTransmPlusvalia.fechaDoc).format('DD/MM/YYYY') :"", casilla: null }, // 83},
                                {term: 'MXX_tipoDocAdquisicion', value:  datos.infoTransmPlusvalia?.tipoDocTransmision?datos.infoTransmPlusvalia?.tipoDocTransmision.nombre : "" , casilla: null }, //84 },
                                // {term: 'MXX_valorAdquisicion', value:  datos.infoTransmPlusvalia?.valorDeclarado ? formatNumStr(datos.infoTransmPlusvalia.valorDeclarado)+"€":"", casilla: null }, //91 },
                            ]
                        }) 
                
                    default:
                        break;
                }
            })
            
            return infoApartados.reduce( (accum,curr) => (Object.assign(accum,curr)),{})
        
        })    
    ))
        
    console.log(out)
    return out as TBorradorModelo08x[][];
  }
 