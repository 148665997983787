import { CSSProperties, FC, useState } from "react";
import { Carousel, CarouselProps } from "react-bootstrap";
import { Typography,  makeStyles, Link as LinkURL } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiChevronLeftCircle, mdiChevronRightCircle} from '@mdi/js';
import { Variant } from "@material-ui/core/styles/createTypography";
import { Link } from "react-router-dom";


type Props = {left: number, right: number, indicatorStyle: CSSProperties, carouselSize: {width: number|string,height: number|string}}
const useStyles = (props: Props) => makeStyles((theme) => ({
    img: {
        display: 'block',
        width: props.carouselSize.width,
        height: props.carouselSize.height,
        maxHeight: 330,
    },
    carousel: {
        width: props.carouselSize.width,
        height: props.carouselSize.height,
        maxHeight: 330,
        '& > div.carousel-indicators': {
            marginBottom: 5,
            '& [data-bs-target] ': {
                ...props.indicatorStyle
            }
        },
        // Posicionar botones next y prev -----------------
        '& .carousel-control-prev': {
            left: props.left,
        },
        '& .carousel-control-next': {
            right: props.right,
        },
        // ------------------------------------------------
        '& .carousel-caption': {
            bottom: 0,
            color: 'black',
            padding: 0,
        },
    }
}));


/**
 * Propiedades del componente
 * img: Conjunto de elemntos del carrusel => 
 *      src: imagen, 
 *      alt: texto que describe la imagen, 
 *      text: texto a mostar sobre la imagen (puede componerse  se un conjunto de elementos) => 
 *              EJ: text:  [   { variant: 'h6' , text: 'First slide label', style:{color:'red'}},
 *                              { variant: 'body2' , text: 'Nulla vitae elit libero, a pharetra augue mollis interdum.'} 
 *                          ] 
 *      slideInterval: intervalo específico para ese elemnto - por si debe ser distinto al conjunto del carousel: Ej: elemento que deseamos mostrar más tiempo
 *      goTo: Redirección a aplicar al pulsar sobre cualquier punto de la imagen (si controls = true los extremos drch/izq quedan reservados para evento de control)
 * carouselAnimation: tipo de animación para la transición de las imágenes.
 * carouselSize: widht/height de los componentes del carousel
 * left/right: permite modificar la posición de los controles next/prev sobre el banner
 * indicatorStyle: permite sobreescribir el estilo de los 'indicators'.
 * interval: The amount of time to delay between automatically cycling an item. If null, carousel will not automatically cycle.
 * indicators: Show a set of slide position indicators
 * controls: Show the Carousel previous and next arrows (if not icons Next/Prev=null) and enable event to changing the current slide
 * interval: The amount of time to delay between automatically cycling an item => NULL to not automatically cycle
 * slideInterval: The amount of time to delay between automatically cycling this specific item. Will default to the Carousel's interval prop value if none is specified.
 * pause: If set to "hover", pauses the cycling of the carousel on mouseenter and resumes the cycling of the carousel on mouseleave. If set to false, hovering over the carousel won't pause it.
 * touch: Whether the carousel should support left/right swipe interactions on touchscreen devices.
 */
type InheritProps = Pick<CarouselProps, 'indicators' | 'controls' |'interval'  |'prevIcon'| 'nextIcon' | 'prevLabel'|'nextLabel' | 'pause' | 'touch'>
type BannerProps = {
    img: {src: string, alt: string, text?: {variant: Variant, text: string, style?: CSSProperties}[], slideInterval?:number, goTo?: string, goToURL?: string}[];
    carouselAnimation?: 'fade' | 'slide' | 'none';
    carouselSize?:{ width: number, height:number};
    left?: number,
    right?: number,
    indicatorStyle?: CSSProperties,
}
const Banner: FC<BannerProps & InheritProps> = ({
    img,
    carouselAnimation= 'slide',
    carouselSize={ width: '100%', height: 'auto'},
    left = -40,
    right= -40,
    indicators= true,
    controls= true,
    interval = 3000,
    prevIcon = <Icon path={mdiChevronLeftCircle} title="previous" color="#fff" size="24" />,
    nextIcon =  <Icon path={mdiChevronRightCircle}title="next"color="#fff"size="24" />,
    indicatorStyle = {
        width: 10,
        height: 10,
        border: '#184c7d',
        borderRadius: 10,
        marginRight: 5,
        marginLeft: 5
    },
    pause='hover',
    touch= true
}) => {

    const classes= useStyles({left,right,indicatorStyle, carouselSize})();

    /** Control posición - Clicks => next, prev or onIndicators - - - */
    const [index, setIndex] = useState<number>(0);
    const handleSelect = (selectedIndex: number) => {
       setIndex(selectedIndex);
    };

    return (
            <Carousel 
                className={ classes.carousel } 
                slide = { carouselAnimation === 'slide' } 
                fade = { carouselAnimation === 'fade'}
                pause = { pause } 
                touch = { touch }
                defaultActiveIndex={ 0 }
                activeIndex = { index } 
                onSelect={(eventKey, ev) => { 
                    handleSelect(eventKey)
                }}
                controls = { controls }
                indicators = { indicators }
                interval = { interval }
                prevIcon = { prevIcon }
                nextIcon = { nextIcon }
            >
                { img.map( (item, idx) => (
                    <Carousel.Item  interval={item.slideInterval}  key={`bannerOption${idx}`}>
                        {item.goTo &&
                            <Link to={item.goTo}>
                                <img className={classes.img} src={item.src} alt={item.alt} />
                            </Link>
                        }
                        {item.goToURL && <LinkURL target="_blank" href={item.goToURL}>
                            <img className={classes.img} src={item.src} alt={item.alt} />
                        </LinkURL>
                        }
                        {!item.goTo && !item.goToURL &&
                            <img className={classes.img} src={item.src} alt={item.alt} />
                        }
                        
                        <Carousel.Caption >
                            {item.text && item.text.length>0 && item.text.map( 
                                (t,idx) => ( 
                                    <Typography 
                                        style={{...t.style, marginBottom: (idx +1) === item.text?.length ? 15: 0 }} // last item marginBottom
                                        variant={t.variant}
                                    >
                                        {t.text}
                                    </Typography> 
                                ))
                            }
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
    )

}

export default Banner;