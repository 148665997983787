import { FC, useContext, useState, useEffect, useMemo } from 'react';
import { Chip, makeStyles } from '@material-ui/core';

import { LiteralsContext } from '../shared/literals';
import { FiltersContext } from './filters.context';
import { FilterDataContext } from './filter.data.context';
import { IFiltersState } from './filters.reducer';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    chip: {
        marginTop: 5,
        marginRight: 2,
        marginLeft: 2
    },
    filterList: {
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        paddingTop: 5,
        paddingBottom: 15
    }
}));

interface IFiltersActiveProps {
    onChange: (query: IFiltersState) => void;
}

const FiltersActive: FC<IFiltersActiveProps> = ({ onChange }) => {
    const terms = useContext(LiteralsContext);
    const filterData = useContext(FilterDataContext);
    const [allFilters, setAllFilters] = useState<Array<{ type: keyof IFiltersState, value: string | number, label: string }>>([]);
    const anyFilters = useMemo(() => allFilters.length > 0, [allFilters]);
    const [filters, filtersDispatch] = useContext(FiltersContext);
    const classes = useStyles();

    useEffect(() => {
        let allFiltersAux = ([] as Array<{ type: keyof IFiltersState, value: string | number, label: string }>);
        for (const filterKey in filters) {
            if (filterKey in filters) {
                const filtersProp = (filters as any)[filterKey];
                if (filtersProp) {
                    switch (filterKey) {
                        case 'ejercicios':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: 'Ejercicio ' + x
                            })));
                            break;
                        case 'situaciones':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: 'Situacion ' + (filterData.situaciones ? filterData.situaciones.filter(y => y.value === x).map((y) => y.label) || x : x)
                            })));
                            break;
                        case 'organismos':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: 'Organismo ' + (filterData.organismos ? filterData.organismos.filter(y => y.value === x).map((y) => y.label) || x : x)
                            })));
                            break;
                        case 'conceptos':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: 'Concepto ' + (filterData.conceptos ? filterData.conceptos.filter(y => y.value === x).map((y) => y.label) || x : x)
                            })));
                            break;
                        case 'domiciliados':
                            if (filtersProp !== undefined) {
                                allFiltersAux = allFiltersAux.concat([({
                                    type: filterKey,
                                    value: filtersProp,
                                    label: filtersProp === 'true' ? 'Domiciliados' : 'Sin domiciliar'
                                })]);
                            }
                            break;
                        case 'modelos':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: 'Modelo ' + (filterData.modelos ? filterData.modelos.filter(y => y.value === x).map((y) => y.label) || x : x)
                            })));
                            break;
                        case 'objTributario':
                            if (filtersProp !== undefined) {
                                allFiltersAux = allFiltersAux.concat([({
                                    type: filterKey,
                                    value: filtersProp,
                                    label: "Objeto Tributario " + filtersProp,
                                })]);
                            }
                            break;
                        case 'dirTributaria':
                                if (filtersProp !== undefined) {
                                    allFiltersAux = allFiltersAux.concat([({
                                        type: filterKey,
                                        value: filtersProp,
                                        label: "Dirección Tributaria " + filtersProp,
                                    })]);
                            }
                            break;
                        case 'fPago':
                                if (filtersProp !== undefined) {
                                    allFiltersAux = allFiltersAux.concat([({
                                        type: filterKey,
                                        value: filtersProp,
                                        label: "Fecha de Pago " + filtersProp,
                                    })]);
                            }
                            break;
                        default:
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: x
                            })));
                            break;

                    }
                }
            }
        }

        setAllFilters(allFiltersAux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, terms]);

    const handleDelete = (filter: { type: keyof IFiltersState, value: string | number }) => () => {
        const currentFilterValues: any = (filters as any)[filter.type];
        let valueToAssign: any = filter.value;

        if (currentFilterValues instanceof Array) {
            valueToAssign = (currentFilterValues as any[]).filter(x => x !== filter.value);
            filtersDispatch({ type: 'update', filter: filter.type, value: valueToAssign });
        } else {
            valueToAssign = undefined;
            filtersDispatch({ type: 'update', filter: filter.type, value: undefined });
        }

        const newFilters = { ...filters };
        newFilters[filter.type] = valueToAssign;
        onChange(newFilters);
    };

    return anyFilters ? (
        <div className={classes.filterList}>
            {
                allFilters.map(x => (
                    <Chip
                        key={x.value}
                        label={x.label}
                        onDelete={handleDelete(x)}
                        className={classes.chip}
                    />
                ))
            }
        </div>
    ) : null;
}

export default FiltersActive;