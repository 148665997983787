import React, { FC } from 'react';
import { withStyles, WithStyles, Drawer, Theme } from "@material-ui/core";
import PagoTable from './pago.table';
import { IModelGateway } from 'gateways/modelGateway.interface';
import { StyleRules } from '@material-ui/styles';

const styles = (theme: Theme): StyleRules => ({
    root: {
        flexGrow: 1,
    },
    drawerPaper: {
        width: 300,
        top: 230,
        height: 'calc(100% - 230px)',
    },
    main: {
        padding: 20
    },
    title: {
        marginBottom: 0
    },
    center: {
        textAlign: 'center'
    }
});

interface IProps extends WithStyles<typeof styles> {
    modelos: IModelGateway[];
}

const PanelDeuda: FC<IProps> = ({ classes, modelos }) => {
    return (
        <Drawer
            classes={{
                paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor="right"
            open>
            <div className={classes.main}>
                <PagoTable modelos={modelos} />
            </div>
        </Drawer>
    )
};

export default withStyles(styles)(PanelDeuda);