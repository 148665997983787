import React, { FC } from 'react';
import { RouteComponentProps, withRouter, Route } from 'react-router-dom';
import Procedimiento from './procedimiento';
import Procedimientos from './procedimientos';

const ProcedimientosIndex: FC<RouteComponentProps> = ({ match }) => {
    return (
        <>
            <Route exact path={`${match.path}`} component={Procedimientos} />
            <Route exact path={`${match.path}/:idProcedimiento`} component={Procedimiento} />
        </>
    );
}

export default withRouter(ProcedimientosIndex);
