import React, { FC } from "react";
import { RouteComponentProps, Route, withRouter } from "react-router-dom";
import CodigoContribuyente from "./codigo-contribuyente";
import SolicitudFinalizada from "./solicitud-finalizada";
import { withLiterals } from "containers/shared/literals";


const Solicitudes: FC<RouteComponentProps> = ({ match }) => {
    return (
        <>
            <Route exact path={`${match.path}/codigo-contribuyente`} component={CodigoContribuyente} />
            <Route exact path={`${match.path}/solicitud-finalizada`} component={SolicitudFinalizada} />
        </>
    );
};
export default withLiterals(['Global','Solicitudes'])(withRouter(Solicitudes));