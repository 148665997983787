import { TipoAsistente } from "./utils";

type Type_PermCertPro = { PERMITIDO: string[], CERTIFICADO: string[], PROHIBIDO: string[] }
export type Type_AvisosModelo = { idModelo: string, idTextoAviso: string, textoAviso?: [{ campo: string, contenido: string }] };

export const TIPOS_ASISTENTE= ['plusvalia']
export const MODELOS_ASISTENTE_PLUSVAL = ['080','081','082']
export const MODELOS_ASISTENTE: Record<string, TipoAsistente> = {
    'PLV': 'plusvalia'
}
// PopUP ask for login : ofrece al usuario la opción de loguearse en modelos que permiten ambas modalidades
export  const TIPO_MODELOS_ASK_AUTH: string[] = ['plusvalia', '071']
export const AUX_TIPO_MODELOS_ACTIVOS = ['060','046', '080','081','082','593','594','621','623','656','800','890','071' ];
//export const PRINT_PERMITIDO = ['041','042','043','044','145','046','048','600','610','620','621','630','650','651','653','654','655','656','666','C45']
//export const PRINT_CERTIFICADO = ['623','689','017','700','702','710','790']

export const PRINT: Type_PermCertPro = {
    PERMITIDO: [
        '041', '042', '043', '044', '145', '046', '048', '600', '610', '620', '630', '650', '651', '653', '654', '655', '656', '666', 'C45', '047', '007', //Autonomicos
        '060', '090', '071', '051', '080', '081', '082' //Locales
    ],
    CERTIFICADO: [
        '623', '689', '017', '700', '702', '710', '790', '890', '621' //Autonomicos
    ],
    PROHIBIDO: [
        '800', '593','594' //Autonomicos
    ]
}

export const PAY_LOGGED: Type_PermCertPro = {
    PERMITIDO: [
        '041', '042', '043', '044', '145', '046', '048', '600', '610', '620', '630', '650', '651', '653', '654', '655', '656', '666', '017', '700', '702', '710', '790', '047', '007', //Autonomicos
        '060', '090', '071', '051', '081' //Locales - No se pagan: '080','082'
    ],
    CERTIFICADO: [
        '800', '593', '621', '594' //Autonomicos
    ],
    PROHIBIDO: ['623', '689', 'C45', '890']
}

export const PAY_NO_LOGGED: Type_PermCertPro = {
    PERMITIDO: [
        '046', '048', '620', '621', '047', '007',  '656', //Autonomicos
        '060', '090', '071', '051', '081' //Locales - No se pagan: '080','082'
    ],
    CERTIFICADO: [
        '041', '042', '043', '044', '600', '610', '630', '650', '651', '653', '654', '655', '666', '017', '700', '702', '710', '790', '800', '593', '621', '594' //Autonomicos
    ],
    PROHIBIDO: [
        '145', '623', '689', 'C45', '890'//Autonomicos
    ]
}

export const PAY_CERTIFICADO_PERMITIDO = [
    '041', '042', '043', '044', '610', '630', '650', '651', '653', '654', '655', '666', '017', '700', '702', '710', '790', '800', '593', '621', '594', //Autonomicos
    '090', '071', '051' //Locales 
]
export const PAY_CERTIFICADO_PROHIBIDO = [
    '145', '046', '048', '600', '620', '621', '623', '689', 'C45', '007', '890', '047', '656' //Autonomicos
]
export const TIPO_MODELO_600 = '600';
export const TIPO_MODELO_081 = '081';

type IPrint = 'print-permitido' | 'print-certificado' | 'print-prohibido' | null;
type IPay = 'pay-permitido' | 'pay-certificado' | 'pay-prohibido' | null;
export const getIconsPrintPay = (idTipoModelo: string, isLogged: boolean, isCertificated: boolean) => {
    let out: { print: IPrint, pay: IPay } = { print: 'print-prohibido', pay: 'pay-prohibido' };
    out.print = PRINT.PERMITIDO.includes(idTipoModelo)
        ? 'print-permitido'
        : PRINT.CERTIFICADO.includes(idTipoModelo) ? 'print-certificado'
            : PRINT.PROHIBIDO.includes(idTipoModelo) ? 'print-prohibido' : null;
    if (isLogged) {
        out.pay = PAY_LOGGED.PERMITIDO.includes(idTipoModelo)
            ? 'pay-permitido'
            : PAY_LOGGED.CERTIFICADO.includes(idTipoModelo)
                ? 'pay-certificado'
                : PAY_LOGGED.PROHIBIDO.includes(idTipoModelo) ? 'pay-prohibido' : null;
    } else if (isCertificated) {
        out.pay = PAY_CERTIFICADO_PERMITIDO.includes(idTipoModelo)
            ? 'pay-permitido'
            : PAY_CERTIFICADO_PROHIBIDO.includes(idTipoModelo) ? 'pay-prohibido' : null;
    } else {
        out.pay = PAY_NO_LOGGED.PERMITIDO.includes(idTipoModelo)
            ? 'pay-permitido'
            : PAY_NO_LOGGED.CERTIFICADO.includes(idTipoModelo) ? 'pay-certificado'
                : PAY_NO_LOGGED.PROHIBIDO.includes(idTipoModelo) ? 'pay-prohibido' : null;
    }

    return out
}

// Domiciliaciones

export const DIAS_SELECCIONABLES_VOLUNTARIA = 1;

// AVSISOS MODELOS

export const AVISOS_MODELOS: Type_AvisosModelo[] = [
    {
        idModelo: '621',
        idTextoAviso: 'aviso_sede_modelo_621'
    },
    {
        idModelo: '046',
        idTextoAviso: 'aviso_sede_modelo_621'
    },
    {
        idModelo: '080',
        idTextoAviso: 'aviso_sede_modelo_080'
    },{
        idModelo: '082',
        idTextoAviso: 'aviso_sede_modelo_082'
    }
];