import { FC, useContext, useMemo, useState, useCallback } from 'react';
import { Dialog, Button, CircularProgress, DialogTitle, DialogContent, DialogActions, useMediaQuery, useTheme } from "@material-ui/core";
import Term from 'components/term';
import IoC from 'contexts/ioc.context';
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import { IMultas } from 'gateways/tramites.interfaces';
import { TramitesGateway } from 'gateways/tramites.gateway';
import usePage from 'hooks/page.hook';
import { downloadWithoutAlert } from 'utils/download';

export interface IProps {
    open: boolean;
    multa: IMultas;
    mensajeAclaratorio: string;
    onClose: () => void;
}

const DocumentosDetail: FC<IProps> = (props) => {
    const {
        open,
        multa,
        onClose,
    } = props;

    // Global states
    const [, pageDispatcher] = usePage();
    const terms = useContext(LiteralsContext);
    const [loadingImprimir, setLoadingImprimir] = useState(false);

    // services
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(TramitesGateway) as TramitesGateway, [ioc]);

    // local states
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => onClose();

    const handleImprimir = useCallback(async (impirmirJustificante: boolean) => {
        try {
            setLoadingImprimir(true);
            let result: any;
            if (impirmirJustificante) {
                result = await gateway.getJustificanteIdentificacion(multa.idIdentificacionWeb, true);
                const response = downloadWithoutAlert(result, 'JustificanteIdentificacion');
                if (response === true) {
                    onClose();
                } else {
                    pageDispatcher({
                        type: 'show-notification', payload: {
                            message: translate('Tramite', 'ErrorImpresionJustificante', terms),
                            variant: 'error',
                        }
                    });
                }
            } else {
                result = await gateway.getDocumentacionAdjuntaIdentificacion(multa.idIdentificacionWeb);
                const response = downloadWithoutAlert(result, 'DocumentoAdjunto');
                if (response === true) {
                    onClose();
                } else {
                    pageDispatcher({
                        type: 'show-notification', payload: {
                            message: translate('Tramite', 'ErrorImpresionAdjunto', terms),
                            variant: 'error',
                        }
                    });
                }
            }
        } catch (error) {
            pageDispatcher({
                type: 'show-notification', payload: {
                    message: translate('Tramite', 'ErrorImpresionDocumentacion', terms),
                    variant: 'error',
                }
            });
        }
        finally {
            setLoadingImprimir(false);
        }
    }, [gateway, multa, onClose, pageDispatcher, terms]);

    const handleClickJustificante = useCallback(() => {
        handleImprimir(true);
    }, [handleImprimir]);

    const handleClickAdjuntos = useCallback(() => {
        handleImprimir(false);
    }, [handleImprimir]);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            maxWidth={"md"}
            fullWidth={true}
        >
            <DialogTitle>

                <Term component="Tramites" text="ImprimirDocumentacion" />
            </DialogTitle>
            <DialogContent>
                <Term component="Tramites" text="DocumentoAImprimir" />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClickAdjuntos} color="primary" variant="outlined" disabled={loadingImprimir}>
                    <Term component="Tramites" text="ImprimirDocumentacionAdjunta" />
                    {
                        loadingImprimir
                            ?
                            <CircularProgress size={20} />
                            :
                            null
                    }
                </Button>
                <Button onClick={handleClickJustificante} color="primary" variant="outlined" disabled={loadingImprimir}>
                    <Term component="Tramites" text="Imprimir justificante" />
                    {
                        loadingImprimir
                            ?
                            <CircularProgress size={20} />
                            :
                            null
                    }
                </Button>
                <Button onClick={handleClose} color="default">
                    <Term component="Global" text="Cerrar" />
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default DocumentosDetail;