import React, { FC, Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Icon from '@mdi/react';
import { Button, Divider, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, Tooltip, Typography, createMuiTheme, makeStyles, useTheme } from "@material-ui/core";
import { mdiDotsVertical, mdiGoogleTranslate, mdiAccountSupervisorCircle, mdiLogoutVariant, mdiLoginVariant, mdiMenu, mdiAccount, mdiExpandAll, mdiArrowExpandDown } from "@mdi/js";
import IoC from "contexts/ioc.context";
import { MoreInfoContext } from "contexts/more_info.context";
import { AuthGateway } from "gateways/auth.gateway";
import TimerGateway from "gateways/timer.gateway";
import usePage, { getAccessToken, getLang } from "hooks/page.hook";
import { useHistory } from "react-router";
import Term from "./term";
import clsx from 'clsx';

//IMGs
import iconoAtibSuperior from '../resources/iconoAtibSuperior.png';
import backgroundCabecera from '../resources/background-cabecera.png';
import logoSede from '../resources/logo-sede.png';
import imageLogo from '../resources/logo.png';
import { Link } from "react-router-dom";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { dateParse } from "utils/dates";
import PageSearch from "./page-search";
import PageTitle from "./page-title";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";

const useStyles = makeStyles((theme) => ({
    // AppBar -------------------------------------------------------------------------------
    container: {
        width: '100%',
        flex: 1,
        zIndex: 999,
        position: 'fixed',
        [theme.breakpoints.down('md')]: {
            // Is mobile
            display: 'block',
        }
    },
    hideInMobile: {
        display: 'block',
        [theme.breakpoints.down('md')]: {
            // Is mobile
            display: 'none',
        }
    },
    sedeHeader: {
        background: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
    },
    containerLogo: {
        display: 'flex',
        flexGrow: 1,
        margin: '14px 20px',
    },
    logoSede: {
        width: 50,
        marginLeft: 10,
    },
    logoSedeIni: {
        width: 25,
        verticalAlign: 'middle'
    },
    textSede: {
        fontWeight: 'lighter',
        color: 'white',
        fontSize: 20,
        textDecoration: 'none'
    },
    titleIni: {
        flex: 1,
        textDecoration: 'none',
        textAlign: 'left',
        marginLeft: '20px',
        [theme.breakpoints.down('md')]: {
            // Is mobile
            textAlign: 'center',
            marginLeft: 38,
        },
    },
    pointer: {
        cursor: 'pointer',
    },
    timer: {
        display: 'flex',
        margin: '15px 30px',
        alignItems: 'center',
        color: 'white',
        [theme.breakpoints.between('sm', 'md')]: {
            //tablet
            marginTop: 17,
        },
        [theme.breakpoints.down('sm')]: {
            //mobile
            justifyContent: 'center',
            margin: '2px 0px',
        }
    },
    iconTextLog: {
        marginRight: 5,
    },
    containerRightMenu: {
        display: 'flex',
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            // is tablet
            marginRight: 0,
            fontSize: 8,
        },
        [theme.breakpoints.down(450)]: {
            // is mobile
            marginRight: 5,
        }
    },
    // Page Header --------------------------------------------------------------------------
    headerWrap: {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    },
    headerContainer: {
        background: `url(${backgroundCabecera}) no-repeat center top`,
        backgroundColor: '#e0eaf4',
        paddingBottom: 10,
    },
    hidelogoContainer: {
        paddingRight: 0,
        paddingLeft: 0,
        width: 300, // Ancho de drawer
        minHeight: 164,
        [theme.breakpoints.down('md')]: {
            width: 100, // Ancho de drawer
        }
    },
    logoContainer: {
        display: 'flex',
        paddingRight: 60,
        paddingLeft: 25,
        minHeight: 164, // alto IMG
    },
    withoutLink: {
        color: '#123c6a',
    },
    zIndex: {
        zIndex: 9999,
    },
    textHeader: {
        fontWeight: 'bolder',
    },
    titleContainer: {
        flex: 1,
        justifyContent: 'center',
        padding: '0 20px',
    },
    textHeaderContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: '1.5rem',
        textAlign: 'right'
    },
    select: {
        color: 'white',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
        },
    },
    icon: {
        color: 'white'
    },
    selectRoot: {
        paddingTop: 5,
        paddingBottom: 5
    }
}));


// Menu superior derecha Version mvl -----------------------------------------------------------------------------------
type HeaderRightMenuMvlProps = {
    onChangeLang: (event: any, lang: string) => Promise<void>;
    onOpenModalRepresentar: () => void;
    onLogout: () => void; //Promise<void>;
    onLogin: () => void;
}
const HeaderRightMenuMvl: FC<HeaderRightMenuMvlProps> = ({
    onChangeLang, onLogin, onLogout, onOpenModalRepresentar
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [openSubMenuAnchorEl, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const [pageState, pageDispatcher] = usePage();
    const isLogged = pageState.jwp !== null;

    const onToggleSubMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpenSubMenuAnchorEl(event.currentTarget);
        pageDispatcher({ type: 'open-nav-right' })
    };

    const onCloseSubMenu = () => {
        setOpenSubMenuAnchorEl(null);
        pageDispatcher({ type: 'close-nav-right' })
    };

    return (
        <>
            <IconButton className={classes.containerRightMenu}
                color="inherit"
                onClick={onToggleSubMenu}
            >
                <Icon path={mdiDotsVertical} title="Opciones" color="#fff" size={1} />
            </IconButton>
            <Menu
                id={"simple-menu"}
                anchorEl={openSubMenuAnchorEl}
                keepMounted
                open={pageState.navRightOpen}
                onClose={onCloseSubMenu}
            >
                {pageState.header && pageState.header.right &&
                    <div>
                        {
                            pageState.header.right
                        }
                        <Divider />
                    </div>
                }
                {pageState.lang === 'es'
                    ?
                    <MenuItem onClick={(event) => onChangeLang(event, 'ca')}>
                        <Icon
                            path={mdiGoogleTranslate}
                            title="icono idioma"
                            size={1}
                            className={classes.iconTextLog}
                            color="dimgrey"
                        />
                        <span style={{ color: 'dimgrey' }}>Català</span>
                    </MenuItem>
                    :
                    <MenuItem onClick={(event) => onChangeLang(event, 'es')}>
                        <Icon
                            path={mdiGoogleTranslate}
                            title="icono idioma"
                            size={1}
                            className={classes.iconTextLog}
                            color="dimgrey"
                        />
                        <span style={{ color: 'dimgrey' }}>Castellano</span>
                    </MenuItem>
                }
                <div>
                    {pageState && pageState.jwp && pageState.jwp.representante &&
                        <MenuItem onClick={onOpenModalRepresentar}>
                            <Icon
                                path={mdiAccountSupervisorCircle}
                                title="Modal representar"
                                size={1}
                                className={classes.iconTextLog}
                                color="dimgrey"
                            />
                            <span style={{ color: 'dimgrey' }}><Term component="Global" text="Representar" /></span>
                        </MenuItem>
                    }
                    {isLogged ?
                        <MenuItem onClick={onLogout}>
                            <Icon
                                path={mdiLogoutVariant}
                                title="Cerrar sesión"
                                size={1}
                                className={classes.iconTextLog}
                                color="dimgrey"
                            />
                            <span style={{ color: 'dimgrey' }}><Term component="Global" text="Cerrar sesión" /></span>

                        </MenuItem>
                        :
                        <MenuItem onClick={onLogin}>
                            <Icon
                                path={mdiLoginVariant}
                                title="Iniciar sesión"
                                size={1}
                                className={classes.iconTextLog}
                                color="dimgrey"
                            />
                            <span style={{ color: 'dimgrey' }}><Term component="Global" text="Iniciar sesión" /></span>

                        </MenuItem>
                    }
                </div>
            </Menu>
        </>
    )
}

// ---------------------------------------------------------------------------------------------------------------------


// Menu superior derecha Version web -----------------------------------------------------------------------------------
type HeaderRightMenuWebProps = {
    onChangeLang: (event: any, lang: string) => Promise<void>;
    onOpenModalRepresentar: () => void;
    onLogout: () => void; //Promise<void>;
    onLogin: () => void;
    onAreaPersonal: () => void;
}
const HeaderRightMenuWeb: FC<HeaderRightMenuWebProps> = ({
    onChangeLang, onLogin, onLogout, onOpenModalRepresentar, onAreaPersonal
}) => {
    const [pageState, pageDispatcher] = usePage();
    const isLogged = useMemo(() => pageState.jwp !== null, [pageState.jwp]);
    const isRepresentante = useMemo(() => pageState.jwp !== null && pageState.jwp.representante, [pageState.jwp]);
    const theme = useTheme();
    const classes = useStyles(theme);


    return (
        <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
                <Select
                    margin="dense"
                    variant="outlined"
                    className={classes.select}
                    inputProps={{
                        classes: {
                            root: classes.selectRoot,
                            icon: classes.icon,
                        },
                    }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                    value={pageState.lang as string}
                    onChange={(e) => onChangeLang(e, e.target.value as string)}
                    renderValue={(value) => (value as string).toUpperCase()}
                >
                    <MenuItem value={"ca"} selected={pageState.lang === 'ca'}>Català</MenuItem>
                    <MenuItem value={"es"} selected={pageState.lang === 'es'}>Castellano</MenuItem>
                </Select>

            </Grid>

            <Grid item >
                <Tooltip title={<Term component="Global" text="Area Personal" />}>
                    <IconButton onClick={onAreaPersonal}  >
                        <Icon
                            path={mdiAccount}
                            title="Area Personal"
                            color="#fff"
                            size={1}
                        />
                    </IconButton>
                </Tooltip>
            </Grid>

            {isRepresentante &&
                <Grid item >
                    <Tooltip title={<Term component="Global" text="Representar" />}>
                        <IconButton onClick={onOpenModalRepresentar} >
                            <Icon
                                path={mdiAccountSupervisorCircle}
                                title="Modal representar"
                                color="#fff"
                                size={1}
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
            }
            <Grid item>
                {isLogged
                    ? <Tooltip title={<Term component="Global" text="Cerrar sesión" />}>
                        <IconButton onClick={onLogout} >
                            <Icon
                                path={mdiLogoutVariant}
                                title="Cerrar sesión"
                                color="#fff"
                                size={1}
                            />
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title={<Term component="Global" text="Iniciar sesión" />}>
                        <IconButton onClick={onLogin} size="medium"  >
                            <Icon
                                path={mdiLoginVariant}
                                title="Iniciar sesión"
                                color="#fff"
                                size={1}
                            />
                        </IconButton>
                    </Tooltip>


                }

            </Grid>

        </Grid>
    )
}

// ---------------------------------------------------------------------------------------------------------------------

const PageHeaderNew = () => {

    const history = useHistory();
    const [pageState, pageDispatcher] = usePage();
    const isLogged = pageState.jwp !== null;
    const theme = useTheme();
    const classes = useStyles(theme);
    const [, infoDispatch] = useContext(MoreInfoContext);
    const handleLogin = useCallback(() => history.push('/acceso'), [history]);
    const handleRefresh = useCallback(() => history.push(history.location.pathname), [history]);
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(AuthGateway) as AuthGateway, [ioc]);
    const timerGateway: TimerGateway = useMemo(() => ioc.get(TimerGateway), [ioc]);
    const [lang, setLang] = useState<string>('ca');
    const [time, setTime] = useState(new Date());
    const [count, setCount] = useState(0);
    const [currentDate, setCurrentDate] = useState<string>('');
    const isMobile = window.outerWidth <= 975;

    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext)


    // acciones MENU RIGHT -----------------------------------------------------------------------------------------
    const handleChangeLang = async (event: any, lang: string) => {
        const accessToken = getAccessToken();
        if (accessToken !== null) {
            const result = await gateway.refreshToken(accessToken, lang);
            if (result && result.accessToken !== '') {
                pageDispatcher({ type: 'authenticate', payload: result });
            }
        }
        setLang(lang);
        pageDispatcher({
            type: 'change-lang',
            lang
        });
    };

    const handleOpenModalRepresentar = () => { pageDispatcher({ type: 'open-modal-representar' }) }

    const handleLogout = async () => {
        const token = pageState.jwt !== null && 'accessToken' in pageState.jwt && pageState.jwt.accessToken !== null ? pageState.jwt.accessToken : null
        if (token !== null) {
            await gateway.expireToken(token);
            pageDispatcher({ type: 'logout' });
            handleRefresh();
        }
    }

    const handleGoAreaPersonal = useCallback(() => {

        history.push('/perfil');
    }, [history])
    // -------------------------------------------------------------------------------------------------------------

    // -- Timer
    const handleShowMasInfo = (templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName);
    };

    // -- Navbar menu mvl
    const handleChangeOpenNav = useCallback(() => {
        pageDispatcher({ type: 'open-nav-left' })
    }, [pageDispatcher])

    // Effects
    useEffect(() => {
        const interval = setInterval(async () => {
            if (count === 60) {
                setCount(0);
                const currentTime = await timerGateway.getCurrentTime();
                if (currentTime) {
                    setCurrentDate(dateParse(time, lang));
                    setTime(new Date(currentTime));
                };
            } else {
                setCount(count + 1);
                time.setSeconds(time.getSeconds() + 1);
                setCurrentDate(dateParse(time, lang));
                setTime(time);
            }
        }, 950);
        return () => clearInterval(interval);
    }, [count, lang, time, timerGateway]);

    useEffect(() => {
        setLang(getLang());
    }, []);


    return (
        <div className={classes.container}>
            {/** AppBar  */}
            <Grid container direction="column" className={classes.sedeHeader}>
                <Grid item container direction="row" alignItems="center" justify="space-between">
                    {/**Left menu - NAV */}
                    {isMobile
                        ?
                        <Grid item>
                            <IconButton color="inherit" onClick={handleChangeOpenNav}>
                                <Icon path={mdiMenu}
                                    title="Menu"
                                    size={1}
                                    color="#fff"
                                />
                            </IconButton>
                        </Grid>
                        :
                        <Grid item >
                            <div className={classes.containerLogo} >
                                <a href="https://www.atib.es" >
                                    <img className={classes.logoSedeIni} src={iconoAtibSuperior} alt='logo-sede' />
                                </a>
                                <Link to="/" className={classes.titleIni}>
                                    <span className={classes.textSede}>
                                        <Term component="Global" text="Sede Electrónica" />
                                    </span>
                                    <img className={classes.logoSede} src={logoSede} alt='logo-sede' />
                                </Link>
                            </div>
                        </Grid>
                    }
                    <Grid item style={{ flex: 1, width: '100%' }}>
                        <div>
                            <Grid container alignItems="center" justify="flex-end" direction={isMobile ? "column" : 'row'}>
                                {/** Titulo página en app bar - MVL */}
                                {isMobile && pageState.header &&
                                    <Grid item style={{ flex: 1, alignItems: 'center', textAlign: 'center', marginTop: isMobile ? 17 : 0, }}>
                                        <Typography variant="h6" style={{ fontWeight: 300, color: 'white',overflow:'hidden' }}>
                                            {pageState.header.title}
                                        </Typography>
                                    </Grid>
                                }
                                {/** Timer - Versión WEB */}
                                {!isMobile &&
                                    <Grid item>
                                        <div className={classes.timer}>
                                            {/* <MenuItem button onClick={() => handleShowMasInfo("mas_info_fecha_hora")}>{currentDate}</MenuItem> */}
                                            <Typography className={classes.pointer} onClick={() => handleShowMasInfo("mas_info_fecha_hora")}>{currentDate}</Typography>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item>
                        {isMobile
                            ?
                            <HeaderRightMenuMvl
                                onChangeLang={handleChangeLang}
                                onLogin={handleLogin}
                                onLogout={() => {
                                    alertsDispatch({
                                        type: 'show-alert',
                                        payload: {
                                            message: translate('Acceso', 'LogoutConfirm', terms),
                                            variant: 'warning',
                                            hasCustomAction: true,
                                            actionTemplateName: 'Si',
                                            handleCustomAction: () => {
                                                alertsDispatch({ type: 'hide-alert' })
                                                handleLogout()
                                            },
                                            defaultCloseActionTemplateName: 'No'
                                        }
                                    });
                                }}
                                onOpenModalRepresentar={handleOpenModalRepresentar}
                            />

                            :
                            <HeaderRightMenuWeb
                                onChangeLang={handleChangeLang}
                                onLogin={handleLogin}
                                onLogout={() => {
                                    alertsDispatch({
                                        type: 'show-alert',
                                        payload: {
                                            message: translate('Acceso', 'LogoutConfirm', terms),
                                            variant: 'warning',
                                            hasCustomAction: true,
                                            actionTemplateName: 'Si',
                                            handleCustomAction: () => {
                                                alertsDispatch({ type: 'hide-alert' })
                                                handleLogout()
                                            },
                                            defaultCloseActionTemplateName: 'No'
                                        }
                                    });
                                }}
                                onOpenModalRepresentar={handleOpenModalRepresentar}
                                onAreaPersonal={handleGoAreaPersonal}
                            />
                        }
                    </Grid>
                </Grid>
                {isMobile &&
                    <Grid item>
                        <div className={classes.timer}>
                            {/* <MenuItem button onClick={() => handleShowMasInfo("mas_info_fecha_hora")}>{currentDate}</MenuItem> */}
                            <Typography className={classes.pointer} onClick={() => handleShowMasInfo("mas_info_fecha_hora")}>{currentDate}</Typography>
                        </div>
                    </Grid>
                }
            </Grid>
            {isMobile && !(history.location.pathname === '/acceso') && !(history.location.pathname === '/') && <div style={{ marginRight: 20, marginLeft: 20 }}> <PageSearch /> </div>}
            {
                pageState.header
                    ? <div className={classes.headerWrap}>
                        <Grid container className={classes.headerContainer} alignItems="center">

                            {pageState.header.hideAtibLogo ?
                                <div className={classes.hidelogoContainer}></div>
                                : <Grid item className={classes.logoContainer}>
                                    <a className={[classes.withoutLink, classes.zIndex].join(' ')} href="https://www.atib.es/" target="_blank" rel="noopener noreferrer">
                                        <img src={imageLogo} alt='logo' />
                                    </a>
                                </Grid>
                            }

                            <Grid item className={classes.titleContainer} style={{ marginTop: 30, flex: 1 }}>
                                <PageTitle />
                            </Grid>
                            <Grid item className={classes.textHeaderContainer}>
                                <div style={{ width: 300 }}>
                                    {!pageState.header.homeSearch && <PageSearch />}
                                    {pageState.jwp != null
                                        ? <>
                                            {
                                                pageState.jwp.fullnameRepresentante &&
                                                <div style={{ marginRight: 10, cursor: 'pointer' }} onClick={handleOpenModalRepresentar}>
                                                    <Icon
                                                        path={mdiAccountSupervisorCircle}
                                                        title="Modal representar"
                                                        size={1.5}
                                                        className={classes.iconTextLog}
                                                        color={"#123c6a"}
                                                    />
                                                </div>
                                            }
                                            <Grid className={classes.withoutLink}>
                                                <Typography className={classes.textHeader}>
                                                    <>{pageState.jwp.nif} - </>
                                                    {pageState.jwp.fullname}
                                                </Typography>
                                                {
                                                    pageState.jwp.fullnameRepresentante &&
                                                    <>
                                                        <Typography style={{ fontSize: '0.75rem' }}>{pageState.jwp.fullnameRepresentante}</Typography>
                                                    </>
                                                }
                                            </Grid>
                                        </>
                                        : null
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    : null
            }
        </div>
    )
}

export default PageHeaderNew;