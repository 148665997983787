// LIBRARY IMPORTS
import { FC } from 'react';
import { DialogContentText } from '@material-ui/core';
// LOCAL IMPORTS
import { GenericModal } from 'components/generic-modal';
import useLoadContentHook from 'hooks/load-content.hook';
import usePage from 'hooks/page.hook';

interface IProps {
    open: boolean;
    onCloseModal: () => void;
}

const DialogDomiciliacionIncompatibleModal: FC<IProps> = (props) => {
    const { open, onCloseModal } = props;
    const [state] = usePage();
    const { title, body } = useLoadContentHook({ contentName: 'sede-pop-up_domis_inca', lang: state.lang });

    return (
        <GenericModal
            isTitleHtml
            title={title}
            open={open}
            onClose={onCloseModal}
        >
            <DialogContentText
                dangerouslySetInnerHTML={{ __html: body }}
            />
        </GenericModal >
    )
};

export { DialogDomiciliacionIncompatibleModal };