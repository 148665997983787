import { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { TextField, CircularProgress, useMediaQuery, useTheme, DialogTitle, makeStyles, DialogActions, Grid } from '@material-ui/core';
import Term from 'components/term';
import usePage from 'hooks/page.hook';
import IoC from 'contexts/ioc.context';
import { AlertsContext } from 'contexts/alerts.context';
import { TramitesGateway } from 'gateways/tramites.gateway';
import { InputChangeHandler } from 'utils/events';
import { IDatosDocumentoNotario } from 'gateways/tramites.interfaces';
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import imageAyudaNrd from "./../../../../resources/ayuda-nrd.png";

const useStyles = makeStyles(() => ({
    input: {
        width: '100%'
    },
    nrdAclaratorio: {
        margin: '1em',
    },
    nrdImagen: {
        marginLeft: '1em',
        marginRight: '1em',
        textAlign: 'center',
    }
}));

interface IProps {
    open: boolean;
    onClose: () => void;
    onChange: (documento: IDatosDocumentoNotario) => void;
    busquedaNRD: boolean;
    idExpediente: number;
}

function DocumentoNotarioDialog(props: IProps) {
    const { onClose, onChange, open, busquedaNRD, idExpediente } = props;
    const ioc = useContext(IoC);
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    const [loading, setLoading] = useState(false);

    const [nrd, setNRD] = useState('');

    const [numProtocolo, setNumProtocolo] = useState('');
    const [ejercicio, setEjercicio] = useState('');
    const [fechaOtorgamiento, setFechaOtorgamiento] = useState<Date | null>(null);
    const [nif, setNIF] = useState('');

    const [validationMessageNrd, setValidationMessageNrd] = useState<string | null>(null);
    const [validationMessageNProtocolo, setValidationMessageNProtocolo] = useState<string | null>(null);
    const [validationMessageFecha, setValidationMessageFecha] = useState<string | null>(null);
    const [validationMessageEjercicio, setValidationMessageEjercicio] = useState<string | null>(null);
    const [validationMessageNif, setValidationMessageNif] = useState<string | null>(null);

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const gateway = ioc.get(TramitesGateway) as TramitesGateway;


    const handleClose = () => onClose();



    const handleNRD: InputChangeHandler = (event) => setNRD(event.target.value);
    const handleNumProtocolo: InputChangeHandler = (event) => setNumProtocolo(event.target.value);
    const handleEjercicio: InputChangeHandler = (event) => setEjercicio(event.target.value);
    const handleFechaOtorgamiento = (date: Date | null) => setFechaOtorgamiento(date);
    const handleNif: InputChangeHandler = (event) => setNIF(event.target.value);


    const validateForm = () => {
        let nrdText: string | null = null;
        let nProtocoloText: string | null = null;
        let ejercicioText: string | null = null;
        let fechaText: string | null = null;
        let nifText: string | null = null;

        if (nrd === '') {
            nrdText = translate('Tramites', 'Este campo no puede estar vacío', terms);
        }
        if (numProtocolo === '') {
            nProtocoloText = translate('Tramites', 'Este campo no puede estar vacío', terms);
        }
        if (ejercicio === '') {
            ejercicioText = translate('Tramites', 'Este campo no puede estar vacío', terms);
        }
        if (fechaOtorgamiento === null) {
            fechaText = translate('Tramites', 'Este campo no puede estar vacío', terms);
        }
        if (nif === '') {
            nifText = translate('Tramites', 'Este campo no puede estar vacío', terms);
        }

        setValidationMessageNrd(nrdText)
        setValidationMessageNProtocolo(nProtocoloText)
        setValidationMessageEjercicio(ejercicioText)
        setValidationMessageFecha(fechaText)
        setValidationMessageNif(nifText)

        return (busquedaNRD ? !nrdText : !nProtocoloText && !ejercicioText && !fechaText && !nifText);
    }

    const handleSearch = async () => {
        setLoading(true);
        if (validateForm()) {
            let documentoAux
            try {
                if (busquedaNRD) {
                    documentoAux = await gateway.getDocumentoNotarioNRD(idExpediente, nrd);
                } else {
                    documentoAux = await gateway.getDocumentoNotarioDocumento(idExpediente, numProtocolo, ejercicio, moment(fechaOtorgamiento || new Date()).format(), nif);
                }
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Tramites', 'Se ha encontrado su documento', terms),
                        variant: 'success',
                    }
                });
                onChange({ ...documentoAux });
                onClose();
            } catch (error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tramites', (error as any).message, terms),
                        variant: 'error',
                    }
                });
            }
        }
        setLoading(false);
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Term component="Tramites" text={busquedaNRD ? 'BuscarDatosNRD' : 'BuscarDatosDocumento'} />
                </DialogTitle>
                <DialogContent>
                    {
                        busquedaNRD
                            ?
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-nrd-input"
                                        label={<Term component="Tramites" text="NRD" />}
                                        className={classes.input}
                                        error={validationMessageNrd !== null}
                                        helperText={validationMessageNrd}
                                        value={nrd}
                                        onChange={handleNRD}
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.nrdAclaratorio}>
                                    <Term
                                        component="Tramites"
                                        text="nrdAclaratorio" />
                                </Grid>
                                <Grid item xs={12} className={classes.nrdImagen}>
                                    <img alt="Encontrar NRD"
                                        src={imageAyudaNrd} />
                                </Grid>
                            </Grid>
                            :
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-numProtocolo-input"
                                        className={classes.input}
                                        error={validationMessageNProtocolo !== null}
                                        helperText={validationMessageNProtocolo}
                                        label={<Term component="Tramites" text="numProtocolo" />}
                                        value={numProtocolo}
                                        onChange={handleNumProtocolo}
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-ejercicio-input"
                                        className={classes.input}
                                        error={validationMessageEjercicio !== null}
                                        helperText={validationMessageEjercicio}
                                        label={<Term component="Tramites" text="ejercicio" />}
                                        value={ejercicio}
                                        onChange={handleEjercicio}
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        margin="dense"
                                        format="dd/MM/yyyy"
                                        error={validationMessageFecha !== null}
                                        className={classes.input}
                                        id="datepicker-fecha"
                                        label={<Term component="Tramites" text="fechaOtorgamiento" />}
                                        value={fechaOtorgamiento}
                                        onChange={handleFechaOtorgamiento}
                                        helperText={validationMessageFecha}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-nif-input"
                                        className={classes.input}
                                        error={validationMessageNif !== null}
                                        helperText={validationMessageNif}
                                        label={<Term component="Tramites" text="nifNotario" />}
                                        value={nif}
                                        onChange={handleNif}
                                        margin="dense"
                                    />
                                </Grid>
                            </Grid>
                    }


                </DialogContent>
                <DialogActions>
                    <div>
                        <Button color="primary" variant="outlined" onClick={handleSearch} disabled={loading}>
                            <Term component="Perfil" text="Buscar" />
                            {
                                loading ?
                                    <CircularProgress size={20} />
                                    :
                                    null
                            }
                        </Button>
                    </div>

                </DialogActions>
            </Dialog>
        </div>
    );
}


export default DocumentoNotarioDialog;