import { withLiterals, LiteralsContext } from "containers/shared/literals";
import React, { useContext, useMemo, useEffect, useState, useCallback } from "react";
import { makeStyles, Card, CardContent, FormControl, RadioGroup, FormControlLabel, Radio, Button, CircularProgress, Dialog, DialogTitle, useMediaQuery, useTheme, DialogContent, DialogActions, Grid, InputLabel, Input, FormHelperText, MenuItem } from "@material-ui/core";
import usePage from "hooks/page.hook";
import IoC from "contexts/ioc.context";
import { TramitesGateway } from "gateways/tramites.gateway";
import Term from "components/term";
import { mdiCertificate } from "@mdi/js";
import { ITipoCertificado } from "gateways/tramites.interfaces";
import { DocumentsUtils } from "utils/documents";
import { translate } from "utils/i18n";
import { AlertsContext } from "contexts/alerts.context";
import DivTemplate from "components/div-template";
import { RouteComponentProps, withRouter } from "react-router";
import queryString from 'query-string';
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { MoreInfoContext } from "contexts/more_info.context";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    select: {
        width: 230,
    },
    buttonList: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    formControl: {
        marginBottom: 10,
        display: 'block',
        '& > div': {
            width: '100%',
        },
        '& > button': {
            width: '100%',
        }
    },
    button: {
        width: 200
    },
    form: {
        display: 'flex',
        marginTop: 15,
        marginLeft: 4,
    },
    margin: {
        paddingLeft: 36,
        paddingRight: 20,
        margin: '0 2rem',
    }
}));
type Props = RouteComponentProps;

const Certificados: React.FC<Props> = ({ history, location }) => {
    const classes = useStyles();
    const [pageState, pageDispatcher] = usePage();
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [open, setOpen] = useState(false);
    const [idDocumento, setIdDocumento] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [certificados, setCertificados] = useState<ITipoCertificado[]>([]);
    const [certificado, setCertificado] = useState<number>(-1);
    const [positivo, setPositivo] = useState<boolean | null>(null);
    const [parametrosPlantilla, setParametrosPlantilla] = useState({});
    const [nombrePlantilla, setNombrePlantila] = useState('certificadosNoError');
    const [nif, setNif] = useState('');
    const [nifError, setNifError] = useState<string | null>(null);
    const [nombre, setNombre] = useState('');
    const [pantalla, setPantalla] = useState(1);

    const deshabilitarBotonContinuar = useMemo(() => {
        if (pantalla === 1) {
            return certificado === -1;
        } else if (pantalla === 2) {
            return certificado === -1 || nombre === '' || nif === '';
        } else {
            return false;
        }
    }, [certificado, pantalla, nombre, nif])

    const deshabilitarBotonImprimir = useMemo(() => {
        return loadingModal || positivo == null || idDocumento == null;
    }, [loadingModal, positivo, idDocumento])

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(TramitesGateway) as TramitesGateway, [ioc]);

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info',templateName )
    },[infoDispatch])
    
    useEffect(() => {
        const loadPage = async () => {
            setLoading(true);
            const values = queryString.parse(location.search);
            if (values.administracion === '1') {
                const result = await gateway.comprobarUsuarioRegistrado(7);
                if (result && pageState !== null && pageState.jwp !== null && pageState.jwp.esAdministracion) {
                    setPantalla(2);
                } else {
                    setPantalla(3);
                }
            } else {
                setPantalla(1);
            }
            try {
                const certificadosAux = await gateway.obtenerListadoCertificados();
                setCertificados(certificadosAux);
            }
            catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoading(false);
            }
        }
        loadPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gateway, pageDispatcher, location, location.search]);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiCertificate,
                moreInfoTemplate: 'mas_info_certificados',
                title: <Term component="Tramites" text="Obtener Certificados" />,
                right: <MenuItem button onClick={() => handleShowMasInfo("mas_info_certificados")}><Term component="Global" text="Mas informacion" /></MenuItem>   
            },
            menu: true,
        });
    }, [pageDispatcher]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCertificado(parseInt(event.target.value as any, 10));
    };

    const handleOpen = async () => {
        if (pantalla === 2) {
            setNombrePlantila('avisoObtencionCertificadoTerceros');
            setParametrosPlantilla({ 'nombre': nombre });
            if (nif.length !== 9 || !new RegExp(/^(\d|[a-z]|[A-Z]){1}[0-9]{7}([a-z]|[A-Z]|\d){1}/).test(nif)) {
                setNifError(translate('Tramites', 'El campo nif no tiene un formato correcto', terms))
                return;
            }
        }
        setOpen(true);
        setLoadingModal(true);
        try {
            const certificadoAux = certificados.find(x => x.id === certificado);
            if (!!certificadoAux) {
                let response = { resultado: null, idDocumento: null };
                if (pantalla === 2) {
                    response = await gateway.permitirImprimirCertificadoTerceros(certificadoAux.codigoMares, nif, nombre);
                } else {
                    response = await gateway.permitirImprimirCertificado(certificadoAux.codigoMares);
                }
                if (response.resultado !== null && response.idDocumento !== null) {
                    setPositivo(response.resultado);
                    setIdDocumento(response.idDocumento);
                } else {
                    setPositivo(null);
                    setIdDocumento(null);
                    setNombrePlantila('certificadosError');
                    setParametrosPlantilla({ "url": "https://www.atib.es/General/Contacto.aspx" });
                }
            } else {
                setPositivo(null);
                setIdDocumento(null);
                setNombrePlantila('certificadosError');
                setParametrosPlantilla({ "url": "https://www.atib.es/General/Contacto.aspx" });
            }
        }
        catch (e) {
            setPositivo(null);
            setIdDocumento(null);
            if (e === 404) {
                setNombrePlantila('certificadosErrorSujetoNoEncontrado');
                setParametrosPlantilla({ "url": "https://www.atib.es/General/Contacto.aspx" });
            } else {
                setNombrePlantila('certificadosError');
                setParametrosPlantilla({ "url": "https://www.atib.es/General/Contacto.aspx" });
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
        }
        finally {
            setLoadingModal(false);
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSetNif = (inputNif: string) => {
        setNif(inputNif);
    }

    const handleSetNombre = (inputNombre: string) => {
        setNombre(inputNombre);
    }

    const handleSubmit = async () => {
        setLoadingModal(true);
        try {
            if (idDocumento !== null) {
                try {
                    DocumentsUtils.downloadDoc(idDocumento);
                } catch (error) {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Tramites', (error as any).message, terms),
                            variant: 'error',
                        }
                    });
                }
            }
        }
        catch (e) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (e as any).message,
                    variant: 'error',
                }
            });
        }
        finally {
            setLoadingModal(false);
        }
    }

    return (
        <>
            <div className={classes.root}>
                <Card className={classes.margin}>
                    <CardContent>
                        {
                            pantalla !== 3
                                ?
                                <>
                                    {
                                        pantalla === 2
                                            ?
                                            <>
                                                <h3><Term component="Tramites" text="Datos del solicitante" /></h3>
                                                <Grid container item xs={12} spacing={5}>
                                                    <Grid item xs={12} sm={3}>
                                                        <FormControl className={classes.formControl}>
                                                            <InputLabel error={nifError !== null} htmlFor="adornment-nif">
                                                                <Term component="Global" text="Nif" />
                                                            </InputLabel>
                                                            <Input
                                                                id="adornment-nif"
                                                                value={nif}
                                                                error={nifError !== null}
                                                                onChange={(e) => handleSetNif(e.target.value)}
                                                                inputProps={{
                                                                    tabIndex: 1,
                                                                }}
                                                            />
                                                            {
                                                                nifError !== null
                                                                    ?
                                                                    <FormHelperText error={true}>{nifError}</FormHelperText>
                                                                    :
                                                                    null

                                                            }
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <FormControl className={classes.formControl}>
                                                            <InputLabel htmlFor="adornment-name">
                                                                <Term component="Global" text="ApellidosYNombre" />
                                                            </InputLabel>
                                                            <Input
                                                                id="adornment-name"
                                                                value={nombre}
                                                                onChange={(e) => handleSetNombre(e.target.value)}
                                                                inputProps={{
                                                                    tabIndex: 2,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            :
                                            null
                                    }
                                    <h3><Term component="Tramites" text="Finalidad del certificado" /></h3>{
                                        loading || certificados === null
                                            ?
                                            <div>
                                                <CircularProgress size={35} />
                                            </div>
                                            :
                                            <>
                                                <FormControl component="fieldset" className={classes.form}>
                                                    <RadioGroup aria-label="certificado" name="certificado" value={certificado} onChange={handleChange}>
                                                        {certificados.map((item) => (
                                                            <FormControlLabel
                                                                key={'certificado_' + item.id}
                                                                value={item.id}
                                                                control={<Radio />}
                                                                label={<Term component="Tramites" text={item.nombre} />}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                    <div className={classes.buttonList}>
                                                        <Button type="submit" variant="contained" size="small" className={classes.button} color="primary" onClick={handleOpen} disabled={deshabilitarBotonContinuar}>
                                                            <Term component="Global" text="Continuar" />
                                                        </Button>
                                                    </div>
                                                </FormControl>
                                            </>
                                    }

                                </>
                                :
                                <DivTemplate nombrePlantilla={'CertificadoRequisitoUsuario'} parametros={{ }} />
                        }
                    </CardContent>
                </Card>
            </div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                maxWidth={"md"}
                fullWidth={true}
            >
                <DialogTitle>
                    <Term component="Tramites" text="Emisión certificado" />
                </DialogTitle>
                <DialogContent>
                    {
                        !loadingModal
                            ?
                            <>
                                <DivTemplate nombrePlantilla={nombrePlantilla} parametros={parametrosPlantilla} />
                            </>
                            :
                            <div>
                                <CircularProgress size={35} />
                            </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        <Term component="Global" text="Cerrar" />
                    </Button>
                    <Button variant="outlined" color="primary" onClick={handleSubmit} disabled={deshabilitarBotonImprimir}>
                        <Term component="Tramites" text="Imprimir Certificado" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}

export default withRouter(withLiterals(['Tramites'])(Certificados));