import { Dispatch, ReducerState, ReducerAction, createContext } from "react";

import { IInmueble, IVehiculo} from "gateways/bienesSujeto.interface";
import { ISelectionReducerConfigType } from "./selection.reducer";

type InmueblesReducer = ReturnType<ISelectionReducerConfigType<IInmueble>>;
type VehiculosReducer = ReturnType<ISelectionReducerConfigType<IVehiculo>>;

type SelectionReducerState = ReducerState<InmueblesReducer> | ReducerState<VehiculosReducer >;


type SelectionDispatchAction = Dispatch<ReducerAction<InmueblesReducer>> | Dispatch<ReducerAction<VehiculosReducer>>;


type SelectionContextType = [SelectionReducerState, SelectionDispatchAction];

export const SelectionContext = createContext<SelectionContextType>([{ selected: [] as any[] }, (state: any) => state]);
