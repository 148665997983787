import React, { Children, FC, forwardRef , ForwardRefRenderFunction, PropsWithChildren, useContext} from "react";
import { Button, Grid, IconButton, Typography, withStyles, WithStyles } from "@material-ui/core";


import { AlertsContext } from "contexts/alerts.context";
import { Alert } from "react-bootstrap";

import Icon from "@mdi/react";

import { mdiFileDocumentMultipleOutline } from "@mdi/js";

import CopyRef from "components/copy-ref";
import GenericButtonsStepper, { IGenericButtonStepper } from "./generic-buttons-stepper";
import { translate } from "utils/i18n";
import { useCommonStyles } from "./styles";
import { LiteralsContext } from "containers/shared/literals";


interface StepSchemaProps extends IGenericButtonStepper {
    idRef: string | null,
    withContinuarMasTardeModal: boolean
    withRecoverRefContinuarMasTardeModal?: boolean
    renderContentModalContinuarMasTardeModal?: () => JSX.Element,
}
  
const GenericStepSchema: FC<PropsWithChildren<StepSchemaProps>> = ({
    children,
    isFirst,
    isLast,
    onNext,
    onBack,
    onExit,
    disabledNext,
    disabledBack,
    forceExit,
    idRef,
    withContinuarMasTardeModal,
    withRecoverRefContinuarMasTardeModal= false,
    allowContinuarMasTarde=true,
    renderContentModalContinuarMasTardeModal,
  }) => {
    const terms= useContext(LiteralsContext)
    const CommonClasses= useCommonStyles();
    const [alerts, alertsDispatch] = useContext(AlertsContext);
    return(
        <Grid container direction="column" className={CommonClasses.container} wrap="nowrap">
             <Grid item className={CommonClasses.root}>
                {children}
            </Grid>

            <Grid item >
               <GenericButtonsStepper 
                    isFirst={isFirst} 
                    isLast={isLast}
                    onNext={onNext}
                    disabledNext={disabledNext}
                    onBack={onBack}
                    disabledBack={disabledBack}
                    allowContinuarMasTarde={allowContinuarMasTarde}
                    onExit={(cause:'continuar-mas-tarde'|'force') => {
                        if(allowContinuarMasTarde && cause === 'continuar-mas-tarde' && withContinuarMasTardeModal){
                            alertsDispatch({
                                type: 'show-alert',
                                payload: {
                                    isHtml: true,
                                    message: 
                                    <div>
                                        {renderContentModalContinuarMasTardeModal && renderContentModalContinuarMasTardeModal()}
                                        { withRecoverRefContinuarMasTardeModal && 
                                            <>
                                            <Typography>{translate('Global', 'continuar_mas_tarde_copyRef', terms)}</Typography>
                                            <CopyRef referencia={idRef}/>
                                            </>
                                        }
                                        
                                    </div>,
                                    variant: 'warning',
                                    defaultCloseActionTemplateName: translate('Global','Continuar',terms),
                                    actionTemplateName: translate('Global','exit',terms),
                                    hasCustomAction: true,
                                    handleCustomAction: () => {
                                        alertsDispatch({type: 'hide-alert'})
                                        onExit(cause)
                                    }
                                }
                            })
                        } else {
                            onExit(cause)
                        }
                         
                    }}
                    forceExit={forceExit}
                /> 
            </Grid> 
        </Grid>
    )
}

export default GenericStepSchema;