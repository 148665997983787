import { Typography, Button, DialogTitle, DialogActions, useMediaQuery, useTheme, DialogContent, WithStyles, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { FC } from 'react';
import Term from 'components/term';
import styles from '../shared/notificaciones.styles';

interface IProps extends WithStyles<typeof styles> {
    open: boolean;
    onClose: () => void;
}

const MensajeNotib: FC<IProps> = ({ open, onClose, classes }) => {
    // services
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const handleClose = () => onClose();

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            maxWidth={"md"}
            fullWidth={true}
        >
            <DialogTitle>
                <Term component="Notificaciones" text="Nota importante" />
            </DialogTitle>
            <DialogContent>
                <Typography component="p">
                    <Term component="Notificaciones" text="Mensaje Notib" />
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">
                    <Term component="Global" text="Cerrar" />
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default withStyles(styles)(MensajeNotib);