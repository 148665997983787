import React, { useState, useEffect, useContext, FC, useMemo, useCallback, useReducer } from 'react';
import { withStyles, WithStyles, Hidden, MenuItem } from '@material-ui/core';
import usePage from '../../hooks/page.hook';
import IoC from 'contexts/ioc.context';

import { RouteComponentProps } from 'react-router';
import { IBienesFilter, IDatosRecibo, IInmueble, IVehiculo } from 'gateways/bienesSujeto.interface';


import styles from '../shared/tributos.styles';
import NoContent from 'components/no-content';
import image from '../../resources/no-deuda.png';
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import { mdiHomeAccount } from '@mdi/js';
// Filter
import { FilterDataContext } from './filter.data.context';
import FiltersReducer, { IFiltersState, QueryRequestInmueble, QueryRequestVehiculos } from './filters.reducer';
import { ScreenType } from './filters.form';
import { FiltersContext } from './filters.context';
import FiltersActive from './filters.active';

import Term from 'components/term';
import { AlertsContext } from 'contexts/alerts.context';

import { download } from 'utils/download';
import { withRouter } from 'react-router-dom';
import CustomTabs from "components/custom-tabs";
import TabPanel from "components/tab-panel";



import { SujetosBienesGateway } from 'gateways/sujetos.bienes.gateway';

import { ContenidosGateway } from 'gateways/contenido.gateway';
import { SelectionContext } from './selection.context';

import PanelDrawer from './panel.drawer';
import SelectionReducer from './selection.reducer';
import Vehiculos from './vehiculos';
import Inmuebles from './inmuebles';
import { MoreInfoContext } from 'contexts/more_info.context';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';



import RecibosDialog from './recibos-dialog';
import { RecibosGateway } from 'gateways/recibo.gateway';

import CustomCountTerm from 'components/count-term/count-term';

const numberElementsPerPage = 50;

interface IParams {
    screen: ScreenType;
}

type Props = WithStyles<typeof styles> & RouteComponentProps<IParams>;

const BienesSujeto: FC<Props> = ({ classes, match, history }) => {
    const terms = useContext(LiteralsContext);
    const ioc = useContext(IoC);
    const sujetosBienesGateway: SujetosBienesGateway = useMemo(() => ioc.get(SujetosBienesGateway), [ioc]);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
    const recibosGateway = useMemo(() => ioc.get(RecibosGateway) as RecibosGateway, [ioc]);

    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);

    const filtersReducer = useReducer(FiltersReducer, {});
    const [filterData, setFilterData] = useState<IBienesFilter>({});
    const [filters, filtersDispatch] = filtersReducer;
    const [showFilters, setShowFilters] = useState(false);
    const [, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [page, setPage] = useState(0);
    const tabActive = useMemo(() => match.params.screen === 'inmuebles' ? 0 : 1, [match.params.screen])

    /** Recibos redirect */
    const [open, setOpen] = useState(false);
    const [seleccionado, setSeleccionado] = useState<IDatosRecibo>();
    const [munis, setMunis] = useState<string[]>([])
    const selectedMuniAtib: boolean = useMemo(() => munis.includes(seleccionado?.municipio || ""), [munis, seleccionado?.municipio]);

    /** Inmuebles */
    const [loadingInmuebles, setLoadingInmuebles] = useState(false);
    const [allInmuebles, setAllInmuebles] = useState<IInmueble[] | null>(null)
    const [updateInm, setUpdateInmuebles] = useState(false)
    const inmuebles = useMemo(() => {
        if (allInmuebles !== null || (updateInm && allInmuebles !== null)) {
            setLoadingInmuebles(true)
            let inm = [...allInmuebles]
            if (filters.refCatastral) {
                inm = inm.filter(i => i.refCatastral.includes(filters.refCatastral as string))
            }
            if (filters.tipoInmueble && filters.tipoInmueble.length > 0) {
                inm = inm.filter(i => filters.tipoInmueble?.includes(i.clase))
            }
            setLoadingInmuebles(false)
            setUpdateInmuebles(false)
            return inm;
        } else {
            setUpdateInmuebles(false)
        }
    }, [updateInm, allInmuebles]);
    const inmueblesCount = useMemo(() => (inmuebles ? inmuebles.length : 0), [inmuebles])

    const noContentInmuebles = useMemo(() => !loadingInmuebles && allInmuebles !== null && allInmuebles.length === 0, [loadingInmuebles, allInmuebles]);

    /**Vehiculos */
    const [vehiculos, setVehiculos] = useState<IVehiculo[]>([])
    const vehiculosCount = useMemo(() => (vehiculos ? vehiculos.length : 0), [vehiculos]);
    const [loadingVehiculosTab, setLoadingVehiculosTab] = useState(false);
    const [loadingVehiculos, setLoadingVehiculos] = useState(false);
    const hasMoreVehiculos = useMemo(() => vehiculosCount > (page === 0 ? (page + 1) : page) * numberElementsPerPage, [page, vehiculosCount]);
    const noContentVehiculos = useMemo(() => !loadingVehiculos && vehiculos && vehiculos.length === 0, [loadingVehiculos, vehiculos]);

    const handleSelectInmueble = useCallback(() => {
        return [] as IInmueble[]
    }, [alertsDispatch, contenidoGateway]);

    const handleSelectVehiculo = useCallback(() => {
        return [] as IVehiculo[]
    }, [alertsDispatch, contenidoGateway]);

    const selectionReV = SelectionReducer<IVehiculo>({ isSelectable: handleSelectVehiculo });
    const selectionReI = SelectionReducer<IInmueble>({ isSelectable: handleSelectInmueble });

    const selectionReducer = useReducer(match.params.screen === 'inmuebles' ? selectionReI : selectionReV, { selected: [] });
    const [, selectionDispatch] = selectionReducer;

    // Functions
    const buildQueryRequestInmueble = (newFilters: IFiltersState, newPage: number) => {
        return {
            refCatastral: newFilters.refCatastral,
            tipos: newFilters.tipoInmueble,
            skip: newPage * numberElementsPerPage,
            take: numberElementsPerPage
        } as QueryRequestInmueble;
    }

    const buildQueryRequestVehiculo = (newFilters: IFiltersState, newPage: number) => {
        return Object.assign({}, {
            matricula: newFilters.matricula,
            skip: newPage * numberElementsPerPage,
            take: numberElementsPerPage
        }) as QueryRequestVehiculos;
    }

    const handleLoadInmuebles = useCallback(async (newFilters: IFiltersState, newPage: number) => {
        const query = buildQueryRequestInmueble(newFilters, newPage);
        if (!loadingInmuebles) {
            try {
                setLoadingInmuebles(() => true);
                const inmueblesRecived = await sujetosBienesGateway.getInmueblesFilterData(query);
                if (inmueblesRecived) {
                    //if(!inmueblesRecived.ok) throw new Error(translate('BienesSujeto','ErrorFindAllInmuebles', terms));
                    setAllInmuebles(inmueblesRecived.bienes);
                    setPage(0)
                    setFilterData(inmueblesRecived.filtros)
                }
            } catch (error) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (error as any).message,
                        variant: 'error',
                    }
                });
                setAllInmuebles([])
            } finally {
                setLoadingInmuebles(() => false);
            }
        }
    }, [buildQueryRequestInmueble, sujetosBienesGateway, pageDispatcher]);

    const handleLoadVehiculos = useCallback(async (newFilters: IFiltersState, newPage: number, isMore: boolean) => {
        const query = buildQueryRequestVehiculo(newFilters, newPage);
        if (!loadingVehiculos) {
            isMore && setLoadingVehiculos(true);
            !isMore && setLoadingVehiculosTab(true);
            try {
                const vehiculosRecived = await sujetosBienesGateway.getVehiculosFilterData(query);
                if (vehiculosRecived) {
                    if (isMore) {
                        setVehiculos(vehiculos.concat(vehiculosRecived.vehiculos));
                    } else {
                        setVehiculos(vehiculosRecived.vehiculos);
                    }
                }
            } catch (error) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (error as any).message,
                        variant: 'error',
                    }
                });
                setVehiculos([])
            } finally {
                isMore && setLoadingVehiculos(false);
                !isMore && setLoadingVehiculosTab(false);
            }
        }
    }, [buildQueryRequestVehiculo, sujetosBienesGateway, pageDispatcher]);

    const handleMoreVehiculos = useCallback(() => {
        if (hasMoreVehiculos && !loadingVehiculos) {
            setPage(page + 1);
            handleLoadVehiculos(filters, page + 1, true);
        }
    }, [hasMoreVehiculos, loadingVehiculos, page, handleLoadVehiculos, filters]);

    const handleChange = (_E: React.ChangeEvent<{}>, newValue: number) => {
        history.push(`${newValue === 0 ? 'inmuebles' : 'vehiculos'}`)
    };

    const handleRedirectDeuda = async (pendiente: boolean) => {
        if (seleccionado) {
            const objetoTributario = seleccionado.objeto;
            history.push({
                pathname: `/cartero-virtual/deuda-${pendiente ? 'pendiente' : 'pagada'}`,
                state: {
                    objeto: objetoTributario,
                    municipio: seleccionado.municipio,
                }
            })
        }
    }

    const handleSelectBien = (datosRecibo: IDatosRecibo) => {
        setOpen(true)
        setSeleccionado(datosRecibo)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleExportRequest = useCallback(async () => {
        if (match.params.screen === 'inmuebles') {
            const query = buildQueryRequestInmueble(filters, 1);
            delete (query as any).skip;
            query.take = 9999;
            let response = null;
            response = await sujetosBienesGateway.exportarInmuebles(query);
            download(response.blob, alertsDispatch,
                translate('Global', 'No se ha podido exportar', terms),
                translate('Global', 'Exportar', terms), response.fileName);
        } else {
            const query = buildQueryRequestVehiculo(filters, 1);
            delete (query as any).skip;
            query.take = 9999;
            let response = null;
            response = await sujetosBienesGateway.exportarVehiculos(query);
            download(response.blob, alertsDispatch,
                translate('Global', 'No se ha podido exportar', terms),
                translate('Global', 'Exportar', terms), response.fileName);
        }

    }, [buildQueryRequestInmueble, buildQueryRequestVehiculo, filters, match.params.screen, alertsDispatch, terms, sujetosBienesGateway]);

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch])
    const handleCloseSubMenu = useCallback(() => setOpenSubMenuAnchorEl(null), []);
    const handleShowFilters = useCallback(() => {
        setShowFilters(true);
        handleCloseSubMenu();
    }, [handleCloseSubMenu]);

    // Effects
    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiHomeAccount,
                title: <Term component="Global" text={'BienesSujeto'} />,
                moreInfoTemplate: 'mas_info_bienes_sujeto',
                right: (
                    <>
                        <MenuItem button onClick={handleShowFilters}><Term component="Global" text="Filtrar resultados" /></MenuItem>
                        <MenuItem button onClick={() => handleShowMasInfo('mas_info_bienes_sujeto')}>
                            <Term component="Global" text="Mas informacion" />
                        </MenuItem>
                    </>
                )
            },
            menu: true,
        });
    }, [pageDispatcher, terms]);


    useEffect(() => {
        // CLEAN not needed info and LOAD required data
        if (match.params.screen === 'inmuebles') {
            setVehiculos([]);
            // Get info - fisrt all + filters
            handleLoadInmuebles({}, 0,);
        } else {
            setLoadingVehiculosTab(true);
        }
        // CLEAN Filter
        setPage(0);
        filtersDispatch({ type: 'clear' });
        selectionDispatch({ type: 'clear' });
    }, [match.params.screen]);

    useEffect(() => {
        if (match.params.screen === 'vehiculos') {
            const delayDebounceFn = setTimeout(() => {
                //console.log(filters)
                // request here
                handleLoadVehiculos(filters, 0, false)
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        } else {
            if (allInmuebles !== null) {
                setLoadingInmuebles(true)
                const delayDebounceFn = setTimeout(() => {
                    // console.log(filters)
                    // request here
                    setUpdateInmuebles(true);
                    setLoadingInmuebles(false);
                }, 1000)

                return () => clearTimeout(delayDebounceFn)
            }
        }
    }, [filters])

    useEffect(() => {
        const func = async () => {
            const municipiosAtib = await sujetosBienesGateway.getMunicipiosAtib();
            setMunis(municipiosAtib)
        };
        func();
    }, [])

    return (
        <FiltersContext.Provider value={filtersReducer}>
            <SelectionContext.Provider value={selectionReducer}>
                <FilterDataContext.Provider value={filterData}>
                    <div className={classes.root}>
                        <div className={classes.recibosContainer}>
                            <FiltersActive />
                            <RecibosDialog open={open} handleClose={handleClose} handleRedirectDeuda={handleRedirectDeuda} selectedMuniAtib={selectedMuniAtib}></RecibosDialog>
                            {/* Selector tabs */}
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '1%', backgroundColor: 'white', borderRadius: '3px' }}>
                                <CustomTabs
                                    labelTab1={translate('BienesSujeto', 'inmuebles', terms).toUpperCase()}
                                    labelTab2={translate('BienesSujeto', 'vehiculos', terms).toUpperCase()}
                                    onChange={handleChange}
                                    tabActive={tabActive}
                                />
                            </div>
                            <TabPanel value={tabActive} index={0} loading={loadingInmuebles}>
                                <NoContent
                                    image={image}
                                    visible={noContentInmuebles}
                                    component='BienesSujeto'
                                    text={'No se ha encontrado bienes inmuebles'}
                                />
                                {
                                    inmueblesCount > 0 && inmuebles
                                        ?
                                        <Inmuebles
                                            inmuebles={inmuebles}
                                            count={inmueblesCount}
                                            handleSelectBien={handleSelectBien}
                                        />
                                        : null
                                }
                            </TabPanel>
                            <TabPanel value={tabActive} index={1} loading={loadingVehiculosTab} >
                                <NoContent
                                    image={image}
                                    visible={noContentVehiculos}
                                    component='BienesSujeto'
                                    text={'No se ha encontrado vehículos'}
                                />
                                {
                                    vehiculosCount > 0
                                        ?
                                        <Vehiculos
                                            vehiculos={vehiculos}
                                            count={vehiculosCount}
                                            handleMore={handleMoreVehiculos}
                                            handleSelectBien={handleSelectBien}
                                            hasMore={hasMoreVehiculos}
                                            loading={loadingVehiculos}
                                        />
                                        : null
                                }

                            </TabPanel>
                        </div>
                    </div>


                    <div className={classes.drawer}>
                        <Hidden smDown implementation="css">
                            <PanelDrawer
                                screen={match.params.screen}
                                //onFiltersChange={handleFiltersChanged}
                                onExportRequest={handleExportRequest}
                            />
                        </Hidden>
                    </div>


                </FilterDataContext.Provider>
            </SelectionContext.Provider>
        </FiltersContext.Provider >
    );
}

export default withRouter(withStyles(styles)(BienesSujeto));
