import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Dialog, Button, DialogActions, DialogContent, WithStyles, Divider, createMuiTheme, MuiThemeProvider, withStyles, DialogTitle, TextField, useTheme, useMediaQuery } from "@material-ui/core";
import Term from './term';
import { StyleRules } from '@material-ui/styles';
import usePage from 'hooks/page.hook';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IRepresentado, ISolicitudRepresentacion } from 'gateways/tramites/representaciones.interfaces';
import IoC from 'contexts/ioc.context';
import { RepresentacionesGateway } from 'gateways/tramites/representaciones.gateway';
import { AuthGateway } from 'gateways/auth.gateway';
import { translate } from 'utils/i18n';
import { InputChangeHandler } from 'utils/events';
import { LiteralsContext } from 'containers/shared/literals';
import { BLUE } from 'containers/shared/colors';

const styles = (): StyleRules => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    content: {
        margin: 5,
    },
    title: {
        color: '#004f84',
        fontSize: 32,
        fontWeight: 'lighter'
    },
    selectField: {
        width: '100%'
    },
});

type Props = WithStyles<typeof styles>;

const RepresentantesDialog: FC<Props> = ({ classes }) => {
    const [page, pageDispatch] = usePage();
    const terms = useContext(LiteralsContext);    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const ioc = useContext(IoC);
    const authGateway = useMemo(() => ioc.get(AuthGateway) as AuthGateway, [ioc]);
    const representacionesGateway = useMemo(() => ioc.get(RepresentacionesGateway) as RepresentacionesGateway, [ioc]);

    // States
    const [representaciones, setRepresentaciones] = useState<IRepresentado[]>([]);
    const [, setRepresentado] = useState('');

    const formLabelsTheme = createMuiTheme({
        overrides: {
            MuiTypography: {
                h6: {
                    fontSize: 32,
                    color: '#004f84',
                    fontFamily: 'Roboto',
                    fontWeight: 'lighter',
                }
            },
            MuiButton: {
                textPrimary: {
                    color: BLUE,
                }
            },
            MuiDialog: {
                paperWidthSm: {
                    minWidth: 600,
                }
            },
        }
    });

    const handleChangeRepresentadoText: InputChangeHandler = (event) => {
        setRepresentado(event.target.value);
    }

    const handleClose = useCallback(() => {
        pageDispatch({ type: 'close-modal-representar' });
    }, [pageDispatch]);

    const handleActuarNombrePropio = useCallback(async () => {
        const result = await authGateway.actuarNombrePropio();
        if (result && result.accessToken !== '') {
            pageDispatch({ type: 'authenticate', payload: result });
        }
        
        if(window.location.pathname.includes('DUTI/consulta/action')){
            window.location.replace('/tributos/DUTI/consulta')
        } else {
            window.location.reload();
        }

        handleClose();
    }, [authGateway, handleClose, pageDispatch]);

    const handleRepresentar = useCallback(async (event: any, newValue: any) => {
        const result = await authGateway.refreshTokenRepresentado(newValue.nifPoderdante, newValue.procedimientos);
        if (result && result.accessToken !== '') {
            pageDispatch({ type: 'authenticate', payload: result });
        }

        if(window.location.pathname.includes('DUTI/consulta/action')){
            window.location.replace('/tributos/DUTI/consulta')
        } else {
            window.location.reload();
        }
       
        handleClose();
    }, [authGateway, pageDispatch, handleClose]);

    useEffect(() => {
        (async () => {
            if (page.jwp) {
                const result = await representacionesGateway.getRepresentados();
                setRepresentaciones(result);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page.jwp]);

    return page.openModalRepresentar
        ? (
            <MuiThemeProvider theme={formLabelsTheme}>
                <Dialog
                    fullScreen={fullScreen}
                    open={page.openModalRepresentar}
                    aria-labelledby="representar-dialog-title"
                    aria-describedby="representar-dialog-description"
                >
                    <DialogTitle id="representar-dialog-title" style={{ marginTop: 20 }} className={classes.content}>
                        <span className={classes.title}><Term component="Global" text="ActuarRepresentacion" /></span>
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                    <Autocomplete
                        freeSolo
                        id="outlined-representado-input"
                        className={classes.selectField}
                        options={representaciones}
                        getOptionLabel={(option) => option.nifPoderdante + ' - ' + option.nombrePoderdante}
                        noOptionsText={translate('Perfil', 'No existen coincidencias', terms)}
                        onChange={handleRepresentar}
                        renderInput={(params) => (
                            <TextField {...params}
                                variant="standard"
                                onChange={handleChangeRepresentadoText}
                                label={translate('Global', 'Representados', terms)}
                            />
                        )}
                    />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ color: BLUE }}>
                        <Button
                            onClick={handleClose}
                            variant="outlined"
                            color="default"
                        >
                            <Term component="Global" text="Cerrar" />
                        </Button>
                        {
                            page && page.jwp && page.jwp.nifRepresentante &&
                            <Button
                                onClick={handleActuarNombrePropio}
                                variant="outlined"
                                color="primary"
                            >
                                <Term component="Global" text="Actuar en nombre propio" />
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        ) : null
};

export default withStyles(styles)(RepresentantesDialog);