import { withLiterals } from 'containers/shared/literals';
import React from 'react';
import ProtectedRoute from 'components/protected-route';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import CitaPrevia from './cita-previa';
import CancelarCita from './cancelar-cita';
import Justificante from './justificante';
import MisCitas from './mis-citas';
import CitaPreviaHome from './cita-previa-home';



const CitaPreviaIndex: React.FC<RouteComponentProps> = ({ match }) => {
    return (
        <>
            <Route path={`${match.path}`} exact component={CitaPreviaHome} />

            <Route path={`${match.path}/cita`} exact component={CitaPrevia} />
            <Route path={`${match.path}/cancelar-cita`} exact component={CancelarCita} />
            <Route path={`${match.path}/justificante`} exact component={Justificante} />
            <ProtectedRoute path={`${match.path}/mis-citas`} component={MisCitas} idModulos={[17]} />

        </>
    );
}

export default withLiterals(['Global','CitaPrevia'])(withRouter(CitaPreviaIndex));