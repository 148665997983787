import React, { FC, useEffect, useState, useContext } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText } from "@material-ui/core";
import Term from "components/term";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";

interface ISimpleDialogProps {
    open: boolean;
    onClose: () => void;
    idOficina: number | null;
}

const ServiciosDialog: FC<ISimpleDialogProps> = ({ open, onClose, idOficina }) => {
    const terms = useContext(LiteralsContext);
    const [textoComputado, setTextoComputado] = useState<string | null>(null);

    const handleClose = () => {
        onClose();
    }

    useEffect(() => {
        if (idOficina !== null) {
            setTextoComputado(translate('Oficinas', 'Tiene id oficina', terms) + ': ' + idOficina);
        } else {
            setTextoComputado(translate('Oficinas', 'No tiene id oficina', terms));
        }
    }, [idOficina, terms]);

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="alert-dialog-title">{"Servicios disponibles"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Term component="Oficinas" text="Lista de servicios" />
                    <p><Term component="Oficinas" text="Id Oficina" />: {idOficina}</p>
                    <p>{textoComputado}</p>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default ServiciosDialog;
