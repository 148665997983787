import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";

const styles = (theme: Theme): StyleRules => ({
    root: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
            width: 300,
        }
    },
    centerContent: {
        textAlign: 'center',
        paddingTop: 250,
        width: '100%',
    },
    centerContentAbsolute: {
        position: 'absolute',
        left: '0%',
        top: '0%',
        zIndex: 10,
        width: '100%',
        height: '100%',
        backgroundColor: '#185abc30',
        
    },
    progressAbsolute: {
        zIndex: 10,
        position: 'absolute',
        left: '50%',
        top: '50%',
        opacity: 1,
    },
    progressContainer: { 
        display:'flex',
        height: '100%',
        alignItems:'center',
        justifyContent: 'center',
    },
    cardContador: {
        marginBottom: '0.5rem',
        backgroundColor: 'rgba(255,255,255,0.6)',
    },
    cardContadorContainer: {
        padding: '13px !important',
        display: 'flex',
        alignItems: 'center',
    },
    titleCardContador: {
        fontWeight: 300,
        fontSize: 16,
        marginLeft: '1rem',
        color: '#406a8d',
        flex: 1
    },
    recibosContainer: {
        flex: 1,
        margin: '0 0.5rem'
    },
    recibo: {
        marginBottom: '0.5rem'
    },
    mainButton: {
        width: '100%',
        textAlign: 'left',
    },
    [theme.breakpoints.up('md')]: {
        root: {
            display: 'flex',
        },
        recibosContainer: {
            margin: '0 2rem'
        },
        scrollPanel: {
            height: 570,
            overflow: "auto",
        },
        buttonPagarTodos: {
            display: 'block',
            marginLeft: 10,
        }
    },
    hide: {
        display: 'none'
    },
    margin15: {
        marginLeft: 15,
    },
    pointer:{
        cursor:'pointer'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        [theme.breakpoints.up('md')]: {
            left: '50%',
        }
    }
});

export default styles;