import { StyleRules, Theme, makeStyles } from "@material-ui/core";
import { BLUE, BLUE_DARK, BLUE_PLV, BLUE_RIGHT_HEADER } from "containers/shared/colors";

const styles =  (theme: Theme): StyleRules => ({
    root: {
        margin: '0 0.5rem',
        flex: 1,
    },
    padding: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 15,
        paddingLeft: 15,
        height: '100%'
    },
    column:{
        display:'flex',
        flexDirection: 'column',
        height: 'calc( 100% - 48px )'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    rowBtn: {

        height: 48,
        width: '100%',
        textAlign:'right'
    },
    paddingInfoTransm: {
        marginTop:20
    },
    /** Cuadro azul ---------------------------------------------------------------------------------------- */
    borderPlusval: {
        borderBottom:'solid 1px',
        borderBottomColor: BLUE_RIGHT_HEADER,
        padding: '10px 10px 10px 10px',
        marginBottom: 10,
        position: 'relative'
    },
    divTextBorderPlusval: {
        display: 'flex',        /* Activa Flexbox */
        justifyContent: 'flex-end', /* Alinea el contenido al final (derecha) */
        width: '100%',         /* Asegura que ocupe todo el ancho del Grid */
        position: 'absolute',   /* Posicionamiento absoluto respecto a su contenedor relativo */
        right: 20,            /* Alinea a la derecha */
        top: -20,              /* Alinea al borde superior */
    },
    textBorderPlusval: {
        padding: 10,
        color: BLUE_PLV,
        backgroundColor: 'white'
    },
    // render short detail
    textRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        paddingRight: 25
    },
    text: {
        color: 'grey',
        paddingRight:10
    },
    textValue: {
        fontWeight: 'bolder',
        paddingRight:10
    },
    marginBottom:{
        marginBottom: 10
    },
    titleBlue : {
        width: '100%',
        color: BLUE_RIGHT_HEADER,
        fontWeight: 450,
        fontSize: 18
    },
    titleGray : {
        width: '100%',
        color: 'gray',
        fontWeight: 450,
    },
    // lista calculos
    headerDerecho:{
        borderBottomColor: BLUE_RIGHT_HEADER,
        borderBottom: 'solid 2px',
    },
    textDerecho: {
        color: BLUE_RIGHT_HEADER,
        fontSize: 18,
        fontWeight: 500,
    },
    /** END Cuadro azul ------------------------------------------------------------------------------------ */
    input285: { 
        width: 285
    },
    
})

export const useStyles = makeStyles(styles)
export default styles