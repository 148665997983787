import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import IoC from "contexts/ioc.context";
import { ISujeto } from "gateways/perfil.interfaces";
import { SujetosGateway } from "gateways/sujetos.gateway";
import { FC, useContext, useEffect, useState } from "react";
import { dateFormat } from "utils/dates";
import Term from "./term";
import { mdiHomeCity, mdiAt, mdiCellphone } from '@mdi/js';
import { Icon } from '@mdi/react';
import usePage from "hooks/page.hook";

/**
 * Especificación de la tarea
 * 
 * - Se debe verificar cada 12 meses
 * - Móvil y teléfono no son obligatorios de ser rellenados
 * - El campo dirección debe validarse cada 12 meses y es obligatorio
 * 
 */

const useStyles = makeStyles(() => ({
    centerContent: {
        textAlign: 'center',
        padding: 100,
        width: '100%',
    },
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    content: {
        margin: '0 20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem'
    },
    title: {
        color: '#004f84',
        fontSize: 32,
        fontWeight: 'lighter'
    },
    containerContentDetail: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem'
    },
    contentDetail: {
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center'
    },
    containerBtns: {
        margin: '30px',
        display: 'flex',
        marginBottom: 20
    },
    circularProgressCentered: {
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        position: "absolute"
    }
}));

interface IProps {
    sujeto: ISujeto | null;
    fechaDatosValidados: Date | null;
    onModificacionDatosSujeto: () => void;
    onClose: () => void;
}

const AvisoVerificacionDatosSujetoDialog: FC<IProps> = ({ sujeto, fechaDatosValidados, onModificacionDatosSujeto, onClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [pageState, pageDispatcher] = usePage();
    // Servicios
    const ioc = useContext(IoC);
    const perfilG = ioc.get(SujetosGateway) as SujetosGateway;
    // Variables
    const [loading, setLoading] = useState(false);
    const [loadingRecuperarJustificante, setLoadingRecuperarJustificante] = useState(false);
    const [sujetoDatosValidados, setSujetoDatosValidados] = useState<"validados" | "no-validados" | "pendiente">("pendiente");
    const [isMissingPhoneAndEmail, setMissingPhoneAndEmail] = useState(false);

    const handleTitle = () => {
        if (sujetoDatosValidados === "validados")
            return <Term component="Perfil" text="Datos actualizados" />;
        else if (sujetoDatosValidados === "no-validados")
            return <Term component="Perfil" text="Datos no actualizados" />;
        else
            return <Term component="Global" text="Aviso importante" />;
    }

    const handleInfoToDisplay = () => {
        if (sujetoDatosValidados === "validados" && sujeto && sujeto.email)
            return <Term component="Perfil" text="Datos actualizados información" />;
        else if (sujetoDatosValidados === "validados" && sujeto && !sujeto.email)
            return <Term component="Perfil" text="Datos actualizados y sujeto sin email" />;
        else if (sujetoDatosValidados === "no-validados")
            return <Term component="Perfil" text="Datos no actualizados información" />;
        else
            <Term component='Acceso' text='Verificar_datos_conf_avisos' />
    }

    const handleDatosCorrectos = async () => {
        const response = await perfilG.validarDatosSujeto();
        response
            ? setSujetoDatosValidados("validados")
            : setSujetoDatosValidados("no-validados");
    };

    const handleCambiarDatos = () => {
        onModificacionDatosSujeto();
    };

    const handleCloseDialog = async () => {
        setLoadingRecuperarJustificante(true);
        if (sujetoDatosValidados === 'validados') {
            const response = await perfilG.validarDatosSujeto();
            await perfilG.recuperarJustificanteVerificacionDatos();
            if (window.location.pathname.includes('perfil') && response) window.location.reload();
            else if (!response) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: <Term component="Perfil" text="Error actualizar fecha verificación" />,
                        variant: 'error',
                    }
                })
            }
        }
        onClose();
        setLoadingRecuperarJustificante(false);
    }

    // ---------- UseEffects ----------

    useEffect(() => {
        if (pageState.datosSujetoDialog.variant === "info") setSujetoDatosValidados("pendiente");
        else if (pageState.datosSujetoDialog.variant === "success") setSujetoDatosValidados("validados");
        else setSujetoDatosValidados("no-validados");
    }, [pageState.datosSujetoDialog]);

    useEffect(() => {
        if (!sujeto) {
            setLoading(true);
            return;
        }
        else if (sujeto) setLoading(false);

        if (sujeto && !sujeto.movil && !sujeto.email) setMissingPhoneAndEmail(true);
        else setMissingPhoneAndEmail(false);
    }, [sujeto]);

    return (
        <Dialog
            open={true}
            fullScreen={fullScreen}
        >
            {loading
                ?
                <div className={classes.centerContent}>
                    <CircularProgress size={50} />
                </div>
                :
                <>
                    <DialogTitle style={{ marginTop: 20 }} className={classes.content}>
                        <Typography className={classes.title}>
                            {handleTitle()}
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        {handleInfoToDisplay()}

                        {sujetoDatosValidados === "pendiente" &&
                            <div>
                                <div className={classes.contentDetail}>
                                    <Term component={'Acceso'} text={'Última verificación'} />:
                                    <strong>
                                        {
                                            fechaDatosValidados
                                                ? dateFormat(fechaDatosValidados)
                                                : <Term component={'Acceso'} text={'Sin verificar'} />
                                        }
                                    </strong>
                                </div>
                                {sujeto && sujeto.nombre && sujeto.nif && `${sujeto.nombre} - ${sujeto.nif}`}
                            </div>
                        }

                        {sujetoDatosValidados !== "no-validados" &&
                            <div className={classes.containerContentDetail}>
                                <div className={classes.contentDetail}>
                                    <Icon path={mdiHomeCity} size={0.8} color="black" />
                                    <strong>
                                        {
                                            sujeto && sujeto.direccionNotificacionString
                                                ? sujeto.direccionNotificacionString
                                                : <Term component={'Acceso'} text={'Datos no registrados'} />
                                        }
                                    </strong>
                                </div>
                                <div className={classes.contentDetail}>
                                    <Icon path={mdiAt} size={0.8} color="black" />
                                    <strong>
                                        {
                                            sujeto && sujeto.email
                                                ? sujeto.email
                                                : <Term component={'Acceso'} text={'Datos no registrados'} />
                                        }
                                    </strong>
                                </div>
                                <div className={classes.contentDetail}>
                                    <Icon path={mdiCellphone} size={0.8} color="black" />
                                    <strong>
                                        {
                                            sujeto && sujeto.movil
                                                ? `${sujeto.movil}`
                                                : <Term component={'Acceso'} text={'Datos no registrados'} />
                                        }
                                    </strong>
                                </div>
                            </div>
                        }
                    </DialogContent>
                    <DialogActions className={classes.containerBtns} style={sujetoDatosValidados === "pendiente" && !isMissingPhoneAndEmail ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}>
                        {sujetoDatosValidados === "pendiente"
                            ?
                            <>
                                {!isMissingPhoneAndEmail &&
                                    <Button
                                        onClick={handleDatosCorrectos}
                                        color="default"
                                        variant="outlined"
                                    >
                                        <Term component={'Global'} text={'Datos correctos'} />
                                    </Button>
                                }
                                <Button
                                    onClick={handleCambiarDatos}
                                    color="primary"
                                    variant="contained"
                                >
                                    <Term component={'Global'} text={'Cambiar datos'} />
                                </Button>
                            </>
                            :
                            <Button
                                onClick={handleCloseDialog}
                                color="primary"
                                variant="contained"
                                disabled={loadingRecuperarJustificante}
                            >
                                <Term component={'Global'} text={'Cerrar'} />
                                {loadingRecuperarJustificante &&
                                    <CircularProgress size={20} className={classes.circularProgressCentered} />
                                }
                            </Button>
                        }
                    </DialogActions>
                </>
            }
        </Dialog>
    );
}

export default AvisoVerificacionDatosSujetoDialog;