import { Reducer } from "react";
import { ScreenType } from "./filters.form";

export interface IFiltersState {
    matricula?: string;
    tipoInmueble?: string[];
    refCatastral?: string
}
export type QueryRequest= IFiltersState & {
    skip?: number;
    take?: number;
}

export type QueryRequestInmueble = {
    refCatastral: string;
    tipos?: string[];
    skip?: number;
    take?: number;
}

export type QueryRequestVehiculos = {
    matricula?: string;
    anyo?: string;
    skip?: number;
    take?: number;
}



export type FiltersAction =
    | { type: 'update-any', filters: Partial<IFiltersState> }
    | { type: 'update', filter: keyof IFiltersState, value: any }
    | { type: 'clear' }

const FiltersReducer: Reducer<IFiltersState, FiltersAction> = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case 'update-any':
            return { ...state, ...action.filters };
        case 'update':
            newState[action.filter] = action.value;
            return newState;
        case 'clear':
            for (const prop in newState) {
                if (prop in newState) {
                    (newState as any)[prop] = undefined;
                }
            }
            return newState;
        default: throw new Error('Unexpected action');
    }
};

export default FiltersReducer;