
import { TSelect } from "containers/DUTI/utils"
import { translate } from "utils/i18n";
import { ITipoTransmision, TTipoDerechoTransmision, TTipoDocTransmision } from "gateways/model.new.interface";
import { TipoAsistente } from "containers/tributos-autonomicos-locales/utils";

export const getTiposTransmsisionByTipoAsistente=(tipos: ITipoTransmision[], terms: any): TSelect[] => {
    const out= tipos.map(tipo => ({
        id: tipo.idTipoTransmision,
        nombre: translate('Plusvalias',`tipo_transmision_${tipo.idTipoTransmision}`,terms)
    }))

    return out
}

export const getTiposDerechoTransmsisionByTipoAsistente= (tipoAsistente: TipoAsistente, terms: any ): TSelect[] => {
    const tipos: Record<TipoAsistente,TTipoDerechoTransmision[]> = { 
        'plusvalia': ['pleno-dominio', 'nuda-propiedad','usufructo-temporal', 'usufructo-vitalicio']
    }

    const out= tipos[tipoAsistente].map( item => ({
        id: item,
        nombre: translate('Global',`tipo-derecho-${item}`.replaceAll('-','_'),terms)
    }))

    return out
}

export const getTiposDocTransmsisionByTipoAsistente= (tipoAsistente: TipoAsistente, terms: any ): TSelect[] => {
    const tipos: Record<TipoAsistente,TTipoDocTransmision[]> = { 
        'plusvalia': ['privado','publico-administrativo','publico-judicial','publico-notarial','publico-otros']
    }

    const out= tipos[tipoAsistente].map( item => ({
        id: item,
        nombre: translate('Global',`tipo-doc-transmision-${item}`.replaceAll('-','_'),terms)
    }))

    return out
}
