import { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent, Grid, GridSize, IconButton} from '@material-ui/core';
import { Typography, Button, makeStyles } from '@material-ui/core';
import styles from './styles';
import { formatXMLFixedMultipletoObject, formatXMLMultipletoObject, formatXMLtoObject, GenericInitialObject, GenericObject, getEmptyObject, getInitialObject, getKeysRequiredCampos, getXMLValueByFormula, GroupLabel, renderCampos, SelectoresInfo } from '../utils';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    formulaRepeticiones: string | null,
    datosXml: string | null,
    datosXmlApartadosPrevios: GenericObject,
    setFinished: (value: boolean) => void,
    handleSave: (data: GenericInitialObject[]) => Promise<boolean>,
}

const FixedApartadoMultiple: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        formulaRepeticiones,
        datosXml,
        datosXmlApartadosPrevios,
        setFinished,
        handleSave
    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes,setChanges]= useState<boolean|null>(false);
    const [campos, setCampos] = useState<ICampo[] | null>(null);
    const [EMPTY,setEmpty] = useState<GenericInitialObject|null>(null);
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    const [dataList, setDataList] = useState<GenericInitialObject[]>([]);
    const [groupsOperacion, setGroupsOperacion] = useState<GroupLabel>({});
    const [fieldsRepeatNumber, setFieldsRepeatNumber] = useState<number>(0);

    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData= (name:string, value: any) => {
        let newData=null;
        if(EMPTY){
            newData= data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY,[name]: {...EMPTY[name], value: value }}

        }
        setData(newData)
        setChanges(true)
    }

    const onSave = () => { // Auto-Save 
        (async()=> {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            if(camposRequiredKeys && camposRequiredKeys.length>0 ){
                if(data){
                    camposRequiredKeys.forEach( element => {
                        //console.log('element', element)
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal' || 'decimal2'|| 'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                }
            }
            const fieldsArrayByGroup = [];
            for(let i=0;i<fieldsRepeatNumber;i++){
                const filteredFields: any[] | undefined = campos?.filter(campo => campo.renderGroup == i);
                fieldsArrayByGroup.push(filteredFields);
            }
            const fieldsList: any[] = [];
            fieldsArrayByGroup && fieldsArrayByGroup.forEach((groupFields) => {
                const fieldsGrouped = groupFields && groupFields.reduce((obj, currentValue) => {return {...obj, [currentValue.codigo]:currentValue}},{});
                fieldsList.push(fieldsGrouped);
            });
            if(valid){
                const newDataList: GenericInitialObject[] = [];
                if (data) {
                    fieldsList.forEach(fieldGroup => {
                        let fieldData: any = {};
                        Object.keys(fieldGroup).forEach(key => {
                            fieldData[key] = data[key];
                        })
                        newDataList.push(fieldData);
                    })
                }
                const saved = await handleSave(newDataList); 
                setChanges(!saved);
            } else {
                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos','FormularioError', terms),
                        variant: 'error',
                    }
                });   
            }
            setLoading(false);
        })();
    }

    useEffect(() => {
        (async()=> {
            try {
                setLoading(true)
                //console.log('formulaRepeticiones',formulaRepeticiones);
                //console.log('datosXmlApartadosPrevios',datosXmlApartadosPrevios);
                const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                const repeatNumberByFormula = formulaRepeticiones && datosXmlApartadosPrevios ? getXMLValueByFormula(formulaRepeticiones, datosXmlApartadosPrevios) : 0;
                const repeatNumber = Number(repeatNumberByFormula) > 0 ? Number(repeatNumberByFormula) : 1;
                setFieldsRepeatNumber(repeatNumber);
                const camposRepetidos: ICampo[] = [];
                //console.log('repeatNumber', repeatNumber);
                for (let i = 0; i < repeatNumber; i++) {
                    camposApartado.forEach((campoApartado:ICampo) => {
                        const newCampo = Object.assign({},campoApartado);
                        newCampo.casilla = newCampo.casilla + i;
                        newCampo.idCampo = i > 0 ? newCampo.idCampo + i + 4 : newCampo.idCampo;
                        newCampo.codigo = newCampo.codigo.substring(0, newCampo.codigo.indexOf('c')+1) + newCampo.casilla
                        newCampo.renderGroup = i;
                        camposRepetidos.push(newCampo);
                    });
                    setGroupsOperacion((prev: any) =>({
                        ...prev,
                        [i]: 'Operacion ' + (i+1)
                    }))
                }
                setCampos(camposRepetidos);
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                let newDataList: GenericInitialObject[] = [];
                if((repeatNumber > 1 && camposRepetidos) || (camposApartado && camposApartado?.length>0)){
                    emptyObjt= getEmptyObject(repeatNumber > 1 ? camposRepetidos : camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEmpty(emptyObjt);
                    const keys = getKeysRequiredCampos(repeatNumber > 1 ? camposRepetidos : camposApartado);
                    setCamposRequiredKeys(keys)  
                    if(datosXml){
                        //console.log('USE datosXML', datosXml)
                        newDataList = formatXMLFixedMultipletoObject(datosXml,emptyObjt) as GenericInitialObject[];
                        setDataList(newDataList) 
                        setChanges(false);
                        newData = newDataList.reduce((obj,currentValue) => {return {...obj, ...currentValue}},{});
                    } else{
                        setChanges(true);
                        newData = emptyObjt 
                    }    
                }
                setData(newData);
                setLoading(false)
            } catch (error) {
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);
    
    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
    },[changes])
 
    return(
        <div> 
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
            <div className={classes.row} style={{display: 'inline-flex', paddingBottom: 20}}>
                {campos && campos.length>0 && camposRequiredKeys !== null && data &&
                    renderCampos(campos, data, updateData, '', idTipoApartado, undefined, groupsOperacion, classes)
                }                 
            </div> 
            <div className={classes.rowAlignRight}>
                <Button 
                    disabled={!changes}
                    style={{marginLeft: 10}}
                    color='primary'
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnSave',terms)} 
                </Button>
            </div>    
        </div>
    )
}
export default withLiterals(['Tributos'])(FixedApartadoMultiple);

