import React, { ReactNode, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogContentText, useTheme, useMediaQuery, makeStyles, DialogTitle, DialogActions } from '@material-ui/core';
import Term from 'components/term';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        textAlign: "center",
    },
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        textAlign: 'center'
    },
    main: {
        padding: 20
    },
    section: {
        marginBottom: 20
    },
    text: {
        marginTop: 5,
        marginBottom: 5
    },
    textField: {
        width: '100%'
    },
    bold: {
        fontWeight: 'bold'
    },
    mainButton: {
        width: '100%',
        textAlign: 'left',
        paddingTop: 15,
        paddingBottom: 15,
    },
    mensajeNotificacion: {
        color: 'red',
    },
    [theme.breakpoints.up('sm')]: {
        emailDialogContent: {
            minWidth: 599
        }
    }
}));

interface IProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    notificado: boolean;
    text: string | ReactNode;
    type: 'H' | 'P';
}

function ConfirmDialog(props: IProps) {
    const { onClose, onConfirm, open, notificado, text, type } = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [disabledButton, setDisabled] = useState(false);

    const handleClose = () => {
        onClose();
    }

    const handleConfirm = () => {
        setDisabled(true);
        onConfirm();
        setTimeout(() => setDisabled(false), 5000);
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Term component="CarteroVirtual" text="AVISO" />
                </DialogTitle>

                <DialogContent className={classes.emailDialogContent}>

                    <div>
                        <DialogContentText>
                            {
                                type === 'H' && typeof text === 'string'
                                    ? <div dangerouslySetInnerHTML={{ __html: text }} />
                                    : <DialogContentText id="alert-dialog-description">
                                        {text}
                                    </DialogContentText>
                            }
                        </DialogContentText>
                    </div>
                    {
                        notificado ?
                            <b className={classes.mensajeNotificacion}>
                                <Term component="Perfil" text="ATENCION: Recuerde que usted esta dado de alta en el servicio de notificación" />
                            </b>
                            :
                            null
                    }
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button color="secondary" onClick={handleClose}>
                            <Term component="Perfil" text="Cancelar" />
                        </Button>
                        <Button color="primary" onClick={handleConfirm} disabled={disabledButton}>
                            <Term component="Perfil" text="Aceptar" />
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default ConfirmDialog;