import { FC, useEffect, useContext, useState, useMemo } from 'react';
import { WithStyles, withStyles, Hidden, CircularProgress, Card, CardContent, Button } from '@material-ui/core';
import { IParams } from './params.interfaces';
import { RouteComponentProps } from 'react-router-dom';
import IoC from 'contexts/ioc.context';
import styles from './pago.styles';
import * as paymentGateway from 'gateways/payment.gateway';
import Term from 'components/term';
import usePage from 'hooks/page.hook';
import ModeloReciboGrid from './modelo.recibo.grid'
import { IModelGateway } from 'gateways/modelGateway.interface';
import { LiteralsContext } from 'containers/shared/literals';
import { Icon } from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';
import ModelGateway from 'gateways/model.new.gateway';
import { RecibosGateway } from 'gateways/recibo.gateway';
import { IDetalleRecibo } from 'gateways/recibo.interfaces';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import { AlertsContext } from 'contexts/alerts.context';

const VueltaEntidad: FC<WithStyles<typeof styles> & RouteComponentProps<IParams>> = ({ classes, match, location, history }) => {
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);

    const [modelosRecibos, setModelosRecibos] = useState<IModelGateway[]>([]);
    const [confirmado, setConfirmado] = useState(true);
    const [loading, setLoading] = useState(false);

    // Services 
    const ioc = useContext(IoC);
    const recibosGateway: RecibosGateway = ioc.get(RecibosGateway);
    const gateway = ioc.get(paymentGateway.PaymentGateway) as paymentGateway.PaymentGateway;
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
    // Global states
    const [, pageDispatcher] = usePage();
    const [esValidacion, setEsValidacion] = useState(false);
    const [idSolicitudAboNac,setIdSolicitudAboNac] = useState<string | null>(null)


    const redirectDetalle = (id: string) => {
        history.push(`/tramites/abono-nacimiento/detalle/${id}`)
    } 

    useEffect(() => {
        (async () => {
            if (modelosRecibos.length < 1) return;
            let contentAlert: string = "";

            const infoAvisoDeudaPagadaConPrecinto = await contenidoGateway.getContent(
                'info-aviso-deuda-pagada-con-precinto', null
            );

            let bodyInfo = infoAvisoDeudaPagadaConPrecinto.filter((content: any) => content.campo === "body");
            if (bodyInfo.length > 0) contentAlert = bodyInfo[0].contenido
            let ImpTotal = 0
            modelosRecibos.forEach(async (modeloRecibo: IModelGateway) => {
                ImpTotal =ImpTotal+modeloRecibo.modelo.importeAIngresar
                const recibo: IDetalleRecibo | null = await recibosGateway.getOneDetalleRecibo(modeloRecibo.recibo.id);
                if (contentAlert !== "" && recibo && recibo.ConC === 'CP' && recibo.Estado === "10") {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            isHtml: true,
                            variant: 'warning',
                            message: contentAlert,
                        }
                    });
                    return;
                }
            });

            setEsValidacion( ImpTotal === 0 )
        })();
    }, [modelosRecibos]);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: null,
                title: <Term component="PasarelaPago" text="Pasarela pago" />,
            },
            menu: true,
        });
    }, [pageDispatcher, terms]);

    useEffect(() => {
        const fetchModelsData = async () => {
            setLoading(true);
            const idSolcitudAboNacQuery = match.params.idSolcitudAboNac;
            const result = await gateway.confirmarPago(match.params.token, idSolcitudAboNacQuery);
            if (result.ok) {
                setModelosRecibos(result.entidades);
                setConfirmado(!result.entidades.some(mo => !mo.validado));
                
                //Guardamos documento - justificante pago
                if (result.entidades.length === 1 && result.entidades[0].modelo && result.entidades[0].modelo.id) {
                    await modelGateway.asociarDocumentoModeloDelSujeto(result.entidades[0].modelo.id)
                }
                // DWA-15581 - Guardra codigo NRC e importe NRC en XML
                if( result.entidades.length === 1 && (result.entidades[0].modelo.idTipoModelo === '593' || result.entidades[0].modelo.idTipoModelo === '594') && result.entidades[0].modelo.importeAIngresar>0 ){
                    await modelGateway.addInformacionNRC_M593(result.entidades[0].modelo.id)
                }
            } else {
                setModelosRecibos(result.entidades ? result.entidades : []);
                setConfirmado(false);
            }
            setIdSolicitudAboNac(idSolcitudAboNacQuery);
            setLoading(false);
        };

        fetchModelsData();
    }, [match.params.token,match.params.idSolcitudAboNac, gateway]);

    return (
        <div className={[classes.root, classes.margin10].join(' ')}>
            {
                loading ?
                    <div className={classes.centerContent}>
                        <CircularProgress size={75} />
                    </div>
                    :
                    <>
                        {
                            confirmado ?
                                <>
                                   {esValidacion 
                                        ? null
                                        :<Hidden smDown implementation="css">
                                            <h3><Term component="PasarelaPago" text="DEUDA PAGADA" /></h3>
                                        </Hidden>
                                    }
                                    <Card className={classes.cardContador}>
                                        <CardContent className={classes.cardContadorContainer} >
                                            <span className={classes.titleCardContador}>
                                                <Term 
                                                    component="PasarelaPago" 
                                                    text={esValidacion ? "El modelo se ha validado con éxito":"El pago se ha realizado con éxito"} 
                                                />
                                                <Icon path={mdiCheckCircle} title="Seleccionado" size={1} />
                                            </span>
                                        </CardContent>
                                    </Card>
                                </>
                                :
                                <Card className={classes.cardContador}>
                                    <CardContent className={classes.cardContadorContainer} >
                                        <span className={[classes.titleCardContador, classes.red].join(' ')}>
                                            <Term component="PasarelaPago" text="El pago no ha podido ser confirmado" />
                                        </span>
                                    </CardContent>
                                </Card>
                        }
                        <div className={classes.root}>
                            {modelosRecibos.map((modeloRecibo: IModelGateway, index: number) => (
                                <Card
                                    key={index}
                                    className={[classes.mainButton, classes.recibo, classes.cardSeparate].join(' ')}
                                >
                                    <CardContent>
                                        <ModeloReciboGrid modeloRecibo={modeloRecibo} 
                                            showPrint={idSolicitudAboNac ? false :true} 
                                            withCustomAction={true}
                                            renderCustomAction={
                                                idSolicitudAboNac ?
                                                () => (
                                                    <Button 
                                                        variant="contained"
                                                        color="primary"
                                                        size="medium"
                                                        className={classes.btnAddPay}
                                                        onClick={() => redirectDetalle(idSolicitudAboNac) }
                                                    >
                                                        <Term component="PasarelaPago" text="GoAboNacDetalle" />
                                                    </Button>
                                                )
                                                : undefined
                                            }
                                        />
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </>
            }
        </div>
    )
}

export default withStyles(styles)(VueltaEntidad);