import React, { useContext, useEffect, useMemo, useState } from "react";

// Define el tipo de las props que tu componente original espera
import SujetoDeclarante, {Props as SujetoDeclaranteProps} from '../sujeto-declarante'
import { Grid } from "@material-ui/core";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import ModelGateway from "gateways/model.new.gateway";
import { GeoGateway } from "gateways/geo.gateway";
import IoC from "contexts/ioc.context";
import { Municipio } from "gateways/geo.interface";
import { IMunicipioIVTM } from "gateways/model.new.interface";

type T071SujetoPasivo =Record<
	'acodmunige'|'apeNom'|'cp'|'ejercicio'|'escalera'|'letra'|'locaMuni'|'localizado'|'nif'|'nomVia'|'numero'|'piso'|'provincia' |'puerta' |'siglas',
	{ value: any; casilla: number; formula: string; tipo: string, valid?: boolean }
>; 
// Función del HOC que acepta un componente y devuelve un nuevo componente
export const withSujetoPasivo071 = (Component: React.ComponentType<SujetoDeclaranteProps>) => {
  return function ComponentWithCustomSave(props: SujetoDeclaranteProps) {

    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);

	//Gateways
	const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    //const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
    
	const IB_Prov= "07"
	const[IBmunicipios, setIBmunicipios]= useState<IMunicipioIVTM[]>([])
    
    // Función de validación
    const customValidate = async (value: T071SujetoPasivo) => {
		try{
			const acodMuni = IBmunicipios.find(m => m.nombre === value.locaMuni.value)
			if(value.nif.value && value.apeNom.value && acodMuni){
				// validar sujeto IVTM
				const sujetoIVTM = await modelGateway.validaSujetoIVTM(
					{ nif: value.nif.value, locaMuni: acodMuni.acodmunigest ?? '' ,apeNom: value.apeNom.value}, 
					translate('Tributos', 'errValidaSujetoIvtm', terms))
				console.log('respuesta sujetoIVTM ', sujetoIVTM)
				if(!sujetoIVTM || sujetoIVTM && (!sujetoIVTM.nif  || sujetoIVTM.nif === '')){
					throw new Error(translate('Tributos', 'errNoSujetoIvtm', terms))
				}
				
				
				return {valid: true, sujetoIVTM}
			}
			return {valid: false, sujetoIVTM: null}
			
		} catch(error){
			const result = (error as Error).message
			alertsDispatch({
				type: "show-alert",
				payload: {
				  isHtml: true,
				  message: result,
				  variant: "error",
				},
			});

			return {valid: false, sujetoIVTM: null}
		}
      
    }

    const handleSave = async (value: T071SujetoPasivo) => {
		const result = await customValidate(value)
		if(result.valid && result.sujetoIVTM){
			// Completar datos +save apartado
			if(
				result.sujetoIVTM.direccion.cp && result.sujetoIVTM.direccion.cp !== ''
				&& result.sujetoIVTM.direccion.acodmunigest && result.sujetoIVTM.direccion.acodmunigest !== ''
				&& value.nif.value && value.apeNom.value && value.provincia.value
			){
				const finalValue: T071SujetoPasivo = {
					...value,
					cp: {...value['cp'] , value: result.sujetoIVTM.direccion.cp},
					nomVia: {...value['nomVia'], value: result.sujetoIVTM.direccion.calle },
					numero:{...value['numero'], value: result.sujetoIVTM.direccion.numero},
					piso: {...value['piso'], value: result.sujetoIVTM.direccion.planta },
					puerta: {...value['puerta'], value: result.sujetoIVTM.direccion.puerta},
					escalera: {...value['escalera'], value: result.sujetoIVTM.direccion.escalera},
					letra: {...value['letra'], value: result.sujetoIVTM.direccion.letra},
					siglas:{...value['siglas'], value: result.sujetoIVTM.direccion.siglas},
					acodmunige: {...value['acodmunige'], value: result.sujetoIVTM.direccion.acodmunigest},
					ejercicio: {...value['ejercicio'], value: new Date().getFullYear().toString() }
				}

				// ir a save apartado
				console.log('finalValue ', finalValue)
				const response = await props.handleSave(finalValue);
				return response;
			} else{
				alertsDispatch({
					type: "show-alert",
					payload: {
					  message: translate('Tributos','notfount_cp_acodminigest',terms),
					  variant: "error",
					},
				});

				return false
			}

      }  

      return false;
      
    }

	useEffect(() => {
		(async() => {
			// Recuperar municipios
			const optionsMuni = await modelGateway.getMunicipiosIVTM()
			setIBmunicipios(optionsMuni)
		})()
		
	},[])
    // Propaga todas las props al componente original, pero sobrescribe `handleSave`
    return (
      <Grid container direction="column">
        <Component {...props} handleSave={handleSave} />
      </Grid>

    )
  }
}


// Exporta el componente envuelto por el HOC
// Componente
const SujetoPasivo071 = withSujetoPasivo071(SujetoDeclarante)
export default  SujetoPasivo071


