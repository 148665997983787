import React, { FC } from "react";
import { Select, Input, WithStyles, withStyles, Chip, MenuItem, ListItemText, Checkbox, Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 450,
        },
    },
};

const styles = (theme: Theme): StyleRules => ({
    root: {
        flexGrow: 1,
    }
});

interface IProps extends WithStyles<typeof styles> {
    className?: string;
    options: Array<{ value: any, label: string }>;
    value: any[];
    onChange: (value: any[]) => void;
}

const SelectMultiple: FC<IProps> = ({ className, value, onChange, classes, options }) => {

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (typeof onChange === 'function') {
            const values = event.target.value as any[];
            onChange(values);
        }
    };

    const renderValue = (selected: any) => {
        const values = selected ? selected as any[] : [];
        const items = options.filter(x => values.indexOf(x.value) >= 0);
        return (
            <div className={classes.chips}>
                {
                    items.map((item) => (
                        <Chip key={item.value} label={item.label} className={classes.chip} />
                    ))
                }
            </div>
        );
    }

    const isChecked = (option: any) => {
        return value.indexOf(option.value) >= 0;
    }

    return (
        <Select
            className={className}
            multiple
            value={value}
            autoWidth={true}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={renderValue}
            MenuProps={MenuProps}
        >
            {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    <Checkbox color="default" checked={isChecked(option)} />
                    <ListItemText primary={option.label} />
                </MenuItem>
            ))}
        </Select>
    );
}

export default withStyles(styles)(SelectMultiple);