export function download(blob: Blob, alertsDispatch: any, mensaje: string, aviso: string, fileName: string = "justificante.pdf"): boolean {
    try {
        if (blob.size > 0) {
            return downloadBrowser(blob, fileName, aviso, alertsDispatch);
        } else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    isHtml: true,
                    message: mensaje,
                    variant: 'error',
                }
            });
            return false;
        }
    } catch (e) {
        alertsDispatch({
            type: 'show-alert',
            payload: {
                isHtml: true,
                message: mensaje,
                variant: 'error',
            }
        });
        return false;
    }
}

export function downloadWithoutAlert(blob: Blob, fileName: string) {
    try {
        if (blob.size > 0) {
            downloadBrowser(blob, fileName, "", "");
            return true;
        }
    }
    catch (e) {
        return null;
    }
}

function downloadBrowser(blob: Blob, fileName: string, aviso: string, alertsDispatch: any): boolean {
    const ua = window.navigator.userAgent;
    const isIexplorer = (ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0 || ua.indexOf('Edge/') > 0);

    if (isIexplorer) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
        return true;
    } else {
        const anchor = document.createElement('a');
        const fileURL = URL.createObjectURL(blob);

        anchor.href = fileURL;
        anchor.download = fileName;
        anchor.target = '_blank';

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        return true;
        // const pantalla = window.open(fileURL, '_blank');

        // if (!pantalla || pantalla.closed || typeof pantalla.closed === 'undefined') {
        //     alertsDispatch({
        //         type: 'show-alert',
        //         isHtml: true,
        //         payload: {
        //             isHtml: true,
        //             message: aviso,
        //             variant: 'warning',
        //         }
        //     });
        // }
    }
}