import { FC,  useContext,  useEffect, useMemo, useRef, useState } from "react";
import { Grid, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { StepComponentProps } from "components/generic-stepper/generic-step-component";
import { LiteralsContext, withLiterals } from "containers/shared/literals";

import { translate } from "utils/i18n";

import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import { AbonoNacimientoGateway } from "gateways/tramites/abonoNacimiento.gateway";
import GenericTypedInputNumber from "components/generic-input-number/typed-input-number";

import { camposToObject } from "../../utils";
import useCommonStyles from "../styles";
import { BIOPT, TDatosIdentificativos, TDeduccion,TFinReint, TFinSol, TIDentifica, TIdentificacionAbono, TModalidadSolicitud, TReintegroAbonoNacimiento, TSolicitudAbonoNacimiento, TTipo, TTipoSolicitud } from "../types";
import FullLoading from "components/full-loading";
import { ContenidosGateway } from "gateways/contenido.gateway";

import GenericStepSchema from "components/generic-stepper/generic-step-schema";
import ModalConformidad from "components/modal-conformidad";
import GenericInputDate from "components/generic-input-date";
import { ORDERED_APARTADOS, extraerAutorizacion, extraerDatosIdentificativos, extraerDeclaracionResponsable, extraerDirecRepre, extraerIdentificaAbono, extraerJustificanteFin, renderIconResumen, renderTitleResumen, resumenAbono, resumenContribuyente, resumenDeduccion } from "./extraer";
import GenericCard from "components/generic-card";



type TInfoResumen = {
    contribuyente: TIDentifica
    derechoDeduccion: TDeduccion
    tipoSolicitud: TTipoSolicitud,
    modalidadSolicitud: TModalidadSolicitud | undefined
    identificacion_abono: TIdentificacionAbono | null
} 
export type FinMap = {
    [K in TTipo]: K extends "solicitud" ? TFinSol : TFinReint
};
export type TInfoDoc = {
    [K in TTipo]: K extends "solicitud" ? TSolicitudAbonoNacimiento : TReintegroAbonoNacimiento
};

type FinalizacionJustificanteProps = StepComponentProps & WithStyles<typeof styles> 

const TypedFinalizacionJustificante: FC<FinalizacionJustificanteProps & {tipo: TTipo}> = ({classes,tipo, idRef,apartado, onBack,onNext,onCancel, isFirst, isLast}) => { 
    const CommonClasses= useCommonStyles();
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext);
    const componentMounted = useRef(true);
    // Gateways
    const ioc = useContext(IoC);
    const abonoNacimientoGateway: AbonoNacimientoGateway = useMemo(() => ioc.get(AbonoNacimientoGateway), [ioc]);
  
    //const [docGenera, setDocGenera] = useState<TTipoDocGenera | null>(null)
    const [loading, setLoading]= useState(false);

    const [openModalConformidad, setOpenModalConformidad]= useState(false);
    const [infoDoc, setInfoDoc]= useState<TSolicitudAbonoNacimiento | TReintegroAbonoNacimiento>({} as TInfoDoc[ typeof tipo] );
    const [datos_fin,setDatosFin] = useState<FinMap[ typeof tipo] | null>(null);
    const [BI,setBI]= useState<BIOPT[]>([])
    
    const [importeErr, setImporteErr]= useState(false);

    const [infoResumen, setInfoResumen]= useState<TInfoResumen|undefined>(undefined)

    // -- Memos    ---------------------------------------------------------------------------------------------------------------------------

    const withImporte = useMemo(() => {
       return tipo === 'solicitud' && infoDoc.declaracion_responsable?.tipoDocGenera === 50 ? true  : false
    },[tipo,infoDoc]);

    const disableNEXT = useMemo(() => {
        // Validacion Fin
        if(tipo === 'reintegro'){
            const datos= datos_fin as FinMap[ typeof tipo]
            return datos && datos.importeAIngresar !== undefined && datos.importeAIngresar>0 && !importeErr ? false : true;
        } else {
            const datos= datos_fin as FinMap[ typeof tipo]
            //console.log(tipo, datos, withImporte)
            return withImporte===true 
                ? datos && datos.importeSolicitud !== undefined && datos.importeSolicitud>0  ? false : true
                : false;
        }
    },[tipo, datos_fin, withImporte]);

    // -- Functions --------------------------------------------------------------------------------------------------------------------------
    const handleNext = async () => {
        try {
            setLoading(true)
            const response = await abonoNacimientoGateway.saveStep(idRef,'finalizacion_justificante',datos_fin)
            if(response.saved){
                onNext(response.referencia)
            }else{
                if(response.error){
                    alertsDispatch({
                        type:'show-alert',
                        payload: {
                            variant:'error',
                            message: translate('AbonoNacimiento',response.error,terms) + translate('AbonoNacimiento','finalizacion_justificante',terms)
                        }
                    })
                } else {throw new Error ('ErrorSaveStep')}
            }
        } catch (error) {
            alertsDispatch({
                type:'show-alert',
                payload: {
                    variant:'error',
                    message: translate('AbonoNacimiento', (error as Error).message ?? 'ErrorSaveStep',terms) + translate('AbonoNacimiento','finalizacion_justificante',terms)
                }
            })
            
        } finally {
            setLoading(false)
        }
    }

    const renderContentInfo = (data: TSolicitudAbonoNacimiento | TReintegroAbonoNacimiento) => {
        let out: JSX.Element[] = []
        const monoparental = data.datos_identificativos?.familiaMonoparental === true
        ORDERED_APARTADOS.forEach(apart => {
            if(data.hasOwnProperty(apart)){
                switch (apart) {
                    case 'datos_identificativos':
                        out.push(extraerDatosIdentificativos(data[apart], tipo, terms))
                        break;
                    case 'direccion_representante':
                        out.push(extraerDirecRepre(data[apart], terms))
                        break;
                    case 'declaracion_responsable':
                        out.push(extraerDeclaracionResponsable(data[apart],monoparental, terms, BI))
                        break;
                    case 'direccion_representante':
                        out.push(extraerDirecRepre(data[apart], terms))
                        break;
                    case 'autorizacion':
                        out.push(extraerAutorizacion(data[apart], tipo, terms))
                        break;
                    case 'finalizacion_justificante':
                        out.push(extraerJustificanteFin(data[apart], tipo, terms))
                        break;  
                    case 'identificacion_abono' :
                        out.push(extraerIdentificaAbono((data as TReintegroAbonoNacimiento)[apart], terms))
                        break;
                    default:
                        break;
                }
            }
        });

        return out
    }
    // -- Effects  ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                //  INICIALIZACIÓN
                if(!idRef ){ throw new Error('not-idRef')}
                const campos =  await abonoNacimientoGateway.getCamposStep('finalizacion_justificante')
                let emptyObject= camposToObject(campos)
                
                const response = tipo === 'solicitud' 
                    ? await abonoNacimientoGateway.getApartadosByRef<'solicitud'>(idRef)
                    : await abonoNacimientoGateway.getApartadosByRef<'reintegro'>(idRef);

                const BI: BIOPT[] = response.declaracion_responsable?.tipoDocGenera ? await abonoNacimientoGateway.getBiOptionsByDoc(response.declaracion_responsable?.tipoDocGenera) : []
                let info: TInfoResumen= {} as TInfoResumen
                const importeSol = response.declaracion_responsable?.tipoDocGenera === 50
                    ?   await abonoNacimientoGateway.calcularImporteSolicitud(idRef)
                    : undefined
                if(response.datos_identificativos?.contribuyente && response.datos_identificativos?.derechoDeduccion && response.datos_identificativos?.tipoSolicitud){
                    info = {...info,
                        contribuyente: response.datos_identificativos.contribuyente,
                        derechoDeduccion: response.datos_identificativos.derechoDeduccion,
                        modalidadSolicitud:response.datos_identificativos.modalidadSolicitud,
                        tipoSolicitud:response.datos_identificativos.tipoSolicitud,
                    }

                }
                if(tipo === 'reintegro' &&  (response as TReintegroAbonoNacimiento).identificacion_abono){
                    info = {...info,
                        identificacion_abono: (response as TReintegroAbonoNacimiento).identificacion_abono ?? null,
                    }
                } else {
                    info = {...info,
                        identificacion_abono: null
                    }
                }
            

                if(tipo === 'reintegro'){
                    emptyObject = { ...emptyObject,fechaFin: new Date(),importeAIngresar: undefined} as TFinReint//Map[typeof doc]
                } else {
                    
                    emptyObject= {...emptyObject,fechaFin: new Date(), importeSolicitud: importeSol} as TFinSol
                }
                
               
                //console.log(doc, {...emptyObject})// as TFinMap[typeof doc])
                if(componentMounted.current){
                    setInfoDoc(response)
                    setDatosFin({...emptyObject} as FinMap[typeof tipo])
                    setInfoResumen(info)
                    setBI(BI)
                }

            } catch(err){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        variant:'error',
                        message: translate('AbonoNacimiento','ErrorLoadingStep',terms) 
                    }
                })
            } finally {
                setLoading(false)
            }
            
        })();

        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
        
    },[idRef, apartado, tipo]);

    return (

        <GenericStepSchema
            isFirst={isFirst}
            isLast={isLast}
            onNext={() => {

                setInfoDoc(curr => tipo === 'solicitud'
                    ? ({ ...curr as TSolicitudAbonoNacimiento, finalizacion_justificante: datos_fin !== null ? {...datos_fin} as FinMap[typeof tipo]: undefined})
                    : ({ ...curr as TReintegroAbonoNacimiento , finalizacion_justificante: datos_fin !== null ? {...datos_fin} as FinMap[typeof tipo]: undefined})
                 )
                setOpenModalConformidad(true)
            }}
            disabledNext={disableNEXT}
            onBack={onBack}
            disabledBack={false}
            onExit={onCancel}
            forceExit={false} 
            idRef={idRef} 
            allowContinuarMasTarde={false}
            withRecoverRefContinuarMasTardeModal={false}
            withContinuarMasTardeModal={true}
        >
            {/**Componente */}
            <>
                <FullLoading loading={loading}/>
                {datos_fin && 
                    <div className={classes.row}>
                        {tipo === 'reintegro' && 
                            <div className={classes.row}>

                                <GenericTypedInputNumber 
                                    type="money"
                                    //disabled
                                    required//={false}
                                    allowZero={true}
                                    labelTerm="importeAIngresar"
                                    componentIG="AbonoNacimiento"
                                    value={(datos_fin as FinMap[typeof tipo]).importeAIngresar}
                                    onChangeValue={(v, err) => {
                                        setDatosFin(curr=> curr && ({ ...curr, importeAIngresar: v }))
                                        setImporteErr(err)
                                    }}
                                    max={infoResumen?.identificacion_abono?.importePercibido ?? undefined}
                                />
                            </div>
                        }
                        {withImporte && tipo === 'solicitud'&&
                            <div className={classes.row}>
                                <GenericTypedInputNumber 
                                    type="money"
                                    disabled
                                    required//={false}
                                    allowZero={true}
                                    labelTerm="importeSolicitud"
                                    componentIG="AbonoNacimiento"
                                    value={(datos_fin as FinMap[typeof tipo]).importeSolicitud}
                                    onChangeValue={(v, err) => {
                                        setDatosFin(curr=> curr && ({ ...curr, importeSolicitud: v }))
                                    }}
                                />
                            </div>
                        }
                        <GenericInputDate
                            componentIG="AbonoNacimiento"
                            labelTerm='fechaFin' 
                            value={datos_fin.fechaFin}
                            disabled
                            onChangeValue={ () => {}}
                            required
                        />
                                           
                    </div>
                }
                {infoResumen && 
                <Grid container spacing={1}>  
                    <Grid item xs={12} md={5} lg={6}>
                        <GenericCard 
                            styleCard={{backgroundColor: '#e0eaf4',  height: 200, alignItems:'flex-start'}}
                            styleCardContent={{padding: 0, marginTop:3}}
                            withActions={false}
                            withTitle={true} 
                            withCardHeaderBotomRow={true}
                            renderTitle={() => renderTitleResumen('AbonoNacimiento',infoResumen.tipoSolicitud === 'anticipado' ? 'panel_contribuyente' :'panel_principal',terms)}
                            withLeftIcon={true}
                            renderLeftIcon={() => renderIconResumen('contribuyente')}
                        >
                            {resumenContribuyente(infoResumen.contribuyente,/*infoResumen.tipoSolicitud,*/ terms)}
                        </GenericCard>
                    </Grid>
                    <Grid item xs={12} md={7} lg={6}>
                        <GenericCard 
                            styleCard={{backgroundColor: '#e0eaf4', height: 200, alignItems:'flex-start'}}
                            styleCardContent={{padding: 0, marginTop:3}}
                            withActions={false}
                            withTitle={true} 
                            withCardHeaderBotomRow={true}
                            renderTitle={() => renderTitleResumen('AbonoNacimiento','panel_derecho_deduccion',terms)}
                            withLeftIcon={true}
                            renderLeftIcon={() => renderIconResumen('deduccion')}
                        >
                            {resumenDeduccion(infoResumen.derechoDeduccion,terms)}
                        </GenericCard>
                    </Grid>
                   {infoResumen.identificacion_abono && 
                        <Grid item xs={12} >
                            <GenericCard 
                                styleCard={{backgroundColor: '#e0eaf4', /*height: 150,*/ alignItems:'flex-start'}}
                                styleCardContent={{padding: 0, marginTop:3}}
                                withActions={false}
                                withTitle={true} 
                                withCardHeaderBotomRow={true}
                                renderTitle={() => renderTitleResumen('AbonoNacimiento','identificacion_abono',terms)}
                                withLeftIcon={true}
                                renderLeftIcon={() => renderIconResumen('abono')}
                            >
                                {resumenAbono(infoResumen.identificacion_abono,terms)}
                            </GenericCard>
                        </Grid>
                    }

                </Grid>
                }
                <ModalConformidad
                    open={openModalConformidad}
                    renderContent={() => {
                        const elementos= renderContentInfo(infoDoc)
                        return(
                            <Grid container direction='column'>
                                {elementos.map( el =>  el )}
                            </Grid>
                        )
                    }}
                    onClose={() => {setOpenModalConformidad(false)}}
                    onSave={handleNext}
                />
            </>
            </GenericStepSchema>
   
        

    )
}


const styles = (theme: Theme): StyleRules => ({
    textContainer:{
        padding: '0px 10px 0px 10px'
    },
    infoContentContainer:{

    },
    row:{
        display:'flex',
        flexDirection: 'row'
    },
    column:{
        display:'flex',
        flexDirection: 'column'
    },

    bold: {
        fontWeight: 'bolder'
    }
});

export default withLiterals(['Global', 'Tramites','AbonoNacimiento' ])(withStyles(styles)(TypedFinalizacionJustificante))
