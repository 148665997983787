import {FC, useContext, useEffect, useMemo, useState} from "react";
import { Dialog, DialogTitle, Typography, DialogContent, Grid, DialogActions, withStyles, WithStyles, Button } from "@material-ui/core";
import { translate } from "utils/i18n";
import duti_styles from "containers/DUTI/styles";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import { IDocumentoAtib } from "gateways/duti.interface";
import InputText from "./input-text";
import FullLoading from "../full-loading";
import InputSearch from "./input-search";
import { TDTInfoTransmision } from "containers/DUTI/steps/types";
import TypedInputNumber from "./typed-input-number";
import InputDate from "./input-date";
import { CustomError } from "utils/custom.error";
import usePage from "hooks/page.hook";


interface IProps extends WithStyles {
    show: boolean,
    currentInfoTransmision: TDTInfoTransmision,
    //currentNRD: string|undefined
    onConfirm: (doc: IDocumentoAtib|undefined, newInfo: TDTInfoTransmision, nrs:string|undefined) => void,
    onClose: () => void,
    setShouldBlock: (v: boolean) => void,
}

const ModalNRD: FC<IProps> = ({classes, show, /*currentNRD,*/ currentInfoTransmision, onConfirm, onClose, setShouldBlock}) => {
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const [loading, setLoading] = useState(false)
    const [newInfo, setNewInfo]=useState<TDTInfoTransmision>({} as TDTInfoTransmision)
    const [localNRD, setLocalNRD]= useState<string|undefined>(undefined)
    const [doc, setDoc]= useState<undefined|IDocumentoAtib>(undefined)


    const searchByNRD = async () => {
        try {
            if (localNRD && localNRD !== "") {
                setLoading(true);
                try {
                    const result = await dutiGateway.getEscrituraPublicaByNRD(localNRD);
                    if (result.info && result.escritura) {
                        setNewInfo({
                            ...currentInfoTransmision,
                            nifNotario: result.info.nifNotario.toUpperCase(),
                            notario: result.info.notario.toUpperCase(),
                            fecha: result.info.fecha,
                            anyo: result.info.anyo,
                            numeroProtocolo: result.info.protocolo,
                            nrd: localNRD,
                            idDocumentoEscritura:result.escritura.idDocumento
                        });
                        setDoc(result.escritura);
                    } else {
                        setNewInfo({} as TDTInfoTransmision);
                        setDoc(undefined);
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('DUTI', 'search_by_nrd_not_found', terms),
                                variant: 'error',
                            }
                        });
                    }
                } catch (error) {
                    if (error instanceof CustomError && error.code === 403) {
                        setShouldBlock(false);
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('Global', 'sesion_caducada', terms),
                                variant: 'warning',
                                hasCustomAction: true,
                                handleCustomAction: () => { 
                                    pageDispatcher({ type: "logout" });
                                }
                            }
                        });
                    } else {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('DUTI','error_dusti', terms),
                                variant: "error"
                            }
                        });
                    }
                } finally {
                    setLoading(false);
                }
            } else {
                setNewInfo({} as TDTInfoTransmision)
                setDoc(undefined)
            }
        } catch (error) {
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI', 'search_by_nrd_err', terms),
                    variant: 'error',
                }
            });
        }
        
    }

    useEffect(() => {
        if(show){
            setLocalNRD(undefined)
            setNewInfo({} as TDTInfoTransmision)
        }
    }, [show])

    return (   
        <Dialog open={show} fullWidth maxWidth='md'>
            <FullLoading loading={loading} animation={{type:'intricom',name:'DUSTI'}}/>
            <DialogTitle>
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    <Typography style={{fontSize: 32, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>{translate('DUTI', 'title_modal_nrd', terms)}</Typography>
                </div>
            </DialogTitle> 

            <DialogContent>
                <Grid container direction='column' spacing={1} style={{paddingRight: 20, paddingLeft: 20}} >
                    <Grid item>
                    <Typography >{translate('DUTI', 'text_modal_nrd', terms)}</Typography>
                        <div style={{marginTop: 10}}>
                            
                            <InputSearch
                                fieldTerm='search_nrd'
                                value={localNRD}
                                //required
                                forceUpperCase={true}
                                className={classes.input360}
                                onChangeValue={(v:string|undefined, err: boolean) => {
                                    setLocalNRD(v)
                                }}                        
                                onSearch={searchByNRD } //() => searchByNRD(localNRD)}
                            />

                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.column}>
                            <div className={classes.row}>
                                <InputDate 
                                    fieldTerm="fecha"
                                    value={newInfo.fecha}
                                    onChangeValue={(v: Date| undefined, err:boolean) =>{}}
                                    disabled={true}
                                />
                                <TypedInputNumber
                                    type="year"
                                    fieldTerm={'anyo'}
                                    value={newInfo.anyo}
                                    onChangeValue={(v:number|undefined, err:boolean) =>{}}
                                    disabled={true}
                                    required={false}
                                    //className={classes.input200}
                                />
                                <InputText
                                    fieldTerm={'numeroProtocolo'}
                                    value={newInfo.numeroProtocolo}
                                    onChangeValue={(v:string|undefined, err: boolean) =>{ }}
                                    disabled={true}
                                    //className={classes.input200}

                                />
                            </div>
                            <div style={{width: (260+10)*3}}>
                                <InputText fullWidth
                                    fieldTerm={'notario'}
                                    value={newInfo.notario}
                                    onChangeValue={(v:string|undefined, err: boolean) =>{ }}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        
                    </Grid>
            </Grid>
               
            </DialogContent>
           
            <DialogActions>
                <div style={{display: 'flex',justifyContent:'flex-end', paddingRight: 10, paddingBottom: 10}}>
                    <Button style={{marginRight: 15}}
                            variant="contained" color="inherit"
                            onClick={onClose} 
                            disabled={loading}
                        >
                        {translate('DUTI', 'btn_close',terms)}
                    </Button>
                    <Button
                        variant="contained" color="primary"
                        onClick={() =>  onConfirm(doc, newInfo, localNRD ) }
                    >
                        {translate('DUTI', 'btn_continuar',terms)}
                    </Button>
                </div>
            </DialogActions>
        
        </Dialog> 
    )   

}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(ModalNRD));