import React, { FC, useCallback, useContext, useEffect } from "react";

import duti_styles, { duti_colors } from "containers/DUTI/styles";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { IMoreInfo, TInfoTemplateSize } from "contexts/more_info.reducer";
import { IconButton, Typography, WithStyles, withStyles } from "@material-ui/core";
import { Icon } from 'atomic/atoms/icon/icon';
import { MoreInfoContext } from "contexts/more_info.context";
import { translate } from "utils/i18n";

export type TLabelType = 'field' | 'title' | 'text'
interface ILabelWithInfo extends WithStyles {
    fieldTerm: string,
    type: TLabelType,
    moreInfo?: {
        template?: string, size?: TInfoTemplateSize, asMessage?: boolean
    },
    componentIG?:string
    style?:any
};


const LabelWithInfo : FC<ILabelWithInfo>= ({classes, fieldTerm, moreInfo, type="field",componentIG, style }) => {
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);

   
    const handleShowMoreInfo = useCallback(() => {
        if (moreInfo) {

            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: moreInfo.asMessage ? undefined : moreInfo.template,
                    message: moreInfo.asMessage ?  moreInfo.template: undefined,
                    size:moreInfo?.size ?? undefined,
                }
            });
        }
    }, [infoDispatch])


    return(
        <div style={{display:'inline-flex', alignItems:'center', marginBottom: type === 'title' ? 8 : 0, minHeight: type !== 'text' ? 48: undefined}}>
            <Typography className={type !== 'text' ? classes.radioBtn_Field : undefined} variant={ type === 'title' ? "h6": "body1"} style={style}>
                {translate(componentIG ?? 'DUTI',fieldTerm, terms)}
            </Typography>
            {moreInfo && 
                <IconButton onClick={handleShowMoreInfo}>
                    <Icon name={'info-outline'} size={1} color={duti_colors.blueIconInfo}/>
                </IconButton>
            }
        </div>
    )
}

export default withLiterals([
    "Global", "Acceso", "Perfil", "CarteroVirtual", "Tramites", "Notificaciones", 
    "Domiciliaciones", "Tributos", "BienesSujeto", "DUTI","Plusvalias"
])(withStyles(duti_styles)(LabelWithInfo))