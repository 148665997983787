import { Card, CardContent, CardMedia, CircularProgress, Grid, Hidden, ListItem, makeStyles, MenuItem } from '@material-ui/core';
import { mdiMapMarker, mdiOfficeBuilding } from '@mdi/js';
import { Icon } from '@mdi/react';
import Term from 'components/term';
import IoC from 'contexts/ioc.context';
import OficinasGateway from 'gateways/oficinas.gateway';
import IOficinaDto, { IFilterData, IOficinaFotos } from 'gateways/oficinas.interfaces';
import usePage from 'hooks/page.hook';
import React, { FC, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import FiltersActive from './filters.active';
import { FiltersContext, filtersContextInitialState } from './filters.context';
import { FilterDataContext } from './filters.data.context';
import FiltersDialog from './filters.dialog';
import FiltersReducer, { IFiltersState } from './filters.reducer';
import PanelDrawer from './panel.drawer';
import GoogleMapReact from 'google-map-react';
import env from '../../env';
import imagelogoBola from '../../resources/logoBola.png';
import imageNOFOTO from '../../resources/NOFOTO.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'calc(100% - 300px)',

    },
    item: {
        height: 400

    },
    contenido: {
        width: '80%',
        maxHeight: 100,
    },
    oficinas: {
        height: 250,
        marginTop: 15,
    },
    map: {
        height: '75vh',
        marginRight: '20px',
        marginLeft: '20px',
    },
    logoBola: {
        width: 30,
        marginLeft: '-15px',
        marginTop: '-15px',
    },
    main: {
        marginLeft: 50, 
        marginRight: 50
    },
    iconClass: {
        width: 60, marginTop: 17
    },
    [theme.breakpoints.down('sm')]: {
        root: {
            width: '100%'
        },
        main: {
            marginLeft: 20, marginRight: 20
        },
        contenido: {
            width: 200,
        },
        map: {
            margin: 0
        }
    },
    [theme.breakpoints.down('lg')]: {
        contenido: {
            maxHeight: 100,
        }
    }
}));

const OficinaList: FC = () => {

    const ioc = useContext(IoC);
    const classes = useStyles();
    const oficinasGateway: OficinasGateway = ioc.get(OficinasGateway);
    const [oficinasFiltered, setOficinasFiltered] = useState<IOficinaDto[]>([]);
    const [oficinas, setOficinas] = useState<IOficinaDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const filtersReducer = useReducer(FiltersReducer, filtersContextInitialState());
    const [filters, filtersDispatch] = filtersReducer;
    const [filterData, setFilterData] = useState<IFilterData>({});
    const [, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);

    const Marker = ({ idOfi }: any) =>
        <Link to={"/oficinas/" + idOfi}>
            <img alt="Oficina" className={classes.logoBola} src={imagelogoBola}></img>
        </Link>;
    const handleHideFilters = useCallback(() => setShowFilters(false), []);
    const handleCloseSubMenu = useCallback(() => setOpenSubMenuAnchorEl(null), []);
    const handleToggleSubMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpenSubMenuAnchorEl(event.currentTarget);
    }, []);
    const handleShowFilters = useCallback(() => {
        setShowFilters(true);
        handleCloseSubMenu();
    }, [handleCloseSubMenu]);

    const handleCambioVista = useCallback(() => {
        filtersDispatch({ type: 'update', filter: 'cambioLista', value: !filters.cambioLista });
    }, [filters.cambioLista, filtersDispatch])

    const handleFiltersChanged = useCallback((newFilters: IFiltersState) => {
        //console.log(newFilters);
    }, []);

    const [, pageDispatcher] = usePage();

    function ordenar(ascending: boolean) {
        return function (a: IOficinaFotos, b: IOficinaFotos) {
          if (a.orden === b.orden) {
              return 0;
          }
          if (a.orden === null) {
              return 1;
          }
          if (b.orden === null) {
              return -1;
          }
          if (ascending) {
              return a.orden < b.orden ? -1 : 1;
          }
          return a.orden< b.orden ? 1 : -1;
        };
      }

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiOfficeBuilding,
                title: <Term component="Global" text="Oficinas y servicios" />,
                text: <Term component="Global" text="Oficinas y servicios Descripcion" />,

                right: (
                    <>
                        {filters.cambioLista ?
                            <>
                                <MenuItem button onClick={handleShowFilters}><Term component="Global" text="Filtrar resultados" /></MenuItem>
                                <MenuItem button onClick={handleCambioVista}><Term component="Oficinas" text="Mapa" /></MenuItem>
                            </>
                            :
                            <MenuItem button onClick={handleCambioVista}><Term component="Oficinas" text="Lista" /></MenuItem>
                        }
                    </>)
            },
            menu: true,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.cambioLista, pageDispatcher, handleToggleSubMenu]);

    useEffect(() => {
        const handleLoadOficinas = async () => {
            setLoading(true);
            try {
                const oficinasTemp = await oficinasGateway.findAll();
                const filterDataTemp = await oficinasGateway.getFilters();
                setFilterData({ municipio: filterDataTemp })
                setOficinas(oficinasTemp);
            } finally {
                setLoading(false);
            }
        }
        handleLoadOficinas();
    }, [oficinasGateway]);

    useEffect(() => {
        if (filters && filters.municipio && filters.municipio.length > 0) {
            const oficinasSelected: IOficinaDto[] = []
            for (const x of filters.municipio) {
                oficinas.filter(y => y.codigoMunicipio === x as unknown as string).map(x => oficinasSelected.push(x))
            }
            setOficinasFiltered(oficinasSelected)
        } else {
            setOficinasFiltered(oficinas);
        }

    }, [oficinas, filters])

    return (
        <div className={classes.root}>
            <FiltersContext.Provider value={filtersReducer}>
                <FilterDataContext.Provider value={filterData}>
                    {
                        loading
                            ? <ListItem style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                            }}>
                                <CircularProgress size={30} />
                            </ListItem>
                            :
                            <>
                                {filters.cambioLista === true ?
                                    <div className={classes.main} style={{ minHeight: '75vh' }}>
                                        <div>
                                            <FiltersActive
                                                onChange={handleFiltersChanged}
                                            />
                                        </div>
                                        <Grid className={classes.gridBase} container spacing={2}>
                                            {oficinasFiltered.map((x: IOficinaDto) => (
                                                <Grid key={x.id + 'oficinas'} item xs={12} md={12} lg={6} xl={4}>
                                                    <Card className={classes.item}>
                                                        <Link to={"/oficinas/" + x.id} style={{textDecoration: 'none'}}>
                                                            <ListItem style={{ padding: '0px 0px 0px 10px' }}>
                                                                <Icon className={classes.iconClass} path={mdiMapMarker}
                                                                    title="logo"
                                                                    color="#00638f"
                                                                    size="60"
                                                                />
                                                                <CardContent className={classes.contenido}>
                                                                    <div style={{ color: '#006993', fontWeight: 'bold', marginTop: 15 }}>{x.nombre}</div>
                                                                    <div style={{ color: '#808080', width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{x.direccion}</div>
                                                                </CardContent>
                                                            </ListItem>
                                                            {
                                                                x && x.fotos !== undefined && x.fotos.length > 0
                                                                    ?
                                                                    <CardMedia className={classes.oficinas}
                                                                        component="img"

                                                                        image={env.API_URL + 'documentos/' + x.fotos.sort(ordenar(true))[0].idDocumento + '/content'}
                                                                        title="Sede"
                                                                    />
                                                                    :
                                                                    <CardMedia className={classes.oficinas}
                                                                        component="img"
                                                                        image={imageNOFOTO}
                                                                        title="Sede"
                                                                    />
                                                            }
                                                        </Link>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                    :
                                    <div className={classes.map}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: env.GOOGLE_API_KEY }}
                                            defaultCenter={{ lat: 39.40782931275160, lng: 2.650792061916491 }}
                                            defaultZoom={9}
                                        >
                                            {oficinas.map((x: IOficinaDto, i: number) =>
                                                <Marker key={`marker_map_${i}_${x.id}`}
                                                    lat={x.latitud}
                                                    lng={x.longitud}
                                                    idOfi={x.id}
                                                />
                                            )
                                            }
                                        </GoogleMapReact>
                                    </div >
                                }
                                <div>
                                    <Hidden mdUp implementation="css">
                                        <FiltersDialog
                                            open={showFilters}
                                            onClose={handleHideFilters}
                                            onChange={handleFiltersChanged}
                                        />
                                    </Hidden>

                                    <Hidden smDown implementation="css">
                                        <PanelDrawer
                                            onFiltersChange={handleFiltersChanged}
                                        />
                                    </Hidden>
                                </div>
                            </>
                    }
                </FilterDataContext.Provider>
            </FiltersContext.Provider>
        </div>
    )
}

export default OficinaList;
