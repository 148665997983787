import { ContactSupportOutlined, RepeatOneSharp } from "@material-ui/icons";
import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { QueryRequestInmueble, QueryRequestVehiculos } from "containers/bienes-sujeto/filters.reducer";
import { IFilterOption } from "utils/interfaces";
import { createValidationError } from "utils/response";
import { IBienesFilter, IInmuebleFilterData, IRespuestaCatastro, IRespuestaVehiculos, } from "./bienesSujeto.interface";
import { TTipoInmueble, TUsoInmueble } from "./catastro.interface";


@inject('axios')
export class SujetosBienesGateway {

    constructor(
        private axios: AxiosInstance
    ) {

    }

    public toOptionFormat<T>(arr: Array<T> | undefined): Array<IFilterOption<T>> | undefined {
        console.log('toOptionFormat', arr)
        if(arr === undefined) return undefined;
        const out = arr.map( item => ({value: item as T, label: item as unknown as string}));
        return out
    }
    public toOptionFormatINM<T>(arr: Array<TTipoInmueble> | undefined): Array<IFilterOption<TTipoInmueble>> | undefined {
        console.log('toOptionFormat', arr)
        if(arr === undefined) return undefined;
        const out = arr.map(  item => {
            console.log('item', item)
            let label = ''; 
            switch (item) {
                case 'UR':
                    label = 'Urbana';
                    break  
                case 'RU':
                    label = 'Rústica';
                    break 
                case 'BI':
                    label='BICES';
                    break;
            }
            return ({value: item as TTipoInmueble, label: label});
        });
        return out
    }

    public getTipos () {
        const out: Array<IFilterOption<TTipoInmueble>> = ['Urbana', 'Rústica', 'BICES'].map( item => ({value: item as TTipoInmueble, label: item}) )
        return out
    }

    public getUsos (){
        const out: Array<IFilterOption<TUsoInmueble>> = [ 
            'Almacen-Estacionamiento','Residencial','Industrial','Oficinas','Comercial',
            'Deportivo', 'Espectáculos','Ocio y Hosteleria','Sanidad y Beneficencia','Cultural',
            'Religioso','Obras de urbanización y jardineria, suelos sin edificar','Edificio Singular',
            'RDL 1/2004 8.2a','RDL 1/2004 8.2b','RDL 1/2004 8.2c','RDL 1/2004 8.2d','Almacen agrario',
            'Industrial agrario','Agrario'].map( item => ({value: item as TUsoInmueble, label: item}) )
        return out
    }

    public async getInmueblesFilter(): Promise<IBienesFilter> {
        const filter: IBienesFilter = {
            tipoInmueble: this.getTipos(),
            usos: this.getUsos(),
            matricula: undefined,
            refCatastral: undefined
        }
        return filter
    }

    public async getInmueblesFilterData(query: QueryRequestInmueble): Promise<IInmuebleFilterData> {
        try {
            //console.log('GATEWAY getInmueblesFilterData : ', 'URL: sujetos/datos-catastro', {params: {...query}});
            const response = await this.axios.get('sujetos/datos-catastro', {params: {...query}});
            // console.log('response Inmuebles: ', response)
            if(response.status === 200) {
                const respData : IRespuestaCatastro = response.data 
                return   {
                    ok: true, //espData.ok,
                    error: respData.error,
                    filtros: { 
                        refCatastral: query.refCatastral ? {value: query.refCatastral, label: query.refCatastral } : undefined, 
                        tipoInmueble: this.toOptionFormatINM(respData.filtros?.tipos as TTipoInmueble[]),
                        usos: this.toOptionFormat(respData.filtros?.usos as TUsoInmueble[]), 
                        matricula: undefined
                    },
                    bienes: respData.bienes,
                    count: respData.count ?? 0,
                } 
            } else{
                return { ok: false, error: 'No "datos-catastro" recived', filtros: {}, bienes: [], count:0 }
            }
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getUrlGoogle(ref: string): Promise<string> {
        try {
            //console.log('GATEWAY getUrlGoogle --> REF. Catastro: ref', ref);
            const response = await this.axios.get('sujetos/url-google', {params: {refCatastral: ref}});
            return response.data 
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getMunicipiosAtib(): Promise<string[]> {
        try {
            //console.log('GATEWAY getUrlGoogle --> REF. Catastro: ref', ref);
            const response = await this.axios.get('sujetos/municipios-atib');
            return response.data 
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getVehiculosFilterData(query: QueryRequestVehiculos): Promise<IRespuestaVehiculos> {
        try {
            //console.log('GATEWAY getVehiculosFilterData : ', 'URL: sujetos/vehiculos', {params: {...query}});
            const response = await this.axios.get('sujetos/vehiculos', {params: {...query}});
            //console.log('response Vehiculos: ', response)
            return response.status=== 200 
            ? { ...response.data, ok: true } as IRespuestaVehiculos 
            : { ok: false, vehiculos: [], count:0 }
        } catch (e) {
            throw createValidationError(e as any);
        }
    }


    public async exportarInmuebles (query: QueryRequestInmueble) {
        const response = await this.axios.get('sujetos/exportarInmuebles', {
            params: {
                ...query,
                expires: Date.now(),
            },
            responseType: 'blob',
        });

        const disposition = response.headers['content-disposition'];
        const match = /^filename="?(.+?)"?$/gmi.exec(disposition);
        const fileName = match ? match[1] : 'exportacion.xlsx';
        return {
            fileName,
            blob: response.data
        };

    }

    
    public async exportarVehiculos (query: QueryRequestVehiculos) {
        const response = await this.axios.get('sujetos/exportarVehiculos', {
            params: {
                ...query,
                expires: Date.now(),
            },
            responseType: 'blob',
        });

        const disposition = response.headers['content-disposition'];
        const match = /^filename="?(.+?)"?$/gmi.exec(disposition);
        const fileName = match ? match[1] : 'exportacion.xlsx';
        return {
            fileName,
            blob: response.data
        };
    }

}