// Source: https://bobbyhadz.com/blog/javascript-get-all-dates-in-month
import { dateFormat } from "./dates";

export function getAllDatesByYearMonths(dateGroups: { year: number, months: number[] }[]): string[] {
    let allDates: string[] = [];

    // Iteramos sobre los grupos de año-mes
    for (let dateGroup of dateGroups) {
        // Iteramos sobre los meses por año
        for (let month of dateGroup.months) {
            const date = new Date(dateGroup.year, month, 1);

            // Recogemos todos los días de un mes
            while (date.getMonth() === month) {
                allDates.push(dateFormat(new Date(date).toString(), 'YYYY-MM-DD'));
                date.setDate(date.getDate() + 1);
            }
        }
    }
    return allDates;
}