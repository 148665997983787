import React from "react";
import { FC } from "react";
import { useRouteMatch } from "react-router";
import ProtectedRoute from "components/protected-route";
import { SolicitudAltaDeRepresentacion } from "./alta-de-representacion";
import { GestionDeRepresentaciones } from "./gestion-de-representaciones";
import { FichaDeRepresentacion } from "./ficha-de-representacion";
import { withLiterals } from "containers/shared/literals";
import usePage from "hooks/page.hook";

const Representaciones: FC = () => {
    const match = useRouteMatch();
    const [page, ] = usePage();

    return (
        <>
            <ProtectedRoute exact path={`${match.path}/alta-de-representacion`} component={SolicitudAltaDeRepresentacion} accessLevel={1} idModulos={[10]} suplantado={page && page.jwp && page.jwp.nifRepresentante ? true : null } />
            <ProtectedRoute exact path={`${match.path}/gestion-de-representaciones`} component={GestionDeRepresentaciones} accessLevel={1} idModulos={[10]} suplantado={page && page.jwp && page.jwp.nifRepresentante ? true : null } />
            <ProtectedRoute exact path={`${match.path}/gestion-de-representaciones/:id`} component={FichaDeRepresentacion} accessLevel={1} idModulos={[10]} suplantado={page && page.jwp && page.jwp.nifRepresentante ? true : null } />
        </>
    )
}

export default withLiterals(['Representaciones'])(Representaciones);