import { Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { StyleRules, WithStyles, withStyles } from "@material-ui/styles";
import React from "react";


const styles = (theme: Theme): StyleRules => ({
    tabsContainer: {
        marginBottom: theme.spacing(1),
        flexDirection: 'row'
    },
    totalCount: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        marginRight: '1.5rem',
        width: '100%',
    }
});


interface IProps extends WithStyles<typeof styles> {
    totalCount?: JSX.Element;
}


const CustomCountTerm: React.FC<IProps> = (props) => {
    const { classes, totalCount } = props;

    const handleCount = () => {
        if (totalCount) {
            return (totalCount)
        } else {
            return <></>
        }
    }

    return (
        <div className={classes.totalCount}>
            {handleCount()}
        </div>
    )
}
export default withStyles(styles)(CustomCountTerm);