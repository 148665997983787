import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { IConceptoMetadata } from './interfaces';

import base_inmobiliaria from './images/base-inmobiliaria.png'
import ibi from './images/ibi.png'
import iae from './images/iae.png'
import vehiculos from './images/vehiculos.png'
import sanciones from './images/sanciones.png'
import sancionesIG from './images/sancionesIG.png'
import plusvalias from './images/plusvalias.png'
import icio from './images/icio.png'
import otros from './images/otros.png'
import turistico from './images/turistico.png'
import autonomicos from './images/autonomicos.png'

export function getConceptoMetadata(idConcepto: number | null, codigoConcepto?: string): IConceptoMetadata {
    switch (idConcepto) {
        case 2:
        case 9:
        case 10:
        case 11:
        case 14:
        case 15:
            return { source: base_inmobiliaria, backgroundColor: '#838A89' };
        case 1:
        case 12:
            return { source: ibi, backgroundColor: '#8A829B' };
        case 3:
            return { source: iae, backgroundColor: '#db996c' };
        case 4:
            return { source: vehiculos, backgroundColor: '#009BBF' };
        case 7:
            return { source: sanciones, backgroundColor: '#569abd' };
        case 13:
            return { source: plusvalias, backgroundColor: '#DB996C'/*'#569abd'*/ };
        case 98:
            return { source: icio, backgroundColor: '#569abd' };        
        case 8:
            if (codigoConcepto && codigoConcepto === 'IG') {
                return { source: sancionesIG, backgroundColor: '#569abd' };
            } else {
                return { source: otros, backgroundColor: '#e9c36d' };
            }
        case 5:
        case 16:
        case 17:
        case 18:
        case 99:
            return { source: otros, backgroundColor: '#e9c36d' };
        case 19:
            return { source: turistico, backgroundColor: '#569abd' };
        //autonomicos
        case -1:
            return { source: autonomicos, backgroundColor: '#E07061' };
        default:
            return { source: otros, backgroundColor: '#e9c36d' };
    }
}

export function getClassMetadata(idConcepto: number | null, classes: ClassNameMap<string>, codigoConcepto?: string): string | undefined {
    switch (idConcepto) {
        case 2:
        case 9:
        case 10:
        case 11:
        case 14:
        case 15:
            return classes.imagenContainerBI;
        case 1:
        case 12:
            return classes.imagenContainerIBI;
        case 3:
            return classes.imagenContainerIAE;
        case 4:
            return classes.imagenContainerIVTM;
        case 7:
            return classes.imagenContainerSanciones;
        case 13:
            return classes.imagenContainerPlus;
        case 98:
            return classes.imagenContainerIcio;
        case 8:
            if (codigoConcepto && codigoConcepto === 'IG') {
                return classes.imagenContainerSanciones;
            } else {
                return classes.imagenContainerOtros;
            }
        case 5:        
        case 16:
        case 17:
        case 18:
        case 99:
            return classes.imagenContainerOtros;
        case 19:
            return classes.imagenContainerIT;
        //autonomicos
        case -1:
            return classes.imagenContainerAU;
        default:
            return classes.imagenContainerOtros;
    }
}