import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { CircularProgress, Button, makeStyles, CardContent, Card, TextField, Grid, Theme } from '@material-ui/core';
import IoC from 'contexts/ioc.context';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import usePage from 'hooks/page.hook';
import { translate } from 'utils/i18n';
import Term from 'components/term';
import { LiteralsContext } from 'containers/shared/literals';
import { mdiShieldAccount } from '@mdi/js';
import { NotificacionesGateway } from 'gateways/notificaciones.gateway';
import { AlertsContext } from 'contexts/alerts.context';
import { green } from "@material-ui/core/colors";
import { download } from 'utils/download';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flex: 1,
        flexGrow: 1,
        margin: '0 1rem',
        paddingBottom: 25,
    },
    danger: {
        fontWeight: 'bold',
        color: 'red',
    },
    margin10: {
        marginLeft: 10,
    },
    button: {
        marginTop: 27,
        [theme.breakpoints.down('md')]: {
            margin: 0,
            textAlign: 'center',
        }
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        [theme.breakpoints.up('md')]: {
            left: '50%',
        }
    },
}));

type Props = RouteComponentProps;

const ActivarServicio: React.FC<Props> = ({ location, history }) => {
    // Contexts
    const terms = useContext(LiteralsContext);
    const ioc = useContext(IoC);
    const [, alertsDispatch] = useContext(AlertsContext);
    // States    
    const [loading, setLoading] = useState(false);
    const [, pageDispatcher] = usePage();
    const [accion, setAccion] = useState('0');
    const [codigoSms, setCodigoSms] = useState('');
    const [referencia, setReferencia] = useState('');
    const [smsObligatorio, setSmsObligatorio] = useState('');
    // Styles
    const classes = useStyles();
    // Gateways
    const gateway = useMemo(() => ioc.get(NotificacionesGateway) as NotificacionesGateway, [ioc]);

    const redirectToProfile = useCallback(() => {
        alertsDispatch({
            type: 'hide-alert',
        });
        history.push('/perfil');
    }, [alertsDispatch, history]);

    useEffect(() => {
        const values = queryString.parse(location.search);
        const ac: string = values.ac as string;
        const referenciaRequest: string = values.referencia as string;
        setAccion(ac);
        setReferencia(referenciaRequest);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiShieldAccount,
                title: <Term text="Area Personal" />,
            },
            menu: true,
        });
    }, [pageDispatcher]);

    const activarServicio = async () => {
        if (codigoSms !== '') {
            setLoading(true);
            setSmsObligatorio('');
            try {
                const checkCode = await gateway.checkCodigoValidacion(accion, referencia, codigoSms);

                if (checkCode.result) {
                    const documento = await gateway.activarServicioNotificaciones(accion, referencia, codigoSms);
                    if (documento) {
                        const downloadOk = download(documento, alertsDispatch,
                            translate('Perfil', accion === '0' ? 'Error validacion baja' : (accion === '1' ? 'Error validacion alta' : 'Error validacion modificacion'), terms),
                            translate('Global', 'BloqueoPantalla', terms));
                        if (downloadOk) {
                            alertsDispatch({
                                type: 'show-alert',
                                payload: {
                                    message: translate('Perfil', accion === '0' ? 'Validacion baja correcta' : (accion === '1' ? 'Validacion alta correcta' : 'Validacion modificacion correcta'), terms),
                                    variant: 'success',
                                }
                            });
                            setTimeout(() => redirectToProfile(), 3000);
                        }
                    } else {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('Perfil', accion === '0' ? 'Error validacion baja' : (accion === '1' ? 'Error validacion alta' : 'Error validacion modificacion'), terms),
                                variant: 'error',
                            }
                        });
                    }
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Global', checkCode.textError, terms),
                            variant: 'error',
                        }
                    });
                }

            } catch (e) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Perfil', accion === '0' ? 'Error validacion baja' : (accion === '1' ? 'Error validacion alta' : 'Error validacion modificacion'), terms),
                        variant: 'error',
                    }
                });
            } finally {
                setLoading(false);
            }
        } else {
            setSmsObligatorio(translate('Perfil', 'Codigo SMS obligatorio', terms));
        }
    };

    const handleSetCodigoSms = (codigoSmsLocal: string) => {
        setCodigoSms(codigoSmsLocal);
    }

    return (
        <div className={classes.root}>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="codigoSms"
                                error={smsObligatorio !== ''}
                                helperText={smsObligatorio}
                                value={codigoSms}
                                label={translate('Perfil', 'CodigoSms', terms)}
                                style={{ margin: 8 }}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                onFocus={(e) => setSmsObligatorio('')}
                                onChange={
                                    (event) =>
                                        // tslint:disable-next-line: jsx-no-lambda
                                        handleSetCodigoSms(event.target.value)
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.button}>
                            <Button disabled={loading} variant="outlined" color="primary" onClick={activarServicio}>
                                <Term component="Perfil" text={accion === '0' ? 'Validar baja' : (accion === '1' ? 'Validar alta' : 'Validar modificacion')} />
                                {
                                    loading
                                        ? <CircularProgress size={24} className={classes.buttonProgress} />
                                        : null
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}

export default withRouter(ActivarServicio);