import { Typography, Button, DialogTitle, DialogActions, useMediaQuery, useTheme, DialogContent, WithStyles, withStyles, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { FC, Fragment, useContext, useMemo, useState, useCallback, useEffect } from 'react';
import { INotificacion } from 'gateways/notificaciones.interfaces';
import Term from 'components/term';
import styles from '../shared/notificaciones.styles';
import moment from 'moment';
import IoC from 'contexts/ioc.context';
import { NotificacionesGateway } from 'gateways/notificaciones.gateway';

interface IProps extends WithStyles<typeof styles> {
    open: boolean;
    notificacion: INotificacion;

    onClose: () => void;
}

const NotificacionDetail: FC<IProps> = ({ open, notificacion, onClose, classes }) => {
    // services
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));    

    // gateways
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(NotificacionesGateway) as NotificacionesGateway, [ioc]);

    // states
    const [loading, setLoading] = useState(false);
    const [referencia, setReferencia] = useState<string>('');

    // methods
    const getReferencia = useCallback(async (idMensaje: number) => {
        setLoading(true);
        setReferencia(await gateway.getReferenciaNotificacionDocumento(idMensaje)); 
        setLoading(false);
    }, [gateway]);

    // handles
    const handleClose = () => onClose();

    useEffect(() => {
        if (open) {
            getReferencia(notificacion.idMensaje);            
        }
    }, [notificacion, open, getReferencia]);    

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            maxWidth={"md"}
            fullWidth={true}
        >
            <DialogTitle>
                <Term component="Notificaciones" text={notificacion.tipoEnvio === 'C'? "Informacion comunicacion" : "Informacion notificacion"} />
            </DialogTitle>
            <DialogContent>
                {
                    loading || !notificacion
                    ? <div className={classes.centerContent}>
                        <CircularProgress size={75} />
                    </div>
                    :
                    <Fragment>
                        <div className={classes.marginDetail25}>
                            <Typography component="p">
                                <strong>{notificacion.concepto}</strong>
                            </Typography>   
                            {
                            notificacion.incomparecencia
                                ?
                                <Typography className={[classes.highlightText, classes.highRed].join(' ')}>
                                    <Term component="Notificaciones" text="Notificada incompareciente" />
                                </Typography>
                                :
                                notificacion.fechaNotificacion
                                ?
                                <Typography className={[classes.highlightText, classes.highGray].join(' ')}>
                                    <Term component="Notificaciones" text="Notificada" />
                                </Typography>
                                :
                                <Typography className={[classes.highlightText, classes.highBlue].join(' ')}>
                                    <Term component="Notificaciones" text="Notificacion pendiente" />
                                </Typography>
                            }                    
                        </div>     
                        <div className={classes.marginDetail25}>
                            {/* <Typography>
                                <Term component="Notificaciones" text="Titular" /> - <b>{notificacion.nombreTitular}</b>
                            </Typography>   */}
                            {
                                notificacion.nombreDestinatario
                                ?
                                <Typography>
                                    <Term component="Notificaciones" text="Destinatario" /> - <b>{notificacion.nombreDestinatario}</b>
                                </Typography>  
                                :
                                null
                            }                            
                        </div>
                        <div className={classes.marginDetail25}>
                            <Typography>
                                <Term component="Notificaciones" text="Fecha de emision" />:&nbsp;
                                <span className={classes.highlightText}>
                                    {notificacion.fechaVisible  ? moment(notificacion.fechaVisible).format('DD/MM/YYYY'): null}
                                </span>
                            </Typography>
                            {
                                notificacion.fechaNotificacion
                                    ?
                                    <>
                                    <Typography>
                                        <Term component="Notificaciones" text="Fecha firma" />:&nbsp;
                                        <span className={classes.highlightText}>
                                        {moment.utc(notificacion.fechaNotificacion).format('DD/MM/YYYY HH:mm:ss')}
                                        </span>
                                    </Typography>
                                    </>
                                    :
                                    notificacion.incomparecencia && notificacion.fechaNotificacion
                                    ?
                                    <>
                                    <Typography>
                                        <Term component="Notificaciones" text="Notificada" />:&nbsp;
                                        <span className={[classes.highlightText, classes.red].join(' ')}>
                                        {moment.utc(notificacion.fechaNotificacion).format('DD/MM/YYYY HH:mm:ss')}
                                        </span>
                                    </Typography>
                                    </>
                                    :
                                    null
                            }
                        </div>
                        <div className={classes.marginDetail25}>
                            <Typography>
                                <Term component="Global" text="Descripcion" />
                            </Typography>                        
                            <Typography>
                                <b>{notificacion.texto}</b>
                            </Typography>  
                        </div>
                        {
                            referencia 
                            ?
                            <div className={classes.marginDetail25}>
                                <Typography>
                                    <Term component="Notificaciones" text={notificacion.tipoEnvio === 'C'? "Datos de la comunicacion":"Datos de la notificacion"} />
                                </Typography>   
                                <Typography>
                                    <b><Term component="Notificaciones" text="Referencia del expediente" />&nbsp;{referencia}</b>
                                </Typography>   
                            </div>
                            :
                            null
                        }
                    </Fragment>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">
                    <Term component="Global" text="Cerrar" />
                </Button>                
            </DialogActions>
        </Dialog>
    )
};

export default withStyles(styles)(NotificacionDetail);