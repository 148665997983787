// LIBRARY IMPORTS
import { FC, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Card, CircularProgress, FormControl, TextField, makeStyles, useTheme } from "@material-ui/core";
import { mdiBriefcaseOutline } from "@mdi/js";
import { RouteComponentProps, useLocation } from "react-router";
import * as ibantools from 'ibantools';
// LOCAL IMPORTS
import usePage from "hooks/page.hook";
import NumberFormatCustom from "../../../cartero-virtual/number-format.custom";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { MoreInfoContext } from "contexts/more_info.context";
import { ContenidosGateway } from "gateways/contenido.gateway";
import IoC from "contexts/ioc.context";
import Term from "components/term";
import { validateNif } from "utils/validateNif";
import { EmbargosGateway } from "gateways/embargos.gateway";
import { IDatosConsulta, IDatosEmbargo } from "gateways/embargos.interface";
import { AlertsContext } from "contexts/alerts.context";


export interface IErrorEmbargo {
  nif?: ReactElement | string;
  iban?: ReactElement | string;
  importe?: ReactElement | string;
}

const useStyles = makeStyles((theme) =>({
    base: {
        margin: "0px 40px",
        width: '100%',
        display:'flex',
        gap:'20px',
        
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          marginTop: 50,
          marginRight: 10,
          marginLeft: 10,
        },
      },

      formIban: {
        marginTop: 20,
        display: "inline",
        marginRight: 50,
        justifyContent:'space-between',

        [theme.breakpoints.up("xs")]: {
          "& > div": {
            width: "calc(100% - 20%)",
          },
        },
        [theme.breakpoints.up("sm")]: {
          "& > div": {
            width: "calc(100% - 62%)",
          },
        },
        [theme.breakpoints.up("md")]: {
          "& > div": {
            width: "calc(100% - 60%)",
          },
        },

        [theme.breakpoints.up("lg")]: {
          "& > div": {
            width: "calc(100% - 54%)",
          },
        },
      },

      formControl: {
        marginTop: 20,
        display: "inline",
        marginRight: 50,
        justifyContent:'space-between',
    
        "& > button": {
          width: "100px",
        },
    
        [theme.breakpoints.up("xs")]: {
          "& > div": {
            width: "calc(100% - 20%)",
          },
        },
        [theme.breakpoints.up("sm")]: {
          "& > div": {
            width: "calc(100% - 62%)",
          },
        },
        [theme.breakpoints.up("md")]: {
          "& > div": {
            width: "calc(100% - 60%)",
          },
        },
        [theme.breakpoints.up("lg")]: {
          "& > div": {
            width: "calc(100% - 82%)",
          },
        },
      },

      formContainer: {
        margin: "50px 40px 50px",
        height: '80%',
      },
    
      [theme.breakpoints.up('md')]: {
        rootTexto: {
          width: "100%",
          flex: 1,
      
        },
        rootDatos: {
          width: "100%",
          flex: 2,
        },
      },
      [theme.breakpoints.down('md')]: {
          rootDatos: {
            width: "100%",
          },
          rootTexto: {
            width: "100%",
          },
      },
        button: {
            width: 200,
            margin: 50,
            float: "right",
            [theme.breakpoints.down("xs")]: {
            float: "unset",
            },
        },
}));

type Props = RouteComponentProps;

const Embargos: FC<Props> = ({ history })  => {
    const ioc = useContext(IoC);
    const [, infoDispatch] = useContext(MoreInfoContext);
    const theme = useTheme();
    const classes = useStyles(theme);
    const location = useLocation();
    const search = useMemo(() => new URLSearchParams(location.search), [location]);
    const [{ lang }, pageDispatcher] = usePage();
    const [errors, setErrors] = useState<IErrorEmbargo | null>();
    
    const [nif, setNif] = useState ('') ;
    const [errorNif, setErrorNif] = useState(false);
    const [iban, setIban] = useState('');
    const [errorIban, setErrorIban] = useState(false);
    const [importe, setImporte] = useState('');
    const [errorImporte, setErrorImporte] = useState(false);
    const [loading, setLoading] = useState(false);

    const [body, setBody] = useState("");
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
    const embargosGateway: EmbargosGateway = ioc.get(EmbargosGateway);
    const [, alertsDispatch] = useContext(AlertsContext);

    const handleSetNif = (nifLocal: string) => {
        setNif(nifLocal.toUpperCase());
        setErrors({ nif: '', iban: errors?.iban, importe: errors?.importe });
        setErrorNif(false);
    }

    const handleSetIban = (ibanLocal: string) => {
      setIban(ibanLocal.toUpperCase());
      setErrors({nif: errors?.nif, iban: '', importe: errors?.importe });
      setErrorIban(false);
  }

    const handleSetImporte = useCallback((x: string) => {
      setImporte(x);
      setErrors({nif: errors?.nif, iban: errors?.iban, importe: '' });
      setErrorImporte(false);
    }, []);

    const handleValidate = useCallback(() => {
      let valido = true;
      let errorsTemp: IErrorEmbargo | null = {
        nif: '',
        importe:'',
        iban: ''
      }
      if(!validateNif(nif)){
        setErrorNif(true)
        errorsTemp.nif = <Term component='Global' text='El formato del NIF / CIF no es válido' />
        valido = false;
      }

      if (!nif) {
        setErrorNif(true)
        errorsTemp.nif = <Term component='Domiciliaciones' text='El NIF del titular es obligatorio' />
        valido = false;
      }

      if(!ibantools.isValidIBAN(iban)) {
        setErrorIban(true)
        errorsTemp.iban =<Term component='Domiciliaciones' text='El código IBAN no es válido' />
        valido = false;
      }

      if (!iban) {
        setErrorIban(true)
        errorsTemp.iban =<Term component='Domiciliaciones' text='El código IBAN es obligatorio' />
        valido = false;
      }
      if (!importe) {
        setErrorImporte(true)
        errorsTemp.importe =<Term component="CarteroVirtual" text="Error importe" /> 
        valido = false;
      }
      setErrors(errorsTemp)
      return valido;
    }, [nif, iban, importe]);

    const handleRedireccion = useCallback(
      (embargosList: IDatosEmbargo[]) => {
        history.push(`/tramites/embargos/list/`, embargosList);
      },
      [history]
    );
    

    const loadTemplate = useCallback(async () => {
      const bodyAux = await contenidoGateway.getContent("info_embargo", {});
      setBody(bodyAux[0].contenido);
    }, [contenidoGateway]);

    useEffect(() => {
        loadTemplate();
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiBriefcaseOutline,
                moreInfoTemplate: "mas_info_embargo",
                title: <Term component="Tramites" text="ConsultaEmbargos" />,
            },
            menu: true,
        });
    }, [pageDispatcher]);

    useEffect(() => {
      loadTemplate();
    }, [loadTemplate, lang]);
  
  const handleContinuar = useCallback(async() => {
    setLoading(true);
    if(handleValidate()){
      let datosTemp: IDatosConsulta = {
        nif,
        iban,
        importe
      }
      const embargosList = await embargosGateway.getEmbargos(datosTemp)
      if(embargosList.ok){

        handleRedireccion(embargosList.entidad)
      }
      else alertsDispatch({
        type: "show-alert",
        payload: {
          message: (
            <Term component="Tramites" text={embargosList.mensaje} />
          ),
          variant: "error",
        },
      })
  }
  setLoading(false)
  },[nif, iban, importe])
  
return( 
    <div className={classes.base} >
      <Card className={classes.rootTexto} >
        <div className={classes.formContainer}>
          <div style={{ fontWeight: "bold", marginBottom: 15 }}>
            <Term component="Tramites" text="EmbargosInfo" />
          </div>
          <div
              dangerouslySetInnerHTML={{ __html: body }}
              style={{ textAlign: "justify", textJustify: "inter-word" }}
          />
        </div>
      </Card>
      <Card className={classes.rootDatos}>
        <div className={classes.formContainer}>
          <div style={{height:'100%'}}>
            <div style={{ fontWeight: "bold", marginBottom: 15 }}>
              <Term component="Tramites" text="EmbargosDatos"/>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', height:'100%', justifyContent:'space-between'}}>
              <div>
                <FormControl
                key="Nif"
                className={classes.formControl}>
                  <TextField key="NIF"
                  label="NIF *"
                  error={errorNif}
                  onChange={(e) => handleSetNif(e.target.value)}
                  helperText={
                    errors?.nif
                  }
                  >
                  </TextField>
                </FormControl>
                <FormControl
                key="Iban"
                className={classes.formIban}
                >
                  <TextField key="Iban"
                  label={<Term component="CarteroVirtual" text="IBAN de la cuenta (para cuentas en España)"/>}
                  error={errorIban}
                  onChange={(e) => handleSetIban(e.target.value)}
                  helperText={
                    errors?.iban
                  }>
                  </TextField>
                </FormControl>
                <FormControl
                key="Importe"
                className={classes.formControl}>
                  <TextField key="Importe"
                  label={<Term component="CarteroVirtual" text="Importe" />}
                  onChange={(x) => {
                    handleSetImporte(x.target.value)}}
                  error={errorImporte}
                  helperText={
                    errors?.importe
                    }
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                    defaultValue={importe}
                  >
                  </TextField>
                </FormControl>
              </div>
              <div >
                <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.button}
                onClick={handleContinuar}
                disabled={loading}
                >{
                  loading
                    ?
                    <CircularProgress size={20} />
                    :
                    null
                }
                  <Term component="Global" text="Continuar" />
                </Button>
              </div>
            </div>
        </div>
        </div>
      </Card>
    </div>
)
}

export default Embargos