import React, { useMemo } from 'react';
import imageLogo from '../../resources/logo.png';
import { Paper, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { BLUE_BORRADOR } from 'containers/shared/colors';
import { translate } from "utils/i18n"
import { TValuesMxx, renderApartado, renderThreeColumns, renderTwoColumns } from './shared';
import HeaderJustificanteBorrador from 'components/DUTI/header-justificante-borrador';
import { TTipoModeloPLV } from 'containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types';

type TApartadosM081 = 'MXX_D_PLUSV'|'M081_E' |'M08X_E_OP';
type TApartadosCALC ={ calculo: TValuesMxx, desglose: {operacion: TValuesMxx ,objetivo: TValuesMxx, real: TValuesMxx }[], liquidacion: TValuesMxx}


export type TBorradorSimuladorModelo081 =  Record<Exclude<TApartadosM081,'M08X_E_OP'>,TValuesMxx|TValuesMxx[]> &  { M08X_E_OP : TApartadosCALC[] }


/** Render from ARRAY */
export const renderSimuladorM081 = (
    M081:   TBorradorSimuladorModelo081 | null, 
    terms:any, 
    footerTextoLegal?: string
) => {
    const modelo='081'

    return(
        <>
        { M081 ?
            <div  style={{margin: 20, paddingBottom: 60}}>
                <Grid container direction='column'  style={{padding: 20}}>
                    {/* { M08x.length > 1 && 
                        <Grid item container direction='row' justify='flex-end'>
                            <Typography style={{fontSize: 15, marginLeft: 30, fontWeight: 'bolder'}}>{`${translate("DUTI",'borr_modelo_declarante',terms)} ${idx+1}`}</Typography>
                        </Grid>
                    } */}
                    <HeaderJustificanteBorrador
                        componentTerms={"Plusvalias"}
                        docTypeTerm="borr_justif_simul_plv"
                        terms={terms}
                        titleTerm={`borr_simul_PLV_title`}
                    />
                    <Grid item container direction='column'>
                        { (Object.keys(M081) as (keyof TBorradorSimuladorModelo081)[]).map( (apartado) => {  
                            return(
                                <Grid item key={apartado}>
                                    { M081[apartado] !== null &&
                                        <Typography style={{backgroundColor: BLUE_BORRADOR, color: 'white',marginTop: 10, padding: '2px 5px 2px 5px',display:'flex', flex:1}}>
                                            {translate(
                                                'Plusvalias',
                                                !apartado.includes('PLUSV') && ['A', 'B', 'C','D'].includes(apartado.split('_')[1]) 
                                                    ? `M08X_${apartado.split('_')[1]}_title`
                                                    : `${apartado}_title`,
                                                terms
                                            ).toUpperCase()}
                                        </Typography>
                                    }
                                    { apartado === 'MXX_D_PLUSV' &&  //["MXX_D_PLUSV" ].includes(apartado) && 
                                        M081[apartado] !== null && 
                                        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                            {renderApartado(M081[apartado] as TValuesMxx, modelo,terms, "DUTI")}
                                        </div>
                                    }
                                    
                                    {apartado === 'M081_E' && //["M081_E"].includes(apartado) && 
                                        <Grid item container direction='column'>
                                            {renderApartado(M081[apartado] as TValuesMxx, modelo,terms, "DUTI")}
                                        </Grid>
                                    }

                                    {apartado === 'M08X_E_OP' &&  //'M08X_E_OP' in M081 && ["M08X_E_OP"].includes(apartado) && 
                                        <Grid item container direction='column'>
                                            { M081["M08X_E_OP"].map( (item) => {
                                                return (
                                                    <Grid item container direction='column' style={{borderColor: '#8fbae1',borderBottomStyle:'solid'}}>
                                                        <Grid item>
                                                            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                                {renderApartado(item.calculo as TValuesMxx, modelo,terms, "DUTI") }
                                                            </div>
                                                        </Grid>
                                                        {item.desglose.length>0 && 
                                                        <Grid item style={{backgroundColor: '#e0eaf4',borderRadius: 5, marginTop:10 , marginLeft: 25, marginBottom:5, padding: 5}}>
                                                            <Typography style={{paddingRight: 5 ,paddingLeft: 5}}>
                                                                {translate('Plusvalias','operaciones',terms).toUpperCase()}
                                                            </Typography>
                                                        
                                                            {item.desglose.map( (desgl, idx) => (
                                                                <Grid item style={{borderColor: '#8fbae1',borderTopStyle:'solid', marginTop: 5}}>
                                                                    <div style={{paddingTop: 5}}>
                                                                        {renderThreeColumns(desgl.operacion as TValuesMxx, modelo,terms, "DUTI", false)}
                                                                    </div>
                                                                    <div style={{paddingLeft: 15, marginTop: 3}}>
                                                                        <div style={{ paddingBottom: 5}}>
                                                                            <Typography style={{backgroundColor: BLUE_BORRADOR, color: 'white',paddingLeft: 5}}>
                                                                                {translate('Plusvalias','metodoObjetivo',terms)}
                                                                            </Typography>
                                                                            {renderThreeColumns(desgl.objetivo as TValuesMxx, modelo,terms, "DUTI", false)}
                                                                        </div>
                                                                        { desgl.real.length>0 && 
                                                                        <div style={{ paddingBottom: 5}}>
                                                                            <Typography style={{backgroundColor: BLUE_BORRADOR, color: 'white',paddingLeft: 5 }}>
                                                                                {translate('Plusvalias','metodoReal',terms)}
                                                                            </Typography>
                                                                            {renderThreeColumns(desgl.real as TValuesMxx, modelo,terms, "DUTI", false)}
                                                                        </div>}
                                                                    </div>
                                                                    
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                        }
                                                        {item.liquidacion.length>0 && 
                                                        <Grid item style={{backgroundColor: '#e0eaf4',borderRadius: 5, marginTop:10 , marginLeft: 25, marginBottom:5 ,padding: 5}}>
                                                            <Typography style={{paddingRight: 5 ,paddingLeft: 5}}>
                                                                {translate('Plusvalias','liquidacion',terms).toUpperCase()}
                                                            </Typography>
                                                            <div style={{paddingRight: 5 ,paddingLeft: 5, paddingBottom: 5 }}>
                                                                {renderTwoColumns(item.liquidacion as TValuesMxx, modelo,terms, "DUTI", false) }
                                                            </div>
                                                        </Grid>
                                                        }
                                                </Grid>
                                                )
                                            })} 

                                        </Grid>
                                    }
                                
                                </Grid>
                            )
                        })}
                       {footerTextoLegal 
                        ? <div>
                                <Typography style={{color: '#afafaf', fontStyle:'italic',paddingTop:60}}>
                                    {footerTextoLegal}
                                </Typography>
                            </div>
                        : null}
                       
                    </Grid>
                </Grid>
            </div>

        : <> Ooops !! Not model data to render</>
        }
        </>
    )
}