import React, { FC, useCallback } from 'react';
import { Button, Drawer, makeStyles } from "@material-ui/core";
import Term from '../../../components/term';
import FiltersForm, { ScreenType } from './filters.form';
import { IFiltersState } from './filters.reducer';
import OrderForm from './order.form';
import FiltersRemesasForm from './filters-remesas.form';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,    
    },
    drawerPaper: {
        width: 300,
        top: 230,
        height: 'calc(100% - 230px)',
        borderTopLeftRadius: 8,
    },
    main: {
        padding: 20
    },
    title: {
        marginBottom: 0
    },
    center: {
        textAlign: 'center'
    }
}));

export interface IPanelDrawerProps {
    onFiltersChange: (filters: IFiltersState) => void;
    screen: ScreenType;
    activeTab: number;
    onExportClick: () => void;
    onIdentificacionClick: () => void;
}

const PanelDrawer: FC<IPanelDrawerProps> = ({ onFiltersChange, screen, activeTab, onExportClick, onIdentificacionClick }) => {
    const classes = useStyles();

    const handleExport = useCallback(() => {
        onExportClick();
    }, [onExportClick]);

    const handleIdentification = useCallback(() => {
        onIdentificacionClick();
    }, [onIdentificacionClick]);


    return (
        <Drawer
            classes={{
                paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor="right"
            open>
            <div className={classes.main}>
                { screen === 'conductor' && activeTab == 1?
                    <>
                        <h3 className={classes.title}><Term component="Global" text="Filtros de remesas" /></h3>
                        <FiltersRemesasForm />
                        <div className={classes.center}>
                        <Button style={{marginTop: 20, marginBottom: 20}} onClick={handleIdentification} variant="outlined" >
                            <Term component="Global" text="IdentificacionMasiva" />
                        </Button>
                        </div>
                    </>
                : null
                }
                <h3 className={classes.title}><Term component="Global" text="Filtros de busqueda" /></h3>
                <FiltersForm
                    screen={screen}
                />
                <h3 className={classes.title}><Term component="Global" text="Ordenar por" /></h3>
                <OrderForm
                    screen={screen}
                />
                { screen === 'conductor' && activeTab == 0?
                    <div className={classes.center}>
                        <Button style={{marginTop: 20}} onClick={handleExport} variant="outlined" >
                            <Term component="Global" text="Exportar" />
                        </Button>
                        <Button style={{marginTop: 20, marginBottom: 20}} onClick={handleIdentification} variant="outlined" >
                            <Term component="Global" text="IdentificacionMasiva" />
                        </Button>
                    </div>
                : null
                }
            </div>
        </Drawer>
    )
};

export default PanelDrawer;