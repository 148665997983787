import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { BLUE } from "containers/shared/colors";

export const colors = {
   blueIconInfo: '#004f82', //10%, #dee2e6 ,
   white: '#FFF',
   blueBtn: BLUE,
}
const styles =  (theme: Theme): StyleRules => ({
    
})

export default styles