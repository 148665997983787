import React, { useEffect, FC, useState, useContext, useCallback, useRef, useMemo } from 'react';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import {  makeStyles, StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import { Card, Stepper, Step, StepLabel, StepConnector, Button, StepContent, Grid, StepIconProps, CardContent, CardActions, CardHeader, Typography, Divider, IconButton, Link, createMuiTheme, MuiThemeProvider, CardActionArea} from '@material-ui/core';
import Icon from '@mdi/react';
import { Icon as Icon2 } from 'atomic/atoms/icon/icon';
import { mdiArrowRight, mdiArrowLeft, mdiContentSave, mdiOpenInNew, mdiHome} from '@mdi/js';
import clsx from 'clsx';
import { translate } from 'utils/i18n';
import { MoreInfoContext } from 'contexts/more_info.context';
import Term from '../term';
import { TInfoTemplateSize } from 'contexts/more_info.reducer';
import { TNameStep, TStatusStep } from 'containers/DUTI/steps/types';
import DustiIcon from 'resources/home/tramites/iconoDusti.png'
import { IAsistenteStep } from 'gateways/model.new.interface';
import { GenericObject, TipoAsistente } from 'containers/tributos-autonomicos-locales/utils';
import DynamicStep from 'containers/tributos-autonomicos-locales/componentesAsistente/shared/dynamicStep';
import { BLUE } from 'containers/shared/colors';

export type THeaderStepper = {refCat:string, dir: string, urlCatastro: string, muni: string|undefined}
// export type TStepsContent = {
//     name: string, 
//     isMultiple?: boolean, 
//     isFixedMultiple?: boolean, 
//     component: () => JSX.Element, 
//     moreInfoTemplate?:string, 
//     moreInfoTemplateSize?: TInfoTemplateSize, 
//     formulaRepeticiones: string | null, 
//     xmlPrevio: GenericObject | null  
// }

export type TStepsContentAsistente = {
    name: string;
    idTipoApartado: string,
    component: () => JSX.Element;
    moreInfoTemplate?: string;
    moreInfoTemplateSize?: TInfoTemplateSize;
}

/** STATUS
 * 'INI': Step iniciado, con datos parciales sin constar como finalizado
 * 'FIN': Step finalizado - Activa SIGUIENTE
 * 'EDT': Step editado - Deshabilita SIGUIENTE hasta que guarden y pase a FIN
 *  null: El usuario aún no he realizado ninguna acción
 */

export interface IStepExtended extends IAsistenteStep {
    active:boolean, 
    status: TStatusStep,
    moreInfoTemplate?: string;
    moreInfoTemplateSize?: TInfoTemplateSize;
}


interface IProps {
    idRef: string
    tipoAsistente: TipoAsistente,
    steps: IStepExtended[],
    activeStep: number, // index of active step
    // stepsContent: TStepsContentAsistente[],
    handleNext: () => void,
    handlePrev: () => void,
    handleFinish: () => void,
    handleExit: () => void
}


const StepperModelosAsistente: FC<IProps> = ({idRef,tipoAsistente, steps, activeStep, handlePrev, handleNext, handleFinish, handleExit}) => {
    const classes = useStyles();
    const classesIcon = useColorlibStepIconStyles();
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [headerStepper, setHeaderStepper]= useState<THeaderStepper | null>(null)

    
    // const [heightPaper, setHeightPaper] = useState(720);

    const ref = useRef(null);

    // MEMOS ---------------------------------------------------------------------------------------------------
    // const activeStep = useMemo(() => {
    //     const findActive= steps && steps.length>0 
    //         ? steps.findIndex(s => s.active === true)
    //         : 0
    //     return  findActive >0 ? findActive : 0
    // }, [steps])

    const ready: boolean = useMemo(() => { 
        return (steps && steps.length>0 ) ? true : false
    }, [steps])
    // ---------------------------------------------------------------------------------------------------------

    const handleShowMoreInfo = useCallback(() => {
        if (steps && activeStep && steps[activeStep].moreInfoTemplate) {
            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: steps[activeStep].moreInfoTemplate ?? '',
                    size: steps[activeStep].moreInfoTemplateSize ?? undefined,
                }
            });
        }
    }, [infoDispatch, activeStep, steps ])


    
    // useEffect( () => {
    //     setShowPrev(activeStep > 0) 
    //     setShowNext(activeStep < steps.length-1)
    //     setShowFinish(activeStep === steps.length-1)
    // },[activeStep])

    useEffect(() => {
        // 👇️ use a ref (best)
        const element2 = ref.current;
        // console.log(element2);
    
        // 👇️ use document.querySelector()
        // should only be used when you can't set a ref prop on the element
        // (you don't have access to the element)
        const element = document.querySelector('#paper');
        // element && setHeightPaper(element?.scrollHeight);
        // console.log(element?.scrollHeight);
    }, []);

    return (
        <Grid container direction={window.screen.width <= 960 ? 'column-reverse' : 'row'} 
            style={{
                flexGrow: 1,
                display:'flex',
                flexWrap: 'nowrap',
                minHeight: window.screen.width <= 960 ? undefined : '600px',
                width: '100%'
            }}
        >
            {ready && 
            <>
                <Grid 
                    item 
                    xs={window.screen.width <= 960 ? 12 : 9}
                    sm={12} 
                    md={9}
                    lg={9}
                    xl={10}
                    style={{
                        display:'flex',
                        flexGrow:1,
                        marginTop: window.screen.width <= 960 ? 10: 0,
                        marginBottom: 0 
                    }}
                >
                    <Card elevation={1}
                        style={{
                            display:'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            marginLeft: 10,
                            marginRight: window.screen.width <= 960 ? 10: undefined,
                        }}
                    >
                        <CardHeader className={classes.borderBottom}
                            title= {steps[activeStep].valor.toUpperCase()}
                            subheader={
                                steps[activeStep].moreInfoTemplate 
                                ?
                                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleShowMoreInfo} size="small">
                                        <Term component="Global" text="Más información" />
                                    </Button>
                                : null
                            }
                            action={undefined}
                        />
                        <CardContent className={classes.cardContent}>
                            <DynamicStep 
                                componentType={steps[activeStep].webComponent}
                                componentProps= {{
                                    idRef:idRef,
                                    tipoAsistente: tipoAsistente,
                                    stepInfo: steps[activeStep],
                                    isFirst: activeStep === 0 ? true : false,
                                    isLast: (steps.length - 1) === activeStep,
                                    handleBack: handlePrev,
                                    handleNext: handleNext,                                 
                                    handleExit: handleExit,
                                    setHeader: setHeaderStepper
                                }}
                                
                                />
                                {/* {stepsContent[activeStep].component()} */}
                        </CardContent> 
                    </Card> 

                </Grid>
            
                <Grid item 
                    xs={window.screen.width <= 960 ? 12 : 3} 
                    sm={12}
                    md={3}
                    lg={3}
                    xl={2}
                    style={{
                        display:'flex',
                        flexGrow: 1, 
                        minHeight: window.screen.width <= 960 ? undefined : '600px',
                        marginTop: window.screen.width <= 960 ? 10: 0 ,
                        flexDirection:  window.screen.width <= 960 ? 'row': 'column',
                        position: 'relative',
                    }}
                >   

                    {/** info inmueble */}
                    { headerStepper && 
                        <div style={{top:0}} className={classes.stepperHeaderFooter}>
                            <div style={{display: 'flex', flexGrow:1, flexDirection: 'column'}}>
                                <Grid item container direction='row' alignItems='center' 
                                    style={{height: window.screen.width <= 960 ? 49 : 64, alignItems:'center'}}
                                >
                                    <Icon path={mdiHome} size={1} />
   
                                    <div className={classes.column} style={{paddingLeft: 10, height: 49, justifyContent: 'center'}}> {/**Casar con el Titulo CARD => 70px => padding 20 + 49 texto + 1 divider */}
                                        <Typography style={{ fontSize:window.screen.width <= 1440 ? 11: 12, marginBottom: 2}}>{translate('DUTI','refCatastral' ,terms)}</Typography>
                                        <div className={classes.row}>
                                            <Typography style={{fontWeight:'bolder', fontSize: 11 /*window.screen.width <= 1440 ? 11: 14*/, paddingTop: 3}}>{headerStepper.refCat}</Typography> 
                                            <Link
                                                href={headerStepper.urlCatastro}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Icon
                                                    style={{ marginLeft: 5, alignSelf: 'center' }}
                                                    path={mdiOpenInNew}
                                                    size={window.screen.width <= 1440 ? 0.6 : 0.8}
                                                    color={'blue'}
                                                />
                       
                                            </Link>
                                        </div>
                                    </div>
                                </Grid>
                                <div style={{backgroundColor: '#dfdfdf', minHeight: 1, maxHeight:1, display:'flex'}}></div>
                                <Typography style={{
                                        fontSize: 11, 
                                        minHeight: window.screen.width <= 960 ? 49: 64, 
                                        display:'flex', 
                                        alignItems: 'center'
                                    }}
                                >
                                    {headerStepper.dir}
                                </Typography> 
                                <div style={{backgroundColor: '#dfdfdf',  minHeight: 1, maxHeight:1, display:'flex'}}></div> 
                            </div>

                            {/** MVL Footer -> foto municipio */}
                            {headerStepper.muni && window.screen.width <= 960 &&
                                <div style={{marginLeft: 10,maxWidth: 220,display: 'flex', alignItems: 'center', justifyContent: 'center' ,flexGrow: 1, maxHeight: 100}}>
                                    <img  style={{objectFit: 'scale-down', maxWidth: 220, maxHeight: 100}} src={`https://www.atib.es/App_Themes/LogosMuni/${headerStepper.muni}.png`} alt={'muni logo'} />
                                </div>
                            }
                        </div>
                    }
                    

                    {/** Pasos Stepper */}
                    <Stepper id='paper' ref={ref} 
                        className={classes.stepperContainer}
                        orientation={ window.screen.width <= 960 ? "horizontal" : "vertical"} 
                        activeStep={activeStep} 
                        connector={<ColorlibConnector style={{flexGrow : 0}}/>} 
                    >
                        {steps.map((step, index) => (
                            <Step key={step.idTipoApartado}>
                                <StepLabel StepIconComponent={ (props: StepIconProps) =>(
                                    <div
                                        className={clsx(classesIcon.root, {
                                        [classesIcon.active]: props.active,
                                        [classesIcon.completed]: props.completed,
                                        })}
                                    >
                                        {step.webOrder}
                                    </div>
                                    )}
                                >
                                    <div className={
                                        activeStep === index 
                                        ? classes.boldText 
                                        : activeStep > index  ? classes.completedText : classes.lightText
                                    }>
                                        {step.valor.toUpperCase()}
                                    </div>
                                </StepLabel>
                                <StepContent style={{flexGrow: 1, flex: 'none'}}>{}</StepContent>
                            </Step>
                        ))}
                    </Stepper>

                    {/** Footer -> foto municipio */}
                    {headerStepper && headerStepper.muni && !(window.screen.width <= 960) && 
                        <div style={{ bottom:0, paddingRight:0, paddingLeft: 0}} className={classes.stepperFooter} >
                            <div style={{backgroundColor: '#dfdfdf', height: 1, display:'flex'}}></div>
                            <div className={classes.containerImgFooter}>
                                <img width={'100%'} height={'70px'} style={{objectFit: 'scale-down'}} 
                                    src={`https://www.atib.es/App_Themes/LogosMuni/${headerStepper.muni}.png`} 
                                    alt={`logo ${headerStepper.muni}`}
                                />
                            </div>
                        </div>
                    }

                </Grid>

            </>
            }
        </Grid>

    );
}

export default withLiterals(['Global',"DUTI","Tributos","Plusvalias","Modelos"])(StepperModelosAsistente);


const useStyles = makeStyles( (theme: Theme): StyleRules =>({
    // STEPPER
    boldText: {
        color: 'black',
        fontWeight: 'bold'
    },
    completedText: {
        color: 'rgba(45,178,190,1)', 
    },
    lightText: {
        fontWeight: 'lighter',
        color: 'grey',
    },
    stepperContainer: {
        display:'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginLeft: 10,
        marginRight: 0,
        borderRadius: 10,
        zIndex: 1,
        paddingTop: 135, // HeaderStepper
        paddingBottom:80, // Footer
        position: 'relative',
        width: '100%',
        boxShadow: '0 1px 1px 0 rgba(0,0,0,.5)',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            paddingTop: 120,
            paddingBottom: 10,
            paddingLeft:10,
            paddingRight:10,
            marginRight: 10,
        },           
    },
    row: {
        display:'flex', 
        flexDirection: 'row'
    },
    column: {
        display:'flex', 
        flexDirection: 'column'
    },
    borderBottom: {
        borderBottom: '1px solid #d3d3d3', 
    },
    stepperHeaderFooter: {
        position: 'absolute',
        left:0,
        zIndex: 3,
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 20,
        paddingLeft: 20,
        marginLeft:10,
        paddingTop:0,
        flexGrow: 1,
        minHeight: 120, // -----
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minHeight: 120,
            flexDirection: 'row',
            marginRight: 20,
        },
    },
    stepperFooter: {
        position: 'absolute',
        left:0,
        zIndex: 3,
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 20,
        paddingLeft: 20,
        marginLeft:10,
        paddingTop:0,
        flexGrow: 1,
        maxHeight: 75, // -----
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxHeight: 75,
            flexDirection: 'row',
            marginRight: 20,
        },
    },
    containerImgFooter:{
        display: 'flex', alignItems: 'center', 
        justifyContent: 'center',flexGrow: 1, 
        maxHeight: 80, 
        width: '100%', paddingRight:10, paddingLeft:10
    },
    cardContent: {
        flexGrow: 1, 
        height:'100%',
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
          }
    }
}));

const useColorlibStepIconStyles = makeStyles({
    root: {
        zIndex: 1,
        color: '#fff',
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'lightgrey',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        fontWeight: 'bold',
        fontSize: '18px'
    },
    active: {
        backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage: 'linear-gradient(to right, rgba(45,178,190,1),rgba(168,220,249,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }
});


const ColorlibConnector = withStyles({
    line: {
        minHeight: 8,
        border: 0,
        width: 2,
        backgroundColor: '#eaeaf0', 
        marginLeft: 8
    },
})(StepConnector);

