import React, { useContext, useMemo, useState, useCallback, useEffect } from 'react';
import { Card, WithStyles, withStyles, Typography } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { RouteComponentProps, withRouter } from 'react-router';
import styles from '../cita-previa.styles';
import Term from 'components/term';
import { IArgumentosJustificante, IParamsCitaPrevia } from 'gateways/citaPrevia.interface';
import { mdiOfficeBuilding, mdiMapMarker, mdiCalendar, mdiClockOutline, mdiAccount, mdiAt, mdiPhone, mdiBarcode, mdiPrinter } from '@mdi/js';
import moment from 'moment';
import CitaPreviaGateway from 'gateways/citaPrevia.gateway';
import IoC from 'contexts/ioc.context';
import { AlertsContext } from 'contexts/alerts.context';
import { translate } from 'utils/i18n';
import { download } from 'utils/download';
import { LiteralsContext } from 'containers/shared/literals';
import usePage from 'hooks/page.hook';

interface IProps {
    paramsCita: IParamsCitaPrevia | undefined;
    codigoCita: string | null;
    displayStepper: boolean;
}

type Props = WithStyles<typeof styles> & RouteComponentProps & IProps;

const ResumenCita: React.FC<Props> = ({ classes, paramsCita, codigoCita, displayStepper }) => {
    const ioc = useContext(IoC);
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const citaPreviaGateway: CitaPreviaGateway = useMemo(() => ioc.get(CitaPreviaGateway), [ioc]);
    const [fechaDiaSemana, setFechaDiaSemana] = useState('')
    const [{ lang },] = usePage();
    const handleJustificante = async () => {
        if (paramsCita && paramsCita.oficina) {
            let response: Blob
            let justificanteForm: IArgumentosJustificante;
            justificanteForm = {
                AppointmentCode: 'G-' + codigoCita,
                Dni: paramsCita.docNum as string,
                OfficeCode: paramsCita.oficina.code,
                Date: paramsCita.fechaCita as string + "T" + paramsCita.horaCita as string,
            }
            try {
                response = await citaPreviaGateway.getJustificanteCitaBlob(justificanteForm)
                download(response, alertsDispatch, translate('CitaPrevia', 'Error', terms), 'Justificante')
            }
            catch (e) {
                throw e
            }
        }
    }
    const handleCapitalize = useCallback(() => {
        let diaTemp
        if (paramsCita && paramsCita.fechaCita) {
            diaTemp = moment(paramsCita.fechaCita.split("T")[0]).format("dddd DD-MM-YYYY")
            setFechaDiaSemana(diaTemp.charAt(0).toUpperCase() + diaTemp.slice(1))
        }
    }, [paramsCita])

    useEffect(() => {
        moment.locale(lang)
        handleCapitalize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang, paramsCita])

    return (
        <>
            <Card className={displayStepper ? classes.resumenCard : classes.resumenFullCard}>
                <div className={classes.resumenSectionOne}>
                    <Typography className={classes.headerResumen}>
                        <Term component="CitaPrevia" text="Cita solicitada" />
                    </Typography>
                    <Typography className={classes.tramiteName}>
                        {paramsCita && paramsCita.tramiteSeleccionado
                            ? <Term component={"CitaPrevia"} text={paramsCita.tramiteSeleccionado} />
                            : <></>
                        }
                    </Typography>
                    <Typography className={classes.tramiteInfo}>
                        {paramsCita && paramsCita.nombreAgrupacionTramite
                            ? <Term component={"CitaPrevia"} text={paramsCita.nombreAgrupacionTramite} />
                            : <></>
                        }
                    </Typography>
                    {codigoCita
                        ? <div className={classes.seccionesInfo} style={{ padding: 30 }}>
                            <Icon path={mdiPrinter}
                                size={1}
                                color={"#267cab"}
                            />
                            <Typography style={{ paddingLeft: 8, textDecoration: 'underline', cursor: 'pointer' }} onClick={handleJustificante}>
                                <Term component="Global" text="Imprimir" />
                            </Typography>
                        </div>
                        : null
                    }
                </div>

                <div className={classes.resumenSectionTwo}>
                    <div className={classes.seccionInfoOfiFecha}>
                        <div className={classes.seccionesInfo}>
                            <Icon path={mdiOfficeBuilding}
                                size={1}
                                color={"#267cab"}
                                />
                            <Typography style={{ paddingLeft: 8 }}>
                                {paramsCita && paramsCita.oficina && paramsCita.oficina.name}
                            </Typography>
                        </div>

                        <div className={classes.seccionesInfo}>
                            <Icon path={mdiMapMarker}
                                size={1}
                                color={'#267cab'}
                                />
                            <Typography style={{ paddingLeft: 8 }}>
                                {paramsCita && paramsCita.oficina && paramsCita.oficina.address}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.seccionInfoOfiFecha}>
                        <div className={classes.seccionesInfo}>
                            <Icon path={mdiCalendar}
                                size={1}
                                color={"#267cab"}
                            />
                            <Typography style={{ paddingLeft: 8 }}>
                                {fechaDiaSemana}
                            </Typography>
                        </div>

                        <div className={classes.seccionesInfo}>
                            <Icon path={mdiClockOutline}
                                size={1}
                                color={"#267cab"}
                            />
                            <Typography style={{ paddingLeft: 8 }}>
                                {paramsCita && moment(paramsCita.horaCita, 'hh:mm:ss').format('hh:mm')}
                            </Typography>
                        </div>
                    </div>

                    <div className={classes.seccionesInfo}>
                        <Icon path={mdiAccount}
                            size={1}
                            color={"#267cab"}
                        />
                        <Typography style={{ paddingLeft: 8 }}>
                            {paramsCita && paramsCita.docNum} -  {paramsCita && paramsCita.nombreCompleto}
                        </Typography>
                    </div>

                    <div className={classes.seccionesInfoContacto}>
                        {paramsCita && paramsCita.email !== '' &&
                            <div style={{ display: 'flex', marginBottom: 20 }}>
                                <Icon path={mdiAt}
                                    size={1}
                                    color={"#267cab"}
                                />
                                <Typography style={{ paddingRight: 15, paddingLeft: 8 }}>
                                    {paramsCita && paramsCita.email}
                                </Typography>
                            </div>
                        }

                        <div style={{ display: 'flex' }}>
                            <Icon path={mdiPhone}
                                size={1}
                                color={"#267cab"}
                            />
                            <Typography style={{ paddingLeft: 8 }}>
                                {paramsCita && paramsCita.phone}
                            </Typography>
                        </div>

                    </div>

                    {codigoCita &&
                        <div className={classes.seccionesInfo}>
                            <Icon path={mdiBarcode}
                                size={1}
                                color={"#267cab"}
                            />
                            <Typography style={{ paddingLeft: 8 }}>
                                G-{codigoCita}
                            </Typography>
                        </div>
                    }

                </div>
            </Card>
        </>
    );
}

export default withRouter(withStyles(styles)(ResumenCita));