// LIBRARY IMPORTS
import { FC, useContext, useState } from "react";
import {
    Button,
    DialogActions,
    useTheme,
    makeStyles,
    DialogContent,
    CircularProgress,
    DialogTitle,
    Typography,
    // TextField,
} from "@material-ui/core";
// LOCAL IMPORTS
import { GenericModal } from "components/generic-modal";
import Term from "components/term";
// STYLES IMPORTS
import styles from './styles';
import { InfoSancionCompleta } from "gateways/recibo.interfaces";
import ConceptoIcon from "components/concepto.icon";
import moment from "moment";
import usePage from "hooks/page.hook";
import InputText from "components/DUTI/input-text";
import { validarDocumentoIdentificativo, validateNif } from "utils/validateNif";
import GenericInputSelect, { TSelect } from "components/generic-input-select";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";

const useStyles = makeStyles(styles);

export interface IProps {
    open: boolean;
    data: InfoSancionCompleta | null;
    identificada: boolean;
    nif: string |undefined;
    nombre: string |undefined;
    onClose: () => void;
    continuar: () => void;
    setNif: (nif: string|undefined) => void;
    setNombre:(nombre: string|undefined) => void;
}

const PagoTelematicoMulta: FC<IProps> = (props) => {
    const { open, data, identificada, nombre, nif, onClose, continuar, setNombre, setNif } = props;
    // HOOKS

    const [nifError, setNifError] = useState(false)
    const [nombreError, setNombreError] = useState(false)
    const terms = useContext(LiteralsContext);

    const theme = useTheme();
    const classes = useStyles(theme);
    const [pageState, ] = usePage();

    // STATES
    const [loadingPrint, setLoadingPrint] = useState(false);
    const [tipoDoc, setTipoDoc] = useState<TSelect |undefined>();
    const tipos = [{id:'1', nombre: 'DNI/NIF/NIE'}, {id: '2', nombre: translate('Global', "doc_ident", terms)}]
    const handleCloseModal = () => {
        setNif('');
        setNombre('');
        setNifError(false);
        setTipoDoc(undefined);
        setLoadingPrint(false);
        onClose();
    };

    const handleChangeNif = (value: string|undefined) => {
        setNif(value)
        setNifError(false)
    }
    
    const handleChangeNombre = (value: string|undefined) => {
        setNombre(value)
        setNombreError(false)
    }
    
    const handleContinuar = async () => {
        let error = false
        if( !nombre || nombre === ''){
            setNombreError(true)
        }
        //Una validacion u otra dependiendo del tipo
        if(tipoDoc?.id === '1' || tipoDoc === undefined){
            if(nif && validateNif(nif.toUpperCase()) && !error){
                setLoadingPrint(true);
                await continuar();
            }
            else{
                setNifError(true);
            }
        }

        else{
            if(nif && validarDocumentoIdentificativo(nif.toUpperCase()) && !error){
                setLoadingPrint(true);
                await continuar();
            }
            else{
                setNifError(true);
            }
        }
        setLoadingPrint(false);
    }

    return (
        data ?
        <GenericModal open={open}>
            <DialogTitle>
                <Term component={"Global"} text={"Informacion de sancion"} />
            </DialogTitle>
            <DialogContent >
                <div className={classes.grid1} >
                    <div className={classes.logo}>
                        <ConceptoIcon concepto={7} classes={{width:"25%"}}></ConceptoIcon>
                    </div>
                    <div className={classes.textContainer}>
                        <Term component="Global" text="Boletin"></Term>:
                        <Typography>{data?.Multa.NumeroBoletin}</Typography>
                    </div>
                    <div className={classes.textContainer}>
                        <Term component="Global" text="Municipio"></Term>:
                        <Typography>{data?.Multa.Municipio}</Typography>
                    </div>
                    <div className={classes.textContainer}>
                        <Term component="Global" text="Lugar"></Term>:
                        <Typography>{data?.Recibo.LugarDenuncia}</Typography>
                    </div>
                    <div className={classes.textContainer}>
                        <Term component="Global" text="Fecha multa"></Term>:
                        <Typography>{moment(data?.Recibo.FechaMulta).format('DD/MM/YYYY HH:mm')}</Typography>
                    </div>
                </div>
                <div className={classes.title}>
                    <Term component="Global" text="Datos multa"></Term>
                </div>
                <div className={classes.grid1}>
                    <div className={classes.textContainer}>
                        <Term component="Global" text="Articulo"></Term>:
                        <Typography>{data?.Recibo.Articulo}</Typography>
                    </div>
                    <div className={[classes.textContainer, classes.concepte].join(' ')}>
                        <Term component="Global" text="Concepto"></Term>:
                        <Typography>{pageState.lang === "es" ? data?.Multa.DescripcionES : data?.Multa.DescripcionCA}</Typography>
                    </div>
                    <div className={classes.textContainer}>
                        <Term component="Global" text="Importe"></Term>:
                        <Typography>{data?.Multa.ImportePendiente + "€"}</Typography>
                    </div>
                    <div className={classes.textContainer}>
                        <Term component="Global" text="Gravedad"></Term>:
                        <Typography>
                        <Term component="Global" text={data?.Recibo.Gravedad}></Term>
                        </Typography>
                    </div>
                </div>
                <div className={classes.flex}>
                    <div style={{width: "100%"}}>
                        <div className={classes.title}>
                            <Term component="Global" text="Datos del vehiculo"></Term>
                        </div>
                        <div className={classes.flexWrap}>
                        <div className={classes.textContainer}>
                            <Term component="Global" text="Matrícula"></Term>:
                            <Typography>{data?.Recibo.Matricula}</Typography>
                        </div>
                        <div className={classes.textContainer}>
                            <Term component="Global" text="Marca"></Term>:
                            <Typography>{data?.Recibo.Marca}</Typography>
                        </div>
                        <div className={classes.textContainer}> 
                            <Term component="Global" text="modelo"></Term>:
                            <Typography>{data?.Recibo.Modelo}</Typography>
                        </div>
                        </div>
                    </div>
                    <div style={{width: "100%"}}>
                        <div className={classes.title}>
                        {
                            identificada  
                            ? <Term component="Global" text="Datos del infractor"></Term>
                            : <Term component="Global" text="Intriduzca los datos del infractor"></Term>
                        }
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex'}}>

                        <GenericInputSelect 
                            componentIG={"GenericComponents"}
                            labelTerm='tipo_doc'
                            value={tipoDoc}
                            options={tipos}
                            onChangeValue={(v: TSelect | undefined,err: boolean) => {
                                setTipoDoc(v)
                                setNif('')
                                setNifError(false)
                            }}
                            className={classes.input200}
                            disabled={identificada || loadingPrint}
                            />

                        <InputText
                                    fieldTerm={(tipoDoc?.id === '1' || tipoDoc === undefined) ? 'NIF' : 'doc_indent'}
                                    value={nif}
                                    onChangeValue={(v:string|undefined, err: boolean) => {
                                        handleChangeNif(v)
                                    }}
                                    disabled={identificada || loadingPrint || !tipoDoc}
                                    error={nifError}
                                    errorMsgExterno={ <Term component="Global" text={(tipoDoc?.id === '1' || tipoDoc === undefined) ? "NIF no valido" : 'Doc no valido'}></Term>}
                                    skipValidate
                                    />
                                    </div>
                        <InputText
                                    fieldTerm='NomApe'
                                    value={nombre}
                                    error={nombreError}
                                    onChangeValue={(v:string|undefined, err: boolean) => {
                                        handleChangeNombre(v)
                                    }}
                                    errorMsgExterno={ <Term component="Global" text="Obligatorio"></Term>}
                                    disabled={identificada || loadingPrint}
                                    skipValidate
                                />
                        </div>
                    </div>

                </div>

            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCloseModal}
                    color="default"
                    disabled={loadingPrint}
                >
                    <Term component="Global" text="Cerrar" />
                </Button>

                <Button 
                variant="outlined"  
                color="primary" 
                disabled={nombre === '' || nif === '' || loadingPrint || nifError || nombreError}
                onClick={handleContinuar}
                >
                    {<Term component="Tramites" text="Siguiente" />}
                </Button>
                {loadingPrint && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
            </DialogActions>
        </GenericModal>
        : <></>
    );
};

export { PagoTelematicoMulta };
