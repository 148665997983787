import React, { useState, useContext } from 'react';
import { WithStyles, withStyles, Theme, TextField, Typography, DialogTitle } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';
import IoC from 'contexts/ioc.context';
import * as i18nGateway from 'gateways/i18n.gateway';
import usePage from 'hooks/page.hook';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog, { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import { Icon } from '@mdi/react';
import { mdiHome } from '@mdi/js';

const styles = (theme: Theme): StyleRules => ({
  modal: {
    fontSize: 12,
    position: "relative",
  },
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
    textAlign: 'center'
  },
  content: {
    width: "100%",
    padding: "10px 5px",
  },
  actions: {
    margin: "auto",
    width: "100%",
    padding: "10px 5px",
    textAlign: "center",
  },
  button: {
    fontFamily: "Roboto,Arial,sans-serif",
    color: "#000",
    cursor: "pointer",
    padding: "0 30px",
    display: "inline-block",
    margin: "10px 15px",
    textTransform: "uppercase",
    lineHeight: "2em",
    letterSpacing: "1.5px",
    outline: "none",
    position: "relative",
    fontSize: 14,
    border: "3px solid #cfcece",
    backgroundColor: "#fff",
    borderRadius: "15px 15px 15px 15px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
});

export interface IProps extends WithStyles<typeof styles> {
  className?: string;
  text: string;
  component?: string;
  type?: string;
  translatedText?: string;
  open: boolean;
  onClose: () => void;
}

function LiteralDialog(props: IProps & WithMobileDialog & WithStyles<typeof styles>) {
  const { fullScreen, onClose, open, classes, component, text, translatedText } = props;
  const [state] = usePage();
  const [newTranslatedText, setNewTranslatedText] = useState(translatedText);

  // Services
  const ioc = useContext(IoC);
  const gateway = ioc.get(i18nGateway.I18nGateway) as i18nGateway.I18nGateway;

  const handleClose = () => onClose();

  const updateTranslatedText = async () => {
    await gateway.updateTranslatedText(text, state.lang, newTranslatedText, component);
    onClose();
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Icon path={mdiHome}
            title="Cerrar"
            size={1}
            color="dimgrey"
          />
          <Typography variant="h6" color="inherit" className={classes.title}>
            {text}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.modal}>
            <div className={classes.content}>
              <TextField
                id="translatedText"
                multiline
                rows="4"
                defaultValue={translatedText}
                className={classes.textField}
                margin="normal"
                variant="filled"
                autoFocus
                fullWidth
                onChange={(e) => setNewTranslatedText(e.target.value)}
              />
            </div>
            <div className={classes.actions}>
              <button
                className={classes.button}
                onClick={updateTranslatedText}
              >
                Actualizar
                  </button>
              <button
                className={classes.button}
                onClick={handleClose}
              >
                Cerrar
                  </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withStyles(styles)(withMobileDialog()(LiteralDialog));