import React, {FC, useContext, useEffect, useMemo, useRef, useState} from "react"
import { Button, Grid, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core"
import { LiteralsContext, withLiterals } from "containers/shared/literals"
import { translate } from "utils/i18n"
import { AlertsContext } from "contexts/alerts.context"
import IoC from "contexts/ioc.context"
import ModelGateway from "gateways/model.new.gateway"
import Modal from "components/modal"
import { TCalcPLV, TCalcPlusvalia, TTipoModeloPLV } from "../../plusvalias/types"
import FullLoading from "components/full-loading"
import {extraerBorradorM08x } from "components/BorradorModelos/extraerModeloAsistentePLV"
import { IAsistentePLVObj } from "gateways/model.new.interface"
import { TBorradorModelo08x, renderM08x } from "components/BorradorModelos/modelo_08x_asistente"
import { mdiEyeCircleOutline } from "@mdi/js"
import { BLUE } from "containers/shared/colors"
import Icon from "@mdi/react"
import CheckBox from "components/DUTI/check-box"
import TypedInputNumber from "components/DUTI/typed-input-number"
import InputText from "components/DUTI/input-text"

interface IProps extends WithStyles<typeof styles> {
    idReferencia: string
}
const ResumenPLV :FC<IProps>= ({
    classes, idReferencia
}) => {

    const componentMounted = useRef(true);
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelosGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    

    const [tipoModelo,setTipoModelo]= useState<TTipoModeloPLV| undefined>(undefined)
    const [loading, setLoading]= useState(false)
    const [showBorrador, setShowBorrador]= useState(false)
    const [datosAsistente, setDatosAsistente]= useState({} as IAsistentePLVObj)
    const [codeMuni, setCodeMuni]= useState("")
    const [condSujetoPasivo, setCondSujetoPasivo]= useState<boolean|undefined>(undefined)


    const isMobile = window.outerWidth <= 950;
    const adptarComoModelosIndependientes = ( info: IAsistentePLVObj) => {
        // duplicar N veces el objeto CalcPlusvalia para aquellos calculos con liquidacionMultiple (tantos como nifs)
        // cada CalcPlusvalia debe tener un solo nifSujeotPasivo para que el borrador lo muestre como un modelo independiente
        const toDuplicar = info.calcPlusvalia?.map((c, idx) => ({ duplicar: c.liquidacionMultiple, nifs: c.calcPLV.map( n => n.nifSujetoPasivo)}))
        const calcPLV_ARR: TCalcPlusvalia[] = []
        info.calcPlusvalia?.forEach( calcPlusval => {
            if(calcPlusval.liquidacionMultiple){
                const nifs= calcPlusval.calcPLV.map( n => n.nifSujetoPasivo)
                nifs.forEach(nif => {
                    const newCalcPLV = calcPlusval.calcPLV.filter( c => c.nifSujetoPasivo === nif)
                    if (newCalcPLV){
                        calcPLV_ARR.push({...calcPlusval, calcPLV: newCalcPLV})
                    }
                    
                });
            } else {
                calcPLV_ARR.push(calcPlusval)
            }

        })
   
        const out =[{...info, calcPlusvalia: calcPLV_ARR }];
        return out
    }
    const MDatos: TBorradorModelo08x[][] | null = useMemo(() => {
        if(tipoModelo && datosAsistente){
            const datos:IAsistentePLVObj[] = adptarComoModelosIndependientes(datosAsistente)   
            return extraerBorradorM08x(datos, terms)
        } else {
            return null
        }
    },[tipoModelo, datosAsistente])

    const valueTotalIngresar = useMemo(() => {
        if( datosAsistente.calcPlusvalia ){
            const values : number[] = datosAsistente.calcPlusvalia.map(item => {
                const t = item.calcPLV.length>0 ? item.calcPLV.map(c => {
                    const all = c.calculos.map(cc => {
                        if (cc.modelo && cc.modelo.totalIngresar) {
                            const totalIngresar = parseFloat(cc.modelo.totalIngresar.toString());
                            return isNaN(totalIngresar) ? 0 : +totalIngresar.toFixed(2);
                        }
                        return 0;
                    });
                    const allSum = all.reduce((a,b) => (a+b), 0);
                    return allSum;
                }) : [0];
                const sum = t.reduce((a,b) => (a+b), 0);
                return sum;
            });
            const val = values.reduce((a,b) => (a+b), 0)

            return parseFloat(val.toFixed(2))

        } else {
            return 0
        }

    }, [datosAsistente.calcPlusvalia])

    useEffect(() => {
        (async()=> {
            try {
                setLoading(true)
                const prevInfo = await modelosGateway.getAllApartadosModeloAsistente('PLV',idReferencia)
                const codeMuni = prevInfo.preguntasPlusvalia && prevInfo.preguntasPlusvalia.municipio ? prevInfo.preguntasPlusvalia.municipio.id :""

                // Calls - await 
                if(componentMounted.current){
                    //setters
                    setDatosAsistente(prevInfo)
                    const tipoMod=prevInfo.preguntasPlusvalia && prevInfo.preguntasPlusvalia.tipoPlusvaliaModelo 
                        ? prevInfo.preguntasPlusvalia.tipoPlusvaliaModelo.id as TTipoModeloPLV : undefined
                    setTipoModelo(tipoMod)
                    setCodeMuni(codeMuni)   
                    console.log('prevInfo.preguntasPlusvalia?.sujetoPasivo',prevInfo.preguntasPlusvalia?.sujetoPasivo)
                    setCondSujetoPasivo(prevInfo.preguntasPlusvalia?.sujetoPasivo)                 
                }
            } catch (err){
                const msg= (err as Error).message
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Plusvalias', msg, terms),
                        variant: 'error',
                    }
                });
            } finally {
                setLoading(false)
            }
        })();

        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    },[idReferencia]);

    

    return (
        <>
            <FullLoading loading={loading} />
            <Grid container className={classes.sectionJustif}>
                <Grid item xs={12} sm={12} md={12}lg={3} xl={2}>
                    <img className={classes.logoTramites} src={`https://www.atib.es/App_Themes/LogosMuni/${codeMuni}.png`} alt = "logo-muni"/>
                </Grid>   
                <Grid item   xs={12} sm={12} md={12} lg={7} xl={8} container direction="column">
                    <Grid item>
                        <CheckBox 
                            fieldTerm='tramite_plusvalia_atib'
                            addToLabel={
                                ' - '.concat(
                                    tipoModelo === '081' 
                                    ? translate('DUTI','tramite_plusvalia_autoliquidacion_atib',terms)
                                    : tipoModelo === '080' 
                                        ? translate('DUTI','tramite_plusvalia_declaracion_atib',terms) 
                                        : translate('DUTI','tramite_plusvalia_comunicacion_atib',terms)
                                )
                            }
                            disabled={true}
                            value={MDatos && tipoModelo}
                        />
                    </Grid>
                    <Grid item>
                        <div style={{ paddingLeft: 30, marginTop: 10}}>
                            {datosAsistente.calcPlusvalia && datosAsistente.calcPlusvalia.length > 1 ?
                            <>
                                <div className={classes.row} style={{alignItems:'flex-start'}}>
                                    <div className={classes.column} style={{maxWidth:340, marginRight: 30, alignContent:'center'}}>
                                        <Typography>
                                            {translate('DUTI', 'tramiGen_totalDeclarantes', terms)}: {datosAsistente.calcPlusvalia.length}
                                        </Typography>
                                        {/* <Typography style={{fontSize: 15, fontStyle: 'italic'}}>{translate('DUTI','m081_ver_borrador',terms)}</Typography> */}
                                    </div>
                                    {tipoModelo === '081' && 
                                    <TypedInputNumber 
                                        key="totalIngresar"
                                        type="money"
                                        disabled
                                        allowZero={true}
                                        required={false}
                                        min={0}
                                        maxDecimal={3}
                                        fieldTerm="totalIngresar"
                                        value={valueTotalIngresar}
                                        onChangeValue={(v, err) => {}}
                                        className={classes.input160} 
                                    />  
                                    }
                                </div>
                                
                            </>
                                                
                            :
                                <div className={classes.row}>
                                    {datosAsistente.calcPlusvalia && datosAsistente.calcPlusvalia[0].calcPLV.map ( c =>  (
                                        <InputText
                                            required 
                                            disabled
                                            fieldTerm='tramiGen_nifSuetoPasivo'
                                            value = {
                                                (c.nifSujetoPasivo)
                                                    ? c.nifSujetoPasivo 
                                                    : ' - ' 
                                            }
                                            onChangeValue= {(v:string, err:boolean) => {}}
                                            className={classes.input260} 
                                        />
                                    ))}
                                    
                                    {tipoModelo === '081' && 
                                        <TypedInputNumber key="totalIngresar"
                                            type="money"
                                            disabled
                                            allowZero={true}
                                            required={false}
                                            min={0}
                                            //maxDecimal={2}
                                            fieldTerm="totalIngresar"
                                            value={valueTotalIngresar}
         
                                            onChangeValue={(v, err) => {}}
                                            className={classes.input160} 
                                        />                                            
                                    }
                                </div>
                            }
                        </div>
                    </Grid>
                </Grid>
                <Grid item  xs={12} sm={12} md={12} lg={2} xl={2} className={classes.btnTramites}>
                    <Button 
                        variant="outlined" color='primary' size="small"
                        startIcon= { 
                            <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} /> 
                        }
                        onClick={()=> setShowBorrador(true)}
                    >
                        {translate('DUTI', 'btn_consulta_borrador',terms)}
                    </Button>
                </Grid>
                       
               
            </Grid>



            
            <Modal 
                fullWidth
                maxWidth='lg' 
                PaperProps={{style: {backgroundColor: '#e0eaf4'}}}
                open={showBorrador}
                title={translate('DUTI', 'modal_borrador_title', terms).toUpperCase().concat(' ',tipoModelo ?? "")}
                renderContent={ () => <> {MDatos?.map( mdato => renderM08x(mdato, tipoModelo,terms) )} </>}
                renderActions={ () => (
                    <Button style={{margin:10}}
                        variant="contained" color="primary"
                        onClick={()=> setShowBorrador(false)}>
                        {translate('Global', 'Cerrar',terms)}
                    </Button>
                    )
                }
            />
        </>
    )
}

const styles =  (theme: Theme): StyleRules => ({
    sectionJustif:{
        paddingTop: 10,
        paddingBottom:10,
        borderBottom: 'solid', 
        borderBottomWidth:1, 
        borderBottomColor:'#d3d3d3'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap:'wrap'
    },
    btnTramites:{
        width: 150, 
        display:'flex',
        alignItems:'center',
        justifyContent: 'flex-end'
    },
    logoTramites:{
        width: 200, 
        objectFit: 'scale-down',
        paddingRight: 20
    },
    grow: { // Eliminar tamaño logo (logoTramites) y boton (btnTramites)
        display: 'flex',
        justifyContent:'center',
        flexGrow: 1,
       
    },

})

export default withLiterals(['Global','DUTI','Plusvalias'])(withStyles(styles)(ResumenPLV));

