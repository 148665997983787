import { Button, Typography, Tooltip, makeStyles } from "@material-ui/core";
import React, { useState, ChangeEvent, useEffect, useContext, useMemo, useCallback } from 'react';
import { DocumentosGateway } from "gateways/documentos.gateway";
import IoC from "contexts/ioc.context";
import Term from "./term";

let nextInstanceID = 0;

const styles = makeStyles(() => ({
    buttonAttached: {
        height: 24,
        boxShadow: 'none',
    },
    label: {
        transform: 'scale(0.75)',
        transformOrigin: 'top left',
        color: 'rgba(0, 0, 0, 0.54)',
        display: 'flex',
        alignItems: 'center'
    },
    input: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    inputError: {
        borderBottom: '1px solid red',
    },
    marginName: {
        marginLeft: 10,
        marginTop: 5
    },
    flex: {
        display: 'flex'
    },
    error: {
        color: 'red !important'
    },
    helperText: {
        color: 'rgba(0, 0, 0, 0.54)',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: 8,
        minHeight: '1em',
        textAlign: 'left',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontWeight: 400,
        lineHeight: '1em',
        letterSpacing: '0.03333em',
    }
}));

export interface IProps {
    label?: React.ReactNode;
    required?: boolean;
    error?: boolean;
    helperText?: string | null;
    onChange(idDoc: string | string[]): void;
    fileType?: string;
    handleNotAllowedDocuments?: () => void;
}

export function FileField({ required, onChange, label, error, helperText, fileType, handleNotAllowedDocuments = () => { } }: IProps) {
    const ficherosPermitidos = useMemo(() => fileType ? [fileType] : [".jpeg", ".jpg", ".png", ".gif", ".doc", ".docx", ".pdf", ".odt", ".rtf", ".txt"], [fileType]);
    const classes = styles();
    const [files, setFiles] = useState<File[] | null>(null);
    const [instanceID, setInstaceID] = useState(0);
    const [tooltipText, setTooltipText] = useState('');
    const [errorSize, setErrorSize] = useState(false);
    const ioc = useContext(IoC);
    const gateway = ioc.get(DocumentosGateway) as DocumentosGateway;
    useEffect(() => {
        setInstaceID(nextInstanceID++);
    }, [])
    const handleFileAttach = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
        setErrorSize(false);
        if (event.target && event.target.files) {
            if (event.target.files.length === 0) {
                return;
            } else {
                for (let i = 0; i < event.target.files.length; i++) {
                    if (event.target.files[i].size > 50000000) {
                        setErrorSize(true);
                        return;
                    }
                }
            }

            if (ficherosPermitidos.includes(event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf(".")).toLowerCase())) {
                const ficheros: File[] = [];
                let tooltip = '';
                Array.prototype.forEach.call(event.target.files, (file) => {
                    ficheros.push(file);
                })
                setFiles(ficheros);
                for (const x of ficheros) {
                    tooltip += x.name + ' / '
                }
                setTooltipText(tooltip);
                const idDoc: string | string[] = await gateway.subirDocumentos(event.target.files, 'sede-presentacion')
                onChange(idDoc.length === 1 ? idDoc[0] : idDoc);
            } else {
                handleNotAllowedDocuments();
            }
        } else {
            setFiles(null);
        }
    }, [ficherosPermitidos, gateway, onChange, handleNotAllowedDocuments])
    return (
        <>
            <input
                accept={fileType ? fileType : "image/*,.pdf"}
                className={classes.input}
                style={{ display: 'none' }}
                id={`file-field${instanceID}`}
                type="file"
                multiple
                onChange={handleFileAttach}
            />
            {
                label
                    ?
                    <Typography className={!error ? classes.label : `${classes.error} ${classes.label}`} >{label} {required ? '*' : ''}</Typography>
                    :
                    null
            }

            <label className={error ? `${classes.flex} ${classes.inputError}` : `${classes.flex} ${classes.input}`} htmlFor={`file-field${instanceID}`}>
                <Button variant="contained" component="span" className={classes.buttonAttached}>
                    {files ? 'Cambiar' : 'Adjuntar'}
                </Button>
                {
                    !!files ?
                        <>
                            {
                                files.length === 1
                                    ?
                                    <div className={error ? `${classes.error} ${classes.marginName}` : classes.marginName}>{` ${files[0].name}`}</div>
                                    :
                                    <div className={error ? `${classes.error} ${classes.marginName}` : classes.marginName}><Tooltip title={tooltipText} placement="right"><span>documentos({files.length})</span></Tooltip></div>
                            }
                        </>
                        :
                        null
                }
            </label>
            {
                errorSize &&
                <Typography className={`${classes.error} ${classes.helperText}`}><Term component="global" text="ficheroGrande" /></Typography>
            }
            {
                !errorSize && error
                    ? <Typography className={`${classes.error} ${classes.helperText}`}>{helperText ? helperText : 'Debe adjuntar un fichero'}</Typography>
                    : (
                        helperText
                            ? <Typography className={classes.helperText}>{helperText}</Typography>
                            : null
                    )
            }
        </>
    )
}