import { FC, useState } from 'react';
import { Card } from '@material-ui/core';
import DocumentacionNotario from 'containers/tramites/expedientes/cajas/documentacionNotario';
import DocumentoBase from 'containers/tramites/expedientes/cajas/documentoBase';
import { LinkSolicitudAjutsCovid } from 'containers/tramites/expedientes/cajas/link-solicitud-ajuts-covid';
import ModelosAsociados from 'containers/tramites/expedientes/cajas/modelosAsociados';
import { IRenderComponent } from 'gateways/tramites.interfaces';
import { DetallePresentacion } from 'containers/tramites/expedientes/detalle-presentacion';
import { match } from 'react-router-dom';
import { useHistory } from 'react-router';
import DocumentosAdicionales from 'containers/tramites/expedientes/cajas/documentosAdiconales';

const diccionarioComponentes: { [component: string]: IRenderComponent } = {
    'escritura_publica': {
        renderComponent: DocumentacionNotario,
    },
    'vinculo_modelos': {
        renderComponent: ModelosAsociados,
    },
    'doc_base': {
        renderComponent: DocumentoBase,
    },
    'docs_adicionales': {
        renderComponent: DocumentosAdicionales,
    },
    'vinculo_ajuts_covid': {
        renderComponent: LinkSolicitudAjutsCovid,
    }
};

interface IProps {
    idExpediente: number;
    handleNext: () => void;
}

const PresentacionExpedienteDusti: FC<IProps> = ({ idExpediente, handleNext }) => {
    // Global states
    const history = useHistory();
    const location = history.location;

    // Local states
    const [showContinuar, setShowContinuar] = useState(false);

    const myMatch: match<{ idExpediente: string; }> = {
        isExact: true,
        path: "",
        url: "",
        params: { idExpediente: idExpediente.toString() }
    };

    const onHandleShowcontinuar = () => {
        setShowContinuar(true);
    }

    return (
        <Card style={{padding: '20px 10px 10px 10px'}}> 
            <DetallePresentacion 
                diccionarioComponentes={diccionarioComponentes}
                idExpediente={idExpediente}
                history={history}
                location={location}
                match={myMatch}
                onHandleShowContinuar={onHandleShowcontinuar}
                showContinuar={showContinuar}
                handleNext={handleNext}
            />            
        </Card>
    );
}

export default PresentacionExpedienteDusti;
