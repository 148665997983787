import React, {FC, useContext, useEffect, useMemo, useState} from "react"
import { WithStyles, withStyles ,  StyleRules, Theme } from "@material-ui/core"
import { mdiAlertCircleOutline } from "@mdi/js"
import Icon from "@mdi/react"

import IoC from "contexts/ioc.context"
import { ContenidosGateway } from "gateways/contenido.gateway"
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals"

import { translate } from "utils/i18n"
import { BLUE, BLUE_BORRADOR } from "containers/shared/colors"




type Props = WithStyles<typeof styles> & {
    type?: 'error' | 'info'
    } & ({
        content: string,
        paramContent: Record<string,any>
        componentIG?: never, termIG?: never, paramTermIG?: never
    } | {
        content?: never,
        paramContent?: never,
        componentIG: string, 
        termIG: string,
        paramTermIG?: string[]
    }
)

const EndProcessMSG : FC<Props>= ({ classes,type='error', componentIG,termIG,paramTermIG, content, paramContent}) => {
    //Gateways
    const ioc = useContext(IoC);
    const contenidoGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);
    const terms = useContext(LiteralsContext);

    const [MSGContent,setMSGContent]= useState<string|undefined>(undefined)
    const COLORS= {
        error: '#ef1111' ,
        info: BLUE
    }
    
    useEffect(()=> {
        if(content && paramContent){
            (async() => {
                const MSG = await contenidoGateway.getContent(content, paramContent)
                setMSGContent(MSG[0].contenido)
            })()
        } else {setMSGContent('')}
    },[content,paramContent])

    return (
        
        <div className={classes.containerMSG} style={{borderColor: COLORS[type]}}>
            <div style={{marginRight: 10}}>
                <Icon path={mdiAlertCircleOutline} color={COLORS[type]} style={{alignSelf:'flex-start'}} size={4} />
            </div>
            {content 
                ? <div className={classes.textCancel} dangerouslySetInnerHTML={{ __html: MSGContent ?? 'Oops ! Lost MSG' }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                : <div className={classes.textCancel}>{ componentIG && termIG ? translate(componentIG,termIG, terms, paramTermIG ?? []) : 'Oops ! Lost MSG'}</div>
            }
        </div>
       
    )
}


const styles =  (theme: Theme): StyleRules => ({
    containerMSG: {
        border:'solid',
        borderRadius: 10, 
        borderWidth: 2, 
        marginTop: 20,
        marginBottom:20,
        display:'inline-flex', 
        alignItems: 'center',
        width: '100%'
    },
    textCancel: {
        fontWeight: 450,
        paddingTop: 30,
        paddingLeft: 10,
        paddingBottom: 30,
        paddingRight: 30,
        
    },
})

export default withLiterals(ALL_LITERALS)(withStyles(styles)(EndProcessMSG));