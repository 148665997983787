import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { CircularProgress, Button, makeStyles, CardContent, Card } from '@material-ui/core';
import IoC from 'contexts/ioc.context';
import { SujetosGateway } from 'gateways/sujetos.gateway';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import usePage from 'hooks/page.hook';
import { translate } from 'utils/i18n';
import Term from 'components/term';
import { LiteralsContext } from 'containers/shared/literals';
import { mdiShieldAccount } from '@mdi/js';

const useStyles = makeStyles(() => ({
    centerText: {
        textAlign: 'center',
        margin: '0 2rem',
    },
    centerContent: {
        textAlign: 'center',
        width: 'calc(100% - 300px)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },

    root: {
        margin: '0 1rem 1rem',
        flex: 1,
    },

    card: {
        maxWidth: '350px'
    },
}));

type Props = RouteComponentProps;

const ConfirmarMail: React.FC<Props> = ({ location, history }) => {
    const terms = useContext(LiteralsContext);
    const ioc = useContext(IoC);
    const [, pageDispatcher] = usePage();
    const perfilG = useMemo(() => ioc.get(SujetosGateway) as SujetosGateway, [ioc]);
    const classes = useStyles();
    // Query string values
    const values = queryString.parse(location.search);
    const token: string = values.token as string;
    const tipo: string = values.tipo as string;

    // States
    const [ok, setOk] = useState(true);
    const [loading, setLoading] = useState(false);
    const [mailSended, setMailSended] = useState(false);
    const [email,] = useState('');
    const [validationErrorMessageKey,setValidationErrorMessageKey] = useState('');
    const [alreadyChecked, setAlreadyChecked] = useState(false);

    const redirectToProfile = useCallback(() => {
        history.push('/perfil');
    }, [history]);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiShieldAccount,
                title: <Term text="Area Personal" />,
            },
            menu: true,
        });
    }, [pageDispatcher, perfilG, terms]);

    const getSujeto = async () => {
        setLoading(true);
        try {
            return await perfilG.getDatosSujeto();
        }
        catch (e) {
            return null;
        }
        finally {
            setLoading(false);
        }
    }

    const sendEmail = async () => {
        setLoading(true);
        try {
            let result: boolean = false;
            if (tipo === "2") {
                const sujeto = await getSujeto();
                if (sujeto) {
                    result = await perfilG.sendDirectionEmail(sujeto);
                }
            } else {
                result = await perfilG.sendEmail(email);
            }

            if (result) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Perfil', 'El nuevo email se ha enviado correctamente', terms),
                        variant: 'success',
                    }
                });
                setMailSended(true);
            } else {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Perfil', 'Error envio correo electrónico', terms),
                        variant: 'error',
                    }
                });
            }
        } catch (e) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (e as any).message,
                    variant: 'error',
                }
            });
            setMailSended(false);
        } finally {
            setLoading(false);
        }
    };

    const validateEmail = async () => {
        if (await perfilG.validateEmail(token)) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: translate('Perfil', 'El email se ha verificado correctamente', terms),
                    variant: 'success',
                }
            });
            setTimeout(() => redirectToProfile(), 3000);
        } else {
            setOk(false);
        }
    };

    const validateDirection = async () => {
        const validation = await perfilG.validateDirectionEmail(token);
        if (validation.result) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: translate('Perfil', 'El email se ha verificado correctamente', terms),
                    variant: 'success',
                }
            });
            setTimeout(() => redirectToProfile(), 3000);
        } else {
            setValidationErrorMessageKey(validation.errorCode);
            setOk(false);
        }
    };

    useEffect(() => {
        const loadPage = async () => {
            setLoading(true);
            try {
                if(!alreadyChecked){
                    if (tipo === "2") {
                        await validateDirection();
                    } else {
                        await validateEmail();
                    }
                    setAlreadyChecked(true)
                }
            } catch (e) {
                setOk(false);
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            } finally {
                setLoading(false);
            }
        }

        loadPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classes.centerText + ' ' + classes.root}>

            {
                loading ?
                    <div className={classes.centerContent}>
                        <CircularProgress size={35} />
                    </div>
                    :
                    <div className={classes.centerContent}>
                        <Card className={classes.card}>
                            <CardContent>
                                <div>
                                    {
                                        ok ?
                                            <div>
                                                <h2> <Term component="Perfil" text="Su correo se ha verificado correctamente. Se le va a redireccionar a su perfil." /> </h2>
                                                <Button variant="outlined" color="primary" onClick={redirectToProfile}>
                                                    <Term component="Perfil" text={'Ir a perfil'} />
                                                </Button>
                                                <br />
                                                <small> <Term component="Perfil" text="En caso de que no se redireccione, pulse en el boton" /> </small>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    !mailSended ?
                                                        <div>
                                                            <h2><Term component="Perfil" text={validationErrorMessageKey} />  </h2>
                                                            <Button variant="outlined" color="primary" onClick={sendEmail}>
                                                                <Term component="Perfil" text={'Volver a enviar'} />
                                                            </Button>
                                                            <br />
                                                            <small><Term component="Perfil" text="Se le enviara un nuevo correo de verificación" /></small>
                                                        </div>
                                                        :
                                                        <h2> <Term component="Perfil" text="Le hemos enviado un nuevo correo a su dirección de correo electrónico" /> </h2>
                                                }
                                            </div>
                                    }
                                </div>
                            </CardContent>
                        </Card>
                    </div>
            }

        </div>
    );
}

export default withRouter(ConfirmarMail);