import React, { FC, useEffect, useState } from "react";
import { mdiBell } from "@mdi/js";
import Term from "components/term";
import usePage from "hooks/page.hook";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, Grid, makeStyles } from "@material-ui/core";
import BoxMenuItem from "components/box-menu-item";
import { TMenuItem } from "containers/shared/menu-item-types";

import AltaUsuarioIcon from '../../resources/home/notificaciones/AltaUsuario.png'
import DiasCortesiaIcon from '../../resources/home/notificaciones/DiasCortesia.png'
import MisNotificaIcon from '../../resources/home/notificaciones/MisNotifica.png'
import MasInfoIcon from '../../resources/home/notificaciones/info.png'
import styles from "containers/shared/container-home";
import ModalAcceso from "components/modal-acceso";



interface IRouteParams {}
type Props = RouteComponentProps<IRouteParams>;
const useStyles = makeStyles(styles);

const NotificacionesHome: FC<Props> = ({match, history}) => {

    const classes= useStyles()
    const [showLogin, setShowLogin] = useState(false);
    const [pageState, pageDispatcher] = usePage();
    const isMobile = window.innerWidth <= 590;

    const MENU_ITEMS: TMenuItem[] = [
        { title: {component: "Global", text:"Mis notificaciones"}, img: MisNotificaIcon, children : [
            { title: {component: "Global", text:"mis_notificaciones_descripcion"}, link: 'perfil/notificaciones/mis-notificaciones', protected: 2, idModulo: 6},
        ] },
        { title: {component: "Global", text:"Como darse de alta"}, img: AltaUsuarioIcon , children : [
            { title: {component: "Global", text:"como_darse_alta_descripcion"}, link: 'perfil/notificaciones/alta-servicio', protected: 0},
        ] },
        { title: {component: "Global", text:"Dias de cortesia"}, img: DiasCortesiaIcon , children : [
            { title: {component: "Global", text:"dias_cortesia_descripcion"}, link: 'perfil/notificaciones/dias-cortesia', protected: 2, idModulo: 7},
        ] },
        { title: {component: "Global", text:"Mas informacion"}, img: MasInfoIcon , children : [
            { title: {component: "Global", text:"mas_info_descripcion"}, link: 'perfil/notificaciones/contenidos', protected: 0},
        ] }
    ]

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiBell,
                title: <Term component="Global" text="Notificaciones" />,
                text:  <Term component="Global" text="Notificaciones Descripcion" />,
            },
            menu: true,
            footer: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    return(
        <>
        <Card className={classes.root}>
            <Grid container direction='row' justify="flex-start">
                {MENU_ITEMS.map( (menuitem, idx) => (
                    <Grid className={classes.widget} item xs={12} md={6} xl={4} key={`notificaciones-${idx}`}>
                        <BoxMenuItem 
                            item={menuitem} 
                            onLogin={(accessLevel) => {
                                if(isMobile){history.push('/acceso', { goBack: history.location.pathname }) } 
                                else {
                                    if(accessLevel === 2){history.push('/acceso/clave', { goBack: history.location.pathname } )}
                                    if(accessLevel === 1){setShowLogin(true)}
                                } 
                            }} 
                        />
                    </Grid>
                    
                ))}
            </Grid>
        </Card>
        <ModalAcceso openModalAcceso={showLogin} setOpenModalAcceso={setShowLogin} history={history} />
        </>
        
    )
}

export default withRouter(NotificacionesHome);

