import { FC,  useMemo } from "react";
import { withLiterals } from "containers/shared/literals";
import { useLocation } from "react-router";
import TypedDeclaracionResponsable, { DeclaracionResponsableProps } from "./componentes/typed-declaracion-responsable";
import useCommonStyles from "./styles";



const DeclaracionResponsable: FC<DeclaracionResponsableProps> = ({...props}) => { 
    const location = useLocation();
    const tipo = useMemo(() => ( location.pathname.includes('alta-solicitud') ? 'solicitud' : 'reintegro'),[location.pathname])
    return <TypedDeclaracionResponsable {...props} tipo={tipo}/>
        
}


export default withLiterals(['Global', 'Tramites','AbonoNacimiento' ])((DeclaracionResponsable))
