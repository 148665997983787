import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IBaseResponse } from "./base.response.interfaces";
import { IDatosConsulta, IDatosEmbargo } from "./embargos.interface";


@inject('axios')
export class EmbargosGateway {
    constructor(
        private axios: AxiosInstance,

    ) {

    }
   
    public async getEmbargos(datos: IDatosConsulta): Promise<IBaseResponse<IDatosEmbargo[]>> {
        const response = await this.axios.post('embargos/consultaEmbargo', datos);
        
        if (response.status !== 201) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    

    
}