import {FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import { Dialog, DialogTitle, Typography, DialogContent, Grid, DialogActions, withStyles, WithStyles, Button, ListItem, List } from "@material-ui/core";
import { translate } from "utils/i18n";
import duti_styles from "containers/DUTI/styles";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import { INotario } from "gateways/duti.interface";
import { validateNif } from "utils/validateNif";
import InputText from "./input-text";
import { TExtraValidation } from "./types";
import FullLoading from "../full-loading";
import InputSearch from "./input-search";
import { BLUE } from "containers/shared/colors";
import { CustomError } from "utils/custom.error";
import usePage from "hooks/page.hook";


interface IProps extends WithStyles {
    show: boolean,
    currentNotario: string|undefined,
    currentNifNotario: string|undefined,
    onSelected: (nifNotario: string, notario:string) => void,
    onClose: () => void,
    setShouldBlock: (v: boolean) => void,
}

const ModalNotarios: FC<IProps> = ({classes, show, currentNotario,currentNifNotario, onSelected, onClose, setShouldBlock}) => {
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext)
    const [, pageDispatcher] = usePage();
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const [loading, setLoading] = useState(false)
    const [nifNotario, setNifNotario]= useState<string|undefined>(undefined)
    const [nifNotarioError, setNifNotarioError]= useState(false)
    const [notario, setNotario]= useState<string|undefined>(undefined)
    const [notarioError, setNotarioError]= useState(false)
    const [search, setSearch]= useState<string | undefined>(undefined)
    const [list, setList] = useState<INotario[]>([])
    const [pendingSearch, setPendingSearch]= useState(false)
    const componentMounted = useRef(true)


    const validateNIF = (nif: string) => {
        const isValid = validateNif(nif)
        //console.log('validateNIF ', nif, isValid)
        setNifNotarioError(!isValid)
        const result: TExtraValidation={ 
            error: isValid ? false: true, 
            error_msg: translate('DUTI', 'nif_error_format',terms)
        }
        return result
    }

    const getNotarios = async (tosearch:string) => {
        setLoading(true);

        try {
            const notarios = (tosearch !== '') ? await dutiGateway.searchNotarios(tosearch): [];
            if(notarios.length === 1) {
                setNifNotario(notarios[0].nifNotario);
                setNotario(notarios[0].notario);
                setNotarioError(false);
                setNifNotarioError(false);
            }
            if(notarios.length === 0) {
                setNotario(undefined);
                setNifNotario(undefined);
            }
            setList(notarios);
            setPendingSearch(false);
        } catch (error) {
            if (error instanceof CustomError && error.code === 403) {
                setShouldBlock(false);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_dusti', terms),
                        variant: "error"
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        if(show){
            (async() => {
                //console.log('effect - current', currentNotario)
                if(currentNotario !== undefined){
                    await getNotarios(currentNotario)
                }
                if(componentMounted.current){
                    //setList(data)
                    currentNifNotario === undefined ? setNifNotario(undefined): setNifNotario(currentNifNotario);
                    currentNotario === undefined ? setNotario(undefined): setNotario(currentNotario);
                    currentNotario === undefined && setList([]);
                    currentNotario === undefined && setPendingSearch(true);
                    setNifNotarioError(false);
                    setNotarioError(false);
                    setSearch(currentNotario ?? '') 
                }
            })();
            
        }
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }

    }, [currentNotario, show])  /*data*/

    return (   
        <Dialog open={show} fullWidth maxWidth='md'>
            <FullLoading loading={loading} animation={{type:'intricom',name:'DUSTI'}}/>
            <DialogTitle>
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    <Typography style={{fontSize: 32, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>{translate('DUTI', 'title_modal_notarios', terms)}</Typography>
                </div>
            </DialogTitle> 

            <DialogContent>
                <Grid container direction='column' spacing={1} style={{paddingRight: 20, paddingLeft: 20}} >
                <Typography >{translate('DUTI', 'text_modal_notarios', terms)}</Typography>
                    <div style={{marginTop: 10}}>
                        
                        <InputSearch
                            fieldTerm='search_notario'
                            value={search}
                            //required
                            forceUpperCase={true}
                            className={classes.input360}
                            onChangeValue={(v:string|undefined, err: boolean) => {
                                setSearch(v ?? '')
                                setPendingSearch(true)
                            }}                        
                            onSearch={() => getNotarios(search ?? "")}
                            extraValidation={(v: string) => {
                                return {error: v.length<3 , error_msg: translate('DUTI', 'error_lenght_notario',terms)} as TExtraValidation
                            }}
                        />

                    </div>
                    <div>
                        { list.length>0  ? 
                            <div style={{border:'solid', borderWidth: '1px', borderRadius: 5, borderColor: BLUE }}>
                                <Typography style={{ fontWeight: 400,paddingRight: 20, paddingLeft: 20, paddingTop: 5, paddingBottom: 5, backgroundColor:BLUE, color: '#fff'}}>
                                    {translate('DUTI','notariosList', terms).toUpperCase()}
                                </Typography>
                                <div style={{height: 400, overflow:'auto'}}>
                                    {/* {list.length > 0 
                                        ?  */}
                                        <List>
                                            {list.map( not => {
                                                return(
                                                    <ListItem key={not.nifNotario}
                                                        button
                                                        selected={nifNotario === not.nifNotario}
                                                        onClick={() => {
                                                            setNotario(not.notario.toUpperCase())
                                                            setNifNotario(not.nifNotario.toUpperCase())
                                                        }}
                                                        divider
                                                    >
                                                        <div style={{display: 'flex', flexDirection:'row', paddingRight: 20,paddingLeft: 20}}>
                                                            <Typography>{not.notario.toUpperCase()}</Typography>
                                                            {/* <Typography style={{marginLeft: 20}}>{not.nifNotario}</Typography> */}
                                                        </div>

                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    {/* : <Typography style={{fontStyle: 'italic'}}>{translate('DUTI', 'notario_not_found',terms)}</Typography>
                                    } */}
                                </div>
                            </div>
                            :
                            
                            <div style={{height: 400}}>
                                {search !== undefined && !pendingSearch ?
                                <>
                                    <Typography style={{fontStyle: 'italic', paddingBottom: 30}}>{translate('DUTI', 'notario_not_found',terms)}</Typography>
                                    <Typography style={{fontWeight: 450, marginBottom: 5}}>{translate('DUTI', 'modal_insertar_datos_notario', terms)}</Typography>
                                    <Grid container direction='row' spacing={1} alignItems="flex-start">
                                        <InputText 
                                            fieldTerm='notario'
                                            value={notario}
                                            onChangeValue={(v:string|undefined, err: boolean) => {
                                                setNotario(v)
                                                setNotarioError(err)
                                            }}
                                            forceUpperCase={true}
                                            required
                                            error={notarioError}
                                            className={classes.input360}
                                        />
                                
                                        <InputText 
                                            fieldTerm='nifNotario'
                                            value={nifNotario}
                                            onChangeValue={(v:string|undefined, err: boolean) => {
                                                setNifNotario(v)
                                                setNifNotarioError(err)
                                            }}
                                            required
                                            forceUpperCase={true}
                                            error={nifNotarioError}
                                            extraValidation={validateNIF}
                                        />
                                    
                                    </Grid>
                                </>
                                : null}
                            </div> 
                                
                            
                    
                        }
                       
                    
                </div>
                

            </Grid>
               
            </DialogContent>
           
            <DialogActions>
                <div style={{display: 'flex',justifyContent:'flex-end', paddingRight: 10, paddingBottom: 10}}>
                    <Button style={{marginRight: 15}}
                            variant="contained" color="inherit"
                            onClick={onClose} 
                            disabled={loading}
                        >
                        {translate('DUTI', 'btn_close',terms)}
                    </Button>
                    <Button
                        variant="contained" color="primary"
                        onClick={() =>  onSelected(nifNotario ?? '', notario?.toUpperCase() ?? '') }
                        disabled={nifNotario === undefined || nifNotarioError || notario === undefined || notarioError}
                    >
                        {translate('DUTI', 'btn_continuar',terms)}
                    </Button>
                </div>
            </DialogActions>
        
        </Dialog> 
    )   

}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(ModalNotarios));