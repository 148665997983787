import { IconButton, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import { CSSProperties, FC, useCallback } from "react";
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
interface IProps {
  amount: number;
  style?: CSSProperties;
  selected: number;
  setSelected: (num: number) => void;
  arrows?: boolean;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
  },
});

const CarrouselButtons: FC<IProps> = ({
  amount,
  style,
  selected,
  setSelected,
  arrows,
}: IProps) => {
  const classes = useStyles();

  const handleArrows = useCallback(
    (amountArrows) => {
      if (!(0 > selected + amountArrows || amount <= selected + amountArrows))
        setSelected(selected + amountArrows);
    },
    [selected, amount, setSelected]
  );

  return (
    <div className={classes.root} style={style}>
      {arrows && (
        <IconButton style={{ width: 50 }} onClick={() => handleArrows(-1)}>
          <Icon path={mdiChevronLeft}></Icon>
        </IconButton>
      )}
      <RadioGroup row>
        {[...Array(amount)].map((x, index) => {
          return (
            <Radio
              value={index}
              onChange={(evt) => setSelected(parseInt(evt.target.value))}
              checked={selected === index}
              color="primary"
              size="small"
            />
          );
        })}
      </RadioGroup>
      {arrows && (
        <IconButton style={{ width: 50 }} onClick={() => handleArrows(1)}>
          <Icon path={mdiChevronRight}></Icon>
        </IconButton>
      )}
    </div>
  );
};

export default CarrouselButtons;
