import React, { FC } from "react";
import { Select, WithStyles, withStyles, MenuItem, ListItemText, Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 450,
        },
    },
};

const styles = (theme: Theme): StyleRules => ({
    root: {
        flexGrow: 1,
    }
});

interface IProps extends WithStyles<typeof styles> {
    className?: string;
    options: Array<{ value: any, label: string }>;
    value: any[];
    onChange: (value: any[]) => void;
}

const CustomSelect: FC<IProps> = ({ className, value, onChange, classes, options }) => {

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (typeof onChange === 'function') {
            const values = event.target.value as any[];
            onChange(values);
        }
    };

    return (
        <Select
            className={className}
            value={value}
            autoWidth={true}
            onChange={handleChange}
            MenuProps={MenuProps}
        >
            {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    <ListItemText primary={option.label} />
                </MenuItem>
            ))}
        </Select>
    );
}

export default withStyles(styles)(CustomSelect);