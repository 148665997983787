import React from "react"
import imageLogo from '../../resources/logo.png';
import { translate } from "utils/i18n"
import { Paper, Grid, Typography } from "@material-ui/core"
import { BLUE_BORRADOR } from "containers/shared/colors"
import { renderApartado } from "./shared"
import HeaderJustificanteBorrador from "components/DUTI/header-justificante-borrador";



type TValuesMxx =  {term: string, value: any, casilla: number, fullWidth?:boolean }[]
export type TBorradorModelo600 = Record<'M600_A'|'M600_B'|'M600_C', TValuesMxx> 
    & { 
        M600_D: {
        docPublico: TValuesMxx,
        docPrivado:TValuesMxx,
        operacion: TValuesMxx,
        operacionContinuacion: TValuesMxx,
        }
    } & {
        M600_E: {
        liqComplementaria: TValuesMxx,
        liquidacion: TValuesMxx,
        }
    } & Record<'M600_ANEXOS1' | 'M600_ANEXOS2', TValuesMxx[] | null>;


export const renderM600 = (
    M600:  TBorradorModelo600, 
    terms:any, 
    componentTerms: string 
) => {
    const modelo= '600';
    return (
        <Paper elevation={5} style={{margin: 20}}>
            <Grid container direction='column'  style={{padding: 20}}>
                {/* <Grid item container direction='row' alignItems='center'>
                    <Grid item xs={2} style={{paddingLeft: 20}} > {/**Logo atib /}
                        <img width={180} height={'auto'} src={imageLogo} alt='logo' />
                    </Grid>
                    <Grid item xs={2}>{/**Tipo modelo /}
                        <Typography style={{fontSize: 20, alignSelf: 'center'}}>{translate(componentTerms,'borr_modelo',terms)} {modelo}</Typography>
                    </Grid>
                    <Grid item xs={8} container direction="column" >{/**Tipo modelo descripcion/}
                    { translate(componentTerms,`borr_M${modelo}_title`,terms).split('/').map( val => (
                        val !== '/' && 
                        <Typography style={{fontSize: 19, marginLeft: 30, fontWeight: 'bolder'}}>{val}</Typography>)
                    )}
                    </Grid>
                </Grid> */}
                <HeaderJustificanteBorrador
                    componentTerms={componentTerms}
                    docTypeTerm="borr_justif_dusti"
                    terms={terms}
                    titleTerm={`borr_M${modelo}_title`}
                />

                <Grid item container direction='column'>
                    {modelo === '600' && M600 && 
                        (Object.keys(M600) as (keyof typeof M600)[]).map( (apartado) => {  
                            return(
                                <Grid item>
                                { M600[apartado] !== null &&
                                        <Typography style={{backgroundColor: BLUE_BORRADOR, color: 'white',marginTop: 10, padding: '2px 5px 2px 5px',display:'flex', flex:1}}>
                                            {translate(componentTerms,`${apartado}_title`,terms).toUpperCase()}
                                        </Typography>
                                    }
                                    { ["M600_0","M600_A", "M600_B", "M600_C"].includes(apartado) 
                                        && M600[apartado] !== null && 
                                        
                                        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                            {renderApartado(M600[apartado] as {term: string, value:any, casilla: number}[], modelo, terms, componentTerms)}
                                        </div>
                                    }
                                    {apartado === 'M600_D' && 
                                        <Grid container spacing={1}>
                                            <Grid item xs={5} >
                                                <div style={{backgroundColor: '#e0eaf4',borderRadius: 5, padding: ' 10px 5px 5px 10px', marginTop: 5}}>
                                                    <Typography>{translate(componentTerms, 'docPublico_title', terms)}</Typography>
                                                    <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                        { renderApartado(M600.M600_D.docPublico, modelo, terms, componentTerms)}
                                                        </div>
                                                </div>
                                                <div style={{backgroundColor: '#e0eaf4',borderRadius: 5, padding: 10, marginTop: 5}}>
                                                    <Typography>{translate(componentTerms, 'docPrivado_title', terms)}</Typography>
                                                    <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}> 
                                                        { renderApartado(M600.M600_D.docPrivado, modelo, terms, componentTerms)}
                                                    </div> 
                                                </div>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                { renderApartado(M600.M600_D.operacion, modelo, terms, componentTerms)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                { renderApartado(M600.M600_D.operacionContinuacion, modelo, terms, componentTerms)}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    }
                                    {apartado === 'M600_E' && 
                                        <Grid container spacing={1}>
                                            <Grid item xs={5} >
                                                <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                    { renderApartado(M600.M600_E.liqComplementaria, modelo, terms, componentTerms)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                    { renderApartado(M600.M600_E.liquidacion, modelo, terms, componentTerms)}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    }
                                    {["M600_ANEXOS1", "M600_ANEXOS2"].includes(apartado) && M600[apartado] !== null && 
                                    <Grid container direction='column'>
                                        {( M600[apartado] as { term: string; value: any; casilla: number;}[][]).map( (item,idx )=>( 
                                            <Grid item >
                                                <div key={apartado+idx} 
                                                    style={{ 
                                                        display:'flex', flexDirection:'row', flexWrap:'wrap',
                                                        borderColor: '#e0eaf4',borderBottomStyle:'solid'
                                                    }}
                                                >
                                                    {renderApartado(item, modelo, terms, componentTerms)}
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    }
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Paper>
)
}