import React, { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent, Tooltip} from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import styles from './styles';
import { formatOptions, formatXMLtoObject, GenericInitialObject, getEmptyObject, getKeysRequiredCampos, Option, renderCampos, SelectoresInfo, sujetoFormatter } from '../utils';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { ISujeto } from 'gateways/perfil.interfaces';
import { GeoGateway } from 'gateways/geo.gateway';
import { Municipio, Provincia, Sigla} from 'gateways/geo.interface';

const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXml: string | null,
    loggedSujeto: ISujeto | null,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
}

const SujetoLogueado: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        loggedSujeto,
        datosXml,
        setFinished,
        handleSave
    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes, setChanges] = useState(false);
    const [campos, setCampos] = useState<ICampo[] | null>(null);
    const [EMPTY_SUJETO,setEmptySujeto] = useState<GenericInitialObject| null>({});
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    const [selectoresInfo, setSelectoresInfo] = useState<SelectoresInfo| null>(null);
    const [initiliazed, setInitialized] = useState<boolean>(false);
    
    const [provincia, setProvincia]=useState<string| null>(null) 
    //const [municipio,setMunicipio]= useState<string| null>(null) 

    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData = ( name: string, value: any) => {
        setChanges(true)
        let newData=null;
        if(EMPTY_SUJETO){
            newData= data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY_SUJETO,[name]: {...EMPTY_SUJETO[name], value: value }}

            switch (name) {
                case 'provincia':     
                    setProvincia(value)
                    newData = {
                        ...newData,
                        locaMuni: {...newData['locaMuni'], value: undefined }
                    }
                    break;
                case 'locaMuni':
                    break;
                default:
                    break;
            }

        }
        setData(newData)
    }

    const onChargeSujeto = () => {
        if(loggedSujeto){
            const provincia= selectoresInfo?.provincia?.find(p => p.id === loggedSujeto.direccionNotificacion.provincia)
            const municipio= selectoresInfo?.municipio?.find(p => p.id === loggedSujeto.direccionNotificacion.codigoMunicipio)
            const mySujeto = {
                ...loggedSujeto,
                direccionNotificacion: {
                    ...loggedSujeto.direccionNotificacion,
                    provincia: provincia?.nombre || '',
                    codigoMunicipio:municipio?.nombre || ''
                }
            }
            const newData = sujetoFormatter(data ? data : {}, mySujeto);
            //console.log('newData', newData);
            setData(s=>({...newData}))
        }
    }

    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            if(camposRequiredKeys && camposRequiredKeys.length>0 ){
                if(data){
                    camposRequiredKeys.forEach( element => {
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal' || 'decimal2'|| 'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                }
            }
            if(valid){
                const saved = await handleSave(data); 
                //setFinished(saved);
                setChanges(false);
            } else {
                //setFinished(false);
                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos','FormularioError', terms),
                        variant: 'error',
                    }
                });

            }
            setLoading(false);

        })();
    }
    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                setCampos(camposApartado);
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                if(camposApartado && camposApartado?.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEmptySujeto(emptyObjt);
                    const keys = getKeysRequiredCampos(camposApartado);
                    setCamposRequiredKeys(keys)
                    if(datosXml){
                        //console.log('USE datosXML')
                        newData = formatXMLtoObject(datosXml,emptyObjt);
                        //setChanges(false);
                        //setFinished(true); 
                    } else{
                        setChanges(true); 
                        newData = emptyObjt
                    }
                }
                setData(newData);

                //Empty Selectores data 
                const optionsProv = await geoGateway.getProvincias('')
                const optionsTV = await geoGateway.getSiglas()
                const selected: Provincia|null|undefined = optionsProv && newData?.provincia?.value 
                    ? optionsProv.find(opt => (opt.nombre === newData?.provincia?.value.toString() || opt.idProvincia === newData?.provincia?.value.toString())) 
                    : null
                const optionsMuni = selected && selected.idProvincia ? await geoGateway.getMunicipios(selected.idProvincia): null    
                setSelectoresInfo({
                    municipio: optionsMuni && optionsMuni.length>0 ? formatOptions<Municipio>('idMunicipio','nombre',optionsMuni) : null,
                    provincia: optionsProv.length>0 ? formatOptions<Provincia>('idProvincia','nombre', optionsProv) : null,
                    tipoVia: optionsTV.length>0 ? formatOptions<Sigla>('idSiglas','nombre', optionsTV) : null,
                })
                if(selected){
                    setProvincia(selected.nombre)
                }
                setInitialized(true);
                setLoading(false);

            } catch (error) {
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            }
        })();
    },[idTipoApartado, idTipoModelo,loggedSujeto, datosXml]);

    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
    },[changes])

    useEffect(()=>{
        /**Buscar municipios de la provincia seleccionada */
        (async()=>{
            //console.log('provincia',provincia)
            let newOptions
            if(!provincia){
                newOptions = selectoresInfo ? {...selectoresInfo, municipio: null}: {municipio: null, provincia: null, tipoVia: null}
            } else{
                const selected : Option | null | undefined = selectoresInfo &&  selectoresInfo.provincia 
                    ? selectoresInfo.provincia?.find(opt => (opt.nombre === provincia || opt.id === provincia)) 
                    : null
                //console.log('selected',selected)
                const optionsMuni = await geoGateway.getMunicipios(selected ? selected.id : '')      
                //console.log('optionsMuni',optionsMuni)
                const muniProv: Option[] | null= optionsMuni.length>0 ? formatOptions<Municipio>('idMunicipio','nombre', optionsMuni) : null
                newOptions = selectoresInfo ? {...selectoresInfo, municipio: muniProv}: {municipio: null, provincia: null, tipoVia: null}
            }
            setSelectoresInfo(newOptions)
        })();
    },[provincia])

    useEffect(() => {
        onChargeSujeto();
    },[initiliazed]);
    
    return(
        <div>
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
            {campos && campos.length>0 && camposRequiredKeys !== null && data && selectoresInfo &&
                renderCampos(campos, data, updateData, '', idTipoApartado, selectoresInfo, undefined, classes )
            }

            <div className={classes.rowAlignRight}>
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    disabled={!changes}
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnSave',terms)} 
                </Button>
            </div>
        </div>
    )
}
export default withLiterals(['Tributos'])(SujetoLogueado);


