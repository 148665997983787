import React, { useEffect, useState, useContext } from 'react';
import { WithStyles, withStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';
import usePage from 'hooks/page.hook';
import { mdiShieldPlus } from '@mdi/js';
import Term from 'components/term';
import SeleccionTramite from './cajas/seleccionTramite';
import ExpedienteCard from './expediente-card';
import IoC from 'contexts/ioc.context';
import { TramitesGateway } from 'gateways/tramites.gateway';
import { ITramite } from 'gateways/tramites.interfaces';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import { RouteComponentProps, useParams } from 'react-router';
import { AlertsContext } from 'contexts/alerts.context';

const styles = (theme: Theme): StyleRules => ({
    root: {
        flex: 1,
        flexGrow: 1,
    },
    marginAlta: {
        margin: '0 1rem',
    },
});

type Props = WithStyles<typeof styles> & RouteComponentProps;

const Presentacion: React.FC<Props> = ({ classes, history }) => {
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const { idGrupo } = useParams<{ idGrupo: string }>();
    const [tramite, setTramite] = useState<number>(-1);
    const [tramites, setTramites] = useState<ITramite[]>([]);
    const terms = useContext(LiteralsContext);
    const ioc = useContext(IoC);
    const gateway: TramitesGateway = ioc.get(TramitesGateway);

    const handleChangeTramites = (seleccionado: number) => {
        setTramite(seleccionado);
    }

    const handleSubmit = async () => {
        try {
            const response = await gateway.crearExpediente(tramite);
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: translate('Tramites', 'Se ha creado el expediente', terms),
                    variant: 'success',
                }
            });
            history.push('/tributos/presentaciones/expediente/' + response.idExpediente);
        } catch (e) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Tramites', (e as any).message, terms),
                    variant: 'error',
                }
            });
        }
    }

    useEffect(() => {
        const loadTramites = (async () => {
            const tramitesAux = await gateway.getTramites(parseInt(idGrupo, 10));
            setTramites(tramitesAux);
        })

        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiShieldPlus,
                title: <Term component="Tramites" text="Presentación de expedientes" />
            },
            menu: true,
        });

        loadTramites();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    return (
        <>
            <div className={classes.root}>
                <div className={classes.marginAlta}>
                    <ExpedienteCard step={1} title="Seleccionar tramite" moreInfo="titulo_seleccionar_tramite">
                        <SeleccionTramite onChange={handleChangeTramites} tramites={tramites} tramite={tramite} onSubmit={handleSubmit} />
                    </ExpedienteCard>
                </div>
            </div>
        </>
    );
}

export default withStyles(styles)(Presentacion);