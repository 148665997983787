import { Reducer } from "react";
import { IFilterOption } from "utils/interfaces";

export interface IFiltersState {
    conceptos?: number[];
    ejercicios?: number[];
    situaciones?: string[];
    organismos?: string[];
    modelos?: string[];
    objTributario?: string;
    dirTributaria?: string;
    fPago?: string;
    domiciliados?: boolean;
    domiciliadosPC?: boolean;
    estados?: Array<IFilterOption<number>>;
    tipos?: Array<IFilterOption<number>>;
    meses?: Array<IFilterOption<number>>;
    refExpediente?: string
}

export type QueryRequest = IFiltersState & {
    pendiente: boolean;
    skip?: number;
    take?: number;
}

export type FiltersAction =
    | { type: 'update-any', filters: Partial<IFiltersState> }
    | { type: 'update', filter: keyof IFiltersState, value: any }
    | { type: 'clear' }

const FiltersReducer: Reducer<IFiltersState, FiltersAction> = (state, action) => {
    const newState = { ...state };

    switch (action.type) {
        case 'update-any':
            return { ...state, ...action.filters };
        case 'update':
            newState[action.filter] = action.value;
            return newState;
        case 'clear':
            for (const prop in newState) {
                if (prop in newState) {
                    (newState as any)[prop] = undefined;
                }
            }
            return newState;
        default: throw new Error('Unexpected action');
    }
};

export default FiltersReducer;