import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { createValidationError } from "utils/response";
import { IRequestConsulta, IRequestImpresoSolicitud } from "./solicitudes.interfaces";

@inject('axios')
export class SolicitudesGateway {

    constructor(
        private axios: AxiosInstance
    ) { }

    public async envioSmsSolicitud(phone: string): Promise<boolean> {
        try {
            const response = await this.axios.post(
                'solicitudes/enviar-sms-solicitud', {
                phone
            }
            );
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async imprimir(request: IRequestImpresoSolicitud): Promise<Blob> {
        const response = await this.axios.get('solicitudes/justificante-solicitud-codigo/' + request.idSolicitud + '/' + request.nif, {
            responseType: 'blob',
        });

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async existeSolicitudAprobada(nif: string, email: string): Promise<boolean> {
        try {
            const response = await this.axios.get(`solicitudes/existeSolicitudAprobada/${nif}/${email}`);
            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }
            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

    public async enviarConsulta(data:IRequestConsulta): Promise<boolean> {
        try {
            const response = await this.axios.post(`solicitudes/consultas-sugerencias/`, data);
            if (response.status !== 201 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }
            return response.data;
        } catch (error) {
            throw createValidationError(error as any);
        }
    }

}
