import { Button, Card, CardContent, withStyles, WithStyles } from "@material-ui/core";
import { Theme, StyleRules } from "@material-ui/core/styles";
import { FC } from "react";
import Term from "./term";

const styles = (theme: Theme): StyleRules => ({
    [theme.breakpoints.up('md')]: {
        buttonPagarTodos: {
            display: 'block',
            float: 'right',
            marginLeft: 10,
            marginBottom: '1em'
        }
    },
    cardContador: {
        marginTop: '1.5em',
        marginBottom: '0.5rem',
        backgroundColor: 'rgba(255,255,255,0.6)'
    },
    cardContadorContainer:{
        verticalAlign: 'middle'
    },
    titleCardContador: {
        fontWeight: 300,
        fontSize: 16,
        marginTop: '1em',
        marginLeft: '1rem',
        color: '#406a8d',
        flex: 1
    },
});

export interface IProps extends WithStyles<typeof styles> {
    showShowPendientesOption: boolean;
    showShowAllOption: boolean;
    showSelectOption: boolean;
    showUnSelectOption: boolean;
    showPrintJustificante: boolean;
    customTitle: any;
    onShowOnlyPendientes: () => void;
    onShowAll: () => void;
    onSelect: () => void;
    onUnSelect: () => void;
    onPrintJustificante: () => void
}

const ListContador: FC<IProps> = ({classes, showShowPendientesOption, showShowAllOption, showSelectOption, showUnSelectOption, showPrintJustificante, customTitle, onShowOnlyPendientes, onShowAll, onSelect, onUnSelect, onPrintJustificante}) => {
    return (
        <Card className={classes.cardContador}>
            <CardContent className={classes.cardContadorContainer} >
                {
                    !showPrintJustificante ?
                        <span className={classes.titleCardContador}>
                            {customTitle}
                        </span>
                    : null
                }
                {
                    showSelectOption ?
                        <Button variant="contained" color="primary" size="small" className={classes.buttonPagarTodos} onClick={onSelect}>
                            <Term component="Global" text="SeleccionarPendientes" />
                        </Button>
                    : null
                }
                {
                    showUnSelectOption ?
                        <Button variant="contained" color="primary" size="small" className={classes.buttonPagarTodos} onClick={onUnSelect}>
                            <Term component="Global" text="Deseleccionar" />
                        </Button>
                    : null
                }
                {
                    showShowPendientesOption ?
                        <Button variant="contained" color="primary" size="small" className={classes.buttonPagarTodos} onClick={onShowOnlyPendientes}>
                            <Term component="Global" text="VerSoloPendientes" />
                        </Button>
                    : null
                }
                {
                    showShowAllOption ?
                        <Button variant="contained" color="primary" size="small" className={classes.buttonPagarTodos} onClick={onShowAll}>
                            <Term component="Global" text="VerTodos" />
                        </Button>
                    : null
                }
                {
                    showPrintJustificante ? 
                        <Button variant="contained" color="primary" size="small" className={classes.buttonPagarTodos} onClick={onPrintJustificante}>
                            <Term component="Global" text="ImprimirJustificante" />
                        </Button>
                    : null
                }
            </CardContent>
        </Card>
    )
}

export default withStyles(styles)(ListContador);