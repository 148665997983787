// LIBRARY IMPORTS
import { FC, useContext, useState, useMemo, useCallback } from "react";
import { Card, CardContent, DialogContentText, Grid, makeStyles } from "@material-ui/core";
// LOCAL IMPORTS
import { IDatosIban, ITributo } from "gateways/tributos.interfaces";
import { FilterDataContext } from "containers/cartero-virtual/filter.data.context";
import { ScreenType } from "containers/cartero-virtual/filters.form";
import { GenericModal } from "components/generic-modal";
import { DomiciliacionesSelectionContext } from "contexts/domiciliaciones-selection.context";
import { DomiciliacionGrid } from "../../domiciliacion-grid/domiciliacion-grid";
import { DialogDomiciliacionDetail } from "../../dialogs/dialog-domiciliacion-detail/domiciliacion-detail";
import { DialogDomiciliacionGestion } from "../../dialogs/dialog-domiciliacion-gestion";
import usePage from "hooks/page.hook";
import useLoadContentHook from "hooks/load-content.hook";
// STYLES IMPORTS
import styles from '../../../../shared/tributos.styles';
import IoC from "contexts/ioc.context";
import { TributosGateway } from "gateways/tributos.gateway";
import moment from "moment";



const useStyles = makeStyles(styles);

interface ITributoCardProps {
    tributo: ITributo;
    selectable: boolean;
    visibleButton: boolean;
    screen: ScreenType;
    isConfirmacionScreen: boolean;
    multiplesRegistrosSeleccionados?: boolean;
    domiciliables?: boolean;
    onlyPagoCarta?: boolean;
    onlyDomiciliados?: boolean;

    handleFechaCargo: (tributo: ITributo, domiciliacion: IDatosIban | null) => void;
    altaPagoCarta?: (tributo: ITributo) => void;
    altaDomiciliacion?: (tributo: ITributo) => void;
    onModificarPagoCuenta?: (tributo: ITributo) => void;
}

const TributoCard: FC<ITributoCardProps> = (props) => {
    const { tributo, selectable, visibleButton, screen, altaPagoCarta, multiplesRegistrosSeleccionados, domiciliables, onlyPagoCarta, onlyDomiciliados, handleFechaCargo, altaDomiciliacion, onModificarPagoCuenta } = props;
    // HOOKS
    const [state] = usePage();
    const ioc = useContext(IoC);
    // GATEWAYS
    const tributosGateway = useMemo(() => ioc.get(TributosGateway) as TributosGateway, [ioc]);
    // CONSTANTS
    const classes = useStyles();
    // CONTEXTS and MEMOS 
    const [selection, selectionDispatch] = useContext(DomiciliacionesSelectionContext);
    const filterData = useContext(FilterDataContext);
    const selected = useMemo(() => selection.selected.findIndex((x: ITributo) => x.idRecibo === tributo.idRecibo) >= 0, [selection.selected, tributo.idRecibo]);
    // STATES
    const { title, body } = useLoadContentHook({ contentName: 'sede-pop-up_domis_periodo_cerrado', lang: state.lang });
    // BOOLEAN STATES
    const [openDetail, setOpenDetail] = useState(false);
    const [openGestion, setOpenGestion] = useState(false);
    const [isOpenModalFechaUltimoDiaDomi, setIsOpenModalFechaUltimoDiaDomi] = useState(false);


    const handleOpenDetail = useCallback(() => setOpenDetail(true), []);
    const handleCloseDetail = useCallback(() => setOpenDetail(false), []);

    const handleOpenGestion = useCallback(() => setOpenGestion(true), []);
    const handleCloseGestion = useCallback(() => setOpenGestion(false), []);

    const handleOpenCloseModalFechaUltimoDiaDomi = () => setIsOpenModalFechaUltimoDiaDomi(prev => !prev);

    const handleSelect = useCallback(() => selectionDispatch({ type: 'select', item: tributo }), [selectionDispatch, tributo]);
    const handleUnselect = useCallback(() => selectionDispatch({ type: 'unselect', item: tributo }), [selectionDispatch, tributo]);

    const handleModificarPagoCuenta = (tributo: ITributo) => {
        handleCloseGestion();
        onModificarPagoCuenta && onModificarPagoCuenta(tributo);
    };

    return (
        <>
            <Grid
                item
                className={[classes.mainButton, classes.recibo].join(' ')}
            >
                <Card>
                    <CardContent style={{ padding: 24 }}>
                        <DomiciliacionGrid
                            selectable={selectable}
                            visibleButton={visibleButton}
                            tributo={tributo}
                            filterData={filterData}
                            screen={screen}
                            selected={selected}
                            domiciliables={domiciliables}
                            multiplesRegistrosSeleccionados={multiplesRegistrosSeleccionados}
                            onlyDomiciliados={onlyDomiciliados}
                            onlyPagoCarta={onlyPagoCarta}
                            moreInfo={handleOpenDetail}
                            gestionDomiciliacion={handleOpenGestion}
                            onSelect={handleSelect}
                            onUnselect={handleUnselect}
                            pagoCarta={altaPagoCarta}
                        />
                    </CardContent>
                </Card>
            </Grid>

            <DialogDomiciliacionDetail
                open={openDetail}
                tributo={tributo}
                domiciliables={domiciliables}
                screen={screen}
                onClose={handleCloseDetail}
                onSelect={handleSelect}
                onUnselect={handleUnselect}
                onHandleFechaCargo={handleFechaCargo}
                onHandleAltaDomiciliacion={altaDomiciliacion}
            />

            <DialogDomiciliacionGestion
                open={openGestion}
                tributo={tributo}
                domiciliables={domiciliables}
                screen={screen}
                onClose={handleCloseGestion}
                onSelect={handleSelect}
                onUnselect={handleUnselect}
                onHandleFechaCargo={handleFechaCargo}
                onHandleAltaDomiciliacion={altaDomiciliacion} 
                onHandleAltaPagoCarta={altaPagoCarta}        
                onModificarPagoCuenta={handleModificarPagoCuenta}        
            />

            <GenericModal
                componentTitleTranslation={"Domiciliaciones"}
                title={title}
                isTitleHtml
                onClose={handleOpenCloseModalFechaUltimoDiaDomi}
                open={isOpenModalFechaUltimoDiaDomi}
            >
                <DialogContentText
                    dangerouslySetInnerHTML={{ __html: body }}
                />
            </GenericModal>
        </>
    );
}

export { TributoCard };