import { Button, ButtonBase, Card, CircularProgress, Grid, Typography, withStyles, WithStyles } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styles from './cita-previa.styles';
import { RouteComponentProps, withRouter } from 'react-router';
import usePage from 'hooks/page.hook';
import Term from 'components/term';
import IoC from 'contexts/ioc.context';
import CitaPreviaGateway from 'gateways/citaPrevia.gateway';
import { AlertsContext } from 'contexts/alerts.context';
import { IArgumentosCancelar, IArgumentosJustificante, IMisCitas } from 'gateways/citaPrevia.interface';
import { Icon } from '@mdi/react';
import { mdiCheck, mdiCalendarClock } from '@mdi/js';
import moment from 'moment';
import 'moment/locale/es';
import { download } from 'utils/download';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import 'moment/locale/ca';
import 'moment/locale/es';
import imageNoHayCita from '../../resources/NoHayCita.png';

type Props = WithStyles<typeof styles> & RouteComponentProps;

const MisCitas: React.FC<Props> = ({ classes, history }) => {
    const ioc = useContext(IoC);
    const citaPreviaGateway: CitaPreviaGateway = useMemo(() => ioc.get(CitaPreviaGateway), [ioc]);
    const [pageState, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const handleToggleSubMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpenSubMenuAnchorEl(event.currentTarget);
    }, []);
    const [citaSeleccionada, setCitaSeleccionada] = useState<IMisCitas | null>()
    const [loading, setLoading] = useState(false)
    const [misCitas, setMisCitas] = useState<IMisCitas[]>()
    const [visible, setVisible] = useState(false)
    const terms = useContext(LiteralsContext);

    const handleLocale = useCallback(() => {
        if (pageState !== null && pageState.jwp !== null) {
            moment.locale(pageState.jwp.idioma)
        }
    }, [pageState])

    useEffect(() => {
        const handleLoadMisCitas = async () => {
            const x = await citaPreviaGateway.getMisCitas()
            setMisCitas(x)
        }
        handleLoadMisCitas()
        handleLocale()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiCalendarClock,
                title: <Term component="CitaPrevia" text="Titulo Cita Previa" />,
                text: <Term component="CitaPrevia" text="Subtitulo cita previa" />
            },
            menu: true,
        });
    }, [pageDispatcher, handleToggleSubMenu]);
    const handleRedireccion = useCallback(() => {
        history.push('/cita-previa/cita');
    }, [history])
    const handleComparar = useCallback((cita: IMisCitas) => {
        if (citaSeleccionada !== null && citaSeleccionada !== undefined) {
            if (cita === citaSeleccionada) {
                return true
            }
            else {
                return false
            }
        }
    }, [citaSeleccionada])
    const handleSelect = useCallback((cita: IMisCitas) => {
        if (citaSeleccionada !== null && citaSeleccionada !== undefined) {
            if (cita.Number === citaSeleccionada.Number && cita.AppointmentTime === citaSeleccionada.AppointmentTime) {
                setCitaSeleccionada(null);
                setVisible(false);
            }
            else {
                setCitaSeleccionada(cita);
                setVisible(true);
            }
        }
        else {
            setCitaSeleccionada(cita);
            setVisible(true);
        }

    }, [citaSeleccionada])

    const handleCancelar = useCallback(async () => {
        setLoading(true)
        if (citaSeleccionada !== undefined && citaSeleccionada !== null && pageState.jwp !== null && pageState.jwp !== undefined) {
            const argumentosCancelar: IArgumentosCancelar = {
                codigoCita: citaSeleccionada.GroupCode + '-' + citaSeleccionada.Number,
                nif: pageState.jwp.nif,
                dia: citaSeleccionada.AppointmentTime,
                oficina: citaSeleccionada.OfficeCode,
            }
            try {
                const response = await citaPreviaGateway.cancelCita(argumentosCancelar)
                if (response.result) {
                    if (misCitas !== undefined) {
                        setMisCitas(misCitas.filter(x => x.GroupCode !== citaSeleccionada.GroupCode || x.Dni !== citaSeleccionada.Dni || x.OfficeCode !== citaSeleccionada.OfficeCode || x.AppointmentTime !== citaSeleccionada.AppointmentTime))
                        setVisible(false)
                        setLoading(false)
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: <Term component='citaPrevia' text='Cancelar exito' />,
                                variant: 'success',
                            }
                        });
                    }
                }
                else {
                    setVisible(false)
                    setLoading(false)
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: <Term component='citaPrevia' text='Ha ocurrido un error' />,
                            variant: 'error',
                        }
                    });
                }
            }
            catch (e) {
                throw e
            }
        }
    }, [citaSeleccionada, pageState.jwp, citaPreviaGateway, misCitas, alertsDispatch])

    const handleJustificante = useCallback(async () => {
        if (citaSeleccionada !== undefined && citaSeleccionada !== null && pageState.jwp !== null && pageState.jwp !== undefined) {
            setLoading(true)
            let response: Blob
            let justificanteForm: IArgumentosJustificante;
            justificanteForm = {
                AppointmentCode: citaSeleccionada.GroupCode + '-' + citaSeleccionada.Number,
                Dni: pageState.jwp.nif,
                OfficeCode: citaSeleccionada.OfficeCode,
                Date: citaSeleccionada.AppointmentTime,
            }
            try {
                response = await citaPreviaGateway.getJustificanteCitaBlob(justificanteForm)
                download(response, alertsDispatch, translate('CitaPrevia', 'Error', terms), 'Justificante')
                setLoading(false)
            }
            catch (e) {
                throw e
            }
        }
    }, [citaSeleccionada, pageState.jwp, citaPreviaGateway, alertsDispatch, terms])

    return (
        <>{<div className={classes.container}>
            <Card className={classes.noCitasCard}>
                <div style={{ flex: 1 }}>
                    <Typography className={classes.tipografia}>
                        {misCitas !== undefined && misCitas.length > 0 ? <Term component='citaPrevia' text='Proximas citas' /> : <Term component='citaPrevia' text='No hay citas previstas' />}

                    </Typography>
                </div>
                <div style={{ flex: 1 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.botonRedirec}
                        disabled={loading}
                        onClick={handleRedireccion}>
                        <Term component="CitaPrevia" text={"Pedir Cita"} />
                    </Button>
                </div>
            </Card>
            {
                misCitas !== undefined && misCitas.length > 0
                    ?

                    misCitas.map(currentElement =>
                        <Card key={currentElement.AppointmentTime + currentElement.Number} onClick={() => handleSelect(currentElement)} className={classes.cardProximasCitas} style={{ display: 'flex', }}>
                            <div className={classes.flexBox}>
                                <ButtonBase
                                    className={handleComparar(currentElement) ? classes.imagenSelected : classes.imagenContainer}>
                                    {handleComparar(currentElement)
                                        ? <Icon path={mdiCheck} title="Seleccionado" size={1} />
                                        : <Icon path={mdiCalendarClock} title="Seleccionado" size={'calc(50%)'} color='#FFFFFF' />}
                                </ButtonBase>
                            </div>
                            <div className={classes.flexControl}>
                                <div className={classes.flexBoxDirec}>
                                    <Typography style={{ fontWeight: 'bold' }}>
                                        {currentElement.OfficeName}
                                    </Typography>
                                    <Typography style={{ fontWeight: 'bold' }}>
                                        {currentElement.OfficeAddress}
                                    </Typography>
                                    <Typography>
                                        {moment(currentElement.AppointmentTime).format('LLLL')}
                                    </Typography>
                                </div>
                                <div className={classes.flexBoxInfo}>
                                    <Typography className={classes.tramite}>
                                        {currentElement.GroupCode + currentElement.Number}
                                    </Typography>
                                    <Typography className={classes.tramite}>
                                        {currentElement.ProcedureDescription}
                                    </Typography>
                                </div>
                            </div>
                        </Card>
                    )
                    :
                    <>
                        <img src={imageNoHayCita} alt="noCitas" className={classes.noCitasImagen} />
                    </>
            }
            <div className={classes.fixedBottom}>
                <Grid container className={[classes.rootProximasCitas, visible ? classes.display : ''].join(' ')}>
                    <Grid item xs={6} className={classes.payContainer}>
                        <div className={classes.wrapper} style={{ display: 'flex' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonDrawer}
                                disabled={loading}
                                onClick={handleCancelar}>
                                <Term component="CitaPrevia" text={"Cancelar cita"} />
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonDrawerSecondary}
                                disabled={loading}
                                onClick={handleJustificante}>
                                <Term component="CitaPrevia" text={"Justificante"} />
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} style={{ marginLeft: 10, marginTop: 7 }} />}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
        }
        </>
    )
}

export default withRouter(withStyles(styles)(MisCitas));