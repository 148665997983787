import { Reducer } from "react";

export interface IOrderState {
    orden?: number
}

export type OrderAction =
    | { type: 'update-any', order: Partial<IOrderState> }
    | { type: 'update', order: keyof IOrderState, value: any }
    | { type: 'clear' }

const OrderReducer: Reducer<IOrderState, OrderAction> = (state, action) => {
    const newState = { ...state };

    switch (action.type) {
        case 'update-any':
            return { ...state, ...action.order };
        case 'update':
            newState[action.order] = action.value;
            return newState;
        case 'clear':
            for (const prop in newState) {
                if (prop in newState) {
                    (newState as any)[prop] = undefined;
                }
            }
            return newState;
        default: throw new Error('Unexpected action');
    }
};

export default OrderReducer;