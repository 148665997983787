// LIBRARY IMPORTS
import { FC } from 'react';
import { Drawer, useTheme, makeStyles } from "@material-ui/core";
// LOCAL IMPORTS
import { ITributo } from 'gateways/tributos.interfaces';
import { TributoTable } from '../tributo-table/tributo.table';
// STYLES IMPORTS
import styles from './styles';

const useStyles = makeStyles(styles);

interface IProps {
    tributos: ITributo[];
}

const PanelTributos: FC<IProps> = ({ tributos }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Drawer
            classes={{
                paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor="right"
            open>
            <div className={classes.main}>
                <TributoTable tributos={tributos} />
            </div>
        </Drawer>
    )
};

export { PanelTributos };