import React, { useContext, FC, useMemo, useEffect, useState } from 'react';
import usePage from 'hooks/page.hook';
import Term from 'components/term';
import IoC from 'contexts/ioc.context';
import { TramitesGateway } from 'gateways/tramites.gateway';
import { CardContent, Card, Theme, CircularProgress } from '@material-ui/core';
import { StyleRules, WithStyles, withStyles } from '@material-ui/styles';
import { IJOCS, IJOCSERROR } from 'gateways/tramites.interfaces';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { mdiWalletTravel } from '@mdi/js';
import { withLiterals } from 'containers/shared/literals';

const styles = (theme: Theme): StyleRules => ({
    root: {
        margin: '0 0.5rem',
        flex: 1,
    },
    formControl: {
        marginBottom: 10,
        display: 'block',
        '& > div': {
            width: '100%',
        },
        '& > button': {
            width: '100%',
        }
    },
    button: {
        padding: '0px 40px'
    },
    cardContador: {
        marginBottom: '0.5rem',
        backgroundColor: 'rgba(255,255,255,0.6)',
    },
    centerContent: {
        textAlign: 'center',
        padding: 30,
    },
    cardContadorContainer: {
        padding: '13px !important',
        display: 'flex',
        alignItems: 'center',
    },
    titleCardContador: {
        fontWeight: 300,
        fontSize: 16,
        marginLeft: '1rem',
        color: '#406a8d',
        flex: 1
    },
    contenido: {
        marginBottom: '0.5rem',
    },
    [theme.breakpoints.up('md')]: {
        title: {
            padding: '0 20px'
        },
        button: {
            marginTop: 40,
            marginLeft: 20,
        },
        formContainer: {
            '& > .MuiGrid-item': {
                padding: '0 20px'
            }
        },
    }
});

interface IProps extends WithStyles<typeof styles> {
}

const JOCS: FC<IProps> = ({ classes }) => {
    const [, pageDispatcher] = usePage();
    const ioc = useContext(IoC);
    const tramitesG = useMemo(() => ioc.get(TramitesGateway) as TramitesGateway, [ioc]);
    const [table, setTable] = useState<IJOCS[] | null>();
    const [loading, setLoading] = useState(false);


    const handleSubmit = async () => {
        try {
            setLoading(true);
            const response = await tramitesG.getCensoJOCS();
            setTable(null);
            if (response != null) {
                if (Object.prototype.hasOwnProperty.call(response, "error")) {
                    pageDispatcher({
                        type: 'show-notification',
                        payload: {
                            message: <Term component="Global" text={(response as IJOCSERROR).descripcion} />,
                            variant: 'warning',
                        }
                    })
                } else if (Object.prototype.hasOwnProperty.call(response, "length")) {
                    setTable(response as IJOCS[]);
                }
            }
        } catch (e) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: <Term component="Global" text={(e as any).toString()} />,
                    variant: 'error',
                }
            })
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        handleSubmit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiWalletTravel,
                title: <Term component="Global" text="Datos Censo Maquinas" params={[new Date().getFullYear()]} />
            },
            menu: true,
        });
    }, [pageDispatcher]);

    return (
        <div className={classes.root}>
            {
                table != null && !loading
                    ?
                    <>
                        <Card className={[classes.cardContador, classes.contenido].join(' ')}>
                            <CardContent className={classes.cardContadorContainer} >
                                <span className={classes.titleCardContador}>
                                    <Term component="Tramites" text={'ContadorJOCS'} params={[table.length]} />
                                </span>
                            </CardContent>
                        </Card>
                        <Card className={classes.contenido}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ejercicio</TableCell>
                                        <TableCell align="right">Nº Guia</TableCell>
                                        <TableCell align="right">Tipo de máquina</TableCell>
                                        <TableCell align="right">Nº Jugadores</TableCell>
                                        <TableCell align="right">Importe partida</TableCell>
                                        <TableCell align="right">Tipo local</TableCell>
                                        <TableCell align="right">Fecha local</TableCell>
                                        <TableCell align="right">Código territorial</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {table.map(row => (
                                        <TableRow key={row.nombreLocal}>
                                            <TableCell component="th" scope="row">{row.ejercicio}</TableCell>
                                            <TableCell align="right">{row.guia}</TableCell>
                                            <TableCell align="right">{row.tipoMaquina + ' ' + row.subTipoMaquina}</TableCell>
                                            <TableCell align="right">{row.numJugadores}</TableCell>
                                            <TableCell align="right">{row.importePartida}</TableCell>
                                            <TableCell align="right">{row.tipoLocal}</TableCell>
                                            <TableCell align="right">{row.fechaLocal}</TableCell>
                                            <TableCell align="right">{row.codigoTerritorial}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Card>
                    </>
                    :
                    loading ?
                    <div className={classes.centerContent}>
                        <CircularProgress size={100} />
                    </div>
                        :
                        <Card className={[classes.cardContador, classes.contenido].join(' ')}>
                            <CardContent className={classes.cardContadorContainer} >
                                <span className={classes.titleCardContador}>
                                    <Term component="Tramites" text={'NoJOCSresult'} />
                                </span>
                            </CardContent>
                        </Card>                    
            }
        </div>
    )
}

export default withLiterals(['Tramites'])(withStyles(styles)(JOCS));
