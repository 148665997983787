import React, { FC } from "react";
import { Theme, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import vehiculo from '../resources/bienesSujeto/Vehiculo.png'
import rustica from '../resources/bienesSujeto/Rustica.png'
import urbana from '../resources/bienesSujeto/Urbana.png'
import otros from '../concepto-resource/images/otros.png'

export type TImageName = 'vehiculo'|'rustica'|'urbana'
const getImgSource = ( imgName: TImageName | null ) => {
    //console.log('getImgSource', imgName)
    //if(!imgName) return { source: otros };
    
    switch (imgName) {
        case 'vehiculo':
            return { source: vehiculo };
        case 'rustica':
            return { source: rustica };
        case 'urbana':
            return { source: urbana };
        default:
            return { source: otros };
    }

}

const styles = (theme: Theme): StyleRules => ({
    imagen: {
        width: '100%'
    },
    otros: {
        borderRadius: '50%',
        backgrounColor: '#ffffff'
    },
    bienesSujeto: {
        width: 148,
        backgrounColor: '#ffffff',
        padding: 0,
    }
});

export interface IProps extends WithStyles<typeof styles> {
    imgName: TImageName | null;
    from: 'bienesSujeto' | 'otros';
}

const ImageIcon: FC<IProps> = ({ classes, imgName, from }) => {
    const meta = getImgSource(imgName);
    //console.log('meta', meta) 
    return (
        <div className={classes[from]} >
            <img src={meta.source} className={classes.imagen} alt={from} />
        </div>
    );
}

export default withStyles(styles)(ImageIcon);