import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";

const styles = (theme: Theme): StyleRules => ({
    centerContent: {
        textAlign: 'center',
        paddingTop: 250,
        width: '100%',
    },
    // Stepper
    stepsStepperCard: {
        // '& .WithStyles\(ForwardRef\(StepConnector\)\)-alternativeLabel-244': {
        //     width: '40rem'
        // },
        // '& .MuiStepConnector-alternativeLabel': {
        //     left: 'calc(-89% + 20px)',
        //     right: 'calc(10% + 20px)'
        // },
        [theme.breakpoints.down('sm')]: {
            padding: 'unset',
            paddingTop: 24
        }
    },
    iconBox: {
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    activeIconBox: {
        backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

    },
    nonActiveIconBox: {
        backgroundColor: 'lightgrey',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    boldText: {
        color: 'black'
    },
    lightText: {
        fontWeight: 'lighter',
        color: 'grey',
    },
    textField: {
        width: '100%'
    },
    // Shared
    title: {
        color: '#004f84',
        fontSize: 32,
        fontWeight: 'lighter'
    },
    paddingContainerTitle: {
        padding: '0 20px'
    },
    // Content, Actions
    contaierContent: {
        padding: '0 75px 50px',
        overflow: "hidden"
    },
    // Email Section
    emailsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '1rem',
        marginTop: '1.5rem',
        padding: '0 20px'
    },
    textFieldEmail: {
        width: '40%'
    },
    // Phone Section
    containerPhoneInputs: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '2rem',
        padding: '0 20px'

    },
    // Dirección section
    section: {
        marginBottom: 20
    },
    text: {
        marginTop: 5,
        marginBottom: 5
    },
    linearCharge: {
        marginTop: -4
    },
    textFieldDireccion: {
        width: '100%'
    },
    selectField: {
        width: '100%'
    },
    circularProgressCentered: {
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        position: "absolute"
    },
    [theme.breakpoints.up('md')]: {
        section: {
            '& > .MuiGrid-item': {
                padding: '0 20px'
            }
        },
        selectField: {
            marginTop: 15
        },
    },
    [theme.breakpoints.down('sm')]: {
        emailsContainer: {
            flexDirection: "column"
        },
        containerPhoneInputs: {
            flexDirection: "column",
            gap: "5rem"
        }
    }
});

export default styles;