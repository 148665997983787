import { inject } from "aurelia-dependency-injection";
import { IRecibo, IFindAllQuery, IFilterData, IDatosIngreso, IDetalleRecibo, TipoIdentificador, InfoSancionCompleta, IResultPagosAcuenta, IReciboDetallePagoBonificado } from "./recibo.interfaces";
import { AxiosInstance } from "axios";
import { IBaseResponse } from "./base.response.interfaces";

@inject('axios')
export class RecibosGateway {

    constructor(
        private axios: AxiosInstance
    ) {

    }

    public async findAll(query?: IFindAllQuery): Promise<IRecibo[]> {
        try {
            const response = await this.axios.get('recibos', {
                params: {
                    ...query,
                    expires: Date.now(),
                }
            });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        } catch (e) {
            return [];
        }
    }


    public async getCount(query?: IFindAllQuery): Promise<number> {
        try {
            const response = await this.axios.get('recibos/count', {
                params: {
                    ...query,
                    expires: Date.now(),
                }
            });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        } catch (e) {
            return 0;
        }
    }

    public async getFilterData(query?: IFindAllQuery): Promise<IFilterData> {
        try {
            const response = await this.axios.get('recibos/filters', {
                params: {
                    ...query,
                    expires: Date.now(),
                }
            });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return {
                organismos: response.data.organismos.map((x: any) => ({ value: x.codigo, label: x.nombre })),
                ejercicios: response.data.ejercicios.map((x: any) => ({ value: x, label: x })),
                situaciones: response.data.situaciones.map((x: any) => ({ value: x, label: 'Situacion_' + x })),
                conceptos: response.data.conceptos.map((x: any) => ({ value: x.id, label: x.nombre }))
            };
        } catch (e) {
            return {};
        }
    }

    public async generarModelos(recibos: number[]): Promise<IBaseResponse<string>> {
        const response = await this.axios.post('recibos/generar-modelos', {
            idsRecibos: recibos,
        });

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }


    public async generarModelosPagoACuenta(recibo: number, importe: number): Promise<IBaseResponse<string>> {
        const response = await this.axios.post('recibos/generar-modelo-pago-a-cuenta', {
            idRecibo: recibo,
            importe: importe,
        });

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async generarModelosConsultasExternas(recibos: number[]): Promise<IBaseResponse<string>> {
        const response = await this.axios.post('recibos/generar-modelos-consultas-externas', {
            idsRecibos: recibos,
        });

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getLineasAuxiliares(idRecibo: number): Promise<string> {
        const response = await this.axios.get('recibos/getLineasAuxiliares/' + idRecibo.toString());

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        let str = '';
        if (Array.isArray(response.data)) {
            response.data.forEach((i) => {
                if (i.campo === 'ALIQUID') {
                    str += 'AÑO LIQUIDACIÓN / ANY LIQUIDACIÓ:  ';
                }

                str += i.valor;

                if (i.campo === 'ALIQUID') {
                    str += '<br>';
                }

                str += '<br>';

                if (i.campo === '_INFODERIVA') {
                    str += '<br>';
                }
            });
        }

        return str;
    }

    public async getLineasAuxiliaresNoIdentification(idRecibo: number): Promise<string> {
        const response = await this.axios.get('recibos/getLineasAuxiliaresNoIdentification/' + idRecibo.toString());

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        let str = '';
        if (Array.isArray(response.data)) {
            response.data.forEach((i) => {
                if (i.campo === 'ALIQUID') {
                    str += 'AÑO LIQUIDACIÓN / ANY LIQUIDACIÓ:  ';
                }

                str += i.valor;

                if (i.campo === 'ALIQUID') {
                    str += '<br>';
                }

                str += '<br>';

                if (i.campo === '_INFODERIVA') {
                    str += '<br>';
                }
            });
        }

        return str;
    }

    public async getExcedentePago(idRecibo: number): Promise<boolean> {
        const response = await this.axios.get('recibos/getExcedentePago/' + idRecibo.toString());

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }
    public async getDatosPendiente(idRecibo: number): Promise<any> {
        const response = await this.axios.get('tributos/getDatosDeuda/' + idRecibo.toString());
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }
    public async exportarDeuda(query?: IFindAllQuery): Promise<{ fileName: string, blob: Blob }> {
        const response = await this.axios.get('recibos/exportarDeuda', {
            params: {
                ...query,
                expires: Date.now(),
            },
            responseType: 'blob',
        });

        const disposition = response.headers['content-disposition'];
        // eslint-disable-next-line no-useless-escape
        const match = /^filename=\"?(.+?)\"?$/gmi.exec(disposition);
        const fileName = match ? match[1] : 'exportacion.xlsx';
        return {
            fileName,
            blob: response.data
        };
    }

    public async checkTipoIdentificador (datosIngreso: IDatosIngreso):Promise<IBaseResponse<TipoIdentificador>> {
        const response = await this.axios.post(
            "recibos/check-identificador/",
            datosIngreso,
        )
        if ((response.status !== 201 && response.status !== 403)) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data
    }

    public async completarMultaPagoTelematico (datosIngreso: IDatosIngreso, datosSancion: InfoSancionCompleta):Promise<IBaseResponse<string>> {
        const response = await this.axios.post(
            "recibos/completarMultaPagoTelematico/",
            { datosIngreso: datosIngreso,
              datosSancion: datosSancion  
            }
        )
        if ((response.status !== 201 && response.status !== 403)) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data
    }

    public async localizaPropuesta(datosIngreso: IDatosIngreso): Promise<IBaseResponse<string>> {
        datosIngreso.emisora = datosIngreso.emisora.replace(/\s/g, "")
        datosIngreso.identificacion = datosIngreso.identificacion.replace(/\s/g, "")
        datosIngreso.referencia = datosIngreso.referencia.replace(/\s/g, "")
        const response = await this.axios.post(
            "recibos/localiza-propuesta/",
            datosIngreso,
        );
        if ((response.status !== 201 && response.status !== 403)) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data as IBaseResponse<string>
    }

    public async findAllRecibos(query?: IFindAllQuery): Promise<IRecibo[]> {
        try {
            const response = await this.axios.get('recibos/findAllRecibos', {
                params: {
                    ...query,
                    expires: Date.now(),
                }
            });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        } catch (e) {
            return [];
        }
    }

    public async getObjetoTributario(obj: string): Promise<string> {
        const response = await this.axios.get('recibos/getObjetoTributario/' + obj);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async printJustificanteRecibosPagados(
        nifTitular: string,
        codigoConcepto: string,
        referencia: string,
        direccion: string): Promise<Blob> {
        try {
            const response = await this.axios.get('recibos/printJustificanteRecibosPagados/' + nifTitular + '/' + codigoConcepto + '/' + referencia + '/' + direccion, {
                responseType: 'blob',
            });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data as Blob;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getOneDetalleRecibo(idRecibo: number): Promise<IDetalleRecibo | null> {
        try {
            const response = await this.axios.get(`recibos/${idRecibo}`);

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        }
        catch (e) {
            throw new Error(e as string);
        }
    }

    public async getListadoPagosACuenta(idDomiRec: number): Promise<IResultPagosAcuenta[]> {
        const response = await this.axios.get(`recibos/getListadoPagosByIdRecibo/${idDomiRec}`);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async pagoACuentaDomiciliacion(idDomiRec: number, fechaEnvio: string, importe: string): Promise<boolean> {
        const response = await this.axios.post('recibos/alta-pago-a-cuenta-domi', {
            idDomiRec,
            fechaEnvio,
            importe
        });

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad Iresponse status: ' + response.statusText);
        }

        return response.data;
    }

    public async bajaPagoACuentaDomiciliacion(idDomiRec: number): Promise<boolean> {
        const response = await this.axios.post('recibos/baja-pago-a-cuenta-domi', { idDomiRec });

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async printJustificantePagosCuentaDomiciliacion(idDomiRec: number): Promise<Blob> {
        try {
            const response = await this.axios.get(`recibos/justificante-pago-a-cuenta-domi/${idDomiRec}`, {
                responseType: 'blob',
            });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data as Blob;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getImportesReciboDomiciliadoBonificado(idRecibo: number): Promise<IReciboDetallePagoBonificado | null> {
        const response = await this.axios.get(`recibos/importes-recibo-domiciliado-bonificado/${idRecibo}`);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }
}