import React, { FC, useContext, useMemo } from "react";

import AboNacIMG from "../../../../../resources/home/tramites/neixments_Icono.png";
import reintIniIMG from "../../../../../resources/abonoNacimiento/reintegro.png"
import { StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { useLocation } from "react-router";
export type THeaderProps = {tipoDocTerm: null|string|undefined, modalidad: string|null|undefined}
const HeaderStepper : FC<THeaderProps & WithStyles<typeof styles>>= ({classes,tipoDocTerm,modalidad }) => {
    const terms= useContext(LiteralsContext)
    //const location=useLocation()
    const size= window.screen.width < 1445 ? 45 :window.screen.width < 1930 ? 55:75
    //const tipo = useMemo(() => ( location.pathname.includes('alta-solicitud') ? 'solicitud' : 'reintegro'),[location.pathname])
    return (
        <div className={classes.headerContainer}>
            <div className={classes.row}>
                <img src={ AboNacIMG /*tipo === 'solicitud' ? AboNacIMG: reintIniIMG*/} width={size} height={size} style={{objectFit: "scale-down", marginTop: 4}}/>
                <div className={classes.column}>
                    <Typography variant={window.screen.width < 1445 ? "h6": "h5"} style={{lineHeight: 1.1}} >{translate('AbonoNacimiento', 'headerStepperTitle',terms)}</Typography>
                    <Typography variant={window.screen.width < 1445 ? "h6": "h5"} style={{lineHeight: 1.1}}>{translate('AbonoNacimiento', 'headerStepperTitle2',terms)}</Typography>
                </div>
            </div>
            {tipoDocTerm && <Typography className={classes.text}>{`${tipoDocTerm ? translate('AbonoNacimiento', tipoDocTerm,terms):''} ${modalidad ? `(${modalidad})`: ''}`}</Typography>}
        </div>
       
     )
}

const styles = (theme: Theme): StyleRules => ({
    headerContainer: {
        display:'flex',
        flexDirection:'column',
        paddingTop: 25,
        paddingRight: 15,
        paddingLeft: 5
    },
    row:{
        display: 'flex',
        flexDirection:'row',
        alignItems:'flex-end',
    },
    column:{
        display:'flex',
        flexDirection:'column',
        paddingLeft:5,
        marginBottom: 5
    },
    title: {
        fontSize: 26,
        fontWeight: 500
    },
    text: {
        textAlign: 'justify',
        marginTop: 10,
        paddingRight: 10,
        paddingLeft: 20,
        fontSize:14,
        [theme.breakpoints.down('xl')]:{
            paddingLeft: 10,
            fontSize: 13
        }
    }
});
export default withLiterals(['AbonoNacimiento'])(withStyles(styles)(HeaderStepper));
