import React, { useContext, useMemo, useState, useEffect, useCallback, useReducer } from 'react';
import { useTheme, makeStyles, Card, CardContent, CircularProgress, Hidden, MenuItem, Button } from '@material-ui/core';
import IoC from 'contexts/ioc.context';
import usePage from 'hooks/page.hook';
import { ProcedimientoGateway } from '../../../gateways/procedimientos.gateway';
import { IFilterDataProcedimientos, IGetProcedimientosRQ } from '../../../gateways/procedimientos.interfaces';
import { mdiBriefcase } from '@mdi/js';
import { IProcedimiento } from '../../../gateways/rolsac/procedimiento';
import Term from '../../../components/term';
import InfiniteScroll from 'react-infinite-scroller';
import NoContent from 'components/no-content';
import image from '../../../resources/no-deuda.png';
import FiltersReducer, { IFiltersState } from './filters.reducer';
import { FiltersContext } from './filters.context';
import { FilterDataContext } from './filter-data.context';
import FiltersDialog from './filters.dialog';
import PanelDrawer from './panel.drawer';
import FiltersActive from './filters.active';
import { ITramite } from '../../../gateways/rolsac/tramite';
import { RouteComponentProps } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        flex: 1,
        margin: '0 0.5rem'
    },
    procedimientosContainer: {
        flex: 1,
        margin: '0 0.5rem'
    },
    centerContent: {
        margin: '0 auto',
    },
    cardContador: {
        marginBottom: '0.5rem',
        backgroundColor: 'rgba(255,255,255,0.6)',
    },
    cardContadorContainer: {
        padding: '13px !important',
        display: 'flex',
        alignItems: 'center',
    },
    tituloProcedimiento: {
        display: 'flex'
    },
    nombreProcedimiento: {
        flex: 1
    },
    titleCardContador: {
        fontWeight: 300,
        fontSize: 16,
        marginLeft: '1rem',
        color: '#406a8d',
        flex: 1
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
            width: 300,
        }
    },
    procedimiento: {
        marginBottom: '0.5rem'
    },
    [theme.breakpoints.up('md')]: {
        root: {
            display: 'flex',
        },
        container: {
            margin: '0 2rem'
        },
        procedimientosContainer: {
            margin: '0 2rem'
        },
    },
}));

type Props = RouteComponentProps;

const numberElementsPerPage = 50;

const Procedimientos: React.FC<Props> = ({ history, location }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const ioc = useContext(IoC);
    const filtersReducer = useReducer(FiltersReducer, {});
    const procedimientoGateway = useMemo(() => ioc.get(ProcedimientoGateway) as ProcedimientoGateway, [ioc]);

    const [, pageDispatcher] = usePage();
    const [loading, setLoading] = useState(true);
    const [loadingProcedimientos, setLoadingProcedimientos] = useState(false);
    const [page, setPage] = useState(1);
    const [procedimientos, setProcedimientos] = useState<IProcedimiento[]>([]);

    const [filters,] = filtersReducer;
    const [filterData, setFilterData] = useState<IFilterDataProcedimientos>({});
    const [showFilters, setShowFilters] = useState(false);
    const [contadorProcedimientos, setContadorProcedimientos] = useState(0);
    const noContent = useMemo(() => !loadingProcedimientos && procedimientos.length === 0, [loadingProcedimientos, procedimientos]);
    const hasMore = useMemo(() => contadorProcedimientos > page * numberElementsPerPage, [page, contadorProcedimientos]);

    const handleShowFilters = useCallback(() => {
        setShowFilters(true);
    }, []);

    const handleContinuar = (id: string) => {
        history.push('/tramites/procedimientos/' + id);
    }

    const handleHideFilters = useCallback(() => setShowFilters(false), []);

    const buildQueryRequest = useCallback((newFilters: IFiltersState, newPage: number) => {
        return Object.assign(filters, newFilters, {
            skip: (newPage - 1) * numberElementsPerPage,
            take: numberElementsPerPage,
        }) as IGetProcedimientosRQ;
    }, [filters]);

    const handleLoadProcedimientos = useCallback(async (newFilters: IFiltersState, newPage: number) => {
        const query = buildQueryRequest(newFilters, newPage);
        if (!loadingProcedimientos) {
            try {
                setLoadingProcedimientos(true);
                const request = await procedimientoGateway.obtenerProcedimientos((query as any) as IGetProcedimientosRQ);
                setProcedimientos(newPage === 1 ? request.procedimientos : procedimientos.concat(request.procedimientos));
                setLoadingProcedimientos(false);
            } catch (error) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (error as any).message,
                        variant: 'error',
                    }
                });
            }
        }
    }, [procedimientoGateway, pageDispatcher, procedimientos, loadingProcedimientos, buildQueryRequest]);

    const handleLoadMoreProcedimientos = useCallback(() => {
        if (hasMore && !loadingProcedimientos) {
            setPage(page + 1);
            handleLoadProcedimientos(filters, page + 1);
        }
    }, [hasMore, page, handleLoadProcedimientos, loadingProcedimientos, filters]);

    const handleFiltersChanged = useCallback((newFilters: IFiltersState) => {
        setPage(1);
        handleLoadProcedimientos(newFilters, 1);
    }, [handleLoadProcedimientos]);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiBriefcase,
                right: (
                    <MenuItem button onClick={handleShowFilters}><Term component="Global" text="Filtrar resultados" /></MenuItem>
                ),
                title: <Term component="Tramites" text="ListadoDeProcedimientos" />
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    useEffect(() => {
        (async () => {
            const query = buildQueryRequest(filters, 0);
            const response = await procedimientoGateway.obtenerProcedimientos(query);
            setProcedimientos(response.procedimientos);
            setContadorProcedimientos(response.contador);
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedimientoGateway, filters])

    useEffect(() => {
        (async () => {
            const filtros = await procedimientoGateway.obtenerFamilias();
            setFilterData(filtros);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className={classes.root}>
                <FiltersContext.Provider value={filtersReducer}>
                    <FilterDataContext.Provider value={filterData}>
                        <div className={classes.root}>
                            <div className={classes.procedimientosContainer}>
                                <FiltersActive
                                    onChange={handleFiltersChanged}
                                />
                                {
                                    loading
                                        ?
                                        <div className={classes.centerContent}>
                                            <CircularProgress />
                                        </div>
                                        :
                                        <>
                                            <NoContent
                                                image={image}
                                                visible={noContent}
                                                component='Tramites'
                                                text={'No se han encontrado procedimientos'}
                                            />
                                            {
                                                contadorProcedimientos <= 0
                                                    ?
                                                    null
                                                    :
                                                    <>
                                                        <div>
                                                            <Card className={classes.cardContador}>
                                                                <CardContent className={classes.cardContadorContainer} >
                                                                    <span className={classes.titleCardContador}>
                                                                        <Term component="Tramites" text={'TituloProcedimientos'} params={[contadorProcedimientos]} />
                                                                    </span>
                                                                </CardContent>
                                                            </Card>
                                                            <InfiniteScroll
                                                                pageStart={1}
                                                                initialLoad={false}
                                                                loadMore={handleLoadMoreProcedimientos}
                                                                hasMore={hasMore}
                                                                loader={<div key="infiniteProgress" style={{ display: hasMore ? 'block' : 'none' }} className={classes.progressContainer}><CircularProgress className={classes.progress} /></div>}
                                                            >
                                                                {procedimientos.map((procedimiento: IProcedimiento, index: number) => (
                                                                    <Card className={classes.procedimiento} key={'procedimiento_' + procedimiento.codigo + '_' + index}>
                                                                        <CardContent>
                                                                            <div className={classes.tituloProcedimiento}>
                                                                                <b className={classes.nombreProcedimiento}>
                                                                                    {

                                                                                        procedimiento.nombre

                                                                                    }
                                                                                </b>
                                                                                {
                                                                                    <Button variant="outlined" color="primary" onClick={() => handleContinuar(procedimiento.codigo.toString())}>
                                                                                        <Term component="Tramites" text="MasInformacion" />
                                                                                    </Button>
                                                                                }
                                                                            </div>
                                                                            <p>
                                                                                <u>
                                                                                    <Term component="Tramites" text="TramitesDelProcedimiento" />
                                                                                </u>
                                                                            </p>
                                                                            <ul>
                                                                                {procedimiento.tramites.map((tramite: ITramite, index2: number) => (
                                                                                    <li key={"tramite_" + procedimiento.codigo + "_" + tramite.codigo + "_" + index2}>
                                                                                        {
                                                                                            tramite.nombre
                                                                                        }
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </CardContent>
                                                                    </Card>
                                                                ))}
                                                            </InfiniteScroll>

                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                            <div className={classes.drawer}>
                                <Hidden mdUp implementation="css">
                                    <FiltersDialog
                                        open={showFilters}
                                        screen={'procedimientos'}
                                        onClose={handleHideFilters}
                                        onChange={handleFiltersChanged}
                                    />
                                </Hidden>

                                <Hidden smDown implementation="css">
                                    <PanelDrawer
                                        screen={'procedimientos'}
                                        onFiltersChange={handleFiltersChanged}
                                    />
                                </Hidden>
                            </div>
                        </div>
                    </FilterDataContext.Provider>
                </FiltersContext.Provider >
            </div>
        </>
    );
}

export default Procedimientos;