import { StyleRules } from "@material-ui/core";

const styles = (): StyleRules => ({

    title: {
        fontSize: 32, 
        color: "#2196f3",
        fontWeight: "lighter", 
        fontFamily: 'Roboto'
    }
});

export default styles;