import { FC, useContext } from 'react';
import { WithStyles, FormControl, withStyles, Grid, Hidden, CardContent, Card, IconButton } from '@material-ui/core';
import Term from 'components/term';
import { IPaymentMethod } from 'gateways/payment.method.interfaces';
import { IModelGateway } from 'gateways/modelGateway.interface';
import Icon from "@mdi/react";
import DeudaDialog from 'containers/pasarela-pago/deuda.dialog';
import TotalPago from 'containers/pasarela-pago/total.pago';
import PanelDeuda from 'containers/pasarela-pago/panel.deuda.drawer';
import styles from '../containers/pasarela-pago/pago.styles';
import BB from '../resources/payment/BB.jpg';
import BB_d from '../resources/payment/BB_d.jpg';
import BK from '../resources/payment/BK.jpg';
import BM from '../resources/payment/BM.jpg';
import BM_d from '../resources/payment/BM_d.jpg';
import CC from '../resources/payment/CC.jpg';
import CC_d from '../resources/payment/CC_d.jpg';
import LC from '../resources/payment/LC.jpg';
import LC_d from '../resources/payment/LC_d.jpg';
import TJ from '../resources/payment/TJ.jpg';
import noPaymentImage from "../resources/NOFOTO.jpg"
import { mdiChevronLeft } from '@mdi/js';

interface IProps {
    banks: IPaymentMethod[];
    modelos: IModelGateway[];
    showDeuda: boolean;
    showTotalPagar: boolean;
    handlePay: (paymentMethodSelected: string) => void;
    handleHideDeuda?: () => void;
    handleBack?: () => void;
}

function getImage(id: string) {
    switch(id) {
        case "BB": {
            return BB;
        }
        case "BB_d": {
            return BB_d;
        }
        case "BK": {
            return BK;
        }
        case "BM": {
            return BM;
        }
        case "BM_d": {
            return BM_d;
        }
        case "CC": {
            return CC;
        }
        case "CC_d": {
            return CC_d;
        }
        case "LC": {
            return LC;
        }
        case "LC_d": {
            return LC_d;
        }
        case "TJ": {
            return TJ;
        }
        default: {
            return noPaymentImage;
        }
    }
}

const EntidadBancaria: FC<WithStyles<typeof styles> & IProps> = ({ 
    classes,
    banks,
    modelos,
    showDeuda,
    showTotalPagar,
    handlePay,
    handleHideDeuda,
    handleBack
}) => {
    return (
        <>
            <div className={classes.root}>
                <Card className={showDeuda ? classes.mainCard : ''}>
                    <CardContent>
                        <div className={classes.formasPago}>
                            <FormControl component={"fieldset" as "div"} className={classes.formControl}>
                                <Hidden smDown implementation="css">
                                    <h3><Term component="PasarelaPago" text="Banca Electrónica" /></h3>
                                    {/* <Term component="PasarelaPago" text="Esta opción permite realizar el pago desde las plataformas de pago de diferentes entidades" /> */}
                                    <Term component="PasarelaPago" text="OpcionPago" />
                                </Hidden>
                                <Grid container style={{ marginTop: 20 }} className={classes.imageGridcontainer}>
                                    {banks.map((paymentMethod: IPaymentMethod, index: number) => {
                                        if (paymentMethod.activa) {
                                            return (
                                                <Grid item key={index} className={classes.block}>
                                                    <Card elevation={2}>
                                                        <CardContent>
                                                            <img src={getImage(paymentMethod.id)} alt={paymentMethod.nombre} className={classes.imageEffect}
                                                                onClick={() => handlePay(paymentMethod.id)} />
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            );
                                        } else {
                                            return (
                                                <Grid item key={index} className={classes.block}>
                                                    <Card elevation={2}>
                                                        <CardContent>
                                                            <img src={getImage(`${paymentMethod.id}_d`)} alt={paymentMethod.nombre} className={classes.imageEffect} />
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            );
                                        }
                                    }
                                    )}
                                </Grid>
                                { handleBack &&
                                <IconButton style={{ width: 50 }} onClick={() => handleBack()}>
                                    <Icon path={mdiChevronLeft}></Icon>
                                </IconButton>
                                }
                            </FormControl>                            
                        </div>
                    </CardContent>
                </Card>
                { showTotalPagar &&
                <div className={classes.fixedBottom}>
                    <TotalPago items={modelos} visible={true} />
                </div>
                }
            </div>
            { handleHideDeuda &&
            <div className={classes.drawer}>
                <Hidden mdUp implementation="css">
                    <DeudaDialog
                        open={showDeuda}
                        onClose={handleHideDeuda}
                        modelos={modelos}
                    />
                </Hidden>
                <Hidden smDown implementation="css">
                    <PanelDeuda modelos={modelos} />
                </Hidden>
            </div>
            }
        </>
    );
}

export default withStyles(styles)(EntidadBancaria);