export function esConceptoVivienda(idConcepto: number): boolean {
    switch (idConcepto) {
        case 1:
        case 2:
        case 9:
        case 10:
        case 11:
        case 12:
        case 14:
        case 15:
            return true;
        default:
            return false;
    }
}