import React from "react"
import imageLogo from '../../resources/logo.png';
import { translate } from "utils/i18n"
import { Paper, Grid, Typography } from "@material-ui/core"
import { BLUE_BORRADOR } from "containers/shared/colors"
import { renderApartado, renderApartadoText } from "./shared";
import { ITasasTramiteMunicipio } from "gateways/duti.interface";
import HeaderJustificanteBorrador from "components/DUTI/header-justificante-borrador";

type TValuesJxx =  {term: string, value: any, fullWidth?:boolean}[]
export type TJustificanteTASAS = Record<'APART_1'|'APART_2'|'APART_3'|'APART_6', TValuesJxx > & Record<'APART_4'|'APART_5',{titulares: TValuesJxx[]}> 


export const renderJTASAS = (
    justificante:  TJustificanteTASAS , 
    terms:any, 
    componentTerms: string ,
    tasas: ITasasTramiteMunicipio[]
) => {

    return (
        <Paper elevation={5} style={{margin: 20, paddingBottom: 60}}>
            <Grid container direction='column' style={{padding: 20}}>
                <HeaderJustificanteBorrador
                    componentTerms={componentTerms}
                    docTypeTerm="borr_justif_dusti"
                    terms={terms}
                    titleTerm={`borr_tasas_title`}
                />
               
                <Grid item >
                    {(Object.keys(justificante) as (keyof TJustificanteTASAS)[]).map( (apartado) => {  
                        return(
                            <Grid item >
                                { justificante[apartado] !== null &&
                                    <Typography style={{backgroundColor: BLUE_BORRADOR, color: 'white',marginTop: 10, padding: '2px 5px 2px 5px',display:'flex', flex:1}}>
                                        {translate(componentTerms,`tasas_${apartado}_title`,terms).toUpperCase()}
                                    </Typography>
                                } 
                                {apartado=== "APART_1" &&
                                    <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap'}}>
                                        {renderApartadoText(justificante[apartado], 'tasas', terms, componentTerms)}
                                    </div>
                                }
                                {(apartado === "APART_2" || apartado === "APART_3") &&
                                    <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap', marginBottom: 20}}>
                                        {renderApartado(justificante[apartado], 'tasas', terms, componentTerms)}
                                    </div>
                                }

                                {('APART_4'=== apartado || "APART_5" === apartado) && 
                                    <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap', marginTop: 10}}>
                                        { justificante[apartado].titulares.map((titular: TValuesJxx, idx) => (
                                            <div key={apartado+idx} 
                                                style={{ 
                                                    display:'flex', flexDirection:'row', flexWrap:'wrap',
                                                    borderColor: '#e0eaf4', 
                                                    borderBottomStyle:'solid'
                                                }}
                                            >
                                                {renderApartado(titular, 'tasas', terms, componentTerms)}
                                            </div>
                                        ))}
                                    </div>
                                }

                                {apartado === "APART_6" 
                                    &&
                                        <div style={{display:'flex', flexDirection:'column',padding: 10 }}>
                                        { tasas.map( t => ( 
                                                <div key={t.id} style={{display: "inline-flex"}}>
                                                    <Typography style={{paddingRight:10}} key={t.id+'_dot'} >-</Typography> 
                                                    <Typography  key={t.id}>{translate('DUTI', t.term, terms)}</Typography> 
                                                </div>
                                            ))}
                                        </div>

                                }
                                
                            </Grid>
                        )
                    })}
                    
                </Grid>
            </Grid>
        </Paper>
)
}