import { inject } from "aurelia-dependency-injection";
import { AxiosInstance, AxiosResponse } from "axios";
import { createValidationError } from "utils/response";
import { IJOCS, IJOCSERROR, IMultas, IFilterData, IFindAllQuery, IIdentificacionConductor, ITramite, IDatosDocumentoNotario, IModelo, IDetalleExpediente, ITipoExpediente, IExpediente, IFindAllQueryExpedientes, IFilterDataTramites, IOrderData, IDatosMulta, IFindAllByRemesaQuery, IRemesaMultas, Modelo017, IFindAllQueryCensado } from "./tramites.interfaces";
import qs from 'qs'
import { dateFormat } from "utils/dates";
import { QueryBuilder } from "@material-ui/icons";

@inject('axios')
export class TramitesGateway {

    constructor(
        private axios: AxiosInstance
    ) { }

    public async getCensoJOCS(): Promise<IJOCS[] | IJOCSERROR> {
        try {
            const response = await this.axios.get('jocs/getCensoJOCS');
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async generarJustificante(idExpediente: number): Promise<string> {
        try {
            const response = await this.axios.post(`expediente/${idExpediente}/genera-justificante`);
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getMultasIdentificar(query?: IFindAllQuery): Promise<IMultas[]> {
        try {
            const response = await this.axios.get('conductor/listado', {
                params: {
                    ...query
                }
            });

            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getCount(query?: IFindAllQuery): Promise<number> {

        try {
            const response = await this.axios.get('conductor/count', {
                params: {
                    ...query,
                }
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getFilterData(query?: IFindAllQuery): Promise<IFilterData> {
        try {
            const response = await this.axios.get('conductor/filters', {
                params: {
                    ...query,
                }
            });

            return {
                municipios: response.data.organismos !== undefined ?
                    response.data.organismos.map((x: any) => ({ value: x.codigo, label: x.nombre })) :
                    [],
                gravedad: response.data.gravedades !== undefined ?
                    response.data.gravedades.map((x: any) => ({ value: x.Gravedad, label: x.Gravedad })) :
                    [],
                estado: response.data.estados !== undefined ?
                    response.data.estados.map((x: any) => ({ value: x.Estado, label: x.Estado })) :
                    [],
                obligatoriedad: response.data.obligatoriedad !== undefined ?
                    response.data.obligatoriedad.map((x: any) => ({ value: x.obligatoriedad, label: x.obligatoriedad })) :
                    [],
            };
        } catch (e) {
            throw createValidationError(e as any);
        }

    }

    public async getOrderData(query?: IFindAllQuery): Promise<IOrderData> {
        return {
            orden: [{ value: 1, label: 'Fecha alta - descendente' },
            { value: 2, label: 'Fecha alta - ascendente' },
            { value: 3, label: 'Fecha límite - descendente' },
            { value: 4, label: 'Fecha límite - ascendente' }]
        };
    }

    public async isRentACar(): Promise<boolean> {
        try {
            const response = await this.axios.get('conductor/is-rent-a-car');
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async localizaMulta(datos: IDatosMulta) {
        try {
            const response = await this.axios.post('conductor/multa', datos);
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async guardarDatosConductor(datosConductor: IIdentificacionConductor, idExpediente: number) {
        try {
            const response = await this.axios.post('expediente/' + idExpediente + '/guardar-datos-conductor', datosConductor);
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async identificarConductor(solicitud: IIdentificacionConductor, isLoggedIn: boolean, datosMulta?: IDatosMulta) {
        try {
            if (isLoggedIn) {
                const response = await this.axios.post('conductor/identificar', solicitud);
                return response.data
            } else {
                const response = await this.axios.post('conductor/identificar-sin-autentificacion', { datosMulta: datosMulta, identificacion: solicitud });
                return response.data
            }
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getDocumentoNotarioNRD(idExpediente: number, nrd: string): Promise<IDatosDocumentoNotario> {
        try {
            const response = await this.axios.post('expediente/asociar-escritura-publica-nrd', {
                idExpediente,
                nrd
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getDocumentoNotarioDocumento(idExpediente: number, numProtocolo: string, ejercicio: string, fecha: string, nif: string): Promise<IDatosDocumentoNotario> {
        try {
            const response = await this.axios.post('expediente/asociar-escritura-publica-doc', {
                idExpediente,
                protocolo: numProtocolo,
                ejercicio,
                fechaOtorgamiento: fecha,
                nifNotario: nif
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getTramites(idGrupo: number): Promise<ITramite[]> {
        try {
            const response = await this.axios.get('/tipo-expediente/presentaciones/' + idGrupo, {
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async anularExpediente(idExpediente: number): Promise<void> {
        try {
            const response = await this.axios.delete(`/expediente/${idExpediente}`, {
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getPresentaciones(query: IFindAllQueryExpedientes): Promise<IExpediente[]> {
        try {
            const params = qs.stringify({
                idEstado: query.idEstado,
                idTipoExpediente: query.tipoExpediente,
                skip: query.skip,
                take: query.take,
                modelo: query.modelo,
                nifSujetoPas: query.nif,
                nrd: query.nrd,
                fecha: query.fecha !== undefined ? query.fecha : null,
            });
            const response = await this.axios.get('/expediente/me?' + params, {
            });
            if (response.status === 200) {
                return response.data.map((x: any) => ({
                    estado: x.estado.id,
                    idDocumento: x.idDocumentoJustificante,
                    idExpediente: x.idExpediente,
                    referencia: x.referencia,
                    fecha: new Date(x.fecha).toLocaleDateString(),
                    tipoExpediente: x.tipo.nombre,
                    escrituraPublica: x.escrituraPublica,
                    datosModelo: x.datosModelo
                } as IExpediente));
            } else {
                return [];
            }


        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async comprobarUsuarioRegistrado(tipoUsuario: number) {
        try {
            const response = await this.axios.get('certificados/comprobarUsuarioRegistrado/' + tipoUsuario, {
            });
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async obtenerListadoCertificados() {
        try {
            const response = await this.axios.get('certificados/obtenerListadoCertificados', {
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async permitirImprimirCertificadoTerceros(tipo: string, nif: string, nombre: string) {
        return await this.axios.post('certificados/comprobarCertificadoSujetoTerceros/', {
            nif,
            tipo,
            nombre,
        }).then(response => {
            return response.data
        }).catch(error => {
            if (error.response.status === 404) {
                throw error.response.status;
            }
            throw createValidationError(error);
        });
    }

    public async permitirImprimirCertificado(tipo: string) {
        try {
            const response = await this.axios.get('certificados/comprobarCertificadoSujeto/' + tipo, {
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getJustificanteIdentificacion(idIdentificacion: number, isLoggedIn: boolean, datosMulta?: IDatosMulta) {
        if (isLoggedIn) {
            const response = await this.axios.get('conductor/getJustificante/' + idIdentificacion, {
                responseType: 'blob',
            });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        } else {
            const response = await this.axios.post('conductor/getJustificante-sin-autentificacion/' + idIdentificacion, datosMulta, {
                responseType: 'blob',
            });

            if (response.status !== 200 && response.status !== 201 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        }
    }

    public async getDocumentacionAdjuntaIdentificacion(idIdentificacion: number) {
        const response = await this.axios.get('conductor/getDocumentacion/' + idIdentificacion, {
            responseType: 'blob',
        });

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getCountPresentaciones(query: IFindAllQueryExpedientes): Promise<number> {
        try {
            const params = qs.stringify(
                {
                    idEstado: query.idEstado ? query.idEstado : undefined,
                    idTipoExpediente: query.tipoExpediente ? query.tipoExpediente : undefined,
                    fecha: query.fecha ? query.fecha : undefined,
                    modelo: query.modelo ? query.modelo : undefined,
                    nifSujetoPas: query ? query.nif : undefined,
                    nrd: query.nrd ? query.nrd : undefined
                }
            );
            const response = await this.axios.get('/expediente/me/count?' + params, {
            });
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getFilterDataPresentaciones(query: IFindAllQueryExpedientes): Promise<IFilterDataTramites> {
        try {
            const response = await this.axios.get('/expediente/me/stats');

            if (response.status === 200) {
                return {
                    idEstado: response.data.estados.map((x: any) => ({ value: x.id, label: x.nombre })),
                    tipoExpediente: response.data.tipos.map((x: any) => ({ value: x.id, label: x.nombre }))
                };
            }

            return { idEstado: [], tipoExpediente: [] };
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getDetalleTipoExpediente(idTipoExpediente: number): Promise<ITipoExpediente> {
        try {
            const response = await this.axios.get('/tipo-expediente/' + idTipoExpediente.toString());
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getDetalleExpediente(idExpediente: number): Promise<IDetalleExpediente> {
        try {
            const response = await this.axios.get('/expediente/' + idExpediente.toString());
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async desvincularEscrituraPublica(idExpediente: number) {
        try {
            const response = await this.axios.delete('/expediente/' + idExpediente + '/escritura-publica', {
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async desvincularModelo(idExpediente: number, localizador: string) {
        try {
            const response = await this.axios.delete('/expediente/' + idExpediente + '/modelo/' + localizador, {
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async anyadirModelo(idExpediente: number, localizador: string, fecha: string, nif: string, importe: number): Promise<IModelo[]> {
        try {
            const response = await this.axios.post('/expediente/asociar-modelo', {
                idExpediente,
                localizador,
                fecha,
                nifSujetoPasivo: nif,
                importeAIngresar: importe
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async generarDocumentoModelo(idReferencia: string): Promise<Blob> {
        try {
            const response = await this.axios.get(`modelos/${idReferencia}/genera-documento`, {
                responseType: 'blob',
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async asociarDocumentoBase(id: string, idExpediente: number, tipoDocumento: string, descripcion: string | undefined) {
        try {
            const response = await this.axios.post('/expediente/asociar-documento-base', {
                idExpediente,
                idDocumento: id,
                tipoDocumento,
                descripcion: descripcion
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async asociarDocumento(ids: string[], nombreTipoDocumento: string, idExpediente: number) {
        try {
            const responses: any[] = [];
            for (const element of ids) {
                const response = await this.axios.post('/expediente/asociar-documento', {
                    idExpediente,
                    idDocumento: element,
                    tipoDocumento: nombreTipoDocumento
                });
                if (response.status !== 201 && response.status !== 403) {
                    throw new Error('Bad response status: ' + response.statusText);
                } else {
                    responses.push(response.data);
                }
            }

            return responses;
        } catch (e) {
            throw createValidationError(e as any);
        }

    }

    public async subirDocumentos(files: any) {
        try {
            const formData = new FormData();
            for (const file of files) {
                formData.append('files', file);
            }
            const responseSubidaFicheros = await this.axios.post('/file-upload?source=sede-presentacion', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            return responseSubidaFicheros.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async desvincularDocumentos(idExpediente: number, idDocumento: string) {
        try {
            const response = await this.axios.delete('/expediente/' + idExpediente + '/documento/' + idDocumento, {
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async crearExpediente(idTipoExpediente: number, referencia: string | null = null) {
        try {
            const response = await this.axios.post('/expediente/iniciar', {
                idTipoExpediente,
                referencia
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    };

    public async validarPresentacion(idExpediente: number) {
        try {
            const response = await this.axios.post('/expediente/' + idExpediente + '/validar-presentacion', {
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async presentar(idExpediente: number) {
        try {
            const response = await this.axios.post('/expediente/' + idExpediente + '/presentar', {
            });
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async identificacionObligatoria(idRecibo: number) {
        try {
            const response = await this.axios.get(`conductor/identificacion-obligatoria/${idRecibo}`);
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async puedeIdentificar(idRecibo: number) {
        try {
            const response = await this.axios.get(`conductor/puede-identificar/${idRecibo}`);
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getEstadoIdentificacion(idRecibo: number) {
        try {
            const response = await this.axios.get(`conductor/get-estado-identificacion/${idRecibo}`);
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getAllTramites() {
        try {
            const response = await this.axios.get('tramites')
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }


    public async exportarMultas(query?: IFindAllQuery): Promise<{ fileName: string, blob: Blob }> {
        const response = await this.axios.get('conductor/exportar-multas', {
            params: {
                ...query,
                expires: Date.now(),
            },
            responseType: 'blob',
        });

        const disposition = response.headers['content-disposition'];
        // eslint-disable-next-line no-useless-escape
        const match = /^filename=\"?(.+?)\"?$/gmi.exec(disposition);
        const fileName = match ? match[1] : 'exportacion.xlsx';
        return {
            fileName,
            blob: response.data
        };
    }

    public async exportarPlantillaMultas(query?: IFindAllQuery): Promise<{ fileName: string, blob: Blob }> {
        const response = await this.axios.get('conductor/exportar-plantilla-multas', {
            params: {
                ...query,
                expires: Date.now(),
            },
            responseType: 'blob',
        });

        const disposition = response.headers['content-disposition'];
        // eslint-disable-next-line no-useless-escape
        const match = /^filename=\"?(.+?)\"?$/gmi.exec(disposition);
        const fileName = match ? match[1] : 'exportacion.xlsx';
        return {
            fileName,
            blob: response.data
        };
    }

    public async downloadDatosParaIdentificar(): Promise<{ fileName: string, blob: Blob }> {
        const response = await this.axios.get('conductor/descargar-datos', {
            responseType: 'blob',
        });

        const disposition = response.headers['content-disposition'];
        // eslint-disable-next-line no-useless-escape
        const match = /^filename=\"?(.+?)\"?$/gmi.exec(disposition);
        const fileName = match ? match[1] : 'exportacion.xlsx';
        return {
            fileName,
            blob: response.data
        };
    }

    public async printJustificanteIdentificacionMasiva(listaMultasIdentificadas: any[]): Promise<{ fileName: string, blob: Blob }> {
        const response = await this.axios.post('conductor/printJustificanteIdentificacionMasiva', { 'listaMultasIdentificadas': listaMultasIdentificadas }, {
            responseType: 'blob',
        });
        const disposition = response.headers['content-disposition'];
        // eslint-disable-next-line no-useless-escape
        const match = /^filename=\"?(.+?)\"?$/gmi.exec(disposition);
        const fileName = match ? match[1] : 'justificanteMasivo.pdf';
        return {
            fileName,
            blob: response.data
        };
    }

    public async asociarJustificanteMasivoARecibos(listaMultasIdentificadas: any[]): Promise<boolean> {
        const response = await this.axios.post('conductor/asociarJustificanteMasivo', { 'listaMultasIdentificadas': listaMultasIdentificadas });
        return response.data;
    }

    public async getUsuarioPermitidoConsultasExternas(): Promise<boolean> {
        const response = await this.axios.get('tramites/usuario-permitido-consultas-externas');
        return response.data;
    }

    public async asociarNuevoDocumentoToExpediente(id: string, descripcion: string, idExpediente: number, fileAttachDto: any): Promise<IDatosDocumentoNotario> {
        try {
            const response = await this.axios.post('/expediente/asociar-escritura-publica-new-doc', {
                idExpediente: idExpediente,
                protocolo: fileAttachDto.protocolo,
                ejercicio: fileAttachDto.ejercicio,
                fechaOtorgamiento: fileAttachDto.fechaOtorgamiento,
                nifNotario: fileAttachDto.nifNotario,
                nombreNotario: fileAttachDto.nombreNotario,
                descripcion: descripcion,
                idDocumento: id,
            });
            if (response.status !== 201 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }

    }

    public async getNotarioByNif(nifNotario: string): Promise<any> {
        try {
            const response = await this.axios.get('/expediente/notario-by-nif/' + nifNotario);
            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }

    }

    public async getRemesasdeMultas(query: IFindAllByRemesaQuery | null): Promise<IRemesaMultas[]> {
        try {
            const request = this.getFiltersRemesasMultasRequest(query);
            const response = await this.axios.get('conductor/listado-remesas-multas', {
                params: {
                    ...request
                }
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getCountRemesasDeMulta(query: IFindAllByRemesaQuery | null): Promise<number> {
        try {
            const request = this.getFiltersRemesasMultasRequest(query);
            const response = await this.axios.get('conductor/count-remesas-multa', {
                params: {
                    ...request
                }
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async getMultasIdentificarPorRemesa(query: IFindAllByRemesaQuery | null): Promise<IMultas[]> {
        try {
            const request = this.getFiltersRemesasMultasRequest(query);
            const response = await this.axios.get('conductor/lista-multas-por-remesa', {
                params: {
                    ...request
                }
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async exportarPlantillaMultasRemesa(query: IFindAllByRemesaQuery | null): Promise<{ fileName: string, blob: Blob }> {
        const request = this.getFiltersRemesasMultasRequest(query);
        const response = await this.axios.get('conductor/exportar-plantilla-multas-remesa', {
            params: {
                ...query,
                expires: Date.now(),
            },
            responseType: 'blob',
        });

        const disposition = response.headers['content-disposition'];
        // eslint-disable-next-line no-useless-escape
        const match = /^filename=\"?(.+?)\"?$/gmi.exec(disposition);
        const fileName = match ? match[1] : 'exportacion.xlsx';
        return {
            fileName,
            blob: response.data
        };
    }

    public async getPermisoConvenioRemesas(): Promise<boolean> {
        const response = await this.axios.get('conductor/permiso-convenio-remesas');
        return response.data;
    }

    private getFiltersRemesasMultasRequest(query: IFindAllByRemesaQuery | null): IFindAllByRemesaQuery | null {
        if (query && query['fechaRemesa']) {
            query['fechaRemesa'] = dateFormat(query['fechaRemesa']);
        }
        return query;
    }

    /* CONSULTA DE CENSO */

    public async getEstablecimientos(nif: string): Promise<Modelo017[]> {
        try {
            const response : AxiosResponse = await this.axios.get(`modelos/getEstablecimientos/${nif}`);
            return response.data as Modelo017[];
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

}
