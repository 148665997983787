import { FC, useContext } from 'react';
import { WithStyles, withStyles, Hidden, CardContent, Card, Grid, Typography, Button } from '@material-ui/core';
import Term from 'components/term';
import { IModelGateway } from 'gateways/modelGateway.interface';
import Icon from "@mdi/react";
import { mdiCheckCircle } from '@mdi/js';
import styles from '../../containers/pasarela-pago/pago.styles';
import ConceptoIcon from 'components/concepto.icon';
import { IModeloDeclaracionPago } from 'gateways/duti.interface';
import TextNumber from 'components/text-number';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { translate } from 'utils/i18n';

interface IProps {
    pagoConfirmado: boolean;
    showPanelConfirmacion: boolean;
    showNext: boolean;
    showValidar: boolean;
    modelosDeclaracion: IModeloDeclaracionPago[];
    handleNext: () => void;
}

interface IPropsCard {
    pagoConfirmado: boolean;
}

const ConfirmacionCard: FC<WithStyles<typeof styles> & IPropsCard> = ({ classes, pagoConfirmado }) => {
    return(
        <>
        {
            pagoConfirmado ?
                <Card className={classes.cardContador}>
                    <CardContent className={classes.cardContadorContainer} >
                        <span className={classes.titleCardContador}>
                            <Term component="PasarelaPago" text="El pago se ha realizado con éxito" />
                            <Icon path={mdiCheckCircle} title="Seleccionado" size={1} />
                        </span>
                    </CardContent>
                </Card>
                :
                <Card className={classes.cardContador}>
                    <CardContent className={classes.cardContadorContainer} >
                        <span className={[classes.titleCardContador, classes.red].join(' ')}>
                            <Term component="PasarelaPago" text="El pago no ha podido ser confirmado" />
                        </span>
                    </CardContent>
                </Card>
        }
        </>
    );
};

const ModeloPagoDusti: FC<WithStyles<typeof styles> & IProps> = ({ 
    classes,
    pagoConfirmado,
    showPanelConfirmacion,
    showNext,
    showValidar,
    modelosDeclaracion,
    handleNext,
}) => {
    
    const terms = useContext(LiteralsContext);
    
    const getTitle = (modeloDeclaracion: IModeloDeclaracionPago) => {
        switch(modeloDeclaracion.idTipoModelo) {
            case "600":
                return "Modelo 600";
            case "081":
                return "Plusvalias";
            default:
                return "";
        }
    }

    const getDetail = (modeloDeclaracion: IModeloDeclaracionPago) => {
        switch(modeloDeclaracion.idTipoModelo) {
            case "081":
                return modeloDeclaracion.idModelo;
            default:
                return modeloDeclaracion.idModelo;
        }
    }

    return (
        <div className={classes.componenteContainer}>
        {showPanelConfirmacion &&
            <ConfirmacionCard classes={classes} pagoConfirmado={pagoConfirmado} />
        }
         <Grid container direction='row' spacing={2}>  
            {  modelosDeclaracion.map((modeloDeclaracion: IModeloDeclaracionPago) => {
                    return (
                    <Grid item 
                        xs={ modelosDeclaracion.length===1
                            ? 12 
                            : modelosDeclaracion.length === 3? 4: 6 }>            
                        <Card
                            key={modeloDeclaracion.idModelo}
                            className={[classes.mainButtonDusti, classes.recibo, classes.cardSeparate].join(' ')}
                        >
                            <CardContent>
                                <Grid container className={classes.rootGrid}>
                                    <Grid item className={classes.imageGrid}>
                                        <div className={classes.imagenContainer}>
                                            <ConceptoIcon concepto={modeloDeclaracion.idConcepto ? modeloDeclaracion.idConcepto : null} />
                                        </div>
                                    </Grid>
                                    <Grid container item className={classes.grow}>
                                        <Grid className={classes.grow} xs={8}>
                                            <Typography className={classes.highlightText}>
                                                { translate("DUTI",getTitle(modeloDeclaracion),terms)}
                                            </Typography>
                                            <Typography className={classes.spanText}>
                                                {getDetail(modeloDeclaracion)}
                                            </Typography>
                                            <div style={{display:'flex'}}>
                                                <Typography component="span" className={classes.spanText}>
                                                    <Term component="DUTI" text="Ref. Catastral" />:
                                                </Typography>
                                                <Typography className={classes.spanText}>
                                                    {modeloDeclaracion.referenciaCatastral}
                                                </Typography>
                                            </div>
                                            <div style={{display:'flex', color: 'blue'}}>
                                                <Typography component="span" className={classes.blueText}>
                                                    <Term component="DUTI" text="Importe" />:
                                                </Typography>
                                                <TextNumber
                                                    component="span"
                                                    className={classes.blueText}
                                                    value={modeloDeclaracion.importe}
                                                    options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }} /> 
                                            </div>
                                        </Grid>
                                        <Grid className={classes.grow} xs={4}>
                                        { showNext && modelosDeclaracion.length === 1 &&
                                        <Button variant="contained"
                                            color="primary"
                                            size="medium"
                                            className={classes.btnAddPay}
                                            onClick={handleNext}>
                                            <Term component="DUTI" text="Siguiente" />
                                        </Button>
                                        }
                                        { showValidar && modelosDeclaracion.length === 1 &&
                                            <Button variant="contained"
                                                color="primary"
                                                size="medium"
                                                className={classes.btnAddPay}
                                                onClick={handleNext}>
                                                <Term component="DUTI" text="btn_validar" />
                                            </Button>
                                        }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                            </CardContent>
                        </Card>
                    </Grid>);
                })
            }

        </Grid>
        {showNext && modelosDeclaracion.length > 1 &&  
            <div style={{justifyContent: 'flex-end', display:'flex', flexGrow: 1}}>      
                <Button variant="contained"
                    color="primary"
                    size="medium"
                    onClick={handleNext}>
                    <Term component="DUTI" text="Siguiente" />
                </Button>
            </div>      
        }
        { showValidar && modelosDeclaracion.length > 1 &&
            <div style={{justifyContent: 'flex-end', display:'flex', flexGrow: 1}}>
                <Button variant="contained"
                    color="primary"
                    size="medium"
                    onClick={handleNext}>
                    <Term component="DUTI" text="btn_validar" />
                </Button>
            </div>
        }
        </div>
    );
}

export default withLiterals(["Global","PasarelaPago", "DUTI"])(withStyles(styles)(ModeloPagoDusti));