import React, { useEffect, useContext, FC, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import usePage from 'hooks/page.hook';
import queryString from 'query-string';
import Term from 'components/term';
import IoC from 'contexts/ioc.context';
import { SujetosGateway } from 'gateways/sujetos.gateway';

const EntradaPage: FC<RouteComponentProps> = ({ location, history }) => {
    const [, pageDispatcher] = usePage();
    const ioc = useContext(IoC);
    const perfilG = useMemo(() => ioc.get(SujetosGateway) as SujetosGateway, [ioc]);

    useEffect(() => {
        const values = queryString.parse(location.search);
        const token: string = values.token as string;

        pageDispatcher({
            type: 'authenticate', payload: {
                accessToken: token
            }
        });
        const loadSujeto = async () => {
            const sujeto = await perfilG.getDatosSujeto()
            if (sujeto && !sujeto.activarAvisos) {
                pageDispatcher({
                    type: 'show-alta-alert'
                });
            } else if (!sujeto) {
                pageDispatcher({
                    type: 'show-alta-alert'
                });
            }
        }
        loadSujeto();

        const inicio = '/cartero-virtual/deuda-pendiente';
        history.push(inicio);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    return (
        <div>
            <Term component="Global" text="Esta siendo redirigido a la SEDE Electrónica" />
        </div>
    )
}

export default withRouter(EntradaPage);