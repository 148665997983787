import React, { useContext, useEffect, useState, FC, useMemo, useCallback, useReducer } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { WithStyles, withStyles, CircularProgress, Grid, Hidden, Card, CardContent, MenuItem } from '@material-ui/core';
import usePage from 'hooks/page.hook';
import IoC from 'contexts/ioc.context';
import { NotificacionesGateway } from 'gateways/notificaciones.gateway';
import { INotificacion } from '../../gateways/notificaciones.interfaces';
import { IFindAllQuery, IFilterData } from 'gateways/notificaciones.interfaces';
import FiltersActive from './filters.active';
import FiltersDialog from './filters.dialog';
import PanelDrawer from './panel.drawer';
import styles from '../shared/tributos.styles';
import NoContent from 'components/no-content';
import image from '../../resources/no-deuda.png';
import { LiteralsContext } from 'containers/shared/literals';
import { FiltersContext } from 'containers/cartero-virtual/filters.context';
import { FilterDataContext } from './filter.data.context';
import FiltersReducer, { IFiltersState } from 'containers/cartero-virtual/filters.reducer';
import Term from 'components/term';
import { translate } from 'utils/i18n';
import NotificacionCard from './notificacion.card';
import { mdiBell } from '@mdi/js';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';
import { MoreInfoContext } from 'contexts/more_info.context';
import CustomTabs from 'components/custom-tabs';
import TabPanel from 'components/tab-panel';

const numberElementsPerPage = 50;

type Props = WithStyles<typeof styles>;

const Notificaciones: FC<Props> = ({ classes }) => {
    // Global states
    const terms = useContext(LiteralsContext);
    const ioc = useContext(IoC);
    const [, pageDispatcher] = usePage();
    const filtersReducer = useReducer(FiltersReducer, {});
    const [filters, filtersDispatch] = filtersReducer;

    // gateways
    const notificacionesGateway = useMemo(() => ioc.get(NotificacionesGateway) as NotificacionesGateway, [ioc]);

    // local states
    const [page, setPage] = useState(0);
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [notificaciones, setNotificaciones] = useState<INotificacion[]>([]);
    const [loadingNotificaciones, setLoadingNotificaciones] = useState(false);
    const [filterData, setFilterData] = useState<IFilterData>({});
    const [showFilters, setShowFilters] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [notificacionesCount, setNotificacionesCount] = useState(0);
    const [notificacionesPendientes, setNotificacionesPendientes] = useState(0);
    const [, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);

    const [tipoEnvioTAB, setTipoEnvioTAB]= useState<'C'|'N'>('N');
    const tabActive = useMemo(() => tipoEnvioTAB === 'C' ? 1 : 0, [tipoEnvioTAB])
    const [loading, setLoading]= useState(false)

    const hasMore = useMemo(() => notificacionesCount > (page === 0 ? (page + 1) : page) * numberElementsPerPage, [page, notificacionesCount]);
    const noContent = useMemo(() => !loadingNotificaciones && notificaciones.length === 0, [loadingNotificaciones, notificaciones]);

    function buildQueryRequest(newFilters: IFiltersState, newPage: number, tipoEnvio: 'N'|'C'): IFindAllQuery {
        return Object.assign({}, newFilters, {
            esPadre: true,
            skip: newPage * numberElementsPerPage,
            take: numberElementsPerPage,
            tipoEnvio
        }) as any;
    }

    const handleCloseSubMenu = useCallback(() => setOpenSubMenuAnchorEl(null), []);

    const handleShowFilters = useCallback(() => {
        setShowFilters(true);
        handleCloseSubMenu();
    }, [handleCloseSubMenu]);

    const handleHideFilters = useCallback(() => setShowFilters(false), []);

    const handleLoadNotificaciones = useCallback(async (newFilters: IFiltersState, newPage: number) => {
        if (!loadingNotificaciones) {
            setLoadingNotificaciones(true);
            const query = buildQueryRequest(newFilters, newPage, tipoEnvioTAB);

            try {
                const notificacionesReceived = await notificacionesGateway.findAll(query);
                if (notificacionesReceived) {
                    setNotificaciones(newPage === 0 ? notificacionesReceived : notificaciones.concat(notificacionesReceived));
                    setPage(newPage);
                }
            } catch (error) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (error as any).message,
                        variant: 'error',
                    }
                });
            }
            finally {
                setFirstLoad(false);
                setLoadingNotificaciones(false);
            }
        }
    }, [loadingNotificaciones, notificaciones, notificacionesGateway, pageDispatcher, tipoEnvioTAB]);

    const handleNotificacionesPendientes = () => {
        setNotificacionesPendientes(notificacionesPendientes - 1);
    };

    const handleLoadMoreNotificaciones = useCallback(() => {
        if (hasMore) {
            handleLoadNotificaciones(filters, page + 1);
        }
    }, [hasMore, handleLoadNotificaciones, filters, page]);

    const handleToggleSubMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpenSubMenuAnchorEl(event.currentTarget);
    }, []);

    const handleFiltersChanged = useCallback((newFilters: IFiltersState) => {
        setPage(0);
        setFirstLoad(true);
        handleLoadNotificaciones(newFilters, 0);
    }, [handleLoadNotificaciones]);

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info',templateName )
    },[infoDispatch])
    
    // render
    const renderComponent = () => {
        return (
            <div>
                <NoContent
                    image={image}
                    visible={noContent}
                    component='Notificaciones'
                    text='No se han encontrado notificaciones'
                />
                {/*
                    !firstLoad && */ notificaciones.length > 0  && //?
                        <>
                            <Card className={classes.cardContador}>
                                <CardContent className={classes.cardContadorContainer} >
                                    <span className={classes.titleCardContador}>
                                        <Term 
                                            component="Notificaciones" 
                                            text={tipoEnvioTAB === 'C' ? 'Comunicaciones pendientes' :'Notificaciones pendientes'} 
                                            params={[notificacionesPendientes]} 
                                        />
                                    </span>
                                </CardContent>
                            </Card>
                            <InfiniteScroll
                                pageStart={0}
                                initialLoad={false}
                                loadMore={handleLoadMoreNotificaciones}
                                hasMore={hasMore}
                                loader={
                                    <div key="infiniteProgress" style={{ display: hasMore ? 'block' : 'none' }} className={classes.progressContainer}>
                                        <CircularProgress className={classes.progress} />
                                    </div>}
                            >
                                <Grid container>
                                    {notificaciones.map((notificacion: INotificacion, index: number) => (
                                        <NotificacionCard
                                            key={'Notificacion_' + index}
                                            notificacion={notificacion}
                                            handleNotificacionesPendientes={handleNotificacionesPendientes}
                                        />
                                    ))}
                                </Grid>
                            </InfiniteScroll>
                        </>
                        /*:
                        noContent ?
                            null
                            :
                            <div key="progress" className={classes.progressContainer}><CircularProgress className={classes.progress} /></div>
                            */
                }
            </div>
        )
    }

    // EFFECTs
    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiBell,
                title: <Term component="Notificaciones" text={tipoEnvioTAB === 'C' ?'Comunicaciones' :'Notificaciones'} />,
                moreInfoTemplate: tipoEnvioTAB === 'C' ? 'mas_info_comunicaciones' :'mas_info_notificaciones',
                right: (
                    <>
                        <MenuItem button onClick={handleShowFilters}><Term component="Global" text="Filtrar resultados" /></MenuItem>
                        <MenuItem button onClick={() => handleShowMasInfo(tipoEnvioTAB === 'C' ? 'mas_info_comunicaciones' :'mas_info_notificaciones')}>
                            <Term component="Global" text="Mas informacion" />
                        </MenuItem>
                    </>
                )
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher, terms, handleToggleSubMenu, notificaciones, tipoEnvioTAB]);

    useEffect(() => {
        const query = buildQueryRequest(filters, 0, tipoEnvioTAB);
        delete query.skip;
        delete query.take;

        const filterDataTemp = notificacionesGateway.getFilterData(tipoEnvioTAB);
        if (filterDataTemp.tipos) {
            filterDataTemp.tipos.forEach((value) => {
                value.label = translate('Notificaciones', value.label, terms);
            });
        }
        setFilterData(filterDataTemp);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificaciones, terms, tipoEnvioTAB]);

    useEffect(() => {
        (async () => {
            handleFiltersChanged(filters);
            setNotificacionesCount(0);
            setNotificacionesPendientes(0);
            const query = buildQueryRequest(filters, 0, tipoEnvioTAB);
            delete query.skip;
            delete query.take;
            const notificacionesCountResult = await notificacionesGateway.getCount(query);
            query.estados = 2;
            const notificacionesNoLeidas = await notificacionesGateway.getCount(query);
            setNotificacionesPendientes(notificacionesNoLeidas);
            setNotificacionesCount(notificacionesCountResult);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, notificacionesGateway, tipoEnvioTAB]);

    useEffect(() => {
        setNotificaciones([]);
        setPage(0);
        filtersDispatch({ type: 'clear' });
        // handleLoadNotificaciones({}, 0);        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FiltersContext.Provider value={filtersReducer}>
            <FilterDataContext.Provider value={filterData}>
                <div className={classes.root}>
                    <div className={classes.recibosContainer}>
                        <FiltersActive
                            onChange={handleFiltersChanged}
                        />
                        {/* Selector tabs */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '1%', backgroundColor: 'white', borderRadius: '3px' }}>
                            <CustomTabs
                                labelTab1={translate('Notificaciones', 'notificaciones', terms).toUpperCase()}
                                labelTab2={translate('Notificaciones', 'comunicaciones', terms).toUpperCase()}
                                onChange={(e, newValue) => setTipoEnvioTAB(newValue > 0 ? 'C' :'N')}
                                tabActive={tabActive}
                            />
                        </div>
                        <TabPanel value={tabActive} index={0} loading={loading || firstLoad }>{renderComponent()}</TabPanel>
                        <TabPanel value={tabActive} index={1} loading={loading || firstLoad }>{renderComponent()}</TabPanel>

                    </div>
                </div>

                <div className={classes.drawer}>
                    <Hidden mdUp implementation="css">
                        <FiltersDialog
                            open={showFilters}
                            onClose={handleHideFilters}
                            onChange={handleFiltersChanged}
                        />
                    </Hidden>

                    <Hidden smDown implementation="css">
                        <PanelDrawer 
                            tipoEnvio={tipoEnvioTAB}
                            onFiltersChange={handleFiltersChanged}
                        />
                    </Hidden>
                </div>
            </FilterDataContext.Provider>
        </FiltersContext.Provider >
    )
}

export default withStyles(styles)(Notificaciones);