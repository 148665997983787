import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, Grid, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";

import { TSelect } from "containers/DUTI/utils";


import { cloneDeep } from "lodash";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";

import Modal from "components/modal";
import LabelWithInfo from "components/DUTI/label-with-info";

import CheckBox from "components/DUTI/check-box";
import { DocumentosGateway } from "gateways/documentos.gateway";
import ModelGateway from "gateways/model.new.gateway";
import IoC from "contexts/ioc.context";
import FullLoading from "components/full-loading";
import OperacionPLV from "components/Modelos/componentes/operacionPLV";
import FormCalculoPLV from "components/Modelos/componentes/formCalculoPLV";
import { TCalculoSimulador, TDatosPrevios, TOperacionSimulador, TOperacionSimuladorErr } from "./types";
import FormCalculoPLVSimulador from "./formCalculoPLVSimulador";

type TErrors = Record<keyof Omit<TCalculoSimulador,'operaciones'>,boolean > & Record<keyof Pick<TCalculoSimulador,'operaciones'>,TOperacionSimuladorErr[]>
const EMPTY_ERR: TErrors = {
    numOperaciones: false,
    porcentaje: false,
    edadUsufructuaria:false,
    tipoDerecho:false,
    operaciones: [] as TOperacionSimuladorErr[],
    modelo: false,
}

const EMPTY_OPERACION: TOperacionSimulador = {
    fechaAnteriorAdquisicion: undefined,
    porcentajeAnteriorAdquisicion: 0,
    importeAnteriorAdquisicion: undefined
}
const EMPTY_OP_ERR: TOperacionSimuladorErr = {
    fechaAnteriorAdquisicion: false,
    porcentajeAnteriorAdquisicion: false,
    importeAnteriorAdquisicion: false,
}
type TProps = {
    calculo: TCalculoSimulador
    setCalculo: (v:TCalculoSimulador, idGestion:number|null, idSujetoGestion:number|null) => void
    onCancel: () => void
    datosPrevios: TDatosPrevios
    idGestion: number | null;
    idGestionSujeto:number | null;
} & WithStyles<typeof styles>

const CalcPlusvaliaSimuladorAddCalculo : FC<TProps>= ({classes, calculo, setCalculo, onCancel, datosPrevios, idGestion, idGestionSujeto}) => {
    const terms= useContext(LiteralsContext)

     //Gateways
     const ioc = useContext(IoC);
     const documentosGateway: DocumentosGateway = useMemo(() => ioc.get(DocumentosGateway), [ioc]);
     const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
     
    
    const [loading, setLoading]= useState(false) 
    const [showModalCalc, setShowModalCalc]= useState(false)
    const [showPorcentTotalErr, setShowPorcentTotalErr]= useState(false)
    const [showAllOrAnyImportErr, setShowAllOrAnyImportErr]= useState(false)
    const [showReqDocsErr, setShowReqDocsErr]= useState(false)

    const [errorCalcPLV, setErrorCalcPLV]= useState(false) 
    const [local_idGestion,setIdGestion] = useState<number|null>(null)
    const [local_idGestionSujeto, setIdGestionSujeto] = useState<number|null>(null)
    const [localInfo, setLocalInfo]= useState<TCalculoSimulador>({} as TCalculoSimulador)
    const [localInfoErr, setLocalInfoErr]= useState<TErrors>(EMPTY_ERR)


    // desde modal Calular si no se requiren documentos
    //const autoSave =  () => setCalculo(localInfo, local_idGestion, local_idGestionSujeto)

    const onSave = () => { 
        setCalculo(localInfo, local_idGestion, local_idGestionSujeto);
        setLocalInfoErr(EMPTY_ERR)
        setLocalInfo({} as TCalculoSimulador)
    }

    const onBack = () => {
        setLocalInfoErr(EMPTY_ERR)
        setLocalInfo({} as TCalculoSimulador)
        onCancel()
    }

    const onBackOperaciones = () => {
        setLoading(true)
        const newOP = localInfo.operaciones.map(op => ({...op, idDocAnteriorAdquisicion: undefined }))

        setLocalInfo(curr => ({...curr, 
            modelo: undefined,
            operaciones: newOP,
        }))
        setLoading(true)
    }

    const onCheckInfoToCalculate= async () => {
        const newErr = cloneDeep(localInfoErr.operaciones)
        let sumaPorcent=0
        const withImport:boolean[] = []
        const checkedOp = localInfo.operaciones.map((op,idx) =>{
            newErr[idx]={
                fechaAnteriorAdquisicion: op.fechaAnteriorAdquisicion === undefined,
                porcentajeAnteriorAdquisicion: op.porcentajeAnteriorAdquisicion === undefined || op.porcentajeAnteriorAdquisicion === 0,
                importeAnteriorAdquisicion: false,
            }
            
            if(Object.values(newErr[idx]).includes(true)){
                return false
            } else{
                sumaPorcent=sumaPorcent+op.porcentajeAnteriorAdquisicion
                withImport.push(op.importeAnteriorAdquisicion !== undefined && op.importeAnteriorAdquisicion > 0) 
                return true
            }
        })
        const errImport = checkedOp.includes(false) 
            ? false 
            : (withImport.length> 0 && withImport.includes(true) && withImport.includes(false))? true: false
        
        if(!(checkedOp.includes(false)) && (sumaPorcent === 100) && !errImport){
            // ALL OK
            const result = await modelGateway.calcularPlusvaliaSimulador(localInfo, datosPrevios)
            console.log(result)
            if(result.ok){
                setLocalInfo({...result.data})
            } else {
                setErrorCalcPLV(true)
            }
            setShowModalCalc(true)
            
        } else {
            if(sumaPorcent !== 100){
                setShowPorcentTotalErr(true)
            }
            if(errImport){
                setShowAllOrAnyImportErr(errImport)
            }

            setLocalInfoErr(curr => ({...curr,
                operaciones: newErr
            }))
        }

    }

    useEffect(() => {
        setLocalInfo(calculo)
        const EMPTY_INFO_ERR: TErrors = {
            ...EMPTY_ERR, 
            operaciones: Array(calculo.operaciones.length).fill({...EMPTY_OP_ERR})
        }
        setLocalInfoErr(EMPTY_INFO_ERR)
        setIdGestion(idGestion)
        setIdGestionSujeto(idGestionSujeto)
    }, [calculo])

    useEffect(() => {
        setIdGestion(idGestion)
        setIdGestionSujeto(idGestionSujeto)
    }, [idGestion, idGestionSujeto])
    
    return (
        <div style={{marginLeft: 10}}>
            <FullLoading loading={loading} />
            <Grid container direction='column' >

                <Grid item container direction="row" justify="space-between" className={classes.headerDerecho}>
                    <Grid item xs={3} className={[classes.row].join(' ')}>
                        <Typography className={classes.infotext}>{translate('Global','derechoTransmision', terms)}</Typography>
                        <Typography className={classes.infotext} style={{paddingRight:5 , paddingLeft: 5}}>-</Typography>
                        <Typography className={classes.infotext}><b>{localInfo.tipoDerecho?.nombre}</b></Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.row}>
                        <Typography className={classes.infotext}>{translate('Plusvalias','numOperacionesShort2', terms)}: <b>{localInfo.numOperaciones}</b></Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.row}>
                        <Typography className={classes.infotext}>{translate('DUTI','porcentajeTitularidad', terms)}: <b>{localInfo.porcentaje} %</b></Typography>
                    </Grid>

                    {localInfo.edadUsufructuaria ?                     
                    <Grid item xs={3} className={classes.row}>
                        <Typography className={classes.infotext}>
                            {translate('Plusvalias',localInfo.tipoDerecho?.id === 'usufructo-temporal' ? "duracionUsufructo": "edadUsufructuaria", terms)}: <b>{localInfo.edadUsufructuaria} {translate('Plusvalias','anyos', terms).toLowerCase()}</b>
                        </Typography>
                    </Grid>
                    : null
                    }



                </Grid>

                <Grid item>
                    <LabelWithInfo type="text" fieldTerm={'text_calc_operaciones'} componentIG={"Plusvalias"} moreInfo={{template: 'plusvalias_info_calc_operaciones',size:'xs'}} />
                    <div style={{minHeight: 30, marginBottom:5, width: '100%'}}>
                        {showPorcentTotalErr && 
                            <Typography color='error'>
                                {translate('DUTI','operaciones_percentaje_not_sum_100',terms)}
                            </Typography>
                        }   
                        {showAllOrAnyImportErr && 
                            <Typography color="error">
                                {translate('DUTI','operaciones_not_all_importe_anterior',terms)}
                            </Typography>
                        }
                        {showReqDocsErr && 
                        <Typography color="error">
                            {translate('Plusvalias','operaciones_not_all_doc_anterior',terms)}
                        </Typography>
                        }
                    </div>
                    <div style={{width: '100%'}}>
                        {localInfo.operaciones?.map( (op, idx) => {
                            return (
                                <OperacionPLV 
                                    key={idx}
                                    renderType={'form'}
                                    initialValue={{...op}}
                                    errors={localInfoErr.operaciones[idx]}
                                    onChange= {(v: TOperacionSimulador, vErr: TOperacionSimuladorErr, resetModelo:boolean) => {
                                        setShowPorcentTotalErr(false)
                                        setShowAllOrAnyImportErr(false)
                                        setShowReqDocsErr(false)
                                        const newOp = cloneDeep(localInfo.operaciones)
                                        const newOpErr = cloneDeep(localInfoErr.operaciones)
                                        newOp[idx]=v
                                        newOpErr[idx]=vErr
                                        setLocalInfo(curr => resetModelo
                                            ? ({...curr, operaciones:newOp, modelo: undefined, declaracionResponsable: undefined })
                                            : ({...curr, operaciones:newOp})
                                        )
                                        setLocalInfoErr(curr => ({...curr, operaciones:newOpErr}))
                                    }}
                                    maxDate= {datosPrevios.fechaTransmision}
                                    hideImporte={((datosPrevios.valorDeclarado === undefined) || (datosPrevios.valorDeclarado === 0)) ? true : false}
                                />
                            )
                        })
                        }
                    </div>
                   
                </Grid>
                {localInfo.modelo === undefined &&
                    <Grid item style={{width: '100%', marginTop:10, marginBottom: 20}}>
                        <Button 
                            style={{marginRight:10}}
                            variant="contained" 
                            onClick={onBack}
                        >
                            {translate('Global', 'Atras', terms)}
                        </Button>
                        <Button 
                            
                            variant="contained" color="primary"
                            onClick={onCheckInfoToCalculate}
                        >
                            {translate('Global', 'btnCalcular', terms)}
                        </Button>          
                    </Grid>
                }
                {localInfo.modelo !== undefined &&
                    <Grid item style={{width: '100%', marginTop:10, marginBottom: 20}}>
                        <Button 
                            style={{marginRight:10}}
                            variant="contained" 
                            onClick={onBackOperaciones}
                        >
                            {translate('Global', 'Atras', terms)}
                        </Button>
                        <Button 
                            variant="contained" color="primary"
                            onClick={onSave}
                        >
                            {translate('DUTI', 'btn_confirmar', terms)}
                        </Button>          
                    </Grid>
                }
            </Grid>

            <Modal
                fullWidth
                maxWidth="md"
                open={showModalCalc}
                title={translate('DUTI','metodo_calculo_m081',terms).toUpperCase()}
                renderContent={() => (
                    <FormCalculoPLVSimulador
                        info={localInfo}  
                        error={errorCalcPLV}
                        porcentaje={calculo.porcentaje}
                        derecho={calculo.tipoDerecho?.nombre}
                        onConfirm={() => {
                            setShowModalCalc(false)
                            onSave() 
                           
                        }}  
                        onCancel={() => { 
                            setShowModalCalc(false)
                            setLocalInfo(curr=> ({...curr, modelo: undefined}))
                        }} 
                    />
                )}
            />
        </div>
    )
}

const styles =  (theme: Theme): StyleRules => ({
    infotext: {
        marginRight: 10
    },
    row:{
        display: 'flex',
        flexDirection: 'row'
    },
    headerDerecho: {
        borderBottom: 'black solid 2px ',
        marginTop: 15,
        marginRight: 10
    },
})

export default withLiterals(['DUTI','Plusvalias', 'Global'])(withStyles(styles)(CalcPlusvaliaSimuladorAddCalculo))