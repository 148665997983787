import { Dispatch, ReducerState, ReducerAction, createContext } from "react";
import { ITributo } from "gateways/tributos.interfaces";
import { ISelectionReducerConfigType } from "containers/cartero-virtual/selection.reducer";

type TributosReducer = ReturnType<ISelectionReducerConfigType<ITributo>>;
type SelectionReducerState = ReducerState<TributosReducer>;
type SelectionDispatchAction = Dispatch<ReducerAction<TributosReducer>>;
type SelectionContextType = [SelectionReducerState, SelectionDispatchAction];

export const DomiciliacionesSelectionContext = createContext<SelectionContextType>([{ selected: [] as any[] }, (state: any) => state]);
