import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { INotificacion, IFindAllQuery, IFilterData, IDocumento, IDiasCortesia } from "./notificaciones.interfaces";
import { IFilterOption } from "utils/interfaces";
import { ISignature } from "./afirma.interfaces";

@inject('axios')
export class NotificacionesGateway {
    constructor(
        private axios: AxiosInstance
    ) {

    }

    public async findAll(query?: IFindAllQuery): Promise<INotificacion[]> {
        const response = await this.axios.get('notificaciones', {
            params: {
                ...query,
                expires: Date.now(),
            }
        });

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getCount(query?: IFindAllQuery): Promise<number> {
        const response = await this.axios.get('notificaciones/count', {
            params: {
                ...query,
                expires: Date.now(),
            }
        });

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getDocumentos(idMensaje: number): Promise<IDocumento[]> {
        const response = await this.axios.get('notificaciones/getDocumentos/' + idMensaje);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async iniciarFirmaDocumento(idDocumento: number): Promise<number> {
        const response = await this.axios.get('notificaciones/iniciarFirmaDocumento/' + idDocumento);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async firmaDocumentosMensaje(signature: ISignature): Promise<boolean> {
        const response = await this.axios.post('notificaciones/firmaDocumentosMensaje', signature);

        if (response.status !== 201) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getDocumento(idDocumento: number): Promise<Blob> {
        const response = await this.axios.get('notificaciones/getDocumento/' + idDocumento, {
            responseType: 'blob',
        });

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getDocumentoNotificacion(idFirma: number): Promise<IDocumento> {
        const response = await this.axios.get('notificaciones/getDocumentoNotificacion/' + idFirma);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async generarDocumentoAcuse(signature: ISignature): Promise<number> {
        const response = await this.axios.post('notificaciones/generarDocumentoAcuse', signature);

        if (response.status !== 201) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getDiasCortesia(ejercicio: number): Promise<IDiasCortesia> {
        const response = await this.axios.get('notificaciones/getDiasCortesia/' + ejercicio);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async registerSignature(idFirma: number, documento: IDocumento): Promise<boolean> {
        const response = await this.axios.post('notificaciones/registerSignature/' + idFirma, documento);

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async updateMensajeLeido(idMensaje: number): Promise<boolean> {
        const response = await this.axios.post('notificaciones/updateMensajeLeido/' + idMensaje);

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async updateDiasCortesia(ejercicio: number, diasCortesiaSeleccionados: string[]): Promise<boolean> {
        const response = await this.axios.post('notificaciones/updateDiasCortesia', {
            ejercicio,
            diasCortesiaSeleccionados
        });

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async actualizarCanalesNotificaciones(activar: boolean, email: string, movil: string): Promise<boolean> {
        const response = await this.axios.post('notificaciones/actualizarCanalesNotificaciones', {
            activar,
            email,
            movil
        }
        );

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async bajaServicioNotificaciones(): Promise<Blob> {
        const response = await this.axios.post('notificaciones/bajaServicioNotificaciones', {
            accion: '0',
        }, {
            responseType: 'blob',
        }
        );

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async activarServicioAvisos(accion: number): Promise<boolean> {
        const response = await this.axios.post('notificaciones/activar-servicio-avisos', {
            accion,
        });

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async actualizarDatosContactoNotificacion(email: string, movil: string): Promise<boolean> {
        const response = await this.axios.post('notificaciones/actualizarDatosContactoNotificacion', {
            email,
            movil,
        }
        );

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async reenviarEmail(emailNotificacion: string, movilNotificacion: string, accion: number): Promise<boolean> {
        const response = await this.axios.post('notificaciones/reenviar-email', {
            accion,
            email: emailNotificacion,
            movil: movilNotificacion,
        }
        );

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async checkCodigoValidacion(accion: string, referencia: string, codigoSms: string): Promise<any> {
        const response = await this.axios.post('notificaciones/checkCodigoValidacion', {
            accion,
            referencia,
            codigoSms,
        }
        );

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async activarServicioNotificaciones(accion: string, referencia: string, codigoSms: string): Promise<Blob> {
        const response = await this.axios.post('notificaciones/activarServicioNotificaciones', {
            accion,
            referencia,
            codigoSms,
        }, {
            responseType: 'blob',
        }
        );

        if (response.status !== 201 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getReferenciaNotificacionDocumento(idMensaje: number): Promise<string> {
        const response = await this.axios.get('notificaciones/getReferenciaNotificacionDocumento/' + idMensaje);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async validarEmail(email: string): Promise<boolean> {
        const response = await this.axios.get('notificaciones/validar-email?email=' + encodeURIComponent(email));

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public getFilterData(tipoEnvio: 'N'|'C'): IFilterData {
        const tipos = new Array<IFilterOption<number>>();
        tipos.push({ value: 1, label: `${tipoEnvio === 'C'? 'Comunicación' : 'Notificación'} pendiente` });
        tipos.push({ value: 2, label: `${tipoEnvio === 'C'? 'Comunicación' : 'Notificación'} aceptada` });
        tipos.push({ value: 3, label: `${tipoEnvio === 'C'? 'Comunicación' : 'Notificación'} rechazada` });
        const meses = new Array<IFilterOption<number>>();
        meses.push({ value: 1, label: '1' });
        meses.push({ value: 2, label: '2' });
        meses.push({ value: 6, label: '6' });
        meses.push({ value: 12, label: '12' });

        return {
            tipos,
            meses,
        }
    }
}