// LIBRARY IMPORTS
import { FC } from 'react';
import { Typography, List, ListItem, ListItemText, Divider, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
// LOCAL IMPORTS
import { esConceptoVivienda } from 'utils/concepto.vivienda';
import { ITributo } from 'gateways/tributos.interfaces';
import Term from 'components/term';

const themeProvider = createMuiTheme({
    overrides: {
        MuiListItem: {
            gutters: {
                paddingLeft: 0,
                paddingRight: 0,
            }
        },
        MuiTypography: {
            body1: {
                fontWeight: 500,
                fontSize: 14,
            }
        }
    }
});

interface IProps {
    tributos: ITributo[];
}

const TributoTable: FC<IProps> = ({ tributos }) => {
    return (
        <MuiThemeProvider theme={themeProvider}>
            <Typography variant="h6">
                <Term component="Domiciliaciones" text="Tributos seleccionados" />
            </Typography>
            <List>
                {tributos.map(tributo => (
                    <div key={tributo.idRecibo}>
                        <ListItem key={tributo.idRecibo}>
                            {
                                esConceptoVivienda(tributo.concepto.id)
                                    ?
                                    <ListItemText primary={tributo.concepto.nombre} secondary={tributo.direccionTributaria} />
                                    :
                                    <ListItemText primary={tributo.concepto.nombre} secondary={tributo.objeto} />
                            }
                        </ListItem>
                        <Divider />
                    </div>
                ))}
            </List>
        </MuiThemeProvider>
    );
}

export { TributoTable };