import { StyleRules, Theme } from "@material-ui/core";

const generic_styles =  (theme: Theme): StyleRules => ({
    title: {
        fontWeight: 400,
        fontSize: 16,
        flex: 1
    },
    row: {
        display:'flex',
        flexDirection: 'row',
    },
    rowBtn: {
        display:'flex',
        flexDirection: 'row',
        flexGrow:1,
        justifyContent: 'space-between'
    },
    // Avisos Modelo
    containerAvisoModelo: {
        marginTop: 30,
        border: '1px solid red',
        display: 'flex',
        padding: 10,
        borderRadius: 8
    },
})

export default generic_styles