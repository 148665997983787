import {FC, useContext, useEffect, useMemo, useState} from "react";
import { Typography, Grid, withStyles, WithStyles, Button } from "@material-ui/core";
import { translate } from "utils/i18n";
import duti_styles from "containers/DUTI/styles";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import { IDocumentoAtib } from "gateways/duti.interface";


import { CustomError } from "utils/custom.error";
import usePage from "hooks/page.hook";
import Modal from "components/modal";
import FullLoading from "components/full-loading";
import InputDate from "components/DUTI/input-date";
import InputSearch from "components/DUTI/input-search";
import InputText from "components/DUTI/input-text";
import TypedInputNumber from "components/DUTI/typed-input-number";

export interface IInfo {
    fecha: Date ;
    anyo: number;
    numeroProtocolo: string ;
    nifNotario: string ;
    notario: string ;
}

export interface IModalCheckEscrituraNRDProps {
    show: boolean,
    info: IInfo,
    onChecked: (checked: boolean, nrd: string|undefined) => void,
    onClose: () => void,

}

const ModalCheckEscrituraNRD: FC<IModalCheckEscrituraNRDProps> = ({ show,info, onChecked, onClose}) => {
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext);
  
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const [NRD, setNRD] = useState<string|undefined>(undefined)
    const [NRDResponse, setNRDResponse] = useState<{ok:boolean, msg:string} | undefined>(undefined);
    const [loading, setLoading] = useState(false)

    const checkNRD = async () => {
        try {
            if(NRD){
                setLoading(true);
                const result = await dutiGateway.checkNRD(info.fecha, info.anyo, info.numeroProtocolo, info.nifNotario, NRD );
                if(result === false){
                    setNRD(undefined);
                    setNRDResponse( { ok: false, msg: translate('DUTI', 'check_nrd_not_valid', terms)});
                } else {
                    setNRDResponse( { ok: true, msg: ''});
                }
                return result;
            } else {
                return false;
            }
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Global','error_check_nrd', terms),
                    variant: "error"
                }
            });
            return false
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(show){
            setNRD(undefined)
        }
    }, [show])

    return (    
        <Modal open={show} fullWidth maxWidth='md'
            title={translate('DUTI', 'DT_docTransmision_title', terms)}
            renderContent={ () => (
                <>
                    <FullLoading loading={loading} animation={{type:'intricom',name:'DUSTI'}}/>
                    <Grid container direction='column' spacing={1} style={{paddingRight: 20, paddingLeft: 20}} >
                        <Typography>{translate('DUTI','nrd_to_see_escritura',terms)}</Typography>
                        <InputSearch
                            fieldTerm='NRD'
                            value={NRD}
                            onChangeValue={(v: string|undefined, err: boolean) => {
                                setNRD(v)
                            }}
                            onSearch={async() => { 
                                const checked = await checkNRD()
                                console.log('checked' ,checked)
                                onChecked(checked, NRD)
                            }}
                            required={false}
                        />
                        {NRDResponse && NRDResponse.msg !== ''&&
                            <Typography color="error">{NRDResponse?.msg}</Typography>
                        }

                    </Grid>
                </>
            )}
            renderActions={() => (
                <div >
                    <Button style={{marginRight: 15}}
                            variant="contained" color="inherit"
                            onClick={onClose} 
                            disabled={loading}
                        >
                        {translate('DUTI', 'btn_close',terms)}
                    </Button>
                
                </div>
            )}
        /> 
    )   

}

export default  withLiterals(['DUTI'])(ModalCheckEscrituraNRD);