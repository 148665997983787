import React, { FC, useEffect, useState, useMemo, useContext } from "react";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { makeStyles, CardContent, Card, Grid, FormControl, InputLabel, Input, CircularProgress } from "@material-ui/core";
import usePage from "hooks/page.hook";
import Term from "components/term";
import { mdiShieldAccount } from "@mdi/js";
import { ISujeto } from "gateways/perfil.interfaces";
import { SujetosGateway } from "gateways/sujetos.gateway";
import IoC from "contexts/ioc.context";
import { translate } from "utils/i18n";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 0.5rem',
        flex: 1,
    },
    contenido: {
        marginBottom: '0.5rem',
    },
    formControl: {
        marginBottom: 10,
        display: 'block',
        '& > div': {
            width: '100%',
        },
        '& > button': {
            width: '100%',
        }
    },
    [theme.breakpoints.up('md')]: {
        title: {
            padding: '0 20px'
        },
        formContainer: {
            '& > .MuiGrid-item': {
                padding: '0 20px'
            }
        },
    }
}));

const Registro: FC = () => {
    const classes = useStyles();
    const [, pageDispatcher] = usePage();
    const terms = useContext(LiteralsContext);
    const [sujeto, setSujeto] = useState<ISujeto | null>(null);
    const [loading, setLoading] = useState(false);
    const ioc = useContext(IoC);
    const perfilG = useMemo(() => ioc.get(SujetosGateway) as SujetosGateway, [ioc]);

    useEffect(() => {
        const loadPage = async () => {
            setLoading(true);
            try {
                // const now = new Date();
                const sujetoLocal = await perfilG.getDatosSujeto();
                if (sujetoLocal) {
                    setSujeto(sujetoLocal);
                } else {
                    pageDispatcher({
                        type: 'show-notification',
                        payload: {
                            message: translate('Perfil', 'Error datos sujeto', terms),
                            variant: 'error',
                        }
                    });
                }
                // const diasCortesiaLocal = await notificacionesGateway.getDiasCortesia(now.getFullYear());
                // setDiasCortesia(diasCortesiaLocal);                
            }
            catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoading(false);
            }
        }
        loadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perfilG, pageDispatcher]);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiShieldAccount,
                title: <Term text="Registro de usuario" />,
            },
            menu: true,
        });
    }, [pageDispatcher]);


    return (
        <div className={classes.root}>
            {
                loading || sujeto === null
                    ? 
                    <div className={classes.centerContent}>
                        <CircularProgress size={35} />
                    </div>
                    :
                    <>
                        <div className={classes.contenido}>
                            <Card>
                                <CardContent>
                                    <h3 className={classes.title}><Term component="Acceso" text="Datos Usuario" /></h3>
                                    <Grid container className={classes.formContainer}>
                                        <Grid item xs={8}>
                                            <Grid container item xs={12} spacing={3}>
                                                <Grid item xs>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel htmlFor="adornment-name">
                                                            <Term component="Global" text="Nombre" />
                                                        </InputLabel>
                                                        <Input
                                                            id="adornment-name"
                                                            value={sujeto.nombre}
                                                            inputProps={{
                                                                tabIndex: 1,
                                                            }}
                                                            readOnly={true}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs>
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel htmlFor="adornment-nif">
                                                            <Term component="Global" text="Nif" />
                                                        </InputLabel>
                                                        <Input
                                                            id="adornment-nif"
                                                            value={sujeto.nif}
                                                            inputProps={{
                                                                tabIndex: 2,
                                                            }}
                                                            readOnly={true}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </div>
                    </>
            }
        </div>
    )
};

export default withLiterals(['Acceso'])(Registro);