import React, {FC, useContext} from "react";

import { RadioGroup, FormControlLabel, Radio, FormControl, withStyles, WithStyles, FormHelperText } from "@material-ui/core";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import duti_styles from "containers/DUTI/styles";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import LabelWithInfo, { TLabelType } from "./label-with-info";


type TBoolRadioOption = { id: boolean, term: string};

interface IBooleanRadioButton extends WithStyles {
    options: TBoolRadioOption[],
    fieldTerm: string,
    value: boolean | undefined,
    onChangeValue: (v:boolean) => void,
    moreInfo?: {
        template: string, size?: TInfoTemplateSize
    },
    row?: boolean,
    error?:boolean,
    errorMsg?:string,
    labelAs?: TLabelType | 'notLabel',
    componentIG?: string
};


const BooleanRadioButton : FC<IBooleanRadioButton>= ({classes, options, fieldTerm, value, onChangeValue, moreInfo, row, error, errorMsg, labelAs='field',componentIG }) => {
    const terms = useContext(LiteralsContext);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeValue(event.target.value === 'true'? true: false);
    }
    
    return (
        <FormControl component="fieldset">
            { labelAs !== 'notLabel' && 
                <LabelWithInfo type={labelAs} fieldTerm={fieldTerm} moreInfo={moreInfo} componentIG={componentIG} />
            }
            <RadioGroup value={ value?.toString() ?? ''} row={row} onChange={handleChange}>
            {options.map( o => (
                <FormControlLabel style={{marginRight: 20}}
                    key={o.id.toString()} 
                    value={o.id.toString()} 
                    control={<Radio color="primary" />} 
                    label={translate(componentIG ??'DUTI',o.term, terms)} 
                />
            ))}
            </RadioGroup>
            {error && <FormHelperText error>{errorMsg}</FormHelperText>}
        </FormControl>
    )
}

export default withLiterals(['DUTI','Global', 'Plusvalias'])(withStyles(duti_styles)(BooleanRadioButton));