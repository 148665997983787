import React, { FC, useContext } from "react";

import { Grid, StyleRules, Theme, WithStyles, withStyles } from "@material-ui/core";
import { TextField } from "components/text-field";
import { translate } from "utils/i18n";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";


import GenericInputText from "components/generic-input-text";
import TypedInputNumber from "components/generic-input-number/typed-input-number";
import { validateEmail } from "components/generic-input-text/utils";
import GenericRadioButton, { IGenericRadioButtonOption } from "components/generic-radio-button";

export type TContact = {
    telefono?: string 
    email?: string 
}


interface ContactoProps extends WithStyles<typeof styles> {
    telefono: string | undefined;
    email: string | undefined;
    onChange: (key: keyof TContact, value: string|undefined, err: boolean) => void,
    required?: {telefono: boolean, email: boolean}
    labelTelefono?: string,
    labelEmail?: string,
    componentIG?: string,
    prefixedTLF?:boolean
};

const Contacto: FC<ContactoProps> = ({
    classes,  telefono, email, onChange,required,
    labelTelefono='telefono', labelEmail='email', componentIG='GenericComponents',
    prefixedTLF=false
}) => {
    const terms =useContext(LiteralsContext);

    
    return (
        <Grid container direction="row" alignItems="center" justify="space-between">
            
                <TypedInputNumber
                    componentIG={componentIG}
                    labelTerm={labelTelefono}
                    value={telefono ? Number(telefono): undefined}
                    type={prefixedTLF ? "prefixed-tlf":"tlf"}
                    required={required?.telefono ?? false}
                    onChangeValue={(v: number|undefined, err: boolean) =>  onChange('telefono', v?.toString(), err)}
                />
                <GenericInputText 
                    componentIG={componentIG}
                    labelTerm={labelEmail} 
                    value={email} 
                    onChangeValue={(v: string|undefined, err: boolean) =>  onChange('email', v, err)}
                    className={classes.input360}
                    extraValidation={validateEmail}
                    required={required?.email}
                    //disabled
                    //error={}
                />




            
        </Grid>

    )
};

const styles = (theme: Theme): StyleRules => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    input360: {
        width: 360
    }
});
export default withLiterals(ALL_LITERALS)(withStyles(styles)(Contacto));