import {FC, useContext, useEffect, useMemo, useState} from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import usePage from "hooks/page.hook";
import duti_styles from "./styles";
import StepperDuti, { IDUTIStepStepper, TDutiStepsContent, THeaderStepper, } from "components/DUTI/stepper-duti";
import DatosTransmision from "./steps/DatosTransmision";
import { TNameStep } from "./steps/types";
import { cloneDeep } from "lodash";
import { useHistory, useLocation } from 'react-router';
import FullLoading from "components/full-loading";
import DUTIGateway from "gateways/duti.gateway";
import IoC from "contexts/ioc.context";
import { IDUTIstep, IDeclaracion } from "gateways/duti.interface";
import PasarelaDusti from "./steps/pasarela.dusti";
import JustificantesDusti from "./steps/justificantes.dusti";
import TramitesGenerados from "./steps/TramitesGenerados";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import useBlockNavigation from "hooks/use-blocker.hook";
import { CustomError } from "utils/custom.error";
import { AlertsContext } from "contexts/alerts.context";

type Props = { DUSTI: IDeclaracion | null } & WithStyles<typeof duti_styles>

const DUTIComponent: FC<Props> = ({ DUSTI }) => {
    const location = useLocation();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);

    const history = useHistory<{ notBlockNav: boolean }>();

    const terms = useContext(LiteralsContext)

    const [loading, setLoading] = useState(false);
    const [stepsBBDD, setStepsBBDD] = useState<IDUTIstep[]>([])
    const [steps, setSteps] = useState<IDUTIStepStepper[] | null>(null)
    const [stepsContent, setStepsContent] = useState<TDutiStepsContent[] | null>(null)

    const [headerStepper, setHeaderStepper] = useState<THeaderStepper | null>(null)

    //Si es alta recupera IdDeclaracion del primer Step
    const [idDeclaracion, setIdDeclaracion] = useState<number>(-1)

    const [shouldBlock, setShouldBlock] = useState(true);

    /** Blocker NAV Variables */
    useBlockNavigation(
        shouldBlock,
        translate('DUTI', 'confirm_cancelDUTI_user_choice', terms),
        'warning',
        translate("DUTI", "si", terms).toUpperCase(),
        translate("DUTI", "no", terms).toUpperCase()
    );

    const redirectToDustiConsulta = () => {
        history.push("/tributos/DUTI/consulta")//,{notBlockNav: true})
    };


    const handleNextStep = (p_steps: IDUTIStepStepper[] | null) => {
        if (p_steps) {
            const step = p_steps.find(s => s.active === true);
            if (step) {
                const stepIdx = p_steps.findIndex(s => s.termTitle === step.termTitle);
                const nextStepIdx = stepIdx + 1;
                const newSteps = cloneDeep(p_steps);
                //Cambiar current step active=FALSE
                newSteps[stepIdx] = { ...newSteps[stepIdx], active: false, status: 'FIN' };
                //Cambiar estado de next STEP => 'INI' + active=TRUE
                newSteps[nextStepIdx] = { ...newSteps[nextStepIdx], active: true, status: 'INI' };
                if (stepIdx + 1 > p_steps.length) {
                    // FIN de la DUSTI
                }
                setSteps(newSteps);
            }
        }
    };

    const handlePrevStep = (p_steps: IDUTIStepStepper[] | null) => {
        if (p_steps) {
            const step = p_steps.find(s => s.active === true);
            if (step) {
                const stepIdx = p_steps.findIndex(s => s.termTitle === step.termTitle);
                if (stepIdx === 0) {
                    // Primer PASO
                } else {
                    const nextStepIdx = stepIdx > 0 ? stepIdx - 1 : 0;
                    const newSteps = cloneDeep(p_steps);
                    //Cambiar current step active=FALSE
                    newSteps[stepIdx] = { ...newSteps[stepIdx], active: false, status: newSteps[stepIdx].status === 'FIN' ? 'EDT' : 'INI' };
                    //Cambiar estado de next STEP => 'INI' + active=TRUE
                    newSteps[nextStepIdx] = { ...newSteps[nextStepIdx], active: true, status: 'EDT' };

                    setSteps(newSteps);
                }
            }
        }
    }



    // -------------------------------------------------------------------------------------------------------------------------------------------------

    const get_DUTI_STEPS_CONTENT = (contentSteps: IDUTIstep[], idDeclaracion: number, steps: IDUTIStepStepper[], activDeclPaso: number) => {
        const out: TDutiStepsContent[] = contentSteps.map(step => ({
            termTitle: step.Nombre as TNameStep,
            idPaso: step.id,
            moreInfoTemplate: step.Nombre === 'TramitesGenerados' ? 'mas_info_tramites_generados' : undefined,
            component: () => {
                switch (step.Nombre) {
                    case 'DatosTransmision':
                        return (
                            <DatosTransmision
                                idDeclaracion={DUSTI ? DUSTI.id : idDeclaracion}
                                idPaso={step.id}
                                steps={steps}
                                idPasoDeclaracionActivo={activDeclPaso}
                                setFinished={(id: number, steps: IDUTIStepStepper[] | null) => {
                                    setIdDeclaracion(id)
                                    handleNextStep(steps)
                                }}
                                onExit={redirectToDustiConsulta}
                                onFirstSave={headerInfo => setHeaderStepper(headerInfo)}
                                setShouldBlock={setShouldBlock}
                            />
                        )
                    case 'TramitesGenerados':
                        return (
                            <TramitesGenerados
                                idDeclaracion={idDeclaracion}
                                idPaso={step.id}
                                steps={steps}
                                handleBack={handlePrevStep}
                                setFinished={handleNextStep}
                                onExit={redirectToDustiConsulta}
                                setShouldBlock={setShouldBlock}
                            />
                        )
                    case 'FinalizacionPago':
                        return (
                            <PasarelaDusti
                                idDeclaracion={idDeclaracion}
                                steps={steps}
                                handleNextStep={handleNextStep}
                                setIdDeclaracion={setIdDeclaracion}
                                setHeaderStepper={setHeaderStepper}
                            />
                        )
                    case 'Justificantes':
                        return (
                            <JustificantesDusti
                                idDeclaracion={idDeclaracion}
                                setShouldBlock={setShouldBlock}
                            />
                        )
                    default:
                        return <>NOT found</>;
                }
            }

        }))

        return out
    };

    const checkStepper = (stepsStepper: IDUTIStepStepper[]): IDUTIStepStepper[] => {
        const query = new URLSearchParams(location.search);

        // Si el acceso al alta se produce desde la entidad bancaria, se muestra el paso de finalización de pago
        if (query.get('idOperacion')) {
            stepsStepper.map(s => {
                s.active = (s.termTitle === 'FinalizacionPago');
            });
        }
        return stepsStepper;
    }

    const initDuti = async () => {
        setLoading(true);

        try {
            const steps  = await dutiGateway.getPasosByIdPasoPadre(undefined) as IDUTIstep[]
            setStepsBBDD(steps as IDUTIstep[]);
            // Default active step 1 
            let ActiveStep = 1;
            let idDusti = 0;
            let estadoDusti = 'INI';
            if(DUSTI === null){
                idDusti = -1;
            } else {
                idDusti = DUSTI.id;
                estadoDusti = DUSTI.estado.codigo;
                const infoDusti = await dutiGateway.getDetalleDeclaracion(DUSTI.id);
                setHeaderStepper({
                    refCat: infoDusti.DatosTransmision.datosPrevios.infoCatastral?.refCatastral ?? ' - ',
                    dir: infoDusti.DatosTransmision.datosPrevios.infoCatastral?.direccion ?? ' - ',
                    urlCatastro: infoDusti.DatosTransmision.datosPrevios.infoCatastral?.urlCatastro ?? ' - ',
                    muni: infoDusti.DatosTransmision.datosPrevios.municipio?.code
                });
                let parentStep = steps.find( stp => stp.id === DUSTI.paso.id);
                if(parentStep === undefined){
                    parentStep = await dutiGateway.getParentOfSubstep(DUSTI.paso.id);
                }
                if(parentStep !==undefined){ ActiveStep = parentStep.Orden }
            }

            const stepsStepper: IDUTIStepStepper[] = steps.map( s => { 
                let active = false;

                if (estadoDusti === 'PPA' || estadoDusti === 'FIN') {
                    if (s.Nombre === 'Justificantes') {
                        active = true; 
                    }
                } else {
                    active = s.Orden === ActiveStep;
                }
                
                const status = ActiveStep > s.Orden
                    ? 'FIN'
                    : ActiveStep === s.Orden ? 'INI': null;
                return ({
                    idPaso: s.id, 
                    order: s.Orden,  
                    termTitle: s.Nombre as TNameStep, 
                    active: active, 
                    status: status,
                });
            });
            
            setSteps(checkStepper(stepsStepper));
            setIdDeclaracion(idDusti);
            setStepsContent(get_DUTI_STEPS_CONTENT(steps as IDUTIstep[], idDusti, stepsStepper, ActiveStep));
        } catch (error) {
            if (error instanceof CustomError && error.code === 403) {
                setShouldBlock(false);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_dusti', terms),
                        variant: "error"
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    }


    // Effects -----------------------------------------------------------------------------------------------------------
    useEffect(() => {
        initDuti();
    }, [DUSTI])

    useEffect(() => {
        if(steps && idDeclaracion){
            (async() => {
                try {
                    const decl = idDeclaracion !== -1 ? await dutiGateway.getDeclaracion(idDeclaracion) : undefined;
                    setStepsContent(get_DUTI_STEPS_CONTENT(stepsBBDD, idDeclaracion, steps, decl?.paso.id ?? 0));
                } catch (error) {
                    if (error instanceof CustomError && error.code === 403) {                        
                        setShouldBlock(false);
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('Global', 'sesion_caducada', terms),
                                variant: 'warning',
                                hasCustomAction: true,
                                handleCustomAction: () => { 
                                    pageDispatcher({ type: "logout" });
                                }
                            }
                        });
                    } else {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('DUTI','error_dusti', terms),
                                variant: "error"
                            }
                        });
                    }
                }
            })()

        }
    }, [steps, idDeclaracion])


    return (
        <div style={{ display: 'flex', flexGrow: 1, maxWidth: '99%' }} >
            <FullLoading loading={loading} animation={{ type: 'intricom', name: 'DUSTI' }} />

            {steps && steps.length > 0 && stepsContent && stepsContent.length > 0 &&
                <StepperDuti
                    headerStepper={headerStepper}
                    steps={steps}
                    stepsContent={stepsContent}
                />
            }


        </div>
    )
}

export default withStyles(duti_styles)(DUTIComponent);
