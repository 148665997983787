// @ts-ignore
/* eslint-disable */

const env = (window as any).env;

export default env as {
  PUBLIC_URL: string;
  RECAPTCHA_CLIENT_KEY: string;
  API_URL: string;
  ATIB_URL: string;
  NOTI_ALTA_URL: string;
  NOTI_BAJA_URL: string;
  AFIRMA_DESCARGA_DOCUMENTO: string;
  AFIRMA_STORAGE: string;
  AFIRMA_RETRIEVER: string;
  GOOGLE_API_KEY: string;
  CIPHER_PASSWORD: string;
};

// export default {
//   PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL || "$PUBLIC_URL",
//   RECAPTCHA_CLIENT_KEY: process.env.REACT_APP_RECAPTCHA_CLIENT_KEY || "$RECAPTCHA_CLIENT_KEY",
//   API_URL: process.env.REACT_APP_API_URL || "$API_URL",
//   NOTI_ALTA_URL: process.env.REACT_APP_NOTI_ALTA_URL || "$NOTI_ALTA_URL",
//   NOTI_BAJA_URL: process.env.REACT_APP_NOTI_BAJA_URL || "$NOTI_BAJA_URL",
//   AFIRMA_DESCARGA_DOCUMENTO: process.env.REACT_APP_AFIRMA_DESCARGA_DOCUMENTO || "$AFIRMA_DESCARGA_DOCUMENTO",
//   AFIRMA_STORAGE: process.env.REACT_APP_AFIRMA_STORAGE || "$AFIRMA_STORAGE",
//   AFIRMA_RETRIEVER: process.env.REACT_APP_AFIRMA_RETRIEVER || "$AFIRMA_RETRIEVER",
// };
