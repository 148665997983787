import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import Term from "components/term";
import SelectMultiple from '../../components/select-multiple';
import { FormControl, InputLabel, makeStyles, Select, MenuItem, useTheme, TextField } from "@material-ui/core";
import { FiltersContext } from "./filters.context";
import { FilterDataContext } from "./filter.data.context";
import { SelectChangeHandler } from "utils/events";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    formControl: {
        margin: `30px 0`,
        [theme.breakpoints.up('md')]: {
            margin: `10px 5px`,
        }
    },
    group: {
        margin: `10px 0`,
    },
    block: {
        display: `inline-block`
    },
    hidden: {
        display: `none`
    },
    middle: {
        verticalAlign: `middle`
    },
}));

export type ScreenType = 'domiciliaciones' |  'cartero-virtual-pendiente' | 'cartero-virtual-pagado' | 'tributos-autonomicos' | 'domiciliados' | 'pago-carta';

export interface IFiltersFormProps {
    screen: ScreenType;
}

const FiltersForm: FC<IFiltersFormProps> = ({ screen }) => {
    
    const [filters, filtersDispatch] = useContext(FiltersContext);
    const filterData = useContext(FilterDataContext);
    const fechaDate = useMemo(
        () => (filters.fPago ? moment(filters.fPago, "DD/MM/YYYY").toDate() : null),
        [filters.fPago]
      );
    
    const handleEjercicioChange = (ejercicio: number[]) => filtersDispatch({ type: 'update', filter: 'ejercicios', value: ejercicio });
    const handleMunicipioChange = (municipio: string[]) => filtersDispatch({ type: 'update', filter: 'organismos', value: municipio });
    const handleSituacionChange = (situacion: string[]) => filtersDispatch({ type: 'update', filter: 'situaciones', value: situacion });
    const handleConceptoChange = (concepto: number[]) => filtersDispatch({ type: 'update', filter: 'conceptos', value: concepto });
    const handleObjTributario = (objTributario: string) => filtersDispatch({ type: 'update', filter: 'objTributario', value: objTributario });
    const handleDirTributaria = (dirTributaria: string) => filtersDispatch({ type: 'update', filter: 'dirTributaria', value: dirTributaria });
    const handleFechaChange = useCallback(
        (date: Date | null) => {
          if((date && moment(date).isValid()) || date === null){
              filtersDispatch({ type: "update", filter: "fPago", value: date ? moment(date, "DD/MM/YYYY").format("DD/MM/YYYY") : null });
            }
        },
        [filtersDispatch]
    );    

    const handleDomiciliadoChange: SelectChangeHandler = (event) => filtersDispatch({ type: 'update', filter: 'domiciliados', value: event.target.value });
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <div className={classes.root}>
        {
            screen === 'tributos-autonomicos'
                ?
                <FormControl key='ejercicio' className={filterData && filterData.ejercicios ? classes.formControl : classes.hidden} fullWidth>
                    <InputLabel key='label-ejercicio' htmlFor="select-multiple-chip">
                        <Term component="Global" text="periodo" />
                    </InputLabel>
                    <SelectMultiple
                        key='ejercicios'
                        className={filterData && filterData.ejercicios ? '' : classes.hidden}
                        options={filterData && filterData.ejercicios ? filterData.ejercicios : []} value={filters.ejercicios || []}
                        onChange={(values: any) => handleEjercicioChange(values)}
                    />
                </FormControl>
                : 
                <>
                <FormControl key='ejercicio' className={filterData && filterData.ejercicios ? classes.formControl : classes.hidden} fullWidth>
                    <InputLabel key='label-ejercicio' htmlFor="select-multiple-chip">
                        <Term component="Global" text="Ejercicio" />
                    </InputLabel>
                    <SelectMultiple
                        key='ejercicios'
                        className={filterData && filterData.ejercicios ? '' : classes.hidden}
                        options={filterData && filterData.ejercicios ? filterData.ejercicios : []} 
                        value={filters.ejercicios || []}
                        onChange={(values: any) => handleEjercicioChange(values)}
                    />
                </FormControl>
                <FormControl key='situacion' className={filterData && filterData.situaciones ? classes.formControl : classes.hidden} fullWidth>
                    <InputLabel key='label-situacion' htmlFor="select-multiple-chip">
                        <Term component="Global" text="Situación" />
                    </InputLabel>
                    <SelectMultiple
                        key='situaciones'
                        options={filterData && filterData.situaciones ? filterData.situaciones : []} 
                        value={filters.situaciones || []}
                        onChange={(values: any) => handleSituacionChange(values)}
                    />
                </FormControl>
                <FormControl key='organismo' className={filterData && filterData.organismos ? classes.formControl : classes.hidden} fullWidth>
                    <InputLabel key='label-organismo' htmlFor="select-multiple-chip">
                        <Term component="Global" text="Municipio / Organismo" />
                    </InputLabel>
                    <SelectMultiple
                        key='organismos'
                        options={filterData && filterData.organismos ? filterData.organismos : []} value={filters.organismos || []}
                        onChange={(values: any) => handleMunicipioChange(values)}
                    />
                </FormControl>
                <FormControl key='concepto' className={filterData && filterData.conceptos ? classes.formControl : classes.hidden} fullWidth>
                    <InputLabel key='label-concepto' htmlFor="select-multiple-chip">
                        <Term component="Global" text="Concepto" />
                    </InputLabel>
                    <SelectMultiple
                        key='conceptos'
                        options={filterData && filterData.conceptos ? filterData.conceptos : []} value={filters.conceptos || []}
                        onChange={(values: any) => handleConceptoChange(values)} />
                </FormControl>
                <FormControl key="objTributario" className={classes.formControl} fullWidth>
                    <TextField
                        key="objTributario"
                        value={filters.objTributario || ""}
                        label={<Term component="CarteroVirtual" text="Objeto Tributario" />}
                        onChange={(evt) => handleObjTributario(evt.target.value)}
                    />
                </FormControl>
                <FormControl key="dirTributaria" className={classes.formControl} fullWidth>
                        <TextField
                            key="dirTributaria"
                            value={filters.dirTributaria || ""}
                            label={<Term component="CarteroVirtual" text="Dirección Tributaria" />}
                            onChange={(evt) => handleDirTributaria(evt.target.value)}
                        />
                </FormControl>
                {
                    screen === 'cartero-virtual-pagado'
                        ?
                    <FormControl key="fPago" className={classes.formControl} fullWidth>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            margin="dense"
                            format="dd/MM/yyyy"
                            id="datepicker-fecha"
                            label={<Term component="CarteroVirtual" text="FechaPago" />}
                            value={fechaDate}
                            onChange={handleFechaChange}
                        />
                    
                    </FormControl>
                : null
                }
                {
                    screen === 'cartero-virtual-pendiente'
                        ?
                        <FormControl key='domiciliado' className={classes.formControl} fullWidth>
                            <InputLabel shrink={true} htmlFor="domiciliados-select"><Term component="Global" text="Domiciliados / Pago a la carta" /></InputLabel>
                            <Select
                                onChange={handleDomiciliadoChange}
                                value={filters.domiciliados}
                                displayEmpty
                                inputProps={{
                                    name: 'domiciliados-label',
                                    id: 'domiciliados-select',
                                }}
                            >
                                <MenuItem>
                                    <Term component="Global" text="Todos" />
                                </MenuItem>
                                <MenuItem value="true"><Term component="Global" text="SI domiciliados" /></MenuItem>
                                <MenuItem value="false"><Term component="Global" text="NO domiciliados" /></MenuItem>
                            </Select>
                        </FormControl>
                        : null
                }
                </>
            }
        </div>
    );
}

export default FiltersForm;