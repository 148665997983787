import { Card, Grid, Hidden, Typography, makeStyles, useTheme,Button } from '@material-ui/core';
import { mdiFrequentlyAskedQuestions } from '@mdi/js';
import Term from 'components/term';
import IoC from 'contexts/ioc.context';
import { FAQSGateway } from 'gateways/faqs.gateway';
import usePage from 'hooks/page.hook';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { getIconFromCategory, getImgCategory, getNameFromCategory, ICategoria } from './categorias-faqs';
import { Pregunta } from './pregunta';
import { SearchFAQS } from './search-faqs';
import NoContent from 'components/no-content';
import image from '../../resources/SinFAQ.png';
import Icon from 'atomic/atoms/icon';
import { Categories, ICategory } from 'components/categories';
import { MenuIconCategories } from 'components/categories-item-menu-icon';
import { height } from '@fortawesome/free-solid-svg-icons/faFilter';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: 10, 
        marginLeft: 10,
        gridAutoRows: "max-content",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px"
       }
    },
    flex: {
        display: 'flex'
    },
    // detalle: {
    //     flex: 1,
    //     marginLeft: '1rem',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     gap: '0.5rem',
    // },
    title: {
        display: 'flex',
        color: '#004f82',
        fontSize: 25,
        fontWeight: 500,
        textTransform: 'uppercase',
        marginLeft: 20,
    },
    cardContainer: {
        maxWidth: 500
    },
    widget: {
        marginTop: 10,
        marginBottom: 10,
    },
    firstElement: {
        
    },
    card: {
        padding: 10,
        marginRight: 10, 
        marginLeft: 10,  
        display: 'flex', 
        flexDirection: 'row' //'column'
    },
    cardSubCat:{
        padding: 10,
        marginRight: 10,
        marginLeft: 10,
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    negrita: {
        fontWeight: 'bold',
        fontSize: 15,
        '& p': { marginTop: 5, marginBottom: 5}
    },
}));

export interface IFAQ {
    question: string,
    answer: string,
    categoryId: number,
}


export const DetailFAQS: React.FC = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { params  } = useRouteMatch();
    const ioc = useContext(IoC);
    const terms = useContext(LiteralsContext);
    const history = useHistory<{search:string | undefined, subCategory: ICategory[]} | undefined>();
    const lang = localStorage.getItem('lang');

    const gateway: FAQSGateway = useMemo(() => ioc.get(FAQSGateway), [ioc]);
    const [categoriasFAQS, setCategoriasFAQS] = useState<ICategory[] | null>(null)
    const [listadoPreguntas, setListadoPreguntas] = useState<IFAQ[] | null>(null);
    const [listadoPreguntasCat, setListadoPreguntasCat] = useState<IFAQ[] | null>(null);

    const [listadoPreguntasSubCat, setListadoPreguntasSubCat] = useState<{id: number , preguntas: IFAQ[]}[] | null>(null);
    const [selectedSubCat, setSelectedSubCat]=useState<number| null>(null);

    const [inputValue, setInputValue] = useState('');
    const [, pageDispatcher] = usePage();
    const [listFiltrados, setFiltrados] = useState<IFAQ[] | null>(null);
    const [subCategory, setSubCategory] = useState<ICategory[]>([])
    
    const categoryId = useMemo(() => parseInt((params as any).idCategoria), [params]);
    const modeSearch = useMemo(() => listFiltrados ? true : false, [listFiltrados]);


    const handleSearch = useCallback(async (value: string) => {
        const response = await gateway.search(value, selectedSubCat ? selectedSubCat : categoryId);
        //console.log('resp: ', response, 'cat: ', categoryId);        
        setFiltrados(response);
    }, [gateway, inputValue]);

    const handleInputChange = (inputValue: string) => {
        setInputValue(inputValue);
    };

    const handleNavigate = (id: number /*, subCat: ICategory[]*/) => {
        history.push(`/faqs/${id}` ) //, {search: undefined, subCategory: subCat})
    };

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiFrequentlyAskedQuestions,
                title: <Term component="FAQS" text="PreguntasFrecuentes" />,
                text: <Term component="FAQS" text="InformacionTramitesServicios" />
            },
            menu: true,
        });
    }, [pageDispatcher]);

    useEffect(() => {
        async function loadPage() {
            setCategoriasFAQS(null);
            const response = await gateway.getAllCategories();
            setCategoriasFAQS(response);
        }
        loadPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang])

    useEffect(() => {
        async function loadPage() {
            setListadoPreguntas(null);
            setFiltrados(null);
            setInputValue('');
            const preguntas = await gateway.getQuestions(categoryId); 
            const subCategory = await gateway.getSubCategories(categoryId);            

            setSubCategory(subCategory);
            setListadoPreguntasCat(preguntas);  

            if(subCategory.length >0 ){
                const preguntasSubCat = await Promise.all(
                    subCategory.map( async sc => {
                       const p : IFAQ[]= await gateway.getQuestions(sc.id)
                       return {id: sc.id, preguntas: p}; 
                    })
                );
                //console.log('preguntasSubCat', preguntasSubCat)
                setListadoPreguntasSubCat(preguntasSubCat)
                setListadoPreguntas(null);  
            }  else{
                setListadoPreguntas(preguntas);  
            }        
            setSelectedSubCat(null)  
        }
        loadPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId, lang])

    useEffect(() => {
        if(listadoPreguntasSubCat){
            if(selectedSubCat ){
                const p = listadoPreguntasSubCat.filter( item => item.id === selectedSubCat)
                setListadoPreguntas(p[0].preguntas)
            } else {
                setListadoPreguntas(null)
            }
        }
    }, [selectedSubCat])

    
    useEffect(() => {
        if(history.location.state){
            setInputValue(history.location.state.search??'')
            handleSearch(history.location.state.search??'')
        }
    },[])


    const icon = useMemo(() => getIconFromCategory(categoryId, categoriasFAQS) ,[categoryId,categoriasFAQS])
    const imgIcon = useMemo(() => icon ? getImgCategory(icon): undefined, [icon])

    return (
        <div className={classes.container}>
            <MenuIconCategories activeCategory={categoryId} listCategorias={categoriasFAQS ?? []} handleNavigate={handleNavigate} literalsComponent={'FAQS'} /> 

            <Card className={classes.card} style={{ marginTop: 90}}>
                <div style={{display: 'inline-flex', alignItems: 'center', margin: 20, width: '50%'}}>
                    {imgIcon 
                        ? <img src={imgIcon} alt={icon} style={{width: 60, height: 60}}/> 
                        : <Icon size={2.5} name={getIconFromCategory(categoryId, categoriasFAQS) as any} color='#004f82'  />   
                    }
                    
                    <div className={classes.title} >
                        <Term component='FAQS' text={getNameFromCategory(categoryId, categoriasFAQS) as any} />
                    </div> 

                </div>
                
                <div style={{padding: 10, marginTop: 10,width: '50%', alignContent:'baseline' }}>
                    <SearchFAQS
                        onSearch={handleSearch}
                        onInputChange={handleInputChange}
                        withCategory={true}
                        inputValue={inputValue}
                    />
                </div>
            </Card>

            <Grid container>
                {subCategory.length >0 && 
                    <Grid item xs={selectedSubCat ? 4: 12}>
                        {subCategory.map( subCat => 
                            <Grid item className={[classes.widget, classes.firstElement].join(' ')} xs={12} md={12} xl={12}>
                                <Card className={classes.cardSubCat} 
                                    style={{backgroundColor: selectedSubCat&& selectedSubCat===subCat.id ? '#c5def1' : undefined}} 
                                    onClick={() =>{ 
                                        if(selectedSubCat && selectedSubCat=== subCat.id){
                                            setSelectedSubCat(null)
                                        } else {
                                            setSelectedSubCat(subCat.id)
                                        }
                                        setFiltrados(null)
                                    }}>
                                    <Typography className={classes.negrita}>{translate("FAQS",subCat.categoryName, terms)}</Typography>
                                    <Icon name='chevron-right' />
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                }

                <Grid item xs={subCategory.length>0 ? 8 : 12}>
                    {modeSearch 
                    ? 
                        listFiltrados && listFiltrados.map((x, index) => (
                            <Grid className={[classes.widget, classes.firstElement].join(' ')} item xs={12} md={12} xl={12} key={`faqs_search_respuesta${index}`}>
                                <Pregunta key={`Pregunta_Categoria${x.categoryId}_i`} answer={x.answer} categoryId={x.categoryId} question={x.question} />
                            </Grid>
                        ))
                    :
                        listadoPreguntas && listadoPreguntas.map((x, index) => {
                                return (
                                    (x.answer && x.answer != '' && x.question && x.question != '') &&
                                    <Grid className={[classes.widget, classes.firstElement].join(' ')} item xs={12} md={12} xl={12} key={`faqs_respuesta${index}`}>
                                        <Pregunta key={`Pregunta_Categoria${x.categoryId}_i`} answer={x.answer} categoryId={x.categoryId} question={x.question} />
                                    </Grid>
                                )
                            })
                    }  
                   
                </Grid>
            </Grid>


            
            {
                // categoriasFAQS !== null && listadoPreguntas !== null && <div className={classes.container}>
                
                //     <SearchFAQS onSearch={handleSearch} withCategory={true} />
                //     <div className={classes.flex}>
                //         {
                //             !modeSearch &&
                //             <div className={classes.detalle}> 
                //                 {
                //                     listadoPreguntas.length === 0 &&
                //                     <>
                //                     <div className={classes.title}>
                //                         <Icon size={1} name={getIconFromCategory(categoryId, categoriasFAQS) as any} color='#004f82' />
                //                         <Term component='FAQS' text={getNameFromCategory(categoryId, categoriasFAQS) as any} />
                //                     </div>
                //                     <NoContent
                //                         image={image}
                //                         visible={true}
                //                         component='FAQS'
                //                         text={'No se han encontrado resultados'}
                //                     />
                //                     </>
                //                 }
                //                 {
                //                     listadoPreguntas.length > 0 &&
                //                     <div className={classes.title}>
                //                         <Icon size={1} name={getIconFromCategory(categoryId, categoriasFAQS) as any} color='#004f82' />
                //                         <Term component='FAQS' text={getNameFromCategory(categoryId, categoriasFAQS) as any} />
                //                     </div>
                //                 }
                //                 {
                //                     listadoPreguntas.map((x, i) => (
                //                         (x.answer && x.answer != '' && x.question && x.question != '') &&
                //                         <Pregunta key={`Pregunta_Categoria${x.categoryId}_i`} answer={x.answer} categoryId={x.categoryId} question={x.question} />
                //                     ))
                //                 }
                //             </div>
                //         }
                //         {modeSearch &&
                //             <div className={classes.detalle}>
                                
                //                 {
                //                     listFiltrados && listFiltrados.length === 0 &&
                                    
                //                     <NoContent
                //                         image={image}
                //                         visible={true}
                //                         component='FAQS'
                //                         text={'No se han encontrado resultados'}
                //                     />
                //                 }
                //                 {
                //                     listFiltrados && listFiltrados.map((x) => (
                //                         <Pregunta key={`Pregunta_Categoria${x.categoryId}_i`} answer={x.answer} categoryId={x.categoryId} question={x.question} icon={getIconFromCategory(x.categoryId, categoriasFAQS)} />
                //                     ))
                //                 }
                //             </div>
                //         }
                //         <Hidden smDown>
                //             <div>
                //                 <div className={classes.cardContainer}>
                //                     <Categories listCategorias={categoriasFAQS} handleNavigate={handleNavigate} literalsComponent='FAQS' />
                //                 </div>
                //             </div>
                //         </Hidden>

                //     </div>

                // </div>
            }
        </div>

        
        )
}

