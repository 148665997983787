import React, { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent, Tooltip} from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import styles from './styles';
import { formatOptions, formatXMLtoObject, GenericInitialObject, getEmptyObject, getKeysRequiredCampos, Option, OPTIONS_trimestre, renderCampos, SelectoresInfo, sujetoFormatter } from '../utils';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { ISujeto } from 'gateways/perfil.interfaces';
import moment from 'moment';
import { Municipio } from 'gateways/geo.interface';
import { GeoGateway } from 'gateways/geo.gateway';


const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXml: string | null,
    datosXmlFromModelosAction: string|undefined
    loggedSujeto: ISujeto | null,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
}

const DeclarTrimestral: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        loggedSujeto,
        datosXml,
        datosXmlFromModelosAction,
        setFinished,
        handleSave
    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    
    const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes, setChanges] = useState(false);
    const [campos, setCampos] = useState<ICampo[] | null>(null);
    const [EMPTY_DECL_TRIMESTRAL,setEmptyDeclTrim] = useState<GenericInitialObject| null>({});
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    const YEAR = moment().year()
    const [infoSelect, setInfoSelect]= useState<SelectoresInfo | null>({
        trimestre: OPTIONS_trimestre,
        ejercTrimestre: OPTIONS_trimestre.map( opt => ({...opt, nombre: `${YEAR} - ${opt.nombre}` })), 
        // [ 
        //     {id: '1T', nombre: `${YEAR} - 1er Trimestre`},
        //     {id: '2T', nombre: `${YEAR} - 2º Trimestre`}, 
        //     {id: '3T', nombre: `${YEAR} - 3er Trimestre`}, 
        //     {id: '4T', nombre: `${YEAR} - 4º Trimestre`}, 
        // ],
        oficina: [
            {id: 'IB', nombre: '07200'}
        ],
        delegacion: null,
        municipio: null
        
    })
    
    /**Permite identificar un campo selector de municipio y un segundo campo que registra ID del municipio */
    const searchMuniID = useMemo(() => {
        if(campos) {
            const c = campos.find(c => c.tipoCampoWeb === 'municipioId')
            const cM = campos.find(c => c.tipoCampoWeb === 'select' && c.selectRef === 'municipio')

            return c && cM ? {muni: cM.codigo, muniId: c.codigo} : null;
        } else {
            return null
        }
    },[campos])

    
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData = ( name: string, value: any) => {
        setChanges(true)
        let newData: GenericInitialObject | null =null;
        if(EMPTY_DECL_TRIMESTRAL){
            newData= data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY_DECL_TRIMESTRAL,[name]: {...EMPTY_DECL_TRIMESTRAL[name], value: value }}
            const keys= data ? Object.keys(data  ): Object.keys( EMPTY_DECL_TRIMESTRAL)

            /**Autocomplete - Municipio ID :  Ej: CODI Muni en 656 */
            if(infoSelect?.municipio && searchMuniID && name === searchMuniID.muni){
                const muni = infoSelect.municipio.find(m => m.nombre === value)
                newData={
                    ...newData, 
                    [searchMuniID.muniId]: {...newData[searchMuniID.muniId], value: muni?.id}
                }
            }

            {/** 593_ 0 casilla 67: Si no está activa Deshabilitamos casilla 68 */}
            if((idTipoApartado==='593_0'||idTipoApartado==='594_0') && name === 'DeclCompl' && campos){
                const newCampos= campos.map((item: ICampo) => {
                    if(item.casilla === 68 ){
                        if(newData && newData['DeclCompl'].value === false ){
                            // Limpiamos localizador + Deshabilitar cmapo
                            newData={...newData, 'LocaCompl': {...newData['LocaCompl'], value: undefined}}
                            return {...item, visible: false, obligatorio: false}
                        } else {
                            return {...item, visible: true, obligatorio: true}
                        }
                    } else {
                        return {...item}
                    }
                    
                });

                const keys = getKeysRequiredCampos(newCampos);
                setCamposRequiredKeys(keys)
                setCampos(newCampos)
            }

        }
        setData(newData)
    }



    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            let modeloExist= true;
            //console.log('on save camposRequiredKeys', camposRequiredKeys)
            
            if(camposRequiredKeys && camposRequiredKeys.length>0 ){
                if(data && (idTipoApartado === '593_0'|| idTipoApartado === '594_0') && camposRequiredKeys?.includes('LocaCompl')){
                    const modelo = data ? await modelGateway.getModeloById(data['LocaCompl'].value, 'Not found Localizador') : null
                    if(!modelo){ modeloExist = false}
                } 
                if(data){
                    camposRequiredKeys.forEach( element => {
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal' || 'decimal2'||'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value|| data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                }
            }
 
            if(valid && modeloExist){
                const saved = await handleSave(data); 
                //setFinished(saved);
                setChanges(false);

            } else {
                //setFinished(false);
                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: `${!valid ? translate('Tributos','FormularioError', terms):  ''} ${!modeloExist ? translate('Tributos','FormularioError_LocaCompl', terms):  ''}`,
                        variant: 'error',
                    }
                });

            }
            setLoading(false);

        })();
    }
    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                /** Selectores */
                const delegacion = await modelGateway.getDelegacion(translate('Tributos', 'GetSelectDelegacionError', terms))
                
                let camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                let optionsMuni: Municipio[] | null = null;
                if(camposApartado.find(c => c.selectRef === 'municipio')){
                    const provinciaBaleares = '07'            
                    optionsMuni = await geoGateway.getMunicipios(provinciaBaleares)    
                }
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                if(camposApartado && camposApartado?.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEmptyDeclTrim(emptyObjt);

                    if(datosXml){
                        //console.log('USE datosXML')
                        newData = formatXMLtoObject(datosXml,emptyObjt);
                        setChanges(false);
                        setFinished(true); 
                    } else {
                        setChanges(true); 
                        newData = {
                            ...emptyObjt,
                            ejercicio: {...emptyObjt['ejercicio'], value: datosXmlFromModelosAction ?? moment().year() }
                        }
                    }
                }

                if(idTipoApartado === '593_0' ||idTipoApartado === '594_0'){
                    /** Nueva casilla 67 -> Si es TRUE podemos definir Localizador, si es FALSE no puede permitirse
                     * modificamos el campo cons casilla 68 para que sea solo lectura y así quede deshabilitado
                    */
                    //console.log(newData)
                    camposApartado = camposApartado.map((item: ICampo) => {
                        if(item.casilla === 68 ){
                            if(newData && Boolean(newData['DeclCompl'].value) === false){
                                //  Deshabilitar campo
                                return {...item, visible: false, obligatorio: false}
                            } else {
                                return {...item, visible:true, obligatorio: true}
                            }
                        } else {
                            return {...item}
                        }
                        
                    });
                    
                    //console.log('camposApartado adapted', camposApartado)
                }

                //console.log('newData', newData)
                const keys = getKeysRequiredCampos(camposApartado);
                setCamposRequiredKeys(keys)
                setCampos(camposApartado);
                setLoading(false)
                setData(newData);
                setInfoSelect({...infoSelect, 
                    delegacion: delegacion,
                    municipio: optionsMuni && optionsMuni.length>0 ? formatOptions<Municipio>('idMunicipio','nombre',optionsMuni) : null,
                    
                });


            } catch (error) {
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            }
        })();
    },[idTipoApartado, idTipoModelo,loggedSujeto, datosXml]);

    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
    },[changes])

   
    
    return(
        <div> 
            <div className={classes.rowAlignLeft}>
  
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    variant='contained' 
                    disabled={ data === null || data === EMPTY_DECL_TRIMESTRAL }
                    onClick={() => {
                        setData(EMPTY_DECL_TRIMESTRAL)
                        setChanges(true)
                    }}
                > 
                    {translate('Tributos','btnClean',terms)} 
                </Button>
                

            </div>
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
            {campos && campos.length>0 && camposRequiredKeys !== null && data && infoSelect &&
                renderCampos(campos, data, updateData, '', idTipoApartado, infoSelect, undefined, classes )
            }

            <div className={classes.rowAlignRight}>
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    disabled={!changes}
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnSave',terms)} 
                </Button>
            </div>
        </div>
    )
}
export default withLiterals(['Tributos'])(DeclarTrimestral);


