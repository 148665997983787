import React, { FC } from "react";
import { Theme, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { getClassMetadata, getConceptoMetadata } from "concepto-resource";

const styles = (theme: Theme): StyleRules => ({
    imagenContainerBI: {
        backgroundColor: '#838A89',
        borderRadius: '50%'
    },
    imagenContainerIBI: {
        backgroundColor: '#8A829B',
        borderRadius: '50%'
    },
    imagenContainerIAE: {
        backgroundColor: '#db996c',
        borderRadius: '50%'
    },
    imagenContainerIVTM: {
        backgroundColor: '#009BBF',
        borderRadius: '50%'
    },
    imagenContainerSanciones: {
        backgroundColor: '#569abd',
        borderRadius: '50%'
    },
    imagenContainerPlus: {
        backgroundColor: '#569abd',
        borderRadius: '50%'
    },
    imagenContainerIcio: {
        backgroundColor: '#569abd',
        borderRadius: '50%'
    },
    imagenContainerAU: {
        backgroundColor: '#E07061',
        borderRadius: '50%'
    },
    imagenContainerIT: {
        backgroundColor: '#569abd',
        borderRadius: '50%'
    },
    imagenContainerOtros: {
        backgroundColor: '#e9c36d',
        borderRadius: '50%'
    },
    imagen: {
        width: '100%'
    }
});

export interface IProps extends WithStyles<typeof styles> {
    concepto: number | null;
    codigo?: string;
}

const ConceptoIcon: FC<IProps> = ({ classes, concepto, codigo }) => {
    const meta = getConceptoMetadata(concepto, codigo);
    const classContainer = getClassMetadata(concepto, classes, codigo);
    
    return (
        <div className={classContainer}>
            <img src={meta.source} className={classes.imagen} alt="deuda" />
        </div>
    );
}

export default withStyles(styles)(ConceptoIcon);