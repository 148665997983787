import React, {  } from 'react';
import { WithStyles, withStyles, FormControlLabel, FormControl, RadioGroup, Radio, Button } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';
import Term from 'components/term';
import { ITramite } from 'gateways/tramites.interfaces';


const styles = (): StyleRules => ({
    select: {
        width: 230,
    },
    buttonList: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    button: {
        width: 200
    },
    form: {
        display: 'flex',
        marginTop: 15,
        marginLeft: 4,
    }
});


interface IProps extends WithStyles<typeof styles> {
    onChange: (value: any) => void;
    onSubmit: () => void;
    tramites: ITramite[];
    tramite: number;
}


const SeleccionTramite: React.FC<IProps> = ({ onChange, tramites, tramite, classes, onSubmit }) => {

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (typeof onChange === 'function') {
            const values = event.target.value as any;
            onChange(parseInt(values, 10));
        }
    };

    const handleSubmit = () => {
        onSubmit();
    }

    return (
        <>
            <FormControl component="fieldset" className={classes.form}>
                <RadioGroup aria-label="tramite" name="tramite" value={tramite} onChange={handleChange}>
                    {tramites.map((item) => (
                        <FormControlLabel
                            key={'tramite_' + item.id}
                            value={item.id}
                            control={<Radio />}
                            label={<Term component="Tramites" text={item.nombre} />}
                        />
                    ))}
                </RadioGroup>
                <div className={classes.buttonList}>
                    <Button type="submit" variant="contained" size="small" className={classes.button} color="primary" onClick={handleSubmit} disabled={tramite === -1}>
                        <Term component="Global" text="Continuar" />
                    </Button>
                </div>
            </FormControl>
        </>
    );
}

export default withStyles(styles)(SeleccionTramite);