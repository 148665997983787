export default function getAllKeys<T>(arr: T[]): string[] {
    const set = new Set<string>();

    for (const element of arr) {
        for (const key of Object.keys(element)) {
            set.add(key);
        }
    }

    return Array.from(set);
}
