import { Card, CardContent, CircularProgress, FormControl, Grid, makeStyles, TextField, List, ListItem, ListItemIcon, Checkbox, ListItemText, FormControlLabel, Button, Dialog, DialogTitle, DialogContent, Typography, DialogActions, Hidden, Divider, Box } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { mdiAccountMultiple } from "@mdi/js";
import { RouteComponentProps } from "react-router";
import Term from "components/term";
import usePage from "hooks/page.hook";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState, useContext, useMemo } from "react"
import IoC from "contexts/ioc.context";
import { ProcedimientosGateway } from "gateways/tramites/procedimientos.gateway";
import { IProcedimiento } from "gateways/tramites/procedimientos.interfaces";
import { Alert } from "@material-ui/lab";
import { ISolicitudAltaRepresentacionDto } from "gateways/tramites/representaciones.interfaces";
import { RepresentacionesGateway } from "gateways/tramites/representaciones.gateway";
import { DocumentsUtils } from "utils/documents";
import { validateNif } from "utils/validateNif";
import ProcedimientosDialog from "./procedimientos.dialog";
import Icon from 'atomic/atoms/icon';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(0, 1),
    },
    subtitle: {
        fontSize: 14
    },
    spaceBottom: {
        marginBottom: theme.spacing(1),
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(5, 0),
    },
    errorText: {
        color: theme.palette.error.main,
    },
    xsHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(1, 0),
    },
    xsHeaderTitle: {
        fontSize: 14,
        color: '#004f84'
    },
    buttonBox: {
        float: 'left',
    },
    button: {
        width: 200,
        float: "unset",
        [theme.breakpoints.up('md')]: {
            width: 475,
        },
    },
    icon: {
        float: 'left',
        marginLeft: 15,
    }
}))

const fechaMinFinApoderamiento = moment().add(1, 'days').startOf('day');
const fechaMaxFinApoderamiento = moment().add(5, 'years');

type InputValidaciones = {
    nifApoderadoFormatoNoValido?: boolean;
    minProcedimientosOtorgados?: boolean;
    minFechaFinApoderamiento?: boolean;
    maxFechaFinApoderamiento?: boolean;
    requiereAceptarAvisoLegal?: boolean;
    emailApoderadoNoValido?: boolean;
};

type Props = RouteComponentProps;

export const SolicitudAltaDeRepresentacion: FC<Props> = ({ history }) => {
    const [page, pageDispatcher] = usePage();
    const classes = useStyles();
    const ioc = useContext(IoC);
    const procedimientosGateway = useMemo(() => ioc.get(ProcedimientosGateway) as ProcedimientosGateway, [ioc]);
    const representacionesGateway = useMemo(() => ioc.get(RepresentacionesGateway) as RepresentacionesGateway, [ioc]);


    const [procedimientosDisponibles, setProcedimientosDisponibles] = useState<IProcedimiento[]>([]);
    const [procedimientosSeleccionados, setProcedimientosSeleccionados] = useState<IProcedimiento[]>([]);
    const [procedimientosOtorgados, setProcedimientosOtorgados] = useState<IProcedimiento[]>([]);
    const [nifApoderado, setNifApoderado] = useState('');
    const [emailApoderado, setEmailApoderado] = useState('');
    const [fechaFinApoderamiento, setFechaFinApoderamiento] = useState<Date>(fechaMaxFinApoderamiento.toDate());
    const [aceptarAvisoLegal, setAceptarAvisoLegal] = useState(false);

    const [sendStatus, setSendStatus] = useState<{ sending: boolean, success?: boolean, idDocumento?: string, mensaje?: string }>({ sending: false });
    const [validaciones, setValidaciones] = useState<InputValidaciones>({});
    const [showProcedimientosDialog, setShowProcedimientosDialog] = useState(false);
    const [poderGeneral, setPoderGeneral] = useState(false);
    const [showSendDialog, setShowSendDialog] = useState(false);
    const nif = page.jwp ? page.jwp.nif : '';

    const handleChangeFechaFinApoderamiento = useCallback((date: MaterialUiPickersDate) => {
        setFechaFinApoderamiento(date || fechaMaxFinApoderamiento.toDate());
    }, []);

    const handleChangeNifApoderado: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        const value = e.target.value;       
        const nifValido = validateNif(value.toUpperCase()); 
        setValidaciones((v) => ({ ...v, nifApoderadoFormatoNoValido: !nifValido || value.toUpperCase() === nif.toUpperCase() }));
        setNifApoderado(value.toUpperCase());
        if (nifValido) {
            procedimientosGateway.getProcedimientosPoderdante(value.toUpperCase()).then((procedimientos) => {
                setProcedimientosDisponibles(procedimientos);
            });
        }
    }, [nif]);

    const handleChangeEmailApoderado: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        const value = e.target.value;
        // eslint-disable-next-line no-useless-escape
        setValidaciones((v) => ({ ...v, emailApoderadoNoValido: !/^.+?\@.+?\..+?$/.test(value) }));
        setEmailApoderado(value);
    }, []);

    const handleSelectProcedimiento = useCallback((procedimiento: IProcedimiento) => {
        const callback: React.ChangeEventHandler<HTMLInputElement> = (e) => {
            const procedimientosSeleccionadosActualizados = e.target.checked
                ? [...procedimientosSeleccionados.filter(item => item.id !== 2), procedimiento]
                : procedimientosSeleccionados.filter(x => x !== procedimiento);

            setValidaciones((v) => ({ ...v, minProcedimientosOtorgados: procedimientosSeleccionadosActualizados.length === 0 }));
            setProcedimientosSeleccionados(procedimientosSeleccionadosActualizados);
        }
        return callback;
    }, [procedimientosSeleccionados]);

    const handleChangeAceptarAvisoLegal: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        setValidaciones(v => ({ ...v, requiereAceptarAvisoLegal: !e.target.checked }))
        setAceptarAvisoLegal(e.target.checked);
    }, []);

    const handleOpenDialog = useCallback((poderGeneral: boolean) => {
        setPoderGeneral(poderGeneral);
        setShowProcedimientosDialog(true);
    }, []);

    const handleAccept = useCallback((poderGeneral: boolean) => {
        setPoderGeneral(poderGeneral);
        if (poderGeneral) {
            const procedimientoGeneral = procedimientosDisponibles.find((p: IProcedimiento) => p.id === 2);
            setProcedimientosOtorgados(procedimientoGeneral ? [procedimientoGeneral] : []);
            setProcedimientosSeleccionados(procedimientoGeneral ? [procedimientoGeneral] : []);
        } else {
            setProcedimientosOtorgados(procedimientosSeleccionados);
        }
        setShowProcedimientosDialog(false);
    }, [procedimientosSeleccionados, procedimientosDisponibles]);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiAccountMultiple,
                title: <Term component="Representaciones" text="Titulo" />,
                text: <Term component="Representaciones" text="alta_poder" />
            },
            menu: true,
        });
    }, [pageDispatcher]);    

    const handleFirmarEnviar = useCallback(() => {
        const validaciones: InputValidaciones = {
            emailApoderadoNoValido: false,
            maxFechaFinApoderamiento: false,
            minFechaFinApoderamiento: false,
            minProcedimientosOtorgados: false,
            nifApoderadoFormatoNoValido: false,
            requiereAceptarAvisoLegal: false,
        };
        let formularioValido: boolean = true;        
        
        if (!validateNif(nifApoderado) || nifApoderado === nif) {
            validaciones.nifApoderadoFormatoNoValido = true;
            formularioValido = false;
        }
        if (procedimientosOtorgados.length === 0) {
            validaciones.minProcedimientosOtorgados = true;
            formularioValido = false;
        }
        if (fechaFinApoderamiento < fechaMinFinApoderamiento.toDate()) {
            validaciones.minFechaFinApoderamiento = true;
            formularioValido = false;
        }
        if (fechaFinApoderamiento > fechaMaxFinApoderamiento.toDate()) {
            validaciones.maxFechaFinApoderamiento = true;
            formularioValido = false;
        }
        if (!aceptarAvisoLegal) {
            validaciones.requiereAceptarAvisoLegal = true;
            formularioValido = false;
        }

        // eslint-disable-next-line no-useless-escape
        if (emailApoderado.length > 0 && !/^.+?\@.+?\..+?$/.test(emailApoderado)) {
            validaciones.emailApoderadoNoValido = true;
            formularioValido = false;
        }

        setValidaciones(validaciones);

        if (formularioValido) {
            setShowSendDialog(true);
        }
    }, [nifApoderado, nif, procedimientosOtorgados.length, fechaFinApoderamiento, aceptarAvisoLegal, emailApoderado]);

    const handleCloseSendDialog = useCallback(() => {
        if (sendStatus.success) {
            history.push('/perfil/representaciones/gestion-de-representaciones?index=1');
        }
        setSendStatus({ sending: false });
        setShowSendDialog(false);
    }, [history, sendStatus]);

    const handleSubmit = useCallback(async () => {
        setSendStatus({ sending: true });

        const payload: ISolicitudAltaRepresentacionDto = {
            procedimientos: procedimientosSeleccionados.map(x => x.id),
            nifApoderado,
            fechaFinVigencia: fechaFinApoderamiento,
            emailApoderado: emailApoderado.length === 0 ? undefined : emailApoderado,
        };

        try {
            const result = await representacionesGateway.solicitudAltaPoder(payload);
            setSendStatus({ sending: false, success: result.ok, idDocumento: result.idDocumento, mensaje: result.mensaje });
        } catch (e) {
            setSendStatus({ sending: false, success: false });
        }

    }, [representacionesGateway, procedimientosSeleccionados, nifApoderado, fechaFinApoderamiento, emailApoderado]);

    const handleDownload = useCallback(() => {
        if (!sendStatus.idDocumento) {
            return;
        }
        DocumentsUtils.downloadDoc(sendStatus.idDocumento);
    }, [sendStatus.idDocumento]);

    useEffect(() => {
        procedimientosGateway.getProcedimientos().then((procedimientos) => {
            setProcedimientosDisponibles(procedimientos);
        });
    }, [procedimientosGateway]);

    if (!page.jwp) {
        return (
            <CircularProgress />
        );
    }

    return (
        <div className={classes.root}>

            <Hidden mdUp>
                <Card className={classes.xsHeader}>
                    <h2 className={classes.xsHeaderTitle} >
                        <Term component="Representaciones" text="alta_poder" />
                    </h2>
                </Card>
            </Hidden>


            <Card className={classes.spaceBottom}>
                <CardContent>
                    <h2 className={classes.subtitle}>
                        <Term component="Representaciones" text="Datos del poderdante" />
                    </h2>
                    <Grid container spacing={2} >
                        <Grid item xs={12} xl={6}>
                            <TextField name="nifPoderdante" fullWidth disabled label={<Term component="Representaciones" text="Poderdante" />} value={`${page.jwp.nif} - ${page.jwp.fullname}`} />
                        </Grid>
                    </Grid>

                    <h2 className={classes.subtitle} style={{ paddingTop: 20 }}>
                        <Term component="Representaciones" text="Datos del apoderado" />
                    </h2>
                    <Grid container spacing={1}>
                        <Grid item xs={5} xl={2}>
                            <TextField
                                name="nifApoderado"
                                fullWidth
                                label={<Term component="Representaciones" text="NIF APODERADO" />}
                                onChange={handleChangeNifApoderado}
                                error={validaciones.nifApoderadoFormatoNoValido}
                            />
                        </Grid>
                        <Grid item xs={7} xl={4}>
                            <TextField
                                name="emailApoderado"
                                fullWidth
                                type="email"
                                label={<Term component="Representaciones" text="Email del apoderado" />}
                                onChange={handleChangeEmailApoderado}
                                error={validaciones.emailApoderadoNoValido}
                            />
                        </Grid>
                    </Grid>

                    <h2 className={classes.subtitle} style={{ paddingTop: 20 }}>
                        <Term component="Representaciones" text="Vigencia del apoderamiento" />
                    </h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={2}>
                            <FormControl fullWidth>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    minDate={fechaMinFinApoderamiento}
                                    maxDate={fechaMaxFinApoderamiento}
                                    id="fechaFinApoderamiento"
                                    value={fechaFinApoderamiento}
                                    onChange={handleChangeFechaFinApoderamiento}
                                    invalidDateMessage={<Term component="Representaciones" text="fecha_invalida" />}
                                    minDateMessage={<Term component="Representaciones" text="fecha_minima" params={[fechaMinFinApoderamiento.format('DD/MM/YYYY')]} />}
                                    maxDateMessage={<Term component="Representaciones" text="fecha_maxima" params={[fechaMaxFinApoderamiento.format('DD/MM/YYYY')]} />}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card className={classes.spaceBottom}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <h2 className={classes.subtitle}>
                                <Term component="Representaciones" text="Procedimientos a los que desea otorgar poderes (representación)" />
                            </h2>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item md="auto" lg={6}>
                                <Box className={classes.buttonBox}>
                                    <Button variant="contained" size="small" className={classes.button} color="primary" disabled={validaciones.nifApoderadoFormatoNoValido === undefined || validaciones.nifApoderadoFormatoNoValido === true} onClick={() => handleOpenDialog(true)}>
                                        <Term component="Representaciones" text="Alta de poder general" />                                    
                                    </Button>
                                </Box>
                                { procedimientosOtorgados.length > 0 && procedimientosOtorgados.some(p => p.id === 2) &&
                                <Box className={classes.icon}>
                                    <Icon name="check-ok" size={1} />
                                </Box>
                                }
                            </Grid>
                            <Grid item md="auto" lg={6}>
                                <Box className={classes.buttonBox}>
                                    <Button variant="contained" size="small" className={classes.button} color="primary" disabled={validaciones.nifApoderadoFormatoNoValido === undefined || validaciones.nifApoderadoFormatoNoValido === true} onClick={() => handleOpenDialog(false)}>
                                        <Term component="Representaciones" text="Alta de poder especifico" />
                                    </Button>                    
                                </Box>
                                { procedimientosOtorgados.length > 0 && !procedimientosOtorgados.some(p => p.id === 2) &&
                                <Box className={classes.icon}>
                                    <Icon name="check-ok" size={1} />
                                </Box>
                                }
                            </Grid>                            
                        </Grid>
                        <Grid item>
                            {
                                validaciones.minProcedimientosOtorgados === true
                                && <Alert severity="error">
                                    <Term component="Representaciones" text="Debe seleccionar por lo menos un procedimiento al que otorgar poderes" />
                                </Alert>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card className={classes.spaceBottom}>
                <CardContent>
                    <h2 className={classes.subtitle}>
                        <Term component="Representaciones" text="Información sobre protección de datos de carácter personal" />
                    </h2>
                    <FormControlLabel
                        control={<Checkbox color="primary" name="proteccion_de_datos" onChange={handleChangeAceptarAvisoLegal} />}
                        label={<Term component="Representaciones" text="otorgante_autoriza" />}
                    />
                    {
                        validaciones.requiereAceptarAvisoLegal && <Alert severity="error">
                            <Term component="Representaciones" text="aceptar_autorizacion" />
                        </Alert>
                    }
                </CardContent>
            </Card>

            <div className={classes.footer}>
                <Button id="send-form" variant="contained" color="primary" onClick={handleFirmarEnviar}>
                    <Term component="Representaciones" text="Firmar Enviar" />
                </Button>
            </div>

            <ProcedimientosDialog 
                open={showProcedimientosDialog}
                poderGeneral={poderGeneral}
                procedimientos={procedimientosDisponibles}
                procedimientosSeleccionados={procedimientosSeleccionados}
                procedimientosOtorgados={procedimientosOtorgados}
                onAccept={handleAccept}
                onSelectProcedimiento={handleSelectProcedimiento}
                onClose={() => setShowProcedimientosDialog(false)}
            />
            <Dialog fullWidth open={showSendDialog} disableBackdropClick={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Term component="Representaciones" text="Confirmación de datos" />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div className={classes.spaceBottom} style={{ paddingTop: 10 }}>
                        <Typography variant="subtitle2">
                            <Term component="Representaciones" text="Poderdante" />
                        </Typography>
                        <Typography variant="body2">
                            {page.jwp.nif} - {page.jwp.fullname}
                        </Typography>
                    </div>

                    <div className={classes.spaceBottom} style={{ paddingTop: 10 }}>
                        <Typography variant="subtitle2">
                            <Term component="Representaciones" text="NIF APODERADO" />
                        </Typography>
                        <Typography variant="body2">
                            {nifApoderado}
                        </Typography>
                    </div>
                    {
                        emailApoderado.length > 0 && <div className={classes.spaceBottom} style={{ paddingTop: 10 }}>
                            <Typography variant="subtitle2">
                                <Term component="Representaciones" text="Email del apoderado" />
                            </Typography>
                            <Typography variant="body2">
                                {emailApoderado}
                            </Typography>
                        </div>
                    }
                    <div className={classes.spaceBottom} style={{ paddingTop: 10 }}>
                        <Typography variant="subtitle2">
                            <Term component="Representaciones" text="Vigencia del apoderamiento" />
                        </Typography>
                        <Typography variant="body2">
                            {moment(fechaFinApoderamiento).format('DD/MM/YYYY')}
                        </Typography>
                    </div>
                    <div className={classes.spaceBottom} style={{ paddingTop: 10 }}>
                        <Typography variant="subtitle2">
                            <Term component="Representaciones" text="Procedimientos a los que se otorgará poderes" />
                        </Typography>
                        <List dense disablePadding>
                            {
                                procedimientosSeleccionados.map(x => (
                                    <ListItem key={`procedimiento_${x.codigo}`} dense>
                                        <ListItemIcon style={{ minWidth: 0 }}>
                                            <Checkbox
                                                edge="start"
                                                disabled
                                                color="primary"
                                                checked={true}
                                                tabIndex={-1}
                                                inputProps={{ 'aria-labelledby': `procedimiento_${x.codigo}` }}
                                                onChange={handleSelectProcedimiento(x)}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            id={`procedimiento_${x.codigo}`}
                                            primary={<Term component="Representaciones" text={`PROCEDIMIENTO_${x.codigo}_NOMBRE`} />}
                                        />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </div>

                    {
                        sendStatus.success === true && <Alert severity="success">
                            <Term component="Representaciones" text="registrado_correctamente" />
                        </Alert>
                    }
                    {
                        sendStatus.success === false && <Alert severity="error">
                            <Term component="Representaciones" text={sendStatus.mensaje ? sendStatus.mensaje : "error_procesar_solicitud"} />
                        </Alert>
                    }

                </DialogContent>

                <DialogActions>
                    <Button disabled={sendStatus.sending} color="default" onClick={handleCloseSendDialog}>
                        <Term component="Representaciones" text="Cerrar" />
                    </Button>
                    {
                        sendStatus.success === true
                        && <Button disabled={sendStatus.sending} color="primary" autoFocus onClick={handleDownload}>
                            <Term component="Representaciones" text="Descargar justificante" />
                        </Button>
                    }
                    {
                        sendStatus.success !== true
                        && <Button disabled={sendStatus.sending} color="primary" autoFocus onClick={handleSubmit}>
                            <Term component="Representaciones" text="Firmar Enviar" />
                        </Button>
                    }
                </DialogActions>

            </Dialog>
        </div>
    )
}