import React, { FC } from 'react';
import { RouteComponentProps, withRouter, Route } from 'react-router-dom';
import Contenidos from './contenidos';

const ContenidoIndex: FC<RouteComponentProps> = ({ match }) => {
    return (
        <>
            <Route exact path={`${match.path}/:landingName`} component={Contenidos} />            
        </>
    );
}

export default withRouter(ContenidoIndex);