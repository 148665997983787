import { TDirection, EMPTY_DIRECTION } from "components/DUTI/direction-form";
import { TDirectionATIB } from "components/DUTI/direction-form-ATIB";
import { translate } from "utils/i18n";
import { IComprador,  IVendedor, TDesgloseDirFiscal, TDTDatosComprador, TDTDatosOperacionM600, TDTDatosVendedor, TDTDatosVendedorPlusval, TDTInfoTransmision, TDTPrevios, TDUSTIInfo, TPasoDatosTransmision, TPlusvOperacion, TTipoTransmitente } from "./steps/types";
import moment from "moment";
import { Grid, Typography } from "@material-ui/core";





type ObtainKeys<T, V> = keyof {
  [K in keyof T as T[K] extends V ? K : never]: any
}

/** SELECTORES DIRECCION */
export type TSelectExtended = TSelect & { param?: any}
export type TSelect = {id:string, nombre: string}
export function formatSelectOptions<T>(
    idName: keyof T,
    textName: keyof T,
    list: T[]
  ) {
    const out: TSelect[] = list.map((item: T) => ({
      id: String(item[idName]) ,
      nombre: String(item[textName])
    }));
    return out;
  }

export type TSelectDUTI = {id:number,code: string, nombre: string}

export function formatSelectDUTIOptions<T>(
    idName: keyof T,
    codeName: keyof T,
    textName: keyof T,
    list: T[]
) {
  const out: TSelectDUTI[] = list.map((item: T) => ({
    id: Number(item[idName]) ,
    code: String(item[codeName]) ,
    nombre: String(item[textName])
  }));
  return out;
}

interface IGenericObject {[key: string]: any;}


// Transforma TDireccion a texto
export const direccionToText = (dir: TDirection | TDirectionATIB, terms:any) => {
  const itemsRow1: (string|number)[]= []
  const itemsRow2= []
  if(dir !== undefined){
    itemsRow1.push(dir.tipoVia? dir.tipoVia.id : '')
    itemsRow1.push((dir.nombreVia ?? '').concat(', '))
    itemsRow1.push(dir.numero ?? '')
    itemsRow1.push(dir.piso ? translate('DUTI', 'piso',terms).concat(`: ${dir.piso}`) : '')
    itemsRow1.push(dir.puerta ? translate('DUTI', 'puerta',terms).concat(`: ${dir.puerta}`) : '')
    itemsRow1.push(dir.escalera ? translate('DUTI', 'escalera',terms).concat(`: ${dir.escalera}`) : '')
    itemsRow1.push(dir.letra ? translate('DUTI', 'letra',terms).concat(`: ${dir.letra}`) : '')

    itemsRow1.push(dir.cp ? translate('DUTI', 'CP',terms).concat(`: ${dir.cp}`) : '')
    itemsRow1.push( dir.municipio?.nombre ? ', '.concat(dir.municipio.nombre) : '')
    itemsRow1.push( dir.provincia?.nombre ? ', '.concat(dir.provincia.nombre) : '')

    const r1 = itemsRow1.join(' ')
    //const r2 = itemsRow2.join(' ')
    return r1 //[r1,r2]. join(`\n`)
  } else {
    return ''
  }
}
// Transforma direccion del catastro al objeto TDirection
export const REGEX_CP = new RegExp(/^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/) // CP españa 01000 - 52999
const REGEX_NUM = new RegExp(/^[0-9]{1,3}$/) // solo numeros y longitud 3 => 999
const REGEX_Letters = new RegExp(/^[A-Z]+$/i) // solo letras

//FEGA.gob.es => Referencia Catastral, Texto(20), \d{7}[A-Z]{2}\d{4}[A-Z]\d{4}[A-Z]{2}|\d{5}[A-Z]\d{12}[A-Z]{2},
// Formato Referencia Catastral 1: 7 dígitos, 2 letras, 4 dígitos, Letra, 4 dígitos, 2 letras
// Formato Referencia Catastral 2: 5 dígitos, Letras, 12 dígitos, Letra,  2 letras
// Diseminados: formato en base a ejemplo 000201400DD89C0001KT (ALARÓ)
export const REGEX_REF_CATASTRAL_1 = new RegExp(/^\d{7}[A-Z]{2}\d{4}[A-Z]\d{4}[A-Z]{2}$/)
export const REGEX_REF_CATASTRAL_2 = new RegExp(/^\d{5}[A-Z]\d{12}[A-Z]{2}$/)
export const REGEX_REF_CATASTRAL_3 = new RegExp(/^\d{5}[A-Z]\d[A-Z]{2}\d{4}[A-Z]\d{4}[A-Z]{2}$/)
export const REGEX_REF_CATASTRAL_DISEMINADOS = new RegExp(/^\d{9}[A-Z]{2}\d{2}[A-Z]\d{4}[A-Z]{2}$/)


export type AuxDirFormater = { municipio: TSelectDUTI[],provincia: TSelect[],tipoVia: TSelect[] }
export const domicilioCatastroToDireccion = (dir: string, aux: AuxDirFormater) => {
    const dir_split = dir.split(' ')
    console.log('domicilioCatastroToDireccion => ', dir)

    const numIdx = dir_split.findIndex( s => REGEX_NUM.test(s))
    console.log('numero: ',dir_split[numIdx])
    const cpIdx = dir_split.findIndex( s => REGEX_CP.test(s))
    console.log('cp: ',dir_split[cpIdx])
    const pisoIdx = dir_split.findIndex( s => s.includes('Pl:') )
    const piso =pisoIdx >0 ? dir_split[pisoIdx].replace('Pl:', '') : ''
    console.log('piso ',piso)
    const puertaIdx = dir_split.findIndex( s => s.includes('Pt:') )
    const puerta = puertaIdx>0 ? dir_split[puertaIdx].replace('Pt:', '') :''
    console.log('puerta ',puerta)


    const provInitIdx = dir_split.findIndex( s => s.includes('(') )
    const provEndIdx = dir_split.findIndex( s => s.includes(')') )
    const prov = provInitIdx === provEndIdx 
        ? dir_split[provInitIdx].replace('(','') 
        : [ dir_split[provInitIdx].replace('(',''), dir_split[provEndIdx].replace(')','') ].join(' ')
    const provincia = aux.provincia.find( p => p.nombre.toUpperCase()  === prov.toUpperCase() )
    const muni = dir_split.slice(cpIdx+1,provInitIdx).join(' ')
    const municipio = aux.municipio.find( m => m.nombre.toUpperCase() === muni.toUpperCase())
    const calle = dir_split.slice(1,numIdx ?? pisoIdx).filter( d => REGEX_Letters.test(d))
    const sigla = dir_split[0]
    const tipoVia= aux.tipoVia.find( t=> t.id.toUpperCase() === sigla.toUpperCase()  || t.id.toUpperCase().includes(sigla.toUpperCase() ))

    const out: TDirection = {
      ...EMPTY_DIRECTION,
      cp: cpIdx ? dir_split[cpIdx]: undefined,
      numero: dir_split[numIdx] ? Number(dir_split[numIdx]): undefined,
      tipoVia: tipoVia,
      provincia: provincia,
      municipio: municipio,
      nombreVia: calle.join(' '),
      piso: piso,
      puerta: puerta
    }

    console.log('Formated => ', out)

    return out
}

export const idDeclaracionAsRefDusti = ( id:number) => {
  const anyo = new Date().getFullYear();
  return `DUSTI${anyo}-${id}`
}

export const calcularEdad = (p_fechaNacimiento: Date) => {
  const today = new Date();
  const fechaNacimiento= new Date(p_fechaNacimiento);
  //Restamos los años
  const año = fechaNacimiento.getFullYear()
  let años = today.getFullYear() - año;
  // Si no ha llegado su cumpleaños le restamos el año por cumplir
  if (fechaNacimiento.getMonth() > (today.getMonth()) || fechaNacimiento.getDate() > today.getDate()){ 
    años--
  };
  return años
}

// Formatear numero con separador de miles => STRING
export const formatNumStr = ( value: number ): string => {
  const formatedNum= value.toLocaleString('es-ES', { maximumFractionDigits:2, minimumFractionDigits:0})
  return formatedNum
}

// DUSTI to resumen
const renderField = (term:string, value: string|undefined ,terms:any, addPadding?:boolean, concatTerm?: string)=>(
  <Grid item container direction="row" alignItems="flex-start" spacing={1}>
    <Grid item xs={4}>
      <Typography style={{paddingLeft: addPadding ? 10: 0}}>{ translate('DUTI', term, terms).concat(concatTerm ?? '') }:</Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography style={{fontWeight: 450}}>{value}</Typography>
    </Grid>
  </Grid>
);

export const renderConfirmDUSTI = (info: TDUSTIInfo, terms:any) => {
  const renderDatosPrevios = (dp: TDTPrevios) => {
    return (
      <>
        <Typography style={{fontWeight:'bolder', borderBottom:'solid', borderBottomWidth: 1,borderBottomColor: '#d3d3d3', paddingTop:5}}
         // style={{fontWeight:'bolder', border:'solid', borderColor: '#d3d3d3', borderTopWidth: 1, borderBottomWidth: 1, borderLeft:0 ,borderRight:0}} 
        >
        {translate('DUTI','DTPrevios',terms).toUpperCase()}
        </Typography>
        {renderField('municipio', dp.municipio?.nombre ?? '', terms)}
        {renderField('refCatastral', dp.infoCatastral?.refCatastral, terms)}
        {renderField('esComplementaria', dp.esComplementaria? 'Sí': 'No', terms)}
        {renderField('esPlenoDominio', dp.esPlenoDominio? 'Sí': 'No', terms)}
        {renderField('actuaNombrePropio', dp.actuaNombrePropio? 'Sí': 'No', terms)}
       
        {renderField('representante', (dp.representante !== undefined && dp.representante.nif )  ? '': 'No', terms)}
        { dp.representante && dp.representante.nif && 
          <>
            {renderField('nif', dp.representante.nif, terms)}
            {renderField('nomApe', dp.representante.nomApe, terms)}
            {renderField('direccion', direccionToText(dp.representante.direccion, terms), terms)}
            {renderField('email', dp.representante.contacto?.email, terms)}
            {renderField('telefono', dp.representante.contacto?.telefono?.toString(),terms)}
          </>
        }
        {renderField('tipoTransmitente', dp.tipoTransmitente, terms)}
        {renderField('nifSujeto', dp.nifSujeto, terms)}
        {renderField('tramite_comunicacion_domicilio_fiscal_aeat', dp.tramitesGest?.COM_DOMFISC ? 'Sí': 'No', terms)}
        {renderField('tramite_ibi_atib', dp.tramitesGest?.IBI? 'Sí': 'No', terms)}
        {renderField('tramite_tasas_atib', dp.tramitesGest?.TASAS_MUNI? 'Sí': 'No', terms)}
        {renderField('tramite_modelo_600', dp.tramitesGest?.MODELO600 === false ? 'No':undefined,terms)}
        {dp.tramitesGest?.MODELO600 !== false && renderField('tramite_modelo_600_autoliquidacion',  dp.tramitesGest?.MODELO600.autoliquidacion === true ? 'Sí': 'No',terms)}
        {dp.tramitesGest?.MODELO600 !== false && renderField('tramite_modelo_600_autoliquidacion_presentacion',  dp.tramitesGest?.MODELO600.autoliquidacionPresentacion === true ? 'Sí': 'No',terms)}
        {renderField('tramite_plusvalia_atib', dp.tramitesGest?.PLUSVAL === false ? 'No' : undefined, terms)}
        {dp.tramitesGest?.PLUSVAL !== false && renderField('tramite_gest_ask_plusval', dp.tramitesGest?.PLUSVAL.sujetoPasivo === true ? 'Sí' : 'No', terms)}
        {dp.tramitesGest?.PLUSVAL !== false && renderField('tramite_plusvalia_autoliquidacion_atib', dp.tramitesGest?.PLUSVAL.autoliquidacion === true ? 'Sí' : 'No', terms)}
        {dp.tramitesGest?.PLUSVAL !== false && renderField('tramite_plusvalia_comunicacion_atib', dp.tramitesGest?.PLUSVAL.comunicacion === true ? 'Sí' : 'No', terms)}
        {dp.tramitesGest?.PLUSVAL !== false &&renderField('tramite_plusvalia_declaracion_atib', dp.tramitesGest?.PLUSVAL.declaracion === true ? 'Sí' : 'No', terms)}
      </>
      
    )
  }

  const renderInfoTransmsision = (it: TDTInfoTransmision) => {
    return(
      <>
        <Typography style={{fontWeight:'bolder', borderTop:'solid',borderBottom:'solid', borderColor: '#d3d3d3', borderTopWidth: 1, borderBottomWidth: 1, paddingTop:5}} >
          {translate('DUTI','DTInfoTransmision',terms).toUpperCase()}
        </Typography>
        {renderField('refCatastral', it.refCatastral, terms)}
        {renderField('fecha', moment(it.fecha).format('DD/MM/YYYY'), terms)}
        {renderField('tipoTransmision', it.tipoTransmision?.nombre, terms)}
        {renderField('tipoDocTransmision', it.tipoDocTransmision?.nombre, terms)}
        {renderField('tipoTransmision', it.tipoTransmision?.nombre, terms)}
        {renderField('anyo', it.anyo?.toString(), terms)}
        {renderField('numeroProtocolo', it.numeroProtocolo?.toString(), terms)}
        {renderField('nifNotario', it.nifNotario, terms)}
        {renderField('notario', it.notario, terms)}
        {renderField('valorDeclarado', it.valorDeclarado? formatNumStr(it.valorDeclarado)+'€': "", terms)}
        {renderField('porcentaje', it.porcentaje ? it.porcentaje.toString()+'%': "", terms)}
        {renderField('idDocumentoEscritura', it.idDocumentoEscritura, terms)}
      
      { it.withPlusval && it.plusval &&
        <>
          {renderField('oficina', it.plusval.oficina, terms)}
          {renderField('tomo', it.plusval.tomo, terms)}
          {renderField('libro', it.plusval.libro, terms)}
          {renderField('finca', it.plusval.finca, terms)}
          {renderField('folio', it.plusval.folio, terms)}
        </>
      }
      </>
    )
  }

  const renderDatosCompradorVendedor = <T extends TTipoTransmitente>(dt: T extends 'comprador' ? TDTDatosComprador :TDTDatosVendedor,tipo :T) => {
    return(
      <>
        <Typography style={{fontWeight:'bolder', borderTop:'solid',borderBottom:'solid', borderColor: '#d3d3d3', borderTopWidth: 1, borderBottomWidth: 1,paddingTop:5}}>
          {translate('DUTI',tipo === 'comprador' ? 'DTCompradores': 'DTVendedores',terms).toUpperCase()}
        </Typography>
        {dt.map( (c, idx) => (
          <>
            <Typography style={{textDecoration: 'underline'}}>{`${translate('DUTI',`datos_${tipo}`,terms).toUpperCase()} ${idx+1}:`}</Typography>
            {renderField('nif', c.nif, terms, true)}
            {renderField('nomApe', c.nomApe, terms, true)}
            {renderField('fechaNacimiento', c.fechaNacimiento? moment(c.fechaNacimiento).format('DD/MM/YYYY'): "", terms, true)}
            {renderField('direccion', direccionToText(c.direccion, terms), terms, true)}
            {renderField('email', c.contacto?.email, terms, true)}
            {renderField('telefono', c.contacto?.telefono?.toString(),terms, true)}
            {renderField(tipo === 'comprador'? 'porcentajeAdquisicion': 'porcentajeTitularidad', c.porcentaje.toString()+'%',terms, true)}
            {renderField('direccionFiscal', typeof c.direccionFiscal === 'string' ? c.direccionFiscal ?? '' : '' ,terms, true)}
            {tipo === 'comprador' && renderField('viviendaHabitual', (c as IComprador).viviendaHabitual ? 'Sí':'No',terms, true)}
            {tipo === 'vendedor' && renderField('numOperacionesAdquisicion', (c as IVendedor).numOperacionesAdquisicion.toString(),terms, true)}
          </>
        ))}
      </>
    )
  }

  const renderDatosM600 = (m: TDTDatosOperacionM600) => {
    return (
      <>
        <Typography style={{fontWeight:'bolder', borderTop:'solid',borderBottom:'solid', borderColor: '#d3d3d3', borderTopWidth: 1, borderBottomWidth: 1, paddingTop:5}}>
          {translate('DUTI','DTM600',terms).toUpperCase()}
        </Typography>
        {renderField('baseImponible', m.baseImponible ? formatNumStr(m.baseImponible)+'€': "", terms)}
        {renderField('interesesDemora', m.interesesDemora ? formatNumStr(m.interesesDemora)+'€' : "", terms)}
        {renderField('recargo', m.recargo ? formatNumStr(m.recargo)+'€' : "", terms)}
        {renderField('concepto', m.concepto ? m.concepto.id+' - '+ m.concepto.nombre : "", terms)}
        {renderField('tipo', m.tipo ? m.tipo.nombre : "", terms)}
        {renderField('noSujeto', m.noSujeto===true ? 'Sí': 'No', terms)}
        {renderField('exento', m.exento===true ? 'Sí': 'No', terms)}
        {renderField('observaciones', m.observaciones ?? " - ", terms)}
        {renderField('baseLiquidable', m.baseLiquidable?formatNumStr(m.baseLiquidable)+'€' : '', terms)}
      </>
    )
  }

  const getImporteAnteriorTransmision = (operaciones: TPlusvOperacion[]) => {
    let importeAnteriorTransmision = 0;

    operaciones.forEach( (op) => {
      if(op.importeAnteriorAdquisicion){
        importeAnteriorTransmision += op.importeAnteriorAdquisicion
      }
    });

    return importeAnteriorTransmision > 0 ? formatNumStr(importeAnteriorTransmision) + '€' : "";
  }

  const renderPlusval= (pl: TDTDatosVendedorPlusval) => {

    return (
      <>
      <Typography style={{fontWeight:'bolder', borderTop:'solid',borderBottom:'solid', borderColor: '#d3d3d3', borderTopWidth: 1, borderBottomWidth: 1,paddingTop:5}}>
        {translate('DUTI','DTPlusvalia',terms).toUpperCase()}
      </Typography>
      <>
        {pl.map( (p, idx) => {
          return (
            <>
              <Typography style={{textDecoration: 'underline'}}>{`${translate('DUTI','datosPlusvalia' ,terms)} ${idx+1}:`}</Typography>
              {renderField('nifVendedor',p.nifVendedor,terms, true)}
              {renderField('nifSujetoPasivo',p.nifSujetoPasivo,terms, true)}
              {renderField('sujetoPasivo',p.sujetoPasivo === true? 'Sí': 'No',terms, true)}
              <div style={{paddingLeft: 10,paddingRight:10 }}>
              {p.operaciones.length > 0 && ( p.modelo080 || p.modelo082 ) && 
                <>
                  <Typography>{`${translate('DUTI','operacion' ,terms)} ${idx+1}:`}</Typography>
                  {p.operaciones.map((op,idx) => (
                    <>
                      {renderField('fechaAnteriorAdquisicion',  op.fechaAnteriorAdquisicion ? moment(op.fechaAnteriorAdquisicion).format('DD/MM/YYYY'): "", terms, true)}
                      {renderField('porcentajeAnteriorAdquisicion',  op.porcentajeAnteriorAdquisicion? op.porcentajeAnteriorAdquisicion.toString()+"%" : "", terms, true)}
                      {renderField('importeAnteriorAdquisicion',  op.importeAnteriorAdquisicion ? formatNumStr(op.importeAnteriorAdquisicion)+'€' : "", terms, true)}
                      {renderField('idEscrituraAnterior',  op.idEscrituraAnterior?? "", terms, true)}
                    </>
                  ))}
                </>
              }
              {
                p.modelo080 && 
                <>
                  <Typography style={{fontWeight: 'bold'}}>{`${translate('DUTI','modelo080' ,terms)}`}</Typography>
                  {renderField('nifSujetoPasivo', p.modelo080.nifSujetoPasivo,terms, true)}
                  {renderField('fechaPresentacion', p.modelo080.fechaPresentacion ? moment(p.modelo080.fechaPresentacion).format('DD/MM/YYYY'): "",terms, true)}
                  {renderField('valorTransmision', p.modelo080.valorTransmision?formatNumStr(p.modelo080.valorTransmision)+'€': "",terms, true)}
                  {renderField('valorAdquisicion', p.modelo080.valorAdquisicion?formatNumStr(p.modelo080.valorAdquisicion)+'€': "",terms, true)}
                  {renderField('tipoDocAdquisicion', p.modelo080.tipoDocAdquisicion,terms, true)}
                  {renderField('fechaDocAdquisicion', p.modelo080.fechaDocAdquisicion ? moment(p.modelo080.fechaDocAdquisicion).format('DD/MM/YYYY'): "",terms, true)}
                </>
              }
              {
                p.modelo081 && 
                <>
                  {p.modelo081.adquisiciones.length>0 && 
                    <>{p.modelo081.adquisiciones.map((adq, adqIdx) => (
                      <div>
                          <Typography style={{textDecoration: 'underline'}}>{`${translate('DUTI','operacion' ,terms)} ${adqIdx+1}:`}</Typography>
                          <div style={{marginLeft: 30}}>
                              {renderField('fechaAdquisicion', adq.metodoObjetivo.fechaAdquisicion ? moment(adq.metodoObjetivo.fechaAdquisicion ).format('DD/MM/YYYY'): "",terms,true)}
                              {renderField('porcentajeAnteriorAdquisicion', adq.metodoObjetivo.porcentaje.toString() + "%",terms,true)}
                              {renderField('importeAnteriorAdquisicion', adq.metodoObjetivo.importeAnteriorAdquisicion ? formatNumStr(adq.metodoObjetivo.importeAnteriorAdquisicion)+'€' : "",terms,true)}
                                  
      
                              <div >
                                  <Typography style={{textDecoration: 'underline'}}>{translate('Plusvalias','metodoObjetivo' ,terms)}</Typography>
                                  {renderField('valorParcial', adq.metodoObjetivo.valorParcial ? formatNumStr(adq.metodoObjetivo.valorParcial)+'€' : "",terms,true)}
                                  {renderField('baseImponible', adq.metodoObjetivo.baseImponible ? formatNumStr(adq.metodoObjetivo.baseImponible)+'€' : "",terms,true)}
                                  {renderField('anyosTenencia', adq.metodoObjetivo.anyosTenencia.toString() ,terms,true)}
                                  {renderField('cuotaIntegra', adq.metodoObjetivo.cuotaIntegra ? formatNumStr(adq.metodoObjetivo.cuotaIntegra)+'€' : "",terms,true)}
                                  {renderField('tipoAnual', adq.metodoObjetivo.tipoAnual ? adq.metodoObjetivo.tipoAnual.toString()+'%' : "",terms,true)}
                                  {renderField('tipoGravamen', adq.metodoObjetivo.tipoGravamen ? adq.metodoObjetivo.tipoGravamen.toString()+'%' : "",terms,true)}


                                  {adq.metodoReal ?
                                      <div>
                                          <Typography style={{textDecoration: 'underline'}}>{translate('Plusvalias','metodoReal' ,terms)}</Typography>
                                      </div>
                                      : <div>
                                          <Typography style={{textDecoration: 'underline'}}>{translate('Plusvalias','metodoReal' ,terms)}</Typography>
                                          {renderField('noAplica',  "",terms,true)}
                                      </div>
                                  }


                              </div>
                          </div>
                      </div>
                  ))}
                    </>
                  }
                  <Typography style={{fontWeight: 'bold'}}>{`${translate('DUTI','modelo081' ,terms)}`}</Typography>
                  {renderField('nifSujetoPasivo', p.modelo081.nifSujetoPasivo,terms, true)}
                  {renderField('declaracionResponsable', p.modelo081.declaracionResponsable === true ? 'Sí': 'No',terms, true)}
                  {renderField('importeAnteriorTransmision', getImporteAnteriorTransmision(p.operaciones), terms, true)}
                  {renderField('baseImponible', p.modelo081.baseImponible ? formatNumStr(p.modelo081.baseImponible)+'€': "",terms, true)}
                  {renderField('bonificacion', p.modelo081.bonificacion ? formatNumStr(p.modelo081.bonificacion)+'€': "",terms, true)}
                 
                  {renderField('fechaPresentacion', p.modelo081.fechaPresentacion ? moment(p.modelo081.fechaPresentacion).format('DD/MM/YYYY'): "",terms, true)}
                  {renderField('fechaDevengo', p.modelo081.fechaDevengo ? moment(p.modelo081.fechaDevengo).format('DD/MM/YYYY'): "",terms, true)}
                  {renderField('tipoGravamen', p.modelo081.tipoGravamen ? p.modelo081.tipoGravamen.toString()+'%' : "",terms, true)}
                  
                  {renderField('porcentajeTransm', p.modelo081.porcentajeTransm ? p.modelo081.porcentajeTransm.toString()+'%' : "",terms, true)}
                  {renderField('fechaAdquisicionBien', p.modelo081.fechaAdquisicionBien ? moment(p.modelo081.fechaAdquisicionBien).format('DD/MM/YYYY'): "",terms, true)}
                  {renderField('importeAdquisicion081',  p.modelo081.importeAdquisicion ? formatNumStr(p.modelo081.importeAdquisicion)+'€' : "", terms, true)}
                  {renderField('importeTransmision081',  p.modelo081.importeTransmision ? formatNumStr(p.modelo081.importeTransmision)+'€': "", terms, true)}
                  {renderField('importeMetodoObjetivo',  p.modelo081.importeMetodoObjetivo ? formatNumStr(p.modelo081.importeMetodoObjetivo)+'€' : "", terms, true)}
                  {renderField('importeMetodoReal',  p.modelo081.importeMetodoReal ? formatNumStr(p.modelo081.importeMetodoReal)+'€': "", terms, true)}
                  {renderField('totalIngresar',  p.modelo081.totalIngresar ? formatNumStr(p.modelo081.totalIngresar)+'€': "", terms, true)}
                
                </>  
              }
              {
                p.modelo082 && 
                <>
                  <Typography style={{fontWeight: 'bold'}}>{`${translate('DUTI','modelo082' ,terms)}`}</Typography>
                  {renderField('nifSujetoPasivo', p.modelo082.nifSujetoPasivo,terms, true)}
                  {renderField('valorAdquisicion', p.modelo082.valorAdquisicion? formatNumStr(p.modelo082.valorAdquisicion)+'€': "",terms, true)}
                </>
              }
              </div>

            </>

          )

        })}
      </>
      </>
    )
  }

  const renderDesgloseDir= (d:TDesgloseDirFiscal) => {
    console.log('renderDesgloseDir ', d)
    const keys = Object.keys(d)
    console.log('keys ', keys)
    return(
      keys.map( k  => {
        return typeof d[k as keyof TDesgloseDirFiscal] === 'object' 
          ? renderField('dir_'+k , (d[k as keyof TDesgloseDirFiscal] as TSelect).nombre, terms)
          : d[k as keyof TDesgloseDirFiscal] ? renderField('dir_'+k , d[k as keyof TDesgloseDirFiscal] as string, terms) : null
      })
    )
  }
  const keys= Object.keys(info)
  return(
    <Grid container >
      {keys.map( (k,idx) =>( 
        <Grid item xs={12}>
          <div>
            <Typography style={{fontWeight:'bolder',fontSize: 18, marginTop: 20, marginBottom: 5,borderBottom:'solid', borderColor: '#000', borderBottomWidth: 1,}} >
              {idx+1} - {translate('DUTI', `step_${k}`,terms).toUpperCase()}
            </Typography>
            {k === 'DatosTransmision' ? 
              <>
                {renderDatosPrevios(info.DatosTransmision.datosPrevios)}
                {renderInfoTransmsision(info.DatosTransmision.infoTransmision)}
                {renderDatosCompradorVendedor(info.DatosTransmision.datosComprador, 'comprador')}
                {renderDatosCompradorVendedor(info.DatosTransmision.datosVendedor, 'vendedor')}
                { info.DatosTransmision.datosPrevios.tramitesGest.MODELO600!==false 
                  && (info.DatosTransmision.datosPrevios.tramitesGest.MODELO600.autoliquidacion === true || info.DatosTransmision.datosPrevios.tramitesGest.MODELO600.autoliquidacionPresentacion === true)
                  && renderDatosM600(info.DatosTransmision.datosOperacionModelo600)
                }
                { info.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL!==false 
                  && (
                    info.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL.autoliquidacion === true 
                    || info.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL.comunicacion === true
                    || info.DatosTransmision.datosPrevios.tramitesGest.PLUSVAL.declaracion === true
                  ) && 
                  renderPlusval(info.DatosTransmision.datosOperacionPlusvalia)}
              </>
              : null
            }
            {k === 'TramitesGenerados' ? 
              <>
                {renderField('tramite_ibi_atib', info.TramitesGenerados.comunicacionCatastro === true ? 'Sí': 'No', terms)}
                {renderField('tramite_comunicacion_domicilio_fiscal_aeat', info.TramitesGenerados.comunicacionDomicilioFiscalAEAT === true ? 'Sí': 'No', terms)}
                {renderField('tramite_tasas_atib', info.TramitesGenerados.comunicacionTasasATIB === true ? 'Sí': 'No', terms)}
                {renderField('tramite_modelo_600', info.TramitesGenerados.modelo600 === true ? 'Sí': 'No', terms)}
                {renderField('tramite_plusvalia_atib', (info.TramitesGenerados.modelo080 === true || info.TramitesGenerados.modelo081 === true || info.TramitesGenerados.modelo082 === true) ? 'Sí': 'No', terms)}
              </>
              : null
            }
            { k === 'DesgloseDirAEAT' && info.DesgloseDirAEAT?.nombreVia && info.DesgloseDirAEAT.nombreVia.length >0 ?
              <>
              {renderDesgloseDir(info.DesgloseDirAEAT)}
              </>
              :null  
            }
          </div>
        </Grid>
      ))}
    </Grid>
  )
}