import { useEffect, FC, useContext, useCallback, useRef, useMemo } from 'react';
import clsx from 'clsx';

import { Card, Stepper, Step, StepLabel, StepConnector, Button, StepContent, Grid, StepIconProps, CardContent, CardHeader} from '@material-ui/core';

import { ALL_LITERALS, LiteralsContext, withLiterals } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import { MoreInfoContext } from 'contexts/more_info.context';
import { TInfoTemplateSize } from 'contexts/more_info.reducer';
import { default as HeaderComponent } from '../generic-component';
import GenericStepComponent from './generic-step-component';
import { useColorlibConnectorStyles, useColorlibStepIconStyles, useStyles } from './styles';


export type THeaderStepper = {refCat:string, dir: string, urlCatastro: string, muni: string|undefined}


export interface IStep {
    idTipoApartado: string;
    nameTermIG: string; // IG term para recuperar el nombre
    componentIG: string   // IG Componente para los textos
    orden: number;
    webOrder: number; // valor iniciado en 1 .. Se muestre en el stepper
    //datos: Record<string, any> | null; // valores de inicialización
    component: () => JSX.Element;
    moreInfoTemplate?: string;
    moreInfoTemplateSize?: TInfoTemplateSize;
}

interface IProps {
    idRef: string | null
    activeStep: number, // index of active step
    steps: IStep[],
    handleNext: (idRef?: string | null, infoHeader?: any) => void // as onNext Generic Stepper
    handlePrev: () => void,
    handleFinish: () => void,
    handleExit: () => void,
    size?: 'default' | 'small';
    withHeader?:boolean,
    componentHeader?: () => JSX.Element,
    headerProps?: any;
}




const GenericStepper: FC<IProps> = ({
    idRef, steps, activeStep, handlePrev, handleNext, handleFinish, handleExit, withHeader, headerProps,componentHeader, 
    size='default'
}) => {
    const classes = useStyles();
    const classesIcon = useColorlibStepIconStyles();
    const classesLine = useColorlibConnectorStyles();

    
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);
    // const [headerStepper, setHeaderStepper]= useState<THeaderStepper | null>(null)

    
    // const [heightPaper, setHeightPaper] = useState(720);

    const ref = useRef(null);

    const ready: boolean = useMemo(() => { 
        return (steps && steps.length>0 ) ? true : false
    }, [steps])
    // ---------------------------------------------------------------------------------------------------------

    const handleShowMoreInfo = useCallback(() => {
        if (steps && activeStep && steps[activeStep].moreInfoTemplate) {
            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: steps[activeStep].moreInfoTemplate ?? '',
                    size: steps[activeStep].moreInfoTemplateSize ?? undefined,
                }
            });
        }
    }, [infoDispatch, activeStep, steps ])



    useEffect(() => {
        // 👇️ use a ref (best)
        const element2 = ref.current;
        // 👇️ use document.querySelector()
        // should only be used when you can't set a ref prop on the element
        // (you don't have access to the element)
        const element = document.querySelector('#paper');
    }, []);


    return (
        <Grid container direction={window.screen.width <= 960 ? 'column-reverse' : 'row'} 
            style={{
                flexGrow: 1,
                display:'flex',
                flexWrap: 'nowrap',
                minHeight: window.screen.width <= 960 ? undefined : 600,
                width: '100%',
                height: window.screen.width <= 960 ? '100%' : window.innerHeight -324 //window.screen.height - 384
            }}
        >
            {ready && 
            <>
                <Grid 
                    item 
                    xs={window.screen.width <= 960 ? 12 : 9}
                    sm={12} 
                    md={9}
                    lg={9}
                    xl={10}
                    style={{
                        display:'flex',
                        flexGrow:1,
                        marginTop: window.screen.width <= 960 ? 10: 0,
                        marginBottom: 0 
                    }}
                >
                    <Card elevation={1}
                        style={{
                            display:'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            marginLeft: 10,
                            marginRight: window.screen.width <= 960 ? 10: undefined,
                        }}
                    >
                        <CardHeader className={classes.borderBottom}
                            title= {translate(steps[activeStep].componentIG, steps[activeStep].nameTermIG, terms).toUpperCase()}
                            subheader={
                                steps[activeStep].moreInfoTemplate 
                                ?
                                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleShowMoreInfo} size="small">
                                        {translate("Global", "Más información", terms)}
                                    </Button>
                                : null
                            }
                            action={undefined}
                        />
                        <CardContent className={classes.cardContent}>
                            <GenericStepComponent
                                component={steps[activeStep].component} 
                                componentProps={{
                                    idRef: idRef,
                                    apartado: steps[activeStep].idTipoApartado,
                                    onNext: (referencia?: string | null, infoHeader?: any) => {
                                        //console.log('hi from stepper/generic-component/ on-save')
                                        if(activeStep === (steps.length-1)){ handleFinish()}
                                        else { handleNext(referencia, infoHeader) }
                                    },
                                    onBack: handlePrev,
                                    onCancel: handleExit,
                                    isFirst: activeStep === 0,
                                    isLast: activeStep === (steps.length-1) 
                                }}
                            />
                        </CardContent> 
                    </Card> 

                </Grid>
            
                <Grid item 
                    style={{
                        display:'flex',
                        flexGrow: 1, 
                        minHeight: window.screen.width <= 960 ? undefined : 600,
                        minWidth: window.screen.width <= 960 ? '100%' : '300px',
                        marginTop: window.screen.width <= 960 ? 10: 0 ,
                        flexDirection:  window.screen.width <= 960 ? 'row': 'column',
                        position: 'relative',
                    }}
                >   

                    {/** info inmueble */}
                    { withHeader && componentHeader && 
                        <div style={{top:0}} className={classes.stepperHeaderFooter}>
                            <HeaderComponent
                                component={componentHeader} 
                                componentProps={headerProps}
                            />
                        </div>
                    }
                    

                    {/** Pasos Stepper */}
                    <Stepper id='paper' ref={ref} 
                        className={classes.stepperContainer}
                        style={ withHeader ? {
                            paddingTop: window.screen.width <= 960 ? 120 : 135, // HeaderStepper
                            paddingBottom: window.screen.width <= 960 ? 10 : 80, // Footer
                        }: undefined}
                        orientation={ window.screen.width <= 960 ? "horizontal" : "vertical"} 
                        activeStep={activeStep} 
                        connector={
                        <StepConnector classes={{
                            line: size === 'small' ? classesLine.lineSmall :classesLine.line
                        }}
                            style={{flexGrow : 0}}/>} 
                    >
                        {steps.map((step, index) => (
                            <Step key={step.idTipoApartado}>
                                <StepLabel StepIconComponent={ (props: StepIconProps) =>(
                                    <div style={{
                                        width: size === 'small' ? 45: 50,
                                        height: size === 'small' ? 45: 50,
                                    }}
                                        className={clsx(classesIcon.root, {
                                        [classesIcon.active]: props.active,
                                        [classesIcon.completed]: props.completed,
                                        })}
                                    >
                                        {step.webOrder}
                                    </div>
                                    )}
                                >
                                    <div className={[
                                        activeStep === index 
                                            ? classes.boldText 
                                            : activeStep > index  ? classes.completedText : classes.lightText,
                                        size === 'small' ? classes.textSmall: undefined
                                    ].join(' ')}>
                                        {translate(step.componentIG, step.nameTermIG, terms).toUpperCase()}
                                    </div>
                                </StepLabel>
                                <StepContent style={{flexGrow: 1, flex: 'none'}}>{}</StepContent>
                            </Step>
                        ))}
                    </Stepper>

                    {/** Footer -> foto municipio */}
                    {/* {headerStepper && headerStepper.muni && !(window.screen.width <= 960) && 
                        <div style={{ bottom:0, paddingRight:0, paddingLeft: 0}} className={classes.stepperFooter} >
                            <div style={{backgroundColor: '#dfdfdf', height: 1, display:'flex'}}></div>
                            <div className={classes.containerImgFooter}>
                                <img width={'100%'} height={'70px'} style={{objectFit: 'scale-down'}} 
                                    src={`https://www.atib.es/App_Themes/LogosMuni/${headerStepper.muni}.png`} 
                                    alt={`logo ${headerStepper.muni}`}
                                />
                            </div>
                        </div>
                    } */}

                </Grid>

            </>
            }
        </Grid>

    );
}

export default withLiterals(ALL_LITERALS)(GenericStepper);





