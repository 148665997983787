import { StyleRules, Theme } from "@material-ui/core";
import { BLUE } from "containers/shared/colors";

const plv_styles =  (theme: Theme): StyleRules => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    /** Cuadro azul ---------------------------------------------------------------------------------------- */
    borderPlusval: {
        border:'solid',
        borderWidth: '1px',
        borderRadius: 10,
        borderColor: BLUE,
        padding: '20px 10px 10px 10px',
        marginBottom: 10,
        position: 'relative'
    },
    divTextBorderPlusval: {
        display: 'flex',        /* Activa Flexbox */
        justifyContent: 'flex-end', /* Alinea el contenido al final (derecha) */
        width: '100%',         /* Asegura que ocupe todo el ancho del Grid */
        position: 'absolute',   /* Posicionamiento absoluto respecto a su contenedor relativo */
        right: 20,            /* Alinea a la derecha */
        top: -20,              /* Alinea al borde superior */
    },
    textBorderPlusval: {
        padding: 10,
        color: BLUE,
        backgroundColor: 'white'
    },
    /** END Cuadro azul ------------------------------------------------------------------------------------ */

})

export default plv_styles