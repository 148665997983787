import {FC, useContext, useEffect,  useState} from "react";

import { withStyles, WithStyles, TextField, TextFieldProps, } from "@material-ui/core";
import { translate } from "utils/i18n";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import styles from "./styles";
import { Autocomplete } from "@material-ui/lab";

export type TSelectDUTI = {id:number,code: string, nombre: string}
export type TSelect = {id:string, nombre: string}
export type TExtraValidation = {
    error:boolean, 
    error_msg: string | undefined,
    componentIG?:string
}

type TMSGError = 'err_required' 
type TInput = Pick<TextFieldProps, 'className' |'disabled'| 'variant' | 'size' | 'margin'|'error'> & WithStyles
interface IGenericInputSelect extends TInput {
    componentIG: string
    labelTerm: string,
    value: TSelect|TSelectDUTI | undefined,
    options: TSelect[] |TSelectDUTI[],
    onChangeValue: (v: TSelect|TSelectDUTI|undefined, err: boolean) => void,
    required: boolean | undefined
};

const GenericInputSelect : FC<IGenericInputSelect>= ({
    classes,componentIG="GenericComponents", labelTerm, value, options, onChangeValue, 
    required = false, error,
    variant='outlined', size='small', margin='dense',className ,
    disabled, 
}) => {
    const terms = useContext(LiteralsContext);

    const [err, setErr] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState< string|undefined>(undefined)
    const empyOption : TSelect = {id: '-', nombre: ""}
    const validate = (value: TSelect| TSelectDUTI | undefined) => {
        let msg : TMSGError|undefined = undefined
        const requiredErr = !required 
            ? false 
            : value && value.id ? false : true 
        //console.log('requiredErr ->', requiredErr , 'required => ', required , value)

        if(requiredErr) msg='err_required'

        setErrMsg(msg ? translate('GenericComponents', msg,terms): undefined)
        setErr( requiredErr )
        return  requiredErr 
    }

    useEffect(() => {
        if(options && options.length>0){
            if(value === undefined && required && options.length === 1){
                onChangeValue(options[0], false)
            } 
        }
    }, [options, value])

    useEffect(() => {
        // setea el error que me envían desde fuera
        if(error !== undefined){ 
            setErr(error)
        }
    }, [error])


    //console.log('options',options, value)
    return (
        <div style={{display:'flex', flexDirection: 'row', alignItems:'center', marginRight: 10}} >
            <Autocomplete<TSelect|TSelectDUTI|null, false, boolean, false>
                disabled={options.length===0 || disabled}
                autoComplete={true}
                multiple={false}
                freeSolo={false}
                options={options}
                value={ value === undefined ? null: value}
                defaultValue={null}
                onChange={(ev,new_value) => {
                    const err = validate( new_value !== null ? new_value: undefined)
                    onChangeValue( new_value !== null ? new_value : undefined , err)
                }}             
                disableClearable={required}
                getOptionLabel={(opt) => {
                    return (opt && opt.nombre ? opt.nombre:  "")
                }}
                getOptionSelected={(opt, val) => {
                    return (val && opt && opt.id && val.id  ? val.id=== opt.id : false)
                }}
                onBlur={() => {
                    const err = validate(value)
                    err && onChangeValue(value, err)
                }}
                className={className ?? classes.inputDefault}
                renderInput={(params) => { 
                    //console.log(params)
                    return(
                        <TextField {...params} 
                            size={size}  
                            margin={margin}
                            label={translate(componentIG,labelTerm, terms).concat(required ? '*':'')} 
                            variant={variant}
                            InputLabelProps={{classes: {
                                root: classes.inputLabel,
                            }}}
                            error={err}
                            helperText={ err ? (errMsg ?? ' ') : ' '}
                        />
                    )
                }}
            />

        </div>
           
    )
}

export default withLiterals(ALL_LITERALS)(withStyles(styles)(GenericInputSelect));