import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";

const styles = (theme: Theme): StyleRules => ({
    root: {
        flexWrap: 'nowrap',
        position: 'relative'
    },
    contentRoot: {
        flexWrap: 'nowrap'
    },
    grow: {
        flexGrow: 1
    },
    highlightText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#000',
        fontSize: 12,
        fontWeight: 700
    },
    pagoCartaText: {
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5,
        fontWeight: 'bold',
        color: '#004f82',
    },
    etiquetaText: {
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5,
        fontWeight: 'bold',
        color: '#004f82',
    },
    detailText: {
        color: '#000',
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5
    },
    detailTextWarning: {
        color: '#ff0000',
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5
    },
    tagDomiciliados: {
        fontSize: 12,
        padding: '0px 5px',
        backgroundColor: '#E1E1E1',
        borderRadius: '2px',
        maxWidth: '100px',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    tagPagoCarta: {
        fontSize: 12,
        padding: '0px 5px',
        backgroundColor: '#C6E5FD',
        borderRadius: '2px',
        maxWidth: 'fit-content',//,130,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    gridDomi: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    price: {
        marginLeft: 10,
        marginRight: 10,
        color: '#406a8d',
        fontSize: 12,
        fontWeight: 700,
        [theme.breakpoints.up('md')]: {
            fontSize: 18
        }
    },
    priceNotResalted: {
        flex: 1,
        color: '#406a8d',
        fontSize: 12,
        [theme.breakpoints.up('md')]: {
            fontSize: 12
        }
    },
    priceEjecutiva: {
        color: '#ca261b'
    },
    imagenContainer: {
        width: 40,
        height: 40,
        backgroundColor: '#92b58e',
        borderRadius: '50%',
        marginRight: 15
    },
    imagen: {
        width: 40,
        height: 40
    },
    checked: {
        backgroundColor: '#fff',
        borderRadius: '50%',
        border: '1px solid #000',
        '& svg': {
            margin: '0 auto',
        }
    },
    imageGrid: {
        alignSelf: 'center'
    },
    fullButton: {
        width: '100%'
    },
    width0: {
        width: 0
    },
    btnAddPay: {
    },
    containerBtnPagoCarta: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    btnPagoCarta: {
        color: 'white',
        width: 205,
    },
    btnPagoCarta2: {
        color: 'white',
        width: 'fit-content',
    },
    btnDomi: {
        width: 175,
    },
    minWidth90: {
        minWidth: 90,
    },
    widthBtnDomiGrid205: {
        minWidth: 205
    },
    btnMoreInfo: {
        marginRight: 10
    },
    bannerBtn: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        flex: 1,
        justifyContent: 'flex-end',
        gap: '10px'
    },
    priceLabel: {
        display: 'none',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
    },
    [theme.breakpoints.up('lg')]: {
        btnMoreInfo: {
            display: 'block'
        },
        btnAddPay: {
            display: 'block'
        },
    },
    [theme.breakpoints.up('md')]: {
        priceLabel: {
            display: 'inline-block',
        },
        imagenContainer: {
            width: 80,
            height: 80,
        },
        imagen: {
            width: 80,
            height: 80
        },
        checkIcon: {
            fontSize: '1.6rem'
        }
    },
    // Fecha Voluntaria Domi
    containerBtnsModifDomi: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px'
    },
    marginLeft10: {
        marginLeft:10
    }
});

export default styles;