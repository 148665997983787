import IoC from "contexts/ioc.context";
import { ContenidosGateway } from "gateways/contenido.gateway";
import { useContext, useEffect, useMemo, useState } from "react";

interface IProps {
    contentName: string;
    lang: string;
}

const useLoadContentHook = (props: IProps) => {
    const { contentName, lang } = props;
    // GATEWAY
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(ContenidosGateway) as ContenidosGateway, [ioc]);
    // STATE
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    useEffect(() => {
        (async () => {
            const response = await gateway.getContent(contentName, lang);

            if (response.length < 1) return;

            response.forEach((element: { campo: string, contenido: string }) => {
                if (element.campo === 'title') setTitle(element.contenido);
                else if (element.campo === 'body') setBody(element.contenido);
            });
        })();
    }, [lang]);


    return { title, body };
}

export default useLoadContentHook;
