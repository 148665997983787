import React, { FC, useContext, useMemo, useState } from 'react';
import { Grid, Typography, Button, makeStyles, CircularProgress } from "@material-ui/core";
import moment from 'moment';
import IoC from 'contexts/ioc.context';
import Term from '../../components/term';
import TextNumber from '../../components/text-number';
import ConceptoIcon from '../../components/concepto.icon';
import styles from '../shared/tributos.grid.styles';
import { ITributoAutonomico } from 'gateways/tributos.interfaces';
import { TributosAutonomicosGateway } from 'gateways/tributos.autonomicos.gateway';
import { download } from 'utils/download';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext } from 'containers/shared/literals';
import { PaymentGateway } from 'gateways/payment.gateway';

interface IProps {
    tributo: ITributoAutonomico;
}

const useStyles = makeStyles(styles);

const TributoGrid: FC<IProps> = ({ tributo }) => {
    const classes = useStyles();
    // Global context
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);

    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(TributosAutonomicosGateway) as TributosAutonomicosGateway, [ioc]);
    const paymentGateway = ioc.get(PaymentGateway) as PaymentGateway;

    // States
    const [loadingPrint, setLoadingPrint] = useState(false);

    const handlePrint = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setLoadingPrint(true);
        if (tributo.tipoModelo === '090') {
            const result = await paymentGateway.getJustificanteModeloPago(tributo.localizador);
            download(result, alertsDispatch,
                translate('PasarelaPago', 'No es posible descargar el justificante de pago', terms),
                translate('Global', 'BloqueoPantalla', terms));
        } else {
            //Resto de modelos (incl. pago ficticio)
            const result = await gateway.print(tributo.referencia, tributo.tipoModelo, tributo.localizador);
            download(result, alertsDispatch,
                translate('CarteroVirtual', 'error_justificante_tributo_autonomico', terms),
                translate('Global', 'BloqueoPantalla', terms));
        }
        
        setLoadingPrint(false);
    };

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.imageGrid}>
                <div className={classes.imagenContainer}>
                    <ConceptoIcon concepto={-1} />
                </div>
            </Grid>
            <Grid item className={classes.grow}>
                <Grid container className={classes.contentRoot}>
                    <Grid className={[classes.grow, classes.width0].join(' ')}>
                        <Typography className={classes.highlightText}>
                            {tributo.localizador}
                        </Typography>
                        <Typography className={classes.highlightText}>
                            {moment(tributo.fechaCreacion).format('DD/MM/YYYY')}
                        </Typography>
                    </Grid>
                    <Grid>
                        <div>
                            <Typography component="span" className={[classes.price, classes.priceLabel].join(' ')}>
                                <Term component="CarteroVirtual" text="Importe pagado" />
                            </Typography>
                            <TextNumber
                                component="span"
                                className={classes.price}
                                value={tributo.importe}
                                options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }} />
                        </div>
                    </Grid>
                </Grid>
                <Typography className={classes.detailText}>
                    {tributo.descripcion}
                </Typography>
                <div className={classes.bannerBtn}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handlePrint}
                        disabled={loadingPrint}
                    >
                        <Term component="CarteroVirtual" text="Imprimir justificante de pago" />
                        {loadingPrint && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

export default TributoGrid;