import { GenericInitialObject } from "containers/tributos-autonomicos-locales/utils";
import { Reducer } from "react";

export interface IModelosActionsState {
    conceptoXml: string | null;
    ejercicio: string | undefined;

}

export type ModelosActionsAction =
    | { type: 'saveConceptoXml', payload: string | null } 
    | { type: 'saveEjercicio', payload: string | undefined};

const ModelosActionsReducer: Reducer<IModelosActionsState, ModelosActionsAction> = (state, action) => {
    switch (action.type) {
        case 'saveConceptoXml': return { ...state, conceptoXml: action.payload };
        case 'saveEjercicio': return { ...state, ejercicio: action.payload };

        default: throw new Error('Unexpected action');
    }
};

export default ModelosActionsReducer;