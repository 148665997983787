import { Button, Grid, IconButton, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import InputNumber from "components/DUTI/input-number";
import InputSelect from "components/DUTI/input-select";
import { TSelect } from "containers/DUTI/utils";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { TCalcPLV, TCalcPlusvalia, TCalculo, TOperacion, TOperacionErr } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types";
import { getTiposDerechoTransmsisionByTipoAsistente } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/utils";
import {FC, useContext, useEffect, useMemo, useState} from "react";
import { translate } from "utils/i18n";
import InputText from "components/DUTI/input-text";
import { cloneDeep } from "lodash";
import FullLoading from "components/full-loading";
import { BLUE, BLUE_DARK, BLUE_PLV, BLUE_RIGHT_HEADER } from "containers/shared/colors";
import Modal from "components/modal";
import IoC from "contexts/ioc.context";
import { DocumentosGateway } from "gateways/documentos.gateway";
import { TTipoInmueble } from "gateways/catastro.interface";
import TypedInputNumber from "components/DUTI/typed-input-number";
import { mdiEyeCircleOutline, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import CalcPlusvaliaAddCalculo from "./calcPlusvaliaAddCalculo";
import GenericCheckbox from "components/generic-checkbox";
import CalcPlusvaliaAddCalcPLV from "./calcPlusvaliaAddCalcPLV";
import { info } from "console";

type TCalculoError= Record<keyof TCalculo,boolean> 
type TErrors = Record<keyof TCalcPlusvalia,boolean > //&  Record<keyof Pick<TCalcPlusvalia,'calculos'>,TCalculoError[]>
const EMPTY_ERR: TErrors = {
    sujetoPasivo: false,
    liquidacionMultiple: false,
    nifVendedor: false,
    calcPLV: false
}

const EMPTY_CALCULO: TCalculo = {
    tipoDerecho: undefined,
    edadUsufructuaria: undefined,
    porcentaje: 0,
    numOperaciones: 0,
    operaciones: [],
    idGestion: undefined,
    idGestionSujeto: undefined,
    modelo: undefined,
    declaracionResponsable: undefined
}
const EMPTY_CALCULO_ERR: TCalculoError = {
    tipoDerecho: false,
    edadUsufructuaria: false,
    porcentaje: false,
    numOperaciones: false,
    operaciones: false,
    idGestion: false,
    idGestionSujeto: false,
    modelo: false,
    declaracionResponsable: false
}
const EMPTY_OPERACION: TOperacion = {
    fechaAnteriorAdquisicion: undefined,
    porcentajeAnteriorAdquisicion: 0,
    importeAnteriorAdquisicion: undefined,
    idDocAnteriorAdquisicion: undefined
}

interface IPropsOperacionPLV extends WithStyles<typeof styles>{
    renderType: 'form' | 'detail'
    initialValue: TCalcPlusvalia;
    onChange: (v: TCalcPlusvalia, action: 'save'|'cancel') => void
    idRef:string
    apartado: string
    fechaTransmision: Date
    valorDeclarado: number|undefined
    infoVend: { nif:string, nomApe: string, titularidad: number}
    compradoresList: TSelect[]
    tipoModelo: '080'| '081' |'082'
    tipoBien?: TTipoInmueble
    tipoBienMSG?: string
}



const CalcPlusvaliaForm : FC<IPropsOperacionPLV>= ({
    classes, renderType, initialValue, onChange, idRef,apartado,fechaTransmision, infoVend, compradoresList, tipoModelo, tipoBien, tipoBienMSG, valorDeclarado
}) => {
    const terms= useContext(LiteralsContext)

    //Gateways
    const ioc = useContext(IoC);
    //const documentosGateway: DocumentosGateway = useMemo(() => ioc.get(DocumentosGateway), [ioc]);

    //const OPTs = getTiposDerechoTransmsisionByTipoAsistente('plusvalia', terms)

    const [localInfo, setLocalInfo]= useState({} as TCalcPlusvalia)
    const [localInfoErr, setLocalInfoErr]= useState<TErrors>({...EMPTY_ERR}) //TErrorsCalcPlusvalia
    const [loading, setLoading]= useState(false)
    const [goCompleteCalcPLV, setGoCompleteCalcPLV] = useState<{info: TCalcPLV, idx: number} | null>(null)

    const EMPTY_INFO_ERR: TErrors =useMemo(()=> ({...EMPTY_ERR}), [initialValue]) //TErrorsCalcPlusvalia

    
    const onSave= async () => {
        // Validate 
        const nifsOK= localInfo.calcPLV.map( c => (c.nifSujetoPasivo!== undefined && c.nifSujetoPasivo !== ''))
        const calcOK= localInfo.calcPLV.map( c => (tipoModelo!== '082' ? c.calculos.length>0  : c.calculos.length===0))

        let err = Object.values(localInfoErr).includes(true) 
            && (nifsOK.includes(false)) 
            && (calcOK.includes(false));
        
        if( !err ){   
            onChange(localInfo, 'save')
        } else {
            setLocalInfoErr({
                calcPLV: nifsOK.includes(false) || calcOK.includes(false),
                nifVendedor:  localInfo.nifVendedor === '',
                sujetoPasivo: false,
                liquidacionMultiple:false
            })
        }
    }

    const onCancel = async () => {
        setLocalInfo({...initialValue})
        setLocalInfoErr({...EMPTY_INFO_ERR})
        onChange({...initialValue}, 'cancel')
    }

    const onSaveCalcPLV = (v: TCalcPLV , action: 'save'|'cancel',idx: number) => {
            if( action === 'save'){

                const newCalcPLV = localInfo.calcPLV.map( (item,itemIdx) => itemIdx === idx ? {...v}:{...item})
                //Guardamos en local y seguimos
                setLocalInfo( curr => ({...curr, calcPLV: newCalcPLV}))
        
            }

        setGoCompleteCalcPLV(null)

    }


    useEffect(() => {
        if(initialValue){
            //console.log('initialValue', initialValue)
            if(initialValue.sujetoPasivo || (initialValue.calcPLV[0].nifSujetoPasivo && !initialValue.liquidacionMultiple)){
                setGoCompleteCalcPLV({info: initialValue.calcPLV[0], idx: 0})
            } else {
                setGoCompleteCalcPLV(null)
            }
            setLocalInfo({...initialValue})
            setLocalInfoErr({...EMPTY_INFO_ERR})
        }
    },[initialValue])

 
    //console.log('local info ', localInfo, goCompleteCalcPLV)
    return(
        <>
            <div>
                <FullLoading loading={loading}/>

                <Grid container direction="column">
                    <Grid item xs={12} sm={renderType === 'detail' ? 9: 12} md={renderType === 'detail' ? 9: 12} lg={renderType === 'detail' ? 9: 12}>
                        { localInfo.sujetoPasivo 
                            ?  
                                <>
                                    <InputText
                                        required 
                                        disabled
                                        fieldTerm='nifSujetoPasivo'
                                        value = {localInfo.calcPLV[0].nifSujetoPasivo === infoVend.nif ?  infoVend.nomApe + ' - '+ localInfo.calcPLV[0].nifSujetoPasivo  : localInfo.calcPLV[0].nifSujetoPasivo  }// item.nifVendedor}
                                        onChangeValue= {(v: TSelect, err: boolean) => {}}
                                        className={classes.input470}
                                    />
                                    
                                       
                                    </>
                            :  <>
                                {compradoresList.length > 1 ?
                                    <GenericCheckbox
                                        disabled={renderType === "detail" }
                                        componentIG={'Plusvalias'}
                                        labelTerm={'liquidacionMultiple'}
                                        value={localInfo.liquidacionMultiple}
                                        onChangeValue={ (v:boolean) => { 
                                            if(v){
                                                const calcPLV = compradoresList.map( compr => ({ nifSujetoPasivo: compr.id, calculos: [] as TCalculo[]}))
                                                setLocalInfo(curr => ({...curr, liquidacionMultiple: v, calcPLV: calcPLV }))
                                            } else{
                                                const calcPLV: TCalcPLV = { nifSujetoPasivo: undefined, calculos: [] as TCalculo[]}
                                                setLocalInfo(curr => ({...curr, liquidacionMultiple: v, calcPLV: [calcPLV] }))
                                            }
                                            
                                        }}
                                        moreInfo={{template: 'plusvalias_info_field_liquidMultiple', size: 'sm'}}

                                    />
                                : null
                                }
                                
                                {localInfo.liquidacionMultiple ?
                                    <>
                                    {
                                        localInfo.calcPLV && localInfo.calcPLV.length > 0  && 
                                        localInfo.calcPLV.map((calcInfo, calcIdx) => {
                                            const suj= compradoresList.find(c => c.id === calcInfo.nifSujetoPasivo)
                                            return(
                                                <Grid container direction="column" style={{borderBottomColor: BLUE_PLV, borderBottomWidth: 2, borderBottomStyle: 'solid', marginBottom: 10}}>
                                                    <div className={classes.row}>
                                                        <InputText
                                                            required
                                                            disabled
                                                            fieldTerm='nifSujetoPasivo'
                                                            value={ `${suj?.nombre}` }
                                                            options={compradoresList}
                                                            onChangeValue= {(v: TSelect|undefined, err: boolean) => {}}
                                                            className={classes.input470}
                                                        />
                                                        {renderType === 'form'&&
                                                            <Button style={{alignSelf:'flex-start', marginTop: 7}}
                                                                variant="outlined" color='primary'
                                                                disabled={goCompleteCalcPLV !== null}
                                                                onClick= {() => setGoCompleteCalcPLV({info:calcInfo, idx: calcIdx})}
                                                            >
                                                                {translate('Global', calcInfo.calculos.length === 0 ? 'btnComplete' : 'btnEdit', terms)}
                                                            </Button>
                                                        }
                                                    </div>
                                                    <Grid item xs={12}>
                                                        {goCompleteCalcPLV && goCompleteCalcPLV.idx === calcIdx && localInfo.liquidacionMultiple === true ?
                                                            <CalcPlusvaliaAddCalcPLV 
                                                                renderType={renderType}
                                                                initialValue={goCompleteCalcPLV.info}
                                                                onChange={ (v: TCalcPLV, action: 'save'|'cancel') => {
                                                                    onSaveCalcPLV(v, action, goCompleteCalcPLV.idx)
                                                                }}
                                                                fechaTransmision={fechaTransmision}
                                                                valorDeclarado={valorDeclarado}
                                                                infoVend={infoVend}
                                                                tipoModelo={tipoModelo}
                                                                idRef={idRef}
                                                                apartado={apartado}
                                                                tipoBien={tipoBien}
                                                            />
                                                            : null 
                                                        }
                                                        {/** Lista de calculos - Liq. multiple */}
                                                        { calcInfo.calculos.length>0 && 
                                                            <CalcPlusvaliaAddCalcPLV 
                                                                renderType={'detail'}
                                                                initialValue={calcInfo}
                                                                onChange={ (v: TCalcPLV, action: 'save'|'cancel') => {}}
                                                                fechaTransmision={fechaTransmision}
                                                                valorDeclarado={valorDeclarado}
                                                                infoVend={infoVend}
                                                                tipoModelo={tipoModelo}
                                                                idRef={idRef}
                                                                apartado={apartado}
                                                                tipoBien={tipoBien}
                                                            />
                                                        }
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                    </>
                                    : 
                                        <Grid item container>
                                            <Grid item xs={12}>
                                                <InputSelect
                                                    required
                                                    fieldTerm='nifSujetoPasivo'
                                                    disabled={renderType==="detail"}
                                                    value={ localInfo.calcPLV && localInfo.calcPLV.length > 0 
                                                        ? compradoresList.find(c => c.id === localInfo.calcPLV[0].nifSujetoPasivo)
                                                        : undefined
                                                    }
                                                    options={compradoresList}
                                                    onChangeValue= {(v: TSelect|undefined, err: boolean) => {
                                                        const newCalcPLV = {
                                                            nifSujetoPasivo: v ? v.id: undefined,
                                                            calculos: [] as TCalculo[]
                                                        }
                                                        setLocalInfo(curr => ({...curr, calcPLV: [newCalcPLV] }))
                                                        setLocalInfoErr(curr => ({...curr, calcPLV: err}))
                                                        setGoCompleteCalcPLV(newCalcPLV.nifSujetoPasivo ? {info: newCalcPLV , idx: 0} : null)
                                                    }}
                                                    className={classes.input470}
                                                    error={localInfoErr.calcPLV}
                                                />
                                            </Grid>
                                        
                                            
                                        

                                        </Grid>
                                        
                                }
                                {/** */}

                            </>
                        }

                        {goCompleteCalcPLV  && localInfo.liquidacionMultiple === false ?
                            <CalcPlusvaliaAddCalcPLV 
                                renderType={renderType}
                                initialValue={goCompleteCalcPLV.info}
                                onChange={ (v: TCalcPLV, action: 'save'|'cancel') => {
                                    onSaveCalcPLV(v, action, goCompleteCalcPLV.idx)
                                }}
                                fechaTransmision={fechaTransmision}
                                valorDeclarado={valorDeclarado}
                                infoVend={infoVend}
                                tipoModelo={tipoModelo}
                                idRef={idRef}
                                apartado={apartado}
                                tipoBien={tipoBien}

                            />
                            : null
                        }

                        {/** Lista de cálculo/s - No multiples */}
                        {!localInfo.liquidacionMultiple && !goCompleteCalcPLV && localInfo.calcPLV && localInfo.calcPLV.length > 0 && localInfo.calcPLV[0].nifSujetoPasivo 
                        ?
                            <Grid item xs={12} style={{marginTop: -10, marginBottom: 10}}>
                                {localInfo.calcPLV.map((calc, calcIdx) =>{ 
                                    const info: TSelect|undefined = localInfo.sujetoPasivo && calc.nifSujetoPasivo === infoVend.nif 
                                        ? {id: infoVend.nif, nombre: infoVend.nomApe}
                                        : compradoresList.find(c => c.id === calc.nifSujetoPasivo)

                                    return (
                                        calc.nifSujetoPasivo !== undefined ? 
                                        <Grid item container direction="column" >
                                            
                                            {calc.calculos.map((c,idx) => (
                                                <Grid item container direction="row" alignItems='center' className={[classes.row, classes.tableRow].join(' ')}>
                                                    <Grid item xs={12} className={[classes.row, classes.headerDerecho].join(' ')}>
                                                        <Typography className={classes.textDerecho}>{translate('Global','derechoTransmision', terms)}</Typography>
                                                        <Typography className={classes.textDerecho} style={{paddingRight:10 , paddingLeft: 10}}>-</Typography>
                                                        <Typography className={classes.textDerecho}>{c.tipoDerecho?.nombre}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.row}>
                                                        <Typography>{translate('DUTI','porcentajeTitularidad', terms)}:</Typography>
                                                        <Typography className={classes.textBold}>{c.porcentaje} %</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.row}>
                                                        <Typography>{translate('Plusvalias', c.tipoDerecho?.id === 'usufructo-temporal' ? "duracionUsufructo": "edadUsufructuaria", terms)}:</Typography>
                                                        <Typography className={classes.textBold}>{c.edadUsufructuaria ? `${c.edadUsufructuaria} ${translate('Plusvalias','anyos', terms).toLowerCase()} `: ''}</Typography>
                                                    </Grid>
                                                    
                                                    <Grid item xs={2} className={classes.row}>
                                                        <Typography>{translate('Plusvalias','numOperacionesShort', terms)}:</Typography>
                                                        <Typography className={classes.textBold}>{c.numOperaciones}</Typography>
                                                    </Grid>
                                                    {tipoModelo === '081'?
                                                        <>
                                                        <Grid item xs={3} className={classes.row}>
                                                        <Typography>{translate('DUTI','metodo_calculo_m081',terms)}:</Typography>   
                                                            <Typography className={classes.textBold}>
                                                                { c.modelo && c.modelo.totalIngresar 
                                                                    ? translate('DUTI', c.modelo.importeMetodoReal && c.modelo.importeMetodoReal < c.modelo.importeMetodoObjetivo ? 'metodoReal':'metodoObjetivo', terms) 
                                                                    : ''
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2} className={classes.row}>
                                                            <Typography>{translate('DUTI','totalIngresar', terms)}:</Typography>
                                                            <Typography className={classes.textBold}>
                                                                {c.modelo ? c.modelo.totalIngresar.toLocaleString('es-ES', { maximumFractionDigits:2, minimumFractionDigits:0}) : ' - '} €
                                                            </Typography></Grid>
                                                        </>
                                                        :null
                                                    }

                                                </Grid>
                                            ))}
                                        </Grid>
                                        : null
                                    
                                    )
                                })}
                            </Grid>
                            : null
                        }
                        
                    </Grid> 

                    

                   { renderType ==='form' && 
                    <Grid item container direction="row" justify="space-between" style={{paddingTop: 25}}>
                        <div style={{ marginTop: 6.5, display: 'inline-flex', marginLeft: 10 }}>
                            <div>
                                <Button 
                                    disabled={goCompleteCalcPLV !== null}
                                    variant="contained" color="inherit"
                                    onClick={onCancel}
                                >
                                    {translate('Global', 'Cancelar', terms)}
                                </Button>
                            </div>
                    
                            <div>
                                <Button className={classes.buttonSavePanel}
                                    disabled={goCompleteCalcPLV !== null} // || localInfo.calculos.length === 0}
                                    variant="contained" //color="primary"
                               
                                    onClick={onSave}
                                >
                                    {translate('Global', 'btnConfirm', terms)}
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    } 
                    



                </Grid>

                
                
            </div>

           



        </>
    )
}

const styles =  (theme: Theme): StyleRules => ({
    input200: { 
        width: 200
    },
    input285: { 
        width: 285
    },
    
    input470: { 
        width: 470    
    },
    row: {
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    rowPlusvalDetail: {
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        // alignContent: 'flex-end',
    },
    plusvalDetail_title: {
        fontWeight:'bolder',
    },
    plusvalDetail_value: {
        marginLeft: 10,
    },
    divTextBorderPlusval: {
        display: 'flex',            /* Activa Flexbox */
        justifyContent: 'flex-end', /* Alinea el contenido al final (derecha) */
        width: '100%',              /* Asegura que ocupe todo el ancho del Grid */
        position: 'absolute',       /* Posicionamiento absoluto respecto a su contenedor relativo */
        right: 20,                  /* Alinea a la derecha */
        top: -20,                   /* Alinea al borde superior */
    },
    textBorderPlusval: {
        padding: 10,
        color: BLUE,
        backgroundColor: 'white'
    },
    headerDerecho:{
        borderBottom: '#3587a9 solid 2px ',
    },
    textDerecho: {
        color: '#3587a9',
        fontSize: 18,
        fontWeight: 500,
    },
    textBold: {
        paddingLeft:5,
        fontWeight: "bolder",
        marginRight: 15
    },
    buttonSavePanel: {
        marginLeft:10, 
        backgroundColor: BLUE_DARK, 
        color:'white',
        "&:disabled ":{
            opacity: 0.5,
            backgroundColor: BLUE_DARK, 
            color:'white',
        },
        "&:hover":{
            backgroundColor: '#156da8', 
        }
    }
})


export default withLiterals(['DUTI','Plusvalias', 'Global'])(withStyles(styles)(CalcPlusvaliaForm))