import React, { FC } from 'react';
import { RouteComponentProps, withRouter, Route } from 'react-router-dom';
import Presentacion from './presentacion';
import { DetallePresentacion } from './detalle-presentacion';
import Presentaciones from './presentaciones';
import { IRenderComponent } from 'gateways/tramites.interfaces';
import DocumentacionNotario from './cajas/documentacionNotario';
import DocumentoBase from './cajas/documentoBase';
import DocumentosAdicionales from './cajas/documentosAdiconales';
import { LinkSolicitudAjutsCovid } from './cajas/link-solicitud-ajuts-covid';
import ModelosAsociados from './cajas/modelosAsociados';

const diccionarioComponentes: { [component: string]: IRenderComponent } = {
    'escritura_publica': {
        renderComponent: DocumentacionNotario
    },
    'vinculo_modelos': {
        renderComponent: ModelosAsociados
    },
    'docs_adicionales': {
        renderComponent: DocumentosAdicionales
    },
    'doc_base': {
        renderComponent: DocumentoBase
    },
    'vinculo_ajuts_covid': {
        renderComponent: LinkSolicitudAjutsCovid,
    }
};

const ExpedientesIndex: FC<RouteComponentProps> = ({ match }) => {
    return (
        <>
            <Route exact path={`${match.path}`} component={Presentaciones} />
            <Route exact path={`${match.path}/alta/:idGrupo`} component={Presentacion} />
            <Route exact path={`${match.path}/expediente/:idExpediente`} render={(props) => <DetallePresentacion diccionarioComponentes={diccionarioComponentes} showContinuar={false} {...props} />} />
        </>
    );
}

export default withRouter(ExpedientesIndex);
