import { ReportSharp } from "@material-ui/icons";
import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IDocListRN, IDocRN, IDocResponse, IJustificanteDo } from "containers/tramites/consultas/consultar-cid/cid.interface";
import { createValidationError } from "utils/response";
import { IDocumentoAtib } from "./documentos.interface";


@inject('axios')
export class DocumentosGateway {
    constructor(
        private axios: AxiosInstance,
    ) {

    }

    public async getContent(idDocumento: SVGFESpecularLightingElement): Promise<{ fileName: string, blob: Blob }> {
        const response = await this.axios.get(`documentos/${idDocumento}/content`, {
            responseType: 'blob',
        });

        if (response.status !== 200) {
            throw new Error('Documento no encontrado');
        }

        return {
            fileName: '',
            blob: response.data
        };
    }

    public async getDocAtib(idDocumento: string): Promise<IDocumentoAtib> {
        try {
            const response = await this.axios.get(`documentos/doc-atib/${idDocumento}`);

            if (response.status !== 200) {
                throw new Error('Documento_no_encontrado');
            }
            return response.data
        } catch (e) {
            throw e
        }
    }
    public async deleteDocAtib(idDocumento: string): Promise<boolean> {
        try {
            const response = await this.axios.get(`documentos/doc-atib-delete/${idDocumento}`);

            if (response.status !== 200 && response.status !== 201) {
                throw new Error('Documento_no_borrado');
            }
            return response.data
        } catch (e) {
            throw e
        }
    }

    public async subirDocumentos(files: any, source?: 'sede-presentacion'| 'consultas-sugerencias') {
        try {
            const formData = new FormData();
            for (const file of files) {
                formData.append('files', file);
            }
            const responseSubidaFicheros = await this.axios.post(`/file-upload?source=${source}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            return responseSubidaFicheros.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }



    /* DOCUMENTOS NOTIFICACIONES REGION */

    public async getDocumentoByRef(docType: string, idReferencia: string, token: string): Promise<IDocResponse | null> {
        try {
            const response = await this.axios.get(`notificaciones/getDocumentoByReference/${docType}/${idReferencia}${token !== '' ? "?token=" + encodeURIComponent(token) : ""}`);
            if (response.status !== 200) {
                throw new Error(` ${idReferencia}.(Bad response status: ${response.statusText})`)
            }
            return response.data ? response.data as IDocResponse : null;
        } catch (error) {
            throw new Error(error as string);
        }
    }


    public async getListadoDocumento(docType: string, idReferencia: string): Promise<IDocListRN[] | null> {
        try {
            const response = await this.axios.get(`notificaciones/getListadoDocumento/${docType}/${idReferencia}`);
            if (response.status !== 200) {
                throw new Error(` ${idReferencia}. (Bad response status: ${response.statusText})`)
            }
            return response.data ? response.data as IDocListRN[] : null;
        } catch (error) {
            throw new Error(error as string);
        }
    }


    public async getIdRecibo(idReferencia: string, docType: string): Promise<number | null> {
        try {
            const response = await this.axios.get(`notificaciones/getIdRecibo/${docType}/${idReferencia}`);
            if (response.status !== 200) {
                throw new Error(` ${idReferencia}. (Bad response status: ${response.statusText})`)
            }
            return response.data ? response.data[0][""] as number : null;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getJustificantePagoTributoGrupoCID(nifSujeto: string, idRecibos: string[]): Promise<Blob> {
        try {
            const response = await this.axios.post(`notificaciones/getJustificantePagoTributoGrupoCID`, {
                dto: idRecibos,
                nif: nifSujeto
            },
                { responseType: 'blob' });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getDocumentoNotificacion(cid: string): Promise<Blob> {
        try {
            const response = await this.axios.get(`notificaciones/getDocumentoNotificacionCID/${cid}`, {
                responseType: 'blob'
            });

            if (response.status === 200) {
                return response.data;
            } else {
                console.error(`Received unexpected status code: ${response.status}`);
                throw new Error(`Bad response status: ${response.status} ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error fetching document', error);
            throw new Error('Error fetching document');
        }
    }




}
