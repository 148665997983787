import { StyleRules } from "@material-ui/core";

const styles = (): StyleRules => ({
    // FLEX
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    alignCenter: {
        alignItems: 'center'
    },
    alignEnd: {
        alignItems: 'flex-end'
    },
    // SPACING
    marginBottom5: {
        marginBottom: 5
    }
});

export default styles;