import React, { FC, useContext } from 'react';
import { Drawer, makeStyles } from "@material-ui/core";
import Term from '../../components/term';
import FiltersForm  from './filters.form';
import { FiltersContext } from './filters.context';
import { IFiltersState } from './filters.reducer';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    drawerPaper: {
        width: 300,
        top: 230,
        height: 'calc(100% - 230px)',
        borderTopLeftRadius: 8,
    },
    main: {
        padding: 20
    },
    title: {
        marginBottom: 0
    },
    center: {
        textAlign: 'center'
    },

}));

export interface IPanelDrawerProps {
    onFiltersChange: (filters: IFiltersState) => void;
}

const PanelDrawer: FC<IPanelDrawerProps> = ({ onFiltersChange }) => {
    const [filters,] = useContext(FiltersContext);

    const classes = useStyles();

    return (
        <Drawer
            classes={{
                paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor="right"
            open>
            <div className={classes.main}>
                <h3 className={classes.title}><Term component="Global" text="Vista" /></h3>
                <FiltersForm mostrarMapa={true} mostrarFiltro={false}/>
                {filters.cambioLista &&
                <>
                <h3 className={classes.title}><Term component="Global" text="Filtros de busqueda" /></h3>
                <FiltersForm mostrarMapa={false} mostrarFiltro={true}/>
                </>
            }
                </div>
        </Drawer>
    )
};

export default PanelDrawer;