import { inject } from "aurelia-dependency-injection";
import { AxiosInstance, CancelTokenSource } from "axios";

@inject('axios')
export class ImporterGateway {
    private cts: CancelTokenSource | null = null;
    constructor(
        private axios: AxiosInstance,
    ) { }

    public async importXLSX(file: File, type: string): Promise<any> {
        const data = new FormData();
        data.append('file', file);
        data.append('tipo', type);
        const response = await this.axios.post('importer/file/data', data);

        if (response.status < 200 || response.status >= 300) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async identificarConductor(conductor: Record<string, any>, idDocumento: string): Promise<boolean> {
        const response = await this.axios.post(`importer/conductor?idDocumento=${idDocumento}`, conductor);

        if (response.status < 200 || response.status >= 300) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async saveExcel(file: File): Promise<string | null> {
        const data = new FormData();
        data.append('file', file);
        const response = await this.axios.post('importer/saveExcel', data);

        if (response.status < 200 || response.status >= 300) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }
}