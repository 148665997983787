import { StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules => ({
    icon: {
        marginLeft: 10,
        marginRight: 5,
        padding: 1
    },
    pac_detail:{
        display:'flex',
        flexDirection: 'column',
        margin: 15,
    },
    pac_detail_result:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    pcBlueRim: {
        display: "flex",
        flexDirection: "column",
        boxShadow: '2px 1px 2px rgba(0, 0, 0, .1)',
        borderRadius: 5,
        marginBottom: 10,
        flexGrow: 1,
        width: '100%'
    },
    text: {
        padding: 5
    },
    bold: {
        fontWeight: "bold",
    },
    pcBlueRimTitle: {
        cursor: "pointer",
        justifyContent: "space-between",
        display: "flex",
        paddingLeft: 10
    },
    deleteExpand: {

    },
    delete: {

    }
});

export default styles;