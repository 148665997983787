import { Card, CircularProgress, Typography, WithStyles, withStyles } from "@material-ui/core";
import { styles } from "../styles";
import Term from "components/term";
import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import { mdiFileAlert, mdiFileCheck, mdiPen, mdiSignature } from "@mdi/js";
import Icon from "@mdi/react";
import { IDocDO, IDocResponse } from "../../cid.interface";
import DomiciliacionViewer from "../domiciliacion-info/domiciliacion-viewer";



interface IProps extends WithStyles<typeof styles> {
    document: IDocResponse | undefined,
    isSearchOpen: boolean,
    canDownload: boolean,
    isError?: boolean,
    isDownloading: boolean,
    handleDownloadDoc: (buffer: Buffer | undefined) => void,
    handleDownloadDomi: () => void,
    handleDownloadJP: () => void,
    handleListado: (docType: string, codigo: string) => void,
    handleOpenInfoDomi: (domi: IDocDO) => void
}


const DocumentViewer: React.FC<IProps> = ({ classes, document, canDownload, isSearchOpen, isError, isDownloading, handleDownloadDoc, handleDownloadJP, handleDownloadDomi, handleListado, handleOpenInfoDomi }) => {

    const handleDocTypeTitle = useCallback(() => {
        if (document) {
            if (!document.docType) {
                return "--"
            }
            return <Term component="Notificaciones" text={`documento_${document.docType}`}></Term >
        }
    }, [document?.docType])

    const handleFecha = useCallback(() => {
        if (!document) {
            return "--"
        }
        return moment(document.fecha).format("DD/MM/YYYY HH:mm").toString()
    }, [])

    const handleChangeListado = () => {
        if (document) {
            if (document.docType)
                handleListado(document.docType, document.codigo)
        }
    }

    const handleOpenDomiInfo = () => {
        if (document?.docDo)
            handleOpenInfoDomi(document?.docDo)
    }

    //USE EFFECTS



    return (
        <>
            <Card className={classes.card}>
                {
                    isDownloading
                        ?
                        <div className={classes.centerContent} >
                            <CircularProgress />
                        </div >
                        :
                        <div className={classes.content}>
                            {!isError ?
                                <>
                                    <div className={classes.docInfo}>
                                        <div style={{ flex: 2 }}>
                                            <Typography variant="h6">
                                                <Term text="doc_base" component="Tramites"></Term>
                                            </Typography>
                                            <Typography>{handleDocTypeTitle()}</Typography>
                                        </div>
                                        {document && document.fecha &&
                                            <div style={{ flex: 1 }}>
                                                <Typography variant="h6">
                                                    <Term text="fechaHora" component="Tramites"></Term>
                                                </Typography>
                                                <Typography>{handleFecha()}</Typography>
                                            </div>
                                        }
                                    </div>
                                    <div className={classes.docDetails}>
                                        {document?.docType !== "VF" ?
                                            <Icon path={mdiFileCheck} size={6} color={'#2196f3'} />
                                            :
                                            <Icon path={mdiPen} size={6} color={'#2196f3'} />
                                        }
                                        {(document && document.docType === "DO" && document.docDo) ?
                                            <div style={{ flex: 1 }}>
                                                <DomiciliacionViewer domiciliacion={document.docDo.domi} />
                                                <div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
                                                        {(document && document.docDo) &&
                                                            <Button
                                                                className={classes.button}
                                                                style={{ backgroundColor: '#2196f3' }}
                                                                variant='contained'
                                                                onClick={handleOpenDomiInfo}
                                                            >
                                                                <Typography style={{ color: 'white' }}>
                                                                    <Term text='+ INFO' />
                                                                </Typography>
                                                            </Button>
                                                        }
                                                        {canDownload &&
                                                            <Button
                                                                className={classes.button}
                                                                style={{ backgroundColor: "#8b8b8b" }}
                                                                variant='contained'
                                                                onClick={handleDownloadDomi}
                                                                disabled={!canDownload}
                                                            >
                                                                <Typography style={{ color: 'white' }}>
                                                                    <Term text='descargar' component="Tramites" />
                                                                </Typography>
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div style={{ flex: 1 }}>
                                                    <Typography variant="h6">
                                                        {document?.docType !== "VF" ?
                                                            <Term text="documentoVerificado" component="Tramites"></Term>
                                                            :
                                                            <Term text="firmaValidada" component="Tramites"></Term>
                                                        }
                                                    </Typography>
                                                    <Typography style={{ fontSize: 16 }}>
                                                        <Term text="watchDoc" component="Tramites"></Term>
                                                    </Typography>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
                                                    {document && document.docRN &&
                                                        <Button
                                                            className={classes.button}
                                                            style={{ backgroundColor: '#2196f3' }}
                                                            variant='contained'
                                                            onClick={handleChangeListado}
                                                        >
                                                            <Typography style={{ color: 'white' }}>
                                                                {isSearchOpen ?
                                                                    <Term text='openList' component="Tramites" />
                                                                    :
                                                                    <Term text='openSearch' component="Tramites" />
                                                                }
                                                            </Typography>
                                                        </Button>
                                                    }
                                                    {canDownload && document && document.docType === "VF" &&
                                                        < Button
                                                            className={classes.button}
                                                            style={{ backgroundColor: "#8b8b8b" }}
                                                            variant='contained'
                                                            onClick={() => handleDownloadDoc(document.docVF?.buffer)}
                                                            disabled={!canDownload}
                                                        >
                                                            <Typography style={{ color: 'white' }}>
                                                                <Term text='downloadFV' component="Tramites" />
                                                            </Typography>
                                                        </Button>
                                                    }
                                                    {canDownload && document && document.docType === "JP" &&
                                                        < Button
                                                            className={classes.button}
                                                            style={{ backgroundColor: "#8b8b8b" }}
                                                            variant='contained'
                                                            onClick={handleDownloadJP}
                                                            disabled={!canDownload}
                                                        >
                                                            <Typography style={{ color: 'white' }}>
                                                                <Term text='downloadJP' component="Tramites" />
                                                            </Typography>
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                                :
                                <div>
                                    <div style={{ flex: 1 }}>
                                        <Icon path={mdiFileAlert} size={6} color={'#d0312d'} />
                                        <Typography variant="h6">
                                            <Term text="documentoNoVerificado" component="Tramites"></Term>
                                        </Typography>
                                        <Term text={`
                                        No se ha localizado ningún documento con el código seguro de verificación aportado.
                                        Por favor, revise que el código introducido se corresponda con el que dispone y en caso de duda recuerde que siempre puede contactar con nosotros mediante el módulo de 
                                        `}></Term>
                                        <a href="https://atib.es/General/Contacto.aspx" style={{ textDecoration: 'none' }}>Consultas y Sugerencias</a>.
                                    </div>
                                </div>
                            }
                        </div>
                }
            </Card >
        </>);
}
export default withStyles(styles)(DocumentViewer);