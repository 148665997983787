import React, { ComponentType } from "react";


export interface StepComponentProps {
  apartado: string
  idRef: string | null, // valor de referencia que permite consultar datos de inicialización
  onNext: (referencia?: string | null, infoHeader?: any) => void,
  onBack: () => void,
  onCancel: () => void,
  isFirst: boolean,
  isLast: boolean,
}
interface GenericComponentProps<T> {
component: ComponentType<T>;
componentProps: T;
}
// Componente genérico para renderizar STEPS CONTENT
function GenericStepComponent<T extends StepComponentProps>({ component: Component, componentProps }: GenericComponentProps<T>) {
  return <Component {...componentProps} />;  
}
export default  GenericStepComponent;