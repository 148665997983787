import { StyleRules, Theme } from "@material-ui/core";

export const styles = (theme: Theme): StyleRules => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        [theme.breakpoints.down(1200)]: {
            display: 'list-item'
        }
    },
    row: {
        marginTop: 40,
        display: 'flex',
        minWidth: '300',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    textField: {
        minWidth: '250px',
        flex: 4
    },
    card: {
        margin: 10,
        marginRight: 5,
        flex: 1
    },
    selectorTramites: {
        minWidth: 100,
        flex: 2,
        marginRight: 15,
        [theme.breakpoints.down('sm')]: {
            width: 210
        }
    },
    centerContent: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    noContent: {
        borderRadius: 10,
        borderWidth: 1,
        borderColor: 'black',
    },

})