import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Button, makeStyles, useTheme, CircularProgress, Card, CardActions } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import usePage from 'hooks/page.hook';
import Term from 'components/term';
import IoC from 'contexts/ioc.context';
import { LiteralsContext } from 'containers/shared/literals';
import { SolicitudesGateway } from 'gateways/solicitudes.gateway';
import { translate } from 'utils/i18n';
import { download } from 'utils/download';
import { AlertsContext } from 'contexts/alerts.context';


const useStyles = makeStyles(() => ({
    centerText: {
        textAlign: 'center',
        width: '100%',
        margin: '0 20px'
    }
}));

type Props = RouteComponentProps;

const SolicitudFinalizada: React.FC<Props> = ({ history, location }) => {
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [loadingRedirect, setLoadingRedirect] = useState(false);
    const [loadingImprimir, setLoadingImprimir] = useState(false);
    const theme = useTheme();
    const classes = useStyles(theme);
    const ioc = useContext(IoC);
    const terms = useContext(LiteralsContext);
    const solicitudesG = ioc.get(SolicitudesGateway) as SolicitudesGateway;
    const [resultadoSolicitud,] = useState(location.state as any);

    const redirectToLogin = useCallback(() => {
        setLoadingRedirect(true);
        history.push('/acceso');
    }, [history]);

    const imprimir = async () => {
        setLoadingImprimir(true);
        const result = await solicitudesG.imprimir(resultadoSolicitud);
        download(result, alertsDispatch,
            translate('Solicitudes', 'Ha ocurrido un error al generar el justificante de domiciliación', terms),
            translate('Global', 'BloqueoPantalla', terms));
        setLoadingImprimir(false);
    }

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: null,
                title: <Term text="Solicitudes" />,
            },
            menu: true,
        });
    }, [pageDispatcher]);

    useEffect(() => {
        if (!resultadoSolicitud) {
            history.push('/solicitudes/codigo-contribuyente');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultadoSolicitud])

    return (
        <div className={classes.centerText}>
            <Card>
                <h2> <Term component="Solicitudes" text="SolicitudFinalizadaCorrectamente" /> </h2>
                <CardActions>
                    <Button variant="outlined" color="default" onClick={redirectToLogin}>
                        <Term component="Solicitudes" text={'Ir a login'} />
                        {
                            loadingRedirect ?
                                <CircularProgress size={20} />
                                :
                                null
                        }
                    </Button>
                    <Button variant="outlined" color="primary" onClick={imprimir}>
                        <Term component="Solicitudes" text={'Imprimir justificante'} />
                        {
                            loadingImprimir ?
                                <CircularProgress size={20} />
                                :
                                null
                        }
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
}

export default withRouter(SolicitudFinalizada);