// LIBRARY IMPORTS
import { FC } from 'react';
import { Button, CircularProgress, makeStyles, useTheme } from '@material-ui/core';
// LOCAL IMPORTS
import { ScreenType } from 'containers/cartero-virtual/filters.form';
import Term from 'components/term';
// STYLES IMPORTS
import stylesLocal from './styles';
import stylesShared from '../../../../shared/tributos.styles';

const useStylesLocal = makeStyles(stylesLocal);
const useStylesShared = makeStyles(stylesShared);

interface ISeleccionarDeseleccionarTodosButton {
    screen: ScreenType; // domiciliaciones | 'domiciliados' | 'pago-carta'
    isSelectedAllActivated: boolean;
    selectingAll: boolean;
    onlyDomiciliados: boolean;
    onlyPagoCarta: boolean;

    onClearData: () => void;
    onSelectAll: (value: boolean) => void;
}

const SeleccionarDeseleccionarTodosButton: FC<ISeleccionarDeseleccionarTodosButton> = (props) => {
    const {
        screen,
        isSelectedAllActivated,
        selectingAll,
        onlyDomiciliados,
        onlyPagoCarta,

        onClearData,
        onSelectAll
    } = props;
    // HOOKS
    const theme = useTheme();
    const classesLocal = useStylesLocal(theme);
    const classesShared = useStylesShared(theme);

    const handleButtonContent = () => {
        if (screen === 'pago-carta')
            return <Term component="Domiciliaciones" text="Modificar todos pago a la carta" />
        else if (screen === 'domiciliados')
            return <Term component="Domiciliaciones" text="Modificar todos domiciliados" />
        return <Term component="Domiciliaciones" text="Domiciliar todos" />
    };

    // handleBtnDeseleccionarTodos
    if (isSelectedAllActivated) {
        return (
            <Button
                disabled={screen === 'pago-carta' ? (selectingAll || onlyDomiciliados) : (selectingAll || onlyPagoCarta)}
                variant="contained"
                color={screen === 'pago-carta' ? "secondary" : "primary"}
                size="small"
                className={[classesLocal.white, classesLocal.margin].join(' ')}
                onClick={onClearData}
            >
                <Term component="Domiciliaciones" text="Deseleccionar todos" />
                {
                    selectingAll &&
                    <CircularProgress size={24} className={classesShared.buttonProgress} />
                }
            </Button>
        );
    }

    // handleBtnSeleccionarTodos
    return (
        <Button
            disabled={screen === 'pago-carta' ? (selectingAll || onlyDomiciliados) : (selectingAll || onlyPagoCarta)}
            variant="contained"
            color={screen === 'pago-carta' ? "secondary" : "primary"}
            size="small"
            className={[classesLocal.white, classesLocal.margin].join(' ')}
            onClick={screen === 'pago-carta' ? () => onSelectAll(false) : () => onSelectAll(true)}
        >
            {handleButtonContent()}
            {
                selectingAll &&
                <CircularProgress size={24} className={classesShared.buttonProgress} />
            }
        </Button>
    );
}

export { SeleccionarDeseleccionarTodosButton };