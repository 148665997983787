
import {FC, useContext, useMemo, useRef, useState} from "react";
import { Button, Grid, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { translate } from "utils/i18n";
import ModelGateway from "gateways/model.new.gateway";
import IoC from "contexts/ioc.context";
import { TCalculo } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types";
import LabelWithInfo from "components/DUTI/label-with-info";
import TypedInputNumber from "components/DUTI/typed-input-number";
import FullLoading from "components/full-loading";
import { AlertsContext } from "contexts/alerts.context";


interface Props extends WithStyles<typeof styles> {
    info: TCalculo,
    infoVend: { nif:string, nomApe: string, titularidad: number}
    error:boolean,
    onConfirm: () => void
    onCancel: () => void
}
const FormCalculoPLV:FC<Props> = ({
    classes,  info, error, infoVend, onConfirm, onCancel
})=> {
    const componentMounted=useRef(true)
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext)
   
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    //const [info, setinfo] = useState<TCalcPlusvalia| undefined>(undefined)

    // Local 
    const [loading, setLoading] = useState(false);  
    const [calcError, setCalcErr] = useState(translate('Plusvalias', 'error_calcular_plusvalia', terms));
    

    const showImporteReal = useMemo(() => (info.operaciones && info.operaciones[0].importeAnteriorAdquisicion && info.operaciones[0].importeAnteriorAdquisicion > 0), [info])


    return (
        <>
            <FullLoading loading={loading} msg={translate('DUTI','loading_calculate_m081',terms)} animation={{type:'lottie', name:'calcular'}}/> 

            <Grid container  direction='column'className={classes.container}>
                
                    <Grid item container 
                        direction='row' 
                        alignItems="center" 
                        style={{backgroundColor:'#006993', color:'#fff',borderTopLeftRadius: 9,borderTopRightRadius:9, padding: 10}}
                    >
                        <Typography style={{fontWeight:400}}>{infoVend.nif.toUpperCase()}</Typography>
                        <Typography style={{marginRight: 10, marginLeft: 10, fontWeight:400}}>{'-'}</Typography>
                        <Typography style={{fontWeight:400}}>{infoVend.nomApe.toUpperCase()}</Typography>
                        {/*<Typography style={{marginRight: 10, marginLeft: 10, fontWeight:400}}>{'-'}</Typography>
                         <Typography style={{fontWeight:400}}>{infoVend.titularidad}</Typography>
                        <Typography style={{fontWeight:400}}>{translate('DUTI','porcentajeTitularidad',terms)}</Typography> */}
                    </Grid>

                    {error ?
                       <Grid item container >
                            <Grid item xs={12} sm={12} md={6}lg={6} style={{padding: 10, minHeight: 150}}>
                                <Typography color="error">{calcError}</Typography>
                            </Grid>
                        </Grid>
                    :
                    <> 
                        <Grid item container >
                            <Grid item xs={12} sm={12} md={6}lg={6} style={{padding: 10}}>
                                <LabelWithInfo type="field" fieldTerm={'plusval_calc_metodoObjetivo'} moreInfo={{template: 'plusvalias_info_calc_metodo_objetivo', size: 'xs'}}/>
                                <TypedInputNumber 
                                    key='importeMetodoObjetivo'
                                    type="money"
                                    disabled
                                    required={false}
                                    allowZero={true}
                                    fieldTerm="importeMetodoObjetivo"
                                    value={ info.modelo?.importeMetodoObjetivo ?? 0}
                                    onChangeValue={(v, err) => {}}
                                />
                            </Grid>
                        
                                <Grid item container direction='column' spacing={1} xs={12} sm={12} md={6}lg={6} alignItems="flex-start"
                                    style={{
                                        border:'solid',
                                        borderTopWidth: '0px',
                                        borderBottomWidth: '0px',
                                        borderLeftWidth: '1px',
                                        borderRightWidth: '0px',
                                        borderColor: '#006993',
                                        padding: 10
                                    }}
                                >
                                    <Grid item container>
                                        <LabelWithInfo type="field" fieldTerm={'plusval_calc_metodoReal'} moreInfo={{template: 'plusvalias_info_calc_metodo_real', size: 'xs'}}/>
                                    </Grid>
                                    <Grid item>
                                        {info.modelo && info.modelo.importeMetodoReal !== null && showImporteReal ? 
                                            <TypedInputNumber 
                                                key='importeMetodoReal'
                                                type="money"
                                                disabled
                                                required={false}
                                                allowZero={true}
                                                fieldTerm="importeMetodoReal"
                                                value={ info.modelo.importeMetodoReal}
                                                onChangeValue={(v, err) => {}}
                                            />
                                            : <Typography>{translate('DUTI','calc_metodoReal_no_aplica',terms)}</Typography>
                                        }
                                    </Grid>

                                </Grid>
                            
                        </Grid>
                        <Grid item container direction='column' justify="center" alignItems="center" 
                            style={{ 
                                width: '100%',
                                border:'solid',
                                borderTopWidth: '1px',
                                borderBottomWidth: '0px',
                                borderLeftWidth: '0px',
                                borderRightWidth: '0px',
                                borderColor: '#006993',
                                padding: 10
                            }}
                        >
                            <LabelWithInfo type="field" fieldTerm={'plusval_calc_totalIngresar'}  />
                            <TypedInputNumber 
                                key='totalIngresar'
                                type="money"
                                disabled
                                required={false}
                                allowZero={true}
                                fieldTerm="totalIngresar"
                                value={ info?.modelo?.totalIngresar ?? 0}
                                onChangeValue={(v, err) => {}}
                            />
                        </Grid>
                    </>
                    }
                    

            </Grid>

                <div className={[classes.row, classes.btns].join(' ')}>
                    <Button variant='contained' onClick={onCancel} style={{marginRight: 20}} >{translate('DUTI','btn_cancel',terms)}</Button>
                    <Button 
                        color="primary" 
                        variant='contained' 
                        disabled={error}
                        onClick={onConfirm}
                    >
                        {translate('DUTI','btn_confirmar',terms)}
                    </Button>
                    
                </div>
  
        </>
    )
}

const styles =  (theme: Theme): StyleRules => ({
    input200: { 
        width: 200
    },
    row: {
        display:'flex',
        flexDirection:'row'
    },
    btns: {
        flexGrow: 1,
        justifyContent: 'flex-end',
        alignItems:'center',
        height: 80
    },
    container: { 
        width: '100%',
        border:'solid',
        borderWidth: '1px',
        borderRadius: 10,
        borderColor: '#006993',
        
    }
})
export default withLiterals(['DUTI','Plusvalias', 'Global'])(withStyles(styles)(FormCalculoPLV))