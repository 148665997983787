import { StyleRules } from "@material-ui/core";

const styles = (): StyleRules => ({
    // HEIGHT & WIDTH
    maxHeight300: {
        maxHeight: 300
    },
    // FLEX
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    alignCenter: {
        alignItems: 'center'
    },
    // SPACING
    gap20: {
        gap: '20px'
    },
    padding20: {
        padding: 20
    },
    paddingLeftRight25: {
        paddingLeft: 25,
        paddingRight: 25
    },
    marginLeft25: {
        marginLeft: 25
    },
    // OTHERS
    overflowScroll: {
        overflowY: 'scroll'
    },
    borderBottom: {
        //borderBottom: '1px solid black'
        borderBottom: 'solid', 
        borderBottomWidth: 1, 
        borderBottomColor:'#d3d3d3'
    },
    // Title style DUSTI
    titleText:{
        fontSize: '1.5rem',
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: 1.334,
        letterSpacing: '0em',
        
    },
    margin15: {
        margin: 15
    }
});

export default styles;