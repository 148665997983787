// LIBRARY IMPORTS
import { FC } from "react";
import { Grid, Typography, Button, makeStyles, CircularProgress } from "@material-ui/core";
// LOCAL IMPORTS
import Term from "./../../../../components/term";
import { ITributo } from "./../../../../gateways/tributos.interfaces";
// STYLES IMPORTS
import styles from './../../../shared/carrito.styles'

const useStyles = makeStyles(styles);

export interface IProps {
    items: ITributo[];
    visible: boolean;
    literal: string;
    alta: boolean;
    loading: boolean;

    onSelect: () => void;
}

const CarritoDomiciliaciones: FC<IProps> = (props) => {
    // PROPS
    const { items, visible, literal, alta, loading, onSelect } = props;
    // HOOKS
    const classes = useStyles();

    return (
        <Grid container className={[alta ? classes.rootCarrito : classes.root, visible ? classes.display : classes.displayNone].join(' ')}>
            <Grid item xs={6} className={classes.priceInfo}>
                <Typography className={classes.totalPriceSelected}>
                    {items.length}&nbsp;
                    <Term component="Domiciliaciones" text="Tributos seleccionados" />
                </Typography>
            </Grid>
            <Grid item xs={6} className={classes.payContainer}>
                <div className={classes.wrapper}>
                    <Button
                        variant="contained"
                        color={"primary"}
                        className={literal === "Modificar cuenta" ? undefined : classes.buttonSuccess}
                        disabled={loading}
                        onClick={onSelect}>
                        <Term component="Domiciliaciones" text={literal} />
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </Grid>
        </Grid>
    )
}


export { CarritoDomiciliaciones };