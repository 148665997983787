
import React, { FC } from "react";
import { WithStyles, withStyles, Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";


const styles = (theme: Theme): StyleRules => ({
    row: {
        flexDirection: 'row'
    }, 
    column: {
        flexGrow: 1,
        flexDirection: 'column',
        marginBottom:10,
    },
    etiquetaText: {
        fontSize: 12,
        marginTop: 5,
        fontWeight: 550,
        color: '#b2b2b2',
    },
    detailText: {
        color: '#000',
        marginBottom: 5
    },

})
interface IFieldProps {
    label: string;
    value: string;
    maxWidth?: string | number ;
    width?: string | number ;

}
type FProps = WithStyles<typeof styles> & IFieldProps;
const Field: FC<FProps> = ({ classes, label, value, maxWidth, width}) => {
    return (
        <div className={classes.column} style={{maxWidth: maxWidth, width: width}} >
            <div className={classes.etiquetaText}>{label}</div>
            <div className={classes.detailText}>{value}</div>
        </div>
    )
}
export default withStyles(styles)(Field);