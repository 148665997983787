import { inject } from "aurelia-dependency-injection";
import Axios, { AxiosInstance, CancelTokenSource } from "axios";
import { ICategory } from "components/categories";
import { ICategoria } from "containers/faqs/categorias-faqs";

@inject('axios')
export class FAQSGateway {
    private cts: CancelTokenSource | null = null;
    constructor(
        private axios: AxiosInstance,

    ) {

    }

    public async getAllCategories(): Promise<ICategory[]> {
        const response = await this.axios.get('faq/categories?scope=Sede');

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getSubCategories(id:number): Promise<ICategory[]> {
        const response = await this.axios.get(`faq/sub-categories?scope=Sede&idParent=${id}`);

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getQuestions(categoryId?: number) {
        let path = 'faq?scope=Sede';
        if (categoryId) {
            path = path + '&categoryId=' + categoryId;
        }
        const response = await this.axios.get(path);

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async search(texto: string, categoryId?: number) {
        if (texto === '') {
            return null;
        }
        this.cts?.cancel();
        this.cts = Axios.CancelToken.source();
        const params = {
            text: texto,
            categoryId: categoryId,
            scope: 'Sede',
        }
        const response = await this.axios.get('faq/search', {
            cancelToken: this.cts.token,
            params: params,            
        });

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        if (response.data.length === 0)
            return [];

        return response.data;
    }
}