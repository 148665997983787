import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";
import { BLUE } from "./colors";

const styles = (theme: Theme): StyleRules => ({
    root: {
        flexWrap: 'nowrap',
        position: 'relative'
    },
    contentRoot: {
        flexWrap: 'nowrap'
    },
    body: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
    grow: {
        flexGrow: 1
    },
    highlightText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#000',
        fontSize: 14,
        fontWeight: 700
    },
    highBlue: {
        color: '#277BB9',
        textDecoration: 'underline',
    },
    highRed: {
        color: '#FF3521',
        textDecoration: 'underline',
    },
    highGray: {
        color: '#A8A8A8',
        textDecoration: 'underline',
    },
    normalText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#000',
        fontSize: 12,
    },
    red: {
        color: '#FF3521',
    },
    imagenContainer: {
        paddingRight: 15,
    },
    imageGrid: {
        alignSelf: 'center'
    },
    fullButton: {
        width: '100%'
    },
    width0: {
        width: 0
    },
    width015: {
        width: 0,
    },
    width100: {
        width: '100%',
    },
    marginDetail15: {
        marginBottom: 15,
    },
    marginDetail25: {
        marginBottom: 25,
    },
    btnNotificacion: {
        bottom: 0,
        marginRight: 10,
    },
    btnMoreInfo: {
        marginRight: 10
    },
    bannerBtn: {
        display: 'flex',
        flex: 1,
    },
    contents: {
        display: 'contents',
    },
    centerContent: {
        textAlign: 'center',
        padding: 50,
    },
    documentContainer: {
        textAlign: 'center',
        padding: 10,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        [theme.breakpoints.up('md')]: {
            left: '50%',
        }
    },
    buttonProgressDays: {
        color: green[500],
        position: 'absolute',
        marginTop: 5,
        marginLeft: -55,
    },
    yearButtonGroupRow: {
        textAlign: 'center',
    },
    yearButtonGroup: {
        marginTop: 10,
        marginBottom: 10,
        alignSelf: 'center',
    },
    btnConversation: {
        bottom: 0,
        top: 10,
        width: 'auto'
    },
    width50: {
        width: 50,
    },
    justifySart: {
        justifyContent: 'flex-start'
    },
    texto: {
        width: '80%',
        paddingTop: 10,
    },
    section: {
        margin: theme.spacing(3, 2),
    },
    calendar: {
        padding: 10,
        display: 'flex',
    },
    buttonPosition: {
        textAlign: 'center',
        padding: 10,
    },
    padding10: {
        padding: 10,
    },
    isSelected: {
        width: 25,
        backgroundColor: BLUE,
        color: 'white',
        textAlign: 'center',
    },
    isSelectedNoModificable: {
        width: 25,
        backgroundColor: '#DE942B',
        color: 'white',
        textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
        body: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
        },
        btnMoreInfo: {
            display: 'block'
        },
        calendar: {
            width: '25%',
            padding: 10,
            display: 'flex',
        },
        buttonPosition: {
            textAlign: 'right',
            padding: 10,
        },
    },
    [theme.breakpoints.down('md')]: {
        width015: {
            width: 0,
            marginTop: 15,
        },
        btnNotificacion: {
            width: '90%',
        },
    },
});

export default styles;