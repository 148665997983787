import React, { FC, useEffect, useContext, useMemo, useState, useCallback } from "react";
import { RouteComponentProps } from "react-router";
import { Icon } from "@mdi/react";
import { Link } from 'react-router-dom';
import { mdiAccountMultiple, mdiClockAlert, mdiClose, mdiCloseOctagon, mdiDownload, mdiHandBackRight, mdiPauseCircle, mdiPlay } from "@mdi/js";
import { makeStyles, Card, CardContent, CircularProgress, Dialog, DialogContent, Grid, Typography, DialogTitle, DialogActions, Button, Chip, Hidden, Divider, DialogContentText, List, ListItemText, ListItem, Avatar } from "@material-ui/core";
import queryString from 'query-string';
import moment from 'moment';
import { Alert } from "@material-ui/lab";
import { LiteralsContext } from "containers/shared/literals";
import Term from "components/term";
import usePage, { getAccessToken } from "hooks/page.hook";
import { translate } from "utils/i18n";
import IoC from "contexts/ioc.context";
import { RepresentacionesGateway } from "gateways/tramites/representaciones.gateway";
import { ISolicitudRepresentacion } from "gateways/tramites/representaciones.interfaces";
import { DocumentsUtils } from "utils/documents";
import BuscadorRepresentaciones from './buscador-representaciones';
import TabPanel from "components/tab-panel";
import CustomTabs from "components/custom-tabs";
import { AuthGateway } from "gateways/auth.gateway";
import CustomCountTerm from 'components/count-term/count-term';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(0, 1.5),
    },
    tabsContainer: {
        marginBottom: theme.spacing(1),
        flexDirection: 'row',
    },
    marginRow: {
        marginBottom: theme.spacing(1),
    },
    detailDialogTitle: {
        '& .MuiTypography-root': {
            display: 'flex',
            '& > span': {
                flex: 1,
            }
        }
    },
    dialogTitleRef: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        }
    },
    dialogTitleEst: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
        }
    },
    detailDialogContent: {
        paddingTop: theme.spacing(2),
    },
    statusInfo: {
        marginTop: theme.spacing(2),
    },
    labelUndefined: {
        fontStyle: 'italic'
    },
    grow: {
        flexGrow: 1,
    },
    cambiosEstadoContainer: {
        marginTop: theme.spacing(3),
    },
    list: {
        padding: 0,
    },
    listEstados: {
        marginBottom: 25,
        padding: 0,
    },
    subtitle: {
        fontSize: 14
    },
    margin25: {
        margin: '0 25px 0 25px',
    },
    xsHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: theme.spacing(1, 0),
    },
    xsHeaderTitle: {
        fontSize: 14,
        color: '#004f84',
    },
    changeStateHour: {
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
        }
    },
    btnAlta: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        margin: theme.spacing(1, 0),
        marginRight: 10,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
    centerGridItem: {
        textAlign: 'center'
    },

    progressContainer: { 
        display:'flex',
        height: '100%',
        width: '100%',
        alignItems:'center',
        justifyContent: 'center',
    },
}));

function IconoEstadoExpediente(idEstado: number) {
    switch (idEstado) {
        case 1:
            return (<Avatar style={{ backgroundColor: '#B4B4B4' }}>
                <Icon path={mdiPauseCircle} color={'#FFF'} size={1} />
            </Avatar>);
        case 2:
            return (<Avatar style={{ backgroundColor: '#EF2A31' }}>
                <Icon path={mdiClose} color={'#FFF'} size={1} />
            </Avatar>);
        case 3:
            return (<Avatar style={{ backgroundColor: '#86B275' }}>
                <Icon path={mdiPlay} color={'#FFF'} size={1} />
            </Avatar>);
        case 4:
            return (<Avatar style={{ backgroundColor: '#FFCD4F' }}>
                <Icon path={mdiClockAlert} color={'#FFF'} size={1} />
            </Avatar>);
        case 5:
            return (<Avatar style={{ backgroundColor: '#F8A85B' }}>
                <Icon path={mdiHandBackRight} color={'#FFF'} size={1} />
            </Avatar>);
        case 6:
            return (<Avatar style={{ backgroundColor: '#F37B63' }}>
                <Icon path={mdiCloseOctagon} color={'#FFF'} size={1} />
            </Avatar>);
        default:
            return (<Avatar style={{ backgroundColor: '#B4B4B4' }}>
                <Icon path={mdiPauseCircle} color={'#FFF'} size={1} />
            </Avatar>);
    }
}

export const AsLink = (to: string) => React.forwardRef((props, ref: any) => <Link {...props} style={{ textTransform: 'unset' }} to={to} ref={ref as any} />) as any;

export const GestionDeRepresentaciones: FC<RouteComponentProps> = ({ location, history }) => {
    const [page, pageDispatcher] = usePage();
    const nif = useMemo(() => page.jwp ? page.jwp.nif : null, [page.jwp]);
    const classes = useStyles();
    const terms = useContext(LiteralsContext);

    const [tabActive, setTabActive] = useState(0);
    const [representaciones, setRepresentaciones] = useState<ISolicitudRepresentacion[]>([]);
    const [poderes, setPoderes] = useState<ISolicitudRepresentacion[]>([]);
    const poderesAdquiridos = useMemo(() => nif ? poderes.filter((x) => x.nifApoderado.toUpperCase() === nif.toUpperCase()) : [], [poderes, nif]);
    const poderesOtorgados = useMemo(() => nif ? poderes.filter((x) => x.nifPoderdante.toUpperCase() === nif.toUpperCase()) : [], [poderes, nif]);

    const [poderSeleccionado, setPoderSeleccionado] = useState<ISolicitudRepresentacion | null>(null);
    const [confirmationDialog, setConfirmationDialog] = useState({ open: false, newStatusId: 0 });
    const [statusAction, setStatusAction] = useState<{ executing: boolean, success?: boolean, idDocumento?: string }>({ executing: false });
    const [filtros, setFiltros] = useState<{
        idEstado: number | null,
        codigoProcedimiento: string | null,
        nif: string | null,
        fechaFinVigenciaDesde: Date | null,
        fechaFinVigenciaHasta: Date | null
    }>({ idEstado: null, codigoProcedimiento: null, nif: null, fechaFinVigenciaDesde: null, fechaFinVigenciaHasta: null });

    const ioc = useContext(IoC);
    const representacionesGateway = useMemo(() => ioc.get(RepresentacionesGateway) as RepresentacionesGateway, [ioc]);
    const authGateway = useMemo(() => ioc.get(AuthGateway) as AuthGateway, [ioc]);

    const handleChange = useCallback((_E: React.ChangeEvent<{}>, newValue: number) => {
        setTabActive(newValue);
    }, []);

    const handleShowDetailModal = useCallback((solicitud: ISolicitudRepresentacion) => {
        return () => setPoderSeleccionado(solicitud);
    }, []);

    const handleCloseDetailModal = useCallback(() => {
        setPoderSeleccionado(null);
        setStatusAction({ executing: false });
    }, []);

    const handleCloseConfirmationDialog = useCallback(() => setConfirmationDialog({ open: false, newStatusId: 0 }), []);

    const handleRefreshTokenApoderado = useCallback(async () => {
        if (page.jwp && page.jwp.level > 1) {
            const accessToken = getAccessToken();

            if (accessToken !== null) {
                const result = await authGateway.refreshTokenApoderado(accessToken);
                if (result && result.accessToken !== '') {
                    pageDispatcher({ type: 'authenticate', payload: result });
                }
            }
        }
    }, [authGateway, pageDispatcher]);

    const handleActualizaEstado = useCallback(async (idEstado: number) => {
        if (!poderSeleccionado) {
            return;
        }
        setStatusAction({ executing: true });
        try {
            const result = await representacionesGateway.actualizaEstado(poderSeleccionado.id, idEstado);
            if (result.ok && result.solicitud) {
                const solicitudActualizada = result.solicitud;
                // solicitudActualizada.idDocumentoSolicitud = solicitudActualizada.idDocumentoSolicitud ? solicitudActualizada.idDocumentoSolicitud : result.idDocumento;
                setPoderSeleccionado(solicitudActualizada);
                setPoderes((poderesActuales) => ([
                    ...poderesActuales.filter(x => x.id !== poderSeleccionado.id),
                    solicitudActualizada,
                ]));
                handleRefreshTokenApoderado();
            }
            setStatusAction({ executing: false, success: result.ok, idDocumento: result.idDocumento });
        } catch (e) {
            setStatusAction({ executing: false, success: false });
        }
    }, [poderSeleccionado, representacionesGateway]);

    const handleAceptarSolicitud = useCallback(() => {
        setConfirmationDialog({ open: true, newStatusId: 3 });
    }, []);

    const handleRechazarSolicitud = useCallback(() => {
        setConfirmationDialog({ open: true, newStatusId: 2 });
    }, []);

    const handleRenunciarSolicitud = useCallback(() => {
        setConfirmationDialog({ open: true, newStatusId: 6 });
    }, []);

    const handleRevocarSolicitud = useCallback(() => {
        setConfirmationDialog({ open: true, newStatusId: 5 });
    }, []);

    const handleAcceptAction = useCallback(() => {
        handleActualizaEstado(confirmationDialog.newStatusId);
        setConfirmationDialog({ open: false, newStatusId: 0 });
    }, [handleActualizaEstado, confirmationDialog.newStatusId]);

    const handleDownloadJustificante = useCallback((idDocumento: string) => {
        return () => {
            DocumentsUtils.downloadDoc(idDocumento);
        }
    }, []);

    const handleChangeEstado = useCallback(
        (event: React.ChangeEvent<{ value: number }>) => {
            setFiltros({ ...filtros, idEstado: Number.isInteger(event.target.value) ? event.target.value : null });
            setPoderes(representaciones.filter((r) => {
                const fechaFinVigencia = moment(r.fechaFinVigencia).toDate();
                return (r.idEstado === (Number.isInteger(event.target.value) ? event.target.value : r.idEstado)) &&
                    (filtros.codigoProcedimiento ? r.codigoProcedimiento === filtros.codigoProcedimiento : true) &&
                    (filtros.nif ? r.nifPoderdante.includes(filtros.nif.toUpperCase()) : true) &&
                    (filtros.fechaFinVigenciaDesde ? fechaFinVigencia >= filtros.fechaFinVigenciaDesde : true) &&
                    (filtros.fechaFinVigenciaHasta ? fechaFinVigencia <= filtros.fechaFinVigenciaHasta : true);
            }));
        },
        [filtros, representaciones]
    );

    const handleChangeProcedimiento = useCallback(
        (event: React.ChangeEvent<{ value: string }>) => {
            setFiltros({ ...filtros, codigoProcedimiento: event.target.value !== '' ? event.target.value : null });
            setPoderes(representaciones.filter((r) => {
                const fechaFinVigencia = moment(r.fechaFinVigencia).toDate();
                return (r.codigoProcedimiento === (event.target.value !== '' ? event.target.value : r.codigoProcedimiento)) &&
                    (filtros.idEstado ? r.idEstado === filtros.idEstado : true) &&
                    (filtros.nif ? r.nifPoderdante.includes(filtros.nif.toUpperCase()) : true) &&
                    (filtros.fechaFinVigenciaDesde ? fechaFinVigencia >= filtros.fechaFinVigenciaDesde : true) &&
                    (filtros.fechaFinVigenciaHasta ? fechaFinVigencia <= filtros.fechaFinVigenciaHasta : true);
            }));
        },
        [filtros, representaciones]
    );

    const handleChangeNif = useCallback((nifFiltro: string) => {
        setFiltros({ ...filtros, nif: nifFiltro });
        setPoderes(representaciones.filter((r) => {
            const fechaFinVigencia = moment(r.fechaFinVigencia).toDate();
            return (filtros.idEstado ? r.idEstado === filtros.idEstado : true) &&
                (filtros.codigoProcedimiento ? r.codigoProcedimiento === filtros.codigoProcedimiento : true) &&
                (r.nifApoderado.includes(nifFiltro.toUpperCase()) || r.nifPoderdante.includes(nifFiltro.toUpperCase())) &&
                (filtros.fechaFinVigenciaDesde ? fechaFinVigencia >= filtros.fechaFinVigenciaDesde : true) &&
                (filtros.fechaFinVigenciaHasta ? fechaFinVigencia <= filtros.fechaFinVigenciaHasta : true);
        }));
    }, [filtros, representaciones]);

    const handleChangeFechaDesde = useCallback((date: Date | null) => {
        if (date && !isNaN(date.getTime())) {
            setFiltros({ ...filtros, fechaFinVigenciaDesde: date });
            setPoderes(representaciones.filter((r) => {
                const fechaFinVigencia = moment(r.fechaFinVigencia).toDate();
                return (filtros.idEstado ? r.idEstado === filtros.idEstado : true) &&
                    (filtros.codigoProcedimiento ? r.codigoProcedimiento === filtros.codigoProcedimiento : true) &&
                    (filtros.nif ? r.nifPoderdante.includes(filtros.nif.toUpperCase()) : true) &&
                    (fechaFinVigencia >= date) &&
                    (filtros.fechaFinVigenciaHasta ? fechaFinVigencia <= filtros.fechaFinVigenciaHasta : true);
            }));
        }
    }, [filtros, representaciones]);

    const handleChangeFechaHasta = useCallback((date: Date | null) => {
        if (date && !isNaN(date.getTime())) {
            date.setHours(23, 59, 0, 0);
            setFiltros({ ...filtros, fechaFinVigenciaHasta: date });
            setPoderes(representaciones.filter((r) => {
                const fechaFinVigencia = moment(r.fechaFinVigencia).toDate();
                return (filtros.idEstado ? r.idEstado === filtros.idEstado : true) &&
                    (filtros.codigoProcedimiento ? r.codigoProcedimiento === filtros.codigoProcedimiento : true) &&
                    (filtros.nif ? r.nifPoderdante.includes(filtros.nif.toUpperCase()) : true) &&
                    (filtros.fechaFinVigenciaDesde ? fechaFinVigencia >= filtros.fechaFinVigenciaDesde : true) &&
                    (fechaFinVigencia <= date);
            }));
        }
    }, [filtros, representaciones]);

    useEffect(() => {
        (async () => {
            const values = queryString.parse(location.search);
            const index: string = values.index as string;
            setTabActive(index ? parseInt(index, 10) : 0);
            const result = await representacionesGateway.getListado(null);
            setRepresentaciones(result);
            setPoderes(result);
        })();
    }, [location.search, representacionesGateway])

    useEffect(() => {
        (async () => {
            const result = await representacionesGateway.getListado(null);
            setRepresentaciones(result);
            setPoderes(result);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabActive])

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiAccountMultiple,
                title: <Term component="Representaciones" text="Ficha de poderes" />,
                text: ''
            },
            menu: true,
        });
    }, [pageDispatcher]);

    if (!page.jwp || !poderes) {
        return (
            <div className={classes.progressContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={classes.root}>

            {/* Cabecera mobile */}
            <Hidden mdUp>
                <Card className={classes.xsHeader}>
                    <h2 className={classes.xsHeaderTitle} >
                        <Term component="Representaciones" text="Gestion de poderes" />
                    </h2>

                    <div className={classes.btnAlta}>
                        <Button id="send-form" variant="contained" color="primary" component={AsLink('/perfil/representaciones/alta-de-representacion')} >
                            <Term component="Representaciones" text="Alta representacion" />
                        </Button>
                    </div>
                </Card>
            </Hidden>

            {/* Selector tabs */}

            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '1%', backgroundColor: 'white', borderRadius: '3px' }}>
                <CustomTabs
                    labelTab1={translate('Representaciones', 'poderes_adquiridos', terms)}
                    labelTab2={translate('Representaciones', 'poderes_otorgados', terms)}
                    onChange={handleChange}
                    tabActive={tabActive}
                />
                <Hidden smDown>
                    <div className={classes.grow} />
                    <div className={classes.btnAlta}>
                        <Button id="send-form" variant="contained" color="primary" component={AsLink('/perfil/representaciones/alta-de-representacion')} >
                            <Term component="Representaciones" text="Alta representacion" />
                        </Button>
                    </div>
                </Hidden>
            </div>

            {/* Listado poderes adquiridos */}
            <TabPanel value={tabActive} index={0}>
                <BuscadorRepresentaciones
                    onChangeEstado={handleChangeEstado}
                    onChangeProcedimiento={handleChangeProcedimiento}
                    onChangeNif={handleChangeNif}
                    onChangeFechaDesde={handleChangeFechaDesde}
                    onChangeFechaHasta={handleChangeFechaHasta}
                />
                {
                    poderesAdquiridos.length === 0
                        ? <Alert severity="warning">
                            <Term component="Representaciones" text="No se han encontrado registros" />
                        </Alert>

                        : <List dense>
                            {poderesAdquiridos.map((x, i) => {
                                return (
                                    <ListItem key={'poderes_adquiridos_' + i} style={{ cursor: 'pointer' }} onClick={handleShowDetailModal(x)} className={classes.list}>
                                        <Card style={{ flex: 1, marginBottom: 5 }}>
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        {IconoEstadoExpediente(x.idEstado)}
                                                    </Grid>
                                                    <Grid item xs={12} sm container>
                                                        <Grid item xs container direction="row" spacing={1}>
                                                            <Grid item xs>
                                                                <ListItemText>
                                                                    <Typography variant="caption">
                                                                        <Term component="Representaciones" text="Estado" />
                                                                    </Typography>
                                                                    <Typography variant="subtitle2">
                                                                        <Term component="Representaciones" text={`PROCEDIMIENTO_${x.idEstado}_ESTADO`} />
                                                                    </Typography>
                                                                </ListItemText>
                                                            </Grid>
                                                            <Grid item xs>
                                                                <ListItemText>
                                                                    <Typography variant="caption">
                                                                        <Term component="Representaciones" text="Descripcion_del_tramite" />
                                                                    </Typography>
                                                                    <Typography variant="subtitle2">
                                                                        <Term component="Representaciones" text={`PROCEDIMIENTO_${x.codigoProcedimiento}_NOMBRE`} />
                                                                    </Typography>
                                                                </ListItemText>
                                                            </Grid>
                                                            <Grid item xs className={classes.centerGridItem}>
                                                                <ListItemText>
                                                                    <Typography variant="caption">
                                                                        <Term component="Representaciones" text="NIF PODERDANTE" />
                                                                    </Typography>
                                                                    <Typography variant="subtitle2">
                                                                        {x.nifPoderdante}
                                                                    </Typography>
                                                                </ListItemText>
                                                            </Grid>
                                                            <Grid item xs>
                                                                <ListItemText>
                                                                    <Typography variant="caption">
                                                                        <Term component="Representaciones" text="NOMBRE PODERDANTE" />
                                                                    </Typography>
                                                                    <Typography variant="subtitle2">
                                                                        {x.nombrePoderdante}
                                                                    </Typography>
                                                                </ListItemText>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <ListItemText>
                                                                <Typography variant="caption">
                                                                    <Term component="Representaciones" text="Fecha fin autorización" />
                                                                </Typography>
                                                                <Typography variant="subtitle2">
                                                                    {moment(x.fechaFinVigencia).format('DD/MM/YYYY')}
                                                                </Typography>
                                                            </ListItemText>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </ListItem>
                                );
                            })}
                        </List>
                }
            </TabPanel>

            {/* Listado poderes otorgados */}
            <TabPanel value={tabActive} index={1}>
                <BuscadorRepresentaciones
                    onChangeEstado={handleChangeEstado}
                    onChangeProcedimiento={handleChangeProcedimiento}
                    onChangeNif={handleChangeNif}
                    onChangeFechaDesde={handleChangeFechaDesde}
                    onChangeFechaHasta={handleChangeFechaHasta}
                />
                {
                    poderesOtorgados.length === 0
                        ? <Alert severity="warning">
                            <Term component="Representaciones" text="No se han encontrado registros" />
                        </Alert>

                        : <List dense>
                            {poderesOtorgados.map((x, i) => {
                                return (
                                    <ListItem key={'poderes_otorgados_' + i} style={{ cursor: 'pointer' }} onClick={handleShowDetailModal(x)} className={classes.list}>
                                        <Card style={{ flex: 1, marginBottom: 5 }}>
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        {IconoEstadoExpediente(x.idEstado)}
                                                    </Grid>
                                                    <Grid item xs={12} sm container>
                                                        <Grid item xs container direction="row" spacing={1}>
                                                            <Grid item xs>
                                                                <ListItemText>
                                                                    <Typography variant="caption">
                                                                        <Term component="Representaciones" text="Estado" />
                                                                    </Typography>
                                                                    <Typography variant="subtitle2">
                                                                        <Term component="Representaciones" text={`PROCEDIMIENTO_${x.idEstado}_ESTADO`} />
                                                                    </Typography>
                                                                </ListItemText>
                                                            </Grid>

                                                            <Grid item xs>
                                                                <ListItemText>
                                                                    <Typography variant="caption">
                                                                        <Term component="Representaciones" text="Descripcion_del_tramite" />
                                                                    </Typography>
                                                                    <Typography variant="subtitle2">
                                                                        <Term component="Representaciones" text={`PROCEDIMIENTO_${x.codigoProcedimiento}_NOMBRE`} />
                                                                    </Typography>
                                                                </ListItemText>

                                                            </Grid>
                                                            <Grid item xs className={classes.centerGridItem}>
                                                                <ListItemText>
                                                                    <Typography variant="caption">
                                                                        <Term component="Representaciones" text="NIF APODERADO" />
                                                                    </Typography>
                                                                    <Typography variant="subtitle2">
                                                                        {x.nifApoderado}
                                                                    </Typography>
                                                                </ListItemText>
                                                            </Grid>

                                                            <Grid item xs>
                                                                <ListItemText>
                                                                    <Typography variant="caption">
                                                                        <Term component="Representaciones" text="NOMBRE APODERADO" />
                                                                    </Typography>
                                                                    <Typography variant="subtitle2">
                                                                        {x.nombreApoderado}
                                                                    </Typography>
                                                                </ListItemText>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <ListItemText>
                                                                <Typography variant="caption">
                                                                    <Term component="Representaciones" text="Fecha fin autorización" />
                                                                </Typography>
                                                                <Typography variant="subtitle2">
                                                                    {moment(x.fechaFinVigencia).format('DD/MM/YYYY')}
                                                                </Typography>
                                                            </ListItemText>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </ListItem>
                                );
                            })}
                        </List>
                }
            </TabPanel>

            {/* Diálogo detalle */}
            <Dialog fullWidth maxWidth="md" open={!!poderSeleccionado} onClose={handleCloseDetailModal} aria-labelledby="dialog-detail-title">
                {
                    poderSeleccionado && (<>
                        <DialogTitle id="dialog-detail-title" className={classes.detailDialogTitle}>
                            <Grid container>
                                <Grid item xs={12} md={9} className={[classes.marginRow, classes.dialogTitleRef].join(' ')}>
                                    <span>Ref. {poderSeleccionado.referencia}</span>
                                </Grid>
                                <Grid item xs={12} md={3} className={[classes.marginRow, classes.dialogTitleEst].join(' ')}>
                                    {poderSeleccionado.idEstado === 3 && <Chip variant="outlined" color="primary" label={<Term component="Representaciones" text={`PROCEDIMIENTO_${poderSeleccionado.idEstado}_ESTADO`} />} />}
                                    {[2, 4, 5, 6].includes(poderSeleccionado.idEstado) && <Chip variant="outlined" color="secondary" label={<Term component="Representaciones" text={`PROCEDIMIENTO_${poderSeleccionado.idEstado}_ESTADO`} />} />}
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <div className={classes.margin25}>
                            <h2 className={classes.subtitle}>
                                <Term component="Representaciones" text="Datos" />
                            </h2>
                            <Divider />
                        </div>
                        <DialogContent className={classes.detailDialogContent}>
                            <Grid container>

                                <Grid item xs={12} md={6} className={classes.marginRow}>
                                    <Typography variant="caption">
                                        <Term component="Representaciones" text="Poderdante" />
                                    </Typography>
                                    <Typography variant="subtitle2">{poderSeleccionado.nifPoderdante} - {poderSeleccionado.nombrePoderdante}</Typography>
                                </Grid>

                                <Grid item xs={12} md={6} className={classes.marginRow}>
                                    <Typography variant="caption">
                                        <Term component="Representaciones" text="Apoderado" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {
                                            poderSeleccionado.nombreApoderado
                                                ? <Typography variant="subtitle2">
                                                    {poderSeleccionado.nifApoderado} - {poderSeleccionado.nombreApoderado}
                                                </Typography>
                                                : <Typography variant="subtitle2" className={classes.labelUndefined} color="textSecondary">
                                                    {
                                                        poderSeleccionado.nifPoderdante === page.jwp.fullname
                                                            ? <Term component="Representaciones" text="Pendiente" />
                                                            : poderSeleccionado.nifApoderado
                                                    }
                                                </Typography>
                                        }
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6} className={classes.marginRow}>
                                    <Typography variant="caption">
                                        <Term component="Representaciones" text="Fecha alta" />
                                    </Typography>
                                    <Typography variant="subtitle2">{moment(poderSeleccionado.fechaAlta).format('DD/MM/YYYY')}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6} className={classes.marginRow}>
                                    <Typography variant="caption">
                                        <Term component="Representaciones" text="Fecha fin autorización" />
                                    </Typography>
                                    <Typography variant="subtitle2">{moment(poderSeleccionado.fechaFinVigencia).format('DD/MM/YYYY')}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6} className={classes.marginRow}>
                                    <Typography variant="caption">
                                        <Term component="Representaciones" text="Procedimiento" />
                                    </Typography>
                                    <Typography variant="subtitle2"><Term component="Representaciones" text={`PROCEDIMIENTO_${poderSeleccionado.codigoProcedimiento}_NOMBRE`} /></Typography>
                                </Grid>
                                <Grid item xs={12} md={6} className={classes.marginRow}>
                                    <Typography variant="caption">
                                        <Term component="Representaciones" text="Email" />
                                    </Typography>
                                    <Typography variant="subtitle2"><Term component="Representaciones" text={poderSeleccionado.emailApoderado ? poderSeleccionado.emailApoderado : ""} /></Typography>
                                </Grid>
                            </Grid>


                            {
                                poderSeleccionado.cambios && (
                                    <div className={classes.cambiosEstadoContainer}>
                                        <h2 className={classes.subtitle}>
                                            <Term component="Representaciones" text="Estados" />
                                        </h2>
                                        <Divider />
                                        <List dense>
                                            <ListItem key={'poderes_seleccionado_' + poderSeleccionado.id} className={classes.listEstados}>
                                                <Grid container>
                                                    <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
                                                        <h2 className={classes.subtitle}>
                                                            <Term component="Representaciones" text="Fecha" />
                                                        </h2>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} style={{ textAlign: 'center' }}>
                                                        <h2 className={classes.subtitle}>
                                                            <Term component="Representaciones" text="Estado" />
                                                        </h2>
                                                    </Grid>
                                                    <Grid item xs={12} md={2} style={{ cursor: 'pointer', textAlign: 'center' }} >
                                                        <h2 className={classes.subtitle}>
                                                            <Term component="Representaciones" text="Justificante" />
                                                        </h2>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem key={'poderes_seleccionado_' + poderSeleccionado.id} className={classes.listEstados}>
                                                <Grid container>
                                                    <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
                                                        <span style={{ verticalAlign: 'super' }}>{moment(poderSeleccionado.fechaAlta).utc().format('DD/MM/YYYY HH:mm')}</span>
                                                        <Hidden mdUp>
                                                            <Icon path={mdiDownload} color='default' size={1} />
                                                        </Hidden>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} style={{ textAlign: 'center' }}>
                                                        {<Chip variant="outlined" color="default" label={<Term component="Representaciones" text={`PROCEDIMIENTO_1_ESTADO`} />} size={'small'} />}
                                                    </Grid>
                                                    <Grid item xs={12} md={2} onClick={handleDownloadJustificante(poderSeleccionado.idDocumentoSolicitud)} style={{ cursor: 'pointer', textAlign: 'center' }} >
                                                        <Hidden mdDown>
                                                            <Icon path={mdiDownload} color='default' size={1} />
                                                        </Hidden>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            {poderSeleccionado.cambios.map((x, i) => {
                                                return (
                                                    <ListItem key={'poderes_seleccionado_' + x.id} className={classes.listEstados}>

                                                        <Grid container>
                                                            <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
                                                                <span style={{ verticalAlign: 'super' }}>{moment(x.fechaCambio).utc().format('DD/MM/YYYY HH:mm')}</span>
                                                                <Hidden mdUp>
                                                                    <Icon path={mdiDownload} color='default' size={1} />
                                                                </Hidden>
                                                            </Grid>
                                                            <Grid item xs={12} md={3} style={{ textAlign: 'center' }}>
                                                                {[1].includes(x.idEstadoPosterior) && <Chip variant="outlined" color="default" label={<Term component="Representaciones" text={`PROCEDIMIENTO_${x.idEstadoPosterior}_ESTADO`} />} size={'small'} />}
                                                                {[3].includes(x.idEstadoPosterior) && <Chip variant="outlined" color="primary" label={<Term component="Representaciones" text={`PROCEDIMIENTO_${x.idEstadoPosterior}_ESTADO`} />} size={'small'} />}
                                                                {[2, 4, 5, 6].includes(x.idEstadoPosterior) && <Chip variant="outlined" color="secondary" label={<Term component="Representaciones" text={`PROCEDIMIENTO_${x.idEstadoPosterior}_ESTADO`} />} size={'small'} />}
                                                            </Grid>
                                                            <Grid item xs={12} md={2} onClick={handleDownloadJustificante(x.idDocumento)} style={{ cursor: 'pointer', textAlign: 'center' }} >
                                                                <Hidden mdDown>
                                                                    <Icon path={mdiDownload} color='default' size={1} />
                                                                </Hidden>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </div>
                                )
                            }

                            {poderSeleccionado.idEstado === 1 && <Alert severity="info" className={classes.statusInfo}>
                                <Term component="Representaciones" text="El otorgamiento del poder esta pendiente de que el apoderado resuelva la solicitud." />
                            </Alert>}

                            {statusAction.success === true && <Alert severity="success" className={classes.statusInfo}>
                                <Term component="Representaciones" text="Se ha actualizado la solicitud correctamente" />
                            </Alert>}
                            {statusAction.success === false && <Alert severity="error" className={classes.statusInfo}>
                                <Term component="Representaciones" text="Ha ocurrido un error al actualizar la solicitud" />
                            </Alert>}
                        </DialogContent>

                        <DialogActions className={classes.detailDialogContent}>
                            <div className={classes.grow} />
                            {
                                poderSeleccionado.nifApoderado === page.jwp.nif && poderSeleccionado.idEstado === 1 && (<>
                                    <Button variant="outlined" color="secondary" onClick={handleRechazarSolicitud} disabled={statusAction.executing}>
                                        <Term component="Representaciones" text="Rechazar" />
                                    </Button>
                                    <Button variant="outlined" color="primary" onClick={handleAceptarSolicitud} disabled={statusAction.executing}>
                                        <Term component="Representaciones" text="Aceptar" />
                                    </Button>
                                </>)
                            }
                            {
                                poderSeleccionado.nifApoderado === page.jwp.nif && poderSeleccionado.idEstado === 3 && (<>
                                    <Button variant="outlined" color="secondary" onClick={handleRenunciarSolicitud} disabled={statusAction.executing}>
                                        <Term component="Representaciones" text="Renunciar" />
                                    </Button>
                                </>)
                            }
                            {
                                poderSeleccionado.nifPoderdante === page.jwp.nif && poderSeleccionado.idEstado === 3 && (<>
                                    <Button variant="outlined" color="secondary" onClick={handleRevocarSolicitud} disabled={statusAction.executing}>
                                        <Term component="Representaciones" text="Revocar" />
                                    </Button>
                                </>)
                            }
                            {
                                poderSeleccionado.nifPoderdante === page.jwp.nif && poderSeleccionado.idEstado === 1 && (<>
                                    <Button variant="outlined" color="secondary" onClick={handleRevocarSolicitud} disabled={statusAction.executing}>
                                        <Term component="Representaciones" text="Revocar" />
                                    </Button>
                                </>)
                            }

                            <Button variant="outlined" color="default" onClick={handleCloseDetailModal}>
                                <Term component="Representaciones" text="Cerrar" />
                            </Button>
                        </DialogActions>
                    </>)
                }
            </Dialog>

            {/* Diálogo confirmar acción */}
            <Dialog
                open={confirmationDialog.open && !!poderSeleccionado}
                onClose={handleCloseConfirmationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Term component="Representaciones" text="Confirmación" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {confirmationDialog.newStatusId === 2 && <Term component="Representaciones" text="¿Confirma que quiere rechazar la solicitud?" />}
                        {confirmationDialog.newStatusId === 3 && <Term component="Representaciones" text="¿Confirma que quiere aceptar la solicitud?" />}
                        {confirmationDialog.newStatusId === 5 && <Term component="Representaciones" text="¿Confirma que quiere revocar la solicitud?" />}
                        {confirmationDialog.newStatusId === 6 && <Term component="Representaciones" text="¿Confirma que quiere renunciar la solicitud?" />}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmationDialog} color="default">
                        <Term component="Representaciones" text="Cancelar" />
                    </Button>
                    <Button onClick={handleAcceptAction} color="primary" autoFocus>
                        <Term component="Representaciones" text="Continuar" />
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}