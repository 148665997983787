import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import { ISujeto, JwpSujeto } from "gateways/perfil.interfaces";
import { FC, useContext, useEffect, useState } from "react";
import { ProteccionDatosGateway } from "gateways/proteccion-datos.gateway";
import { AlertsContext } from "contexts/alerts.context";
import Term from "./term";
import IoC from "contexts/ioc.context";

const useStyles = makeStyles(() => ({
    centerContent: {
        textAlign: 'center',
        padding: 100,
        width: '100%',
    },
    containerListDatosPersonales: {
        backgroundColor: '#dfe7ef',
        margin: '1rem',
        padding: 5,
        borderRadius: 4
    },
    listDatosPersonales: {
        margin: '5px 0px'
    },
    containerListElement: {
        display: 'flex',
        gap: '5px'
    },
    containerActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px 24px'
    },
    containerCheckBox: {
        display: 'flex',
        alignItems: 'center',
    },
    btnsActions: {
        display: 'flex',
        gap: '1rem'
    },
}));

interface IProps {
    sujeto: ISujeto | null;
    onClose: () => void;
}

const DialogProteccionDatos: FC<IProps> = ({ sujeto, onClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [, alertsDispatch] = useContext(AlertsContext);
    // Services
    const ioc = useContext(IoC);
    const proteccionDatosG = ioc.get(ProteccionDatosGateway) as ProteccionDatosGateway;
    // Constants
    const [asunto,] = useState<{ key: string, value: string }>({ key: "primera_entrada_sede", value: "primera entrada sede" });
    // Variables
    const [loading, setLoading] = useState(false);
    const [checkConforme, setCheckConforme] = useState(false);
    const [isProteccionDatosRequestedThisSession, setIsProteccionDatosRequestedThisSession] = useState(false);
    const [jwp, setJwp] = useState<JwpSujeto | null>(null);

    const handleConformeDatosProteccion = async () => {
        try {
            if (!jwp || !jwp.idUsuario || !sujeto) return;

            const tempDatos = `nombre${sujeto.nombre};nif:${sujeto.nif};email:${sujeto.email};idSujeto:${jwp.idSujeto};`;

            setLoading(true);
            await proteccionDatosG.postProteccionDatos({
                idUsuario: jwp.idUsuario.toString(),
                datos: tempDatos,
                motivo: asunto.key,
                tipoObjeto: 'sel',
                idObjeto: sujeto.nif,
            });

            handleCheckSessionStorage();
            handleClose();
        }
        catch (error) {
            handleClose();
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component={'Global'} text={'Error try later again'} />,
                    variant: 'error',
                }
            });
        }
        finally {
            setLoading(false);
        }
    }

    const handleCheckConforme = () => {
        setCheckConforme(!checkConforme);
    }

    const handleClose = () => {
        handleCheckSessionStorage();
        onClose();
    }

    const handleCheckSessionStorage = () => {
        if (!isProteccionDatosRequestedThisSession) {
            sessionStorage.setItem('isProteccionDatosRequestedThisSession', '1');
            setIsProteccionDatosRequestedThisSession(true);
        }
    }

    useEffect(() => {
        const jwpLocalStorage = localStorage.getItem('jwp');

        if (jwpLocalStorage) {
            const jwpLocalStorageJSON = JSON.parse(jwpLocalStorage);
            setJwp(jwpLocalStorageJSON);
        }
    }, []);

    return (
        <Dialog
            open={true}
            fullScreen={fullScreen}
        >
            {loading
                ?
                <div className={classes.centerContent}>
                    <CircularProgress size={50} />
                </div>
                :
                <>
                    <DialogTitle>
                        <Term component={'Global'} text={'Información sobre protección de datos de carácter personal'} />
                    </DialogTitle>

                    <DialogContent>
                        <Typography>
                            <Term component={'Global'} text={'Reglamento General de Protección de Datos'} />
                        </Typography>
                        <Typography>
                            <Term component={'Global'} text={'Usted va a enviar la siguiente información'} />:
                        </Typography>

                        <div className={classes.containerListDatosPersonales}>
                            <ul className={classes.listDatosPersonales}>
                                <li>
                                    <div className={classes.containerListElement}>
                                        <Typography style={{ fontSize: 'small' }}>
                                            <Term component={'Global'} text={'Nombre'} />:
                                        </Typography>
                                        <Typography style={{ fontSize: 'small' }}>
                                            {sujeto?.nombre}
                                        </Typography>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.containerListElement}>
                                        <Typography style={{ fontSize: 'small' }}>
                                            <Term component={'Global'} text={'Número de teléfono'} />:
                                        </Typography>
                                        <Typography style={{ fontSize: 'small' }}>
                                            {sujeto?.movil}
                                        </Typography>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.containerListElement}>
                                        <Typography style={{ fontSize: 'small' }}>
                                            <Term component={'Global'} text={'Correo electronico'} />:
                                        </Typography>
                                        <Typography style={{ fontSize: 'small' }}>
                                            {sujeto?.email}
                                        </Typography>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.containerListElement}>
                                        <Typography style={{ fontSize: 'small' }}>
                                            <Term component={'Global'} text={'Asunto'} />:
                                        </Typography>
                                        <Typography style={{ fontSize: 'small' }}>
                                            {asunto.value}
                                        </Typography>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <Typography><Term component={'Global'} text={'Conformidad condiciones de privacidad y protección de datos'} /></Typography>
                    </DialogContent>

                    <DialogActions className={classes.containerActions}>
                        <div className={classes.containerCheckBox}>
                            <Checkbox
                                checked={checkConforme}
                                color="primary"
                                onChange={handleCheckConforme}
                            />
                            <Term component={'Global'} text={'Conforme'} />
                        </div>

                        <div className={classes.btnsActions}>
                            <Button
                                onClick={handleClose}
                                color="default"
                                variant="outlined"
                            >
                                <Term component={'Global'} text={'Cancelar'} />
                            </Button>

                            <Button
                                onClick={handleConformeDatosProteccion}
                                color="primary"
                                variant="outlined"
                                disabled={!checkConforme}
                            >
                                <Term component={'Global'} text={'Aceptar'} />
                            </Button>
                        </div>
                    </DialogActions>
                </>
            }
        </Dialog>
    )
}

export default DialogProteccionDatos;