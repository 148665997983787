import React, { FC, useContext, useEffect, useState } from "react";
import IoC from "contexts/ioc.context";
import { AuthGateway } from "gateways/auth.gateway";
import { RouteComponentProps } from "react-router-dom";
import ClaveForm from "components/clave-form";
import queryString from 'query-string';
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    centerContent: {
        textAlign: 'center',
        paddingTop: 250,
        width: '100%',
    },
}));

type Props = RouteComponentProps<any>;

const RedirectClave: FC<Props> = ({ location, history }) => {
    const ioc = useContext(IoC);
    const classes = useStyles();
    const authGateway: AuthGateway = ioc.get(AuthGateway);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        redirectToClave();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectToClave = async () => {
        setLoading(true);
        const values = queryString.parse(location.search);
        const loginToken: string = values.token as string;
        const result = await authGateway.getAuthenticationRequest('/', '4', loginToken);
        if (result.ok) {
            const claveForm = document.getElementById('claveForm') as HTMLFormElement;
            const relayState = document.getElementById('RelayState') as HTMLInputElement;
            const samlRequest = document.getElementById('SAMLRequest') as HTMLInputElement;

            relayState.value = result.relayState;
            samlRequest.value = result.samlRequest ? result.samlRequest : '';
            claveForm.action = result.provider;
            claveForm.submit();
        }
    };

    return (
        <>
            <ClaveForm />
            {loading ?
                <div className={classes.centerContent}>
                    <CircularProgress size={75} />
                </div>
                : null
            }
        </>
    );

};

export default RedirectClave;