import { FC,  useContext,  useEffect, useMemo, useRef, useState } from "react";
import { Grid, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { StepComponentProps } from "components/generic-stepper/generic-step-component";
import ButtonsStepper from "containers/tributos-autonomicos-locales/componentesAsistente/shared/buttonsStepper";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import useCommonStyles from './../styles'
import { translate } from "utils/i18n";
import { BIOPT, DeclaracionMap, TCausaValor, TCausaValor2, TDeclaracionResponsable, TDeclaracionResponsable50, TDeclaracionResponsable51, TDeclaracionResponsable52, TDeclaracionResponsable53, TModalidadSolicitud, TNHProgenitor, TSolicitudAbonoNacimiento, TTipo, TTipoDocGenera } from "../types";
import GenericCheckbox from "components/generic-checkbox";
import FixStepInputNumber from "components/generic-input-number/fix-step-input-number";
import GenericTypedInputNumber from "components/generic-input-number/typed-input-number";
import GenericInputText, { TExtraValidation } from "components/generic-input-text";
import * as ibantools from 'ibantools';
import GenericRadioButton from "components/generic-radio-button";
import EsCausaValor from "../componentes/es-causa-valor";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import { AbonoNacimientoGateway } from "gateways/tramites/abonoNacimiento.gateway";
import { camposByDocToObject, deepUpdate } from "../../utils";
import FullLoading from "components/full-loading";
import GenericStepSchema from "components/generic-stepper/generic-step-schema";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import { ContenidosGateway } from "gateways/contenido.gateway";
import GenericBooleanRadioButton, { BOOL_OPTIONS } from "components/generic-radio-button/generic-boolean-radio-button";



type TToRender<T extends TTipoDocGenera> = {
    label: string
    key: keyof DeclaracionMap[T],
    checked: boolean
    show: boolean
    hasValue: boolean
    keyValue: (keyof DeclaracionMap[T])[] //| keyof DeclaracionMap[T][],
    value: any //number|string |undefined
    ready: boolean ,
    moreInfo: {template: string ;size?: TInfoTemplateSize }|undefined,
    moreInfoValue: {template: string ;size?: TInfoTemplateSize }| undefined,          
}

type TToRendeMap = {
    [K in TTipoDocGenera]: TToRender<K> 
};




export type DeclaracionResponsableProps = StepComponentProps  & WithStyles<typeof styles> 

const TypedDeclaracionResponsable: FC<DeclaracionResponsableProps & {tipo: TTipo}> = ({classes, tipo, idRef, apartado, onBack,onNext,onCancel, isFirst, isLast}) => { 
    const CommonClasses= useCommonStyles()
    const terms=useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext);
    const componentMounted = useRef(true);
    // Gateways
    const ioc = useContext(IoC);
    const abonoNacimientoGateway: AbonoNacimientoGateway = useMemo(() => ioc.get(AbonoNacimientoGateway), [ioc]);
    const contenidosGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);


    const [loading, setLoading]= useState(false);
    const [msgLoading, setMsgLoading]= useState<string| undefined>(undefined)
    const [declarcionResponsable, setDeclaracionResponsable] = useState<TDeclaracionResponsable>({declResponsable: null, tipoDocGenera: null,codigoPostal_valor1: null,codigoPostal_valor2:null,modalidadSolicitud: null, residenteIB_validacion: null})
    const [forceExit, setForceExit] = useState<{force: boolean, cause: string|undefined}>({force:false, cause: undefined});
    const [docGenera, setDocGenera] = useState<TTipoDocGenera>(51);
    const [infoResidenteIB, setInfoResidenteIB] = useState<string|undefined>(undefined)
    const [nifsToCheck,setNifsToCheck]= useState<{nif1: string | null, nif2: string | null}>({nif1: null, nif2: null})
    const [literals,setLiterals]=useState<string[]>([])
    const DOC_GENERA = useMemo(() => ( declarcionResponsable.tipoDocGenera !== null ? declarcionResponsable.tipoDocGenera: 50 ) ,[declarcionResponsable.tipoDocGenera]);
    const [toRender,setToRender]=useState<TToRendeMap[typeof DOC_GENERA][]>([])
    const [EMPTY_DECL_RESP, setEmptyDeclResp] = useState<DeclaracionMap[typeof DOC_GENERA] | null>(null)
    const [modalidad,setModalidad]=useState<TModalidadSolicitud | null>(null)
    const [isFamiliaNum, setIsFamiliaNum] = useState(false)
    const [isMonoParental,setIsMonoParental]= useState(false)
    const [numHijos_50, setNumHijos_50]=useState(0)
    const [numHijos_52, setNumHijos_52]=useState<TNHProgenitor>({
        numHijosProgenitor_valor1: undefined,
        numHijosProgenitor_valor2:undefined
    })

    
    const [allBIOptions, setAllBIOptions] = useState<BIOPT[]>([])
    const [selectedBI, setSelectedBI] = useState<undefined|{id:string, term:string,limiteBI:number}>(undefined)

    
    //const numProgenitores_options: {id:string, term:string}[] = [{id: '1', term: 'uno'},{id: '2', term: 'dos'}]
    // Chekc - Keys que tienen un moreInfo
    const withMoreInfo=['50_baseImponible','50_familiaNumerosa','52_baseImponible', '52_familiaNumerosa']//,'52_numHijosProgenitor']
    const withMoreInfo_inValue=['50_numHijosImpuestoIRPF', '52_importeIRPF','52_numHijosProgenitor']


    // -- Memos ------------------------------------------------------------------------------------------------------------------------------
    const baseImponible_options: {id:string, term:string, limiteBI: number}[] = useMemo(() => {
        const mod= modalidad === 'conjunta' ? 2 : 1;
        const out : {id:string, term:string, limiteBI: number}[] = []
        //console.log('options', DOC_GENERA,'modalidad' , mod,'isFamiliaNum', isFamiliaNum, 'isMonoParental', numHijos_50, numHijos_52)
        const monoparental = isMonoParental && ( (
            DOC_GENERA === 50 && (numHijos_50-1)>=2
        ) || (
            DOC_GENERA === 52 && isMonoParental && //((
                /*modalidad==='individual' &&*/ numHijos_52.numHijosProgenitor_valor1 && (numHijos_52.numHijosProgenitor_valor1-1) >=2 
            /*) || (
                modalidad==='conjunta' && numHijos_52.numHijosProgenitor_valor1 && numHijos_52.numHijosProgenitor_valor2 && ((numHijos_52.numHijosProgenitor_valor1 + numHijos_52.numHijosProgenitor_valor2)-2) >= 2
            ))   */
        ))
        let opt = allBIOptions.filter( item => (item.fMonoparental===monoparental  && item.fNumerosa === isFamiliaNum))
        if(DOC_GENERA === 52){
            opt = opt.filter(o => o.modalidad === mod)
        }
        opt.forEach(o => {
            out.push({id: o.id.toString(), term: o.term, limiteBI:o.limiteBI})
        })
        if(DOC_GENERA === 50){
            const optNoIRPF = allBIOptions.find( item => (item.limiteBI === 0) )
            optNoIRPF && out.push({id: optNoIRPF.id.toString(), term: optNoIRPF.term , limiteBI: optNoIRPF.limiteBI})
        }

        return out

        
    },[allBIOptions, isMonoParental, isFamiliaNum, modalidad, numHijos_50,numHijos_52,DOC_GENERA])
     
    const NUMPROG52 = useMemo(() => {
        return isMonoParental 
            ? ['numHijosProgenitor_valor1']
            : ['numHijosProgenitor_valor1','numHijosProgenitor_valor2']
    }, [isMonoParental,modalidad]);

    const disableNEXT = useMemo(() => {
        // console.log(toRender)
        const checkeds = toRender.length===0 || declarcionResponsable.declResponsable === null || declarcionResponsable.tipoDocGenera === null
            ? [false] 
            : toRender.map( r => { 
                //console.log(r.checked, r.ready, r.key)
                return(
                    r.checked===true && r.ready==true //((r.checked===true && r.ready==true && r.key !== 'autorizaConsultaDatos') || (r.checked !== undefined && r.ready === true && r.key === 'autorizaConsultaDatos' )) 
                    ? true 
                    : false
                )
            })

        // console.log('checkeds ', checkeds, toRender)
        if(!checkeds.includes(false) && declarcionResponsable.declResponsable !== null){
            //console.log(toRender)
            let newDR = {...EMPTY_DECL_RESP}
            toRender.forEach((element: TToRendeMap[typeof DOC_GENERA])=> {
                const key = element.key as keyof typeof newDR;
                const keyValue = element.keyValue as (keyof typeof newDR)[];
                if (key in newDR && typeof newDR[key] === 'boolean') {
                    newDR = {...newDR, [key] : element.checked};
                    if(element.hasValue && keyValue.length ===1 && (keyValue[0] in newDR) ){
                        newDR = {...newDR, [keyValue[0]] : element.value};
                    }
                    if(element.hasValue && keyValue.length >1){
                        keyValue.forEach( keyV => {
                            if(keyV in newDR){
                                newDR = {...newDR, [keyV] : element.value[keyV]};
                            }
                            
                        });
                    }
                }

            });

            //console.log('setDeclaracionResponsable', newDR)
            setDeclaracionResponsable(curr => ({
                ...curr,
                declResponsable: newDR as DeclaracionMap[typeof DOC_GENERA]
            }))
        }
        //console.log('return ', checkeds.includes(false) )
        return checkeds.includes(false) || declarcionResponsable.tipoDocGenera === null
    },[toRender, declarcionResponsable.tipoDocGenera])


  // -- Functions  -------------------------------------------------------------------------------------------------------------------------
    const validateIBAN = (iban:string) => {
        const isOK =  ibantools.isValidIBAN(iban)
        const isES = iban.slice(0,2) === 'ES';
        return({error: isOK && isES ? false: true, error_msg: !isOK ? 'invalid_IBAN' : (!isES ? 'not_ES_IBAN' : undefined), componentIG: "GenericComponents"} as TExtraValidation)
    }

    const updateToRender = <T extends TTipoDocGenera>(
        key:  keyof TDeclaracionResponsable50 | keyof TDeclaracionResponsable51 | keyof TDeclaracionResponsable52| keyof TDeclaracionResponsable53, 
        idx: number, 
        p_checkedVal: boolean , 
        value: any, //number| string | undefined,
        valueErr:boolean
    ) => {
        (async() => {
            try {
                let checkedVal = p_checkedVal
                if(key === 'residenteIB' && checkedVal === true){
                    const currentEjercicio = new Date().getFullYear()
                    setMsgLoading(translate('AbonoNacimiento','comprobandoResidenciaFiscalIB', terms, [currentEjercicio-1]))
                    setLoading(true);
                    let isOK1 = false
                    let isOK2 = false
                    let newDecl = {...declarcionResponsable}
    
                    if(nifsToCheck.nif1){
                        const response1 = await abonoNacimientoGateway.comprobarResidenciaFiscal(nifsToCheck.nif1, currentEjercicio-1, docGenera)
                        //console.log('response 1  ', response1)
                        isOK1=response1.ok
                        newDecl ={...newDecl, codigoPostal_valor1: response1.cp}
                    }
                    if(nifsToCheck.nif2){
                        const response2 = await abonoNacimientoGateway.comprobarResidenciaFiscal(nifsToCheck.nif2, currentEjercicio-1, docGenera)
                        //console.log('response 2  ', response2)
                        isOK2=response2.ok
                        newDecl = {...newDecl, codigoPostal_valor2: response2.cp}
                    } else{
                        isOK2=true
                    }
                    setDeclaracionResponsable({...newDecl, residenteIB_validacion: isOK1 && isOK2 ? true : false});
                    
                    // Si hay que bloquear continuar decl
                    // setForceExit({force: !(isOK1 && isOK2) , cause: (isOK1 && isOK2)? undefined : 'no-residencia-fiscal-IB' })
                    
                    // Se quita temporalmente el aviso de no validación de residencia fiscal
                    // if(!(isOK1 && isOK2)){
                    //     //checkedVal= false; // Si hay que bloquear continuar decl
                    //     alertsDispatch({
                    //         type: 'show-alert',
                    //         payload: {
                    //             variant:'error',
                    //             isHtml: true,
                    //             message: infoResidenteIB ?? 'Ooops not message found !!'
                    //         }
                    //     })
                    // }
                    setLoading(false);
                    setMsgLoading(undefined)
    
                }

                if(checkedVal){ 

                    const isReady = checkedVal && !valueErr && (toRender[idx].hasValue === false || (toRender[idx].hasValue && (
                        typeof value === 'object' && key !== 'esCausa_valor'
                            ? key === 'numHijosProgenitor_valor' && isMonoParental 
                                ? Object.values(value)[0] !== undefined
                                : Object.values(value).every(v => v!== undefined)
                            : (typeof value !== 'undefined')
                        )))
                    const foreClean = key === 'familiaNumerosa_valor' || key === 'numHijosImpuestoIRPF_valor' || key === 'numHijosProgenitor_valor'

                    const newTR = toRender.map((tr, indx ) => { 
                        const next = idx+1
                        return (
                            indx === idx 
                                ? ({...tr, checked: checkedVal, value: value, ready: isReady})
                                : foreClean && indx > idx 
                                    ? {...tr, show: indx === next , checked: false, value: undefined, ready:false} 
                                    : (indx === next) 
                                        ? {...tr, 
                                            show: isReady, 
                                            checked: isReady 
                                                ? tr.checked //tr.key ==='autorizaConsultaDatos' && tr.checked === undefined ? false:
                                                : false, 
                                        
                                            value: isReady ?  tr.value : undefined, 
                                            ready: isReady 
                                                ? tr.ready //tr.key ==='autorizaConsultaDatos' ? true :
                                                : false
                                        } 
                                        : indx > next && !isReady 
                                            ? {...tr, show: false, checked: false, value: undefined, ready:false}  
                                            : tr                   
                        )
                    })
                    setToRender(newTR)
                } else {
                    //console.log('paso por aqui otro')
                    // deshabilitar ready y resetear todos los indices posteriores
                    const newTR = toRender.map((tr, indx )=>{
                        return (
                            indx === idx 
                                ? ({...tr, checked: checkedVal, value: undefined})
                                :   (indx > idx) ? {...tr, show: false, checked: false, value: undefined, ready: false} : tr
                        )
                    })
                    setToRender(newTR)
                }
            } catch(error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        variant:'error',
                        isHtml: true,
                        message: 'errorValidandoResidenciaFiscalIB'
                    }
                })
                setDeclaracionResponsable(curr => ({...curr, residenteIB_validacion: 'error'}))
            } finally{
                setLoading(false);
            }
        })();
    }
    
    const extraerDeclResponsableFronToRender = (p_toRender: TToRendeMap[typeof DOC_GENERA][]) => {
        const newDecl = toRender.reduce( (obj, item) => {
            let out ={...obj, [item.key]: item.checked} 
            if(item.hasValue){

               item.keyValue.map( k => {
                    //console.log( 'has value',k, item.value, item.value[k])
                    let value= item.value  //typeof item.value === 'object' && k !== 'esCausa_valor' ? item.value[k] : item.value 
                    if(k === 'esCausa_valor'){
                        const entries = Object.entries(item.value)
                        value = entries.reduce((prev, curr ) => ({
                            ...prev, 
                            [curr[0]]: !curr[0].includes('_descripcion') && curr[1] === undefined? false: curr[1] 
                        }),{})
                    }

                    out={...out, [k]: value }
               })
                
            }
            //console.log('out ', out)
            return out 
        },{})
        return newDecl
    }

    const handleOnExit = async () => {
        try {
            setLoading(true)
            let newDecl = extraerDeclResponsableFronToRender(toRender)
            const mod = selectedBI ? allBIOptions.find(opt=> opt.id.toString() === selectedBI.id)?.modalidad : null
            await abonoNacimientoGateway.saveStep(
                idRef,
                'declaracion_responsable', {...declarcionResponsable, modalidadSolicitud: mod ? mod===1 ? 'individual': 'conjunta' : null, declResponsable: newDecl  },
                undefined,
                {causa: forceExit.cause ?? 'generic-error', tipoDoc: declarcionResponsable.tipoDocGenera ?? DOC_GENERA }
            )
            
        } catch (error) {
            
        } finally{
            setLoading(false)
            onCancel()
        }
    }

    const handleNext = async () => {
        try {
            setLoading(false)
            // console.log('toRender', toRender)
            const newDecl = extraerDeclResponsableFronToRender(toRender)
            const mod = selectedBI ? allBIOptions.find(opt=> opt.id.toString() === selectedBI.id)?.modalidad : null
            const response = await abonoNacimientoGateway.saveStep(idRef,'declaracion_responsable', {...declarcionResponsable,modalidadSolicitud: mod ? mod===1 ? 'individual': 'conjunta' : null ,declResponsable: newDecl })
            if(response.saved && response.referencia){
                onNext(response.referencia)
            } else{
                if(response.error){
                    alertsDispatch({
                        type:'show-alert',
                        payload: {
                            variant:'error',
                            message: translate('AbonoNacimiento',response.error,terms) + translate('AbonoNacimiento','declaracion_responsable',terms)
                        }
                    })
                } else {throw new Error ('ErrorSaveStep')}
            }
            
        } catch (error) {
            alertsDispatch({
                type:'show-alert',
                payload: {
                    variant:'error',
                    message: translate('AbonoNacimiento','ErrorSaveStep',terms) + translate('AbonoNacimiento','declaracion_responsable',terms)
                }
            })
            
        } finally {
            setLoading(false)
        }
    }
    
    
    // -- Effects  ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                
                //  INICIALIZACIÓN
                if(!idRef ){ throw new Error('not-idRef')}
                // consultar datos BBDD
                const response = tipo === 'solicitud' 
                    ? await abonoNacimientoGateway.getApartadosByRef<'solicitud'>(idRef)
                    : await abonoNacimientoGateway.getApartadosByRef<'reintegro'>(idRef);
                //console.log(idRef,' tipo: ', tipo,'response -- ', response)

                let docGeneraPrev = response.declaracion_responsable ? response.declaracion_responsable.tipoDocGenera: null
                // if(tipo === 'solicitud'){
                //     const info = (response as TSolicitudAbonoNacimiento).datos_identificativos
                //     //console.log(info?.tipoSolicitud === 'anticipado')
                // }
                
                let docGeneraCurr: TTipoDocGenera = tipo === 'solicitud'
                    ?  (response as TSolicitudAbonoNacimiento).datos_identificativos?.tipoSolicitud === 'anticipado' ? 50 : 52
                    :  (response as TSolicitudAbonoNacimiento).datos_identificativos?.tipoSolicitud === 'anticipado' ? 51 : 53 
                const BI: BIOPT[] = await abonoNacimientoGateway.getBiOptionsByDoc(docGeneraCurr)
                //console.log('BI', BI)//'docGeneraPrev', docGeneraPrev,'docGeneraCurr',docGeneraCurr)
                const docGenera = docGeneraPrev!== undefined && docGeneraPrev!== null && docGeneraPrev === docGeneraCurr 
                    ? docGeneraPrev
                    : docGeneraCurr;
                    
                // MSG Error en caso de No residencia fiscal
                const content = await contenidosGateway.getContent( docGenera === 50 ? 'ABO_NAC_50_info_residenciaFisaclIB' : 'ABO_NAC_info_residenciaFisaclIB', {})

                //Comprobamos que tip doc BBDD sea igual al actual tras edición del ususario
                const changeTipoDoc = !docGeneraPrev || (docGeneraPrev && docGeneraPrev === docGeneraCurr) ? false : true;

                // Generar empty object
                const infoCampos =  await abonoNacimientoGateway.getCamposDeclRespByDoc('declaracion_responsable', docGenera, response.datos_identificativos?.modalidadSolicitud);
                const emptyObject= camposByDocToObject(infoCampos.campos) as TDeclaracionResponsable
                // console.log('emptyObject', emptyObject)
                let recoverDecl = null 
                if(response.declaracion_responsable) {
                    recoverDecl = deepUpdate(emptyObject, response.declaracion_responsable) 
                }

                const initVal= ( changeTipoDoc || recoverDecl === null) ? {...emptyObject,tipoDocGenera: docGenera } : recoverDecl;
                const famNum =  /*docGeneraCurr === 50*/ tipo === 'solicitud' && initVal?.declResponsable ?
                    (initVal.declResponsable as TDeclaracionResponsable50).familiaNumerosa_valor ?? false
                    : false
                let idOPT : string | null = null;
                let hijos50 = 0
                let hijos52: TNHProgenitor = { numHijosProgenitor_valor1: undefined, numHijosProgenitor_valor2: undefined}
                if(initVal && docGenera === 50 && !changeTipoDoc){
                   hijos50 =(initVal.declResponsable as TDeclaracionResponsable50).numHijosImpuestoIRPF_valor ?? 0
                   idOPT = (initVal.declResponsable as TDeclaracionResponsable50).baseImponible_valor.baseImponible_valor1 ?? null

                }
                if(initVal && docGenera === 52 && !changeTipoDoc){
                    hijos52 = (initVal.declResponsable as TDeclaracionResponsable52).numHijosProgenitor_valor 
                    idOPT = (initVal.declResponsable as TDeclaracionResponsable52).baseImponible_valor.baseImponible_valor1 ?? null
                }
                
                const select = idOPT ? BI.find(b => b.id.toString() === idOPT):undefined
                if(componentMounted.current){
                    //console.log('initVal ', initVal)
                    setIsFamiliaNum(famNum)
                    setInfoResidenteIB(content[0]?.contenido)
                    setDeclaracionResponsable(initVal)
                    setEmptyDeclResp(emptyObject.declResponsable)
                    setModalidad(response.datos_identificativos?.modalidadSolicitud ?? 'individual')
                    setNifsToCheck({nif1: response.datos_identificativos?.contribuyente?.nif ?? null, nif2: response.datos_identificativos?.progenitor?.nif ?? null})
                    setLiterals(infoCampos.literales)
                    setIsMonoParental(response.datos_identificativos!== undefined && response.datos_identificativos.familiaMonoparental!== undefined && response.datos_identificativos.familiaMonoparental===true)
                    setAllBIOptions(BI)
                    setSelectedBI(select ? {id:select.id.toString(), term: select.term, limiteBI: select.limiteBI} : undefined)
                    setNumHijos_50(hijos50)
                    setNumHijos_52(hijos52)
                    setDocGenera(docGenera)
                }

            } catch(err){
                
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        variant:'error',
                        message: translate('AbonoNacimiento','ErrorLoadingStep',terms) 
                    }
                })
            } finally {
                setLoading(false)
            }
            
        })();

        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
        
    },[idRef, apartado]);

    useEffect(() => {
        // console.log('declarcionResponsable  ' , declarcionResponsable)
        //console.log('toRender',toRender, 'literals', literals)
        const keys = declarcionResponsable.declResponsable !== null ?  Object.keys(declarcionResponsable.declResponsable) : [] 
        //console.log('keys',keys)

        if(toRender.length === 0 && declarcionResponsable.declResponsable !== null && literals.length>0){
            const ARR: TToRendeMap[typeof DOC_GENERA][] = literals.map((lit, idx) => {
                const field=  lit.split('_')[1] as keyof DeclaracionMap[typeof DOC_GENERA]
                const valorToSearch= `${field}_valor` as keyof DeclaracionMap[typeof DOC_GENERA]
                //console.log('valorToSearch ', valorToSearch)
                //const withVal = declarcionResponsable.declResponsable !== null && Object.keys(declarcionResponsable.declResponsable).includes(valor)
                
                const itemsValor = keys.filter(key=> key === valorToSearch) as (keyof DeclaracionMap[typeof DOC_GENERA])[]
               // console.log('itemsValor',valorToSearch, itemsValor)
                const withVal = itemsValor.length > 0 ;
                const valor = declarcionResponsable.declResponsable 
                    ?  (itemsValor.length > 1 
                        ? itemsValor.reduce((prev,curr) => ({...prev, [curr]: declarcionResponsable.declResponsable ? declarcionResponsable.declResponsable[curr] : undefined }) ,{}) 
                        : declarcionResponsable.declResponsable[itemsValor[0]]
                    )
                    : undefined;
                const ready = declarcionResponsable.declResponsable !== null && declarcionResponsable.declResponsable[field] === true && 
                        ( withVal===false || (withVal && declarcionResponsable.declResponsable!== null && valor !== undefined) )
                const show= idx === 0  || (declarcionResponsable.declResponsable!==null && (
                    declarcionResponsable.declResponsable[field] === true || (declarcionResponsable.declResponsable[field] === false && ready)
                ))
                const term = (DOC_GENERA === 52 && modalidad==='conjunta' && lit === '52_numHijosProgenitor') 
                                ? lit+'_conjunta' 
                                : lit ; 
                const more_info = withMoreInfo.includes(term) ? {template:`ABO_NAC_${term}_info`, size: "lg" as TInfoTemplateSize} : undefined
                const more_info_value = withMoreInfo_inValue.includes(lit) ? {template:`ABO_NAC_${lit}_info`, size: "lg" as TInfoTemplateSize} : undefined

                //console.log(withVal, itemsValor, valor)
                //console.log(declarcionResponsable.declResponsable!==null && declarcionResponsable.declResponsable[field] )

                return (
                    {  
                        label: term,
                        key: field, 
                        checked: declarcionResponsable.declResponsable!==null && declarcionResponsable.declResponsable[field],// && ready , 
                        show: show,  
                        hasValue: withVal,
                        keyValue: itemsValor, 
                        value: valor,
                        ready: ready,
                        moreInfo: more_info,
                        moreInfoValue: more_info_value
                    }
                )
            })
            setToRender(ARR)
            //console.log('TO RENDER', ARR)

        }
    },[declarcionResponsable, literals])

  
    return (
 
        <GenericStepSchema
            isFirst={isFirst}
            isLast={isLast}
            onNext={handleNext}
            disabledNext={disableNEXT}
            onBack={onBack}
            disabledBack={false}
            onExit={handleOnExit}
            forceExit={forceExit.force} 
            idRef={idRef} 
            allowContinuarMasTarde={false}
            withRecoverRefContinuarMasTardeModal={false}
            withContinuarMasTardeModal={false}
        >
           
            <FullLoading loading={loading} msg={msgLoading}/>
            {/**Componente  */}
            {/** Abono por Nacimiento */}
            {apartado.includes('ABONAC') && EMPTY_DECL_RESP &&
                <Grid container direction="column" alignItems="flex-start" justify="flex-start">
                    <Typography className={[classes.fullWidth, classes.row, classes.marginTitle].join(' ')}>
                        {translate('AbonoNacimiento','ABO_NAC_decl_resp_title', terms)}
                    </Typography>
                    {toRender.map( (item, idx) => {
                        //console.log('item to render - ', item)
                        return (
                            <Grid item 
                                style={{
                                    //minHeight:45, 
                                    marginBottom: 11,
                                    visibility: item.show === true ? 'visible' : 'hidden'
                                }}
                            >
                                <GenericCheckbox key={item.key}
                                    styles={{width: '100%'}}
                                    componentIG="AbonoNacimiento"
                                    labelTerm={item.label}
                                    value={item.checked === true}
                                    onChangeValue= {(v:boolean) => {
                                        const updateKey = item.key as keyof DeclaracionMap[typeof DOC_GENERA];
                                        //const resetVal = item.key === 'esCausa' ? EMPTY_DECL_RESP[51]['esCausa_valor'] : undefined
                                        updateToRender<typeof DOC_GENERA>(updateKey, idx, v, undefined, false)}
                                    }
                                    moreInfo={item.moreInfo}
                                />
                                {item.hasValue ?
                                    <div className={classes.row} style={{marginLeft: 32}}>
                                        {item.keyValue.map( keyValue => (
                                            <>
                                                { keyValue=== 'numHijosImpuestoIRPF_valor' ?
                                                    <FixStepInputNumber 
                                                        disabled={!item.checked}
                                                        required
                                                        componentIG="AbonoNacimiento" 
                                                        labelTerm={keyValue}
                                                        value={typeof item.value !== 'undefined' ? Number(item.value): undefined}
                                                        onChangeValue={(v: number| undefined, err:boolean) => {
                                                            const updateKeyValue = keyValue as keyof DeclaracionMap[typeof DOC_GENERA];
                                                            updateToRender(updateKeyValue, idx, item.checked, v, err)
                                                            setNumHijos_50(v ?? 0)
                                                        }}
                                                        error={ !item.checked ? false : undefined}
                                                        min={1}
                                                        max={15}
                                                        step={1}
                                                        className={[classes.input, classes.input260].join(' ')}
                                                        moreInfo={item.moreInfoValue}
                                                    />
                                                    : null 
                                                }
                                                { keyValue === 'importeIRPF_valor' ?
                                                    <GenericTypedInputNumber
                                                        type='money' 
                                                        disabled={!item.checked}
                                                        required={true}
                                                        componentIG="AbonoNacimiento" 
                                                        labelTerm={keyValue}
                                                        value={typeof item.value !== 'undefined' ? Number(item.value): undefined}
                                                        onChangeValue={(v: number| undefined, err) => {
                                                            const updateKeyValue = keyValue as keyof DeclaracionMap[typeof DOC_GENERA];
                                                            updateToRender(updateKeyValue , idx, item.checked, v, err)
                                                        }}
                                                        //error={ !item.checked ? false : undefined}
                                                        allowZero
                                                        className={classes.input}
                                                        moreInfo={item.moreInfoValue}
                                                    />
                                                    : null
                                                }    
                                                { keyValue.includes('IBAN') ? 
                                                    <GenericInputText
                                                        disabled={!item.checked}
                                                        required
                                                        componentIG="AbonoNacimiento" 
                                                        labelTerm={keyValue }
                                                        value={item.value?.toString()}
                                                        onChangeValue={(v: string| undefined, err:boolean) => {
                                                            const updateKeyValue = keyValue as keyof DeclaracionMap[typeof DOC_GENERA];
                                                            updateToRender(updateKeyValue, idx, item.checked, v, err)
                                                        }}
                                                        error={ !item.checked ? false : undefined}
                                                        className={[classes.input, classes.input360].join(' ')}
                                                        extraValidation={() => item.value && validateIBAN(item.value.toString())}
                                                    /> : null
                                                } 
                                                {/*  No aplica TRAS REUNIÓN 09/07/2024 
                                                 {keyValue === 'numProgenitores_valor' ?
                                                    <GenericRadioButton key={keyValue}
                                                        disabled={!item.checked}
                                                        componentIG='AbonoNacimiento' 
                                                        labelTerm={''} 
                                                        withLabel={false}
                                                        optionsInRow={true}
                                                        value={item.value}
                                                        options={numProgenitores_options.filter( item => numProgOpt.includes(item.id))}
                                                        onChangeValue={(v: string | undefined, err:boolean) => {
                                                            const updateKeyValue = keyValue as keyof DeclaracionMap[typeof DOC_GENERA];
                                                            updateToRender(updateKeyValue, idx, item.checked, v,err)
                                                        }}
                                                    />
                                                    : null
                                                }  */}
                                                {keyValue === 'familiaNumerosa_valor'
                                                    ? <GenericBooleanRadioButton key={keyValue}
                                                        disabled={!item.checked}
                                                        componentIG='AbonoNacimiento' 
                                                        labelTerm={''} 
                                                        withLabel={false}
                                                        optionsInRow={true}
                                                        value={item.value}
                                                        options={BOOL_OPTIONS}
                                                        onChangeValue={(v: boolean, err:boolean) => {
                                                            const updateKeyValue = keyValue as keyof DeclaracionMap[typeof DOC_GENERA];
                                                            setIsFamiliaNum(v)
                                                            updateToRender(updateKeyValue, idx, item.checked, v,err)
                                                        }}
                                                        moreInfo={item.moreInfoValue}
                                                    />
                                                    :null
                                                }
                                                {keyValue === 'esCausa_valor' ?
                                                    <EsCausaValor
                                                        disabled={!item.checked}
                                                        value={item.value 
                                                            ? item.value 
                                                            : EMPTY_DECL_RESP  
                                                                ? EMPTY_DECL_RESP['esCausa_valor' as keyof DeclaracionMap[typeof DOC_GENERA]] 
                                                                : undefined 
                                                                /*?? (DOC_GENERA === 51 ? EMPTY_DECL_RESP[51]['esCausa_valor']: EMPTY_DECL_RESP[53]['esCausa_valor'] )*/} 
                                                        onChangeValue={ (v: TCausaValor | TCausaValor2, err:boolean) =>{ 
                                                            //console.log('update causaValor: ', v, err )
                                                            const updateKeyValue = keyValue as keyof DeclaracionMap[typeof DOC_GENERA];
                                                            updateToRender(updateKeyValue, idx, item.checked, v, err)}
                                                        }
                                                    />
                                                    : null
                                                }  
                                                {keyValue === 'baseImponible_valor' ?
                                                    <div style={{marginTop: 10}}>
                                                        <GenericRadioButton key={keyValue}
                                                            disabled={!item.checked}
                                                            componentIG='AbonoNacimiento' 
                                                            labelTerm='' 
                                                            withLabel={false}
                                                            optionsInRow={false}
                                                            value={typeof item.value !== 'undefined' ? item.value['baseImponible_valor1'] : undefined}
                                                            options={baseImponible_options}//baseImponible_options}
                                                            onChangeValue={(v: string | undefined, err:boolean) => {
                                                                const updateKeyValue = keyValue as keyof DeclaracionMap[typeof DOC_GENERA];
                                                                const optSelected = baseImponible_options.find( opt => opt.id === v)
                                                                setSelectedBI(optSelected)
                                                               
                                                                let newV= typeof item.value !== 'undefined'  
                                                                    ? {...item.value,
                                                                        baseImponible_valor1: v, // valor del radio button
                                                                        baseImponible_valor2: optSelected?.limiteBI === 0 ? null : undefined
                                                                    } 
                                                                    : {...(EMPTY_DECL_RESP as DeclaracionMap[50])['baseImponible_valor'],
                                                                        baseImponible_valor1: v, // valor del radio button
                                                                        baseImponible_valor2: optSelected?.limiteBI === 0 ? null : undefined
                                                                    } 
                                                                updateToRender(updateKeyValue, idx, item.checked, newV ,err)
                                                            }}
                                                        />
                                                        { item.value && item.value['baseImponible_valor1'] !== undefined && selectedBI && selectedBI.limiteBI>0 ?
                                                            <GenericTypedInputNumber
                                                                type='money' 
                                                                disabled={!item.checked}
                                                                required={true}
                                                                componentIG="AbonoNacimiento" 
                                                                labelTerm={'baseImponible'}
                                                                value={item.value['baseImponible_valor2']} //typeof item.value !== 'undefined' && item.value['baseImponible_valor2'] ? Number(item.value['baseImponible_valor2']): undefined}
                                                                onChangeValue={(v: number| undefined, err) => {
                                                                    const updateKeyValue = keyValue as keyof DeclaracionMap[typeof DOC_GENERA];
                                                                    let newV= typeof item.value !== 'undefined'  ? {...item.value} : (EMPTY_DECL_RESP as DeclaracionMap[50])['baseImponible_valor']
                                                                    newV={
                                                                        ...newV,
                                                                        baseImponible_valor2: v // valor base imponible
                                                                    }
                                                                    updateToRender(updateKeyValue, idx, item.checked, newV ,err)
                                                                }}
                                                                extraValidation={ (v:number|undefined) => {
                                                                    const isOK = selectedBI && v && selectedBI.limiteBI < v ? false : true
                                                                    setForceExit({force: !isOK, cause: 'limite-BI' })
                                                                    return ({ error: isOK ? false :true, error_msg: isOK ? undefined: 'limite_base_imponible', componentIG:'AbonoNacimiento' })
                                                                }}
                                                               
                                                                allowZero
                                                                className={classes.input}
                                                                moreInfo={item.moreInfoValue}
                                                               
                                                            />
                                                            : null
                                                        }
                                                    </div>
                                                    : null
                                                }
                                                {keyValue === 'numHijosProgenitor_valor' ?
                                                <div style={{marginTop: 10, display:'inline-flex'}}>
                                                    {NUMPROG52.map((key) =>{ 
                                                        return(
                                                            <FixStepInputNumber 
                                                                key={key}
                                                                disabled={!item.checked || (key.includes('valor2') && isMonoParental)}
                                                                required
                                                                componentIG="AbonoNacimiento" 
                                                                labelTerm={NUMPROG52.length>1 ?  key : 'numHijos'}
                                                                value={typeof item.value !== 'undefined' && item.value[key] !== undefined ? Number(item.value[key]): undefined}
                                                                onChangeValue={(v: number| undefined, err:boolean) => {
                                                                    const updateKeyValue = keyValue as keyof DeclaracionMap[typeof DOC_GENERA];
                                                                    let newV= typeof item.value !== 'undefined'  ? {...item.value} : EMPTY_DECL_RESP[updateKeyValue]
                                                                    newV =  {...newV, [key]: v};
                                                                    // console.log(key, newV)
                                                                    updateToRender(updateKeyValue, idx, item.checked, newV, err)
                                                                    setNumHijos_52(newV)
                                                                }}
                                                                error={ !item.checked ? false : undefined}
                                                                min={1}
                                                                max={15}
                                                                step={1}
                                                                className={[classes.input, classes.input260].join(' ')}
                                                                moreInfo={item.moreInfoValue}
                                                            />
                                                    )}
                                                    )}
                                                  
                                                    

                                                    
                                                </div>
                                            : null
                                        }
                                            </>
                                             
                                        ))}
                                         
                                        
                                        

                                        

                                        
                                    </div>
                                : null
                                }
                            </Grid>
                        )
                    })}

                </Grid>
            }
          
        </GenericStepSchema>
      

    )
}



const styles = (theme: Theme): StyleRules => ({
    fullWidth: {
        width: "100%"
    },
    row: {
        display: 'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    marginTitle: {
        marginBottom: 25
    },
    alignTop:{
        alignItems: 'flex-start'
    },
    alignBottom:{
        alignItems: 'flex-end'
    },
    inputInLine: {
        padding:0, marginTop: 0
    },
    input: {
        marginTop: 15   
    },
    input360: {
        width:360
    },
    input260: {
        width:260
    },
    hidden: {
        display: 'none'
    }

});

export default withLiterals(['Global', 'Tramites','AbonoNacimiento' ])(withStyles(styles)(TypedDeclaracionResponsable))

