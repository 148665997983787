// LIBRARY IMPORTS
import { FC } from 'react';
import { makeStyles, useTheme, DialogContentText } from '@material-ui/core';
// LOCAL IMPORTS
import { GenericModal } from 'components/generic-modal';
// STYLES IMPORTS
import styles from './styles';

const useStyles = makeStyles(styles);

interface IDialogInfoDomiciliacionTodosRecibos {
    open: boolean;
    body: string;

    onClose: () => void;
}

const DialogInfoDomiciliacionTodosRecibos: FC<IDialogInfoDomiciliacionTodosRecibos> = (props) => {
    const { open, body, onClose } = props;
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <GenericModal
            open={open}
            title="Dialog información domicilaición todos los recibos"
            componentTitleTranslation='Domiciliaciones'
            onClose={onClose}
        >
            <DialogContentText className={[classes.padding, classes.marginDefault].join(' ')}>
                <div dangerouslySetInnerHTML={{ __html: body }} />
            </DialogContentText>
        </GenericModal>
    );
}

export { DialogInfoDomiciliacionTodosRecibos };