import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { BLUE } from "containers/shared/colors";

export const duti_colors = {
   blueIconInfo: '#004f82', //10%, #dee2e6 ,
   white: '#FFF',
   blueBtn: BLUE,
}
const duti_styles =  (theme: Theme): StyleRules => ({
    root: {
        // flex: 1,
        display:'flex',
        flexGrow: 1,
        marginRight: 15,
        marginLeft: 15,
        padding: 10
    },    
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    componenteContainer:{
        height: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    inmuebleContainer: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },
    nextAction: {display: 'flex',  alignItems: 'center', justifyContent:'flex-end', height: 40},
    backNextActions: {display: 'flex', alignItems: 'center', justifyContent:'space-between', height: 40},
    btnCancelDusti: {
        color:'white',
        backgroundColor:'#ef1111',
        '&:hover': {
            backgroundColor:'#af0909',
        }
    },
    textCancelDusti: {
        paddingTop: 30,
        paddingLeft: 10,
        paddingBottom: 30,
        paddingRight: 30,
        border:'solid',
        borderColor: '#ef1111',
        borderRadius: 10, 
        borderWidth: 2, fontWeight: 450,
        marginTop: 20,
        marginBottom:20,
        display:'inline-flex', 
        alignContent:'flex-start',
        alignItems: 'center'
    },
    textWarningAsCancelDusti:{
        paddingTop: 10,
        paddingLeft: 10,
        paddingBottom: 10,
        paddingRight: 30,
        border:'solid',
        borderColor: BLUE,
        borderRadius: 10, 
        borderWidth: 2, fontWeight: 450,
        marginTop: 10,
        marginBottom:10,
        display:'inline-flex', 
        alignContent:'flex-start',
        alignItems: 'center'
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxHeight: 'calc(100% - 50px)', //50px-> footercolumn
        flex:1,
        overflowY: 'auto',
        paddingRight: 20, paddingLeft: 20
    },
    footerColumnContainer: {
        height: '50px',
        borderTop:'solid',
        borderTopWidth: 1,
        borderColor: '#d3d3d3',
        paddingTop: 10,
        paddingRight: 20, paddingLeft: 20
    },
    radioBtn_Field: {
        fontWeight: 'bold'
    },
    input_small_dense:{
        height: 46,
        selfAlign: 'flex-start',
        marginTop: 2
    },
    icon_btn: {
        backgroundColor: duti_colors.blueBtn,
        borderRadius: 5,
        padding: 10,        
    },
    inputSearch_btn: {
        //backgroundColor: duti_colors.blueBtn,
        //borderRadius: 5,
        marginLeft:5,
        minWidth: 45,
        padding: 10,  
        marginTop: 2      
    },
    inputLabel: {
        backgroundColor: duti_colors.white,
        paddingRight: 5,
        paddingLeft:5
    },
    input70: { // Small: puerta, esc
        width: 70
    },
    input100: { // Small: puerta, esc
        width: 100
    },
    input160: { // 
        width: 160
    },
    input200: { // 
        width: 200
    },
    input285: { // 
        width: 285
    },
    input360: { // mediano: nombre
        width: 360
    },
    input545: {
        width: 545
    },
    inputDefault: { // mediano: nombre
        width: 260
    },
    input300: { // mediano: nombre
        width: 300
    },
    inputFullWidth: { // Small: puerta, esc
        width: '100%'
    },
    formularioComprVendContainer: {
        display:'flex', flexDirection: 'column',
        paddingTop: 10, paddingBottom: 10,
        paddingRight: 20, paddingLeft:10,
        // border:'solid',
        // borderWidth: '1px',
        // borderRadius: 10
    },
    borderPlusval: {
        border:'solid',
        borderWidth: '1px',
        borderRadius: 10,
        borderColor: BLUE,
        padding: '20px 10px 10px 10px',
        marginBottom: 10,
        position: 'relative'
    },
    divTextBorderPlusval: {
        display: 'flex',        /* Activa Flexbox */
        justifyContent: 'flex-end', /* Alinea el contenido al final (derecha) */
        width: '100%',         /* Asegura que ocupe todo el ancho del Grid */
        position: 'absolute',   /* Posicionamiento absoluto respecto a su contenedor relativo */
        right: 20,            /* Alinea a la derecha */
        top: -20,              /* Alinea al borde superior */
    },
    textBorderPlusval: {
        padding: 10,
        color: BLUE,
        backgroundColor: 'white'
    },
    borderPlusvalDisabled: {
        border:'solid',
        borderWidth: '1px',
        borderRadius: 10,
        borderColor: '#e0eaf4',
        padding: '20px 10px 10px 10px',
        marginBottom: 10,
        position: 'relative'
    },
    divTextBorderPlusvalDisabled: {
        display: 'flex',        /* Activa Flexbox */
        justifyContent: 'flex-end', /* Alinea el contenido al final (derecha) */
        width: '100%',         /* Asegura que ocupe todo el ancho del Grid */
        position: 'absolute',   /* Posicionamiento absoluto respecto a su contenedor relativo */
        right: 20,            /* Alinea a la derecha */
        top: -20,              /* Alinea al borde superior */
    },
    textBorderPlusvalDisabled: {
        padding: 10,
        color: '#e0eaf4',
        backgroundColor: 'white'
    },
    rowPlusvalDetail: {
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        alignContent: 'flex-end',
    },
    plusvalDetail_title: {
        width: '70%', fontWeight:'bolder'
    },
    plusvalDetail_value: {
        marginLeft: 10, width: '30%', 
    },
    // Consulta --------------------------------------------------------------------------------------------------------
    rootContainer: {
        display:'flex',
        //width: '100%',
        flex: 1,
        flexGrow: 1,
        margin: '0 0.5rem'
    },
    cardContadorContainer: {
        padding: 10,
        display: 'flex',
        alignItems: 'center',
    },
    cardContador: {
        padding: '10px !important'
    },
    titleCardContador: {
        fontWeight: 300,
        fontSize: 16,
        marginLeft: '1rem',
        color: '#406a8d',
        flex: 1
    },
    borderM600: {
        border:'solid',
        borderWidth: '1px',
        borderRadius: 5,
        borderColor: duti_colors.blueIconInfo,
        padding: '5px 5px 5px 25px',
        height: 45, 
        marginTop: 4,
        display:'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minWidth: 285
    },
    //--- Justificantes
    btnContainer: {textAlign: 'right', width: 140, marginLeft: 10},
    rowJustif: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom:10,
    },
    
    btnTramites:{
        width: 150, 
        display:'flex',
        alignItems:'center',
        justifyContent: 'flex-end'
    },
    logoTramites:{
        width: 200, 
        objectFit: 'scale-down',
        paddingRight: 20
    },
    grow: { // Eliminar tamaño logo (logoTramites) y boton (btnTramites)
        display: 'flex',
        flexGrow: 1,
        maxWidth: 'calc(100% - 350px)'
    },
    grow2: { // Eliminar tamaño boton (btnTramites)
        display: 'flex',
        flexGrow: 1,
        maxWidth: 'calc(100% - 150px)'
    },
    grow3: { // Eliminar tamaño logo (logoTramites)
        display: 'flex',
        flexGrow: 1,
        maxWidth: 'calc(100% - 200px)'
    },
    sectionJustif:{
        // display: 'flex',
        // flexDirection: 'row',
        // alignItems: 'center',
        // minHeight: 70, 
        paddingTop: 10,
        paddingBottom:10,
        borderBottom: 'solid', 
        borderBottomWidth:1, 
        borderBottomColor:'#d3d3d3'
    },
    sectionJustifNoMargin: {
        borderBottom: 'solid', 
        borderBottomWidth:1, 
        borderBottomColor:'#d3d3d3'
    },
    
    // -----------------------------------------------------------------------------------------------------------------
    centerContent: {
        textAlign: 'center',
    },
    // STYLES STEPPER
    rootStepper: {
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection:'column',
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    iconBox: {
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconBoxJustify: {
        zIndex: 1,
        color: '#fff',
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    activeIconBox: {
        backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

    },
    nonActiveIconBox: {
        backgroundColor: 'lightgrey',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completedIconBox: {
        backgroundImage: 'linear-gradient(to right, rgba(45,178,190,1),rgba(168,220,249,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    stepperContainer: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#e0eaf4',
            width: '100%',
            height: 207,
            position: 'fixed',
            zIndex: 1,
            top: 55
        }
    },
    stepperCard: {
        display: 'contents',
        width: 'calc(100% - 330px)',
        position: 'fixed',
        left: 0,
        right: 0,
        margin: 'auto',
        marginRight: 15,
        [theme.breakpoints.down('sm')]: {            
            width: 'calc(100% - 30px)',
            height: 158,
            zIndex: 1,
            marginTop: 35,
            marginLeft: 15,
            left: 'unset',
            right: 'unset'
        }
    },
    stepsStepperCard: {
        margin: '0px 0px 20px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: 'unset',
            paddingTop: 0
        }
    },
    boldText: {
        color: 'black',
        fontWeight: 'bolder',
    },
    lightText: {
        fontWeight: 'lighter',
        color: 'grey',
    },
    iconoImage: {
        width: 400,
        height: 169,
        [theme.breakpoints.down('md')]: {
            iconoImage: {
                width: 60,
                height: 60
            },
        },
    },
    btnPay: {
        backgroundColor: 'white',
        color: BLUE,
    },
    logoAEAT: {
        width: 150,
    }
})

export default duti_styles