import { IAccesoModulo } from "gateways/auth.interfaces";
import { authStorage } from "storages";

export const tryReadStorage = (key: string, storage: Storage = authStorage) => {
    try {
        const data = storage.getItem(key);
        if (data !== null) {
            return JSON.parse(data);
        }
        return null;
    } catch (e) {
        return null;
    }
};

export const tryReadStorageAccesoModulo = (key: string, storage: Storage): IAccesoModulo[] => {
    try {
        const data = storage.getItem(key);
        if (data !== null) {
            return JSON.parse(data);
        }
        return [];
    } catch (e) {
        return [];
    }
};