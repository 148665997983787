import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IJwtResponse, IJwtRefreshResponse, IUserStatus, IAuthenticationRequestResult, IUserParameters, IUserInfo, IAccesoModulo } from "./auth.interfaces";
import { createValidationError } from "utils/response";
import * as Sentry from '@sentry/browser';

@inject('axios')
export class AuthGateway {

    constructor(
        private axios: AxiosInstance
    ) { }

    public async signIn(nif: string, password: string, mode: 'PASSWORD' | 'CODE'): Promise<IJwtResponse> {
        try {
            const response = await this.axios.post('/auth/codigo-contribuyente', {
                nif,
                password,
                mode
            });

            return response.data;
        } catch (e) {
            Sentry.captureException(e);
            throw createValidationError(e as any);
        }
    }

    public async refreshToken(currentJwt: string, language?: string): Promise<IJwtRefreshResponse> {
        try {
            if (currentJwt) {
                const response = await this.axios.post('/auth/refresh-token', {
                    accessToken: currentJwt,
                    newClaims: {
                        idioma: language
                    },
                }, {
                    headers: {
                        'X-With-Refresh': '1'
                    },
                });
                return response.data;
            }

            const empty: IJwtRefreshResponse = { accessToken: '' };

            return empty;
        } catch (e) {
            Sentry.captureException(e);
            throw createValidationError(e as any);
        }
    }

    public async refreshTokenApoderado(currentJwt: string): Promise<IJwtRefreshResponse> {
        try {
            if (currentJwt) {
                const response = await this.axios.post('/auth/refresh-token-apoderado', {
                    accessToken: currentJwt,                    
                }, {
                    headers: {
                        'X-With-Refresh': '1'
                    },
                });
                return response.data;
            }

            const empty: IJwtRefreshResponse = { accessToken: '' };

            return empty;
        } catch (e) {
            Sentry.captureException(e);
            throw createValidationError(e as any);
        }
    }

    public async refreshTokenRepresentado(nif: string, procedimientos: string[]): Promise<IJwtRefreshResponse> {
        try {

            const response = await this.axios.get(`/auth/refresh-token-representado?nifRepresentado=${nif}&procedimientos=${procedimientos.join(',')}`);
            return response.data;

        } catch (e) {
            Sentry.captureException(e);
            throw createValidationError(e as any);
        }
    }

    public async actuarNombrePropio(): Promise<IJwtRefreshResponse> {
        try {

            const response = await this.axios.get(`/auth/refresh-token-representate`);
            return response.data;

        } catch (e) {
            Sentry.captureException(e);
            throw createValidationError(e as any);
        }
    }


    public async expireToken(token: string) {
        try {
            await this.axios.post(
                'auth/expirar-token', {
                token
            }
            );
        } catch (e) {
            Sentry.captureException(e);
            throw createValidationError(e as any);
        }
    }

    public async getUserStatus(nif: string): Promise<IUserStatus> {
        try {
            const response = await this.axios.get('/auth/sujeto-estado/' + nif);
            return response.data;
        } catch (e) {
            Sentry.captureException(e);
            throw createValidationError(e as any);
        }
    }

    public async changePasswordNoLogged(nif: string, token: string, password: string): Promise<{ accessToken: string }> {
        try {
            const response = await this.axios.post(
                'auth/change-password', {
                nif,
                token,
                password
            }
            );
            return response.data;
        } catch (error) {
            Sentry.captureException(error);
            throw createValidationError(error as any);
        }
    }

    public async getAuthenticationRequest(returnUrl: string, origen: string, token: string): Promise<IAuthenticationRequestResult> {
        try {
            const response = await this.axios.get('/auth/authentication-request?returnUrl=' + encodeURIComponent(returnUrl) + '&origen=' + origen + '&token=' + encodeURIComponent(token));
            return response.data;
        } catch (e) {
            Sentry.captureException(e);
            throw createValidationError(e as any);
        }
    }

    public async getInfoUsuario(): Promise<IUserInfo> {
        const response = await this.axios.get('/auth/getInfoUsuario');

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async guardarSesion(params: IUserParameters): Promise<boolean> {
        const response = await this.axios.post('auth/guardar-sesion', params);

        if (response.status !== 201) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getAccesoModulos(): Promise<IAccesoModulo> {
        try {
            const response = await this.axios.get('/auth/acceso-modulos/');
            return response.data;
        } catch (e) {
            Sentry.captureException(e);
            throw createValidationError(e as any);
        }
    }
}
