// LIBRARY IMPORTS
import { useContext, useEffect, useMemo, useState } from "react";
// LOCAL IMPORTS
import { IDatosIban, ITributo } from "gateways/tributos.interfaces";
import { IDomiVoluntaria } from "gateways/datos.domiciliacion.interfaces";
import { TributosGateway } from "gateways/tributos.gateway";
import { getAllDatesByYearMonths } from "utils/all-dates-by-months";
import { sanitizeCalendarProps } from "utils/sanitize-calendar-props";
import { IDiasCortesia } from "gateways/notificaciones.interfaces";
import IoC from "contexts/ioc.context";

interface IProps {
    tributos: ITributo[];
    detalleDomiciliacion?: IDatosIban | null;
}

const useLoadDiasVoluntaria = (props: IProps) => {
    const { tributos, detalleDomiciliacion } = props;

    // SERVICES
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(TributosGateway) as TributosGateway, [ioc]);

    // STATES
    const [diasVoluntaria, setDiasVoluntaria] = useState<IDiasCortesia>();
    const [availableYearMonth, setAvailableYearMonth] = useState<{ year: number, months: number[] }[]>([]);
    const [isLoadingDiasVoluntaria, setIsLoadingDiasVoluntaria] = useState<boolean>(false);



    // -------- USE EFFECT --------

    useEffect(() => {
        if (tributos.length < 1) return;
        (async () => {
            setIsLoadingDiasVoluntaria(true);

            if (!tributos[0] || !tributos[0].domiciliacion) return;
            // Creamos array donde incluiremos los meses disponibles para la domiciliación voluntaria y obtenemos las fechas para la domi. vol. y el idSujeto
            let availableYearMonth: { year: number, months: number[] }[] = [];

            const fechaDomiciliacionIncompatible: string | null = await gateway.getFechaDomiciliacionIncompatible(tributos[0].organismo.codigo);
            const result: IDomiVoluntaria | boolean = await gateway.getFechasVoluntaria(tributos[0].idRecibo, handleIdDomi() );
            if (typeof result === "boolean") return;

            // Obtenemos los años y meses en los cuales se puede realizar la domiciliación voluntaria, porque ?
            /**
            * La llamada de la cual obtenemos las fechas únicamente nos devuelve las fechas en las cuales se puede realizar la domi. vol.
            * pero no las fechas deshabilitadas, de este modo obtendremos las fechas en las cuales se puede domiciliar voluntariamente y las fechas
            * en las cuales está deshabilitada la domiciliación voluntaria
            */
            for (let dia of result.fechasVoluntaria) {
                let availableYear = availableYearMonth.find(({ year }) => year === new Date(dia).getFullYear());

                if (!availableYear) availableYearMonth.push({ year: new Date(dia).getFullYear(), months: [new Date(dia).getMonth()] });

                else if (!availableYear.months.includes(new Date(dia).getMonth())) availableYear.months.push(new Date(dia).getMonth());
            }

            // Una vez tenemos los meses por año en los cuales se puede realizar la domi. vol. obtendremos los días correspondientes a cada mes
            const daysArray: string[] = getAllDatesByYearMonths(availableYearMonth);

            // Seteamos los días que son seleccionables que nos han llegado de BD y los días que no figuran en el json de DB los ponemos como `selecionable: false`
            let voluntaria: IDiasCortesia = sanitizeCalendarProps(result, daysArray, fechaDomiciliacionIncompatible, 1);

            setDiasVoluntaria(voluntaria);
            setAvailableYearMonth(availableYearMonth);
            // TODO: mock para mostrar 9 meses de un año mediante el uso del componente <Calendar />
            // setAvailableYearMonth([{year: 2024, months: [3, 4, 5, 6, 7, 8 , 9, 10, 11]}]);

            setIsLoadingDiasVoluntaria(false);
        })();
    }, []);



    // -------- HANDLERS --------

    const onChangeDiasVoluntaria = (diasVoluntariaModifed: IDiasCortesia) => {
        if (!diasVoluntaria || diasVoluntaria.dias.length < 1) return;

        let diasVoluntariaCopy = { ...diasVoluntaria };
        diasVoluntariaCopy.dias = diasVoluntariaModifed.dias;
        setDiasVoluntaria(diasVoluntariaCopy);
    }

    const onUnselectAllDiasVoluntaria = () => {
        if (!diasVoluntaria || diasVoluntaria.dias.length < 1) return;

        let diasVoluntariaCopy = { ...diasVoluntaria };
        diasVoluntariaCopy.dias = diasVoluntaria.dias.map(dia => ({ ...dia, seleccionada: false }));

        setDiasVoluntaria(diasVoluntariaCopy);
    };

    const handleIdDomi = () => {
        if (detalleDomiciliacion) return detalleDomiciliacion.idDomiciliacion;
        else if (tributos.length > 0 && tributos[0].domiciliacion) return tributos[0].domiciliacion.idDomiciliacion;
        else return null;
    }



    // -------- RETURN SECTION --------

    return {
        diasVoluntaria,
        availableYearMonth,
        isLoadingDiasVoluntaria,
        onChangeDiasVoluntaria,
        onUnselectAllDiasVoluntaria
    }
}

export default useLoadDiasVoluntaria;
