import React, { FC, useContext, useState } from "react";
import { Button, Card, IconButton, Paper, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { mdiChevronDown, mdiChevronRight } from "@mdi/js";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import Icon from "@mdi/react";
import { translate } from "utils/i18n";
import DeleteIcon from "@material-ui/icons/Delete";
import styles from "./styles";




interface IGenericExpandPanelProps extends WithStyles<typeof styles> {
    renderHeader: () => JSX.Element,
    renderContent: () => JSX.Element,
    withDelete?: boolean,
    onDelete?: () => void
    color?: string,
    expandable?: boolean
    initialPosition?: 'expanded' | 'collapse'
}



const GenericExpandPanel: FC<IGenericExpandPanelProps> = ({classes, renderHeader,renderContent, color ='#006993', withDelete=false, onDelete , expandable=true, initialPosition='collapse'}) => {
    const terms = useContext(LiteralsContext);
    const [expand, setExpand] = useState(initialPosition === 'expanded');

    const toggleExpand =() => {
        expandable && setExpand(curr => !curr)
    }
    return (
        <div className={classes.pcBlueRim} style={{
            border: `1px solid ${color}`, 
        }}>
            <div
                onClick={toggleExpand}
                style= {{
                    height: 45,
                    backgroundColor: expand ? color: 'white',
                    borderRadius: expand ? undefined: 5,
                    color: expand ? 'white' : color ?? 'black',
                    display: 'flex', flexDirection:'row', alignItems:'center', justifyItems: 'space-between'
                }}
                className={[classes.text, classes.pcBlueRimTitle].join(' ')}
            >
                <div style={{ display: 'flex', flexGrow: 1}}>
                    {renderHeader()}
                </div>
                <div >
                    {withDelete && onDelete && 
                        <Button variant="text"
                            endIcon={<DeleteIcon/>}
                            style={{
                                color: expand ? 'white': color,
                                borderColor: expand ? 'white': color,
                                marginRight: 20
                            }}
                            onClick={(e) => { 
                                e.preventDefault(); 
                                onDelete()
                            }}
                        >
                        {translate('GenericComponents','btn_delete',terms)}
                    </Button>}
                    {expandable 
                        ? expand 
                            ?  <Icon path={mdiChevronDown} title="expandir" size={1} className={classes.icon} color={'white'}/>
                            :  <Icon path={mdiChevronRight}title="contraer" size={1} className={classes.icon} color={color ?? 'black'}/>
                        : null
                    }
                </div>
                

                                 
                
                
            </div>
            { expand && 
                <div className={classes.pac_detail}>
                   {renderContent()}
                </div>
            
            }
            
        </div>
    )
}
export default withLiterals(ALL_LITERALS)(withStyles(styles)(GenericExpandPanel))
