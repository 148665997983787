import { useState, useEffect, FC } from 'react';
import styled, { css } from 'styled-components';
import { IDiaCortesiaDetalle } from 'gateways/notificaciones.interfaces';
import Term from 'components/term';
import { BLUE } from './colors';
import { dateFormat } from 'utils/dates';

const Frame = styled.div<IFrameProps>`
  width: 270px;
  border: 1px solid lightgrey;
  box-shadow: 2px 2px 2px #eee;

  ${(props: IFrameProps) =>
    props.isSmall &&
    css`
        width: 250px;
      `}
`;

const Header = styled.div<IHeaderProps>`
  font-size: 18px;
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  display: block;
  justify-content: space-between;
  background-color: #f5f6fa;
  text-align: center;

  ${(props: IHeaderProps) =>
    props.isSmall &&
    css`
        font-size: 15px;
      `}
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Day = styled.div<IDayProps>`
  width: 13.5%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props: IDayProps) =>
    props.isSmall &&
    css`
        font-size: 0.9rem;
        `}

    ${(props: IDayProps) =>
    props.isToday &&
    css`
        border: 1px solid #eee;
        `}

    ${(props: IDayProps) =>
    props.isSelected &&
    css`
        background-color: #2196F3;
        color: white;
        `}

    ${(props: IDayProps) =>
    props.isSelectedNoModificable &&
    css`
            background-color: #DE942B;
            color: white;
        `}

    ${(props: IDayProps) =>
    props.isDisabled &&
    css`
            pointer-events: none;
            background-color: #dddddd;
        `}
`;

interface IFrameProps {
  isSmall?: boolean;
}

interface IHeaderProps {
  isSmall?: boolean;
}

interface IDayProps {
  isToday?: boolean;
  isSelected?: boolean;
  isSelectedNoModificable?: boolean;
  isDisabled?: boolean;
  isSmall?: boolean;
}

export interface ICalendarProps {
  firstDate: Date;
  diasCortesia: IDiaCortesiaDetalle[];
  diasCortesiaRef: any;
  limiteDiasSeleccionables?: number;
  fechaPorDefecto?: string;
  isDisabledOnClick?: boolean;
  isSmall?: boolean;
  isActivatedReturnDiaDeseleccionado?: boolean;
  setDiasModificables?: (diasSeleccionados: number) => void;
  setDiaSeleccionado?: (item: HTMLDivElement, fecha: string) => void;
}

interface IDiasSeleccionados {
  item: any;
  diaSeleccionado: IDiaCortesiaDetalle;
}

const Calendar: FC<ICalendarProps> = ({ firstDate, diasCortesia, diasCortesiaRef, limiteDiasSeleccionables, fechaPorDefecto, isDisabledOnClick, isActivatedReturnDiaDeseleccionado, isSmall, setDiasModificables, setDiaSeleccionado }) => {
  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_OF_THE_WEEK = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
  const MONTHS = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  const today = new Date();

  // Gestión días seleccionados
  const [arrayDiasSeleccionados, setArrayDiasSeleccionados] = useState<IDiasSeleccionados[]>([]);

  // Local states    
  const [date,] = useState(firstDate);
  // console.log('------------------');
  // console.log('date', date);
  const [, setDay] = useState(date.getDate());
  const [month, setMonth] = useState(date.getMonth());
  // console.log('month', month);
  const [year, setYear] = useState(date.getFullYear());
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date));//0-6 0 domingo 6 sabado
  // console.log('(startDay - 1): ', startDay - 1);

  useEffect(() => {
    setDay(date.getDate());
    setMonth(date.getMonth());
    setYear(date.getFullYear());
    setStartDay(getStartDayOfMonth(date));
  }, [date]);

  function getStartDayOfMonth(fecha: Date) {
    return new Date(fecha.getFullYear(), fecha.getMonth(), 1).getDay();
  }

  function isLeapYear(anyo: number) {
    return (anyo % 4 === 0 && anyo % 100 !== 0) || anyo % 400 === 0;
  }

  const days = isLeapYear(year) ? DAYS_LEAP : DAYS;

  const handleLimitSelection = (item: any, diaSeleccionado: IDiaCortesiaDetalle) => {
    const diaInArraySeleccionados = arrayDiasSeleccionados.find(element => element.diaSeleccionado.fecha === diaSeleccionado.fecha);

    if (!limiteDiasSeleccionables) return;

    else if (diaInArraySeleccionados) {
      setArrayDiasSeleccionados(arrayDiasSeleccionados.filter(element => element.diaSeleccionado.fecha != diaSeleccionado.fecha));
      return
    }

    else if (arrayDiasSeleccionados.length < limiteDiasSeleccionables) {
      setArrayDiasSeleccionados([...arrayDiasSeleccionados, { item, diaSeleccionado }]);
      return;
    }

    const ultimoDiaArraySeleccionadosAEliminar = arrayDiasSeleccionados[0];
    const diaSeleccionadoDiasCortesiaAEliminar = diasCortesia.find(dia => dia.fecha === ultimoDiaArraySeleccionadosAEliminar?.diaSeleccionado.fecha);

    if (diaSeleccionadoDiasCortesiaAEliminar && ultimoDiaArraySeleccionadosAEliminar) {
      ultimoDiaArraySeleccionadosAEliminar.item.style.backgroundColor = 'white';
      ultimoDiaArraySeleccionadosAEliminar.item.style.color = 'black';
      diaSeleccionadoDiasCortesiaAEliminar.seleccionada = !ultimoDiaArraySeleccionadosAEliminar.diaSeleccionado.seleccionada;

      const arrayElements = arrayDiasSeleccionados.filter(element => element.diaSeleccionado.fecha != ultimoDiaArraySeleccionadosAEliminar.diaSeleccionado.fecha);
      setArrayDiasSeleccionados([...arrayElements, { item, diaSeleccionado: diaSeleccionado }]);
    }
    return
  }

  const selectDay = (item: any, fecha: string) => {
    const diaSeleccionado = diasCortesia.find(dia => dia.fecha === fecha);

    if (!diaSeleccionado) return;
    if (diasCortesia.filter(d => d.seleccionada && d.modificable).length === limiteDiasSeleccionables && diaSeleccionado.fecha === fechaPorDefecto) return;

    handleLimitSelection(item, diaSeleccionado);

    if (!diaSeleccionado.modificable) return;
    else if (diaSeleccionado.seleccionada) {
      item.style.backgroundColor = 'white';
      item.style.color = 'black';
    }
    else {
      item.style.backgroundColor = BLUE;
      item.style.color = 'white';
    }

    diaSeleccionado.seleccionada = !diaSeleccionado.seleccionada;
    diasCortesiaRef.current = diasCortesia;

    if (setDiasModificables) {
      const diasCortesiaSeleccionados = diasCortesia.filter(d => d.seleccionada && d.modificable);
      setDiasModificables(diasCortesiaSeleccionados.length);
    }

    if (isActivatedReturnDiaDeseleccionado && setDiaSeleccionado) {
      setDiaSeleccionado(item, fecha);
    }
    else if (diaSeleccionado.seleccionada && setDiaSeleccionado) {
      setDiaSeleccionado(item, fecha);
    }
  }

  return (
    <Frame isSmall={isSmall}>
      <Header isSmall={isSmall}>
        <div>
          <Term component="Global" text={MONTHS[month]} />&nbsp;{year}
        </div>
      </Header>
      <Body>
        {DAYS_OF_THE_WEEK.map(d => (
          <Day key={d}>
            <strong style={isSmall ? { fontSize: '0.9rem' } : {}}><Term component="Global" text={d} /></strong>
          </Day>
        ))}
        {
          Array(days[month] + (startDay !== 0 ? startDay - 1 : 6))
            .fill(null)
            .map((_, index) => {
              const d = index - ((startDay === 0 ? 7 : startDay) - 2);
              const currentDate = new Date(year, month, d);
              const diaCortesia = diasCortesia.find(dia => {
                const newDay = new Date(dia.fecha)
                return d > 0
                  && newDay.getFullYear() === currentDate.getFullYear()
                  && newDay.getMonth() === currentDate.getMonth()
                  && newDay.getDate() === currentDate.getDate()
              });
              const isSelected = diaCortesia && diaCortesia.seleccionada && diaCortesia.modificable;
              const isSelectedNoModificable = diaCortesia && diaCortesia.seleccionada && !diaCortesia.modificable;
              const isDisabled = diaCortesia && ((currentDate < today && !isSelectedNoModificable) || !diaCortesia.modificable);

              return (
                <Day
                  id={`calendar_day_${index}_${dateFormat(currentDate)}`}
                  key={index}
                  isSelected={isSelected}
                  isSelectedNoModificable={isSelectedNoModificable}
                  isDisabled={isDisabled && !isSelectedNoModificable}
                  isSmall={isSmall}
                  onClick={(evt) => isDisabledOnClick ? true : selectDay(evt.currentTarget, diaCortesia ? diaCortesia.fecha : '')}
                >
                  {d > 0 ? d : ''}
                </Day>
              );
            })
        }
      </Body>
    </Frame>
  );
}

export default Calendar;