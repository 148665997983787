import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { green, grey } from "@material-ui/core/colors";
import { BLUE } from "containers/shared/colors";

const styles = (theme: Theme): StyleRules => ({
    // GENERIC STYLES
    // Width & Hright
    w100: {
        width: '100%'
    },
    // Margins & Paddings
    m20: {
        margin: 20
    },
    mTop20: {
        marginTop: 20
    },
    mTop40: {
        marginTop: 40
    },
    mBottom40: {
        marginBottom: 40
    },
    // Flex
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    flex1: {
        flex: 1
    },
    flexNoWrap: {
        flexWrap: 'nowrap'
    },
    // Flex align & justify
    alignCenter: {
        alignItems: 'center'
    },
    justifyEnd: {
        justifyContent: 'flex-end'
    },
    // Grid
    grid: {
        display: 'grid',
        gridTemplateColumns: 'auto'
    },
    // Gaps
    gap20: {
        gap: '20px'
    },
    // Text
    bold: {
        fontWeight: 'bold'
    },
    borderBottom: {
        borderBottom: '1px solid black'
    },
    borderBottomGrey: {
        borderBottom: '1px solid #dfdfdf'
    },
    relative: {
        position: 'relative'
    },
    // PagoCarta Confirmación
    cardSpacing: {
        padding: '40px 70px',
        margin: '0px 20px'
    },

    // Custom styles
    root: {
        flex: 1,
        flexGrow: 1,
    },
    rootCard: {
        flexGrow: 1,
        width: '95%',
    },
    marginAlta: {
        margin: '0 1rem',
    },
    selectForm: {
        minWidth: '150px',
        [theme.breakpoints.down('md')]: {
            marginTop: 15,
            minWidth: '150px',
        }
    },
    cardContador: {
        marginBottom: '0.5rem',
        backgroundColor: 'rgba(255,255,255,0.6)',
    },
    cardContadorContainer: {
        padding: '13px !important',
        display: 'flex',
        alignItems: 'center',
    },
    titleCardContador: {
        fontWeight: 300,
        fontSize: 16,
        marginLeft: '1rem',
        color: '#406a8d',
        flex: 1
    },
    danger: {
        fontWeight: 'bold',
        color: 'red'
    },
    lista: {
        width: '100%',
        maxWidth: 550,
        backgroundColor: theme.palette.background.paper,
    },
    contentRoot: {
        flexWrap: 'nowrap'
    },
    grow: {
        flexGrow: 1,
        paddingRight: '5px'
    },
    highlightText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#000',
        fontSize: 12,
        fontWeight: 700
    },
    detailText: {
        color: '#000',
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5
    },
    titleTextCard:{
        fontSize: '1.5rem',
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: 1.334,
        letterSpacing: '0em',
        
    },
    subtitleText:{
        fontSize: '1.3rem',
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: 1.334,
        letterSpacing: '0em',
    },
    buttonPagarTodos: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    },
    imagenContainer: {
        width: 40,
        height: 40,
        backgroundColor: '#92b58e',
        borderRadius: '50%',
        alignSelf: 'center',
        [theme.breakpoints.up('md')]: {
            width: 80,
            height: 80,
        }
    },
    container: {
        flexGrow: 1,
        margin: '0 0.5rem',
        [theme.breakpoints.up('md')]: {
            margin: '0 2rem'
        }
    },
    imageGrid: {
        alignSelf: 'center'
    },
    button: {
        margin: theme.spacing(1),
        width: '98%'
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    left: {
        float: 'left'
    },
    margin10: {
        margin: '10px'
    },
    margin15: {
        marginLeft: '15px'
    },
    margin0: {
        marginLeft: '0 !important'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        [theme.breakpoints.up('md')]: {
            left: '50%',
        }
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
            width: 300,
        }
    },
    [theme.breakpoints.up('md')]: {
        root: {
        },
        rootCard: {
        },
        fixedBottom: {
            position: 'fixed',
            bottom: 0,
            width: 'calc(100% - 300px)',
            marginLeft: 0,
        },
    },
    flexConatinerInputs: {
        display: "flex",
        justifyContent: "space-between",
        gap: '2rem',
        '& > div': {
            width: "50%",
        }
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonCancel: {
        backgroundColor: grey[500],
        '&:hover': {
            backgroundColor: grey[700],
        },
    },
    containerCircularProgress: {
        position: "absolute",
        margin: "auto",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    circularProgressDomiciliacion: {
        position: "absolute",
        display: "flex",
    },
    containerInfo: {
        margin: 0,
        padding: "8px 24px"
    },
    formControl: {
        '& .MuiInputLabel-outlined': {
            padding: '0 2px',
            backgroundColor: 'white'
        },
        '& .MuiSelect-iconOutlined': {
            zIndex: 1
        },
        '& .MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'white'
        },
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            zIndex: 1
        }
    },
    marginError: {
        margin: '5px 0 5px 15px'
    },
    containerPlazosPC: {
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        flexWrap: 'wrap'
    },
    buttonInfoPC: {
        margin: theme.spacing(1),
    },
    // Fecha Prevista Voluntaria
    marginDomiciliacionesFechaCargo: {
        margin: "1rem"
    },
    calendarFechCargo: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: "1rem",
        alignItems: "flex-start",
        justifyContent: "center"
    },
    fechaCargo: {
        display: "flex",
        flexDirection: "column"
    },
    paddingFechaCargo: {
        padding: "16px",
        paddingBottom: "24px"
    },
    fechaPrevista: {
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center'
    },
    progressContainer: {
        display: "flex",
        justifyContent: "center",
        margin: '1rem'
    },
    progressLoaderOverButton: {
        color: green[500],
        position: 'absolute',
    },
    containerLeyendaDiasVoluntaria: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '0.5rem',
    },
    containerExplicacionLeyenda: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center'
    },
    leyendaDiasVoluntaria: {
        width: 20,
        height: 20
    },
    colorDiasDeshabilitados: {
        backgroundColor: '#dddddd'
    },
    colorDiasHabilitados: {
        backgroundColor: '#FFFF',
        border: '1px solid black'
    },
    colorDiaSeleccionado: {
        backgroundColor: BLUE
    }
});

export default styles;