import { withStyles, StyleRules, WithStyles } from "@material-ui/styles";
import React from 'react';

const styles = (): StyleRules => ({
    etiqueta: {
        marginBlockStart: 0,
        marginBlockEnd: 0,
        maxWidth: 'inherit'
    },
    fieldB: {
        fontWeight: 500,
    }
});

export interface IProps extends WithStyles<typeof styles> {
    label: React.ReactNode;
    text?: React.ReactNode;
    multiline?: string | null;
}

function LabelField(props: IProps & WithStyles<typeof styles>) {
    const { classes, text, label, multiline } = props;


    return (
        <>
            <p className={classes.etiqueta}><small>{label}</small></p>
            <p className={classes.etiqueta}><b className={classes.fieldB}>{multiline ? <div dangerouslySetInnerHTML={{ __html: multiline }} /> : text}</b></p>
        </>
    )
}

export default withStyles(styles)(LabelField);