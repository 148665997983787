import { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent, Typography} from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import styles from './styles';
import { formatXMLtoObject, GenericInitialObject, getEmptyObject, getKeysRequiredCampos, renderCampos,  GenericObject, getInitialObject, isSameData, mixData, GroupLabel, TPeriodo, OPTIONS_trimestre} from '../utils';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { GeoGateway } from 'gateways/geo.gateway';

const eval656_D = (
    info: GenericInitialObject, empty:GenericInitialObject,
    codigo67: string, formulaBase67: string, codigo71: string, formulaBase71: string, 
    codigo667: string, codigo668: string, campos: ICampo[]
) =>{
    const CAMPOS_COND_667=[79];
    const CAMPOS_COND_668=[64,65,66];
    let out = {...info}

    // estado de los checkbox ??
    const v667 = out[codigo667].value as boolean
    const v668 = out[codigo668].value as boolean
    // Evaluar el objeto 
    //console.log('to eval ->  667:', v667 ,'  668: ', v668, empty)
    // Fórmula del 67 - actualizar
    const newFormula = v667 ? `${formulaBase67}-([79])`:  `${formulaBase67}-([66])` // Fómrula dinámica Casilla 67
    const newFormula71 = `${newFormula}-${formulaBase71}`

    //console.log('formulas')
    out[codigo67] ={...out[codigo67], formula:  newFormula}
    out[codigo71] ={...out[codigo71], formula:  newFormula71}

    // Poner a valor inicial campos que ya no esten habilitados
    for (const key in info) {
        const element = out[key]
        if(CAMPOS_COND_667.includes(element.casilla) && v667 == false){
            out[key] ={...out[key], value: v667 ? out[key].value : empty[key].value}
        }
        if(CAMPOS_COND_668.includes(element.casilla) && v668 == false){
            out[key] ={...out[key], value: empty[key].value}
        }
    }

    //Actualizar array de campos
    const campEval = campos.map( c => {
        if(CAMPOS_COND_667.includes(c.casilla)){
            return({...c, soloLectura: v667 ? false  :true})
        } else if(CAMPOS_COND_668.includes(c.casilla)){
            return({...c, soloLectura: v668 ? false  :true})
        } else if(c.casilla === 67){
            return({...c, formula: newFormula})
        } else if(c.casilla === 71){
            return({...c, formula: newFormula71})
        } else{
            return({...c})
        }
    })
    //console.log('eval656_D ', {data: out, newCampos: campEval})
    return {data: out, newCampos: campEval};
}


const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXmlApartadosPrevios: GenericObject,
    datosXml: string | null,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
}

const Datos656D: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXmlApartadosPrevios,
        datosXml,
        setFinished,
        handleSave
    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes, setChanges] = useState(false);
    const [campos, setCampos] = useState<ICampo[] | undefined>(undefined);
    const [EMPTY_Object,setEmptyObject] = useState<GenericInitialObject| null>({});
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    const [groupLabels, setGroupLabels] = useState<GroupLabel| undefined>(undefined);

    // condicion periodo
    const [isThisPeriod, setIsThisPeriod]= useState(false)
    const [paramPeriod, setParamPeriod]= useState<string[]>([])

    const CAMPOS_PERIODO =  ['ejerPeri']
    const MODELO_PERIODO: Record<string,TPeriodo> = {
        '656': '4T'
    }
    // Formulas dinámicas
    const [formula67,setFormula67] = useState<{formula:string, codigo:string} | null>(null);
    const [formula71,setFormula71] = useState<{formula:string, codigo:string} | null>(null);
    const [codigosCond ,setCodigosCond] = useState<{667: string, 668: string} | null>(null)
      
    
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData = ( name: string, value: any) => {
        setChanges(true)
        let newData=null;
        if(EMPTY_Object){
            newData= data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY_Object,[name]: {...EMPTY_Object[name], value: value }}

       
            if(newData[name].tipo === 'exclusiveBool'){
                //Poner a falso los otros exclusive
                if(value === true){
                    // 1. Obtener casillas que intervienen en la formula
                    const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g; //(?!\[)-?\d+(?=\])/g;
                    const casillas = newData[name].formula.match(myRegExp);
                    //2. setear a falso todos los valores de la formula
                    if(casillas){
                        for (const key in newData) {
                            if (Object.prototype.hasOwnProperty.call(newData, key)) {
                                const element = newData[key];
                                if(casillas.includes(element.casilla.toString())){
                                    newData[key] = {...newData[key], value: false}
                                } 
                            }
                        }
                    }
                }
                // Actualizar valores para el apartado
                if(formula67 && formula71 && codigosCond && campos){
                    const evalResult = eval656_D(newData, EMPTY_Object, formula67.codigo, formula67.formula, formula71.codigo, formula71.formula, codigosCond[667], codigosCond[668], campos)
                    newData = evalResult.data
                    setCampos(evalResult.newCampos)
                }
                

            }
        }

        setData(newData)
    }

    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            //console.log('on save camposRequiredKeys', camposRequiredKeys)
            if(camposRequiredKeys && camposRequiredKeys.length>0 ){
                if(data){
                    camposRequiredKeys.forEach( element => {
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal' || 'decimal2' ||'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                }
            }
            if (valid) {
                valid = campos?.findIndex(campo => campo.isValid != undefined && !campo.isValid && campo.visible) == -1;
            }
            if(valid){
                const savedTemp = await handleSave(data); 
                setChanges(!savedTemp);
            } else {
                //setFinished(false);
                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos','FormularioError', terms),
                        variant: 'error',
                    }
                });

            }
            setLoading(false);

        })();
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                //console.log('idTipoApartado', idTipoApartado)
                let camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                
                const periodoCampo = camposApartado.filter( c => CAMPOS_PERIODO.includes(c.codigo))
                
                const campo67 = camposApartado.find(c => c.casilla===67)
                const campo71 = camposApartado.find(c => c.casilla===71)

                if(campo67 && !formula67 ){
                    setFormula67({formula: campo67?.formula, codigo: campo67.codigo})
                }

                if(campo71 && !formula71){
                    setFormula71({formula: campo71?.formula, codigo: campo71.codigo})

                }

                // const campo67Idx = camposApartado.findIndex(c => c.casilla===67)
                // const campo79Idx = camposApartado.findIndex(c => c.casilla===79)
                const codigoCampo667 = camposApartado.find(c => c.casilla===667)?.codigo
                const codigoCampo668 = camposApartado.find(c => c.casilla===668)?.codigo
                


                //console.log('camposApartado', camposApartado)

                
                let emptyObjt = null;
                let changes= true;
                let newData: GenericInitialObject | null = null;
                let dataXML: GenericInitialObject | null = null;
                let dataPrevios: GenericInitialObject | null = null;
                
                
                //console.log('condicion', camposApartado && camposApartado.length>0 )
                if(camposApartado && camposApartado.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEmptyObject(emptyObjt);
                    const keys = getKeysRequiredCampos(camposApartado);
                    setCamposRequiredKeys(keys)
                    const datosPrevString = Object.values(datosXmlApartadosPrevios).join('')
                    //console.log('datosPrevString ', datosPrevString)
                    //console.log('datosXml ', datosXml)
                    if(datosXml){
                        dataXML = formatXMLtoObject(datosXml,emptyObjt);
                        //console.log('USE datosXML', newData)
                    } 
                    if(datosPrevString){
                        dataPrevios = getInitialObject(camposApartado, datosPrevString, idTipoApartado);
                        //console.log('useDatos datosPrevString', dataPrevios)
                    }
                    
                    
                    //console.log('isSameData:', isSameData(dataXML, dataPrevios))
                    if(isSameData(dataXML, dataPrevios)){
                        changes=false
                        newData= dataXML
                    } else{
                        const mixDataObjt = mixData(newData, dataPrevios, idTipoApartado)
                        changes=!isSameData(newData, mixDataObjt)
                        newData = mixDataObjt ?? newData
                    }

                }
                let finalData =  newData || emptyObjt;
                // Evaluar si aplica 
                let apply = true
                if(finalData && periodoCampo.length>0 && MODELO_PERIODO[idTipoModelo]){
                    // Ver si es 4T
                    const miData = periodoCampo[0].codigo
                    const miPeriodo = OPTIONS_trimestre.find( opt => opt.id === MODELO_PERIODO[idTipoModelo])?.nombre ?? 'not-found'
                    miPeriodo !== 'not-found' && setParamPeriod([miPeriodo])
                    apply = finalData[miData].value.includes(miPeriodo)
                    changes = apply ? changes : false
                    //console.log('campo: ', miData, 'periodoAplica: ', miPeriodo, ' es mi periodo ? ',apply)
                }
                

                //console.log('finalData ', finalData)
                if(apply){
                    if(finalData && emptyObjt && codigoCampo667 && codigoCampo668 && campo67 && campo71){

                        // Actualizar valores para el apartado
                        const evalResult = eval656_D(finalData, emptyObjt, campo67.codigo, campo67.formula, campo71.codigo, campo71.formula, codigoCampo667, codigoCampo668, camposApartado)
                        finalData = evalResult.data
                        camposApartado = evalResult.newCampos
                    }
                } else {
                    camposApartado= camposApartado.map(c=> ({...c, soloLectura: true}))
                }
                

                
                setGroupLabels({
                    '1S': translate('Tributos','estimacionObjetiva',terms),
                    '2S': translate('Tributos','estimacionDirecta',terms),
                })

            
                setData(finalData);
                setCampos(camposApartado);
                setCodigosCond(codigoCampo667 && codigoCampo668 ? {667: codigoCampo667, 668: codigoCampo668}: null)
                setIsThisPeriod(apply)
                setChanges(changes);
                setLoading(false);

            } catch (error) {
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);

    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
    },[changes])

   
    
    return(
        <div> 
            
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
            {isThisPeriod 
                ? null
                :   <Typography 
                        style={{margin: '10px 10px 25px 10px', color:'#004f82', fontSize: 18, fontStyle:'italic'}}
                    >{translate('Tributos', `soloCumplimentarPeriodo${MODELO_PERIODO[idTipoModelo]}`, terms,paramPeriod)}
                    </Typography>
            }
            {campos && campos.length>0 && camposRequiredKeys !== null && data && 
                renderCampos(campos, data, updateData, Object.values(datosXmlApartadosPrevios).join(''), idTipoApartado, undefined, groupLabels, classes )
            }

            <div className={classes.rowAlignRight}>
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    disabled={!changes}
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnSave',terms)} 
                </Button>
            </div>
        </div>
    )
}
export default withLiterals(['Tributos'])(Datos656D);



