import React, { useEffect, FC, useState, useContext, useCallback, useRef, useMemo } from 'react';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import {  makeStyles, StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import { Card, Stepper, Step, StepLabel, StepConnector, Button, StepContent, Grid, StepIconProps, CardContent, Paper, CardHeader, Typography, Divider, IconButton, Link} from '@material-ui/core';
import Icon from '@mdi/react';
import { Icon as Icon2 } from 'atomic/atoms/icon/icon';
import { mdiArrowRight, mdiArrowLeft, mdiContentSave, mdiOpenInNew} from '@mdi/js';
import clsx from 'clsx';
import { translate } from 'utils/i18n';
import { MoreInfoContext } from 'contexts/more_info.context';
import Term from '../term';
import { TInfoTemplateSize } from 'contexts/more_info.reducer';
import { TNameStep, TStatusStep } from 'containers/DUTI/steps/types';
import DustiIcon from 'resources/home/tramites/iconoDusti.png'
import { duti_colors } from 'containers/DUTI/styles';

/**
 * 'INI': Step iniciado, con datos parciales sin constar como finalizado
 * 'FIN': Step finalizado - dependiendo del step podrá editarse o no
 *  null: El usuario aún no he realizado ninguna acción
 */

//Conciden con los nombre registrados en GestionTibutaria.dusti.PasoDeclaracion
export type THeaderStepper = {refCat:string, dir: string, urlCatastro: string, muni: string|undefined}
export type IDUTIStepStepper = { idPaso: number, order: number, termTitle: TNameStep, active:boolean, status: TStatusStep}
export type TDutiStepsContent = { idPaso: number, termTitle: TNameStep,  component: () => JSX.Element , moreInfoTemplate?:string, moreInfoTemplateSize?: TInfoTemplateSize  }
interface IProps {
    headerStepper: THeaderStepper | null
    steps: IDUTIStepStepper[], 
    stepsContent: TDutiStepsContent[],

}


const StepperDUTI: FC<IProps> = ({headerStepper, steps, stepsContent}) => {
    const classes = useStyles();
    const classesIcon = useColorlibStepIconStyles();
    const terms = useContext(LiteralsContext);
    const [showPrev,setShowPrev] = useState(false); 
    const [showNext,setShowNext] = useState(false); 
    const [showFinish,setShowFinish] = useState(false); 
    const [, infoDispatch] = useContext(MoreInfoContext);
    const ref = useRef(null);
    const [heightPaper, setHeightPaper] = useState(720);
    const hiddenSteps = [2, 3];

    const {activeStep, activeStepFinished} = useMemo(() => {
        const findActive= steps && steps.length>0 
            ? steps.findIndex(s => s.active === true)
            : 0
        const idxActive = findActive >0 ? findActive : 0
        const isFinished = steps && steps.length>0 && steps[idxActive].status
            ? steps[idxActive].status === 'FIN'
            : false
        //console.log('stepperSteps: ', steps, 'activeStep:', idxActive, ', activeStepFinished ', isFinished)
        return ({activeStep: idxActive, activeStepFinished: isFinished})
    }, [steps])

    const ready: boolean = useMemo(() => { 
        return (steps && steps.length>0 && stepsContent && stepsContent.length>0) ? true : false
    }, [steps,stepsContent])
 

    const handleShowMoreInfo = useCallback(() => {
        if (stepsContent && activeStep && stepsContent[activeStep].moreInfoTemplate) {
            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: stepsContent[activeStep].moreInfoTemplate ?? '',
                    size: stepsContent[activeStep].moreInfoTemplateSize ?? undefined,
                }
            });
        }
    }, [infoDispatch, activeStep, stepsContent ])
    
    useEffect( () => {
        setShowPrev(activeStep > 0) 
        setShowNext(activeStep < steps.length-1)
        setShowFinish(activeStep === steps.length-1)
    },[activeStep])

    useEffect(() => {
        // 👇️ use a ref (best)
        const element2 = ref.current;
        // console.log(element2);
    
        // 👇️ use document.querySelector()
        // should only be used when you can't set a ref prop on the element
        // (you don't have access to the element)
        const element = document.querySelector('#paper');
        element && setHeightPaper(element?.scrollHeight);
        // console.log(element?.scrollHeight);
    }, []);

    //console.log('ready ', ready)
    return (

        <Grid container direction={window.screen.width <= 960 ? 'column-reverse' : 'row'} 
            style={{
                flexGrow: 1,
                display:'flex',
                flexWrap: 'nowrap',
                minHeight: window.screen.width <= 960 ? undefined : '600px',
                //maxHeight: 'calc(100% - 96px)',
                width: '100%'
            }}
        >
            {ready && 
            <>
            
                <Grid 
                    item 
                    xs={window.screen.width <= 960 ? 12 : 9}
                    sm={12} 
                    md={9}
                    lg={9}
                    xl={10}
                    style={{
                        display:'flex',
                        flexGrow:1,
                        marginTop: window.screen.width <= 960 ? 10: 0 
                    }}
                >
                    <Card elevation={steps[activeStep].termTitle==="FinalizacionPago" ? 0 : 1}
                        style={{
                            height: window.screen.width <= 960 ? '100%': heightPaper,
                            marginRight: window.screen.width <= 960 ? 10: undefined,
                            flexGrow: 1,
                            marginLeft: 10,
                        }}
                    >
                        { !hiddenSteps.includes(activeStep) &&
                            <CardHeader style={{borderBottom: 'solid', borderBottomWidth:1, borderColor:'#d3d3d3', height: 70 }}
                                title={
                                    <div className={classes.row} style={{alignItems:'center'}}>
                                        {translate('DUTI', `step_${steps[activeStep].termTitle}`,terms).toUpperCase()}
                                        {
                                            stepsContent[activeStep].moreInfoTemplate 
                                            ?
                                                <IconButton onClick={handleShowMoreInfo}>
                                                    <Icon2 name={'info-outline'} size={1} color={duti_colors.blueIconInfo}/>
                                                </IconButton>
                                            : null
                                        }
                                    </div>
                                }
                                subheader={
                                    null
                                }
                            />
                        }
                        <CardContent 
                            style={{ 
                                height: (steps[activeStep].termTitle==="Justificantes" || steps[activeStep].termTitle==="FinalizacionPago") ?'100%': 'calc(100% - 70px)', //70px header card
                                backgroundColor: steps[activeStep].termTitle==="FinalizacionPago" ? '#e0eaf4' : undefined, 
                                overflowY:'auto', 
                                paddingBottom: steps[activeStep].termTitle==="FinalizacionPago" ? 0: 10, 
                                paddingTop:0,
                                paddingRight:0, paddingLeft:0
                            }}>
                            {stepsContent[activeStep].component()}
                        </CardContent> 
                    </Card> 

                </Grid>
            
                <Grid item 
                    xs={window.screen.width <= 960 ? 12 : 3} 
                    sm={12}
                    md={3}
                    lg={3}
                    xl={2}
                    style={{
                        display:'flex',
                        flexGrow: 1, 
                        minHeight: window.screen.width <= 960 ? undefined : '600px',
                        marginTop: window.screen.width <= 960 ? 10: 0 ,
                        flexDirection:  window.screen.width <= 960 ? 'row': 'column',
                        position: 'relative',
                    }}
                >   

                    {/** info inmueble */}
                    { headerStepper && 
                        <div style={{top:0}} className={classes.stepperHeaderFooter}>
                            <div style={{display: 'flex', flexGrow:1, flexDirection: 'column'}}>
                                <Grid item container direction='row' alignItems='center' 
                                    style={{height: window.screen.width <= 960 ? 49 : 69, alignItems:'center'}}
                                >
                                    <img width={window.screen.width <= 1440 ? 30:35} height={window.screen.width <= 1440 ? 30: 35} src={DustiIcon} alt={'dusti icon'} />
                                    <div className={classes.column} style={{paddingLeft: 10, height: 49, justifyContent: 'center'}}> {/**Casar con el Titulo CARD => 70px => padding 20 + 49 texto + 1 divider */}
                                        <Typography style={{ fontSize:window.screen.width <= 1440 ? 11: 12, marginBottom: 2}}>{translate('DUTI','refCatastral' ,terms)}</Typography>
                                        <div className={classes.row}>
                                            <Typography style={{fontWeight:'bolder', fontSize:window.screen.width <= 1440 ? 11: 14}}>{headerStepper.refCat}</Typography> 
                                            <Link
                                                href={headerStepper.urlCatastro}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Icon
                                                    style={{ marginLeft: 5, alignSelf: 'center' }}
                                                    path={mdiOpenInNew}
                                                    size={window.screen.width <= 1440 ? 0.6 : 0.8}
                                                    color="blue"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </Grid>
                                <div style={{backgroundColor: '#dfdfdf', minHeight: 1, maxHeight:1, display:'flex'}}></div>
                                <Typography style={{
                                        fontSize: 12, 
                                        minHeight: window.screen.width <= 960 ? 49: 69, 
                                        display:'flex', 
                                        alignItems: 'center'
                                    }}
                                >
                                    {headerStepper.dir}
                                </Typography> 
                                <div style={{backgroundColor: '#dfdfdf',  minHeight: 1, maxHeight:1, display:'flex'}}></div> 
                            </div>

                            {/** MVL Footer -> foto municipio */}
                            {headerStepper.muni && window.screen.width <= 960 &&
                                <div style={{marginLeft: 10,maxWidth: 220,display: 'flex', alignItems: 'center', justifyContent: 'center' ,flexGrow: 1, maxHeight: 100}}>
                                    <img  style={{objectFit: 'scale-down', maxWidth: 220, maxHeight: 100}} src={`https://www.atib.es/App_Themes/LogosMuni/${headerStepper.muni}.png`} alt={'muni logo'} />
                                </div>
                            }
                        </div>
                    }
                    

                    {/** Pasos Stepper */}
                    <Stepper id='paper' ref={ref} 
                        className={classes.stepperContainer}
                        orientation={ window.screen.width <= 960 ? "horizontal" : "vertical"} 
                        activeStep={activeStep} 
                        connector={<ColorlibConnector style={{flexGrow : 0}}/>} 
                    >
                        {steps.map((step, index) => (
                            <Step key={step.termTitle}>
                                <StepLabel StepIconComponent={ (props: StepIconProps) =>(
                                    <div
                                        className={clsx(classesIcon.root, {
                                        [classesIcon.active]: props.active,
                                        [classesIcon.completed]: props.completed,
                                        })}
                                    >
                                        {step.order}
                                    </div>
                                    )}
                                >
                                    <div className={
                                        activeStep === index 
                                        ? classes.boldText 
                                        : activeStep > index  ? classes.completedText : classes.lightText
                                    }>
                                        {translate('DUTI', `step_${step.termTitle}`, terms) }
                                    </div>
                                </StepLabel>
                                <StepContent style={{flexGrow: 1, flex: 'none'}}>{}</StepContent>
                            </Step>
                        ))}
                    </Stepper>

                    {/** Footer -> foto municipio */}
                    {headerStepper && headerStepper.muni && !(window.screen.width <= 960) && 
                        <div style={{ bottom:0, paddingRight:0, paddingLeft: 0 }} className={classes.stepperHeaderFooter}>
                            <div style={{backgroundColor: '#dfdfdf', height: 1, display:'flex'}}></div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',flexGrow: 1, maxHeight: 199, width: '100%', padding: 10 }}>
                                <img width={'100%'} height={'100%'} style={{objectFit: 'scale-down'}} src={`https://www.atib.es/App_Themes/LogosMuni/${headerStepper.muni}.png`} alt={'muni logo'} />
                            </div>
                        </div>
                    }

                </Grid>

            </>}
        </Grid>
        
    );
}

export default withLiterals(['Global','DUTI'])(StepperDUTI);



const useStyles = makeStyles( (theme: Theme): StyleRules =>({
    // STEPPER
    boldText: {
        color: 'black',
        fontWeight: 'bold'
    },
    completedText: {
        color: 'rgba(45,178,190,1)', 
    },
    lightText: {
        fontWeight: 'lighter',
        color: 'grey',
    },
    stepperContainer: {
        height: '100%',
        display:'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginLeft: 10,
        marginRight: 0,
        borderRadius: 10,
        zIndex: 1,
        paddingTop: 220,
        paddingBottom: 220,
        position: 'relative',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            paddingTop: 120,
            paddingBottom: 10,
            paddingLeft:10,
            paddingRight:10,
            marginRight: 10,
        },           
    },
    stepperIcon: {
        fill: '#004f84',
    },
    stepperDisabledIcon:{
        fill: '#e0e0e0'
    },
    stepperButton: {
        color: '#004f84',
        borderColor: '#004f84',
    },
    row: {
        display:'flex', 
        flexDirection: 'row'
    },
    column: {
        display:'flex', 
        flexDirection: 'column'
    },

    stepperHeaderFooter: {
        position: 'absolute',
        left:0,
        zIndex: 3,
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 20,
        paddingLeft: 20,
        marginLeft:10,
        paddingTop:0,
        flexGrow: 1,
        height: 200,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxHight: 120,
            flexDirection: 'row',
            marginRight: 20,
            paddingTop:10,
            paddingBottom:10
        },
    }
}));

const useColorlibStepIconStyles = makeStyles({
    root: {
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'lightgrey',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        fontWeight: 'bold',
        fontSize: '18px'
    },
    active: {
        backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage: 'linear-gradient(to right, rgba(45,178,190,1),rgba(168,220,249,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }
});


const ColorlibConnector = withStyles({
    line: {
        height: 3,
        border: 0,
        width: 2,
        backgroundColor: '#eaeaf0', 
        marginLeft: 12.5
    },
})(StepConnector);

