import { Theme } from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import { StyleRules } from "@material-ui/styles";

const styles = (theme: Theme): StyleRules => ({
    root: {
        flex: 1,
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
            width: 300,
        }
    },
    rootLeft: {
        float: 'left',
        width: '80%'
    },
    fullWidth: {
        width: '100%'
    },
    cardContador: {
        marginBottom: '0.5rem',
        backgroundColor: '#fff',
    },
    cardContadorContainer: {
        padding: '13px !important',
        display: 'flex',
        alignItems: 'center',
    },
    titleCardContador: {
        fontWeight: 300,
        fontSize: 16,
        marginLeft: '1rem',
        color: '#406a8d',
        flex: 1
    },
    cardSeparate: {
        marginBottom: '0.5rem'
    },
    rootRight: {
        float: 'right',
        width: '20%',
        minHeight: '100%',
        borderLeft: '2px solid #e0e0e0',
        padding: '10px',
    },
    mainCard: {
        margin: '0 1rem',
    },
    formasPago: {
        flexGrow: 1,
    },
    formControl: {
        marginLeft: `15px`,
    },
    group: {
        margin: `10px 0`,
    },
    block: {
        display: `inline-block`,
        paddingRight: '15px'
    },
    middle: {
        verticalAlign: `middle`
    },
    rootTable: {
        width: '100%',
        marginTop: '3px',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    price: {
        marginLeft: 10,
        marginRight: 10,
        color: '#406a8d',
        fontSize: 12,
        [theme.breakpoints.up('md')]: {
            fontSize: 15,
            color: '#000000',
        }
    },
    priceEjecutiva: {
        color: '#ca261b'
    },
    wrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        margin: theme.spacing(1),
        width: '100%'
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        // top: '50%',
        // left: '50%',
        // marginTop: -12,
        // marginLeft: -12,
        // [theme.breakpoints.up('md')]: {
        //     left: '13%',
        // }
    },
    printProgress1: {
        color: green[500],
        position: 'absolute',
        top: '25%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        [theme.breakpoints.up('md')]: {
            left: '13%',
            top: '50%',
        }
    },
    printProgress2: {
        color: green[500],
        position: 'absolute',
        top: '75%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        [theme.breakpoints.up('md')]: {
            left: '38%',
            top: '50%',
        }
    },
    buttonProgressList: {
        color: green[500],
        position: 'absolute',
        // top: '89%',
        // left: '50%',
        // marginTop: -14,
        // marginLeft: 6,
        // [theme.breakpoints.up('md')]: {
        //     left: '95%',
        //     top: '88%',
        // }
    },
    right: {
        textAlign: 'right',
        marginRight: '5px'
    },
    divider: {
        height: '2px',
        margin: 0,
        border: 'none',
        flexShrink: 0,
        backgroundColor: grey[500]
    },
    rootTotal: {
        borderTop: '2px solid #e0e0e0',
        backgroundColor: '#fff',
        transition: 'all .5s ease 0s',
        margin: 5,
        width: '97%',
        [theme.breakpoints.up('md')]: {
            width: '85%',
            margin: 0,
        }
    },
    priceInfo: {
        paddingTop: 10,
        paddingLeft: 10,
        paddingBottom: 10,
    },
    payContainer: {
        borderTop: '2px solid #d3c45b',
        textAlign: 'center'
    },
    totalPriceSelected: {
        color: '#000',
        fontWeight: 'bold'
    },
    totalPrice: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: 22
    },
    tableRoot: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paddingBottom: {
        paddingBottom: '25px'
    },
    imageEffect: {
        verticalAlign: `middle`,
        cursor: 'pointer'
    },
    iconoImage: {
        width: 40,
        height: 40
    },
    hidden: {
        display: 'none'
    },
    modeloCaducado: {
        flexGrow: 1,
        margin: '25px',
        textAlign: 'center',
        color: 'red'
    },
    left: {
        float: 'left'
    },
    margin10: {
        margin: '10px'
    },
    recibo: {
        borderBottom: '1px solid #e0e0e0'
    },
    mainButton: {
        width: '100%',
        textAlign: 'left',
        paddingTop: 15,
        paddingBottom: 15,
    },
    mainButtonDusti: {
        width: '100%',
        textAlign: 'left',
    },
    rootGrid: {
        flexWrap: 'nowrap',
        position: 'relative'
    },
    contentRoot: {
        flexWrap: 'nowrap'
    },
    grow: {
        flexGrow: 1,
        paddingRight: '5px'
    },
    highlightText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#000',
        fontSize: 12,
        fontWeight: 700
    },
    detailText: {
        color: '#000',
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5
    },
    spanText: {
        color: '#000',
        fontSize: 12,
        marginRight: 2,
        marginTop: 2
    },
    blueText: {
        color: 'blue',
        fontSize: 12,
        marginRight: 2,
        marginTop: 2,
    },
    imagenContainer: {
        width: 40,
        height: 40,
        backgroundColor: '#92b58e',
        borderRadius: '50%',
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 50
    },
    iconoContainer: {
        width: 40,
        height: 40,
        marginRight: 15
    },
    imagen: {
        width: 40,
        height: 40
    },
    checked: {
        backgroundColor: '#fff',
        borderRadius: '50%',
        border: '1px solid #000'
    },
    imageGrid: {
        alignSelf: 'center'
    },
    fullButton: {
        width: '100%'
    },
    width0: {
        width: 0
    },
    red: {
        color: '#FF0000'
    },
    btnAddPay: {
        bottom: 0,
        // width: '95%'
        position: 'relative'
    },
    priceLabel: {
        display: 'none',
    },
    priceBold: {
        fontWeight: 700
    },
    width100: {
        width: '100%'
    },
    imageGridcontainer: {
        display: 'block',
        textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
        root: {
        },
        fixedBottom: {
            position: 'fixed',
            bottom: 0,
            width: 'calc(100% - 300px)',
            marginLeft: 0,
        },
        priceLabel: {
            display: 'inline-block',
        },
        btnAddPay: {
            right: 10,
            position: 'absolute',
            width: 'inherit',
            bottom: 0,
        },
        imagenContainer: {
            width: 80,
            height: 80,
            marginBottom: 0
        },
        iconoContainer: {
            width: 80,
            height: 80,
            margin: 0
        },
        iconoImage: {
            width: 60,
            height: 60
        },
        imagen: {
            width: 80,
            height: 80
        },
        checkIcon: {
            fontSize: '1.6rem'
        },
        button: {
            width: 250
        }
    },
    centerContent: {
        textAlign: 'center',
    },
    text: {
        marginTop: 5,
        marginBottom: 5
    },
    bold: {
        fontWeight: 'bold'
    },
    fontSizeP: {
        fontSize: '0.875rem',
    },
    paymentMethodButton: {
        flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'
    },
    subCardGroup: {
        display: "flex",
        flexDirection: 'row',
        marginTop: 175,
        float: 'left',
        position: 'fixed',
        width: 'calc(100% - 330px)',
        [theme.breakpoints.down('lg')]: {
            marginTop: 175
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 30px)',
            height: 'auto',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            float: 'none',
            alignItems: 'center',
            marginLeft: 15,
            marginTop: 230
        }
    },
});

export default styles;