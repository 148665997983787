import {FC, KeyboardEventHandler, useCallback, useContext, useEffect, useMemo, useState} from "react";

import { withStyles, WithStyles, TextField, TextFieldProps, IconButton, InputAdornment } from "@material-ui/core";
import { translate } from "utils/i18n";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import styles, { colors } from "./styles";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import { MoreInfoContext } from "contexts/more_info.context";
import Icon from "atomic/atoms/icon";

export type TExtraValidation = {
    error:boolean, 
    error_msg: string | undefined,
    componentIG?:string
}

type TMSGError = 'outOfRange_max'|'outOfRange_min' | 'err_required' |'notAllowDecimal'| 'manyDecimals'
type TInput = Pick<TextFieldProps, 'className' | 'variant' | 'size' | 'margin'| 'InputProps' |'error' >  & WithStyles

export interface IInputNumber extends TInput {
    componentIG: string
    labelTerm: string,
    value: number| undefined,
    onChangeValue: (v: number|undefined, err: boolean) => void,
    max?: number;
    min?: number;
    step?: number;
    required: boolean,
    extraValidation?: (v: number|undefined) => TExtraValidation
    disabled?:boolean
    moreInfo?: {
        template: string, size?: TInfoTemplateSize
    },
    errorMsgExterno?:string
    isMoney?:boolean
    helperText?: string // Por defeceto tiene un espacio y se reserva el espacio que ocupa (helperText y msgError). Poner a '' si no se desea reservar el espacio.
    
};


const FixStepInputNumber : FC<IInputNumber>= ({
    classes, labelTerm, value, onChangeValue, // onChangeError,
    max, min=0,step=1, required=false, disabled,
    variant='outlined', size='small', margin='dense', isMoney=false,helperText=' ',
    className, extraValidation, error ,errorMsgExterno, moreInfo,componentIG, ...props
}) => {

    const asMvl = window.screen.width < 960;
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [err, setErr] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState< string|undefined>(undefined)

    const validate = (value: number | undefined, withExtravalidation: boolean) => {
        let msg : TMSGError|undefined = undefined
        let msgComplement= ''
        let maxErr=false;
        let minErr =false;
        let prevError = false

        const requiredErr = required && value === undefined ? true : false
        if(requiredErr){ 
            msg='err_required'
            prevError=true
        } 
        
        maxErr = !prevError && max!== undefined && value ? value > max : false;
        minErr =!prevError &&  min!== undefined && value  ? value < min : false;

        if(maxErr || minErr){ 
            msg = maxErr ? 'outOfRange_max': 'outOfRange_min';
            msgComplement = maxErr ? max?.toString()?? '' : min?.toString() ??''
            prevError=true
        }

        let result: TExtraValidation= {error: false,error_msg: undefined,}
        if(!prevError && extraValidation && withExtravalidation){
            result = extraValidation(value)
            if(result.error){ 
                onChangeValue(value, result.error)
            }
        }

        setErrMsg(  msg 
            ? translate('GenericComponents', msg,terms) +' '+ msgComplement 
            : translate(result.componentIG ?? 'GenericComponents', result.error_msg ?? 'Oops !!',terms)  
        )
        setErr( maxErr || minErr || requiredErr || result.error)
        return maxErr || minErr || requiredErr || result.error
    }


    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // Permitir acciones de teclado para navegación y borrado
        if (!['ArrowUp', 'ArrowDown', 'Tab', 'Backspace', 'Delete', 'Enter'].includes(event.key)) {
          event.preventDefault(); // Previene la entrada de otros caracteres
        }
      };


    useEffect(() => {
        // setea el error que me envían desde fuera
        if(error !== undefined){ 
            setErr(error)
            if(errorMsgExterno && error === true){ setErrMsg(errorMsgExterno)}
            if(error ===false){ setErr(false) }
        }
        if(value){validate(value,true)}
    }, [error, errorMsgExterno, value])

    const handleShowMoreInfo = useCallback(() => {
        if (moreInfo) {
            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: moreInfo.template ?? '',
                    size:moreInfo?.size ?? undefined,
                }
            });
        }
    }, [infoDispatch])
    
    return (
        <div style={{display:'flex', flexDirection: 'row', alignItems:'flex-start', marginRight: 10}} >
            <TextField 
                {...props}
                disabled={disabled}
                className={className ?? classes.inputDefault}
                size={size}
                variant={variant}
                type="number"
                InputProps={{
                    style: {
                        caretColor: "transparent", // Hace el cursor invisible
                        paddingRight: asMvl ? 0: undefined,
                        paddingLeft: asMvl ? 0: undefined

                    },
                    startAdornment: asMvl
                        ?(
                            <InputAdornment position="start">
                            <IconButton onClick={()=>{
                                console.log('onclick - ',value)
                                const newV = value && value > min ? value-step: min
                                console.log('newV - ',newV)

                                const err = validate(newV, false)
                                onChangeValue(newV,err)
                            }}>
                                <Icon name={'minus'} size={1} color={colors.blueIconInfo}/>
                            </IconButton>
                            </InputAdornment>
                        ): undefined,
                    endAdornment: asMvl 
                        ? (
                            <InputAdornment position="end">
                            <IconButton onClick={() => {
                                console.log('onclick + ',value)
                                const newV = value 
                                    ? (value < (max??0)) ? (value+step) : max
                                    : min;
                                console.log('newV + ',newV);

                                const err = validate(newV, false)
                                onChangeValue(newV,err)

                            }}>
                                <Icon name={'plus'} size={1} color={colors.blueIconInfo}/>
                    
                            </IconButton>
                            </InputAdornment>
                        ) :undefined,
                     
                }}
                inputProps={{
                    min: min, 
                    max: max, 
                    step: step,
                    style: {
                        justifyItems: asMvl ? 'center': undefined
                    }
                }}
                label={translate(componentIG,labelTerm, terms).concat(required? '*':'')} 
                value={value ?? ''} 
                onChange={(e: { target: { value: string; }; }) => {
                    const err = validate(Number(e.target.value), false)
                    onChangeValue( e.target.value === '' ? undefined : Number(e.target.value), err)
              
                }} 
                error={err}
                helperText={ err ? (errMsg ?? ' ') : helperText}
                margin={margin}
                onKeyDown={handleKeyDown}
                onBlur={() => {
                    const err = validate(value, true)
                    err && onChangeValue(value,err)
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.inputLabel,
                    },}}
            />
            {moreInfo && 
                <IconButton onClick={handleShowMoreInfo} style={{marginTop: 8}}>
                    <Icon name={'info-outline'} size={1} color={colors.blueIconInfo}/>
                </IconButton>
            }
            
        </div>
           
    )
}

export default withLiterals(ALL_LITERALS)(withStyles(styles)(FixStepInputNumber));