const DNI_REGEX = /^(([KLM])|(\d{1}))(\d{7})([A-Z])$/;
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

export enum TipoDocumento {
    DNI = 'DNI',
    NIE = 'NIE',
    CIF = 'CIF'
}

export function validateNif(nif: string) {
    let valid: boolean | string = false;
    const type = spainIdType(nif);

    switch (type) {
        case TipoDocumento.DNI:
            valid = validDNI(nif);
            break;
        case TipoDocumento.NIE:
            valid = validNIE(nif);
            break;
        case TipoDocumento.CIF:
            valid = validCIF(nif);
            break;
    }

    return valid;
};

export function isCIF(nif: string) {
    // Ensure upcase and remove whitespace
    nif = nif.toUpperCase().replace(/\s/, '');

    const type = spainIdType(nif);
    return type === TipoDocumento.CIF;
};

export function validarDocumentoIdentificativo(doc: string): boolean {
    if (!doc) {
        return false;
    }

    if (doc.length > 15) {
        return false;
    }

    const alfanumericoRegex = /^[a-zA-Z0-9]*$/;
    if (!alfanumericoRegex.test(doc)) {
        return false;
    }

    return true;
}


const spainIdType = function (nif: string) {
    if (nif.match(DNI_REGEX)) {
        return TipoDocumento.DNI;
    }
    if (nif.match(CIF_REGEX)) {
        return TipoDocumento.CIF;
    }
    if (nif.match(NIE_REGEX)) {
        return TipoDocumento.NIE;
    }
    return TipoDocumento.CIF;
};

const isValidDNIinfo = (dni: string): boolean => {
    let dniCheck = dni
    if (dni.charAt(0).match(/[a-z]/i)){
        dniCheck = dni.substring(1)
    }
    const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    const letter = dni_letters.charAt(parseInt(dniCheck, 10) % 23);

    return letter === dni.charAt(8);
}

const isValidDNIformat = (dni: string): boolean => {
    const validFormat = /\d{7,8}\w/;
    return validFormat.test(dni);
}

const validDNI = function (dni: string) {
    if (isValidDNIformat(dni) && isValidDNIinfo(dni)) return dni;
    return false;
};

const validNIE = function (nie: string) {
    // Change the initial letter for the corresponding number and validate as DNI
    let nie_prefix = nie.charAt(0);
    switch (nie_prefix) {
        case 'X': nie_prefix = '0'; break;
        case 'Y': nie_prefix = '1'; break;
        case 'Z': nie_prefix = '2'; break;
    }
    return validDNI(nie_prefix + nie.substr(1));
};

const validCIF = function (cif: string) {
    const match = cif.match(CIF_REGEX);

    if (match) {
        const letter = match[1];
        const number = match[2];
        const control = match[3];

        let even_sum: number = 0;
        let odd_sum: number = 0;
        let n: number;

        for (let i = 0; i < number.length; i++) {
            n = parseInt(number[i], 10);
            // Odd positions (Even index equals to odd position. i=0 equals first position)
            if (i % 2 === 0) {
                // Odd positions are multiplied first.
                n *= 2;
                // If the multiplication is bigger than 10 we need to adjust
                odd_sum += n < 10 ? n : n - 9;
                // Even positions
                // Just sum them
            } else {
                even_sum += n;
            }
        }

        const control_digit = parseInt((10 - parseInt((even_sum + odd_sum).toString().substr(-1), 10)).toString().substr(-1), 10);
        const control_letter = 'JABCDEFGHI'.substr(control_digit, 1);

        // Control must be a digit
        if (letter.match(/[ABEH]/)) {
            return control === control_digit.toString();
            // Control must be a letter
        } else if (letter.match(/[KPQS]/)) {
            return control === control_letter;
            // Can be either
        } else {
            return control === control_digit.toString() || control === control_letter;
        }
    }

    return false;
};
