import { Button, Card, FormControl, InputLabel, makeStyles, Select, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Term from 'components/term';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useCallback } from 'react';
import usePage from 'hooks/page.hook';
import moment from 'moment';
import IoC from 'contexts/ioc.context';
import CitaPreviaGateway from 'gateways/citaPrevia.gateway';
import { IOficinas } from 'gateways/citaPrevia.interface';
import { SelectChangeHandler } from 'utils/events';
import { validateNif } from "utils/validateNif";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        [theme.breakpoints.up('xs')]: {
            marginTop: 50,
            marginRight: 10,
            marginLeft: 10,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 50,
            marginRight: 40,
            marginLeft: 40,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            marginRight: 40,
            marginLeft: 40,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 0,
            marginRight: 40,
            marginLeft: 40,
        },

    },
    formControl: {
        marginRight: 40,
        marginTop: 40,
        [theme.breakpoints.up('xs')]: {
            '& > div': {
                width: 'calc(100%)',
            },
        },
        [theme.breakpoints.up('md')]: {
            '& > div': {
                width: 'calc(100%)',
            },
        },
        [theme.breakpoints.up('lg')]: {
            '& > div': {
                width: 'calc(100%)',
            },
        }
    },
    button: {
        width: 200,
        margin: 50,
        [theme.breakpoints.up('xs')]: {
            float: "unset",
            margin: 40,
        },
        [theme.breakpoints.up('sm')]: {
            float: "unset"
        },
        [theme.breakpoints.up('md')]: {
            float: "unset"
        },
        [theme.breakpoints.up('lg')]: {
            float: "right"
        },
    },

    formContainer: {
        marginLeft: '40px',
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'column',
            marginRight: '40px',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',

        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column',

        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            flexDirection: 'row',
            marginRight: '40px',

        },
    },
    formDivider: {
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'row',
        },
        [theme.breakpoints.up('sm')]: {
            flex: 2,
            display: 'flex',
            flexDirection: 'row',
        },
        [theme.breakpoints.up('md')]: {
            flex: 2,
            display: 'flex',
            flexDirection: 'row',

        },
        [theme.breakpoints.up('lg')]: {
            flex: 2,
            display: 'flex',
            flexDirection: 'row',

        },
    },
}
));

interface IProps {
    handleConfirmar: (codigo: string, fecha: string, oficina: string, doc: string) => void
    titulo: any
}
const CitaPreviaForm: React.FC<IProps> = (props) => {
    const { handleConfirmar, titulo } = props;
    const classes = useStyles();
    const ioc = useContext(IoC);
    const citaPreviaGateway: CitaPreviaGateway = useMemo(() => ioc.get(CitaPreviaGateway), [ioc]);
    const [, pageDispatcher] = usePage();
    const [, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const [oficinaSelec, setOficinaSelec] = useState('')
    const handleToggleSubMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpenSubMenuAnchorEl(event.currentTarget);
    }, []);

    const [fecha, setFecha] = useState<Date | null>(null);
    const [oficinas, setOficinas] = useState<IOficinas[]>([]);
    const [doc, setDoc] = useState('')
    const [codigo, setCodigo] = useState('')

    const [errorDoc, setErrorDoc] = useState(false)
    const [errorFecha, setErrorFecha] = useState(false)
    const [errorOficina, setErrorOficina] = useState(false)
    const [errorCodigo, setErrorCodigo] = useState(false)

    const [errorDocVacio, setErrorDocVacio] = useState(false)
    const [errorFechaVacio, setErrorFechaVacio] = useState(false)
    const [errorCodigoVacio, setErrorCodigoVacio] = useState(false)

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: null,
                title: <Term component="CitaPrevia" text="Titulo Cita Previa" />
            },
            menu: true,
        });
    }, [pageDispatcher, handleToggleSubMenu]);

    useEffect(() => {
        const handleLoadOficinas = async () => {
            const x = await citaPreviaGateway.getOficinas();
            setOficinas(x)
        }
        handleLoadOficinas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleErrorCodigo = useCallback((tipo: boolean) => {
        tipo
            ? setErrorCodigo(true)
            : setErrorCodigoVacio(true)
    }, [])

    const handleErrorFecha = useCallback((tipo: boolean) => {
        tipo
            ? setErrorFecha(true)
            : setErrorFechaVacio(true)
    }, [])

    const handleErrorDoc = useCallback((tipo: boolean) => {
        tipo
            ? setErrorDoc(true)
            : setErrorDocVacio(true)
    }, [])

    const handleErrorOficina = useCallback(() => {
        setErrorOficina(true)
    }, [])

    const handleValidate = useCallback(() => {
        /*Codigo*/
        let result = true
        if (codigo !== 'G-' && codigo !== '') {
            if (!codigo.match(/^(\d{7}|\d{6})$/)) {
                handleErrorCodigo(true)
                result = false;
            }
        }
        else {
            handleErrorCodigo(false)
            result = false;
        }

        /*Oficina*/
        if (oficinaSelec === "") {
            result = false;
            handleErrorOficina()
        }

        /*DNI/CIF*/
        if (doc !== '') {
            if (!validateNif(doc)) {
                handleErrorDoc(true)
                result = false
            }
        }
        else {
            handleErrorDoc(false);
            result = false
        }

        /*Fecha*/
        if (fecha !== null) {
            if (isNaN(fecha.getTime())) {
                handleErrorFecha(true)
                result = false
            }
        }
        else {
            handleErrorFecha(false)
            result = false
        }

        return result
    }, [codigo, oficinaSelec, doc, fecha, handleErrorCodigo, handleErrorOficina, handleErrorDoc, handleErrorFecha])

    const handleConfirmarForm = useCallback(() => {
        if (handleValidate()) {
            if (fecha !== null) {
                handleConfirmar(codigo, moment(fecha).format('YYYY-MM-DD'), oficinaSelec, doc)
            }
        }
    }, [codigo, doc, fecha, handleConfirmar, handleValidate, oficinaSelec])


    const handleOficina: SelectChangeHandler = useCallback((event) => {
        setOficinaSelec(event.target.value)
        setErrorOficina(false)
    }, [])

    const handleFecha = useCallback((date: Date | null) => {
        setErrorFecha(false)
        setErrorFechaVacio(false)
        if (date !== null) {
            if (moment(date) > moment().add(60, 'days')) {
                setFecha(moment().add(60, 'days').toDate())
            }
            else if (moment(date) < moment()) {
                setFecha(moment().toDate())
            }
            else {
                setFecha(date)
            }
        }
    }, []);

    const handleDoc = useCallback((value: string) => {
        setDoc(value.toUpperCase())
        setErrorDoc(false)
        setErrorDocVacio(false)
    }, []);

    const handleCodigo = useCallback((value: string) => {
        if (value.length! <= 9) {
            value.substring(0, 2) !== 'G-' || value === ''
                ? setCodigo('')
                : setCodigo(value.split('-')[1])
            setErrorCodigo(false)
            setErrorCodigoVacio(false)
        }
    }, []);

    return (
        <>
            <div className={classes.root}>
                <Card >
                    <div style={{ fontWeight: 'bold', fontSize: 20, margin: 40, marginBottom: 0 }}>{titulo}</div>
                    <div className={classes.formContainer}>
                        <div className={classes.formDivider}>
                            <FormControl className={classes.formControl} style={{ marginTop: 35 }} fullWidth>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="dense"
                                    format="dd/MM/yyyy"
                                    error={errorFecha || errorFechaVacio}
                                    id="datepicker-fecha"
                                    label={<Term component="CitaPrevia" text="Fecha de la cita" />}
                                    value={fecha}
                                    onChange={handleFecha}
                                    minDate={moment()}
                                    maxDate={moment().add(60, 'days')}
                                />
                                {errorFecha
                                    ? <div style={{ color: '#f44336' }}>
                                        <Term component='CitaPrevia' text='La fecha no es valida' />
                                    </div>
                                    : null}
                                {errorFechaVacio
                                    ? <div style={{ color: '#f44336' }}>
                                        <Term component='Global' text='El campo no puede estar vacio' />
                                    </div>
                                    : null}
                            </FormControl>
                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="DNI/CIF"
                                    value={doc}
                                    onChange={(e) => handleDoc(e.target.value)}
                                    error={errorDoc || errorDocVacio}
                                    inputProps={{ maxLength: 9 }}
                                />
                                {errorDoc &&
                                    <div style={{ color: '#f44336' }}>
                                        <Term component='Global' text='El formato del NIF / CIF no es válido' />
                                    </div>
                                }
                                {errorDocVacio &&
                                    <div style={{ color: '#f44336' }}>
                                        <Term component='Global' text='El campo no puede estar vacio' />
                                    </div>
                                }
                            </FormControl>
                        </div>
                        <div className={classes.formDivider}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel key="label-OFICINAS" htmlFor="select-multiple-chip" error={errorOficina}>
                                    <Term component="Oficinas" text="Oficina" />
                                </InputLabel>
                                <Select
                                    native={true}
                                    value={oficinaSelec}
                                    autoWidth={true}
                                    onChange={handleOficina}
                                    error={errorOficina}
                                >
                                    <option value={""}></option>
                                    {oficinas.map((x: IOficinas) =>
                                        <option key={x.Value} value={x.Value}>{x.Text}</option>
                                    )}
                                </Select>
                                {errorOficina
                                    ? <div style={{ color: '#f44336' }}>
                                        <Term component='Global' text='El campo no puede estar vacio' />
                                    </div>
                                    : null}
                            </FormControl>
                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label={<Term component='CitaPrevia' text='Codigo de la cita'></Term>}
                                    value={'G-' + codigo}
                                    onChange={(e) => handleCodigo(e.target.value)}
                                    error={errorCodigo || errorCodigoVacio}
                                />
                                {errorCodigo
                                    ? <div style={{ color: '#f44336' }}>
                                        <Term component='CitaPrevia' text='El codigo no es valido' />
                                    </div>
                                    : null}

                                {errorCodigoVacio
                                    ? <div style={{ color: '#f44336' }}>
                                        <Term component='Global' text='El campo no puede estar vacio' />
                                    </div>
                                    : null}
                            </FormControl>
                        </div>
                    </div>
                    <div>
                        <Button variant="contained" size="small" className={classes.button} color="primary" onClick={handleConfirmarForm}>
                            <Term component="Global" text="Continuar" />
                        </Button>
                    </div>
                </Card>
            </div>
        </>)
}
export default CitaPreviaForm;