// LIBRARY IMPORTS
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Card, Divider, Grid, InputAdornment, MenuItem, TextField, Typography, makeStyles } from "@material-ui/core";
import { RouteComponentProps, } from "react-router-dom";
import { mdiLaptop } from "@mdi/js";
import moment from "moment";
// LOCAL IMPORTS
import { LiteralsContext } from "containers/shared/literals";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { MoreInfoContext } from "contexts/more_info.context";
import { esConceptoVivienda } from "utils/concepto.vivienda";
import { IRecibo } from "gateways/recibo.interfaces";
import { translate } from "utils/i18n";
import ConceptoIcon from "components/concepto.icon";
import TextNumber from "components/text-number";
import usePage from "hooks/page.hook";
import Term from "components/term";
import Carrito from "../carrito";
// STYLES
import styles from '../../shared/pago-a-cuenta.styles';

type LocationState = { recibo: IRecibo };
interface IParams { estado: 'pendiente' | 'pagado'; };
type Context = any;
type Props = RouteComponentProps<IParams, Context, LocationState>;

const useStyles = makeStyles(styles);

const PagoACuenta: FC<Props> = ({ location, match }) => {
    const recibo = location.state.recibo;
    const { estado } = match.params;

    // HOOKS
    const terms = useContext(LiteralsContext);
    const classes = useStyles();

    const [, infoDispatch] = useContext(MoreInfoContext);
    const [, pageDispatcher] = usePage();

    // LOCAL STATES
    const [importe, setImporte] = useState<undefined | number>(undefined)
    const [error, setError] = useState<boolean>(false)

    // MEMOS
    const disableCarritoAction = useMemo(() => error ? true : false, [error])



    // -------- USE EFFECT --------

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiLaptop,
                title: <Term component="CarteroVirtual" text={'Pago a cuenta'} />,
                moreInfoTemplate: 'mas_info_pago_a_cuenta',
                right: (
                    <MenuItem button onClick={() => handleShowMasInfo('mas_info_pago_a_cuenta')}>
                        <Term component="Global" text="Mas informacion" />
                    </MenuItem>

                ),
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher, terms]);



    // -------- HANDLERS --------

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch])



    return (
        <Card className={classes.cardContainer}>
            {recibo ?
                <>
                    <Grid container direction="column" >

                        {/* TITLE */}

                        <Grid item>
                            <Typography variant="h6" className={classes.title}>
                                {translate("CarteroVirtual", "PagoACuentaTitle", terms).toUpperCase()}
                            </Typography>
                            <div className={classes.row}>
                                <Typography variant="body1" className={classes.text}>
                                    <Term component="CarteroVirtual" text="PagoACuentaText" />
                                    <span className={classes.textRed}>{recibo.fechaFin ? moment(recibo.fechaFin).format('DD/MM/YYYY') : ''}</span>
                                </Typography>
                            </div>
                        </Grid>

                        {/* RECIBO */}

                        <Grid item container direction='row' spacing={2} style={{ marginBottom: 15 }}>

                            {/* TRIBUTO SELECCIONADO*/}

                            <Grid item xs={6} container direction='column'>
                                <Typography className={classes.subTitle}>
                                    <Term component="CarteroVirtual" text="tributoSeleccionado" />
                                </Typography>
                                <Divider className={classes.divider} />
                                <div className={[classes.row, classes.content].join(' ')}>
                                    <div className={classes.imageGrid}>
                                        <div className={classes.imagenContainer}>
                                            <ConceptoIcon concepto={recibo.concepto.idTipo} codigo={recibo.concepto.codigo} />
                                        </div>
                                    </div>
                                    <div className={classes.column}>
                                        <Typography className={classes.highlightText}>
                                            {recibo.organismo.codigo ? recibo.organismo.nombre : recibo.organismo.nombre} | {recibo.ejercicio}  {recibo.periodo !== '' ? '| ' + recibo.periodo : null}
                                        </Typography>
                                        <Typography className={classes.highlightText}>
                                            <Term component="CarteroVirtual" text={recibo.concepto.nombre} />
                                        </Typography>
                                        <Typography className={classes.highlightText}>
                                            {recibo.referencia}
                                        </Typography>
                                        <Typography className={classes.detailText} style={{ marginTop: 10 }}>
                                            {
                                                esConceptoVivienda(recibo.concepto.idTipo) ? recibo.direccionTributaria : recibo.objeto
                                            }
                                        </Typography>
                                        {recibo.fechaInicio && recibo.fechaFin && recibo.fechaFin > recibo.fechaInicio
                                            ? <Typography className={classes.detailText}>del {moment(recibo.fechaInicio).format('DD/MM/YYYY')} al {moment(recibo.fechaFin).format('DD/MM/YYYY')}</Typography>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </Grid>

                            {/*  IMPORTE PENDIENTE  */}

                            <Grid item xs={6}>
                                <Typography className={classes.subTitle}>
                                    <Term component="CarteroVirtual" text="importePendienteTributo" />
                                </Typography>
                                <Divider className={classes.divider} />
                                <div className={[classes.content].join(' ')}>
                                    <TextNumber
                                        component="span"
                                        className={classes.price}
                                        value={recibo.desglose.pendiente}
                                        options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }} />
                                </div>
                            </Grid>
                        </Grid>

                        {/*  IMPORTE A CUENTA QUE DESEA PAGAR */}

                        <Grid item container direction='row' spacing={2}>
                            <Grid item xs={6} container direction="column">
                                <Typography className={classes.subTitle}>
                                    <Term component="CarteroVirtual" text="importeAbonar" />
                                </Typography>
                                <Divider className={classes.divider} />
                                <div className={[classes.content].join(' ')} style={{ marginTop: 10 }}>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        label={translate("CarteroVirtual", "Importe", terms)}
                                        value={importe}
                                        onChange={(e) => {
                                            if (e.target.value && parseFloat(e.target.value) > recibo.desglose.pendiente) {
                                                setError(true)
                                            } else {
                                                setError(false)
                                            }
                                            setImporte(e.target.value ? parseFloat(e.target.value) : undefined)
                                        }}
                                        error={error}
                                        helperText={error ? translate("CarteroVirtual", "ImporteError", terms) : ''}
                                        inputProps={{
                                            step: 0.01, min: 0
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Carrito
                        items={[recibo]}
                        visible={true}
                        pendiente={estado === 'pendiente'}
                        isFromConsultasExternas={false}
                        disableButton={disableCarritoAction}
                        importeAbonado={importe}
                        fullWidth
                    />

                </>
                : <Typography >
                    <Term component="CarteroVirtual" text="ErrorCargaRecibo" />
                </Typography>
            }
        </Card>

    )

}

export default PagoACuenta;