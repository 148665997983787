import { FC,   useMemo,} from "react";
import { StepComponentProps } from "components/generic-stepper/generic-step-component";
import TypedFinJustificante from "./componentes/typed-fin-justificante";
import { useLocation } from "react-router";
import { withLiterals } from "containers/shared/literals";
import { height } from "@fortawesome/free-solid-svg-icons/faFilter";


type FinalizacionJustificanteProps = StepComponentProps

const FinalizacionJustificante: FC<FinalizacionJustificanteProps> = ({...props}) => { 
    const location = useLocation();
    const tipo = useMemo(() => ( location.pathname.includes('alta-solicitud') ? 'solicitud' : 'reintegro'),[location.pathname])
    return <TypedFinJustificante {...props} tipo={tipo}/>
}

export default withLiterals(['Global', 'Tramites','AbonoNacimiento' ])(FinalizacionJustificante);
