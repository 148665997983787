import { Card, IconButton, Typography, Collapse, makeStyles, useTheme } from "@material-ui/core";
import { mdiBriefcaseOutline, mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Term from "components/term";
import { IDatosEmbargo } from "gateways/embargos.interface";
import usePage from "hooks/page.hook";
import { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import Icon from "@mdi/react";
import { EmbargosDetail } from "./embargos.detail";


const useStyles = makeStyles((theme) =>({
  base: {
      margin: "0px 30px",
      width: '100%',
      gap:'20px',
      [theme.breakpoints.down("sm")]: {
        margin: "0px",
      },
    },

    card: {
      padding: 5,
      marginRight: 10, 
      marginLeft: 10,
      marginBottom: 15,  
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'space-arround',
      

  },

    content: {
      gap:'10px',
      marginBottom:5,
      marginTop:10,
      paddingLeft:16,
      fontWeight: 'bold',
      display: 'grid',
      gridTemplateColumns: '2fr 2fr 3fr 3fr 2fr 2fr 1fr',
      
      [theme.breakpoints.down("sm")]: {
        gridTemplateRows: 'repeat(5, 1fr) ',
        gridTemplateColumns: 'repeat(4, 1fr)',   
      },
      
    },
    referencia: {
      [theme.breakpoints.down("sm")]: {
      gridColumn: '1 / 5' ,
      gridRow: 1,
      },
    },

    nif: {
      [theme.breakpoints.down("sm")]: {
      gridColumn: '1 / 6',
      gridRow: 2,
      },
    },

    nombre: {
      [theme.breakpoints.down("sm")]: {
      gridColumn: '1 / 6',
      gridRow: 3,
      },
    },

    concepto: {
      [theme.breakpoints.down("sm")]: {
      gridColumn: '1 / 6',
      gridRow: 4,
      },
    },

    deuda: {
      [theme.breakpoints.down("sm")]: {
        gridColumn: '1 / 2',
        gridRow: 5,
        },
    },

    retenido: {
      [theme.breakpoints.down("sm")]: {
        gridColumn: '4 / 4',
        gridRow: 5,
        marginLeft: 20,
        },
    },

    flecha:{
      width: 50,
      [theme.breakpoints.down("sm")]: {
        gridColumn: '5 / 5',
        gridRow: 1,
        },
    },

    datos:{
      fontWeight: 'bold',
    }

  }))

type Props = RouteComponentProps;

const EmbargosList: FC<Props> = ({ history })  =>{
  const theme = useTheme();
  const classes = useStyles(theme);
  const [{ lang }, pageDispatcher] = usePage();
  const [embargosList, setEmbargosList] = useState<IDatosEmbargo[]>();
  const [expanded, setExpanded] = useState('');
  
  
  useEffect(() => {
    let embargosTemp: IDatosEmbargo[] | undefined = history.location.state as IDatosEmbargo[]
    if(embargosTemp && embargosTemp.length > 0){
      
      setEmbargosList(history.location.state as IDatosEmbargo[])
      
      setEmbargosList(embargosTemp)
      return
    }
    else{
      history.push('/tramites/embargos/')
    }

  setEmbargosList(embargosTemp)
  },[lang])

  useEffect(() => {  
    pageDispatcher({
        type: 'setHeader',
        header: {
            icon: mdiBriefcaseOutline,
            moreInfoTemplate: "mas_info_embargo",
            title: <Term component="Tramites" text="ConsultaEmbargos" />,
        },
        menu: true,
    });
}, [pageDispatcher]);

const toggleExpanded = (embargo: IDatosEmbargo) => {
  setExpanded(expanded === embargo.refe ? '' : embargo.refe);
};

    return (
      <div className={classes.base} >
        {embargosList ?
          embargosList.map((embargo : IDatosEmbargo)=> (
            <Card className={classes.card} key={embargo.refe}>
              <div className={classes.content}>
                <div className={classes.referencia}>
                  <Typography><Term component="Global" text= "Referencia" /></Typography>
                  <Typography className={classes.datos}>{embargo.refe}</Typography>
                </div>
                <div className={classes.nif}>
                  <Typography><Term component="Global" text="Nif" /></Typography>
                  <Typography className={classes.datos}>{embargo.nif}</Typography>
                </div>
                <div className={classes.nombre}>
                  <Typography><Term component="Global" text="Nombre" /></Typography>
                  <Typography className={classes.datos}>{embargo.nom}</Typography>
                </div>
                <div className={classes.concepto}>
                  <Typography><Term component="Global" text="Concepto" /></Typography>
                  <Typography className={classes.datos}>{embargo.Concepto}</Typography>
                </div>
                <div className={classes.deuda}>
                  <Typography><Term component="Global" text="Deuda" /></Typography>
                  <Typography className={classes.datos}>{embargo.ImporteAPagar.toString().replace('.', ',')}</Typography>
                </div>
                <div className={classes.retenido}>
                  <Typography ><Term component="Global" text="Retenido" /></Typography>
                  <Typography className={classes.datos}>{embargo.ImporteRetenido.toString().replace('.', ',')}</Typography>
                </div>
                <IconButton className={classes.flecha} onClick={() => toggleExpanded(embargo)} /* Para que sea clicable, TO DO funcion onClick={}*/>
                  <Icon path={embargo.refe === expanded ? mdiChevronDown : mdiChevronUp} size={1.5}></Icon>
                </IconButton>
              </div>
                <Collapse style={{width:'100%'}} in={embargo.refe === expanded} timeout="auto" unmountOnExit >
                <EmbargosDetail embargo={embargo}></EmbargosDetail>
                </Collapse> 
            </Card>  
          ))
      : <></>
        }
      </div>
    )
}

export default EmbargosList