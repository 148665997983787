import { FC, useEffect, useContext, useReducer, useCallback } from 'react';
import { Dialog, Button, DialogTitle, DialogActions, makeStyles } from "@material-ui/core";
import Term from '../../../components/term';
import FiltersForm, { ScreenType } from './filters.form';
import { FiltersContext } from './filters.context';
import FiltersReducer, { IFiltersState } from './filters.reducer';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        textAlign: 'center'
    },
    main: {
        padding: 20
    },
}));

interface IProps {
    open: boolean;
    screen: ScreenType;
    onClose: () => void;
    onChange: (query: IFiltersState) => void;
}

const FiltersDialog: FC<IProps> = ({ open, onClose, onChange, screen }) => {
    const [filters, filtersDispatch] = useContext(FiltersContext);

    const filtersReducer = useReducer(FiltersReducer, filters);
    const [newFilters, newFiltersDispatch] = filtersReducer;

    const classes = useStyles();
    const handleClose = useCallback(() => onClose(), [onClose]);

    const handleApplyFilters = useCallback(() => {
        filtersDispatch({ type: 'update-any', filters: newFilters });
        handleClose();
        onChange(newFilters);
    }, [filtersDispatch, newFilters, handleClose, onChange]);

    useEffect(() => {
        newFiltersDispatch({ type: 'update-any', filters });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <FiltersContext.Provider value={filtersReducer}>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Term component="Global" text="Filtrar expedientes" />
                </DialogTitle>
                <div className={classes.main}>
                    <FiltersForm 
                        screen={screen}
                    />
                </div>
                <DialogActions style={{flex: 1, alignItems:'flex-end', marginBottom: '10px', marginRight: '10px'}}>
                    <Button onClick={handleClose} variant="outlined" color="default">
                        <Term component="Global" text="Cerrar" />
                    </Button>
                    <Button color="primary" variant="outlined" onClick={handleApplyFilters}>
                        <Term component="Global" text="Aplicar filtros" />
                    </Button>
                </DialogActions>
            </Dialog>
        </FiltersContext.Provider>
    )
};

export default FiltersDialog;