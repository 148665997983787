import { CircularProgress } from "@material-ui/core";
import React, {FC} from "react";

const InfiniteScrollLoader: FC<{show: boolean}> = ({show}) => {
    return (
        <div key="infiniteProgress" 
            style={{ 
                display: show ? 'block' : 'none',
                textAlign: 'center',
                paddingTop: 250,
                width: '100%',
            }} 
        >
                <CircularProgress />
        </div>
    )
}

export default InfiniteScrollLoader;