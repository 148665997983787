import { StyleRules, Theme } from "@material-ui/core";

export const styles = (theme: Theme): StyleRules => ({
    root: {
        flexGrow: 1,
    },
    progressContainer: {
        textAlign: 'center',
        marginTop: '20%'
    },
    recibosContainer: {
        flex: 1,
        margin: '0 0.5rem'
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            flexShrink: 0,
            width: 300,
        },
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    [theme.breakpoints.up('md')]: {
        root: {
            display: 'flex',
        },
        recibosContainer: {
            margin: '0 2rem'
        },
    },
    identificacionDialog: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '1000px !important',
            minHeight: '325px !important',
        }
    },
    actionsContainer: {
        display: 'inline-flex',
        marginTop: 20,
        width: '100%',
        height: 100
    },
    actionContent: {
        marginRight: 20,
    },
    actionIcon: {
        width: 42,
        heigth: 42,
        fill: 'grey'
    },
    border: {
        border: '1px solid lightgrey',
        borderRadius: 10
    },
    formControlRadioBtns: {
        gap: '1rem',
        marginTop: 5
    },
    // FLEX
    alignCenter: {
        alignItems: 'center',
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },


})