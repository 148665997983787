// LIBRARY IMPORTS
import { useEffect, useState } from "react";
import { Button, StyleRules, Typography, makeStyles } from "@material-ui/core";
// LOCAL IMPORTS
import { dateTransformIntoRedeableFormat } from "utils/dates";
import { GenericModal } from "components/generic-modal";
import { TextField } from "components/text-field";
import Term from "components/term";

const styles = (): StyleRules => ({
    // Generic styles
    wFull: {
        width: '100%'
    },
    wHalf: {
        width: '50%'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    mTop50: {
        marginTop: 50
    },
    mTop20: {
        marginTop: 20
    },
    bold: {
        fontWeight: 'bold'
    },
    fontXL: {
        fontSize: 20
    },
    // Specific styles
    container: {
        minHeight: '20rem',
        marginTop: 30,
        display: 'flex',
        gap: '50px',
    },
    containerBtns: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '20px'
    },
    span: {
        height: 2,
        display: 'block',
        backgroundColor: 'black'
    },
    importeDeudaPendiente: {
        fontWeight: 'bold',
        color: 'red',
        textAlign: 'end',
        fontSize: 20,
        marginTop: 10,
    }
});

const useStyles = makeStyles(styles);

interface IDialogSeleccionarImportePorPeriodoProps {
    isOpen: boolean;
    date: string;
    importeTotal: string;

    onClose: () => void;
    onContinuar: (importe: string) => void;
}

const DialogSeleccionarImportePorPeriodo = (props: IDialogSeleccionarImportePorPeriodoProps) => {
    const { isOpen, date, importeTotal, onContinuar, onClose } = props;

    // HOOKS
    const classes = useStyles();

    // STATES
    const lang = localStorage.getItem('lang');

    const [importeParaAbonar, setImporteParaAbonar] = useState('');
    const [deudaPendiente, setDeudaPendiente] = useState(importeTotal);

    const [error, setError] = useState<'' | 'pago_cuenta_domiciliacion_importe_requerido' | 'pago_cuenta_domiciliacion_importe_maximo'>('');



    // -------- USE EFFECT --------

    useEffect(() => {
        setImporteParaAbonar('');
        setError('');
        setDeudaPendiente(importeTotal);
    }, [isOpen]);



    // -------- HANDLERS --------

    const handleImporteParaAbonar = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^\d*(\.\d{0,2})?$/;

        if (regex.test(e.target.value)) {
            setImporteParaAbonar(e.target.value);
            handleDeudaPendiente(e.target.value);
        }
    };

    const handleDeudaPendiente = (importe: string) => {
        const importeParsed = parseFloat(importe.replace(',', '.'));
        const importeTotalParsed = parseFloat(importeTotal.replace(',', '.'));

        if (isNaN(importeParsed)) {
            setDeudaPendiente(importeTotal);
            return;
        }

        setDeudaPendiente((importeTotalParsed - importeParsed).toFixed(2).toString());
    };

    const handleContinuar = () => {
        if (parseFloat(importeParaAbonar) < 1 || isNaN(parseFloat(importeParaAbonar))) {
            setError('pago_cuenta_domiciliacion_importe_requerido');
            return;
        }
        else if (parseFloat(importeParaAbonar) > parseFloat(importeTotal)) {
            setError('pago_cuenta_domiciliacion_importe_maximo');
            return;
        }

        onContinuar(importeParaAbonar);
    }

    return (
        <GenericModal
            open={isOpen}
        >
            <Typography variant="h6" className={classes.bold}>
                <Term component="CarteroVirtual" text="periocidad_e_importes" />
            </Typography>

            {/* FECHA SELECCIOANDA */}

            <div className={classes.container}>
                <div className={classes.wHalf}>
                    <Typography className={classes.bold}>
                        <Term component="CarteroVirtual" text="fecha_cobro_seleccionada" />
                    </Typography>
                    <span className={classes.span} />

                    <Typography className={classes.mTop20}>
                        {date !== '' && dateTransformIntoRedeableFormat(date, lang)}
                    </Typography>
                </div>

                {/* IMPORTE A ABONAR */}

                <div className={[classes.wHalf, classes.flexColumn].join(' ')}>
                    <Typography className={classes.bold}>
                        <Term component="CarteroVirtual" text="importe_a_abonar" />
                    </Typography>
                    <span className={classes.span} />

                    <TextField
                        id="importe"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        label="Importe"
                        value={importeParaAbonar.toString()}
                        placeholder="1.00"
                        className={classes.mTop20}
                        onChange={handleImporteParaAbonar}
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                        }}
                        error={error !== ''}
                        helperText={error !== '' && <Term component="CarteroVirtual" text={error} />}
                    />

                    {/* DEUDA PENDIENTE */}

                    <Typography className={[classes.bold, classes.mTop50].join(' ')}>
                        <Term component="CarteroVirtual" text="deuda_pendiente" />
                    </Typography>
                    <span className={classes.span} />
                    <Typography className={classes.importeDeudaPendiente}>{deudaPendiente} €</Typography>
                </div>
            </div>

            {/* CANCELAR Y CONTINUAR */}

            <div className={classes.containerBtns}>
                <Button onClick={onClose}>
                    <Term component="Global" text="cancelar" />
                </Button>
                <Button variant="contained" color="primary" onClick={handleContinuar}>
                    <Term component="Global" text="continuar" />
                </Button>
            </div>
        </GenericModal>
    );
}

export default DialogSeleccionarImportePorPeriodo;