import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { mdiHomeAccount } from "@mdi/js";
import Term from "components/term";
import usePage from "hooks/page.hook";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, Grid, MenuItem, makeStyles, useTheme } from "@material-ui/core";
import MisBienesIcon from '../../resources/bienesSujeto/BienesSuj.png';
import ValoracionesIcon from '../../resources/bienesSujeto/Valoraciones.png';
import { TMenuItem } from "containers/shared/menu-item-types";
import BoxMenuItem from "components/box-menu-item";
import styles from "containers/shared/container-home";
import ModalAcceso from "components/modal-acceso";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { MoreInfoContext } from "contexts/more_info.context";

interface IRouteParams {}

type Props = RouteComponentProps<IRouteParams>;

const useStyles = makeStyles(styles);
const BienesHome: FC<Props> = ({match, history}) => {
    const classes= useStyles()
    const [showLogin, setShowLogin] = useState(false);
    const [pageState, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);
    const isMobile = window.innerWidth <= 590;
    const MENU_ITEMS: TMenuItem[] = [
        { title: {component: "Global", text:"BienesSujeto"}, img: MisBienesIcon, children : [
            { title: {component: "Global", text:"Inmuebles"}, link: 'bienes-sujeto/inmuebles', protected: 2, idModulo: 8},
            { title: {component: "Global", text:"Vehiculos"}, link: 'bienes-sujeto/vehiculos', protected: 2, idModulo: 9},

        ] },
        { title: {component: "Global", text:"ValoracionesBienesSujeto"}, img: ValoracionesIcon , children : [
            { title: {component: "BienesSujeto", text:"valoracion_bienes"}, link: `https://www.atib.es/TA/valoraciones/default.aspx?lang=`, isExternLink: true, protected: 0},
        ] }
    ]

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch])

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiHomeAccount,
                title: <Term component="Global" text="BienesSujeto" />,
                text: <Term component="Global" text="BienesSujetoDescripcion" />,
                moreInfoTemplate: 'mas_info_bienes_sujeto_general',
                right : (
                    <MenuItem button onClick={() => handleShowMasInfo('mas_info_bienes_sujeto_general')}>
                        <Term component="Global" text="Mas informacion" />
                    </MenuItem> 
            ),
            },
            menu: true,
            footer: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    return(
        <>
        <Card className={classes.root}>
            <Grid container direction='row' justify="flex-start">
                {MENU_ITEMS.map( (menuitem, idx) => (
                    <Grid className={classes.widget} item xs={12} md={6} xl={4} key={`bienes-${idx}`}>
                        <BoxMenuItem 
                            item={menuitem} 
                            onLogin={(accessLevel) => {
                                if(isMobile){history.push('/acceso', { goBack: history.location.pathname }) } 
                                else {
                                    if(accessLevel === 2){history.push('/acceso/clave', { goBack: history.location.pathname } )}
                                    if(accessLevel === 1){setShowLogin(true)}
                                } 
                            }} 
                        />
                    </Grid>
                    
                ))}
            </Grid>
        </Card>
        <ModalAcceso openModalAcceso={showLogin} setOpenModalAcceso={setShowLogin} history={history} />
        </>
        
    )
}

export default withRouter(BienesHome);

