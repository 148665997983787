import { CircularProgress, withStyles, WithStyles } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styles from './cita-previa.styles';
import { RouteComponentProps, withRouter } from 'react-router';
import usePage from 'hooks/page.hook';
import Term from 'components/term';
import CitaPreviaForm from 'components/cita-previa-form';
import IoC from 'contexts/ioc.context';
import CitaPreviaGateway from 'gateways/citaPrevia.gateway';
import { AlertsContext } from 'contexts/alerts.context';
import { IArgumentosJustificante } from 'gateways/citaPrevia.interface';
import { download } from 'utils/download';
import { mdiCalendarClock } from '@mdi/js';
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from "utils/i18n";

type Props = WithStyles<typeof styles> & RouteComponentProps;

const Justificante: React.FC<Props> = ({ classes }) => {
    const ioc = useContext(IoC);
    const citaPreviaGateway: CitaPreviaGateway = useMemo(() => ioc.get(CitaPreviaGateway), [ioc]);
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const handleToggleSubMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpenSubMenuAnchorEl(event.currentTarget);
    }, []);
    const [loading, setLoading] = useState(false)
    const terms = useContext(LiteralsContext);


    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiCalendarClock,
                title: <Term component="CitaPrevia" text="Titulo Cita Previa" />,
                text: <Term component="CitaPrevia" text="Subtitulo cita previa" />
            },
            menu: true,
        });
    }, [pageDispatcher, handleToggleSubMenu]);

    const handleJustificante = useCallback(async (codigo: string, fecha: string, oficina: string, doc: string) => {
        setLoading(true)
        let response: Blob
        let justificanteForm: IArgumentosJustificante;
        justificanteForm = {
            AppointmentCode: "G-" + codigo,
            Dni: doc,
            OfficeCode: oficina,
            Date: fecha,
        }
        try {
            response = await citaPreviaGateway.getJustificanteCitaBlob(justificanteForm)
            download(response, alertsDispatch, translate('CitaPrevia', 'Ha ocurrido un error', terms), 'Justificante')
            setLoading(false)
        }
        catch {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message:
                        <Term component="CitaPrevia" text="Ha ocurrido un error" />,
                    variant: 'warning',
                }
            })
        }
    }, [alertsDispatch, citaPreviaGateway, terms])
    return (
        <>{loading ?
            <div className={classes.content}>
                <CircularProgress size={30} />
            </div>
            :
            <>
                <CitaPreviaForm handleConfirmar={handleJustificante}
                    titulo={<Term component='CitaPrevia' text='Justificante' />}
                />
            </>
        }
        </>)
}
export default withRouter(withStyles(styles)(Justificante));