import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, Grid, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { mdiAccountQuestion } from "@mdi/js";
import BooleanRadioButton from "components/DUTI/boolean-radio-button";
import { TRepresentante } from "containers/DUTI/steps/types";
import { BLUE } from "containers/shared/colors";
import { LiteralsContext } from "containers/shared/literals";
import usePage from "hooks/page.hook";
import { translate } from "utils/i18n";
import Icon from "@mdi/react";
import DUTIGateway from "gateways/duti.gateway";
import IoC from "contexts/ioc.context";
import CheckBox from "components/DUTI/check-box";
import Modal from "components/modal/index";
import SujetoForm from "./sujetoForm";
import { TSujeto } from "containers/tributos-autonomicos-locales/componentesAsistente/shared/types";


interface IProps extends WithStyles<typeof styles>{
    actuaNombrePropio: boolean | undefined, 
    checkRepresentacion: boolean,
    representante: TSujeto|undefined;
    onChange: (actuaNomP:boolean, repre:TSujeto|undefined, err:boolean , check: boolean) => void
    moreInfoActuaNombrePropio?:{template: string;size: string;}
}
const ActuaRepresentacion:FC<IProps> = ({ classes,actuaNombrePropio,checkRepresentacion,representante,onChange,moreInfoActuaNombrePropio})=>{

    const terms = useContext(LiteralsContext);
    const [pageState, pageDispatcher] = usePage();

    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    //const contenidoGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);
    const OPTIONS = [
        {id: true, term: 'actuaNomprePropio_si'},
        {id: false, term: 'actuaNomprePropio_no'},
    ]

    // const [ checkRepresentacion, setCheckRepresentacion ] = useState(false)
    const [ errActuaNombrePropio,setErrActuaNombrePropio ] = useState(false)

    const [ representanteJWP, setRepresentanteJWP] = useState<TRepresentante| undefined>(undefined)
    const [ representado, setRepresentado]= useState<{nif: string, nomApe:string} | undefined>(undefined)
    const [ openModalSuj, setOpenModalSuj] = useState(false);




    useEffect(() => {
        (async () => {
            if(pageState.jwp){
                console.log(pageState.jwp)
                if(pageState.jwp && pageState.jwp.nifRepresentante){
                    if(!representante || (representante && representante.nif === pageState.jwp.nifRepresentante)){
                        const suj = await dutiGateway.getSujeto(pageState.jwp.nifRepresentante)
                        if (suj) {
                            setRepresentanteJWP({
                                contacto: suj.contacto,
                                direccion: suj.direccion,
                                nif: suj.nif,
                                nomApe: suj.nomApe,
                                personaFisica: suj.personaFisica
                            })
                        }
                    }
                }
                if(pageState.jwp && pageState.jwp.nif && pageState.jwp.fullname){
                    setRepresentado({nif:pageState.jwp.nif, nomApe:pageState.jwp.fullname})
                }
            } else{
                setRepresentado(undefined);
                setRepresentanteJWP(undefined)
            }
            
        })();
    }, [pageState.jwp]);

    useEffect(() => {
        if(!actuaNombrePropio){
            setErrActuaNombrePropio(representante && representante.nif ? false: true)
        }
    },[representante, actuaNombrePropio])


    return(
        <Grid item container direction="column">
            <BooleanRadioButton row
                options={OPTIONS} 
                componentIG='Plusvalias'
                fieldTerm="actuaNombrePropio"
                value={actuaNombrePropio}
                onChangeValue = { (v: boolean) => {
                    const err = !v && !representante?.nif ? true : false
                    onChange(v, v ===true ? undefined: representante ,err, checkRepresentacion)
                }}
                moreInfo= {moreInfoActuaNombrePropio}
            />
                            
            {actuaNombrePropio === false &&
                <div className={classes.textWarning}>
                    <div style={{marginRight: 20}}>
                        <Icon path={mdiAccountQuestion} color={BLUE} style={{alignSelf:'flex-start'}} size={3} />                   
                    </div>
                    <div className={classes.column}>
                    { /** Estamos logueados en representación ?
                     *  Sí => solo podemos actuar en represnetcaión dekl ususario al que representamos
                     *  No => acceso al formulario de sujeto, no se permite insertar NIF del usuario logueado (en caso de que lo haya)
                     */}
                        { (representanteJWP && representanteJWP.nif) ?
                                <CheckBox 
                                    value={checkRepresentacion} 
                                    onChangeValue={(v: boolean) => {
                                        
                                        const err= !actuaNombrePropio && representanteJWP && representanteJWP.nif && v ? false : true
                                        setErrActuaNombrePropio(err)
                                        onChange(actuaNombrePropio, v ? representanteJWP: undefined, err, v)
                                        
                                    }}
                                    fieldTerm="checkRepresentacion" 
                                    error={errActuaNombrePropio}
                                    errorMsg={translate('DUTI', 'confirm_required', terms)}    
                                    addToLabel={ `: ${representado?.nomApe} - ${representado?.nif}`}
                                    style={{height: 20,alignItems: 'flex-start' }}
                                />  
                            :
                            <Grid container direction="column"> 
                                <Grid item>
                                    <div className={classes.row}>
                                        <Typography color={ "inherit"}>
                                            {translate('Plusvalias', 'representanteText', terms)}
                                        </Typography>
                                        <Button style={{marginLeft: 10}}
                                            variant="contained" 
                                            color ="primary" 
                                            onClick={() => setOpenModalSuj(true)}
                                        >
                                            {translate('Plusvalias', 'datosRepresentante',terms)}
                                        </Button> 
                                    </div>

                                </Grid>
                                <Grid item>
                                { representante && representante.nif && 
                                    <Typography>
                                        { `${representante.nomApe} - ${representante.nif}`}
                                    </Typography>
                                }
                                {errActuaNombrePropio && 
                                    <Typography style={{marginTop: 5}} color={'error'}>
                                        {translate('Plusvalias','err_fill_repre_info',terms)}
                                    </Typography>
                                }
                                </Grid>
                            </Grid>
                             
                        }
                    </div>
                    
                </div>   
            }

            {/**Modal Actions included in content */}
            <Modal
                open={openModalSuj}
                disableBackdropClick
                fullWidth
                maxWidth='lg'
                title={translate('Plusvalias', 'datosRepresentado',terms)}
                renderContent={()=>{ 
                    return (
                        <>
                            <SujetoForm
                                renderType='modal'
                                action='new'
                                allowChargeRepresentanteLoggued={false}
                                allowChargeLogged={false}
                                initialInfo={representante}
                                notAllowedNIFs={ 
                                    // No Actua en nombre propio -> no permitir nif usuario logueado
                                    !actuaNombrePropio && pageState.jwp && pageState.jwp.nif 
                                    ? [ pageState.jwp.nif ] 
                                    : []    
                                }
                                notAllowedNIFsMsgErr={translate('Plusvalias','can_not_be_yourself',terms)}
                                onSave = {(action:'new'|'edit', rep:TRepresentante) => {
                                    if(actuaNombrePropio !== undefined){
                                        onChange(actuaNombrePropio, rep, false, false)
                                        return {ok: true, msgErr: undefined}
                                    } else{
                                        return {ok: false, msgErr: translate('Plusvalias', 'err_actua_nom_propio_undefined',terms)}
                                    }
                                    
                                }}
                                onEnd = {()=> setOpenModalSuj(false)}
                            />
                        </>
                    )
                }}
            />
            
        </Grid>
    )
}

const styles =  (theme: Theme): StyleRules => ({
    textWarning:{
        paddingTop: 10,
        paddingLeft: 10,
        paddingBottom: 10,
        paddingRight: 30,
        border:'solid',
        borderColor: BLUE,
        borderRadius: 10, 
        borderWidth: 2, fontWeight: 450,
        marginTop: 10,
        marginBottom:10,
        display:'inline-flex', 
        alignContent:'flex-start',
        alignItems: 'center'
    },
    column: {
        display:'flex',
        flexDirection: 'column'
    },
    row: {
        display:'flex',
        flexDirection: 'row',
        alignItems: 'flex-end'
    }
})

export default withStyles(styles)(ActuaRepresentacion)