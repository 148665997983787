import { StyleRules } from "@material-ui/core";

const styles = (): StyleRules => ({
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    alignCenter: {
        alignItems: 'center'
    },
    gap20: {
        gap: '20px'
    },
    padding20: {
        padding: 20
    }
});

export default styles;