import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { BLUE } from "containers/shared/colors";

export const colors = {
   blueIconInfo: '#004f82', //10%, #dee2e6 ,
   white: '#FFF',
   blueBtn: BLUE,
}
const styles =  (theme: Theme): StyleRules => ({   
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
  
    inputLabel: {
        backgroundColor: 'white',
        paddingRight: 5,
        paddingLeft:5
    },
    input70: { // Small: puerta, esc
        width: 70
    },
    input100: { // Small: puerta, esc
        width: 100
    },
    input160: { // 
        width: 160
    },
    input200: { // 
        width: 200
    },
    input360: { // mediano: nombre
        width: 360
    },
    input545: {
        width: 545
    },
    inputDefault: { // mediano: nombre
        width: 260
    },
    inputFullWidth: { // Small: puerta, esc
        width: '100%'
    },
    
})

export default styles