import { FC, useContext, Fragment } from "react";
import { makeStyles, Grid, FormControl } from "@material-ui/core";
import styles from '../../shared/tributos.styles';
import MultasGrid from "./multas.grid";
import { FilterDataContext } from "./filter-data.context";
import { IMultas } from "gateways/tramites.interfaces";
import LabelField from 'components/label-field';
import Term from "components/term";
import { dateFormatToUTC } from "utils/dates";

const useStyles = makeStyles(styles);

interface IMultaCardProps {
    multa: IMultas;
    mensajeAclaratorio?: string;
    handleIdentificar: (multa: IMultas) => void;
    isRentACar: boolean;
    handleNotAllowedDocuments: () => void;
    idReciboAIdentificar?: number;
}

const MultaCard: FC<IMultaCardProps> = ({ multa, mensajeAclaratorio, handleIdentificar, isRentACar, handleNotAllowedDocuments, idReciboAIdentificar }) => {
    const classes = useStyles();

    const filterData = useContext(FilterDataContext);

    return (
        <Fragment>
            <MultasGrid
                multa={multa}
                handleIdentificar={handleIdentificar}
                filterData={filterData}
                mensajeAclaratorio={mensajeAclaratorio}
                isRentACar={isRentACar}
                handleNotAllowedDocuments={handleNotAllowedDocuments}
                idReciboAIdentificar={idReciboAIdentificar}>
                <Grid container
                    spacing={1}
                    className={[classes.mainButton, classes.recibo, classes.pointer].join(' ')}>
                    <Grid item xs={12} sm={4}>
                        <FormControl>
                            <LabelField
                                label={<Term component="Tramites" text="concepto" />}
                                text={multa.concepto}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl>
                            <LabelField
                                label={<Term component="Tramites" text="fechaMulta" />}
                                text={dateFormatToUTC(multa.fechaMulta, 'DD/MM/YYYY HH:mm')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl>
                            <LabelField
                                label={<Term component="Tramites" text="boletin" />}
                                text={multa.boletin}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <FormControl>
                            <LabelField
                                label={<Term component="Tramites" text="importe" />}
                                text={multa.importe.toLocaleString() + '€'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <FormControl>
                            <LabelField
                                label={<Term component="Tramites" text="gravedad" />}
                                text={<Term component="Tramites" text={'gravedad_' + multa.gravedad.toLocaleLowerCase()} />}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl>
                            <LabelField
                                label={<Term component="Tramites" text="lugar" />}
                                text={multa.lugar}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl>
                            <LabelField
                                label={<Term component="Tramites" text="municipio" />}
                                text={multa.municipio}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl>
                            <LabelField
                                label={<Term component="Tramites" text="marca" />}
                                text={multa.marca}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl>
                            <LabelField
                                label={<Term component="Tramites" text="articulo" />}
                                text={multa.articulo}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </MultasGrid>


        </Fragment>
    );
};


export default MultaCard;