import { useContext, useCallback, useState, useEffect } from "react";
import usePage from "hooks/page.hook";
import { makeStyles, Button } from "@material-ui/core";
import Term from 'components/term';
import { Icon } from '@mdi/react'
import { MoreInfoContext } from "contexts/more_info.context";

const useStyles = makeStyles((theme) => {
    const mdValue = theme.breakpoints.values.md
    const tenPercentOfMd = mdValue * 0.3;
    return {
        pageTitle: {
            display: 'none',
            fontWeight: 300,
            fontSize: 25,
            color: '#004f84',
            textTransform: 'uppercase',
        },
        [theme.breakpoints.up('md')]: {
            pageTitle: {
                display: 'flex',
                alignItems: 'center',
                // paddingBottom: '1rem',
                // borderBottom: '1px solid #a5b2b9',
            },
            iconContainer: {
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#004f82',
                padding: 7,
                borderRadius: '50%',
                marginRight: 10,
            },
            button: {
                marginLeft: 20,
                padding: '0 20px',
            },
            pageText: {
                fontSize: 14,
                color: '#004f84',
                fontWeight: 900,
            },
        },
        pageTitleHide: {
            [theme.breakpoints.down(tenPercentOfMd + mdValue)]: {
                display: 'none'
            }
        }
    }
});

const PageTitle = () => {
    const [{ header }] = usePage();
    const classes = useStyles();
    const [, infoDispatch] = useContext(MoreInfoContext);

    const handleShowMoreInfo = useCallback(() => {
        if (header && header.moreInfoTemplate) {
            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: header.moreInfoTemplate,
                    size: header.moreInfoTemplateSize
                }
            });
        }
    }, [infoDispatch, header])

    

    return header
        ? <div>
            <h1 className={classes.pageTitle} style={(header.title as string).length > 150 ? { fontSize: 18 } : (header.title as string).length > 100 ? { fontSize: 20 } : {}}>
                <div className={classes.pageTitleHide}>
                    {
                        header.icon
                            ? <span className={classes.iconContainer}>
                                <Icon
                                    path={header.icon}
                                    size={0.6}
                                    color="white"
                                />
                            </span>
                            : null
                    }

                    {typeof header.title === 'string' ? <Term component="Global" text={header.title} /> : header.title}
                </div>

                {header.moreInfoTemplate ?
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleShowMoreInfo} size="small">
                        <Term component="Global" text="Más información" />
                    </Button>
                    :
                    null}
            </h1>
            <p className={classes.pageText}>
                {
                    typeof header.text === 'string'
                        ? <Term component="Global" text={header.text} />
                        : (
                            !!header.text
                                ? header.text
                                : null
                        )
                }
            </p>
        </div>
        : null
}

export default PageTitle;