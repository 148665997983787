import { useEffect, useState } from "react";
import { IDiasCortesia } from "gateways/notificaciones.interfaces";
import { getMomentFromDate, getTodayStart } from "utils/dates";
import moment from "moment";

interface IProps {
    diasVoluntaria: IDiasCortesia | undefined;
    fechaDomiciliacionIncompatible: string | null;
}

const useCheckFechaDomiciliable = (props: IProps) => {
    const { diasVoluntaria, fechaDomiciliacionIncompatible } = props;
    // States
    const [isOpenModalIncompatible, setIsOpenModalIncompatible] = useState<boolean>(false);

    useEffect(() => {
        if (!fechaDomiciliacionIncompatible) return;

        const tomorrow = moment().add(1, 'days').startOf('day');
        const isTodayInVoluntariaDaysArray = diasVoluntaria
            ? diasVoluntaria.dias.findIndex(dia => moment(dia.fecha).startOf('day').isSame(getTodayStart()) && dia.modificable)
            : false;

        // Fecha límite es fecha actual o anterior a la actual
        if (fechaDomiciliacionIncompatible && getMomentFromDate(fechaDomiciliacionIncompatible).isSameOrBefore(getTodayStart())) {
            setIsOpenModalIncompatible(true);
        }
        // Fecha límite es mañana y fecha actual no está en días voluntaria seleccionables para domiciliación voluntaria 
        else if (getMomentFromDate(fechaDomiciliacionIncompatible).startOf('day').isSame(tomorrow) && isTodayInVoluntariaDaysArray) {
            setIsOpenModalIncompatible(true);
        }
    }, [fechaDomiciliacionIncompatible, diasVoluntaria]);


    return {
        isOpenModalIncompatible
    }
}

export default useCheckFechaDomiciliable;
