import { IModelGateway } from "./modelGateway.interface";

export interface IDatosPago {
    modelos: IModelGateway[];
    formasPago: FormasPagoEnum[];
}

export enum FormasPagoEnum {
    BE = 0, // Banca electrónica
    TJ = 1, // Tarjeta de crédito
    DII = 2, // Documento de ingreso individual
    DIG = 3, // Documento de ingreso grupal
}