import { FC, useContext, useState, useEffect, useMemo } from 'react';
import { Chip, makeStyles } from '@material-ui/core';

import { LiteralsContext } from '../../shared/literals';
import { FiltersContext } from './filters.context';
import { FilterDataContext } from './filter-data.context';
import { IFiltersState } from './filters.reducer';
import { translate } from "utils/i18n";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    chip: {
        marginTop: 5,
        marginRight: 2,
        marginLeft: 2
    },
    filterList: {
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        paddingTop: 5,
        paddingBottom: 15
    }
}));

interface IFiltersActiveProps {
    onChange: (query: IFiltersState) => void;
}

const FiltersActive: FC<IFiltersActiveProps> = ({ onChange }) => {
    const terms = useContext(LiteralsContext);
    const filterData = useContext(FilterDataContext);
    const [allFilters, setAllFilters] = useState<Array<{ type: keyof IFiltersState, value: string | number, label: string }>>([]);
    const anyFilters = useMemo(() => allFilters.length > 0, [allFilters]);
    const [filters, filtersDispatch] = useContext(FiltersContext);
    const classes = useStyles();

    useEffect(() => {
        let allFiltersAux = ([] as Array<{ type: keyof IFiltersState, value: string | number, label: string }>);

        for (const filterKey in filters) {
            if (filterKey in filters) {
                let filtersProp = (filters as any)[filterKey];
                if (!!filtersProp && !(filtersProp instanceof Array)) {
                    filtersProp = [filtersProp];
                }
                if (filtersProp) {
                    switch (filterKey) {
                        case 'estado':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: translate('Tramites', 'Estado_' + x, terms)
                            })));
                            break;
                        case 'tipoExpediente':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: filterData.tipoExpediente ? filterData.tipoExpediente.filter(y => y.value === x).map((y) => translate('Tramites', y.label, terms))[0] || x : x
                            })));
                            break;
                        case 'fecha':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: translate('Tramites', 'Fecha Alta', terms) + ' ' + moment(filters.fecha).format('DD/MM/YYYY')
                            })));
                            break;
                        case 'modelo':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: translate('Tramites', 'modelo', terms) + ' ' + x
                            })));
                            break;
                        case 'nrd':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: translate('Tramites', 'nrd', terms) + ' ' + x
                            })));
                            break;
                        default:
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: x
                            })));
                            break;

                    }
                }
            }
        }

        setAllFilters(allFiltersAux);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, terms]);

    const handleDelete = (filter: { type: keyof IFiltersState, value: string | number }) => () => {
        const currentFilterValues: any = (filters as any)[filter.type];
        let valueToAssign: any = filter.value;

        if (currentFilterValues instanceof Array) {
            valueToAssign = (currentFilterValues as any[]).filter(x => x !== filter.value);
            filtersDispatch({ type: 'update', filter: filter.type, value: valueToAssign });
        } else {
            valueToAssign = undefined;
            filtersDispatch({ type: 'update', filter: filter.type, value: undefined });
        }

        const newFilters = { ...filters };
        newFilters[filter.type] = valueToAssign;
        onChange(newFilters);
    };

    return anyFilters ? (
        <div className={classes.filterList}>
            {
                allFilters.map(x => (
                    <Chip
                        key={x.value}
                        label={x.label}
                        onDelete={handleDelete(x)}
                        className={classes.chip}
                    />
                ))
            }
        </div>
    ) : null;
}

export default FiltersActive;