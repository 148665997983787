import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'react-phone-number-input/style.css'
import 'bootstrap/dist/css/bootstrap.css'

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { Providers } from 'providers';

library.add(faFilter, faCheckSquare, faCheck, faInfoCircle, faCheckCircle);
// Sentry.init({ dsn: "https://bb73e86de60d45098fecb242d1d20e0a@sentry.intricom.es/18" });

let waitingConfirmation = false;
window.addEventListener('keyup', (e) => {
    if (e.shiftKey && e.which === 76) {
        e.preventDefault();
        e.stopPropagation();
        if (waitingConfirmation) {
            if (window.confirm('¿Quieres restablecer los literales?')) {
                const keys: string[] = [];
                for (let i = 0; i < sessionStorage.length; i++) {
                    const key = sessionStorage.key(i);
                    if (key && key.indexOf('norm_literals') === 0) {
                        keys.push(key);
                    }
                }
                keys.forEach(x => sessionStorage.removeItem(x));
                window.location.reload();
            }
            waitingConfirmation = false;
        } else {
            waitingConfirmation = true;
        }
    } else {
        waitingConfirmation = false;
    }
});

ReactDOM.render(<Providers />, document.getElementById('root'));
