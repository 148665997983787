import {FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import { Dialog, DialogTitle, Typography, DialogContent, Grid, DialogActions, withStyles, WithStyles, CircularProgress, Button, Icon, Divider } from "@material-ui/core";
import { TDTInfoCatastral, TTipoTransmitente, TTramitesGest } from "containers/DUTI/steps/types";
import { translate } from "utils/i18n";
import InmuebleInfo from "./inmueble-info";
import duti_styles from "containers/DUTI/styles";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import { ResponseTramitesMunicipio } from "gateways/duti.interface";
import CheckBox from "./check-box";
import BooleanRadioButton from "./boolean-radio-button";
import RadioButton from "./radio-button";
import LabelWithInfo from "./label-with-info";
import { TSelectDUTI } from "containers/DUTI/utils";


import { CustomError } from "utils/custom.error";
import usePage from "hooks/page.hook";

interface IProps extends WithStyles {
    show: boolean,
    data: TTramitesGest,
    infoCatastral: TDTInfoCatastral
    muni: TSelectDUTI,
    tipoTransmitente: TTipoTransmitente, 
    actuaNombrePropio: boolean,
    nifSuj: string,
    saveData: (data: TTramitesGest, haveChanges:boolean) => void,
    cancel: () => void,
    setShouldBlock: (v: boolean) => void,
}

const ModalTramitesGest: FC<IProps> = ({classes, show,data,infoCatastral,muni,actuaNombrePropio,tipoTransmitente,nifSuj, saveData, cancel, setShouldBlock}) => {
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext)
    const [, pageDispatcher] = usePage();
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);

    const [loading, setLoading] = useState(false)
    const [changes, setChanges] = useState(false)
    const [esPersFisica, setEsPersFisica] = useState(false)

    const componentMounted = useRef(true)

    const [localData, setLocalData] = useState<TTramitesGest>({ COM_DOMFISC:false, DUSTI:false, IBI:false,MODELO600:false,PLUSVAL:false, TASAS_MUNI: false})
    const [localDataError, setLocalDataError] = useState<Record<keyof TTramitesGest, boolean>>({ COM_DOMFISC:false, DUSTI:false, IBI:false,MODELO600:false,PLUSVAL:false, TASAS_MUNI: false})
    const [contentTramitesModal, setContentTramitesModal]  = useState<ResponseTramitesMunicipio[]>([]);

    const updateLocalData = (key : keyof TTramitesGest, val: boolean| Record<string, boolean | undefined>) => {
        //console.log('updateLocalData', key, val)
        setLocalData(curr =>( {...curr, [key]: val}))
        setChanges(true)
    }

    const renderSimpleGestTramite = (tramite: ResponseTramitesMunicipio, value: boolean, update: (v:boolean) => void) => {
        return(
            <>
                <CheckBox key={tramite.idTramite} boldLabel fieldTerm={tramite.term}
                    value={value}
                    onChangeValue={update}
                />
                {tramite.childs.map( c => (
                    <div key={c.idTramite} className={classes.row} style={{paddingRight:10, paddingLeft: 30}}>
                        <Typography  style={{color: value ? 'black': '#bdbdbd', paddingRight:10}} key={c.idTramite+'_dot'} >-</Typography> 
                        <Typography style={{color: value ? 'black': '#bdbdbd'}} key={c.idTramite}>{translate('DUTI', c.term, terms)}</Typography> 
                        {/* <CheckBox fieldTerm={c.term}/> */}
                    </div>
                ))} 
            </>
        )
    }

    const renderPlusvalGestTramite = (tramite: ResponseTramitesMunicipio, value: Record<string, boolean|undefined> | boolean, update: (v: Record<string, boolean|undefined> | boolean) => void) => {
        const ID_COM = tramite.childs.find(c => c.term === 'tramite_plusvalia_comunicacion_atib')?.idTramite.toString()
        const ID_AUTOL = tramite.childs.find(c => c.term === 'tramite_plusvalia_autoliquidacion_atib')?.idTramite.toString()
        const ID_DECL = tramite.childs.find(c => c.term === 'tramite_plusvalia_declaracion_atib')?.idTramite.toString()

        const comAtib = tramite.childs.find(c => c.term === 'tramite_plusvalia_comunicacion_atib')
        const options = tramite.childs.filter(c => c.term !== 'tramite_plusvalia_comunicacion_atib') 
        const alloptions = comAtib && tipoTransmitente === 'comprador' ? [options[0], comAtib] : [options[0]]
        const rbtn_opt= alloptions.map(o => ({id: o.idTramite.toString(), term: o.term}))
        console.log(' value ', value, 'rbtn_opt', rbtn_opt)
        const val = typeof value !== "boolean" 
            ? value.autoliquidacion === true
                ? ID_AUTOL 
                : value.declaracion ===true
                    ? ID_DECL  
                    : value.comunicacion ===true ? ID_COM : undefined
            : undefined
        //console.log('val', val, typeof val)
        return(
            <div className={classes.column}>
                <div className={classes.row} style={{alignItems: 'center'}}>
                    <CheckBox key={tramite.idTramite} boldLabel 
                        fieldTerm={tramite.term}
                        value={value ? true : false}
                        onChangeValue={(v: boolean) =>{
                            if(v){ 
                                update(typeof value === "boolean" 
                                    ? {
                                        sujetoPasivo: undefined,
                                        declaracion: false,
                                        autoliquidacion:false,
                                        comunicacion:false,
                                    }: {...value}
                                )
                            } else {
                                update(false)
                            }
                            setLocalDataError({ ...localDataError, PLUSVAL: false})
                        }}
                    />
                    {localDataError.PLUSVAL && <Typography color="error">{translate('DUTI','error_modal_tramites_plusval',terms)}</Typography>}
                </div>
                {/** condicion sujeto de plusvalia - Todos residentes españa */}
                <div className={classes.row} style={{paddingRight:10, paddingLeft: 30, alignItems: 'center'}}>   
                    <div style={{marginRight: 15}}>
                        <LabelWithInfo type='text'  fieldTerm={'tramite_gest_ask_plusval'} />
                    </div>
                    <BooleanRadioButton disabled={typeof value === "boolean"}
                        fieldTerm={''}
                        labelAs="notLabel"
                        row
                        value={typeof value !== 'boolean' ? value.sujetoPasivo: undefined   }
                        onChangeValue={ (v: boolean) =>{ 
                            if( typeof value !== 'boolean' ){
                                update({...value, 
                                    sujetoPasivo: v, 
                                    comunicacion: rbtn_opt.length === 1 && rbtn_opt[0].id === ID_COM ? true:  false, 
                                    autoliquidacion:rbtn_opt.length === 1 && rbtn_opt[0].id === ID_AUTOL ? true:  false, 
                                    declaracion: rbtn_opt.length === 1 && rbtn_opt[0].id === ID_DECL ? true:  false, 
                                })
                            }
                            setLocalDataError({...localDataError, PLUSVAL: false})
                        }}
                        options={[{id:true , term:'radioButton_si'}, {id: false , term:'radioButton_no'}]}
                    />
                </div>

                {/** Selector tramites PLUSVAL */}
                {typeof value !== 'boolean' &&  value.sujetoPasivo !== undefined &&  
                <div className={classes.row} style={{paddingRight:10, paddingLeft: 30}}>
                    <RadioButton 
                        fieldTerm={''} //'tramite_gest_ask_plusval'}
                        labelAs="notLabel"
                        row
                        disabled={typeof value === 'boolean' }
                        value={val}
                        onChangeValue={ (v: string)=> { 
                            if(typeof value !== 'boolean'){  
                                update({
                                    ...value, 
                                    comunicacion: v === ID_COM , 
                                    autoliquidacion:v === ID_AUTOL, 
                                    declaracion:v === ID_DECL, 
                                })
                            }
                            setLocalDataError({...localDataError, PLUSVAL: false})
                        }}
                        options={rbtn_opt}
                    />
                    
                </div>
                }

               
            </div>
        )
    }

    const renderM600GestTramite =  (tramite: ResponseTramitesMunicipio, value: Record<string, boolean|undefined> | boolean, update: (v:Record<string, boolean|undefined> | boolean) => void) => {
        const options = tramite.childs.map( c => ({id: c.idTramite.toString(), term: c.term}))
        const ID_AUTOLIQ = tramite.childs.find(t => t.term==='tramite_modelo_600_autoliquidacion')?.idTramite.toString() //'101'
        const ID_AUTOLIQPRESENT =  tramite.childs.find(t => t.term==='tramite_modelo_600_autoliquidacion_presentacion')?.idTramite.toString() // '102
        const val = typeof value === 'boolean'
            ? undefined
            : value.autoliquidacion === true 
                ? ID_AUTOLIQ 
                : value.autoliquidacionPresentacion === true 
                    ? ID_AUTOLIQPRESENT
                    : undefined;
        return(
            <div className={classes.column}>
                <div className={classes.row} style={{alignItems: 'center'}}>
                    <CheckBox key={tramite.idTramite} boldLabel 
                        fieldTerm={tramite.term}
                        value={value ? true : false}
                        onChangeValue={(v: boolean) =>{
                            if(v) {
                             update(typeof value === "boolean" ?
                                {   autoliquidacion:  false,
                                    autoliquidacionPresentacion: false
                                } : {...value})
                            } else { update(false) } 
                            setLocalDataError({...localDataError, MODELO600: false})
                        } }

                    />
                    {localDataError.MODELO600 && <Typography color="error">{translate('DUTI','error_modal_tramites_plusval',terms)}</Typography>}
                </div>
               <div style={{paddingRight:10, paddingLeft: 30}}>
                    <RadioButton disabled={ typeof value === 'boolean' }
                        fieldTerm={''}
                        labelAs="notLabel"
                        row
                        value={val}
                        onChangeValue={( val:string ) => {
                            setLocalDataError({...localDataError, MODELO600: false})
                            if(typeof value !== 'boolean'){  
                                update({...value,
                                    autoliquidacion: ID_AUTOLIQ === val ? true : false,
                                    autoliquidacionPresentacion: ID_AUTOLIQPRESENT === val ? true : false,
                                })
                            }
                        }}
                        options={options}
                    />
                </div>
            </div>
        )
    }

    const validate = () => {
        //console.log(localData)
        const errors = {...localDataError,
            MODELO600: localData.MODELO600 !== false  && (!localData.MODELO600.autoliquidacion && !localData.MODELO600.autoliquidacionPresentacion) ? true: false,
            PLUSVAL: localData.PLUSVAL !== false && (
                localData.PLUSVAL.sujetoPasivo === undefined || (!localData.PLUSVAL.autoliquidacion && !localData.PLUSVAL.comunicacion && !localData.PLUSVAL.declaracion)
                ) ? true : false
        }
        setLocalDataError({...errors})
        return Object.values(errors).includes(true) ? false: true
    }

    useEffect(() => {
        (async() => {
            setLoading(true)
            try {
                const tramitesMuni = await dutiGateway.getTramitesMunicipio(muni.id,  tipoTransmitente, actuaNombrePropio)
                const esPersFisica = await dutiGateway.esPersonaFisica(nifSuj)
                console.log('tramitesMuni ', tramitesMuni)
                if(componentMounted){
                    setLocalData({
                        ...data,
                        TASAS_MUNI: tramitesMuni.find( t => t.muniGest === 'TASAS_MUNI') ? data.TASAS_MUNI : false,
                        IBI: tramitesMuni.find( t => t.muniGest === 'IBI') ? data.IBI : false,
                        COM_DOMFISC: tramitesMuni.find( t => t.muniGest === 'COM_DOMFISC')  && esPersFisica 
                            ? data.COM_DOMFISC 
                            : false, //tipoTransmitente === "comprador" ? data.COM_DOMFISC : false,
                        PLUSVAL: 
                            tramitesMuni.find( t => t.muniGest === 'PLUSVAL') === undefined 
                            || Object.keys(data.PLUSVAL).length === 0 || typeof data.PLUSVAL ==='boolean' 
                            || infoCatastral.tipoBien === "RU"
                                ? false 
                                : {...data.PLUSVAL},
                        MODELO600: 
                            tramitesMuni.find( t => t.muniGest === 'MODELO600') === undefined  
                            || Object.keys(data.MODELO600).length === 0 || typeof data.MODELO600 ==='boolean' 
                            || tipoTransmitente === 'vendedor'
                                ? false
                                : {...data.MODELO600, 
                                    autoliquidacion: data.MODELO600.autoliquidacion!== undefined ? data.MODELO600.autoliquidacion : false,
                                    autoliquidacionPresentacion: data.MODELO600.autoliquidacionPresentacion!== undefined ? data.MODELO600.autoliquidacionPresentacion : false
                                },              
                    });
                    
                    setContentTramitesModal(tramitesMuni);
                    setEsPersFisica(esPersFisica);
                    setChanges(false);
                }
            } catch (error) {
                if (error instanceof CustomError && error.code === 403) {
                    setShouldBlock(false);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Global', 'sesion_caducada', terms),
                            variant: 'warning',
                            hasCustomAction: true,
                            handleCustomAction: () => { 
                                pageDispatcher({ type: "logout" });
                            }
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('DUTI','error_dusti', terms),
                            variant: "error"
                        }
                    });
                }
            } finally {
                setLoading(false);
            }
            
            return () => { // This code runs when component is unmounted
                componentMounted.current = false; // set it to false when we leave the page
            }
        })()
        
    }, [data])


    return (   
        <Dialog open={show} fullWidth maxWidth='lg'>
            <DialogTitle>
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    <Typography style={{fontSize: 32, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>{translate('DUTI', 'title_DTPrevios_tramites_dispo', terms)}</Typography>
                </div>
            </DialogTitle> 
            
            <DialogContent>
                {loading ?
                    <div style={{display:'flex', flexGrow: 1, alignItems:'center', justifyItems: 'center'}}>
                        <CircularProgress size={50}/>
                    </div>
                :
                    <Grid container direction='column' spacing={1} style={{paddingLeft: 20, paddingRight:20}} alignItems="flex-start">
                        <Typography>{translate('DUTI', 'subtitle_DTPrevios_tramites_dispo', terms)}</Typography>
                        <Grid item container direction="column" alignItems="center" style={{width: '100%'}}>
                            <div style={{width: '100%', backgroundColor: '#dfdfdf', height: 1, display:'flex', flexGrow:1, marginBottom:10}}></div>
                            <Grid container direction="row" alignItems="center" >
                                <Grid item xs={3} style={{display:'flex', justifyContent: 'center'}}>
                                    <img width={'100%'} src={`https://www.atib.es/App_Themes/LogosMuni/${muni.code}.png`} alt={'muni logo'} />
                                </Grid>
                                <Grid item xs={9} style={{display:'flex', justifyContent: 'center'}}>
                                    <InmuebleInfo
                                        refCatastral={infoCatastral.refCatastral} 
                                        domicilio={infoCatastral.direccion}  
                                        tipoBien={infoCatastral.tipoBien}
                                        urlCatastro={infoCatastral.urlCatastro}
                                        urlGoogle={infoCatastral.urlGoogle}
                                        uso={infoCatastral.datosEconomicos?.uso}
                                    />
                                </Grid>
                            </Grid>
                           
                            <div style={{width: '100%', backgroundColor: '#dfdfdf', height: 1, display:'flex', flexGrow:1, marginTop:10}}></div>
                        </Grid>
                        <Grid item>
                        {   
                            contentTramitesModal.map( t => {
                                if( ["COM_DOMFISC","IBI","TASAS_MUNI"].includes(t.muniGest) 
                                    && (t.muniGest !=="COM_DOMFISC" || (t.muniGest ==="COM_DOMFISC" && esPersFisica))
                                ){ 
                                    return  <Grid item key={t.idTramite}>{renderSimpleGestTramite(t, localData[t.muniGest] as boolean , (v) => updateLocalData(t.muniGest, v))}</Grid>
                                } else if( t.muniGest === 'PLUSVAL' && infoCatastral.tipoBien !== "RU" ){
                                    return <Grid item key={t.idTramite}>{renderPlusvalGestTramite(t, localData[t.muniGest] , (v) => updateLocalData(t.muniGest, v))}</Grid>
                                } else if(t.muniGest === 'MODELO600'){
                                    return <Grid item key={t.idTramite}>{renderM600GestTramite(t, localData[t.muniGest] , (v) => updateLocalData(t.muniGest, v))}</Grid>
                                } else {
                                    return null
                                }
                            })
                        }
                        </Grid>

                    </Grid> 
                }
            </DialogContent>
            

            <DialogActions>
                <div style={{display: 'flex',justifyContent:'flex-end', paddingRight: 10, paddingBottom: 10}}>
                    <Button style={{marginRight: 15}}
                            variant="contained" color="inherit"
                            onClick={cancel} 
                            disabled={loading}
                        >
                        {translate('DUTI', 'btn_close',terms)}
                    </Button>
                    
                    <Button
                        variant="contained" color="primary"
                        onClick={() => { 
                            if(validate()){
                                saveData(localData, changes)
                            }
                           
                        }} 
                        disabled={loading}
                    >
                        {translate('DUTI', 'btn_continuar',terms)}
                    </Button>
                </div>
            </DialogActions>
        
        </Dialog> 
    )   

}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(ModalTramitesGest));