import Term from "components/term";
import usePage from "hooks/page.hook";
import { mdiAccountMultiple } from "@mdi/js";
import React, { FC, useContext, useEffect, useMemo, useState } from "react"
import { Card, CardContent, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import IoC from "contexts/ioc.context";
import { RepresentacionesGateway } from "gateways/tramites/representaciones.gateway";
import { RouteComponentProps } from "react-router";
import { ISolicitudRepresentacion } from "gateways/tramites/representaciones.interfaces";
import moment from "moment";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        margin: '0 1.5rem',
    },
    marginRow: {
        marginBottom: '1.5rem'
    },
    bodySection: {
        marginTop: '1.5rem'
    },
    upper: {
        textTransform: 'uppercase',
    }
}))

export const FichaDePoderApoderado: FC<{ ficha: ISolicitudRepresentacion }> = ({ ficha }) => {
    const classes = useStyles();

    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="NUMERO DE REFERENCIA" />
                    </Typography>
                    <Typography variant="body2">{ficha.referencia}</Typography>
                </Grid>
                <Grid item xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="NIF PODERDANTE" />
                    </Typography>
                    <Typography variant="body2">{ficha.nifPoderdante}</Typography>
                </Grid>
                <Grid xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="NOMBRE PODERDANTE" />
                    </Typography>
                    <Typography variant="body2">{ficha.nombrePoderdante}</Typography>
                </Grid>
                <Grid xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="DESCRIPCION DEL PROCEDIMIENTO" />
                    </Typography>
                    <Typography variant="body2">
                        <Term component="Representaciones" text={`PROCEDIMIENTO_${ficha.codigoProcedimiento}_NOMBRE`} />
                    </Typography>
                </Grid>
                <Grid xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="Fecha alta" />
                    </Typography>
                    <Typography variant="body2">{moment(ficha.fechaAlta).format('DD/MM/YYYY')}</Typography>
                </Grid>
                <Grid xs={12} md={3} className={[classes.marginRow, classes.upper].join(' ')}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="Fecha fin autorización" />
                    </Typography>
                    <Typography variant="body2">{moment(ficha.fechaFinVigencia).format('DD/MM/YYYY')}</Typography>
                </Grid>
                <Grid xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="ESTADO" />
                    </Typography>
                    <Typography variant="body2">
                        <Term component="Representaciones" text={`PROCEDIMIENTO_${ficha.idEstado}_ESTADO`} />
                    </Typography>
                </Grid>
                <Grid xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="CÓDIGO DEL PROCEDIMIENTO" />
                    </Typography>
                    <Typography variant="body2">{ficha.codigoProcedimiento}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export const FichaDePoderPoderdante: FC<{ ficha: ISolicitudRepresentacion }> = ({ ficha }) => {
    const classes = useStyles();

    return (
        <div className={classes.bodySection}>
            <Grid container>
                <Grid item xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="NUMERO DE REFERENCIA" />
                    </Typography>
                    <Typography variant="body2">{ficha.referencia}</Typography>
                </Grid>
                <Grid item xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="NIF PODERDANTE" />
                    </Typography>
                    <Typography variant="body2">{ficha.nifPoderdante}</Typography>
                </Grid>
                <Grid xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="NOMBRE PODERDANTE" />
                    </Typography>
                    <Typography variant="body2">{ficha.nombrePoderdante}</Typography>
                </Grid>
                <Grid xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="DESCRIPCION DEL PROCEDIMIENTO" />
                    </Typography>
                    <Typography variant="body2">
                        <Term component="Representaciones" text={`PROCEDIMIENTO_${ficha.codigoProcedimiento}_NOMBRE`} />
                    </Typography>
                </Grid>
                <Grid xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="Fecha alta" />
                    </Typography>
                    <Typography variant="body2">{moment(ficha.fechaAlta).format('DD/MM/YYYY')}</Typography>
                </Grid>
                <Grid xs={12} md={3} className={[classes.marginRow, classes.upper].join(' ')}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="Fecha fin autorización" />
                    </Typography>
                    <Typography variant="body2">{moment(ficha.fechaFinVigencia).format('DD/MM/YYYY')}</Typography>
                </Grid>
                <Grid xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="ESTADO" />
                    </Typography>
                    <Typography variant="body2">
                        <Term component="Representaciones" text={`PROCEDIMIENTO_${ficha.idEstado}_ESTADO`} />
                    </Typography>
                </Grid>
                <Grid xs={12} md={3} className={classes.marginRow}>
                    <Typography variant="subtitle2">
                        <Term component="Representaciones" text="CÓDIGO DEL PROCEDIMIENTO" />
                    </Typography>
                    <Typography variant="body2">{ficha.codigoProcedimiento}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export const FichaDeRepresentacion: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
    const [page, pageDispatcher] = usePage();
    const classes = useStyles();
    const [tipoPoder, setTipoPoder] = useState<'apoderado' | 'poderdante'>('apoderado');
    const [ficha, setFicha] = useState<ISolicitudRepresentacion | null>(null);

    const ioc = useContext(IoC);
    const representacionesGateway = useMemo(() => ioc.get(RepresentacionesGateway) as RepresentacionesGateway, [ioc]);

    useEffect(() => {
        (async () => {
            if (!page || !page.jwp || !page.jwp.nif) {
                return;
            }
            const response = await representacionesGateway.getOne(parseInt(match.params.id));
            const tipoPoderAux = response.nifApoderado === page.jwp.nif ? 'apoderado' : 'poderdante';
            setTipoPoder(tipoPoderAux);
            setFicha(response);
        })();
    }, [match, representacionesGateway, page])

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiAccountMultiple,
                title: <Term component="Representaciones" text="Apoderamiento" />,
                text: <Term component="Representaciones" text="alta_poder" />
            },
            menu: true,
        });
    }, [pageDispatcher]);

    if (!match.params.id || ficha === null || !page || !page.jwp || !page.jwp.nif) {
        return (
            <CircularProgress />
        );
    }

    return (
        <div className={classes.root}>
            <Card>
                <CardContent>
                    <Typography component="h2" variant="h6">
                        <Term component="Representaciones" text="valor_referencia" params={[ficha.referencia]} />
                    </Typography>
                    {tipoPoder === 'apoderado' && <FichaDePoderApoderado ficha={ficha} />}
                    {tipoPoder === 'poderdante' && <FichaDePoderPoderdante ficha={ficha} />}
                </CardContent>
            </Card>
        </div>
    )
}