import React, { FC, useState, useContext, useEffect } from 'react';
import { withStyles, Theme, WithStyles, IconButton, InputLabel, FormControl, Grid, Input, InputAdornment, Card, CardContent, Button, RadioGroup, FormControlLabel, Radio, CircularProgress, FormHelperText, Typography } from "@material-ui/core";
import Term from '../../components/term';
import { ISujeto } from 'gateways/perfil.interfaces';
import { amber } from '@material-ui/core/colors';
import { StyleRules } from '@material-ui/styles';
import { Icon } from '@mdi/react';
import { mdiPencil } from '@mdi/js';
import PasswordDialog from './modals/password.modal';
import { GridDirection } from '@material-ui/core/Grid';
import IoC from 'contexts/ioc.context';
import { SujetosGateway } from 'gateways/sujetos.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import usePage from 'hooks/page.hook';
import { LiteralsContext } from 'containers/shared/literals';
import { dateFormat } from 'utils/dates';

const styles = (theme: Theme): StyleRules => ({
    formControl: {
        marginBottom: 10,
        marginLeft: 20,
        display: 'block',
        '& > div': {
            width: '100%',
        },
        '& > button': {
            width: '100%',
        }
    },
    containerBtnsPerfil: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    button: {
        padding: '0px 40px'
    },
    containerFechaValidaBtnCambiarDatos: {
        display: 'flex',
        alignItems: 'center',
        gap: '2rem'
    },
    warningMessage: {
        color: amber[900],
        fontWeight: 'bold',
    },
    bold: {
        fontWeight: 'bold',
    },
    [theme.breakpoints.up('md')]: {
        title: {
            padding: '0 20px',
            fontSize: '1.17em',
            fontWeight: 'bold',
        },
        button: {
            marginTop: 40,
            marginLeft: 20,
        },
        formContainer: {
            '& > .MuiGrid-item': {
                padding: '0 20px'
            }
        },
        formContainerWithoutGrid: {
            '& > .MuiFormControl-root': {
                padding: '0 20px'
            }
        }
    }
});

interface IProps extends WithStyles<typeof styles> {
    sujeto: ISujeto;
    direccion: GridDirection;
}

const PersonalData: FC<IProps> = ({ classes, direccion, sujeto }) => {
    // Contexts 
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    const terms = useContext(LiteralsContext);
    // States
    const [movil, setMovil] = useState(sujeto.movil);
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [savingLanguage, setSavingLanguage] = useState(false);
    const [checkIdioma, setCheckIdioma] = useState<string>(sujeto.idiomaEnvioAvisos);
    const [fechaDatosValidados, setFechaDatosValidados] = useState<Date | null>(null);
    // const [allowEditDirection, setAllowEditDirection] = useState(movil !== null || sujeto.email !== null);

    // Gateways
    const ioc = useContext(IoC);
    const perfilG = ioc.get(SujetosGateway) as SujetosGateway;

    // eslint-disable-next-line

    const handleChangePassword = () => {
        setPasswordDialogOpen(true);
    }

    const handleModalClosePassword = () => {
        setPasswordDialogOpen(false);
    };

    const handleUpdateDataSujeto = () => {
        pageDispatcher({ type: "open-info-datos-sujeto" });
    }

    const handleChangeIdioma = async (language: string) => {
        if (language !== '') {
            setCheckIdioma(language);
            setSavingLanguage(true);
            try {
                sujeto.idiomaEnvioAvisos = language;
                if (await perfilG.verificarDatos(sujeto)) {
                    pageDispatcher({
                        type: 'change-lang',
                        lang: language
                    });
                    pageDispatcher({
                        type: 'show-notification',
                        payload: {
                            message: translate('Perfil', 'El idioma se ha cambiado correctamente', terms),
                            variant: 'success',
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Perfil', 'No se ha podido cambiar el idioma', terms),
                            variant: 'error',
                        }
                    });
                    setCheckIdioma(sujeto.idiomaEnvioAvisos);
                }
            } catch (e) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Perfil', 'No se ha podido cambiar el idioma', terms),
                        variant: 'error',
                    }
                });
                setCheckIdioma(sujeto.idiomaEnvioAvisos);
            } finally {
                setSavingLanguage(false);
            }
        } else {
            setSavingLanguage(false);
        }
    }

    useEffect(() => {
        (async () => {
            const fechaDatosValidados: Date | null = await perfilG.getFechaValidacionDatos();
            setFechaDatosValidados(fechaDatosValidados);
        })();
    }, []);

    return (
        <section>
            <Card>
                <CardContent>
                    <Grid container direction={direccion}>
                        <Grid item xs={9}>
                            <h3 className={classes.title}><Term component="Perfil" text="Mis datos" /></h3>
                            <Grid container className={classes.formContainer} direction={direccion}>
                                <Grid item xs={12}>
                                    <Grid container item xs={12} spacing={3} direction={direccion}>
                                        <Grid item xs>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor={"adornment-name" + direccion}>
                                                    <Term component="Global" text="Nombre" />
                                                </InputLabel>
                                                <Input
                                                    id={"adornment-name" + direccion}
                                                    value={sujeto.nombre}
                                                    inputProps={{
                                                        tabIndex: 1,
                                                    }}
                                                    readOnly={true}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor={"adornment-nif" + direccion}>
                                                    <Term component="Global" text="Nif" />
                                                </InputLabel>
                                                <Input
                                                    id={"adornment-nif" + direccion}
                                                    value={sujeto.nif}
                                                    inputProps={{
                                                        tabIndex: 2,
                                                    }}
                                                    readOnly={true}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={3} direction={direccion}>
                                        <Grid item xs>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor={"adornment-email-personal" + direccion} shrink={true}>
                                                    <Term component="Global" text="Email" />
                                                </InputLabel>
                                                <Input
                                                    id={"adornment-email-personal" + direccion}
                                                    value={sujeto.email}
                                                    readOnly={true}
                                                    inputProps={{
                                                        tabIndex: 4,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor={"adornment-movil" + direccion} shrink={true}>
                                                    <Term component="Global" text="Movil" />
                                                </InputLabel>
                                                <Input
                                                    id={"adornment-movil" + direccion}
                                                    value={movil}
                                                    readOnly={true}
                                                    inputProps={{
                                                        tabIndex: 5,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={3} direction={direccion}>
                                        <Grid item xs>
                                            
                                            <FormControl className={classes.formControl}>
                                                <InputLabel >
                                                    <Term component="Global" text="Dirección" />
                                                </InputLabel>
                                                <Input
                                                    id={"adornment-address" + direccion}
                                                    value={sujeto.direccionNotificacionString}
                                                    readOnly={true}
                                                    inputProps={{
                                                        tabIndex: 3,
                                                    }}
                                                />
                                                <FormHelperText id="email-helper-text" className={classes.warningMessage}>
                                                    <Term component="Perfil" text="DomicilioNotificacion" />
                                                </FormHelperText>

                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={3} xs={12}> 
                            <h3 className={classes.title}><Term component="Perfil" text="Elegir idioma de comunicacion" /></h3>
                            <FormControl className={classes.formControl}>
                                <RadioGroup
                                    aria-label="Idioma"
                                    name="language"
                                    className={classes.group}
                                    value={(checkIdioma === 'c' || checkIdioma === 'ca') ? 'ca' : 'es'}
                                    onChange={(e, value) => handleChangeIdioma(value)}
                                >
                                    <Grid container alignItems="center">
                                        <FormControlLabel disabled={savingLanguage} value="es" control={<Radio color="primary" />} label={<Term component="Perfil" text="Castellano" />} />
                                        <FormControlLabel disabled={savingLanguage} value="ca" control={<Radio color="primary" />} label={<Term component="Perfil" text="Catalan" />} />
                                        {
                                            savingLanguage ?
                                                <CircularProgress size={20} />
                                                : null
                                        }
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div className={classes.containerBtnsPerfil}>
                        <Button className={classes.button} variant="outlined" color="primary" onClick={handleChangePassword}>
                            <Term component="Perfil" text="Cambiar contraseña" />
                        </Button>
                        <div className={classes.containerFechaValidaBtnCambiarDatos}>
                            {fechaDatosValidados &&
                                <div style={{ display: 'flex', alignItems: 'flex-end', gap: '0.5rem' }}>
                                    <Term component="Perfil" text="Última actualización" />: <Typography>{dateFormat(fechaDatosValidados)}</Typography>
                                </div>
                            }
                            <Button style={{ margin: 0 }} className={classes.button} variant="contained" color="primary" onClick={handleUpdateDataSujeto}>
                                <Term component="Perfil" text="Editar datos" />
                            </Button>
                        </div>
                    </div>
                    <PasswordDialog
                        open={passwordDialogOpen}
                        onClose={handleModalClosePassword}
                    />
                </CardContent>
            </Card>
        </section>
    )
};

export default withStyles(styles)(PersonalData);