import React, { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent, Tooltip, Typography} from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import styles from './styles';
import { formatXMLtoObject, GenericInitialObject, getEmptyObject, getKeysRequiredCampos, renderCampos,  identificativoFormatter, formatOptions, SelectoresInfo, GenericObject, getInitialObject, isSameData, mixData, TPeriodo, OPTIONS_trimestre} from '../utils';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { ISujeto } from 'gateways/perfil.interfaces';
import { GeoGateway } from 'gateways/geo.gateway';
import { Municipio, Provincia, Isla, Sigla} from 'gateways/geo.interface';



const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXmlApartadosPrevios: GenericObject,
    datosXml: string | null,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
    withEjercicioPeriodo: boolean

}

const Datos: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXmlApartadosPrevios,
        datosXml,
        setFinished,
        handleSave,
        withEjercicioPeriodo

    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes, setChanges] = useState(false);
    const [campos, setCampos] = useState<ICampo[] | undefined>(undefined);
    const [EMPTY_Object,setEmptyObject] = useState<GenericInitialObject| null>({});
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    const [selectoresInfo, setSelectoresInfo] = useState<SelectoresInfo| null>(null);
    const [saved, setSaved] = useState(false)
    const [pressed, setPressed] = useState(false);

    const [isThisPeriod, setIsThisPeriod]= useState(false);
    const [paramPeriod, setParamPeriod]= useState<string[]>([])

    const CAMPOS_PERIODO =  ['ejerPeri','periodo']
    const MODELO_PERIODO: Record<string,TPeriodo> = {
        '656': '4T'
    }

    
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData = ( name: string, value: any) => {
        setChanges(true)
        let newData=null;
        if(EMPTY_Object){
            newData= data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY_Object,[name]: {...EMPTY_Object[name], value: value }}
        }

        if(newData && newData[name].tipo === 'exclusiveBool' && value === true){
            // 1. Obtener casillas que intervienen en la formula
            const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g; //(?!\[)-?\d+(?=\])/g;
            const casillas = newData[name].formula.match(myRegExp);
            //2. setear a falso todos los valores de la formula
            if(casillas){
                for (const key in newData) {
                    if (Object.prototype.hasOwnProperty.call(newData, key)) {
                        const element = newData[key];
                        if(casillas.includes(element.casilla.toString())){
                            newData[key] = {...newData[key], value: false}
                        }
                        
                    }
                }
            }
        } 

        setData(newData)
    }

    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            //console.log('on save camposRequiredKeys', camposRequiredKeys)
            if(camposRequiredKeys && camposRequiredKeys.length>0 ){
                if(data){
                    camposRequiredKeys.forEach( element => {
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal' || 'decimal2' ||'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                }
            }
            if (valid) {
                valid = campos?.findIndex(campo => campo.isValid != undefined && !campo.isValid && campo.visible) == -1;
            }
            if(valid){
                const savedTemp = await handleSave(data); 
                setSaved(savedTemp);
                setChanges(!savedTemp);
                setPressed(true)

            } else {
                //setFinished(false);
                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos','FormularioError', terms),
                        variant: 'error',
                    }
                });

            }
            setLoading(false);

        })();
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                //console.log('idTipoApartado', idTipoApartado)
                const delegacion = await modelGateway.getDelegacion(translate('Tributos', 'GetSelectDelegacionError', terms))
                const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                const periodoCampo = withEjercicioPeriodo
                    ? camposApartado.filter( c => CAMPOS_PERIODO.includes(c.codigo)) 
                    : undefined
                

                
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                let dataXML: GenericInitialObject | null = null;
                let dataPrevios: GenericInitialObject | null = null;
                let changes= false;

                //console.log('condicion', camposApartado && camposApartado.length>0 )
                if(camposApartado && camposApartado.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEmptyObject(emptyObjt);
                    const keys = getKeysRequiredCampos(camposApartado);
                    setCamposRequiredKeys(keys)
                    const datosPrevString = Object.values(datosXmlApartadosPrevios).join('')
                    //console.log('datosPrevString ', datosPrevString)
                    //console.log('datosXml ', datosXml)
                    if(datosXml){
                        dataXML = formatXMLtoObject(datosXml,emptyObjt);
                        //console.log('USE datosXML', dataXML)
                    } 
                    if(datosPrevString){
                        dataPrevios = getInitialObject(camposApartado, datosPrevString, idTipoApartado);
                        //console.log('useDatos datosPrevString', dataPrevios)
                    }
                    
                    //console.log('isSameData:', isSameData(dataXML, dataPrevios))
                    if(isSameData(dataXML, dataPrevios) || saved){
                        changes=false
                        newData= dataXML
                    } else {
                        const mixDataObjt = mixData(dataXML, dataPrevios, idTipoApartado)
                        changes=true
                        newData = mixDataObjt ?? newData
                    }

                }

                // Condiciona el componente a todo disabled si aplica a un solo periodo del ejercicio
                let apply = true
                if(withEjercicioPeriodo && periodoCampo){
                    if(newData && MODELO_PERIODO[idTipoModelo]){
                        // Ver si es 4T
                        const miData = periodoCampo[0].codigo
                        const miPeriodo = OPTIONS_trimestre.find( opt => opt.id === MODELO_PERIODO[idTipoModelo])?.nombre ?? 'not-found'
                        miPeriodo !== 'not-found' && setParamPeriod([miPeriodo])
                        apply = newData[miData].value.includes(miPeriodo)
                        changes = apply && !pressed ? changes : false
                        //console.log('campo: ', miData, 'periodoAplica: ', miPeriodo, ' es mi periodo ? ',apply)
                    }
                }
                
                setCampos(
                    withEjercicioPeriodo && !apply 
                    ? camposApartado.map(c=> ({...c, soloLectura: true}))
                    : camposApartado
                );
                setIsThisPeriod(apply)
                setData(newData || emptyObjt);
                setChanges(changes || (idTipoApartado === "621_D" && !saved))

                //Empty Selectores data - si es necesario
                const provinciaBaleares = '07'
                const optionsTV = await geoGateway.getSiglas()
                const optionsProv = await geoGateway.getProvincias('')
                const selected: Provincia|null|undefined = optionsProv && newData?.provincia?.value 
                    ? optionsProv.find(opt => (opt.nombre === newData?.provincia?.value.toString() || opt.idProvincia === newData?.provincia?.value.toString())) 
                    : null
            
                const optionsMuni = await geoGateway.getMunicipios(selected?.idProvincia || provinciaBaleares)    
                setSelectoresInfo({
                    municipio: optionsMuni && optionsMuni.length>0 ? formatOptions<Municipio>('idMunicipio','nombre',optionsMuni) : null,
                    provincia: optionsProv.length>0 ? formatOptions<Provincia>('idProvincia','nombre', optionsProv) : null,
                    tipoVia: optionsTV.length>0 ? formatOptions<Sigla>('idSiglas','nombre', optionsTV) : null,
                    oficina: delegacion
                })

                setLoading(false);

            } catch (error) {
                console.log('effect DATOS Error', error)
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);

    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
        if(idTipoApartado === "621_D" && saved){
            setChanges(false)
            setFinished(true)
        }
    },[changes])

   
    
    return(
        <div> 
            <div className={classes.rowAlignLeft}>
                {(withEjercicioPeriodo && isThisPeriod) || (!withEjercicioPeriodo)  && 
                    <Button 
                        style={{marginLeft: 10}}
                        color='primary'
                        variant='contained' 
                        disabled={ data === null || data === EMPTY_Object }
                        onClick={() => {
                            setData(EMPTY_Object)
                            setChanges(true)
                        }}
                    > 
                        {translate('Tributos','btnClean',terms)} 
                    </Button>
                }
            </div>
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>

            {(withEjercicioPeriodo && isThisPeriod) || (!withEjercicioPeriodo) 
                ? null
                :   <Typography 
                        style={{margin: '10px 10px 25px 10px', color:'#004f82', fontSize: 18, fontStyle:'italic'}}
                    >{translate('Tributos', `soloCumplimentarPeriodo${MODELO_PERIODO[idTipoModelo]}`, terms,paramPeriod)}
                    </Typography>
        
            }
            {campos && campos.length>0 && camposRequiredKeys !== null && data && selectoresInfo &&
                renderCampos(campos, data, updateData, Object.values(datosXmlApartadosPrevios).join(''), idTipoApartado, selectoresInfo, undefined, classes )
            }

            <div className={classes.rowAlignRight}>
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    disabled={!changes}
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnSave',terms)} 
                </Button>
            </div>
        </div>
    )
}
export default withLiterals(['Tributos'])(Datos);



