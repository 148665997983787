import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import {
    IArgumentosCancelar,
    IArgumentosJustificante,
    IBookAppointment,
    IDetallesOficina,
    IDiasHorariosCita,
    IDisponibilidadCita,
    IMisCitas,
    IOficinas,
    ISuccessBookAppointment,
    ISuccessCancelAppointment,
} from "./citaPrevia.interface";


@inject('axios')
export default class CitaPreviaGateway {
    constructor(
        private axios: AxiosInstance
    ) {
        if (!axios) {
            throw new Error('CitaPreviaGateway requires an axios instance');
        }
    }

    public async getContent(): Promise<IDisponibilidadCita> {
        try {
            const response = await this.axios.get('citaPrevia/procedimientos/es');
            if (response.status !== 200) {
                throw new Error('Bad response status: ' + response.statusText)
            }
            return response.data as IDisponibilidadCita;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getOficinasById(proceduresAgrupationId: number): Promise<IDetallesOficina[]> {
        try {
            const response = await this.axios.get('citaPrevia/oficinas/' + proceduresAgrupationId);
            if (response.status !== 200) {
                throw new Error('Bad response status: ' + response.statusText)
            }
            return response.data as IDetallesOficina[];
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getDiasHorariosCita(codeOficina: string): Promise<IDiasHorariosCita> {
        try {
            //const response = await this.axios.get('citaPrevia/disponible/' + codeOficina);
            // EndPoint cambiado hasta que se pueda subir APP ATIB
            const response = await this.axios.get('citaPrevia/getDisponibleSede/' + codeOficina);
            if (response.status !== 200) {
                throw new Error('Bad response status: ' + response.statusText)
            }
            return response.data as IDiasHorariosCita;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async reservarCita(paramsReservarCita: IBookAppointment): Promise<ISuccessBookAppointment> {
        try {
            const response = await this.axios.post("citaPrevia/bookAppointment", paramsReservarCita);
            if (response.status !== 201) {
                throw new Error('Bad response status: ' + response.statusText)
            }
            return response.data as ISuccessBookAppointment;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getJustificanteCitaBlob(paramsJustificante: IArgumentosJustificante): Promise<Blob> {
        try {
            const response = await this.axios.post('citaPrevia/copiaJustificanteBlob', paramsJustificante, {
                responseType: 'blob',
            });
            if (response.status !== 201 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText)
            }
            return response.data as Blob;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async cancelCita(cancelarForm: IArgumentosCancelar): Promise<ISuccessCancelAppointment> {
        try {
            const response = await this.axios.post("citaPrevia/cancelAppointment", {
                OfficeCode: cancelarForm.oficina,
                AppointmentCode: cancelarForm.codigoCita,
                Dni: cancelarForm.nif,
                Date: cancelarForm.dia,
                DeleteUserCode: "88888887"
            });
            if (response.status !== 201) {
                throw new Error('Bad response status: ' + response.statusText);
            }
            return response.data as ISuccessCancelAppointment;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getMisCitas(): Promise<IMisCitas[]> {
        try {
            const response = await this.axios.get(`citaPrevia/misCitas`);
            if (response.status !== 200) {
                throw new Error('Bad response status: ' + response.statusText);
            }
            return response.data as IMisCitas[];
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getOficinas(): Promise<IOficinas[]> {
        try {
            const response = await this.axios.get(`citaPrevia/getOficinas`);
            if (response.status !== 200) {
                throw new Error('Bad response status: ' + response.statusText);
            }
            return response.data as IOficinas[];
        } catch (error) {
            throw new Error(error as string);
        }
    }
};