import { StyleRules, Theme } from "@material-ui/core";

export const styles = (theme: Theme): StyleRules => ({
    base: {
        marginTop: 0,
        marginRight: 40,
        marginLeft: 40,
        [theme.breakpoints.down('xs')]: {
            marginTop: 50,
            marginRight: 10,
            marginLeft: 10,
        },
    },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    root: {
        width: "100%",
        marginLeft: 10,
        marginRight:10
    },
    formContainer: {
        marginTop: 50,
        marginLeft: '40px',
        marginRight: '40px',
    },
    formControl: {
        marginTop: 50,
        display: "inline",
        marginRight: 50,

        '& > button': {
            width: '100px',
        },

        [theme.breakpoints.up('xs')]: {
            '& > div': {
                width: 'calc(100% - 20%)',
            },
        },
        [theme.breakpoints.up('sm')]: {
            '& > div': {
                width: 'calc(100% - 62%)',
            },
        },
        [theme.breakpoints.up('md')]: {
            '& > div': {
                width: 'calc(100% - 60%)',
            },
        },
        [theme.breakpoints.up('lg')]: {
            '& > div': {
                width: 'calc(100% - 82%)',
            },
        }
    },
    button: {
        width: 200,
        marginTop: 50,
        marginBottom: 50,
        float: "right",
        [theme.breakpoints.down('xs')]: {
            float: "unset"
        },
    },
    smallFomrControl: {
        width: '20%',
        marginRight: 30
    },
    fomrControl: {
        width: '40%',
        marginRight: 30
    },
    [theme.breakpoints.up('md')]: {
        section: {
            '& > .MuiGrid-item': {
                padding: '0 20px'
            }
        },
        selectField: {
            marginTop: 15
        }
    },
    cardMargin: {
        marginBottom: '0.5rem'
    },
    explanationText: {
        marginBottom: 20,
        marginTop:20,
        width:'100%'
    },
    firstFiltersDiv: {
        display: 'inline-flex',
        width:'100%',
        marginTop:10
    },
    errorTip: {
        color: '#f44336',
        marginTop: 5
    },
    searchButton: {
        marginTop:10,
        marginRight:20
    },
    progressContainer: {
        marginLeft: 30,
        marginTop: 10
    },
    contentInline: {
        display: 'inline-flex',
        width: '100%'
    },
    marginLeft10: {
        marginLeft: 10,
    }
});