import { Typography, Button, DialogTitle, DialogActions, useMediaQuery, useTheme, DialogContent, WithStyles, withStyles, Grid, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import { FC, Fragment, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import IoC from 'contexts/ioc.context';
import { INotificacion, IDocumento } from 'gateways/notificaciones.interfaces';
import Term from 'components/term';
import styles from '../shared/notificaciones.styles';
import moment from 'moment';
import { NotificacionesGateway } from 'gateways/notificaciones.gateway';
import Documentos from "./documentos";
import imageNotIncompareciente from '../../resources/NotIncompareciente.png';
import imageNotNotificada from '../../resources/NotNotificada.png';
import imageNotPendiente from '../../resources/NotPendiente.png';

interface IProps extends WithStyles<typeof styles> {
    open: boolean;
    notificacion: INotificacion;
    notificacionRef: any;

    onClose: () => void;
}

const DocumentosNotificacion: FC<IProps> = ({ open, notificacion, notificacionRef, onClose, classes }) => {
    // services
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(NotificacionesGateway) as NotificacionesGateway, [ioc]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // local states
    const [state, setState] = useState<INotificacion>();
    const [loading, setLoading] = useState(false);
    const [documentos, setDocumentos] = useState<IDocumento[]>([]);

    // handles
    const handleClose = () => onClose();
    const getDocumentos = useCallback(async (idMensaje: number) => {
        setLoading(true);
        const docs = await gateway.getDocumentos(idMensaje);
        setDocumentos(docs);
        setLoading(false);
    }, [gateway]);

    // effects
    useEffect(() => {
        if (open) {
            getDocumentos(notificacion.idMensaje);
        }
    }, [notificacion, open, getDocumentos]);

    useEffect(() => {
        notificacionRef.current = state;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            maxWidth={"md"}
            fullWidth={true}
        >
            <DialogTitle>
                <Term component="Notificaciones" text={notificacion.tipoEnvio === 'C' ? "Informacion comunicacion": "Informacion notificacion"} />
            </DialogTitle>
            <DialogContent>
                {
                    loading || notificacion === null
                        ? <div className={classes.centerContent}>
                            <CircularProgress size={75} />
                        </div>
                        :
                        <Fragment>
                            <Grid container className={classes.root}>
                                <Grid item>
                                    <div className={classes.imagenContainer}>
                                        {
                                            notificacion.incomparecencia
                                                ?
                                                <img src={imageNotIncompareciente} alt="notificación" />
                                                :
                                                notificacion.leido
                                                    ?
                                                    <img src={imageNotNotificada} alt="notificación" />
                                                    :
                                                    <img src={imageNotPendiente} alt="notificación" />
                                        }
                                    </div>
                                </Grid>
                                <Grid container direction="column">
                                    <Grid item className={classes.grow}>
                                        <Grid container className={classes.contentRoot}>
                                            <Grid className={[classes.grow, classes.width0].join(' ')}>
                                                <Typography component="p">
                                                    <strong>{notificacion.concepto}</strong>
                                                </Typography>
                                                {
                                                    notificacion.incomparecencia
                                                        ?
                                                        <Typography className={[classes.highlightText, classes.highRed].join(' ')}>
                                                            <Term component="Notificaciones" text="Notificada incompareciente" />
                                                        </Typography>
                                                        :
                                                        notificacion.leido
                                                            ?
                                                            <Typography className={[classes.highlightText, classes.highGray].join(' ')}>
                                                                <Term component="Notificaciones" text="Notificada" />
                                                            </Typography>
                                                            :
                                                            <Typography className={[classes.highlightText, classes.highBlue].join(' ')}>
                                                                <Term component="Notificaciones" text="Notificacion pendiente" />
                                                            </Typography>
                                                }
                                                <Typography>
                                                    <Term component="Notificaciones" text="Estado" />:&nbsp;<b><Term component="Notificaciones" text={notificacion.leido ? "leido" : "no leido"} /></b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={[classes.grow, classes.contents].join(' ')}>
                                        <Grid container className={classes.contentRoot}>
                                            <Grid className={[classes.grow, classes.marginDetail15].join(' ')}>
                                                {/* <Typography>
                                                    <Term component="Notificaciones" text="Titular" /> - <span className={classes.highlightText}>{notificacion.nombreTitular}</span>
                                                </Typography>   */}
                                                {
                                                    notificacion.nombreDestinatario
                                                        ?
                                                        <Typography component="p">
                                                            <Term component="Notificaciones" text="Destinatario" /> - <span className={classes.highlightText}>{notificacion.nombreDestinatario}</span>
                                                        </Typography>
                                                        :
                                                        null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={[classes.grow, classes.contents].join(' ')}>
                                        <Grid container className={classes.contentRoot}>
                                            <Grid className={[classes.grow, classes.marginDetail15].join(' ')}>
                                                <Typography>
                                                    <Term component="Notificaciones" text="Fecha de emision" />
                                                </Typography>
                                                <Typography className={classes.highlightText}>
                                                    {moment.utc(notificacion.fechaEmision).format('DD/MM/YYYY HH:mm:ss')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div className={classes.marginDetail15}>
                                <Typography>
                                    <Term component="Notificaciones" text="Documentos adjuntos" />:&nbsp;
                                </Typography>
                                <Documentos notificacion={notificacion} documentos={documentos} setLoading={setLoading} setNotificacionState={setState} />
                            </div>
                        </Fragment>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">
                    <Term component="Global" text="Cerrar" />
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default withStyles(styles)(DocumentosNotificacion);