// LIBRARY IMPORTS
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, WithStyles, withStyles, Typography, CardActionArea, FormControlLabel, Radio, RadioGroup, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { Disable } from 'react-disable';
import { Icon } from '@mdi/react';
import moment from 'moment';

// LOCAL IMPORTS
import { IDisponibilidadCita, IArgsDisponibilidadCita, IDetallesOficina, IDiasHorariosCita, IParamsCitaPrevia, IDíaSemana, IHorasDisabled } from 'gateways/citaPrevia.interface';
import IOficinaDto from 'gateways/oficinas.interfaces';
import { mdiChevronRight, mdiChevronDown, mdiOfficeBuilding, mdiCalendar, mdiClock, mdiMapMarker } from '@mdi/js';
import { InteractiveCalendar } from 'components/calendario/interactive-calendar-component';
import { SelectChangeHandler } from 'utils/events';
import GridComponent from 'components/hours-grid/hours-grid';
import usePage from 'hooks/page.hook';
import Term from 'components/term';
import { OFICINAS_NO_PERMITIDAS } from 'constants/cita-previa';

// STYLES
import styles from '../cita-previa.styles';

interface IProps {
    diccionarioTramites: IDisponibilidadCita | null;
    oficinasList: IDetallesOficina[] | null;
    oficinasListSede: IOficinaDto[];
    horariosOficina: IDiasHorariosCita | null;
    paramsCita: IParamsCitaPrevia | undefined;

    onSelectedTramite: (tramite: string, tramiteId: number) => void;
    onOficinaValue: (oficinaValue: string) => void;
    onNextStep: (
        currentAggrupationId: string,
        currentAggrupationName: string,
        tramiteId: string,
        tramite: string,
        oficinaSeleccionada: IDetallesOficina,
        fechaCita: string,
        horaCita: string
    ) => void;
    onNextStepWithoutParams: () => void;
    onChangeDisableButton: (disableButton: boolean) => void;
}

type Props = WithStyles<typeof styles> & RouteComponentProps & IProps;

const SeleccionarTramite: React.FC<Props> = (props) => {
    const {
        classes,
        diccionarioTramites,
        oficinasList,
        oficinasListSede,
        horariosOficina,
        paramsCita,

        onSelectedTramite,
        onOficinaValue,
        onNextStep,
        onNextStepWithoutParams,
        onChangeDisableButton
    } = props;

    // HOOKS
    const myRef = useRef<HTMLDivElement | any>();
    const [{ lang },] = usePage();

    // STATES    
    const [activeCard, setActiveCard] = useState(false);
    const [selectedCard, setSelectedCard] = useState<number | null>(null);

    const [currentAggrupationId, setCurrentAggrupationId] = useState(0);
    const [currentAggrupationName, setCurrentAgrupationName] = useState<string | null>(null);

    const [tramite, setTramite] = useState<string | null>(null);
    const [tramiteId, setTramiteId] = useState<string | null>(null);

    const [oficinaSeleccionada, setOficinaSeleccionada] = useState<IDetallesOficina | null>(null);
    const [oficinaValue, setOficinaValue] = useState<string>('');
    const [idOficinaSede, setIdOficinaSede] = useState(null);

    const [fechaCita, setFechaCita] = useState<string>('');
    const [horasDisponiblesCita, setHorasDisponiblesCita] = useState<IHorasDisabled[] | null>(null);
    const [, setFechaDiaSemana] = useState<IDíaSemana[]>();
    const [horaCita, setHoraCita] = useState<string>('');

    const [disableOficinas, setDisableOficinas] = useState(true);
    const [disableCalendarioCita, setDisableCalendarioCita] = useState(true);
    const [disableHorasCita, setDisableHorasCita] = useState(true);

    const [isOpenOficinas, setIsOpenOficinas] = useState(false);
    const [isOpenDayCalendar, setIsOpenDayCalendar] = useState(false);



    // USE EFFECTS

    useEffect(() => {
        handleLocale()
        if (horariosOficina) {
            handleCapitalize()
            handleCapitalizeForCalendar()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang, horariosOficina])

    useEffect(() => {
        if (tramite !== null && tramiteId !== null) {
            onSelectedTramite(tramite, Number(tramiteId));
        }
    }, [tramite, tramiteId, onSelectedTramite]);

    useEffect(() => {
        if (paramsCita !== undefined) {
            setDisableOficinas(false);
            setDisableCalendarioCita(false);
            setDisableHorasCita(false);
            paramsCita.fechaCita !== undefined && handleHorasCita(paramsCita.fechaCita);
            paramsCita.oficina && setOficinaValue(paramsCita.oficina.code);
            paramsCita.oficina && setOficinaSeleccionada(paramsCita.oficina);
            paramsCita.tramiteSeleccionado !== undefined && setTramite(paramsCita.tramiteSeleccionado);
            paramsCita.tramiteId && setTramiteId(paramsCita.tramiteId);
            paramsCita.nombreAgrupacionTramite && setCurrentAgrupationName(paramsCita.nombreAgrupacionTramite);
            paramsCita.fechaCita && setFechaCita(paramsCita.fechaCita);
            paramsCita.horaCita && setHoraCita(paramsCita.horaCita);
        }
        handleLocale()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // TOGGLERS

    const onToggleOficinasSelector = () => {
        setIsOpenOficinas(!isOpenOficinas);
    }

    const onToggleDayCalendar = () => {
        setIsOpenDayCalendar(!isOpenDayCalendar);
    }


    // WINDOW

    const handleTramitesRef = useCallback((indexTramite: number) => {
        window.scrollTo({ top: (110 * indexTramite) + 20, left: 0, behavior: 'smooth' });
    }, []);

    const handleMyRef = useCallback(() => {
        window.scrollTo({ top: myRef.current.offsetTop, left: 0, behavior: 'smooth' });
    }, []);


    // HANDLERS

    const handleCurrentCard = useCallback((aggrupationId: number, aggrupationName: string, indiceTramite: number) => {
        if (currentAggrupationId === aggrupationId) {
            setActiveCard(false);
            setCurrentAggrupationId(0);
            setCurrentAgrupationName(null);
        } else {
            setActiveCard(true);
            setCurrentAggrupationId(aggrupationId);
            setCurrentAgrupationName(aggrupationName);
            handleTramitesRef(indiceTramite);
        }
    }, [currentAggrupationId, handleTramitesRef]);

    const handleIcon = useCallback((proceduresAggrupationId: number) => {
        return (
            // eslint-disable-next-line no-mixed-operators
            (activeCard && proceduresAggrupationId === currentAggrupationId || proceduresAggrupationId === selectedCard) ?
                <Icon path={mdiChevronDown} color={"#006993"} size={2} className={classes.iconCardTramites} />
                : <Icon path={mdiChevronRight} color={"#006993"} size={2} className={classes.iconCardTramites} />
        );
    }, [activeCard, classes.iconCardTramites, currentAggrupationId, selectedCard])


    // HANDLERS TRAMITE

    const handleParamsTramite = useCallback((horaCita: string) => {
        if (currentAggrupationName !== null && tramiteId !== null && tramite !== null && oficinaSeleccionada !== null && fechaCita !== '') {
            onNextStep(currentAggrupationId.toString(), currentAggrupationName, tramiteId, tramite, oficinaSeleccionada, fechaCita, horaCita);
        } else if (paramsCita && paramsCita.horaCita !== undefined) {
            onNextStepWithoutParams();
        }
    }, [currentAggrupationName, tramiteId, tramite,
        oficinaSeleccionada, fechaCita, paramsCita,
        onNextStep, currentAggrupationId, onNextStepWithoutParams]);

    const handleTramiteName = useCallback((id: string) => {
        diccionarioTramites && Object.entries(diccionarioTramites as IDisponibilidadCita).map(([k, v]) => {
            const tramiteTemp = v.filter((x) => x.id.toString() === id);
            if (tramiteTemp[0] !== undefined) {
                setTramite(tramiteTemp[0].name)
                setSelectedCard(tramiteTemp[0].proceduresAggrupationId);
            }
            return tramiteTemp;
        });
    }, [diccionarioTramites]);

    const handleTramiteSelection = useCallback((tramiteSeleccionado: React.ChangeEvent<HTMLInputElement>) => {
        setTramiteId(tramiteSeleccionado.target.value);
        handleTramiteName(tramiteSeleccionado.target.value);
        setOficinaSeleccionada(null);
        setOficinaValue('');
        setFechaCita('');
        setHoraCita('');
        setHorasDisponiblesCita([])
        setDisableOficinas(false);
        setDisableCalendarioCita(true);
        setDisableHorasCita(true);
        onChangeDisableButton(true);
        handleMyRef();
    }, [handleMyRef, handleTramiteName, onChangeDisableButton]);

    const handleTramiteOptions = useCallback((opcionesTramites: IArgsDisponibilidadCita[]) => {
        return (
            opcionesTramites.map((tramites: IArgsDisponibilidadCita, i: number) => {
                return (
                    <div key={`tramites${i}_citaPrevia`} className={classes.optionsTramitesCard}>
                        <FormControl component="fieldset">
                            <RadioGroup value={tramiteId} onChange={handleTramiteSelection}>
                                <FormControlLabel value={tramites.id.toString()} control={<Radio />} label={<Term component={"CitaPrevia"} text={tramites.name} />
                                } />
                            </RadioGroup>
                        </FormControl>
                    </div>
                );
            }));
    }, [classes.optionsTramitesCard, handleTramiteSelection, tramiteId]);


    // HANDLERS OFICINA

    const handleOficinaSeleccionada = useCallback((codigoOficina: string, useNombre = false) => {
        let oficina = oficinasList && oficinasList.find((oficinas) => oficinas.code === codigoOficina);
        if (!oficina) return;
        let findIdOficinaSede = null;
        oficinasListSede && oficinasListSede.map((oficinaSede) => {
                if (oficinaSede.direccion && oficinaSede.direccion.includes(oficina.postalcode) && !OFICINAS_NO_PERMITIDAS.includes(oficinaSede.nombre)) {
                    findIdOficinaSede = oficinaSede.id;
                }
            
        });
        if (findIdOficinaSede) {
            setIdOficinaSede(findIdOficinaSede)
        }
        setOficinaSeleccionada(oficina);
    }, [oficinasList, oficinasListSede, idOficinaSede]);


    const handleOficina: SelectChangeHandler = useCallback((event) => {
        setOficinaValue(event.target.value);
        onOficinaValue(event.target.value);

        setFechaCita('');
        setHoraCita('');

        handleOficinaSeleccionada(event.target.value);
        onToggleOficinasSelector();

        setDisableCalendarioCita(false);
        setDisableHorasCita(true);
        onChangeDisableButton(true);
    }, [handleOficinaSeleccionada, onChangeDisableButton, onOficinaValue, onToggleOficinasSelector]);


    // HANDLERS CALENDARIO

    const handleHorasCita = useCallback((date: string) => {
        horariosOficina && Object.entries(horariosOficina).map(([k, v], i: number) => {
            if (k.split("T")[0] === date) {
                setHorasDisponiblesCita(v);
            }
            return k;
        })
    }, [horariosOficina]);

    const handleHoraCitaGrid = useCallback((hour: string) => {
        setHoraCita(hour);
        handleParamsTramite(hour);
        onChangeDisableButton(false);
    }, [handleParamsTramite, onChangeDisableButton]);

    const handleFechaMui = useCallback((date: string) => {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setFechaCita(formattedDate)
        setHoraCita('');
        handleHorasCita(formattedDate);
        setDisableHorasCita(false);
        onChangeDisableButton(true);
        setIsOpenDayCalendar(false);
    }, [handleHorasCita, onChangeDisableButton])


    // HANDLERS CAPITILIZE

    const handleCapitalize = useCallback(() => {
        let arrayTemp: IDíaSemana[] = []
        horariosOficina && Object.entries(horariosOficina).forEach(([k, v], i: number) => {
            let tempJson: IDíaSemana;
            let tempLabel: string;
            tempLabel = moment(k.split("T")[0]).format("dddd DD-MM-YYYY")
            tempJson = {
                key: k,
                label: tempLabel.charAt(0).toUpperCase() + tempLabel.slice(1)
            }
            arrayTemp.push(tempJson)
        })
        setFechaDiaSemana(arrayTemp)
    }, [horariosOficina]);

    const handleCapitalizeForCalendar = useCallback(() => {
        let arrayTemp: IDíaSemana[] = []
        horariosOficina && Object.entries(horariosOficina).forEach(([k, v], i: number) => {
            let tempJson: IDíaSemana;
            let tempLabel: string;
            tempLabel = moment(k).format("DD-MM-YYYY")
            tempJson = {
                key: k,
                label: tempLabel
            }
            arrayTemp.push(tempJson)
        })
        setFechaDiaSemana(arrayTemp)
    }, [horariosOficina]);

    const handleLocale = useCallback(() => {
        moment.locale(lang)
    }, [lang])


    return (
        <>
            <div className={classes.subCardGroup} style={{ height: 'calc(100% - 490px)' }} >
                <div className={classes.tramitesLeftCard}>
                    {
                        <>
                            {diccionarioTramites ? Object.entries(diccionarioTramites).map(([k, v], i: number) => {
                                return (
                                    <div key={`procedimient${i}_citaPrevia`}>
                                        <Card className={classes.tramiteCards}>
                                            <CardActionArea className={classes.tramiteCardActionArea} onClick={() => handleCurrentCard(v[0].proceduresAggrupationId, v[0].aggrupationName, i)}>
                                                <div className={classes.tramitesCardHeader}>
                                                    <Typography className={classes.textCardTramites}>
                                                        <Term component={"CitaPrevia"} text={v[0].aggrupationName} />
                                                    </Typography>
                                                    {handleIcon(v[0].proceduresAggrupationId)}
                                                </div>
                                            </CardActionArea>
                                            {
                                                // eslint-disable-next-line no-mixed-operators
                                                (activeCard && v[0].proceduresAggrupationId === currentAggrupationId || v[0].proceduresAggrupationId === selectedCard) && handleTramiteOptions(v)
                                            }
                                        </Card>
                                    </div>
                                );
                            })
                                : null}
                        </>}
                </div>

                <div className={classes.tramitesRightCard} ref={myRef}>
                    <Card className={classes.optionsCard}>
                        <div className={classes.cardContainers}>

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Disable disabled={disableOficinas}>
                                    <div style={{ display: 'table' }} >
                                        <div className={classes.optionsCardHeader} onClick={onToggleOficinasSelector}>
                                            <Icon path={mdiOfficeBuilding} color={"#006993"} size={1.5} className={classes.iconCardTramites} />
                                            <Typography className={classes.boldText} style={{ paddingLeft: 10 }}>
                                                <Term component="Oficinas" text="Oficina" />
                                            </Typography>
                                        </div>
                                        <div className={classes.optionsCardBody}>
                                            <FormControl className={classes.selectorTramites}>
                                                {oficinaSeleccionada === null &&
                                                    <InputLabel>
                                                        <Term component="Oficinas" text="Seleccionar oficina" />
                                                    </InputLabel>
                                                }
                                                <Select
                                                    value={oficinaValue}
                                                    onChange={handleOficina}
                                                    open={isOpenOficinas}
                                                    onClick={onToggleOficinasSelector}
                                                >
                                                    {tramite !== '' && oficinasList && oficinasList.map((oficina: IDetallesOficina, i: number) => {
                                                        return (
                                                            <MenuItem key={`oficina${i}__citaPrevia`} value={oficina.code}>{oficina.name}</MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {oficinaSeleccionada !== null && (
                                            <div className={classes.addressOficina}>
                                                <Icon path={mdiMapMarker} color={'grey'} size={1} />
                                                <Typography style={{ color: '#006993', paddingLeft: 15 }}>
                                                    <a
                                                        href={`https://sede.atib.es/cva/oficinas/${idOficinaSede}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ color: '#006993', textDecoration: 'none' }}
                                                    >
                                                        {oficinaSeleccionada.address}, {oficinaSeleccionada.postalcode}, {oficinaSeleccionada.name}
                                                    </a>
                                                </Typography>
                                            </div>
                                        )}

                                    </div>
                                </Disable>



                                <Disable disabled={disableCalendarioCita}>
                                    <div style={{ display: 'table' }} >
                                        <div className={classes.optionsCardHeader} onClick={onToggleDayCalendar}>
                                            <Icon path={mdiCalendar} color={"#006993"} size={1.5} className={classes.iconCardTramites} />
                                            <Typography className={classes.boldText} style={{ paddingLeft: 10 }}>
                                                <Term component="CitaPrevia" text="Seleccionar fecha" />
                                            </Typography>
                                        </div>
                                        <div className={classes.optionsCardBody}>
                                            <FormControl className={classes.selectorTramites}>
                                                <InteractiveCalendar
                                                    days={horariosOficina ? horariosOficina : undefined}
                                                    date={fechaCita !== '' ? moment(fechaCita).toDate() : null}
                                                    disabled={disableCalendarioCita}
                                                    disablePast={true}
                                                    open={isOpenDayCalendar}
                                                    onChange={handleFechaMui}
                                                    onToggle={onToggleDayCalendar}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </Disable>

                            </div>

                            <Disable disabled={disableHorasCita}>
                                <div style={{ display: 'table', marginRight: '10%' }} >
                                    <div className={classes.optionsCardHeader}>
                                        <Icon path={mdiClock} color={"#006993"} size={1.5} className={classes.iconCardTramites} />
                                        <Typography className={classes.boldText} style={{ paddingLeft: 10 }}>
                                            <Term component="CitaPrevia" text="Disponibilidad horaria" />
                                        </Typography>
                                    </div>
                                    <div className={classes.optionsCardBody}>
                                        <FormControl className={classes.selectorTramites} style={{ marginBottom: 5, overflowY: 'scroll' }}>
                                            {!disableHorasCita && !disableCalendarioCita ? (
                                                tramite !== null && horasDisponiblesCita !== null &&
                                                <GridComponent
                                                    data={horasDisponiblesCita}
                                                    horaCitaSeleccionada={horaCita}
                                                    handleHour={handleHoraCitaGrid}
                                                />
                                            ) : (null
                                            )
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                            </Disable>
                        </div>
                    </Card >
                </div >
            </div >
        </>
    );
}

export default withRouter(withStyles(styles)(SeleccionarTramite));