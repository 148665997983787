import { StyleRules, Theme } from "@material-ui/core"

const styles =  (theme: Theme): StyleRules => ({
    row: {
        display:'flex',
        flexDirection: 'row',
        marginRight: 20,
        alignItems:'flex-start'
    }
})
export default styles