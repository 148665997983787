import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import plv_styles from './styles';
import {  Button, Grid, IconButton, Typography, WithStyles, withStyles } from "@material-ui/core";
import {  TInfoTransmPlusvalia } from "./types";
import { IInfoRegistral, IParamStepper } from "../shared/types";
import StepSchema from "../shared/stepSchema";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import IoC from "contexts/ioc.context";
import ModelGateway from "gateways/model.new.gateway";
import { IAsistentePLVObj } from "gateways/model.new.interface";
import { translate } from "utils/i18n";
import { AlertsContext } from "contexts/alerts.context";
import FullLoading from "components/full-loading";
import LabelWithInfo from "components/DUTI/label-with-info";
import InfoTransmisionForm, { FECHA_DEVENGO_MIN } from "components/Modelos/componentes/infoTransmisionForm";
import InfoRegistralForm from "components/Modelos/componentes/infoRegistralForm";
import { IDocumentoAtib } from "gateways/documentos.interface";
import { DocumentosGateway } from "gateways/documentos.gateway";
import EndProcessMSG from "components/Modelos/componentes/endProcessMSG";
import Icon from "@mdi/react";
import { mdiFileCertificateOutline, mdiEyeCircleOutline } from "@mdi/js";
import { BLUE } from "containers/shared/colors";
import usePage from "hooks/page.hook";
import ModalCheckEscrituraNRD from "components/Modelos/componentes/modalCheckEscrituraNRD";

import DUTIGateway from "gateways/duti.gateway";
import { DocumentsUtils } from "utils/documents";
import Modal from "components/modal";
import { ContenidosGateway } from "gateways/contenido.gateway";
import { TSelect } from "containers/DUTI/utils";
import { isBefore } from "date-fns";



export interface IInfoTransmPlusvalia extends IParamStepper, WithStyles<typeof plv_styles>{
    apartado: string;
    orden: number;
}
const InfoTransmPlusvalia: FC<IInfoTransmPlusvalia> = ({
    classes, apartado, orden,tipoAsistente, idRef, isFirst, isLast, handleNext, handleBack, handleExit
}) => {
    const componentMounted = useRef(true);
    const terms = useContext(LiteralsContext);
    const [pageState] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    
    //Gateways
    const ioc = useContext(IoC);
    const modelosGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const documentosGateway: DocumentosGateway = useMemo(() => ioc.get(DocumentosGateway), [ioc]);
    const contenidoGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);

    const [motivoRU, setMotivoRU]= useState<TSelect | undefined>(undefined)
    const [noSujOPTIONS, setNoSujOPTIONS] = useState<TSelect[]>([]);
    const [exentoOPTIONS, setExentoOPTIONS] = useState<TSelect[]>([]);


    const [MSGYaExiste,setMSGYaExiste] = useState<string>('');
    const [MSGEscritura,setMSGEscritura] = useState<{si:string, no:string}>({si: '', no:''});
    const [MSGValorDeclarado, setMSGValorDeclarado] = useState<string>('');

    const [prevStepsInfo, setPrevStepsInfo]= useState<IAsistentePLVObj | undefined>(undefined)
    const [infoTransmPlusval, setInfoTransmPlusval]= useState<TInfoTransmPlusvalia>({} as TInfoTransmPlusvalia)
    const [localDoc, setLocalDoc] = useState<IDocumentoAtib | 'notFound' | undefined>(undefined);
    const [origenDocumento, setOrigenDocumento] = useState<number>(0);
    const [showCheckNRD,setShowCheckNRD] = useState(false);
    const [showModalEscritura, setShowModalEscritura]= useState(false);

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading]= useState<string|undefined>(undefined)
    const [showMsgYaExiste, setShowMsgYaExiste]= useState(false)
    const [showMsgNoEncontrado, setShowMsgNoEncontrado]= useState(false)

    const [haveChanges, setHaveChanges] = useState(false)

    
    // MEMOS -------------------------------------------------------------------------------------------------
    const disabledContinuar: boolean = useMemo(() => (
        (infoTransmPlusval
            && infoTransmPlusval.fecha && !isBefore(infoTransmPlusval.fecha, new Date(FECHA_DEVENGO_MIN)) /*&& infoTransmPlusval.valorDeclarado*/ 
            && infoTransmPlusval.porcentaje 
            && infoTransmPlusval.tipoDocTransmision && infoTransmPlusval.tipoDocTransmision.id 
            && infoTransmPlusval.fechaDoc &&
            ((infoTransmPlusval.tipoDocTransmision.id ==='publico-notarial' && 
                infoTransmPlusval.anyo && infoTransmPlusval.notario && infoTransmPlusval.notario.nif && 
                infoTransmPlusval.numeroProtocolo 
            ) || (
                infoTransmPlusval.idDoc // && infoTransmPlusval.docDescripcion
            ))
        ) && localDoc !=='notFound' && !showMsgYaExiste && 
        ((!infoTransmPlusval.noSujeto && !infoTransmPlusval.exento) || ((infoTransmPlusval.noSujeto || infoTransmPlusval.exento) 
            && infoTransmPlusval.motivo!== undefined && (
                !(infoTransmPlusval.motivo.nombre.toLowerCase().includes('otros') || infoTransmPlusval.motivo.nombre.toLowerCase().includes('altres'))
                || (
                    (infoTransmPlusval.motivo.nombre.toLowerCase().includes('otros') || infoTransmPlusval.motivo.nombre.toLowerCase().includes('altres'))
                    && infoTransmPlusval.observaciones!== undefined && infoTransmPlusval.observaciones!== ''
                )
            )))
        ? false : true 
    ),[ infoTransmPlusval, localDoc ,showMsgYaExiste ])

    // -------------------------------------------------------------------------------------------------------
   
    // FUNCIONES----------------------------------------------------------------------------------------------
    const buscarEscritura= async() => {
        try {
            setLoading(true)
            // Check if existe trámite
            const existeTramite = false; // await  modelosGateway.check ...
            setShowMsgYaExiste(existeTramite);
            if(!existeTramite){
                setMsgLoading(translate('DUTI', 'buscando_escritura', terms));
                const escritura = await modelosGateway.getEscrituraPublica(infoTransmPlusval.fechaDoc, infoTransmPlusval.anyo,infoTransmPlusval.numeroProtocolo,infoTransmPlusval.notario?.nif);
                if(escritura && escritura.IdDocumento) {
                    setInfoTransmPlusval({
                        ...infoTransmPlusval, 
                        idDoc: escritura.IdDocumento
                    });
                    setHaveChanges(true);
                    setLocalDoc({...escritura});
                    setOrigenDocumento(1);
                    setShowMsgNoEncontrado(false);
                } else {
                    setLocalDoc('notFound');
                    setOrigenDocumento(2);
                    setShowMsgNoEncontrado(true);
                }
                
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alertsDispatch({
                type: "show-alert",
                payload: {
                  message: translate('Plusvalias', 'error_buscar_escritura', terms),
                  variant: "error",
                },
            });
        }
    }

    const showDoc = async( id: string | undefined) => {
        try {
            if(!id){throw new Error('lost_id_doc_to_show')}
            setLoading(true)
            DocumentsUtils.downloadDoc(id);
            setLoading(false)
        } catch (error) {
            const msgErr = (error as Error).message
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Global', msgErr ??'can_not_show_attached_doc', terms),
                    variant: 'error',
                }
            });
        } finally {
            setLoading(false);
        }
               
    }
    // -------------------------------------------------------------------------------------------------------

    // GESTIÓN NAV Step --------------------------------------------------------------------------------------
    const onExit = async  (cause:'continuar-mas-tarde'|'force')  => {
        try {
            // validate if pending changes - MSG - save info ¿?
            if(haveChanges && cause === 'continuar-mas-tarde'){
                await modelosGateway.saveStepAsistente(idRef, apartado, orden, infoTransmPlusval)              
            }
            handleExit()
        } catch(err) {

            alertsDispatch({
                type: "show-alert",
                payload: {
                    message: translate('Plusvalias','error_save_continuar_mas_tarde', terms),
                    variant: "warning",
                    hasCustomAction: true,
                    defaultCloseActionTemplateName: translate('Global','no',terms),
                    actionTemplateName: translate('Global','si',terms),
                    handleCustomAction: () => {
                        alertsDispatch({ type: 'hide-alert' })
                        handleExit()
                    }
                },
            });
        }
    }

    const onBack = () => {
        handleBack()
    }

    const saveApartado = async() => {
        try {
            setLoading(true)              
            const result = haveChanges ? await modelosGateway.saveStepAsistente(idRef, apartado, orden, infoTransmPlusval) : {ok:true, idRef: idRef}
            if(result.ok){
                setLoading(false)
                setHaveChanges(false)
                // si todo OK
                //console.log('goNext ---- > from InfoTransmPlusval')
                handleNext()
            }  
        } catch(err) {
            setLoading(false)
            const error = err as Error
            const errMSG = translate('Plusvalias', error.message, terms);
            const cause= error.cause ?? "";
            alertsDispatch({
                type: "show-alert",
                payload: {
                message: errMSG.concat(`. ${cause}`),
                variant: "error",
                },
            });
        }
    }

    const onNext = async() => {
          try {
                  
            // validate required
            if(infoTransmPlusval.idDoc){  
                let valorRef = undefined
                if(prevStepsInfo?.preguntasPlusvalia?.inmueble?.refCatastral
                    && infoTransmPlusval.fecha 
                ){
                    setLoading(true)  
                    valorRef = await dutiGateway.getValorReferencia(
                        prevStepsInfo?.preguntasPlusvalia?.inmueble?.refCatastral as string, 
                        infoTransmPlusval.fecha as Date
                    );
                    setLoading(false)  
                }

                if(infoTransmPlusval.valorDeclarado && valorRef && valorRef>infoTransmPlusval.valorDeclarado){
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: MSGValorDeclarado,
                            isHtml: true,
                            variant: 'warning',
                            hasCustomAction: true,
                            defaultCloseActionTemplateName: translate("DUTI", "btn_close", terms).toUpperCase(),
                            actionTemplateName: translate("DUTI", "btn_continuar", terms).toUpperCase(),
                            handleCustomAction: async () => { 
                                alertsDispatch({type: 'hide-alert'}) 
                                await saveApartado()
                            }
                        }
                    });
                } else {
                    await saveApartado()
                }
            } else {
                // buscar escritura por anyo/protocolo/notario
               await buscarEscritura()
            }  
            
        } catch(err) {
            setLoading(false)
            const error = err as Error
            const errMSG = translate('Plusvalias', error.message, terms);
            const cause= error.cause ?? "";
            alertsDispatch({
                type: "show-alert",
                payload: {
                  message: errMSG.concat(`.${cause}`),
                  variant: "error",
                },
            });
        }
    }
    // -------------------------------------------------------------------------------------------------------
    
    // EFFECTS -----------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try {
                setLoading(true)
                const prevInfo = await modelosGateway.getAllApartadosModeloAsistente('PLV',idRef)
                const motivos = await modelosGateway.getMotivosExencionNosujecion( prevInfo.preguntasPlusvalia?.municipio?.id ?? '',translate('Plusvalias','error_consulta_motivos_exen_nosuj',terms))
                const motivoRU = await modelosGateway.getMotivoExtentoRU(prevInfo.preguntasPlusvalia?.municipio?.id ?? '');
              
                // Msg
                const MSGExist = await contenidoGateway.getContent('info_modal_ya_existe_datos_transmision', {});
                const MSGNoEscritura = await contenidoGateway.getContent('info_modal_escritura_no_encontrada', {});
                const MSGSiEscritura = await contenidoGateway.getContent( 'info_modal_escritura_encontrada', {});
                const MSGValorDecl = await contenidoGateway.getContent( 'info_modal_valorReferencia_Declarado', {});
                
                let doc: IDocumentoAtib | undefined = undefined
                if(prevInfo && prevInfo.infoTransmPlusvalia &&  prevInfo.infoTransmPlusvalia.idDoc){
                    doc = await documentosGateway.getDocAtib(prevInfo.infoTransmPlusvalia.idDoc)
                }
                // Calls - await 
                if(componentMounted.current){
                    //setters
                    setInfoTransmPlusval(prevInfo.infoTransmPlusvalia ?? {} as TInfoTransmPlusvalia);
                    setPrevStepsInfo(prevInfo);
                    setLocalDoc(doc);
                    setOrigenDocumento(doc !== undefined ? 2 : 0);

                    setMSGYaExiste(MSGExist[0].contenido);
                    setMSGEscritura({
                        si: MSGSiEscritura[0].contenido,
                        no: MSGNoEscritura[0].contenido,
                    });
                    setMSGValorDeclarado(MSGValorDecl[0].contenido);
                    if(motivos){
                        setNoSujOPTIONS(motivos.noSujecion.map(item => ({id: item.id.toString(), nombre: translate('Plusvalias',item.term,terms)}) ))
                        setExentoOPTIONS (motivos.exencion.map(item => ({id: item.id.toString(), nombre: translate('Plusvalias',item.term,terms)}) ))
                    }
                    motivoRU && setMotivoRU({id: motivoRU.id.toString(), nombre: translate('Plusvalias',motivoRU.term,terms)})
                    
                }

            } catch (err){
                const msg= (err as Error).message
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Plusvalias', msg, terms),
                        variant: 'error',
                    }
                });

            } finally {
                setLoading(false)
            }

            
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    },[])

    return(
        <StepSchema idRef={idRef} isFirst={isFirst} isLast={isLast} onNext={onNext} onBack={onBack} onExit={onExit} disabledNext={disabledContinuar}>
            <FullLoading loading={ loading } msg={msgLoading} />       
                   
            <Grid item container  direction='column'>
                <LabelWithInfo type="title" fieldTerm="DT_IntroDatosDUTI_InfoTransmision_title"  
                    moreInfo= {{template: 'plusvalias_info_InfoTransmision_title', size: 'md'}}
                /> 
                <div style={{flex:1, flexGrow:1, overflowY: 'auto'}}>
                    <Grid item>
                        <InfoTransmisionForm
                            tipoAsistente= {tipoAsistente}
                            tipoTransmision= {prevStepsInfo?.preguntasPlusvalia?.tipoTransmision.tipo } 
                            tipoTransmisionBoni ={prevStepsInfo?.preguntasPlusvalia?.tipoTransmision.bonificacion?.valor}
                            tipoTransmisionDescrip ={prevStepsInfo?.preguntasPlusvalia?.tipoTransmision.tipoTransmisionDescripcion}
                            initialValues={infoTransmPlusval}
                            onChange= {(v: TInfoTransmPlusvalia) =>{
                                setInfoTransmPlusval({...v})
                                setHaveChanges(true)
                            }}
                            doc={localDoc}
                            onChangeDoc={setLocalDoc}                            
                            componentIG= {'Plusvalias'}
                            idTipoApartado={apartado}
                            idReferencia={idRef}
                            motivoRU={motivoRU}
                            tipoBien={prevStepsInfo?.preguntasPlusvalia?.inmueble?.tipoBien}
                            noSujOPTIONS={noSujOPTIONS}
                            exentoOPTIONS={exentoOPTIONS}
                            origenDocumento={origenDocumento}
                            onChangeOrigenDoc={setOrigenDocumento}
                            setShowMsgNoEncontrado={setShowMsgNoEncontrado}
                        />
                    </Grid>
                    {infoTransmPlusval && infoTransmPlusval.tipoDocTransmision?.id && 
                        <Grid item >
                            <InfoRegistralForm
                                initialValues={infoTransmPlusval?.infoRegistral}
                                onChange={ (v: IInfoRegistral) => {
                                    setInfoTransmPlusval( {...infoTransmPlusval, infoRegistral: {...v}})
                                    setHaveChanges(true)
                                }}
                            />
                        </Grid>
                    }

                    {/**Visualizar escritura */}
                    {   localDoc !== undefined && infoTransmPlusval && infoTransmPlusval.tipoDocTransmision?.id  && 
                        infoTransmPlusval.tipoDocTransmision?.id === 'publico-notarial' &&
                        <div style={{marginTop:25}} >
                            { localDoc !== 'notFound' && origenDocumento === 1 ? 
                                <div className={classes.borderPlusval} >
                                    <div className={classes.divTextBorderPlusval}>
                                        <Typography className={classes.textBorderPlusval}>{translate('DUTI','title_box_escrituraPubl',terms)}</Typography>
                                    </div> 
                                    <div className={classes.row}>
                                        <div style={{marginRight: 20}}>
                                            <Icon path={mdiFileCertificateOutline} color={BLUE} style={{alignSelf:'flex-start'}} size={3} />
                                        </div>
                                        <div className={classes.column}>
                                            { pageState.jwp !== null 
                                                ? <Typography variant="body1">{translate('Plusvalias','idDocumentoEscritura_found_download',terms)} <Icon path={mdiEyeCircleOutline} size={1}/></Typography>
                                                : <Typography variant="body1">{translate('Plusvalias','idDocumentoEscritura_found',terms)} </Typography>
                                            }
                                            <div className={classes.row} style={{alignItems: 'center', minHeight: 48}}>
                                                <Typography variant="body1" style={{marginRight: 10, marginLeft: 10,marginTop:5}}>{localDoc.NombreFichero}</Typography>
                                                {(pageState.jwp !== null) && 
                                                    <IconButton 
                                                        onClick={() => { 
                                                            if(infoTransmPlusval.nrd){
                                                                showDoc(localDoc.IdDocumento)
                                                            } else {
                                                                setShowCheckNRD(true)
                                                            }
                                                        }} 
                                                        style={{padding:5, marginRight: 5, marginLeft:5}}
                                                    >
                                                        <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} />
                                                    </IconButton>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                            showMsgNoEncontrado &&                            
                            <EndProcessMSG componentIG="Plusvalias" termIG="documentoEscritura_notFound" />
                        }
                        </div>
                        
                    }

                     { showMsgYaExiste && <EndProcessMSG content={MSGYaExiste} paramContent={{}}/>} 
                </div>
            </Grid>

            <Modal
                open={showModalEscritura}
                title={translate('DUTI', 'DT_docTransmision_title', terms)}
                renderContent={()=> (
                    ((infoTransmPlusval && infoTransmPlusval.idDoc && infoTransmPlusval.idDoc=== '' )|| localDoc === undefined ||  localDoc === 'notFound' )
                        ? <div dangerouslySetInnerHTML={{ __html: MSGEscritura.no }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                        : <div dangerouslySetInnerHTML={{ __html: MSGEscritura.si }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                )}
                renderActions={()=> {
                    return(
                        <div style={{display: 'flex',justifyContent:'flex-end', paddingRight: 10, paddingBottom: 10}}>
                            <Button
                                variant="contained" color="primary" 
                                onClick={() =>{ 
                                    setShowModalEscritura(false)
                                }} 
                            >
                                {translate('DUTI', 'btn_close',terms)}
                            </Button>
                        </div>
                    )
                }}
            /> 
           
            <ModalCheckEscrituraNRD
                show={showCheckNRD}
                info={{ 
                    fecha: infoTransmPlusval?.fecha,
                    anyo: infoTransmPlusval?.anyo,
                    numeroProtocolo: infoTransmPlusval?.numeroProtocolo,
                    nifNotario: infoTransmPlusval?.notario?.nif,
                    notario: infoTransmPlusval?.notario?.nomApe
                }}
                onChecked={ (checked: boolean, nrd: string|undefined) => {
                    let newV = {...infoTransmPlusval}
                    if(!checked){
                        setLocalDoc('notFound')
                        newV={
                            ...newV,
                            nrd: undefined,
                            idDoc: undefined
                        }
                    } else {
                        newV={...newV,nrd: nrd}
                        setShowCheckNRD(false)
                        showDoc(newV.idDoc)
                    }
                    setInfoTransmPlusval({...newV})
                    setHaveChanges(true)

                }}
                onClose={() => setShowCheckNRD(false)} 
            />

        </StepSchema>
    )
}

export default  withLiterals(["Plusvalias","Global"])(withStyles(plv_styles)(InfoTransmPlusvalia))