import React, { ComponentType } from "react";

interface GenericComponentProps<T> {
    component: ComponentType<T>;
    componentProps: T;
  }
// Componente genérico 
function GenericComponent<T extends {}>({ component: Component, componentProps }: GenericComponentProps<T>) {
    return <Component {...componentProps} />;
}

export default  GenericComponent;