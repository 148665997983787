import { Reducer } from "react";


export interface IFiltersState {
    municipio?: string[];
    cambioLista: boolean;
}

export type FiltersAction =
    | { type: 'update-any', filters: Partial<IFiltersState> }
    | { type: 'update', filter: keyof IFiltersState, value: any }
    | { type: 'clear' }

const FiltersReducer: Reducer<IFiltersState, FiltersAction> = (state, action) => {
    let newState = { ...state };

    switch (action.type) {
        case 'update-any':
            newState = { ...state, ...action.filters };
            break;
        case 'update':
            newState[action.filter] = action.value;
            break;
        case 'clear':
            for (const prop in newState) {
                if (prop in newState) {
                    (newState as any)[prop] = undefined;
                }
            }
            break;
        default: throw new Error('Unexpected action');
    }

    localStorage.setItem('oficinas.filters', JSON.stringify(newState))

    return newState;
};

export default FiltersReducer;