import React, { FC, useContext } from "react";
import Term from "components/term";
import { FormControl, InputLabel, makeStyles, Select } from "@material-ui/core";
import { OrderContext } from "./order.context";
import { OrderDataContext } from "./order-data.context";
import { IFilterOption } from "utils/interfaces";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    formControl: {
        margin: `30px 0`,
        [theme.breakpoints.up('md')]: {
            margin: `10px 5px`,
        }
    },
    group: {
        margin: `10px 0`,
    },
    block: {
        display: `inline-block`
    },
    hidden: {
        display: `none`
    },
    middle: {
        verticalAlign: `middle`
    },
}));

export type ScreenType = 'conductor';

export interface IOrderFormProps {
    screen: ScreenType;
}

const OrderForm: FC<IOrderFormProps> = ({ screen }) => {

    const [order, orderDispatch] = useContext(OrderContext);
    const orderData = useContext(OrderDataContext);

    const handleOrderChange = (event: any) => orderDispatch({ type: 'update', order: 'orden', value: event.target.value });

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FormControl key='orden' className={orderData && orderData.orden ? classes.formControl : classes.hidden} fullWidth>
                <InputLabel key='label-orden' htmlFor="select-multiple-chip">
                    <Term component="Global" text="Orden" />
                </InputLabel>
                <Select
                    native={true}
                    value={order.orden || 1}
                    autoWidth={true}
                    onChange={handleOrderChange}
                >
                    {orderData && orderData.orden ? orderData.orden.map((x: IFilterOption<number>) =>
                        <option key={x.value} value={x.value}>{x.label}</option>
                    ) : <option value={""}></option>}
                </Select>
            </FormControl>
        </div>
    );
}

export default OrderForm;