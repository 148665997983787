import React, { FC, useContext } from 'react';
import { Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { mdiFileAccount } from "@mdi/js";
import { translate } from 'utils/i18n';

import TributosList from './tributos-list';
import ModelosTributos from './modelos-tributos';
import ModelosTributosAsistente from './modelos-tributos-asistente';

import ModelosAction from './modelos-action';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';



import { TipoTributo } from './utils';
import ModelosPago from './modelos-pago';
import ProtectedRoute from 'components/protected-route';
import { PAY_CERTIFICADO_PERMITIDO, PAY_NO_LOGGED, PRINT } from './constants';
import TributosHome from './tributos-home';
import DUTIIndex from 'containers/DUTI';
import Contenido from 'components/contenido';

import ExpedientesIndex from "../tramites/expedientes/index";

const TributosIndex: FC<RouteComponentProps> = ({ match, history }) => {
    const terms = useContext(LiteralsContext)  
    const split = history.location.pathname.split('/');

    return (
        <>
            <Route exact path={`${match.path}`} component={TributosHome} />
            <Route exact path={`${match.path}/tributos-:textTipoTributo`} component={TributosList} />
            {/** Modelo 080,081,082 => Asistente: plusvalia*/}
            <Route exact path={`${match.path}/tributos-:textTipoTributo/:tipoAsistente/:action`} component={ ModelosTributosAsistente } />
            {/** Modelos normales  */}
            {split.length >= 4 && 
                ( PRINT.CERTIFICADO.includes(split[3]) 
                    || (PAY_CERTIFICADO_PERMITIDO.includes(split[3]) && !PAY_NO_LOGGED.PERMITIDO.includes(split[3])) ) ?
                    <>
                        <ProtectedRoute exact path={`${match.path}/tributos-:textTipoTributo/:idTipoModelo`} component={ModelosAction} accessLevel={2} idModulos={[]}/> 
                        <ProtectedRoute exact 
                            path={`${match.path}/tributos-:textTipoTributo/:idTipoModelo/:action`} 
                            component={ModelosTributos} 
                            accessLevel={2} 
                            idModulos={[]}
                        />
                    </>
                :  // Permite acceso sin estar autenticado o bien permite ambas modalidades (EJ: 071)
                    <>
                        <Route exact path={`${match.path}/tributos-:textTipoTributo/:idTipoModelo`} component={ModelosAction}  />
                        <Route exact 
                            path={`${match.path}/tributos-:textTipoTributo/:idTipoModelo/:action`} 
                            component={ModelosTributos} 
                        />
                    </>

            }
            
            <Route exact path={`${match.path}/tributos-:textTipoTributo/:idTipoModelo/:action/pago/:idModelo`} component={ModelosPago}  />
            <Route exact path={`${match.path}/contenidos`} render={(props) => <Contenido landingName='PlazosTA_TL' {...props} pageTitle={translate('Global', 'TRIBUTOS', terms)} pageSubtitle={translate('Global', 'PlazosTA_TL_Descripcion', terms)} icon={mdiFileAccount} />} />
            <Route path={`${match.path}/DUTI`} component={DUTIIndex}  />
            
            {/* Presentaciones expedientes */}
            <ProtectedRoute path={`${match.path}/presentaciones`} component={ExpedientesIndex} accessLevel={2} idModulos={[14]} /> 
        </>
    );
}

export default withLiterals(['Global','Tributos','Tramites', 'Plusvalias'])(TributosIndex);