import {FC, useCallback, useContext, useEffect} from "react";
import { MenuItem, WithStyles, withStyles } from "@material-ui/core";
import { mdiFileAccount } from "@mdi/js";
import Term from "components/term";
import { MoreInfoContext } from "contexts/more_info.context";
import usePage from "hooks/page.hook";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import duti_styles from "./styles";
import { LiteralsContext } from "containers/shared/literals";
import DUTIComponent from "./DUTI-component";

type Props = WithStyles<typeof duti_styles> 

const DUTIAlta: FC<Props> = ({classes}) => {
    const terms = useContext(LiteralsContext);
    const [pageState, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch])

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiFileAccount,
                title: <Term component="DUTI" text="DUSTIAlta" />,
                text:  <Term component="DUTI" text="DUSTIAltaDescripcion" />,
                moreInfoTemplate: 'mas_info_duti_alta',
                moreInfoTemplateSize: 'lg',
                moreInfoTemplateComponentIG: 'duti-ayuda',
                right: (
                    <>
                        <MenuItem button onClick={() => handleShowMasInfo('mas_info_duti_alta')}>
                            <Term component="Global" text="Mas informacion" />
                        </MenuItem>
                    </>
                )
            },
            menu: true,
            footer: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);


    return( <DUTIComponent DUSTI={null}/> )
   

}

export default withStyles(duti_styles)(DUTIAlta);