import React, {
  FC,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  DatosModeloSujetoLiqTrimestral,
  ICampo,
  IExtraButtonsModelo,
  IFuncionesValidar,
  IReturnModeloLiqTrimestral,
} from "../../../gateways/model.new.interface";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import { Button, makeStyles } from "@material-ui/core";
import styles from "./styles";
import {
  formatXMLtoObject,
  GenericInitialObject,
  getEmptyObject,
  getKeysRequiredCampos,
  renderCampos,
  identificativoFormatter,
  formatOptions,
  SelectoresInfo,
  sujetoFormatterCenso,
  Option,
  copySujetoPas,
  copyTransm,
  GenericObject,
  extractCasillaFromPreviousXml,
  vendedorFormatterDGT,
  vendedorFormatterDGTNotificado
} from "../utils";
import IoC from "contexts/ioc.context";
import ModelGateway from "gateways/model.new.gateway";
import { translate } from "utils/i18n";
import { AlertsContext } from "contexts/alerts.context";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { ISujeto } from "gateways/perfil.interfaces";
import { GeoGateway } from "gateways/geo.gateway";
import { Municipio, Provincia, Isla, Sigla } from "gateways/geo.interface";
import { ContenidosGateway } from "gateways/contenido.gateway";
import { extraerDatosModelo593 } from "utils/string";
import { ignoredYellowBox } from "console";

const useStyles = makeStyles(styles);

const TEST_SUJETO_593 = {
  //...loggedSujeto,
  nombre: 'rafael',
  nif: '51383988X',
  direccionNotificacion: {
    idDireccion: 52,
    codigoMunicipio: '',
    provincia: '',
    idPoblacion: 0,
    codigoPostal: '',
    sigla: '',
    via: '',
    numero: '',
    bloque: '',
    portal: '',
    escalera: '',
    piso: '',
    puerta: '',
    toponimia: '',
  },
  email: null,
  movil: null,
  emailNotificacion: '',
  movilNotificacion: '',
  activarAvisos: false,
  notificacion: false,
  notificacionPendienteConfirmacion: false,
  bajaNotificacionPendienteConfirmacion: false,
  modNotificacionPendienteConfirmacion: false,
  adhesionDeOficio: false,
  idiomaEnvioAvisos: 'es'
}
interface Props {
  idTipoModelo: string;
  idTipoApartado: string;
  datosXml: string | null;
  datosXmlApartadosPrevios: GenericObject,
  loggedSujeto: ISujeto | null;
  notificadoDGTExternal: boolean;
  setNoficadoDGTExternal: (value: boolean) => void
  setFinished: (value: boolean) => void;
  handleSave: (data: any) => Promise<boolean>;
}

const Identificativo: FC<Props> = (props) => {
  const {
    idTipoModelo,
    idTipoApartado,
    loggedSujeto,
    datosXml,
    datosXmlApartadosPrevios,
    setFinished,
    handleSave,
    setNoficadoDGTExternal,
    notificadoDGTExternal
  } = props;

  const VALIDAR_DATOS = ["621_A", "621_B", "621_C"];
  const EXTRA_BUTTONS: IExtraButtonsModelo = {
    "621_C": [
      {
        label: "pas",
        func: () => {
          setIsNewSujeto(false)
          copySujetoPas(data, setData, datosXmlApartadosPrevios, validateDatosCenso)
        }
      },
      {
        label: "transm",
        func: () => {
          setIsNewSujeto(false)
          copyTransm(data, setData, datosXmlApartadosPrevios, validaVendedor)
        }
      },
      {
        label: "nuevoSuj",
        func: () => {
          setIsNewSujeto(true)
          setData(EMPTY_SUJETO);
          setChanges(true);
          setValidado(!needsValidation);
        }
      }
    ]
  }

  const classes = useStyles();
  const [, alertsDispatch] = useContext(AlertsContext);
  const terms = useContext(LiteralsContext);
  //Gateways
  const ioc = useContext(IoC);
  const modelGateway: ModelGateway = useMemo(
    () => ioc.get(ModelGateway),
    [ioc]
  );
  const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
  const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
  //Local data
  const needsValidation = useMemo(() => VALIDAR_DATOS.includes(idTipoApartado), [VALIDAR_DATOS, idTipoApartado]);
  const [isNewSujeto, setIsNewSujeto] = useState(true)
  const HAS_EXTRA_BUTTONS = useMemo(() => EXTRA_BUTTONS[idTipoApartado] !== undefined, [EXTRA_BUTTONS, idTipoApartado]);
  const [validado, setValidado] = useState(!needsValidation);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GenericInitialObject | null>(null);
  const [changes, setChanges] = useState(false);
  const [campos, setCampos] = useState<ICampo[] | null>(null);
  const [EMPTY_SUJETO, setEmptySujeto] = useState<GenericInitialObject | null>(
    {}
  );
  const [notificadoDGT, setNotificadoDGT] = useState(notificadoDGTExternal)
  const [camposRequiredKeys, setCamposRequiredKeys] =
    useState<string[] | null>(null);
  const [selectoresInfo, setSelectoresInfo] =
    useState<SelectoresInfo | null>(null);

  const CirNimaApartados = ['593_A', '594_A']
  const CirNimaModelo = ['593', '594']


  // Functions - Get data ------------------------------------------------------------------------------------------------------
  const getNimaCir = async (
    nif: string
  ): Promise<{ nima: string | null; cir: string[] | null }> => {
    return await modelGateway.getNimaCir(
      nif,
      translate("Tributos", "GetNimaCirError", terms)
    );
  };

  const updateData = async (name: string, value: any) => {
    // console.log("handleSave", name, value)
    setChanges(true);
    setValidado(!needsValidation);
    let newData = null;
    // console.log('update name: '+ name + ' value:'+ value, EMPTY_SUJETO)
    if (EMPTY_SUJETO) {
      newData = data
        ? { ...data, [name]: { ...data[name], value: value } }
        : { ...EMPTY_SUJETO, [name]: { ...EMPTY_SUJETO[name], value: value } };

      if (CirNimaApartados.includes(idTipoApartado) && name === 'nif' && value.length >= 9) {
        //console.log('update nif', idTipoApartado)
        // Recuperar NIMA y CIR --- mySujeto.nif
        const response = await getNimaCir(value)
        newData = {
          ...newData,
          'nima': { ...newData['nima'], value: response.nima },
          'cir8': response.cir?.length === 1
            ? { ...newData['cir8'], value: response.cir[0] }
            : { ...newData['cir8'] }
        }
        const cirOption: Option[] | null = response.cir ? response.cir.map(c => ({ id: c, nombre: c })) : null
        //console.log('cirOption', cirOption)
        setSelectoresInfo(s => ({ ...s, cir: cirOption }))
      } else if (CirNimaApartados.includes(idTipoApartado) && name === 'nif' && value.length < 9 && newData['nima'].value) {
        newData = {
          ...newData,
          'nima': { ...newData['nima'], value: null },
        }
      }
    }
    setData(newData)
  }
  const onChargeSujeto = async () => {
    try {
      if (loggedSujeto) {
        const provincia: Option | undefined = selectoresInfo?.provincia?.find(p => p.id === loggedSujeto.direccionNotificacion.provincia)
        const municipio: Option | undefined = selectoresInfo?.municipio?.find(p => p.id === loggedSujeto.direccionNotificacion.codigoMunicipio)
        const mySujeto = {
          ...loggedSujeto,
          direccionNotificacion: {
            ...loggedSujeto.direccionNotificacion,
            provincia: provincia?.nombre || '',
            codigoMunicipio: municipio?.nombre || ''
          }
        }
        let newData = identificativoFormatter(data ? data : {}, mySujeto);
        let cirOption: Option[] | null = null
        if (CirNimaModelo.includes(idTipoModelo)) {
          // Recuperar NIMA y CIR --- mySujeto.nif
          const response = await getNimaCir(mySujeto.nif)
          newData = {
            ...newData,
            'nima': { ...newData['nima'], value: response.nima },
            'cir8': response.cir?.length === 1
              ? { ...newData['cir8'], value: response.cir[0] }
              : { ...newData['cir8'] }
          }
          cirOption = response.cir ? response.cir.map(c => ({ id: c, nombre: c })) : null
        }
        if (idTipoModelo === '621') {
          newData = {
            ...newData, "nomVia": { ...newData["nomVia"], value: "" },
            "numero": { ...newData["numero"], value: "" },
            "locaMuni": { ...newData["locaMuni"], value: "" }
          }
          setValidado(!needsValidation)
          setIsNewSujeto(true)
        }
        setChanges(true)
        setData(newData)
        setSelectoresInfo(s => ({ ...s, cir: cirOption }))
      }
    } catch (error) {
      const result = (error as Error).message;
      setLoading(false)
      alertsDispatch({
        type: 'show-alert',
        payload: {
          message: result,
          variant: 'error',
        }
      });
    }

  };

  const validateDatosCenso = useCallback(async () => {
    setLoading(true)
    if (data !== null && data["nif"].value !== null && data["apeNom"].value) {
      const nif = data["nif"].value;
      const nombre = data["apeNom"].value;
      let result = await modelGateway.validateDatosCenso(
        nif,
        nombre,
        idTipoApartado === "621_A"
      );
      if (result.ok && result.data.cp.substring(0, 2) === "07") {
        const newData = sujetoFormatterCenso(data ? data : {}, result.data);
        setData(newData);
        setValidado(needsValidation);
      } else {
        alertsDispatch({
          type: "show-alert",
          payload: {
            message: translate("Global", "ErrorCenso", terms),
            variant: "error",
          },
        });
      }
    } else {
      setChanges(true);
      setValidado(!needsValidation);

      alertsDispatch({
        type: "show-alert",
        payload: {
          message: translate("Tributos", "FormularioError", terms),
          variant: "error",
        },
      });
    }
    setLoading(false)
  }, [alertsDispatch, data, modelGateway, terms]);

  const validaVendedor = async () => {
    try {
      setLoading(true)
      if (data !== null && data["nif"].value && data["apeNom"].value) {
        const nif = data["nif"].value;
        const nombre = data["apeNom"].value;
        let nifComprador = extractCasillaFromPreviousXml(Object.values(datosXmlApartadosPrevios).join(''), "621_A.5")
        if (nifComprador === nif) {
          const bodyAux = await contenidoGateway.getContent(
            "NoPuedeMismoSujeto",
            {}
          );
          alertsDispatch({
            type: 'show-alert',
            payload: {
              isHtml: true,
              message: bodyAux[0].contenido,
              variant: 'error',
            }
          });
          setLoading(false);
          setValidado(!needsValidation)
          setChanges(true)
          return;
        }
        let response = await modelGateway.validateConsultaDGT(data["matriculaDGT"] !== undefined ? data["matriculaDGT"].value : extractCasillaFromPreviousXml(Object.values(datosXmlApartadosPrevios).join(''), "621_B.504"), nombre, nif, idTipoApartado === "621_C" ? true : notificadoDGT)
        if (response.ok) {
          if (idTipoApartado === "621_C" || notificadoDGT) {
            const newData = vendedorFormatterDGTNotificado(data ? data : {}, response.data, data['apeNom'].value, data["nif"].value);
            setData(newData);
            setValidado(needsValidation);
          }
          else {
            const newData = vendedorFormatterDGT(data ? data : {}, response.data);
            setData(newData);
            setValidado(needsValidation);
          }
        }
        else {
          setChanges(true);
          setValidado(!needsValidation);
          const bodyAux = await contenidoGateway.getContent(
            response.errorMessage,
            {}
          );
          alertsDispatch({
            type: 'show-alert',
            payload: {
              isHtml: true,
              message: bodyAux[0].contenido,
              variant: 'error',
            }
          });
          setLoading(false)
          return;
        }
      }
      else {
        setChanges(true);
        setValidado(!needsValidation);
        setLoading(false)
        alertsDispatch({
          type: "show-alert",
          payload: {
            message: translate("Tributos", "FormularioError", terms),
            variant: "error",
          },
        });
      }
    }
    catch (e) {
      const bodyAux = await contenidoGateway.getContent(
        'ErrorDesconocido2',
        {}
      );
      alertsDispatch({
        type: 'show-alert',
        payload: {
          isHtml: true,
          message: bodyAux[0].contenido,
          variant: 'error',
        }
      });
      setLoading(false)
      return;
    }
  }


  const checkPresentador = () => {
    if (isNewSujeto) {
      setValidado(true);
    }
    else {
      validateDatosCenso()
    }
  }


  // useEffect(() => {
  //   if(idTipoApartado === "621_C"){
  //     isNewSujeto ? showForm() : hideForm()
  //   }
  // },[campos, idTipoApartado, isNewSujeto])

  const handleIsModelAlreadyPresented = useCallback(async (data: GenericInitialObject): Promise<IReturnModeloLiqTrimestral> => {
    let res: [string | null, string | null] = extraerDatosModelo593(datosXmlApartadosPrevios[`${idTipoModelo}_0`], idTipoModelo)
    let reference = ""
    if (res[0] === null || res[1] === null) {
      return { exists: false, reference: reference }
    }
    let dataToSend: DatosModeloSujetoLiqTrimestral = {
      cir8: data["cir8"].value,
      ejercicio: res[0],
      nif: data["nif"].value,
      nima: data["nima"].value,
      periodo: res[1]
    }

    let result = await modelGateway.comprobarExistenciaModelo(dataToSend, "Error al recuperar el MODELO8");

    return result
  }, [datosXmlApartadosPrevios])



  const onSave = () => {
    (async () => {
      setLoading(true);
      // comprobar campos required
      let valid = true;
      let alreadyPresented: IReturnModeloLiqTrimestral = { exists: false, reference: "" };
      // console.log('on save camposRequiredKeys', camposRequiredKeys)
      if (camposRequiredKeys && camposRequiredKeys.length > 0) {
        if (data) {
          camposRequiredKeys.forEach(async (element) => {
            switch (data[element].tipo) {
              case "number":
                if (Number(data[element].value) === 0) {
                  valid = false;
                }
                break;
              case "decimal" || "decimal2" || 'decimal3':
                if (parseFloat(data[element].value) === 0) {
                  valid = false;
                }
                break;
              default:
                if (
                  !data[element].value ||
                  data[element].value === undefined ||
                  data[element].value === ""
                ) {
                  valid = false;
                }
                break;
            }
          });
          if (CirNimaApartados.includes(idTipoApartado)) {
            alreadyPresented = await handleIsModelAlreadyPresented(data)
            if (alreadyPresented.exists) {
              valid = false
            }
          }
        } else {
          valid = false;
        }
      }
      //console.log('valid', valid)
      //console.log('data', data)

      if (valid) {
        const saved = await handleSave(data);
        //setFinished(saved);
        setChanges(!saved);
      } else {
        //setFinished(false);
        setChanges(true);
        if (alreadyPresented.exists) {
          console.log("alreadyPresented.reference", alreadyPresented.reference)
          alertsDispatch({
            type: "show-alert",
            payload: {
              message: translate("Tributos", "FormularioErrorYaExistente", terms) + `  ${alreadyPresented.reference}`,
              variant: "error",
            },
          });
        } else {
          alertsDispatch({
            type: "show-alert",
            payload: {
              message: translate("Tributos", "FormularioError", terms),
              variant: "error",
            },
          });
        }
      }
      setLoading(false);
    })();
  };
  // ---------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        //console.log('idTipoApartado', idTipoApartado)
        const camposApartado = await modelGateway.getCamposApartado(
          idTipoApartado,
          idTipoModelo,
          translate("Tributos", "GetCamposApartadoError", terms)
        );
        // console.log('camposApartado', camposApartado)

        setCampos(camposApartado);
        let emptyObjt = null;
        let newData: GenericInitialObject | null = null;
        let cirOption: Option[] | null = null;
        //console.log('condicion', camposApartado && camposApartado.length>0 )
        if (camposApartado && camposApartado.length > 0) {
          emptyObjt = getEmptyObject(camposApartado, false)
          //console.log('emptyObjt', emptyObjt)
          setEmptySujeto(emptyObjt);
          const keys = getKeysRequiredCampos(camposApartado);
          setCamposRequiredKeys(keys)
          if (datosXml) {
            //console.log('USE datosXML',datosXml)
            newData = formatXMLtoObject(datosXml, emptyObjt);
            //console.log('USE datosXML newData',newData);
            if (newData['nif'].value && CirNimaApartados.includes(idTipoApartado)) {
              const response = await getNimaCir(newData['nif'].value)
              cirOption = response.cir ? response.cir.map(c => ({ id: c, nombre: c })) : null
            }
            setChanges(false);
            setFinished(true);
            if (newData['nif'].value && idTipoApartado === '621_B') {
              let nifComprador = extractCasillaFromPreviousXml(Object.values(datosXmlApartadosPrevios).join(''), "621_A.5")
              let nifVendedor = extractCasillaFromPreviousXml(Object.values(datosXmlApartadosPrevios).join(''), "621_B.19")
              if (nifComprador === nifVendedor) {
                const bodyAux = await contenidoGateway.getContent(
                  "NoPuedeMismoSujeto",
                  {}
                );
                alertsDispatch({
                  type: 'show-alert',
                  payload: {
                    isHtml: true,
                    message: bodyAux[0].contenido,
                    variant: 'error',
                  }
                });
                setLoading(false);
                setValidado(!needsValidation)
                setChanges(true)
              }
            }
          } else {
            setChanges(true);
            newData = emptyObjt
          }
        }
        //console.log('newData', newData)
        setData(newData);

        //Empty Selectores data
        const provinciaBaleares = "07";
        const optionsTV = await geoGateway.getSiglas();
        const optionsProv = await geoGateway.getProvincias("");
        const selected: Provincia | null | undefined =
          optionsProv && newData?.provincia?.value
            ? optionsProv.find(
              (opt) =>
                opt.nombre === newData?.provincia?.value.toString() ||
                opt.idProvincia === newData?.provincia?.value.toString()
            )
            : null;

        const optionsMuni = await geoGateway.getMunicipios(
          selected?.idProvincia || provinciaBaleares
        );
        const optionsIsla = await geoGateway.getIslas();
        setSelectoresInfo(() => ({
          isla:
            optionsIsla && optionsIsla.length > 0
              ? formatOptions<Isla>("idIsla", "nombre", optionsIsla)
              : null,
          municipio:
            optionsMuni && optionsMuni.length > 0
              ? formatOptions<Municipio>("idMunicipio", "nombre", optionsMuni)
              : null,
          provincia:
            optionsProv.length > 0
              ? formatOptions<Provincia>("idProvincia", "nombre", optionsProv)
              : null,
          tipoVia:
            optionsTV.length > 0
              ? formatOptions<Sigla>("idSiglas", "nombre", optionsTV)
              : null,
          cir: cirOption && cirOption.length > 0 ? cirOption : null,
        }));
        setLoading(false);
      } catch (error) {
        const result = (error as Error).message;
        setLoading(false);
        alertsDispatch({
          type: "show-alert",
          payload: {
            message: result,
            variant: "error",
          },
        });
      }
    })();
  }, [idTipoApartado, idTipoModelo, loggedSujeto, datosXml]);

  useEffect(() => {
    if (changes) {
      setFinished(false);
    } else {
      setFinished(true);
    }
  }, [changes]);

  useEffect(() => {
    if (validado && needsValidation) {
      onSave();
    }
  }, [validado]);

  const FUNCIONES_VALIDATE: IFuncionesValidar = {
    "621_A": validateDatosCenso,
    "621_B": validaVendedor,
    "621_C": checkPresentador,
  };
  return (
    <div>
      <div className={classes.rowAlignLeft}>
        <Tooltip
          placement="top"
          title={
            loggedSujeto === null
              ? translate("Tributos", "tooltipRequiredLogged", terms)
              : ""
          }
        >
          <Button
            className={classes.btnChargeDataUserLogged}
            color="primary"
            variant="contained"
            component={loggedSujeto === null ? "div" : "button"}
            disabled={loggedSujeto === null}
            onClick={onChargeSujeto}
          >
            {translate("Tributos", "btnChargeDataUserLogged", terms)}
          </Button>
        </Tooltip>

        <Button
          style={{ marginLeft: 10 }}
          color="primary"
          variant="contained"
          disabled={data === null || data === EMPTY_SUJETO}
          onClick={() => {
            setData(EMPTY_SUJETO);
            setChanges(true);
            setValidado(!needsValidation);
            setNoficadoDGTExternal(false);
            setNotificadoDGT(false)
          }}
        >
          {translate("Tributos", "btnClean", terms)}
        </Button>
        {HAS_EXTRA_BUTTONS &&
          <>
            {EXTRA_BUTTONS[idTipoApartado].map((x) => {
              return (
                <Button
                  style={{ marginLeft: 10 }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setChanges(true);
                    setValidado(!needsValidation)
                    x.func()
                  }}
                >
                  {translate("Tributos", x.label, terms)}
                </Button>
              )
            })}
          </>
        }
      </div>

      <Dialog open={loading}>
        <DialogContent>
          <CircularProgress size={35} />
        </DialogContent>
      </Dialog>
      {campos && campos.length > 0 && camposRequiredKeys !== null && data && selectoresInfo &&
        renderCampos(campos, data, updateData, "", idTipoApartado, selectoresInfo, undefined, classes)}
      {
        idTipoApartado === '621_B'
          ?
          <FormControl style={{ display: 'flex', alignItems: 'start', marginLeft: 10 }} >
            <FormControlLabel control={
              <Checkbox
                {...{ inputProps: { "aria-label": `${translate('Tributos', "NotificadoDGT", terms)}` } }}
                classes={{
                  root: classes.root,
                }}
                color='primary'
                value={notificadoDGT}
                checked={notificadoDGT}
                onChange={() => {
                  setNotificadoDGT(!notificadoDGT)
                  setNoficadoDGTExternal(!notificadoDGT)
                }}
              />}
              label={`${translate('Tributos', "NotificadoDGT", terms)}`}
            />
          </FormControl>
          : <></>
      }
      <div className={classes.rowAlignRight}>
        {needsValidation ? (
          <Button
            style={{ marginLeft: 10 }}
            color="primary"
            disabled={!changes}
            variant="contained"
            onClick={FUNCIONES_VALIDATE[idTipoApartado]}
          >
            {translate("Tributos", "btnValidate", terms)}
          </Button>
        ) : (
          <Button
            style={{ marginLeft: 10 }}
            color="primary"
            disabled={!changes}
            variant="contained"
            onClick={onSave}
          >
            {translate("Tributos", "btnSave", terms)}
          </Button>
        )}
      </div>
    </div>
  );
};
export default withLiterals(["Tributos"])(Identificativo);
