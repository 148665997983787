import React, { FC, useContext } from 'react';
import { RouteComponentProps, withRouter, Route } from 'react-router-dom';
import Acceso from './login'
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import RecuperacionContrasenya from './recuperacion-contrasenya';
import CambiarContrasenya from './cambio-contrasenya';
import EntradaPage from './entrada';
import ClavePage from './clave';
import Registro from './registro';
import RedirectClave from './redirect-clave';
import Contenido from 'components/contenido';
import { translate } from 'utils/i18n';
import { mdiLoginVariant } from "@mdi/js";


const AccesoIndex: FC<RouteComponentProps> = ({ match }) => {
    const terms = useContext(LiteralsContext)

    return (
        <>
            <Route exact path={`${match.path}`} component={Acceso} />            
            <Route exact path={`${match.path}/registro`} component={Registro} />
            <Route exact path={`${match.path}/recuperar`} component={RecuperacionContrasenya} />
            <Route exact path={`${match.path}/cambiar`} component={CambiarContrasenya} />
            <Route exact path={`${match.path}/entrada`} component={EntradaPage} />
            <Route exact path={`${match.path}/clave`} component={ClavePage} />
            <Route exact path={`${match.path}/redirect-clave`} component={RedirectClave} />
            <Route exact path={`${match.path}/contenidos`} render={(props) => <Contenido landingName='mas-info-login' {...props} pageTitle={translate('Global', 'FormasAcceso', terms)} icon={mdiLoginVariant}/>} />
        </>
    );
}

export default withLiterals(['Global','Login','Acceso'])(withRouter(AccesoIndex));
