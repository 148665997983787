import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Grid, Step, StepConnector, StepLabel, Stepper, makeStyles, useTheme, withStyles } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { IParamsModelosDusti } from '../../pasarela-pago/params.interfaces';
import styles from "../styles";
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import PagoModeloDusti from './pago.modelo.dusti';
import { useLocation } from 'react-router';
import IoC from 'contexts/ioc.context';
import DUTIGateway from 'gateways/duti.gateway';
import { IModel } from 'gateways/model.interfaces';
import { mdiClipboardCheckOutline } from '@mdi/js';
import Term from 'components/term';
import PresentacionExpedienteDusti from './presentacion.expediente.dusti';
import { IDetallePagoDeclaracion } from 'gateways/duti.interface';
import { TIPO_MODELO_081, TIPO_MODELO_600 } from 'containers/tributos-autonomicos-locales/constants';
import FullLoading from 'components/full-loading';
import { CustomError } from 'utils/custom.error';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import usePage from 'hooks/page.hook';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 30,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useStyles = makeStyles(styles);

const PasarelaDusti: FC<IParamsModelosDusti> = ({ idDeclaracion, steps, handleNextStep, setIdDeclaracion, setHeaderStepper, setShouldBlock }) => {    
    const [loading, setLoading] = useState(false);
    const [loadingConfirmacion, setLoadingConfirmacion] = useState(false);
    const [modelo, setModelo] = useState<IModel | null>(null);
    const [pagoConfirmado600, setPagoConfirmado600] = useState(false);
    const [pagoConfirmado081, setPagoConfirmado081] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [displayStepper,] = useState(true);
    const [idOperacion, setIdOperacion] = useState<string | null>(null);
    const [idDeclaracionPago, setIdDeclaracionPago] = useState(idDeclaracion);
    const [detallePago, setDetallePago] = useState<IDetallePagoDeclaracion>();

    // Global states
    const theme = useTheme();
    const classes = useStyles(theme);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    const terms = useContext(LiteralsContext);
    const location = useLocation();    

    // Services
    const ioc = useContext(IoC);
    const gateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);

    const setIconStepOne = () => {
        return (
            <Button style={{cursor: 'default'}}>
                <div
                    className={[
                        classes.iconBox, activeStep === 0 
                        ? classes.activeIconBox 
                        :  activeStep>0 ? classes.completedIconBox : classes.nonActiveIconBox
                    ].join(' ')}
                    style={activeStep === 0 ? { backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))' } : { backgroundColor: 'lightgrey' }}
                >
                    <Icon path={mdiClipboardCheckOutline}
                        size={'25px'}
                        color={"white"}
                    />
                </div>
            </Button>
        )
    }
    
    const setIconStepTwo = () => {
        return (
            <Button style={{cursor: 'default'}}>
                <div
                    className={[
                        classes.iconBox, 
                        activeStep === 1 
                        ? classes.activeIconBox 
                        : activeStep>1 ? classes.completedIconBox : classes.nonActiveIconBox
                    ].join(' ')}
                    style={activeStep === 1 ? { backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))' } : { backgroundColor: 'lightgrey' }}
                >
                    <Icon path={mdiClipboardCheckOutline}
                        size={'25px'}
                        color={"white"}
                    />
                </div>
            </Button>
        )
    }

    const setIconStepThree = () => {
        return (
            <Button style={{cursor: 'default'}}>
                <div
                    className={[
                        classes.iconBox, 
                        activeStep === 2 
                        ? classes.activeIconBox 
                        : activeStep>2 ? classes.completedIconBox : classes.nonActiveIconBox
                    ].join(' ')}
                    style={activeStep === 2 ? { backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))' } : { backgroundColor: 'lightgrey' }}
                >
                    <Icon path={mdiClipboardCheckOutline}
                        size={'25px'}
                        color={"white"}
                    />
                </div>
            </Button>
        )
    }

    const getActiveStep = useCallback(() => {
        if (detallePago?.acciones.pagoModelo600 && detallePago?.acciones.pagoModelo081 && detallePago?.acciones.presentacionAutoliquidacion) {
            return activeStep === 0 ? 1 : 2;
        } else if (detallePago?.acciones.pagoModelo600 && !detallePago?.acciones.pagoModelo081 && detallePago?.acciones.presentacionAutoliquidacion) {
            return 2;
        } else if (!detallePago?.acciones.presentacionAutoliquidacion) {
            return activeStep === 0 ? 1 : 2;
        }
        return 0;
    }, [activeStep, detallePago]);

    const pagoFinalizado = useCallback(() => {
        if ((detallePago?.acciones.pagoModelo600 && detallePago?.acciones.pagoModelo081 && detallePago?.acciones.presentacionAutoliquidacion) ||
            (detallePago?.acciones.pagoModelo600 && detallePago?.acciones.presentacionAutoliquidacion)) {
            return activeStep === 2;
        } else if (detallePago?.acciones.pagoModelo600 && detallePago?.acciones.pagoModelo081 && !detallePago?.acciones.presentacionAutoliquidacion) {
            return activeStep === 1;
        } else if (!detallePago?.acciones.pagoModelo600 && detallePago?.acciones.pagoModelo081) {
            return activeStep === 1;
        }
        return activeStep === 0;
    }, [activeStep, detallePago]);

    const handleNext = useCallback(() => {        
        if (pagoFinalizado()) {
            handleNextStep(steps);
        } else {
            setActiveStep(getActiveStep());
            setModelo(null);
            setIdOperacion(null);
        }
    }, [activeStep, steps, detallePago]);
        
    // Effects
    useEffect(() => {
        const fetchModelsData = async () => {
            setLoading(true);

            try {
                const query = new URLSearchParams(location.search);
                const idDeclaracionQuery = query.get('idDeclaracion');
                if (idDeclaracionQuery) {
                    const idDeclaracion = parseInt(idDeclaracionQuery, 10);
                    setIdOperacion(query.get('idOperacion'));
                    setIdDeclaracion(idDeclaracion);
                    setIdDeclaracionPago(idDeclaracion);
                    const detallePago = await gateway.getDetallePagoDeclaracion(idDeclaracion);
                    setDetallePago(detallePago);
                    const infoDeclaracion = await gateway.getDetalleDeclaracion(idDeclaracion);
                    setHeaderStepper({
                        refCat: infoDeclaracion.DatosTransmision.datosPrevios.infoCatastral?.refCatastral ?? ' - ',
                        dir: infoDeclaracion.DatosTransmision.datosPrevios.infoCatastral?.direccion ?? ' - ',
                        urlCatastro: infoDeclaracion.DatosTransmision.datosPrevios.infoCatastral?.urlCatastro ?? ' - ',
                        muni: infoDeclaracion.DatosTransmision.datosPrevios.municipio?.code
                    });
                } else {
                    const detallePago = await gateway.getDetallePagoDeclaracion(idDeclaracionPago);
                    setDetallePago(detallePago);
                    setActiveStep(detallePago.modelos.some(m => m.idTipoModelo === TIPO_MODELO_600) ? 0 : 1);
                }
            } catch (error) {
                if (error instanceof CustomError && error.code === 403) {
                    setShouldBlock(false);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Global', 'sesion_caducada', terms),
                            variant: 'warning',
                            hasCustomAction: true,
                            handleCustomAction: () => { 
                                pageDispatcher({ type: "logout" });
                            }
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('DUTI','error_dusti', terms),
                            variant: "error"
                        }
                    });
                }
            } finally {
                setLoading(false);
            }
        };

        fetchModelsData();
    }, []);

    useEffect(() => {
        const fetchConfirmacion = async () => {
            setLoadingConfirmacion(true);
            if (idOperacion) {
                try {
                    const result = await gateway.confirmarPago(idOperacion);
                    if (result.entidades) {
                        const entidad = result.entidades[0];
                        setModelo(entidad.modelo);
                        if (entidad.modelo.idTipoModelo === "600") {
                            setActiveStep(0);
                            setPagoConfirmado600(entidad.validado);
                        } else {
                            setActiveStep(1);
                            setPagoConfirmado081(entidad.validado);
                        }
                    }
                } catch (error) {
                    if (error instanceof CustomError && error.code === 403) {
                        setShouldBlock(false);
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('Global', 'sesion_caducada', terms),
                                variant: 'warning',
                                hasCustomAction: true,
                                handleCustomAction: () => { 
                                    pageDispatcher({ type: "logout" });
                                }
                            }
                        });
                    } else {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('DUTI','error_dusti', terms),
                                variant: "error"
                            }
                        });
                    }
                }
            }
            setLoadingConfirmacion(false);
        };

        fetchConfirmacion();
    }, [idOperacion, gateway]);

    return (
        <Grid container direction='column' className={classes.componenteContainer}>
            <FullLoading loading={loading || loadingConfirmacion} animation={{type:'intricom',name:'DUSTI'}}/>
            {
            !(loading || loadingConfirmacion) &&
                <div className={classes.rootStepper} style={{display:'flex', flexGrow:1, paddingRight:10, paddingLeft:10}}>
                    {displayStepper &&
                        // <div className={classes.stepperContainer}>  </div>
                            <Card style={{marginBottom: 20}} >
                                <Stepper className={classes.stepsStepperCard} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                    { detallePago?.acciones.pagoModelo600 &&
                                    <Step>
                                        <StepLabel StepIconComponent={setIconStepOne}>
                                            <div className={activeStep === 0 ? classes.boldText : classes.lightText}>
                                                <Term component="DUTI" text="Pago modelo 600" />
                                            </div>
                                        </StepLabel>
                                    </Step>
                                    }
                                    { detallePago?.acciones.pagoModelo081 &&
                                    <Step>
                                        <StepLabel StepIconComponent={setIconStepTwo}>
                                            <div className={activeStep === 1 ? classes.boldText : classes.lightText}>
                                                <Term component="DUTI" text="Pago plusvalia" />
                                            </div>
                                        </StepLabel>
                                    </Step>
                                    }
                                    { detallePago?.acciones.presentacionAutoliquidacion &&
                                    <Step>
                                        <StepLabel StepIconComponent={setIconStepThree}>
                                            <div className={activeStep === 2 ? classes.boldText : classes.lightText}>
                                                <Term component="DUTI" text="Presentación" />
                                            </div>
                                        </StepLabel>
                                    </Step>
                                    }
                                </Stepper>
                            </Card>
                      
                    }

                    {activeStep === 0 && detallePago?.acciones.pagoModelo600 &&
                        <PagoModeloDusti 
                            idDeclaracion={idDeclaracionPago}
                            modelosDeclaracion={detallePago ? detallePago.modelos.filter(m => m.idTipoModelo === TIPO_MODELO_600) : []} 
                            idTipoModelo={TIPO_MODELO_600}
                            modelo={modelo}
                            pagoConfirmado={pagoConfirmado600}
                            showNext={pagoConfirmado600}
                            handleNext={handleNext}
                        />
                    }

                    {activeStep === 1 && detallePago?.acciones.pagoModelo081 &&
                        <PagoModeloDusti 
                            idDeclaracion={idDeclaracionPago}
                            modelosDeclaracion={detallePago ? detallePago.modelos.filter(m => m.idTipoModelo === TIPO_MODELO_081) : []} 
                            idTipoModelo={TIPO_MODELO_081}
                            modelo={modelo}
                            pagoConfirmado={pagoConfirmado081}
                            showNext={pagoConfirmado081}
                            handleNext={handleNext}
                        />
                    }

                    {activeStep === 2 && detallePago?.acciones.presentacionAutoliquidacion &&
                        <PresentacionExpedienteDusti
                            idExpediente={detallePago?.idExpediente}   
                            handleNext={handleNext}
                        />
                    }
                </div>
            }
        </Grid>
    );
}

export default withLiterals(["Global","PasarelaPago", "DUTI"])(PasarelaDusti);
