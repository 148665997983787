import { ICampos, ICamposByDoc } from "gateways/tramites/abonoNacimiento.interfaces";
import { cloneDeep } from "lodash";

export const camposToObject = (campos: ICampos[]) => {
    const obj = campos.reduce( (prev, curr) => {
        const value= curr.campos.reduce( (cPrev, cCurr) => ({...cPrev, [cCurr]: undefined}), {})
        const panelName = curr.panel.replace('panel_','')
        const toAdd = panelName !== "" ? {[panelName]: value} : value
        return ({...prev,...toAdd});
    }, {})

    return obj
}

export const camposByDocToObject = (campos: ICamposByDoc[]) => {

    const obj = campos.reduce( (prev, curr) => {
        const value= curr.campos.reduce( (cPrev, cCurr) => {
            const camponValue = cCurr.children.length>0 
            ? cCurr.children.reduce((pCh,cCh) => ({...pCh, [cCh]: undefined}),{}) //  cCurr.children.reduce((pCh,cCh) => ({...pCh, [cCh]: cCh.includes('valor') ? undefined: false}),{})
            : undefined
            return ({
                ...cPrev, 
                [cCurr.campo]: camponValue
            })
        }, {})
        const panelName = curr.panel.replace('panel_','')
        const toAdd = panelName !== "" ? {[panelName]: value} : value
        return ({...prev,...toAdd});
    }, {})

    // console.log('camposByDocToObject ', obj)

    return obj
}

  

  type ValueOrNestedObject<T> = {
    [P in keyof T]: T[P] | ValueOrNestedObject<T[P]>;
};

export const deepUpdate = <T>(original: ValueOrNestedObject<T>, partial: Partial<ValueOrNestedObject<T>>): ValueOrNestedObject<T> => {
    // Crear una copia superficial del objeto original para comenzar
    const updated = { ...original };

    // Recorrer cada propiedad en el objeto 'partial'
    Object.entries(partial).forEach(([key, partialValue]) => {
        const originalValue = original[key as keyof T];

        if (partialValue !== undefined && partialValue !== null && typeof partialValue === 'object' && !Array.isArray(partialValue)) {
            if (typeof originalValue === 'object' && originalValue !== null && !Array.isArray(originalValue)) {
                // Si ambos valores son objetos, se llama recursivamente a deepUpdate y se asigna el resultado
                updated[key as keyof T] = deepUpdate(originalValue as ValueOrNestedObject<T[keyof T]>, partialValue as Partial<ValueOrNestedObject<T[keyof T]>>);
            } else {
                // Si el valor original no es un objeto, simplemente se asigna el nuevo objeto
                updated[key as keyof T] = { ...partialValue } as ValueOrNestedObject<T[keyof T]>;
            }
        } else {
            // Si es un valor primitivo o un array, actualizar directamente
            updated[key as keyof T] = partialValue as T[keyof T];
        }
    });

    // Retornar el objeto actualizado sin modificar el original
    return updated;
};

// --------------------------------------------------------------------------------------------------------

  
