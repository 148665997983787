import IoC from "contexts/ioc.context";
import { ISujeto } from "gateways/perfil.interfaces";
import { SujetosGateway } from "gateways/sujetos.gateway";
import { useContext, useEffect, useMemo, useState } from "react";

const useSujeto = () => {
    const ioc = useContext(IoC);
    const perfilG = useMemo(() => ioc.get(SujetosGateway) as SujetosGateway, [ioc]);

    const [sujeto, setSujeto] = useState<ISujeto | null>(null)

    useEffect(() => {
        (async () => {
            const sujetoTemp = await perfilG.getDatosSujeto();

            if (sujetoTemp) {
                setSujeto(sujetoTemp);
            }
        })();
    }, [perfilG]);

    return { sujeto };
}

export default useSujeto;
