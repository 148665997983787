import {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Card, CardContent, MenuItem, WithStyles, withStyles } from "@material-ui/core";
import { mdiFileAccount } from "@mdi/js";
import Term from "components/term";
import { MoreInfoContext } from "contexts/more_info.context";
import usePage from "hooks/page.hook";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";

import FullLoading from "components/full-loading";
import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import duti_styles from "./styles";

import imageNoHayDeclaraciones from '../../resources/no-deuda.png';
import InfiniteScroll from "react-infinite-scroller";
import InfiniteScrollLoader from "components/infinite-scroll-loader";
import { IDeclaracion } from "gateways/duti.interface";
import NoContent from "components/no-content";
import DustiItemList, { TDustiConsultaAction } from "components/DUTI/dusti-item-list";
import { CustomError } from "utils/custom.error";
import { translate } from "utils/i18n";
import { AlertsContext } from "contexts/alerts.context";
import { LiteralsContext } from "containers/shared/literals";

interface IRouteParams {}
type Props = RouteComponentProps<IRouteParams> & WithStyles<typeof duti_styles>;

const DUTIConsulta: FC<Props> = ({classes, history, match}) => {
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();

    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);

    const componentMounted = useRef(true);    
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [loading, setLoading] = useState(false);
    const [declaraciones, setDeclaraciones] = useState<IDeclaracion[]>([]);
    //declaraciones que se muestran 
    const [, setDeclaracionesList] = useState<IDeclaracion[]>([]);
    //totalCount
    const declaracionesCount = useMemo(() => declaraciones.length,[declaraciones])
    const [page,setPage] = useState(0);
    const numberElementsPerPage = 50;
    const hasMore = useMemo(() => (
        declaracionesCount > (page === 0 ? (page + 1) : page) * numberElementsPerPage
    ), [page, declaracionesCount]);

    const init = async() => {
        setLoading(true);

        try {
            const declaraciones = await dutiGateway.getAllDeclaraciones()
            setDeclaraciones(declaraciones);
        } catch (error) {
            if (error instanceof CustomError && error.code === 403) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_dusti', terms),
                        variant: "error"
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const onAction = (dustiItem: IDeclaracion, action: TDustiConsultaAction) => {
        //console.log('onAction')
        history.push({
            pathname: `${match.path}/action`,
            state: { action: action, declaracion: dustiItem }
        })
        

    }
    // Gestion Infinite scroll -------------------------------------------------------
    const loadPageDeclaraciones = (filters: undefined, page:number) => {
        const calcInitIndex = page * numberElementsPerPage
        const declaracionesNextPage = declaraciones.slice(calcInitIndex, numberElementsPerPage)
        setDeclaracionesList(declaracionesNextPage)
    }
    const loadMoreDeclaraciones = (page: number)=> {
        if(hasMore){
            const nextPage= page+1
            setPage(nextPage)
            loadPageDeclaraciones(undefined, nextPage)
        }
    }
    const renderContenido = () : JSX.Element[] => {
        const out = declaraciones.map(d => (
            <DustiItemList key={d.id}
                handleAction={onAction} 
                dusti={d} 
            />
        ))
        return out
    }
    // End Infinite scroll -----------------------------------------------------------

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch])

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiFileAccount,
                title: <Term component="DUTI" text="DUSTIConsulta" />,
                text:  <Term component="DUTI" text="DUSTIConsultaDescripcion" />,
                moreInfoTemplate: 'mas_info_duti_consulta',
                moreInfoTemplateSize: 'lg',
                moreInfoTemplateComponentIG: 'duti-ayuda',
                right: (
                    <>
                        <MenuItem button onClick={() => handleShowMasInfo('mas_info_duti_consulta')}>
                            <Term component="Global" text="Mas informacion" />
                        </MenuItem>
                    </>
                )
            },
            menu: true,
            footer: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    useEffect(() => {
        (async() => {
            await init()
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    }, [])

    return(
        <div className={classes.rootContainer}>
            <FullLoading loading={loading} animation={{type:'intricom',name:'DUSTI'}}/>
            <NoContent
                image={imageNoHayDeclaraciones}
                visible={declaracionesCount === 0 }
                component='DUTI'
                text={'tituloCardConsultaDUSTI_NoContent'}
            />

            {declaracionesCount > 0 && 
                <div style={{display:'flex', flexDirection: 'column', flexGrow:1}}>
                    <Card className={classes.cardContadorContainer}>   
                        <CardContent className={classes.cardContador} >
                            <span className={classes.titleCardContador}>
                                <Term component="DUTI" text={'tituloCardConsultaDUSTI' } params={[declaracionesCount]} />
                            </span>
                        </CardContent>
                    </Card>
                    
                    <div>
                        <InfiniteScroll 
                            pageStart={0}
                            initialLoad={false}
                            loadMore={loadMoreDeclaraciones}
                            hasMore={hasMore}
                            loader={ <InfiniteScrollLoader show={hasMore}/> }                     
                        >
                            {renderContenido()}
                        </InfiniteScroll>
                    </div>
                </div>
            }
            
        </div>
    )
}




export default withRouter(withStyles(duti_styles)(DUTIConsulta));