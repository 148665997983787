import { withLiterals } from 'containers/shared/literals';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { Route } from 'react-router-dom';
import { DetailFAQS } from './detail-faqs';
import { FAQS } from './faqs';


const FAQSIndex: React.FC = () => {
    const { path } = useRouteMatch();

    console.log(path)
    return (
        <>
            <Route path={`${path}/`} exact component={FAQS} />
            <Route exact path={`${path}/:idCategoria`} component={DetailFAQS} />
        </>
    );
}

export default withLiterals(['Global','FAQS'])(FAQSIndex);