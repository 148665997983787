import { FC, useCallback, useContext, useEffect, useState } from "react";
import Term from "components/term";
import CustomSelect from '../../components/custom-select';
import { FormControl, InputLabel, makeStyles, TextField } from "@material-ui/core";
import { FilterDataContext } from "./filter.data.context";
import { FiltersContext } from "../cartero-virtual/filters.context";
import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    formControl: {
        margin: `30px 0`,
        [theme.breakpoints.up('md')]: {
            margin: `10px 5px`,
        }
    },
    group: {
        margin: `10px 0`,
    },
    block: {
        display: `inline-block`
    },
    hidden: {
        display: `none`
    },
    middle: {
        verticalAlign: `middle`
    },
}));

const FiltersForm: FC<{tipoEnvio: 'N'|'C'}> = (tipoEnvio) => {

    const [filters, filtersDispatch] = useContext(FiltersContext);
    const filterData = useContext(FilterDataContext);
    const [refExpedienteFilter, setRefExpedienteFilter] = useState<string>();
    const handleEstadoChange = (estado: number[]) => filtersDispatch({ type: 'update', filter: 'estados', value: estado });
    const handleTipoChange = (tipo: number[]) => filtersDispatch({ type: 'update', filter: 'tipos', value: tipo });
    const handleMesChange = (mes: number[]) => filtersDispatch({ type: 'update', filter: 'meses', value: mes });
    const handleReferenciaChange = () => filtersDispatch({ type: 'update', filter: 'refExpediente', value: refExpedienteFilter });
    const handleRefExpedienteChange = (refExpediente: string) => {
        setRefExpedienteFilter(refExpediente);
    }
    
    useEffect(() => {
        const debouncedFilter = setTimeout(() => {
            handleReferenciaChange();
        }, 500);
        return () => clearTimeout(debouncedFilter);
    }, [refExpedienteFilter]);
    
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FormControl key='estado' className={filterData && filterData.estados ? classes.formControl : classes.hidden} fullWidth>
                <InputLabel key='label-estado' htmlFor="select-multiple-chip">
                    <Term component="Notificaciones" text="Estado" />
                </InputLabel>
                <CustomSelect
                    key='estados'
                    className={filterData && filterData.estados ? '' : classes.hidden}
                    options={filterData && filterData.estados ? filterData.estados : []} value={filters.estados || []}
                    onChange={(values) => handleEstadoChange(values)}
                />
            </FormControl>
            <FormControl key='tipo' className={filterData && filterData.tipos ? classes.formControl : classes.hidden} fullWidth>
                <InputLabel key='label-estado' htmlFor="select-multiple-chip">
                    <Term component="Notificaciones" text="Tipo" />
                </InputLabel>
                <CustomSelect
                    key='tipos'
                    className={filterData && filterData.tipos ? '' : classes.hidden}
                    options={filterData && filterData.tipos ? filterData.tipos : []} value={filters.tipos || []}
                    onChange={(values) => handleTipoChange(values)}
                />
            </FormControl>
            <FormControl key='mes' className={filterData && filterData.meses ? classes.formControl : classes.hidden} fullWidth>
                <InputLabel key='label-estado' htmlFor="select-multiple-chip">
                    <Term component="Notificaciones" text="Ultimos meses" />
                </InputLabel>
                <CustomSelect
                    key='meses'
                    className={filterData && filterData.tipos ? '' : classes.hidden}
                    options={filterData && filterData.meses ? filterData.meses : []} value={filters.meses || []}
                    onChange={(values) => handleMesChange(values)}
                />
            </FormControl>
            <FormControl key="objTributario" className={classes.formControl} fullWidth>
                <TextField
                    key="objTributario"
                    value={refExpedienteFilter || ""}
                    label={<Term component="Notificaciones" text="RefExpediente" />}
                    onChange={(evt) => handleRefExpedienteChange(evt.target.value)}
                />
            </FormControl>
        </div>
    );
}

export default FiltersForm;