import React, { FC } from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import ProtectedRoute from "components/protected-route";
import Notificaciones from "./notificaciones";
import DiasCortesia from "./dias.cortesia";
import ActivarServicio from "./activar.servicio";
import { withLiterals } from 'containers/shared/literals';
import AltaServicio from './alta.servicio';
import NotificacionesHome from './notificaciones-home';
import Contenido from 'components/contenido';

const NotificacionIndex: FC<RouteComponentProps> = ({ match }) => {
    return (
        <>
            <Route exact path={`${match.path}`} component={NotificacionesHome}  />    
            <ProtectedRoute exact path={`${match.path}/notificaciones/mis-notificaciones`} component={Notificaciones} accessLevel={2} idModulos={[6]} />
            <ProtectedRoute exact path={`${match.path}/notificaciones/dias-cortesia`} component={DiasCortesia} accessLevel={2} idModulos={[7]} />
            <ProtectedRoute exact path={`${match.path}/notificaciones/activar-servicio`} component={ActivarServicio} accessLevel={2} idModulos={[6]} />
            <Route exact path={`${match.path}/notificaciones/alta-servicio`} render={(props) => <Contenido landingName='alta_servicio_notificaciones' {...props}/>} />
            <Route exact path={`${match.path}/contenidos`} render={(props) => <Contenido landingName='mas_info_notificaciones_electronicas' {...props}/>} />
        </>
    );
}

export default withLiterals(['Global','Notificaciones','Perfil'])(withRouter(NotificacionIndex));