import React, { useState, useContext, ChangeEvent, useMemo } from 'react';
import { FormControlLabel, FormControl, RadioGroup, Radio, Button, IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Term from 'components/term';
import { ICajaProps } from './caja.props';
import usePage from 'hooks/page.hook';
import { translate } from 'utils/i18n';
import IoC from 'contexts/ioc.context';
import { TramitesGateway } from 'gateways/tramites.gateway';
import { LiteralsContext } from 'containers/shared/literals';
import { mdiEyeCircleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { DocumentsUtils } from 'utils/documents';
import { AlertsContext } from 'contexts/alerts.context';

const useStyles = makeStyles({
    select: {
        width: 230,
    },
    buttonList: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    fullWidth: {
        width: '100%'
    },
    button: {
        width: 200
    },
    form: {
        display: 'flex',
        marginTop: 15,
        marginLeft: 4,
    },
    inline: {
        display: 'inline'
    }
});



const DocumentoBase: React.FC<ICajaProps> = ({ onChange, detalle, tipo }) => {
    const classes = useStyles();
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const ioc = useContext(IoC);
    const gateway = ioc.get(TramitesGateway) as TramitesGateway;
    const listado = useMemo(() => tipo.componentes.find(x => x.tipo.nombre === 'doc_base'), [tipo]);
    const [value, setValue] = useState(!!detalle.documentoBase ? detalle.documentoBase.tipoDocumento : !!listado ? listado.documentos[0].nombre : null);
    const [, setSubiendoFichero] = useState(false);
    const terms = useContext(LiteralsContext);
    const [descripcion, setDescripcion] = useState<string|undefined>(undefined);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const values = event.target.value as any;
        setValue(values);
        if (!!detalle.documentoBase && !!detalle.documentoBase.idDocumento) {
            (async () => {
                try {
                    const response = await gateway.asociarDocumentoBase(detalle.documentoBase!.idDocumento, detalle.idExpediente, values, descripcion);
                    detalle.documentoBase = response;
                    pageDispatcher({
                        type: 'show-notification',
                        payload: {
                            message: translate('Tramites', 'Se ha actualizado correctamente el tipo de  documento', terms),
                            variant: 'success',
                        }
                    });
                    onChange(detalle);
                } catch (error) {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Tramites', (error as any).message, terms),
                            variant: 'error',
                        }
                    });
                }
            })();
        }
    };

    const handleVerDocumento = async (idDocumento: string) => {
        if (idDocumento !== null) {
            try {
                DocumentsUtils.downloadDoc(idDocumento);
            } catch (error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tramites', (error as any).message, terms),
                        variant: 'error',
                    }
                });
            }
        }
    }

    const handleAddDocument = async (event: ChangeEvent<HTMLInputElement>) => {
        setSubiendoFichero(true);
        try {
            if (!event.target.files) {
                return;
            }

            if (event.target.files.length === 0) {
                return;
            } else {
                for (let i = 0; i < event.target.files.length; i++) {
                    if (event.target.files[i].size > 50000000) {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('global', 'ficheroGrande', terms),
                                variant: 'warning',
                            }
                        });
                        return;
                    }
                }
            }

            const ids = await gateway.subirDocumentos(event.target.files)
            const response = await gateway.asociarDocumentoBase(ids[0], detalle.idExpediente, value, descripcion);
            detalle.documentoBase = response;
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: translate('Tramites', 'Se han subido correctamente los documento', terms),
                    variant: 'success',
                }
            });
            onChange(detalle);
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Tramites', (error as any).message, terms),
                    variant: 'error',
                }
            });
        } finally {
            setSubiendoFichero(true);
        }

    }

    const handleDescripcionChange = (text: string) => {
        setDescripcion(text);
    }

    return (
        <>
            {
                !!detalle && !!tipo && !!listado
                    ?
                    <FormControl disabled={detalle.estado.id === 'ANU'} component="fieldset" className={classes.form}>
                        <RadioGroup aria-label="tramite" name="tramite" value={value} onChange={handleChange}>
                            {listado.documentos.map((item, index) => (
                                <FormControlLabel
                                    key={'tramite_doc_base' + index}
                                    value={item.nombre}
                                    control={<Radio disabled={detalle.estado.id == 'PRE'} />}
                                    label={<Term component="Tramites" text={item.nombre} />}
                                />
                            ))}
                        </RadioGroup>
                        {detalle.estado.id != 'PRE' &&  
                            <TextField
                                value={descripcion ?? ''}
                                id="descripcion"
                                label={<Term component="Tramites" text="descripcion" />}
                                style={{ margin: 8, width: '50%' }}
                                margin="normal"
                                inputProps={{ maxLength: 115 }}
                                onChange={(event) => {handleDescripcionChange(event.target.value)}}
                            />
                        }
                        {
                            !!detalle.documentoBase
                                ?
                                <div className={classes.inline}>
                                    <span>{detalle.documentoBase.nombre}</span>
                                    <IconButton aria-label="ver" onClick={() => handleVerDocumento(detalle.documentoBase.idDocumento)}>
                                        <Icon path={mdiEyeCircleOutline}
                                            size={1}
                                            color='blue'
                                        />
                                    </IconButton>
                                </div>
                                :
                                null
                        }
                        {detalle.estado.id != 'PRE' &&
                        <div className={classes.buttonList}>
                            <input
                                accept="image/*,.pdf"
                                style={{ display: 'none' }}
                                id={`file-field${tipo.id}`}
                                type="file"
                                onChange={(e) => handleAddDocument(e)}
                            />

                            <Button disabled={detalle.estado.id === 'ANU'} type="submit" variant="contained" size="small" className={classes.button} color="primary">
                                <label htmlFor={`file-field${tipo.id}`} className={classes.fullWidth}>
                                    <Term component="Tramites" text={!!!detalle.documentoBase ? 'Adjuntar' : 'Cambiar'} />
                                </label>
                            </Button>

                        </div>
                        }
                    </FormControl>
                    :
                    null
            }
        </>
    );
}

export default DocumentoBase;