import { FC, useEffect, useContext, useState, useCallback } from 'react';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import IoC from 'contexts/ioc.context';
import usePage from '../hooks/page.hook';


interface IProps {
    nombrePlantilla: string,
    parametros: any
}


const DivTemplate: FC<IProps> = ({ nombrePlantilla, parametros }) => {
    const ioc = useContext(IoC);
    const [state] = usePage();
    const [body, setBody] = useState('');
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);

    const loadTemplate = useCallback(async () => {
        const bodyAux = await contenidoGateway.getContent(
            nombrePlantilla,
            parametros
        );
        if (!!bodyAux[0]) { setBody(bodyAux[0].contenido) }
    }, [contenidoGateway, nombrePlantilla, parametros]);

    useEffect(() => {
        loadTemplate();
    }, [loadTemplate, state.lang]);

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: body }} />
        </>
    )
};

export default DivTemplate;