import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";

const styles = (theme: Theme): StyleRules => ({
    root: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
            width: 300,
        }
    },
    centerContent: {
        textAlign: 'center',
        paddingTop: 250,
        width: '100%',
    },
    cardContador: {
        marginBottom: '0.5rem',
        backgroundColor: 'rgba(255,255,255,0.6)',
    },
    cardContadorContainer: {
        padding: '13px !important',
        display: 'flex',
        alignItems: 'center',
    },
    titleCardContador: {
        fontWeight: 300,
        fontSize: 16,
        marginLeft: '1rem',
        color: '#406a8d',
        flex: 1
    },
    imgContainer : {
        width: '100%',
        display: 'flex', 
        justifyContent: 'flex-start',
    },
    
    linkContentRow: {
        display: 'flex', 
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
    },

    contentRow: {
        display: 'flex', 
        flexDirection: 'column',
    },
    bottomButtonRow:{
        display: 'flex',
        justifyContent: 'end'
    },
    [theme.breakpoints.up('sm')]: {
        contentRow: {
            display: 'flex', 
            flexDirection: 'row',
            flexWrap: 'wrap',
        }
    },
    [theme.breakpoints.up('md')]: {
        contentRow: {
            display: 'flex', 
            flexWrap: 'wrap',
            flexDirection: 'column'
        }
    },
    [theme.breakpoints.up('lg')]: {
        contentRow: {
            display: 'flex', 
            flexWrap: 'wrap',
            flexDirection: 'row',
        }
    },

    // Bienes sujeto
    inline: {
        display: 'flex-inline'
    },
    card: {
        marginBottom: 10
    }
});

export default styles;