import { StyleRules, Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles( (theme: Theme): StyleRules =>({
    // STEPPER

    boldText: {
        color: 'black',
        fontWeight: 'bold'
    },
    completedText: {
        color: 'rgba(45,178,190,1)', 
    },
    lightText: {
        fontWeight: 'lighter',
        color: 'grey',
    },
    textSmall:{
        fontSize: 12
    },
    stepperContainer: {
        display:'flex',
        flexDirection: 'column',
        justifyContent:'center',
        flexGrow: 1,
        marginLeft: 10,
        marginRight: 10,
        borderRadius: 10,
        zIndex: 1,
        // paddingTop: 135, // HeaderStepper
        // paddingBottom:80, // Footer
        position: 'relative',
        boxShadow: '0 1px 1px 0 rgba(0,0,0,.5)',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            // paddingTop: 120,
            // paddingBottom: 10,
            paddingLeft:10,
            paddingRight:10,
            marginRight: 10,
        },           
    },
    row: {
        display:'flex', 
        flexDirection: 'row'
    },
    column: {
        display:'flex', 
        flexDirection: 'column'
    },
    borderBottom: {
        borderBottom: '1px solid #d3d3d3', 
    },
    stepperHeaderFooter: {
        position: 'absolute',
        left:0,
        zIndex: 3,
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 10,
        paddingLeft: 10,
        marginLeft:10,
        marginRight:10,
        paddingTop:0,
        flexGrow: 1,
        minHeight: 120, // -----
        [theme.breakpoints.down('sm')]: {
            minHeight: 120,
            flexDirection: 'row',
            marginRight: 10,
        },
    },
    stepperFooter: {
        position: 'absolute',
        left:0,
        zIndex: 3,
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 20,
        paddingLeft: 20,
        marginLeft:10,
        paddingTop:0,
        flexGrow: 1,
        maxHeight: 75, // -----
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxHeight: 75,
            flexDirection: 'row',
            marginRight: 20,
        },
    },
    containerImgFooter:{
        display: 'flex', alignItems: 'center', 
        justifyContent: 'center',flexGrow: 1, 
        maxHeight: 80, 
        width: '100%', paddingRight:10, paddingLeft:10
    },
    cardContent: {
        flexGrow: 1, 
        maxHeight: 'calc(100% - 69px)', // 70px Title card
        //height:'100%',
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
          }
    }
}));

export const useColorlibStepIconStyles = makeStyles({
    root: {
        zIndex: 1,
        color: '#fff',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'lightgrey',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        fontWeight: 'bold',
        fontSize: '18px'
    },
    active: {
        backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage: 'linear-gradient(to right, rgba(45,178,190,1),rgba(168,220,249,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }
});


export const useColorlibConnectorStyles = makeStyles({
    line: {
        height: 3,
        border: 0,
        width: 2,
        backgroundColor: '#eaeaf0', 
        marginLeft: 12.5
    },
    lineSmall: {
        minHeight: 8,
        border: 0,
        width: 2,
        backgroundColor: '#eaeaf0', 
        marginLeft: 8
    },
})


export const useCommonStyles = makeStyles({
    container: {
        height: '100%',
    },
    root: {
        flexGrow: 1,
        overflowY:'auto',
        padding: 16,
        maxHeight: 'calc(100% - 70px)', // 69px buttons Footer
    },
    buttons: {
        height: 69
    },
    // End Process MSG
    containerMSG: {
        border:'solid',
        borderColor: '#ef1111',
        borderRadius: 10, 
        borderWidth: 2, 
        marginTop: 20,
        marginBottom:20,
        display:'inline-flex', 
        alignItems: 'center',
        width: '100%'
    },
    textCancel: {
        fontWeight: 450,
        paddingTop: 30,
        paddingLeft: 10,
        paddingBottom: 30,
        paddingRight: 30,
        
    },
});