import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Grid,  makeStyles, useTheme } from '@material-ui/core';
import { IParamsPagoDusti } from '../../pasarela-pago/params.interfaces';
import usePage, { getAccessToken } from 'hooks/page.hook';
import styles from '../../pasarela-pago/pago.styles';
import { IModelGateway } from 'gateways/modelGateway.interface';
import { IPaymentMethod } from 'gateways/payment.method.interfaces';
import { AlertsContext } from 'contexts/alerts.context';
import IoC from 'contexts/ioc.context';
import { translate } from 'utils/i18n';
import { PaymentGateway } from 'gateways/payment.gateway';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import MetodosPago from 'components/metodos.pago';
import EntidadBancaria from 'components/entidad.bancaria';
import ModeloPagoDusti from 'components/DUTI/modelo-pago-dusti';
import FullLoading from 'components/full-loading';
import DUTIGateway from '../../../gateways/duti.gateway';
import { AuthGateway } from 'gateways/auth.gateway';
import { CustomError } from 'utils/custom.error';

const useStyles = makeStyles(styles);

const PagoModeloDusti: FC<IParamsPagoDusti> = ({ modelosDeclaracion, modelo, pagoConfirmado, showNext, idDeclaracion, idTipoModelo, handleNext, setShouldBlock }) => {
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
    const [creditCardMethod, setCreditCardMethod] = useState<IPaymentMethod>();
    const [bizumMethod, setBizumMethod] = useState<IPaymentMethod>();
    const [permitirPagoBanca,] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showEntidadesBancarias, setShowEntidadesBancarias] = useState(false);    
    const [modelosPago, setModelosPago] = useState<IModelGateway[]>([]);
    const [showModeloCaducado, setShowModeloCaducado] = useState(false);
    
    // Global states
    const theme = useTheme();
    const classes = useStyles(theme);
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);

    // Services 
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const paymentGateway = useMemo(() => ioc.get(PaymentGateway), [ioc]);
    const authGateway = useMemo(() => ioc.get(AuthGateway) as AuthGateway, [ioc]);

    // Entidades bancarias
    const banks = paymentMethods.filter((p: { tipoPago: number; }) => p.tipoPago === 9);
    const LIMITE_BIZUM = 99999;
    const [disabledBizum, setDisabledBizum]= useState(false);

    // Effects
    useEffect(() => {
        const fetchPaymentMethodsData = async () => {
            const result = await paymentGateway.getEntidades();
            const creditCard = result.find(p => p.tipoPago === 8);
            const bizum = result.find( p => p.tipoPago === 11);
            setCreditCardMethod(creditCard);
            setBizumMethod(bizum);
            setPaymentMethods(result);
        };
        fetchPaymentMethodsData();
    }, [pageDispatcher]);

    useEffect(() => {
        setModelosPago(modelosDeclaracion.map(m => {
            return m.modelos;
        }).flat());
        setShowModeloCaducado(modelosDeclaracion.some(m => m.caducado));
        // Gestión bizum deshabilitado
        const totalPagar = modelosDeclaracion.reduce((acumulador, m) => acumulador + m.importe, 0);
        setDisabledBizum(totalPagar > LIMITE_BIZUM)
    }, [modelosDeclaracion]);
    
    // Events
    const handlePayCreditCard = async () => {        
        const creditCard = paymentMethods.find(p => p.id === 'TJ');
        if (creditCard && modelosDeclaracion.length > 0) {
            setLoading(true);
            await refreshToken();
            const result = await paymentGateway.pagarTributosDusti(idDeclaracion, creditCard.id, idTipoModelo, modelosPago);
            if (result.ok) {
                window.location.href = result.entidad;
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('PasarelaPago', 'No ha sido posible realizar el pago con tarjeta de crédito', terms),
                        variant: 'warning',
                    }
                });
                setLoading(false);
            }
        }        
    };

    const handlePayBank = async () => {
        setShowEntidadesBancarias(true);
    };

    const handleBack = async () => {
        setShowEntidadesBancarias(false);
    };

    const handlePayBizum = async (modelosAPagar: IModelGateway[]) => {        
        const bizum = paymentMethods.find(p => p.id === 'BZ');
        if (bizum) {
            setLoading(true);
            await refreshToken();
            const result = await paymentGateway.pagarTributosDusti(idDeclaracion, bizum.id, idTipoModelo, modelosAPagar);
            if (result.ok) {
                window.location.href = result.entidad;
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('PasarelaPago', 'No ha sido posible realizar el pago con tarjeta de crédito', terms),
                        variant: 'warning',
                    }
                });
                setLoading(false);
            }
        }        
    };

    const handlePay = async (paymentMethodSelected: string) => {        
        if (modelosDeclaracion.length > 0) {
            setLoading(true);
            await refreshToken();
            const result = await paymentGateway.pagarTributosDusti(idDeclaracion, paymentMethodSelected, idTipoModelo, modelosPago);
            if (result.ok) {
                window.location.href = result.entidad;
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('PasarelaPago', 'No es posible realizar el pago a través de banca electrónica', terms),
                        variant: 'warning',
                    }
                });
                setLoading(false);
            }
        } else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('PasarelaPago', 'No es posible realizar el pago a través de banca electrónica', terms),
                    variant: 'warning',
                }
            });
        }        
    };

    const onHandleNext = useCallback(async () => {     
        if (!modelosDeclaracion.some(m => m.importe > 0)) {
            try {
                await gateway.finalizarModelosDusti(idDeclaracion, modelosDeclaracion.map(m => m.idModelo));
            } catch (error) {
                if (error instanceof CustomError && error.code === 403) {
                    setShouldBlock(false);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Global', 'sesion_caducada', terms),
                            variant: 'warning',
                            hasCustomAction: true,
                            handleCustomAction: () => { 
                                pageDispatcher({ type: "logout" });
                            }
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('DUTI','error_dusti', terms),
                            variant: "error"
                        }
                    });
                }
            }
        }
        handleNext();
    }, [gateway, handleNext, idDeclaracion, modelosDeclaracion]);

    const refreshToken = async () => {
        const accessToken = getAccessToken();
        if (accessToken !== null) {
            const result = await authGateway.refreshToken(accessToken);
            if (result && result.accessToken !== '') {
                pageDispatcher({ type: 'authenticate', payload: result });
            }
        }
    };

    return (
        <div style={{display:'flex', flexGrow: 1}} >
            <FullLoading loading={ loading } animation={{type:'intricom',name:'DUSTI'}}/>             
            <Grid item container direction='column' justify='space-between' className={classes.columnContainer}>
                { !pagoConfirmado && modelosDeclaracion.some(m => m.importe > 0) &&
                <Grid item>
                    { !modelo && showEntidadesBancarias ? (
                            <>
                                <EntidadBancaria
                                    classes={classes}
                                    banks={banks}
                                    handlePay={handlePay}
                                    modelos={modelosPago}
                                    showDeuda={false}
                                    showTotalPagar={false}
                                    handleBack={handleBack}
                                />
                            </>
                        ) : (
                            <MetodosPago 
                                classes={classes} 
                                creditCardMethod={creditCardMethod}
                                bizumMethod={bizumMethod ? {...bizumMethod, activa: !disabledBizum}: undefined}
                                modelos={modelosPago}
                                showDeuda={false}
                                showModeloCaducado={showModeloCaducado}
                                permitirDocIngresoG={false}
                                permitirPagoBanca={permitirPagoBanca}
                                permitirDocIngresoI={false}
                                loading={loading}
                                showTotalPagar={false}
                                handlePayCreditCard={handlePayCreditCard}
                                handlePayBank={handlePayBank}
                                handlePayBizum={handlePayBizum}            
                            />
                        )
                    }
                </Grid>
                }
                <Grid item >
                    { modelosDeclaracion.length > 0 &&
                        <ModeloPagoDusti 
                            classes={classes}
                            pagoConfirmado={pagoConfirmado}
                            showNext={showNext}
                            showValidar={!modelosDeclaracion.some(m => m.importe > 0)}
                            showPanelConfirmacion={modelo !== null}
                            modelosDeclaracion={modelosDeclaracion} 
                            handleNext={onHandleNext}
                        />
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default withLiterals(["Global","PasarelaPago", "DUTI"])(PagoModeloDusti);
