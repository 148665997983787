import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IFilterDataProcedimientos, IGetProcedimiento, IGetProcedimientosRQ, IProcedimientoResponse } from "./procedimientos.interfaces";
import { createValidationError } from "../utils/response";
import { IProcedimiento } from "./rolsac/procedimiento";

@inject('axios')
export class ProcedimientoGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async obtenerProcedimientos(request: IGetProcedimientosRQ): Promise<IProcedimientoResponse> {
        try {
            const response = await this.axios.get(`procedimientos/obtenerProcedimientos`, {
                params: { ...request as IGetProcedimientosRQ }
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async obtenerProcedimiento(request: IGetProcedimiento): Promise<IProcedimiento> {
        try {
            const response = await this.axios.get(`procedimientos/obtenerProcedimiento`, {
                params: { ...request as IGetProcedimiento }
            });
            return response.data
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async obtenerFamilias(): Promise<IFilterDataProcedimientos> {
        try {
            const response = await this.axios.get(`procedimientos/obtenerFamilias`);
            if (response.status === 200) {
                return {
                    familia: response.data.map((x: any) => ({ value: x.codigo, label: x.nombre })),
                };
            }

            return { familia: [] };
        } catch (e) {
            throw createValidationError(e as any);
        }
    }
}
