import React, {FC, useContext} from "react";

import { RadioGroup, FormControlLabel, Radio, FormControl, withStyles, WithStyles, FormHelperText } from "@material-ui/core";
import { translate } from "utils/i18n";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import styles from "./styles";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import GenericLabelWithInfo, { TLabelType } from "components/generic-label-with-info";



export type IGenericBoolRadioButtonOption = { 
    id: boolean, 
    term: string, // TERM de IG a pintar - label option
    componentIG?:string, // Componente de IG en el que se busca el TERM y labelOptions si la option no tiene componentIG
    label?: string // texto adicional a concatenar tras el term
} 
export const BOOL_OPTIONS: IGenericBoolRadioButtonOption[] = [
    {id: true, term: 'si', componentIG:'Global'},
    {id: false, term: 'no', componentIG: 'Global'}
]

interface IGenericBooleanRadioButton extends WithStyles {
    labelTerm: string, // Etiqueta del radio group
    componentIG: string // Componente de IG en el que se busca mensage Label,     
    
    options: IGenericBoolRadioButtonOption[],
    value: boolean | undefined,
    onChangeValue: (v:boolean) => void,
    moreInfo?: {
        template: string, size?: TInfoTemplateSize
    },
    labelInRow?:boolean // Permite poner o no añadir en la ROW el label
    optionsInRow?:boolean // Opciones del radiobutton en ROW
    required?:boolean
    disabled?: boolean;
    error?:boolean,
    errorMsg?:string,
    withLabel?: boolean
    typeLabel?: TLabelType 
};


const GenericBooleanRadioButton : FC<IGenericBooleanRadioButton>= ({    
    classes,  labelTerm, componentIG,
    value, options,onChangeValue, 
    moreInfo, labelInRow= false, optionsInRow=false, error, errorMsg, 
    withLabel=true, typeLabel='field', disabled, required
     }) => {
    const terms = useContext(LiteralsContext);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeValue(event.target.value === 'true'? true : false);
    }
    
    return (
        <FormControl component="fieldset" 
            style={{display:'flex', flexDirection: labelInRow ?'row': 'column', flexWrap:'wrap', alignItems: labelInRow ? 'center':'flex-start' , marginBottom:10}}
        >
            { withLabel && 
                <GenericLabelWithInfo
                    type={typeLabel} 
                    labelTerm={labelTerm} 
                    componentIG={componentIG} 
                    extraInLabel={`${required ?'*': ''} ${labelInRow ? ':': ''}`}
                    moreInfo={moreInfo} 
                    required={required}
                    style={{marginRight: labelInRow? 15: undefined}} 
                    />
            }
            <RadioGroup 
                value={ value !== undefined ? value.toString() : ''} 
                row={optionsInRow} 
                onChange={handleChange}
            >
            {options.map( o => (
                <FormControlLabel style={{marginRight: 20}}
                    key={o.id.toString()} 
                    value={o.id.toString()} 
                    control={<Radio color="primary" />} 
                    label={translate( o.componentIG ?? componentIG, o.term, terms).concat(o.label ?? '')} 
                    disabled={disabled}
                />
            ))}
            </RadioGroup>
            {error && errorMsg && <FormHelperText error style={{width: '100%', marginTop: -5}}>{translate( componentIG, errorMsg, terms)}</FormHelperText>
            }
        </FormControl>
    )
}


export default withLiterals(ALL_LITERALS)(withStyles(styles)(GenericBooleanRadioButton));