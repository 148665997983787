import { Card, Input, makeStyles } from '@material-ui/core';
import { Icon } from 'atomic/atoms/icon/icon';
import { useDebounce } from 'atomic/hooks/debounce';
import Term from 'components/term';
import { LiteralsContext } from 'containers/shared/literals';

import React, { useCallback, useContext, useState } from 'react';
import { translate } from 'utils/i18n';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection:'column',
        marginLeft: 20,
        marginRight: 20,
        padding: 20,
        //backgroundColor: 'rgba(255,255,255,0.4)',
        alignItems: 'flex-start',
    },
    flex: {
        flex: 1,
        width: '100%',
    }
}));

interface IProps {
    onSearch: (texto: string) => void;
    onInputChange: (newValue: string) => void;
    withCategory: boolean;
    inputValue: string;
    labelStyle?: React.CSSProperties;
}

export const SearchFAQS: React.FC<IProps> = ({ onSearch, onInputChange, withCategory, labelStyle, inputValue }) => {
    const classes = useStyles();
    const terms = useContext(LiteralsContext);
    
    const handleSearchDebounced = useDebounce((newValue: string) => onSearch(newValue), 500);

    const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        onInputChange(newValue);
        handleSearchDebounced(newValue);
    }, [onSearch]);

    return (
        withCategory ? 
            <Input 
                endAdornment={<Icon name="search" size={1} color="#004f84" />}
                placeholder={withCategory ? translate("FAQS", "BuscarFAQsWithCategory", terms ) : translate("Global", "Buscar", terms )} 
                className={classes.flex} 
                onChange={handleSearch}
                value={inputValue}
             />
        
        :
            <Card className={classes.container}>
                <div style={labelStyle}>
                    <Term component="FAQS" text="BuscarFAQs"/>
                </div>
                <Input 
                    endAdornment={<Icon name="search" size={1} color="#004f84" />}
                    placeholder={translate("Global", "Buscar", terms )} 
                    className={classes.flex} 
                    onChange={handleSearch}
                    value={inputValue}
                />

            </Card>
            
    )
}