import { CircularProgress, withStyles, WithStyles } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styles from './cita-previa.styles';
import { RouteComponentProps, withRouter } from 'react-router';
import Term from 'components/term';
import { useCallback } from 'react';
import usePage from 'hooks/page.hook';
import IoC from 'contexts/ioc.context';
import CitaPreviaGateway from 'gateways/citaPrevia.gateway';
import { IArgumentosCancelar, ISuccessCancelAppointment } from 'gateways/citaPrevia.interface';
import CitaPreviaForm from 'components/cita-previa-form';
import { AlertsContext } from 'contexts/alerts.context';
import { mdiCalendarClock } from '@mdi/js';

type Props = WithStyles<typeof styles> & RouteComponentProps;

const CancelarCita: React.FC<Props> = ({ classes }) => {
    const ioc = useContext(IoC);
    const citaPreviaGateway: CitaPreviaGateway = useMemo(() => ioc.get(CitaPreviaGateway), [ioc]);
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const [loading, setLoading] = useState(false)
    const handleToggleSubMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpenSubMenuAnchorEl(event.currentTarget);
    }, []);
    

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiCalendarClock,
                title: <Term component="CitaPrevia" text="Titulo Cita Previa" />,
                text: <Term component="CitaPrevia" text="Subtitulo cita previa" />
            },
            menu: true,
        });
    }, [pageDispatcher, handleToggleSubMenu]);


    const handleMensajeCancelar = useCallback((error: boolean, errorDoc: boolean) => {
        if (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component="CitaPrevia" text="Ha ocurrido un error"/>,
                    variant: 'error',
                }
            })
        }
        else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component="CitaPrevia" text={"Cancelar exito"} />,
                    variant: 'success',
                }
            })
        }

    }, [ alertsDispatch])

    const handleCancelarCita = useCallback(async (codigo: string, fecha: string, oficina: string, doc: string) => {
        setLoading(true);
        try {
            let response: ISuccessCancelAppointment
            let cancelarForm: IArgumentosCancelar;
            cancelarForm = {
                codigoCita: "G-" + codigo,
                nif: doc,
                oficina: oficina,
                dia: fecha,
            }
            response = await citaPreviaGateway.cancelCita(cancelarForm)
            if (response.result) {
                handleMensajeCancelar(false, false);
                setLoading(false);
            }
            else {
                response.motivo !== "La documentación aportada no es válida"
                    ? handleMensajeCancelar(true, false)
                    :  handleMensajeCancelar(true, true)
               
                setLoading(false);
            }
        }
        catch (error) {
            handleMensajeCancelar(true, false);
            setLoading(false);
        }
    }, [citaPreviaGateway, handleMensajeCancelar])

    return (
        <>{loading ?
            <div className={classes.content}>
                <CircularProgress size={30} />
            </div>
            :
            <>
            <CitaPreviaForm handleConfirmar={handleCancelarCita} 
            titulo={<Term  component='CitaPrevia' text='Cancelar cita'/>}
            />
            </>
        }
        </>)
}
export default withRouter(withStyles(styles)(CancelarCita));