import moment, { Moment } from 'moment';
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/ca";

export function dateFormat(date: string | Date | Moment, format: string = 'DD/MM/YYYY'): string {
    return moment(date).format(format);
}

export function dateFormatToUTC(date: string | Date | Moment, format: string = 'DD/MM/YYYY'): string {
    return typeof date === 'string'
        ? moment.utc(new Date(date).toISOString()).format(format)
        : moment.utc(date).format(format);
}

export function dateTransformIntoRedeableFormat(date: string, lang: string | null): string {
    let language: string = 'es-ES';

    if (lang && lang === 'ca') {
        language = 'ca-ES';        
    }

    const formatterFecha = new Intl.DateTimeFormat(language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    return formatterFecha.format(new Date(date));
}

export function getMomentFromDate(date: string | Date | Moment, format: string = 'DD/MM/YYYY'): moment.Moment {
    return moment(date, format);
}

export function getTodayStart(): Date {
    return moment().startOf('day').toDate();
}

export function getTodayEnd(): Date {
    return moment().endOf('day').toDate();
}

export function dateParse(time: Date, lang: string): string {
    let currentDate = dayjs(time).locale(lang).format("dddd, DD MMMM YYYY HH:mm:ss");
    const words = currentDate.split(" ");
    if (words.length > 2) {
        words[2] = words[2].charAt(0).toUpperCase() + words[2].slice(1);
    }
    currentDate = words.join(" ");
    return currentDate.charAt(0).toUpperCase() + currentDate.slice(1);
}

export function dateFormatToUTCDate(date: Date | null): Date {
    if (date != null) {
        const timezoneOffsetMs = date.getTimezoneOffset() * 60000;
        // Create a new Date object in UTC by adjusting the original date
        const utcDate = new Date(date.getTime() - timezoneOffsetMs);
        utcDate.setUTCHours(0, 0, 0, 0);
        return utcDate;
    }
    return new Date();
}

export function convertirFechaAFormato(fecha: any) {
    return fecha.toISOString().split('.')[0] + "+01:00";
}

export function adjustForTimezone(date: Date) {
    var timezoneOffset = date.getTimezoneOffset();

    var offsetMillis = timezoneOffset * 60000;

    var adjustedDate = new Date(date.getTime() - offsetMillis);

    return adjustedDate;
}