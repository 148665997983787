import { Grid, makeStyles } from "@material-ui/core";
import Term from "components/term";
import moment from "moment";
import React, { useMemo } from "react";
import { FC } from "react";
import { ICajaProps } from "./caja.props";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    }
}))

export const LinkSolicitudAjutsCovid: FC<ICajaProps> = ({ detalle }) => {
    const { nif, nombre, idExpediente, fechaRegistro, detalles } = detalle.ajutsCovid;
    const classes = useStyles();

    const fechaRegistroFormateada = useMemo(() => moment.utc(fechaRegistro).format('DD/MM/YYYY HH:mm'), [fechaRegistro]);

    const email = useMemo(() => (detalles.find(x => x.codigo === '10') || { valor: '' }).valor, [detalles]);
    const movil = useMemo(() => (detalles.find(x => x.codigo === '11') || { valor: '' }).valor, [detalles]);

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item md={4}>
                    <Term component="Tramites" text="Nº Expediente" />: <b>{idExpediente}</b>
                </Grid>
                <Grid item md={4}>
                    <Term component="Tramites" text="Nombre" />: <b>{nombre}</b>
                </Grid>
                <Grid item md={4}>
                    D.N.I / N.I.F / N.I.E: <b>{nif}</b>
                </Grid>
                <Grid item md={4}>
                    Email: <b>{email}</b>
                </Grid>
                <Grid item md={4}>
                    <Term component="Tramites" text="Movil" />: <b>{movil}</b>
                </Grid>
                <Grid item md={4}>
                    <Term component="Tramites" text="Fecha solicitud" />: <b>{fechaRegistroFormateada}</b>
                </Grid>
            </Grid>
        </div>
    )
}