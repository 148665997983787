// LIBRARY IMPORTS
import { FC, useState, useContext } from "react";
import {
    Typography,
    Button,
    DialogTitle,
    DialogActions,
    useTheme,
    makeStyles,
    DialogContent,
    CircularProgress,
    Grid,
    // TextField,
} from "@material-ui/core";
// LOCAL IMPORTS
import { translate } from 'utils/i18n';
import { ITributo, ITributoRelacionado } from "gateways/tributos.interfaces";
import { LiteralsContext } from "containers/shared/literals";
import { IDatosDomiciliacion } from "gateways/datos.domiciliacion.interfaces";
import { TextField } from "components/text-field";
import { GenericModal } from "components/generic-modal";
import { PagoCartaTributoAdherible } from "../pago-carta-tributo-adherible";
import Term from "components/term";
// STYLES IMPORTS
import styles from './styles';
import ConceptoIcon from "components/concepto.icon";

const useStyles = makeStyles(styles);

export interface IProps {
    tributo: ITributo;
    datosDomi: IDatosDomiciliacion;
    tributosRelated: ITributoRelacionado[];
    open: boolean;

    onClose: () => void;
    continuar: (selec: ITributoRelacionado[]) => void;
}

const PagoCartaDetail: FC<IProps> = (props) => {
    const { tributo, datosDomi, tributosRelated, open, onClose, continuar } = props;
    // HOOKS
    const terms = useContext(LiteralsContext);
    const theme = useTheme();
    const classes = useStyles(theme);
    // STATES
    const [selected, setSelected] = useState<ITributoRelacionado[]>([]);
    const [loadingPrint, setLoadingPrint] = useState(false);

    const handleCloseModal = () => {
        setSelected([])
        onClose()
    };

    const handleCheck = (tributo: ITributoRelacionado) => {
        let tempSelected = [...selected];
        if (selected.includes(tributo)) {
            if (selected.length > 1) {
                tempSelected.splice(tempSelected.indexOf(tributo), 1);
                setSelected(tempSelected);
            }
            else {
                setSelected([])
            }
        }
        else {
            tempSelected.push(tributo);
            setSelected(tempSelected);
        }
    };

    return (
        <GenericModal open={open}>
            <DialogContent >
                <Grid container direction="column" spacing={1} style={{padding: 15}}>
                    <Grid item>
                        <TextField
                            id="ibanpc"
                            fullWidth
                            value={datosDomi.iban}
                            label="IBAN *"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item container direction="row" spacing={2} >
                        <Grid item xs={6}>
                            <TextField
                                id="nifpc"
                                fullWidth
                                value={datosDomi.nif}
                                label={`${translate('Domiciliaciones', 'NIF titular', terms)} *`}
                                disabled={true}
                            />

                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="nompc"
                                label={`${translate('Domiciliaciones', 'Nombre titular', terms)} *`}
                                fullWidth
                                value={datosDomi.nombre}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <div className={tributosRelated.length > 0 ? classes.borderBottom : undefined}>
                   
                    <Typography className={[classes.titleText, classes.margin15].join(' ')}>
                        <Term component={"Domiciliaciones"} text={"Tributo seleccionado pago carta"} />
                    </Typography>
                   <div className={[classes.flexRow, classes.margin15].join(' ')}>
                    <div style={{width:80, height:80, alignSelf:'center' }}>
                        <ConceptoIcon concepto={tributo.concepto.idTipo} />
                    </div>
                    <div className={[classes.flexColumn, classes.margin15].join(' ')}>
                        <Typography>{tributo.organismo.nombre}</Typography>
                        <Typography>{tributo.concepto.nombre}</Typography>
                        <Typography>{tributo.objeto}</Typography>
                        <Typography>{tributo.direccionTributaria}</Typography>
                    </div>
                    </div>
                </div>

                {tributosRelated.length > 0 &&
                    <>
                        
                        <Typography className={[classes.titleText, classes.margin15].join(' ')}>
                            <Term component={"Domiciliaciones"} text={"Otros recibos"} />
                        </Typography>
                        
                        <Typography
                            className={[
                                classes.flexRow,
                                classes.alignCenter,
                                classes.paddingLeftRight25
                            ].join(' ')}>
                            <Term component={"Domiciliaciones"} text={"Pago carta domiciliados conjunta"} />
                        </Typography>

                        <div className={[classes.overflowScroll, classes.maxHeight300].join(' ')}>
                            {tributosRelated.map((x) => {
                                return (
                                    <PagoCartaTributoAdherible
                                        tributoRelacionado={x}
                                        tributoRelacionadoSelected={selected}
                                        onCheck={handleCheck}
                                    />
                                );
                            })}
                        </div>
                    </>
                }
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleCloseModal}
                    color="default"
                    disabled={loadingPrint}
                >
                    <Term component="Global" text="Cerrar" />
                </Button>

                <Button variant="outlined" onClick={() => continuar(selected)} color="primary">
                    {<Term component="Tramites" text="Siguiente" />}
                </Button>
                {loadingPrint && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
            </DialogActions>
        </GenericModal>
    );
};

export { PagoCartaDetail };
