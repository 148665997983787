import React, {FC, useContext} from "react";

import { RadioGroup, FormControlLabel, Radio, FormControl, withStyles, WithStyles, FormHelperText, Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import duti_styles from "containers/DUTI/styles";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import styles from "./styles";
import GenericLabelWithInfo, { TLabelType } from "components/generic-label-with-info";




export type IGenericRadioButtonOption = { 
    id: string, 
    term: string, // TERM de IG a pintar - label option
    componentIG?:string, // Componente de IG en el que se busca el TERM
    label?: string // texto adicional a concatenar tras el term
    moreInfo?:{ template: string, size?: TInfoTemplateSize} // i para la opcion
    disabled?:boolean
} 

interface IGenericRadioButtonProps extends WithStyles {
    labelTerm: string, // Etiqueta del radio group
    componentIG: string // Componente de IG en el que se busca mensage Label y labelOptions si la option no tiene componentIG
    value: string | undefined
    options: IGenericRadioButtonOption[]
    onChangeValue: (v:string) => void,
    
    disabled?: boolean; // all disabled
    moreInfo?: { // Más info para Etiqueta del radio group
        template: string, size?: TInfoTemplateSize
    },
    labelInRow?:boolean // Permite poner o no añadir en la ROW el label
    optionsInRow?:boolean // Opciones del radiobutton en ROW
    required?:boolean
    error?:boolean,
    errorMsg?:string,
    withLabel?: boolean
    typeLabel?: TLabelType 
};


const GenericRadioButton : FC<IGenericRadioButtonProps>= ({
    classes,  labelTerm, componentIG, 
    value, options,onChangeValue, 
    moreInfo, labelInRow= false, optionsInRow=false, error, errorMsg, 
    withLabel=true,typeLabel='field', disabled, required,
    
}) => {

    const terms = useContext(LiteralsContext);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value:string) => {
        onChangeValue(value);
    };

    return (
        <FormControl component="fieldset" 
            style={{display:'flex', flexDirection: labelInRow ?'row': 'column', flexWrap:'wrap', alignItems: labelInRow ? 'center':'flex-start' , marginBottom:10}}
        >
            { withLabel && 
                <GenericLabelWithInfo
                    //key={'label'.concat(labelTerm)}
                    type={typeLabel} 
                    labelTerm={labelTerm} 
                    componentIG={componentIG} 
                    extraInLabel={`${required ?'*': ''} ${labelInRow ? ':': ''}`}
                    moreInfo={moreInfo} 
                    required={required}
                    style={{marginRight: labelInRow? 15: undefined}} 
                />
            }
            <RadioGroup 
                value={value ?? ''} 
                onChange={handleChange} row={optionsInRow} 
                style={{display: 'flex', flexDirection: optionsInRow ? 'row': undefined,
            }}
            >
            {options.map( o => (
                <div className={classes.row}>
                    <FormControlLabel style={{marginRight:0}}
                        key={o.id} 
                        value={o.id} 
                        control={<Radio color="primary" style={{alignSelf: 'flex-start'}} />} 
                        label={<Typography style={{paddingTop: 9, paddingBottom:9}}>{translate( o.componentIG ?? componentIG, o.term, terms).concat(o.label ?? '')}</Typography>} 
                        disabled={disabled || o.disabled}
                    />
                    {o.moreInfo && 
                        <GenericLabelWithInfo 
                        key={o.moreInfo.template} componentIG={""} type="field" labelTerm={""} 
                        moreInfo={o.disabled ? {...o.moreInfo, template: o.moreInfo.template+'_disabled'} : o.moreInfo} 
                        infoIconStyle={{padding: 9}} 
                        />
                    }
                </div>
                
            ))}
            </RadioGroup>
            {error && errorMsg && 
                <FormHelperText error style={{width: '100%', marginTop: -5}}>{translate( componentIG, errorMsg, terms)}</FormHelperText>
             }
        </FormControl>
    )
}

export default withLiterals(ALL_LITERALS)(withStyles(styles)(GenericRadioButton));