import {FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import { Dialog, DialogTitle, Typography, DialogContent, Grid, DialogActions, withStyles, WithStyles, Button } from "@material-ui/core";
import { translate } from "utils/i18n";
import duti_styles from "containers/DUTI/styles";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import FullLoading from "../full-loading";
import { IPlusvalVendedor } from "containers/DUTI/steps/types";
import TypedInputNumber from "./typed-input-number";
import LabelWithInfo from "./label-with-info";
import { CustomError } from "utils/custom.error";
import usePage from "hooks/page.hook";

interface IProps extends WithStyles {
    idDeclaracion: number,
    show: boolean,
    info: IPlusvalVendedor,
    infoVendedor: {titularidad: number, nif: string, nomApe:string},
    idGestion: number | null,
    idGestionSujeto: number | null,
    onConfirm: (v: IPlusvalVendedor) => void,
    onCancel: () => void,    
    setIdGestion: (id: number | null) => void,
    setIdGestionSujeto: (id: number | null, nifSujetoPasivo: string) => void,
    setShouldBlock: (v: boolean) => void,
}

const ModalPlusvalM081: FC<IProps> = ({
    classes, idDeclaracion,
    show, info, infoVendedor,
    idGestion, idGestionSujeto,
    onConfirm, onCancel, setShouldBlock,
    setIdGestion, setIdGestionSujeto,    
}) => {
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext)
    const [, pageDispatcher] = usePage();
    const componentMounted = useRef(true);
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);

    // States
    const [loading, setLoading] = useState(false);    

    const [ calculatedPlusval, setCalculatedPlusval] = useState<IPlusvalVendedor>({} as IPlusvalVendedor)
    const showImporteReal = useMemo(() => (info.operaciones[0].importeAnteriorAdquisicion && info.operaciones[0].importeAnteriorAdquisicion > 0), [info])

    useEffect(() => {
        (async() => {
            setLoading(true);

            try {
                const response = await dutiGateway.calcularModelo('081', idDeclaracion, idGestion, idGestionSujeto, info);
                if(response.ok === false){
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('DUTI', 'can_not_calculate_m081', terms),
                            variant: 'error',
                            hideButtonAction: true,
                            hasCustomAction: true,
                            handleCustomAction: () => onCancel() 
                        }
                    });
                }

                if(componentMounted.current){
                    const data = response.data[0];
                    setCalculatedPlusval(response.ok 
                        ? {...data
                            // eliminar - MOCK Metodo real
                            //, modelo081: {...response.data[0].modelo081, importeMetodoObjetivo: 50, importeMetodoReal:51, totalIngresar:51} as TDatosM081
                        } 
                        : {...info});
                    setIdGestion(data.idGestion);
                    setIdGestionSujeto(data.idGestionSujeto, data.nifSujetoPasivo);
                }
            } catch (error) {
                if (error instanceof CustomError && error.code === 403) {
                    setShouldBlock(false);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Global', 'sesion_caducada', terms),
                            variant: 'warning',
                            hasCustomAction: true,
                            handleCustomAction: () => { 
                                pageDispatcher({ type: "logout" });
                            }
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('DUTI','error_dusti', terms),
                            variant: "error"
                        }
                    });
                }
            } finally {
                setLoading(false);
            }
            
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    },[info])

    return (   
        <Dialog open={show} fullWidth maxWidth='lg'>
            <FullLoading loading={loading} msg={translate('DUTI','loading_calculate_m081',terms)} animation={{type:'lottie', name:'calcular'}}/> 

            <DialogTitle>
                <div className={classes.row} style= {{display:'flex', alignItems:'baseline', justifyContent: 'space-between'}}>
                    
                    <Typography style={{fontSize: 25, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>{translate('DUTI','metodo_calculo_m081',terms).toUpperCase()}</Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container 
                    direction='column'
                    style={{ 
                        width: '100%',
                        border:'solid',
                        borderWidth: '1px',
                        borderRadius: 10,
                        borderColor: '#006993',
                        
                    }}
                >
                    <Grid item container 
                        direction='row' 
                        alignItems="center" 
                        style={{backgroundColor:'#006993', color:'#fff',borderTopLeftRadius: 9,borderTopRightRadius:9}}
                    >
                        <Typography style={{marginLeft: 10, fontWeight:400}}>{infoVendedor.nif.toUpperCase()}</Typography>
                        <Typography style={{marginRight: 10, marginLeft: 10, fontWeight:400}}>{'-'}</Typography>
                        <Typography style={{fontWeight:400}}>{infoVendedor.nomApe.toUpperCase()}</Typography>
                        <Typography style={{marginRight: 10, marginLeft: 10, fontWeight:400}}>{'-'}</Typography>
                        <Typography style={{fontWeight:400}}>{infoVendedor.titularidad}</Typography>
                        <Typography style={{fontWeight:400}}>{translate('DUTI','porcentajeTitularidad',terms)}</Typography>
                    </Grid>
                    <Grid item container >
                        <Grid item xs={12} sm={12} md={6}lg={6} style={{padding: 10}}>
                            <LabelWithInfo type="field" fieldTerm={'plusval_calc_metodoObjetivo'} moreInfo={{template: 'info_field_plusval_calc_metodoObjetivo', size: 'xs'}}/>
                            <TypedInputNumber 
                                key='importeMetodoObjetivo'
                                type="money"
                                disabled
                                required={false}
                                allowZero={true}
                                fieldTerm="importeMetodoObjetivo"
                                value={ calculatedPlusval.modelo081?.importeMetodoObjetivo ?? 0}
                                onChangeValue={(v, err) => {}}
                            />
                        </Grid>
                       
                            <Grid item container direction='column' spacing={1} xs={12} sm={12} md={6}lg={6} alignItems="flex-start"
                                style={{
                                    border:'solid',
                                    borderTopWidth: '0px',
                                    borderBottomWidth: '0px',
                                    borderLeftWidth: '1px',
                                    borderRightWidth: '0px',
                                    borderColor: '#006993',
                                    padding: 10
                                }}
                            >
                                <Grid item container>
                                    <LabelWithInfo type="field" fieldTerm={'plusval_calc_metodoReal'} moreInfo={{template: 'info_field_plusval_calc_metodoReal', size: 'xs'}}/>
                                </Grid>
                                <Grid item>
                                    {showImporteReal ? 
                                        <TypedInputNumber 
                                            key='importeMetodoReal'
                                            type="money"
                                            disabled
                                            required={false}
                                            allowZero={true}
                                            fieldTerm="importeMetodoReal"
                                            value={ calculatedPlusval.modelo081?.importeMetodoReal ?? 0 }
                                            onChangeValue={(v, err) => {}}
                                        />
                                        : <Typography>{translate('DUTI','calc_metodoReal_no_aplica',terms)}</Typography>
                                    }
                                </Grid>

                            </Grid>
                        
                    </Grid>
                    <Grid item container direction='column' justify="center" alignItems="center" 
                        style={{ 
                            width: '100%',
                            border:'solid',
                            borderTopWidth: '1px',
                            borderBottomWidth: '0px',
                            borderLeftWidth: '0px',
                            borderRightWidth: '0px',
                            borderColor: '#006993',
                            padding: 10
                        }}
                    >
                        <LabelWithInfo type="field" fieldTerm={'plusval_calc_totalIngresar'} /*moreInfo={{template: 'info_field_plusval_calc_totalIngresar', size: 'xs'}}*/ />
                        <TypedInputNumber 
                            key='totalIngresar'
                            type="money"
                            disabled
                            required={false}
                            allowZero={true}
                            fieldTerm="totalIngresar"
                            value={ calculatedPlusval.modelo081?.totalIngresar ?? 0}
                            onChangeValue={(v, err) => {}}
                        />
                    </Grid>

                </Grid>
                
            </DialogContent>
            <DialogActions>
                <div className={classes.row}>
                    <Button variant='contained' onClick={onCancel} style={{marginRight: 20}} >{translate('DUTI','btn_cancel',terms)}</Button>
                    <Button 
                        color="primary" 
                        variant='contained' 
                        onClick={() => {
                            const cleanOp = info.operaciones.map(op => ({...op, idEscrituraAnterior: undefined}))
                            onConfirm({
                                ...calculatedPlusval, 
                                operaciones: cleanOp, 
                                modelo081: calculatedPlusval.modelo081? {...calculatedPlusval.modelo081, declaracionResponsable: false} : undefined
                            })
                        }} 
                    >{translate('DUTI','btn_confirmar',terms)}</Button>
                    
                </div>
            </DialogActions>
        
        </Dialog> 
    )   

}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(ModalPlusvalM081));