import { FC, Fragment } from "react";
import { Card, makeStyles, CardContent } from "@material-ui/core";
import styles from '../shared/tributos.styles';
import { ITributoAutonomico } from "gateways/tributos.interfaces";
import TributoGrid from "./tributo.grid";

const useStyles = makeStyles(styles);

interface IProps {
    tributo: ITributoAutonomico;
}

const TributoCard: FC<IProps> = ({ tributo }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Card className={[classes.mainButton, classes.recibo].join(' ')}>
                <CardContent>
                    <TributoGrid tributo={tributo} />
                </CardContent>
            </Card>
        </Fragment>
    );
};


export default TributoCard;