import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import usePage from 'hooks/page.hook';
import Term from 'components/term';
import { LiteralsContext } from 'containers/shared/literals';

const ProtectedPage = () => {
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const returnPath = document.location.pathname + document.location.search + document.location.hash;
    const loginPath = '/acceso?return=' + encodeURIComponent(returnPath);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                title: <Term component="Acceso" text="Area protegida"/>,
                icon: null,
            },
            menu: true,
        });
    }, [pageDispatcher, terms]);

    return (
        <>
            <div><Term component="Acceso" text="Esta página esta protegida" /></div>
            <div>
                <Link to={loginPath}><Term component="Acceso" text="Identificarse" /></Link>
            </div>
        </>
    )
}

export default (ProtectedPage);