import React, { FC, useEffect, useState, useContext, useCallback, FormEventHandler } from "react";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";
import { CircularProgress, Button, InputAdornment, IconButton, FormHelperText, createMuiTheme, MuiThemeProvider, TextField, Theme } from "@material-ui/core";
import usePage from "hooks/page.hook";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import IoC from "contexts/ioc.context";
import { mdiEye, mdiEyeOff } from '@mdi/js';
import { Icon } from '@mdi/react';
import { RouteComponentProps } from "react-router";
import queryString from 'query-string';
import { AuthGateway } from "gateways/auth.gateway";
import Term from "components/term";
import Background from 'resources/background-login.jpg';
import PageFooter from '../../components/page-footer';
import imageLogoSede from "./../../resources/Logoatib.png";

const styles = (theme: Theme): StyleRules => ({
    root: {
        flexGrow: 1,
    },
    textWarning: {
        color: 'white',
    },
    spaceText: {
        marginLeft: 10,
    },
    nifLabel: {
        textAlign: 'left',
        cursor: 'pointer',
        fontSize: 20
    },
    noSoyYo: {
        textAlign: 'center',
        cursor: 'pointer',
        fontSize: 20
    },
    loginBox: {
        width: 'auto',
        margin: '0 auto',
        background: 'rgba(0, 43, 90, 0.6)',
        borderRadius: 20,
        marginTop: 25,
        padding: 20,
        marginBottom: 5,
        textAlign: 'center',
        [theme.breakpoints.up('md')]:
        {
            width: 480,
            padding: 40,
        }
    },
    inputColor: {
        color: 'white',
        borderBottom: '1px solid white',
        '&:after': {
            borderBottom: '2px solid white',
        },
    },
    colorWhite: {
        color: 'white',
    },
    flex: {
        flex: 1,
    },
    buttonForm: {
        textAlign: 'center',
        marginTop: 24,
    },
    button: {
        marginLeft: 40,
        marginRight: 40,
        marginTop: 5,
        backgroundColor: 'rgba(255,255,255,0.15)',
        marginBottom: 5,
        color: 'white',
        border: '2px solid white',
        '&:after': {
            border: '2px solid white',
        },
        borderRadius: 20,
    },
    buttonLink: {
        color: '#fff',
        fontWeight: 'lighter',
        letterSpacing: 1,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        }
    },
    listLinks: {
        flex: 1,
        marginTop: 30,
        marginBottom: 50,
    },
    remarcar: {
        fontWeight: 400,
        lineHeight: '1.5em',
        color: '#71bbd7'
    },
    remarcarError: {
        fontWeight: 400,
        lineHeight: '1.5em',
        color: '#ffa500'
    },
    imageFullWidth: {
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%'
    },
    form: {
        marginLeft: 20,
        marginRight: 20,
    },
    imagenMargin: {
        marginBottom: 10
    },
});

type Props = WithStyles<typeof styles> & RouteComponentProps;

const CambioContrasenya: FC<Props> = ({ classes, location, history }) => {
    const [, pageDispatcher] = usePage();
    const terms = useContext(LiteralsContext);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [nif, setNif] = useState('');
    const [token, setToken] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');
    const [textErrorPassword, setTextErrorPassword] = useState<string | null>(null);
    const [textErrorRepeatedPassword, setTextErrorRepeatedPassword] = useState<string | null>(null);

    const ioc = useContext(IoC);
    const authG = ioc.get(AuthGateway) as AuthGateway;

    const redirectToPerfil = useCallback(() => {
        history.push('/perfil');
    }, [history]);

    useEffect(() => {
        const loadPage = async () => {
            const values = queryString.parse(location.search);
            const tokenAux: string = values.token as string;
            const nifAux: string = values.nif as string;
            setToken(tokenAux);
            setNif(nifAux);
        }

        loadPage();
    }, [])

    const info = translate('Acceso', 'textoAclarioCambiarContrasenya', terms);

    const validatePassword = useCallback((passwordLocal: string, repeatedPasswordLocal: string) => {
        let textoAuxPW = '';
        let textoAuxPWR = '';
        if (passwordLocal === '') {
            textoAuxPW = 'El campo no puede estar vacio';
        }
        if (passwordLocal !== '' && (passwordLocal.length < 6 || passwordLocal.length > 18)) {
            textoAuxPW = 'La contraseña debe tener entre 6 y 18 caracteres';
        }
        if (passwordLocal !== '' && (!new RegExp(/([A-Z])/).test(passwordLocal) || !new RegExp(/[a-z]/).test(passwordLocal) || !new RegExp(/\d/).test(passwordLocal))) {
            textoAuxPW = 'La contraseña debe contener almenos una minuscula, una mayúscula y un número';
        }
        if (repeatedPasswordLocal !== '' && (passwordLocal.length >= 6 || passwordLocal.length <= 18)) {

            if ((passwordLocal !== repeatedPasswordLocal)) {
                textoAuxPWR = 'Las contraseñas no coinciden';
            }
        }
        setTextErrorPassword(textoAuxPW);
        setTextErrorRepeatedPassword(textoAuxPWR);
        return textoAuxPW === '' && textoAuxPWR === '';
    }, [])

    const handlePassword = useCallback((inputPassword: string) => {
        setPassword(inputPassword);
        validatePassword(inputPassword, repeatedPassword)
    }, [repeatedPassword, validatePassword])

    const handleRepatedPassword = useCallback((inputPassword: string) => {
        setRepeatedPassword(inputPassword);
        validatePassword(password, inputPassword)
    }, [password, validatePassword])

    const handleClickShowPassword = useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword])

    const handleClickShowRepeatedPassword = useCallback(() => {
        setShowRepeatedPassword(!showRepeatedPassword);
    }, [showRepeatedPassword])

    const formLabelsTheme = createMuiTheme({
        overrides: {
            MuiFormLabel: {
                asterisk: {
                    color: 'white',
                    '&$error': {
                        color: '#ffa500'
                    },
                }
            },
            MuiInputBase: {
                input: {
                    fontSize: 24,
                    textAlign: 'center',
                }
            }
        }
    })

    const handlerChangePassword = useCallback(async () => {
        if (validatePassword(password, repeatedPassword)) {
            setLoading(true);
            try {
                const response = await authG.changePasswordNoLogged(nif, token, password)
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: <Term component="Global" text="La contraseña se ha guardado correctamente" />,
                        variant: 'success',
                    }
                });
                pageDispatcher({ type: 'authenticate', payload: response });
                redirectToPerfil();
            } catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: <Term component="Global" text={(e as any).toString()} />,
                        variant: 'error',
                    }
                })
            } finally {
                setLoading(false);
            }
        }

    }, [authG, nif, pageDispatcher, password, redirectToPerfil, repeatedPassword, token, validatePassword])

    const handleSubmitForm: FormEventHandler<HTMLFormElement> = useCallback((e) => {
        e.preventDefault();
        handlerChangePassword();
    }, [handlerChangePassword])

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: null,
            menu: false,
        });
    }, [pageDispatcher]);
    return (
        <>
            <div className={classes.imageFullWidth}>
                <MuiThemeProvider theme={formLabelsTheme}>
                    <form className={classes.form} onSubmit={handleSubmitForm}>

                        <div className={classes.loginBox}>
                            <div>
                                <img alt="Logo sede" className={classes.imagenMargin} src={imageLogoSede} />
                            </div>
                            <div className={classes.colorWhite} dangerouslySetInnerHTML={{ __html: info }} />
                            <TextField
                                disabled={loading}
                                id="password"
                                margin="normal"
                                required
                                fullWidth
                                autoFocus
                                label={
                                    <span className={classes.colorWhite}>
                                        <Term component="Global" text="Contraseña" />
                                    </span>
                                }
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="off"
                                onChange={(e) => handlePassword(e.target.value)}
                                InputProps={{
                                    className: classes.inputColor,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                <Icon path={showPassword ? mdiEye : mdiEyeOff}
                                                    title="Password"
                                                    size={1}
                                                    color="white"
                                                    className={classes.rightIcon}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div className={classes.textHelper}>
                                {
                                    textErrorPassword !== null
                                        ?

                                        <FormHelperText id="password-helper-text" className={classes.remarcarError}>
                                            <Term component="Global" text={textErrorPassword} />
                                        </FormHelperText>
                                        :
                                        <FormHelperText id="password-helper-text" className={classes.remarcar}>
                                            <Term component="Global" text="requisitosContasenya" />
                                        </FormHelperText>
                                }
                            </div>
                            <TextField
                                disabled={loading}
                                id="password-repeated"
                                margin="normal"
                                required
                                fullWidth
                                autoFocus
                                label={
                                    <span className={classes.colorWhite}>
                                        <Term component="Global" text="Repetir contraseña" />
                                    </span>
                                }
                                type={showRepeatedPassword ? 'text' : 'password'}
                                autoComplete="off"
                                onChange={(e) => handleRepatedPassword(e.target.value)}
                                InputProps={{
                                    className: classes.inputColor,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowRepeatedPassword}
                                            >
                                                <Icon path={showPassword ? mdiEye : mdiEyeOff}
                                                    title="PasswordRepeated"
                                                    size={1}
                                                    color="white"
                                                    className={classes.rightIcon}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div className={classes.textHelper}>
                                {
                                    textErrorRepeatedPassword != null
                                        ? <FormHelperText id="email-helper-text" className={classes.remarcarError}>
                                            <Term component="Global" text={textErrorRepeatedPassword} />
                                        </FormHelperText>
                                        : null
                                }
                            </div>
                            <div className={classes.buttonForm}>
                                <Button variant="outlined" color="primary" className={classes.button} type="submit" size="large" disabled={loading}>
                                    <Term component="Global" text="Guardar contraseña" />
                                    {
                                        loading ?
                                            <CircularProgress size={20} />
                                            :
                                            null
                                    }
                                </Button>
                            </div>
                        </div>
                    </form>
                    <PageFooter />
                </MuiThemeProvider>
            </div>
        </>
    );
}

export default withStyles(styles)(CambioContrasenya);
