import { useEffect, useContext, FC, useState, useCallback } from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import usePage from 'hooks/page.hook';
import queryString from 'query-string';
import Term from 'components/term';
import IoC from 'contexts/ioc.context';
import { mdiLogin } from '@mdi/js';
import { Theme, Card, CardContent, Button } from '@material-ui/core';
import { StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';
import { AuthGateway } from 'gateways/auth.gateway';
import { RecaptchaGateway } from 'gateways/recaptcha.gateway';
import ClaveForm from 'components/clave-form';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import { AlertsContext } from 'contexts/alerts.context';
import { IUserParameters } from 'gateways/auth.interfaces';
import { getBrowser, getPlatform, getUserAgent } from 'utils/client';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import env from '../../env';
import imageClaveModal from "./../../resources/ImgClaveModal.jpg";

const styles = (theme: Theme): StyleRules => ({
    root: {
        margin: '0 0.5rem',
        flex: 1,
    },
    contenido: {
        marginBottom: '0.5rem',
    },
    button: {
        marginLeft: 40,
        marginRight: 40,
        marginTop: 5,
        backgroundColor: 'rgba(255,255,255,0.15)',
        marginBottom: 5,
        border: '2px solid',
        '&:after': {
            border: '2px solid',
        },
        borderRadius: 20,
    },
    center: {
        textAlign: 'center',
        margin: 15,
    }
});

type IState = undefined | { goBack:string}
type Props = WithStyles<typeof styles> & RouteComponentProps<{}, StaticContext, IState>;

const ClavePage: FC<Props> = ({ classes, location, history }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const terms = useContext(LiteralsContext);
    const qs = queryString.parse(history.location.search)
    const [pageState] = usePage();
    const [body, setBody] = useState('');

    // Global states
    const [state] = usePage();
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);

    // Services 
    const ioc = useContext(IoC);
    const authGateway: AuthGateway = ioc.get(AuthGateway);
    const recaptchaGateWay: RecaptchaGateway = ioc.get(RecaptchaGateway);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);

    useEffect(() => {
        (async () => {
            const values = queryString.parse(location.search);
            const token: string = values.token as string;
            const returnUrl: string = values.returnUrl as string;
            const error: string = values.error as string;
            if (token) {
                pageDispatcher({
                    type: 'authenticate', payload: {
                        accessToken: token
                    }
                });
            } else if (error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Acceso', error, terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        actionTemplateName: translate('Acceso', 'Volver', terms),
                        handleCustomAction: () => {
                            alertsDispatch({type: 'hide-alert'}) 
                            history.push(returnUrl) 
                        } 
                    }
                });
            }

            if (pageState.jwp && pageState.jwp.level > 1) {
                const params: IUserParameters = {
                    browser: getBrowser(),
                    urlInicial: window.location.origin + returnUrl,
                    platform: getPlatform(),
                    userAgent: getUserAgent(),
                }
                authGateway.guardarSesion(params);
                history.push(returnUrl);        
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiLogin,
                title: <Term component="Global" text="Autenticación usuario" />
            },
            menu: true,
        });
    }, [pageDispatcher]);

    useEffect(() => {
        (async () => {
            const bodyAux = await contenidoGateway.getContent(
                'acceso_clave', null
            );

            setBody(bodyAux[0].contenido);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.lang]);

    const redirectToClave = useCallback(async () => {
        let loginToken = '';

        const recaptchaCheckIPResult = await recaptchaGateWay.checkIP();
        if (!recaptchaCheckIPResult || recaptchaCheckIPResult.useRecaptcha) {
            loginToken = executeRecaptcha ? await executeRecaptcha('sede_v2_login') : '';
        }

        let returnUrl = (qs.returnUrl instanceof Array ? qs.returnUrl[0] : qs.returnUrl) || '/';
        //returnUrl = returnUrl.replace(env.PUBLIC_URL, '/');

        if(history.location.state && history.location.state.goBack){
            returnUrl = history.location.state.goBack;
        } else {
            returnUrl = returnUrl.replace(env.PUBLIC_URL, '/');
        }

        const result = await authGateway.getAuthenticationRequest(returnUrl, '2', loginToken);
        if (result.ok) {
            const claveForm = document.getElementById('claveForm') as HTMLFormElement;
            const relayState = document.getElementById('RelayState') as HTMLInputElement;
            const samlRequest = document.getElementById('SAMLRequest') as HTMLInputElement;

            relayState.value = result.relayState;
            samlRequest.value = result.samlRequest ? result.samlRequest : '';
            claveForm.action = result.provider;
            claveForm.submit();
        } else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Acceso', result.message ? result.message : '', terms),
                    variant: 'error',
                }
            });
        }
    }, [alertsDispatch, authGateway, executeRecaptcha, qs.returnUrl, recaptchaGateWay, terms]);

    return (
        <div className={classes.root}>
            <Card className={classes.contenido}>
                <CardContent>
                    <div className={classes.center}>
                        <img src={imageClaveModal} alt="clave" />
                    </div>
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: body }} />
                        <div className={classes.center}>
                            <Button variant="outlined" color="primary" className={classes.button} onClick={redirectToClave} size="large">
                                <Term component="Login" text="Continuar" />
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <ClaveForm />
        </div>
    )
}

export default withStyles(styles)(ClavePage);