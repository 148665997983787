import { Card, Typography, WithStyles, withStyles } from "@material-ui/core";
import { styles } from "../styles";
import Term from "components/term";
import { IDomiciliacionCID } from "../../cid.interface";



interface IProps extends WithStyles<typeof styles> {
    domiciliacion: IDomiciliacionCID
}


const DomiciliacionViewer: React.FC<IProps> = ({ classes, domiciliacion }) => {

    return (
        <Card className={classes.card}>
            <div style={{ display: 'flex', alignItems: 'start', flex: 1 }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} >
                    <div style={{ flex: 1 }}>
                        <Typography variant="h6">
                            <Term text="numExpediente" component="Tramites"></Term> :
                        </Typography>
                        <Typography className={classes.noWrapEllipsis}>{domiciliacion.Refe}</Typography>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Typography variant="h6">
                            <Term text="nif"></Term> :
                        </Typography>
                        <Typography className={classes.noWrapEllipsis}>{domiciliacion.DF_Nif}</Typography>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Typography variant="h6">
                            <Term text="Importe" component="Tramites"></Term> :
                        </Typography>
                        <Typography className={classes.noWrapEllipsis}>{domiciliacion.Importe}</Typography>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} >
                    <div style={{ flex: 1 }}>
                        <Typography variant="h6">
                            <Term text="nombre" component="Global"></Term> :
                        </Typography>
                        <Typography className={classes.noWrapEllipsis}>{domiciliacion.DF_Nom}</Typography>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Typography variant="h6">
                            <Term text="Estado" component="Tramites"></Term> :
                        </Typography>
                        <Typography className={classes.noWrapEllipsis}>
                            <Term text={`${domiciliacion.EstadoString}`} component="Tramites"></Term>
                        </Typography>
                    </div>
                </div>
            </div >
        </Card >
    );
}
export default withStyles(styles)(DomiciliacionViewer);