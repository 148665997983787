
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, Paper, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { translate } from "utils/i18n";
import { mdiCreditCard, mdiPrinter, mdiSignatureFreehand, mdiWalletTravel } from "@mdi/js";
import usePage from "hooks/page.hook";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { RouteComponentProps } from "react-router-dom";

import { AbonoNacimientoGateway } from "gateways/tramites/abonoNacimiento.gateway";
import IoC from "contexts/ioc.context";
import { ISolicitud } from "gateways/tramites/abonoNacimiento.interfaces";

import { AlertsContext } from "contexts/alerts.context";
import FullLoading from "components/full-loading";
import moment from "moment";
import { download } from "utils/download";
import Icon from "@mdi/react";

import { GenericModal } from "components/generic-modal";

import Resumen3IMG from "../../../resources/abonoNacimiento/Resumen3.png";
import m046IMG from "../../../concepto-resource/images/autonomicos.png";
import { EstadoSolicitud } from "./enums/estadoSolicitud.enum";
import { formatNumStr } from "components/generic-sujeto/utils";
import { TTipoEstado } from "./steps/types";
import ModalFirmarSolicitud from "./modals/modal-firmar-solicitud";
import ModalPagarSolicitud from "./modals/modal-pagar-solicitud";
import ModelGateway from "gateways/model.new.gateway";
import EndProcessMSG from "components/Modelos/componentes/endProcessMSG";
import GenericDivMSG from "components/generic-div-msg/genericDivMSG";
import { TributosAutonomicosGateway } from "gateways/tributos.autonomicos.gateway";
import { Info } from "@material-ui/icons";





type IProps = WithStyles<typeof styles>  & RouteComponentProps<{idSolicitud: string}> &  {} 
const AbonoNacimientoDetalle : FC<IProps> = ({classes, match, history }) => {

    const terms=useContext(LiteralsContext)
    const [,alertsDispatch] = useContext(AlertsContext);
    const componentMounted = useRef(true);

    // Gateways
    const ioc = useContext(IoC);
    const abonoNacimientoGateway: AbonoNacimientoGateway = useMemo(() => ioc.get(AbonoNacimientoGateway), [ioc]); 
    const tributosAutonomicosGateway: TributosAutonomicosGateway = useMemo(() => ioc.get(TributosAutonomicosGateway), [ioc]); 



    const [page, pageDispatcher] = usePage();

    const [loading, setLoading] = useState(false)
    const [showFirma, setShowFirma] = useState(false)

    // const [showPago, setShowPago] = useState(false)
    

    const [info, setInfo] =  useState<ISolicitud|null>(null)
    // const SOLICITUDES =[50,52]
    const REINTEGROS = [51,53]
    const EstadosPendientes = [EstadoSolicitud['SPF'], EstadoSolicitud['RPP'],EstadoSolicitud['RPF']]



    // -- Memos ----------------------------------------------------------------------------------------------------------------------
    const idSolicitud : number= useMemo(() => {
        // console.log('match ',match.params)
        return  (match.params.idSolicitud && !isNaN(Number(match.params.idSolicitud)) ) ? Number(match.params.idSolicitud) : 0
    },[match.params.idSolicitud])

    const allowBtnFirma = useMemo(() => {
        if(!info){ return false}
        if(EstadoSolicitud['RPF']=== info.estado.id || EstadoSolicitud['SPF']=== info.estado.id){ return true }
        return false
    },[info])

    const allowBtnPagar = useMemo(() => {
        if(!info){ return false}
        //console.log('info.modeloEstado ', info.modeloEstado )
        if(REINTEGROS.includes(info.tipoDocumento.id) &&  EstadoSolicitud['RPP'] === info.estado.id && info.modeloEstado !== 'FIN'){
            return true
        }
        return false
    },[info])


   
    // -- Functions-------------------------------------------------------------------------------------------------------------------
    const handlePagar = async (idSolicitud: number, idModelo: string|null) => {
        try{
            setLoading(true)
            const tokenPago = await abonoNacimientoGateway.getURLPago(idSolicitud, idModelo)
            console.log('tokenPago' , tokenPago)
            if(tokenPago !== null){
                history.push(`/pasarela-pago/pago/${tokenPago}`, {from: 'ABO_NAC', idSolicitud: idSolicitud});
            }
            
        } catch (err){
            alertsDispatch({
                type: 'show-alert',
                payload:{
                    message: translate('AbonoNacimiento', 'ErrorTokenPago', terms),
                    variant:'error'
                }
            })
        } finally{
            setLoading(false)
        }
    }


    // Futurible integración - firma ClaveFirma
    // const onCloseFirma = (info: ISolicitud | null, updated: boolean) => {
    //     if(updated){setInfo(info);}
    //     setShowFirma(false)
    // }


    /** Firma y descraga el documento+Modelo */
    const handlePrint = async (e: React.MouseEvent) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            setLoading(true);

            const result = await abonoNacimientoGateway.getJustificantSolicitud(idSolicitud);
            const ok = download(result, alertsDispatch,
                translate('AbonoNacimiento', 'No es posible descargar el justificante de la solicitud', terms),
                translate('Global', 'BloqueoPantalla', terms));

            if(ok){
                const result = await abonoNacimientoGateway.getDetalleSolicitudById(idSolicitud)
                setInfo(result)
            }
            // if(ok && info && info.idModelo && info.modeloReferencia){
            //     await handlePrintModelo(info.modeloReferencia, info.idModelo)
            // }

        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload:{
                    message: translate('AbonoNacimiento', 'No es posible descargar el justificante de la solicitud', terms),
                    variant:'error'
                }
            })
        } finally {
            setLoading(false);
        }
        
    };
    

    // -- Effects --------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiWalletTravel,
                title: translate("AbonoNacimiento","AbonoNacimiento_detalle", terms),
                // text: translate("AbonoNacimiento","AbonoNacimiento_detalleDescripcion", terms)
            },
            menu: true,
        });
    }, [pageDispatcher]);

    useEffect(() => {
        (async() => {
            try {
                setLoading(true)
                const result = await abonoNacimientoGateway.getDetalleSolicitudById(idSolicitud)
                if(componentMounted.current){
                    setInfo(result)

                }
            } catch(err){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        variant:'error',
                        message: translate('AbonoNacimiento','ErrorConsultadoDetalleSolicitud',terms)
                    }
                })
            } finally {
                setLoading(false)
            }

        })();

        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }

    },[idSolicitud])

    //console.log('hi from  AbonoNacimientoAlta ')
    

    return (
        <Grid container direction="column" className={classes.container}>
            <FullLoading loading={loading}/>
            <Grid item className={classes.root}>
            {info && 
                <Paper className={classes.paper}>
                    <Grid container direction="column">
                        <Grid item container direction="row">
                            <div>
                                <img src={Resumen3IMG} ></img>
                            </div>
                            {/** Detalle  */}
                            <div className={[classes.column].join(' ')}>
                            <div className={[classes.column, classes.marginDetail].join(' ')}>
                                    <div className={[classes.row, classes.bottom].join(' ')}>
                                        <Typography className={classes.label}>{translate('AbonoNacimiento','refSolicitud',terms)}:</Typography>
                                        <Typography className={classes.text}>{`${info.ejercicio}/${info.numSolicitud}`}</Typography>
                                    </div>
                                    
                                    <div className={[classes.row, classes.bottom].join(' ')}>
                                        <Typography className={classes.label}>{translate('AbonoNacimiento','tipoDocumento',terms)}:</Typography>
                                        <Typography className={classes.text}>{translate('AbonoNacimiento',info.tipoDocumento.term,terms)}</Typography>
                                    </div>
                                    {info.modalidad?.id &&
                                        <div className={[classes.row, classes.bottom].join(' ')}>
                                            <Typography className={classes.label}>{translate('AbonoNacimiento','tipoModalidad',terms)}:</Typography>
                                            <Typography className={classes.text}>{translate('AbonoNacimiento',info.modalidad.term,terms)}</Typography>
                                        </div>
                                    }
                                    <div className={[classes.row, classes.bottom].join(' ')}>
                                        <Typography className={classes.label}>{translate('AbonoNacimiento',[50,51].includes(info.tipoDocumento.id) ? 'panel_contribuyente': 'panel_principal',terms)}:</Typography>
                                        <Typography className={classes.text}>{info.nifSujeto}</Typography>
                                    </div>
                                    <div className={[classes.row, classes.bottom].join(' ')}>
                                        <Typography className={classes.label}>{translate('AbonoNacimiento','fechaAlta',terms)}:</Typography>
                                        <Typography className={classes.text}>{moment.utc(info.fechaAlta).format('DD/MM/YYYY   HH:mm')}h</Typography>
                                    </div>

                                    {info.importe && !REINTEGROS.includes(info.tipoDocumento.id) &&
                                        <div className={[classes.row, classes.bottom].join(' ')}>
                                            <Typography className={classes.label}>{translate('AbonoNacimiento', 'importeSolicitud',terms)}:</Typography>
                                            <Typography className={classes.text}>{formatNumStr(info.importe)}€</Typography>
                                        </div>
                                    }
                                    {info.importe && REINTEGROS.includes(info.tipoDocumento.id) && 
                                        <div className={[classes.row, classes.bottom].join(' ')}>
                                            <Typography className={classes.label}>{translate('AbonoNacimiento','importeAIngresar',terms)}:</Typography>
                                            <Typography className={classes.text}>{formatNumStr(info.importe)}€</Typography>
                                        </div>
                                    }
                                    <div className={[classes.row, classes.bottom].join(' ')}>
                                        <Typography className={classes.label}>{translate('AbonoNacimiento','estado',terms)}:</Typography>
                                        <Typography className={classes.text}>{translate('AbonoNacimiento',info.estado.term,terms)}</Typography>
                                    </div>
                                </div>
                                
                                {/**Info del model 046 Ligado al reintegro */}
                                {REINTEGROS.includes(info.tipoDocumento.id) && 
                                <div className={classes.row} style={{alignContent:'flex-start',alignItems:'center', width:'100%'}}>
                                <div >
                                    <img src={m046IMG} width={80} height={80}/>
                                </div>
                                <div className={[classes.row, classes.bottom].join(' ')} style={{ marginTop: 5}} >
                                    <Typography className={classes.label}>{translate('AbonoNacimiento','localizador',terms)}:</Typography>
                                    <Typography className={classes.text}>{info.idModelo}</Typography>
                                </div>
                                <div className={[classes.row, classes.bottom].join(' ')} style={{ marginTop: 5}}>
                                    <Typography className={classes.label}>{translate('AbonoNacimiento','importeAingresar',terms)}:</Typography>
                                    <Typography className={classes.text}>{info.modeloImporte} €</Typography>

                                </div>
                                <div className={[classes.row, classes.bottom].join(' ')}>
                                    <Typography className={classes.label}>{translate('AbonoNacimiento','estado',terms)}:</Typography>
                                    <Typography className={classes.text} style={{ marginTop: 5}}>
                                        {(info.modeloEstado === 'FIN' || info.modeloEstado === 'CNK') 
                                            ? translate('AbonoNacimiento',`estado_${info.modeloEstado}`,terms)
                                            : translate('AbonoNacimiento',`estado_pendiente_pago`,terms)
                                        }
                                    </Typography>
                                    
                                </div>
                                
                            </div>
                                }
                                
                            </div>

                        </Grid>
                        <Grid item className={classes.containerButtons}>
                            {allowBtnPagar ?
                                    <Button style={{marginRight: 10}}
                                        variant="contained" 
                                        color="primary" 
                                        onClick={() => handlePagar(info.idSolicitud, info.idModelo)}
                                        startIcon= { <Icon path={mdiCreditCard} size={1} color={"#fff"} /> } 
                                    >
                                        {translate('Global', 'Pagar', terms)}
                                    </Button>
                             
                            : <>
                                { allowBtnFirma &&
                                    <Button style={{marginRight: 10}}
                                        variant="contained" 
                                        color="primary" 
                                        onClick={handlePrint} //() => setShowFirma(true)}
                                        startIcon= { <Icon path={mdiSignatureFreehand} size={1} color={"#fff"} /> } 
                                    >
                                        {translate('Global', 'Presentar', terms)}
                                        {/* {`${translate('Global', 'Firmar', terms)}/${translate('Global', 'Imprimir', terms)}`} */}
                                    </Button>
                                }

                                {/** 
                                 * Unificamos firmar e imprimri  - No firmamos con Clave firma
                                 * Utilizar CID + sello propio ATIB (provisional)
                                 * Ocultamos button si el estado es pendiente de firma
                                 */}

                                {!([EstadoSolicitud['SPF'], EstadoSolicitud['RPF']].includes(info.estado.id)) && 
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={handlePrint}
                                        disabled={ !([EstadoSolicitud['SPF'],EstadoSolicitud['SPT'],EstadoSolicitud['SAP'], EstadoSolicitud['SAA'], EstadoSolicitud['RPI'], EstadoSolicitud['RPF']].includes(info.estado.id)) }
                                        startIcon={ <Icon path={mdiPrinter} size={1} color={"#fff"} /> } 
                                    >
                                        {translate('Global', 'Imprimir', terms)}
                                    </Button>
                                }
                            </>
                        }
                        </Grid>

                        { EstadosPendientes.includes(info.estado.id) &&
                        <Grid item style={{padding: '50px 0px 0px 10px'}}>
                            <GenericDivMSG 
                                type='info' 
                                paramContent= {{}}
                                content={REINTEGROS.includes(info.tipoDocumento.id) ? 'ABO_NAC_info_detalle_reintegro': 'ABO_NAC_info_detalle_solicitud'} 
                            />
                        </Grid>}
                        
                    </Grid>
                   
                        
                </Paper>
            }

            {/* <ModalFirmarSolicitud show={showFirma} info={info} onClose={onCloseFirma}/> */}
            </Grid>
        </Grid>


    )
}

const styles = (theme: Theme): StyleRules => ({
    container: {
        height: '100%',
        overflowY: 'auto'
    },
    root: {
        flexGrow: 1,
        overflowY:'auto',
        margin: '0px 10px 0px 10px',
    },
    paper: {
        flexGrow: 1,
        padding: 16
    },
    textContainer:{
        padding: '0px 10px 0px 10px'
    },
    row: {
        display:'flex',
        flexDirection:'row',
    },
    column: {
        display:'flex',
        flexDirection:'column',
    },
    bottom: {
        alignItems: 'flex-end'
    },
    center: {
        alignItems: 'center'
    },
    modelo: {
        alignItems:'center', 
        justifyContent:'space-between',
        flexGrow:1,
        marginLeft: 150
    },
    marginDetail: {
        marginLeft: 20,
    },
    text:{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 700,
        marginRight: 25
    },
    label: {
        color: '#000',
        fontSize: 14,
        marginTop: 5,
        marginRight: 5
    },
    paddingIMG: {
        paddingRight: 10,
        paddingLeft: 10
    },
    containerButtons: {
        widht: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 10,
    }
   
});

export default  withLiterals(['Global', 'Tramites','AbonoNacimiento' ])(withStyles(styles)(AbonoNacimientoDetalle)); 
