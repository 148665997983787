import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";

@inject('axios')
export default class TimerGateway {
  constructor(
    private axios: AxiosInstance
  ) {
      if (!axios) {
          throw new Error('OficinasGateway requires an axios instance');
      }
  }

  public async getCurrentTime(): Promise<string> {
    const response = await this.axios.get('timer');

    if (response.status !== 200) {
        throw new Error('Bad response status: ' + response.statusText);
    }

    return response.data;
  }
}