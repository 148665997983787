import { height } from "@fortawesome/free-solid-svg-icons/faFilter";
import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { mdiBorderColor } from "@mdi/js";

const styles = (theme: Theme): StyleRules => ({
    centerContent: {
        textAlign: 'center',
        paddingTop: 250,
        width: '100%',
        zIndex: 1000,
    },
    row: {
        display:'flex', 
        flexDirection:'row', 
        alignItems:'center'
    },
    rowAlignRight: {
        marginTop: 10,
        marginBottom: 10,
        display:'flex', 
        flexDirection:'row', 
        alignItems:'center',
        justifyContent: 'flex-end',
        flexGrow: 1
    },
    rowAlignLeft: {
        marginTop: 10,
        marginBottom: 10,
        display:'flex', 
        flexDirection:'row', 
        alignItems:'center',
        justifyContent: 'flex-start',
        flexGrow: 1
    },
    rowAlignSpaceBetween: {
        marginTop: 10,
        marginBottom: 10,
        display:'flex', 
        flexDirection:'row', 
        alignItems:'center',
        justifyContent: 'space-between',
        flexGrow: 1
    },
    // CONCEPTOS
    outlinedButtonIcon: {
        color: '#004f84',
        borderColor: '#004f84',
        padding: 0,
        borderRadius: 50,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 1
    },
    outlinedButton:{
        color: '#004f84',
        borderColor: '#004f84',
    },
    outlinedButtonWhite:{
        color: 'white',
        borderColor: 'white',
    },
    outlinedGrid:{
        marginTop: 30,
        padding: 10,
        height: '60%',
        width: 'auto',
        /*border: 'solid',
        borderWidth: 'thin',
        borderRadius: 10,
        borderColor: '#004f84',*/
    },
    listItemRootInverse: {
        marginBottom: 10,
        borderRadius: 10,
        backgroundColor: '#d3d3d3',
        color: '#000',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            backgroundColor: '#ebebeb'
        },
    },
    listItemSelected: {
        color: '#FFF',
        backgroundColor: '#299db1 !important', 
    },
    search :{
        margin: 5,
        display: 'flex',
        flexGrow: 1,
    },
    // CAMPO
    notchedOutlineRequired: {
        borderWidth: "1.5px",
        borderColor: "#007fff !important",
    },
    field: {
        margin: 10, 
        width:360,
    },
    'x-small': {
        margin: 10, 
        width: 104,
    },
    small: {
        margin: 10, 
        width: 180,
    },
    'xs-big': {
        margin: 10, 
        width: 500,
    },
    big: {
        margin: 10, 
        width: '95%',
    },
    popper: { 
        width: 'max-content'
    },
    //SUJETO
    btnChargeDataUserLogged: {
        marginLeft: 10,
        "&.Mui-disabled": {
          pointerEvents: "auto"
        }},
    //Relacion contribuyentes
    itemHeaderTable: {
        textAlign: 'center',
        padding: 10,
        background: '#004f82',
        border: 'solid',
        borderWidth: '0px 1px 0px 0px',
        borderColor: '#fff',
    }, 
    itemHeaderTableLastInRow: {
        textAlign: 'center',
        padding: 10,
        background: '#004f82',
    },
    itemTitle:{
        fontWeight: 500,
        color: '#fff',
    },
    itemTable: {
        textAlign: 'center',
        padding: 10,
        border: 'solid',
        borderWidth: '0px 0px 1px 1px',
        borderColor: '#004f82',
    }, 
    itemTableLastInRow: {
        textAlign: 'center',
        padding: 10,
        border: 'solid',
        borderWidth: '0px 1px 1px 1px',
        borderColor: '#004f82',
    }, 
    hiddenSelector: {
        display: 'none',
    }, 
    // UTILS styles
    table: {marginTop: 30, marginBottom: 10 },
    titlerow_multiple_Width_800: {marginLeft: 20,width: '40%'},
    titlerow_simple_Width_800: {marginLeft: 20,width: '72%'},
    titlerow_multiple_Width_890: {marginLeft: 20,width: '20%'},
    titlerow_simple_Width_890: {marginLeft: 20, width: '50%'},
    titlerow_Width_623: {marginLeft: 20, width: '100%'},
    titlerow: {marginLeft: 20},
    section: {marginTop: 30, marginBottom: 20, fontWeight: 'bold' }
})

export default styles;