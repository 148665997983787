export enum EstadoSolicitud {
    SPF = 1, //Solicitud pendiente de firma
    SPT = 2, //Solicitud presentada en tramitació
    SAP = 3, //Solicitud aprobada pendiente abono
    SAA = 4, //Solicitud aprobada y abonada
    SDE = 5, //Solicitud denegada
    RPP = 6, //Autoliquidacion reintegro pendiente ingreso
    RPF = 7, //Autoliquidacion reintegro pendiente firma
    RPI = 8, //Autoliquidacion reintegro presentada e ingresada
    ERR = 9 , //Solicitud/Reintegro error
    SCD = 15, //Solicitud/Reintegro Caducada
    SDP = 16  //Solicitud Despresentada
}

