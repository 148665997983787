import { Grid, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import ModelGateway from "gateways/model.new.gateway";
import { IAsistentePLVObj } from "gateways/model.new.interface";
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { translate } from "utils/i18n";
import { TCalcPlusvalia, TComprador, TInfoTransmPlusvalia, TPreguntasPlusvalia, TTipoModeloPLV, TVendedor } from "../../plusvalias/types";
import { renderField, renderSujeto, renderTitle } from "./utils";
import { formatNumStr } from "containers/DUTI/utils";
import moment from "moment";
import { TSujeto } from "../../shared/types";


  

interface IProps extends WithStyles<typeof styles>{
    idReferencia: string
}
const ContentConformidadPLV: FC<IProps>= ({classes, idReferencia}) => {
    const componentMounted = useRef(true);

    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelosGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    

    const [tipoModelo,setTipoModelo]= useState<TTipoModeloPLV| undefined>(undefined)
    const [loading, setLoading]= useState(false)
    const [datosAsistente, setDatosAsistente]= useState({} as IAsistentePLVObj)

    const resumenPreguntasPlusvalia = (data: TPreguntasPlusvalia) => {
        return(
            <>
            {renderTitle('preguntasPlusvalia', terms, 'Plusvalias')}
            {renderField('municipio',data.municipio?.nombre, terms, 'DUTI')}
            {renderField('refCatastral',data.inmueble?.refCatastral, terms, 'DUTI')}
            {renderField('tipoTransmision',data.tipoTransmision.tipo?.nombre, terms, 'Plusvalias')}
            {renderField('bonificacion',data.tipoTransmision.bonificacion?.valor ? data.tipoTransmision.bonificacion.valor.toString()+"%": undefined, terms, 'Plusvalias')}
            {renderField('actuaNombrePropio', data.actuaNombrePropio ? 'Sí': 'No', terms, 'Plusvalias')}
            {!data.actuaNombrePropio && data.representante &&
                <>
                    {renderSujeto(data.representante, terms, 'DUTI')}
                </>
            }
            {renderField('tipoPlusvaliaModelo', data.tipoPlusvaliaModelo ? translate('Plusvalias',`tipoPLV${data.tipoPlusvaliaModelo.id}`,terms): "" , terms, 'Plusvalias')}
            </>
        )
    }

    const resumenInfoTransmPlusvalia = (data: TInfoTransmPlusvalia) => {
        return(
            <>
                {renderTitle('infoTransmPlusvalia', terms, 'Plusvalias')}
                {renderField('fecha', moment(data.fecha).format('DD/MM/YYYY'), terms,'Plusvalias')}
                {renderField('tipoDocTransmision', data.tipoDocTransmision?.nombre, terms,'DUTI')}
                {renderField('fechaDoc', moment(data.fechaDoc).format('DD/MM/YYYY'), terms,'Plusvalias')}
                {renderField('anyo', data.anyo?.toString(), terms,'DUTI')}
                {renderField('numeroProtocolo', data.numeroProtocolo?.toString(), terms,'DUTI')}
                {renderField('nifNotario', data.notario?.nif?? "", terms,'DUTI')}
                {renderField('notario', data.notario?.nomApe ?? "", terms,'DUTI')}
                {renderField('valorDeclarado', data.valorDeclarado? formatNumStr(data.valorDeclarado)+'€': "", terms,'DUTI')}
                {renderField('porcentaje', data.porcentaje ? data.porcentaje.toString()+'%': "", terms,'DUTI')}
                {renderField('idDocumentoEscritura', data.idDoc, terms,'DUTI')}
                { data.infoRegistral &&
                    <>
                    {renderField('oficina', data.infoRegistral.oficina, terms,'DUTI')}
                    {renderField('tomo', data.infoRegistral.tomo, terms,'DUTI')}
                    {renderField('libro', data.infoRegistral.libro, terms,'DUTI')}
                    {renderField('finca', data.infoRegistral.finca, terms,'DUTI')}
                    {renderField('folio', data.infoRegistral.folio, terms,'DUTI')}
                    </>
                }
        </>
        )
    }
    const renderCompradorVendedor = <T extends 'comprador'|'vendedor'>(data: T extends 'comprador' ? TComprador[] :TVendedor[],tipo :T) => {
        return(
            <>
                {renderTitle(tipo === 'comprador' ? 'compradores': 'vendedores', terms, 'Plusvalias')}
                <>
                {data.map( (c, idx) => { 
                    if(tipo === 'comprador') {
                        //const {viviendaHabitual,porcentaje,fechaNacimiento, ...restSuj} = c as TComprador
                        const {porcentaje, ...restSuj} = c as TComprador

                        return (
                            <>
                                <Typography style={{textDecoration: 'underline'}}>{`${translate('DUTI',`datos_${tipo}`,terms).toUpperCase()} ${idx+1}:`}</Typography>
                                {renderSujeto(restSuj,terms,'DUTI')}
                                {renderField('porcentajeAdquisicion',porcentaje ? porcentaje.toString()+"%" : "", terms,'DUTI',true)}
                                {/* {renderField('viviendaHabitual',viviendaHabitual ? 'Sí':'No', terms,'DUTI',true)}
                                {renderField('fechaNacimiento',fechaNacimiento ? moment(fechaNacimiento).format('DD/MM/YYYY') : "", terms,'DUTI',true)} */}
                            </>
                        )
                    } else {
                        const {/*porcentaje,numOperaciones,*/ ...restSuj} = c as TVendedor
                        return (
                            <>
                                <Typography style={{textDecoration: 'underline'}}>{`${translate('DUTI',`datos_${tipo}`,terms).toUpperCase()} ${idx+1}:`}</Typography>
                                {renderSujeto(restSuj,terms,'DUTI')}
                                {/* {renderField('porcentajeTitularidad',porcentaje ? porcentaje.toString()+"%" : "", terms,'DUTI',true)}
                                {renderField('numOperacionesAdquisicion',numOperaciones ? numOperaciones.toString() : "", terms,'DUTI',true)} */}
                            </>
                        )
                    }
                    
                })}
                </>
            </>  
        )
    }

    const renderCalcPlusvalia = (data: TCalcPlusvalia[]) => {
        return(
            <>
                {renderTitle('calcPlusvalia', terms, 'Plusvalias')}
                {data.map( (item, idx)=> (
                    <>
                       <Typography style={{textDecoration: 'underline'}}>{`${translate('DUTI','datosPlusvalia' ,terms).toUpperCase()} ${idx+1}:`}</Typography>
                       {renderField('nifVendedor',item.nifVendedor,terms,'Plusvalias', true)}
                       {renderField('condSujetoPasivo',item.sujetoPasivo ? 'Sí': 'No',terms,'Plusvalias', true)}
                       {renderField('liquidacionMultiple',item.liquidacionMultiple ? 'Sí': 'No',terms,'Plusvalias', true)}
                       <div style={{marginLeft: 0}}>
                            {/** Completar */}
                            {item.calcPLV.map(calcPLV => {
                                return (
                                    <>
                                        <Typography style={{textDecoration: 'underline'}}>{`${translate('Plusvalias','operacionesAnterioresAdquisiciones' ,terms).toUpperCase()} `}</Typography>
                                        {renderField('nifSujetoPasivo',calcPLV.nifSujetoPasivo,terms,'DUTI', true)}
                                        <>
                                        { calcPLV.calculos.map( (calc,idx) => (
                                            <>
                                                <Typography style={{textDecoration: 'underline', marginLeft: 10}}>{`${translate('Plusvalias','adquisicion' ,terms)} ${idx+1}:`}</Typography>
                                                <div style={{marginLeft: 20,}}>
                                                    {renderField('tipoDerecho',calc.tipoDerecho ? calc.tipoDerecho.nombre : "",terms,'Plusvalias', true)}
                                                    {renderField('edadUsufructuaria',calc.edadUsufructuaria?.toString() ?? "",terms,'Plusvalias', true)} 
                                                    {renderField('porcentajeTitularidad',calc.porcentaje?.toString() ?? "",terms,'Plusvalias', true)}
                                                    {renderField('numOperacionesAdquisicion',calc.numOperaciones?.toString() ?? "",terms,'DUTI', true)}
                                                    <div style={{marginLeft: 25}}>
                                                        {calc.modelo && calc.modelo.adquisiciones ?
                                                            <>   
                                                                {calc.modelo.adquisiciones.map((adq, adqIdx) => (
                                                                    <div>
                                                                        <Typography style={{textDecoration: 'underline'}}>{`${translate('DUTI','operacion' ,terms)} ${adqIdx+1}:`}</Typography>
                                                                        <div style={{marginLeft: 30}}>
                                                                            {renderField('fechaAdquisicion', adq.metodoObjetivo.fechaAdquisicion ? moment(adq.metodoObjetivo.fechaAdquisicion ).format('DD/MM/YYYY'): "",terms,'DUTI',true)}
                                                                            {renderField('porcentaje', adq.metodoObjetivo.porcentaje.toString() + "%",terms,'Plusvalias',true)}
                                                                            {renderField('importeAnteriorAdquisicion', adq.metodoObjetivo.importeAnteriorAdquisicion ? formatNumStr(adq.metodoObjetivo.importeAnteriorAdquisicion)+'€' : "",terms,'DUTI',true)}
                                                                                
                                                    
                                                                            <div >
                                                                                <Typography style={{textDecoration: 'underline'}}>{translate('Plusvalias','metodoObjetivo' ,terms)}</Typography>
                                                                                {renderField('valorParcial', adq.metodoObjetivo.valorParcial ? formatNumStr(adq.metodoObjetivo.valorParcial)+'€' : "",terms,'Plusvalias',true)}
                                                                                {renderField('baseImponible', adq.metodoObjetivo.baseImponible ? formatNumStr(adq.metodoObjetivo.baseImponible)+'€' : "",terms,'DUTI',true)}
                                                                                {renderField('anyosTenencia', adq.metodoObjetivo.anyosTenencia.toString() ,terms,'Plusvalias',true)}
                                                                                {renderField('cuotaIntegra', adq.metodoObjetivo.cuotaIntegra ? formatNumStr(adq.metodoObjetivo.cuotaIntegra)+'€' : "",terms,'Plusvalias',true)}
                                                                                {renderField('tipoAnual', adq.metodoObjetivo.tipoAnual ? adq.metodoObjetivo.tipoAnual.toString()+'%' : "",terms,'Plusvalias',true)}
                                                                                {renderField('tipoGravamen', adq.metodoObjetivo.tipoGravamen ? adq.metodoObjetivo.tipoGravamen.toString()+'%' : "",terms,'Plusvalias',true)}


                                                                                {adq.metodoReal ?
                                                                                    <div>
                                                                                        <Typography style={{textDecoration: 'underline'}}>{translate('Plusvalias','metodoReal' ,terms)}</Typography>
                                                                                    </div>
                                                                                    : <div>
                                                                                        <Typography style={{textDecoration: 'underline'}}>{translate('Plusvalias','metodoReal' ,terms)}</Typography>
                                                                                        {renderField('noAplica',  "",terms,'Plusvalias',true)}
                                                                                    </div>
                                                                                }


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </>
                                                            : 
                                                            <>
                                                                {calc.operaciones && calc.operaciones.length > 0 && calc.operaciones.map((op,opIdx) => (
                                                                    <>
                                                                        <Typography style={{textDecoration: 'underline'}}>{`${translate('DUTI','operacion' ,terms)} ${opIdx+1}:`}</Typography>
                                                                        <div style={{marginLeft: 30}}>
                                                                            {renderField('fechaAnteriorAdquisicion',  op.fechaAnteriorAdquisicion ? moment(op.fechaAnteriorAdquisicion).format('DD/MM/YYYY'): "",terms, 'DUTI', true)}
                                                                            {renderField('porcentajeAnteriorAdquisicion',  op.porcentajeAnteriorAdquisicion? op.porcentajeAnteriorAdquisicion.toString()+"%" : "", terms, 'DUTI', true)}
                                                                            {renderField('importeAnteriorAdquisicion',  op.importeAnteriorAdquisicion ? formatNumStr(op.importeAnteriorAdquisicion)+'€' : "", terms, 'DUTI', true)}
                                                                            {renderField('idEscrituraAnterior',  op.idDocAnteriorAdquisicion ?'Sí': 'No', terms, 'DUTI', true)}
                                                                        </div>
                                                                    </> 
                                                                ))}
                                                            </>
                                                        }
                                                        {calc.modelo ? 
                                                            <>
                                                                <Typography style={{textDecoration: 'underline'}}>{translate('Plusvalias','liquidacion' ,terms)}</Typography>
                                                                <div style={{marginLeft: 30}}>
                                                                    {renderField('importeMetodoObjetivo', formatNumStr(calc.modelo.importeMetodoObjetivo) +"€" , terms, 'DUTI', true)}
                                                                    {renderField('importeMetodoReal',calc.modelo?.importeMetodoReal ? formatNumStr(calc.modelo.importeMetodoReal)+"€" : "", terms, 'DUTI', true)}
                                                                    {renderField('totalIngresar', calc.modelo?.totalIngresar ? formatNumStr(calc.modelo.totalIngresar)+"€" : "", terms, 'DUTI', true)}
                                                                </div>
                                                            </>
                                                            :null 
                                                        }

                                                    </div>
                                                
                                                </div>
                                            </>
                                        ))}
                                        </>
                                    </>
                                )
                            })}
                       </div>
                    </>
                ))}
            </>
        )

    }

    useEffect(() => {
        (async()=> {
            try {
                setLoading(true)
                const prevInfo = await modelosGateway.getAllApartadosModeloAsistente('PLV',idReferencia)
                // Calls - await 
                if(componentMounted.current){
                    //setters
                    setDatosAsistente(prevInfo)
                    const tipoMod=prevInfo.preguntasPlusvalia && prevInfo.preguntasPlusvalia.tipoPlusvaliaModelo ? prevInfo.preguntasPlusvalia.tipoPlusvaliaModelo.id as TTipoModeloPLV : undefined
                    setTipoModelo(tipoMod)
                    
                }
            } catch (err){
                const msg= (err as Error).message
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Plusvalias', msg, terms),
                        variant: 'error',
                    }
                });
            } finally {
                setLoading(false)
            }

        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    },[idReferencia]);

    return(
        <div style={{width: '100%'}}>
            {datosAsistente.preguntasPlusvalia && resumenPreguntasPlusvalia(datosAsistente.preguntasPlusvalia)}
            {datosAsistente.infoTransmPlusvalia && resumenInfoTransmPlusvalia(datosAsistente.infoTransmPlusvalia)}
            {datosAsistente.compradores && renderCompradorVendedor(datosAsistente.compradores, "comprador")}
            {datosAsistente.vendedores && renderCompradorVendedor(datosAsistente.vendedores, "vendedor")}
            {datosAsistente.calcPlusvalia && renderCalcPlusvalia(datosAsistente.calcPlusvalia)}
        </div>
    )
}

const styles =  (theme: Theme): StyleRules => ({

})

export default withLiterals(["Plusvalias","Global","DUTI"])(withStyles(styles)(ContentConformidadPLV))