import React, { FC, useEffect, useContext, useReducer, useCallback } from 'react';
import { Dialog, Button, DialogTitle, DialogActions, makeStyles } from "@material-ui/core";
import Term from '../../../components/term';
import { ScreenType } from './filters.form';
import { OrderContext } from './order.context';
import OrderReducer, { IOrderState } from './order.reducer';
import OrderForm from './order.form';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
    },
    title: {
        textAlign: 'center'
    },
    main: {
        padding: 20
    },
}));

interface IProps {
    open: boolean;
    screen: ScreenType;
    onClose: () => void;
    onChange: (query: IOrderState) => void;
}

const OrderDialog: FC<IProps> = ({ open, onClose, onChange, screen }) => {
    const [order, orderDispatch] = useContext(OrderContext);

    const orderReducer = useReducer(OrderReducer, order);
    const [newOrder, newOrderDispatch] = orderReducer;

    const classes = useStyles();
    const handleClose = useCallback(() => onClose(), [onClose]);

    const handleApplyOrder = useCallback(() => {
        orderDispatch({ type: 'update-any', order: newOrder });
        handleClose();
        onChange(newOrder);
    }, [orderDispatch, newOrder, handleClose, onChange]);

    useEffect(() => {
        newOrderDispatch({ type: 'update-any', order });
    }, [newOrderDispatch, order]);

    return (
        <OrderContext.Provider value={orderReducer}>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Term component="Global" text="Ordenar deuda" />
                </DialogTitle>
                <div className={classes.main}>
                    <OrderForm 
                        screen={screen}
                    />
                </div>
                <DialogActions style={{flex: 1, alignItems:'flex-end', marginBottom: '10px', marginRight: '10px'}}>
                    <Button onClick={handleClose} variant="outlined" color="default">
                        <Term component="Global" text="Cerrar" />
                    </Button>
                    <Button color="primary" variant="outlined" onClick={handleApplyOrder}>
                        <Term component="Global" text="Aplicar orden" />
                    </Button>
                </DialogActions>
            </Dialog>
        </OrderContext.Provider>
    )
};

export default OrderDialog;