import React, {FC, useContext} from "react";

import { RadioGroup, FormControlLabel, Radio, FormControl, withStyles, WithStyles, FormHelperText, Checkbox } from "@material-ui/core";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import duti_styles from "containers/DUTI/styles";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import LabelWithInfo, { TLabelType } from "./label-with-info";
import CheckBox from "./check-box";


export type IRadioButtonOption = { id: string, term: string, label?: string, moreInfo?: {template?: string, size?: TInfoTemplateSize}} // Label para cuando no podemos aplicar un term IG directamente};

interface IRadioButton extends WithStyles {
    options: IRadioButtonOption[]
    fieldTerm: string,
    value: string | undefined
    onChangeValue: (key:string, v:boolean) => void,
    moreInfoGroup?: {
        template: string, size?: TInfoTemplateSize
    },
    row?:boolean
    error?:boolean,
    errorMsg?:string,
    labelAs?: TLabelType | 'notLabel'
    disabled?: boolean
};


const CheckBoxGroup : FC<IRadioButton>= ({classes, options, fieldTerm, value, onChangeValue, moreInfoGroup, row, error, errorMsg, labelAs='field', disabled}) => {
    const terms = useContext(LiteralsContext);


    return (
        <FormControl component="fieldset" >
            { labelAs !== 'notLabel' && <LabelWithInfo type={labelAs} fieldTerm={fieldTerm} moreInfo={moreInfoGroup} />}

            <div className={classes.row}>
            {options.map( o => (
                <div style={{marginRight: 20, display: 'inline-flex', alignItems: 'center'}}>
                    <FormControlLabel 
                        key={o.id} 
                        control={<Checkbox color="primary" checked={o.id === value} onChange={(ev,checked) => { onChangeValue(o.id, checked) }} />} 
                        label={translate('DUTI',o.term, terms).concat(o.label ?? '')} 
                        disabled={disabled}
                    />
                    <LabelWithInfo type={'notLabel'} fieldTerm={""} moreInfo={o.moreInfo} />
                </div>
            ))}
            </div>
            
            {error && <FormHelperText error >{errorMsg}</FormHelperText>}
        </FormControl>
    )
}

export default withLiterals(['DUTI'])(withStyles(duti_styles)(CheckBoxGroup));