import { StyleRules, Theme } from "@material-ui/core";

const styles =  (theme: Theme): StyleRules => ({
    container: {
        height: '100%'
    },
    root: {
        flexGrow: 1,
        overflowY:'auto',
        padding: 16
    },
    // End Process MSG
    containerMSG: {
        border:'solid',
        borderColor: '#ef1111',
        borderRadius: 10, 
        borderWidth: 2, 
        marginTop: 20,
        marginBottom:20,
        display:'inline-flex', 
        alignItems: 'center',
        width: '100%'
    },
    textCancel: {
        fontWeight: 450,
        paddingTop: 30,
        paddingLeft: 10,
        paddingBottom: 30,
        paddingRight: 30,
        
    },
})

export default styles