import React, { FC } from 'react';
import { formatNumber, IFormatNumberOptions } from '../utils/number';
import { Typography } from '@material-ui/core';

interface IProps {
    value: string | number | undefined | null;
    options?: IFormatNumberOptions;
    className?: string;
    component?: any;
}

const TextNumber: FC<IProps> = ({ value, options, className, component }) => {
    const result = formatNumber(value, options);

    return (
        <Typography component={component} className={className}>{result}</Typography>
    );
};

export default TextNumber;