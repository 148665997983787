import { Dispatch, ReducerState, ReducerAction, createContext } from "react";
import FiltersReducer, { IFiltersState } from "./filters.reducer";

type FiltersReducerState = ReducerState<typeof FiltersReducer>;
type FiltersDispatchAction = Dispatch<ReducerAction<typeof FiltersReducer>>;
type FiltersContextType = [FiltersReducerState, FiltersDispatchAction];


const defaultInitialState: IFiltersState = { cambioLista: true };

export const filtersContextInitialState: () => IFiltersState = () => {
    const filtersStateLocalStorage = localStorage.getItem('oficinas.filters');

    return filtersStateLocalStorage
        ? { ...defaultInitialState, ...JSON.parse(filtersStateLocalStorage) }
        : defaultInitialState;
}

export const FiltersContext = createContext<FiltersContextType>([filtersContextInitialState(), (state) => state]);