import { TDTInfoCatastral } from "./types";
import { IDatosBienesInmuebles } from "gateways/duti.interface";



/** Está en la API  

type TXML_DTPrevios = {field: keyof TDTPrevios, casilla:number, xml:boolean}
const XmlTags = {
    step: 'DUSTI_',
    section: 'S_'
}

const getXMLtag = (step: IDUTIstep) => {
    if(step.idPasoPadre){
        return XmlTags.section.concat(step.Orden.toString())
    } else {
        return XmlTags.step.concat(step.Orden.toString())
    }
}


export const objectToXml = ( data: Record<string,any>, tagXML:string ): string => {
    const dataEntries = Object.entries(data) //.map( e => ({ field: e[0], value: e[1]}))
    const xmlRows = dataEntries.map( (r) => {
        if( typeof r[1] === "object"){
            const childXml= objectToXml(r[1], r[0])
            return (childXml)
        } else {
            return (`<${r[0]}>${r[1] ?? ''}</${r[0]}>`)
        }
    })
    const innerXml= xmlRows.join('\n')
    const xml = `
        <${tagXML}>
            ${innerXml}
        </${tagXML}>
    `
    return xml
}

// export const updateXML = (parentTag:TNameStep,childTag: TNameSubStep, prevXml: string, xmlSubStep: string) => {}
*/




// Adpatación de objetos
export const getFormattedInfoCatastral = (refCatastral:string, info: IDatosBienesInmuebles): TDTInfoCatastral => {
    const out: TDTInfoCatastral = {
        datosEconomicos: {
            anyoValorCatastral: info.bienInmueble.datosEconomicos.avc,
            valorCatastral: info.bienInmueble.datosEconomicos.vcat,
            valorCatastralSuelo: info.bienInmueble.datosEconomicos.vcs,
            valorCatastralConstruccion: info.bienInmueble.datosEconomicos.vcc,
            valorReferencia: info.bienInmueble.datosEconomicos.vref,
            superficieConstruida: info.bienInmueble.datosEconomicos.sfc,
            uso: info.bienInmueble.datosEconomicos.luso,
        },
        refCatastral: refCatastral,
        tipoBien: info.bienInmueble.informacionCatastral.codigo,
        direccion: info.bienInmueble.domicilio,
        urlCatastro: info.urlCatastro,
        urlGoogle: info.urlGoogle
    }

    return out
}





