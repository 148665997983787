import { FC, useContext, useMemo } from 'react';

import { Dialog, DialogActions, Grid, Typography, WithStyles, withStyles, Button, Paper, DialogTitle, DialogContent } from '@material-ui/core';
import { TPasoDatosTransmision } from 'containers/DUTI/steps/types';
import duti_styles from 'containers/DUTI/styles';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { AlertsContext } from 'contexts/alerts.context';
import { translate } from 'utils/i18n';

import { extraerBorradorM600, extraerBorradorM081, extraerBorradorM080, extraerBorradorM082 } from 'components/BorradorModelos/extraerModeloFromDUSTI';
import { renderM08x } from 'components/BorradorModelos/modelo_08x';
import { renderM600 } from 'components/BorradorModelos/modelo_600';
import { extraerBorradorJIBI, extraerBorradorJAEAT, extraerBorradorJTASAS } from 'components/Justificantes/extraerJustificanteFromDusti';
import { renderJAEAT } from 'components/Justificantes/justificante_aeat';
import { TJustificanteIBI, renderJIBI } from 'components/Justificantes/justificante_ibi';
import { renderJTASAS } from 'components/Justificantes/justificante_tasas';
import { ITasasTramiteMunicipio } from 'gateways/duti.interface';

interface IProps extends WithStyles {
    show: boolean,
    onClose: () => void,
    justificante:  'ibi' | 'aeat' | 'tasas' | 'dusti'
    datos: TPasoDatosTransmision,
    tasas?: ITasasTramiteMunicipio[]
}

const ModalJustificanteBorrador: FC<IProps> = ({
    classes, show, onClose, datos, justificante,tasas
}) => {
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext)
    // Gateways
    // const ioc = useContext(IoC);
    
    const JIBI = useMemo(() => justificante === 'ibi' && datos ? extraerBorradorJIBI(datos, terms): null,[datos, justificante])
    const JAEAT = useMemo(() => justificante === 'aeat' && datos ? extraerBorradorJAEAT(datos, terms): null,[datos, justificante])
    const JTASAS = useMemo(() => justificante === 'tasas' && datos && tasas ? extraerBorradorJTASAS(datos, terms): null,[datos, justificante])

    // const JDUSTI = useMemo(() => justificante === 'dusti' && datos ? extraerBorradorJDUSTI(datos, terms): null,[datos, justificante])


    return(
        <Dialog open={show} maxWidth='lg' PaperProps={{style: {backgroundColor: '#e0eaf4', flexGrow: 1}}}>
            <DialogTitle>
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    <Typography style={{fontSize: 25, color: "#2196f3", fontWeight: 450, fontFamily: 'Roboto'}}>{translate('DUTI', 'modal_justif_borrador_title', terms).toUpperCase()}</Typography>
                </div>
            </DialogTitle>  
 
                {justificante === 'aeat' && JAEAT && renderJAEAT(JAEAT, terms, 'DUTI')}
                {justificante === 'ibi' && JIBI && renderJIBI(JIBI, terms, 'DUTI')}
                {justificante === 'tasas' && JTASAS && tasas && renderJTASAS(JTASAS, terms, 'DUTI', tasas)}

            <DialogActions style={{margin: 10}} >
                <Button color='primary' variant='contained' onClick={onClose}>{translate('DUTI', 'btn_close', terms).toUpperCase()}</Button>
            </DialogActions>
        </Dialog>
        
    )
}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(ModalJustificanteBorrador));
