import { Dialog } from "@material-ui/core";
import { AlertsContext } from "contexts/alerts.context";
import React, { useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router";

// useBlockNavigation Hook
const useBlockNavigation = (shouldBlock: boolean, msg: string, varinat: 'success' | 'warning' | 'error' | 'info',btnConfirmLabel?:string, btnCloseLabel?: string) => {
    const history = useHistory() //<{notBlockNav: boolean}>();
    const [,alertsDispatch]= useContext(AlertsContext)
    
    useEffect(() => {
        // Bloquear la navegación si shouldBlock es true
        const unblock = history.block((location) => {
          if (shouldBlock) {  
            // Lógica de bloqueo, mostrar una confirmación al usuario antes de cambiar de ruta.
            // return false para bloquear la navegación
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: msg,
                    variant: varinat,
                    hasCustomAction: true,
                    defaultCloseActionTemplateName: btnCloseLabel ??  "no".toUpperCase(),
                    actionTemplateName: btnConfirmLabel ?? "si".toUpperCase(),
                    handleCustomAction: () => {
                        //  Hay confirmación => desbloqueamos + redirigir manualmente
                        alertsDispatch({ type: 'hide-alert' })
                        unblock();
                        history.push(location.pathname);
                    }
                }
              })

              return false
          } 

          return undefined;
        });
  
      // Limpiar el bloqueo cuando el componente se desmonte o shouldBlock cambie
      return () => {
        unblock();
      };

    }, [shouldBlock, history]);
  };
  
  export default useBlockNavigation;
