import { StyleRules } from "@material-ui/core";

const styles = (): StyleRules => ({
    white: {
        color: 'white',
    },
    margin: {
        margin: '0px 10px'
    }
});

export default styles;