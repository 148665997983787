import React, { useState, useContext, useMemo } from 'react';
import { Button, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Snackbar, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Term from 'components/term';
import { IModelo } from 'gateways/tramites.interfaces';
import { ICajaProps } from './caja.props';
import { makeStyles } from '@material-ui/styles';
import { Icon } from '@mdi/react';
import { mdiFilePdfBox, mdiTrashCan } from '@mdi/js';
import { TramitesGateway } from 'gateways/tramites.gateway';
import IoC from 'contexts/ioc.context';
import usePage from 'hooks/page.hook';
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import ModelosAsociadosDialog from './modelosAsociados-modal';
import moment from 'moment';
import { AlertsContext } from 'contexts/alerts.context';
import env from 'env';
import { download } from 'utils/download';

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
    root: {
        marginLeft: 45,
    },
    rootDusti: {
        marginLeft: 0,
    },
    buttonList: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    button: {
        width: 200,
        marginLeft: 15,
    },
    linkToModel: {
        textDecoration: 'underline',
        fontWeight: 'bold',
    },
});

const altaRapidaModelos: string[] = ['600'];

const ModelosAsociados: React.FC<ICajaProps> = ({ onChange, detalle, tipo }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [downloading, setDownloading] = useState<string[]>([]);
    const ioc = useContext(IoC);
    const gateway = ioc.get(TramitesGateway) as TramitesGateway;
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const hayModelos = useMemo(() => detalle.modelos !== undefined && detalle.modelos !== null && detalle.modelos.length !== 0, [detalle]);
    const componente = useMemo(() => tipo.componentes.find(x => x.tipo.nombre === 'vinculo_modelos'), [tipo]);
    const modelosVinculables = useMemo(() => [
        ...componente?.modelosObligatorios?.flatMap(x => x.modelos?.split(',')) ?? [],
        ...componente?.modelosOpcionales?.flatMap(x => x.modelos?.split(',')) ?? []
    ], [componente]);

    const isDUSTI = useMemo(() => detalle.isDUSTI ,[detalle])

    console.log('modelos vinculables', {
        modelosVinculables,
        componente,
    });

    const handleDesvincularModelo = async (localizador: string) => {
        if (window.confirm(translate('Tramites', 'PreguntaDesvincularModelo', terms)) && hayModelos) {
            try {
                await gateway.desvincularModelo(detalle.idExpediente, localizador);
                onChange({ ...detalle, modelos: detalle.modelos.filter((m: IModelo) => m.localizador !== localizador) });
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Tramites', 'Se ha desvinculado correctamente el modelo', terms),
                        variant: 'success',
                    }
                });
            } catch (error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tramites', error.message, terms),
                        variant: 'error',
                    }
                });
            }
        }
    };

    const handleChange = (modelos: IModelo[]) => {
        onChange({ ...detalle, modelos: [...detalle.modelos, ...modelos] });
    }

    const handleBuscarModelo = () => {
        setOpen(true);
    }

    const handleOpenModelo = async (idRef: string) => {
        const args: any = {};
        args.PReferencia = idRef;

        setDownloading([...downloading, idRef]);
        console.log(`downloading true => ${JSON.stringify(downloading)}`)
        const blob = await gateway.generarDocumentoModelo(idRef);
        download(blob,
            alertsDispatch,
            translate('Tramites', 'Ha ocurrido un error al descargar el modelo', terms),
            translate('Global', 'BloqueoPantalla', terms),
            `modelo_${idRef}.pdf`);
        const index = downloading.indexOf(idRef);
        setDownloading([...downloading].splice(index, 1));
        console.log(`downloading false => ${JSON.stringify(downloading)}`)
    }

    const handleCrearNuevoModelo = (idTipoModelo: string) => {
        return () => {
            if (detalle.estado.id === 'INI') {
                window.open(`${env.ATIB_URL}/TA/modelos/Modelo.aspx?m=${idTipoModelo}&exp=${detalle.idExpediente}`, '_blank');
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpenAlert = () => {
        setOpenAlert(true);
    }

    const handleCloseAlert = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    }

    return (
        <div className={ isDUSTI ? classes.rootDusti : classes.root}>
            {
                hayModelos
                    ?
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"><Term component="Tramites" text="Localizador" /></TableCell>
                                <TableCell align="left"><Term component="Tramites" text="NifSujPasivo" /> </TableCell>
                                <TableCell align="left"><Term component="Tramites" text="FechaCreacionModelo" /></TableCell>
                                <TableCell align="left"><Term component="Tramites" text="Importe" /></TableCell>
                                <TableCell align="left"><Term component="Tramites" text="Estado" /></TableCell>
                                {
                                    detalle.estado.id !== 'PRE'
                                        ?
                                        <TableCell align="left" />
                                        :
                                        null
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {detalle.modelos.map((modelo: IModelo, key: number) => (
                                <TableRow key={key + '_' + modelo.localizador}>
                                    <TableCell align="left">{modelo.localizador}</TableCell>
                                    <TableCell align="left">{modelo.nifSujetoPasivo}</TableCell>
                                    <TableCell align="left">{moment(modelo.fechaCreacion || new Date()).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell align="left">{modelo.importeAIngresar.toLocaleString()}</TableCell>
                                    <TableCell align="left"><Term component="Tramites" text={translate('Tramites', modelo.idEstado !== undefined ? 'Estado_' + modelo.idEstado : 'Estado_', terms)} /></TableCell>
                                    <TableCell align="center">
                                        {!isDUSTI ?
                                            <IconButton
                                                aria-label="print"
                                                onClick={modelo.idOrigenMod === 1 ? () => handleOpenModelo(modelo.idReferencia) : handleOpenAlert}>
                                                {
                                                    downloading.includes(modelo.idReferencia) ?
                                                        <CircularProgress size={20} />
                                                        :
                                                        <Icon path={mdiFilePdfBox}
                                                            size={1}
                                                            color='red'
                                                        />
                                                }
                                            </IconButton>
                                        : null 
                                        }
                                        {detalle.estado.id !== 'PRE' && !isDUSTI &&
                                            <IconButton aria-label="delete" onClick={() => handleDesvincularModelo(modelo.localizador)}>
                                                <Icon path={mdiTrashCan}
                                                    size={1}
                                                    color='red'
                                                />
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    <Term component='Tramites' text='NoModelosAsociados' />
            }
            {
                detalle.estado.id === 'INI' && !isDUSTI &&
                <div className={classes.buttonList}>
                    {
                        modelosVinculables.filter(x => altaRapidaModelos.includes(x)).map(x => (
                            <Button variant="outlined" color="primary" className={classes.button} onClick={handleCrearNuevoModelo(x)} size="small">
                                <Term component='Tramites' text={`crearNuevo${x}`} />
                            </Button>
                        ))
                    }

                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleBuscarModelo} size="small">
                        <Term component='Tramites' text='buscarModelos' />
                    </Button>
                </div>
            }
            <Snackbar open={openAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="warning">
                    <Term component='Tramites' text='ModNoGeneradoAtib' />
                </Alert>
            </Snackbar>

            <ModelosAsociadosDialog
                idExpediente={detalle.idExpediente}
                open={open}
                onChange={handleChange}
                onClose={handleClose}
            />
        </div>
    );
}

export default ModelosAsociados;