import { FC, useContext, useMemo } from "react";

import { Grid, StyleRules, Theme, WithStyles, withStyles } from "@material-ui/core";
import { LiteralsContext, withLiterals } from "containers/shared/literals";

import { TCausaValor, TCausaValor2, TDeduccion, TTipoDocGenera } from "../types";
import GenericInputText from "components/generic-input-text";
import GenericCheckbox from "components/generic-checkbox";




interface EsCausaValorProps extends WithStyles<typeof styles> {
    value: TCausaValor | TCausaValor2
    onChangeValue: (v:TCausaValor | TCausaValor2, err:boolean)=> void
    disabled: boolean
};

const EsCausaValor: FC<EsCausaValorProps> = ({classes, value , onChangeValue, disabled }) => {
    const terms =useContext(LiteralsContext);

    const CHECKS= useMemo(() => {
        const keys = Object.keys(value)
        const asOption= keys.filter(k => k!=='otros_descripcion' && k!== 'otros')
        asOption.push('otros') // Garantizamso otros como última oipcion
        return asOption //.map(opt => ({ id: opt, term: opt}))
    },[])

    return (
        <Grid container direction="column" alignItems="flex-start" justify="space-between">
            {CHECKS.map((key)=> {
                return(
                    <div style={{flexGrow: 1, width: '100%'}} className={ key === 'otros' ?  [classes.minHeight, classes.otrosHeight].join(' ') : [classes.row, classes.minHeight].join(' ')}>
                        <GenericCheckbox style={{marginTop: 12}}
                            key={key}
                            disabled={disabled}
                            componentIG="AbonoNacimiento"
                            labelTerm={key}
                            value={ value[key as keyof (typeof value)]}
                            onChangeValue= {(v:boolean) => {
                                const newV={
                                    ...value,
                                    [key]: v,
                                    otros_descripcion: (key==='otros' && v===false) ? undefined : value.otros_descripcion
                                }
                                if(Object.values(newV).includes(true)){
          
                                    const err = newV.otros && (newV.otros_descripcion === '' || !newV.otros_descripcion)
                                    onChangeValue(newV,err)
                                } else {
                                    onChangeValue(newV,true)
                                }
                            }}
                        />
                        {key === 'otros' && value[key as keyof (typeof value)] && 
                            <div className={classes.inputInLine}>
                            <GenericInputText required
                                variant='outlined'
                                componentIG="AbonoNacimiento"
                                labelTerm={'otros_descripcion'}
                                value={ value['otros_descripcion']}
                                onChangeValue= {(v:string| undefined, err: boolean) => {
                                    const newV={...value, otros_descripcion: v}
                                    onChangeValue(newV,err)
                                }}
                                fullWidth
                                multiline
                                rows={3}
                                rowsMax={5}
                            />
                            </div>
                        }
                    </div>
                   

                )

            })}
            

            
        </Grid>

    )
};

const styles = (theme: Theme): StyleRules => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    minHeight: {
        minHeight: 42
    },
    inputInLine: {
        marginLeft: 35,
        marginRight: 35,

    },
    otrosHeight: {
        minHeight:72
    }
});
export default withLiterals(['Global', 'Tramites','AbonoNacimiento','GenericComponents' ])(withStyles(styles)(EsCausaValor));