import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { ISignature } from "./afirma.interfaces";

@inject('axios')
export class AfirmaGateway {
    constructor(
        private axios: AxiosInstance
    ) {

    }

    public async getIdOrdenFirma(idDocumento: number): Promise<number> {        
        const response = await this.axios.get('afirma/getIdOrdenFirma/' + idDocumento);
        
        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async saveSignature(signature: ISignature): Promise<number> {        
        const response = await this.axios.post('afirma/saveSignature', signature);
        
        if (response.status !== 201) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }    
}