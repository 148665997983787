import React, { FC, useContext, useState } from "react";
import { Button, Card, IconButton, Paper, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { mdiChevronDown, mdiChevronRight } from "@mdi/js";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import Icon from "@mdi/react";
import { translate } from "utils/i18n";
import DeleteIcon from "@material-ui/icons/Delete";


const styles = (theme: Theme): StyleRules => ({

    icon: {
        marginLeft: 10,
        marginRight: 5,
        padding: 1
    },
    pac_detail:{
        display:'flex',
        flexDirection: 'column',
        margin: 15,
    },
    pac_detail_result:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    pcBlueRim: {
        display: "flex",
        flexDirection: "column",
        boxShadow: '2px 1px 2px rgba(0, 0, 0, .1)',
        borderRadius: 5,
        marginBottom: 10,
        
    },
    text: {
        padding: 5
    },
    bold: {
        fontWeight: "bold",
    },
    pcBlueRimTitle: {
        cursor: "pointer",
        justifyContent: "space-between",
        display: "flex",
    },
    deleteExpand: {

    },
    delete: {

    }
});

interface IProps extends WithStyles {
    renderHeader: () => JSX.Element,
    renderContent: () => JSX.Element,
    withDelete?: boolean,
    onDelete?: () => void
    color?: string,
    initialExpand?: boolean
}



const ExpandPanel: FC<IProps> = ({classes, renderHeader,renderContent, color ='#006993', withDelete=false, onDelete , initialExpand=false}) => {
    const terms = useContext(LiteralsContext);
    const [expand, setExpand] = useState(initialExpand);
    const toggleExpand =() => {
        setExpand(curr => !curr)
    }
    return (
        <div className={classes.pcBlueRim} style={{
            border: `1px solid ${color}`, 
        }}>
            <div
                onClick={toggleExpand}
                style= {{
                    height: 45,
                    backgroundColor: expand ? color: 'white',
                    borderRadius: expand ? undefined: 5,
                    color: expand ? 'white' : color ?? 'black',
                    display: 'flex', flexDirection:'row', alignItems:'center', justifyItems: 'space-between'
                }}
                className={[classes.text, classes.pcBlueRimTitle].join(' ')}
            >
                <div style={{ display: 'flex', flexGrow: 1}}>
                    {renderHeader()}
                </div>
                <div >
                    {withDelete && onDelete && 
                        <Button variant="text"
                            endIcon={<DeleteIcon/>}
                            style={{
                                color: expand ? 'white': color,
                                borderColor: expand ? 'white': color,
                                marginRight: 20
                            }}
                            onClick={(e) => { 
                                e.preventDefault(); 
                                onDelete()
                            }}
                        >
                        {translate('DUTI','btn_delete',terms)}
                    </Button>}
                    {expand 
                        ?  <Icon path={mdiChevronDown} title="expandir" size={1} className={classes.icon} color={'white'}/>
                        :  <Icon path={mdiChevronRight}title="contraer" size={1} className={classes.icon} color={color ?? 'black'}/>
                    }
                </div>
                

                                 
                
                
            </div>
            { expand && 
                <div className={classes.pac_detail}>
                   {renderContent()}
                </div>
            
            }
            
        </div>
    )
}
export default withLiterals(["DUTI","GLOBAL" ])(withStyles(styles)(ExpandPanel))
