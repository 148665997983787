import { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { TextField, CircularProgress, useMediaQuery, useTheme, DialogTitle, makeStyles, DialogActions, Grid } from '@material-ui/core';
import Term from 'components/term';
import usePage from 'hooks/page.hook';
import IoC from 'contexts/ioc.context';
import { AlertsContext } from 'contexts/alerts.context';
import { TramitesGateway } from 'gateways/tramites.gateway';
import { InputChangeHandler } from 'utils/events';
import { IModelo } from 'gateways/tramites.interfaces';
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

const useStyles = makeStyles(() => ({
    input: {
        width: '100%'
    }
}));

interface IProps {
    open: boolean;
    onClose: () => void;
    onChange: (modelos: IModelo[]) => void;
    idExpediente: number;
}

function ModelosAsociadosDialog(props: IProps) {
    const { onClose, onChange, open, idExpediente } = props;
    const ioc = useContext(IoC);
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [loading, setLoading] = useState(false);
    const [localizador, setLocalizador] = useState('');
    const [fecha, setFecha] = useState<Date | null>(new Date());
    const [importe, setImporte] = useState<number | null>(null);
    const [nif, setNIF] = useState('');

    const [validationMessageLocalizador, setValidationLocalizador] = useState<string | null>(null);
    const [validationMessageImporte, setValidationMessageImporte] = useState<string | null>(null);
    const [validationMessageFecha, setValidationMessageFecha] = useState<string | null>(null);
    const [validationMessageNif, setValidationMessageNif] = useState<string | null>(null);

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const gateway = ioc.get(TramitesGateway) as TramitesGateway;


    const handleClose = () => onClose();


    const handleLocalizador: InputChangeHandler = (event) => setLocalizador(event.target.value);
    const handleFecha = (date: Date | null) => setFecha(date);
    const handleImporte: InputChangeHandler = (event) => setImporte(parseFloat(event.target.value.replace(/\./gmi, '').replace(',', '.')));
    const handleNif: InputChangeHandler = (event) => setNIF(event.target.value);

    const validateForm = () => {
        let localizadorText: string | null = null;
        let fechaText: string | null = null;
        let importeText: string | null = null;
        let nifText: string | null = null;

        if (localizador === '') {
            localizadorText = translate('Tramites', 'Este campo no puede estar vacío', terms);
        }
        if (fecha === null) {
            fechaText = translate('Tramites', 'Este campo no puede estar vacío', terms);
        }
        if (importe === null) {
            importeText = translate('Tramites', 'Este campo no puede estar vacío', terms);
        }
        if (nif === '') {
            nifText = translate('Tramites', 'Este campo no puede estar vacío', terms);
        }

        setValidationLocalizador(localizadorText);
        setValidationMessageImporte(importeText);
        setValidationMessageFecha(fechaText);
        setValidationMessageNif(nifText);

        return (!localizadorText && !fechaText && !importeText && !fechaText && !nifText);
    }

    const handleSearch = async () => {
        setLoading(true);
        if (validateForm()) {
            try {
                const modelos = await gateway.anyadirModelo(idExpediente, localizador, moment(fecha || new Date()).format(), nif, importe || 0);

                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Tramites', 'Se ha encontrado su documento', terms),
                        variant: 'success',
                    }
                });
                onChange(modelos);
                onClose();
            } catch (error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tramites', (error as any).message, terms),
                        variant: 'error',
                    }
                });
            }
        }
        setLoading(false);
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Term component="Tramites" text='BuscarModelosAsociar' />
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-localizador-input"
                                label={<Term component="Tramites" text="Localizador" />}
                                className={classes.input}
                                error={validationMessageLocalizador !== null}
                                helperText={validationMessageLocalizador}
                                value={localizador}
                                onChange={handleLocalizador}
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                margin="dense"
                                format="dd/MM/yyyy"
                                error={validationMessageFecha !== null}
                                className={classes.input}
                                id="datepicker-fecha"
                                label={<Term component="Tramites" text="FechaCreacionModelo" />}
                                value={fecha}
                                onChange={handleFecha}
                                helperText={validationMessageFecha}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-nif-input"
                                className={classes.input}
                                error={validationMessageNif !== null}
                                helperText={validationMessageNif}
                                label={<Term component="Tramites" text="NifSujPasivo" />}
                                value={nif}
                                onChange={handleNif}
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CurrencyTextField
                                label={<Term component="Tramites" text="Importe" />}
                                margin="dense"
                                variant="standard"
                                className={classes.input}
                                error={validationMessageImporte !== null}
                                decimalCharacter=","
                                digitGroupSeparator="."
                                currencySymbol="€"
                                outputFormat="string"
                                textAlign="left"
                                onChange={handleImporte}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button color="primary" variant="outlined" onClick={handleSearch} disabled={loading}>
                            <Term component="Tramites" text="Buscar" />
                            {
                                loading ?
                                    <CircularProgress size={20} />
                                    :
                                    null
                            }
                        </Button>
                    </div>

                </DialogActions>
            </Dialog>
        </div>
    );
}


export default ModelosAsociadosDialog;