import { makeStyles } from "@material-ui/core";

const useCommonStyles = makeStyles({
    container: {
        height: '100%'
    },
    root: {
        flexGrow: 1,
        overflowY:'auto',
        padding: 16,
        maxHeight: 'calc(100% - 70px)', // 69px buttons Footer
    },
    buttons: {
        height: 69
    },
    // End Process MSG
    containerMSG: {
        border:'solid',
        borderColor: '#ef1111',
        borderRadius: 10, 
        borderWidth: 2, 
        marginTop: 20,
        marginBottom:20,
        display:'inline-flex', 
        alignItems: 'center',
        width: '100%'
    },
    textCancel: {
        fontWeight: 450,
        paddingTop: 30,
        paddingLeft: 10,
        paddingBottom: 30,
        paddingRight: 30,
        
    },
});

export default useCommonStyles;