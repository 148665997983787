import { FC } from 'react';
import { Divider, StyleRules, Table, TableBody, TableCell, TableHead, TableRow, withStyles, WithStyles } from '@material-ui/core';
import getAllKeys from 'utils/wizard';
import { IResult } from 'gateways/importer.interfaces';
import Icon from 'atomic/atoms/icon';
import LinearWithValueLabel from './linear-value-label';
import Term from './term';
import moment from 'moment';

const styles = (): StyleRules => ({
    header: {
        backgroundColor: '#dcdcdc'
    },
    nowrap: {
        whiteSpace: 'nowrap',
    },
    bold: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    },
    identificacionDate: {
        fontWeight: "bold",
        marginBottom: 20,
        textAlign: "right"
    }
});

interface IProps extends WithStyles<typeof styles> {
    data: IResult[],    
    numElementsImported: number,
}

const TableWizardResult: FC<IProps> = ({ data, numElementsImported, classes }) => {
    const initialValue = parseFloat((100 / data.length).toFixed(2));
    const getRowValue = (item: IResult, index: number): any => {
        return (
            <TableRow key={`columnWizard${index}`}>
                <TableCell className={classes.nowrap}>
                    {item.id}
                </TableCell>
                <TableCell className={classes.nowrap}>
                    {item.boletin}
                </TableCell>
                <TableCell className={classes.nowrap}>
                    {item.refExpediente}
                </TableCell>
                <TableCell className={classes.nowrap}>
                    {item.matricula}
                </TableCell>
                <TableCell className={classes.nowrap}>
                    {item.nif}
                </TableCell>
                <TableCell className={classes.nowrap}>
                    {item.nombre}
                </TableCell>
                <TableCell className={classes.nowrap}>
                    {item.apellidos}
                </TableCell>
				<TableCell>
                    { item.result != null &&
                    <Icon name={item.result ? 'check-ok' : 'check-error'} size={1} color={item.result ? '#004f82' : "red"} />
                    }
                </TableCell>
            </TableRow>
        );
    }

    return (
        <>
            <div className={classes.identificacionDate}>{'FECHA IDENTIFICACION:'} {moment().format('DD/MM/YYYY HH:mm:ss')}</div>
            <LinearWithValueLabel initialValue={initialValue} numElements={numElementsImported} />        
            <Divider variant="inset" />
            <Table>
                <TableHead>
                    <TableRow className={classes.header}>
                        {
                            data && getAllKeys(data as any[]).map((key, index) => (
                                <TableCell key={`${key}-${index}`} className={classes.bold}>
                                    <Term component={"Wizard"} text={key} />
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data && data.map((item: any, index: any) => (getRowValue(item, index)))
                    }
                </TableBody>
            </Table>
        </>
    );
};

export default withStyles(styles)(TableWizardResult);