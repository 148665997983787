import React, { FC, useContext } from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import ProtectedRoute from "components/protected-route";
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import Contenido from 'components/contenido';
import { translate } from 'utils/i18n';
import { mdiShieldAccount } from "@mdi/js";

import ConfirmarMail from "./confirmar.mail";
import Perfil from "./perfil";
import PerfilHome from "./perfil-home";
import AtPersonalizadaConsultas from './at_personalizada.consultas';

import Notificaciones from "../notificaciones/notificaciones";
import DiasCortesia from "../notificaciones/dias.cortesia";
import ActivarServicio from "../notificaciones/activar.servicio";
import ExpedientesIndex from '../tramites/expedientes/index';
import Representaciones from '../tramites/representaciones';




const PerfilIndex: FC<RouteComponentProps> = ({ match }) => {
    const terms = useContext(LiteralsContext)
    
    return (
        <>
            {/* Área Personal */}
            <Route exact path={`${match.path}`} component={PerfilHome} />
            <ProtectedRoute exact path={`${match.path}/mi-perfil`} component={Perfil} idModulos={[18]} />
            <ProtectedRoute exact path={`${match.path}/confirmar-mail`} component={ConfirmarMail} idModulos={[18]} />
            {/* Notificaciones */}
            <ProtectedRoute exact path={`${match.path}/notificaciones/mis-notificaciones`} component={Notificaciones} accessLevel={2} idModulos={[6]} />
            <ProtectedRoute exact path={`${match.path}/notificaciones/dias-cortesia`} component={DiasCortesia} accessLevel={2} idModulos={[7]} />
            <ProtectedRoute exact path={`${match.path}/notificaciones/activar-servicio`} component={ActivarServicio} accessLevel={2} idModulos={[6]} />
            <Route exact path={`${match.path}/notificaciones/alta-servicio`} render={(props) => <Contenido landingName='alta_servicio_notificaciones' {...props}/>} />
            <Route exact path={`${match.path}/contenidos`} render={(props) => <Contenido landingName='mas_info_notificaciones_electronicas' {...props} pageTitle={translate('Global', 'AreaPersonalNotificaciones', terms)} pageSubtitle={translate('Global', 'AreaPersonalNotificacionesDescripcion', terms)} icon={mdiShieldAccount}/>} />
            {/* Representaciones */}
            <ProtectedRoute path={`${match.path}/representaciones`} component={Representaciones} accessLevel={1} idModulos={[10]} />
            <ProtectedRoute path={`${match.path}/presentaciones`} component={ExpedientesIndex} accessLevel={2} idModulos={[14]} />        
            {/**Atención personalizada */}
            <Route exact path={`${match.path}/atencion-personalizada/consultas`} component={AtPersonalizadaConsultas} />
        </>
    );
}

export default withLiterals(['Global','Perfil'])(withRouter(PerfilIndex));
