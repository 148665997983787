import { inject } from "aurelia-dependency-injection";
import Axios, { AxiosInstance, CancelTokenSource } from "axios";
import { ICategory } from "components/categories";
import { IFindAllQuery, IFilterData } from "./recibo.interfaces";
import { ITributoAutonomico } from "./tributos.interfaces";

@inject('axios')
export class TributosAutonomicosGateway {
    private cts: CancelTokenSource | null = null;
    constructor(
        private axios: AxiosInstance
    ) {

    }

    public async findAll(query?: IFindAllQuery): Promise<ITributoAutonomico[]> {
        try {
            const response = await this.axios.get('autonomicos', {
                params: {
                    ...query,
                    expires: Date.now(),
                }
            });

            if (response.status !== 200) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        } catch (e) {
            return [];
        }
    }

    public async getCount(query?: IFindAllQuery): Promise<number> {
        try {
            const response = await this.axios.get('autonomicos/count', {
                params: {
                    ...query,
                    expires: Date.now(),
                }
            });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        } catch (e) {
            return 0;
        }
    }

    public async getFilterData(query?: IFindAllQuery): Promise<IFilterData> {
        try {
            const response = await this.axios.get('autonomicos/filters', {
                params: {
                    ...query,
                    expires: Date.now(),
                }
            });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return {
                ejercicios: response.data.ejercicios.map((x: any) => ({ value: x, label: x })),
            };
        } catch (e) {
            return {};
        }
    }    

    public async exportarTributos(query?: IFindAllQuery): Promise<{ fileName: string, blob: Blob }> {
        const response = await this.axios.get('autonomicos/exportarTributos', {
            params: {
                ...query,
                expires: Date.now(),
            },
            responseType: 'blob',
        });
        
        const disposition = response.headers['content-disposition'];
        // eslint-disable-next-line no-useless-escape
        const match = /^filename=\"?(.+?)\"?$/gmi.exec(disposition);
        const fileName = match ? match[1] : 'exportacion.xlsx';
        return {
            fileName,
            blob: response.data
        };
    }

    public async print(referencia: string, tipoModelo: string, localizador: string): Promise<Blob> {
        try {
            //console.log(`CALL -- autonomicos/print?tipoModelo=${tipoModelo}&localizador=${localizador}&referencia=${referencia}`)
            const response = await this.axios.get(`autonomicos/print?tipoModelo=${tipoModelo}&localizador=${localizador}&referencia=${referencia}`, {
                responseType: 'blob',
            });

            if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            }

            return response.data;
        } catch (error) {
            //console.log('error',error)
            return {} as Blob
        }
    }

    public async getAllCategories(): Promise<ICategory[]> {
        const response = await this.axios.get('autonomicos/categories');

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getCategory(categoryId: number) {
        const response = await this.axios.get(`autonomicos/category/${categoryId}`);

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getTributos(categoryId?: number) {
        let path = 'autonomicos/listado';
        if (categoryId) {
            path = path + '?categoryId=' + categoryId;
        }
        const response = await this.axios.get(path);

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async search(texto: string, categoryId?: number) {
        if (texto === '') {
            return null;
        }
        this.cts?.cancel();
        this.cts = Axios.CancelToken.source();
        const params = {
            text: texto,
            categoryId: categoryId
        }
        const response = await this.axios.get('autonomicos/search', {
            cancelToken: this.cts.token,
            params: params,
        });

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        if (response.data.length === 0)
            return [];

        return response.data;
    }
}