import React, { useContext, useEffect } from "react";
import usePage from "./page.hook";
import { LiteralsContext } from "containers/shared/literals";

interface IProps {
    title: string | JSX.Element;
    icon: string;
    menu: boolean;
    right?: React.ReactNode;
    moreInfoTemplate?: string;
}

const useSetHeader = (props: IProps) => {
    const { title, icon, menu, moreInfoTemplate, right } = props;
    const [, pageDispatcher] = usePage();
    const terms = useContext(LiteralsContext);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                title: title,
                icon: icon,
                moreInfoTemplate: moreInfoTemplate,
                right: right,
            },
            menu: menu,
        });
    }, [pageDispatcher, terms]);
}

export default useSetHeader;
