// LIBRARY IMPORTS
import { useContext, useEffect, useMemo, useState } from "react";
// LOCAL IMPORTS
import { IDomiciliacionCompFVol, ITributo } from "gateways/tributos.interfaces";
import { TributosGateway } from "gateways/tributos.gateway";
import IoC from "contexts/ioc.context";

interface IProps {
    diaSeleccionado: string | undefined;
    tributos: ITributo[];
}

const useLoadDomiciliacionesIncompatibles = (props: IProps) => {
    const { tributos, diaSeleccionado } = props;
    // Services 
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(TributosGateway) as TributosGateway, [ioc]);
    // States
    const [loadingDomiciliacionesCompatibles, setLoadingDomiciliacionesCompatibles] = useState<boolean>(false);
    const [domiciliacionesCompatibles, setDomiciliacionesCompatibles] = useState<IDomiciliacionCompFVol[]>([]);

    useEffect(() => {
        (async () => {
            setLoadingDomiciliacionesCompatibles(true);
            if (!tributos[0].idRecibo || !diaSeleccionado) {
                setLoadingDomiciliacionesCompatibles(false);
                return;
            }

            const domiciliacionesCompatiblesDiaVoluntaria = await gateway.findTributosPorFechaVoluntaria(tributos[0].idRecibo, diaSeleccionado);
            if (!domiciliacionesCompatiblesDiaVoluntaria) {
                setLoadingDomiciliacionesCompatibles(false);
                return;
            }

            setDomiciliacionesCompatibles(domiciliacionesCompatiblesDiaVoluntaria);
            setLoadingDomiciliacionesCompatibles(false);
        })();
    }, [diaSeleccionado, tributos[0].idRecibo]);

    return {
        loadingDomiciliacionesCompatibles,
        domiciliacionesCompatibles
    }
}

export default useLoadDomiciliacionesIncompatibles;
