import React, { FC } from 'react';
import { LinearProgressProps, Box, LinearProgress, Typography } from '@material-ui/core';

interface IProps {
    initialValue: number;
    numElements: number;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Box style={{ width: '100%', marginRight: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box style={{ minWidth: 35 }}>
        <Typography variant="body2" color="secondary">{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}

const LinearWithValueLabel: FC<IProps> = ({ initialValue, numElements }) => {    
    return (
        <Box style={{ width: '100%' }}>
            <LinearProgressWithLabel value={(initialValue * numElements) > 100 ? 100 : parseFloat((initialValue * numElements).toFixed(1))} />
        </Box>
    );
};

export default LinearWithValueLabel;
