// LIBRARY IMPORTS
import { FC } from 'react';
import { makeStyles, useTheme, DialogActions, Button, DialogContentText } from '@material-ui/core';
// LOCAL IMPORTS
import Term from 'components/term';
import { GenericModal } from 'components/generic-modal';
// STYLES IMPORTS
import styles from './styles';

const useStyles = makeStyles(styles);

interface ICheckConservarDiaVoluntaria {
    open: boolean;
    
    onClose: (userSelection: boolean) => void;
    onCloseModal: () => void;
}

const DialogCheckConservarDiaVoluntaria: FC<ICheckConservarDiaVoluntaria> = (props) => {
    const { open, onClose, onCloseModal } = props;
    // HOOKS
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <GenericModal
            open={open}
            title="Mantener fecha voluntaria"
            componentTitleTranslation='Domiciliaciones'
        >
            <DialogContentText className={[classes.padding, classes.marginDefault].join(' ')} >
                <Term component="Domiciliaciones" text="Información mantener fecha voluntaria" />
            </DialogContentText>

            <DialogActions className={[classes.flexRow, classes.justifySpaceBetween, classes.padding].join(' ')}>
                <Button
                    onClick={onCloseModal}
                    color="default"
                    variant="outlined"
                >
                    <Term component="Global" text="Cancelar" />
                </Button>

                <div className={[classes.flexRow, classes.gap].join(' ')}>
                    <Button
                        onClick={() => onClose(false)}
                        color="default"
                        variant="outlined"
                    >
                        <Term component="Global" text="No" />
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => onClose(true)}
                        color="primary"
                    >
                        <Term component="Global" text="Si" />
                    </Button>
                </div>
            </DialogActions>
        </GenericModal>
    );
}

export { DialogCheckConservarDiaVoluntaria };