import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { translate } from "utils/i18n"
type TValue= {term: string, value: any, casilla: number| null, fullWidth? :boolean }
export  type TValuesMxx = TValue[]
export const renderField = (
    field: TValue, 
    modelo:string, 
    terms:any, 
    componentTerms: string
) => {
    return (
        <div
            style={{
                display: 'flex', flexDirection:'row',
                flexWrap: field.fullWidth === true ? 'wrap': undefined,
                minWidth: field.fullWidth === true ? '100%': 220,
                marginTop :5, border:'solid', borderColor: '#8fbae1', borderWidth: '0px 1px 1px 0px', backgroundColor: 'white' 
            }}
        >
            <Typography style={{marginLeft:5,marginRight: 5, color: '#8fbae1'}}>
                {
                    translate(componentTerms, (field.term.includes('MXX_') || field.term.includes('M08x_')) 
                        ? field.term 
                        : `${modelo}_${field.term}` ,terms
                    ).concat(field.casilla ?` [${field.casilla}]`: ``)
                } 
            </Typography>
            <Typography style={{marginLeft:5,marginRight:25}}>{field.value}</Typography>
        </div>
    )
}


export const renderApartado = (
    data: TValuesMxx | null, 
    modelo:string, 
    terms:any, 
    componentTerms: string
) => {
    return (
        data?.map( field => (   
            renderField(field, modelo, terms, componentTerms)
        ))
    )
}

export const renderTwoColumns = (
    data: TValuesMxx, 
    modelo:string, 
    terms:any, 
    componentTerms: string,
    fieldGapGrow: boolean
) => {
    let current : TValuesMxx = []
    let result: TValuesMxx[] = []

    data.forEach((element) => {
        if (current.length === 3) {
            result.push(current);
            current.push({...element, fullWidth: true});
            current = [];
        } else {
            current.push({...element, fullWidth: true}); 
        }
    });
    
    return (
        <div style= {{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            {data.map( item => {
                return (
                    <Grid item xs={6} style={{display:'flex', height: fieldGapGrow ? undefined: 30 }}>
                        {renderField({...item, fullWidth:true}, modelo, terms, componentTerms)}
                    </Grid>

                )
            })}
        </div>
    )

}

export const renderThreeColumns = (
    data: TValuesMxx, 
    modelo:string, 
    terms:any, 
    componentTerms: string,
    fieldGapGrow: boolean
) => {
    let current : TValuesMxx = []
    let result: TValuesMxx[] = []

    data.forEach((element) => {
        if (current.length === 3) {
            result.push(current);
            current.push({...element, fullWidth: true});
            current = [];
        } else {
            current.push({...element, fullWidth: true}); 
        }
    });
    
    return (
        <div style= {{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            {data.map( item => {
                return (
                    <Grid item xs={4} style={{display:'flex', height: fieldGapGrow ? undefined: 30 }}>
                        {renderField({...item, fullWidth:true}, modelo, terms, componentTerms)}
                    </Grid>

                )
            })}
        </div>
    )

}


