export enum TipoEstablecimiento {
    AG = 'Agroturismo',
    AJ = 'Albergue',
    A = 'Apartamento',
    CT = 'Camping',
    H7 = 'Casa huéspedes',
    H6 = 'Ciudad de vacaciones',
    CR = 'Crucero',
    H9 = 'Fonda',
    HT = 'Hospedería',
    H4 = 'Hostal',
    H5 = 'Hostal residencia',
    H = 'Hotel',
    H0 = 'Hotel apartamento',
    H1 = 'Hotel ciudad',
    HR = 'Hotel rural',
    H2 = 'Hotel residencia',
    H8 = 'Pensión',
    P = 'Posada',
    R = 'Refugio',
    H3 = 'Residencia apartamento',
    TI = 'Turismo interior',
    VT = 'Vivienda turística',
    O = 'Otros'
}

export enum DiasExplotacion {
    'DE1' = 'Hasta 62 días',
    'DE2' = 'Entre 63 y 122 días',
    'DE3' = 'Entre 123 y 213 días',
    'DE4' = 'Entre 214 y 305 días',
    'DE5' = 'Más de 305 días',
    'DE9' = 'Actividad de temporada',
    'DE10' = 'Ejercicio completo'
}

export enum Categoria {
    'C1' = "CincoEstrellasGL", //'5* GL'
    'C2' = "CincoEstrellas",// = '5*',
    'C3' = "CuatroEstrellasS",// = '4* S',
    'C4' = "CuatroEstrellas",// = '4*',
    'C5' = "TresEstrellasS",// = '3* S',
    'C6' = "TresEstrellas",// = '3*',
    'C7' = "DosEstrellas",// = '2*',
    'C8' = "UnaEstrella",// = '1*',
    'C9' = "Sin Categoria",
    'C10' = "LICinco",// = '5 LI',
    'C11' = "LICuatroS",// = '4 LIS',
    'C12' = "LICuatro",// = '4 LI',
    'C13' = "LITresS",// = '3 LIS',
    'C14' = "LITres",// = '3 LI',
    'C15' = "LIDos",// = '2 LI',
    'C16' = "LIUno",// = '1 LI'
}

// enum TipoRelacion {
//     '1' = 'Alta',
//     '2' = 'Baja',
//     '3' = 'Modificación'
// }
