import React, { FC, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { ICampo, IConcepto } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent} from '@material-ui/core';
import { Grid, Typography, List, Button, Paper, makeStyles } from '@material-ui/core';
import styles from './styles';

import EditIcon from '@material-ui/icons/Edit'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'

import { formatXMLtoObject, GenericInitialObject, GenericObject, getEmptyObject, getInitialObject, getKeysRequiredCampos, isSameData, mixData, OPTIONS_trimestre, renderCampos, TPeriodo } from '../utils';

import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { ISujeto } from 'gateways/perfil.interfaces';
import { SaveRounded } from '@material-ui/icons';
import { isEqual } from 'lodash';
import EndProcessMSG from 'components/Modelos/componentes/endProcessMSG';

const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXml: string | null,
    datosXmlApartadosPrevios: GenericObject,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
    withEjercicioPeriodo: boolean
}

const Autoliquidacion: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXml,
        datosXmlApartadosPrevios,
        setFinished,
        handleSave, 
        withEjercicioPeriodo
    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [pressed, setPressed] = useState(false);

    const [data, setData] = useState<GenericInitialObject| null>(null);
    const [changes,setChanges]= useState<boolean|null>(null);
    const [campos, setCampos] = useState<ICampo[] | null>(null);
    const [EMPTY_AUTOLOQUIDACION,setEmptyAutoliquidacion] = useState<GenericInitialObject|null>(null);
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);

    const [isThisPeriod, setIsThisPeriod]= useState(false)
    const [paramPeriod, setParamPeriod]= useState<string[]>([])

    const CAMPOS_PERIODO =  ['ejerPeri','periodo']
    const MODELO_PERIODO: Record<string,TPeriodo> = {
        '656': '4T'
    }
    
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData= (name:string, value: any) => {
        setChanges(true);
        if(EMPTY_AUTOLOQUIDACION){
            let newData= data 
                ? {...data, [name]: {...data[name], value: value}} 
                : {...EMPTY_AUTOLOQUIDACION, [name]: {...EMPTY_AUTOLOQUIDACION[name],value: value, valid: false}}
            if(newData && newData[name].tipo === 'exclusiveBool' && value === true){
                // 1. Obtener casillas que intervienen en la formula
                const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g; //(?!\[)-?\d+(?=\])/g;
                const casillas = newData[name].formula.match(myRegExp);
                //2. setear a falso todos los valores de la formula
                if(casillas){
                    for (const key in newData) {
                        if (Object.prototype.hasOwnProperty.call(newData, key)) {
                            const element = newData[key];
                            if(casillas.includes(element.casilla.toString())){
                                newData[key] = {...newData[key], value: false}
                            }
                            
                        }
                    }
                }
            } 
            setData(newData);
        }
    }


    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            if(camposRequiredKeys && camposRequiredKeys.length>0 ){
                if(data){
                    camposRequiredKeys.forEach( element => {
                        //console.log('element', element)
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal' || 'decimal2' || 'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                    
                }
            }
            if(valid){
                const saved = await handleSave(data); 
                setChanges(!saved);
                setPressed(true)
            } else {
                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos','FormularioError', terms),
                        variant: 'error',
                    }
                });   
            }
            setLoading(false);
        })();
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                const periodoCampo = withEjercicioPeriodo
                    ? camposApartado.filter( c => CAMPOS_PERIODO.includes(c.codigo)) 
                    : undefined
                
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                let dataXML: GenericInitialObject | null = null;
                let dataPrevios: GenericInitialObject | null = null;
                let changes= false;
                
                if(camposApartado && camposApartado?.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEmptyAutoliquidacion(emptyObjt);
                    const keys = getKeysRequiredCampos(camposApartado);
                    setCamposRequiredKeys(keys)
                    const datosPrevString = Object.values(datosXmlApartadosPrevios).join('')
                    //console.log('datosXml', datosXml)
                    if(datosXml){
                        dataXML = formatXMLtoObject(datosXml,emptyObjt);
                        //console.log('useDatos xml', dataXML)
                    } 
                    if(datosPrevString){
                        dataPrevios = getInitialObject(camposApartado, datosPrevString, idTipoApartado);
                        //console.log('useDatos datosPrevString', dataPrevios)
                    }

                    // copare if dataXML!==dataPrevios => have changes
                    if(isSameData(dataXML, dataPrevios)){
                        //setChanges(false)
                        changes=false
                        newData= dataXML
                    } else{
                        const mixDataObjt = mixData(newData, dataPrevios, idTipoApartado)
                        //setChanges( !isSameData(newData, mixDataObjt))
                        changes = !isSameData(newData, mixDataObjt)
                        newData = mixDataObjt ?? newData
                        // setChanges(true)
                        // newData =dataPrevios
                    }
                }


                // Condiciona el componente a todo disabled si aplica a un solo periodo del ejercicio
                let apply = true
                if(withEjercicioPeriodo && periodoCampo){
                    if(newData && MODELO_PERIODO[idTipoModelo]){
                        // Ver si es 4T
                        const miData = periodoCampo[0].codigo
                        const miPeriodo = OPTIONS_trimestre.find( opt => opt.id === MODELO_PERIODO[idTipoModelo])?.nombre ?? 'not-found'
                        miPeriodo !== 'not-found' && setParamPeriod([miPeriodo])
                        apply = newData[miData].value.includes(miPeriodo)
                        changes = apply && !pressed ? changes : false
                        //console.log('campo: ', miData, 'periodoAplica: ', miPeriodo, ' es mi periodo ? ',apply)
                    }
                }

                setCampos(
                    withEjercicioPeriodo && !apply 
                    ? camposApartado.map(c=> ({...c, soloLectura: true}))
                    : camposApartado
                );
                setIsThisPeriod(apply)
                setData(newData || emptyObjt);
                setChanges(changes)
 

            } catch (error) {
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            } finally{
                setLoading(false)
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);
    
    useEffect(() => {
        if(changes){
            setFinished(false)
        } else {
            setFinished(true)
        }
    },[changes, data])

 
    return(
        <div> 
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
     
            {(withEjercicioPeriodo && isThisPeriod) || (!withEjercicioPeriodo) 
                ? null
                :   <EndProcessMSG 
                        type='info'
                        componentIG='Tributos'
                        termIG={`soloCumplimentarPeriodo${MODELO_PERIODO[idTipoModelo]}`}
                        paramTermIG={paramPeriod}  
                    />
                // <Typography 
                //         style={{margin: '10px 10px 25px 10px', color:'#004f82', fontSize: 18, fontStyle:'italic'}}
                //     >{translate('Tributos', `soloCumplimentarPeriodo${MODELO_PERIODO[idTipoModelo]}`, terms, paramPeriod)}
                //     </Typography>
        
            }
            {campos && campos.length>0 && camposRequiredKeys !== null && data &&
                renderCampos(campos, data, updateData, Object.values(datosXmlApartadosPrevios).join(''), idTipoApartado, undefined, undefined, classes )
            } 

            <div className={classes.rowAlignRight}>
                <Button 
                    disabled={!changes}
                    style={{marginLeft: 10}}
                    color='primary'
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnConfirm', terms)}
                </Button>
            </div>
            

        </div>
    )
}
export default withLiterals(['Tributos'])(Autoliquidacion);

