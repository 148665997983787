import { FC, useContext } from "react";
import { StaticContext } from "react-router";
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { mdiLaptop } from "@mdi/js";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { IStateHistory } from "hooks/domiciliaciones/altas-domiciliacion.hook";
import CarteroVirtualDeuda from './deuda';
import ProtectedRoute from "components/protected-route";
import PagoTelematico from "./pago-telematico";
import CarteroVirtualHome from "./cartero-virtual-home";
import DomiciliacionAlta from "../domiciliaciones/domiciliacion.alta";
import DomiciliacionConfirmacion from "../domiciliaciones/domiciliacion.confirmacion";
import Domiciliaciones from "../domiciliaciones/domiciliaciones";
import pagoCartaConfirmacion from "../domiciliaciones/pagoCarta.confirmacion";
import PagoACuenta from "./pago-a-cuenta/pago-a-cuenta";
import Contenido from "components/contenido";
import SeleccionModoPago from "./pago-a-cuenta/pago-a-cuenta-seleccion-modo-pago";
import PagoACuentaDomiciliado from "./pago-a-cuenta/pago-a-cuenta-domiciliado";
import PagoACuentaConfirmacion from "./pago-a-cuenta/pago-a-cuenta-confirmacion";


const CarteroVirtualIndex: FC<RouteComponentProps<{}, StaticContext, IStateHistory>> = ({ match }) => {
    const terms = useContext(LiteralsContext)
    return (
        <Switch> {/** Switch:  solo busca hasta que uno cumple exactPath - El orden  de las rutas puede afectar */}
            {/** Ruta pago a cuenta */}
            <ProtectedRoute exact path={`${match.path}/deuda-:estado/pago-a-cuenta`} component={PagoACuenta} idModulos={[31]} />
            <ProtectedRoute exact path={`${match.path}/deuda-pendiente/seleccion-modo-pago`} component={SeleccionModoPago} idModulos={[28]} />
            <ProtectedRoute exact path={`${match.path}/deuda-pendiente/pago-a-cuenta-domiciliado`} component={PagoACuentaDomiciliado} idModulos={[29]} />
            <ProtectedRoute exact path={`${match.path}/deuda-pendiente/pago-a-cuenta-confirmacion`} component={PagoACuentaConfirmacion} idModulos={[30]} />

            <Route exact path={`${match.path}`} component={CarteroVirtualHome} />
            <ProtectedRoute path={`${match.path}/deuda-:estado`} component={CarteroVirtualDeuda} idModulos={[1, 2]} />
            <Route exact path={`${match.path}/pagoTelematico`} component={PagoTelematico} />

            { /* Rutas de Domiciliaciones */}
            <ProtectedRoute exact path={`${match.path}/domiciliaciones/:tipo/alta`} component={DomiciliacionAlta} idModulos={[4]} />
            <ProtectedRoute exact path={`${match.path}/domiciliaciones/:tipo/confirmacion`} component={DomiciliacionConfirmacion} idModulos={[4]} />
            <ProtectedRoute exact path={`${match.path}/domiciliaciones/:tipo/pagoCartaAlta`} component={pagoCartaConfirmacion} idModulos={[5]} />
            <ProtectedRoute exact path={`${match.path}/domiciliaciones/:tipo/pago-carta`} component={Domiciliaciones} idModulos={[5]} />
            <ProtectedRoute exact path={`${match.path}/domiciliaciones/:tipo/:screen`} component={Domiciliaciones} idModulos={[3]} />
            <ProtectedRoute exact path={`${match.path}/domiciliaciones/:tipo`} component={Domiciliaciones} idModulos={[3]} />
            <Route exact path={`${match.path}/contenidos`} render={(props) => <Contenido landingName='mas-info-login' {...props} pageTitle={translate('Global', 'CarpetaVirtual', terms)} pageSubtitle={translate('Global', 'CarpetaVirtual Descripcion', terms)} icon={mdiLaptop} />} />

        </Switch>
    );
}

export default withLiterals(['Global', 'CarteroVirtual', 'Domiciliaciones'])(CarteroVirtualIndex);