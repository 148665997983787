import React, { useContext, useEffect, useMemo } from 'react';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { IParamStepper, TipoAsistenteStep } from './types';
import { IStepExtended } from 'components/Modelos/stepper-modelos-asistente';

import PreguntasPlusvalia from "../plusvalias/preguntasPlusvalia";
import InfoTransmPlusvalia from "../plusvalias/infoTransmPlusvalia";
import Compradores from '../plusvalias/compradores';
import Vendedores from '../plusvalias/vendedores';
import CalcPlusvalia from '../plusvalias/calcPlusvalia';
import InfoResumen from '../genericSteps/infoResumen';
import Pago from '../genericSteps/pago';


// Props comunes
interface CommonProps extends IParamStepper {
    stepInfo: IStepExtended;
}

// Props para el componente de alto nivel
interface DynamicComponentProps /*extends CommonProps*/ { 
    componentType: TipoAsistenteStep;
    componentProps: CommonProps //IPreguntasPlusvaliaProps | IInfoTransmPlusvalia;
}

// Componente de alto nivel
const DynamicStep: React.FC<DynamicComponentProps> = ({
    componentType,
    componentProps
}) => {
    //Gateways
    const ioc = useContext(IoC);
    const modelosGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const stepProps = {
        apartado: componentProps.stepInfo.idTipoApartado,
        orden: componentProps.stepInfo.orden
    }

    useEffect (() => {
        (async() => {
            if(componentProps.tipoAsistente === 'plusvalia' && componentProps.idRef){
                const info = await modelosGateway.getAllApartadosModeloAsistente('PLV',componentProps.idRef)
                componentProps.setHeader( 
                    info.preguntasPlusvalia && info.preguntasPlusvalia.inmueble?.direccion && info.preguntasPlusvalia.municipio?.id ? {
                    dir: info.preguntasPlusvalia.inmueble.direccion,
                    muni: info.preguntasPlusvalia.municipio.id,
                    refCat:info.preguntasPlusvalia.inmueble.refCatastral,
                    urlCatastro: info.preguntasPlusvalia.inmueble.urlCatastro
                }: null)
            }
        })()
    },[])

    switch (componentType) {
        case 'preguntasPlusvalia':
            return <PreguntasPlusvalia {...stepProps} {...componentProps} />;
        case 'infoTransmPlusvalia':
            return <InfoTransmPlusvalia {...stepProps} {...componentProps} />;
        case 'compradores':
            return <Compradores {...stepProps} {...componentProps} />;
        case 'vendedores':
            return <Vendedores {...stepProps} {...componentProps} />;
        case 'calcPlusvalia':
            return <CalcPlusvalia {...stepProps} {...componentProps} />;

        //Shared 
        case 'infoResumen':
            return <InfoResumen {...stepProps} {...componentProps} />;
        case 'pago':
            return <Pago {...stepProps} {...componentProps}/>

        default:
            return <>NOT Found Component</>;
    }

};

export default DynamicStep;
