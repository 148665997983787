import React, { FC, useState, useCallback, useRef } from "react";
import { Card, CardContent, Grid, makeStyles, Hidden } from "@material-ui/core";
import NotificacionGrid from './notificacion.grid';
import styles from '../shared/tributos.styles';
import NotificacionDetail from "./notificacion.detail";
import DocumentosNotificacion from "./documentos.notificacion";
import FirmaNotificacion from "./firma.notificacion";
import MensajeNotib from "./mensaje.notib";
import { INotificacion } from "gateways/notificaciones.interfaces";

const useStyles = makeStyles(styles);

interface INotificacionCardProps {
    notificacion: INotificacion;
    handleNotificacionesPendientes: () => void;
}

const NotificacionCard: FC<INotificacionCardProps> = ({ notificacion, handleNotificacionesPendientes }) => {
    const classes = useStyles();

    const [openDetail, setOpenDetail] = useState(false);
    const [openDocuments, setOpenDocuments] = useState(false);
    const [openSignDocument, setOpenSignDocument] = useState(false);
    const [openNotibMessage, setOpenNotibMessage] = useState(false);

    const handleCloseDetail = useCallback(() => setOpenDetail(false), []);
    const handleOpenDetail = useCallback(() => setOpenDetail(true), []);
    const handleCloseDocuments = useCallback(() => setOpenDocuments(false), []);
    const handleOpenDocuments = useCallback(() => setOpenDocuments(true), []);
    const handleCloseSignDocument = useCallback((isSigned?: boolean) => {
        setOpenSignDocument(false);
        if (isSigned){
            setOpenNotibMessage(true);
        }
    }, []);
    const handleCloseNotibMessage = useCallback(() => setOpenNotibMessage(false), []);
    const handleOpenSignDocument = useCallback(() => setOpenSignDocument(true), []);

    // Referencias
    const notificacionState = useRef(null);

    return (
        <>
            <Grid
                item
                className={[classes.mainButton, classes.recibo].join(' ')}
                onClick={handleOpenDetail}>
                <Card>
                    <CardContent>
                        <Hidden mdUp implementation="css">
                            <NotificacionGrid
                                direccion1="column"
                                direccion2="row"
                                notificacion={notificacion}
                                notificacionRef={notificacionState}
                                moreInfo={handleOpenDetail}
                                documentosNotificacion={handleOpenDocuments}
                                firmaNotificacion={handleOpenSignDocument}
                                handleNotificacionesPendientes={handleNotificacionesPendientes}
                            />
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <NotificacionGrid
                                direccion1="row"
                                direccion2="column"
                                notificacion={notificacion}
                                notificacionRef={notificacionState}
                                moreInfo={handleOpenDetail}
                                documentosNotificacion={handleOpenDocuments}
                                firmaNotificacion={handleOpenSignDocument}
                                handleNotificacionesPendientes={handleNotificacionesPendientes}
                            />
                        </Hidden>
                    </CardContent>
                </Card>
            </Grid>

            <NotificacionDetail
                onClose={handleCloseDetail}
                open={openDetail}
                notificacion={notificacion}
            />

            <DocumentosNotificacion
                onClose={handleCloseDocuments}
                open={openDocuments}
                notificacion={notificacion}
                notificacionRef={notificacionState}
            />

            <FirmaNotificacion
                onClose={handleCloseSignDocument}
                open={openSignDocument}
                notificacion={notificacion}
                notificacionRef={notificacionState}
            />

            <MensajeNotib
                onClose={handleCloseNotibMessage}
                open={openNotibMessage}
            />
        </>
    );
}

export default NotificacionCard;