import { useContext, useState, useEffect, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { CircularProgress, useMediaQuery, useTheme, DialogTitle, makeStyles, DialogActions } from '@material-ui/core';
import Term from 'components/term';
import usePage from 'hooks/page.hook';
import IoC from 'contexts/ioc.context';
import { AlertsContext } from 'contexts/alerts.context';
import { TramitesGateway } from 'gateways/tramites.gateway';
import { IDetalleExpediente } from 'gateways/tramites.interfaces';
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import { DocumentsUtils } from 'utils/documents';
import { Afirma } from 'utils/afirma';
import { AfirmaGateway } from 'gateways/afirma.gateway';
import { ContenidosGateway } from 'gateways/contenido.gateway';

const useStyles = makeStyles(() => ({
    centerCircle: {
        textAlign: 'center'
    }
}));



interface IProps {
    open: boolean;
    onClose: () => void;
    onChange: (documento: IDetalleExpediente) => void;
    idExpediente: number;
}

function PresentacionDialog(props: IProps) {
    const { onClose, onChange, open, idExpediente } = props;
    const ioc = useContext(IoC);
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [loading, setLoading] = useState(false);
    const [esperandoAutoFirma, setEsperandoAutoFirma] = useState(false);
    const [presentacionOk, setPresentacionOk] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const gateway = ioc.get(TramitesGateway) as TramitesGateway;
    const afirmaGateway = ioc.get(AfirmaGateway) as AfirmaGateway;
    const [documento, setDocumento] = useState<IDetalleExpediente | null>(null);
    const [template, setTemplate] = useState('');
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);

    const loadTemplate = useCallback(async () => {
        const bodyAux = await contenidoGateway.getContent(
            !presentacionOk ? 'informacion-presentacion-modal' : 'expediente-presentado-correctamente',
            {}
        );
        setTemplate(bodyAux[0].contenido);

    }, [contenidoGateway, presentacionOk]);

    useEffect(() => {
        loadTemplate();
    }, [loadTemplate, presentacionOk]);

    const handleClose = () => onClose();

    const handleImprimir = async () => {
        setLoading(true);
        try {
            if (documento !== null && documento.idDocumentoJustificante !== null) {
                DocumentsUtils.downloadDoc(documento.idDocumentoJustificante);
            }
            onClose();
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Tramites', (error as any).message, terms),
                    variant: 'error',
                }
            });
        }
        setLoading(false);
    }

    const handleSimularResultadoAutoFirma = async () => {
        setEsperandoAutoFirma(false);
        const detalle = await gateway.presentar(idExpediente);
        pageDispatcher({
            type: 'show-notification',
            payload: {
                message: translate('Tramites', 'Se ha presentado el expediente', terms),
                variant: 'success',
            }
        });
        setDocumento(detalle);
        onChange(detalle);
        setPresentacionOk(true);
    }

    const handleResultadoAutoFirma = async (idFirma: number) => {
        setEsperandoAutoFirma(false);
        try {
            if (idFirma > 0) {
                const detalle = await gateway.presentar(idExpediente);
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Tramites', 'Se ha presentado el expediente', terms),
                        variant: 'success',
                    }
                });
                setDocumento(detalle);
                onChange(detalle);
                setPresentacionOk(true);
            } else {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Tramites', 'Error en el proceso de firma del documento', terms),
                        variant: 'error',
                    }
                });
            }
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Tramites', (error as any).message, terms),
                    variant: 'error',
                }
            });
        } finally {
            setLoading(false);
        }
    }

    const handlePresentar = async () => {
        setLoading(true);

        try {
            const resultado = await gateway.validarPresentacion(idExpediente);
            if (!!resultado.idOrdenFirma) {
                const afirma = new Afirma(resultado.idOrdenFirma, afirmaGateway, 'presentaciones', handleResultadoAutoFirma);
                setEsperandoAutoFirma(true);
                // Uncomment to - moock presentar - Simular presentación
                if (window.location.origin.includes('localhost') ||
                    window.location.origin.includes('sede-atib.intricom')){
                    handleSimularResultadoAutoFirma();
                } else {
                    // Codigo bueno
                    afirma.processSignature(); // Lanza el programa de autofirma
                }   
            } else {
                const detalle = await gateway.presentar(idExpediente);
                onChange(detalle);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Tramites', (error as any).message, terms),
                    variant: 'error',
                }
            });
        }
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Term component="Tramites" text='PresentarDialog' />
                </DialogTitle>
                <DialogContent className={loading ? classes.centerCircle : ''}>
                    {
                        loading
                            ? esperandoAutoFirma
                                ? <Term component="Tramites" text="EsperandoConfirmacionAutoFirma" />
                                : <Term component="Tramites" text="EsperandoConfirmacionPresentar" />
                            : <div dangerouslySetInnerHTML={{ __html: template }} />
                    }

                </DialogContent>
                <DialogActions>
                    <Button color="secondary" variant="outlined" onClick={handleClose} disabled={loading}>
                        <Term component="Tramites" text="Cancelar" />
                    </Button>
                    {
                        !presentacionOk
                            ?
                            <Button color="primary" variant="outlined" onClick={handlePresentar} disabled={loading}>
                                <Term component="Tramites" text="Presentar" />
                                {
                                    loading ?
                                        <CircularProgress size={20} />
                                        :
                                        null
                                }
                            </Button>
                            :
                            <Button color="primary" variant="outlined" onClick={handleImprimir} disabled={loading}>
                                <Term component="Tramites" text="Imprimir justificante" />
                                {
                                    loading ?
                                        <CircularProgress size={20} />
                                        :
                                        null
                                }
                            </Button>
                    }

                </DialogActions>
            </Dialog>
        </div>
    );
}


export default PresentacionDialog;