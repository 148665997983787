import React, { FC, useCallback } from "react";
import Icon, { IconNames } from "../icon";
import styled from "styled-components";
import useRipple from "atomic/hooks/ripple";

export type IconButtonProps = {
    icon: IconNames;
    color?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const IconButton: FC<IconButtonProps> = ({ icon, color, onClick }) => {
    const [addRipple, ripples] = useRipple();

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
        onClick && onClick(event);
        addRipple(event);
    }, [addRipple, onClick])

    return (
        <ButtonBase onClick={handleClick}>
            {ripples}
            <Icon name={icon} color={color} />
        </ButtonBase>
    )
}

const ButtonBase = styled.button`
    flex: 0 0 auto;
    line-height: 0;
    padding: 0.3rem;
    margin: 0;
    text-align: center;
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 50%;
    background: none;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    :hover{
        background-color: rgba(0, 0, 0, 0.04);
    }
`;