import React, { Children, FC, forwardRef , ForwardRefRenderFunction, PropsWithChildren, useContext} from "react";
import { Button, Grid, IconButton, Typography, withStyles, WithStyles } from "@material-ui/core";
import styles from './styles';
import ButtonsStepper, { IButtonStepper } from "../shared/buttonsStepper";
import { AlertsContext } from "contexts/alerts.context";
import { Alert } from "react-bootstrap";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import Icon from "@mdi/react";
import { handleCopyRef } from "containers/tributos-autonomicos-locales/utils";
import { mdiFileDocumentMultipleOutline } from "@mdi/js";
import { BLUE } from "containers/shared/colors";


interface StepSchemaProps extends WithStyles<typeof styles>, IButtonStepper {}
  
const StepSchema: FC<PropsWithChildren<StepSchemaProps>> = ({
    children,
    classes,
    isFirst,
    isLast,
    onNext,
    onBack,
    onExit,
    disabledNext,
    disabledBack,
    forceExit,
    idRef,
    termModalContinuarMasTarde='continuar_mas_tarde_copyRef'
  }) => {
    const terms= useContext(LiteralsContext)
    const [alerts, alertsDispatch] = useContext(AlertsContext);
    return(
        <Grid container direction="column" className={classes.container}>
             <Grid item className={classes.root}>
                {children}
            </Grid>

            <Grid item >
               <ButtonsStepper 
                    isFirst={isFirst} 
                    isLast={isLast}
                    onNext={onNext}
                    disabledNext={disabledNext}
                    onBack={onBack}
                    disabledBack={disabledBack}
                    onExit={(cause:'continuar-mas-tarde'|'force') => {
                        if(cause==='continuar-mas-tarde' && idRef !== null){
                            alertsDispatch({
                                type: 'show-alert',
                                payload: {
                                    isHtml: true,
                                    message: <div>
                                        <Typography>{translate('Global', termModalContinuarMasTarde, terms)}</Typography>
                                        {idRef !== null &&  
                                            <div style={{display:'inline-flex',alignItems:'center'}}>
                                                <IconButton color="primary"
                                                    onClick={() => handleCopyRef(idRef)}
                                                    className={classes.buttonCopyRef}
                                                >
                                                    <Icon path={mdiFileDocumentMultipleOutline} size={1} color={BLUE}/>
                                                </IconButton>
                                                <Typography variant="body1" style={{ marginRight: 5 }}>
                                                    {translate('Global','Referencia',terms)}:
                                                </Typography>
                                                <Typography style={{fontWeight:'bold'}}>{idRef}</Typography>
                                            </div>
                                        }
                                    </div>,
                                    variant: 'warning',
                                    defaultCloseActionTemplateName: translate('Global','Continuar',terms),
                                    actionTemplateName: translate('Global','exit',terms),
                                    hasCustomAction: true,
                                    handleCustomAction: () => {
                                        alertsDispatch({type: 'hide-alert'})
                                        onExit(cause)
                                    }
                                }
                            })
                        } else {
                            onExit(cause)
                        }
                         
                    }}
                    forceExit={forceExit}
                /> 
            </Grid> 
        </Grid>
    )
}

export default withStyles(styles)(StepSchema);