import {FC, useContext} from "react"
import { Button, Grid, Link, StyleRules, Theme, WithStyles, withStyles } from "@material-ui/core"
import { mdiOpenInNew, mdiMapMarkerRight } from "@mdi/js";
import ImageIcon from "components/image.icon";
import Field from "containers/bienes-sujeto/field";
import { translate } from "utils/i18n";
import { Icon } from "@mdi/react";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { NARANJA_CATASTRO } from "containers/shared/colors";
import CatastroIMGBackground from 'resources/dusti/iconoCatastro_transparente.png'
import CatastroIcon from 'resources/dusti/iconoCatastro.png'
import { TTipoInmueble, TUsoInmueble } from "gateways/catastro.interface";


const styles = (theme: Theme): StyleRules => ({
    imgContainer : {
        //width: '100%',
        display: 'flex', 
        justifyContent: 'flex-start',
    },
    
    linkContentRow: {
        display: 'flex', 
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
    },
    contentRow: {
        display: 'flex', 
        flexDirection: 'column',
    },
    contentRow2: {
        display: 'flex', 
        flexDirection: 'row',
    },
    [theme.breakpoints.up('sm')]: {
        contentRow: {
            display: 'flex', 
            flexDirection: 'row',
            flexWrap: 'wrap',
        }
    },
    [theme.breakpoints.up('md')]: {
        contentRow: {
            display: 'flex', 
            flexWrap: 'wrap',
            flexDirection: 'row'
        }
    },
    [theme.breakpoints.up('lg')]: {
        contentRow: {
            display: 'flex', 
            flexWrap: 'wrap',
            flexDirection: 'row',
        }
    },
    inline: {
        display: 'flex-inline',
    },
    root:{ 
        borderRadius: 10,
        borderWidth: 1,
        backgroundColor: '#f8f8f8',//'#f4faff',f3f2f2, f5f5f5
        boxShadow: '1px 1px 2px rgba(0, 0, 0, .1)',
        borderTop: 'solid',
        borderTopWidth: 10,
        borderColor: NARANJA_CATASTRO,


    },
    backImag:{
        //backgroundImage: "url(" + { CatastroIMGBackground } + ")"
    },
    [theme.breakpoints.up('lg')]: {
        root: {
            padding: 5,
            marginRight: 30, 
            marginLeft: 20,
            maxWidth:'800px'
        }
    },
    [theme.breakpoints.up('sm')]: {
        root: {
            padding: 5,
            marginRight: 30, marginLeft: 20
        }
    },
});

interface IProps extends WithStyles {
    refCatastral: string;
    urlCatastro: string, 
    urlGoogle: string, 
    tipoBien: TTipoInmueble;
    uso:TUsoInmueble;
    domicilio:string
}
const InmuebleInfo: FC<IProps> = ({classes, refCatastral, domicilio, tipoBien, urlCatastro, urlGoogle, uso}) => {
    const terms = useContext(LiteralsContext);

    return (
        <div className={classes.root}
            style={{ 
                //position: 'absolute',
                backgroundImage: `url(${CatastroIMGBackground})`,
                backgroundSize: '50%',
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: '40%',
                backgroundPositionX: 'right',
            }}
        >
            
            <Grid container direction="row">
                <Grid item xs={12} sm={4}  md={6} lg={5} xl={5} className={classes.contentRow}>
                    <div className={classes.imgContainer}>
                        <ImageIcon
                            from="bienesSujeto"
                            imgName={tipoBien === "UR" ? "urbana" : "rustica"}
                        />
                    </div>
                    <div style={{flexDirection:'column'}}>
                        <div className={classes.linkContentRow}>
                            <Link
                                href={urlCatastro}
                                target="_blank"
                                rel="noreferrer"
                            >
                            <Icon
                                style={{ marginRight: 12, marginLeft: 12 }}
                                path={mdiOpenInNew}
                                size={1}
                                color={NARANJA_CATASTRO}
                            />
                            </Link>
                            <Field
                                label={translate(
                                    "DUTI",
                                    "refCatastral_short",
                                    terms
                                )}
                                value={refCatastral}
                            />
                        </div>
                        {urlGoogle && 
                        <div className={classes.linkContentRow}>
                            <Icon
                                style={{ marginRight: 5, marginLeft: 12 }}
                                path={mdiMapMarkerRight}
                                size={1}
                                color="grey"
                            />
                            <Button
                                style={{ textTransform: "capitalize" }}
                                variant="text"
                                onClick={() => window.open(urlGoogle, "_blank") } //() => linkGoogle(refCatastral)}
                            >
                                Google Maps
                            </Button>
                        </div>}
                    </div>
                      
                </Grid>
                    
                <Grid  item xs={12} sm={8}  md={6} lg={7} xl={7} className={classes.contentRow}
                    container direction="column"  style={{ marginTop: 20, paddingLeft: 10 }}
                >
                    <Grid item /*className={classes.inline} */>
                        <Field 
                            label={translate("DUTI", "direccion", terms)}
                            value={domicilio}
                        />
                    </Grid>

                    <Grid item container className={classes.contentRow}>
                        <Field
                            width={"33%"}
                            label={translate("DUTI", "clase", terms)}
                            value={tipoBien ? translate("BienesSujeto", tipoBien, terms) : ""}
                        />
                        <Field
                            width={"33%"}
                            label={translate("DUTI", "uso", terms)}
                            value={uso ? translate("BienesSujeto", uso, terms) : ""}
                        />
                    
                    </Grid>

                </Grid>
            </Grid>
            
        </div>
    )
}

export default withLiterals(["DUTI","BienesSujeto" ])(withStyles(styles)(InmuebleInfo))


