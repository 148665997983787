import React, { FC, useContext } from "react";

import { Grid, StyleRules, Theme, WithStyles, withStyles } from "@material-ui/core";
import { TextField } from "components/text-field";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";

import { TIDentifica } from "../types";
import GenericInputText, { TExtraValidation } from "components/generic-input-text";
import TypedInputNumber from "components/generic-input-number/typed-input-number";
import { validateEmail } from "components/generic-input-text/utils";
import GenericRadioButton, { IGenericRadioButtonOption } from "components/generic-radio-button";
import { nifValidate } from "components/generic-sujeto/utils";
import { isCIF } from "utils/validateNif";


export const EMPTY_IDENT_ERROR : Record<keyof TIDentifica,{value:boolean, msg:string|undefined}> = {
    nif: {value:false,msg:undefined},
    nombre: {value:false,msg:undefined},
    apellidos: {value:false,msg:undefined},
    telefono: {value:false,msg:undefined},
    email: {value:false,msg:undefined},
    parentesco: {value:false,msg:undefined},
}

interface IdentificativoProps extends WithStyles<typeof styles> {
    nif: string | undefined;
    nombre: string | undefined;
    apellidos: string | undefined;
    telefono: string | undefined;
    email: string | undefined;
    parentesco: 'madre'| 'padre'|undefined;
    errors?: Record<keyof TIDentifica,{value:boolean, msg:string|undefined}>,
    required?:boolean;
    disabled?:boolean;
    onChange: (key: keyof TIDentifica, value: string|undefined, err: boolean) => void
};

const Identificativo: FC<IdentificativoProps> = ({
    classes, nif, nombre, apellidos, telefono, email, parentesco, onChange, errors=EMPTY_IDENT_ERROR, required=true, disabled=false
}) => {
    const terms =useContext(LiteralsContext);
    const Parentesco_OPTIONS : IGenericRadioButtonOption[] = [
        {id:'madre', term: 'ABO_NAC_parentesco_madre', componentIG:'AbonoNacimiento'},
        {id:'padre', term: 'ABO_NAC_parentesco_padre', componentIG:'AbonoNacimiento'}
    ]
    
    return (
        <Grid container direction="column">
            <>
                <Grid container direction="column" alignItems="flex-start" justify="flex-start">
                    <Grid item className={classes.row}>
                        <GenericInputText
                            componentIG='GenericComponents'
                            labelTerm='nif'
                            value={nif}
                            required={required}
                            disabled={disabled}
                            error={errors.nif.value}
                            errorMsgExterno={errors.nif.msg}
                            onChangeValue={(v: string|undefined, err: boolean) =>  onChange('nif', v, err )}
                            extraValidation={(nif: string) => {
                                const isJuridica=isCIF(nif)
                                if(isJuridica){
                                    return {
                                        error: true, 
                                        error_msg: 'noEsPersonaFisica', 
                                        componentIG:'AbonoNacimiento'
                                    } as TExtraValidation
                                }
                                const result = nifValidate(nif)
                                //console.log('identifica validate ', result, errors)
                                if(!result.error && errors.nif.value){
                                    return {
                                        error: errors.nif.value, 
                                        error_msg: errors.nif.msg, 
                                        componentIG:'AbonoNacimiento'
                                    } as TExtraValidation
                                }

                                return result
                                
                            }}
                            forceUpperCase
                        />
                        <GenericInputText
                            componentIG='GenericComponents'
                            labelTerm='nombre'
                            value={nombre}
                            required={required}
                            disabled={disabled}

                            error={errors.nombre.value}
                            errorMsgExterno={errors.nombre.msg}
                            onChangeValue={(v: string|undefined, err: boolean) =>  onChange('nombre', v, err)}
                            forceUpperCase
                            className={classes.input360}
                        />
                        <GenericInputText
                            componentIG='GenericComponents'
                            labelTerm='apellidos'
                            value={apellidos}
                            required={required}
                            disabled={disabled}

                            error={errors.apellidos.value}
                            errorMsgExterno={errors.apellidos.msg}
                            onChangeValue={(v: string|undefined, err: boolean) =>  onChange('apellidos', v, err)}
                            forceUpperCase
                            //fullWidth={true}
                            className={classes.inputDouble360}
                        />
                    </Grid>
                    <Grid item className={classes.row}>
                        {/* <TypedInputNumber
                            componentIG="GenericComponents"
                            labelTerm={'telefono'}
                            value={telefono ? Number(telefono): undefined}
                            type="tlf"
                            required={required}
                            disabled={disabled}

                            error={errors.telefono.value}
                            errorMsgExterno={errors.telefono.msg}
                            onChangeValue={(v: number|undefined, err: boolean) =>  onChange('telefono', v?.toString(), err)}
                        /> */}
                        <TypedInputNumber
                            componentIG="GenericComponents"
                            labelTerm={'telefono'}
                            value={telefono ? Number(telefono): undefined}
                            type="prefixed-tlf"
                            required={required}
                            disabled={disabled}
                            error={errors.telefono.value}
                            errorMsgExterno={errors.telefono.msg}
                            onChangeValue={(v: number|undefined, err: boolean) =>  onChange('telefono',  v?.toString(), err)}
                        />
                        <GenericInputText 
                            componentIG="GenericComponents"
                            labelTerm='email' 
                            value={email} 
                            onChangeValue={(v: string|undefined, err: boolean) =>  onChange('email', v, err)}
                            className={classes.input360}
                            extraValidation={validateEmail}
                            required={required}
                            disabled={disabled}
                            error={errors.email.value}
                            errorMsgExterno={errors.email.msg}
                        />

                    </Grid>
                    


                    
                </Grid>
            </>
            <Grid item className={classes.row}>
                <GenericRadioButton key='parentesco'
                    labelInRow={true}
                    optionsInRow={true}
                    componentIG='AbonoNacimiento'
                    labelTerm='ABO_NAC_parentesco'
                    value={parentesco}
                    required={required}
                    disabled={disabled}
                    options={Parentesco_OPTIONS}
                    onChangeValue={ (v: string|undefined, err: boolean) =>  onChange('parentesco', v, err)}
                />
            </Grid>
        </Grid>
    )
};

const styles = (theme: Theme): StyleRules => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexGrow: 1,
        flexWrap:'wrap'
    },
    input360: {
        width: 360
    },
    inputDouble360: { // input360 + inputDefault
        width: 360+10 + 260
    }
   
});
export default withLiterals(['Global', 'Tramites','AbonoNacimiento','GenericComponents' ])(withStyles(styles)(Identificativo));