import react, { FC} from "react";
import {Dialog , DialogActions, DialogContent, DialogProps, DialogTitle, Typography, WithStyles, withStyles} from "@material-ui/core";
import styles from "./styles";




interface IModal extends DialogProps {
    title: string
    renderContent: ()=> JSX.Element
    renderActions?: ()=> JSX.Element
}
const Modal: FC<IModal & WithStyles<typeof styles>> = ({
    classes, title, renderContent, renderActions, ...props
}) => {


    return (
        <Dialog {...props}>
            <DialogTitle>
                <Typography className={classes.title}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>{renderContent()}</DialogContent>
            {renderActions &&
                <DialogActions>{renderActions()}</DialogActions>
            }
        </Dialog>
    );
}

export default withStyles(styles)(Modal) ;