import React, {FC, useContext} from "react";
import { Typography, WithStyles, withStyles } from "@material-ui/core";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import duti_styles from "containers/DUTI/styles";
import { translate } from "utils/i18n";

interface IProps extends WithStyles {term:string,dir:string} 
const DireccionRBT: FC<IProps> = ({classes,term,dir}) =>{ 
    const terms = useContext(LiteralsContext);
    return (
        <div className={classes.column}>
            <Typography color="textSecondary">{translate('DUTI',term,terms)} </Typography>
            <Typography color="primary" style={{textDecoration: 'underline' }}>{dir}</Typography>
        </div>
    )
}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(DireccionRBT));