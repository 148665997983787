import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";

const styles = (theme: Theme): StyleRules => ({
    root: {
        width: '100%',
        marginRight: 15,
        marginLeft: 15,
        padding: 10,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    widget: {
        marginTop: 10,
        marginBottom: 10,
    },
})

export default styles;