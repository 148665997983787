import { Typography } from "@material-ui/core"
import { translate } from "utils/i18n"


export const renderField = (field: {term: string, value: any, fullWidth?:boolean}, justif: string,terms: any, componentTerms: string) => {
    return(
        <div style={{display:'flex', flexDirection: 'row', width: '100%'}}>
            <Typography style={{marginRight: 10, width: '30%', color: '#919191'}}>
                {translate(componentTerms, (field.term.includes('MXX_') || field.term.includes('justif_')) ? field.term : `${justif}_${field.term}` ,terms)}
            </Typography>
            <Typography style={{flex: 1}}>{field.value}</Typography>
        </div>
    )

}

export const renderApartado = (data: {term: string, value: any, fullWidth?:boolean}[], justif: string,terms: any, componentTerms: string) => {
    return (
        data?.map( (field: {term: string, value: any, fullWidth?:boolean}) => {
            return (
                renderField(field, justif,terms,componentTerms)
            )
        })
    )
}
/**Apartadoas de solo TEXTO - Value Undefined o null ya que no se renderiza */
export const renderApartadoText = (data: {term: string, value: any, fullWidth?:boolean}[], justif: string,terms: any, componentTerms: string) => {
    return (
        data?.map( (field: {term: string, value: any, fullWidth?:boolean}) => {
            return (
                translate(componentTerms, field.term.includes('MXX_') ? field.term : `${justif}_${field.term}`,terms, field.value).split('//').map( (val, idx) => (
                    <Typography style={{display:'flex', flexGrow: 1, marginTop:10 , marginBottom:10 }}>{val}</Typography>
                ))   
            )
        })
    )
}

