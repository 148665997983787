import React from "react"
import imageLogo from '../../resources/logo.png';
import { translate } from "utils/i18n"
import { Paper, Grid, Typography } from "@material-ui/core"
import { BLUE_BORRADOR } from "containers/shared/colors"
import { renderApartado, renderApartadoText } from "./shared";
import HeaderJustificanteBorrador from "components/DUTI/header-justificante-borrador";



type TValuesJxx =  {term: string, value: any, fullWidth?:boolean}[]
export type TJustificanteIBI = Record<'APART_1'|'APART_2'|'APART_3', TValuesJxx > & Record< 'APART_4'|'APART_5',{titulares: TValuesJxx[]}> 
//{
//     "APART_4": {
//         //inmueble: TValuesJxx,
//         titulares: TValuesJxx[],
//     },
//     "APART_5": {
//         //inmueble: TValuesJxx,
//         titulares: TValuesJxx[],
//     }
// }




export const renderJIBI = (
    justificante:  TJustificanteIBI , 
    terms:any, 
    componentTerms: string 
) => {

    return (
        <Paper elevation={5} style={{margin: 20, paddingBottom: 60}}>
            <Grid container direction='column'  style={{padding: 20}}>
                <HeaderJustificanteBorrador
                    componentTerms={componentTerms}
                    docTypeTerm="borr_justif_dusti"
                    terms={terms}
                    titleTerm={`borr_ibi_title`}
                />

                <Grid item container direction='column'>
                    {(Object.keys(justificante) as (keyof TJustificanteIBI)[]).map( (apartado) => {  
                        return(
                            <Grid item>
                                { justificante[apartado] !== null &&
                                    <Typography style={{backgroundColor: BLUE_BORRADOR, color: 'white',marginTop: 10, padding: '2px 5px 2px 5px',display:'flex', flex:1}}>
                                        {translate(componentTerms,`ibi_${apartado}_title`,terms).toUpperCase()}
                                    </Typography>
                                } 
                                {('APART_4'!== apartado && "APART_5" !== apartado)
                                    ?   apartado === "APART_1" ?
                                        <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap'}}>
                                            {renderApartadoText(justificante[apartado], 'ibi', terms, componentTerms)}
                                        </div>
                                        :
                                        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', marginBottom: 10, marginTop: 10}}>
                                            {renderApartado(justificante[apartado], 'ibi', terms, componentTerms)}
                                        </div>
                                    : 
                                        <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap', marginTop: 10}}>
                                            { justificante[apartado].titulares.map((titular: TValuesJxx, idx) => (
                                                <div key={apartado+idx} 
                                                    style={{ 
                                                        display:'flex', flexDirection:'row', flexWrap:'wrap',
                                                        borderColor: '#e0eaf4', 
                                                        borderBottomStyle:'solid'
                                                    }}
                                                >
                                                    {renderApartado(titular, 'ibi', terms, componentTerms)}
                                                </div>
                                            ))}
                                        </div>
                                }
                                
                                
                            </Grid>
                        )
                    })}
                    
                </Grid>
            </Grid>
        </Paper>
)
}