import React, { useContext, useState } from 'react';
import { TextField, Button, CircularProgress, Dialog, DialogContent, DialogActions, DialogContentText, useMediaQuery, useTheme, makeStyles, DialogTitle } from "@material-ui/core";
import Term from "components/term";
import usePage from 'hooks/page.hook';
// import IoC from 'contexts/ioc.context';
// import { SolicitudesGateway } from 'gateways/solicitudes.gateway';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import { AlertsContext } from 'contexts/alerts.context';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        textAlign: "center",
    },
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        textAlign: 'center'
    },
    main: {
        padding: 20
    },
    text: {
        marginTop: 5,
        marginBottom: 5
    },
    bold: {
        fontWeight: 'bold'
    },
    mainButton: {
        width: '100%',
        textAlign: 'left',
        paddingTop: 15,
        paddingBottom: 15,
    },
    textField: {
        width: '100%'
    },
}));

interface IProps {
    open: boolean;
    number: string;
    onClose: () => void;
    onVerify: (key: string) => void;
}

function ConfirmarSolicitudDialog(props: IProps) {
    const { onClose, open, onVerify } = props;
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);

    const [loading, setLoading] = useState(false);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [key, setKey] = useState('');

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => onClose();
    const handleVerify = () => onVerify(key);
    const handleChangeKey = (keyLocal: string) => setKey(keyLocal);

    const validateKey = () => {
        let textKey: string | null = null;

        if (key === '') {
            textKey = translate('Perfil', 'El campo no puede estar vacío', terms);
        }

        setValidationMessage(textKey)

        return !textKey;
    }

    const handleVerifySms = async () => {
        if (validateKey()) {
            setLoading(true);
            try {
                const result = true; // await solicitudesG.confirmarSmsCodigo(key);
                setKey("");
                if (result) {
                    handleVerify();
                    handleClose();
                } else {
                    pageDispatcher({
                        type: 'show-notification', payload: {
                            message: translate('Solicitudes', 'El código introducido es incorrecto', terms),
                            variant: 'warning',
                        }
                    });
                }

            }
            catch (e) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Solicitudes', 'No hemos podido finalizar la solicitud', terms),
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoading(false);
            }
        }
    }

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Term component="Solicitudes" text="Verificar código" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Term component="Solicitudes" text="CodigoSms" />
                    </DialogContentText>

                    <TextField
                        id="outlined-sms-input"
                        label={<Term component="Solicitudes" text="Número confirmación" />}
                        margin="dense"
                        fullWidth
                        value={key}
                        error={validationMessage !== null}
                        helperText={validationMessage}
                        className={classes.textField}
                        onChange={(e) => handleChangeKey(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        <Term component="Global" text="Cerrar" />
                    </Button>
                    <Button color="primary" onClick={handleVerifySms} disabled={loading} autoFocus>
                        <Term component="Perfil" text="Verificar" />
                        {
                            loading ?
                                <CircularProgress size={20} />
                                :
                                null
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConfirmarSolicitudDialog;