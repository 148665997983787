import React, { FC } from "react"
import imageLogo from '../../resources/logo.png';
import image3puntos from '../../resources/logo-sede.png';
import { translate } from "utils/i18n"
import {  Grid, Typography, makeStyles, useTheme } from "@material-ui/core"
import { BLUE_BORRADOR } from "containers/shared/colors";
const useStyles = makeStyles((theme) => ({
    textSede: {
        fontWeight: 'lighter',
        color: '#00285f',
        fontSize: 20,
    },
    textSedeContainer: {
        background: 'linear-gradient(to right, rgba(255,255,255,1), rgba(221,234,242,1))',
        paddingLeft: 20,
        marginBottom: 20
    },
    logoSede: {
        width: 50,
        marginLeft: 10,
    },
    titleContainer: {
        borderRight: 'solid', 
        borderRightColor: BLUE_BORRADOR, 
        borderRightWidth:5, 
        paddingRight:20
    }
}));

interface IProps {
    docTypeTerm: string,
    titleTerm: string, 
    terms:any, 
    componentTerms: string 
}

const HeaderJustificanteBorrador: FC<IProps> = ({componentTerms,docTypeTerm,terms,titleTerm}) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Grid container direction='row' alignItems='center'>
            <Grid item xs={3} container direction='column' alignItems='flex-start' style={{paddingLeft:20}}>
                <img width={250} height={'auto'} src={imageLogo} alt='logo' />
            </Grid>
            <Grid item xs={9} container direction="row" justify="flex-end"
                className={classes.titleContainer}
            > {/**Tipo modelo descripcion*/}
               
                    { translate(componentTerms,titleTerm,terms).split('/').map( val => (
                        val !== '/' && 
                            <Grid item container direction="column" alignItems="flex-end">
                                {/* <Typography style={{fontSize: 24, fontWeight: 'bolder', marginLeft: 30, color: "#00265b"}}>{ranslate(componentTerms,docTypeTerm,terms)}</Typography> */}
                                <Typography style={{fontSize: 19,  fontWeight: 400 ,marginLeft: 30, color:"#6c9db7", marginTop: 24}}>{val}</Typography>
                            </Grid>
                        )
                    )}

            </Grid>
            <Grid item xs={12} className={classes.textSedeContainer} > {/**SEDE + Logo 3 puntos */}
                <span className={classes.textSede}>
                    {translate("Global","Sede Electrónica",terms)}
                </span>
                <img className={classes.logoSede} src={image3puntos} alt='logo-sede' />
            </Grid>
            
        </Grid>
    )
}
export default HeaderJustificanteBorrador;