import { useEffect, FC, useState, useContext, useCallback, useRef } from 'react';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import {  makeStyles, StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import { Card, Stepper, Step, StepLabel, StepConnector, Button, StepContent, Grid, StepIconProps, CardContent, Paper, CardHeader, Typography} from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiArrowRight, mdiArrowLeft, mdiContentSave} from '@mdi/js';
import clsx from 'clsx';
import { IStep } from 'gateways/model.new.interface';
import { translate } from 'utils/i18n';
import { MoreInfoContext } from 'contexts/more_info.context';
import Term from '../term';
import { TInfoTemplateSize } from 'contexts/more_info.reducer';
import { GenericObject } from 'containers/tributos-autonomicos-locales/utils';


export type TStepsContent = {  name: string, isMultiple: boolean, isFixedMultiple: boolean, component: () => JSX.Element, moreInfoTemplate?:string, moreInfoTemplateSize?: TInfoTemplateSize, formulaRepeticiones: string | null, xmlPrevio: GenericObject | null  }
interface IProps {
    steps: IStep[],//StepType,
    stepsContent: TStepsContent[],
    handleNext: () => void,
    handlePrev: () => void,
    handleFinish: () => void,
    activeStep: number, 
    activeStepFinished: boolean;
    withIMG?: boolean;
    srcIMG?:any
}


const StepperModels:  FC<IProps> = ({
    steps, stepsContent, handleNext, handlePrev, handleFinish, activeStep, activeStepFinished,
    withIMG=false, srcIMG
}) => {
    const classes = useStyles();
    const classesIcon = useColorlibStepIconStyles();
    const terms = useContext(LiteralsContext);
    const [showPrev,setShowPrev] = useState(false); 
    const [showNext,setShowNext] = useState(false); 
    const [showFinish,setShowFinish] = useState(false); 
    const [, infoDispatch] = useContext(MoreInfoContext);

    const ref = useRef(null);
    const [heightPaper, setHeightPaper] = useState(600);

    const handleShowMoreInfo = useCallback(() => {
        if (stepsContent[activeStep].moreInfoTemplate) {
            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: stepsContent[activeStep].moreInfoTemplate ?? '',
                    size: stepsContent[activeStep].moreInfoTemplateSize ?? undefined,
                }
            });
        }
    }, [infoDispatch, activeStep])
    

    useEffect( () => {
        setShowPrev(activeStep > 0) 
        setShowNext(activeStep < steps.length-1)
        setShowFinish(activeStep === steps.length-1)
    },[activeStep])

    useEffect(() => {
        // 👇️ use a ref (best)
        const element2 = ref.current;
        //console.log(element2);
    
        // 👇️ use document.querySelector()
        // should only be used when you can't set a ref prop on the element
        // (you don't have access to the element)
        const element = document.querySelector('#paper');
        element && setHeightPaper(element?.scrollHeight);
        //console.log(element?.scrollHeight);
      }, []);

    
    return (
        <Grid container direction={window.screen.width <= 960 ? 'column-reverse' : 'row'} style={{
            display: 'flex',
            flexGrow: 1,
            flexWrap: 'nowrap',
            minHeight: window.screen.width <= 960 ? undefined : '600px',
            width: '100%',
            }}
        >
            <Grid 
                item 
                xs={window.screen.width <= 960 ? 12 : 9} 
                style={{
                    flexGrow:1,
                    marginTop: window.screen.width <= 960 ? 10: 0 
                }}
            >
                <Card style={{
                        height: window.screen.width <= 960 ? '100%': heightPaper,
                        marginRight: window.screen.width <= 960 ? 10: undefined,
                        flexGrow: 1,
                        marginLeft: 10,
                    }}
                >
                    <CardHeader
                        title= {steps[activeStep].valor}
                        subheader={
                            stepsContent[activeStep].moreInfoTemplate 
                            ?
                                <Button variant="outlined" color="primary" className={classes.button} onClick={handleShowMoreInfo} size="small">
                                    <Term component="Global" text="Más información" />
                                </Button>
                            : null
                        }
                        action={
                            <Grid container direction='row' spacing={1}>
                                {showPrev && 
                                    <Grid item>
                                        <Button className={classes.stepperButton}
                                            variant='outlined' 
                                            onClick={handlePrev} 
                                            startIcon={<Icon path={mdiArrowLeft} size={1} className={classes.stepperIcon}></Icon>}
                                        >
                                            {translate('Tributos', 'btnPrevious',terms)}
                                        </Button>
                                    </Grid>
                                }
                                {showNext && 
                                    <Grid item>
                                        <Button className={classes.stepperButton}
                                            variant='outlined' 
                                            disabled={!activeStepFinished}
                                            onClick={handleNext}
                                            endIcon={
                                                <Icon 
                                                    path={mdiArrowRight} 
                                                    size={1} 
                                                    className={activeStepFinished ? classes.stepperIcon: classes.stepperDisabledIcon}
                                                ></Icon>
                                            } 
                                        >
                                            {translate('Tributos', 'btnNext',terms)}
                                        </Button>
                                    </Grid>
                                }
                                {showFinish &&
                                    <Grid item>
                                        <Button className={classes.stepperButton}
                                            variant='outlined' 
                                            disabled={!activeStepFinished}
                                            autoFocus={activeStepFinished}
                                            onClick={handleFinish} 
                                            startIcon={
                                                <Icon 
                                                    path={mdiContentSave} 
                                                    size={1}  
                                                    className={activeStepFinished ?classes.stepperIcon: classes.stepperDisabledIcon}
                                                />
                                            }
                                        >
                                            {translate('Tributos', 'btnFinish',terms)}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        }
                    />
                    <CardContent style={{height: '85%', overflowY:'auto'}}>
                        {stepsContent[activeStep].component()}
                    </CardContent>
                </Card>

            </Grid>
            <Grid item id='paper' ref={ref} 
                className={classes.stepperContainer}
                xs={window.screen.width <= 960 ? 12 : 3} 
                style={{
                    flexGrow:1, 
                    minHeight: window.screen.width <= 960 ? undefined : '600px',
                    marginTop: window.screen.width <= 960 ? 10: 0, 
                    background: '#fff'
                    }}
            >
                {/** DWA-15978: Imagen en el Header del Stepper */}
                {withIMG && 
                    <div style={{margin:20, display:'flex', flexGrow:1, alignItems: 'center',justifyContent:'center',height: 120}}>
                        <img alt='Conselleria' src={srcIMG} style={{objectFit: 'contain'}} />
                    </div>
                }
                <Stepper 
                    orientation={ window.screen.width <= 960 ? "horizontal" : "vertical"} 
                    activeStep={activeStep} 
                    connector={<ColorlibConnector style={{flexGrow : 0}}/>} 
                >
                    
                    {steps.map((step, index) => (
                        <Step key={step.idTipoApartado}>
                            <StepLabel StepIconComponent={ (props: StepIconProps) =>(
                                <div
                                    className={clsx(classesIcon.root, {
                                    [classesIcon.active]: props.active,
                                    [classesIcon.completed]: props.completed,
                                    })}
                                >
                                    {index}
                                </div>
                                )}
                            >
                                <div className={
                                    activeStep === index 
                                    ? classes.boldText 
                                    : activeStep > index  ? classes.completedText : classes.lightText
                                }>
                                    {step.valor.toUpperCase()}
                                </div>
                            </StepLabel>
                            <StepContent style={{flex: 'none'}}>{}</StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
           
        </Grid>
    );
}

export default withLiterals(['Tributos'])(StepperModels);



const useStyles = makeStyles( (theme: Theme): StyleRules =>({
    // STEPPER
    boldText: {
        color: 'black',
        fontWeight: 'bold'
    },
    completedText: {
        color: 'rgba(45,178,190,1)', 
    },
    lightText: {
        fontWeight: 'lighter',
        color: 'grey',
    },
    stepperContainer: {
        height: '100%',
        flexGrow: 1,
        marginLeft: 10,
        marginRight: 10, 
        borderRadius: 10,
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)',
            height: 'auto',
        },
        // box-shadow as paper
        boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',                 
    },
    stepperIcon: {
        fill: '#004f84',
    },
    stepperDisabledIcon:{
        fill: '#e0e0e0'
    },
    stepperButton: {
        color: '#004f84',
        borderColor: '#004f84',
    }
}));

const useColorlibStepIconStyles = makeStyles({
    root: {
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'lightgrey',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        fontWeight: 'bold',
        fontSize: '18px'
    },
    active: {
        backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage: 'linear-gradient(to right, rgba(45,178,190,1),rgba(168,220,249,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }
});


const ColorlibConnector = withStyles({
    line: {
        height: 3,
        border: 0,
        width: 2,
        backgroundColor: '#eaeaf0', 
        marginLeft: 12.5
    },
})(StepConnector);

