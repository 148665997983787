import React, { FC } from 'react';
import { Typography, List, ListItem, ListItemText, Divider, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { IModelGateway } from 'gateways/modelGateway.interface';
import Term from 'components/term';
import { esConceptoVivienda } from 'utils/concepto.vivienda';

const themeProvider = createMuiTheme({
    overrides: {
        MuiListItem: {
            gutters: {
                paddingLeft: 0,
                paddingRight: 0,
            }
        },
        MuiTypography: {
            body1: {
                fontWeight: 500,
                fontSize: 14,
            }
        }
    }
});

interface IProps {
    modelos: IModelGateway[];
}

const PagoTable: FC<IProps> = ({ modelos }) => {
    let importeTotal = 0;
    modelos.map((modelo) => importeTotal += modelo.modelo.importeAIngresar);

    return (
        <MuiThemeProvider theme={themeProvider}>
            <Typography variant="h6">
                <Term component="PasarelaPago" text="Deuda seleccionada" />
            </Typography>
            <List>
                {modelos.map(modelo => (
                    <>
                        <ListItem key={modelo.modelo.id}>
                            {
                                modelo.recibo ?
                                    esConceptoVivienda(modelo.recibo.idConcepto) ?
                                    <ListItemText primary={modelo.recibo.descripcion} secondary={modelo.recibo.direccionObjetoTributario} />
                                    :
                                    <ListItemText primary={modelo.recibo.descripcion} secondary={modelo.recibo.objeto} />
                                : 
                                modelo.modelo.importeAIngresar > 0 ?
                                    <ListItemText primary={modelo.modelo.id} secondary={modelo.modelo.nifSujetoPasivo} />
                                :
                                null
                            }
                        </ListItem>
                        <Divider />
                    </>
                ))}
            </List>
        </MuiThemeProvider>
    );
}

export default PagoTable;