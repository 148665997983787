import { Card, CardContent, CircularProgress, Button, TextField, Typography, WithStyles, withStyles, InputLabel, FormControl, Select, MenuItem } from "@material-ui/core";
import Icon from "@mdi/react";
import { styles } from "./styles";
import { RouteComponentProps } from "react-router";
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Term from "components/term";
import SearchIcon from "@material-ui/icons/Search"
import DocumentViewer from "./components/document-viewer/document-viewer";
import usePage from "hooks/page.hook";
import { mdiAlert, mdiFileDocument } from "@mdi/js";
import { DocumentosGateway } from "gateways/documentos.gateway";
import IoC from "contexts/ioc.context";
import { DocumentType, IDocDO, IDocListRN, IDocRARD, IDocResponse, IDocumentTypes } from "./cid.interface";
import DocumentList from "./components/document-list/document-list";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { RecaptchaGateway } from "gateways/recaptcha.gateway";
import { translate } from "utils/i18n";
import { download, downloadWithoutAlert } from "utils/download";
import { LiteralsContext } from "containers/shared/literals";
import { TributosGateway } from "gateways/tributos.gateway";
import { AlertsContext } from "contexts/alerts.context";
import DetailList from "./components/document-list/detail-list";
import DomiciliacionDetail from "./components/domiciliacion-info/domiciliacion-detail";

const documentTypes: IDocumentTypes[] = [
    { code: "RN", name: "Document RN" },
    { code: "RA", name: "Document RA" },
    { code: "RD", name: "Document RD" },
    { code: "DO", name: "Document DO" },
    { code: "VF", name: "Document VF" },
    { code: "JP", name: "Document JP" }
];


interface IProps extends WithStyles<typeof styles> {

}

const ConsultaCid: React.FC<IProps & RouteComponentProps> = ({ classes, history }) => {
    /* PAGE UTILS    */
    const [, pageDispatcher] = usePage();
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext)



    //USE STATE

    //LOADERS
    const [isLoading, setIsLoading] = useState(false);
    const [isListLoading, setIsListLoading] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false);

    //VARIABLES
    const [codigo, setCodigo] = useState<string>("");
    const [docType, setDocType] = useState<DocumentType>("DO")
    const [documento, setDocumento] = useState<IDocResponse | undefined>();
    const [docList, setDocList] = useState<IDocListRN[]>([]);


    // INFO MODALS VARIABLES
    const [selectedItem, setSelectedItem] = useState<IDocListRN>()
    const [infoDomi, setInfoDomi] = useState<IDocDO | undefined>(undefined);
    const [isError, setIsError] = useState(false);

    //BOOLEANS 
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(true);
    const [isDocOpen, setIsDocOpen] = useState<boolean>(false);
    const [isListOpen, setIsListOpen] = useState<boolean>(false);
    const [isListDetailOpen, setIsListDetailOpen] = useState<boolean>(false);
    const [isOpenDomiDetail, setIsOpenDomiDetail] = useState<boolean>();
    const [isDownloadable, setIsDownloadable] = useState<boolean>(false);

    /* GATEWAYS */
    const ioc = useContext(IoC);
    const documentosG = useMemo(() => ioc.get(DocumentosGateway) as DocumentosGateway, [ioc]);
    const tributosG = useMemo(() => ioc.get(TributosGateway) as TributosGateway, [ioc]);
    const recaptchaGateWay: RecaptchaGateway = ioc.get(RecaptchaGateway);

    /* RE-CAPTCHA */

    const { executeRecaptcha } = useGoogleReCaptcha();


    {/* PLACE FOR GETWAYS AND RECOVER METHODS */ }

    const handleRecover = useCallback(async () => {
        setIsError(false)
        setIsLoading(true)
        setIsDocOpen(false)
        setIsDownloadable(false)
        setIsListOpen(false)
        setIsSearchOpen(true)
        setDocumento(undefined)
        setDocList([])


        let recaptchaResult = '';
        const recaptchaCheckIPResult = await recaptchaGateWay.checkIP();

        if (recaptchaCheckIPResult && recaptchaCheckIPResult.useRecaptcha) {
            // Execute reCAPTCHA only if the IP is not whitelisted (useRecaptcha is true)
            // recaptchaResult = executeRecaptcha ? await executeRecaptcha('sede_v2_consulta_cid') : '';
            recaptchaResult = executeRecaptcha ? await executeRecaptcha('sede_v2_consulta_cid') : '';
        }

        if (!docType) {
            return
        }
        if (codigo === "") {
            handleError(translate('Tramites', 'noReferencia', terms));
            return;
        } else {
            if (docType === "VF" && codigo.length < 36) {
                handleError(translate('Tramites', 'referencia36Error', terms));
                return;
            }
            else if (codigo.length < 18) {
                handleError(translate('Tramites', 'referencia18Error', terms));
                return;
            }
        }
        let result

        let idReferencia = codigo.toUpperCase();

        result = await documentosG.getDocumentoByRef(docType, idReferencia, recaptchaResult);

        if (!result) {
            setIsError(true);
            setIsDocOpen(true);
            setIsLoading(false);
            return;
        }

        if (docType === "VF" && result?.docVF) {
            let document = result.docVF;
            if (!document.isCompleted) {
                handleError(translate('Tramites', 'no_pasos', terms));
                return;
            }
            if (!document.buffer || document.buffer === null) {
                setIsError(true);
                setIsDocOpen(true);
                setIsLoading(false);
                return;
            }
            setIsDownloadable(true);
            setDocumento(result);
            setIsDocOpen(true);
        }
        else {
            if (docType === "RN" && result.docRN) {
                setIsDocOpen(true)
                setDocumento(result)
                setIsDownloadable(false)
            }
            else if ((docType === "RD" || docType === "RA")) {
                //El documento se ha recuperado
                if (result?.docRARD?.isCobrable) {
                    //es cobrable
                    // Validamos si existe registro en el procedimiento para obtener emisora, referencia...
                    if (result?.docRARD.REFERENCIA) {
                        //REDIRIGIR A PAGO TELEMATICO
                        const params = new URLSearchParams({
                            emisora: result.docRARD.EMISORA,
                            referencia: result.docRARD.REFERENCIA,
                            identificacion: result.docRARD.IDENTIFICACION,
                            importe: result.docRARD.IMPORTE.toString(),
                        }).toString();

                        const url = `/cartero-virtual/pagoTelematico?${params}`;

                        history.push(url);

                    }
                    else {
                        handleError(translate('Tramites', 'aviso_caducado', terms));
                        return;
                    }
                }
                else {
                    handleError(translate('Tramites', 'aviso_no_cobrable', terms));
                    return;
                }
            }
            else if (docType === "JP" && result.docJP) {
                if (result?.docJP) {
                    setIsDownloadable(true)
                    setDocumento(result)
                    setIsDocOpen(true)
                } else {
                    handleError(translate('Tramites', 'tributo_no_pagado', terms));
                    return;
                }
            }
            else if (docType === "DO" && result?.docDo) {
                if (result.docDo === null) {
                    handleError(translate('Tramites', 'domiciliacion_sin_documento', terms));
                    return;
                }
                setDocumento(result)
                setIsDownloadable(true)
                setIsDocOpen(true)
            }
            else {
                setIsError(true)
                setIsDocOpen(true)
            }

        }

        setIsLoading(false)
    }, [documentosG.getDocumentoByRef, setIsDownloadable, setDocumento, codigo, docType, history])

    /* HANDELERS */

    const handleChangeCodigo = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCodigo(e.target.value.toUpperCase())
    }, [setCodigo])

    const handleDocType = useCallback((event: ChangeEvent<{ name?: string | undefined; value: any; }>) => {
        setDocType(event.target.value)
        setCodigo("")
    }, [setDocType]);

    const handleShowListItem = useCallback((item: IDocListRN) => {
        setSelectedItem(item)
        setIsListDetailOpen(true)
    }, [])

    const handleError = useCallback((text: string) => {
        alertsDispatch({
            type: 'show-alert',
            payload: {
                message: text,
                variant: 'error',
                hideButtonAction: false,
                hasCustomAction: false,
            }
        });
        setIsLoading(false);
    }, [])


    const handleRecoverLista = useCallback(async (codigo: string, docType: string) => {
        setIsListLoading(true)
        let result = await documentosG.getListadoDocumento(codigo, docType)
        if (result !== null) {
            setDocList(result)
        }
        setIsListLoading(false)
    }, [documentosG.getListadoDocumento])


    const handleListado = useCallback((docType: string, codigo: string) => {
        setIsSearchOpen(!isSearchOpen)
        setIsListOpen(!isListOpen)
        if (!docList || docList.length === 0) {
            handleRecoverLista(docType, codigo)
        }
    }, [isSearchOpen, isListOpen, docList])

    const handleOpenDomiModal = (domi: IDocDO) => {
        setIsOpenDomiDetail(true)
        setInfoDomi(domi)
    }

    const handleCloseDomiModal = () => {
        setIsOpenDomiDetail(false)
        setInfoDomi(undefined)
    }

    const handleDownloadDoc = useCallback((buffer: any | undefined) => {
        setIsDownloading(true)
        if (!buffer) {
            handleError(translate('Tramites', 'error_descarga_fichero', terms));
            return;
        }
        const typedArray = new Uint8Array(buffer.data);
        if (isDownloadable && typedArray) {
            const blob = new Blob([typedArray], { type: 'application/pdf' });
            if (blob) {
                download(
                    blob,
                    alertsDispatch,
                    translate('Domiciliaciones', 'Ha ocurrido un error al generar el justificante del recibo domiciliado', terms),
                    translate('Global', 'BloqueoPantalla', terms)
                );
            }
        } else {
            //ERRORRRRR DOWNLOADING THE FILE
            handleError(translate('Tramites', 'error_descarga_fichero', terms));
        }
        setIsDownloading(false)
        return
    }, [documento, isDownloadable])

    const handleDownloadJP = useCallback(async () => {
        setIsDownloading(true)
        if (documento && documento.docJP) {
            const result = await documentosG.getDocumentoNotificacion(codigo);
            if (result) {
                download(
                    result,
                    alertsDispatch,
                    translate('Domiciliaciones', 'Ha ocurrido un error al generar el justificante del justificante de pago', terms),
                    translate('Global', 'BloqueoPantalla', terms)
                );
            } else {
                handleError(translate('Tramites', 'error_descarga_fichero', terms));
            }
        } else {
            handleError(translate('Tramites', 'error_descarga_fichero', terms));
        }
        setIsDownloading(false)
    }, [documentosG.getDocumentoNotificacion, documento])




    const handleDownloadDomi = useCallback(async () => {
        setIsDownloading(true)
        if (documento && documento.docDo) {
            const result = await documentosG.getJustificantePagoTributoGrupoCID(documento.docDo.domi.DF_Nif, [documento.docDo.domi.idRecibo.toString()]);
            if (result) {
                download(
                    result,
                    alertsDispatch,
                    translate('Tramites', 'Ha ocurrido un error al generar el justificante', terms) + ".",
                    translate('Global', 'BloqueoPantalla', terms)
                );
            }
        }
        setIsDownloading(false)
    }, [documentosG.getJustificantePagoTributoGrupoCID, documento]);


    /* USE EFFECTS */
    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiFileDocument,
                title: <Term text="consultaPorCodigoSeguroDeVerificacion" component="Tramites" />
                //TODO Implementar el botón de ms información
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);


    return (
        <div className={classes.root}>
            {
                isLoading
                    ?
                    <div className={classes.centerContent}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {/*LEFT CARD*/}
                        {isSearchOpen &&
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                        <Term text='consultaPorCodigoSeguroDeVerificacion' component='Tramites' />
                                    </Typography>
                                    <Typography style={{ textAlign: 'justify' }}>
                                        <Term component='Tramites' text='RecoverTextInfo' />
                                    </Typography>
                                    <div className={classes.row} style={isDocOpen ? {} : { width: '50%' }} >
                                        <FormControl className={classes.selectorTramites} variant="outlined" margin="dense" required>
                                            {docType === undefined &&
                                                <InputLabel>
                                                    <Term text="selectDocType" component="Tramites" />
                                                </InputLabel>
                                            }
                                            <Select
                                                value={docType}
                                                onChange={handleDocType}
                                            >
                                                {documentTypes.map((type: { code: DocumentType, name: string }, i: number) => {
                                                    return (
                                                        <MenuItem key={`tipoDocumento${i}__CID`} value={type.code}>
                                                            <Typography>
                                                                <Term component="Global" text="Documento"></Term>
                                                                {" " + type.code}
                                                            </Typography>
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            variant='outlined'
                                            margin='dense'
                                            onChange={(e) => handleChangeCodigo(e)}
                                            value={codigo || ""}
                                            label={<Term text='numExpediente' component="Tramites" />}
                                            hiddenLabel
                                            className={classes.textField}
                                            required
                                        />


                                    </div>

                                    <Button
                                        style={{ width: 'fit-content', marginTop: 40, backgroundColor: '#2196f3' }}
                                        variant='contained'
                                        onClick={handleRecover}
                                    >
                                        <Typography style={{ color: 'white' }}>
                                            <Term text='verDocumento' component="Tramites" />
                                        </Typography>
                                        <SearchIcon style={{ color: 'white' }} />
                                    </Button>                                    
                                </CardContent>
                            </Card>
                        }



                        {/* LEFT LIST */}
                        {isListOpen && documento &&
                            <DocumentList
                                isLoading={isListLoading}
                                documento={documento}
                                items={docList}
                                handleShowInfo={handleShowListItem}
                            />
                        }

                        {/*RIGHT CARD*/}
                        {isDocOpen &&
                            <DocumentViewer
                                document={documento}
                                isSearchOpen={isSearchOpen}
                                isDownloading={isDownloading}
                                isError={isError}
                                canDownload={isDownloadable}
                                handleListado={handleListado}
                                handleDownloadDomi={handleDownloadDomi}
                                handleDownloadJP={handleDownloadJP}
                                handleDownloadDoc={handleDownloadDoc}
                                handleOpenInfoDomi={handleOpenDomiModal}
                            />
                        }


                        {/* DETAIL MODALS : DOMICILIACIONES Y ELEMENTOS LISTA DOCUMENTO RN */}
                        {isListDetailOpen && isListOpen && documento &&
                            <DetailList
                                open={isListDetailOpen}
                                item={selectedItem}
                                handleClose={() => setIsListDetailOpen(!isListDetailOpen)}
                            />
                        }

                        {isDocOpen && isOpenDomiDetail && infoDomi &&
                            <DomiciliacionDetail
                                handleClose={handleCloseDomiModal}
                                item={infoDomi}
                                open={isOpenDomiDetail}
                            />
                        }

                    </>
            }

        </div >
    )
}
export default withStyles(styles)(ConsultaCid);