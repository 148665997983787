import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import IoC from 'contexts/ioc.context';
import { WithStyles, withStyles, CircularProgress, Hidden, MenuItem } from '@material-ui/core';
import usePage from '../../hooks/page.hook';
import { SujetosGateway } from 'gateways/sujetos.gateway';
import { ISujeto } from 'gateways/perfil.interfaces';
import PersonalData from './personal.data';
import SectionAvisos from './section.avisos';
import { mdiShieldAccount } from '@mdi/js';
import Term from 'components/term';
import SectionNotificaciones from './section.notificaciones';
import styles from '../shared/perfil.styles';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import { MoreInfoContext } from 'contexts/more_info.context';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';

interface IProps extends WithStyles<typeof styles> {

}

const Perfil: React.FC<IProps> = ({ classes }) => {
    const [pageState, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);
    const terms = useContext(LiteralsContext);
    const [loading, setLoading] = useState(false);
    const [sujeto, setSujeto] = useState<ISujeto | null>(null);
    // const [diasCortesia, setDiasCortesia] = useState<IDiasCortesia>();

    const ioc = useContext(IoC);
    const perfilG = useMemo(() => ioc.get(SujetosGateway) as SujetosGateway, [ioc]);

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch]);

    const handleChangeSujeto = (sujetoLocal: ISujeto) => {
        setSujeto(sujetoLocal);
    }

    const handleLoadSujeto = async () => {
        setLoading(true);
        try {
            // const now = new Date();
            const sujetoLocal = await perfilG.getDatosSujeto();
            if (sujetoLocal) {
                setSujeto(sujetoLocal);
            } else {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Perfil', 'Error datos sujeto', terms),
                        variant: 'error',
                    }
                });
            }
            // const diasCortesiaLocal = await notificacionesGateway.getDiasCortesia(now.getFullYear());
            // setDiasCortesia(diasCortesiaLocal);                
        }
        catch (e) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (e as any).message,
                    variant: 'error',
                }
            });
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            handleLoadSujeto()
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perfilG, pageDispatcher]);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiShieldAccount,
                title: <Term text="Area Personal" />,
                moreInfoTemplate: 'mas_info_area_personal',
                right: <MenuItem button onClick={() => handleShowMasInfo('mas_info_area_personal')}><Term component="Global" text="Mas informacion" /></MenuItem>
            },
            menu: true,
        });
    }, [pageDispatcher]);

    return (
        <div className={classes.root}>
            {
                loading || sujeto === null
                    ? <div className={classes.centerContent}>
                        <CircularProgress size={35} />
                    </div>
                    :
                    <>
                        <div className={classes.contenido}>
                            <Hidden mdUp implementation="css">
                                <PersonalData
                                    sujeto={sujeto}
                                    direccion="column" />
                            </Hidden>
                            <Hidden smDown implementation="css">
                                <PersonalData
                                    sujeto={sujeto}
                                    direccion="row" />
                            </Hidden>
                        </div>
                        <div className={classes.contenido}>
                            <div className={classes.containerSections}>
                                <SectionAvisos sujeto={sujeto} onChange={handleChangeSujeto} />
                                <SectionNotificaciones sujeto={sujeto} />
                            </div>
                        </div>
                        {/* <div className={classes.contenido}>
                            <SectionDiasCortesia 
                                showDiasCortesia={sujeto.notificacion && !sujeto.notificacionPendienteConfirmacion && !sujeto.adhesionDeOficio}
                                diasCortesia={diasCortesia ? diasCortesia : null} 
                            />
                        </div> */}
                    </>
            }
        </div>

    );
}

export default withStyles(styles)(Perfil);