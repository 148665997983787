import React, { useEffect, useState, useContext, useMemo, useReducer, useCallback } from 'react';
import IoC from 'contexts/ioc.context';
import { WithStyles, withStyles, CircularProgress, Theme, Hidden, MenuItem, Dialog, DialogTitle, DialogContent, Button, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import usePage from '../../../hooks/page.hook';
import { StyleRules } from '@material-ui/styles';
import { mdiReload, mdiShieldCar } from '@mdi/js';
import Term from 'components/term';
import { TramitesGateway } from 'gateways/tramites.gateway';
import { IMultas, IFilterData, IOrderData, IRemesaMultas } from 'gateways/tramites.interfaces';
import FiltersReducer, { IFiltersState } from './filters.reducer';
import { FiltersContext } from './filters.context';
import { FilterDataContext } from './filter-data.context';
import FiltersActive from './filters.active';
import FiltersDialog from './filters.dialog';
import { green } from '@material-ui/core/colors';
import PanelDrawer from './panel.drawer';
import NoContent from 'components/no-content';
import image from '../../../resources/no-deuda.png';
import InfiniteScroll from 'react-infinite-scroller';
import MultaCard from './multas.card';
import { RouteComponentProps, useLocation } from 'react-router';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import OrderReducer, { IOrderState } from './order.reducer';
import { OrderContext } from './order.context';
import { OrderDataContext } from './order-data.context';
import OrderDialog from './order.dialog';
import { Icon } from '@mdi/react';
import { mdiFileDownload } from '@mdi/js';
import { mdiFileImportOutline } from '@mdi/js';
import { mdiFileExportOutline } from '@mdi/js';
import { download } from 'utils/download';
import { AlertsContext } from 'contexts/alerts.context';
import TableWizard from '../../../components/table-wizard';
import WizardImporter from '../../../components/wizard';
import { ImporterGateway } from 'gateways/importer.gateway';
import { IMessageResponse, IResult } from 'gateways/importer.interfaces';
import TableWizardResult from 'components/table-wizard-result';
import { MoreInfoContext } from 'contexts/more_info.context';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';
import CustomTabs from 'components/custom-tabs';
import TabPanel from 'components/tab-panel';
import MultaRemesaCard from './multa-remesa.card';
import { FILE_EXTENSION_CSV, FILE_EXTENSION_XLSX } from 'constants/file-extension-types';
import useSujeto from 'hooks/sujeto.hook';
import CustomCountTerm from 'components/count-term/count-term';
import IdentificarConductor from './conductor_tabs/identificar-conductor';
import Remesas from './conductor_tabs/remesas';

export const styles = (theme: Theme): StyleRules => ({
    root: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            flexShrink: 0,
            width: 300,
        },
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    progressContainer: {
        textAlign: 'center',
        marginTop: 30
    },
    recibosContainer: {
        flex: 1,
        margin: '0 0.5rem'
    },
    [theme.breakpoints.up('md')]: {
        root: {
            display: 'flex',
        },
        recibosContainer: {
            margin: '0 2rem'
        },
    },
    centerContent: {
        margin: '0 auto',
    },
    identificacionDialog: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '1000px !important',
            minHeight: '325px !important',
        }
    },
    actionsContainer: {
        display: 'inline-flex',
        marginTop: 20,
        width: '100%',
        height: 100
    },
    actionContent: {
        marginRight: 20,
    },
    actionIcon: {
        width: 42,
        heigth: 42,
        fill: 'grey'
    },
    border: {
        border: '1px solid lightgrey',
        borderRadius: 10
    },
    formControlRadioBtns: {
        gap: '1rem',
        marginTop: 5
    },
    // FLEX
    alignCenter: {
        alignItems: 'center',
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
});

interface IProps extends WithStyles<typeof styles> { }

const numberElementsPerPage = 30;
const numberElementsPerRemesaPage = 20;

const Conductor: React.FC<IProps & RouteComponentProps> = ({ classes, history }) => {
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const { sujeto } = useSujeto();
    const location = useLocation();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);

    //count 
    const [multasCount, setMultasCount] = useState(0);
    const [remesasMultasCount, setRemesasMultasCount] = useState(0);
    const [totalRemesasMultasCount, setTotalRemesasMultasCount] = useState(0);


    const [loading, setLoading] = useState(false);

    const filtersReducer = useReducer(FiltersReducer, { estado: ['Identificable'], fechaRemesa: null, fechaLimiteDesde: null, fechaLimiteHasta: null, fechaMultaDesde: null, fechaMultaHasta: null });
    const orderReducer = useReducer(OrderReducer, { orden: 1 });
    const [page, setPage] = useState(0);
    const [filters, filtersDispatch] = filtersReducer;
    const [order,] = orderReducer;
    
    const [filterData, setFilterData] = useState<IFilterData>({});
    const [orderData, setOrderData] = useState<IOrderData>({});
    const [showFilters, setShowFilters] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [isRentACar, setIsRentACar] = useState(false);

  
   
    const ioc = useContext(IoC);
    const tramitesG = useMemo(() => ioc.get(TramitesGateway) as TramitesGateway, [ioc]);
    const [tabActive, setTabActive] = useState(0);
    const [pemisoConvenioRemesas, setPemisoConvenioRemesas] = useState<boolean>(false);
  

    const [showExportImportDialog, setShowExportImportDialog] = useState<boolean>(false);

    // Events
 
    const handleShowFilters = useCallback(() => {
        setShowFilters(true);
    }, []);

    const handleShowOrder = useCallback(() => {
        setShowOrder(true);
    }, []);


    const handleHideFilters = useCallback(() => setShowFilters(false), []);
    const handleHideOrder = useCallback(() => setShowOrder(false), []);

    const buildQueryRequest = useCallback((newFilters: IFiltersState, newOrder: IOrderState, newPage: number, exportData?: boolean) => {
        return Object.assign({}, newFilters, newOrder, {
            skip: exportData ? (0) : (newPage * numberElementsPerPage),
            take: exportData ? (multasCount > 0 ? multasCount : numberElementsPerPage) : (numberElementsPerPage)
        });
    }, [multasCount, numberElementsPerPage]);

    const buildQueryRequestPorRemesa = useCallback((newFilters: IFiltersState, newOrder: IOrderState, newPage: number, exportData?: boolean) => {
        return Object.assign({}, newFilters, newOrder, {
            skip: exportData ? (0) : (newPage * numberElementsPerRemesaPage),
            take: exportData ? (multasCount > 0 ? multasCount : numberElementsPerRemesaPage) : (numberElementsPerRemesaPage)
        });
    }, [remesasMultasCount, numberElementsPerRemesaPage]);



    const handleFiltersChanged = useCallback((newFilters: IFiltersState) => {
        setPage(0);
        //handleLoadMultas(newFilters, order, 0, tabActive);
    }, [/*handleLoadMultas,*/ order, tabActive]);

    const handleOrderChanged = useCallback((newOrder: IOrderState) => {
        setPage(0);
        //handleLoadMultas(filters, newOrder, 0, tabActive);
    }, [filters/*, handleLoadMultas*/])



    const handleShowIdentificacionDialog = useCallback(() => {
        setShowExportImportDialog(true);
    }, []);

    const handleCloseExportImportDialog = () => {
        setShowExportImportDialog(false);
        //handleLoadMultas(filters, order, page, tabActive);
    }

    const handleExportRequest = useCallback(async () => {
        const query = buildQueryRequest(filters, order, 0, true);
        const response = await tramitesG.exportarMultas(query);
        download(response.blob, alertsDispatch,
            translate('Global', 'No se ha podido exportar', terms),
            translate('Global', 'Exportar', terms), response.fileName);
    }, [buildQueryRequest, filters, order, page, tramitesG, alertsDispatch, terms]);

    const handleDownloadDatos = useCallback(async () => {
        const response = await tramitesG.downloadDatosParaIdentificar();        // setLoadingIdentificacionAccion(false);
        download(response.blob, alertsDispatch,
            translate('Global', 'No se ha podido exportar', terms),
            translate('Global', 'Exportar', terms), response.fileName);
    }, [alertsDispatch, terms, tramitesG]);

    const handleExportPlantillaNotificaciones = useCallback(async (fileExtension: 'csv' | 'xlsx') => {
        let response;
        if (tabActive === 0) {
            const query = buildQueryRequest(filters, order, 0, true);
            query.take = multasCount;
            query['estado'] = ['Identificable'];
            query.fileExtension = fileExtension;

            if (sujeto && sujeto.nif) {
                query.nifSujeto = sujeto.nif;
            }
            response = await tramitesG.exportarPlantillaMultas(query);
        } else {
            const query = buildQueryRequestPorRemesa(filters, order, 0, true);
            query.take = remesasMultasCount //multasRemesa.length;
            query['estado'] = ['Identificable'];
            query.fileExtension = fileExtension;
            if (sujeto && sujeto.nif) {
                query.nifSujeto = sujeto.nif;
            }
            response = await tramitesG.exportarPlantillaMultasRemesa(query);
        }
        if (response) {
            download(response.blob, alertsDispatch,
                translate('Global', 'No se ha podido exportar', terms),
                translate('Global', 'Exportar', terms), response.fileName);
        }
    }, [buildQueryRequest, buildQueryRequestPorRemesa, filters, order, page, multasCount, tramitesG, alertsDispatch, terms, /*multaRemesaSelected, multasRemesa,fileExtension*/]);
  

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch]);

    const handlePrintJustificante = async (listaMultasIdentificadas:any) => {
        setLoading(true)
        let response: any;
        try {
            if (listaMultasIdentificadas && listaMultasIdentificadas.length > 0) {
                response = await tramitesG.printJustificanteIdentificacionMasiva(listaMultasIdentificadas);
                download(response.blob, alertsDispatch, translate('CitaPrevia', 'Ha ocurrido un error', terms), 'JustificanteIdentificacionMasiva')
                setLoading(false);
                //setOpen(false);
                return true
            }
        }
        catch {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message:
                        <Term component="CitaPrevia" text="Ha ocurrido un error" />,
                    variant: 'warning',
                }
            })
        }
        setLoading(false);
        return false
    }

    const handleChange = (_E: React.ChangeEvent<{}>, newValue: number) => {
        setTabActive(newValue);
        handleCleanFilters(newValue);
    };

    const handleCleanFilters = (tabActive: number) => {
        if (tabActive === 0) {
            filtersDispatch({ type: 'update', filter: 'idRemesa', value: null });
            filtersDispatch({ type: 'update', filter: 'fechaRemesa', value: null });
        }
    };

    const handleSelectRemesa = async (multaRemesaSelected: IRemesaMultas) => {
      filtersDispatch({ type: 'update', filter: 'idRemesa', value: multaRemesaSelected.idRemesa });
    }


    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiShieldCar,
                title: <Term text="Identificar conductor" />,
                moreInfoTemplate: 'mas_info_identificacion_con_autenticacion',
                right: (
                    <div>
                        <MenuItem button onClick={handleShowFilters}><Term component="Global" text="Filtrar resultados" /></MenuItem>
                        <MenuItem button onClick={handleShowOrder}><Term component="Global" text="Ordenar resultados" /></MenuItem>
                        <MenuItem button onClick={() => handleShowMasInfo("mas_info_identificacion_con_autenticacion")}><Term component="Global" text="Mas informacion" /></MenuItem>
                    </div>
                )
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);


    useEffect(() => {
        console.log('filters ', filters)
        const query: any = buildQueryRequest(filters, order, 0);
        delete query.skip;
        delete query.take;
        const asyncEffect = async () => {
            const filterDataTemp = await tramitesG.getFilterData(query);
            if (!!filterDataTemp.gravedad) {
                for (const g of filterDataTemp.gravedad) {
                    g.label = translate('Tramites', 'gravedad_' + g.value.toLowerCase(), terms);
                }
            }
            if (!!filterDataTemp.estado) {
                for (const g of filterDataTemp.estado) {
                    g.label = translate('Tramites', 'estado_' + g.value.toLowerCase(), terms);
                }
            }
            setFilterData(filterDataTemp);
        };

        asyncEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tramitesG, filters]);

    useEffect(() => {
        const query: any = buildQueryRequest(filters, order, 0);
        delete query.skip;
        delete query.take;
        const asyncEffect = async () => {
            const orderDataTemp = await tramitesG.getOrderData(query);
            setOrderData(orderDataTemp);
        };

        asyncEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tramitesG, order]);

    useEffect(() => {
        const asyncEffect = async () => {
            const isRentACar = await tramitesG.isRentACar();
            setIsRentACar(isRentACar);
            const permisoConvenioRemesasUsuario = await tramitesG.getPermisoConvenioRemesas();
            setPemisoConvenioRemesas(permisoConvenioRemesasUsuario)
        };

        asyncEffect();
    }, [])

    return (
        <div className={classes.root}>
            {loading
                ?
                <div className={classes.centerContent}>
                    <CircularProgress />
                </div>
                :
                <>
                    <OrderContext.Provider value={orderReducer}>
                        <OrderDataContext.Provider value={orderData}>
                            <FiltersContext.Provider value={filtersReducer}>
                                <FilterDataContext.Provider value={filterData}>
                                    <div className={classes.root}>
                                        <div className={classes.recibosContainer}>
                                            <FiltersActive
                                                onChange={handleFiltersChanged}
                                            />
                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '1%', backgroundColor: 'white', borderRadius: '3px' }}>
                                                <CustomTabs
                                                    labelTab1={translate('Tramites', 'identificar_conductor', terms)}
                                                    labelTab2={pemisoConvenioRemesas ? translate('Tramites', 'identificar_conductor_remesas', terms) : null}
                                                    onChange={handleChange}
                                                    tabActive={tabActive}
                                                />
                                                <CustomCountTerm totalCount={
                                                    tabActive == 0 
                                                    ? <Term component="Tramites" text={'TituloMultas'} params={[multasCount]} /> 
                                                    : <Term component="Tramites" text={'TituloMultasRemesa'} params={[remesasMultasCount,totalRemesasMultasCount]} />} />
                                            </div>
                                            <TabPanel value={tabActive} index={0}>
                                                <IdentificarConductor 
                                                    classes={classes}
                                                    isRentACar={isRentACar}
                                                    filters={filters}
                                                    handleMultasCount={(num) => setMultasCount(num)}
                                                    showExportImportDialog={showExportImportDialog}
                                                    handleCloseExportImportDialog={handleCloseExportImportDialog}
                                                    handleDownloadDatos={handleDownloadDatos}
                                                    handlePrintJustificante={handlePrintJustificante}
                                                    handleExportPlantillaNotificaciones={handleExportPlantillaNotificaciones}
                                                    //handleFiltersChanged={handleFiltersChanged}
                                                />
                                                
                                            </TabPanel>
                                            {pemisoConvenioRemesas &&
                                                <TabPanel value={tabActive} index={1}>
                                                    <Remesas
                                                        classes={classes}
                                                        isRentACar={isRentACar}
                                                        filters={filters}
                                                        handleRemesasMultasCount={(num) => setRemesasMultasCount(num)} 
                                                        handleTotalRemesasMultasCount={(num) => setTotalRemesasMultasCount(num)} 
                                                        handleSelectRemesa={handleSelectRemesa }                                                        
                                                        showExportImportDialog={showExportImportDialog}
                                                        handleCloseExportImportDialog={handleCloseExportImportDialog}
                                                        handleDownloadDatos={handleDownloadDatos}
                                                        handlePrintJustificante={handlePrintJustificante}
                                                        handleExportPlantillaNotificaciones={handleExportPlantillaNotificaciones}
                                                    />
                                                    
                                                </TabPanel>
                                            }
                                        </div>
                                        <div className={classes.drawer}>
                                            <Hidden mdUp implementation="css">
                                                <FiltersDialog
                                                    open={showFilters}
                                                    screen={'conductor'}
                                                    onClose={handleHideFilters}
                                                    onChange={handleFiltersChanged}
                                                />
                                                <OrderDialog
                                                    open={showOrder}
                                                    screen={'conductor'}
                                                    onClose={handleHideOrder}
                                                    onChange={handleOrderChanged}
                                                />
                                            </Hidden>

                                            <Hidden smDown implementation="css">
                                                <PanelDrawer
                                                    screen={'conductor'}
                                                    activeTab={tabActive}
                                                    onFiltersChange={handleFiltersChanged}
                                                    onExportClick={handleExportRequest}
                                                    onIdentificacionClick={handleShowIdentificacionDialog}
                                                />
                                            </Hidden>
                                        </div>
                                    </div>
                                    
                                </FilterDataContext.Provider>
                            </FiltersContext.Provider >
                        </OrderDataContext.Provider>
                    </OrderContext.Provider>
                </>
            }
        </div>

    );
}

export default withStyles(styles)(Conductor);