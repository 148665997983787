import React, { FC } from "react";
import { Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import TextNumber from '../../components/text-number';
import Term from "components/term";
import styles from './pago.styles';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IModelGateway } from "gateways/modelGateway.interface";

type InheritsProps = WithStyles<typeof styles> & RouteComponentProps

export interface IProps extends InheritsProps {
    items: IModelGateway[];
    visible: boolean;
}

const TotalPago: FC<IProps> = ({ classes, items, visible }) => {
    const totalPrice = items.length > 0 ? items.map((x: IModelGateway) => x.modelo.importeAIngresar).reduce((a: number, b: number) => a + b) : 0;

    return (        
        <>                        
            <Grid container className={[classes.rootTotal, visible ? classes.display : classes.hidden].join(' ')}>                            
                <Grid item xs={6} className={classes.priceInfo}>
                    <Typography className={classes.totalPriceSelected}>
                        <Term component="PasarelaPago" text="Total a pagar" />
                    </Typography>
                    <TextNumber className={classes.totalPrice} value={totalPrice} options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }} />
                </Grid>                
            </Grid>
        </>
    )
}


export default withRouter(withStyles(styles)(TotalPago));