import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { green, grey, orange } from "@material-ui/core/colors";

const styles = (theme: Theme): StyleRules => ({
    // GENERAL CLASSS
    // Width & Height
    wFull: {
        width: '100%'
    },
    w250: {
        width: 250
    },
    w30: {
        width: 30
    },
    fitContent: {
        height: 'fit-content'
    },
    // Margins & Paddings
    p20: {
        padding: 20
    },
    m0: {
        margin: 0
    },
    mB0: {
        marginBottom: 0
    },
    m20: {
        margin: 20
    },
    mTop20: {
        marginTop: 20
    },
    mBottom30: {
        marginBottom: 30
    },
    // Position
    relative: {
        position: 'relative'
    },
    // Flex
    flex1: {
        flex: 1
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexCol: {
        display: 'flex',
        flexDirection: 'column',
    },
    flexNoWrap: {
        flexWrap: 'nowrap'
    },
    // Flex positioning
    justifyCenter: {
        justifyContent: 'center',
    },
    justifyEnd: {
        justifyContent: 'flex-end'
    },
    alignCenter: {
        alignItems: 'center'
    },
    // Grid
    grid: {
        display: 'grid',
        gridTemplateColumns: 'auto'
    },
    // Gaps
    gap20: {
        gap: '20px'
    },
    gap10: {
        gap: '10px'
    },
    // Text
    bold: {
        fontWeight: 'bold'
    },
    fontL: {
        fontSize: 18
    },
    fontM: {
        fontSize: 15
    },
    fontS: {
        fontSize: 13
    },
    // Colors
    grey: {
        color: '#666666'
    },
    blueSEDE: {
        color: '#038cbe'
    },
    // Borders
    borderBottomGrey: {
        borderBottom: '1px solid #dfdfdf'
    },
    // OTHER STYLES
    imagenContainer: {
        width: 40,
        height: 40,
        backgroundColor: '#92b58e',
        borderRadius: '50%',
        marginRight: 15
    },
    imageGrid: {
        alignSelf: 'center'
    },
    highlightText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#000',
        fontSize: 12,
        fontWeight: 700
    },
    detailText: {
        color: '#000',
        fontSize: 12,
    },
    cardContainer: {
        flex: 1,
        // margin: '0 2rem',
        margin: '0 20px',
        padding: 25,
        // [theme.breakpoints.up('md')]: {
        //     marginRight: 300,
        // }
    },
    price: {
        marginLeft: 10,
        marginRight: 10,
        //color: '#406a8d',
        fontSize: 12,
        fontWeight: 700,
        [theme.breakpoints.up('md')]: {
            fontSize: 18
        }
    },
    row: {
        display: "flex",
        flexDirection: 'row'
    },
    column: {
        display: "flex",
        flexDirection: 'column'
    },
    title: {
        marginBottom: 30,
        fontSize: '1.5rem',
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: 1.334,
        letterSpacing: '0em',
    },
    text: {
        marginBottom: 50,
        display: 'flex-inline'
    },
    textRed: {
        color: 'red',
        fontWeight: 'bold'
    },
    divider: {
        height: 1,
        marginBottom: 5,
        backgroundColor: "black",
        opacity: 1,
    },
    subTitle: {
        // fontWeight: 'bold',
        //fontSize: 16,
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: '1.3rem',
        lineHeight: 1.334,
        letterSpacing: '0em',
    },
    fechaFin: {
        color: 'red',
        fontWeight: 300,
        marginLeft: 10
    },
    content: {
        padding: 10
    },

    // not used -- 

    contentRoot: {
        flexWrap: 'nowrap'
    },
    grow: {
        flexGrow: 1
    },
    pagoCartaText: {
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5,
        fontWeight: 'bold',
        color: '#004f82',
    },
    etiquetaText: {
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5,
        fontWeight: 'bold',
        color: '#004f82',
    },

    detailTextWarning: {
        color: '#ff0000',
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5
    },
    tagDomiciliados: {
        fontSize: 12,
        padding: '0px 5px',
        backgroundColor: '#E1E1E1',
        borderRadius: '2px',
        maxWidth: '100px',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    tagPagoCarta: {
        fontSize: 12,
        padding: '0px 5px',
        backgroundColor: '#C6E5FD',
        borderRadius: '2px',
        //maxWidth: '100px',
        width: 'fit-content',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    gridDomi: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },

    priceEjecutiva: {
        color: '#ca261b'
    },

    imagen: {
        width: 40,
        height: 40
    },
    checked: {
        backgroundColor: '#fff',
        borderRadius: '50%',
        border: '1px solid #000',
        '& svg': {
            margin: '0 auto',
        }
    },

    fullButton: {
        width: '100%'
    },
    width0: {
        width: 0
    },
    btnAddPay: {
    },
    containerBtnPagoCarta: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    btnPagoCarta: {
        color: 'white',
        width: 'fit-content',
    },
    btnDomi: {
        width: 175,
    },
    minWidth90: {
        minWidth: 90,
    },
    widthBtnDomiGrid205: {
        minWidth: 205
    },
    btnMoreInfo: {
        marginRight: 10
    },
    bannerBtn: {
        width: '100%',
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        gap: '10px'
    },
    priceLabel: {
        display: 'none',
    },
    // Specific classes - Seleccionar Modo de Pago a cuenta
    cardSeleccionarFormaPago: {
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 10,

        padding: 15,

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    btnFormaDePagoPadding: {
        paddingRight: 30,
        paddingLeft: 30
    },
    imgDimension: {
        width: 75,
        height: 75
    },
    // Specific classes - Pago a Cuenta Domiciliado
    containerPagoCuentaDomi: {
        marginLeft: 15,
        marginRight: 15
    },
    containerPlanPagos: {
        gap: '15px',
        marginBottom: 15
    },
    conatienrCardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        gap: '20px'
    },
    containerSelectorAndIcon: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: '10px'
    },
    cardPlanPago: {
        width: '50%',
        padding: 10
    },
    cardContentPlanPagoAutomatico: {
        display: 'flex',
        gap: '25px'
    },
    cardContentPlanPago: {
        display: 'flex',
        flexDirection: 'column',
        gap: '25px'
    },
    radioBtn: {
        width: '35%'
    },
    verPlanBtn: {
        backgroundColor: 'grey',
        fontSize: 13,
        color: 'white',
        minWidth: 150
    },
    containerGridCalendar: {
        minHeight: 200,
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        justifyItems: 'center',
        gap: '20px'
    },
    gridLeyendaCalendario: {
        minWidth: 250,

        display: 'flex',
        flexDirection: 'column',

        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 1,
        gridRowEnd: 2,

        padding: 10,
        gap: '10px'
    },
    containerSpanLeyenda: {
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
    },
    spanLeyendaCalendario: {
        width: 20,
        height: 20,
    },
    containerPeriocidadEImportes: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 10
    },
    childContainerPeriocidadEImportes: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '10px'
    },
    spanPeriocidadEImportes: {
        width: 15,
        height: 15,
        marginTop: 5,
        display: 'block',
        backgroundColor: '#038cbe'
    },
    // Fecha Voluntaria Domi
    containerBtnsModifDomi: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px'
    },
    // Confirmación Pago a Cuenta
    cardSpacing: {
        padding: 20,
        margin: '0px 20px'
    },
    titleTextCard: {
        fontSize: '1.5rem',
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: 1.334,
        letterSpacing: '0em',
    },
    subtitleText: {
        fontSize: '1.3rem',
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: 1.334,
        letterSpacing: '0em',
    },

    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    imagenContainerPagoCuentaConfirmacion: {
        width: 40,
        height: 40,
        backgroundColor: '#92b58e',
        borderRadius: '50%',
        alignSelf: 'center',
        [theme.breakpoints.up('md')]: {
            width: 80,
            height: 80,
        }
    },
    buttonCancel: {
        backgroundColor: grey[500],
        '&:hover': {
            backgroundColor: grey[700],
        },
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    // Carrito Pago a Cuenta
    rootWidth100: {
        backgroundColor: '#fff',
        position: 'fixed',
        bottom: -100,
        transition: 'all .5s ease 0s',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% - 335px)'
        }
    },
    display: {
        bottom: 0,
        [theme.breakpoints.up('md')]: {
            left: 300,
        }
    },
    payContainerCarrito: {
        borderTop: '2px solid #d3c45b',
        textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

        paddingTop: 10,
        paddingLeft: 10,
        paddingBottom: 10,
    },
    wrapper: {
        margin: 15,
        marginRight: 30,
        position: 'relative',
    },
    buttonPagoACuenta: {
        position: 'relative',
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[700],
        },
    },
    // Drawer - Pago a cuenta
    containerDetalleRecibo: {
        marginTop: 15,
        marginBottom: 5
    },
    containerDetalleReciboDomiciliacion: {
        marginTop: 5,
        marginBottom: 15
    },
    containerImporte: {
        marginBottom: 5
    },
    // Drwaer
    drawerPaper: {
        width: 300,
        top: 230,
        height: 'calc(100% - 230px)',
        borderTopLeftRadius: 8,
        zIndex: 10
    },
    // Spinner de carga
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        left: '62%',
        [theme.breakpoints.up('md')]: {
            left: '90%',
        }
    },
    loaderPrint: {
        position: "absolute",
        left: 0,
        right: 0,
        margin: "auto"
    },
    centerTopBottom: {
        top: 0,
        bottom: 0
    },
    // Responsive
    [theme.breakpoints.up('lg')]: {
        btnMoreInfo: {
            display: 'block'
        },
        btnAddPay: {
            display: 'block'
        },
    },
    [theme.breakpoints.up('md')]: {
        priceLabel: {
            display: 'inline-block',
        },
        imagenContainer: {
            width: 80,
            height: 80,
        },
        imagen: {
            width: 80,
            height: 80
        },
        checkIcon: {
            fontSize: '1.6rem'
        },
        drawer: {
            flexShrink: 0,
            width: 300,
        }
    },
});

export default styles;