import React, {  } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

interface ITabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    loading?:boolean
}

function TabPanel(props: ITabPanelProps) {
    const { children, value, index, loading, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && !loading && (
                <Box>
                    {children}
                </Box>
            )}
            {loading &&
                <div key="progress" style={{textAlign: 'center',paddingTop: 250,width: '100%'}}>
                    <CircularProgress />
                </div>
            }
        </div>
    );
}

export default TabPanel;
