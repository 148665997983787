import { Button, Card, CircularProgress, FormControl, ListItem, makeStyles, MenuItem, TextField, useTheme } from "@material-ui/core";
import Term from "components/term";
import { ContenidosGateway } from 'gateways/contenido.gateway';
import usePage from "hooks/page.hook";
import { useCallback, useContext, useEffect, useState } from "react";
import { FC } from "react";
import IoC from 'contexts/ioc.context';
import { AlertsContext } from "contexts/alerts.context";
import { RouteComponentProps } from "react-router-dom";
import { IDatosMulta, IMultas } from "gateways/tramites.interfaces";
import { TramitesGateway } from "gateways/tramites.gateway";
import MultasDetail from "./multas.detail";
import { isCIF } from "utils/validateNif";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import { RecaptchaGateway } from "gateways/recaptcha.gateway";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { MoreInfoContext } from "contexts/more_info.context";
import { mdiShieldCar } from "@mdi/js";


const useStyles = makeStyles((theme) => ({
    base: {
        marginTop: 0,
        marginRight: 40,
        marginLeft: 40,
        [theme.breakpoints.down('xs')]: {
            marginTop: 50,
            marginRight: 10,
            marginLeft: 10,
        },
    },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    root: {
        width: "100%",

    },
    formContainer: {
        marginTop: 50,
        marginLeft: '40px',
        marginRight: '40px',
    },
    formControl: {
        marginTop: 50,
        display: "inline",
        marginRight: 50,

        '& > button': {
            width: '100px',
        },

        [theme.breakpoints.up('xs')]: {
            '& > div': {
                width: 'calc(100% - 20%)',
            },
        },
        [theme.breakpoints.up('sm')]: {
            '& > div': {
                width: 'calc(100% - 62%)',
            },
        },
        [theme.breakpoints.up('md')]: {
            '& > div': {
                width: 'calc(100% - 60%)',
            },
        },
        [theme.breakpoints.up('lg')]: {
            '& > div': {
                width: 'calc(100% - 82%)',
            },
        }
    },
    button: {
        width: 200,
        marginTop: 50,
        marginBottom: 50,
        float: "right",
        [theme.breakpoints.down('xs')]: {
            float: "unset"
        },
    },
}))
type Props = RouteComponentProps;

const FormularioMulta: FC<Props> = ({ history }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const terms = useContext(LiteralsContext);
    const ioc = useContext(IoC);
    const recaptchaGateWay: RecaptchaGateway = ioc.get(RecaptchaGateway);
    const theme = useTheme();
    const [, infoDispatch] = useContext(MoreInfoContext);
    const classes = useStyles(theme);
    const [{ lang }, pageDispatcher] = usePage();
    const [loading,] = useState(false);
    const [nif, setNif] = useState('');
    const [referencia, setReferencia] = useState('');
    const [matricula, setMatricula] = useState('');
    const tramitesGateway: TramitesGateway = ioc.get(TramitesGateway);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
    const [body, setBody] = useState('');
    const [errorDni, setErrorDni] = useState(false)
    const [errorMatricula, setErrorMatricula] = useState(false)
    const [errorReferencia, setErrorReferencia] = useState(false)
    const [, alertsDispatch] = useContext(AlertsContext);
    const [identificar, setIdentificar] = useState(false);
    const [multa, setMulta] = useState<IMultas | null>(null);
    
    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info',templateName )
    },[infoDispatch])

    useEffect(() => {
        loadTemplate();
        pageDispatcher({
            type: 'setHeader',
            header: {
                moreInfoTemplate: "mas_info_identificacion_multa",
                title: <Term component="Tramites" text="Identificación conductor sin autentificar" />,
                icon: mdiShieldCar,
                right: <MenuItem button onClick={() => handleShowMasInfo('mas_info_identificacion_multa')}><Term component="Global" text="Mas informacion" /></MenuItem>
            
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    const handleNotAllowedDocuments = useCallback(() => {
        pageDispatcher({
            type: 'show-notification',
            payload: {
                message: translate('Tramites', 'soloPdfs', terms),
                variant: 'warning',
            }
        });
    }, [pageDispatcher, terms])
    const loadTemplate = useCallback(async () => {
        const bodyAux = await contenidoGateway.getContent(
            'identificación_multas',
            {}
        );
        setBody(bodyAux[0].contenido);
    }, [contenidoGateway]);

    useEffect(() => {
        loadTemplate();
    }, [loadTemplate, lang]);

    const handleSetMatricula = useCallback((x: string) => {
        setMatricula(x)
        setErrorMatricula(false)
    }, [])

    const handleSetReferencia = useCallback((x: string) => {
        setReferencia(x)
        setErrorReferencia(false)
    }, [])

    const handleSetNif = useCallback((x: string) => {
        setNif(x)
        setErrorDni(false)
    }, [])

    const handleRedireccion = useCallback((multa: IMultas) => {
        setIdentificar(true);
        setMulta(multa);
    }, [])

    const handleValidate = useCallback(() => {
        let valido = true;

        if (!nif || nif.length !== 9) {
            setErrorDni(true);
            valido = false;
        }
        /*
        else if (isCIF(nif)) {
            history.push("/tramites/conductor");
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component="Tramites" text="Error identificación persona física" />,
                    variant: 'error',
                }
            });
            valido = false;
        }
        */
        if (!referencia || referencia.replace(/\s/g, "").length !== 13) {
            setErrorReferencia(true);
            valido = false;
        }
        if (!matricula || matricula.length < 2) {
            setErrorMatricula(true);
            valido = false;
        }
        return valido;
    }, [nif, referencia, matricula, alertsDispatch, history])

    const handleContinuar = useCallback(async () => {
        let recaptchaResult = '';
        const recaptchaCheckIPResult = await recaptchaGateWay.checkIP();

        if (!recaptchaCheckIPResult && recaptchaCheckIPResult.useRecaptcha) {
            recaptchaResult = executeRecaptcha ? await executeRecaptcha('sede_v2_formulario_multa') : '';
        }

        if (handleValidate()) {
            const datos: IDatosMulta = {
                nif: nif,
                referencia: referencia,
                matricula: matricula,
                recaptchaToken: recaptchaResult
            }
            try {
                const response = await tramitesGateway.localizaMulta(datos);
                if (response === "") {
                    const message = await contenidoGateway.getContent(
                        'aviso_tramite_no_posible',
                        {
                            linkContacto: 'https://www.atib.es/General/Contacto.aspx'
                        }
                    );
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            isHtml: true,
                            message: message[0].contenido,
                            variant: 'error',
                        }
                    });
                } else {
                    response
                        ? handleRedireccion(response)
                        : alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: <Term component="Tramites" text="Error identificación multa" />,
                                variant: 'error',
                            }
                        });
                }
            }
            catch (e) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: <Term component="Tramites" text="Error identificación multa" />,
                        variant: 'error',
                    }
                })
            };
        }
    }, [recaptchaGateWay, handleValidate, executeRecaptcha, nif, referencia, matricula, tramitesGateway, contenidoGateway, alertsDispatch, handleRedireccion])

    const handleCloseDetail = useCallback(() => setIdentificar(false), []);

    const handleIdentificar = useCallback((multa: IMultas) => {
        history.push("/tramites/identificacionConductor");
    }, [history]);

    return (
        <>
            {
                loading
                    ? <ListItem className={classes.content}>
                        <CircularProgress size={30} />
                    </ListItem>
                    :
                    <div className={classes.base} style={{ minHeight: '65vh' }}>
                        <Card className={classes.root} >
                            <div className={classes.formContainer}>

                                <div dangerouslySetInnerHTML={{ __html: body }} style={{ textAlign: "justify", textJustify: "inter-word" }} />
                                <div style={{ fontWeight: "bold", marginBottom: 5 }}>
                                    <Term component="Tramites" text="Datos de la multa" />
                                </div>
                                <FormControl key="dni" className={classes.formControl} fullWidth>
                                    <TextField
                                        key="dni"
                                        label={<Term component="Tramites" text="dni titular" />}
                                        onChange={(x) => { handleSetNif(x.target.value) }}
                                        error={errorDni}
                                        helperText={errorDni ? <Term component="Tramites" text="Error nif" /> : null}
                                        inputProps={{ maxLength: 9 }}

                                    />
                                </FormControl>
                                <FormControl key="referencia" className={classes.formControl} fullWidth>
                                    <TextField
                                        key="referencia"
                                        label={<Term component="Tramites" text="referencia expediente" />}
                                        onChange={(x) => { handleSetReferencia(x.target.value) }}
                                        error={errorReferencia}
                                        helperText={errorReferencia ? <Term component="Tramites" text="Error referencia" /> : null}
                                        inputProps={{ maxLength: 13 }}
                                    />
                                </FormControl>
                                <FormControl key="matricula" className={classes.formControl} fullWidth>
                                    <TextField
                                        key="matricula"
                                        label={<Term component="Tramites" text="matricula" />}
                                        onChange={(x) => { handleSetMatricula(x.target.value) }}
                                        error={errorMatricula}
                                        helperText={errorMatricula ? <Term component="Tramites" text="Error matricula" /> : null}
                                    />
                                </FormControl>
                                <Button variant="contained" size="small" className={classes.button} color="primary" onClick={handleContinuar}>
                                    <Term component="Global" text="Continuar" />
                                </Button>
                            </div>
                            {
                                identificar && multa ?
                                    <MultasDetail
                                        onClose={handleCloseDetail}
                                        open={identificar}
                                        multa={multa}
                                        mensajeAclaratorio={''}
                                        handleIdentificado={handleIdentificar}
                                        isRentACar={false}
                                        isLoggedIn={false}
                                        datosMulta={{ nif, referencia, matricula }}
                                        handleNotAllowedDocuments={handleNotAllowedDocuments}
                                    />
                                    : null}
                        </Card>
                    </div>
            }
        </>)
}
export default FormularioMulta;