import { Dispatch, ReducerState, ReducerAction, createContext } from "react";
import { ISelectionReducerConfigType } from "./selection.reducer";
import { IRecibo } from "gateways/recibo.interfaces";

type RecibosReducer = ReturnType<ISelectionReducerConfigType<IRecibo>>;
type SelectionReducerState = ReducerState<RecibosReducer>;
type SelectionDispatchAction = Dispatch<ReducerAction<RecibosReducer>>;
type SelectionContextType = [SelectionReducerState, SelectionDispatchAction];

export const SelectionContext = createContext<SelectionContextType>([{ selected: [] as any[] }, (state: any) => state]);
