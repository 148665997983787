import React, { FC } from 'react';
import { RouteComponentProps, withRouter, Route } from 'react-router-dom';
import { withLiterals } from 'containers/shared/literals';
import ProtectedRoute from "components/protected-route";
import BienesSujeto from './bienes-sujeto';
import BienesHome from './bienes-home';



const BienesIndex: FC<RouteComponentProps> = ({ match }) => {
    // console.log('match', match.path)
    return (
        <>
            <Route exact path={`${match.path}/`} component={BienesHome} />

            <ProtectedRoute exact path={`${match.path}/:screen`} component={BienesSujeto} accessLevel={2} idModulos={[8]} />
            {/* <Route exact path={`${match.path}/`} component={BienesSujeto} /> */}
        </>
    );
}

export default withLiterals(['Global','BienesSujeto'])(withRouter(BienesIndex));
