import React, { FC, useCallback, useContext} from "react";
import Term from "components/term";
import SelectMultiple from '../../components/select-multiple';
import { FormControl, InputLabel, makeStyles, useTheme } from "@material-ui/core";
import { FiltersContext } from "./filters.context";
import { Icon } from '@mdi/react';
import { FilterDataContext } from "./filters.data.context";
import { mdiMapSearch } from '@mdi/js';
import { mdiViewModule } from '@mdi/js';
import './oficina.list'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingBottom: 20,
    },

    formControl: {
        margin: `30px 0`,
        [theme.breakpoints.up('md')]: {
            margin: `10px 5px`,
        }
    },

    group: {
        margin: `10px 0`,
    },

    block: {
        display: `inline-block`
    },

    hidden: {
        display: `none`
    },

    middle: {
        verticalAlign: `middle`
    },
    icon2: {
        float: 'left',
        marginTop: -3,
        marginLeft: -4
    },

    text: {
        float: "left",
        color: "#707070",
        fontSize: 20,
    },
    text2: {
        float: "left",
        color: "#707070",
        fontSize: 20,
    },
    iconList: {
        paddingTop: "25px",
        paddingLeft: "7px",
        height: '60px',
        cursor: "pointer",
        display: 'flex'
    },

}));


export interface IFiltersFormProps {
    mostrarMapa: boolean;
    mostrarFiltro: boolean
}

const FiltersForm: FC<IFiltersFormProps> = ({ mostrarMapa, mostrarFiltro }) => {
    const [filters, filtersDispatch] = useContext(FiltersContext);
    const filterData = useContext(FilterDataContext);

    const handleClick = useCallback(
        (bit: boolean) => {
            filtersDispatch({
                type: "update",
                filter: "cambioLista",
                value: bit,
            });
        },
        [filtersDispatch]
    );
    const handleNombreChange = (municipio: string[]) => filtersDispatch({ type: 'update', filter: 'municipio', value: municipio });
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <div className={classes.root}>
            
            { filters.cambioLista && mostrarFiltro?
                <FormControl key='organismo' className={classes.formControl} fullWidth>
                    <InputLabel key='label-organismo' htmlFor="select-multiple-chip">
                        <Term component="Global" text="Municipio / Organismo" />
                    </InputLabel>
                    <SelectMultiple
                        key='organismos'
                        options={filterData && filterData.municipio ? filterData.municipio : []}
                        value={filters.municipio || []}
                        onChange={(values: any) => handleNombreChange(values)}
                    />
                </FormControl>
                :
                null
            }
        
            <>
                {mostrarMapa &&
                    <div onClick={() => handleClick(!filters.cambioLista)} className={classes.iconList}>
                        {filters.cambioLista ?
                            <>
                                <Icon path={mdiMapSearch}
                                    title="logo"
                                    color="#707070"
                                    size="30"
                                />
                                <div className={classes.text}><Term component="Oficinas" text="Mapa" /></div>
                            </>
                            :
                            <>
                                <Icon className={classes.icon2} path={mdiViewModule}
                                    title="logo"
                                    color="#707070"
                                    size="35"
                                />
                                <div className={classes.text2}><Term component="Oficinas" text="Lista" /></div>
                            </>

                        }
                    </div>}


            </>
        </div>
    )
}
export default FiltersForm;