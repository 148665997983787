import { StyleRules } from "@material-ui/core";

const styles = (): StyleRules => ({
    paddingRootContainer: {
        padding: '1rem',
    },
    // dialogActionsPadding: {
    //     padding: '8px 24px',
    // },
    titleText:{
        fontSize: '1.5rem',
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: 1.334,
        letterSpacing: '0em',
    },
});

export default styles;