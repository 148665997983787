import React, {FC, useContext} from "react"
import { Grid, Button } from "@material-ui/core"

import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals"
import { translate } from "utils/i18n"

export interface IGenericButtonStepper {
    isFirst: boolean;
    isLast: boolean;
    onExit: (cause:'continuar-mas-tarde'|'force') => void;
    onNext: () => void;
    onBack: () => void;
    disabledNext?: boolean;
    disabledBack?: boolean;
    forceExit?:boolean;
    allowContinuarMasTarde?:boolean;

    labelBtnContinuarMasTarde?:string
}
const GenericButtonsStepper : FC<IGenericButtonStepper>= ({
    isFirst, isLast,
    onBack, onNext, onExit,
    disabledNext, disabledBack,forceExit,
    allowContinuarMasTarde,
    labelBtnContinuarMasTarde
}) => {
    const terms = useContext(LiteralsContext);
    return(
        <Grid container direction='row' alignItems='center' justify='space-between' wrap="nowrap"
            style={{
                padding:16,
                borderTop: '1px solid #d3d3d3', 
            }} 
        >
            <Grid item >
                {!isFirst && 
                    <Button variant="contained" color="inherit"
                        onClick={onBack}
                        disabled={disabledBack}
                    >
                        {translate('Tributos', 'btnPrevious',terms)}
                    </Button>
                }
            </Grid>

            <Grid item container direction='row' alignItems='center' justify='flex-end'>
               
                <Grid item>
                    {!isFirst && !forceExit && allowContinuarMasTarde &&
                        <Button 
                            variant="outlined"  
                            color="primary"                                                
                            onClick={() => onExit('continuar-mas-tarde')}
                        >
                            {translate('Tributos', 'btnContinuarMasTarde',terms)}
                        </Button>
                    }
                </Grid>

                <Grid item>
                    {forceExit 
                        ?
                            <Button 
                                variant="contained"  
                                style={{ backgroundColor: 'red', color:'white', marginLeft: 10}}                                              
                                onClick={()=> onExit('force')}
                            >
                                {translate('Tributos', 'bntCancelarModelo',terms)}
                            </Button>
                        :    
                            <Button 
                                variant="contained"  
                                color="primary" 
                                style={{marginLeft: 10}}
                                onClick={() => onNext && onNext()}
                                disabled={disabledNext}
                            >
                                {isLast
                                    ?  translate('Tributos', 'btnFinish',terms)
                                    :  translate('DUTI','btn_continuar',terms)
                                }
                            </Button>
                    }
                    
                </Grid>

            </Grid>

        </Grid>
    )
}

export default withLiterals(["Tributos","DUTI","Modelos"])(GenericButtonsStepper)