import { FC, useCallback, useState } from 'react';
import { Button, CircularProgress, Drawer, makeStyles } from "@material-ui/core";
import { green } from '@material-ui/core/colors';
import { IFiltersState } from './filters.reducer';
import FiltersForm, { ScreenType } from './filters.form';
import Term from '../../components/term';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    drawerPaper: {
        width: 300,
        top: 230,
        height: 'calc(100% - 230px)',
        borderTopLeftRadius: 8,
        zIndex: 10
    },
    main: {
        padding: 20
    },
    title: {
        marginBottom: 0
    },
    center: {
        textAlign: 'center'
    },
    buttonProgress: {
        color: green[500],
        position: 'relative',
        left: '25%',
        marginBottom: -5,
    },
}));

export interface IPanelDrawerProps {
    onFiltersChange: (filters: IFiltersState) => void;
    screen: ScreenType;
    onExportRequest: () => any;
}

const PanelDrawer: FC<IPanelDrawerProps> = ({ onFiltersChange, screen, onExportRequest }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const handleExport = useCallback(async () => {
        setLoading(true);
        await onExportRequest();
        setLoading(false);
    }, [onExportRequest]);


    return (
        <Drawer
            classes={{
                paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor="right"
            open>
            <div className={classes.main}>
                <h3 className={classes.title}><Term component="Global" text="Filtros de busqueda" /></h3>
                <FiltersForm
                    screen={screen}
                />
                <div className={classes.center}>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    <Button onClick={handleExport} variant="outlined" disabled={loading}>
                        <Term component="Global" text="Exportar" />
                    </Button>
                </div>
            </div>
        </Drawer>
    )
};

export default PanelDrawer;