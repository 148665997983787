import React, {FC, useContext} from "react";

import { RadioGroup, FormControlLabel,  FormControl, withStyles, WithStyles, FormHelperText, Radio } from "@material-ui/core";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import duti_styles from "containers/DUTI/styles";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import LabelWithInfo, { TLabelType } from "./label-with-info";
import { TTipoDirFisc } from "containers/DUTI/steps/types";

export type IRadioButtonLCOption = {id: TTipoDirFisc; label: JSX.Element;value: string}


interface IRadioButtonLabelComponent extends WithStyles {
    options: IRadioButtonLCOption[]
    fieldTerm: string,
    value: string | undefined,
    onChangeValue: (v:string) => void,
    moreInfo?: {
        template: string, size?: TInfoTemplateSize
    },
    row?:boolean
    error?:boolean,
    errorMsg?:string,
    labelAs?: TLabelType | 'notLabel'
    disabled?: boolean
};


const RadioButtonLabelComponent : FC<IRadioButtonLabelComponent>= ({classes, options, fieldTerm, value, onChangeValue, moreInfo, row, error, errorMsg, labelAs='field', disabled}) => {
    const terms = useContext(LiteralsContext);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value:string) => {
        onChangeValue(value);
    };

    return (
        <FormControl component="fieldset">
            { labelAs !== 'notLabel' && <LabelWithInfo type={labelAs} fieldTerm={fieldTerm} moreInfo={moreInfo}/>}
            <RadioGroup value={value ?? ''} onChange={handleChange} row={row} style={{marginTop:10}} >
            {options.map( o => (
                <FormControlLabel style={{marginRight: 20, alignItems: 'flex-start', margin: 5}} 
                    key={o.id} 
                    value={o.id} 
                    control={ <Radio color="primary" style={{ padding: 0, margin: '5px 10px 10px 10px'}} /> 
                    } 
                    label={o.label} 
                    disabled={disabled}
                />
            ))}
            </RadioGroup>
            {error && <FormHelperText error >{errorMsg}</FormHelperText>}
        </FormControl>
    )
}

export default withLiterals(['DUTI'])(withStyles(duti_styles)(RadioButtonLabelComponent));