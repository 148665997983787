import { height } from "@fortawesome/free-solid-svg-icons/faFilter";
import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { mdiBorderColor } from "@mdi/js";
import { autoinject } from "aurelia-dependency-injection";

const styles = (theme: Theme): StyleRules => ({
    container: {
        display: 'grid',
        width: '100%',
        gap: '0.75rem',
    },
    containerApartado: {
        display: 'flex',
        flexGrow: 1,
        height: 500
    },
    cardContainer: {
        flexGrow: 1,
        marginLeft: 10,
        marginRight: 10,
        padding: 5,
    },
    cardContainerModAction: {
        flexGrow: 1,
        marginLeft: 10,
        marginRight: 10,
        padding: '0px 30px 30px 30px',
    },
    cardContainerMargin: {
        flexGrow: 1,
        marginLeft: 30,
        marginRight: 30,
        padding: 30,
    },
    column: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    columnAlignLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    rowAlignLeft: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 1
    },
    rowAlignRight: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexGrow: 1
    },
    // STEPPER
    boldText: {
        color: 'black',
        fontWeight: 'bold'
    },
    completedText: {
        color: 'rgba(45,178,190,1)', //'black',
    },
    lightText: {
        fontWeight: 'lighter',
        color: 'grey',
    },
    stepperContainer: {
        flex: 1,
        left: 0,
        right: 0,
        marginRight: 10,
        borderRadius: 10,
        //height: '100%',
        marginLeft: 0,
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)',
            height: 'auto',
            marginLeft: 10,
        }
    },
    stepper: {
        borderRadius: 10,
    },
    //LIST
    title: {
        display: 'flex',
        color: '#004f82',
        gap: '0.5rem',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        marginBottom: '0.75rem',
        marginTop: '0.75rem'
    },
    listItemRoot: {
        marginBottom: 10,
        borderRadius: 10,
        backgroundColor: '#c2ddf2',//'#299db1',
        color: '#004f82',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            backgroundColor: '#004f82', //'#8dd0d7'
            color: '#fff'
        },
    },
    listItemRootInverse: {
        marginBottom: 10,
        borderRadius: 10,
        backgroundColor: '#d3d3d3',
        color: '#000',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            backgroundColor: '#ebebeb'
        },
    },
    listItemDisabled: {
        backgroundColor: '#e0eaf4',//'#d3d3d3',
        color: '#878282',
    },
    listItemSelected: {
        color: '#FFF',
        backgroundColor: '#299db1 !important',
    },
    listItemIconsTA: {
        background: 'white',
        alignSelf: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        margin: 5,
        paddingBottom: 5,
        paddingTop: 5
    },
    buttonCopyRef: {
        marginRight: 10,
        paddingRight: 2,
        paddingLeft: 2,
        paddingTop: 0,
        paddingBottom: 0,
        minWidth: 'auto',
    },
    iconCopy: {
        fill: '#004f84',
        '&:hover': {
            fill: '#299db1',
            size: 2
        }
    },
    //PopUp
    resumContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: '#cbdbe3',
        maxHeight: '450px',
        overflow: 'auto',
        padding: 15,
        marginBottom: 25
    },
    marginBottom10: {
        marginBottom: 10
    },
    //Tooltip
    btnDisabledTooltip: {
        marginLeft: 10,
        "&.Mui-disabled": {
            pointerEvents: "auto"
        }
    },
    // Avisos Modelo
    containerAvisoModelo: {
        border: '1px solid red',
        display: 'flex',
        gap: 10,
        padding: 10,
        borderRadius: 8
    },
    recoverButton: {
        width: 400,
        '& .MuiOutlinedInput-root': {
            marginTop: -5

        },
    },
    resizeRevocerButton: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            minWidth: '10vw', // 'vw' is the viewport width unit
            '&.Mui-focused': {
                // Target the focused state
                marginTop: -5, // Increased margin-top when focused
            },
        },
        '@media (min-width: 1421px)': {
            // Apply these styles when the viewport width is 600px or more
            width: '400px', // Maximum width of 400px
        },
    },
    selectEjercicio: {
        margin: 10, 
        width: 180,
    },

})

export default styles;