
import {FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import { Grid } from "@material-ui/core";
import { TSelect, formatSelectOptions } from "containers/DUTI/utils";
import { IBonificacion, TTipoTransmision } from "gateways/model.new.interface";
import ModelGateway from "gateways/model.new.gateway"
import IoC from "contexts/ioc.context";
import InputSelect from "components/DUTI/input-select";
import LabelWithInfo from "components/DUTI/label-with-info";
import { getTiposTransmsisionByTipoAsistente } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/utils";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { ContenidosGateway } from "gateways/contenido.gateway";
import { translate } from "utils/i18n";
import { TTipoTransmPLV } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types";
import FullLoading from "components/full-loading";
import InputText from "components/DUTI/input-text";


export type TTipoTransErr = Record<keyof TTipoTransmPLV, boolean>

interface IProps {
    idMunicipio: string;
    nombreMuni: string,
    tipoTransmision: TTipoTransmPLV;
    onChangeTipoTransmisiones: (v:TTipoTransmPLV, err:TTipoTransErr) => void;
}

const TIPOS_BONIFICADOS: TTipoTransmision[]= ['DD','SH'];

const TipoTansmisionBonificacion: FC<IProps> = ({idMunicipio,nombreMuni, tipoTransmision,onChangeTipoTransmisiones}) => {
    const componentMounted = useRef(true);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway),[ioc]);
    const contenidoGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway),[ioc]);

    const terms = useContext(LiteralsContext);
    const [loading,setLoading]=useState(false);
    
    const [tiposTransmision, setTiposTransmision] = useState([] as TSelect[]);
    const [bonificaciones, setBonificaciones]= useState<IBonificacion[] | null>(null);
    const [bonificacionesSelect, setBonificacionesSelect]= useState<TSelect[] >([]);

    const [MSGCommonBoni, setMSGCommonBoni]= useState<string>("")
    const [MSGMuniBoni, setMSGMuniBoni]= useState<string[]>([] as string[]);
    const [tipoT, setTipoT]=useState<TSelect|undefined>(undefined)
    const [tipoTErr, setTipoTErr]=useState(false)
    const [boni, setBoni]=useState<TSelect|undefined>(undefined)
    const [boniErr, setBoniErr]=useState(false)

    const [descrip, setDescrip]= useState<string|undefined>(undefined)
    const [descripErr, setDescripErr]= useState<boolean>(false)



    const MoreInfo = useMemo(() => {
    //    if( MSGMuniBoni.length>0){
    //         const msgMuni= MSGMuniBoni.map((msg, idx) => (`<p>${MSGMuniBoni.length>1 ? idx+' - '+msg : msg}</p>`))
    //         const msgStr = msgMuni.join(' ')
        return( MSGCommonBoni !== "" ?
            {
                template: MSGCommonBoni, // `${MSGCommonBoni}${msgStr}`,
                size: 'md',
                asMessage: true
            }: undefined
        )
    //    } else {
    //     return undefined
    //    }

    },[MSGCommonBoni])// MSGMuniBoni])

    const getBonificacion = async (id:string, tt: string | undefined) => {
        setLoading(true);
        if(tt && TIPOS_BONIFICADOS.includes(tt as TTipoTransmision)){
            const bonificaciones = await modelGateway.getBoniTipoTransmByMuni(id, 'Error consulta bonificacion', tt);
            // const MSG =bonificaciones.length > 0 ? bonificaciones.map(b => {
            //     console.log(b.componenteIG, b.term)
            //     if (b.valor && b.valorCatastralMin && b.valorCatastralMax) {
            //         return translate(b.componenteIG, b.term, terms, [b.valor, b.valorCatastralMin, b.valorCatastralMax]);
            //     }
            //     if (b.valor && b.valorCatastralMin) {
            //         return translate(b.componenteIG, b.term, terms, [b.valor, b.valorCatastralMin]);
            //     }
            //     if (b.valor) {
            //         return translate(b.componenteIG, b.term, terms, [b.valor]);
            //     }

            //     return translate(b.componenteIG, b.term, terms);
            // }) : [];
            setBonificaciones(bonificaciones.length > 0 ? bonificaciones :null);
            setBonificacionesSelect(
                bonificaciones.length>0 ?
                formatSelectOptions<IBonificacion>("idTarifa", "valor", bonificaciones)
                :[]
            );
            // setMSGMuniBoni(MSG);
        } else {
            setBonificaciones(null)
            setBonificacionesSelect([])
            //setMSGMuniBoni([])
        }
        setLoading(false)
    }


    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                const nomMuni=nombreMuni.toLowerCase().split(' ')
                const boniMSGCommon = await contenidoGateway.getContent( `plusvalias_more_info_bonificacion_${nomMuni.join('_')}`,{});
                await getBonificacion(idMunicipio, tipoTransmision?.tipo?.id ?? undefined)
                
                if(componentMounted.current){
                    if(tipoTransmision){
                        setTipoT(tipoTransmision.tipo)
                        setBoni( tipoTransmision.bonificacion ? 
                            {id:tipoTransmision.bonificacion.idTarifa.toString() ,nombre:tipoTransmision.bonificacion.valor.toString()}
                            : undefined
                        )
                        setDescrip(tipoTransmision.tipoTransmisionDescripcion)
                    }
                    const tiposTransmision = await modelGateway.getTiposTransmision();
                    setTiposTransmision(getTiposTransmsisionByTipoAsistente(tiposTransmision, terms))
                    setMSGCommonBoni(boniMSGCommon[0].contenido)
                }
            } catch (err){
                console.log('error ', err)
            } finally {
                setLoading(false)
            }
            
        })()
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    },[])

    useEffect(() => {
        (async() => {
            const nomMuni=nombreMuni.toLowerCase().split(' ')
            const boniMSGCommon = await contenidoGateway.getContent( `plusvalias_more_info_bonificacion_${nomMuni.join('_')}`,{})
            setMSGCommonBoni(boniMSGCommon[0].contenido)
        })();
    },[nombreMuni])

    useEffect(() => {
        if(tipoTransmision){
            setTipoT(tipoTransmision.tipo)
            setBoni( tipoTransmision.bonificacion ? 
                {id:tipoTransmision.bonificacion.idTarifa.toString() ,nombre:tipoTransmision.bonificacion.valor.toString()}
                : undefined
            )
            setDescrip(tipoTransmision.tipoTransmisionDescripcion)
        }
    },[tipoTransmision])




    return(
        <Grid container direction='row' alignItems="flex-start" spacing={2}> 
            <FullLoading loading={loading}/>
            <Grid item>
                <LabelWithInfo componentIG={'Plusvalias'} fieldTerm={'tipoTransmisionLabel'} />
                <InputSelect 
                    componenteIG='Plusvalias' 
                    fieldTerm='tipoTransmision'
                    options={tiposTransmision}
                    value={tipoTransmision.tipo}
                    error={tipoTErr}
                    required
                    onChangeValue={async (v: TSelect|undefined,err:boolean)=> {
                        // setTipoT(v);
                        setTipoTErr(err);
                        onChangeTipoTransmisiones(
                            {tipo: v, bonificacion: undefined, tipoTransmisionDescripcion: undefined},
                            {tipo: err, bonificacion:boniErr, tipoTransmisionDescripcion: false}
                        )
                        await getBonificacion(idMunicipio, v?.id ?? undefined)
                        if(v && v.id === 'OS'){
                            setDescripErr(true)
                        }
                    }}
                />
            </Grid>

            {bonificaciones && bonificaciones?.length>0 &&
                <Grid item>
                    <LabelWithInfo componentIG={'Plusvalias'} fieldTerm={'bonificacionLabel'} moreInfo={MoreInfo}/>
                    <InputSelect 
                        componentIG='Plusvalias' 
                        fieldTerm='bonificacion'
                        options={bonificacionesSelect}
                        value={tipoTransmision.bonificacion 
                            ? {id: String(tipoTransmision.bonificacion.idTarifa), nombre: String(tipoTransmision.bonificacion.valor)}
                            : undefined}
                        onChangeValue={ (v: TSelect|undefined,err:boolean) => {
                            //setBoni(v);
                            setBoniErr(err)
                            onChangeTipoTransmisiones(
                                {tipo: tipoTransmision.tipo, bonificacion: v? {idTarifa: Number(v.id), valor:Number(v.nombre)}: undefined , tipoTransmisionDescripcion: undefined},
                                {tipo: tipoTErr, bonificacion: boniErr, tipoTransmisionDescripcion: false}
                            )
                        }}
                    
                    />
                    
                </Grid>
            }
            { tipoTransmision.tipo && tipoTransmision.tipo.id === 'OS' && 
                <Grid item style={{maxWidth: 500, flexGrow: 1}}>
                    <LabelWithInfo componentIG={'Plusvalias'} fieldTerm={'tipoTransmisionDescripcionLabel'} /*moreInfo={{template: 'plusvalias_info_field_tipo_transm_descrip', size: 'xs'}}*/ />
                    <InputText
                        componentIG='Plusvalias'
                        fieldTerm='tipoTransmisionDescripcion'
                        value={descrip}
                        multiline
                        error={descripErr}
                        rows={3}
                        required={true}
                        onChangeValue={ (v:string,err: boolean) => {
                            onChangeTipoTransmisiones(
                                {...tipoTransmision, tipoTransmisionDescripcion: v},
                                {tipo: tipoTErr, bonificacion: boniErr, tipoTransmisionDescripcion: err}
                            )
                            setDescripErr(err)
                            setDescrip(v)
                        }}
                        fullWidth
                        // onBlur={()=> {
                            
                        //     onChangeTipoTransmisiones(
                        //         {...tipoTransmision, tipoTransmisionDescripcion: descrip},
                        //         {tipo: tipoTErr, bonificacion: boniErr, tipoTransmisionDescripcion: descripErr}
                        //     )
                        // }}
                    />
                </Grid>
            } 
            

        </Grid>
    );
}

export default withLiterals(["Plusvalias","Global"])(TipoTansmisionBonificacion);