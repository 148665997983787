import React, { useContext, FC } from "react";
import InfiniteScroll from "react-infinite-scroller";
import {
  withStyles,
  WithStyles,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Grid,
} from "@material-ui/core";

import { IDatosRecibo, IVehiculo } from "gateways/bienesSujeto.interface";

import styles from "../shared/bienesSujeto.styles";
import Term from "components/term";
import ImageIcon from "components/image.icon";
import Field from "./field";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import moment from "moment";

interface IProps {
  vehiculos: IVehiculo[];
  count: number;
  handleMore: (page: number) => void;
  handleSelectBien: (datosRecibo: IDatosRecibo) => void;
  hasMore: boolean;
  loading: boolean;
}

type Props = WithStyles<typeof styles> & IProps;

const Vehiculos: FC<Props> = ({
  classes,
  vehiculos,
  count,
  handleMore,
  handleSelectBien,
  hasMore,
  loading,
}) => {
  const terms = useContext(LiteralsContext);
  return (
    <div>
      <Card key="titulo-vehiculos" className={classes.cardContador}>
        <CardContent className={classes.cardContadorContainer}>
          <span className={classes.titleCardContador}>
            <Term
              component="BienesSujeto"
              text="TituloVehiculosCount"
              params={[count]}
            />
          </span>
        </CardContent>
      </Card>
      {vehiculos && vehiculos.length > 0 ? (
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={handleMore}
          hasMore={hasMore}
          loader={
            <div
              key="infiniteProgress"
              style={{ display: hasMore ? "block" : "none" }}
              className={classes.centerContent}
            >
              <CircularProgress className={classes.progress} />
            </div>
          }
        >
          {vehiculos.map((vehiculo) => {
            return (
              <Card key={vehiculo.matricula} className={classes.card}>
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={12}
                      lg={3}
                      xl={2}
                      container
                      direction="column"
                      alignItems="center"
                      justify="flex-start"
                    >
                      <Grid item className={classes.imgContainer}>
                        <ImageIcon from="bienesSujeto" imgName={"vehiculo"} />
                      </Grid>
                      {/* <Grid style={{alignSelf: 'flex-start'}} >
                                        <Field //width={'33%'}
                                            label={translate('BienesSujeto', 'bastidor', terms)} 
                                            value={vehiculo.bastidor} 
                                        />
                                        <Field //width={'33%'}
                                            label={translate('BienesSujeto', 'fechaMatriculacion', terms)} 
                                            value={moment(vehiculo.fechaMatriculacion).format('DD/MM/YYYY')} 
                                        />
                                    </Grid> */}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={9}
                      md={12}
                      lg={9}
                      xl={10}
                      container
                      direction="column"
                      style={{ marginTop: 20, paddingLeft: 10 }}
                    >
                      <Grid item container className={classes.contentRow}>
                        <Field
                          width={"33%"}
                          label={translate("BienesSujeto", "matricula", terms)}
                          value={vehiculo.matricula}
                        />

                        <Field
                          width={"33%"}
                          label={translate("BienesSujeto", "marca", terms)}
                          value={vehiculo.marca}
                        />
                        <Field
                          width={"33%"}
                          label={translate("BienesSujeto", "modelo", terms)}
                          value={vehiculo.modelo}
                        />
                      </Grid>
                      <Grid item container className={classes.contentRow}>
                        <Field
                          width={"33%"}
                          label={translate(
                            "BienesSujeto",
                            "nivelEmisiones",
                            terms
                          )}
                          value={vehiculo.datosTecnicos.nivelEmisiones}
                        />
                        <Field
                          width={"33%"}
                          label={translate("BienesSujeto", "cilindrada", terms)}
                          value={
                            vehiculo.datosTecnicos.potencias.cilindrada.replace(
                              ".",
                              ","
                            ) + " cm3"
                          }
                        />
                        <Field
                          width={"33%"}
                          label={translate(
                            "BienesSujeto",
                            "potenciaFiscal",
                            terms
                          )}
                          value={
                            vehiculo.datosTecnicos.potencias.potenciaFiscal.replace(
                              ".",
                              ","
                            ) + " CVf"
                          }
                        />
                      </Grid>
                      <Grid item container className={classes.contentRow}>
                        <Field
                          width={"33%"}
                          label={translate("BienesSujeto", "bastidor", terms)}
                          value={vehiculo.bastidor}
                        />
                        <Field
                          width={"33%"}
                          label={translate(
                            "BienesSujeto",
                            "fechaMatriculacion",
                            terms
                          )}
                          value={moment(vehiculo.fechaMatriculacion).format(
                            "DD/MM/YYYY"
                          )}
                        />
                        <Field width={"33%"} label={""} value={""} />
                      </Grid>
                      <Grid item className={classes.inline}>
                        <Field
                          label={translate(
                            "BienesSujeto",
                            "municipioVehiculo",
                            terms
                          )}
                          value={vehiculo.direccion}
                        />
                      </Grid>
                      <div className={classes.bottomButtonRow}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleSelectBien({
                              objeto: vehiculo.matricula,
                              municipio: vehiculo.codigoMunicipio,
                            })
                          }
                        >
                          <Term
                            component="BienesSujeto"
                            text="Ver recibos relacionados"
                          />{" "}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
          {loading && (
            <div key="progress" className={classes.centerContent}>
              <CircularProgress className={classes.progress} />
            </div>
          )}
        </InfiniteScroll>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(Vehiculos);
