import { StyleRules } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";

const styles = (): StyleRules => ({
marginDomiciliacionesFechaCargo: {
        margin: "1rem"
    },
    calendarFechCargo: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: "1rem",
        alignItems: "flex-start",
        justifyContent: "center"
    },
    fechaCargo: {
        display: "flex",
        flexDirection: "column"
    },
    paddingFechaCargo: {
        padding: "16px",
        paddingBottom: "24px"
    },
    fechaPrevista: {
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center'
    },
    progressContainer: {
        display: "flex",
        justifyContent: "center",
        margin: '1rem'
    },
    progressLoaderOverButton: {
        color: green[500],
        position: 'absolute',
    },
    containerLeyendaDiasVoluntaria: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '0.5rem',
    },
    containerExplicacionLeyenda: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center'
    },
    leyendaDiasVoluntaria: {
        width: 20,
        height: 20
    },
    colorDiasDeshabilitados: {
        backgroundColor: '#dddddd'
    },
    colorDiasHabilitados: {
        backgroundColor: '#FFFF',
        border: '1px solid black'
    },
    colorDiaSeleccionado: {
        backgroundColor: '#2196F3'
    },
    titleText:{
        fontSize: '1.5rem',
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: 1.334,
        letterSpacing: '0em',
    },
    marginB15: {
        marginBottom: 15
    }
});

export default styles;