import React, { useEffect, useCallback, useState, useContext, useMemo, useReducer } from 'react';
import { Button, makeStyles, useTheme, CircularProgress, Card, CardContent, Hidden, MenuItem, Grid, FormControl, IconButton, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import usePage from 'hooks/page.hook';
import Term from 'components/term';
import IoC from 'contexts/ioc.context';
import { TramitesGateway } from 'gateways/tramites.gateway';
import image from '../../../resources/SinExp.png';
import NoContent from 'components/no-content';
import { FiltersContext } from './filters.context';
import { FilterDataContext } from './filter-data.context';
import FiltersActive from './filters.active';
import FiltersDialog from './filters.dialog';
import PanelDrawer from './panel.drawer';
import FiltersReducer, { IFiltersState } from './filters.reducer';
import { IFilterDataTramites, IExpediente, IDatoModelo } from 'gateways/tramites.interfaces';
import { green } from '@material-ui/core/colors';
import InfiniteScroll from 'react-infinite-scroller';
import { mdiFileAccount, mdiTrashCan, mdiEye, mdiPrinter } from '@mdi/js';
import LabelField from 'components/label-field';
import Icon from '@mdi/react';
import { DocumentsUtils } from 'utils/documents';
import moment from 'moment';
import { BLUE } from 'containers/shared/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
            width: 300,
        }
    },

    container:
    {
        position: 'relative',
    },


    progressContainer: {
        textAlign: 'center',
        marginTop: 30
    },
    progress: {

    },

    button: {
        left: '85%',
        bottom: '85%',
        position: 'absolute',
        [theme.breakpoints.down('md')]: {
            left: '30%',
            bottom: '50%',
        }
    },
    cardContador: {
        marginBottom: '0.5rem',
        backgroundColor: 'rgba(255,255,255,0.6)',
    },
    cardContadorContainer: {
        padding: '13px !important',
        display: 'flex',
        alignItems: 'center',
    },
    titleCardContador: {
        fontWeight: 300,
        fontSize: 16,
        marginLeft: '1rem',
        color: '#406a8d',
        flex: 1
    },
    cardBackground: {
        maxHeight: 150
    },
    recibosContainer: {
        flex: 1,
        margin: '0 0.5rem'
    },
    recibo: {
        marginBottom: '0.5rem'
    },
    buttonPagarTodos: {
        display: 'none',
    },
    mainButton: {
        width: '100%',
        textAlign: 'left',
    },
    textAlignRight: {
        textAlign: 'right',
    },
    [theme.breakpoints.up('md')]: {
        root: {
            display: 'flex',
        },
        recibosContainer: {
            margin: '0 2rem'
        },
        scrollPanel: {
            height: 570,
            overflow: "auto",
        },
        buttonPagarTodos: {
            display: 'block',
        },

    },
    hide: {
        display: 'none'
    },
    margin15: {
        marginLeft: 15,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        [theme.breakpoints.up('md')]: {
            left: '50%',
        }
    },
    flex: {
        display: 'flex'
    }
}));

const numberElementsPerPage = 50;

const Presentaciones: React.FC = () => {
    const history = useHistory();
    const [, pageDispatcher] = usePage();
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const classes = useStyles(theme);
    const ioc = useContext(IoC);
    const gateway = ioc.get(TramitesGateway) as TramitesGateway;
    const [tramites, setTramites] = useState<IExpediente[]>([]);
    const filtersReducer = useReducer(FiltersReducer, {});
    const [page, setPage] = useState(0);
    const [filters,] = filtersReducer;
    const [tramitesCount, setTramitesCount] = useState(0);
    const [filterData, setFilterData] = useState<IFilterDataTramites>({});
    const [showFilters, setShowFilters] = useState(false);
    const [imprimiendo, setImprimiendo] = useState<{ [idExpediente: number]: boolean }>({});

    const hasMore = useMemo(() => tramitesCount > (page === 0 ? (page + 1) : page) * numberElementsPerPage, [page, tramitesCount]);
    const noContent = useMemo(() => !loading && tramites.length === 0, [loading, tramites]);

    const handleContinuar = (id: string) => {
        history.push('/tributos/presentaciones/expediente/' + id);
    }

    const handleAlta = () => {
        history.push('/tributos/presentaciones/alta/1');
    }

    const handleAnularExpediente = async (id: number) => {
        try {
            setLoading(true);
            await gateway.anularExpediente(id);
            tramites.filter(x => x.idExpediente === id)[0].estado = 'ANU';
            setTramites([...tramites]);
        } finally {
            setLoading(false);
        }
    }

    const handleImprimir = (tramite: IExpediente) => {
        (async () => {
            setImprimiendo({ ...imprimiendo, [tramite.idExpediente]: true });
            try {
                if (!!tramite.idDocumento) {
                    DocumentsUtils.downloadDoc(tramite.idDocumento);
                    return;
                }

                const idDoc = await gateway.generarJustificante(tramite.idExpediente);
                DocumentsUtils.downloadDoc(idDoc);
            } finally {
                setImprimiendo({ ...imprimiendo, [tramite.idExpediente]: false });
            }
        })();
    }

    const handleShowFilters = useCallback(() => {
        setShowFilters(true);
    }, []);

    const handleHideFilters = useCallback(() => setShowFilters(false), []);

    const buildQueryRequest = useCallback((newFilters: IFiltersState, newPage: number) => {
        return Object.assign(filters, newFilters, {
            skip: newPage * numberElementsPerPage,
            take: numberElementsPerPage,
        });
    }, [filters]);

    const handleLoadTramites = useCallback(async (newFilters: IFiltersState, newPage: number) => {
        const query = buildQueryRequest(newFilters, newPage);

        setLoading(true);

        try {
            const tramitesReceived = await gateway.getPresentaciones(query);
            setTramites(newPage === 0 ? tramitesReceived : tramites.concat(tramitesReceived));
        } catch (error) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        }
        finally {
            setLoading(false);
        }
    }, [gateway, tramites, buildQueryRequest, pageDispatcher]);

    const handleLoadMoreMultas = useCallback(() => {
        if (hasMore) {
            setPage(page + 1);
            handleLoadTramites(filters, page + 1);
        }
    }, [hasMore, page, handleLoadTramites, filters]);

    const handleFiltersChanged = useCallback((newFilters: IFiltersState) => {
        setPage(0);
        handleLoadTramites(newFilters, 0);
    }, [handleLoadTramites]);

    const handleTermTipoExpediente = (tramite: IExpediente) => {
        const datoModeloObj: IDatoModelo[] = tramite.datosModelo.filter(datoModelo => datoModelo.codigoCampo === "numMatricu");

        return datoModeloObj.length > 0 && (
            <Typography style={{ fontSize: '0.875rem', fontWeight: 500 }}>
                <Term component='Tramites' text='matricula' />: {datoModeloObj[0].valor}
            </Typography>
        );
    }

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiFileAccount,
                title: <Term component="Tramites" text="Mis expedientes" />,
                right: (
                    <MenuItem button onClick={handleShowFilters}><Term component="Global" text="Filtrar resultados" /></MenuItem>
                ),
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    useEffect(() => {
        (async () => {
            handleFiltersChanged(filters);
            setTramitesCount(0);
            const query = buildQueryRequest(filters, 0);
            delete (query as any).skip;
            delete (query as any).take;
            const countResult = await gateway.getCountPresentaciones(query);
            setTramitesCount(countResult);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, gateway]);

    useEffect(() => {
        const query = buildQueryRequest(filters, 0);
        delete (query as any).skip;
        delete (query as any).take;
        const asyncEffect = async () => {
            const filterDataTemp = await gateway.getFilterDataPresentaciones(query);
            setFilterData(filterDataTemp);
        };

        asyncEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gateway]);

    return (
        <div className={classes.root}>
            <FiltersContext.Provider value={filtersReducer}>
                <FilterDataContext.Provider value={filterData}>
                    <div className={classes.root}>
                        <div className={classes.recibosContainer}>
                            <FiltersActive
                                onChange={handleFiltersChanged}
                            />
                            {
                                tramitesCount > 0
                                    ?
                                    <>
                                        <Card className={classes.cardContador}>
                                            <CardContent className={classes.cardContadorContainer} >
                                                <span className={classes.titleCardContador}>
                                                    <Term component="Tramites" text={'ExpedientesCount'} params={[tramitesCount]} />
                                                </span>
                                                <Button variant="contained" color="primary" size="small" className={classes.buttonPagarTodos} onClick={handleAlta}>
                                                    <Term component="Tramites" text="Nuevo expediente" />
                                                </Button>
                                            </CardContent>
                                        </Card>
                                        <InfiniteScroll
                                            pageStart={0}
                                            initialLoad={false}
                                            loadMore={handleLoadMoreMultas}
                                            hasMore={hasMore}
                                            loader={<div key="infiniteProgress" style={{ display: hasMore ? 'block' : 'none' }} className={classes.progressContainer}><CircularProgress className={classes.progress} /></div>}
                                        >
                                            {tramites.map((tramite: IExpediente, index: number) => (
                                                <Card key={'tramites_' + tramite.idExpediente} className={classes.recibo}>
                                                    <CardContent>
                                                        <Grid container>
                                                            <Grid item xs={12} md={1}>
                                                                <FormControl>
                                                                    <LabelField
                                                                        label={<Term component="Tramites" text="estado" />}
                                                                        text={<Term component="Tramites" text={'Estado_' + tramite.estado} />}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4}>
                                                                <FormControl>
                                                                    <LabelField
                                                                        label={<Term component="Tramites" text="numExpediente" />}
                                                                        text={tramite.referencia}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={4}>
                                                                <FormControl>
                                                                    <LabelField
                                                                        label={<Term component="Tramites" text="tipoExpediente" />}
                                                                        text={<Term component="Tramites" text={tramite.tipoExpediente} />}
                                                                    />
                                                                    {
                                                                        tramite.datosModelo.length > 0
                                                                        && handleTermTipoExpediente(tramite)
                                                                    }
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={1}>
                                                                <FormControl>
                                                                    <LabelField
                                                                        label={<Term component="Tramites" text="fecha" />}
                                                                        text={tramite.fecha}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={2} className={classes.textAlignRight}>
                                                                {
                                                                    tramite.estado === 'PRE'
                                                                        ? <div>
                                                                            <IconButton color="inherit" onClick={() => handleContinuar(tramite.idExpediente.toString())}>
                                                                                <Icon path={mdiEye}
                                                                                    size={1}
                                                                                    color={BLUE}
                                                                                />
                                                                            </IconButton>
                                                                            <IconButton color="inherit" onClick={() => handleImprimir(tramite)} disabled={imprimiendo[tramite.idExpediente]}>
                                                                                {imprimiendo[tramite.idExpediente] && <CircularProgress size={12} />}
                                                                                <Icon path={mdiPrinter}
                                                                                    size={1}
                                                                                    color={'green'}
                                                                                />
                                                                            </IconButton>
                                                                        </div>

                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    tramite.estado === 'ANU'
                                                                        ?
                                                                        <IconButton color="inherit" onClick={() => handleContinuar(tramite.idExpediente.toString())}>
                                                                            <Icon path={mdiEye}
                                                                                size={1}
                                                                                color={BLUE}
                                                                            />
                                                                        </IconButton>
                                                                        : null

                                                                }
                                                                {
                                                                    tramite.estado === 'INI'
                                                                        ?
                                                                        <div>
                                                                            <IconButton color="inherit" onClick={() => handleContinuar(tramite.idExpediente.toString())}>
                                                                                <Icon path={mdiEye}
                                                                                    size={1}
                                                                                    color={BLUE}
                                                                                />
                                                                            </IconButton>
                                                                            <IconButton color="inherit" onClick={() => handleAnularExpediente(tramite.idExpediente)}>
                                                                                <Icon path={mdiTrashCan}
                                                                                    size={1}
                                                                                    color={'red'}
                                                                                />
                                                                            </IconButton>
                                                                        </div>

                                                                        :
                                                                        null
                                                                }
                                                            </Grid>
                                                        </Grid>

                                                        {
                                                            tramite.escrituraPublica && tramite.escrituraPublica.documento && tramite.escrituraPublica.nrd
                                                                ? <>
                                                                    <Grid container>
                                                                        <Grid item xs={12} md={2}>
                                                                            <LabelField
                                                                                label={<Term component="Tramites" text="NRD" />}
                                                                                text={tramite.escrituraPublica.nrd}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} md={2}>
                                                                            <LabelField
                                                                                label={<Term component="Tramites" text="numProtocolo" />}
                                                                                text={tramite.escrituraPublica.protocolo}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} md={2}>
                                                                            <LabelField
                                                                                label={<Term component="Tramites" text="ejercicio" />}
                                                                                text={tramite.escrituraPublica.ejercicio}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} md={2}>
                                                                            <LabelField
                                                                                label={<Term component="Tramites" text="fecha" />}
                                                                                text={moment(tramite.escrituraPublica.fechaOtorgamiento).format('DD/MM/YYYY')}
                                                                            />
                                                                        </Grid>
                                                                        {
                                                                            tramite.escrituraPublica.notario
                                                                                ?
                                                                                <>
                                                                                    {tramite.escrituraPublica.notario.nif !== '00000000T' &&
                                                                                        <Grid item xs={12} md={2}>
                                                                                            <LabelField
                                                                                                label={<Term component="Tramites" text="nifNotario" />}
                                                                                                text={tramite.escrituraPublica.notario.nif}
                                                                                            />
                                                                                        </Grid>
                                                                                    }
                                                                                    <Grid item xs={12} md={2}>
                                                                                        <LabelField
                                                                                            label={<Term component="Tramites" text="nombreNotario" />}
                                                                                            text={tramite.escrituraPublica.notario.nombre}
                                                                                        />
                                                                                    </Grid>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                    </Grid>
                                                                </>
                                                                : null
                                                        }

                                                    </CardContent>
                                                </Card>
                                            ))}
                                        </InfiniteScroll>
                                    </>
                                    :
                                    noContent ?
                                        <div className={classes.container}>

                                            <NoContent
                                                image={image}
                                                visible={noContent}
                                                component='Tramites'
                                                text={''}
                                            />
                                            <Button className={classes.button} variant="contained" color="primary" size="small" onClick={handleAlta}>
                                                <Term component="Tramites" text="Nuevo expediente" />
                                            </Button>

                                        </div>
                                        :
                                        <div key="progress" className={classes.progressContainer}><CircularProgress className={classes.progress} /></div>
                            }
                        </div>

                        <div className={classes.drawer}>
                            <Hidden mdUp implementation="css">
                                <FiltersDialog
                                    open={showFilters}
                                    screen={'presentaciones'}
                                    onClose={handleHideFilters}
                                    onChange={handleFiltersChanged}
                                />
                            </Hidden>

                            <Hidden smDown implementation="css">
                                <PanelDrawer
                                    screen={'presentaciones'}
                                    onFiltersChange={handleFiltersChanged}
                                />
                            </Hidden>
                        </div>
                    </div>
                </FilterDataContext.Provider>
            </FiltersContext.Provider >
        </div>
    );
}

export default Presentaciones;