import React, { FC, useContext, useEffect, useMemo, useRef, useState } from "react";


import { Button, CircularProgress, Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import { TPago } from "../plusvalias/types";
import { IParamStepper } from "../shared/types";
import StepSchema from "../shared/stepSchema";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import ModelGateway from "gateways/model.new.gateway";
import { translate } from "utils/i18n";
import { IInfoStepPagoModelos } from "gateways/model.new.interface";
import moment from "moment";
import styles from './styles'
import Icon from "@mdi/react";
import { mdiCreditCard, mdiPrinter } from "@mdi/js";
import { useHistory } from "react-router";
import { AVISOS_MODELOS, Type_AvisosModelo } from "containers/tributos-autonomicos-locales/constants";
import { ContenidosGateway } from "gateways/contenido.gateway";
import { download } from "utils/download";


interface IPagoProps  extends IParamStepper, WithStyles<typeof styles>  {
    apartado: string;
    orden:number;
}
const Pago: FC<IPagoProps> = ({
    classes, idRef,apartado, orden, isFirst,isLast, handleBack,handleExit,handleNext
}) => {
    const componentMounted = useRef(true);
    const terms = useContext(LiteralsContext);
    const history= useHistory()
    const [,alertsDispatch] = useContext(AlertsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelosGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const contenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);

    
    const [loading, setLoading] = useState(false);
    const [info, setInfo]= useState<IInfoStepPagoModelos[]>([]);

    // avisos
    const [avisosModelos, setAvisosModelo] = useState<Type_AvisosModelo[]>([]);

    const disabledNext = useMemo(() => {
        const estados = info.map( mo=> (mo.estado === 'FIN' || mo.estado ==="COK")) // modelos cobrados
        return estados.includes(false);
    },[info])


    const {showPagar, showPagarDisabled, totalPagar, showImprimir} = useMemo(() => {
        let calcTotalPagar = info.reduce((acc,curr) => (acc+curr.importeAIngresar), 0);
        let totalPagar = parseFloat(calcTotalPagar.toFixed(2));

        const pagar = info.map(mo=> (mo.estado !== 'FIN' && mo.estado !== "COK")) // modelos cobrados
        const pagarDis = info.map( mo=> (mo.estado === "COK")) // modelos cobrados No confirmados
        const imprimir = info.map( mo=> (mo.estado === "FIN" || mo.estado === "IMP" )) // modelos Imprimibles

        return {
            showPagar: pagar.includes(true), 
            showPagarDisabled: pagarDis.includes(true), 
            totalPagar , 
            showImprimir: imprimir.includes(false) ? false: true}
    },[info])
    
    const onExit = (cause: 'continuar-mas-tarde'|'force') => {
        // validate if pending changes - MSG - save info ¿?
        handleExit()
    }

    const onBack = () => {
        handleBack()
    }

    const onNext = () => {
        // validate required data
        // isFirst? - Crear referencia
        // save info -> Data + Ref
        
        // si todo OK
        if(isLast){
            console.log('goNext end')
            // Nada que guardar - Salimos
            handleExit()
        }
        
    }

    const handlePay = async() => {
        try {
            setLoading(true)
            const urlPago= await modelosGateway.getUrlPago(idRef)
            setLoading(false)
            history.push(urlPago)
        } catch (error) {
            setLoading(false)
            alertsDispatch({
                type: "show-alert",
                payload: {
                    message: translate('Plusvalias', 'error_url_pago', terms),
                    variant: "error",
                },
            });
        }

    }
    const handleValidate = async() => {
        try {
            setLoading(true)
            const response= await modelosGateway.confirmarPagoFicticioAsistente(idRef, translate('Tributos', 'confirmarPagoFicticioError', terms))
            if (response.textoError) {
                throw new Error(translate('Tributos', response.textoError, terms));
            }
            setLoading(false)
            history.push(`/pasarela-pago/vueltaEntidad/${response.token}`)
            
        } catch (error) {
            const result = (error as Error).message;
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: result,
                    variant: 'error',
                }
            });
        }

    }
    const handleImprimir= async () => {
        try {
            setLoading(true);
            const result = await modelosGateway.getJustificantePago(idRef);
            download(result, alertsDispatch,
                translate('PasarelaPago', 'No es posible descargar la carta de pago', terms),
                translate('Global', 'BloqueoPantalla', terms));
            setLoading(false);
        } catch (error) {
            const result = (error as Error).message;
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: result,
                    variant: 'error',
                }
            });
        }
    }


    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                const info = await modelosGateway.getInfoStepPago(idRef)
                if(info){
                    const modelos = info.map( mo=> (mo.localizador.slice(0,3))) // modelos Imprimibles
                   
                    const avisosModelos = AVISOS_MODELOS.filter(aviso => modelos.includes(aviso.idModelo));
                    avisosModelos.forEach(async (aviso) => {
                        const contenido = await contenidosGateway.getContent(aviso.idTextoAviso, {});
                        setAvisosModelo(avisos => [...avisos, { ...aviso, textoAviso: contenido }]);
                    });
                }
                const resp = await modelosGateway.saveStepAsistente(idRef, apartado, orden, {})
                if(componentMounted.current){
                    //setters
                    setInfo(info)
                }
            } catch (err){
                const error = err as Error
                const errMSG = translate('Plusvalias', error.message, terms);
                const cause= error.cause ?? "";
                alertsDispatch({
                    type: "show-alert",
                    payload: {
                        message: errMSG.concat(`. ${cause}`),
                        variant: "error",
                    },
                });
            } finally {
                setLoading(false)
            }
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    },[])


    return(
        <StepSchema idRef={idRef} isFirst={isFirst} isLast={isLast} onNext={onNext} disabledNext={disabledNext} onBack={onBack} onExit={onExit}>
            <Typography className={classes.title}>{translate('Plusvalias','modelos_generados',terms).toUpperCase()}</Typography>
            {info.map( modelo => 
                <Grid key={modelo.localizador} container direction="row" spacing={2} style={{marginTop: 10, marginBottom:10}}>
                    <Grid item>{translate('Tributos','TributosResumenPagoLocalizador', terms)}: <b>{modelo.localizador}</b></Grid>
                    <Grid item>{translate('Tributos','TributosResumenPagoFechaCreacion', terms)}: <b>{moment(modelo.fechaCreacion).format('DD/MM/YYYY HH:mm')}h</b></Grid>
                    <Grid item>{translate('Tributos','TributosResumenPagoImporte', terms)}: <b>{modelo.importeAIngresar} €</b></Grid>
                    {modelo.estado === 'COK' && 
                        <Typography color="error" style={{fontStyle: 'italic'}}>{translate('Plusvalias','warning_estado_COK',terms)}</Typography>
                    }
                </Grid>
            )}
           

            {(showPagar || showImprimir || info.length>1 )&&
                <div className={classes.rowBtn}>
                    <div className={classes.row}>
                        { info.length>1 && 
                            <Typography className={classes.title}><b>{translate("Plusvalias","TotalPagar",terms).toUpperCase()}:  {totalPagar} €</b></Typography>
                        }
                    </div>
                    {(showPagar || showImprimir) && 
                        <Button disabled={(showPagar && showPagarDisabled) || loading}
                            variant={"contained"} color='primary' size="small"
                            startIcon= { <Icon path={ showPagar ? mdiCreditCard: mdiPrinter} size={1} color={'#fff'} /> }   
                            onClick={() => showPagar 
                                ? totalPagar === 0 ? handleValidate() : handlePay()
                                : handleImprimir()}
                        >
                            {translate("DUTI", showPagar ? (totalPagar === 0 ? "Validar": "Pagar"): "Imprimir",terms)}
                            {loading &&
                                <CircularProgress size={20} className={classes.circularProgressCentered} />
                            }
                        </Button>
                    }
                </div>
            }

            {avisosModelos.length > 0 && disabledNext &&
                <Grid item style={{ width: '100%' }}>
                    <div className={classes.containerAvisoModelo}>
                        {avisosModelos.map((avisoModelo, i) => (
                            avisoModelo.textoAviso && avisoModelo.textoAviso.length > 0 && 
                            <div key={`avisosModelo${avisoModelo.idTextoAviso}-${i}`} dangerouslySetInnerHTML={{ __html: avisoModelo.textoAviso[0].contenido }} 
                            />
                        ))}
                    </div>
                </Grid>
            }


        </StepSchema>
    )
}

export default  withLiterals(["Plusvalias","Global", "DUTI"])(withStyles(styles)(Pago))