import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { GetProcedimientosDto, IProcedimiento } from "./procedimientos.interfaces";

@inject('axios')
export class ProcedimientosGateway {

    constructor(
        private axios: AxiosInstance,
    ) { }

    public async getProcedimientos() {
        const response = await this.axios.get<IProcedimiento[]>('procedimientos?apoderable=true');

        return response.data;
    }

    public async getProcedimientosPoderdante(nifApoderado: string) {
        const response = await this.axios.get<IProcedimiento[]>(`procedimientos/apoderado?apoderable=true&nifApoderado=${nifApoderado}`);

        return response.data;
    }
}
