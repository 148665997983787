import React, { useEffect, useContext, useReducer, useCallback, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles, WithStyles, createMuiTheme } from "@material-ui/core";
import SideNavLeft from "./components/sidenav";

import AccesoIndex from "./containers/acceso/index";
import CarteroVirtualIndex from "./containers/cartero-virtual";
import PasarelaPagoIndex from "./containers/pasarela-pago";
import OficinasIndex from "./containers/oficinas";
import PerfilIndex from "./containers/perfil";
import Inicio from "./containers/inicio";
import FAQSIndex from "./containers/faqs";
// import DomiciliacionIndex from "./containers/domiciliaciones";
import NotificacionIndex from "./containers/notificaciones";
import Solicitudes from "containers/solicitudes";
import { withLiterals } from "containers/shared/literals";
import TributosIndex from "./containers/tributos-autonomicos-locales";

import TramitesIndex from "containers/tramites";
import ValidacionStepper from "containers/perfil/validacion-stepper";
import BienesIndex from "containers/bienes-sujeto";
import ContenidoIndex from "containers/contenidos";


import Notifications from "./components/notifications";
import AlertDialog from "./components/alert.dialog";
import { PageHeader } from "./components/page-header";
import PageFooter from "./components/page-footer";
import IoC from "contexts/ioc.context";
import { EventAggregator } from "aurelia-event-aggregator";
import usePage from "hooks/page.hook";
import { blue, orange } from "@material-ui/core/colors";
import { MuiThemeProvider, Theme, StyleRules } from "@material-ui/core/styles";

import Favicon from "react-favicon";
import { AlertsContext } from "contexts/alerts.context";
import AlertsReducer from "contexts/alerts.reducer";
import { MoreInfoContext } from "contexts/more_info.context";
import MoreInfoReducer from "contexts/more_info.reducer";
import MoreInfoDialog from "components/more-info.dialog";
import clsx from "clsx";
import Analytics from "react-router-ga";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AvisoNotificacionDialog from "components/aviso-notificacion.dialog";

import { configAppAfirma } from "utils/afirma";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import esLocale from "date-fns/locale/es";
import caLocale from "date-fns/locale/ca";
import RecaptchaProvider from "recaptcha";
import env from "./env";
import CitaPreviaIndex from "containers/cita-previa";
import RepresentantesDialog from "components/dialog-representantes";
import PopUpDialog from "atomic/organisms/pop-up-dialog/pop-up-dialog";
import { PopUpContext } from "contexts/pop-up.context";
import { PopUpsGateway } from "gateways/pop-ups.gateway";
import { IPopUpsContentItem } from "gateways/pop-ups.interfaces";
import AvisoVerificacionDatosSujetoDialog from "components/aviso-verificacion-datos-sujeto.dialog";
import { SujetosGateway } from "gateways/sujetos.gateway";
import { isBefore, subMonths } from 'date-fns';
import { ISujeto } from "gateways/perfil.interfaces";
import DialogProteccionDatos from "components/modal-proteccion-datos";
import { IProteccionDatos } from "gateways/proteccion-datos.interface";
import { ProteccionDatosGateway } from "gateways/proteccion-datos.gateway";
import PageHeaderNew from "components/page-header-new";
import { AuthGateway } from "gateways/auth.gateway";
import DutiIndex from "containers/DUTI";
import MapaWeb from "containers/mapa-web"


const themeProvider = createMuiTheme({
  palette: {
    primary: blue,
    secondary: orange
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: 8,
      },
    },
  },
});

const styles = (theme: Theme): StyleRules => ({
  component: {
    paddingTop: 5,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "55px",
    [theme.breakpoints.down('md')]: {
      paddingTop: '10%',
    },
  },
  componentWithoutHeader: {
    paddingTop: 0,
  },
  main: {
    display: "flex",
    flex: 1,
    minHeight: '100vh',
    [theme.breakpoints.between('sm', 'md')]: {
      paddingTop: 25,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 17,
    }
  },
  mainWithoutHeader: {
    marginTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    height: "100%",
  },
  mainContainer: {
    width: "100%",
  },
  pageTitle: {
    display: "none",
  },
  main2: {
    paddingTop: 0,
    height: "100%",
  },
  pageContent: {
    display: "flex",
    //justifyContent: 'center',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 50,
    }
  },
  [theme.breakpoints.up("md")]: {
    component: {
      paddingTop: 230,
      display: "flex",
      marginTop: "0px",
    },
    componentWithoutHeader: {
      paddingLeft: 0,
      paddingTop: 0,
    },
    switch: {
      flex: 1,
    },
    main: {
      paddingTop: 0,
    },
    main2: {
      paddingTop: 0,
      height: "100%",
    },
    mainWithoutHeader: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
      height: "100%",
    },
  },
});



type Props = WithStyles<typeof styles>;

const localeMap: { [lang: string]: Locale } = {
  es: esLocale,
  ca: caLocale,
};

const App: React.FC<Props> = (props) => {
  const { classes } = props;
  const [pageState, pageDispatcher] = usePage();
  const ioc = useContext(IoC);
  const popUpsGateway: PopUpsGateway = ioc.get(PopUpsGateway);
  const alertsReducer = useReducer(AlertsReducer, { alert: null });
  const moreInfoReducer = useReducer(MoreInfoReducer, { info: null });
  const [, popUpsDispatch] = useContext(PopUpContext);
  const isLogged = pageState.jwp !== null;
  const perfilG = ioc.get(SujetosGateway) as SujetosGateway;
  const authGateway = ioc.get(AuthGateway) as AuthGateway;
  const proteccionDatosG = ioc.get(ProteccionDatosGateway) as ProteccionDatosGateway;
  // Params validación Datos Sujeto
  const [sujeto, setSujeto] = useState<ISujeto | null>(null);
  const [fechaDatosValidados, setFechaDatosValidados] = useState<Date | null>(null);
  const [isDev, setIsDev] = useState(false);

  const pathsToClacWidth = [
    '/cartero-virtual/deuda-pendiente',
    '/cartero-virtual/deuda-pagada',
    '/domiciliaciones/domiciliados',
    '/domiciliaciones/domiciliables',
    '/oficinas',
    '/tramites/conductor',
    '/tramites/censo',
    //'/tramites/abono-nacimiento'
  ];

  useEffect(() => {
    if (process.env.NODE_ENV === "development" || "qa") {
      setIsDev(true)
    }
  }, [process.env.NODE_ENV]);


  useEffect(() => {
    handlePopUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    handlePopUpVerificarDatosSujeto();
  }, [pageState.openAltaAlert]);

  useEffect(() => {
    if (!isLogged) return;
    (async () => {
      await handleLoadDatosSujeto();
      await handleLoadAcessoModulos();
    })();
  }, [isLogged]);

  const checkStoredPopUps = useCallback((popUps, storedPopUps) => {
    return popUps.filter(
      (x: IPopUpsContentItem) =>
        !JSON.stringify(storedPopUps).includes(JSON.stringify(x))
    );
  }, []);

  const handlePopUp = useCallback(async () => {
    let stringArray = sessionStorage.getItem("popUpRoutes");
    let url = window.location.pathname.indexOf('/cva') > -1
      ? window.location.pathname.substring(4)
      : window.location.pathname
    let popUps = await popUpsGateway.getPopUps(url);
    if (stringArray !== null) {
      let storedPopUps = JSON.parse(stringArray);
      let validatedPopUps = checkStoredPopUps(popUps, storedPopUps);
      if (validatedPopUps.length !== 0) {
        sessionStorage.setItem(
          "popUpRoutes",
          JSON.stringify([...storedPopUps, ...validatedPopUps])
        );
        popUpsDispatch({
          type: "show-popUp",
          payload: {
            texts: validatedPopUps.map((x: { contenido: any }) => x.contenido),
          },
        });
      }
    }
    else {
      if (popUps.length !== 0) {
        sessionStorage.setItem("popUpRoutes", JSON.stringify([...popUps]));
        popUpsDispatch({
          type: "show-popUp",
          payload: {
            texts: popUps.map((x: { contenido: any }) => x.contenido),
          },
        });
      }
    }
  }, [checkStoredPopUps, popUpsDispatch, popUpsGateway]);

  const handleCloseAltaAlert = () => {
    pageDispatcher({ type: "hide-alta-alert" });
    handlePopUpVerificarDatosSujeto();
  };

  const handleLoadAcessoModulos = async () => {
    try {
        const accesoModulos = await authGateway.getAccesoModulos();
        if (!accesoModulos) return;
        localStorage.setItem('acceso.modulos', JSON.stringify(accesoModulos))
    } catch (error) {
        return;
    }
  }

  // Modal validación datos sujeto
  const handleLoadDatosSujeto = async () => {
    const sujetoTemp = await perfilG.getDatosSujeto();
    if (!sujetoTemp) return;
    setSujeto(sujetoTemp);
  }

  const handleCloseVerificacionDatosSujetoDialog = () => {
    pageDispatcher({ type: "close-info-datos-sujeto" });
  };

  const handlePopUpVerificarDatosSujeto = async () => {
    if (!isLogged) return;

    const fechaDatosValidados: Date | null = await perfilG.getFechaValidacionDatos();

    if (pageState.openAltaAlert) return;
    setFechaDatosValidados(fechaDatosValidados);

    const isVerificacionRealizada = sessionStorage.getItem('verificacionDatosSujeto');
    if (isVerificacionRealizada) return;

    sessionStorage.setItem('verificacionDatosSujeto', '1');

    const mesesExpiracionDatosSujeto: number = await perfilG.getMesesExpiracionValidacionDatosSujeto();

    if (!mesesExpiracionDatosSujeto) return;

    let rangoVerificacion = subMonths(new Date(), mesesExpiracionDatosSujeto);

    if ((!fechaDatosValidados || fechaDatosValidados === null) || isBefore(new Date(fechaDatosValidados), rangoVerificacion)) {
      pageDispatcher({ type: "open-info-datos-sujeto" });
    }
  }

  // Stepper modificación datos sujeto

  const handleValidacionDatosSujeto = () => {
    handleCloseVerificacionDatosSujetoDialog();
    pageDispatcher({ type: "open-modificacion-datos-sujeto" });
  };

  const handleSujetoActualizado = async (isSuccess: boolean) => {
    pageDispatcher({ type: "close-modificacion-datos-sujeto" });

    if (!isSuccess) pageDispatcher({ type: "open-error-datos-sujeto" });
    else {
      await handleLoadDatosSujeto();
      pageDispatcher({ type: "open-success-datos-sujeto" });
    }
  };

  // Modal protección datos

  const handleCloseDialogProteccionDatos = () => {
    pageDispatcher({ type: "close-proteccion-datos-sujeto" });
  }

  const handleOpenModalProteccionDatos = (datosProteccionSujeto: IProteccionDatos | null) => {
    const checkIsProteccionDatosRequestedThisSession = sessionStorage.getItem('isProteccionDatosRequestedThisSession');

    if (!checkIsProteccionDatosRequestedThisSession && !datosProteccionSujeto) {
      pageDispatcher({ type: "open-proteccion-datos-sujeto" });
    }
    else if (checkIsProteccionDatosRequestedThisSession && checkIsProteccionDatosRequestedThisSession === '1') {
      pageDispatcher({ type: "close-proteccion-datos-sujeto" });
    }
  };

  useEffect(() => {
    if (pageState.jwp !== null) {
      (async () => {
        const response: IProteccionDatos | null = await proteccionDatosG.getSelfProteccionDatos();
        handleOpenModalProteccionDatos(response);
      })();
    }
  }, [pageState.jwp]);


  useEffect(() => {
    const ea: EventAggregator = ioc.get(EventAggregator);
    const subscription = ea.subscribe("auth.jwt.expired", () => {
      pageDispatcher({ type: "logout" });
    });
    // Configuración de la aplicación de firma
    configAppAfirma();
    return () => {
      subscription.dispose();
    };
  }, [ioc, pageDispatcher]);

  return (
    <RecaptchaProvider>
      <MuiThemeProvider theme={themeProvider}>
        <AlertsContext.Provider value={alertsReducer}>
          <MoreInfoContext.Provider value={moreInfoReducer}>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={localeMap[pageState.lang] || localeMap["es"]}
            >
              <>
                <Router basename={env.PUBLIC_URL}>
                  <Analytics id="UA-148981657-1" debug>
                    <Favicon url='./favicon.ico' />
                    <CssBaseline />
                    {/* <PageHeader /> delete  */}
                    <PageHeaderNew />
                    <div
                      className={
                        pageState.header || pageState.menu || pageState.footer
                          ? classes.main
                          : classes.main2
                      }
                    >
                      <main
                        className={[
                          classes.main,
                          pageState.header ? "" : classes.mainWithoutHeader,
                        ].join(" ")}
                      >

                        <SideNavLeft />
                        <div
                          className={clsx(
                            classes.component,
                            pageState.header ? "" : classes.componentWithoutHeader
                          )}
                        >
                          <div className={classes.pageContent}>
                            <Switch>
                              <Route path={`/`} exact component={Inicio} />
                              {/** Acceso genérico - No disponen de ruta en el menú */}
                              <Route path={`/acceso`} component={AccesoIndex} />
                              <Route path={`/pasarela-pago`} component={PasarelaPagoIndex} />

                              <Route path={`/solicitudes`} component={Solicitudes} />  {/** <--- Acceso desde trámites*/}
                              {/* <ProtectedRoute path={`/cartero-virtual/domiciliaciones`}  component={DomiciliacionIndex}/>*/} {/** <--- Acceso desde cartero-virtual*/}
                              <Route path={`/contenidos`} component={ContenidoIndex} />  {/* <--- Contenido as Componente Trasladado como RUTA en acceso y  Notificaciones +info*/}

                              {/** Acceso a módulos -> Desde Menu o Home */}
                              <Route path={`/bienes-sujeto`} component={BienesIndex} /> {/*ProtectedRoute*/}
                              <Route path={`/oficinas`} component={OficinasIndex} />

                              <Route path={`/tramites`} component={TramitesIndex} />
                              <Route path={`/cartero-virtual`} component={CarteroVirtualIndex} />
                              {/* <ProtectedRoute path={`/mensajes`} component={MensajeIndex}/> */}
                              <Route path={`/notificaciones`} component={NotificacionIndex} />
                              <Route path={`/perfil`} component={PerfilIndex} /> {/*ProtectedRoute*/}
                              <Route path={`/cita-previa`} component={CitaPreviaIndex} />
                              <Route path={`/faqs`} component={FAQSIndex} />
                              {/* <Routepath={`/tributos/autonomicos`}component={TributosAutonomicosIndex_old} /> */}
                              <Route path={`/tributos`} component={TributosIndex /**TributosAutonomicosLocalesIndex */} />
                              {/* <Route path={`/DUTI`} component={DutiIndex} /> */}{/** <--- Acceso desde tributos */}
                              <Route path={`/mapa-web`} component={MapaWeb} />


                            </Switch>
                          </div>
                          {pageState.header ||
                            pageState.menu ||
                            pageState.footer ? (
                            <PageFooter
                              footerCalculated={
                                pathsToClacWidth.filter(function (val) {
                                  return (window.location.pathname.includes(val))
                                }).length > 0
                              }
                              // footerCalculated={pathsToClacWidth.includes(window.location.pathname)}
                            />
                          ) : null}
                        </div>
                      </main>
                    </div>
                  </Analytics>
                </Router>
                <Notifications />
                <AlertDialog />
                <RepresentantesDialog />
                <MoreInfoDialog />
                <PopUpDialog />
                <AvisoNotificacionDialog
                  open={pageState.openAltaAlert}
                  onClose={handleCloseAltaAlert}
                />
                {pageState.datosSujetoDialog.open &&
                  <AvisoVerificacionDatosSujetoDialog
                    sujeto={sujeto}
                    fechaDatosValidados={fechaDatosValidados}
                    onModificacionDatosSujeto={handleValidacionDatosSujeto}
                    onClose={handleCloseVerificacionDatosSujetoDialog}
                  />
                }
                {pageState.openModificacionDatosSujetoDialog &&
                  <ValidacionStepper
                    sujeto={sujeto}
                    onClose={handleSujetoActualizado}
                  />
                }
                {(!isDev && pageState.openProteccionDatosSujetoDialog) &&
                  <DialogProteccionDatos
                    sujeto={sujeto}
                    onClose={handleCloseDialogProteccionDatos}
                  />
                }
              </>
            </MuiPickersUtilsProvider>
          </MoreInfoContext.Provider>
        </AlertsContext.Provider>
      </MuiThemeProvider>
    </RecaptchaProvider>
  );
};

export default withLiterals([
  "Global", "Acceso", "Perfil", "CarteroVirtual", "Tramites", "Notificaciones", 
  "Domiciliaciones", "Tributos", "BienesSujeto", "DUTI","Plusvalias","AbonoNacimiento"
])(withStyles(styles)(App));