import React, { FC, useEffect, useState } from "react";
import TypedInputNumber from "../../DUTI/typed-input-number";
import { TComplementariaComprador} from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types";
import { AdditionalInfoRenderProps } from "./sujetoForm";
import { withLiterals } from "containers/shared/literals";


const EMPTY :TComplementariaComprador={ porcentaje: 0}

const ExtraFieldsComprador: FC<AdditionalInfoRenderProps<TComplementariaComprador>> = ({
    renderType, initialValues, onChange, showRequireds,reset, onReset
}) => {
    const [localPorcentajeErr,setLocalPorcentajeErr]= useState(false)
  
    useEffect(() => {
        if(showRequireds){
            let errPorcentaje, errFecha
            if(initialValues){
                errPorcentaje = initialValues.porcentaje === 0
            } else {
                errFecha = false
                errPorcentaje = true
            }
            setLocalPorcentajeErr(errPorcentaje)
        }
    }, [showRequireds, initialValues])

    useEffect(()=> {
        if(reset){
            onChange(undefined)
            setLocalPorcentajeErr(false)
            onReset(true)
        }
    },[reset])

    return(
        
        <div style={{display:'inline-flex'}}>
            <TypedInputNumber key='percentaje'
                type="percentaje"
                fieldTerm={"porcentajeAdquisicion"}
                value={initialValues?.porcentaje ?? 0}
                onChangeValue={(v,err) => {
                    onChange(initialValues ? {...initialValues, porcentaje: v}: {...EMPTY,porcentaje: v})
                    setLocalPorcentajeErr(err)
                }}
                required
                allowZero={false}
                maxDecimal={2}
                error={localPorcentajeErr}
                disabled={renderType=== 'detail'}
            />
        </div>
    )

}

export default (withLiterals(['DUTI','Global', 'Plusvalias']))(ExtraFieldsComprador)