import React, { FC, useContext } from "react";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { Grid, WithStyles, withStyles } from "@material-ui/core";
import duti_styles from "containers/DUTI/styles";

import TypedInputNumber from "./typed-input-number";
import InputText from "./input-text";
import { translate } from "utils/i18n";
import { TExtraValidation } from "./types";

export type TContactoFlags= Record<keyof TContacto, boolean>
export type TContacto = {
    telefono: number | undefined,
    email: string
}
export const EMPTY_CONTACTO: TContacto= {email: "", telefono: undefined}
export const EMPTY_CONTACTO_ERRORs: TContactoFlags= {email: false, telefono:false}
export const ALL_CONTACTO_DISABLED: TContactoFlags= {email: true, telefono:true}



interface IContactForm extends WithStyles {
    contact: TContacto;
    setContact: (d: TContacto) => void
    contactErrors: TContactoFlags
    setContactErrors: (cErr: TContactoFlags) => void,
    disabled?: TContactoFlags
    required?: TContactoFlags

};

const ContactForm : FC<IContactForm> = ({classes, contact, setContact,contactErrors,setContactErrors,disabled, required={email:false, telefono:false} }) => {

    const terms = useContext(LiteralsContext);

    const REGEX_EMAIL = new RegExp(/^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}/, "gm"); 
    const validateEmail = (email:string) => {
        const isOK = REGEX_EMAIL.test(email)
        setContactErrors({...contactErrors, email: !isOK })
        return({
            error: !isOK, 
            error_msg: translate('DUTI','email_format_error', terms)
        } as TExtraValidation)
    }
    
    return(
        <Grid container direction="column" spacing={0}>
            <Grid item container direction="row">
                <TypedInputNumber 
                    type="tlf"
                    value={contact?.telefono}
                    onChangeValue={(v: number|undefined, err: boolean) => {
                        setContact({...contact, telefono: v})
                        setContactErrors({...contactErrors, telefono: err })
                    }}
                    fieldTerm={'telefono'}
                    required={required.telefono} 
                    disabled={disabled?.telefono}
                    error={contactErrors.telefono}
                />
                <InputText 
                    fieldTerm='email' 
                    value={contact.email} 
                    onChangeValue={(v: string, err: boolean) =>{
                        setContact({...contact, email: v})
                    }} 
                    required={required.email}
                    className={classes.input360}
                    extraValidation={validateEmail}
                    disabled={disabled?.email}
                    error={contactErrors.email}
                />
            </Grid>
        </Grid>
    );

}

export default withLiterals(['DUTI'])(withStyles(duti_styles)(ContactForm));