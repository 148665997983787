import { FC, useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  WithStyles,
} from "@material-ui/core";

import { RouteComponentProps } from "react-router";

import styles from "../shared/tributos.styles";

import { withRouter } from "react-router-dom";
import { ContenidosGateway } from "gateways/contenido.gateway";
import IoC from "contexts/ioc.context";
import Term from "components/term";
import DeudaPagada from "../../resources/DeudaPagada.png";
import DeudaPendiente from "../../resources/DeudaPendiente.png";

interface IParams {
  open: boolean;
  selectedMuniAtib: boolean;
  handleClose: () => void;
  handleRedirectDeuda: (pendiente: boolean) => void;
}

type Props = WithStyles<typeof styles> & RouteComponentProps;

const RecibosDialog: FC<Props & IParams> = ({
  open,
  selectedMuniAtib,
  handleClose,
  handleRedirectDeuda,
}) => {
  const ioc = useContext(IoC);
  const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
  const [bodyRedireccion, setBodyRedireccion] = useState("");
  const [bodyNoGest, setBodyNoGest] = useState("");

  useEffect(() => {
    const func = async () => {
      const bodyAux = await contenidoGateway.getContent(
        "bienes_sujeto_redireccion",
        {}
      );
      const bodyAuxNoGest = await contenidoGateway.getContent(
        "bienes_sujeto_no_gest",
        {}
      );
      setBodyNoGest(bodyAuxNoGest[0].contenido);
      setBodyRedireccion(bodyAux[0].contenido);
    };
    func();
  }, []);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Term component="Global" text="Informacion" />
      </DialogTitle>
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{
            __html: selectedMuniAtib ? bodyRedireccion : bodyNoGest,
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          {selectedMuniAtib ? (
            <>
              <Button
                style={{
                  margin: "0px 50px",
                }}
                variant="text"
                onClick={() => handleRedirectDeuda(false)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Alta notificaciones"
                    src={DeudaPagada}
                    style={{ width: 75 }}
                  ></img>
                  <Term
                    component="BienesSujeto"
                    text="Ver recibos locales pagados"
                  />
                </div>
              </Button>
              <Button
                style={{
                  margin: "0px 50px",
                }}
                variant="text"
                onClick={() => handleRedirectDeuda(true)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img alt="Alta notificaciones" src={DeudaPendiente}></img>
                  <Term
                    component="BienesSujeto"
                    text="Ver recibos locales pendientes"
                  />
                </div>
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="default" onClick={handleClose}>
          <Term component="Global" text="Cerrar" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(withStyles(styles)(RecibosDialog));
