import { setForceWSMode, setServlets, cargarAppAfirma, downloadRemoteData, sign, setToken } from './miniapplet.js';
import { AfirmaGateway } from 'gateways/afirma.gateway.js';
import { ISignature } from 'gateways/afirma.interfaces.js';
import { getBrowser, getPlatform, getUserAgent } from './client';
import { getAccessToken } from 'hooks/page.hook';
import * as Sentry from '@sentry/browser';
import env from '../env';

export class Afirma {
    private idOrden: number;
    private origen: string;
    private gateway: AfirmaGateway;
    private resultFunctionCallback: any;

    constructor(idOrden: number, gateway: AfirmaGateway, origen: string, resultFunctionCallback: any) {
        this.idOrden = idOrden;
        this.gateway = gateway;
        this.origen = origen;
        this.resultFunctionCallback = resultFunctionCallback;
    }

    public processSignature() {
        const urlDownload = env.AFIRMA_DESCARGA_DOCUMENTO ? env.AFIRMA_DESCARGA_DOCUMENTO + this.idOrden : '';
        const urlStorage = env.AFIRMA_STORAGE ? env.AFIRMA_STORAGE + this.idOrden : '';
        const urlRetriever = env.AFIRMA_RETRIEVER ? env.AFIRMA_RETRIEVER + this.idOrden : '';
        const accessToken = getAccessToken();
        setServlets(urlStorage, urlRetriever);
        setToken(accessToken);
        downloadRemoteData(urlDownload, this.initFirma, this.showErrorInitFirma);
    }

    private initFirma = (datosB64: any) => {
        sign(datosB64, "SHA256withRSA", "XAdES", null, this.saveSignatureFuntion, this.showErrorFunction);
    }

    private showErrorInitFirma(e: any) {
        Sentry.captureException(e);
        this.resultFunctionCallback(0);
    }

    private saveSignatureFuntion = async (signatureB64: string) => {
        const idFirma = await this.saveSignature(signatureB64);

        this.resultFunctionCallback(idFirma);
    }

    private showErrorFunction = (type: any, message: string) => {
        Sentry.withScope((scope) => {
            scope.setExtras(type);
            Sentry.captureException(message);
        });
        this.resultFunctionCallback(0);
    }

    private async saveSignature(textoFirmado?: string): Promise<number> {
        const signature: ISignature = {
            browser: getBrowser(),
            origen: this.origen,
            platform: getPlatform(),
            textoFirmado: textoFirmado ? textoFirmado : '',
            textoSinFirmar: 'Se ha aceptado el contenido adjunto de ' + this.origen,
            userAgent: getUserAgent(),
            idOrden: this.idOrden,
        }

        return await this.gateway.saveSignature(signature);
    }
}

export function configAppAfirma() {
    setForceWSMode(true);
    cargarAppAfirma('');
}