import React, {FC, useContext} from "react";

import { RadioGroup, FormControlLabel, Radio, FormControl, withStyles, WithStyles, FormHelperText } from "@material-ui/core";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import duti_styles from "containers/DUTI/styles";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import LabelWithInfo, { TLabelType } from "./label-with-info";


export type IRadioButtonOption = { id: string, term: string, label?: string} // Label para cuando no podemos aplicar un term IG directamente};

interface IRadioButton extends WithStyles {
    options: IRadioButtonOption[]
    fieldTerm: string,
    value: string | undefined
    onChangeValue: (v:string) => void,
    moreInfo?: {
        template: string, size?: TInfoTemplateSize
    },
    row?:boolean
    error?:boolean,
    errorMsg?:string,
    labelAs?: TLabelType | 'notLabel'
    disabled?: boolean;
    componentIG?: string
};


const RadioButton : FC<IRadioButton>= ({classes, options, fieldTerm, value, onChangeValue, moreInfo, row, error, errorMsg, labelAs='field', disabled,componentIG}) => {
    const terms = useContext(LiteralsContext);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value:string) => {
        onChangeValue(value);
    };

    return (
        <FormControl component="fieldset">
            { labelAs !== 'notLabel' && <LabelWithInfo type={labelAs} fieldTerm={fieldTerm} componentIG={componentIG} moreInfo={moreInfo} />}
            <RadioGroup value={value ?? ''} onChange={handleChange} row={row}>
            {options.map( o => (
                <FormControlLabel style={{marginRight: 20}}
                    key={o.id} 
                    value={o.id} 
                    control={<Radio color="primary" />} 
                    label={translate( componentIG ?? 'DUTI', o.term, terms).concat(o.label ?? '')} 
                    disabled={disabled}
                />
            ))}
            </RadioGroup>
            {error && <FormHelperText error >{errorMsg}</FormHelperText>}
        </FormControl>
    )
}

export default withLiterals(['Global','DUTI', 'Plusvalias'])(withStyles(duti_styles)(RadioButton));