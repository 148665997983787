import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { Card, withStyles, WithStyles, CircularProgress, Button, Stepper, Step, StepLabel, StepConnector, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Term from 'components/term';
import IoC from 'contexts/ioc.context';
import usePage from 'hooks/page.hook';
import styles from './cita-previa.styles';
import { RouteComponentProps, withRouter } from 'react-router';
import { Icon } from '@mdi/react';
import { mdiFormatListBulleted, mdiAccount, mdiClipboardCheckOutline, mdiCalendarClock } from '@mdi/js';
import SeleccionarTramite from './cita-previa-steps/seleccionar-tramite';
import DatosPersonales from './cita-previa-steps/datos-personales';
import ResumenCita from './cita-previa-steps/resumen-cita';
import CitaPreviaGateway from 'gateways/citaPrevia.gateway';
import { IDetallesOficina, IDiasHorariosCita, IDisponibilidadCita, IParamsCitaPrevia, IBookAppointment, ISuccessBookAppointment } from 'gateways/citaPrevia.interface';
import { AlertsContext } from 'contexts/alerts.context';
import { Disable } from 'react-disable';
import { ISujeto } from 'gateways/perfil.interfaces';
import { SujetosGateway } from 'gateways/sujetos.gateway';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import OficinasGateway from 'gateways/oficinas.gateway';
import IOficinaDto from 'gateways/oficinas.interfaces';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 30,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

type Props = WithStyles<typeof styles> & RouteComponentProps;

const CitaPrevia: React.FC<Props> = ({ classes, history }) => {
    const [loading, setLoading] = useState(false);
    const ioc = useContext(IoC);
    const citaPreviaGateway: CitaPreviaGateway = ioc.get(CitaPreviaGateway);
    const oficinasGateway: OficinasGateway = ioc.get(OficinasGateway);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);

    const [, alertsDispatch] = useContext(AlertsContext);
    const [pageState, pageDispatcher] = usePage();
    const perfilG = useMemo(() => ioc.get(SujetosGateway) as SujetosGateway, [ioc]);
    const terms = useContext(LiteralsContext);
    // HEADER
    const [, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const handleToggleSubMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpenSubMenuAnchorEl(event.currentTarget);
    }, []);
    // STEPPER
    const [activeStep, setActiveStep] = useState(0);
    const [, setBackButton] = useState(false);
    const [displayStepper, setDisplayStepper] = useState(true);
    // TRAMITES
    const [diccionarioTramites, setDiccionarioTramites] = useState<IDisponibilidadCita | null>(null);
    const [, setTramiteSeleccionado] = useState<string | null>(null);
    const [, setProcedureAggrupationId] = useState(0);
    const [oficinasList, setOficinasList] = useState<IDetallesOficina[] | null>(null);
    const [oficinasListSede, setOficinasListSede ] = useState <IOficinaDto[]>([]);
    const [, setOficinaValue] = useState<string | null>(null);
    const [horariosOficina, setHorariosOficina] = useState<IDiasHorariosCita | null>(null);
    // OTHER
    const [paramsCita, setParamsCita] = useState<IParamsCitaPrevia>();
    const [codigoCita, setCodigoCita] = useState<string | null>(null);
    const [notVisible, setNotVisible] = useState(true);
    const [validate, setValidate] = useState(false);
    const [confirmValidate, setConfirmValidate] = useState(false);
    const [sujeto, setSujeto] = useState<ISujeto | null>(null);
    const [bodyAbonos, setBodyAbonos] = useState<string>('');
    const [abonosDialogOpen, setAbonosDialogOpen] = useState<boolean>(false);

    const setIconStepOne = () => {
        return (
            <Button onClick={handleJumpStepOne}>
                <div
                    className={[classes.iconBox, activeStep === 0 ? classes.activeIconBox : classes.nonActiveIconBox].join(' ')}
                    style={activeStep > 0 ? { backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))' } : { backgroundColor: 'lightgrey' }}
                >
                    <Icon path={mdiFormatListBulleted}
                        size={'25px'}
                        color={"white"}
                    />
                </div>
            </Button>
        )
    }
    const setIconStepTwo = () => {
        return (
            <Button onClick={handleJumpStepTwo}>
                <div
                    className={[classes.iconBox, activeStep === 1 ? classes.activeIconBox : classes.nonActiveIconBox].join(' ')}
                    style={activeStep > 1 ? { backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))' } : { backgroundColor: 'lightgrey' }}
                >
                    <Icon path={mdiAccount}
                        size={'25px'}
                        color={"white"}
                    />
                </div>
            </Button>
        )
    }
    const setIconStepThree = () => {
        return (
            <Button onClick={handleJumpStepThree}>
                <div
                    className={[classes.iconBox, classes.completed, activeStep === 2 ? classes.activeIconBox : classes.nonActiveIconBox].join(' ')}
                    style={activeStep > 2 ? { backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))' } : { backgroundColor: 'lightgrey' }}
                >
                    <Icon path={mdiClipboardCheckOutline}
                        size={'25px'}
                        color={"white"}
                    />
                </div>
            </Button>
        )
    }

    const handleRealizarCita = useCallback(async () => {
        setLoading(true);
        try {
            if (paramsCita !== undefined) {
                const paramsReservarCita: IBookAppointment = {
                    GroupCode: "G",
                    OfficeCode: paramsCita.oficina && paramsCita.oficina.code,
                    OfficeName: paramsCita.oficina && paramsCita.oficina.name,
                    OfficeAddress: paramsCita.oficina && paramsCita.oficina.address,
                    Dni: paramsCita.docNum,
                    AppointmentTime: (paramsCita.fechaCita + ' ' + paramsCita.horaCita),
                    Surname: '',
                    Email: paramsCita.email,
                    Name: paramsCita.nombreCompleto,
                    Description: null,
                    ChannelCode: "2",
                    MadeByPhone: "0",
                    IdProcedure: paramsCita.tramiteId
                }
                paramsCita.phone
                    ? paramsReservarCita.Phone = paramsCita.phone
                    : paramsReservarCita.Phone = null
                const resultadoReservaCita: ISuccessBookAppointment = await citaPreviaGateway.reservarCita(paramsReservarCita);
                if (resultadoReservaCita.data !== null) {
                    setDisplayStepper(false);
                    setCodigoCita(resultadoReservaCita.data.Entity);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: <Term component="CitaPrevia" text="Reservar cita exito" />,
                            variant: 'success',
                        }
                    });
                    if (pageState.jwp !== null) {
                        history.push('/cita-previa/mis-citas');
                    }
                }
            }
        }
        catch (e) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component="CitaPrevia" text="Reservar cita error" />,
                    variant: 'error',
                }
            });
            throw Error(e as string);
        }
        finally {
            setLoading(false);
        }
    }, [alertsDispatch, citaPreviaGateway, history, pageState.jwp, paramsCita]);


    const handleHideButtonOrMakeAppointment = useCallback(() => {
        if (activeStep === 1) {
            setValidate(true);
            if (confirmValidate) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setNotVisible(false);
                setValidate(false);
                setConfirmValidate(false);
            }
        }
        else if (activeStep === 2) {
            handleRealizarCita();
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setNotVisible(true);
        }
    }, [activeStep, confirmValidate, handleRealizarCita]);

    const handleNextStepShowButton = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setNotVisible(false);
    }, []);

    const handleJumpStepOne = useCallback(() => {
        if (paramsCita && paramsCita.tramiteSeleccionado !== undefined) {
            setActiveStep(0);
            setNotVisible(false);
        }
    }, [paramsCita]);

    const handleJumpStepTwo = useCallback(() => {
        if (paramsCita && paramsCita.docNum !== undefined) {
            setActiveStep(1);
            setNotVisible(false);
        }
    }, [paramsCita]);

    const handleJumpStepThree = useCallback(() => {
        if (paramsCita && paramsCita.docNum !== undefined && paramsCita.tramiteSeleccionado !== undefined) {
            setActiveStep(2);
        }
    }, [paramsCita]);

    const handleNext = useCallback((aggrupationId: string, aggrupationName: string, tramiteId: string, tramite: string, oficina: IDetallesOficina, fechaCita: string, horaCita: string) => {
        let paramsCitaTemp: IParamsCitaPrevia = {
            aggrupationId: aggrupationId,
            nombreAgrupacionTramite: aggrupationName,
            tramiteId: tramiteId,
            tramiteSeleccionado: tramite,
            oficina: oficina,
            fechaCita: fechaCita,
            horaCita: horaCita,
        };
        setParamsCita(paramsCitaTemp);
        setNotVisible(false);
    }, []);

    const handleLastStep = useCallback((docNum: string, email: string, telefonoMovil: string, nombreCompleto: string) => {
        if (paramsCita !== undefined) {
            let paramsCitaTemp: IParamsCitaPrevia = {
                nombreAgrupacionTramite: paramsCita.nombreAgrupacionTramite,
                tramiteSeleccionado: paramsCita.tramiteSeleccionado,
                tramiteId: paramsCita.tramiteId,
                oficina: paramsCita.oficina,
                fechaCita: paramsCita.fechaCita,
                horaCita: paramsCita.horaCita,
                nombreCompleto: nombreCompleto,
                docNum: docNum,
                email: email,
                phone: telefonoMovil,
            }
            setParamsCita(paramsCitaTemp)
            setNotVisible(false);
        }
    }, [paramsCita]);

    const handleOficinasList = useCallback(async (procedureAggrupationId: number) => {
        if (procedureAggrupationId !== 0) {
            let oficinasList = await citaPreviaGateway.getOficinasById(procedureAggrupationId);
            oficinasList.sort((a, b) => a.name.localeCompare(b.name));
            setOficinasList(oficinasList);
        }
    }, [citaPreviaGateway]);

    const loadOficinasListSede = useCallback(async ()=> {
        const result = await oficinasGateway.findAll();
        setOficinasListSede(result);
    },[oficinasGateway])


    const handleComprobarTramiteAbono = useCallback (async (tramiteId: number) => {
        //DWA-16264
        if (tramiteId===22 || tramiteId===23 ){
            const bodyAux = await contenidoGateway.getContent(
                "AvisoCitaAbono",
                {}
            );
            setBodyAbonos(bodyAux[0].contenido)
            setAbonosDialogOpen(true)
        }
        return false;
    }, [paramsCita?.tramiteId]);

    
    const handleTramiteSeleccionado = useCallback((tramite: string, tramiteId: number) => {
        handleComprobarTramiteAbono(tramiteId);
        setTramiteSeleccionado(tramite);
        setProcedureAggrupationId(tramiteId);
        handleOficinasList(tramiteId);
        loadOficinasListSede();
    }, [handleOficinasList]);

    const handleHorariosOficina = useCallback(async (codigoOficina: string) => {
        if (codigoOficina) {
            const horariosOficina = await citaPreviaGateway.getDiasHorariosCita(codigoOficina);
            setHorariosOficina(horariosOficina);
        }
    }, [citaPreviaGateway]);

    const handleOficinaValue = useCallback((codigoOficina: string) => {
        setOficinaValue(codigoOficina);
        handleHorariosOficina(codigoOficina);
    }, [handleHorariosOficina]);

    const handleDisableButton = useCallback((disableButton: boolean) => {
        if (disableButton) {
            setNotVisible(true);
        } else {
            setNotVisible(false);
        }
    }, []);

    const handleConfirmValidate = useCallback((confirm: boolean) => {
        setConfirmValidate(confirm);
        if (!confirm) {
            setValidate(false);
        }
    }, []);

    useEffect(() => {
        if (confirmValidate) {
            handleHideButtonOrMakeAppointment();
        }
    }, [confirmValidate, handleHideButtonOrMakeAppointment])

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiCalendarClock,
                title: <Term component="CitaPrevia" text="Titulo Cita Previa" />,
                text: <Term component="CitaPrevia" text="Subtitulo cita previa" />
            },
            menu: true,
        });
    }, [pageDispatcher, handleToggleSubMenu]);


    useEffect(() => {
        activeStep > 0 ?
            setBackButton(true)
            : setBackButton(false)
    }, [activeStep]);

    useEffect(() => {
        async function load() {
            setLoading(true);
            const diccionarioTramites = await citaPreviaGateway.getContent();
            setDiccionarioTramites(diccionarioTramites);
            setLoading(false);
        }
        load()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const loadPage = async () => {
            if (pageState.jwp !== null) {
                setLoading(true);
                try {
                    const sujetoLocal = await perfilG.getDatosSujeto();
                    if (sujetoLocal) {
                        setSujeto(sujetoLocal);
                    }
                    else {
                        pageDispatcher({
                            type: 'show-notification',
                            payload: {
                                message: translate('CitaPrevia', 'Error datos sujeto', terms),
                                variant: 'error',
                            }
                        });
                    }
                }
                catch (e) {
                    pageDispatcher({
                        type: 'show-notification',
                        payload: {
                            message: (e as any).message,
                            variant: 'error',
                        }
                    });
                }
                finally {
                    setLoading(false);
                }
            }
        }
        loadPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perfilG, pageDispatcher]);

    return (
        <>
            {
                !loading
                    ?
                    <div className={classes.root}>
                        {displayStepper &&
                            <div className={classes.stepperContainer}>
                                <Card className={classes.stepperCard}>
                                    <Stepper className={classes.stepsStepperCard} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                        <Step>
                                            <StepLabel StepIconComponent={setIconStepOne}>
                                                <div className={activeStep === 0 ? classes.boldText : classes.lightText}>
                                                    <Term component="CitaPrevia" text="Seleccionar trámite" />
                                                </div>
                                            </StepLabel>
                                        </Step>
                                        <Step>
                                            <StepLabel StepIconComponent={setIconStepTwo}>
                                                <div className={activeStep === 1 ? classes.boldText : classes.lightText}>
                                                    <Term component="CitaPrevia" text="Datos personales" />
                                                </div>
                                            </StepLabel>
                                        </Step>
                                        <Step>
                                            <StepLabel StepIconComponent={setIconStepThree}>
                                                <div className={activeStep === 2 ? classes.boldText : classes.lightText}>
                                                    <Term component="CitaPrevia" text="Resumen" />
                                                </div>
                                            </StepLabel>
                                        </Step>
                                    </Stepper>
                                </Card>
                                <Dialog open={abonosDialogOpen}>
                                        <DialogTitle>
                                            <Term component="Global" text="Informacion" />
                                        </DialogTitle>
                                        <DialogContent>
                                            <div
                                            dangerouslySetInnerHTML={{
                                                __html: bodyAbonos,
                                            }}
                                            />
                                            
                                        </DialogContent>
                                        <DialogActions>
                                            <Button variant="contained" color="primary" onClick={() => {setAbonosDialogOpen(false)}}>
                                            <Term component="Global" text="Cerrar" />
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                            </div>
                        }

                        {activeStep === 0 &&
                            <SeleccionarTramite
                                diccionarioTramites={diccionarioTramites}
                                oficinasList={oficinasList}
                                oficinasListSede={oficinasListSede}
                                horariosOficina={horariosOficina}
                                paramsCita={paramsCita}
                                onSelectedTramite={handleTramiteSeleccionado}
                                onOficinaValue={handleOficinaValue}
                                onNextStep={handleNext}
                                onNextStepWithoutParams={handleNextStepShowButton}
                                onChangeDisableButton={handleDisableButton}
                            />
                        }

                        {activeStep === 1 &&
                            <DatosPersonales
                                onLastStep={handleLastStep}
                                paramsCita={paramsCita}
                                validate={validate}
                                onChangeDisableButton={handleDisableButton}
                                onConfirmValidate={handleConfirmValidate}
                                sujeto={sujeto}
                            />
                        }

                        {activeStep === 2 &&
                            < ResumenCita
                                paramsCita={paramsCita}
                                codigoCita={codigoCita}
                                displayStepper={displayStepper}
                            />}

                        {codigoCita === null &&
                            <div className={[classes.fixedBottom, classes.marginBottom].join(' ')}>
                                <Grid container className={[classes.rootProximasCitas, classes.display, classes.bottomContainerAlignment].join(' ')}>
                                    <Grid item xs={6} className={classes.payContainer}>
                                        <div className={[classes.wrapper, classes.bottomButtonAlignment].join('')}>
                                            <Disable disabled={notVisible}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.buttonDrawer}
                                                    onClick={handleHideButtonOrMakeAppointment}>
                                                    <Term component="Global" text="Continuar" />
                                                </Button>
                                            </Disable>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </div>
                    :
                    <div className={classes.centerContent}>
                        <CircularProgress size={30} />
                    </div>
            }
        </>
    );
}

export default withRouter(withStyles(styles)(CitaPrevia));
