import { FC, useState, useContext, useEffect, useCallback } from 'react';
import { Card, CardContent, WithStyles, withStyles, FormControl, FormGroup, Grid, FormControlLabel, Switch, CircularProgress, Button } from '@material-ui/core';
import usePage from 'hooks/page.hook';
import Term from 'components/term';
import { ISujeto } from 'gateways/perfil.interfaces';
import styles from '../shared/perfil.styles';
import { AlertsContext } from 'contexts/alerts.context';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import ConfirmDialog from './modals/confirmacion.modal';
import { NotificacionesGateway } from 'gateways/notificaciones.gateway';
import IoC from 'contexts/ioc.context';
import { Icon } from '@mdi/react';
import { mdiBell } from '@mdi/js';
import { download } from 'utils/download';
import { MoreInfoContext } from 'contexts/more_info.context';

interface IProps extends WithStyles<typeof styles> {
    sujeto: ISujeto;
}

const SectionNotificaciones: FC<IProps> = ({ sujeto, classes }) => {
    // Global variables
    const terms = useContext(LiteralsContext);

    // Global states    
    const [pageState] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);

    // Local states
    const [loading, setLoading] = useState(false);
    const [, setPermitirDatosEnvio] = useState(true);
    const [checkNotificacionesActivo, setCheckNotificacionesActivo] = useState(sujeto.notificacion);
    const [, setCheckNotificacionesDisabled] = useState(false);
    const [, setTipoAccion] = useState(0);
    const [bajaNotificaciones, setBajaNotificaciones] = useState(false);
    const [altaNotificaciones, setAltaNotificaciones] = useState(false);
    const [serviceDisabled, setServiceDisabled] = useState(false);

    // Gateways
    const ioc = useContext(IoC);
    const gateway = ioc.get(NotificacionesGateway) as NotificacionesGateway;

    const handleShowMoreInfo = useCallback(() => {
        infoDispatch({
            type: 'show-info',
            payload: {
                templateName: "mas_info_notificaciones_electronicas",
                size: 'xl'
            }
        });
    }, [infoDispatch,])

    // Effects
    useEffect(() => {
        if ((pageState && pageState.jwp && pageState.jwp.level < 2)) {
            setCheckNotificacionesDisabled(true);
            setPermitirDatosEnvio(false);
        }
        setServiceDisabled(sujeto.movil === null && sujeto.email === null);
        if (sujeto.adhesionDeOficio) {
            setCheckNotificacionesDisabled(true);
        }
        if (sujeto.modNotificacionPendienteConfirmacion) {
            setTipoAccion(2);
            setCheckNotificacionesDisabled(true);
        } else if (sujeto.notificacionPendienteConfirmacion) {
            setTipoAccion(1);
            setCheckNotificacionesDisabled(true);
        } else {
            setTipoAccion(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Events
    const openModalActivacionNotificaciones = async () => {
        if (pageState && pageState.jwp && pageState.jwp.level < 2) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Perfil', 'Autenticacion clave necesaria', terms),
                    variant: 'warning',
                }
            });
        } else if (sujeto.adhesionDeOficio) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Perfil', 'Adhesion de oficio', terms),
                    variant: 'warning',
                }
            });
        } else if (sujeto !== null) {
            if (checkNotificacionesActivo) {
                setBajaNotificaciones(true);
            } else {
                setAltaNotificaciones(true);
            }
        }
    }

    const altaServicioNotificaciones = async () => {
        try {
            setLoading(true);
            const documento = await gateway.activarServicioNotificaciones('1', '', '');
            if (documento) {
                const downloadOk = download(documento, alertsDispatch,
                    translate('Perfil', 'Error alta servicio notificaciones', terms),
                    translate('Global', 'BloqueoPantalla', terms));
                if (downloadOk) {
                    setTipoAccion(0);
                    setCheckNotificacionesActivo(!checkNotificacionesActivo);
                    setCheckNotificacionesDisabled(false);
                    setBajaNotificaciones(false);
                    setAltaNotificaciones(false);
                    pageDispatcher({
                        type: 'show-notification',
                        payload: {
                            message: translate('Perfil', 'Alta servicio notificaciones correcta', terms),
                            variant: 'success',
                        }
                    });
                }

            } else {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Perfil', 'Error alta servicio notificaciones', terms),
                        variant: 'error',
                    }
                });
            }
        } catch (e) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: translate('Perfil', 'Error alta servicio notificaciones', terms),
                    variant: 'error',
                }
            });
        } finally {
            setLoading(false);
            setPermitirDatosEnvio(false);
        }
    }

    const bajaServicioNotificaciones = async () => {
        try {
            setLoading(true);
            const documento = await gateway.bajaServicioNotificaciones();
            if (documento) {
                const downloadOk = download(documento, alertsDispatch,
                    translate('Perfil', 'Error baja servicio notificaciones', terms),
                    translate('Global', 'BloqueoPantalla', terms));
                if (downloadOk) {
                    setTipoAccion(0);
                    setCheckNotificacionesActivo(!checkNotificacionesActivo);
                    setCheckNotificacionesDisabled(false);
                    setBajaNotificaciones(false);
                    setAltaNotificaciones(false);
                    pageDispatcher({
                        type: 'show-notification',
                        payload: {
                            message: translate('Perfil', 'Baja servicio notificaciones correcta', terms),
                            variant: 'success',
                        }
                    });
                }

            } else {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Perfil', 'Error baja servicio notificaciones', terms),
                        variant: 'error',
                    }
                });
            }
        } catch (e) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: translate('Perfil', 'Error baja servicio notificaciones', terms),
                    variant: 'error',
                }
            });
        } finally {
            setLoading(false);
            setPermitirDatosEnvio(false);
        }
    }

    // const handleSendEmail = async () => {
    //     try {
    //         setLoading(true);
    //         if (await gateway.reenviarEmail(sujeto.emailNotificacion, sujeto.movilNotificacion, tipoAccion)) {
    //             pageDispatcher({
    //                 type: 'show-notification',
    //                 payload: {
    //                     message: translate('Perfil', 'Se le ha enviado el correo electrónico correctamente', terms),
    //                     variant: 'success',
    //                 }
    //             });
    //         } else {
    //             pageDispatcher({
    //                 type: 'show-notification',
    //                 payload: {
    //                     message: translate('Perfil', 'Error envio correo electrónico', terms),
    //                     variant: 'error',
    //                 }
    //             });
    //         }
    //     } catch (e) {
    //         pageDispatcher({
    //             type: 'show-notification',
    //             payload: {
    //                 message: e.message,
    //                 variant: 'error',
    //             }
    //         });
    //     }
    //     finally {
    //         setLoading(false);
    //     }
    // }

    const closeModalBajaNotificaciones = () => {
        setBajaNotificaciones(false);
    }

    const closeModalAltaNotificaciones = () => {
        setAltaNotificaciones(false);
    }

    return (
        <>
            <Card className={classes.section}>
                <CardContent>
                    <Grid container className={classes.formContainerWithoutGrid}>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems='center'>
                                <Grid item className={classes.circle}>
                                    <Icon path={mdiBell}
                                        title="Notificaciones"
                                        size={1}
                                        color="white"
                                    />
                                </Grid>
                                <Grid item>
                                    <h3 className={classes.title}><Term component="Perfil" text="Servicio de notificaciones electrónicas" /></h3>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.rightContent}>
                            <FormControl>
                                <FormGroup>
                                    <Grid container>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={checkNotificacionesActivo}
                                                    disabled={serviceDisabled}
                                                    value={checkNotificacionesActivo}
                                                    onChange={openModalActivacionNotificaciones}
                                                    color="primary"
                                                />
                                            }
                                            label={<Term component="Perfil" text="Servicio activo" />}
                                        />
                                        {
                                            loading ?
                                                <CircularProgress size={20} />
                                                : null
                                        }
                                    </Grid>
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item className={classes.button}>
                                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleShowMoreInfo} size="small">
                                        <Term component="Global" text="Más información" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* {
                        pendienteConfirmacion
                            ?
                            <Grid container className={classes.formContainer}>
                                <Grid item md={9}>
                                    <Grid container>
                                        <Grid item md={8}>
                                            <FormHelperText id="alta-notificaciones-helper-text" className={classes.warningMessage}>
                                                {
                                                    tipoAccion === 2
                                                        ?
                                                        <Term component="Perfil" text="Debe validar modificación notificaciones" />
                                                        :
                                                        tipoAccion === 1
                                                            ?
                                                            <Term component="Perfil" text="Debe validar alta notificaciones" />
                                                            :
                                                            <Term component="Perfil" text="Debe validar baja notificaciones" />
                                                }
                                            </FormHelperText>
                                        </Grid>
                                        <Grid item md={2}>
                                            <Tooltip title={translate('Perfil', 'Servicio pediente confirmacion', terms)} TransitionComponent={Zoom} placement="top" classes={{ tooltip: classes.customWidth }}>
                                                <Icon path={mdiInformation} size={1} />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={3}>
                                    {
                                        pendienteConfirmacion
                                            ?
                                            pageState && pageState.jwp && pageState.jwp.level < 2
                                                ?
                                                <Button variant="outlined"
                                                    color="primary"
                                                    className={[classes.button, classes.contentButton].join(' ')}
                                                    size="small" disabled>
                                                    <Term component="Perfil" text="Enviar correo" />
                                                </Button>
                                                :
                                                <Grid container>
                                                    <Grid item>
                                                        <Button variant="outlined"
                                                            color="primary"
                                                            className={[classes.button, classes.contentButton].join(' ')}
                                                            onClick={handleSendEmail} size="small">
                                                            <Term component="Perfil" text="Enviar correo" />
                                                            {
                                                                loading ?
                                                                    <CircularProgress size={20} className={classes.buttonProgress} />
                                                                    :
                                                                    null
                                                            }
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            :
                                            null
                                    }
                                </Grid>
                            </Grid>
                            :
                            null
                    } */}
                </CardContent>
            </Card>
            <ConfirmDialog
                open={bajaNotificaciones}
                onClose={closeModalBajaNotificaciones}
                onConfirm={bajaServicioNotificaciones}
                text={translate('Perfil', 'Cambio estado baja servicio notificaciones', terms)}
                notificado={false}
                type={'P'}
            />
            <ConfirmDialog
                open={altaNotificaciones}
                onClose={closeModalAltaNotificaciones}
                onConfirm={altaServicioNotificaciones}
                text={translate('Perfil', 'Cambio estado alta servicio notificaciones', terms)}
                notificado={false}
                type={'P'}
            />
        </>
    )
}

export default withStyles(styles)(SectionNotificaciones);