import env from "env";
import React, { FC } from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const clientKey = env.RECAPTCHA_CLIENT_KEY;

const RecaptchaProvider: FC = ({ children }) => {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={clientKey}
            language="es"
        >
            {children}
        </GoogleReCaptchaProvider>
    )
}

export default RecaptchaProvider;