import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { WithStyles, withStyles, Snackbar, SnackbarContent, Theme } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import { StyleRules } from '@material-ui/styles';
import { Icon } from '@mdi/react';
import { mdiCheckCircle, mdiAlert, mdiAlertCircle, mdiInformationOutline } from '@mdi/js';

const CheckCircleIcon = <Icon path={mdiCheckCircle}
    title="Ok"
    size={1}
    color="dimgrey"
/>;

const WarningIcon = <Icon path={mdiAlert}
title="Warn"
size={1}
color="dimgrey"
/>;

const ErrorIcon = <Icon path={mdiAlertCircle}
title="Error"
size={1}
color="dimgrey"
/>;

const InfoIcon = <Icon path={mdiInformationOutline}
title="Info"
size={1}
color="dimgrey"
/>;

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles = (theme: Theme): StyleRules => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

interface IProps extends WithStyles<typeof styles> {
    open: boolean;
    autoHideDuration?: number;
    message: string | ReactNode;
    onClose: () => void;
    variant: keyof typeof variantIcon;
}

function SnackBarCustomized(props: IProps) {
    const { autoHideDuration, message, onClose, variant, classes, open } = props;
    const AlertIcon = variantIcon[variant];
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={autoHideDuration || 6000}
                onClose={onClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    // variant,
                }}
                message={<span id="message-id">{message}</span>}
            >
                <SnackbarContent
                    className={clsx(classes[variant], variant)}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className={classes.message}>
                            {AlertIcon}
                            {message}
                        </span>
                    }
                />
            </Snackbar>
        </div>
    );
}

export default withStyles(styles)(SnackBarCustomized);


