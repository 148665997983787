import { FC, useCallback, useContext, useState } from 'react';
import { Grid, Card, CardContent, WithStyles, withStyles, FormControl, CircularProgress, FormControlLabel, FormGroup, Switch, Button } from '@material-ui/core';
import { Icon } from '@mdi/react';
import Term from 'components/term';
import { ISujeto } from 'gateways/perfil.interfaces';
import styles from '../shared/perfil.styles';
import { mdiCommentOutline } from '@mdi/js';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import usePage from 'hooks/page.hook';
import ConfirmDialog from './modals/confirmacion.modal';
import IoC from 'contexts/ioc.context';
import { NotificacionesGateway } from 'gateways/notificaciones.gateway';
import { MoreInfoContext } from 'contexts/more_info.context';

interface IProps extends WithStyles<typeof styles> {
    sujeto: ISujeto;
    onChange: (sujeto: ISujeto) => void;
}

const SectionAvisos: FC<IProps> = ({ sujeto, classes, onChange }) => {
    // Global variables
    const terms = useContext(LiteralsContext);

    // Global states    
    const [, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);

    // Local states
    const [loading, setLoading] = useState(false);
    const [checkAvisosActivo, setCheckAvisosActivo] = useState(sujeto.activarAvisos);
    const [bajaServicioAvisos, setBajaServicioAvisos] = useState(false);
    const [altaServicioAvisos, setAltaServicioAvisos] = useState(false);
    const [serviceDisabled,] = useState(sujeto.movil === null && sujeto.email === null);

    // Gateways
    const ioc = useContext(IoC);
    const gateway = ioc.get(NotificacionesGateway) as NotificacionesGateway;

    const handleShowMoreInfo = useCallback(() => {
        infoDispatch({
            type: 'show-info',
            payload: {
                templateName: "mas_info_sms_mail",
                size: 'xl'
            }
        });
    }, [infoDispatch,])

    // Events
    const openModalActivacionAvisos = async () => {
        if (sujeto !== null) {
            if (checkAvisosActivo) {
                setBajaServicioAvisos(true);
            } else {
                setAltaServicioAvisos(true);
            }
        }
    }

    const activarServicioAvisos = async (accion: number) => {
        try {
            setLoading(true);
            if (await gateway.activarServicioAvisos(accion)) {
                setCheckAvisosActivo(!checkAvisosActivo);
                sujeto.activarAvisos = !checkAvisosActivo;
                onChange(sujeto);
                setAltaServicioAvisos(false);
                setBajaServicioAvisos(false);
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Perfil', accion === 1 ? 'Alta servicio avisos correcta' : 'Baja servicio avisos correcta', terms),
                        variant: 'success',
                    }
                });
            } else {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Perfil', accion === 1 ? 'Error alta servicio avisos' : 'Error baja servicio avisos', terms),
                        variant: 'error',
                    }
                });
            }
        } catch (e) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: translate('Perfil', accion === 1 ? 'Error alta servicio avisos' : 'Error baja servicio avisos', terms),
                    variant: 'error',
                }
            });
        } finally {
            setLoading(false);
        }
    }

    const closeModalBajaAvisos = () => {
        setBajaServicioAvisos(false);
    }

    const closeModalAltaAvisos = () => {
        setAltaServicioAvisos(false);
    }

    return (
        <>
            <Card className={[classes.section, classes.separate].join(' ')}>
                <CardContent>
                    <Grid container className={classes.formContainerWithoutGrid}>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems='center'>
                                <Grid item className={classes.circleBlue}>
                                    <Icon path={mdiCommentOutline}
                                        title="Avisos o mensajes"
                                        size={1}
                                        color="white"
                                    />
                                </Grid>
                                <Grid item>
                                    <h3 className={classes.title}><Term component="Perfil" text="Servicio de avisos o mensajes" /></h3>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.rightContent}>
                            <FormControl>
                                <FormGroup>
                                    <Grid container>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={checkAvisosActivo}
                                                    disabled={serviceDisabled}
                                                    value={checkAvisosActivo}
                                                    onChange={openModalActivacionAvisos}
                                                    color="primary"
                                                />
                                            }
                                            label={<Term component="Perfil" text="Servicio activo" />}
                                        />
                                        {
                                            loading ?
                                                <CircularProgress size={20} />
                                                : null
                                        }
                                    </Grid>
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item className={classes.button}>
                                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleShowMoreInfo} size="small">
                                        <Term component="Global" text="Más información" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <ConfirmDialog
                open={bajaServicioAvisos}
                onClose={closeModalBajaAvisos}
                onConfirm={() => activarServicioAvisos(0)}
                text={translate('Perfil', 'Cambio estado baja servicio avisos', terms)}
                notificado={false}
                type={'P'}
            />
            <ConfirmDialog
                open={altaServicioAvisos}
                onClose={closeModalAltaAvisos}
                onConfirm={() => activarServicioAvisos(1)}
                text={translate('Perfil', 'Cambio estado alta servicio avisos', terms)}
                notificado={false}
                type={'P'}
            />
        </>
    )
}

export default withStyles(styles)(SectionAvisos);