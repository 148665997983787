import React, { FC, useCallback, useContext, useEffect } from "react";

import styles, { colors } from "./styles";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import { IMoreInfo, TInfoTemplateSize } from "contexts/more_info.reducer";
import { IconButton, Typography, WithStyles, withStyles } from "@material-ui/core";
import { Icon } from 'atomic/atoms/icon/icon';
import { MoreInfoContext } from "contexts/more_info.context";
import { translate } from "utils/i18n";

export type TLabelType = 'field' | 'title' | 'text'
interface IGenericLabelWithInfo extends WithStyles {
    componentIG:string
    labelTerm: string,
    type: TLabelType,
    moreInfo?: {
        template?: string, size?: TInfoTemplateSize, asMessage?: boolean
    },
    extraInLabel?: string // texto adicional a concatenar tras el term
    style?:any
    upperCase?: boolean;
    infoIconStyle?: React.CSSProperties | undefined;
};


const GenericLabelWithInfo : FC<IGenericLabelWithInfo>= ({classes, labelTerm, moreInfo, type="field",componentIG,extraInLabel, style, upperCase=false , infoIconStyle}) => {
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);

   
    const handleShowMoreInfo = useCallback(() => {
        if (moreInfo) {

            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: moreInfo.asMessage ? undefined : moreInfo.template,
                    message: moreInfo.asMessage ?  moreInfo.template: undefined,
                    size:moreInfo?.size ?? undefined,
                }
            });
        }
    }, [infoDispatch])


    return(
        <div style={{
            display:'inline-flex', 
            alignItems:'center', 
            marginBottom: type === 'title' ? 8 : 0, 
            //minHeight: type !== 'text' ? 48 : undefined
        }}>
            <Typography 
                className={type !== 'text' ? classes.radioBtn_Field : undefined} 
                variant={ type === 'title' ? "h6": "body1"} 
                style={style}
            >
                { upperCase 
                ? `${translate(componentIG,labelTerm, terms).toUpperCase()} ${extraInLabel ? extraInLabel.toUpperCase() : ''}`
                :`${translate(componentIG,labelTerm, terms)} ${extraInLabel ?? ''}`
                }   

            </Typography>
            {moreInfo && 
                <IconButton onClick={handleShowMoreInfo} style={infoIconStyle}>
                    <Icon name={'info-outline'} size={1} color={colors.blueIconInfo}/>
                </IconButton>
            }
        </div>
    )
}

export default withLiterals(ALL_LITERALS)(withStyles(styles)(GenericLabelWithInfo))