import React, { FC, useState, useContext, useEffect, FormEventHandler, useCallback } from "react";
import { 
    TextField, Button, Snackbar, createMuiTheme, MuiThemeProvider, Link, Grid, InputAdornment, 
    IconButton, makeStyles, Hidden, DialogActions, Dialog, DialogContent,DialogTitle, CardMedia, Divider 
} from "@material-ui/core";
import { Icon } from '@mdi/react'
import { mdiEyeOutline, mdiEyeOffOutline, mdiArrowLeftCircle, mdiAlert, mdiInformationOutline, mdiInformation } from '@mdi/js'

import Term from './term';
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import IoC from "contexts/ioc.context";
import { AuthGateway } from "gateways/auth.gateway";
import { ContenidosGateway } from "gateways/contenido.gateway";
import { RecaptchaGateway } from "gateways/recaptcha.gateway";
import usePage from "hooks/page.hook";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ClaveForm from "./clave-form";
import { RouteComponentProps } from "react-router-dom";
import queryString from 'query-string';
import env from "env";

import imageLogoSede from "../resources/Logoatib.png";
import imageClaveLogin from "../resources/claveLoging_N.png"; //"../../resources/claveLoging_N.png";
import imagePopSede from "../resources/popSede.png";
import { History } from "@material-ui/icons";
import { translate } from "utils/i18n";


const useStyles = makeStyles((theme) => ({
    textWarning: {
        color: 'black',
    },
    colorWhite: {
        color: 'white',
    },
    spaceText: {
        marginLeft: 10,
    },
    nifLabel: {
        textAlign: 'left',
        cursor: 'pointer',
        fontSize: 20
    },
    noSoyYo: {
        textAlign: 'center',
        cursor: 'pointer',
        fontSize: 20,
        marginBottom: '1rem'
    },
    loginBox: {
        width: '100%',
        height: '100%',
        margin: '0 auto',
        background: 'rgba(255, 255, 255, 1)',
        padding: 40,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.up('md')]:
        {
            borderRadius: 20,
            width: 480,
            height: 'auto',
        },
        '@media (min-width: 650px) and (max-width: 1440px) and (max-height: 750px)': {
            marginTop: '100px',
        },
        '@media (max-width: 360px)': {
            marginTop: '50px',
        },

    },
    inputColor: {
        color: 'black',
        borderBottom: '1px solid black',
        '&:after': {
            borderBottom: '1px solid #3f51b5',
        },
    },
    media: {
        height: 560, //395,
        width: 550, //385
    },
    // rowClave: {
    //     display: 'flex',
    //     marginBottom: 15,
    // },
    // logoClave: {
    //     background: 'rgba(0, 43, 90, 0.6)',
    //     flex: 0.85,
    //     borderRadius: '20px 0px 0px 20px',
    // },
    // botonInfoClave: {
    //     background: 'rgba(0, 43, 90, 0.6)',
    //     borderRadius: '0px 20px 20px 0px',
    //     marginLeft: 3,
    //     flex: 0.15,
    //     paddingTop: 5,
    //     textAlign: 'center',
    //     paddingRight: 10,
    // },
    flex: {
        flex: 1,
    },
    buttonForm: {
        textAlign: 'center',
        marginTop: 30,
    },
    button: {
        marginLeft: 40,
        marginRight: 40,
        marginTop: 5,
        backgroundColor: 'rgba(140,145,155,1)',
        marginBottom: 5,
        border: '1px solid rgba(140,145,155,1)',
        '&:after': {
            border: '1px solid rgba(140,145,155,1)',
        },
        '&:hover': {
            backgroundColor: 'rgba(140,145,155,0.7)',
        },
        borderRadius: 20,
    },
    buttonLink: {
        fontWeight: 'lighter',
        letterSpacing: 1,
        color: '#616161',
    },
    form: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('md')]:
        {
            width: 'auto',
            height: 'auto',
            marginLeft: 20,
            marginRight: 20,
        }
    },
    imagenMargin: {
        marginBottom: 60,
        display: 'none',
        [theme.breakpoints.up('md')]:
        {
            display: 'block'
        }
    },
    hidden: {
        display: 'none',
    },
    gridLogoItem: {
        borderRight: '1px solid black',
        paddingRight: 15,
    },
    alignCenter: {
        textAlign: 'center',
    },
    titleMoreInfo: {
        fontSize: 24,
        textTransform: 'uppercase',
        marginBottom: 0,
    },
    subTitleMoreInfo: {
        marginTop: 15,
    },
    linkMoreInfo: {
        color: 'black',
        fontSize: 12,
        
    },
    tituloAccesoNif: {
        fontSize: 16
    },
    divDudasUsuario: {
        marginTop: 30,
        textAlign: 'center',
        marginBottom: '1rem',
    },
    divider: {
        marginLeft: -40,
        marginRight: -40,
        backgroundColor: 'black',
    },
    divAccesoClave: {
        marginTop: 30,
        textAlign: 'center',
    }
}));

type History = Pick<RouteComponentProps, 'history'>;
type IProps = {
    openModalAcceso: boolean;
    setOpenModalAcceso: (v:boolean) => void;
} & History

const ModalAcceso = ({ openModalAcceso, setOpenModalAcceso, history}: IProps) => {

    const classes = useStyles();
    const terms = useContext(LiteralsContext)
    const [, pageDispatcher] = usePage();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const qs = queryString.parse(history.location.search)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [nif, setNif] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userStatus, setUserStatus] = useState<boolean | null>(null);
    const [showAlert, setShowAlert] = useState(false);
    const [token, setToken] = useState('');
    const [body, setBody] = useState('');
    const [open, setOpen] = useState(false);
    
    const [sujetoFallecido, setSujetoFallecido] = useState(false);

    const ioc = useContext(IoC);
    const authGateway: AuthGateway = ioc.get(AuthGateway);
    const recaptchaGateWay: RecaptchaGateway = ioc.get(RecaptchaGateway);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);


    const formLabelsTheme = createMuiTheme({
        overrides: {
            MuiInputBase: {
                input: { fontSize: 24,textAlign: 'center'}
            }
        }
    })

    // REDIRECTs
    const redirectToClave = useCallback(async () => {
        let loginToken = '';
        
        const recaptchaCheckIPResult = await recaptchaGateWay.checkIP();
        if (!recaptchaCheckIPResult || recaptchaCheckIPResult.useRecaptcha) {
            loginToken = executeRecaptcha ? await executeRecaptcha('sede_v2_login') : '';
        }
        let returnUrl = '/';
        if(history.location.pathname){ 
            // URL Retorno: Página en la que la modal Acceso se abre
            returnUrl = history.location.pathname;
        }

        const result = await authGateway.getAuthenticationRequest(returnUrl, '2', loginToken);       
        if (result.ok) {
            const claveForm = document.getElementById('claveForm') as HTMLFormElement;
            const relayState = document.getElementById('RelayState') as HTMLInputElement;
            const samlRequest = document.getElementById('SAMLRequest') as HTMLInputElement;

            relayState.value = result.relayState;
            samlRequest.value = result.samlRequest ? result.samlRequest : '';
            claveForm.action = result.provider;
            claveForm.submit();
        }
    }, [authGateway, executeRecaptcha, qs.return, recaptchaGateWay]);

    const redirectToRecoveryPw = useCallback(() => {
        history.push('/acceso/recuperar');
    }, [history]);

    const alertAccepted = () => {
        const redirectUrl = '/acceso/cambiar?nif=' + nif + '&token=' + token
        history.push(redirectUrl);
    }


    // FUNCTIONS
    const getUserStatus = async () => {
        try {
            const result = await authGateway.getUserStatus(nif);
            setShowPassword(!result.status);
            setUserStatus(result.status);
        } catch (e) {
            setSnackbarMessage((e as any).toString());
            setSnackbarOpen(true);
        }
    };


    // Handlers 
    const handleOpenModal = () => {setOpen(true)}

    const handleCloseModal = () => { setOpen(false)}

    const handleBack = () => {setUserStatus(null)};

    const handleBackAlert = () => {
        setShowAlert(false);
        setUserStatus(null);
    }

    const handleCloseSnackbar = () => { setSnackbarOpen(false) };

    const handleChangeNif: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        setNif(target.value);
    };

    const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        let textAux = target.value;
        if (!userStatus) {
            textAux = textAux.toUpperCase();
        }
        setPassword(textAux);
    };

    const handleShowPassword = () => {setShowPassword(!showPassword)};

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => { event.preventDefault() };

    const handleSignIn = async () => {
        try {
            setLoading(true);
            const mode = userStatus === false ? 'CODE' : 'PASSWORD';
            const response = await authGateway.signIn(nif, password, mode);

            if ('message' in response) {
                if (response.message === 'sujeto_fallecido') {
                    setSujetoFallecido(true);
                    const bodyAux = await contenidoGateway.getContent(
                        'sujeto_fallecido',
                        {}
                    );
                    setBody(bodyAux[0].contenido);
                    setShowAlert(true);
                }

                setLoading(false);
                return;
            }

            if (userStatus === true || response.suplantado) {
                pageDispatcher({ type: 'authenticate', payload: response });
                if (!response.avisoActivo) {
                    pageDispatcher({ type: 'show-alta-alert' });
                }
                // let redirectUrl = (qs.return instanceof Array ? qs.return[0] : qs.return) || '/';
                // redirectUrl = redirectUrl.replace(env.PUBLIC_URL, '/');
                // history.push(redirectUrl);
                setOpenModalAcceso(false);
            }
            else if (userStatus === false) {
                setShowAlert(true);
                try {
                    const bodyAux = await contenidoGateway.getContent(
                        'aviso_entrada_sede',
                        {
                            nombre: response.fullname,
                            nif,
                        }
                    );
                    setBody(bodyAux[0].contenido);
                    setToken(response.accessToken);
                } catch (e) {
                    setShowAlert(false);
                }
            }
            setLoading(false);
        } catch (e) {
            setSnackbarMessage((e as any).toString());
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    const handleSubmitForm: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        if (userStatus === null) {
            getUserStatus();
        } else if (userStatus !== null && !showAlert) {
            handleSignIn();
        } else if (userStatus !== null && showAlert) {
            alertAccepted();
        }
        return false;
    };


    return (
        <Dialog fullWidth maxWidth="sm" open={openModalAcceso} 
            onClose={() => { open 
                ? setOpen(false)
                : setOpenModalAcceso(false)
            }}
        >
            {open ?
                <DialogContent>
                    <CardMedia
                        className={classes.media}
                        image={imagePopSede}
                        title="Info"
                    />
                    
                    <DialogActions>
                        <Button autoFocus onClick={handleCloseModal} color="primary">
                            <Term component="Global" text="Cerrar" />
                        </Button>
                    </DialogActions>
                </DialogContent>
            :
                <DialogContent>
                    <MuiThemeProvider theme={formLabelsTheme}>
                        <form className={classes.form} onSubmit={handleSubmitForm}>
                            <div className={classes.loginBox}>
                                <Grid container>
                                    <Hidden smDown implementation="css">
                                        <Grid item className={classes.gridLogoItem}>
                                            <img alt="Logo sede" className={classes.imagenMargin} src={imageLogoSede} />
                                        </Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={6} className={classes.alignCenter}>
                                        <p className={classes.titleMoreInfo}><Term component="Global" text="IDENTIFICACIÓN" /></p>
                                        <p className={classes.subTitleMoreInfo}>                        
                                            <Button size="small"
                                                className={classes.linkMoreInfo} 
                                                onClick={() => history.push('acceso/contenidos')}
                                                startIcon={<Icon path={mdiInformation} size={1} color="#004476" />}
                                            >
                                                <Term component="Global" text="+Info sobre los accesos" />
                                            </Button>
                                        </p>
                                    </Grid>
                                </Grid>
                                <div >
                                    <b className={classes.tituloAccesoNif}><Term component="Global" text="Acceder mediante DNI/NIF" /></b>
                                </div>
                                {
                                    !showAlert
                                        ?
                                        <>
                                            {
                                                userStatus === null
                                                    ? <TextField
                                                        disabled={loading}
                                                        id="nif"
                                                        margin="normal"
                                                        required
                                                        autoFocus
                                                        fullWidth
                                                        label={<span><Term component="Global" text="DNI/NIF/CIF" /></span>}
                                                        value={nif}
                                                        type="text"
                                                        autoComplete="off"
                                                        onChange={handleChangeNif}
                                                        InputProps={{
                                                            className: classes.inputColor
                                                        }}
                                                    />
                                                    :
                                                    <div className={classes.nifLabel} onClick={handleBack}>
                                                        <Icon path={mdiArrowLeftCircle} size={0.8} color="black" />
                                                        <span className={[classes.spaceText].join(' ')}>{nif}</span>
                                                    </div>
                                            }
                                            {
                                                userStatus !== null
                                                    ?
                                                    <TextField
                                                        disabled={loading}
                                                        id="password"
                                                        margin="normal"
                                                        required
                                                        value={password}
                                                        fullWidth
                                                        autoFocus
                                                        label={
                                                            <span>
                                                                {
                                                                    userStatus === true
                                                                        ?
                                                                        <Term component="Global" text="Contraseña" />
                                                                        :
                                                                        <Term component="Global" text="Codigo Contribuyente" />
                                                                }
                                                            </span>
                                                        }
                                                        type={showPassword ? 'text' : 'password'}
                                                        autoComplete="off"
                                                        onChange={handleChangePassword}
                                                        InputProps={{
                                                            className: classes.inputColor,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        edge="end"
                                                                        aria-label="toggle password visibility"
                                                                        onClick={userStatus ? handleShowPassword : handleOpenModal}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                    >
                                                                        {
                                                                            userStatus === true
                                                                                ?
                                                                                showPassword ?
                                                                                    <Icon path={mdiEyeOffOutline}
                                                                                        title="Ver password"
                                                                                        size={1}
                                                                                        color="black"
                                                                                    />
                                                                                    :
                                                                                    <Icon path={mdiEyeOutline}
                                                                                        title="Ocultar password"
                                                                                        size={1}
                                                                                        color="black"
                                                                                    />
                                                                                :
                                                                                <Icon path={mdiInformationOutline}
                                                                                    title="Mas info"
                                                                                    size={1}
                                                                                    color="black"
                                                                                />
                                                                        }
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    :
                                                    null
                                            }
                                        </>
                                        :
                                        <>
                                            <Icon path={mdiAlert} size={1.5} color={'black'} />
                                            <div className={classes.textWarning} dangerouslySetInnerHTML={{ __html: body }} />
                                        </>
                                }

                                <div className={classes.buttonForm}>
                                    <>
                                        {
                                            !sujetoFallecido
                                                ?
                                                <Button variant="outlined" color="primary" className={classes.button} type="submit" size="large" disabled={loading}>
                                                    <span className={classes.colorWhite}>
                                                        {
                                                            userStatus === null || (!userStatus && !showAlert)
                                                                ?
                                                                <Term component="Login" text="Continuar" />
                                                                :
                                                                <Term component="Login" text="Entrar" />

                                                        }
                                                    </span>
                                                </Button>
                                                : null
                                        }
                                    </>

                                </div>
                                <div className={classes.divDudasUsuario}>
                                    {
                                        !sujetoFallecido ? //Primero validamos que no haya fallecido
                                        userStatus === false && !showAlert ? //Si no ha fallecido, enseñamos el botón que toca
                                            <Grid item>
                                                <a
                                                    href="https://sede.atib.es/general/tramites/solicitudes/codigocontribuyente.aspx"
                                                    className={classes.buttonLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <Term component="Acceso" text="¿No tiene codigo de contribuyente?" />
                                                </a>
                                            </Grid>
                                            :
                                            <Link
                                                className={classes.buttonLink}
                                                variant="body2"
                                                onClick={redirectToRecoveryPw}
                                            >
                                                <Term component="Acceso" text="¿Has olvidado tu contraseña?" />
                                            </Link>
                                        : null
                                    }
                                </div>
                                <Divider className={classes.divider} />
                                { !sujetoFallecido
                                                ?
                                <div className={classes.divAccesoClave}>
                                    <Button onClick={redirectToClave}>
                                        <span><Term component="Acceso" text="Acceder a través de" /></span><img style={{ marginBottom: 1, marginLeft: 7 }} src={imageClaveLogin} alt="clave" />
                                    </Button>
                                </div>: null}
                            </div>

                            {
                                userStatus === false && showAlert
                                    ?
                                    <div className={classes.noSoyYo} onClick={handleBackAlert}>
                                        <Icon path={mdiArrowLeftCircle} size={0.8} color="black" />
                                        <span className={[classes.spaceText].join(' ')}>{'No soy yo'}</span>
                                    </div>
                                    :
                                    null
                            }


                        </form>
                    </MuiThemeProvider>
                </DialogContent>
            }
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snackbarOpen}
                onClose={handleCloseSnackbar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{snackbarMessage}</span>}
            />
            <ClaveForm />

                {/* <Dialog
                    open={open}
                    onClose={handleCloseModal}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogContent>
                        <CardMedia
                            className={classes.media}
                            image={imagePopSede}
                            title="Info"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCloseModal} color="primary">
                            <Term component="Global" text="Cerrar" />
                        </Button>
                    </DialogActions>
                </Dialog> */}
            {/* <DialogTitle></DialogTitle>
            <DialogContent>
               
            </DialogContent>
            <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                    <Term component="Global" text="Cerrar" />
                </Button>
            </DialogActions> */}
        </Dialog>
    );
}

export default withLiterals(["Acceso"])(ModalAcceso);