import React, { FC, useContext, useState } from 'react';
import { Paper, makeStyles, useTheme, IconButton } from '@material-ui/core';
import { TBoxHomeOptions } from 'containers/inicio/helper/inicio.helper';
import Term from './term';
import { Link, RouteComponentProps } from 'react-router-dom';
import Icon from 'atomic/atoms/icon';
import {Icon as Icono} from "@mdi/react";
import { mdiInformation } from "@mdi/js";
import { useHistory } from 'react-router';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext } from 'containers/shared/literals';

const useStyles = makeStyles((theme) => ({
    paper: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginRight: 10,
        marginLeft: 10,
        padding: 10,
        position: 'relative',
        height: 200,
        '&:hover': {
            '& $text': {
                opacity: 1
            },
            '& $title': {
                opacity: 0
            },
            },
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: 40,
        //     marginRight: 40,
        // }
    },
    staticPaper : {
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        marginRight: 10,
        marginLeft: 10,
        padding: 10,
        position: 'relative',
        height: 200,
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: 40,
        //     marginRight: 40,
        // }
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width:'100%',
        height:'100%'
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: 40,
        //     marginRight: 40,
        // }
    },
    infoButtonHidden:{
        display:'none',
        '&:hover': {
            '& $text': {
                opacity: 1
            },
            '& $title': {
                opacity: 0
            },
            },
    },
    infoButton:{
        alignSelf:'end',
        '&:click': {
            '& $text': {
                opacity: 1
            },
            '& $title': {
                opacity: 0
            },
            },
    },

    infoTextFlex:{
        display: 'flex',
        flexDirection: 'column',

        height:'100%',
        flex: 1,

    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'center',
        //alignItems: 'center',
        alignContent: 'center',
        height:'100%',
        flex: 1,
        padding: 10,
        marginLeft: 10, 
        marginRight: 10,
       
    },
    imageContainer: {
        padding: 10,
        marginLeft: 10, 
        marginRight: 10,
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
        // marginRight: 10,
        // [theme.breakpoints.up('sm')]: {
        //     marginRight: -50
        // }
    },
    text: {
        opacity: 0,
        position: 'absolute',
        transition: 'opacity 0.5s ease',
        textAlign: 'justify',
        padding: '0px 40px 0px 0px',
        margin: 0,
        fontSize: 16,
    },
    title: {
        position: 'absolute', 
        fontSize: 24,
        paddingRight: '20px',
        fontWeight: 400,
        textTransform:'uppercase',
        transition: 'opacity 0.5s ease',
        paddingTop: 10,
        [theme.breakpoints.down(450)]: {
            fontSize: 16,
        }
    },

}));

// const AsLink = (to: string) => React.forwardRef((props, ref: any) => <Link {...props} style={{ textTransform: 'unset', textDecoration: 'none' }} to={to} ref={ref as any} />) as any;
// const AsExernalLink = (to: string) => React.forwardRef((props, ref: any) => 
//   <a {...props} href={to} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} ref={ref as any}>
//     {props.children}
//   </a>
// ) as any;
export type TResponse= {isValid: boolean, msg?: string}
type Props = {
    item: TBoxHomeOptions
    itemValidation?: () => TResponse
}
const BoxHome: FC<Props> = ({ item ,itemValidation}) => {
    const history = useHistory();
    const theme = useTheme();
    const classes = useStyles(theme);
    const terms = useContext(LiteralsContext);

    const [, alertsDispatch] = useContext(AlertsContext);
    const { boxImage, title, description, link, linkState, isExternLink, icon} = item
    const isMobile = window.outerWidth <= 950;
    const [switchStyles, setSwitchStyles] = useState(false)
    const onClickButton = (event:any) => {
        setSwitchStyles(!switchStyles);
    }
    const handleClickLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(itemValidation){
            const valid = itemValidation ? itemValidation(): {isValid:true, msg:undefined}
            //console.log('valid ', valid)
            if(!valid.isValid ){
                e.preventDefault()
                if(valid.msg !== 'requiredLogged'){
                    alertsDispatch({
                        type:'show-alert',
                        payload: {
                            variant: 'error',
                            message: translate('AbonoNacimiento', valid.msg ?? '', terms)
                        }
                    })
                } else{
                    history.push('/acceso/clave', { goBack: history.location.pathname })
                }
            }else{
                !isExternLink ? history.push(link, linkState) : window.open(link, '_blank', 'noopener,noreferrer');;
            }
            
        } else {
            !isExternLink ? history.push(link, linkState) : window.open(link, '_blank', 'noopener,noreferrer');;
        }
    }
    
    return (
        <Paper elevation={item.disabled ? 0: 1}  className={item.animated ? classes.paper: classes.staticPaper} onClick={(e) => !isMobile && !item.disabled ? handleClickLink(e): () => {}}> 
            <div style={{display:'flex'}}>
                <div className={classes.container} onClick={(e) => isMobile && !item.disabled ? handleClickLink(e): () => {}}>
                    <div className={classes.imageContainer}>
                        { boxImage ?
                            <img className={classes.image} src={boxImage} alt={`iconoBox${boxImage}`} />
                            : icon ? <Icon name={icon as any} size={3} color="#004f82"/> : null
                        }
                    </div>
                
                    <div className={classes.contentContainer}>
                        <p className={ classes.title} 
                            style={{
                                color: item.disabled ? 'lightgrey':'#004f84',
                                opacity: isMobile ? (switchStyles ? 0 : 1 ): undefined
                            }}
                        >
                            <Term component={title.component} text={title.text} />
                        </p>
                        {description && 
                            <p className={classes.text}
                                style={{
                                    color: item.disabled ? 'lightgrey':'grey',
                                    opacity: isMobile ? (switchStyles ? 1 : 0 ): undefined
                                }}
                            >
                                <Term component={description.component} text={description.text} />
                            </p>
                        }
                    </div>
                </div>
                <div className={isMobile ? classes.infoButton: classes.infoButtonHidden}>
                        <IconButton style={{ width: 50, position:'absolute', right:'0px', top: '0px' }} onClick={(e) => !item.disabled ? onClickButton(e): undefined}>
                            <Icono path={mdiInformation}></Icono>
                        </IconButton>
                </div>
            </div>
        </Paper>
    );
}

export default BoxHome;