import React, { useContext, FC, useMemo } from "react";
import {
  withStyles,
  WithStyles,
  Card,
  CardContent,
  Grid,
  Link,
  Button,
} from "@material-ui/core";
import { Icon } from "@mdi/react";
import { mdiMapMarkerRight, mdiOpenInNew } from "@mdi/js";
import { IDatosRecibo, IInmueble } from "gateways/bienesSujeto.interface";

import styles from "../shared/bienesSujeto.styles";
import Term from "components/term";
import ImageIcon from "components/image.icon";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import Field from "./field";
import { SujetosBienesGateway } from "gateways/sujetos.bienes.gateway";
import IoC from "contexts/ioc.context";
import { AlertsContext } from "contexts/alerts.context";

interface IProps {
  inmuebles: IInmueble[];
  count: number;
  handleSelectBien: (datosRecibo: IDatosRecibo) => void;
}

type Props = WithStyles<typeof styles> & IProps;

const Inmuebles: FC<Props> = ({
  classes,
  inmuebles,
  count,
  handleSelectBien,
}) => {
  const terms = useContext(LiteralsContext);
  const [, alertsDispatch] = useContext(AlertsContext);
  const ioc = useContext(IoC);
  const sujetosBienesGateway = useMemo(
    () => ioc.get(SujetosBienesGateway),
    [ioc]
  );
  const linkGoogle = async (ref: string) => {
    try {
      const url = await sujetosBienesGateway.getUrlGoogle(ref);
      window.open(
        url,
        "_blank" // <- This is what makes it open in a new window.
      );
    } catch (error) {
      alertsDispatch({
        type: "show-alert",
        payload: {
          message: translate("BienesSujeto", "url-google-error", terms),
          variant: "error",
        },
      });
    }
  };

  const milFormater = (value: string) => {
    const val = value.includes(".") ? value.replace(".", "") : value;
    const n_value = parseFloat(val.replace(/,/g, "."));
    return n_value.toLocaleString("es", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      useGrouping: true,
    });
  };

  return (
    <div>
      <Card key="titulo-inmuebles" className={classes.cardContador}>
        <CardContent className={classes.cardContadorContainer}>
          <span className={classes.titleCardContador}>
            <Term
              component="BienesSujeto"
              text="TituloInmueblesCount"
              params={[count]}
            />
          </span>
        </CardContent>
      </Card>

      {inmuebles && inmuebles.length > 0
        ? inmuebles.map((inmueble) => {
            return (
              <Card key={inmueble.refCatastral} className={classes.card}>
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={12}
                      lg={5}
                      xl={3}
                      container
                      direction="column"
                      alignItems="center"
                      justify="flex-start"
                    >
                      <Grid item className={classes.imgContainer}>
                        <ImageIcon
                          from="bienesSujeto"
                          imgName={
                            inmueble.clase === "UR" ? "urbana" : "rustica"
                          }
                        />
                      </Grid>
                      {/* <div style={{alignContent: 'flex-start'}}></div> */}
                      <Grid item className={classes.linkContentRow}>
                        <Link
                          href={inmueble.urlCatastro}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon
                            style={{ marginRight: 12, marginLeft: 12 }}
                            path={mdiOpenInNew}
                            size={1}
                            color="blue"
                          />
                        </Link>
                        <Field
                          label={translate(
                            "BienesSujeto",
                            "refCatastral_short",
                            terms
                          )}
                          value={inmueble.refCatastral}
                        />
                      </Grid>

                      <Grid item className={classes.linkContentRow}>
                        <Icon
                          style={{ marginRight: 5, marginLeft: 12 }}
                          path={mdiMapMarkerRight}
                          size={1}
                          color="red"
                        />
                        <Button
                          style={{ textTransform: "capitalize" }}
                          variant="text"
                          onClick={() => linkGoogle(inmueble.refCatastral)}
                        >
                          Google Maps
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={12}
                      lg={7}
                      xl={9}
                      container
                      direction="column"
                      style={{ marginTop: 20, paddingLeft: 10 }}
                    >
                      <Grid item className={classes.inline}>
                        <Field
                          label={translate("BienesSujeto", "direccion", terms)}
                          value={inmueble.direccion}
                        />
                      </Grid>

                      <Grid item container className={classes.contentRow}>
                        <Field
                          width={"33%"}
                          label={translate("BienesSujeto", "clase", terms)}
                          value={inmueble.uso ? translate(
                            "BienesSujeto",
                            inmueble.clase,
                            terms
                          ) : ''}
                        />
                        <Field
                          width={"33%"}
                          label={translate("BienesSujeto", "uso", terms)}
                          value={inmueble.uso ? translate("BienesSujeto", inmueble.uso, terms) : ''}
                        />
                        {inmueble.participacion.participacion && (
                            <Field
                            width={"33%"}
                            label={translate(
                                "BienesSujeto",
                                "participacion",
                                terms
                            )}
                            value={inmueble.participacion.participacion + " %"}
                            />
                        )}
                        {inmueble.participacion.derecho && (
                          <Field
                            width={"33%"}
                            label={translate("BienesSujeto", "derecho", terms)}
                            value={translate(
                              "BienesSujeto",
                              inmueble.participacion.derecho,
                              terms
                            )}
                          />
                        )}
                        {inmueble.datosEconomicos.valorSuelo && (
                          <Field
                            width={"33%"}
                            label={translate(
                              "BienesSujeto",
                              "valorSuelo",
                              terms
                            )}
                            value={milFormater(
                              inmueble.datosEconomicos.valorSuelo
                            )}
                          />
                        )}
                        {inmueble.datosEconomicos.valorCatastral && (
                          <Field
                            width={"33%"}
                            label={translate(
                              "BienesSujeto",
                              "valorCatastral",
                              terms
                            )}
                            value={milFormater(
                              inmueble.datosEconomicos.valorCatastral
                            )}
                          />
                        )}
                        {inmueble.datosEconomicos.valorConstruccion && (
                          <Field
                            width={"33%"}
                            label={translate(
                              "BienesSujeto",
                              "valorConstruccion",
                              terms
                            )}
                            value={milFormater(
                              inmueble.datosEconomicos.valorConstruccion
                            )}
                          />
                        )}
                        {inmueble.datosEconomicos.valorReferencia && (
                          <Field
                            width={"33%"}
                            label={translate("BienesSujeto", "valorRef", terms)}
                            value={milFormater(
                              inmueble.datosEconomicos.valorReferencia
                            )}
                          />
                        )}

                        {/** to adjust size - múltiplos de 3 */}
                        <Field width={"33%"} label="" value="" />
                      </Grid>
                      <div className={classes.bottomButtonRow}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleSelectBien({
                              objeto: inmueble.refCatastral,
                              municipio: inmueble.codigoMunicipio,
                            })
                          }
                        >
                          <Term
                            component="BienesSujeto"
                            text="Ver recibos relacionados"
                          />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })
        : null}
    </div>
  );
};

export default withStyles(styles)(Inmuebles);
