// LIBRARY IMPORTS
import { FC } from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from 'react-router';
import moment from "moment";

// LOCAL IMPORTS
import { IDatosIban, ITributo } from "gateways/tributos.interfaces";
import useAltasDomicilaicion, { IStateHistory } from "hooks/domiciliaciones/altas-domiciliacion.hook";
import Term from "components/term";

// STYLES IMPORTS
import styles from './../../../../shared/tributo.detail.styles';

const useStyles = makeStyles(styles);
interface ITributoCardProps {
    domiciliacion: IDatosIban;
    tributo: ITributo;
    domiciliables?: boolean;
}

const DomicilaicionFuturaCard: FC<ITributoCardProps> = (props) => {
    // PROPS
    const { domiciliacion, tributo, domiciliables } = props;

    // HOOKS
    const classes = useStyles();
    const history = useHistory<IStateHistory>();
    const { onAltaDomiciliaciones } = useAltasDomicilaicion({ history, domiciliables: domiciliables || false });



    return (
        <Grid container direction='column' className={classes.blueBorder} >
            <div className={classes.divColoredTextBorder}>
                <Typography className={classes.blueTextBorder}>{moment().year() + 1}</Typography>
            </div>

            {/* Datos titular e IBAN  */}

            <Grid item container direction='row' alignItems="baseline" justify="space-between" style={{ padding: 15 }} >
                <Grid item>
                    <Typography component="p" className={[classes.marginBottom5, classes.marginRight20].join(' ')}>
                        <Term component="Domiciliaciones" text="Datos titular" />: <strong>{[domiciliacion.nombreTitular, domiciliacion.nifTitular].join(' - ')}</strong>
                    </Typography>
                    <Typography component="p" className={[classes.marginRight20].join(' ')}>
                        <Term component="Domiciliaciones" text="IBAN" />: <strong>{domiciliacion.iban}</strong>
                    </Typography>
                </Grid>

                {/* Domiciliar */}

                <Grid item style={{ alignSelf: 'flex-end' }}>
                    <Button variant="outlined" color="primary" style={{ backgroundColor: '#fff' }}
                        onClick={() => onAltaDomiciliaciones([tributo])} >
                        <Term component="Domiciliaciones" text={"Modificar cuenta"} />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DomicilaicionFuturaCard;