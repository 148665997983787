import { ChangeEvent, FC } from "react";
import { Dialog, DialogTitle, DialogContent, Button, Checkbox, DialogActions, Divider, List, ListItem, ListItemIcon, ListItemText, Grid, useMediaQuery, useTheme, Tooltip } from "@material-ui/core";
import Term from "components/term";
import Icon from 'atomic/atoms/icon';
import { IProcedimiento } from "gateways/tramites/procedimientos.interfaces";

interface IProcedimientosDialogProps {
    open: boolean;    
    poderGeneral: boolean;
    procedimientos: IProcedimiento[];
    procedimientosSeleccionados: IProcedimiento[];
    procedimientosOtorgados: IProcedimiento[];
    onSelectProcedimiento: (procedimiento: IProcedimiento) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    onAccept: (poderGeneral: boolean) => void;
    onClose: () => void;
}

const ProcedimientosDialog: FC<IProcedimientosDialogProps> = ({ open, poderGeneral, procedimientos, procedimientosSeleccionados, procedimientosOtorgados, onAccept, onSelectProcedimiento, onClose }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const mitadProcedimientos = Math.ceil(procedimientos.length / 2);    
    
    return (
        <Dialog 
            fullScreen={fullScreen}
            open={open}
            maxWidth="md"
            disableBackdropClick={true}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                { poderGeneral ?
                <Term component="Representaciones" text="Estos son los procedimientos a los que va a otorgar poderes (representación)" />
                :
                <Term component="Representaciones" text="Procedimientos a los que desea otorgar poderes (representación)" />
                }
            </DialogTitle>
            <Divider />
            <DialogContent>
                { fullScreen ? (
                    <List dense disablePadding>
                        {
                            procedimientos.map(procedimiento => (                                
                                <ListItem key={`procedimiento_${procedimiento.codigo}`}>
                                    { !poderGeneral && procedimiento.id !== 2 ? (
                                    <ListItemIcon style={{ minWidth: 0, marginRight: 5 }}>
                                        <Checkbox
                                            edge="start"
                                            color="primary"
                                            checked={procedimientosSeleccionados.some(p => p.id === procedimiento.id)}
                                            tabIndex={-1}
                                            inputProps={{ 'aria-labelledby': `procedimiento_${procedimiento.codigo}` }}
                                            onChange={onSelectProcedimiento(procedimiento)}
                                        />                                
                                    </ListItemIcon>
                                    ) : poderGeneral && procedimiento.id > 2 && (
                                    <ListItemIcon style={{ minWidth: 0, marginRight: 5 }}>
                                        <Icon name={'check-ok-circle'} size={1} color={'green'} />
                                    </ListItemIcon>
                                    )}
                                    { ((poderGeneral && procedimiento.id > 2) || (!poderGeneral && procedimiento.id !== 2)) &&
                                    <ListItemText
                                        id={`procedimiento_${procedimiento.codigo}`}
                                        primary={<Term component="Representaciones" text={`PROCEDIMIENTO_${procedimiento.codigo}_NOMBRE`} />}
                                    />
                                    }
                                </ListItem>
                            ))
                        }
                    </List>
                ) : (
                    <List dense disablePadding>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                            {procedimientos.slice(0, mitadProcedimientos + 2).map(procedimiento => 
                                ((poderGeneral && procedimiento.id > 2) || (!poderGeneral && procedimiento.id !== 2)) && (
                                <ListItem key={`procedimiento_${procedimiento.codigo}`}>
                                {!poderGeneral && procedimiento.id !== 2 ? (
                                    <ListItemIcon style={{ minWidth: 0, marginRight: 5 }}>
                                    <Checkbox
                                        edge="start"
                                        color="primary"
                                        checked={procedimientosSeleccionados.some(p => p.id === procedimiento.id) || [1, 3].includes(procedimiento.idEstado)}
                                        tabIndex={-1}
                                        inputProps={{ 'aria-labelledby': `procedimiento_${procedimiento.codigo}` }}
                                        onChange={onSelectProcedimiento(procedimiento)}
                                        disabled={[1, 3].includes(procedimiento.idEstado)}
                                    />
                                    </ListItemIcon>
                                ) : poderGeneral && procedimiento.id > 2 && (
                                    <ListItemIcon style={{ minWidth: 0, marginRight: 5 }}>
                                        <Icon name={'check-ok-circle'} size={1} color={'green'} />
                                    </ListItemIcon>
                                )}                                
                                    <ListItemText
                                        id={`procedimiento_${procedimiento.codigo}`}
                                        primary={<Term component="Representaciones" text={`PROCEDIMIENTO_${procedimiento.codigo}_NOMBRE`} />}
                                    />
                                    { [1, 3].includes(procedimiento.idEstado) && !poderGeneral &&
                                    <Tooltip title={ 
                                        <p style={{fontSize: 15, margin: 5}}>
                                            { procedimiento.idEstado === 1 ? 
                                                <Term component="Representaciones" text="Procedimiento solicitado" />
                                                : 
                                                <Term component="Representaciones" text="Procedimiento aceptado" />
                                            }
                                        </p>} placement='top' >
                                        <ListItemIcon>
                                            <Icon name={'info-outline'} size={1} />
                                        </ListItemIcon>
                                    </Tooltip>
                                    }
                                </ListItem>
                                ))
                            }
                            </Grid>
                            <Grid item xs={6}>
                            {procedimientos.slice(mitadProcedimientos + 2).map(procedimiento => 
                                ((poderGeneral && procedimiento.id > 2) || (!poderGeneral && procedimiento.id !== 2)) && (
                                <ListItem key={`procedimiento_${procedimiento.codigo}`}>
                                {!poderGeneral && procedimiento.id !== 2 ? (
                                    <ListItemIcon style={{ minWidth: 0, marginRight: 5 }}>
                                    <Checkbox
                                        edge="start"
                                        color="primary"
                                        checked={procedimientosSeleccionados.some(p => p.id === procedimiento.id) || [1, 3].includes(procedimiento.idEstado)}
                                        tabIndex={-1}
                                        inputProps={{ 'aria-labelledby': `procedimiento_${procedimiento.codigo}` }}
                                        onChange={onSelectProcedimiento(procedimiento)}
                                        disabled={[1, 3].includes(procedimiento.idEstado)}
                                    />
                                    </ListItemIcon>
                                ) : poderGeneral && procedimiento.id > 2 && (
                                    <ListItemIcon style={{ minWidth: 0, marginRight: 5 }}>
                                        <Icon name={'check-ok-circle'} size={1} color={'green'} />
                                    </ListItemIcon>
                                )}                                
                                    <ListItemText
                                        id={`procedimiento_${procedimiento.codigo}`}
                                        primary={<Term component="Representaciones" text={`PROCEDIMIENTO_${procedimiento.codigo}_NOMBRE`} />}
                                    />
                                    { [1, 3].includes(procedimiento.idEstado) && !poderGeneral &&
                                    <Tooltip title={ 
                                        <p style={{fontSize: 15, margin: 5}}>
                                            { procedimiento.idEstado === 1 ? 
                                                <Term component="Representaciones" text="Procedimiento solicitado" />
                                                : 
                                                <Term component="Representaciones" text="Procedimiento aceptado" />
                                            }
                                        </p>} placement='top' >
                                        <ListItemIcon style={{ minWidth: 0, marginLeft: 5 }}>
                                            <Icon name={'info-outline'} size={1} />
                                        </ListItemIcon>
                                    </Tooltip>
                                    }
                                </ListItem>
                            ))}
                            </Grid>
                        </Grid>
                    </List>
                )}
            </DialogContent>

            <DialogActions>                
                <Button color="default" onClick={onClose}>
                    <Term component="Representaciones" text="Cancelar" />
                </Button>
                <Button color="default" 
                    disabled={
                        (!poderGeneral && procedimientosSeleccionados.length === 0) ||
                        (procedimientosSeleccionados.length > 0 && procedimientosSeleccionados.some(p => p.id === 2))
                    }
                    onClick={() => onAccept(poderGeneral)}>
                    <Term component="Representaciones" text="Aceptar" />
                </Button>
            </DialogActions>

        </Dialog>
    );
}

export default ProcedimientosDialog;
