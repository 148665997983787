import React, { FC, useContext, Fragment } from "react";
import { makeStyles, Grid, FormControl, ExpansionPanel, useTheme } from "@material-ui/core";
import styles from '../../shared/tributos.styles';
import { FilterDataContext } from "./filter-data.context";
import { IRemesaMultas, IMultas } from "gateways/tramites.interfaces";
import LabelField from 'components/label-field';
import Term from "components/term";
import { dateFormat } from "utils/dates";
import Icon from "@mdi/react";
import { mdiCircle } from "@mdi/js";

const useStyles = makeStyles(styles);

const useLocalStyles = makeStyles((theme) => ({
    rounded: {
        borderRadius: 8,
        margin: '16px 0'
    },
    main: {
        width: '100%',
        marginLeft: '1%',
        display:'flex',
    },
    botonIdentificacion: {
        display: 'flex',
        alignItems: 'center',
    }
}));

interface IMultaRemesaCardProps {
    multaRemesa: IRemesaMultas;
    handleSelectRemesa: (multaRemesa: IRemesaMultas) => void;
}

const MultaRemesaCard: FC<IMultaRemesaCardProps> = ({ multaRemesa, handleSelectRemesa }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const localClasses = useLocalStyles();

    const filterData = useContext(FilterDataContext);

    const today = new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)

    return (
        <>
        {/* {TODO: DWA-16338 añadir n cobradas y n anuladas} */}
            <Fragment>
                <ExpansionPanel className={[localClasses.rounded].join(' ')} onClick={() => handleSelectRemesa(multaRemesa)}>
                    <div 
                        className={[classes.mainButton, classes.recibo, classes.pointer, localClasses.main].join(' ')}>
                        <Grid item xs={12} sm={1}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="Id Remesa" />}
                                    text={multaRemesa.idRemesa}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="Descripcion" />}
                                    text={multaRemesa.nombre}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="fechaRemesa" />}
                                    text={dateFormat(multaRemesa.fechaRemesa)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2} style={{display:'flex', flexDirection:'row'}}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="fechaLimite" />}
                                    text={dateFormat(multaRemesa.fechaLimite)}
                                />
                            </FormControl>
                            <div style={{paddingLeft: 10,  display:'flex', alignItems:'center'}}>
                                { today.getTime() <= new Date(multaRemesa.fechaLimite).getTime()
                                    ? <Icon path={mdiCircle} size={0.5} color='lightgreen' />
                                    : <Icon path={mdiCircle} size={0.5} color='red' />
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="Num identificaciones" />}
                                    text={multaRemesa.totalIdentificaciones}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="Num identificaciones Pendientes" />}
                                    text={multaRemesa.Pendientes}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="Num Cobradas" />}
                                    text={multaRemesa.Cobradas}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="Num Anuladas" />}
                                    text={multaRemesa.Anuladas}
                                />
                            </FormControl>
                        </Grid>
                    </div>
                </ExpansionPanel>
            </Fragment>
        </>
    );
};


export default MultaRemesaCard;