import { REGEX_EMAIL } from "constants/regular-expressions";

export function validateEmail(email: string): boolean {
    if (email) {
        // Eliminamos los espacios en blanco y nos aseguramos de que las letras sean mayusculas
        email = email.replace(/\s/g, "").replace(/-/g, "");

        if (email.match(REGEX_EMAIL)) {
            return true;
        }
    }
    return false
 }