import React, { FC } from "react";
import { useTheme } from '@material-ui/core';
import styles from "./styles";

import { Card, CardHeader, CardContent, CardActions, withStyles, WithStyles } from "@material-ui/core";


type IGenericModal =  {
    children: React.ReactNode;
    styleCard?: React.CSSProperties; 
    styleCardContent?:React.CSSProperties; 
} & ({
        withTitle: true
        withCardHeaderBotomRow: boolean
        renderTitle: () => JSX.Element
    } | {
        withTitle: false
        renderTitle?: never;
        withCardHeaderBotomRow?:never;
    }
) & ({
        withActions: true
        actionsPosition: 'right' | 'bottom'
        renderActions: () => JSX.Element
    } | {
        withActions: false
        renderActions?: never;
        actionsPosition?:never
    }
)& ({
    withLeftIcon: true
    renderLeftIcon: () => JSX.Element
} | {
    withLeftIcon: false
    renderLeftIcon?: never;
}
) & WithStyles<typeof styles> 

const GenericModal: FC<IGenericModal> = ({
    classes,children,
    withActions,withTitle,withCardHeaderBotomRow,
    renderActions,actionsPosition,renderTitle, 
    styleCard,styleCardContent,
     withLeftIcon, renderLeftIcon
}) => {

    return (
        <Card className={
            withLeftIcon ||(withActions === true && actionsPosition === 'right')
                ? [classes.withLeftIcon, classes.paddingRootContainer].join(' ')
                :classes.paddingRootContainer
            } 
            style={styleCard}
        >
            <div className={ withLeftIcon ||(withActions === true && actionsPosition === 'right')
                ? [classes.withLeftIcon, classes.div].join(' ')
                : classes.div
            }>
            {withLeftIcon && <div>
                {renderLeftIcon()}
            </div>
            }
            <div style={{display:'flex', flexGrow: 1, flexDirection:'column'}}>
                {withTitle && 
                    <CardHeader 
                        style={withCardHeaderBotomRow ? {borderBottom: 'solid', borderBottomWidth:1, borderColor:'#d3d3d3', padding:5}: {padding: 5}}
                        title={renderTitle()}
                    />
                }
                <CardContent style={styleCardContent}>{children}</CardContent>
                {withActions && actionsPosition === 'bottom' && <CardActions>{renderActions()}</CardActions>}
            </div>
            {withActions && actionsPosition === 'right' && 
                <div>
                    <CardActions>{renderActions()}</CardActions>
                </div>
            }
            </div>
            
        </Card>
    );
}

export default  withStyles(styles)(GenericModal) ;