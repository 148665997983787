import React, { FC, useContext, useMemo, useCallback } from "react";
import Term from "components/term";
import {
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  MenuItem,
  TextField,
  ListItemText,
} from "@material-ui/core";
import { FiltersContext } from "./filters.context";
import { FilterDataContext } from "./filter-data.context";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: `30px 0`,
    [theme.breakpoints.up("md")]: {
      margin: `10px 5px`,
    },
  },
  group: {
    margin: `10px 0`,
  },
  block: {
    display: `inline-block`,
  },
  hidden: {
    display: `none`,
  },
  middle: {
    verticalAlign: `middle`,
  },
}));

export type ScreenType = "presentaciones";

export interface IFiltersFormProps {
  screen: ScreenType;
}

const FiltersForm: FC<IFiltersFormProps> = ({ screen }) => {
  const classes = useStyles();

  const [filters, filtersDispatch] = useContext(FiltersContext);
  const filterData = useContext(FilterDataContext);

  const fechaDate = useMemo(
    () => (filters.fecha ? moment(filters.fecha, "DD/MM/YYYY").toDate() : null),
    [filters.fecha]
  );

  const handleTipoExpedienteChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      filtersDispatch({
        type: "update",
        filter: "tipoExpediente",
        value: event.target.value as string === "" ? undefined : event.target.value as string,
      });
    },
    [filtersDispatch]
  );

  const handleEstadoChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      filtersDispatch({
        type: "update",
        filter: "idEstado",
        value: event.target.value === "" ? undefined : event.target.value,
      });
    },
    [filtersDispatch]
  );

  const handleFechaChange = useCallback(
    (date: Date | null) => {
      filtersDispatch({ type: "update", filter: "fecha", value: date });
    },
    [filtersDispatch]
  );

  const handleModeloChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      filtersDispatch({
        type: "update",
        filter: "modelo",
        value: event.target.value === "" ? undefined : event.target.value,
      });
    },
    [filtersDispatch]
  );

  const handleNifChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      filtersDispatch({
        type: "update",
        filter: "nif",
        value: event.target.value === "" ? undefined : event.target.value,
      });
    },
    [filtersDispatch]
  );

  const handleNRDChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      filtersDispatch({
        type: "update",
        filter: "nrd",
        value: event.target.value === "" ? undefined : event.target.value,
      });
    },
    [filtersDispatch]
  );

  return (
    <div className={classes.root}>
      <FormControl
        key="estado"
        className={
          filterData && filterData.idEstado
            ? classes.formControl
            : classes.hidden
        }
        fullWidth
      >
        <InputLabel key="label-estado" htmlFor="select-multiple-chip">
          <Term component="Tramites" text="Estado" />
        </InputLabel>
        <Select
          value={filters.idEstado}
          autoWidth={true}
          onChange={handleEstadoChange}
        >
          <MenuItem value={undefined}>
            <ListItemText
              primary={<Term component="Tramites" text="TODOS" />}
            />
          </MenuItem>
          {(filterData && filterData.idEstado ? filterData.idEstado : []).map(
            (option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText
                  primary={
                    <Term
                      component="Tramites"
                      text={"Estado_" + option.value}
                    />
                  }
                />
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      <FormControl
        key="tipoExpediente"
        className={
          filterData && filterData.tipoExpediente
            ? classes.formControl
            : classes.hidden
        }
        fullWidth
      >
        <InputLabel key="label-tipoExpediente" htmlFor="select-multiple-chip">
          <Term component="Tramites" text="tipoExpediente" />
        </InputLabel>
        <Select
          value={filters.tipoExpediente}
          autoWidth={true}
          onChange={handleTipoExpedienteChange}
        >
          <MenuItem value={undefined}>
            <ListItemText
              primary={<Term component="Tramites" text="TODOS" />}
            />
          </MenuItem>
          {(filterData && filterData.tipoExpediente
            ? filterData.tipoExpediente
            : []
          ).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <ListItemText
                primary={<Term component="Tramites" text={option.label} />}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl key="fecha" className={classes.formControl} fullWidth>
        <DatePicker
          disableToolbar
          variant="inline"
          margin="dense"
          format="dd/MM/yyyy"
          id="datepicker-fecha"
          label={<Term component="Tramites" text="Fecha Alta" />}
          value={fechaDate}
          onChange={handleFechaChange}
        />
      </FormControl>
      <FormControl key="modelo" className={classes.formControl} fullWidth>
        <TextField
          key="modelo"
          value={filters.modelo || ""}
          label={<Term component="Tramites" text="Modelo" />}
          onChange={handleModeloChange}
        />
      </FormControl>
      <FormControl key="nif" className={classes.formControl} fullWidth>
        <TextField
          key="nif"
          value={filters.nif || ""}
          label={<Term component="Tramites" text="NIF" />}
          onChange={handleNifChange}
        />
      </FormControl>
      <FormControl key="nrd" className={classes.formControl} fullWidth>
        <TextField
          key="nrd"
          value={filters.nrd || ""}
          label={<Term component="Tramites" text="NRD" />}
          onChange={handleNRDChange}
        />
      </FormControl>
    </div>
  );
};

export default FiltersForm;
