import { FC, JSXElementConstructor, ReactElement } from "react";
import { StandardTextFieldProps, TextField as TextFieldMUI, TextFieldProps} from "@material-ui/core";
import styles from "./styles";



const TextField: FC<TextFieldProps> = (props) => {


    return (
        <div>
            <TextFieldMUI {...props}

                InputLabelProps={{
                    ...props.InputLabelProps,
                    shrink: true,
                    style: {

                        paddingRight: 5,
                        paddingLeft: 5,
                        backgroundColor: 'white',

                    }
                }}


            />

        </div>
    );
}

export { TextField };