import React, { useState, useEffect, useMemo } from 'react';
import LiteralDialog from './literal-modal';
import usePage from 'hooks/page.hook';
import { format } from 'utils/i18n';

interface IProps {
    text: string;
    component?: string;
    params?: any[];
    isHtml?: boolean;
}

function Term(props: IProps) {
    const [state] = usePage();
    // const termsTemp = useContext(LiteralsContext);
    const { text, component, params } = props;
    const [translatedText, setTranslatedText] = useState('');
    const [literalDialogOpen, setLiteralDialogOpen] = useState(false);
    const isHtml = props.isHtml === true;

    const terms = useMemo(() => {
        const key = ('norm_literals.' + state.lang + '.' + (component || 'Global').toLowerCase()).toLowerCase();
        return JSON.parse(sessionStorage.getItem(key) || '{}');
    }, [state.lang, component]);

    const handleModalCloseLiteral = () => {
        setLiteralDialogOpen(false);
    }

    const handleModalOpenLiteral = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setLiteralDialogOpen(true);
    }

    useEffect(() => {
        if(text != undefined){
            let textResult = text;
            const  termLoaded = terms[text.toLowerCase()];
        
            if (termLoaded) {
                textResult = termLoaded;
            } else {
                textResult = text;
            }
            setTranslatedText(format(textResult, params));
        }
    }, [terms, component, text, params]);

    const textRendered = useMemo(() => isHtml ? <span dangerouslySetInnerHTML={{ __html: translatedText }} /> : translatedText, [isHtml, translatedText]);

    return (
        <>
            {
                state.termsEditable ?
                    (
                        <>
                            <LiteralDialog
                                open={literalDialogOpen}
                                translatedText={translatedText}
                                onClose={handleModalCloseLiteral}
                                {...props}
                            />
                            <span style={{ cursor: "pointer" }} onClick={handleModalOpenLiteral}>{textRendered}</span>
                        </>
                    )
                    : (textRendered)
            }
        </>
    );
}

export default Term;
