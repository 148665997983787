import React, { useEffect, useContext, useMemo, useState, useCallback } from "react";
import { WithStyles, withStyles, Theme, Card, CardContent, Grid, TextField, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { LiteralsContext } from "containers/shared/literals";
import IoC from "contexts/ioc.context";
import { RepresentacionesGateway } from "gateways/tramites/representaciones.gateway";
import { IEstadoSolicitud, IProcedimiento } from "gateways/tramites/representaciones.interfaces";
import { translate } from "utils/i18n";
import Term from "components/term";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const styles = (theme: Theme): StyleRules => ({
    selectField: {
        width: '100%'
    },
});

interface IProps extends WithStyles<typeof styles> {
    onChangeEstado: (event: any) => void;
    onChangeProcedimiento: (event: any) => void;
    onChangeNif: (nif: string) => void;
    onChangeFechaDesde: (date: Date | null) => void;
    onChangeFechaHasta: (date: Date | null) => void;
}

const BuscadorRepresentaciones: React.FC<IProps> = (
    {classes, onChangeEstado, onChangeProcedimiento, onChangeNif, onChangeFechaDesde, onChangeFechaHasta }) => {
    const terms = useContext(LiteralsContext);
    const ioc = useContext(IoC);
    const representacionesGateway = useMemo(() => ioc.get(RepresentacionesGateway) as RepresentacionesGateway, [ioc]);

    const [estados, setEstados] = useState<IEstadoSolicitud[]>([]);
    const [procedimientos, setProcedimientos] = useState<IProcedimiento[]>([]);
    const [fechaFinVigenciaDesde, setFechaFinVigenciaDesde] = useState<Date | null>(null);
    const [fechaFinVigenciaHasta, setFechaFinVigenciaHasta] = useState<Date | null>(null);
    const [labelFechaFinVigenciaDesde, setLabelFechaFinVigenciaDesde] = useState<string | null>(translate('Representaciones', 'Fecha desde', terms));
    const [labelFechaFinVigenciaHasta, setLabelFechaFinVigenciaHasta] = useState<string | null>(translate('Representaciones', 'Fecha hasta', terms));

    useEffect(() => {
        (async () => {
            const estadosSolicitud = await representacionesGateway.getEstados();
            setEstados(estadosSolicitud);
            const tiposProcedmiento = await representacionesGateway.getProcedimientos();
            setProcedimientos(tiposProcedmiento);
        })();
    }, [representacionesGateway]);    

    const handleChangeFechaFinVigenciaDesde = useCallback((date: MaterialUiPickersDate) => {
        setFechaFinVigenciaDesde(date);
        onChangeFechaDesde(date);
    }, [onChangeFechaDesde]);

    const handleOnBlurFechaFinVigenciaDesde = useCallback(() => {
        if (fechaFinVigenciaDesde === null) {
            setLabelFechaFinVigenciaDesde(translate('Representaciones', 'Fecha desde', terms));
        }
    }, [fechaFinVigenciaDesde, terms]);

    const handleChangeFechaFinVigenciaHasta = useCallback((date: MaterialUiPickersDate) => {        
        setFechaFinVigenciaHasta(date);
        onChangeFechaHasta(date);
    }, [onChangeFechaHasta]);

    const handleOnBlurFechaFinVigenciaHasta = useCallback(() => {
        if (fechaFinVigenciaHasta === null) {
            setLabelFechaFinVigenciaHasta(translate('Representaciones', 'Fecha hasta', terms));
        }
    }, [fechaFinVigenciaHasta, terms]);

    return (
        <Card style={{ flex: 1, marginBottom: 5 }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="row" spacing={1}>
                            <Grid item xs>
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel id="estados-label">
                                        <Term component="Representaciones" text="Estados" />
                                    </InputLabel>
                                    <Select
                                        id="estados"
                                        label={translate('Representaciones', 'Estados', terms)}
                                        onChange={onChangeEstado}
                                        defaultValue=""
                                    >
                                        <MenuItem value="">
                                            <em>{translate('Tramites', 'TODOS', terms)}</em>
                                        </MenuItem>
                                        {
                                            estados !== null ?
                                                estados.map(x => (
                                                    <MenuItem key={x.id} value={x.id}>{translate('Representaciones', 'PROCEDIMIENTO_' + x.id + '_ESTADO', terms)}</MenuItem>
                                                )) : null
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel id="procedimientos-label">
                                        <Term component="Representaciones" text="Procedimientos" />
                                    </InputLabel>
                                    <Select
                                        id="procedimientos"
                                        label={translate('Representaciones', 'Procedimientos', terms)}
                                        onChange={onChangeProcedimiento}                                        
                                        defaultValue=""
                                    >
                                        <MenuItem value="">
                                            <em>{translate('Tramites', 'TODOS', terms)}</em>
                                        </MenuItem>
                                        {
                                            procedimientos !== null ?
                                                procedimientos.map(x => (
                                                    <MenuItem key={x.id} value={x.codigo}>{translate('Representaciones', 'PROCEDIMIENTO_' + x.codigo + '_NOMBRE', terms)}</MenuItem>
                                                )) : null
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <TextField 
                                    id="nif"
                                    label="Nif"
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={(e) => onChangeNif(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4} container direction="row">
                                <Grid item xs>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        margin="dense"
                                        format="dd/MM/yyyy"
                                        maxDate={fechaFinVigenciaHasta}
                                        className={classes.input}
                                        id="datepicker-fecha-desde"
                                        label={labelFechaFinVigenciaDesde}
                                        value={fechaFinVigenciaDesde}
                                        onChange={handleChangeFechaFinVigenciaDesde}
                                        onFocus={() => setLabelFechaFinVigenciaDesde(translate('Representaciones', 'Fecha fin representacion desde', terms))}
                                        onBlur={handleOnBlurFechaFinVigenciaDesde}
                                        invalidDateMessage={<Term component="Representaciones" text="fecha_invalida" />}
                                    />      
                                </Grid>                                
                                <Grid item xs>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        margin="dense"
                                        format="dd/MM/yyyy"
                                        minDate={fechaFinVigenciaDesde}
                                        className={classes.input}
                                        id="datepicker-fecha-hasta"
                                        label={labelFechaFinVigenciaHasta}
                                        value={fechaFinVigenciaHasta}
                                        onChange={handleChangeFechaFinVigenciaHasta}
                                        onFocus={() => setLabelFechaFinVigenciaHasta(translate('Representaciones', 'Fecha fin representacion hasta', terms))}                                        
                                        onBlur={handleOnBlurFechaFinVigenciaHasta}
                                        invalidDateMessage={<Term component="Representaciones" text="fecha_invalida" />}
                                    />   
                                </Grid>                             
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default withStyles(styles)(BuscadorRepresentaciones);
