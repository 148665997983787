import { detect } from 'detect-browser'; 

export function getUserAgent(): string {
    return navigator.userAgent;
}

export function getBrowser(): string | null {
    const browser = detect();
    return browser ? browser.name + ' ' + browser.version : null;
}

export function getPlatform(): string {
    return navigator.platform;
}