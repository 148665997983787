import { Typography } from "@material-ui/core";
import { BIOPT, DeclaracionMap, TAutorizacion, TDatosIdentificativos, TDeclaracionResponsable, TDeduccion, TDireccionRepresentante, TFinReint, TFinSol, TIDentifica, TIdentificacionAbono, TTipoDocGenera, TTipoSolicitud } from "../types";
import { translate } from "utils/i18n";
import { TApartado } from "gateways/tramites/abonoNacimiento.interfaces";
import moment from "moment";
import { formatNumStr } from "components/generic-sujeto/utils";
import contribuyeneIMG from "../../../../../resources/abonoNacimiento/Resumen1.png";
import deduccionIMG from "../../../../../resources/abonoNacimiento/Resumen2.png";
import abonoIMG from "../../../../../resources/abonoNacimiento/Resumen3.png";
import { isTSEnumMember } from "@babel/types";


export const ORDERED_APARTADOS : TApartado[] = ['datos_identificativos','direccion_representante','identificacion_abono','declaracion_responsable','autorizacion','finalizacion_justificante']
const renderItemField = (label: string,  value: any) => {
    return (
        <div style={{display: 'inline-flex', width: '100%', marginLeft: 10}}>
            <Typography style={{color: 'grey', marginRight: 5}}>{label}:</Typography>
            <Typography style={{fontWeight: 500}}>{value}</Typography>
        </div>
    )
}
const renderItemFieldDeclResp = (label: string,  value: any) => {
    return (
        <div style={{display: 'inline-flex', width: '100%', paddingLeft: 10, paddingRight: 10,marginBottom:5}} 
            dangerouslySetInnerHTML={{
                __html: value 
                ? `<span style="color:grey; font-size: 1rem;font-family: "Roboto", "Helvetica", "Arial", "sans-serif";font-weight: 400;line-height: 1.5;letter-spacing: 0.00938em;">${label} <b style="color:black;">${value}<b></span>`
                : `<span style="color:grey; font-size: 1rem;font-family: "Roboto", "Helvetica", "Arial", "sans-serif";font-weight: 400;line-height: 1.5;letter-spacing: 0.00938em;">${label}</span>`
        }}></div>
    )
}

export const extraerDatosIdentificativos = ( datos:TDatosIdentificativos | undefined, tipo: 'solicitud'|'reintegro', terms:any  ) => {
    return (
        datos !== undefined  ?
            <div key ='datos_identificativos'>
                <Typography style={{borderBottom: 'solid 1px', marginBottom:10, marginTop: 10}} variant='h6'>
                    {translate('AbonoNacimiento','datos_identificativos',terms).toUpperCase()}
                </Typography>
                {renderItemField(
                    translate('AbonoNacimiento', tipo === 'solicitud'? 'tipoSolicitud':'tipoReintegro', terms),
                    translate('AbonoNacimiento', tipo === 'solicitud' ?`ABO_NAC_tipoSolicitud_${datos.tipoSolicitud}`: `ABO_NAC_tipoReintegro_${datos.tipoSolicitud}`, terms)
                )}
                {datos.modalidadSolicitud  && renderItemField(
                    translate('AbonoNacimiento', 'ABO_NAC_modalidadSolicitud', terms),
                    translate('AbonoNacimiento', `ABO_NAC_modalidadSolicitud_${datos.modalidadSolicitud}`, terms)
                )}
                {tipo === 'solicitud' && datos.tipoSolicitud === 'anticipado' &&
                    renderItemField(translate('AbonoNacimiento','askMonoParental' ,terms), datos.familiaMonoparental === true ? 'Sí': 'No')
                }
                <Typography style={{fontWeight:'bolder'}}>
                    {translate('AbonoNacimiento',datos.tipoSolicitud === 'anticipado' ? 'panel_contribuyente' :'panel_principal',terms).toUpperCase()}
                </Typography>
                {renderItemField(translate('GenericComponents', 'nif', terms), datos.contribuyente?.nif)}
                {renderItemField(translate('GenericComponents', 'nombre', terms), datos.contribuyente?.nombre)}
                {renderItemField(translate('GenericComponents', 'apellidos', terms), datos.contribuyente?.apellidos)}
                {renderItemField(translate('GenericComponents', 'telefono', terms), datos.contribuyente?.telefono)}
                {renderItemField(translate('GenericComponents', 'email', terms), datos.contribuyente?.email)}
                {renderItemField(
                    translate('AbonoNacimiento','ABO_NAC_parentesco',terms),
                    translate('AbonoNacimiento', datos.contribuyente?.parentesco? `ABO_NAC_parentesco_${datos.contribuyente.parentesco}`: '', terms)
                )}
                
                {datos.progenitor !== undefined  && datos.progenitor.nif && (
                    <>
                        <Typography style={{fontWeight:'bolder'}}>
                            {translate('AbonoNacimiento', 'panel_progenitor',terms).toUpperCase()}
                        </Typography>
                        {renderItemField(translate('GenericComponents', 'nif', terms), datos.progenitor?.nif)}
                        {renderItemField(translate('GenericComponents', 'nombre', terms), datos.progenitor?.nombre)}
                        {renderItemField(translate('GenericComponents', 'apellidos', terms), datos.progenitor?.apellidos)}
                        {renderItemField(translate('GenericComponents', 'telefono', terms), datos.progenitor?.telefono)}
                        {renderItemField(translate('GenericComponents', 'email', terms), datos.progenitor?.email)}
                        {renderItemField(
                            translate('AbonoNacimiento','ABO_NAC_parentesco',terms),
                            translate('AbonoNacimiento', datos.progenitor?.parentesco? `ABO_NAC_parentesco_${datos.progenitor.parentesco}`: '', terms)
                        )}
                         {datos.tipoSolicitud === 'anticipado' && tipo ==='solicitud' &&
                            renderItemField(translate('AbonoNacimiento', 'ABO_NAC_pregunta_solicita', terms), datos.progenitor?.solicita ? 'Sí': 'No')
                        }
                    </>
                )}

                <Typography style={{fontWeight:'bolder'}}>
                    {translate('AbonoNacimiento', 'panel_derecho_deduccion',terms).toUpperCase()}
                </Typography>
                {renderItemField(translate('GenericComponents', 'nombre', terms), datos.derechoDeduccion?.nombre)}
                {renderItemField(translate('GenericComponents', 'apellidos', terms), datos.derechoDeduccion?.apellidos)}
                        
                {renderItemField(translate('AbonoNacimiento', 'fechaNacimiento', terms),
                    datos.derechoDeduccion?.fechaNacimiento ? moment(datos.derechoDeduccion?.fechaNacimiento).format('DD/MM/YYYY'): '')
                }
                {renderItemField(translate('GenericComponents', 'dir_pais', terms), datos.derechoDeduccion?.paisNombre)}
                {renderItemField(translate('GenericComponents', 'dir_provincia', terms), datos.derechoDeduccion?.provinciaNombre)}
                {renderItemField(translate('GenericComponents', 'dir_municipio', terms), datos.derechoDeduccion?.municipioNombre)}
                        
                
                
            </div>
        : <div></div>
    )

};

export const extraerIdentificaAbono = (datos: TIdentificacionAbono|undefined, terms:any) => {
    return (
        datos!== undefined
        ? <div key='identificacion_abono'>
            <Typography style={{borderBottom: 'solid 1px', marginBottom:10, marginTop: 10}} variant='h6'>
                {translate('AbonoNacimiento','identificacion_abono',terms).toUpperCase()}
            </Typography>
            {renderItemField(translate('AbonoNacimiento', 'fechaSolicitud', terms), 
                datos.fechaSolicitud ? moment(datos.fechaSolicitud).format('DD/MM/YYYY'): '')
            }
            {renderItemField(translate('AbonoNacimiento', 'importe',terms), datos.importePercibido ? formatNumStr(datos.importePercibido )+ '€': undefined)}
            {renderItemField(translate('AbonoNacimiento', 'refSolicitud',terms), datos.ejercicio+'/'+datos.numSolicitud)}
        </div>
        : <div></div>
    )
}

export const extraerDeclaracionResponsable = ( datos:TDeclaracionResponsable | undefined,monoparental: boolean, terms:any ,arrOPTS: BIOPT[] ) => {
    const tipoDoc = (datos?.tipoDocGenera ?? 50) as TTipoDocGenera

    const declResp = datos? datos.declResponsable as DeclaracionMap[typeof tipoDoc]  : null
    const keys = declResp ? Object.keys(declResp) as  (keyof DeclaracionMap[typeof tipoDoc])[]: [] 
    //console.log(keys)
    // const ARR_OPT = [
    //     {id:'individual', term: '52_baseImponible_individual'},
    //     {id:'familiaNumerosa_indv', term: '52_baseImponible_familiaNumerosa_indv'},
    //     {id:'monoparental_indv', term: '52_baseImponible_monoparental_indv'},
    //     {id:'conjunta', term: '52_baseImponible_conjunta'},
    //     {id:'familiaNumerosa_conj', term: '52_baseImponible_familiaNumerosa_conj'},
    //     {id:'monoparental_conj', term: '52_baseImponible_monoparental_conj'},
    //     {id:'monoparental' , term:  '50_baseImponible_valor1_monoparental' },
    //     {id:'familiaNumerosa_conj' , term:  '50_baseImponible_valor1_familiaNumerosa_conj'},
    //     {id:'familiaNumerosa_indv' , term:  '50_baseImponible_valor1_familiaNumerosa_indv' },
    //     {id:'individual', term:  '50_baseImponible_valor1_individual'},
    //     {id:'conjunta', term:  '50_baseImponible_valor1_conjunta'},
    //     {id:'no-decl-IRPF', term: '50_baseImponible_valor2'}
    // ]
    return (
        declResp !== null  ?
            <div key ='declaracion_responsable'>
                <Typography style={{borderBottom: 'solid 1px', marginBottom:10, marginTop: 10}} variant='h6'>
                    {translate('AbonoNacimiento','declaracion_responsable',terms).toUpperCase()}
                </Typography>
                {keys.filter(item => !(item as string).includes('_valor')).map( key => {
                    let termIG = (key as string).includes('valor') ? `${key}`: `${tipoDoc}_${key}`
                    let value: any = declResp[`${key}_valor`]
                    
                    if(key === 'baseImponible' ){
                        let str: string[]=[]
                        if(value['baseImponible_valor1']){
                            const val = arrOPTS.find(item => item.id.toString() === value['baseImponible_valor1'])
                            str.push(translate('AbonoNacimiento', val?.term ?? '' /*`${tipoDoc}_${fixValue}`*/, terms) as string) 
                        }
                        if(value['baseImponible_valor2']){
                            str.push(`${translate('AbonoNacimiento','baseImponible', terms)}: ${formatNumStr(value['baseImponible_valor2'])+ '€'}`)
                        }
                        value= str.join('  ')
                    }
                   // value = translate('AbonoNacimiento',value, terms) as string
                    if(key === 'numHijosProgenitor'){
                        const k = Object.keys(value)
                        const fixValue = k.map( (item, idx) => {
                            const label = translate('AbonoNacimiento',item, terms)+': '
                            const val = value[item] as string
                            return label.concat(val)
                        })
                        value= fixValue.join(', ')
                        //console.log('val ', value)
                    }
                    if(key ==='importeIRPF'){
                        value= value ? formatNumStr(value )+ '€': ''
                    }
                    if(key === 'familiaNumerosa'){
                        value = value === true? translate('Global','si',terms): translate('Global','no',terms)
                    }

                    return(
                        `${key}_valor` === 'esCausa_valor' ?
                        <>
                            {renderItemFieldDeclResp(translate('AbonoNacimiento', termIG, terms) , undefined)}
                            <div style={{paddingLeft: 10}}>
                            {Object.keys(value).filter(item => !(item as string).includes('descripcion')).map( k => value[k] 
                                ? 
                                renderItemFieldDeclResp(
                                    translate('AbonoNacimiento', k, terms) ,
                                    (k as string).includes('otros') 
                                        ? value[`${k}_descripcion`] 
                                        : '' 
                                )
                                : null
                            )}
                            </div>
                           
                        </>
                        : 
                        renderItemFieldDeclResp(translate('AbonoNacimiento', termIG, terms) , value)
                    )
                })}

            </div>
        : <div></div>
    )

   

};

export const extraerDirecRepre = ( datos:TDireccionRepresentante | undefined, terms:any ) => {
    return (
        datos !== undefined ?
            <div key ='direccion_representante'>
                <Typography style={{borderBottom: 'solid 1px', marginBottom:10, marginTop: 10}} variant='h6'>
                    {translate('AbonoNacimiento','direccion_representante',terms).toUpperCase()}
                </Typography>
                {/* <Typography style={{fontWeight:'bolder'}}>
                    {translate('AbonoNacimiento', 'panel_dir_notificacion',terms).toUpperCase()}
                </Typography>
                {renderItemField(translate('GenericComponents', 'dir_pais', terms), datos.dirNotificacion.pais.nombre)}
                {renderItemField(translate('GenericComponents', 'dir_provincia', terms), datos.dirNotificacion.provincia?.nombre )}
                {renderItemField(translate('GenericComponents', 'dir_municipio', terms), datos.dirNotificacion.municipio?.nombre )}
                {renderItemField(translate('GenericComponents', 'dir_tipoVia', terms), datos.dirNotificacion.tipoVia?.nombre)}
                {renderItemField(translate('GenericComponents', 'dir_nombreVia', terms), datos.dirNotificacion.nombreVia)}
                {renderItemField(translate('GenericComponents', 'dir_numero', terms), datos.dirNotificacion.numero)}
                {renderItemField(translate('GenericComponents', 'dir_escalera', terms), datos.dirNotificacion.escalera)}
                {renderItemField(translate('GenericComponents', 'dir_letra', terms), datos.dirNotificacion.letra)}
                {renderItemField(translate('GenericComponents', 'dir_piso', terms), datos.dirNotificacion.piso)}
                {renderItemField(translate('GenericComponents', 'dir_puerta', terms), datos.dirNotificacion.puerta)}
                {renderItemField(translate('GenericComponents', 'dir_cp', terms), datos.dirNotificacion.cp)} */}

                <Typography style={{fontWeight:'bolder'}}>
                    {translate('AbonoNacimiento', 'panel_representante',terms).toUpperCase()}
                </Typography>
                {renderItemField(translate('GenericComponents', 'nif', terms), datos.representante?.nif)}
                {renderItemField(translate('GenericComponents', 'nomApe', terms), datos.representante?.nomApe)}
                {renderItemField(translate('GenericComponents', 'telefono', terms), datos.representante?.telefono)}
                {renderItemField(translate('GenericComponents', 'email', terms), datos.representante?.email)}
                
               
            </div>
        :<div></div>
    )

};

export const extraerAutorizacion = ( datos:TAutorizacion | undefined,tipo: 'solicitud'|'reintegro', terms:any  ) => {
    //console.log(datos)
    return (
        datos !== undefined  ?
            <div key ='autorizacion'>
                <Typography style={{borderBottom: 'solid 1px', marginBottom:10, marginTop: 10}} variant='h6'>
                    {translate('AbonoNacimiento','autorizacion',terms).toUpperCase()}
                </Typography>
                {renderItemField(translate('AbonoNacimiento', 'autorizaConsultaDatosShort', terms), datos.autorizaConsultaDatos? 'Sí': 'No')}
                { datos.autorizaConsultaDatos === false && tipo === 'solicitud'&&
                    <>
                        {renderItemField(translate('AbonoNacimiento', 'presentaDeclIRPFAnterior',terms), datos.presentaDeclIRPFAnterior ? 'Sí': 'No')}
                        <Typography>{translate('AbonoNacimiento','docsAdjuntos',terms)}</Typography>
                        {renderItemField(translate('AbonoNacimiento', 'declIRPFAnterior',terms), typeof datos.declIRPFAnterior === 'string' ? 'Sí': 'No')}
                        {renderItemField(translate('AbonoNacimiento', 'certifImputacionFiscal',terms), typeof datos.certifImputacionFiscal === 'string'  ? 'Sí': 'No')}
                        {renderItemField(translate('AbonoNacimiento', 'certifResidenciaFiscal',terms), typeof datos.certifResidenciaFiscal === 'string'  ? 'Sí': 'No')}
                        {renderItemField(translate('AbonoNacimiento', 'certifNacimiento',terms), typeof datos.certifNacimiento === 'string'  ? 'Sí': 'No')}
                    </>
                }      
                
            </div>
        :<div></div>
    )

};

export const extraerJustificanteFin = ( datos:TFinReint|TFinSol | undefined, tipo: 'solicitud'|'reintegro', terms:any  ) => {

    return (
        datos !== undefined ?
            <div key ='finalizacion_justificante'>
                <Typography style={{borderBottom: 'solid 1px', marginBottom:10, marginTop: 10}} variant='h6'>
                    {translate('AbonoNacimiento','finalizacion_justificante',terms).toUpperCase()}
                </Typography>
                {tipo === 'solicitud' && (datos as TFinSol).importeSolicitud !== undefined && 
                    renderItemField(translate('AbonoNacimiento', 'importeSolicitud',terms),  formatNumStr((datos as TFinSol).importeSolicitud ?? 0) +'€' )         
                } 
                
                {tipo === 'reintegro' && 
                    renderItemField(translate('AbonoNacimiento', 'importeAIngresar',terms), (datos as TFinReint).importeAIngresar ? formatNumStr((datos as TFinReint).importeAIngresar ?? 0)+'€' : undefined)
                }   
                {renderItemField(translate('AbonoNacimiento', 'fechaFin',terms), datos.fechaFin !== undefined ? moment(datos.fechaFin).format('DD/MM/YYYY'): '')}
            </div>
        : <div></div>
    )

};

// -------------------------------------------------------------------------------------------------------------------------------------------------
//  RESUMEN 
// -------------------------------------------------------------------------------------------------------------------------------------------------
type TImage = 'contribuyente' | 'deduccion' | 'abono'
const getIMG= (type: TImage) => {
    switch (type) {
        case 'contribuyente':
            return contribuyeneIMG;
        case 'deduccion':
            return deduccionIMG;
        case 'abono':
            return abonoIMG;
        default:
            return null;
    }
}
export const renderTitleResumen= (componentIG:string, termIG:string, terms:any) => (
    <Typography style={{fontWeight:'bolder'}}>
        {translate(componentIG,termIG,terms).toUpperCase()}
    </Typography>
)

export const renderIconResumen= (type:TImage ) => {
    const IMG = getIMG(type) 
    return (IMG ? <img src={IMG}></img>: <></>)       
}


const renderItemFieldResumen = (label: string,  value: any) => {
    return (
        <div style={{display: 'inline-flex', width: '100%', flexWrap:'wrap'}}>
            <Typography style={{color:  'grey', marginRight: 5}}>{label}:</Typography>
            <Typography style={{fontWeight: 500}}>{value}</Typography>
        </div>
    )
}
export const resumenContribuyente = ( datos:TIDentifica, /*tipoSolicitud:TTipoSolicitud,*/ terms:any  ) => {
    return (
            <div key ='contribuyente' style={{display:'flex', flexDirection:'column',padding:0, marginTop:3, marginLeft: 5}}>
                {/* <Typography style={{fontWeight:'bolder'}}>
                    {translate('AbonoNacimiento',tipoSolicitud === 'anticipado' ? 'panel_contribuyente' :'panel_principal',terms).toUpperCase()}
                </Typography> */}
                {renderItemFieldResumen(translate('GenericComponents', 'nif', terms), datos.nif)}
                {renderItemFieldResumen(translate('GenericComponents', 'nomApe', terms), datos.nombre +' '+datos.apellidos)}
                {/* {renderItemField(translate('GenericComponents', 'apellidos', terms), datos.apellidos)} */}
                {renderItemFieldResumen(translate('GenericComponents', 'telefono', terms), datos.telefono)}
                {renderItemFieldResumen(translate('GenericComponents', 'email', terms), datos.email)}
                {renderItemFieldResumen(
                    translate('AbonoNacimiento','ABO_NAC_parentesco',terms),
                    translate('AbonoNacimiento', datos.parentesco? `ABO_NAC_parentesco_${datos.parentesco}`: '', terms)
                )}
            </div>
    )
};

export const resumenDeduccion = (datos: TDeduccion, terms: any) => {
    return (
        <div key ='deduccion' style={{display:'flex', flexDirection:'column',padding:0, marginTop:3, marginLeft: 5}}>
               
            {renderItemFieldResumen(translate('GenericComponents', 'nombre', terms), datos.nombre)}
            {renderItemFieldResumen(translate('GenericComponents', 'apellidos', terms), datos.apellidos)}
                    
            {renderItemFieldResumen(translate('AbonoNacimiento', 'fechaNacimiento', terms),
                datos.fechaNacimiento ? moment(datos.fechaNacimiento).format('DD/MM/YYYY'): '')
            }
            {
                renderItemFieldResumen(
                    translate('AbonoNacimiento', 'lugarNacimiento', terms),
                    [datos.paisNombre, datos.provinciaNombre, datos.municipioNombre].join(' ')
                )
            }              
        </div>  
    )
}
export const resumenAbono = (datos: TIdentificacionAbono, terms:any) => {
    return (
        <div key ='deduccion' style={{display:'flex', flexDirection:'column',padding:0, marginTop:3, marginLeft: 5}}>
            {renderItemField(translate('AbonoNacimiento', 'fechaSolicitud', terms), 
                datos.fechaSolicitud ? moment(datos.fechaSolicitud).format('DD/MM/YYYY'): '')
            }
            {renderItemField(translate('AbonoNacimiento', 'importe',terms), datos.importePercibido ? formatNumStr(datos.importePercibido )+ '€': undefined)}
            {renderItemField(translate('AbonoNacimiento', 'refSolicitud',terms), datos.ejercicio+'/'+datos.numSolicitud)}
        </div> 
    )    
}