import React, { FC, useEffect, useState, useContext, useMemo, Fragment } from "react";
import usePage from "hooks/page.hook";
import { RouteComponentProps } from "react-router";
import IoC from "contexts/ioc.context";
import { ContenidosGateway } from "gateways/contenido.gateway";
import { CardContent, Card } from "@material-ui/core";

interface IParams {
    landingName: string;
}

type Props = RouteComponentProps<IParams>;

const Contenidos: FC<Props> = ({ match }) => {
    const [state] = usePage();
    const [, pageDispatcher] = usePage();
    const [title, SetTitle] = useState('');
    const [body, SetBody] = useState('');

    // sevices
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(ContenidosGateway) as ContenidosGateway, [ioc]);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                title,
                icon: null,
            },
            menu: true,
        });
    }, [pageDispatcher, title]);

    useEffect(() => {
        const handleGetLanding = async (landingName: string, idioma: string) => {
            const landing = await gateway.getContent(landingName, idioma);
            const bodyAux = landing.find((x: { campo: string; contenido: string }) => {
                return x.campo === 'body';
            })
            const titleAux = landing.find((x: { campo: string; contenido: string }) => {
                return x.campo === 'title';
            })
            if (titleAux !== undefined || titleAux != null) {
                SetTitle(titleAux.contenido);
            }
            if (bodyAux !== undefined || bodyAux != null) {
                SetBody(bodyAux.contenido);
            }
        }
        handleGetLanding(match.params.landingName, state.lang);
    }, [state.lang, gateway, match.params.landingName]);

    return (
        <Fragment>
            <Card style={{ margin: '0px 15px 0px 15px', textAlign: 'justify' }}>
                <CardContent>
                    <div dangerouslySetInnerHTML={{ __html: body }} />
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default Contenidos;