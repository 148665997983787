import { Typography, Button, DialogTitle, DialogActions, useMediaQuery, useTheme, DialogContent, WithStyles, withStyles, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { FC, Fragment, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { INotificacion } from 'gateways/notificaciones.interfaces';
import Term from 'components/term';
import styles from '../shared/notificaciones.styles';
import { AlertsContext } from 'contexts/alerts.context';
import IoC from 'contexts/ioc.context';
import { NotificacionesGateway } from 'gateways/notificaciones.gateway';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import { download } from 'utils/download';
import { ISignature } from 'gateways/afirma.interfaces';
import { getBrowser, getPlatform, getUserAgent } from 'utils/client';
import { AuthGateway } from 'gateways/auth.gateway';
import { IUserInfo } from 'gateways/auth.interfaces';

interface IProps extends WithStyles<typeof styles> {
    open: boolean;
    notificacion: INotificacion;
    notificacionRef: any;
    onClose: (isSigned?: boolean) => void;
}

const FirmaNotificacion: FC<IProps> = ({ open, notificacion, notificacionRef, onClose, classes }) => {
    // Global states
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);

    // gateways
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(NotificacionesGateway) as NotificacionesGateway, [ioc]);
    const authGateway: AuthGateway = ioc.get(AuthGateway);
    // const afirmaGateway = useMemo(() => ioc.get(AfirmaGateway) as AfirmaGateway, [ioc]);        

    // services
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // local states
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [referencia, setReferencia] = useState<string>('');
    const [usuario, setUsuario] = useState<IUserInfo>();
    const [state, setState] = useState<INotificacion>();

    // methods
    const sign = async () => {
        const signature: ISignature = {
            browser: getBrowser(),
            platform: getPlatform(),
            origen: 'Nueva SEDE',
            textoSinFirmar: 'Se ha aceptado el documento adjunto en el Mensaje ' + notificacion.idMensaje,
            textoFirmado: '',
            userAgent: getUserAgent(),
            idMensaje: notificacion.idMensaje
        };

        const resultado = await gateway.firmaDocumentosMensaje(signature);
        // console.log('res ', resultado)
        if (resultado) {
            if(notificacion.tipoEnvio === 'C'){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Notificaciones', 'El proceso de firma del documento ha ido bien', terms),
                        variant: 'info',
                    }
                });
                setLoadingButton(false);
                notificacion.fechaNotificacion = new Date();
                notificacion.leido = true;
                setState(notificacion);
                onClose();
            } else {
                const now = new Date();
                const idDocumento = await gateway.generarDocumentoAcuse(signature);
                if (idDocumento > 0) {
                    notificacion.fechaNotificacion = now;
                    notificacion.leido = true;
                    setState(notificacion);
                    downloadDocument(idDocumento);
                    onClose(true);
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Notificaciones', 'Error en el proceso de firma del documento', terms),
                            variant: 'error',
                        }
                    });
                    setLoadingButton(false);
                }
            }
            
        } else {
            setLoadingButton(false);
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Notificaciones', 'Error en el proceso de firma del documento', terms),
                    variant: 'error',
                }
            });
        }
    }

    // const sign = async (idDocumento: number) => {        
    //     const idOrden = await gateway.iniciarFirmaDocumento(idDocumento);

    //     if (idOrden > 0) {
    //         const afirma = new Afirma(idOrden, afirmaGateway, 'notificaciones', showResult);

    //         afirma.processSignature();
    //     } else {
    //         setLoadingButton(false);
    //         alertsDispatch({
    //             type: 'show-alert',
    //             payload: {
    //                 message: translate('Notificaciones', 'Error en el proceso de firma del documento', terms),
    //                 variant: 'error',
    //             }
    //         });            
    //     }
    // }

    // const showResult = (idFirma: number) => {
    //     if (idFirma > 0) {
    //         gateway.getDocumentoNotificacion(idFirma).then(documento => {
    //             if (documento) {
    //                 gateway.registerSignature(idFirma, documento).then(result => {
    //                     setLoadingButton(false);        
    //                     if (result) {
    //                         const now = new Date();
    //                         notificacion.fechaNotificacion = now;
    //                         notificacion.leido = true;
    //                         setState(notificacion);
    //                         downloadDocument(documento.idDocumento);
    //                         onClose();
    //                     } else {
    //                         alertsDispatch({
    //                             type: 'show-alert',
    //                             payload: {
    //                                 message: translate('Notificaciones', 'Error en el proceso de firma del documento', terms),
    //                                 variant: 'error',
    //                             }
    //                         });
    //                     }
    //                 });
    //             } else {
    //                 alertsDispatch({
    //                     type: 'show-alert',
    //                     payload: {
    //                         message: translate('Notificaciones', 'Error en el proceso de firma del documento', terms),
    //                         variant: 'error',
    //                     }
    //                 });
    //             }
    //         });            
    //     } else {
    //         setLoadingButton(false);  
    //         alertsDispatch({
    //             type: 'show-alert',
    //             payload: {
    //                 message: translate('Notificaciones', 'Error en el proceso de firma del documento', terms),
    //                 variant: 'warning',
    //             }
    //         });
    //     }        
    // }

    const downloadDocument = async (idDocumento: number) => {
        setLoadingButton(true);
        const result = await gateway.getDocumento(idDocumento);

        download(result, alertsDispatch,
            translate('Global', 'No es posible descargar el documento', terms),
            translate('Global', 'BloqueoPantalla', terms));

        setLoadingButton(false);
    }

    const getInfoUsuario = useCallback(async (idMensaje: number) => {
        setLoading(true);
        setReferencia(await gateway.getReferenciaNotificacionDocumento(idMensaje));
        setUsuario(await authGateway.getInfoUsuario());
        setLoading(false);
    }, [gateway, authGateway]);

    // handles
    const handleClose = () => onClose();

    const handleSign = async () => {
        setLoadingButton(true);
        await sign();
    }

    // effects
    useEffect(() => {
        notificacionRef.current = state;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        if (open) {
            getInfoUsuario(notificacion.idMensaje);
        }
    }, [notificacion, open, getInfoUsuario]);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            maxWidth={"md"}
            fullWidth={true}
        >
            <DialogTitle>
                <Term component="Notificaciones" text={notificacion.tipoEnvio === 'C' 
                ? "Firma comunicación electronica"
                : "Firma notificacion electronica"} />
            </DialogTitle>
            <DialogContent>
                {
                    loading || !notificacion
                        ? <div className={classes.centerContent}>
                            <CircularProgress size={75} />
                        </div>
                        :
                        <Fragment>
                            <div className={classes.marginDetail25}>
                                <Typography component="p">
                                    <b><Term component="Notificaciones" text={notificacion.tipoEnvio === 'C'
                                    ?"Datos de la comunicacion"
                                    :"Datos de la notificacion"
                                } /></b>
                                </Typography>
                                <Typography component="p">
                                    <Term component="Notificaciones" text="Referencia del expediente" />&nbsp;{referencia ? referencia : ''}
                                </Typography>
                            </div>
                            <div className={classes.marginDetail25}>
                                <Typography component="p">
                                    <b><Term component="Notificaciones" text="Datos del usuario" /></b>
                                </Typography>
                                <Typography component="p">
                                    {usuario ? usuario.nombre : ''}&nbsp;-&nbsp;{usuario ? usuario.nifResponsable : ''}
                                </Typography>
                            </div>
                            <div className={classes.marginDetail25}>
                                <Typography component="p">
                                    <b><Term component="Notificaciones" text="Resumen del certificado" /></b>
                                </Typography>
                                <Typography component="p">
                                    {usuario ? usuario.idEmisor : ''}
                                </Typography>
                                <Typography component="p">
                                    {usuario ? usuario.subject : ''}
                                </Typography>
                                <Typography component="p">
                                    {usuario ? usuario.numeroSerie : ''}
                                </Typography>
                                <Typography component="p">
                                    {usuario ? usuario.validoHasta : ''}
                                </Typography>
                            </div>
                        </Fragment>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default" disabled={loadingButton}>
                    <Term component="Global" text="Cerrar" />
                </Button>
                <Button onClick={handleSign} color="primary" disabled={loadingButton}>
                    <Term component="Global" text="Firmar" />
                    {
                        loadingButton ?
                            <CircularProgress size={24} className={classes.buttonProgress} />
                            :
                            null
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default withStyles(styles)(FirmaNotificacion);