// LIBRARY IMPORTS
import { useState, useEffect, useContext, FC, useMemo, useCallback, useReducer } from 'react';
import { withStyles, WithStyles, CircularProgress, Card, CardContent } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';

// LOCLA IMPORTS
import { TributosAutonomicosGateway } from 'gateways/tributos.autonomicos.gateway';
import { ITributoAutonomico } from 'gateways/tributos.interfaces';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import usePage from '../../hooks/page.hook';
import IoC from 'contexts/ioc.context';
import NoContent from 'components/no-content';
import image from '../../resources/no-deuda.png';
import FiltersReducer, { IFiltersState, QueryRequest } from './filters.reducer';
import Term from 'components/term';
import TributoCard from './tributo.card';
import ReciboCard from './recibo.card';

// STYLES
import styles from '../shared/tributos.styles';

const numberElementsPerPage = 50;

export interface IProps extends WithStyles<typeof styles> {
    loading: boolean;
    tributosIni: ITributoAutonomico[];
    tributosCountIni: number;
}

const TributosAutonomicos: FC<IProps> = (props) => {
    const { classes, loading, tributosIni, tributosCountIni } = props;

    // HOOKS
    const [, pageDispatcher] = usePage();

    // GATEWAYS
    const ioc = useContext(IoC);
    const tributosGateway = useMemo(() => ioc.get(TributosAutonomicosGateway) as TributosAutonomicosGateway, [ioc]);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);

    // REDUCERS
    const filtersReducer = useReducer(FiltersReducer, {});
    const [filters,] = filtersReducer;

    // STATES
    const [page, setPage] = useState(0);
    const [loadingTributos, setLoadingTributos] = useState(false);
    const [tributosCount, setTributosCount] = useState(0);
    const [tributos, setTributos] = useState<ITributoAutonomico[]>([]);
    const [bodyAvisoPagoCartaPalma, setBodyAvisoPagoCartaPalma] = useState('');

    // MEMOS
    const hasMore = useMemo(() => tributosCount > (page === 0 ? (page + 1) : page) * numberElementsPerPage, [page, tributosCount]);
    const noContent = useMemo(() => !loadingTributos && tributos.length === 0, [loadingTributos, tributos]);



    // QUERY BUILDER

    const buildQueryRequest = useCallback((newFilters: IFiltersState, newPage: number) => {
        return Object.assign({}, newFilters, {
            skip: newPage * numberElementsPerPage,
            take: numberElementsPerPage
        }) as QueryRequest;
    }, []);



    // LOADERS

    const onLoadAvisoPagoCartaPalma = async () => {
        const bodyAux = await contenidoGateway.getContent(
            "AvisoPagoCartaPalma",
            {}
        );
        setBodyAvisoPagoCartaPalma(bodyAux[0].contenido)
    }

    // HANDLERS

    const handleLoadTributos = useCallback(async (newFilters: IFiltersState, newPage: number) => {
        const query = buildQueryRequest(newFilters, newPage);

        setLoadingTributos(true);

        try {
            const tributosReceived = await tributosGateway.findAll(query);
            if (tributosReceived) {
                setTributos(newPage === 0 ? tributosReceived : tributos.concat(tributosReceived));
            }
        } catch (error) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        }
        finally {
            setLoadingTributos(false);
        }
    }, [tributosGateway, tributos, buildQueryRequest, pageDispatcher]);

    const handleLoadMoreTributos = useCallback(() => {
        if (hasMore && !loadingTributos) {
            setPage(page + 1);
            handleLoadTributos(filters, page + 1);
        }
    }, [hasMore, loadingTributos, page, handleLoadTributos, filters]);



    // USE EFFECT

    useEffect(() => {
        setTributosCount(tributosCountIni);
    }, [tributosCountIni]);

    useEffect(() => {
        setTributos(tributosIni);
    }, [tributosIni]);

    useEffect(() => {
        setLoadingTributos(loading);
    }, [loading]);

    useEffect(() => {
        (async () => {
            await onLoadAvisoPagoCartaPalma();
        })();
    }, []);

    return (

        <div>
            <NoContent
                image={image}
                visible={noContent}
                component='CarteroVirtual'
                text='no_tributos'
            />
            {
                tributosCount > 0
                    ?
                    <>
                        <Card className={classes.cardContador}>
                            <CardContent className={classes.cardContadorContainer} >
                                <span className={classes.titleCardContador}>
                                    <Term component="CarteroVirtual" text='TituloTributosAutonomicos' params={[tributosCount]} />
                                </span>
                            </CardContent>
                        </Card>

                        <InfiniteScroll
                            pageStart={0}
                            initialLoad={false}
                            loadMore={handleLoadMoreTributos}
                            hasMore={hasMore}
                            loader={<div key="infiniteProgress" style={{ display: hasMore ? 'block' : 'none' }} className={classes.centerContent}><CircularProgress className={classes.progress} /></div>}
                        >
                            {tributos.map((tributo: ITributoAutonomico, index: number) => (
                                tributo.id > 0 ?
                                    // TODO: revisar fpago
                                    <ReciboCard
                                        key={'Tributo_' + index}
                                        recibo={tributo}
                                        selectable={false}
                                        mensajeAclaratorio=''
                                        isFromConsultasExternas={false}
                                        bodyAvisoPagoCartaPalma={bodyAvisoPagoCartaPalma}
                                    />
                                    :
                                    <TributoCard
                                        key={'Tributo_' + index}
                                        tributo={tributo}
                                    />
                            ))}
                        </InfiniteScroll>
                    </>
                    :
                    noContent ?
                        null
                        :
                        <div key="progress" className={classes.centerContent}>
                            <CircularProgress className={classes.progress} />
                        </div>
            }
        </div>
    );
}

export default withStyles(styles)(TributosAutonomicos);