import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Button, Tooltip, Typography, makeStyles } from "@material-ui/core";
import usePage from "hooks/page.hook";
import { TipoAsistente, TipoTributo, handleCopyRef } from "./utils";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { ModelosActionContext } from "contexts/modelos-action.context";
import { AlertsContext } from "contexts/alerts.context";

import styles from "./tributos-autonomicos.styles";
import IoC from "contexts/ioc.context";
import ModelGateway from "../../gateways/model.new.gateway";
import { SujetosGateway } from "../../gateways/sujetos.gateway";
import { IAsistenteStep, ITipoModelo } from "gateways/model.new.interface";
import { ISujeto } from "gateways/perfil.interfaces";
import StepperModelosAsistente, { IStepExtended,  TStepsContentAsistente } from "components/Modelos/stepper-modelos-asistente";
import FullLoading from "components/full-loading";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import { cloneDeep } from "lodash";
import { mdiAlphaACircleOutline, mdiAlphaLCircleOutline, mdiFileDocumentMultipleOutline } from "@mdi/js";
import Term from "components/term";
import Icon from "@mdi/react";
import { TDatosAsistente } from "./componentesAsistente/shared/types";
import { TDatosAsistentePLV, TPreguntasPlusvalia } from "./componentesAsistente/plusvalias/types";



export interface IModeloAsistenteParams {
    textTipoTributo: TipoTributo;
    tipoAsistente: TipoAsistente; 
    action: "nuevo" | string;
}

const useStyles = makeStyles(styles);

type Props = RouteComponentProps<IModeloAsistenteParams>;
  
const ModelosTributosAsistente: FC<Props> = ({ match, history }) => {

    const classes = useStyles();
    const [pageState, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //States - Context
    const [modelosAction, modelosActionDispatcher] =
      useContext(ModelosActionContext);
    //Gatways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(
      () => ioc.get(ModelGateway),
      [ioc]
    );
    // Apartados con info - content es mas_info_AparatdoDelModelo. EJ: mas_info_810D
    //Local
    const [loading, setLoading]= useState(false)
    const [ready, setReady] = useState(false)
    const [tipoModeloInfo, setTipoModeloInfo] = useState<ITipoModelo | null>(null);
    const [sujeto, setSujeto] = useState<ISujeto | null>(null);
    const [idReferencia, setIdReferencia] = useState<string | null>(null);
    const [modelo, setModelo] = useState<string | null>(null);


    //Stepper
    const [steps, setSteps] = useState<IStepExtended[]>([]);
    const [stepsContent, setStepsContent] = useState<TStepsContentAsistente[]>([]);
    // const [xmlPrevios, setXmlPrevios] = useState<GenericObject>({});
    // const [stepFinish, setStepFinish] = useState<Record<string, boolean>>({});
    const [activeStep, setActiveStep] = React.useState(0);


    const getModelo = async(ref:string) => {
      let tipoAsistente: string|null;
      switch (match.params.tipoAsistente) {
        case 'plusvalia': 
          tipoAsistente= 'PLV'; 
          break;
        default:
          tipoAsistente= null; 
          break;
        } 
        
        const obj= tipoAsistente!== null ? await modelGateway.getAllApartadosModeloAsistente(tipoAsistente,ref): null;
        setModelo(obj?.preguntasPlusvalia?.tipoPlusvaliaModelo?.id ?? null)
    }

    /**Gestion stepper ************************************************************ */
    const handleBackStep = () => {
        if(activeStep>0){
            const next = activeStep-1;
            const newSteps = cloneDeep(steps)
            // Actual: desactivar step y modificar estado => null si su estado previo era INI (aun no guardado) y EDT si estaba finalizado o ya estaba EDT 
            newSteps[activeStep]={
                ...steps[activeStep], 
                active:false, 
                status: (steps[activeStep].status === 'INI' || steps[activeStep].status === null )
                    ? null
                    : ['EDT','FIN'].includes(steps[activeStep].status ?? '') ? 'EDT' : null 
            }
            // Siguiente IR a ANTERIOR: activar es step e indicar que pasa a EDT 
            newSteps[next]={
                ...steps[next], 
                active: true, 
                status:  'EDT'
            } 
            // Actualizar steps
            setActiveStep(next)
            setSteps(newSteps)
        }
    }

    const handleNextStep = (idRef?: string) => {
        if(idRef){
          setIdReferencia(idRef)
          getModelo(idRef) // Gestion TITLE - pageDispatcher
        }
        if(idReferencia && activeStep == 0)getModelo(idReferencia) //// Gestion TITLE - pageDispatcher
        console.log('handleNextStep activeStep', activeStep)
        if(activeStep<steps.length){
            const next = activeStep+1;
            const newSteps = cloneDeep(steps)
            // Actual: desactivar step y modificar estado => null si su estado previo era INI (aun no guardado) y EDT si estaba finalizado o ya estaba EDT 
            newSteps[activeStep]={
                ...steps[activeStep], 
                active: false, 
                status: 'FIN'
            }
            // Siguiente IR a SIGUIENTE: activar es step e indicar que pasa a INI si aun noha sido iniciado o EDT en caso de que estubiera a FIN
            newSteps[next] = {
                ...steps[next], 
                active: true, 
                status: ['EDT','FIN'].includes(steps[activeStep].status ?? '')? 'EDT' : 'INI'
            } 
            // Actualizar steps
            setActiveStep(next)
            setSteps(newSteps) 
        } 
    }

    const handleFinishProcess = () => {

    }

    const handleRedirectListado = () => {
      history.push(`/tributos/tributos-${match.params.textTipoTributo}`)
    }

/* **************************************************************************** */
    const getExtended = (stepsList: IAsistenteStep[]) => {
        /**Ampliar información con ACTIVE y STATUS */
        const stpWithDatos = stepsList.filter(s => s.datos !== null)
        const stpActive = stpWithDatos && stpWithDatos.length>0 ? stpWithDatos[stpWithDatos.length-1] : null // Ultimo STEP elemento con datos XML
        const stepsExtend: IStepExtended[] = stepsList.map( (stp, idx) => ({
            ...stp, 
            active: stpActive ? stp.idTipoApartado === stpActive.idTipoApartado : idx===0,
            status:  
                stpActive ?
                    stpActive.orden < stp.orden //Pasos anteriores (FIN)
                        ? 'FIN' 
                        : stpActive.orden > stp.orden // Pasos posteriores (NULL)
                            ? null 
                            : 'EDT' // En caso de ser igual - Hay datos y estamos mostrando este como estado EDICIÓN
                :   idx===0 ? 'INI': null
        }))
        return stepsExtend
    }

    const init = async () => {
        try {
            setLoading(true);

            let idRef = "";
            if (match.params.action !== "nuevo") {
              idRef = match.params.action;
              await getModelo(idRef) // Gestion TITLE - pageDispatcher
            }
            setIdReferencia(idRef);

            /**Recuperar pasos del asistente */
            let steps: IAsistenteStep[] =[]
            switch (match.params.tipoAsistente) {
                case 'plusvalia':
                    steps = await modelGateway.getModeloAsistenteSteps('PLV', idRef, translate("Tributos", "GetModeloStepsError", terms));
                    break;
                default:
                    break;
            }

            /**Ampliar información con ACTIVE y STATUS */
            const stepsExtend = getExtended(steps)

            const activeIdx = stepsExtend.findIndex(s => s.active === true)
            //console.log('steps <<<<<<<<< ', stepsExtend)
            setSteps(stepsExtend);
            setActiveStep(activeIdx);
            
        } catch (error) {
            //console.log(' ERROR - UseEffect modelos-asistente ---> ', error)
            const result = (error as Error).message;
            setLoading(false);
            alertsDispatch({
              type: "show-alert",
              payload: {
                message: result,
                variant: "error",
              },
            });
        } finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        pageDispatcher({
          type: "setHeader",
          header: {
            icon: match.params.textTipoTributo === 'autonomicos'? mdiAlphaACircleOutline: mdiAlphaLCircleOutline,
            title: modelo === null  // Gestion TITLE - pageDispatcher
              ? `${translate("Plusvalias", "Asistente", terms)}: ${translate("Plusvalias", match.params.tipoAsistente, terms)}`
              : `${translate("Tributos", "Modelo", terms)} ${modelo}: ${translate("Modelos", modelo , terms)}`,
            text: (
              <div>
                {idReferencia ? (
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip title={translate("Tributos", "copyRef", terms)}>
                      <Button
                        onClick={() => handleCopyRef(idReferencia)}
                        className={classes.buttonCopyRef}
                      >
                        <Icon
                          path={mdiFileDocumentMultipleOutline}
                          size={1}
                          className={classes.iconCopy}
                        ></Icon>
                      </Button>
                    </Tooltip>
                    <Typography variant="body1">
                      <Term text="Referencia" />
                    </Typography>
                    <Typography variant="body1" style={{ marginRight: 5 }}>
                      :
                    </Typography>
                    <span>{idReferencia}</span>
                  </div>
                ) : null}
              </div>
            ),
            moreInfoTemplate: 'mas_info_page_asistente_plusvalias',
            moreInfoTemplateSize: 'lg'
          },
          menu: true,
        });
      }, [pageDispatcher, idReferencia, match.params.tipoAsistente, modelo]);
   
    useEffect(() => {
        (async () => {
            await init()
        })();
    }, [match.params.tipoAsistente, match.params.action]);
    

    return (
        <div style={{ display: 'flex', flexGrow: 1, maxWidth: '99%' }} >
            <FullLoading loading={loading}/>
            {steps && steps.length >0 && (
                <StepperModelosAsistente
                    idRef={idReferencia}
                    tipoAsistente={match.params.tipoAsistente}
                    steps={steps}
                    activeStep={activeStep}
                    handleNext={handleNextStep}
                    handlePrev={handleBackStep}
                    handleFinish={handleFinishProcess}
                    handleExit={handleRedirectListado}
                />
            )}
        </div>

    )
}

export default withLiterals(["Global","Plusvalias","Tributos", "Modelos"])(ModelosTributosAsistente);
  