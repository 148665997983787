import React, { FC } from 'react';
import { Dialog, Slide, DialogContent, DialogActions, Button, makeStyles } from "@material-ui/core";
import PagoTable from './pago.table';
import { IModelGateway } from 'gateways/modelGateway.interface';
import Term from 'components/term';

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        paddingRight: 0,
    },
    title: {
        textAlign: 'center'
    },
    main: {
        padding: 20
    },
}));

interface IProps {
    modelos: IModelGateway[];
    open: boolean;
    onClose: () => void;
}

const DeudaDialog: FC<IProps> = ({  modelos, open, onClose }) => {
    const classes = useStyles();
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <DialogContent>
                <div className={classes.main}>
                    <PagoTable modelos={modelos} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="default">
                    <Term component="Global" text="Cerrar" />
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default DeudaDialog;