import moment from "moment";
import { formatNumStr } from "containers/DUTI/utils";
import { TData } from "containers/tramites/simulador-plusvalias/types";
import { TBorradorSimuladorModelo081 } from "./modelo_081_simulador";


export const extraerBorradorM081Simulador = (datos: TData) => {
    const totalIngresarFinal = datos.calculos 
        ? datos.calculos.map(item => item.modelo?.totalIngresar ?? 0).reduce((a,b) => (a+b), 0)
        : 0;


    const Apartados = ['M081_D_PLUSV','M08X_E_OP','M081_E']
    const infoApartados = Apartados.map((aprt) => {
    switch (aprt) {
        case "M081_D_PLUSV":
            return({
                MXX_D_PLUSV: [ 
                    {term: 'MXX_municipio', value: datos.municipio ? datos.municipio?.nombre : ""  ,casilla:null }, //62 },
                    {term: 'MXX_refCatastral', value: datos.inmueble ? datos.inmueble.refCatastral : "" ,casilla: null }, //65},
                    {term: 'M08x_tipoTransmision', value: datos.tipoTransmision.tipo ? datos.tipoTransmision.tipo.nombre: ""  ,casilla:null }, //63 },
                    {term: 'MXX_bonificacion', value: datos.tipoTransmision.bonificacion ? datos.tipoTransmision.bonificacion.valor+'%': ""  ,casilla:null }, //63 },
                    {term: 'M08x_fechaDevengo', value:  datos.fechaTransmision? moment(datos.fechaTransmision).format('DD/MM/YYYY') :"",casilla: null }, //67 },
                ]
            })

        case "M08X_E_OP":
            return({
                M08X_E_OP: 
                    datos.calculos.map(calculo =>{ 
                        return ({
                            calculo: [
                                {term: 'M08x_tipoDerecho', value: calculo.tipoDerecho?.nombre ?? "" ,casilla: null },
                                {term: 'M08x_edadUsufructuaria', value: calculo.edadUsufructuaria ?? "",casilla: null }, 
                                {term: 'M08x_numOperaciones', value: calculo.numOperaciones ?? "",casilla: null },
                                {term: 'M08x_porcentajeTitularidad', value: calculo.porcentaje ? calculo.porcentaje+'%': "",casilla: null },
                            ],
                            desglose: calculo.modelo && calculo.modelo.adquisiciones 
                                ? calculo.modelo.adquisiciones.map(op => { 
                                    return ({
                                        operacion: [
                                            {term: 'M08x_fechaAnteriorAdquisicion', value: op.metodoObjetivo.fechaAdquisicion ? moment(op.metodoObjetivo.fechaAdquisicion).format('DD/MM/YYYY') : "", casilla: null},
                                            {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.metodoObjetivo.porcentaje +"%", casilla: null},
                                            {term: 'M08x_importeAnteriorAdquisicion', value: op.metodoObjetivo.importeAnteriorAdquisicion ? formatNumStr(Number(op.metodoObjetivo.importeAnteriorAdquisicion ))+"€" : "", casilla: null},
                                            
                                        ],
                                        objetivo:[
                                            // {term: 'M08x_fechaAnteriorAdquisicion', value: op.metodoObjetivo.fechaAdquisicion ? moment(op.metodoObjetivo.fechaAdquisicion).format('DD/MM/YYYY') : "", casilla: null},
                                            // {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.metodoObjetivo.porcentaje +"%", casilla: null},
                                            {term: 'M08x_valorParcial', value: op.metodoObjetivo.valorParcial ? formatNumStr(Number(op.metodoObjetivo.valorParcial))+"€" : "", casilla: null},
                                            {term: 'M08x_anyosTenencia', value: op.metodoObjetivo.anyosTenencia ?? "", casilla: null},
                                            {term: 'baseImponibleParcial', value: op.metodoObjetivo.baseImponible ? formatNumStr(Number(op.metodoObjetivo.baseImponible))+"€" : "", casilla: null},
                                            {term: 'coeficienteAplicado', value: op.metodoObjetivo.tipoAnual  ? op.metodoObjetivo.tipoAnual +"%" : "", casilla: null},
                                            {term: 'MXX_tipoGravamen', value: op.metodoObjetivo.tipoGravamen ? op.metodoObjetivo.tipoGravamen+"%" : "", casilla: null},
                                            {term: 'MXX_cuotaIntegraParcial', value: op.metodoObjetivo.cuotaIntegra ? formatNumStr(Number(op.metodoObjetivo.cuotaIntegra))+"€"  : "", casilla: null},
                                        ],
                                        real: op.metodoReal 
                                            ? [
                                                // {term: 'M08x_fechaAnteriorAdquisicion', value: op.metodoReal.fechaAdquisicion ? moment(op.metodoReal.fechaAdquisicion).format('DD/MM/YYYY') : "", casilla: null},
                                                // {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.metodoReal.porcentaje +"%", casilla: null},
                                                {term: 'M08x_valorParcial', value: op.metodoReal.valorParcial ? formatNumStr(Number(op.metodoReal.valorParcial))+"€" : "", casilla: null},
                                                {term: 'M08x_anyosTenencia', value: op.metodoReal.anyosTenencia ?? "", casilla: null},
                                                {term: 'baseImponibleParcial', value: op.metodoReal.baseImponible ? formatNumStr(Number(op.metodoReal.baseImponible))+"€" : "", casilla: null},
                                                {term: 'coeficienteAplicado', value: op.metodoReal.tipoAnual  ? op.metodoReal.tipoAnual +"%" : "", casilla: null},
                                                {term: 'MXX_tipoGravamen', value: op.metodoReal.tipoGravamen ? op.metodoReal.tipoGravamen+"%" : "", casilla: null},
                                                {term: 'MXX_cuotaIntegraParcial', value: op.metodoReal.cuotaIntegra ? formatNumStr(Number(op.metodoReal.cuotaIntegra))+"€"  : "", casilla: null},
                                            ]
                                            : []
                                    })
                                })
                                : calculo.operaciones.map(op => ([
                                    {term: 'M08x_fechaAnteriorAdquisicion', value: op.fechaAnteriorAdquisicion ? moment(op.fechaAnteriorAdquisicion).format('DD/MM/YYYY') : "", casilla: null},
                                    {term: 'M08x_porcentajeAnteriorAdquisicion', value: op.porcentajeAnteriorAdquisicion +"%", casilla: null},
                                    {term: 'M08x_importeAnteriorAdquisicion', value: op.importeAnteriorAdquisicion ? formatNumStr(op.importeAnteriorAdquisicion)+"€" : "", casilla: null},
                                ])),
                            liquidacion:  calculo.modelo  ? [
                                {term: 'importeMetodoObjetivo', value:  calculo.modelo?.importeMetodoObjetivo ? formatNumStr(calculo.modelo.importeMetodoObjetivo)+"€": "", casilla: null },
                                {term: 'importeMetodoReal', value:  calculo.modelo?.importeMetodoReal ? formatNumStr(calculo.modelo.importeMetodoReal)+"€": "", casilla: null },
                                {term: 'MXX_tipoGravamen', value: calculo.modelo?.tipoGravamen ? calculo.modelo.tipoGravamen+"%": "", casilla: null }, //82 },
                                {term: 'MXX_bonificacion', value:calculo.modelo?.bonificacion? calculo.modelo.bonificacion+"€" :"", casilla: null }, //85 },
                                {term: 'MXX_cuotaIntegra', value: calculo.modelo?.cuotaIntegra ? formatNumStr(calculo.modelo.cuotaIntegra) +"€" :"", casilla: null }, //84 },
                                {term: 'baseImponible', value: calculo.modelo.baseImponible? formatNumStr(calculo.modelo.baseImponible)+"€" : "", casilla: null }, //80 },
                                {term: 'MXX_cuotaLiq', value: calculo.modelo?.cuotaLiquida ? formatNumStr(calculo.modelo.cuotaLiquida)+"€" :"", casilla: null }, //86 },
                                {term: 'MXX_recargo', value: calculo.modelo?.recargo ? calculo.modelo.recargo+"€" :"", casilla: null }, //87 },
                                {term: 'MXX_interesesDemora', value: calculo.modelo?.interesesDemora ? calculo.modelo.interesesDemora+"%":"", casilla: null }, //88 },
                                {term: 'importeAdquisicion081', value: calculo.modelo?.importeAdquisicion ? formatNumStr(calculo.modelo.importeAdquisicion)+"€" :"", casilla: null }, //91 },
                                {term: 'importeTransmision081', value: calculo.modelo?.importeTransmision ? formatNumStr(calculo.modelo.importeTransmision)+"€":"", casilla: null }, //92 },
                                {term: 'MXX_totalIngresar', value:  calculo.modelo?.totalIngresar ? formatNumStr(calculo.modelo?.totalIngresar)+"€": "", casilla: null }, //61 },

                            ]: []
                        })
                    })
            
                    
                })
        case "M081_E":
            return({
                M081_E: [
                    {term: 'MXX_totalIngresar', value:  totalIngresarFinal ? formatNumStr(totalIngresarFinal)+"€": "", casilla: null },
                ]
            })
        default:
            break;
        }
    })
    
    const out= infoApartados.reduce( (accum,curr) => (Object.assign(accum,curr)),{})
      
    // console.log(out)
    return out as TBorradorSimuladorModelo081;
  }
  
 