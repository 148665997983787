// Importa las bibliotecas necesarias
import React, { FC, useContext, useRef } from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";

interface IProps { 
  open: boolean, onClose: () => void 
  componentIG: string
  titleTerm: string,
  render: ()=> JSX.Element 
}
const GenericModalPDF : FC<IProps>= ({ open, onClose,componentIG, titleTerm, render }) => {
  // Utiliza un `ref` para capturar el contenido del modal
  const modalContentRef = useRef<HTMLDivElement | null>(null);

  const terms = useContext(LiteralsContext);

  const downloadPdf = async () => {
    // if (modalContentRef.current) {
    //   // Captura el contenido visible del modal como imagen
    //   const canvas = await html2canvas(modalContentRef.current);
    //   const imgData = canvas.toDataURL("image/png");

    //   // Crea un documento PDF
    //   const pdf = new jsPDF();
    //   const imgWidth = pdf.internal.pageSize.getWidth();
    //   const imgHeight = (canvas.height * imgWidth) / canvas.width;

    //   // Añade la imagen al documento PDF y guarda el archivo
    //   pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    //   pdf.save("simulacion-plusvalia.pdf");
    // }
    if (modalContentRef.current) {
      // Asegura que todo el contenido esté visible
      modalContentRef.current.style.overflow = 'visible';

      // Captura el contenido completo
      const canvas = await html2canvas(modalContentRef.current, {
        scrollY: -window.scrollY,
        useCORS: true,
        windowWidth: document.documentElement.offsetWidth,
        backgroundColor:'white'
      });

      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // Anchura en mm (A4)
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const pdf = new jsPDF('p', 'mm', 'a4');
      let yPosition = 0;
      let pageHeight = pdf.internal.pageSize.height;

      while (yPosition < imgHeight) {
        pdf.addImage(imgData, 'PNG', 0, yPosition, imgWidth, imgHeight);
        yPosition += pageHeight;

        if (yPosition < imgHeight) {
          pdf.addPage();
        }
      }

      pdf.save('simulacion-plusvalia.pdf');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}  maxWidth='lg' PaperProps={{style: {backgroundColor: '#e0eaf4'}}}> 
      <DialogTitle>
        <div style={{display: 'inline-flex', alignItems: 'center'}}>
            <Typography style={{fontSize: 25, color: "#2196f3", fontWeight: 450, fontFamily: 'Roboto'}}>
              {translate(componentIG, titleTerm, terms).toUpperCase()}
            </Typography>
        </div>
      </DialogTitle>
      <DialogContent  >
        {/* Usa un contenedor específico para capturar el contenido */}
        <div ref={modalContentRef} style={{ overflow: 'visble', backgroundColor:'white' }}>
          {render()}
        
        </div>
        
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate('Global','Cerrar', terms)}</Button>
        <Button onClick={downloadPdf}>{translate('Plusvalias','btnDownloadPdf', terms)}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default withLiterals(ALL_LITERALS)(GenericModalPDF);
