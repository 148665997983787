import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";
import { amber } from '@material-ui/core/colors';

const styles = (theme: Theme): StyleRules => ({
    centerContent: {
        textAlign: 'center',
    },
    root: {
        margin: '0 0.5rem',
        flex: 1,
    },    
    body: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
    contenido: {
        marginBottom: '0.5rem',
    },
    formControl: {
        marginBottom: 10,
        display: 'block',
        '& > div': {
            width: '100%',
        },
        '& > button': {
            width: '100%',
        }
    },
    section: {
        marginBottom: '0.5rem'
    },
    contenidoSection: {
        paddingRight: '0.25rem'
    },
    circle: {
        borderRadius: '50%',
        width: 1,
        height: 1,
    },        
    padding10: {
        padding: 10,
    },
    buttonPosition: {
        textAlign: 'center',
        padding: 10,
    },    
    contentButton: {
        textAlign: 'center',
        top: 10,
    },
    button: {
        minWidth: 150,
    },    
    hidden: {
        display: 'none',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        marginTop: 5,
        marginLeft: -90,        
    },
    warningMessage: {
        color: amber[900],
        fontWeight: 'bold',
    },
    customWidth: {
        maxWidth: 'none',
    },
    [theme.breakpoints.up('md')]: {
        root: {
            margin: '0 2rem'
        },
        body: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
        },
        containerSections: {
            display: 'flex'
        },
        formContainer: {
            '& > .MuiGrid-item': {
                padding: '0 20px'
            }
        },
        formContainerWithoutGrid: {
            '& > .MuiFormControl-root': {
                padding: '0 20px'
            }
        },
        section: {
            flex: 1,
            padding: '0 20px',
        },
        separate: {
            marginRight: '0.5rem'
        },        
        circle: {
            borderRadius: '50%',
            width: 35,
            height: 35,
            backgroundColor: '#008353',
            textAlign: 'center',
            margin: 7,
            paddingTop: 5,
        },
        circleBlue: {
            borderRadius: '50%',
            width: 35,
            height: 35,
            backgroundColor: '#3489AC',
            textAlign: 'center',
            margin: 7,
            paddingTop: 5,
        },
        calendar: {
            width: '25%',
            padding: 10,
            display: 'flex',
        },
        contentButton: {
            textAlign: 'left',
            top: 15,
        },
        rightContent: {
            textAlign: 'right',
            top: 5,
        },        
        buttonPosition: {
            textAlign: 'right',
            padding: 10,
        },    
        padding5: {
            padding: 5
        },
        customWidth: {
            maxWidth: 500,
        },
        title: {
            fontSize: '1.17em',
            fontWeight: 'bold',
            marginTop: '1em',
            marginBottom: '1em',

        }
    }
});

export default styles;