// LIBRARY IMPORTS
import { FC, useCallback, useEffect, useState } from "react";
import { Disable } from "react-disable";
import moment from "moment";

// LOCAL IMPORTS
import { adjustForTimezone, convertirFechaAFormato } from "utils/dates";
import { IDiasHorariosCita } from "gateways/citaPrevia.interface";
import { FORMAT_DATE_CALENDAR } from "constants/moment";
import CustomDatePicker from "atomic/molecules/custom-date-picker/custom-date-picker";

interface IInteractiveCalendar {
    days: IDiasHorariosCita | undefined;
    date: Date | undefined | null;
    disabled: boolean;
    disablePast: boolean;
    open: boolean;

    onChange: (date: string) => void;
    onToggle: () => void;
}

const InteractiveCalendar: FC<IInteractiveCalendar> = (props) => {
    const {
        days,
        date,
        disabled,
        disablePast,
        open,
        onChange,
        onToggle
    } = props;

    // STATES
    const [formattedDays, setFormattedDays] = useState<string[]>([]);



    // EFFECTS & FUNCTIONS

    useEffect(() => {
        if (days) {
            let daysArray: string[] = Object.keys(days);
            setFormattedDays(daysArray.map(day => day.split('T')[0]))
        }
    }, [days]);

    const renderCustomDay = useCallback((day: any) => {
        if (!days || formattedDays.length < 1) return false;
        const fechaBuscada = moment(convertirFechaAFormato(adjustForTimezone(day))).format("YYYY-MM-DD");

        if (formattedDays?.includes(fechaBuscada)) return false
        return true
    }, [days, formattedDays]);

    const handleDate = (date: any) => {
        onChange(moment(date).toString())
    }



    return (
        <Disable disabled={disabled}>
            <CustomDatePicker
                labelComponent={""}
                labelText={""}
                tooltipPlacment="bottom"
                
                value={date}
                open={open}
                disablePast={disablePast}
                dateFormat={FORMAT_DATE_CALENDAR}

                onToggle={onToggle}
                onChange={handleDate}
                shouldDisableDate={renderCustomDay}

                style={{ width: '-webkit-fill-available' }}
                InputLabelProps={{
                    style: {
                        width: '-webkit-fill-available'
                    },
                }}
            />
        </Disable>
    );
}

export { InteractiveCalendar };