enum PadDirection {
    left = 0,
    right = 1
}

export function pad(this: string, len: number, char: string, dir: PadDirection = PadDirection.left) {
    const template = (new Array(len)).join(char[0]);
    return dir === PadDirection.left
        ? template.substr(0, template.length - this.length) + this
        : this + template.substr(this.length);
}


export function extraerDatosModelo593(texto: string, idTipoModelo: string): [string | null, string | null] {
    const patronAno = new RegExp(`<${idTipoModelo}_0\\.2>(.*?)</${idTipoModelo}_0\\.2>`);
    const resultadoAno = texto.match(patronAno);
    const ano = resultadoAno ? resultadoAno[1] : null;

    const patronTrimestre = new RegExp(`<${idTipoModelo}_0\\.3>(.*?)</${idTipoModelo}_0\\.3>`);
    const resultadoTrimestre = texto.match(patronTrimestre);
    const trimestre = resultadoTrimestre ? resultadoTrimestre[1] : null;

    return [ano, trimestre];
}
