import { Card, Grid, makeStyles, Theme } from '@material-ui/core';
import { mdiFrequentlyAskedQuestions } from '@mdi/js';
import Term from 'components/term';
import IoC from 'contexts/ioc.context';
import { FAQSGateway } from 'gateways/faqs.gateway';
import usePage from 'hooks/page.hook';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getIconFromCategory, getImgCategory } from './categorias-faqs';
import { IFAQ } from './detail-faqs';
import { Pregunta } from './pregunta';
import { SearchFAQS } from './search-faqs';
import NoContent from 'components/no-content';
import image from '../../resources/SinFAQ.png';
import { Categories, ICategory } from 'components/categories';
import { useHistory } from 'react-router';
import SelectSubcategory from 'components/select-subcategory';
import { TBoxHomeOptions } from 'containers/inicio/helper/inicio.helper';
import BoxHome from 'components/box-home';


const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'grid',
        width: '100%',
        gap: '1em',
        gridAutoRows: "max-content",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px"
       }
    },
    flex: {
        display: "grid",
        gridTemplateColumns: "65% 35%",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexDirection: "column"
       }
    },
    detalle: {
        flex: 1,
        marginLeft: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        marginRight: "1rem",
        [theme.breakpoints.up('sm')]: {
            minHeight: '58vh'
       }
    },
    cardContainer: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        display: 'grid',
        width: '100%',
        gridTemplateColumns: 'repeat(auto-fit,minmax(350px, 1fr)) ',
        gap: '0.75rem'
    },
    widget: {
        marginTop: 10,
        marginBottom: 10,
    }
}));


export const FAQS: React.FC = () => {
    const classes = useStyles();
    const ioc = useContext(IoC);
    const lang = localStorage.getItem('lang');
    const gateway: FAQSGateway = useMemo(() => ioc.get(FAQSGateway), [ioc]);
    const [categoriasFAQS, setCategoriasFAQS] = useState<ICategory[] | null>(null)
    const [options, setOptions] = useState<TBoxHomeOptions[]|undefined>(undefined)
    const [inputValue, setInputValue] = useState('');

    const [listFiltrados, setFiltrados] = useState<IFAQ[] | null>(null);
    const [pageState, pageDispatcher] = usePage();
    const modeSearch = useMemo(() => listFiltrados ? true : false, [listFiltrados]);

    const handleSearch = useCallback(async (value: string) => {
        const response = await gateway.search(value);
        setFiltrados(response);
    }, [gateway, inputValue]);

    const handleInputChange = (inputValue: string) => {
        setInputValue(inputValue);
    };

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiFrequentlyAskedQuestions,
                title: <Term component="FAQS" text="PreguntasFrecuentes" />,
                text: <Term component="FAQS" text="InformacionTramitesServicios" />
            },
            menu: true,
        });
    }, [pageDispatcher]);

    useEffect(() => {
        async function loadPage() {
            const response = await gateway.getAllCategories();
            setCategoriasFAQS(response);
        }
        loadPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang])

    useEffect(() => {
        if(categoriasFAQS){
            const opt : TBoxHomeOptions []= categoriasFAQS.map( cat => {
                const img = getImgCategory(cat.iconPath)
                return ({
                    boxImage: img,
                    title: {component:"FAQS", text: cat.categoryName},
                    link: `/faqs/${cat.id}`,
                    //linkState: {subCategory: cat.subCategories},
                    icon: cat.iconPath,
                })
            })
            opt.push({
                boxImage: getImgCategory("suggestions"),
                title: {component:"FAQS", text: "AtencionPersonalizada"},
                link: 'perfil/atencion-personalizada/consultas',// `https://www.atib.es/General/Contacto.aspx?lang=${pageState.lang}`,
                //isExternLink: true,
                animated: false
            })
            setOptions(opt)
        }

    }, [categoriasFAQS])


    return (
        <>
            <div className={classes.container}>
                <SearchFAQS 
                    onSearch={handleSearch}
                    onInputChange={handleInputChange}
                    withCategory={false}
                    inputValue={inputValue}
                    labelStyle={{
                        fontSize: 20
                    }}
                />
                <div style={{ marginRight: 10, marginLeft: 10}}>
                    
                    <Grid container >
                        {listFiltrados && listFiltrados.length>0 ?
                        
                            listFiltrados.map((x, index) => {
                                const iconCat= getIconFromCategory(x.categoryId, categoriasFAQS)
                                const imgCat = iconCat ? getImgCategory(iconCat): undefined
                                return (
                                    (x.answer && x.answer != '' && x.question && x.question != '') &&
                                    <Grid className={classes.widget} item xs={12} md={12} lg={12} xl={12} key={`faqs_respuesta${index}`}>
                                        <Pregunta key={`Pregunta_Categoria${x.categoryId}_i`} answer={x.answer} categoryId={x.categoryId} question={x.question} icon={iconCat} image={imgCat}/>
                                    </Grid>
                                )
                            })
                        
                        :
                        
                            options && options.map((optionItem: TBoxHomeOptions, index: number) => {
                                return (
                                    <Grid className={classes.widget} item xs={12} md={12} lg={6} xl={4} key={`faqs_optionItem${index}`}>
                                        <BoxHome item={optionItem} key={`faqs_boxOptionItem${index}`} />
                                    </Grid>
                                )
                            })
                        }  
                    </Grid>
                    
                </div>
                
            
            </div>
        
         
        {
            // categoriasFAQS !== null && categoriasFAQS.length > 0 ?
            //         <div className={classes.container}>
            //             <SearchFAQS onSearch={handleSearch} withCategory={false} />
            //                 <div className={classes.flex}>
            //                     <div className={classes.detalle}>
            //                         {
            //                             listFiltrados === null &&
            //                             <SelectSubcategory
            //                                 visible={true}
            //                                 component='FAQS'
            //                              />
            //                         }
            //                         {
            //                             (listFiltrados !== null && listFiltrados.length === 0) &&
            //                             <NoContent
            //                                 image={image}
            //                                 visible={true}
            //                                 component='FAQS'
            //                                 text={'noData'}
            //                             />
                                    
            //                         }
            //                         {
            //                             listFiltrados && listFiltrados.map((x) => (
            //                                 (x.answer && x.answer != '' && x.question && x.question != '') &&
            //                                 <Pregunta key={`Pregunta_Categoria${x.categoryId}_i`} answer={x.answer} categoryId={x.categoryId} question={x.question} icon={getIconFromCategory(x.categoryId, categoriasFAQS)} />
            //                             ))
            //                         }
            //                     </div>

            //                     {/**Listado izq. */}
            //                     <div className={classes.container}>
            //                         <Categories  listCategorias={categoriasFAQS} handleNavigate={handleNavigate} literalsComponent='FAQS' />
            //                     </div>
            //                 </div>
            //         </div>
            //         :
            //         <div className={classes.container}>
            //             <div className={classes.flex}>
            //                 <div className={classes.detalle}>
            //                 <NoContent
            //                     image={image}
            //                     visible={categoriasFAQS?.length === 0}
            //                     component='FAQS'
            //                     text={'noData'}
            //                 />
            //                 </div>
            //             </div>
            //         </div>
            }
        </>
    )
}