import { ILiteralsComponent } from "gateways/i18n.response.interface";

export const format = (text: string, params?: any[]) => {
    return text.replace(/\{(\d+)\}/gi, (m, idx) => {
        return params ? params[parseInt(idx, 2)] : '';
    });
}

export function translate(component: string, text: string, terms: ILiteralsComponent, params?: any[]) {
    if (terms) {
        const componentLoaded: any = terms[(component || 'Global').toLowerCase()];
        if (componentLoaded) {
            // LoadTerm(componentLoaded);
            const termLoaded = componentLoaded[text.toLowerCase()];
            if (termLoaded) {
                text = termLoaded;
            }
        }
    }

    return format(text, params);
}
