import { Card, CardContent, makeStyles } from '@material-ui/core';
import { Icon } from 'atomic/atoms/icon/icon';
import { ICategory } from 'components/categories';
import Term from 'components/term';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import CarteroVirtualImg from '../../resources/home/home/cartero.png';
import ImpostTuristic from '../../resources/faqs/IT.png';
import BonifSucesiones from '../../resources/faqs/BonifSucesiones.png';
import ProgramaAyuda from '../../resources/faqs/ProgAyuda.png';
import TipoGrav from '../../resources/faqs/TipoGrav.png';
import ImpuestoResiduo from '../../resources/faqs/ImpuestoResiduos.png';
import AtencionPersonalizada from '../../resources/faqs/AtencionPersonalizada.png';
import AbonoNacIMG from '../../resources/home/tramites/neixments_Icono.png'




const useStyles = makeStyles(() => ({
    container: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        display: 'grid',
        width: '100%',
        gridTemplateColumns: 'repeat(auto-fit,minmax(350px, 1fr)) ',
        gap: '0.75rem'
    },
    cardelemento: {
        display: 'flex',
        alignItems: 'center',
    },
    textoCard: {
        flex: 1,
        fontSize: 20,
        marginLeft: '1.5rem',
        color: '#004f84'
    },
    cursor: {
        cursor: 'pointer'
    }
}));


export interface ICategoria {
    id: number,
    categoryName: string,
    iconPath: string,
}

interface IProps {
    listCategorias: ICategoria[]
}

export function getIconFromCategory(categoryId: number, categories: ICategory[] | null) {
    let iconPath = categories ? categories.find((x) => x.id === categoryId)?.iconPath: undefined;
    if(!iconPath && categories){
       categories.forEach(element => {
           let newIconPath = element.subCategories.find((x) => x.id === categoryId)?.iconPath
           if(newIconPath != undefined && newIconPath != null){
            iconPath = newIconPath;
           }
       });
    }

    return iconPath;
}

export function getNameFromCategory(categoryId: number, categories: ICategory[]|null) {
     let categoryName = categories ? categories.find((x) => x.id === categoryId)?.categoryName: undefined;
     if(!categoryName && categories){
        categories.forEach(element => {
            let newName = element.subCategories.find((x) => x.id === categoryId)?.categoryName
            if(newName != undefined && newName != null){
                categoryName = newName;
            }
        });
     }

     return categoryName;
}

export const getImgCategory = (iconName: string): string|undefined => {
    switch (iconName) {
        case 'laptop':
            return CarteroVirtualImg;
        case 'dots-vertical':
            return BonifSucesiones;
        case 'bank':
            return TipoGrav;
        case 'wallet':
            return ProgramaAyuda;
        case 'recycle':
            return ImpuestoResiduo;
        case 'airplane-settings':
            return ImpostTuristic;
        case 'suggestions':
            return AtencionPersonalizada;
        case 'teddy-bear':
            return AbonoNacIMG;
        default:
            return undefined;
    }
};

export const CategoriasFAQ: React.FC<IProps> = ({ listCategorias }) => {
    const classes = useStyles();
    const history = useHistory();
    const handleNavigate = useCallback((id: number) => {
        history.push('/faqs/' + id)
    }, [history])

    return (
        <div className={classes.container}>
            {
                listCategorias.map((x) => (
                    <Card key={`Categoria_${x.id}_${x.categoryName}`} className={classes.cursor} onClick={() => handleNavigate(x.id)}>
                        <CardContent>
                            <div className={classes.cardelemento}>
                                <Icon name={x.iconPath as any} size={2} color="#004f82"></Icon>
                                <div className={classes.textoCard}>
                                    <Term component='FAQS' text={x.categoryName}></Term>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                ))
            }
        </div>)
}