import { FC } from 'react';
import { Drawer, makeStyles } from "@material-ui/core";
import Term from '../../../components/term';
import FiltersForm, { ScreenType } from './filters.form';
import { IFiltersState } from './filters.reducer';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    drawerPaper: {
        width: 300,
        top: 230,
        height: 'calc(100% - 230px)',
        borderTopLeftRadius: 8,
    },
    main: {
        padding: 20
    },
    title: {
        marginBottom: 0
    },
    center: {
        textAlign: 'center'
    }
}));

export interface IPanelDrawerProps {
    onFiltersChange: (filters: IFiltersState) => void;
    screen: ScreenType;
}

const PanelDrawer: FC<IPanelDrawerProps> = ({ onFiltersChange, screen }) => {
    // const [filters, ] = useContext(FiltersContext);
    const classes = useStyles();
    // const handleApplyFilters = useCallback(() => {
    //     // filtersDispatch({ type: 'update-any', filters: newFilters });
    //     onFiltersChange(filters);
    // }, [filters]);


    return (
        <Drawer
            classes={{
                paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor="right"
            open>
            <div className={classes.main}>
                <h3 className={classes.title}><Term component="Global" text="Filtros de busqueda" /></h3>
                <FiltersForm
                    screen={screen}
                />
                {/*<div className={classes.center}>
                    <Button onClick={handleApplyFilters} variant="outlined" >
                        <Term component="Global" text="Aplicar filtros" />
                    </Button>
                </div>*/}
            </div>
        </Drawer>
    )
};

export default PanelDrawer;