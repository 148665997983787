import { TInfoTemplateSize } from "contexts/more_info.reducer";
import { IJwtPayload, IJwtResponse } from "gateways/auth.interfaces";
import { createContext, Dispatch, ReactNode, Reducer, useContext } from "react";
import { authStorage } from "storages";
import { tryReadStorage } from "utils/storage";

export interface IPageHeader {
  title: string | ReactNode;
  icon: string | null;
  right?: ReactNode;
  moreInfoTemplate?: string;
  moreInfoTemplateComponentIG?: string;
  text?: string | ReactNode;
  hideAtibLogo?: boolean;
  homeSearch?: boolean;
  moreInfoTemplateSize?:TInfoTemplateSize
}

export interface IPageState {
  header: IPageHeader | null;
  menu: boolean;
  footer: boolean;
  openAltaAlert: boolean;
  openModalRepresentar: boolean;
  openModificacionDatosSujetoDialog: boolean;
  openProteccionDatosSujetoDialog: boolean;
  datosSujetoDialog: IPageDatosSujeto
  navLeftOpen: boolean;
  navRightOpen: boolean;
  navRightComponent: ReactNode | null;
  lang: string;
  jwp: IJwtPayload | null;
  jwt: IJwtResponse | null;
  termsEditable: boolean;
  notifications: IPageNotificationWithOpen[];
  alert: IPageNotificationWithOpen;
  logout: boolean;
}

export interface IPageNotification {
  message: string | ReactNode;
  variant: "success" | "warning" | "error" | "info";
  autoHideDuration?: number;
}

export interface IPageNotificationWithOpen extends IPageNotification {
  open: boolean;
}

export interface IPageDatosSujeto {
  open: boolean;
  variant: "info" | "success" | "error";
}

export type PageAction =
  | {
    type: "setHeader";
    header: IPageHeader | null;
    menu: boolean;
    footer?: boolean;
  }
  | { type: "open-modal-representar" | "close-modal-representar" }
  | { type: "show-alta-alert" | "hide-alta-alert" }
  | { type: "open-nav-left" | "close-nav-left" }
  | { type: "open-modificacion-datos-sujeto" | "close-modificacion-datos-sujeto" }
  | { type: "open-proteccion-datos-sujeto" | "close-proteccion-datos-sujeto" }
  | { type: "open-info-datos-sujeto" | "open-success-datos-sujeto" | "open-error-datos-sujeto" | "close-info-datos-sujeto" }
  | { type: "open-nav-right" | "close-nav-right" }
  | { type: "set-nav-right-component"; component: ReactNode }
  | { type: "authenticate"; payload: IJwtResponse }
  | { type: "logout" }
  | { type: "change-lang"; lang: string }
  | {
    type: "show-notification" | "remove-notification" | "hide-notification";
    payload: IPageNotification;
  };

const getJwtPayloadFromToken = (jwt: string) => {
  if (jwt) {
    const parts = jwt.split(".");
    const data = parts[1];
    return parseToken(data);
  }
  return null;
};

const parseToken = (data: any) => {
  data = data.replace(/-/gim, "+").replace(/_/gim, "/");

  const payload = JSON.parse(atob(data)) as IJwtPayload;
  payload.fullname = decodeURIComponent(escape(payload.fullname));
  if (payload.fullnameRepresentante) {
    payload.fullnameRepresentante = decodeURIComponent(escape(payload.fullnameRepresentante));
  }

  return payload;
};

const authenticate = (state: IPageState, jwt: IJwtResponse) => {
  if (!("accessToken" in jwt)) {
    return state;
  }
  const jwp = getJwtPayloadFromToken(jwt.accessToken);
  if (jwp) {
    state.jwt = jwt;
    state.jwp = jwp;
    if (state.lang !== jwp.idioma) {
      state.lang = jwp.idioma;
      const htmlNodes = document.getElementsByTagName("html");
      if (!!htmlNodes && htmlNodes.length > 0) {
        htmlNodes[0].setAttribute("lang", state.lang);
      }
    }
    authStorage.setItem("lang", state.lang);
    authStorage.setItem("jwp", JSON.stringify(jwp));
    authStorage.setItem("jwt", JSON.stringify(state.jwt));
  }

  return state;
};

const logout = (state: IPageState) => {
  authStorage.removeItem("jwp");
  authStorage.removeItem("jwt");
  state.jwp = null;
  state.jwt = null;
  state.logout = true;
  return state;
};

export const pageReducer: Reducer<IPageState, PageAction> = (state, action) => {
  switch (action.type) {
    case "setHeader":
      return {
        ...state,
        header: action.header,
        menu: action.menu,
        footer: action.footer ? action.footer : false,
      };
    case "open-modal-representar":
      return { ...state, openModalRepresentar: true };
    case "close-modal-representar":
      return { ...state, openModalRepresentar: false };
    case "open-nav-left":
      return { ...state, navLeftOpen: true };
    case "show-alta-alert":
      return updateShowAltaAlert(state, true);
    case "hide-alta-alert":
      return updateShowAltaAlert(state, false);
    case "open-info-datos-sujeto":
      return updateDialogDatosSujeto(state, true, "info");
    case "open-success-datos-sujeto":
      return updateDialogDatosSujeto(state, true, "success");
    case "open-error-datos-sujeto":
      return updateDialogDatosSujeto(state, true, "error");
    case "close-info-datos-sujeto":
      return updateDialogDatosSujeto(state, false, "info");
    case "open-modificacion-datos-sujeto":
      return updateShowModificacionDatosSujeto(state, true);
    case "close-modificacion-datos-sujeto":
      return updateShowModificacionDatosSujeto(state, false);
    case "open-proteccion-datos-sujeto":
      return { ...state, openProteccionDatosSujetoDialog: true };
    case "close-proteccion-datos-sujeto":
      return { ...state, openProteccionDatosSujetoDialog: false };
    case "close-nav-left":
      return { ...state, navLeftOpen: false };
    case "open-nav-right":
      return { ...state, navRightOpen: true };
    case "close-nav-right":
      return { ...state, navRightOpen: false };
    case "set-nav-right-component":
      return { ...state, navRightComponent: action.component };
    case "show-notification":
      return {
        ...state,
        notifications: state.notifications.concat([
          { ...action.payload, open: true },
        ]),
      };
    case "hide-notification":
      return {
        ...state,
        notifications: state.notifications.concat([
          { ...action.payload, open: false },
        ]),
      };
    case "remove-notification":
      return {
        ...state,
        notifications: state.notifications.filter((x) => x !== action.payload),
      };
    case "authenticate":
      return authenticate(state, action.payload);
    case "logout":
      return logout(state);
    case "change-lang":
      return updateLangage(state, action.lang);
    default:
      throw new Error("Unexpected action");
  }
};

const updateLangage = (state: IPageState, language: string) => {
  const newState = { ...state, lang: language };
  localStorage.setItem("lang", language);
  const htmlNodes = document.getElementsByTagName("html");
  if (!!htmlNodes && htmlNodes.length > 0) {
    htmlNodes[0].setAttribute("lang", language);
  }
  // TODO: Si el usuario esta logueado cambiamos el idioma
  return newState;
};

const updateShowAltaAlert = (state: IPageState, openAltaAlert: boolean) => {
  const newState = { ...state, openAltaAlert };
  return newState;
};

const updateShowModificacionDatosSujeto = (state: IPageState, openModificacionDatosSujetoDialog: boolean) => {
  const newState = { ...state, openModificacionDatosSujetoDialog };
  return newState;
}

const updateDialogDatosSujeto = (state: IPageState, isOpen: boolean, variant: "info" | "success" | "error") => {
  const newState = {
    ...state, datosSujetoDialog: {
      open: isOpen,
      variant: variant
    }
  };
  return newState;
};

// SE CORRIGE ERROR EN EL GUARDADO DEL TOKEN
(() => {
  const jwt = tryReadStorage("jwt", localStorage);
  if (jwt && jwt.accessToken && jwt.accessToken.indexOf("token=") >= 0) {
    authStorage.removeItem("jwp");
    authStorage.removeItem("jwt");
  }
})();

export const pageInitialState: IPageState = {
  header: null,
  menu: false,
  footer: false,
  openAltaAlert: false,
  openModalRepresentar: false,
  openModificacionDatosSujetoDialog: false,
  openProteccionDatosSujetoDialog: false,
  datosSujetoDialog: { open: false, variant: "info" },
  navLeftOpen: false,
  navRightOpen: false,
  navRightComponent: null,
  notifications: [],
  alert: {} as IPageNotificationWithOpen,
  lang: getLang(),
  termsEditable: false,
  jwp: tryReadStorage("jwp"),
  jwt: tryReadStorage("jwt"),
  logout: false,
}

export const PageContext = createContext<[IPageState, Dispatch<PageAction>]>([pageInitialState, () => { }]);

const usePage = () => useContext(PageContext);

export function getLang(): string {
  const jwp = tryReadStorage("jwp");
  const lang = authStorage.getItem("lang");
  let userLang = navigator.language ? navigator.language : "ca";
  userLang = userLang !== "es" && userLang !== "ca" ? "ca" : userLang;

  const htmlNodes = document.getElementsByTagName("html");
  if (!!htmlNodes && htmlNodes.length > 0) {
    const htmlNode = htmlNodes[0];
    if (htmlNode.getAttribute("lang") !== userLang) {
      htmlNode.setAttribute("lang", userLang);
    }
  }

  return jwp != null ? jwp.idioma : lang != null ? lang : userLang;
}

export function getAccessToken(): string {
  const jwt = tryReadStorage("jwt");
  return jwt != null ? jwt.accessToken : null;
}

export function setAccessToken(accessToken: string): void {
  const jwt = tryReadStorage("jwt");
  const newJwt = Object.assign({}, jwt, { accessToken });
  authStorage.setItem("jwt", JSON.stringify(newJwt));
}

export default usePage;
