import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, CircularProgress, Grid, IconButton, Typography, WithStyles, withStyles } from "@material-ui/core";
import { IPlusvalVendedor, IVendedor } from "containers/DUTI/steps/types";
import { TSelect } from "containers/DUTI/utils";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import { translate } from "utils/i18n";
import InputDate from "./input-date";
import InputSelect from "./input-select";
import InputText from "./input-text";
import TypedInputNumber from "./typed-input-number";
import duti_styles from "containers/DUTI/styles";
import { mdiEyeCircleOutline } from "@mdi/js";
import { BLUE } from "containers/shared/colors";
import { IDocumentoAtib } from "gateways/duti.interface";
import Icon from "@mdi/react";
import { DocumentsUtils } from "utils/documents";
import LabelWithInfo from "./label-with-info";
import { CustomError } from "utils/custom.error";
import usePage from "hooks/page.hook";

export type TTipoPlusval= 'declaracion' | 'autoliquidacion' | 'comunicacion'
interface ICProps extends WithStyles {
    editable: boolean,
    item: IPlusvalVendedor, 
    vendedores: IVendedor[],
    tipoPlusval: TTipoPlusval,
    sujetoPasivo:boolean,
    compradores: TSelect[],
    onEdit: ()=> void,
    setShouldBlock: (v: boolean) => void,
}
const EMPTY_ERROR = {
    nifVendedor: false,
    operaciones: [],
    sujetoPasivo: false,
    nifSujetoPasivo: false,
}

const PlusvalVendedorDetail : FC<ICProps>= ({classes, editable, item, vendedores,tipoPlusval,sujetoPasivo, compradores, onEdit, setShouldBlock}) => {
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext)
    const [, pageDispatcher] = usePage();
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);

    const [loading, setLoading]= useState(false);
    const nifSujetoPasivo = sujetoPasivo ? vendedores.find(v => v.nif === item.nifVendedor) : null;
    const [localEscrituraAnt, setLocalEscrituraAnt]= useState<{ oper: number, escritura: IDocumentoAtib}[]>([]);

    useEffect(() => {
        (async() => {
            setLoading(true);

            try {
                const escr = await Promise.all(
                    item.operaciones.map( async (op, idx) => {
                        const esc = op.idEscrituraAnterior ? await dutiGateway.getDocAtib(op.idEscrituraAnterior) : null
                        return { oper: idx, escritura: esc}
                    })
                )
                const filtered= escr.filter(e => (e.escritura !== null))
                setLocalEscrituraAnt(filtered as { oper: number, escritura: IDocumentoAtib}[])
            } catch (error) {
                if (error instanceof CustomError && error.code === 403) {
                    setShouldBlock(false);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Global', 'sesion_caducada', terms),
                            variant: 'warning',
                            hasCustomAction: true,
                            handleCustomAction: () => { 
                                pageDispatcher({ type: "logout" });
                            }
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('DUTI','error_dusti', terms),
                            variant: "error"
                        }
                    });
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [item])   

    

    const showDoc = async( doc: string ) => {
        try {
            setLoading(true)
            DocumentsUtils.downloadDoc(doc)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI', 'can_not_show_docuemnta', terms),
                    variant: 'error',
                }
            });
        }
    }
   

    return(
        <div>
            { sujetoPasivo 
                ? <InputText
                        required 
                        disabled
                        fieldTerm='nifSujetoPasivo'
                        value = {nifSujetoPasivo ? nifSujetoPasivo.nomApe+' - '+ nifSujetoPasivo.nif : item.nifVendedor}
                        //value={}
                        onChangeValue= {(v: TSelect, err: boolean) => {}}
                        className={classes.input545}
                    />
                : <InputSelect 
                        disabled
                        fieldTerm='nifSujetoPasivo'
                        value={compradores.find(c => c.id === item.nifSujetoPasivo)}
                        options={compradores}
                        onChangeValue= {(v: TSelect, err: boolean) => {}}
                        className={classes.input545}
                    />
            }
            {tipoPlusval !== 'comunicacion' && 
                <Grid container direction='row' style={{display:'flex'}} >
                    <Grid item xs={12} md={12} lg={12} xl={9} className={classes.column}>
                        <LabelWithInfo type="text" fieldTerm={'plusvalVendOperaciones'} moreInfo={{template: 'info_field_plusvalVendOperaciones',size:'xs'}} />
                        {item.operaciones.map( (op, idx) => {
                            return(
                                <div key={idx} className={classes.row}>
                                    <InputDate key="fechaAnteriorAdquisicion"
                                        disabled={true}
                                        required={true}
                                        maxDate={ new Date() }
                                        fieldTerm={"fechaAnteriorAdquisicion"}
                                        value = {op.fechaAnteriorAdquisicion} 
                                        onChangeValue={(v: Date|undefined,err:boolean) => {}}
                                        //className={classes.inputDefault} 
                                        //className={classes.input200} 
                                    />
                                    <TypedInputNumber key="porcentajeAnteriorAdquisicion"
                                        type="percentaje"
                                        disabled
                                        required={true}
                                        allowZero={false}
                                        maxDecimal={2}
                                        fieldTerm="porcentajeAnteriorAdquisicion"
                                        value={ op.porcentajeAnteriorAdquisicion ?? 0 }
                                        onChangeValue={(v, err) => {}}
                                        className={classes.input200} 
                                    />
                                    <TypedInputNumber key="importeAnteriorAdquisicion"
                                        type="money"
                                        disabled
                                        allowZero={true}
                                        required={false}
                                        min={0}
                                        maxDecimal={2}
                                        fieldTerm="importeAnteriorAdquisicion"
                                        value={ op.importeAnteriorAdquisicion ?? 0 }
                                        onChangeValue={(v, err) => {}}
                                        className={classes.input200}// window.screen.width <= 1500 ? classes.input160 : classes.inputDefault} 
                                    />
                                    {op.idEscrituraAnterior && localEscrituraAnt[idx] &&
                                        <div style={{display: 'flex', flexDirection:'column',alignContent:'flex-start', alignItems: 'center', marginLeft: 20}}>
                                            <Typography variant="body2"  style={{fontWeight: 'bolder'}}>
                                                {translate('DUTI','escrituraAnterior',terms).toUpperCase()}
                                            </Typography>
                                            <div style={{display: 'inline-flex', alignItems:'center'}}>
                                                <Typography variant="body2"  style={{marginRight: 10}}>
                                                    {localEscrituraAnt[idx].escritura.NombreFichero}
                                                </Typography>
                                                <IconButton style={{padding:5}} onClick={() => op.idEscrituraAnterior && showDoc(op.idEscrituraAnterior)}>
                                                    <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                            )
                        })}
                    </Grid>
                    {item.modelo081 && item.modelo081.totalIngresar !== undefined &&
                        <Grid item xs={12} md={12} lg={12} xl={3} 
                            className={classes.borderPlusval} 
                            style={{ marginBottom: 25, display:'flex', flexDirection:"column",flexGrow: 1 }}
                        >
                            
                            <div className={classes.divTextBorderPlusval}>
                                <Typography className={classes.textBorderPlusval}>{translate('DUTI','metodo_calculo_m081',terms)}</Typography>
                            </div>
                            <div className={classes.rowPlusvalDetail}>
                                <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('DUTI','metodoObjetivo',terms)}:</Typography>
                                <Typography color='textSecondary' className={classes.plusvalDetail_value}>{item.modelo081.importeMetodoObjetivo} €</Typography>

                            </div>
                            { item.operaciones[0].idEscrituraAnterior && item.operaciones[0].idEscrituraAnterior !== ""&&
                                <div className={classes.row}>
                                    <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('DUTI','metodoReal',terms)}:</Typography>
                                    <Typography color='textSecondary' className={classes.plusvalDetail_value}>{item.modelo081.importeMetodoReal ?? 0} €</Typography>
                                </div>
                            }
                            <div className={classes.rowPlusvalDetail}>
                                <Typography color='textSecondary' className={classes.plusvalDetail_title} >{translate('DUTI','totalIngresar',terms)}:</Typography>
                                <Typography color='textSecondary' className={classes.plusvalDetail_value}>{item.modelo081.totalIngresar} €</Typography>
                            </div>
                            {/* { item.operaciones[0].idEscrituraAnterior !== undefined && item.operaciones[0].idEscrituraAnterior !== "" &&
                                <div className={classes.rowPlusvalDetail} style={{marginTop: 10}}>
                                    <Typography color='textSecondary' className={classes.plusvalDetail_title} >
                                        {translate('DUTI','totalEscriturasAnterior',terms).toUpperCase()}: 
                                    </Typography>
                                    <Typography color='textSecondary' className={classes.plusvalDetail_value} style={{alignSelf:'flex-end'}}>{item.operaciones.length} </Typography>
                                </div>
                            } */}
                           
                        </Grid>
                    }
                    {item.modelo080 && item.modelo080.valorAdquisicion !== undefined && item.modelo080.valorTransmision !== undefined &&
                        <Grid item xs={12} md={12} lg={12} xl={3} 
                            className={classes.borderPlusval} 
                            style={{ marginBottom: 25, display:'flex', flexDirection:"column",flexGrow: 1 }}
                        >
                            <div className={classes.divTextBorderPlusval}>
                                <Typography className={classes.textBorderPlusval}>{translate('DUTI','info_m080',terms)}</Typography>
                            </div>
                            <div className={classes.rowPlusvalDetail}>
                                <Typography color='textSecondary' className={classes.plusvalDetail_title} >{translate('DUTI','importeAnteriorAdquisicionTotal',terms)}:</Typography>
                                <Typography color='textSecondary' className={classes.plusvalDetail_value}>{item.modelo080.valorAdquisicion} €</Typography>

                            </div>
                            <div className={classes.rowPlusvalDetail}>
                                <Typography color='textSecondary' className={classes.plusvalDetail_title} >{translate('DUTI','valorDeclarado',terms)}:</Typography>
                                <Typography color='textSecondary' className={classes.plusvalDetail_value}>{item.modelo080.valorTransmision} €</Typography>
                            </div>
                            
                        </Grid>
                    }
                </Grid>
            }
            { (tipoPlusval !== 'comunicacion'  || !sujetoPasivo ) &&  
                <Button variant="contained"
                    color="primary" 
                    onClick={() => {
                        if(editable){
                            onEdit()
                        } else{
                            alertsDispatch({
                                type:'show-alert',
                                payload: {
                                    message: translate('DUTI','plusvalVend_pending_changes',terms),
                                    variant: "warning"
                                }
                            })
                        }
                    }}
                >
                    {translate('DUTI', 'btn_editar',terms)}
                </Button>
            }
        </div>
    )
}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(PlusvalVendedorDetail));
