import {FC, useContext, useEffect, useMemo, useState} from "react";
import { LiteralsContext } from "containers/shared/literals";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography, makeStyles } from "@material-ui/core";
import duti_styles from "../styles";
import { TCausaUserChoice, TDTDatosComprador, TDireccionFiscal, TTipoTransmitente } from "./types";
import  DUTIGateway  from "gateways/duti.gateway";
import IoC from "contexts/ioc.context";
import FullLoading from "components/full-loading";
import LabelWithInfo from "components/DUTI/label-with-info";
import ContactForm, { ALL_CONTACTO_DISABLED, EMPTY_CONTACTO_ERRORs } from "components/DUTI/contact-form";
import { AlertsContext } from "contexts/alerts.context";
import { translate } from "utils/i18n";
import { IInfoSujetoTransmitente } from "gateways/duti.interface";
import CheckBox from "components/DUTI/check-box";
import InputText from "components/DUTI/input-text";
import TypedInputNumber from "components/DUTI/typed-input-number";
import DirectionFormATIB, { ALL_DIRECTION_DISABLED, DEFAULT_REQUIRED_DIRECTION, EMPTY_DIRECTION_ERRORs } from "components/DUTI/direction-form-ATIB";
import ExpandPanel from "components/DUTI/expand-panel";
import  WarningIcon  from "@material-ui/icons/Warning";
import ModalCambioDomFiscal from "components/DUTI/modal-cambio-dom-fiscal";
import InputNumber from "components/DUTI/input-number";
import CompradorVendedorForm, { TLocalData } from "components/DUTI/comprador-vendedor-form";
import InputDate from "components/DUTI/input-date";
import { isCIF } from "utils/validateNif";
import { IMPORTE_TP } from "components/DUTI/modal-conceptos-sugeridos-m600";



const useStyles = makeStyles(duti_styles);

interface IProps  {
    tipo: TTipoTransmitente
    idDeclaracion: number
    comDomFisc: boolean,
    nifSujeto: string | undefined;
    tipoTransmitente: TTipoTransmitente | undefined
    direccionInmueble: string;
    refCatastral: string;
    handleContinuar: (v: TLocalData[], haveChanges:boolean, notClean: boolean) => void
    handleBack: () => void,
    handleCancelDUTI: (c: TCausaUserChoice, v: TLocalData[]) => void
    initialDatos: TLocalData[]
    actuaNombrePropio: boolean | undefined,
    compradoresList: TDTDatosComprador,
    withM600: boolean,
    porcentTransm: number,
    importeTransm: number,
    condSujPas: boolean | undefined
}

const DatosTransmisionDatosCompVend: FC<IProps> = ({ 
    tipo, idDeclaracion, comDomFisc, tipoTransmitente, initialDatos,nifSujeto,direccionInmueble,refCatastral, 
    handleBack, handleCancelDUTI, handleContinuar, actuaNombrePropio, compradoresList, withM600,importeTransm,porcentTransm, condSujPas
})=> {
    const terms = useContext(LiteralsContext);
    const classes= useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const [loading, setLoading] = useState(false);
    //Unifica el tipo para poder utilizar el mismo render si errores TS - Solo lectura
    const [localData,setLocalData] = useState<TLocalData[]>([])

    const [NIFs, setNIFs]=useState<string[]>([])

    // Modal Añadir/Editar
    const [openModal, setOpenModal] = useState(false);

    // Gestión contenido Exand => detalle o formulario
    const EMPTY_ON_EDIT = {savedChanges: true, idx: -1}
    const [onEdit, setOnEdit] = useState({...EMPTY_ON_EDIT});
    const [chargeableUserInfo, setChargeableUserInfo]= useState<IInfoSujetoTransmitente | null>(null)
    const [isPersFisica, setIsPersFisica]= useState(false)
    const [dirFiscalSuj, setDirFiscalSuj]= useState<string | undefined>(undefined)

    // Modal Editar - dir fiscal - compradores y persona física
    const requiredConsultaDirFiscal = useMemo(() => comDomFisc && isPersFisica && tipo === tipoTransmitente , [tipo, comDomFisc, tipoTransmitente,isPersFisica])
    const [openModalDirFiscal, setOpenModalDirFiscal] = useState(false);
    // Activa botón CONTINUAR
    const allowContinuar = useMemo(() => (localData.length > 0),[localData])
    // control %
    const [vendedoresPorcentaje,setVendedoresPorcentaje]= useState(0)
    const [compradoresPorcentaje,setCompradoresPorcentaje]= useState(0)
    const [haveChanges, setHaveChanges]= useState(false)


    // Activa botón  CARGAR DATOS SUJETO
    const allowChargeLogged = useMemo(() => {
        if(!nifSujeto){ return false }
        if(tipo === tipoTransmitente ){
            if(localData.length === 0){ return true }
            return NIFs.includes(nifSujeto) ? false : true
        } else {
            return false
        }
    }, [localData, nifSujeto, tipo, tipoTransmitente, NIFs])

    // Bloque añadir si llegamos al 100%
    const allowAdd = useMemo(() => {
        const percent = localData.map(item => item.porcentaje)
        const suma = percent.reduce((acumulador, valorActual) => acumulador + valorActual, 0);
        return (suma < 100) // O SOBRE PORCENTAJE DECLARADO
    }, [localData])

    const algunResidente = useMemo(() => {
        const paisESP = localData.map(item => item.direccion.pais.id === '00')
        return paisESP.includes(true)
    }, [localData])
    
    // Funciones

    // Guardar en Datos Locales Objeto DTDatosComprador/Vendedor ------------------------------------------------------------------------------------
    // Añadir nuevo compr/vend al listado
    const onSaveData = (action: 'add' | 'edit', newItem:TLocalData) => {
        //console.log(action, newItem)
        let errTipo=false
        let errPorcentajeTotal =false
        let newDatos: TLocalData[] = []
        if(newItem.nif === nifSujeto && tipo !== tipoTransmitente){
            errTipo=true
        } else {
            if(action === 'add'){
                newDatos= [...localData, {...newItem}]
            } else {
                newDatos = localData.map( (d, idx)=> idx === onEdit.idx ? {...newItem} : {...d} )

            }
            const percent = newDatos.map(item => item.porcentaje)
            const suma = percent.reduce((acumulador, valorActual) => acumulador + valorActual, 0);
            if(suma> 100){
                errPorcentajeTotal= true

            } else {
                //console.log('in save ok')
                setLocalData(curr => [...newDatos])
                setHaveChanges(true)
                setOnEdit({...EMPTY_ON_EDIT})

            }
        }
        return {
            ok: !errPorcentajeTotal && !errTipo, 
            msgErr: errPorcentajeTotal
                ? translate('DUTI', `err_porcentaje_total`, terms, [ translate('DUTI', `${tipo}`, terms) ])
                : errTipo
                    ? translate('DUTI', `err_nif_tipoTransmitente`, terms, [ 
                        newItem.nif, 
                        translate('DUTI', `DT_IntroduccionDatosDUTI_Datos${tipo}_title`, terms), 
                    ]) + translate('DUTI', `DT_TipoTransmitente_radioBtn_${tipoTransmitente}`, terms)
                    : undefined
         }
    }

    // Eliminar compr/vend del listado
    const onDeleteData = (nif:string) => {
        const newDatos = localData.filter( d => d.nif !== nif)
        setLocalData(curr => [...newDatos])
        setHaveChanges(true)
   
    }
    //  ----------------------------------------------------------------------------------------------------------------------------------------------
    const onSaveModalEtiqfisc = (nif: string, dirFiscal: TDireccionFiscal, haveChangesDF: boolean) => {
        const reqSave = haveChanges === true || haveChangesDF === true
        const notClean = haveChanges === false
        if(haveChangesDF){ 
            const finalData = localData.map(item =>  ({...item, direccionFiscal: item.nif === nif? dirFiscal : item.direccionFiscal}))
            handleContinuar(finalData, reqSave, notClean)
        } else{
            handleContinuar(localData, reqSave , notClean)
        }

        setOpenModalDirFiscal(false)
    }
   // -----------------------------------------------------------------------------------------------------------------------------------------------------
    const renderDetail = (d:TLocalData, idx: number) => {
        const disabledDir = ALL_DIRECTION_DISABLED;
        const disabledContacto = ALL_CONTACTO_DISABLED;
        const esPersFisica = isCIF(d.nif) ? false: true

        return(
            <div className={classes.column}>
                <div  className={classes.formularioComprVendContainer}  >
                    <div className={classes.row} >
                        <InputText key='nif_detail'
                            fieldTerm='nif'
                            value={d.nif}
                            onChangeValue={() => {}}
                            required
                            disabled={true}
                            error={false}
                            forceUpperCase={true}
                        />
                        <InputText key='nomApe_detail'
                            fieldTerm='nomApeRazonSoc'
                            value={d.nomApe}
                            onChangeValue={() => {}}
                            required
                            disabled={true}
                            error={false}
                            className={classes.input360}
                            forceUpperCase={true}
                        />
                    </div>

                    {/* {tipo === 'vendedor' && tipoTransmitente === 'comprador' ? null :    } */}
                    <DirectionFormATIB key='direccion_detail'
                        direction={d.direccion}
                        setDirection={() => {}}
                        dirErrors={EMPTY_DIRECTION_ERRORs} 
                        setDirErrors={() => {}}
                        disabled={ disabledDir}
                        required={{...DEFAULT_REQUIRED_DIRECTION, poblacion: false}}
                    />
                    

                    {/* {tipo === 'vendedor' && tipoTransmitente === 'comprador' ? null:   } */}
                    <ContactForm  key='contacto_detail'
                        contact={d.contacto} 
                        setContact={() => {}}
                        contactErrors={EMPTY_CONTACTO_ERRORs} 
                        setContactErrors={() => {}}
                        disabled={disabledContacto}
                    />
                    

                    <div className={classes.row}>
                        <TypedInputNumber key='percentaje_detail'
                            type="percentaje"
                            fieldTerm={tipo === 'comprador' ? "porcentajeAdquisicion" : "porcentajeTitularidad"}
                            value={d.porcentaje}
                            onChangeValue={() => {}}
                            required
                            allowZero={false}
                            disabled={true}
                            error={false}
                            maxDecimal={2}
                            moreInfo={tipo === 'comprador' ? undefined : {template: 'info_field_porcentajeTitularidad', size: 'xs'}}
                        />
                       
                        {esPersFisica && tipo === 'comprador'&& tipo === tipoTransmitente && withM600 
                            && !(importeTransm > IMPORTE_TP) // importe inferior a 270.151,20€ (IMPORTE_TP)
                            && !(porcentTransm < 50)         // porcentajeTrans >= 50% 
                            &&
                            <div style={{marginLeft: 5, marginTop: 5, display:'inline-flex'}}>
                                <CheckBox key='viviendaHabitual_detail'
                                    fieldTerm={translate("DUTI",'viviendaHabitual', terms)}
                                    value={d.viviendaHabitual}
                                    onChangeValue={() => {}}
                                    disabled={true}
                                />
                               
                                <InputDate key='fechaNacimiento_detail'
                                    fieldTerm="fechaNacimiento"
                                    value={d.fechaNacimiento}
                                    onChangeValue={() =>{}}
                                    required= {d.viviendaHabitual === true}
                                    disabled={true} //esPersFisica && tipo === 'comprador'&& tipo === tipoTransmitente && withM600 && (d.viviendaHabitual !== true) }
                                    maxDate={ new Date() }
                                    
                                />
                                
                            </div>
                        }
                        {tipo === 'vendedor' &&
                            <InputNumber key='numOperacionesAdquisicion_detail'
                                fieldTerm={"numOperacionesAdquisicion"}
                                value={d.numOperacionesAdquisicion}
                                onChangeValue={() => {}}
                                required
                                min={1}
                                maxDecimal={0}
                                disabled={true}
                                error={false}
                                moreInfo={{template: 'info_field_numOperacionesAdquisicion', size: 'xs'}}
                                className={classes.input360}
                            />
                        }
                       
                    </div>
                    <div className={classes.row} style={{alignSelf: 'flex-end'}}>
                        <Button variant="contained" color="primary" 
                            onClick={() =>{ 
                                setOnEdit(curr =>({savedChanges: false, idx}))
                            }}
                        >
                            {translate('DUTI','btn_editar',terms)}
                        </Button>

                    </div>
                </div>
            </div>      
        )
    }
   // -----------------------------------------------------------------------------------------------------------------------------------------------------
   
    useEffect(() => {
        (async() => {
            setLoading(true)
            if(nifSujeto && nifSujeto !== ""){
                const suj = await dutiGateway.getSujeto(nifSujeto);
                if (suj) {
                    setChargeableUserInfo(suj)
                    setIsPersFisica(suj.personaFisica)
                    setDirFiscalSuj(suj.direccionFiscal)
                }
            }
            setLoading(false)
        })();
    },[nifSujeto])

    useEffect(() => {
        const NIFs = localData.map(item => item.nif)
        setNIFs(NIFs)
        const percent = localData.map(item => item.porcentaje)
        const suma= percent.reduce((acumulador, valorActual) => acumulador + valorActual, 0);
        if(tipo === 'vendedor'){
            setVendedoresPorcentaje(suma)
        } else {
            setCompradoresPorcentaje(suma)
        }
    },[localData])
    
    useEffect(() => {
        setLoading(true)
        if(tipo === 'vendedor' ){ 
            const percent = compradoresList.map(item => item.porcentaje)
            const sumaC = percent.reduce((acumulador, valorActual) => acumulador + valorActual, 0);
            setCompradoresPorcentaje(sumaC)
        }
        setLoading(false)
    }, [compradoresList])

    useEffect(() => {
        setLocalData(curr => [...initialDatos])
    }, [initialDatos])
    

    return (
        <>
            <Grid container direction='column' className={classes.componenteContainer}>
                <FullLoading loading={ loading } animation={{type:'intricom',name:'DUSTI'}} />               
                <Grid item container className={classes.columnContainer}>
                    {/* <LabelWithInfo type="title" fieldTerm="DT_IntroduccionDatosDUTI_title"/> */}
                    <LabelWithInfo type="title" fieldTerm={`DT_IntroduccionDatosDUTI_Datos${tipo}_title` }  moreInfo= {{template: `info_title_DT_IntroDatosDUTI_Datos${tipo}_title`, size: 'xs'}} />
                    <div style={{flex:1, flexGrow:1, overflowY: 'auto'}}>
                    {(localData.length > 0 ) 
                        ?
                        <div > 
                            {localData.map( (data, idx ) => {
                                //const dir = direccionText(data.direccion, terms)
                                return ( 
                                    <ExpandPanel
                                        key={data.nif} 
                                        withDelete={true}
                                        onDelete={() => onDeleteData(data.nif)}
                                        renderHeader={() => (
                                                <div className={classes.row} style={{flexGrow: 1, alignItems:'center', justifyItems:'space-between'}}>
                                                    <div className={classes.row} style={{flexGrow: 1}} >
                                                        <Typography style={{marginRight: 10, marginLeft: 5}}>{translate('DUTI',`DT_TipoTransmitente_radioBtn_${tipo}`,terms)+' '+ (idx+1)+ ': '}</Typography>
                                                        <Typography>{data.nomApe}</Typography>
                                                        <Typography style={{marginRight: 5, marginLeft: 5}}>{'-'}</Typography>
                                                        <Typography>{data.nif}</Typography>
                                                    </div>
                                                    { onEdit.idx === idx && !onEdit.savedChanges &&
                                                        <div style={{marginRight:20, marginLeft:20}}>
                                                            <WarningIcon/>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                            
                                        } 
                                        renderContent={() => (
                                                <>
                                                { onEdit.idx === idx && !onEdit.savedChanges 
                                                    ? //renderFormulario(true, false)
                                                    <CompradorVendedorForm
                                                        asDetail={true} 
                                                        asModal={false} 
                                                        allowChargeLogged={false}
                                                        chargeableUserInfo={chargeableUserInfo}
                                                        withM600={withM600} 
                                                        tipo={tipo} 
                                                        porcentTransm={porcentTransm}
                                                        importeTransm={importeTransm}
                                                        tipoTransmitente={tipoTransmitente} 
                                                        NIFsCompradores= {compradoresList.length>0 ? compradoresList.map( c => c.nif) : null }
                                                        NIFsCurrentData={localData.map( l => l.nif)}
                                                        initialInfo={data}
                                                        onSave={onSaveData} 
                                                        onEnd={()=> setOnEdit({...EMPTY_ON_EDIT})}
                                                    />
                                                    : renderDetail(data, idx)   
                                                }
                                                </>
                                            )
                                        }
                                    />
                                    )
                                })
                            }
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={() => {setOpenModal(true)}} 
                                disabled={!allowAdd}
                            >
                                { translate('DUTI', 'btn_añadir',terms)+'  '+translate('DUTI',`DT_TipoTransmitente_radioBtn_${tipo}`,terms) }
                            </Button>
                        </div>
                    :
                        <div>
                            {!loading &&
                            <CompradorVendedorForm
                                asDetail={false} 
                                asModal={false} 
                                allowChargeLogged={allowChargeLogged} 
                                chargeableUserInfo={chargeableUserInfo}
                                actuaNombrePropio={actuaNombrePropio} 
                                tipo={tipo} 
                                withM600={withM600} 
                                porcentTransm={porcentTransm}
                                importeTransm={importeTransm}
                                tipoTransmitente={tipoTransmitente} 
                                NIFsCompradores= {compradoresList.length>0 ? compradoresList.map( c => c.nif) : null }
                                NIFsCurrentData={localData.map( l => l.nif)}
                                onSave={onSaveData} 
                                onEnd={()=> {}}
                            />
                            }

                        </div>
                    }
                    </div>
                </Grid>
                
                <Grid item container direction="row" justify="space-between" alignItems="flex-end" className={classes.footerColumnContainer}>
                    <Button variant="contained" onClick={handleBack} color="inherit">
                        {translate('DUTI', 'btn_back',terms)}
                    </Button>

                    <div>
                        <Button style={{marginRight: 10}}
                            variant="outlined" color="primary"
                            onClick={() => {
                                handleCancelDUTI('continuar-mas-tarde', localData)
                                // Modal que muestra el useBlocker - DutiComponent
                                // alertsDispatch({
                                //     type: 'show-alert',
                                //     payload: {
                                //         message: translate('DUTI', 'confirm_cancelDUTI_user_choice', terms),
                                //         variant: 'warning',
                                //         hasCustomAction: true,
                                //         defaultCloseActionTemplateName: translate("DUTI", "no", terms).toUpperCase(),
                                //         actionTemplateName: translate("DUTI", "si", terms).toUpperCase(),
                                //         handleCustomAction: () => { 
                                //             handleCancelDUTI('continuar-mas-tarde', localData) 
                                //             alertsDispatch({type: 'hide-alert'})
                                //         }
                                //     }
                                // });
                            }} 
                            
                        >
                            {translate('DUTI', 'btn_pause_duti',terms)}
                        </Button>

                        
                        <Button 
                            variant="contained" color="primary"
                            onClick={() => {
                                if(onEdit.savedChanges){
                                    const NIFs = localData.map(item => item.nif)
                                    if((tipoTransmitente === tipo && nifSujeto && NIFs.includes(nifSujeto)) || tipoTransmitente !== tipo ){
                                        if(tipo === 'vendedor' && vendedoresPorcentaje !== parseFloat("100")){
                                            // Alerta los porcentajes compradores y vendedores no coincide
                                            alertsDispatch({
                                                type: 'show-alert',
                                                payload: {
                                                    isHtml: true,
                                                    message:
                                                        `<p>${translate('DUTI', 'compr_vend_diff_percent', terms)}</p>
                                                        <p>${translate('DUTI', 'DT_IntroduccionDatosDUTI_Datoscomprador_title', terms)}:  <b>${compradoresPorcentaje} %</b></p>
                                                        <p>${translate('DUTI', 'DT_IntroduccionDatosDUTI_Datosvendedor_title', terms)}:  <b>${vendedoresPorcentaje} %</b></p>
                                                        `,
                                                    variant: 'warning',
                                                }
                                            });
                                        } else if(tipo === 'vendedor' && condSujPas === true && !algunResidente) { 
                                            // Alerta no hay ningún residente y marcó Almenos uno reside en españa
                                            alertsDispatch({
                                                type: 'show-alert',
                                                payload: {
                                                    message: translate('DUTI', 'vend_no_hay_residente', terms),
                                                    variant: 'warning',
                                                }
                                            });
                                        } else if(tipo === 'vendedor' && condSujPas === false && algunResidente) { 
                                            // Alerta  hay un residente y marcó que no había ningún reside en españa
                                            alertsDispatch({
                                                type: 'show-alert',
                                                payload: {
                                                    message: translate('DUTI', 'vend_hay_residente', terms),
                                                    variant: 'warning',
                                                }
                                            });
                                        } else if(tipo === 'comprador'&& compradoresPorcentaje !== parseFloat("100")){
                                            // Alerta la suma de porcentajes de los compradores no suma 100%
                                            alertsDispatch({
                                                type: 'show-alert',
                                                payload: {
                                                    isHtml: true,
                                                    message:
                                                        `<p>${translate('DUTI', 'compr_diff_percent', terms)}</p>
                                                        <p>${translate('DUTI', 'DT_IntroduccionDatosDUTI_Datoscomprador_title', terms)}:  <b>${compradoresPorcentaje} %</b></p>
                                                        `,
                                                    variant: 'warning',
                                                }
                                            });
                                        } else {
                                            const sujeto = localData.find(c => c.nif === nifSujeto)
                                            //console.log('requiredConsultaDirFiscal', requiredConsultaDirFiscal, sujeto)
                                            if(requiredConsultaDirFiscal && sujeto){
                                                setOpenModalDirFiscal(true)
                                            } else {
                                                handleContinuar(localData,haveChanges,false)
                                            }

                                        }
                                       
                                    } else {
                                        alertsDispatch({
                                            type: 'show-alert',
                                            payload: {
                                                isHtml: true,
                                                message:
                                                    `<p>${translate('DUTI', 'compr_vend_not_includes_suj', terms, [translate('DUTI',tipo,terms)])}</p>
                                                    <p>${translate('DUTI', 'nif', terms)}:  <b>${nifSujeto}</b></p>
                                                    <p>${translate('DUTI', 'compr_vend_tipoTransmitente', terms)}:  <b>${tipoTransmitente ? translate('DUTI',tipoTransmitente,terms): '-'}</b></p>
                                                    `,
                                                variant: 'warning',
                                            }
                                        });
                                    }

                                } else {
                                    alertsDispatch({
                                        type: 'show-alert',
                                        payload: {
                                            message: translate('DUTI', 'compr_vend_with_pending_changes', terms),
                                            variant: 'warning',
                                        }
                                    });
                                }
                            } }
                            disabled={!allowContinuar}
                        >
                            {translate('DUTI', 'btn_continuar',terms)}
                        </Button>
                        

                    </div>
                </Grid> 

                
            </Grid>

            
            <Dialog open={openModal} fullWidth maxWidth={"lg"} >
                <DialogTitle>
                    {translate('DUTI',`datos_${tipo}`,terms)}
                </DialogTitle>
                <DialogContent>
                    {/* {renderFormulario(false, true)} */}
                    <CompradorVendedorForm
                        asDetail={false} 
                        asModal={true} 
                        allowChargeLogged={false}//allowChargeLogged} 
                        chargeableUserInfo={chargeableUserInfo}
                        actuaNombrePropio={actuaNombrePropio} 
                        tipo={tipo} 
                        withM600={withM600} 
                        porcentTransm={porcentTransm}
                        importeTransm={importeTransm}
                        tipoTransmitente={tipoTransmitente} 
                        NIFsCompradores= {compradoresList.length>0 ? compradoresList.map( c => c.nif) : null }
                        NIFsCurrentData={localData.map( l => l.nif)}
                        onSave={onSaveData} 
                        onEnd={()=> setOpenModal(false)}
                    />
                </DialogContent>
            </Dialog>


           {/* { tipo === 'comprador' && } */}
            <ModalCambioDomFiscal
                idDeclaracion={idDeclaracion}
                show={openModalDirFiscal}
                sujetoPasivo={localData.find(s => s.nif === nifSujeto)}
                inmubleDirecion={direccionInmueble}
                inmubleRefCat={refCatastral}
                dirFiscal={dirFiscalSuj ??  ' - '}
                onSave={onSaveModalEtiqfisc}
                onClose={() => setOpenModalDirFiscal(false)}            
            />
            

        </>
        
    )
}

export default DatosTransmisionDatosCompVend;

