import { mdiLaptop, mdiMessageBulleted, mdiShieldAccount, mdiCircle, mdiBell, mdiCalendar, mdiAlphaTCircleOutline, mdiFileAccount   } from '@mdi/js';
import imagenCarteroEsquina from 'resources/cartero_esquina.png';
import imagenCitaPreviaEsquina from 'resources/Citaprevia-esquina.png';
import imagenNotificacionEsquina from 'resources/notificacion_esquina.png';
import imagenCovidEsquina from 'resources/covid-esquina.png';
import imagenIconoTributos from 'resources/IconoTributos.png';

import imagenServiciosEsquina from 'resources/servicios_esquina.png';
import tramites from 'resources/tramites.png'

// new
import imagenAutoliquidaciones from 'resources/home/home/Autoliquidaciones.png';
import imagenAreaPersonal from 'resources/home/home/AreaPersonal.png';
import imagenCitaprevia from 'resources/home/home/Citaprevia.png';
import imagenNotificaciones from 'resources/home/home/Notificaciones.png';
import imagenTramites from 'resources/home/home/Tramites.png';
import imagenCartero from 'resources/home/home/cartero.png';
import { TTerm } from 'containers/shared/menu-item-types';


export interface  IOptionsKeyValue {
    [key: string]: any;
};


export type TBoxHomeOptions = {
    title: TTerm,
    description?: TTerm,
    boxImage?: string,
    link: string,
    linkState?: any,
    isExternLink?: boolean
    icon?: string,
    animated?: boolean,
    disabled?: boolean
}
export const BoxHomeOptions: TBoxHomeOptions[] = [
    {    
        title : {component: 'Global', text: 'AreaPersonalNotificaciones'},
        description : {component: 'Global', text: 'AreaPersonalNotificacionesDescripcion'},
        boxImage: imagenNotificaciones,
        link: '/perfil', 
        animated:true
    },
    {    
        title : {component: 'Global', text: 'CarpetaVirtual'},
        description : {component: 'Global', text: 'CarpetaVirtual Descripcion'},
        boxImage: imagenCartero,
        link: '/cartero-virtual', 
        animated:true

    },
    {    
        title : {component: 'Global', text: 'AUTOLIQUIDACIONES Y PAGO DE TRIBUTOS'},
        description : {component: 'Global', text: 'AUTOLIQUIDACIONES Y PAGO DE TRIBUTOS Descripcion'},
        boxImage: imagenAutoliquidaciones,
        link: '/tributos', 
        animated:true
    },
    {    
        title : {component: 'Global', text: 'TRAMITES'},
        description : {component: 'Global', text: 'TRAMITES Descripcion'},
        boxImage: imagenTramites,
        link: '/tramites',
        animated:true
    },
    {    
        title : {component: 'Global', text: 'Cita previa'},
        description : {component: 'Global', text: 'Cita previa Descripcion'},
        boxImage: imagenCitaprevia,
        link: '/cita-previa', 
        animated:true

    },  
    {    
        title : {component: 'Global', text: 'SERVICIOS'},
        description : {component: 'Global', text: 'SERVICIOS Descripcion'},
        boxImage: imagenAreaPersonal,
        link: '/oficinas',  
        animated:true
    }
]

// export const InicioOptions:any[] = [
//     {
//         'title' : {
//             'component': 'Global',
//             'text': 'AUTOLIQUIDACIONES Y PAGO DE TRIBUTOS'
//         },
//         'boxIcon':mdiFileAccount,
//         'boxImage': imagenIconoTributos,
//         'boxOptionList' : [
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/tributos/tributos-autonomicos',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Tributos autonomicos'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/tributos/tributos-locales',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Tributos locales'
//                 }
//             },
//             {
//                 'linkIconColor': 'purple',
//                 'link':'/cartero-virtual/pagoTelematico',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Pagar con documento'
//                 }
//             },
//         ]
//     },
//     {
//         'title' : {
//             'component': 'Global',
//             'text': 'CarpetaVirtual'
//         },
//         'boxIcon':mdiLaptop,
//         'boxImage':imagenCarteroEsquina,
//         'boxOptionList' : [
//             {
//                 'linkIconColor': 'red',
//                 'link':'/cartero-virtual/deuda-pendiente/',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Deuda pendiente'
//                 }
//             },
//             {
//                 'linkIconColor': 'green',
//                 'link':'/cartero-virtual/deuda-pagada/',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Deuda pagada'
//                 }
//             },
//             {
//                 'linkIconColor': 'purple',
//                 'link':'/cartero-virtual/pagoTelematico',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Pagar con documento'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/domiciliaciones/domiciliados/',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'TributosDomiciliados'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/domiciliaciones/domiciliables/',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'TributosDomiciliables'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'href': 'https://www.atib.es/TL/contenidoCartero.aspx?Id=12200&lang=',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Mas informacion'
//                 }
//             }
//         ]
//     },
//     {
//         'title' : {
//             'component': 'Global',
//             'text': 'Cita previa'
//         },
//         'boxIcon': mdiCalendar,
//         'boxImage': imagenCitaPreviaEsquina,
//         'boxOptionList' : [
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/cita-previa',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Cita previa'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/cita-previa/cancelar-cita',
//                 'optionName': {
//                     'component': 'CitaPrevia',
//                     'text': 'Cancelar cita'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/cita-previa/justificante',
//                 'optionName': {
//                     'component': 'CitaPrevia',
//                     'text': 'Justificante'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/cita-previa/mis-citas',
//                 'optionName': {
//                     'component': 'CitaPrevia',
//                     'text': 'Proximas citas'
//                 }
//             }
//         ]
//     },
//     {
//         'title' : {
//             'component': 'Global',
//             'text': 'Notificaciones'
//         },
//         'boxIcon': mdiBell,
//         'boxImage':imagenNotificacionEsquina,
//         'boxOptionList' : [
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/notificaciones/',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Mis notificaciones'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/notificaciones/alta-servicio',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Como darse de alta'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/notificaciones/dias-cortesia/',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Dias de cortesia'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/contenidos/mas_info_notificaciones_electronicas/',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Mas informacion'
//                 }
//             },
//         ]
//     },
//     {
//         'title' : {
//             'component': 'Global',
//             'text': 'TRAMITES'
//         },
//         'boxIcon': mdiMessageBulleted,
//         'boxImage':tramites,
//         'boxOptionList' : [
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/tramites/representaciones/gestion-de-representaciones',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Gestion de poderes'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/tramites/certificados?administracion=1',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'CertificadosDeudaTerceros'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/tramites/certificados',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'CertificadosDeuda'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/tramites',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Ver más trámites'
//                 }
//             }
//         ]
//     },
//     {
//         'title' : {
//             'component': 'Global',
//             'text': 'SERVICIOS'
//         },
//         'boxIcon': mdiShieldAccount,
//         'boxImage':imagenServiciosEsquina,
//         'boxOptionList' : [
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/oficinas',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Oficinas y servicios'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link': 'faqs',
//                 'optionName': {
//                     'component': 'FAQS',
//                     'text': 'PreguntasFrecuentes'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'href': 'https://sede.atib.es/general/tramites/personal/bandeja.aspx?lang=',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Mis comunicaciones'
//                 }
//             },
//             {
//                 'linkIconColor': 'grey',
//                 'link':'/perfil',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Area personal'
//                 }
//             }
//             ,{
//                 'linkIconColor': 'grey',
//                 'link': '/bienes-sujeto',
//                 'optionName': {
//                     'component': 'Global',
//                     'text': 'Mis bienes'
//                 }
//             }
//         ]
//     }
// ];
