import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IAllowSolicitud, ICampos, ICamposByDoc, ICamposOptions, IDetalleSolicitud, IDocumento, ISaveStepResponse, ISavedDoc, ISolicitud, ISubirDocsResponse, ResponseResidenciaFiscal, TApartado, TErroresReintegro } from "./abonoNacimiento.interfaces";
import { TDatosIdentificativos, TIdentificacionAbono, TInfoDoc, TModalidadSolicitud, TReintegroAbonoNacimiento, TSolicitudAbonoNacimiento, TTipo, TTipoDocGenera, TTipoEstado } from "containers/tramites/abono-nacimientos/steps/types";
import { EOficinaTipoDatos } from "gateways/oficinas.interfaces";



@inject('axios')
export class AbonoNacimientoGateway {

    constructor(
        private axios: AxiosInstance,
    ) { }

    public async getCamposStep(step: TApartado) {
        try {
            const response = await this.axios.get<ICampos[]>(`abonoNacimiento/campos-apartado?apartado=${step}`);

            if (response.status !== 200) {
                throw new Error("Bad response status: " + response.statusText);
            }

            return response.data;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getCamposDeclRespByDoc(step: TApartado, doc: TTipoDocGenera, modalidad: TModalidadSolicitud|undefined) {
        try {
            const response = await this.axios.get<{campos: ICamposByDoc[], literales: string[]}>(`abonoNacimiento/campos-apartado-decl-responsable?apartado=${step}&tipoDoc=${doc}&modalidad=${modalidad}`);

            if (response.status !== 200) {
                throw new Error("Bad response status: " + response.statusText);
            }

            return response.data;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getCamposOptionStep(step: TApartado): Promise<ICamposOptions> {
        try {
            const response = await this.axios.get(`abonoNacimiento/campos-apartado-options?apartado=${step}`);

            if (response.status !== 200) {
                throw new Error("Bad response status: " + response.statusText);
            }

            return response.data;

        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async getApartadosByRef<T extends TTipo>(referencia: string): Promise<T extends 'solicitud' ? TSolicitudAbonoNacimiento : TReintegroAbonoNacimiento> {
        try {
            const response = await this.axios.get(`abonoNacimiento/get-apartados-by-ref?referencia=${referencia}`);

            if (response.status !== 200) {
                throw new Error("Bad response status: " + response.statusText);
            }

            return response.data;

        } catch (error) {
            throw new Error(error as string);
        }
        
    }

    public async saveStep (idRef: string|null, apartado: TApartado, data: any, clean?: TApartado[], error?: {causa: string, tipoDoc: TTipoDocGenera}): Promise<ISaveStepResponse>{
        const response = await this.axios.post(`abonoNacimiento/save-apartado`, { referencia: idRef, apartado: apartado, json: data, apartadosBorrar: clean ?? [], esAltaError: error ?? null});

        if (response.status !== 200 && response.status !== 201 ) {
            throw new Error("Bad response status: " + response.statusText);
        }

        return response.data
    }

    public async getSolicitudByRef (ref: string): Promise<ISolicitud> {
        try {
            const response = await this.axios.get(`abonoNacimiento/get-solicitud-by-ref?referencia=${ref}`);

            if (response.status !== 200) {
                throw new Error("Bad response status: " + response.statusText);
            }

            return response.data;

        } catch (error) {
            throw new Error(error as string);
        }
        
    }

    public async getDetalleSolicitudById (id: number): Promise<ISolicitud> {
        try {
            const response = await this.axios.get(`abonoNacimiento/get-object-solicitud-by-id?idSolicitud=${id}`);

            if (response.status !== 200) {
                throw new Error("Bad response status: " + response.statusText);
            }

            return response.data;

        } catch (error) {
            throw new Error(error as string);
        }
        
    }

    public async getJustificantSolicitud (id: number): Promise<Blob>{
        const response = await this.axios.get(`abonoNacimiento/get-justificante?idSolicitud=${id}`, {responseType: 'blob',});
        
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getResolucionDegacionSolicitud (id: number): Promise<Blob>{
        // TipoDocumentoAdjunto  => 7: Resolucion denegación abono
        const response = await this.axios.get(`abonoNacimiento/get-documento-solicitud?idSolicitud=${id}&tipoDoc=7`, {responseType: 'blob'});
        
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getSolicitudesByNif():Promise<ISolicitud[]> {
        const response = await this.axios.get(`abonoNacimiento/get-solicitudes-by-nif`);
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }

    public async getJsonSolicitudById(id:number): Promise<TSolicitudAbonoNacimiento | null> {
        const response = await this.axios.get(`abonoNacimiento/get-json-solicitud-by-id?idSolicitud=${id}`);
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }

    public async allowSolicitud(datos: TDatosIdentificativos): Promise<IAllowSolicitud>{
        const response = await this.axios.post(`abonoNacimiento/allow-solicitud`, {
            familiaMonoparental: datos.familiaMonoparental,
            contribuyente: datos.contribuyente,
            progenitor: datos.progenitor, 
            derechoDeduccion: datos.derechoDeduccion,
            tipoSolicitud: datos.tipoSolicitud
        });

        if (response.status !== 200 && response.status !== 201) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }


    public async subirDocumentos(doc: TInfoDoc): Promise<string[]>{ //ISubirDocsResponse>{
        if (doc){ //}.doc) {
            const formData = new FormData();
            const filesArray = Array.from(doc)//.doc);
            for (const file of filesArray) {
                formData.append('files', file);
            }
            // const response = await this.axios.post(`abonoNacimiento/subir-documentos?key=${doc.key}`, formData);
            const response = await this.axios.post(`abonoNacimiento/subir-documentos`, formData);


            if (response.status !== 200 && response.status !== 201 ) {
                throw new Error("Bad response status: " + response.statusText);
            }

            return response.data;
        }

        return [];
    }

    public async getDoc(idDocumento: string): Promise<IDocumento> {
        try {
            const response = await this.axios.get(`abonoNacimiento/get-documento?idDocumento=${idDocumento}`);

            if (response.status !== 200) {
                throw new Error('Documento_no_encontrado');
            }
            return response.data
        } catch (e) {
            throw e
        }
    }

    public async deleteDoc(idDocumento: string): Promise<boolean> {
        try {
            const response = await this.axios.get(`abonoNacimiento/delete-documento?idDocumento=${idDocumento}`);

            if (response.status !== 200 && response.status !== 201) {
                throw new Error('Documento_no_borrado');
            }
            return response.data
        } catch (e) {
            throw e
        }
    }

    public async enlazarDocumentos(idRef: string, docs: ISavedDoc[]): Promise<boolean>{
        const response = await this.axios.post(`abonoNacimiento/enlazar-documentos`, { referencia: idRef, docs: docs});
        
        if (response.status !== 200 && response.status !== 201 ) {
            throw new Error("Bad response status: " + response.statusText);
        }

        return response.data
    }

    public async checkSolicitud(info: TIdentificacionAbono | null, nifSujeto: string): Promise<{existe:boolean, idSolicitud: number | null, causa: TErroresReintegro | undefined}>{
        if(info == null){ return {existe:false, idSolicitud: null, causa: undefined}}
        
        const response = await this.axios.post(`abonoNacimiento/existe-solicitud`, { 
            ejercicio: info.ejercicio,
            numSolicitud: info.numSolicitud,
            importe: info.importePercibido,
            fechaAlta:info.fechaSolicitud,
            nifSujeto: nifSujeto
        });

        if (response.status !== 200 && response.status !== 201 ) {
            throw new Error("Bad response status: " + response.statusText);
        }

        return response.data
    }

    public async comprobarResidenciaFiscal(nif:string, ejercicioPrevio:number, tipoDoc: TTipoDocGenera): Promise<ResponseResidenciaFiscal>{

        const response = await this.axios.get(`abonoNacimiento/check-residente-balear?nif=${nif.toUpperCase()}&ejercicio=${ejercicioPrevio}&tipoDocGenera=${tipoDoc}`);
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;

    }

    public async calcularImporteSolicitud (idRef: string): Promise<number | undefined>{
        const response = await this.axios.get(`abonoNacimiento/calcular-importe-solicitud?referencia=${idRef}`);
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
        
    }

    public async actualizarEstadoSolicitud (idSolicitud: number, estado: TTipoEstado): Promise<boolean>{
        const response = await this.axios.post(`abonoNacimiento/actualizar-estado-solicitud`, {
            idSolicitud: idSolicitud,
            codigoEstado: estado
        });

        if (response.status !== 200 && response.status !== 201) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }

    public async getURLPago (idSolicitud: number, idModelo: string| null): Promise<string| null>{
        if(idModelo === null){ return null;}
        const response = await this.axios.get(`abonoNacimiento/get-url-pago?idSolicitud=${idSolicitud}&idModelo=${idModelo}`);
        //console.log('response getURLPago', response)
        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }

    public async getBiOptionsByDoc(tipoDoc:TTipoDocGenera){
        const response = await this.axios.get(`abonoNacimiento/bi-limite-by-tipo-doc?tipoDoc=${tipoDoc}`);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }
    

    public async getRefDate(tipo: 'year'| 'quarter'|'month'| 'day'| 'week'| 'hour'| 'minute'| 'second'| 'millisecond',cantidad:number, fecha: string){
        const response = await this.axios.get(`abonoNacimiento/fecha-dias-habiles?tipoUnidad=${tipo}&cantidad=${cantidad}&fecha=${fecha}`);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }
    

    public async getDispoTipoDoc ( ejercicio: number, tipoDoc: TTipoDocGenera): Promise<{fechaInicio:string, fechaFin:string}>{
        const response = await this.axios.get(`abonoNacimiento/dispo-tipo-doc?ejercicio=${ejercicio}&tipoDoc=${tipoDoc}`);

        if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }

}
