import {FC, useContext, useEffect, useMemo, useState} from "react";

import { withStyles, WithStyles, TextField, TextFieldProps, LinearProgress, emphasize } from "@material-ui/core";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import duti_styles from "containers/DUTI/styles";
import { TSelect, TSelectDUTI } from "containers/DUTI/utils";
import { Autocomplete } from "@material-ui/lab";
import { cloneDeep } from "lodash";
import { TExtraValidation } from "./types";

type TMSGError = 'err_required' 
type TInput = Pick<TextFieldProps, 'className' |'disabled'| 'variant' | 'size' | 'margin'|'error'> & WithStyles
interface IInputSelect extends TInput {
    fieldTerm: string,
    value: TSelect|TSelectDUTI | undefined,
    options: TSelect[] |TSelectDUTI[],
    onChangeValue: (v: TSelect|TSelectDUTI|undefined, err: boolean) => void,
    required: boolean | undefined
    componentIG?: string
    errorMsgExterno?:string,
    fullWidth?:boolean,
};


const InputSelect : FC<IInputSelect>= ({
    classes, fieldTerm, value, options, onChangeValue, 
    required = false, error,
    variant='outlined', size='small', margin='dense',className ,
    disabled, componentIG, errorMsgExterno, fullWidth
}) => {
    const terms = useContext(LiteralsContext);

    const [err, setErr] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string|undefined>(undefined)
    const empyOption : TSelect = {id: '-', nombre: ""}
    const validate = (value: TSelect| TSelectDUTI | undefined) => {
        let msg : TMSGError|undefined = undefined
        const requiredErr = !required 
            ? false 
            : value && value.id ? false : true 

        if(requiredErr) msg='err_required'

        setErrMsg(msg ? translate('DUTI', msg,terms): undefined)
        setErr( requiredErr )
        return  requiredErr 
    }

    useEffect(() => {
        if(options && options.length>0){
            if(value === undefined && required && options.length === 1){
                onChangeValue(options[0], false)
            } 
        }
    }, [options, value])

    useEffect(() => {
        // setea el error que me envían desde fuera
        if(error !== undefined){ 
            setErr(error)
            if(errorMsgExterno && error ===true){ setErrMsg(errorMsgExterno)}
            if(error ===false){ setErr(false) }
        }
        if(value){validate(value)}
    }, [error, errorMsgExterno, value])


    //console.log('options',options, value)
    return (
        <div style={{display:'flex', flexDirection: 'row', alignItems:'center', marginRight: 10, flexGrow: fullWidth ? 1: 0}} >
            <Autocomplete<TSelect|TSelectDUTI|null, false, boolean, false>
                disabled={options.length===0 || disabled}
                autoComplete={true}
                multiple={false}
                freeSolo={false}
                options={options}
                value={ value === undefined ? null: value}
                defaultValue={null}
                onChange={(ev,new_value) => {
                    //console.log('onChange',new_value, typeof new_value, new_value !== null)
                    const err = validate( new_value !== null ? new_value: undefined)
                    onChangeValue( new_value !== null ? new_value : undefined , err)
                }}             
                disableClearable={required}
                getOptionLabel={(opt) => {
                    //console.log( 'getOptionLabel', opt)
                    return (opt && opt.nombre ? opt.nombre:  "")
                }}
                getOptionSelected={(opt, val) => {
                    //console.log('getOptionSelected ', val, opt )
                    return (val && opt && opt.id && val.id  ? val.id=== opt.id : false)
                }}
                onBlur={() => {
                    const err = validate(value)
                    err && onChangeValue(value, err)
                }}
                className={className ?? classes.inputDefault}
                renderInput={(params) => { 
                    //console.log(params)
                    return(
                        <TextField {...params} 
                            size={size}  
                            margin={margin}
                            label={translate(componentIG ?? 'DUTI',fieldTerm, terms).concat(required ? '*':'')} 
                            variant={variant}
                            InputLabelProps={{classes: {
                                root: classes.inputLabel,
                            }}}
                            error={err}
                            helperText={ err ? (errMsg ?? ' ') : ' '}
                        />
                    )
                }}
            />

        </div>
           
    )
}

export default withLiterals(['DUTI'])(withStyles(duti_styles)(InputSelect));