import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import IOficinaDto from "./oficinas.interfaces";


@inject('axios')
export default class OficinasGateway {
    constructor(
        private axios: AxiosInstance
    ) {
        if (!axios) {
            throw new Error('OficinasGateway requires an axios instance');
        }
    }

    public async findAll(): Promise<IOficinaDto[]> {
        const response = await this.axios.get('oficinas');

        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async getFilters(): Promise<any> {
        const response = await this.axios.get('oficinas/filters');
        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;

    }

    public async findOne(id: number): Promise<IOficinaDto> {

        const response = await this.axios.get('oficinas/' + id);
        if (response.status !== 200) {
            throw new Error('Bad response status: ' + response.statusText);
        }
        return response.data;
    }
}