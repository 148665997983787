import {FC, useContext, useEffect, useMemo, useState} from "react";
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, withStyles, WithStyles, Button, Grid } from "@material-ui/core";
import { IComprador, TDesgloseDirFiscal, TDireccionFiscal, TTipoDirFisc } from "containers/DUTI/steps/types";
import { translate } from "utils/i18n";
import duti_styles from "containers/DUTI/styles";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import FullLoading from "../full-loading";
import { AuxDirFormater, direccionToText, domicilioCatastroToDireccion, formatSelectDUTIOptions, formatSelectOptions } from "containers/DUTI/utils";
import RadioButtonLabelComponent, { IRadioButtonLCOption } from "./radio-button-labelComponent";
import DireccionRBT from "./direccion-rbt";
import { mdiAccountReactivate } from '@mdi/js';
import Icon from "@mdi/react";
// Logo AEAT - TODO change
import AEAT2 from 'resources/dusti/AEAT_listado2.jpg';
import DirectionATIB, { EMPTY_DIRECTION, EMPTY_DIRECTION_ERRORs, TDirectionATIB, TDirectionFlags } from "components/DUTI/direction-form-ATIB";
import { SujetosGateway } from "gateways/sujetos.gateway";
import { GeoGateway } from "gateways/geo.gateway";
import { Provincia, Sigla } from "gateways/geo.interface";
import { IDUTIMunicipio } from "gateways/duti.interface";

interface IProps extends WithStyles {
    idDeclaracion: number
    show: boolean,
    sujetoPasivo: IComprador,
    inmubleDirecion: string | null,
    inmubleRefCat: string,
    dirFiscal: string,
    onSave: (nif:string, dir:TDireccionFiscal | undefined, changes: boolean) => void
    onClose: () => void
}

const ModalCambioDireccionFiscal: FC<IProps> = ({classes,idDeclaracion, show, sujetoPasivo, inmubleDirecion,inmubleRefCat, dirFiscal, onSave, onClose}) => {
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext)
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const sujetosGateway: SujetosGateway = useMemo(() => ioc.get(SujetosGateway), [ioc]);
    const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);


    const [loading, setLoading] = useState(false);
    const [haveChanges, setHaveChanges] = useState(false);
   
    const [OPTIONS, setOPTIONS] = useState<IRadioButtonLCOption[]>([])
    const [selected, setSelected] = useState<TTipoDirFisc|undefined>(undefined)
    const [requiredDesglose, setRequiredDesglose] = useState(false);
    const [showCheckRequireds, setShowCheckRequireds] = useState(false);
    const [desgloseDir, setDesgloseDir] = useState<TDirectionATIB>(EMPTY_DIRECTION)
    const [dirErr,setDirErr]= useState<TDirectionFlags>(EMPTY_DIRECTION_ERRORs)
    const [idPaso, setIdPaso]= useState<number>(11); // Por defecto 11 que es DesgloseDirFiscal
    const [auxFormater, setAuxFormater]= useState<AuxDirFormater | null>(null)
    
    const dirOK = useMemo(() =>( 
        desgloseDir.pais.id && desgloseDir.provincia?.id && desgloseDir.cp && desgloseDir.tipoVia?.id && desgloseDir.nombreVia 
        && desgloseDir.numero && desgloseDir.cp && desgloseDir.poblacion
        && !Object.values(dirErr).includes(true) 
        ? true: false
    ), [desgloseDir, dirErr])
    
    const handleSave = async () => {
        try {
            // Add desglose Nueva dir en XML
            if(selected ){
                if(selected === 'fiscal'){
                    const opt = OPTIONS.find(opt=> opt.id === selected)
                    onSave(sujetoPasivo.nif, opt ? {tipo: opt.id, direccion: opt.value}: undefined, haveChanges) 
                } else {
                    // Validate CP
                    if(dirOK){
                        const response= await dutiGateway.savePaso<TDesgloseDirFiscal>(idDeclaracion, idPaso, desgloseDir)
                        if(!response.saved){
                            throw new Error(translate('DUSTI', 'not_saved_desgloseDirAEAT', terms))
                        }
                        const opt = OPTIONS.find(opt=> opt.id === selected)
                        onSave(sujetoPasivo.nif, opt ? {tipo: opt.id, direccion: opt.value}: undefined, haveChanges) 
  
                    } else {
                        setShowCheckRequireds(true);
                    }
                }
            } 
        } catch (error) {
            const msg = (error as Error).message
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    variant: 'error',
                    message: msg
                }
            })
        }
    }

    useEffect(() => {
        if(show){
            setLoading(true)
            const dirSuj= direccionToText(sujetoPasivo.direccion, terms)
            const options: IRadioButtonLCOption[]=  [
                {id:'fiscal', label: <DireccionRBT term={'rbt_dir_fiscal'} dir={dirFiscal}/>, value: dirFiscal},
                {id:'sujeto', label: <DireccionRBT term={'rbt_dir_sujeto'} dir={dirSuj}/>, value: dirSuj },
            ]
            if(inmubleDirecion){
                options.push( {id:'inmueble', label: <DireccionRBT term={'rbt_dir_inmueble'} dir={inmubleDirecion}/>, value:inmubleDirecion })
            }

            if(sujetoPasivo.direccionFiscal ){
                const val = options.find(o => o.id === sujetoPasivo.direccionFiscal?.tipo )
                setSelected(val?.id)
                setHaveChanges(false)
            }
            setOPTIONS(options)
            setLoading(false)   
        }
        if(!auxFormater){
            (async()=>{
                const optionsPaises = await sujetosGateway.getPaises()
                const PAIS = optionsPaises.find(p =>( p.idPais === '00'))
                const optionsProvincias = await geoGateway.getProvincias('')
                const IB = optionsProvincias.find(p => p.idProvincia === '07') ?? { idProvincia: '07', nombre: 'ILLES BALEARS'}
                const optionsTipoViaBBDD= await geoGateway.getSiglas()
                const optionsTipoVia= optionsTipoViaBBDD.map(item => ({...item, nombre: translate('GLOBAL',item.nombre,terms)}))
                const optionsMunicipios= await dutiGateway.getAllMunicipios()
                setAuxFormater({
                    //pais: formatSelectOptions<IPais>('idPais','nombre',optionsPaises), 
                    provincia: formatSelectOptions<Provincia>('idProvincia','nombre',[IB]), // SOLO admite Illes balears 
                    municipio: formatSelectDUTIOptions<IDUTIMunicipio>('id','Codigo','Nombre',optionsMunicipios),
                    tipoVia: formatSelectOptions<Sigla>('idSiglas','nombre', optionsTipoVia)
                })
            })()
        }
    },[sujetoPasivo, show])

    useEffect(() => {
        if(selected){ // Direccion actual
            if(selected === 'fiscal'){
                setRequiredDesglose(false)
                setDesgloseDir(EMPTY_DIRECTION)
                return;
            }

            (async() => {
                setLoading(true)
                const result = await dutiGateway.checkDesgloseDir(selected, sujetoPasivo.nif, inmubleRefCat)
                if(result.idPaso){ setIdPaso(result.idPaso)}
                if(result.ok && result.dir){
                    setRequiredDesglose(true)
                    setDesgloseDir(result.dir)
                } else {
                    //setRequiredDesglose(true)
                    // Pre craga 
                    const opt = OPTIONS.find(opt=> opt.id === selected)
                    const dir = opt && opt.value && auxFormater ? domicilioCatastroToDireccion(opt.value, auxFormater) : null
                    //console.log('domicilioCatastroToDireccion', dir)
                    // Transform dir TO format
                    setDesgloseDir(/*dir ??*/ EMPTY_DIRECTION)
                }
            
                setLoading(false)
            })();
        }
       
    },[selected])

 

    if (!sujetoPasivo) return null;
    
    return (   
        <Dialog open={show} fullWidth maxWidth='lg'>
            <FullLoading loading={loading} animation={{type:'intricom',name:'DUSTI'}}/>
            <DialogTitle>
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    <Typography style={{fontSize: 25, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>{translate('DUTI', 'title_modal_domFiscal', terms)}</Typography>
                </div>
            </DialogTitle> 

            <DialogContent>
                <div style={{display: "inline-flex", alignItems:'center', padding: '0px 20px 20px 20px'}}>
                    <Icon path={mdiAccountReactivate} color= "#2196f3" size={1.5}/>
                    <Typography style={{paddingLeft: 10}}>{sujetoPasivo.nomApe}</Typography>
                </div>
                
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={3}>
                        <img width={'100%'} height={'100%'} style={{objectFit:'scale-down'}} src={AEAT2} alt='logo-aeat' />
                    </Grid>
                    <Grid item xs={9}>
                        <RadioButtonLabelComponent
                            fieldTerm='direccionFiscal'
                            value={selected}
                            options={OPTIONS}
                            onChangeValue={ (v: TTipoDirFisc|undefined, err: boolean) =>{ 
                                setHaveChanges(true)
                                setSelected(v)
                            }}
                        />
                    </Grid>
                    {requiredDesglose && 
                        <Grid item xs={11} style={{ marginTop: 10}}>
                            <Typography style={{marginBottom: 10}}>{translate('DUTI','desglose_dir',terms)}</Typography>
                            {showCheckRequireds && 
                                <Typography color="error" style={{marginBottom: 10}}>{translate('DUTI','desglose_dir_check_requireds',terms)}</Typography>
                            }
                            <DirectionATIB 
                                direction={ desgloseDir } 
                                setDirection={(d) => { setDesgloseDir(d); setShowCheckRequireds(false)}}
                                dirErrors={dirErr}
                                setDirErrors={setDirErr}
                                isCambioDomFisc={true}
                                disabled={{
                                    cp: false,
                                    escalera: false,
                                    letra: false,
                                    municipio: false,
                                    nombreVia: false,
                                    numero: false,
                                    pais:true,
                                    piso: false,
                                    provincia: true,
                                    poblacion: false,
                                    puerta: false,
                                    tipoVia: false,
                                    bloque: false,
                                    portal: false
                                }}      
                            />
                            
                        </Grid>
                    }
                </Grid>
                    
               
            </DialogContent>
            
            <DialogActions>
                <div style={{display: 'flex',justifyContent:'flex-end', paddingRight: 10, paddingBottom: 10}}>
                    <Button style={{marginRight: 15}}
                            variant="contained" color="inherit"
                            onClick={onClose} 
                        >
                        {translate('DUTI', 'btn_close',terms)}
                    </Button>
                    <Button
                        variant="contained" color="primary"
                        onClick={handleSave}
                        disabled={!selected}
                    >
                        {translate('DUTI', 'btn_continuar',terms)}
                    </Button>
                </div>
            </DialogActions>
        
        </Dialog> 
    )   

}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(ModalCambioDireccionFiscal));