import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IPopUpsContentItem, IPopUpsContentRequest } from "./pop-ups.interfaces";

@inject("axios")
export class PopUpsGateway {
  constructor(private axios: AxiosInstance) {}

  public async getPopUps(url: string): Promise<IPopUpsContentItem[]> {
    const popupContentRequest: IPopUpsContentRequest = { 'url': url, 'entorno': 'WEB' }
    let response = await this.axios.post("/pop-ups/get-content/", popupContentRequest);
    return response.data;
  }
}
