import { StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules => ({
    // HEIGHT & WIDTH
    grid1: {
        display: 'grid',
        gridTemplateColumns: "1fr 2fr 2fr 2fr",
        gridTemplateRows: "repeat(2, 1fr)",
        width: '100%',
    },
    logo: {
        width: '70%',
        gridRow:"1 / span 2"
    },
    concepte:{
        gridColumn:"2 / span 2"
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        fontWeight: "bold"
    },
    title:{
        padding: "15px 0px ",
        fontWeight: "bold",
        borderBottom: "1px solid black",
        marginBottom: "20px",
        width: "100%",
        marginTop: "30px"
    },
    flexWrap: {
        display: "flex",
        justifyContent: "space-between",
        gap: "30px",
        flexWrap: "wrap"
    },
    flex: {
        display: "flex",
        justifyContent: "space-between",
        gap: "30px",
    },
    buttonProgress: {
        position: 'relative',
        right: '8%',
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
        }
    },
});

export default styles;