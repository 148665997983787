import React, { ReactComponentElement } from 'react';
import { WithStyles, withStyles, Theme, Paper, Tabs, Tab } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';


interface IProps {
    labelTab1: string | JSX.Element;
    labelTab2: string | null;
    tabActive: number;
    onChange: (_E: React.ChangeEvent<{}>, newValue: number) => void;
}

const CustomTabs: React.FC<IProps> = (props) => {
    const { labelTab1, labelTab2, tabActive, onChange } = props;

    return (
        <Tabs
            value={tabActive}
            indicatorColor="primary"
            textColor="primary"
            onChange={onChange}
            style={{ width: '100%' }}
        >
            <Tab label={labelTab1} />
            {labelTab2 && <Tab label={labelTab2} />}

            {/* CHILDREN */}
            {props.children}
        </Tabs>
    );
}

export default CustomTabs;
