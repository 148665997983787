import React, { FC, useContext } from "react";

import { Grid, StyleRules, Theme, WithStyles, withStyles } from "@material-ui/core";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";


import GenericInputText, { TExtraValidation } from "components/generic-input-text";
import { nifValidate } from "./utils";
export type TNifNomApe = {
    nif: string | undefined
    nomApe: string | undefined
}



interface NifNomApeProps extends WithStyles<typeof styles> {
    nif: string | undefined;
    nomApe: string | undefined;
    onChange: (key: keyof TNifNomApe, value: string|undefined, err: boolean) => void
    labelNif?: string,
    labelNomApe?:string,
    componentIG?: string,
    required?:{
        nif: boolean, nomApe:boolean
    }
    extraNifValidation?: (nif: string) => TExtraValidation

};

const NifNomApe: FC<NifNomApeProps> = ({
    classes, nif, nomApe, onChange,
    labelNif='nif', labelNomApe='nomApe',componentIG='GenericComponents',
    required={nif:true, nomApe:false},
    extraNifValidation
}) => {
    const terms =useContext(LiteralsContext);
    
    return (
        <Grid container direction="row" alignItems="center" >
            <GenericInputText
                componentIG={componentIG}
                labelTerm={labelNif}
                value={nif}
                required={required.nif}
                onChangeValue={(v: string|undefined, err: boolean) =>  onChange('nif', v, err)}
                extraValidation={(nif: string) => {
                    let result = nifValidate(nif)
                    if(!result.error && extraNifValidation){
                        result = extraNifValidation(nif)
                    }
                    return result
                }}
                forceUpperCase
            />
            <GenericInputText
                componentIG={componentIG}
                labelTerm={labelNomApe}
                value={nomApe}
                required={required.nomApe}
                onChangeValue={(v: string|undefined, err: boolean) =>  onChange('nomApe', v, err)}
                forceUpperCase
                className={classes.input360}
            />
        </Grid>

    )
};

const styles = (theme: Theme): StyleRules => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    input360: {
        width: 360
    }
});
export default withLiterals(ALL_LITERALS)(withStyles(styles)(NifNomApe));