import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { createValidationError } from "utils/response";
import { ILiteralsComponent } from "./i18n.response.interface";
import { getAccessToken } from "hooks/page.hook";

@inject('axios')
export class I18nGateway {
    private readonly project = 'SEDE';

    constructor(
        private axios: AxiosInstance
    ) { }

    public async getComponentLiterals(components: string[], language: string): Promise<ILiteralsComponent> {
        try {
            const response = await this.axios.get('/i18n/getComponentLiterals', {
                params: {
                    components,
                    project: this.project,
                    language
                }
            });

            if (response.status === 403) {
                return {};
            } else if (response.status !== 200 && response.status !== 403) {
                throw new Error('Bad response status: ' + response.statusText);
            } 
            
            return response.data;
        } catch (e) {
            throw createValidationError(e);
        }
    }

    public async createComponent(component?:string): Promise<boolean> {
        try {
            const accessToken = getAccessToken();
            if (accessToken !== null) {
                const response = await this.axios.post('/i18n/createComponent', {
                        component,
                        project: this.project,
                });

                if (response.status !== 200 && response.status !== 403) {
                    throw new Error('Bad response status: ' + response.statusText);
                }
                
                return response.data;
            }

            return false;
        } catch (e) {
            throw createValidationError(e);
        }
    }

    public async createTerm(term: string, component?:string): Promise<boolean> {
        try {
            const accessToken = getAccessToken();
            if (accessToken !== null) {
                const response = await this.axios.post('/i18n/createTerm', {
                        component,
                        literal: term,
                        project: this.project,
                });

                if (response.status !== 200 && response.status !== 403) {
                    throw new Error('Bad response status: ' + response.statusText);
                }
                
                return response.data;
            }

            return false;
        } catch (e) {
            throw createValidationError(e);
        }
    }

    public async updateTranslatedText(term: string, language: string, translatedText?: string, component?:string): Promise<boolean> {
        try {
            const accessToken = getAccessToken();
            if (accessToken !== null) {
                const response = await this.axios.put('/i18n/updateTranslatedText', {
                        component,
                        literal: term,
                        translatedText,
                        language,
                        project: this.project,
                });

                if (response.status !== 200 && response.status !== 403) {
                    throw new Error('Bad response status: ' + response.statusText);
                }
                
                return response.data;
            }

            return false;
        } catch (e) {
            throw createValidationError(e);
        }
    }
}
