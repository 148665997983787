import { FC,   useContext,   useEffect,   useMemo, useRef, useState,} from "react";
import { StepComponentProps } from "components/generic-stepper/generic-step-component";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import ButtonsStepper from "containers/tributos-autonomicos-locales/componentesAsistente/shared/buttonsStepper";
import { Grid, Typography } from "@material-ui/core";
import { StyleRules, WithStyles, withStyles } from "@material-ui/styles";
import FullLoading from "components/full-loading";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import { AbonoNacimientoGateway } from "gateways/tramites/abonoNacimiento.gateway";
import { Theme } from "pretty-format";
import { translate } from "utils/i18n";
import { camposToObject } from "../utils";
import useCommonStyles from "./styles";
import { TIdentificacionAbono } from "./types";
import GenericInputDate from "components/generic-input-date";
import GenericTypedInputNumber from "components/generic-input-number/typed-input-number";
import GenericStepSchema from "components/generic-stepper/generic-step-schema";
import GenericInputText from "components/generic-input-text";
import GenericInputNumber from "components/generic-input-number";
import EndProcessMSG from "components/Modelos/componentes/endProcessMSG";



type IdentificacionAbonoProps = WithStyles<typeof styles> & StepComponentProps

const IdentificacionAbono: FC<IdentificacionAbonoProps>  = ({classes, idRef, apartado, onBack,onNext,onCancel, isFirst, isLast}) => { 
    const CommonClasses= useCommonStyles()
    const terms=useContext(LiteralsContext)
    const [,alertsDispatch] = useContext(AlertsContext);
    const componentMounted = useRef(true);
    // Gateways
    const ioc = useContext(IoC);
    const abonoNacimientoGateway: AbonoNacimientoGateway = useMemo(() => ioc.get(AbonoNacimientoGateway), [ioc]);

    const [loading, setLoading]= useState(false);
    const [showMSG, setShowMSG]= useState(false);

    const [identificacion_abono,setIdentificacionAbono] = useState<TIdentificacionAbono|null>(null);
    const [nifSujeto, setNifSujeto] = useState('')
    const [nifSujetoLabel, setNifSujetoLabel] = useState('')
    const [errors,setErrors] = useState<Record<keyof TIdentificacionAbono, boolean> | null>(null);


   


    // -- Memos    ---------------------------------------------------------------------------------------------------------------------------
    const disableNEXT = useMemo(() => {
        // Validacion Fin
        //console.log(errors, identificacion_abono)
        const err = errors ? Object.values(errors): [true];
        return ( 
            !err.includes(true)
            && identificacion_abono 
            && identificacion_abono.importePercibido 
            && identificacion_abono.ejercicio && identificacion_abono.ejercicio > 0
            && identificacion_abono.numSolicitud && identificacion_abono.numSolicitud>0
            && nifSujeto !== ''
            && identificacion_abono.fechaSolicitud ) ? false: true;
        },[identificacion_abono, errors]);

    // -- Functions --------------------------------------------------------------------------------------------------------------------------
    const handleNext = async () => {
        try {
            setLoading(false)
            const isValid =identificacion_abono ? await abonoNacimientoGateway.checkSolicitud(identificacion_abono, nifSujeto): false
            if(isValid){
                const response = await abonoNacimientoGateway.saveStep(idRef,'identificacion_abono',identificacion_abono)
                if(response.saved && response.referencia){
                    onNext(response.referencia)
                } else{
                    if(response.error){
                        alertsDispatch({
                            type:'show-alert',
                            payload: {
                                variant:'error',
                                message: translate('AbonoNacimiento',response.error,terms) + translate('AbonoNacimiento','identificacion_abono',terms)
                            }
                        })
                    } else {throw new Error ('ErrorSaveStep')}
                }
            } else{
                setShowMSG(true)
            }
            
        } catch (error) {
            alertsDispatch({
                type:'show-alert',
                payload: {
                    variant:'error',
                    message: translate('AbonoNacimiento','ErrorSaveStep',terms) + translate('AbonoNacimiento','identificacion_abono',terms)
                }
            })
            
        } finally {
            setLoading(false)
        }
    }


    const onChange = <T extends keyof TIdentificacionAbono>(key: T, value: TIdentificacionAbono[T], error: boolean) => {
        if(showMSG){setShowMSG(false)}
        setIdentificacionAbono( curr => curr && ({...curr, [key]: value}))
        setErrors(curr => curr &&  ({...curr, [key]: error}))
    }

    // -- Effects  ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                //  INICIALIZACIÓN
                if(!idRef ){ throw new Error('not-idRef')}
                const campos =  await abonoNacimientoGateway.getCamposStep('identificacion_abono')
                const emptyObject= camposToObject(campos)
                const emptyErr : Record<keyof TIdentificacionAbono, boolean> = {
                    fechaSolicitud: false,
                    importePercibido: false,
                    ejercicio: false,
                    numSolicitud: false,
                    idSolicitud: false
                }
                let initVal = {...emptyObject} as TIdentificacionAbono
                const response = await abonoNacimientoGateway.getApartadosByRef<'reintegro'>(idRef);
                if(response.identificacion_abono){
                    initVal = response.identificacion_abono
                }
                
                if(componentMounted.current){
                    setIdentificacionAbono(initVal)
                    setNifSujeto(response.datos_identificativos?.contribuyente?.nif ?? '')
                    setNifSujetoLabel(response.datos_identificativos?.tipoSolicitud === 'anticipado' ? 'nifContribuyente': 'nifSolPrincipal')

                    
                    setErrors(emptyErr)
                }

            } catch(err){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        variant:'error',
                        message: translate('AbonoNacimiento','ErrorLoadingStep',terms)
                    }
                })
            } finally {
                setLoading(false)
            }
            
        })();

        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
        
    },[idRef, apartado]);

    return (
        <GenericStepSchema
            isFirst={isFirst}
            isLast={isLast}
            onNext={ handleNext }
            disabledNext={(disableNEXT || showMSG)}
            onBack={onBack}
            disabledBack={false}
            onExit={onCancel}
            forceExit={showMSG} 
            idRef={idRef} 
            allowContinuarMasTarde={false}
            withRecoverRefContinuarMasTardeModal={false}
            withContinuarMasTardeModal={true}
        >
            
            <FullLoading loading={loading}/>
            {identificacion_abono && 
                <Grid item className={CommonClasses.root}>
                    <Grid item className={classes.row}>
                        <GenericInputText
                            componentIG='AbonoNacimiento'
                            labelTerm={nifSujetoLabel}
                            value={nifSujeto}
                            required
                            disabled
                            onChangeValue={() => {}}
                        />
                        <GenericInputDate
                            componentIG="AbonoNacimiento"
                            labelTerm='fechaSolicitud' 
                            value={identificacion_abono.fechaSolicitud}
                            onChangeValue={ (v:Date | undefined, err:boolean) => onChange('fechaSolicitud', v, err)}
                            required
                            maxDate={new Date()}
                            className={classes.input470}
                            //moreInfo?: { template: string, size?: TInfoTemplateSize},
                            //errorMsgExterno?:string,
                            //extraValidation?
                        />
                        <GenericTypedInputNumber 
                            type="money"
                            required
                            allowZero={true}
                            labelTerm="importe"
                            componentIG="AbonoNacimiento"
                            value={identificacion_abono?.importePercibido}
                            onChangeValue={(v, err) => onChange('importePercibido', v, err)}
                        />
                    </Grid>

                    <Grid item className={[classes.row, classes.top].join(' ')}>
                        <Typography style={{marginTop:15, height: 65, marginRight:5}}>{translate('AbonoNacimiento','refSolicitud',terms)}:</Typography>
                        <GenericTypedInputNumber 
                            type="year"
                            required
                            className={classes.inputXS}
                            labelTerm="ejercicio"
                            componentIG="GenericComponent"
                            value={identificacion_abono.ejercicio}
                            onChangeValue={(v, err) => onChange('ejercicio', v, err)}
                        />
                        <Typography style={{ fontSize: 45, alignContent: 'flex-start', lineHeight:1.2, marginRight: 5, fontWeight: 100 }}>/</Typography>
                        <GenericInputNumber 
                            labelTerm='numSolicitud'
                            componentIG='AbonoNacimiento'
                            value={identificacion_abono.numSolicitud}
                            required
                            allowZero={false}
                            maxDecimal={0}
                            min={1}
                            onChangeValue={(v:number, err:boolean) =>onChange('numSolicitud',v,err)}
                            error={errors?.numSolicitud }
                            className={classes.inputXS}
                        />
                    </Grid>

                    {showMSG && 
                        <div>
                            <EndProcessMSG componentIG='AbonoNacimiento' termIG="solicitudNoEncontrada" type={'error'}/>
                        </div>
                    }
                </Grid>
            }
            
        </GenericStepSchema>

    )
}


const styles = (theme: Theme): StyleRules => ({
    textContainer:{
        padding: '0px 10px 0px 10px'
    },
    row: {
        display:'flex',
        flexDirection:'row',
        flexWrap: 'wrap'
    },
    input470: {
        width: 470
    },
    inputXS:{
        width:150,
    },
    top: {
        alignItems: 'flex-start'
    },
    text:{
        paddingTop: 30,
        paddingLeft: 10,
        paddingBottom: 30,
        paddingRight: 30,
    },
    bold: {
        fontWeight: 'bolder'
    }
});


export default withLiterals(['Global', 'Tramites','AbonoNacimiento' ])(withStyles(styles)(IdentificacionAbono));
