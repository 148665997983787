
import { inject } from "aurelia-dependency-injection";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { IDatosBienesInmuebles, IDatosCatastroByRefCatastral, TInmuebleCatastro } from "./catastro.interface";
import { CustomError } from "utils/custom.error";

@inject('axios')
export default class CatastroGateway {
    constructor(
        private axios: AxiosInstance
    ) {
        if (!axios) {
            throw new Error('ModelGateway requires an axios instance');
        }
    }

     // Adpatación de objetos
    private getFormattedInfoInmueble = (refCatastral:string, info: IDatosBienesInmuebles): TInmuebleCatastro => {
        const out: TInmuebleCatastro = {
            datosEconomicos: {
                anyoValorCatastral: info.bienInmueble.datosEconomicos.avc,
                valorCatastral: info.bienInmueble.datosEconomicos.vcat,
                valorCatastralSuelo: info.bienInmueble.datosEconomicos.vcs,
                valorCatastralConstruccion: info.bienInmueble.datosEconomicos.vcc,
                valorReferencia: info.bienInmueble.datosEconomicos.vref,
                superficieConstruida: info.bienInmueble.datosEconomicos.sfc,
                uso: info.bienInmueble.datosEconomicos.luso,
            },
            refCatastral: refCatastral,
            tipoBien: info.bienInmueble.informacionCatastral.codigo,
            direccion: info.bienInmueble.domicilio,
            urlCatastro: info.urlCatastro,
            urlGoogle: info.urlGoogle
        }
    
        return out
    }

    public async getInmuebleByRefCatastral (ref: string) : Promise<{ 
        inmueble: TInmuebleCatastro|null, 
        ok:boolean,
        error: {codigo: string, descripcion:string, componentIG: string|undefined} | null
    }>{
        // MOCK
        // return {inmueble: {
        //     refCatastral: "1899011DD7819N0001FF",
        //     tipoBien: "UR",
        //     direccion: "CL ORQUIDEAS-FONSE 27 Es:1 Pl:00 Pt:01  07193 BUNYOLA (ILLES BALEARS)",
        //     datosEconomicos: {
        //         anyoValorCatastral: "2024",
        //         valorCatastral: "176538,71",
        //         valorCatastralSuelo: "65054,74",
        //         valorCatastralConstruccion: "111483,97",
        //         superficieConstruida: "393",
        //         uso: "Residencial"
        //     },
        //     urlCatastro: '',
        //     urlGoogle: '',
        // }, ok: false, error: null}
         
        try {
            const response: AxiosResponse<IDatosCatastroByRefCatastral> = await this.axios.get(
                `catastro/getDatosCatastroByRefCatastral`, {
                params: {referenciaCatastral: ref},
                timeout: 60000
            })
            if (response.status !== 200) {
                throw new CustomError(response.statusText, response.status);
            }
            if( !response.data.ok ){
                let err ;
                if(response.data.error && response.data.error.codigo === "9999"){
                    err={codigo: response.data.error.codigo ,descripcion: 'error_pinbal',  componentIG: 'DUTI'}
                } else {
                    err={codigo: response.data.error?.codigo ?? "",descripcion: 'error_get_refCatastral',  componentIG: 'DUTI'}
                }
                return {inmueble: null, ok: response.data.ok, error: {...err}}
            }
            if(response.data.ok && response.data.bienesInmuebles && response.data.bienesInmuebles.length === 1){
    
                return {
                    inmueble: this.getFormattedInfoInmueble(ref,response.data.bienesInmuebles[0]), 
                    ok: response.data.ok, 
                    error: null
                }
            }
            return {inmueble: null, ok: false, error: null}
        } catch (error){
            let err;
            if (error instanceof CustomError && error.code === 403) {
                err={codigo: error.code.toString(), componentIG:'Global', descripcion:'sesion_caducada'}
            } else {
                err={codigo: (error as AxiosError).message, componentIG: 'DUTI', descripcion: 'error_get_refCatastral' }
            }
           return {inmueble: null, ok: false, error: {...err}}
        }
        
    }
}




