import React, { useCallback, useContext } from 'react';
import { WithStyles, withStyles, Theme, Card, CardContent, Grid, Button } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';
import Term from 'components/term';
import { MoreInfoContext } from 'contexts/more_info.context';

const styles = (theme: Theme): StyleRules => ({
    circuloNumero: {
        color: 'white',
        backgroundColor: '#868686',
        width: 30,
        height: 30,
        textAlign: 'center',
        borderRadius: '50%',
        fontSize: 22,
        marginRight: 15,
    },
    flex: {
        flex: 1
    }
});

interface IProps extends WithStyles<typeof styles> {
    step: number,
    title: string,
    subTitle?: string | null,
    disabled?: boolean,
    moreInfo?: string | null

}

const ExpedienteCard: React.FC<IProps> = ({ classes, step, title, moreInfo, children, subTitle }) => {
    const [, infoDispatch] = useContext(MoreInfoContext);
    const handleShowMoreInfo = useCallback(() => {
        if (moreInfo) {
            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: moreInfo,
                }
            });
        }
    }, [infoDispatch, moreInfo])

    const renderContent = () => {
        return (
            <>
                <Grid>
                    <Grid container>
                        {(step !== -1 ) && 
                            <div className={classes.circuloNumero}>
                                {step}
                            </div>
                        }
                        <Grid item className={classes.flex}>
                            <small><b><Term component="Tramites" text={title} /></b></small>
                            <br />
                            {
                                !!subTitle
                                    ?
                                    <small><Term component="Tramites" text={subTitle} /></small>
                                    :
                                    null
                            }

                        </Grid>
                        <Grid item>
                            {
                                moreInfo !== null && moreInfo !== undefined && step!== -1 
                                    ?
                                    <Button variant="contained" color="default" size="small" className={classes.button} onClick={handleShowMoreInfo}>
                                        <Term component="Global" text="+ INFO" />
                                    </Button>
                                    :
                                    null
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    {children}
                </Grid>
            </>
        )
    }

    return (
        <>
            {(step === -1 ) ? 
                renderContent()
            : 
                <Card >
                    <CardContent className={classes.contenedorCard}>
                    {renderContent()}
                    </CardContent>
                </Card>
            }
        </>
    );
}

export default withStyles(styles)(ExpedienteCard);