
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Grid,  IconButton, Typography, WithStyles, withStyles } from "@material-ui/core";

import styles from "./styles";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { translate } from "utils/i18n";
import { mdiBriefcaseOutline, mdiEyeCircleOutline, mdiTrashCan } from "@mdi/js";
import usePage from "hooks/page.hook";
import MuniAndRefCatInfo from "components/Modelos/componentes/muniAndRefCatInfo";
import { TSelect, formatSelectOptions } from "containers/DUTI/utils";
import { TInmuebleCatastro } from "gateways/catastro.interface";
import IoC from "contexts/ioc.context";
import ModelGateway from "gateways/model.new.gateway";
import { IMunicipioPLV } from "gateways/model.new.interface";
import { AlertsContext } from "contexts/alerts.context";
import { ContenidosGateway } from "gateways/contenido.gateway";

import GenericInputDate from "components/generic-input-date";
import GenericTypedInputNumber from "components/generic-input-number/typed-input-number";
import GenericLabelWithInfo from "components/generic-label-with-info";
import GenericInputSelect from "components/generic-input-select";
import { getTiposDerechoTransmsisionByTipoAsistente } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/utils";
import GenericInputNumber from "components/generic-input-number";
import { TCalculoError, TCalculoSimulador, TData, TDatosPrevios, TOperacionSimulador, TTipoTransmSimuladorPLV } from "./types";
import CalcPlusvaliaSimuladorAddCalculo from "./calcPlusvaliaSimuladorAddCalculo";
import { cloneDeep } from "lodash";
import TipoTransmisionBonificacionSimulador, { TTipoTransErr } from "./tipoTransmisionBonificacionSimulador";
import RenderShortDetail, { EMPTY_CALCULO, EMPTY_CALCULO_ERR, EmptyData, EMPTY_OPERACION } from "./utils";
import EndProcessMSG from "components/Modelos/componentes/endProcessMSG";
import Icon from "@mdi/react";
import Modal from "components/modal";
import GenericModalPDF from "components/generic-modal-pdf";
import { extraerBorradorM081Simulador } from "components/BorradorModelos/extraerModeloSimuladorPLV";
import { TBorradorSimuladorModelo081, renderSimuladorM081 } from "components/BorradorModelos/modelo_081_simulador";
import { BLUE_RIGHT_HEADER } from "containers/shared/colors";




interface IProps extends WithStyles<typeof styles> {

}

const SimuladorPlusvalias: FC<IProps> = ({ classes }) => {
    const componentMounted = useRef(true);

    const terms = useContext(LiteralsContext);
    const [pageState, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);

    //Gateways
    const ioc = useContext(IoC);
    const modelosGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
    
    // Local info
    const [loading, setLoading]= useState(false)
    const [municipios, setMunicipios] = useState([] as TSelect[]);
    const [munis081, setMunis081]= useState([] as string[])
    const [msgModalBoni, setMsgModalBoni] = useState<string | undefined>(undefined)
    
    const [showCalculos, setShowCalculos] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [showAddCalculos, setShowAddCalculos] = useState(false)
    //Calculo
    const [calc, setCalc]= useState<TCalculoSimulador>(EMPTY_CALCULO)
    const [calcErr, setCalcErr]= useState<TCalculoError>(EMPTY_CALCULO_ERR) 
    const [infoToShow, setInfoToShow] = useState<TCalculoSimulador | undefined>(undefined)
    const [showDetail, setShowDetail] = useState(false)
    // Caso RU
    const [showModalRU, setShowModalRU] = useState(false)
    const [MSGContentRU, setMSGContentRU]= useState<string|undefined>(undefined)
    
     // modal con el borrador
    const [showSimulacion, setShowSimulacion] = useState(false)
    const [M081, setM081]= useState<TBorradorSimuladorModelo081 | null>(null)
    const OPTs = getTiposDerechoTransmsisionByTipoAsistente('plusvalia', terms)
    // Porcentaje disponible por tipo de derecho
    const [percentPlenoDom, setPercentPlenoDom] = useState(100)
    const [percentOtro, setPercentOtros] = useState(1000)
    // Objeto completo
    const [data, setData] = useState<TData>(EmptyData)
    const [idGestion,setIdGestion] = useState<number|null>(null)
    const [idGestionSujeto, setIdGestionSujeto] = useState<number|null>(null)
    // Errors
    const [tipoTransmErr, setTipoTransmErr] = useState(false)
    const [fechaErr, setFechaErr] = useState(false)
    const [porcentajeErr, setPorcentajeErr] = useState(false)
    const [valorDeclaradoErr, setValorDeclaradoErr] = useState(false)

    const [errMuniAndRefCatInfo, setErrMuniAndRefCatInfo] = useState<[
        {errSelect: boolean},
        {errDiffMuni: boolean, msgTerm: string},
        {errRefCat:boolean}
    ]>([
        {errSelect: false},
        {errDiffMuni: false, msgTerm: 'plusvalias_error_diffMunicipio'}, // content
        {errRefCat: false}
    ])
    
    // Memos
    const OPTsDispo = useMemo(() => {
        let optDispo=OPTs
        if(percentPlenoDom === 0){
            optDispo = OPTs.filter(opt => opt.id !== 'pleno-dominio')
        }
        if(percentOtro === 0){
            optDispo = OPTs.filter(opt => opt.id === 'pleno-dominio')
        }

        if(data.calculos){
            const tipoDer = data.calculos.map( c => c.tipoDerecho?.id)
            const td_dispo = optDispo.filter( opt => !tipoDer.includes(opt.id))
            return  td_dispo
        } else {
            return optDispo
        }
    },[OPTs, data, percentPlenoDom, percentOtro])
    
    // Functions
    const onAdd = () => { 
        const asDisabled = !calc.tipoDerecho 
            || (calc.tipoDerecho && calc.tipoDerecho.id !== 'pleno-dominio' && (!calc.edadUsufructuaria || calc.edadUsufructuaria===0))
            || calc.numOperaciones===0 || calc.porcentaje === 0

        if(asDisabled){
            setCalcErr(curr=> ({
                ...curr,
                tipoDerecho: calc.tipoDerecho===undefined ,
                edadUsufructuaria: (calc.tipoDerecho && calc.tipoDerecho.id !== 'pleno-dominio' && (!calc.edadUsufructuaria || calc.edadUsufructuaria===0) )? true : false ,
                numOperaciones: calc.numOperaciones===0,
                porcentaje: calc.porcentaje === 0
            }))

        } else {
        // Only tipoModelo ==='081'
        setCalc(curr =>({
            ...curr,
            operaciones: Array(curr.numOperaciones).fill({...EMPTY_OPERACION})
        }))
        setShowAddCalculos(true) 
        }
    }

    const onSaveCalc= (calc: TCalculoSimulador) => {
        const newC = cloneDeep(data.calculos)
        newC.push(calc)
        setData( curr => ({...curr, calculos: newC}))
        setCalc(EMPTY_CALCULO)
        setCalcErr({...EMPTY_CALCULO_ERR})
    }

    const handleDeleteCalc = async (index:number) => {
        const newC = cloneDeep(data.calculos)
        newC.splice(index,1)
        setData( curr => ({...curr, calculos: newC}))
    }

    const onFinish= () => {
        setShowForm(false); 
        setShowAddCalculos(false);
        const info = extraerBorradorM081Simulador(data)
        setM081(info)
    }


    // Effects
    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiBriefcaseOutline,
                moreInfoTemplate: "mas_info_page_simulador_plusvalias",
                title: translate("Plusvalias","SimuladorPlusvalias",terms),
            },
            menu: true,
        });
    }, [pageDispatcher]);

    useEffect(() => {
        const plenoDom = data.calculos ? data.calculos.find(c => c.tipoDerecho?.id ==='pleno-dominio') : undefined
        const otros = data.calculos ? data.calculos.filter(c => c.tipoDerecho?.id !=='pleno-dominio') : undefined
        if(plenoDom){
            // Calculamos dispo del resto de derechos 
            // Si plenodominio tiene un 100% - Ya no pueden añadirse más derechos
            // Si pleno dominio e sun 70% - Pueden añadirse otros derechos con un máx de 30%
            setPercentPlenoDom(0)
            setPercentOtros(100-plenoDom.porcentaje)
        } else {
            // Calculamos dispo Pleno dominio en base al max % de los otros tipo
            // Si hay otros tipos con 100% ya no tiene cabida Pleno dominio
            // Si hay un max de un 60% en otros tipos - podmeos aplicar hasta un 40% en pleno dominio
            const maxPercent = otros 
                ? otros.reduce( (prev: number, c: TCalculoSimulador) => ( prev < c.porcentaje ? c.porcentaje: prev) , 0) 
                : 0;
            setPercentOtros(100)
            setPercentPlenoDom(100-maxPercent)
        }

    },[data])

    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                const municipios =  await modelosGateway.getMunicipiosPLV('No se ha podido recuperar los municipios')
                const munis081 =  await modelosGateway.getIdMunisPermiten081PLV('No se ha podido recuperar los municipios que permiten 081')
                const boniMSG = await contenidoGateway.getContent(
                    'plusvalias_info_modal_warn_bonificacion',
                    {}
                );
                const MSGInmRU = await contenidoGateway.getContent('plusvalias_simulador_RU', {})
                
                if(componentMounted.current){
                    setMunicipios(formatSelectOptions<IMunicipioPLV>('idMunicipio', 'Nombre', municipios))
                    setMunis081(munis081)
                    setMsgModalBoni(boniMSG[0].contenido)
                    setMSGContentRU(MSGInmRU[0].contenido)
                }
            } catch (err){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: err,
                        variant: "error"
                    }
                });
            } finally {
                setLoading(false)
            }
            
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
            
        }
    },[])

    //console.log( showForm , M081)

    return (
        <>
            <div className={classes.root}>
                <Card className={classes.padding} >
                    {!showCalculos 
                    ?
                        <>
                            <div className={classes.column} style={{flexGrow: 1}}>
                                <MuniAndRefCatInfo 
                                    selectedMuni={data.municipio}
                                    muniOptions={municipios} 
                                    onChangeMuni={(val: TSelect|undefined,err:boolean) => {
                                        setData(curr => ({...curr, municipio: val}))
                                        setErrMuniAndRefCatInfo( curr =>([
                                            {...curr[0], errSelect: err},
                                            {...curr[1]},
                                            {...curr[2]}
                                        ]))
                                    }} 
                                    inmueble={data.inmueble} 
                                    onChangeInmueble={(inm: TInmuebleCatastro | null) =>{ 
                                        if(inm && inm.tipoBien ==='RU'){setShowModalRU(true)}
                                        setData(curr => ({...curr, inmueble: inm})) 
                                }} 
                                    getErrRefCat={(v: boolean) =>  setErrMuniAndRefCatInfo( curr =>([{...curr[0]}, {...curr[1]},{...curr[2], errRefCat: v}])) }
                                    getErrDiffMunis={(v: boolean) =>setErrMuniAndRefCatInfo( curr =>([{...curr[0]},{...curr[1], errDiffMuni:v},{...curr[2]}])) }
                                    labelWithInfo={{
                                        municipio:{componenteIG:'Plusvalias', labelTermIg:'municipioLabel', infoContent:'plusvalias_info_field_municipio'},
                                        refCatastral:{componenteIG:'Plusvalias', labelTermIg:'refCatastralLabel',infoContent: 'plusvalias_info_field_refCatastral'},
                                    }}              
                                />
                                {/**Mensajes Error tipo card*/}
                                { errMuniAndRefCatInfo[1].errDiffMuni && 
                                    <EndProcessMSG 
                                        content={errMuniAndRefCatInfo[1].msgTerm} 
                                        paramContent={ errMuniAndRefCatInfo[1].errDiffMuni ? {muni: data.municipio?.nombre ?? ""} : {} }
                                    />
                                }
                                

                                { !errMuniAndRefCatInfo[1].errDiffMuni  &&data.municipio && data.inmueble && data.inmueble.refCatastral && data.inmueble.tipoBien!=='RU' &&
                                    <TipoTransmisionBonificacionSimulador
                                        idMunicipio={data.municipio.id}
                                        nombreMuni={data.municipio.nombre}
                                        tipoTransmision={data.tipoTransmision}
                                        onChangeTipoTransmisiones={(v: TTipoTransmSimuladorPLV, err: TTipoTransErr) => {
                                            setData(curr => ({...curr, tipoTransmision: v,}))
                                            setTipoTransmErr(Object.values(err).includes(true) ? true: false)
                                        }}
                                    />
                                }

                                

                                {/** INFO. DE LA TRANSMISIÓN */}
                                { !errMuniAndRefCatInfo[1].errDiffMuni 
                                    && data.municipio && data.inmueble && data.inmueble.refCatastral && data.tipoTransmision.tipo 
                                    ? 
                                    <div className={classes.column}>
                                        <GenericLabelWithInfo 
                                            style={{ minHeight: 48, alignContent:'center' }}
                                            componentIG={'Plusvalias'}
                                            labelTerm={'infoTransmTitle_simu'}
                                        />
                                        <div className={classes.row}>
                                            <GenericInputDate 
                                                componentIG={'Plusvalias'}
                                                labelTerm="fecha"
                                                value={data.fechaTransmision}
                                                onChangeValue={(v: Date| undefined, err:boolean) =>{ 
                                                    setData(curr => ({...curr, fechaTransmision: v}))
                                                    setFechaErr(err)
                                                }}
                                                maxDate={ new Date() }
                                                disabled={false}
                                                required={true}
                                                error={fechaErr}
                                                className={classes.inputFullWidth}
                                            />
                                            <GenericTypedInputNumber
                                                type="percentaje"
                                                allowZero={false}
                                                maxDecimal={2}
                                                componentIG="DUTI"
                                                labelTerm="porcentajeTransmision"
                                                value={data.porcentaje ?? 0}
                                                onChangeValue={(v: number, err: boolean) => {
                                                    setData(curr=> ({...curr, porcentaje: v}))
                                                    setPorcentajeErr(err)
                                                }}
                                                required={true} 
                                                error={porcentajeErr}
                                                className={classes.inputFullWidth}
                                                moreInfo={{
                                                    template: 'plusvalias_info_field_porcentajeTransmision'
                                                }}
                                            /> 
                                            <GenericTypedInputNumber
                                                type="money"
                                                allowZero={true}
                                                min={0}
                                                componentIG="Plusvalias"
                                                labelTerm="importeTransmision"
                                                value={data.valorDeclarado}
                                                onChangeValue={(v: number|undefined, err: boolean) => {
                                                    setData(curr=>({...curr, valorDeclarado: v}))
                                                    setValorDeclaradoErr(err)
                                                }}
                                                required={false} 
                                                error={valorDeclaradoErr}
                                                className={classes.inputFullWidth}
                                                moreInfo={{
                                                    template: 'plusvalias_info_field_valorDeclarado'
                                                }}
                                            /> 
                                        </div>
                                    </div>

                                : null }
                            </div>
                        
                            {data.municipio && data.inmueble && data.inmueble.refCatastral && data.tipoTransmision.tipo ? 
                                <div className={classes.rowBtn}> 
                                    <Button style={{alignSelf: 'flex-end'}}
                                            variant="contained"
                                            color='primary'
                                            disabled = {!data.fechaTransmision || !data.porcentaje /*|| !data.valorDeclarado*/}
                                            onClick={() => {
                                                setShowCalculos(true)
                                                setShowForm(true)
                                            } }
                                        >
                                        {translate('Global', 'Continuar',terms)}
                                    </Button>
                                </div>
                            : null}
                            
                        </>
                    :
                    <div className={classes.borderPlusval}>
                        <RenderShortDetail 
                            info={{
                                municipio: data.municipio,
                                inmueble: data.inmueble,
                                tipoTransmision: data.tipoTransmision,
                                fechaTransmision: data.fechaTransmision,
                                porcentaje: data.porcentaje,
                                valorDeclarado: data.valorDeclarado,
                            }}
                            onEdit={() => {
                                setShowCalculos(false)
                                setShowForm(false)
                                setCalc({} as TCalculoSimulador)
                                setData(curr => ({...curr, calculos:[]}))
                            }}
                        />

                    </div>
                        
                    }
                    

                
        

                    {/** CÁLCULOS  */}
                    { showCalculos &&  
                        data.municipio && data.inmueble && data.inmueble.refCatastral && data.tipoTransmision.tipo 
                        && data.fechaTransmision && data.porcentaje /*&& data.valorDeclarado*/
                        ?   <div className={classes.column}>
                                <GenericLabelWithInfo 
                                    style={{ minHeight: 48, alignContent:'center' }}
                                    componentIG={'Plusvalias'}
                                    labelTerm={'calculosTitleSimulador'}
                                    upperCase={true}
                                />
                                <div style={{flexGrow: 1}}>
                                    {/** Lista de cálculo/s */}
                                    { data.calculos && data.calculos.length>0 ?
                                        <Grid item container direction="column" style={{marginBottom: 15}}>
                                            {data.calculos.map((c, idx) => (
                                                <Grid item container direction="row" alignItems='center' className={[classes.row, classes.tableRow].join(' ')}>
                                                    <Grid item xs={12} className={[classes.row, classes.headerDerecho].join(' ')}>
                                                        <Typography className={classes.textDerecho}>{translate('Global','derechoTransmision', terms)}</Typography>
                                                        <Typography className={classes.textDerecho} style={{paddingRight:10 , paddingLeft: 10}}>-</Typography>
                                                        <Typography className={classes.textDerecho}>{c.tipoDerecho?.nombre}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.row}>
                                                        <Typography>{translate('DUTI','porcentajeTitularidad', terms)}:</Typography>
                                                        <Typography className={classes.textBold}>{c.porcentaje} %</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.row}>
                                                        <Typography>{translate('Plusvalias', c.tipoDerecho?.id === 'usufructo-temporal' ? "duracionUsufructo": "edadUsufructuaria", terms)}:</Typography>
                                                        <Typography className={classes.textBold}>{c.edadUsufructuaria ? `${c.edadUsufructuaria} ${translate('Plusvalias','anyos', terms).toLowerCase()} `: ''}</Typography>
                                                    </Grid>
                                                    
                                                    <Grid item xs={2} className={classes.row}>
                                                        <Typography>{translate('Plusvalias','numOperacionesShort2', terms)}:</Typography>
                                                        <Typography className={classes.textBold}>{c.numOperaciones}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3} className={classes.row}>
                                                    <Typography>{translate('DUTI','metodo_calculo_m081',terms)}:</Typography>   
                                                        <Typography className={classes.textBold}>
                                                            { c.modelo && c.modelo.totalIngresar 
                                                                ? translate('DUTI', c.modelo.importeMetodoReal && c.modelo.importeMetodoReal < c.modelo.importeMetodoObjetivo ? 'metodoReal':'metodoObjetivo', terms) 
                                                                : ''
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.row}>
                                                        <Typography>{translate('DUTI','totalIngresar', terms)}:</Typography>
                                                        <Typography className={classes.textBold}>
                                                            {c.modelo ? c.modelo.totalIngresar.toLocaleString('es-ES', { maximumFractionDigits:2, minimumFractionDigits:0}) : ' - '} €
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1} style={{textAlign: 'right'}}>
                                                        { showForm ?
                                                            <IconButton color="inherit" 
                                                                onClick={() => handleDeleteCalc(idx)}>
                                                                <Icon path={mdiTrashCan} size={1} />
                                                            </IconButton>
                                                            : null    
                                                        }       
                                                        <IconButton color="inherit" 
                                                            onClick={() => { 
                                                                setInfoToShow(c); 
                                                                setShowDetail(true)
                                                            }}>
                                                            <Icon path={mdiEyeCircleOutline} color={BLUE_RIGHT_HEADER} size={1} />
                                                        </IconButton>
                                                
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        : null
                                    }

                                    {/** Form añadir tipo derecho */}
                                    { !showAddCalculos && showForm ?
                                        <>
                                            <Grid container direction="row" >
                                                <GenericInputSelect
                                                    key='tipoDerecho'
                                                    componentIG='Global'
                                                    labelTerm='derechoTransmision'
                                                    value={calc.tipoDerecho}
                                                    options={OPTsDispo}
                                                    disabled={OPTsDispo.length===0 }
                                                    required
                                                    onChangeValue={(v: TSelect|undefined, err: boolean) => {
                                                        setCalc(curr => ({...curr, tipoDerecho: v,porcentaje:0,edadUsufructuaria:undefined,numOperaciones:0}))
                                                        setCalcErr(curr => ({...curr, tipoDerecho: err,porcentaje:false,edadUsufructuaria:false,numOperaciones:false}))
                                                    }}
                                                    error={calcErr.tipoDerecho}
                                                />
                                                <GenericTypedInputNumber
                                                    key='percentaje'
                                                    type="percentaje"
                                                    componentIG="Plusvalias"
                                                    labelTerm={"porcentajeTitularidad"}
                                                    value={calc.porcentaje}
                                                    onChangeValue={(v,err) => {
                                                        setCalc(curr => ({...curr, porcentaje: v}))
                                                        setCalcErr(curr => ({...curr, porcentaje: err}))
                                                    }}
                                                    required
                                                    disabled={OPTsDispo.length==0 || !calc.tipoDerecho || OPTsDispo.length===0 }
                                                    allowZero={false}
                                                    maxDecimal={2}
                                                    max={
                                                        calc.tipoDerecho 
                                                        ? calc.tipoDerecho.id === 'pleno-dominio' ? percentPlenoDom : percentOtro 
                                                        : undefined
                                                    }
                                                    error={calcErr.porcentaje}
                                                    className={classes.input200}
                                                />
                                                {calc.tipoDerecho && calc.tipoDerecho.id !== 'pleno-dominio' &&
                                                    <GenericInputNumber  
                                                        key="edadUsufructuaria"
                                                        labelTerm={calc.tipoDerecho && calc.tipoDerecho.id === 'usufructo-temporal' ? "duracionUsufructo": "edadUsufructuaria"}
                                                        componentIG='Plusvalias'
                                                        value={calc.edadUsufructuaria}
                                                        required={(calc.tipoDerecho && calc.tipoDerecho.id !== 'pleno-dominio')}
                                                        allowZero={false}
                                                        maxDecimal={0}
                                                        min={1}
                                                        onChangeValue={(v: number, err:boolean) =>{ 
                                                            setCalc(curr => ({...curr, edadUsufructuaria: v}))
                                                            setCalcErr(curr => ({...curr, edadUsufructuaria: err}))
                                                        }}
                                                        error={calcErr.edadUsufructuaria}
                                                        disabled={ calc.tipoDerecho===undefined || (calc.tipoDerecho && calc.tipoDerecho.id === 'pleno-dominio') || OPTsDispo.length==0 }
                                                        className={classes.input200}
                                                    />
                                                }
                                                <GenericInputNumber key="numOperaciones"
                                                    labelTerm="numOperacionesAdquisicion"
                                                    componentIG='DUTI'
                                                    value={calc.numOperaciones}
                                                    required
                                                    disabled={ OPTsDispo.length==0 || !calc.tipoDerecho }
                                                    error={calcErr.numOperaciones}
                                                    maxDecimal={0}
                                                    min={1}
                                                    onChangeValue={(v: number, err:boolean) =>{ 
                                                        setCalc(curr => ({...curr, numOperaciones: v}))
                                                        setCalcErr(curr => ({...curr, numOperaciones: err}))
                                                    }}
                                                    moreInfo={{template: 'plusvalias_info_field_numOperaciones', size: 'xs'}}
                                                    className={classes.input285}
                                                />
                                                <div style={{ marginTop: 6.5 }}>
                                                    <Button variant="contained" color='primary' 
                                                        disabled={ OPTsDispo.length==0  || showAddCalculos}
                                                        onClick={onAdd}
                                                    >
                                                        {translate('Global', 'btnAdd', terms)}
                                                    </Button>
                                                    {data.calculos.length > 0 ?
                                                        <Button variant="contained" color='primary' 
                                                            style={{marginLeft: 10}}
                                                            onClick={onFinish}
                                                        > 
                                                        {translate('Tributos', 'btnFinish',terms)}
                                                        </Button>
                                                    : null
                                                    }
                                                </div>
                                            </Grid>
                                        </>
                                        : null
                                    }

                                    {/**Form añadir operaciones del nuevo tipo derecho */}
                                    { showAddCalculos ?  
                                        <CalcPlusvaliaSimuladorAddCalculo
                                            calculo={calc} 
                                            setCalculo={ (v:TCalculoSimulador) => {
                                                onSaveCalc(v)
                                                setShowAddCalculos(false)
                                            }}
                                            onCancel={ () => {
                                                setShowAddCalculos(false)
                                            }}
                                            datosPrevios={{
                                                municipio: data.municipio,
                                                inmueble: data.inmueble,
                                                tipoTransmision: data.tipoTransmision,
                                                fechaTransmision: data.fechaTransmision,
                                                porcentaje: data.porcentaje,
                                                valorDeclarado: data.valorDeclarado,
                                            }}
                                            idGestion={idGestion}
                                            idGestionSujeto={idGestionSujeto}
                                            
                                        />
                                        : null
                                    }

                                    {/**Abrir modal borrador */}
                                    { !showForm  ?
                                        <div className={classes.row} style={{marginTop: 20}}>
                                            <Button  variant="contained" color='primary' 
                                                style={{marginRight: 10}}
                                                onClick={() => setShowSimulacion(true)}
                                            >
                                                {translate('Plusvalias', 'verBorradorSimu', terms)}
                                            </Button>
                                            <Button  
                                                variant="outlined" color='primary' 
                                                onClick={() => {setShowForm(true)}}
                                            >
                                                {translate('Plusvalias', 'modificarSimul', terms)}
                                            </Button>
                                        </div>
                                        : null
                                    }
                                </div>
                                
                            </div>
                        : null   
                    }

                </Card>
                
            </div>

            <Modal
                fullWidth
                maxWidth="md"
                open={showDetail}
                title={translate('DUTI','metodo_calculo_m081',terms).toUpperCase()}
                renderContent={() => (
                        <>
                            {infoToShow && infoToShow.modelo && 
                                <Grid container direction='column' >
                                    <Grid item container direction="row">
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title} >{translate('Plusvalias','cuotaIntegra',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>{infoToShow.modelo.cuotaIntegra} €</Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('Plusvalias','bonificacion',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>
                                                {infoToShow.modelo.bonificacion  ? `${infoToShow.modelo.bonificacion} €` : ' - '}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('Plusvalias','reduccion',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>
                                                {infoToShow.modelo.reduccion  ? `${infoToShow.modelo.reduccion} €` : ' - '}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction="row">
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('DUTI','interesesDemora',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>
                                                {infoToShow.modelo.interesesDemora  ? `${infoToShow.modelo.interesesDemora} €` : ' - '}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('DUTI','recargo',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>
                                                {infoToShow.modelo.recargo  ? `${infoToShow.modelo.recargo} €` : ' - '}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction="row">
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('DUTI','metodoObjetivo',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>{infoToShow.modelo.importeMetodoObjetivo} €</Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title}>{translate('DUTI','metodoReal',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>
                                                {infoToShow.modelo.importeMetodoReal  ? `${infoToShow.modelo.importeMetodoReal} €` : ' - '}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.rowPlusvalDetail}>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_title} >{translate('DUTI','totalIngresar',terms)}:</Typography>
                                            <Typography color='textSecondary' className={classes.plusvalDetail_value}>{infoToShow.modelo.totalIngresar} €</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            }
                        </>
                )}
                renderActions={ () =>(
                        <Button variant='contained' color='primary'
                            onClick={() => { 
                                setShowDetail(false) 
                                setInfoToShow(undefined) 
                            }}>
                            {translate('Global','Cerrar', terms)}
                        </Button>
                    )
                }
            />

            <GenericModalPDF 
                open={showSimulacion} 
                titleTerm="SimuladorPlusvalias"
                componentIG="Plusvalias"
                onClose={() => setShowSimulacion(false)} 
                render={() => renderSimuladorM081(M081, terms,translate('Plusvalias','textoLegalSimulador',terms))}
          
            />

            <Modal
                title={translate('Plusvalias','inmueble_RU',terms).toUpperCase()}
                open={showModalRU}
                renderContent={() => (
                    <div className={classes.textCancel} dangerouslySetInnerHTML={{ __html: MSGContentRU ?? 'Oops ! Lost MSG' }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                )}
                renderActions={ () =>(
                    <Button variant='contained' color='primary'
                        onClick={()=> setShowModalRU(false)}>
                        {translate('Global','Cerrar', terms)}
                    </Button>
                )
                }
            />
        </>
    )
}

export default withLiterals(["Plusvalias","DUTI","Global"])(withStyles(styles)(SimuladorPlusvalias));