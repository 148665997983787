import React, {FC, useContext} from "react"
import { translate } from "utils/i18n"
import { IconButton,  Tooltip,  Typography } from "@material-ui/core"
import { mdiFileDocumentMultipleOutline } from "@mdi/js"
import { BLUE, BLUE_DARK } from "containers/shared/colors"
import { LiteralsContext, withLiterals } from "containers/shared/literals"
import Icon from "@mdi/react"


interface ICopyProps {
    referencia: string | null
}

 
const CopyRef: FC<ICopyProps> = ({referencia}) => {

    const terms = useContext(LiteralsContext);

    const handleCopyRef = (idReferencia: string) => {
        navigator.clipboard.writeText(idReferencia);
    };

    return (
        <>
        {referencia ?  
            <div style={{display:'inline-flex',alignItems:'center', paddingTop: 5}}>
                <Tooltip title={translate("Tributos", "copyRef", terms)}>
                    <IconButton color="primary" style={{padding: '0px 5px 0px 0px'}}
                        onClick={() => handleCopyRef(referencia)}
                        
                    >
                        <Icon path={mdiFileDocumentMultipleOutline} size={1} color={BLUE_DARK}/>
                    </IconButton>
                </Tooltip>
                <Typography variant="body1" style={{ marginRight: 5}}>
                    {translate('Global','Referencia',terms)}:
                </Typography>
                <Typography style={{fontWeight:'bold'}}>{referencia}</Typography>
            </div>
        : null}
        </>
       
    )

}

export default withLiterals(['Global', 'Tributos'])(CopyRef)