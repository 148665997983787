import React, { FC, useContext, useState, useRef, useEffect, useCallback, useMemo, Fragment } from 'react';
import { WithStyles, withStyles, Grid, Typography, Button, CircularProgress, Card, CardContent, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, MenuItem } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import usePage from 'hooks/page.hook';
import IoC from 'contexts/ioc.context';
import { NotificacionesGateway } from 'gateways/notificaciones.gateway';
import { IDiaCortesiaDetalle, IDiasCortesia } from 'gateways/notificaciones.interfaces';
import moment from 'moment';
import Term from 'components/term';
import Calendar from 'containers/shared/calendar';
import styles from '../shared/notificaciones.styles';
import { mdiBell } from '@mdi/js';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';
import { MoreInfoContext } from 'contexts/more_info.context';

type Props = WithStyles<typeof styles>;

const DiasCortesia: FC<Props> = ({ classes }) => {
    const MONTHS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    // Obtener el año en curso
    const presentYear: number = new Date().getFullYear();

    // Global states    
    const [, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);
    // Local states
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [diasCortesia, setDiasCortesia] = useState<IDiasCortesia>();
    const [diasModificables, setDiasModificables] = useState(0);
    const [selectedYear, setSelectedYear] = useState(presentYear);
    const [tempYear, setTempYear] = useState(0);
    const [dataChanged, setDataChanged] = useState(false);
    const [dialogOpened, setDialogOpened] = React.useState(false);

    // Gateways
    const ioc = useContext(IoC);
    const gateway = ioc.get(NotificacionesGateway) as NotificacionesGateway;
    const notificacionesGateway = useMemo(() => ioc.get(NotificacionesGateway) as NotificacionesGateway, [ioc]);

    // References
    const diasCortesiaRef = useRef<IDiaCortesiaDetalle[]>(null);

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch])

    // Effects
    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiBell,
                title: <Term component="Global" text="Dias de cortesia" />,
                moreInfoTemplate: 'mas_info_dias_cortesia',
                right: <MenuItem button onClick={() => handleShowMasInfo("mas_info_dias_cortesia")}><Term component="Global" text="Mas informacion" /></MenuItem>
            },
            menu: true,
        });
    }, [pageDispatcher, diasCortesia]);

    useEffect(() => {
        const loadPage = async () => {
            setLoading(true);
            const diasCortesiaLocal = await notificacionesGateway.getDiasCortesia(selectedYear);
            setDiasCortesia(diasCortesiaLocal);
            setDiasModificables(diasCortesiaLocal.diasSolicitadosModificables);
            setLoading(false);
            setDataChanged(false);
        }

        loadPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear]);

    useEffect(() => {
        setDataChanged(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diasCortesiaRef.current]);

    // Events        
    const handleGuardarDiasCortesia = useCallback(async () => {
        if (diasCortesiaRef.current && dataChanged) {
            setLoadingButton(true);
            const diasCortesiaSeleccionados = diasCortesiaRef.current.filter(d => d.seleccionada);

            if (await gateway.updateDiasCortesia(selectedYear, diasCortesiaSeleccionados.map(d => {
                const dia = moment(d.fecha, ['YYYY-MM-DD']).toDate();
                return moment(dia).format('DD/MM/YYYY');
            }))) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: <Term component="Notificaciones" text="Los dias de cortesia se han guardado correctamente" />,
                        variant: 'success',
                    }
                });
            } else {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: <Term component="Notificaciones" text="Error dias cortesia" />,
                        variant: 'error',
                    }
                });
            }

            setLoadingButton(false);
            setDataChanged(false);
        }
    }, [dataChanged, gateway, selectedYear, pageDispatcher]);

    const handleChangeYear = useCallback((event, year: number) => {
        if (year !== null) {
            if (dataChanged) {
                setTempYear(year);
                setDialogOpened(true);
            } else {
                setSelectedYear(year);
            }
        }
    }, [dataChanged]);

    const handleCloseDialog = useCallback(() => {
        setDialogOpened(false);
        setTempYear(0);
    }, []);

    const handleContinuarDialog = useCallback(() => {
        setSelectedYear(tempYear);
        setDialogOpened(false);
        setTempYear(0);
    }, [tempYear]);

    return (
        <div className={[classes.root, classes.width100].join(' ')}>
            {
                loading
                    ? <div className={classes.centerContent}>
                        <CircularProgress size={75} />
                    </div>
                    :
                    <Fragment>
                        <Card style={{ margin: '0px 15px 0px 15px' }}>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography>
                                            <Term component="Perfil" text="Calendario solicitud" />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.yearButtonGroupRow} style={{ textAlign: 'center' }}>
                                        <ToggleButtonGroup
                                            value={selectedYear}
                                            onChange={handleChangeYear}
                                            className={classes.yearButtonGroup}
                                            exclusive>
                                            <ToggleButton
                                                value={presentYear}>
                                                {presentYear}
                                            </ToggleButton>
                                            <ToggleButton
                                                value={presentYear + 1}>
                                                {presentYear + 1}
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                </Grid>
                                <div className={classes.body}>
                                    {
                                        MONTHS.map(m => {
                                            const firstDate = new Date(selectedYear, m, 1);
                                            return (
                                                <Typography component="div" key={m} className={classes.calendar}>
                                                    <Calendar
                                                        key={m}
                                                        firstDate={firstDate}
                                                        diasCortesia={diasCortesia ? diasCortesia.dias : []}
                                                        diasCortesiaRef={diasCortesiaRef ? diasCortesiaRef : []}
                                                        setDiasModificables={setDiasModificables} />
                                                </Typography>
                                            )
                                        })
                                    }
                                    <Grid container>
                                        <Grid item xs={12} md={3} className={classes.padding10}>
                                            <Typography>
                                                <Term component="Perfil" text="Total días disponibles" />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3} className={classes.padding10}>
                                            <Typography>
                                                {diasCortesia ? diasCortesia.maxDiasCortesia : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3} className={classes.padding10}>
                                            <Typography>
                                                <Term component="Perfil" text="Días pendientes" />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3} className={classes.padding10}>
                                            <Typography>
                                                {diasCortesia ? diasCortesia.diasPendientes : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3} className={classes.padding10}>
                                            <Typography>
                                                <Term component="Perfil" text="Días solicitados modificables" />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3} className={classes.padding10}>
                                            <Typography component="div" className={classes.isSelected}>
                                                {diasModificables}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3} className={classes.padding10}>
                                            <Typography>
                                                <Term component="Perfil" text="Días solicitados no modificables" />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3} className={classes.padding10}>
                                            <Typography component="div" className={classes.isSelectedNoModificable}>
                                                {diasCortesia ? diasCortesia.diasSolicitadosNoModificables : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} className={classes.buttonPosition}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                disabled={loadingButton}
                                                className={classes.button}
                                                onClick={handleGuardarDiasCortesia}
                                                size="small">
                                                <Term component="Global" text="Aceptar" />
                                            </Button>
                                            {loadingButton && <CircularProgress size={24} className={classes.buttonProgressDays} />}
                                        </Grid>
                                    </Grid>
                                </div>
                            </CardContent>
                        </Card>
                        <Dialog
                            open={dialogOpened}
                            onClose={handleCloseDialog}>
                            <DialogTitle><Term component="Global" text="Aviso" /></DialogTitle>
                            <DialogContent id="cabron">
                                <DialogContentText>
                                    <Term component="Perfil" text="Hay cambios sin guardar que se perderán en caso que usted cambie de año, ¿Realmente desea continuar?" />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleContinuarDialog}>
                                    <Term component="Global" text="Continuar" />
                                </Button>
                                <Button onClick={handleCloseDialog}>
                                    <Term component="Global" text="Cancelar" />
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Fragment>
            }
        </div>
    );
}

export default withStyles(styles)(DiasCortesia);