import React, { FC, useContext, useMemo, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Dialog, Button, CircularProgress, DialogTitle, DialogContent, DialogActions, useMediaQuery, useTheme, makeStyles, FormControl, Grid, InputLabel, Select, TextField, LinearProgress, RadioGroup, Radio, FormControlLabel, Tooltip, MenuItem, FormHelperText } from "@material-ui/core";
import Term from 'components/term';
import styles from '../../shared/tributo.detail.styles';
import IoC from 'contexts/ioc.context';
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { IMultas, ITramite, IIdentificacionConductor, IDatosMulta } from 'gateways/tramites.interfaces';
import { TramitesGateway } from 'gateways/tramites.gateway';
import usePage from 'hooks/page.hook';
import { IProvincia } from 'gateways/provincia.interface';
import { IMunicipio } from 'gateways/municipio.interface';
import { IPoblacion } from 'gateways/poblacion.interface';
import { IPais } from 'gateways/pais.interface';
import { SujetosGateway } from 'gateways/sujetos.gateway';
import { SelectChangeHandler, InputChangeHandler } from 'utils/events';
import { FileField } from 'components/file-field';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { downloadWithoutAlert } from 'utils/download';
import { validateNif } from 'utils/validateNif';
import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';

const useStyles = makeStyles(styles);

export interface IProps {
    open: boolean;
    multa: IMultas;
    mensajeAclaratorio: string;
    handleIdentificado: (multa: IMultas) => void;
    onClose: () => void;
    isRentACar: boolean;
    isLoggedIn?: boolean;
    datosMulta?: IDatosMulta;
    handleNotAllowedDocuments: () => void;

}

const MultasDetail: FC<IProps> = ({ open, multa, onClose, handleIdentificado, isRentACar, isLoggedIn = true, datosMulta, handleNotAllowedDocuments }: IProps) => {
    // Global states
    const [, pageDispatcher] = usePage();
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [tramites, setTramites] = useState<ITramite[]>([]);
    const [tramite, setTramite] = useState<number>(-1);
    const [loadingProvincias, setLoadingProvincias] = useState(false);
    const [loadingMunicipios, setLoadingMunicipios] = useState(false);
    const [loadingPoblaciones, setLoadingPoblaciones] = useState(false);
    const [provinciasOption, setProvinciasOption] = useState<IProvincia[]>([]);
    const [municipiosOption, setMunicipiosOption] = useState<IMunicipio[]>([]);
    const [poblacionOption, setPoblacionOption] = useState<IPoblacion[]>([]);
    const [paisOption, setPaisOption] = useState<IPais[]>([]);
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [nif, setNif] = useState('');
    const [docType, setDocType] = useState();
    const [permisoConducir, setPermisoConducir] = useState('');
    const [calle, setCalle] = useState('');
    const [numero, setNumero] = useState('');
    const [escalera, setEscalera] = useState('');
    const [puerta, setPuerta] = useState('');
    const [portal, setPortal] = useState('');
    const [bloque, setBloque] = useState('');
    const [errorLetra, setErrorLetra] = useState(false);
    const [messageLetra, setMessageLetra] = useState('');
    const [mostrarErrores, setMostrarErrores] = useState(false);
    const [piso, setPiso] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [provincia, setProvincia] = useState('');
    const [poblacion, setPoblacion] = useState('');
    const [municipioNoEsp, setMunicipioNoEsp] = useState('');
    const [provinciaNoEsp, setProvinciaNoEsp] = useState('');
    const [poblacionNoEsp, setPoblacionNoEsp] = useState('');
    const [documentAttach, setDocumentAttach] = useState<string[] | null>(null);
    const [fecha, setFecha] = useState<Date | null>(null);
    const [pais, setPais] = useState('');
    const [codigoPostal, setCodigoPostal] = useState('');
    const [loadingImprimir, setLoadingImprimir] = useState(false);
    const [errorDocType, setErrorDocType] = useState('');
    const [errorNif, setErrorNif] = useState('');
    const [errorNombre, setErrorNombre] = useState('');
    const [errorApellidos, setErrorApellidos] = useState('');
    const [errorPermisoConducir, setErrorPermisoConducir] = useState('');
    const [errorPais, setErrorPais] = useState('');
    const [errorProvinciaEsp, setErrorProvinciaEsp] = useState('');
    const [errorMunicipio, setErrorMunicipio] = useState('');
    const [errorPoblacion, setErrorPoblacion] = useState('')
    const [errorCodigoPostal, setErrorCodigoPostal] = useState('');
    const [errorCalle, setErrorCalle] = useState('');
    const [errorNumero, setErrorNumero] = useState('');
    const [errorProvinciaNoEsp, setErrorProvinciaNoEsp] = useState('');
    const [titulo, setTitulo] = useState('datos identificacion');
    const [paso, setPaso] = useState<number>(0);

    // services
    const ioc = useContext(IoC);
    const perfilG = useMemo(() => ioc.get(SujetosGateway) as SujetosGateway, [ioc]);
    const gateway = useMemo(() => ioc.get(TramitesGateway) as TramitesGateway, [ioc]);

    // local states
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Constants
    const docTypes = [
        {
            id: 0,
            name: <Term component="Tramites" text="nif" />
        },
        {
            id: 1,
            name: <Term component="Tramites" text="pasaporte" />
        }
    ]

    const handleNif = (event: string) => {
        setErrorNif('');
        setNif(event.replace(/\s+/g, '') // Elimina todos los espacios en blanco
            .trim()               // Elimina espacios al inicio y al final
            .toUpperCase()        // Convierte a mayúsculas
            .replace(/[^A-Z0-9]/gi, '')); // Elimina cualquier carácter que no sea letra o número

    }
    const handleDocType: SelectChangeHandler = (event) => {
        setDocType(event.target.value);
    }
    const handleNombre = (event: string) => {
        setNombre(event.toUpperCase());
    }
    const handleApellidos = (event: string) => {
        setApellidos(event.toUpperCase());
    }
    const handlePermiso = (event: string) => {
        setPermisoConducir(event.toUpperCase());
    }
    const handleMunicipio: SelectChangeHandler = (event) => {
        setMunicipio(event.target.value);
    }

    const handlePais: SelectChangeHandler = (event) => {
        setPais(event.target.value);
    }

    const handleProvincia: SelectChangeHandler = (event) => {
        setProvincia(event.target.value);
    }

    const handlePoblacion: SelectChangeHandler = (event) => {
        setPoblacion(event.target.value);
    }

    const handleMunicipioNoEsp: InputChangeHandler = (event) => {
        setMunicipioNoEsp(event.target.value.toUpperCase());
    }


    // Validates
    const handleValidateDocType = () => {
        if (!docType && docType === undefined) {

            setErrorDocType(translate('Global', 'Obligatorio', terms));
            return false;
        }
        setErrorDocType('');
        return true;

    }

    const handleValidateCP = async () => {
        // Verificar si el campo codigoPostal está vacío
        if (!codigoPostal) {
            setErrorCodigoPostal(translate('Global', 'Obligatorio', terms));
            return false;
        }
        if (pais !== '00' || !!!poblacion || !!!codigoPostal) {
            setErrorCodigoPostal('');
            return true;
        }
        const ok = await perfilG.validateCP(poblacion, codigoPostal);
        if (!ok) {
            setErrorCodigoPostal(translate('Tramites', 'CpInvalido', terms))
        } else {
            setErrorCodigoPostal('');
        }
        return ok;
    }

    const handleValidateNif = async () => {
        if (!nif) {
            setErrorNif(translate('Global', 'Obligatorio', terms))
            return false;
        }
        if (docType === 1) {
            setErrorNif('');
            return true;
        }

        if (pais !== '00' || !!!nif) {
            if (nif.match("^[a-zA-Z0-9]+$")) {
                return true;
            }
            else {
                setErrorNif(translate('Global', 'NIF no valido', terms))
                return false
            }
        }
        const ok = validateNif(nif);
        if (!ok) {
            setErrorNif(translate('Global', 'NIF no valido', terms))
        } else {
            setErrorNif('');
        }
        return ok;
    }

    const handleValidateProvinciaEsp = async () => {
        if (!provincia) {
            setErrorProvinciaEsp(translate('Global', 'Obligatorio', terms))
            return false;
        }
        setErrorProvinciaEsp('')
        return true;
    }

    const handleValidateProvinciaNoEsp = async () => {
        if (!provinciaNoEsp) {
            setErrorProvinciaNoEsp(translate('Global', 'Obligatorio', terms))
            return false;
        }

        if (provinciaNoEsp.trim().match("^[a-zA-Z]+( [a-zA-Z]+)*$")) {
            return true;
        }
        else {
            setErrorProvinciaNoEsp(translate('Wizard', 'provincia_invalid', terms))
            return false;
        }
    }

    const handleValidateGeneric = (paramToValidate: string, setError: Dispatch<SetStateAction<string>>) => {
        // Verificar si el campo está vacío
        if (!paramToValidate) {
            setError(translate('Global', 'Obligatorio', terms));
            return false;
        }
        setError('');
        return true;
    }


    const handleVerificar = async () => {
        setMostrarErrores(true);
        if (!multa.identificable) {
            return false;
        }
        else if (tramite === 1 || tramite === 2) {
            const docTypeOk = handleValidateDocType();
            const nifOk = await handleValidateNif();
            const nombreOk = handleValidateGeneric(nombre, setErrorNombre);
            const apellidosOk = handleValidateGeneric(apellidos, setErrorApellidos);
            const permisoConducirOk = handleValidateGeneric(permisoConducir, setErrorPermisoConducir);
            const paisOk = handleValidateGeneric(pais, setErrorPais);
            const commonOk = !!paisOk && !!nifOk && !!nombreOk && !!apellidosOk && !!permisoConducirOk && !!documentAttach && !errorLetra;
            const cpOk = await handleValidateCP();
            const provinciaEsp = handleValidateProvinciaEsp();
            const provinciaNoEspOk = await handleValidateProvinciaNoEsp();
            const poblacionOk = handleValidateGeneric(poblacion, setErrorPoblacion);
            const municipioOk = handleValidateGeneric(municipio, setErrorMunicipio);
            const calleOk = handleValidateGeneric(calle, setErrorCalle);
            const numeroOk = handleValidateGeneric(numero, setErrorNumero);
            const spainOk = pais !== '00' || (!!provinciaEsp && !!municipioOk && !!poblacionOk && !!calleOk && !!numeroOk && !!codigoPostal && cpOk);
            const notSpainOk = pais === '00' || !!provinciaNoEsp && !!poblacionNoEsp && ((pais === '178' || pais === '189' || pais === '22' || pais === '72') || !!codigoPostal) && provinciaNoEspOk;
            return commonOk && spainOk && notSpainOk && docTypeOk && nifOk;
        }
        else if (tramite === 3) {
            return !!fecha && !!documentAttach;
        }
        else if (tramite === 4) {
            return !!documentAttach;
        }
        else {
            return false;
        }
    }

    const handleProvinciaNoEsp: InputChangeHandler = (event) => {
        setErrorProvinciaNoEsp('');
        setProvinciaNoEsp(event.target.value.toUpperCase());
    }

    const handlePoblacionNoEsp: InputChangeHandler = (event) => {
        setPoblacionNoEsp(event.target.value.toUpperCase());
    }

    const handleCalle: InputChangeHandler = (event) => {
        setCalle(event.target.value.toUpperCase());
    }

    const handleNumero: InputChangeHandler = (event) => {
        setNumero(event.target.value.toUpperCase());
    }

    const handleCP: InputChangeHandler = async (event) => {
        setErrorCodigoPostal('');
        setCodigoPostal(event.target.value.toUpperCase());
    }

    const handleEscalera: InputChangeHandler = (event) => {
        setEscalera(event.target.value.toUpperCase());
    }

    const handlePiso: InputChangeHandler = (event) => {
        setPiso(event.target.value.toUpperCase());
    }
    const handlePortal: InputChangeHandler = (event) => {
        setPortal(event.target.value.toUpperCase());
    }
    const handleBloque: InputChangeHandler = (event) => {
        setBloque(event.target.value.toUpperCase());
    }

    const handlePuerta: InputChangeHandler = (event) => {
        if (event.target.value.length <= 4) {
            setErrorLetra(false);
            setMessageLetra('');
        } else {
            setErrorLetra(true);
            setMessageLetra(translate('tramites', 'errorLetra', terms));
        }
        setPuerta(event.target.value.toUpperCase());
    }

    const handleDocumentAttach = (files: string[]) => {
        if (!Array.isArray(files)) {
            files = [files];
        }
        setDocumentAttach(files);
    }

    useEffect(() => {
        const init = async () => {
            try {
                if (open) {
                    const paises: IPais[] = await perfilG.getPaises();
                    paises.sort((paisA, paisB) => {
                        return paisA.nombre !== paisB.nombre
                            ? paisA.nombre > paisB.nombre
                                ? 1
                                : -1
                            : 0;
                    });
                    setPaisOption(paises);
                }
            } catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
        };
        init();
    }, [perfilG, pageDispatcher, open])

    useEffect(() => {
        const loadProvincias = async () => {

            setLoadingProvincias(true);
            try {
                if (pais === '00') {
                    const provincias: IProvincia[] = await perfilG.getProvincias(pais);
                    setProvinciasOption(provincias);
                }
            } catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            } finally {
                setLoadingProvincias(false);
            }
        };
        loadProvincias();
    }, [pais, perfilG, pageDispatcher])

    useEffect(() => {
        const loadMunicipios = async () => {

            setLoadingMunicipios(true);
            try {
                if (pais === '00') {
                    const municipios: IMunicipio[] = await perfilG.getMunicipios(provincia);
                    municipios.sort((municipioA, municipioB) => {
                        return municipioA.nombre !== municipioB.nombre
                            ? municipioA.nombre > municipioB.nombre
                                ? 1
                                : -1
                            : 0;
                    });
                    setMunicipiosOption(municipios);
                }
            }
            catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoadingMunicipios(false);
            }
        }

        loadMunicipios();
    }, [provincia, perfilG, pageDispatcher, pais]);

    useEffect(() => {
        const loadPoblacion = async () => {

            setLoadingPoblaciones(true);
            try {
                if (pais === '00') {
                    const poblaciones: IPoblacion[] = await perfilG.getPoblacion(provincia, municipio);
                    setPoblacionOption(poblaciones);
                }
            }
            catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoadingPoblaciones(false);
            }
        }

        loadPoblacion();
    }, [provincia, municipio, perfilG, pageDispatcher, pais]);

    const handleClose = () => {
        handleResetParams();
        handleCleanErrors();
        onClose();
    }

    const handleTipoIdentificacion = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTramite(parseInt(event.target.value as string, 10));
    }

    const handleContinuar = () => {
        if (tramite !== -1) {
            setPaso(1);
        }
    }

    const handleImprimir = async () => {
        if (paso === 2) {
            try {
                setLoadingImprimir(true);
                const result = await gateway.getJustificanteIdentificacion(multa.idIdentificacionWeb, isLoggedIn, datosMulta);
                const response = downloadWithoutAlert(result, 'JustificanteIdentificacion');
                if (response === true) {
                    handleResetParams()
                    onClose();
                    setPaso(0);
                    handleIdentificado(multa);
                } else {
                    pageDispatcher({
                        type: 'show-notification', payload: {
                            message: translate('Tramite', 'No se ha podido descargar el justificante de identificacion', terms),
                            variant: 'error',
                        }
                    });
                }
            } catch (error) {
                pageDispatcher({
                    type: 'show-notification', payload: {
                        message: translate('Tramite', 'No se ha podido descargar el justificante de identificacion', terms),
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoadingImprimir(false);
            }
        }
    }

    const handleResetParams = () => {
        const settersToReset = [setNombre, setApellidos, setNif, setPermisoConducir, setCalle, setNumero, setEscalera, setPuerta, setPiso, setMunicipio, setProvincia, setPoblacion, setMunicipioNoEsp, setProvinciaNoEsp, setPoblacionNoEsp, setPais, setCodigoPostal];
        settersToReset.forEach((setter) => setter(''));
        setDocType(undefined);
        setDocumentAttach(null);
        setFecha(null);
        setMostrarErrores(false);
    }

    const handleCleanErrors = () => {
        setErrorDocType('');
        setErrorNif('');
        setErrorNombre('');
        setErrorApellidos('');
        setErrorPermisoConducir('');
        setErrorPais('');
        setErrorProvinciaEsp('');
        setErrorProvinciaNoEsp('');
        setErrorMunicipio('');
        setErrorPoblacion('');
        setErrorCodigoPostal('');
        setErrorCalle('');
        setErrorNumero('');
    }

    const handleRetroceder = () => {
        setPaso(0);
    }

    const handleFecha = (date: Date | null) => setFecha(date);


    const handleIdentificar = async () => {
        if (await handleVerificar()) {
            setLoading(true);
            try {
                if (tramite !== -1 && documentAttach !== null) {
                    let detalle: IIdentificacionConductor = {
                        idIdentificacionWeb: multa.idIdentificacionWeb,
                        documentos: documentAttach,
                        tipoIdentificacion: tramite,
                    };
                    switch (tramite) {
                        case 1:
                        case 2:
                            detalle = {
                                ...detalle,
                                nombre: `${nombre} ${apellidos}`,
                                nif,
                                permisoConducir,
                                calle,
                                numero,
                                escalera,
                                piso,
                                portal,
                                puerta,
                                bloque,
                                codigoPostal,
                                idPoblacion: poblacion,
                                idPais: pais,
                                idMunicipio: municipio,
                                idProvincia: provincia,
                                poblacionNoEsp: poblacionNoEsp,
                                municipioNoEsp: municipioNoEsp,
                                provinciaNoEsp: provinciaNoEsp,
                                nombreProvincia: provincia ? provinciasOption.filter(item => item.idProvincia == provincia)[0].nombre : provinciaNoEsp,
                                nombreMunicipio: municipio ? municipiosOption.filter(item => item.idMunicipio == municipio)[0].nombre : municipioNoEsp,
                                nombrePoblacion: poblacion ? poblacionOption.filter(item => item.idPoblacion.toString() == poblacion)[0].nombre : poblacionNoEsp
                            }
                            break;
                        case 3:
                            detalle = {
                                ...detalle,
                                fechaVenta: fecha,
                            }
                            break;
                        case 4:
                            detalle = {
                                ...detalle,
                            }
                            break;
                    }
                    await gateway.identificarConductor(detalle, isLoggedIn, datosMulta);
                    setPaso(2);
                }

            }
            catch (e) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Solicitudes', 'Ha ocurrido un error procesando la solicitud', terms),
                        variant: 'error',
                    }
                });
            }
            finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        if (isRentACar) {
            setTramites([
                {
                    id: 1,
                    nombre: translate('Tramites', 'IdentificacionConductor', terms),
                },
                {
                    id: 2,
                    nombre: translate('Tramites', 'VehiculoAlquilado', terms),
                },
                {
                    id: 3,
                    nombre: translate('Tramites', 'VehiculoVendido', terms),
                },
                {
                    id: 4,
                    nombre: translate('Tramites', 'VehiculoRobado', terms),
                }
            ]);
        } else {
            setTramites([
                {
                    id: 1,
                    nombre: translate('Tramites', 'IdentificacionConductor', terms),
                }
            ]);
            setPaso(1);
            setTramite(1);
        }

    }, [terms, isRentACar]);

    useEffect(() => {
        if (paso === 1) {
            switch (tramite) {
                case 1:
                    setTitulo("datos conductor");
                    break;
                case 3:
                    setTitulo("VehiculoVendido");
                    break;
                case 4:
                    setTitulo("VehiculoRobado");
                    break;
                default:
                    setTitulo("datos identificacion")
                    break;
            }
        } else {
            setTitulo("datos identificacion")
        }
    }, [paso, tramite]);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            maxWidth={"md"}
            fullWidth={true}
        >
            <DialogTitle>
                <Term component="Tramites" text={paso !== 2 ? titulo : 'Imprimir Justificante'} />
            </DialogTitle>
            <DialogContent>
                {
                    !loading
                        ?
                        <>
                            {
                                tramites !== null && paso === 0
                                    ?
                                    <FormControl component="fieldset" className={classes.form}>
                                        <RadioGroup aria-label="tramite" name="tramite" value={tramite} onChange={handleTipoIdentificacion}>
                                            {tramites.map((item) => (
                                                <FormControlLabel
                                                    key={'tramite_' + item !== null ? item.id : 0}
                                                    value={item.id}
                                                    control={<Radio />}
                                                    label={<Term component="Tramites" text={item.nombre} />}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                    :
                                    null
                            }
                            {
                                paso === 1
                                    ?
                                    <>
                                        {tramite === 1 || tramite === 2
                                            ?
                                            <Grid container className={classes.main}>
                                                {/** Tipo Documento - NIF - Nombre - Apellidos*/}

                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.textFieldForm} >
                                                        <InputLabel htmlFor="nif-type" error={mostrarErrores && docType === undefined} >
                                                            <Term component="Tramites" text="Tipo nif" /> *
                                                        </InputLabel>
                                                        <Select
                                                            id="nif-type-select"
                                                            value={docType}
                                                            error={mostrarErrores && docType === undefined}
                                                            onChange={handleDocType}
                                                            className={classes.selectForm2}
                                                        >
                                                            {
                                                                docTypes.map(x => (
                                                                    <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                        {mostrarErrores && !docType && docType === undefined && (
                                                            <FormHelperText error={true} >{errorDocType}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.textFieldForm}>
                                                        <TextField className={classes.textFieldForm}
                                                            label={docType === 1
                                                                ? <Term component="Tramites" text="passportNum" />
                                                                : <Term component="Tramites" text="Nif" />}
                                                            value={nif}
                                                            error={mostrarErrores && (!nif || errorNif !== '')}
                                                            helperText={errorNif}
                                                            onChange={(e) => handleNif(e.target.value)}
                                                            required
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={false} sm={6}>
                                                    {/**Ajusta row */}
                                                </Grid>


                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.textFieldForm}>
                                                        <TextField className={classes.textFieldForm}
                                                            label={<Term component="Tramites" text="Nombre" />}
                                                            value={nombre}
                                                            error={mostrarErrores && !nombre}
                                                            helperText={!nombre && errorNombre}
                                                            onChange={(e) => handleNombre(e.target.value)}
                                                            required
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl className={classes.textFieldForm}>
                                                        <TextField className={classes.textFieldForm}
                                                            label={<Term component="Tramites" text="Apellidos" />}
                                                            value={apellidos}
                                                            error={mostrarErrores && !apellidos}
                                                            helperText={!apellidos && errorApellidos}
                                                            onChange={(e) => handleApellidos(e.target.value)}
                                                            required
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                {/** Permiso conducir */}
                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.textFieldForm}>
                                                        <TextField className={classes.textFieldForm}
                                                            label={<Term component="Tramites" text="PermisoConducir" />}
                                                            value={permisoConducir}
                                                            error={mostrarErrores && !permisoConducir}
                                                            helperText={!permisoConducir && errorPermisoConducir}
                                                            onChange={(e) => handlePermiso(e.target.value)}
                                                            required
                                                        />
                                                    </FormControl>
                                                </Grid>


                                                {/** País - Província - Municipio - Población - Código Postal */}
                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.selectForm2}>
                                                        <InputLabel htmlFor="pais-simple"
                                                            error={mostrarErrores && !pais}>
                                                            <Term component="Global" text="Pais" /> *
                                                        </InputLabel>
                                                        <Select
                                                            id="pais-select"
                                                            value={pais}
                                                            error={mostrarErrores && !pais}
                                                            onChange={handlePais}
                                                            className={classes.selectForm2}
                                                        >
                                                            {
                                                                paisOption !== null ?
                                                                    paisOption.map(x => (
                                                                        <MenuItem key={x.idPais} value={x.idPais}>{x.nombre.toUpperCase()}</MenuItem>
                                                                    )) : null
                                                            }
                                                        </Select>
                                                        {mostrarErrores && !pais && (
                                                            <FormHelperText error={true} >{errorPais}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    {pais === '00'
                                                        ?
                                                        <FormControl className={classes.selectForm2}>
                                                            <InputLabel htmlFor="provincia-simple"
                                                                error={mostrarErrores && !provincia}>
                                                                <Term component="Global" text="Provincia" /> *
                                                            </InputLabel>
                                                            <Select
                                                                id="provincia-select"
                                                                value={provincia}
                                                                error={mostrarErrores && !provincia}
                                                                onChange={handleProvincia}
                                                                className={classes.selectForm2}
                                                                disabled={loadingProvincias}
                                                            >
                                                                {
                                                                    provinciasOption !== null ?
                                                                        provinciasOption.map(x => (
                                                                            <MenuItem key={x.idProvincia} value={x.idProvincia}>{x.nombre}</MenuItem>
                                                                        )) : null
                                                                }
                                                            </Select>
                                                            {mostrarErrores && !provincia && (
                                                                <FormHelperText error={true} >{errorProvinciaEsp}</FormHelperText>
                                                            )}
                                                            {
                                                                loadingProvincias ?
                                                                    <LinearProgress className={classes.linearCharge} />
                                                                    :
                                                                    null
                                                            }
                                                        </FormControl>
                                                        :
                                                        <FormControl className={classes.selectForm2}>
                                                            <TextField className={classes.textFieldForm}
                                                                label={<Term component="Global" text="Provincia" />}
                                                                value={provinciaNoEsp}
                                                                error={mostrarErrores && (errorProvinciaNoEsp !== '' || !provinciaNoEsp)}
                                                                helperText={!provinciaNoEsp && errorProvinciaNoEsp}
                                                                onChange={handleProvinciaNoEsp}
                                                                required
                                                            />
                                                        </FormControl>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    {pais === '00'
                                                        ?
                                                        <FormControl className={classes.selectForm2}>
                                                            <InputLabel htmlFor="municipio-simple"
                                                                error={mostrarErrores && !municipio} >
                                                                <Term component="Global" text="Municipio" /> *
                                                            </InputLabel>
                                                            <Select
                                                                id='municipio-native-simple'
                                                                disabled={loadingMunicipios}
                                                                value={municipio}
                                                                error={mostrarErrores && !municipio}
                                                                onChange={handleMunicipio}
                                                                className={classes.selectForm2}
                                                            >
                                                                <option key="muni" value="" />
                                                                {
                                                                    municipiosOption !== null ?
                                                                        municipiosOption.map(x => (
                                                                            <MenuItem key={x.idMunicipio} value={x.idMunicipio}>{x.nombre}</MenuItem>
                                                                        )) : null
                                                                }
                                                            </Select>
                                                            {mostrarErrores && !municipio && (
                                                                <FormHelperText error={true} >{errorMunicipio}</FormHelperText>
                                                            )}
                                                            {
                                                                loadingMunicipios ?
                                                                    <LinearProgress className={classes.linearCharge} />
                                                                    :
                                                                    null
                                                            }
                                                        </FormControl>
                                                        :
                                                        <FormControl className={classes.selectForm2}>
                                                            <TextField className={classes.textFieldForm}
                                                                label={<Term component="Global" text="Municipio" />}
                                                                value={municipioNoEsp}
                                                                onChange={handleMunicipioNoEsp}
                                                            />
                                                        </FormControl>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    {pais === '00'
                                                        ?
                                                        <FormControl className={classes.selectForm2}>
                                                            <InputLabel htmlFor="poblacion-simple"
                                                                error={mostrarErrores && !poblacion}>
                                                                <Term component="Global" text="Poblacion" /> *
                                                            </InputLabel>
                                                            <Select
                                                                id='poblacion-native-simple'
                                                                disabled={loadingPoblaciones}
                                                                value={poblacion}
                                                                error={mostrarErrores && !poblacion}
                                                                onChange={handlePoblacion}
                                                                className={classes.selectForm2}
                                                            >
                                                                {
                                                                    poblacionOption !== null ?
                                                                        poblacionOption.map(x => (
                                                                            <MenuItem key={x.idPoblacion} value={x.idPoblacion}>{x.nombre}</MenuItem>
                                                                        )) : null
                                                                }
                                                            </Select>
                                                            {mostrarErrores && !poblacion && (
                                                                <FormHelperText error={true} >{errorPoblacion}</FormHelperText>
                                                            )}
                                                            {
                                                                loadingPoblaciones ?
                                                                    <LinearProgress className={classes.linearCharge} />
                                                                    :
                                                                    null
                                                            }

                                                        </FormControl>
                                                        :
                                                        <FormControl className={classes.selectForm2}>
                                                            <TextField className={classes.textFieldForm}
                                                                label={<Term component="Global" text="poblacion" />}
                                                                value={poblacionNoEsp}
                                                                onChange={handlePoblacionNoEsp}
                                                                error={mostrarErrores && !poblacionNoEsp}
                                                                helperText={!poblacionNoEsp && errorPoblacion}
                                                                required
                                                            />
                                                        </FormControl>
                                                    }
                                                </Grid>


                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.textFieldForm}>
                                                        <TextField
                                                            label={<Term component="Tramites" text="CodigoPostal" />}
                                                            value={codigoPostal}
                                                            onChange={handleCP}
                                                            error={mostrarErrores && (!codigoPostal || errorCodigoPostal !== '') && !(pais === '178' || pais === '189' || pais === '22' || pais === '72')}
                                                            helperText={errorCodigoPostal}
                                                            required={!(pais === '178' || pais === '189' || pais === '22' || pais === '72')}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.textFieldForm}>
                                                        <TextField
                                                            label={<Term component="Tramites" text="Calle" />}
                                                            value={calle}
                                                            onChange={handleCalle}
                                                            error={mostrarErrores && !calle && pais === '00'}
                                                            helperText={!calle && pais === '00' && errorCalle}
                                                            required={pais === '00'}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.textFieldForm}>
                                                        <TextField
                                                            label={<Term component="Tramites" text="Numero" />}
                                                            value={numero}
                                                            onChange={handleNumero}
                                                            error={mostrarErrores && !numero && pais === '00'}
                                                            helperText={!numero && pais === '00' && errorNumero}
                                                            required={pais === '00'}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.textFieldForm}>
                                                        <TextField
                                                            label={<Term component="Tramites" text="bloque" />}
                                                            value={bloque}
                                                            onChange={handleBloque}
                                                        />
                                                    </FormControl>
                                                </Grid>


                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.textFieldForm}>
                                                        <TextField
                                                            label={<Term component="Tramites" text="portal" />}
                                                            value={portal}
                                                            onChange={handlePortal}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.textFieldForm}>
                                                        <TextField
                                                            label={<Term component="Tramites" text="Escalera" />}
                                                            value={escalera}
                                                            onChange={handleEscalera}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.textFieldForm}>
                                                        <TextField
                                                            label={<Term component="Tramites" text="Piso" />}
                                                            value={piso}
                                                            onChange={handlePiso}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <FormControl className={classes.textFieldForm}>
                                                        <TextField
                                                            label={<Term component="Tramites" text="Puerta" />}
                                                            value={puerta}
                                                            onChange={handlePuerta}
                                                            error={errorLetra}
                                                            helperText={messageLetra}
                                                        />
                                                    </FormControl>
                                                </Grid>



                                                <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                                                    <FormControl className={classes.textFieldForm} >
                                                        <FileField
                                                            label={<div className={classes.verticallyCentered}>
                                                                <Tooltip title={isRentACar ? translate('tramites', 'contrato alquiler', terms) : translate('tramites', 'dni conductor', terms)} placement="bottom">
                                                                    <Icon path={mdiInformationOutline}
                                                                        size={1}
                                                                        color={mostrarErrores && !documentAttach ? 'red' : '#0000008A'}
                                                                    />
                                                                </Tooltip>
                                                                &nbsp;<Term component="Tramites" text="documentoAcreditativo" />
                                                            </div>}
                                                            error={mostrarErrores && !documentAttach}
                                                            helperText={translate('Tramites', 'documentoObligatorio', terms)}
                                                            onChange={handleDocumentAttach}
                                                            required
                                                            fileType=".pdf"
                                                            handleNotAllowedDocuments={handleNotAllowedDocuments}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            :
                                            null
                                        }
                                        {
                                            tramite === 3
                                                ?
                                                <Grid container className={classes.main}>
                                                    <Grid item xs={12} sm={6}>
                                                        <KeyboardDatePicker
                                                            disableToolbar
                                                            variant="inline"
                                                            margin="dense"
                                                            format="dd/MM/yyyy"
                                                            className={classes.input}
                                                            error={mostrarErrores && !fecha}
                                                            id="datepicker-fecha"
                                                            label={<Term component="Tramites" text="FechaVenta" />}
                                                            value={fecha}
                                                            onChange={handleFecha}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl >
                                                            <FileField
                                                                label={<Term component="Tramites" text="AdjuntoVendido" />}
                                                                error={mostrarErrores && !documentAttach}
                                                                onChange={handleDocumentAttach}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                :
                                                null
                                        }
                                        {
                                            tramite === 4
                                                ?
                                                <Grid container className={classes.main}>
                                                    <Grid item xs={12} sm={3}>
                                                        <FormControl >
                                                            <FileField
                                                                label={<Term component="Tramites" text="AdjuntoRobado" />}
                                                                error={mostrarErrores && !documentAttach}
                                                                onChange={handleDocumentAttach}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    null
                            }
                        </>
                        :
                        <>
                            <div className={classes.centerContent}>
                                <CircularProgress size={75} />
                            </div>
                        </>
                }
            </DialogContent>
            <DialogActions>
                {
                    paso === 0
                        ?
                        <>
                            <Button onClick={handleClose} color="default">
                                <Term component="Global" text="Cerrar" />
                            </Button>
                            <Button variant="outlined" color="primary" disabled={tramite === -1} onClick={handleContinuar}>
                                <Term component="Tramites" text="Siguiente" />
                            </Button>
                        </>
                        :
                        null
                }
                {
                    paso === 1
                        ?
                        <>
                            {isRentACar
                                ?
                                <Button onClick={handleRetroceder} color="default">
                                    <Term component="Global" text="Atras" />
                                </Button>
                                :
                                <Button onClick={handleClose} color="default">
                                    <Term component="Global" text="Cerrar" />
                                </Button>
                            }
                            <Button variant="outlined" color="primary" onClick={handleIdentificar}>
                                <Term component="Tramites" text="Identificar" />
                                {
                                    loading
                                        ?
                                        <CircularProgress size={20} />
                                        :
                                        null
                                }
                            </Button>
                        </>
                        :
                        null
                }
                {
                    paso === 2
                        ?
                        <>
                            <Button onClick={handleImprimir} color="primary" variant="outlined" disabled={loadingImprimir}>
                                <Term component="Tramites" text="Imprimir Justificante" />
                                {
                                    loadingImprimir
                                        ?
                                        <CircularProgress size={20} />
                                        :
                                        null
                                }
                            </Button>
                        </>
                        :
                        null
                }
            </DialogActions>
        </Dialog>
    )
};

export default MultasDetail;