import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";

const styles = (theme: Theme): StyleRules => ({
    // GENERAL
    centerContent: {
        textAlign: 'center',
        paddingTop: 250,
        width: '100%',
    },
    root: {
        width: '100%',
        marginLeft: 15,
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    button: {
        color: 'white',
        backgroundColor: '#67bef0',
        marginLeft: 30,
        marginTop: 40,
        "&:hover": {
            backgroundColor: "#3790f0"
        },
    },
    buttonRightAlign: {
        float: 'right',
        marginRight: 50,
        marginBottom: 40
    },
    boldText: {
        color: 'black'
    },
    lightText: {
        fontWeight: 'lighter',
        color: 'grey',
    },
    // STYLES STEPPER
    iconBox: {
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    activeIconBox: {
        backgroundImage: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

    },
    nonActiveIconBox: {
        backgroundColor: 'lightgrey',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    stepperCard: {
        width: 'calc(100% - 330px)',
        position: 'fixed',
        left: 0,
        right: 0,
        margin: 'auto',
        marginRight: 15,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 30px)',
            height: 158,
            zIndex: 1,
            marginTop: 35,
            marginLeft: 15,
            left: 'unset',
            right: 'unset'
        }
    },
    stepperContainer: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#e0eaf4',
            width: '100%',
            height: 207,
            position: 'fixed',
            zIndex: 1,
            top: 55,
            marginTop: '7%'
        }
    },
    // SELECCIONAR TRÁMITE SECTION
    subCardGroup: {
        display: "flex",
        flexDirection: 'row',
        marginTop: 175,
        float: 'left',
        position: 'fixed',
        width: 'calc(100% - 330px)',
        [theme.breakpoints.down('lg')]: {
            marginTop: 175
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 30px)',
            height: 'auto',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            float: 'none',
            alignItems: 'center',
            marginLeft: 15,
            marginTop: 230
        }
    },
    stepsStepperCard: {
        [theme.breakpoints.down('sm')]: {
            padding: 'unset',
            paddingTop: 24
        }
    },
    tramitesLeftCard: {
        overflowY: 'scroll',
        paddingRight: 20,
        width: '50%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            overflowY: 'unset',
            padding: 0
        }
    },
    tramitesRightCard: {
        paddingLeft: 20,
        width: '50%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
            marginTop: 20
        }
    },
    tramitesCardHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    tramiteCards: {
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            minHeight: 100
        }
    },
    optionsCard: {
        height: '100%',
        [theme.breakpoints.down('lg')]: {
            overflowY: 'scroll',
        }
    },
    optionsCardHeader: {
        float: 'left',
        display: 'flex',
        marginTop: 40,
        marginBottom: '3%',
        marginLeft: 30,
        alignItems: 'flex-end',
        cursor: 'pointer',
    },
    optionsCardBody: {
        marginTop: 30,
        marginLeft: 30,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30
        }
    },
    optionsTramitesCard: {
        paddingLeft: 30,
        paddingTop: 15,
        paddingBottom: 10
    },
    tramiteCardActionArea: {
        height: 80,
        [theme.breakpoints.down('sm')]: {
            height: 100
        }
    },
    textCardTramites: {
        flex: 5,
        paddingLeft: 15
    },
    iconCardTramites: {
        flex: 1,
    },
    completed: {
        fontWeight: 'normal'
    },
    section: {
        display: 'table'
    },
    officeAndHours: {
        alignItems: 'start',
        justifyContent: 'space-between',
        display: 'flex'
    },
    selectorTramites: {
        minWidth: 330,
        [theme.breakpoints.down('sm')]: {
            width: 210
        }
    },
    addressOficina: {
        display: 'flex',
        flexGrow: 0,
        marginLeft: 30,
        marginTop: 5,
        marginRight: 15,
        maxWidth: '370px'
    },
    formControl: {
        marginLeft: 10,
        marginBottom: 10,
        display: 'block',
        '& > div': {
            width: '300px',
        },
        '& > button': {
            width: '300px',
        }
    },

    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        [theme.breakpoints.up('md')]: {
            left: '50%',
        },

    },

    imagenContainer: {
        width: 80,
        height: 80,
        backgroundColor: '#A9A9A9',
        borderRadius: '50%',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            width: 60,
            height: 60,
        },
        [theme.breakpoints.down('xs')]: {
            width: 60,
            height: 60,
        }
    },

    imagenSelected: {
        width: 80,
        height: 80,
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        border: 'solid',
        borderWidth: 'thin',
        borderColor: '#111111',
        [theme.breakpoints.down('sm')]: {
            width: 60,
            height: 60,
        },
        [theme.breakpoints.down('xs')]: {
            width: 60,
            height: 60,
        }
    },

    cardProximasCitas: {
        minHeight: 150,
        margin: 20,
        marginTop: 0,
        position: 'relative',
        [theme.breakpoints.up('xs')]: {
            marginRight: 10,
            marginLeft: 10,
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: 30,
            marginLeft: 30,
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 30,
            marginLeft: 30,
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: 30,
            marginLeft: 30,
        },
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    flexBox: {
        flex: 1,
        marginRight: 10,
        marginLeft: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80px',
    },
    flexControl: {
        flex: 13,
        flexDirection: 'row',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',

        }
    },
    flexBoxDirec: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'left',
            marginTop: '13px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            textAlign: 'left',
            marginTop: '13px',
        }
    },
    flexBoxInfo: {
        marginTop: 10,
        marginBottom: 10,
        flex: 3,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
            marginBottom: 10,
            marginTop: 0,
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            width: '100%',
            marginBottom: 10,
            marginTop: 0,
        },
        paddingRight: 10
    },
    tramite: {
        fontSize: 18,
        marginRight: 30,
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
            textAlign: 'left',
            marginRight: 0,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
            textAlign: 'left',
            marginRight: 0,
        }
    },
    rootProximasCitas: {
        backgroundColor: '#fff',
        position: 'fixed',
        bottom: -100,
        transition: 'all .5s ease 0s',
        borderTop: '3px solid #e0e0e0',
        height: 70,
        alignItems: 'center',
    },

    payContainer:{
        display: 'flex',
        justifyContent: 'flex-end'
    },

    display: {
        bottom: 0,
        display:'flex',
        justifyContent: 'flex-end',
        paddingRight:'50px',
        maxWidth: 'calc(100% - 300px)',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
        }
    },

    fixedBottom: {
        display: 'flex',
        flexDirection: 'row-reverse',
        position: 'fixed',
        bottom: 0,
        width: 'calc(100% - 300px)',
        marginLeft: 0,
        backgroundColor: 'white',
        borderTop: '3px solid #e0e0e0',
        [theme.breakpoints.down(966)]: {
            width: '100%'
        }
    },
    buttonDrawer: {
        minWidth: 100,
        marginLeft: 30,
    },
    buttonDrawerSecondary: {
        minWidth: 100,
        marginLeft: 30,
        backgroundColor: 'grey',
    },
    noCitasImagen: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('xs')]: {

            height: 200,
            width: 300,
        },
        [theme.breakpoints.up('sm')]: {
            height: 250,
            width: 350,
        },
        [theme.breakpoints.up('md')]: {

            height: 400,
            width: 600,
        },
        [theme.breakpoints.up('lg')]: {
            height: 500,
            width: 700,
        },
    },
    noCitasCard: {
        height: 70, marginRight: 30, marginLeft: 30,
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.up('xs')]: {
            marginTop: 30,
            flexDirection: 'row',
            marginRight: 10, marginLeft: 10,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
            marginRight: 30, marginLeft: 30,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            marginRight: 30, marginLeft: 30,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 0,
            marginRight: 30, marginLeft: 30,
        },
    },
    tipografia: {
        flex: 1,
        marginLeft: 30,
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    botonRedirec: {
        flex: 1,
        height: 40,
        marginRight: 30,
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        float: "right",
    },
    marginBottom: {
        marginLeft: -15,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    // FORM DATOS PERSONALES
    cardDatosPersonales: {
        marginTop: 175,
        marginRight: 15,
        paddingTop: 10,
        paddingLeft: 15,
        position: 'relative',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            marginTop: 230,
            marginLeft: 15,
            width: 'calc(100% - 30px)',
            position: 'relative'
        }
    },
    formSection: {
        display: 'flex',
        marginTop: 15,
        marginBottom: 15,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginTop: 0
        }
    },
    titleFormSection: {
        paddingLeft: 10,
        paddingTop: 10,
    },
    formField: {
        flex: 1,
        marginLeft: 10,
        [theme.breakpoints.down('sm')]: {
            marginTop: 15
        }
    },
    singleFormSection: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            marginTop: -20,
        }
    },
    // RESUMEN
    resumenCard: {
        width: 'calc(100% - 330px)',
        minHeight: 350,
        marginTop: 175,
        display: 'flex',
        position: 'fixed',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 30px)',
            marginLeft: 15,
            marginTop: 205,
            flexDirection: 'column',
            position: 'relative',
            paddingBottom: 20
        }
    },
    resumenFullCard: {
        width: 'calc(100% - 330px)',
        height: 500,
        display: 'flex',
        position: 'fixed',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 30px)',
            flexDirection: 'column',
            height: 'unset',
            marginLeft: 15,
            marginTop: 20,
            paddingBottom: 20,
            position: 'unset'
        }
    },
    resumenSectionOne: {
        width: '50%',
        marginRight: 10,
        marginLeft: 10,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 30px)'
        },
    },
    resumenSectionTwo: {
        width: '50%',
        marginLeft: 10,
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'unset',
            marginLeft: 38,
            width: 'calc(100% - 30px)'
        }
    },
    headerResumen: {
        paddingLeft: 30,
        paddingTop: 25,
        fontWeight: 'bold',
        color: 'grey'
    },
    tramiteName: {
        fontWeight: 'bold',
        paddingLeft: 30,
        paddingTop: 55,
        fontSize: 20,
        marginRight: 50,
        [theme.breakpoints.down('md')]: {
            paddingTop: 10,
            marginRight: 0,
            fontSize: 15
        }
    },
    tramiteInfo: {
        paddingLeft: 30,
        paddingTop: 30,
        fontSize: 17,
        marginRight: 250,
        [theme.breakpoints.down('md')]: {
            marginRight: 20,
            paddingRight: 10
        }
    },

    seccionInfoOfiFecha: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        }
    },

    seccionesInfo: {
        display: 'flex',
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 8,
    },
    seccionesInfoContacto: {
        display: 'flex',
        paddingTop: 12,
        paddingBottom: 12,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    bottomButtonAlignment: {
        display: 'flex',
        justifyContent: 'end',
        marginRight: '3%'
    },
    bottomContainerAlignment: {
        position: 'relative',
        justifyContent: 'end'
    },
    cardContainers: {
        display: 'flex',
        overflowY: 'scroll',
        justifyContent: 'start',
        height: '-webkit-fill-available',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column'
        }
    },
    phoneInputContainer: {
        '& .form-control': {
            width: '100%',
            padding: '10px 50px 8px',// Ajusta el padding para que coincida con TextField
            border: '0px',
            borderBottom: '2px solid rgba(0, 0, 0, 0.23)', // Bordes como en el TextField
            borderRadius: '0px',
            fontSize: '16px',
            margin: '5px',

            '&:focus': {
                borderBottom: '2px solid #3790f0',
                borderColor: 'transparent', // Hace que el borde sea transparente al hacer click/focus
                boxShadow: 'none', // Remueve cualquier sombra
            },
            '&:hover:not(:focus)':{
                borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
                borderColor: 'transparent',
                boxShadow: 'none', 
            },
            '&.invalid-number': {
                borderBottom: '2px solid red',
                borderColor: 'transparent',
                boxShadow: 'none', 
                '&:focus':{
                    borderBottom: '2px solid red',
                    borderColor: 'transparent', 
                    boxShadow: 'none', 
                },
                '&:hover:not(:focus)':{
                    borderBottom: '2px solid red',
                    borderColor: 'transparent', 
                    boxShadow: 'none', 
                }
            }
        },
    },

    phoneInputError: {
        '& .form-control': {
            borderBottom: '2px solid red', // Línea inferior en rojo si hay error
            borderRadius: '0px',
            borderColor: 'transparent',
            boxShadow: 'none',
            padding: '10px 40px 8px',
            margin: '5px',
            fontSize: '16px',

            '&:focus': {
                borderBottom: '2px solid red',
                borderColor: 'transparent', 
                boxShadow: 'none', 
            },
            '&:hover':{
                borderBottom: '2px solid red',
                borderColor: 'transparent', 
                boxShadow: 'none', 
            },
            '&.invalid-number': {
                borderBottom: '2px solid red',
                borderColor: 'transparent',
                boxShadow: 'none', 
                '&:focus':{
                    borderBottom: '2px solid red',
                    borderColor: 'transparent', 
                    boxShadow: 'none', 
                }
            }
        },
    },
});

export default styles;