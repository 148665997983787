import { FC, useReducer, useState } from "react";
import { PageContext, pageReducer, pageInitialState } from './hooks/page.hook';
import App from './app';
import PopUpsReducer from "contexts/pop-up.reducer";
import { PopUpContext } from "contexts/pop-up.context";
import ModelosActionsReducer from "contexts/modelos-action.reducer";
import { initialModelosActionState, ModelosActionContext } from "contexts/modelos-action.context";
import { makeStyles, useTheme, Dialog, DialogContent, useMediaQuery, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    content: {
        fontSize: 'larger'
    }
}));

export const Providers: FC = () => {
    const globalPageReducer = useReducer(pageReducer, pageInitialState);
    const popUpsReducer = useReducer(PopUpsReducer, { popUp: null });
    const ModelosActionReducer = useReducer(ModelosActionsReducer, initialModelosActionState);
    const [networkError, setNetworkError] = useState(false);
    const theme = useTheme();
    const classes = useStyles(theme);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    window.addEventListener('storage', () => {
        handleModalConnection();
    });

    const handleModalConnection = () => {
        const isDefined = sessionStorage.getItem('errorConnectionApi');
        isDefined
            ? setNetworkError(true)
            : setNetworkError(false);
    };

    return (
        <PageContext.Provider value={globalPageReducer}>
            <PopUpContext.Provider value={popUpsReducer}>
                <ModelosActionContext.Provider value={ModelosActionReducer}>
                    <App />
                    <Dialog
                        open={networkError}
                        fullScreen={fullScreen}
                        aria-labelledby="info-dialog-title"
                        aria-describedby="info-dialog-description"
                    >
                        <DialogContent className={classes.content}>
                            <p>S'estan fent tasques de manteniment a la Seu electrònica. El servei es restablirà tan aviat com sigui possible. Disculpau les molèsties</p>
                        </DialogContent>

                        <Divider />

                        <DialogContent className={classes.content}>
                            <p>Se están realizando tareas de mantenimiento en la Sede electrónica. El servicio se restablecerá tan pronto como sea posible. Disculpe las molestias </p>
                        </DialogContent>
                    </Dialog>
                </ModelosActionContext.Provider>
            </PopUpContext.Provider>
        </PageContext.Provider>
    )
}