import { FC,  useContext,  useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { translate } from "utils/i18n";

import IoC from "contexts/ioc.context";
import { IAllowSolicitud, ICamposOptions, ISaveStepResponse } from "gateways/tramites/abonoNacimiento.interfaces";
import { AbonoNacimientoGateway } from "gateways/tramites/abonoNacimiento.gateway";
import { AlertsContext } from "contexts/alerts.context";
import { EMPTY_Deduccion, TDatosIdentificativos, TDeduccion, TIDentifica, TIdentificacionAbono, TModalidadSolicitud, TProgenitor, TReintegroAbonoNacimiento, TSolicitudAbonoNacimiento, TTipoSolicitud } from "./types";

import { StepComponentProps } from "components/generic-stepper/generic-step-component";
import GenericRadioButton, { IGenericRadioButtonOption } from "components/generic-radio-button";
import GenericPanelExpand from "components/generic-panel-expand";
import Identificativo, { EMPTY_IDENT_ERROR } from "./componentes/identificativo";
import GenericBooleanRadioButton, { BOOL_OPTIONS } from "components/generic-radio-button/generic-boolean-radio-button";
import DerechoDeduccion from "./componentes/derecho-deduccion";
import FullLoading from "components/full-loading";
import { camposToObject } from "../utils";
import GenericStepSchema from "components/generic-stepper/generic-step-schema";
import usePage from "hooks/page.hook";
import { useLocation } from "react-router";
import { THeaderProps } from "./componentes/headerStepper";
import { GenericModal } from "components/generic-modal";
import IdentificacionAbono from "./componentes/identificacion-abono";

import imgREF from 'resources/abonoNacimiento/Ref.png'
import { FECHA_PUESTA_MARCHA } from "constants/abono-nacimiento";
import moment from "moment";



type DatosIdentificativosProps = StepComponentProps  & WithStyles<typeof styles> 

const DatosIdentificativos: FC<DatosIdentificativosProps> = ({classes, idRef, apartado, onBack,onNext,onCancel, isFirst, isLast}) => { 
    const terms=useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext);
    const [pageState,] = usePage()
    const componentMounted = useRef(true);
    const location = useLocation();

    // Gateways
    const ioc = useContext(IoC);
    const abonoNacimientoGateway: AbonoNacimientoGateway = useMemo(() => ioc.get(AbonoNacimientoGateway), [ioc]);
    
    const [loading, setLoading]= useState(false);
    const [datos_identificativos,setDatosIdentificativos] = useState({} as TDatosIdentificativos);
    
    const [identificacion_abono,setIdentificacionAbono] = useState<TIdentificacionAbono|null>(null);
    
    const [emptyObject,setEmptyObject] = useState({} as TDatosIdentificativos)
    const [showMonoParental, setShowMonoParental]= useState(false);
    const [forceExit, setForceExit]= useState<{force: boolean, cause: string|undefined}>({force:false, cause: undefined});
    const [reqCleanDecl, setReqCleanDecl]= useState<boolean>(false);

    const [progenitorErr, setProgenitorErr] = useState(EMPTY_IDENT_ERROR)
    const [contribuyenteErr, setContribuyenteErr] = useState(EMPTY_IDENT_ERROR)
    const [options, setOptions] = useState<ICamposOptions | null>(null);


    // -- Memos    ---------------------------------------------------------------------------------------------------------------------------
    const loggedUser = useMemo(() => {
        if(pageState.jwp?.nif) {
            return {
                nif: pageState.jwp?.nif,
                nombre: pageState.jwp?.nombre,
                apellidos: pageState.jwp?.apellidos,
                email: undefined,
                parentesco:undefined,
                telefono: undefined
            } as TIDentifica
        } else {
            return null
        }
    },[pageState])

    const tipo = useMemo(() => ( location.pathname.includes('alta-solicitud') ? 'solicitud' : 'reintegro'),[location.pathname]);

    const disableNEXT= useMemo(() => {
        
        // Validacion goNext
        const rbtsOK = datos_identificativos.tipoSolicitud !== undefined && (
            datos_identificativos.tipoSolicitud==='insuficiencia' 
                ? datos_identificativos.modalidadSolicitud !== undefined 
                : !datos_identificativos.modalidadSolicitud
            );
        const princErr = Object.values(contribuyenteErr).map( item => item.value)
        const princOK = !(princErr.includes(true)) 
            && datos_identificativos.contribuyente 
            && Object.values(datos_identificativos.contribuyente).every(valor => (valor !== undefined && valor !== ''))

        const progErr = Object.values(progenitorErr).map( item => item.value)

        const progOK = !(progErr.includes(true))
            && datos_identificativos.progenitor 
            && ( datos_identificativos.modalidadSolicitud !== 'conjunta' ||
                (  (datos_identificativos.tipoSolicitud === 'anticipado' && 
                        ( Object.values({
                                nif: datos_identificativos.progenitor.nif,
                                nombre: datos_identificativos.progenitor.nombre,
                                apellidos: datos_identificativos.progenitor.apellidos,
                                telefono: datos_identificativos.progenitor.telefono,
                                email: datos_identificativos.progenitor.email,
                                parentesco: datos_identificativos.progenitor.parentesco 
                            }).every(valor => (valor !== undefined && valor !== ''))
                            && ( datos_identificativos.contribuyente &&  datos_identificativos.progenitor.nif !== datos_identificativos.contribuyente.nif)
                            && ( tipo === 'solicitud' && datos_identificativos.tipoSolicitud === 'anticipado' 
                                ? datos_identificativos.progenitor.solicita !== undefined 
                                : true 
                            )
                        )
                    ) || datos_identificativos.tipoSolicitud === 'insuficiencia'
                )
            )

        
        const nacOK =  datos_identificativos.derechoDeduccion 
            && Object.values({
                nombre: datos_identificativos.derechoDeduccion.nombre,
                apellidos: datos_identificativos.derechoDeduccion.apellidos,
                fecha: datos_identificativos.derechoDeduccion.fechaNacimiento
            }).every(valor => (valor !== undefined && valor !== ''))
            && (datos_identificativos.derechoDeduccion.paisId !== undefined)
            && (datos_identificativos.derechoDeduccion.provinciaId!== undefined || datos_identificativos.derechoDeduccion.provinciaNombre!== undefined)
            && (datos_identificativos.derechoDeduccion.municipioId !== undefined|| datos_identificativos.derechoDeduccion.municipioNombre!== undefined)
      

        return rbtsOK && princOK && progOK && nacOK ? false : true;

    },[datos_identificativos ]);


    const reqModalidad = useMemo(() => {
        if((!datos_identificativos.tipoSolicitud || datos_identificativos.tipoSolicitud==='anticipado') && datos_identificativos.modalidadSolicitud !== undefined){
            setDatosIdentificativos(curr => ({...curr, modalidadSolicitud: undefined}))
        }
        return datos_identificativos.tipoSolicitud && datos_identificativos.tipoSolicitud==='insuficiencia' ? true : false;
    },[datos_identificativos.tipoSolicitud])


    const showPanels = useMemo(()=> (
        datos_identificativos.tipoSolicitud !== undefined 
        && ( !reqModalidad || (reqModalidad && datos_identificativos.modalidadSolicitud!== undefined) )
        && ( tipo === 'solicitud' || (tipo === 'reintegro' && identificacion_abono && identificacion_abono.idSolicitud) ) 
        ? true :false
    ),[tipo,reqModalidad, datos_identificativos.modalidadSolicitud, datos_identificativos.tipoSolicitud,identificacion_abono])


    const progenitorEval =useMemo(()=>{
        //console.log(datos_identificativos.progenitor)
        const { solicita, ...common } = datos_identificativos.progenitor ?? {}
        const checkSolicita = ((datos_identificativos.tipoSolicitud ==='anticipado' && solicita !== undefined) || (datos_identificativos.tipoSolicitud ==='insuficiencia' && solicita === undefined)) ? true:false;
        //console.log(checkSolicita, solicita, datos_identificativos.tipoSolicitud )
        //console.log('common ', datos_identificativos.progenitor )
        return{
            empty: Object.values(datos_identificativos.progenitor ?? {}).every(valor => valor === undefined || valor === ''),
            completed: Object.keys(common).length > 0 ? (Object.values(common).every(valor => valor !== undefined && valor !== '' ) && checkSolicita ) : false
        }
    },[datos_identificativos.progenitor, datos_identificativos.tipoSolicitud]) 


    // -- Functions --------------------------------------------------------------------------------------------------------------------------
    const handleOnExit = async () => {
        try {
            setLoading(true)
            const tipoD = tipo ==='solicitud' 
                ? datos_identificativos.tipoSolicitud ==='anticipado' ? 50 :52
                : datos_identificativos.tipoSolicitud ==='anticipado' ? 51 :53
            await abonoNacimientoGateway.saveStep(idRef,'datos_identificativos',datos_identificativos, undefined, {causa: forceExit.cause ?? 'generic-error', tipoDoc: tipoD})
            
        } catch (error) {
            
        } finally{
            setLoading(false)
            onCancel()
        }
    }

    const handleNext = async () => {
        const params :string[] = [];
        try {
            setLoading(false)
            let withClean = false
            let isAllow: IAllowSolicitud = {allow: false, cause: undefined, params:[]}
            
            let toSave= {...datos_identificativos}
            // Comprobar de nuevo fecha nacido
            if(tipo === 'solicitud'){
                const monthLater= await abonoNacimientoGateway.getRefDate('month',1,FECHA_PUESTA_MARCHA)
                const back15days = await abonoNacimientoGateway.getRefDate('day',-15, moment().format('YYYY-MM-DD'))
                const fechaRef = new Date(FECHA_PUESTA_MARCHA);
            
                if(toSave.tipoSolicitud === 'anticipado' && monthLater && back15days){ // 50
                    // Validar fecha anterior a puesta en marcha y hoy no hace más de un mes de puesta en marcha
                    const nacimTime = new Date(moment(toSave.derechoDeduccion?.fechaNacimiento).format('YYYY-MM-DD')).getTime()
                    const refTime = fechaRef.getTime()
                    if(nacimTime < refTime){
                        const now = new Date().getTime()
                        if(now > new Date(monthLater).getTime()){
                            throw new Error('error_masDeUnMes')
                        }
                    } else {       
                        if(nacimTime < new Date(back15days).getTime() ){
                            throw new Error('error_masDe15dias')
                        }
                    }
                }
                if(toSave.tipoSolicitud === 'insuficiencia'){ //52
                    const ejercicio =new Date().getFullYear()
                    const prevEjercicio = ejercicio - 1;
                    const max= new Date(`${prevEjercicio}-12-31`)
                    const min= new Date(`${prevEjercicio}-01-01`)
                    if(toSave.derechoDeduccion?.fechaNacimiento && (
                        toSave.derechoDeduccion.fechaNacimiento < min 
                        || toSave.derechoDeduccion?.fechaNacimiento > max
                    )){
                        // Validar que es 01-01/31-12/ Ejercicio anterior
                        params.push(`: 01/01 al 31/12 de ${prevEjercicio}.`)
                        throw new Error('error_rango_fechas')
                    }

                }
            }
           
            /* If en que solo pedimos monoparental para 50
            if( tipo === 'solicitud' && datos_identificativos.tipoSolicitud === 'anticipado' && 
                datos_identificativos.progenitor &&  datos_identificativos.progenitor.nif && 
                datos_identificativos.familiaMonoparental !== false
            ){
            */

            // Pedir monoparental en caso 50 sin progenitor
            if(tipo === 'solicitud' && datos_identificativos.tipoSolicitud === 'anticipado' 
                && datos_identificativos.progenitor &&  datos_identificativos.progenitor.nif && 
                datos_identificativos.familiaMonoparental !== false
            ){
                if(datos_identificativos.familiaMonoparental !== undefined && idRef !== null){withClean=true}
                toSave= {...datos_identificativos, familiaMonoparental: false}
            }

            // Validaciones
            if(tipo === 'solicitud'){
                isAllow = await abonoNacimientoGateway.allowSolicitud(toSave)
            }

            if(tipo === 'reintegro'){
                isAllow ={ ...isAllow, allow: identificacion_abono?.idSolicitud !== null}
            }
                
            if(isAllow.allow){                
                //console.log('reqCleanDecl', reqCleanDecl ||withClean)
                const response = await abonoNacimientoGateway.saveStep(idRef,'datos_identificativos',toSave, (reqCleanDecl || withClean ? ['declaracion_responsable']: undefined))
                const response2 =(tipo === 'reintegro') 
                    ? await abonoNacimientoGateway.saveStep(response.referencia,'identificacion_abono',identificacion_abono)
                    : {saved:true, referencia: response.referencia, error:undefined}
                
                if(response.saved && response2.saved && response.referencia){
                    
                    const infoHeader: THeaderProps = {
                        tipoDocTerm: options ? options['ABO_NAC_tipoSolicitud'].find(item => item.id === datos_identificativos.tipoSolicitud)?.term: null,
                        modalidad: datos_identificativos.modalidadSolicitud,
                        // tipo: tipo
                    }
                    onNext(response.referencia, infoHeader )
                } else {
                    let msg = response.error !== undefined ? response.error : response2.error 
                    if(msg){
                        alertsDispatch({
                            type:'show-alert',
                            payload: {
                                variant:'error',
                                message: translate('AbonoNacimiento', msg ,terms) //+ translate('AbonoNacimiento','datos_identificativos',terms)
                            }
                        })
                    } else {throw new Error ('ErrorSaveStep')}
                }
            } else {
                setDatosIdentificativos(toSave)
                setForceExit({force: true, cause:isAllow.cause})
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        variant: 'error',
                        message: translate('AbonoNacimiento', isAllow.cause ?? 'notAllowSolicitud', terms, isAllow.params)
                    }
                });
            }
        } catch (error) {
            const msg = (error as Error).message

            alertsDispatch({
                type:'show-alert',
                payload: {
                    variant:'error',
                    message: translate('AbonoNacimiento', msg,terms,params ) //+ translate('AbonoNacimiento','datos_identificativos',terms)
                }
            })
            
        } finally {
            setLoading(false)
        }
    }

    const getInitialInfo = async (ref: string, initialObj: TDatosIdentificativos) => {
        try {
            // consultar datos BBDD
            const response =  tipo === 'solicitud' 
            ? await abonoNacimientoGateway.getApartadosByRef<'solicitud'>(ref)
            : await abonoNacimientoGateway.getApartadosByRef<'reintegro'>(ref);
            // consultar datos BBDD
            let initVal = response.datos_identificativos ?? {...initialObj} as TDatosIdentificativos
            const AllEmpty = initVal.derechoDeduccion ? Object.values(initVal.derechoDeduccion).map( v => v=== undefined) : [true]
            if(!AllEmpty.includes(false)){
                initVal['derechoDeduccion'] = {...EMPTY_Deduccion} 
            }
            if(tipo == 'reintegro' && (response as TReintegroAbonoNacimiento).identificacion_abono){
                setIdentificacionAbono((response as TReintegroAbonoNacimiento).identificacion_abono ?? null )
            }
            return initVal
        } catch (error) {
            return emptyObject
        }
    }

    const getInfoSolicitudRel = async (id: number) => {
        try {
            setLoading(true)
            // consultar datos BBDD
            const response = await abonoNacimientoGateway.getJsonSolicitudById(id)
            if(!response){ throw new Error()}
            // consultar datos BBDD
            if(response.datos_identificativos){
                setDatosIdentificativos( curr => ({...response.datos_identificativos} as TDatosIdentificativos))
            }

            
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    variant: 'error',
                    message: translate('AbonoNacimiento', 'ErrorRecuperarJsonSolicitud', terms)
                }
            })
        } finally {
            setLoading(false)
        }
    }
   
    // -- Effects  ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                //  INICIALIZACIÓN
                let today = new Date();
                today.setHours(0);
                today.setMinutes(0);
                today.setSeconds(0);

                const dispoDoc52 = await abonoNacimientoGateway.getDispoTipoDoc( today.getFullYear(), 52)
                let max = new Date(dispoDoc52.fechaFin);
                max.setHours(0);
                max.setMinutes(0);
                max.setSeconds(0);
                let min = new Date(dispoDoc52.fechaInicio);
                min.setHours(0);
                min.setMinutes(0);
                min.setSeconds(0);
                const disable_52 = (today.getTime() >= min.getTime()) && (today.getTime() <= max.getTime()) ? false : true;

                const dispoDoc50 = await abonoNacimientoGateway.getDispoTipoDoc( today.getFullYear(), 50)
                let max2 = dispoDoc50 && dispoDoc50.fechaFin ? new Date(dispoDoc50.fechaFin): null; // Fecha hasta NULL => Está habilitado por defecto
                if(max2){
                    max2.setHours(0);
                    max2.setMinutes(0);
                    max2.setSeconds(0);
                }
                let min2 = dispoDoc50 && dispoDoc50.fechaInicio ? new Date(dispoDoc50.fechaInicio): null;
                if(min2){
                    min2.setHours(0);
                    min2.setMinutes(0);
                    min2.setSeconds(0);
                }
                console.log(today,min, min2)
                const disable_50 = max2 
                    ?   min2 // Fecha inicio NULL => Está deshabilitado por defecto
                        ? (today.getTime() >= min2.getTime()) && (today.getTime() <= max2.getTime()) ? false : true 
                        : true
                    : false;

                

                const campos =  await abonoNacimientoGateway.getCamposStep('datos_identificativos')
                const emptyObject= camposToObject(campos)
                let options =  await abonoNacimientoGateway.getCamposOptionStep('datos_identificativos')
                const tipoTermsReintergo= options['ABO_NAC_tipoSolicitud'].map( item =>({
                    ...item, 
                    term: tipo === 'reintegro'? item.term.replace('Solicitud', 'Reintegro'): item.term, 
                    moreInfo: item.moreInfo
                        ? tipo === 'reintegro' ? {...item.moreInfo, template: item.moreInfo.template.replace('Solicitud', 'Reintegro'), size: 'lg'}: {...item.moreInfo, size: 'lg'}
                        : undefined,
                    disabled: tipo === 'solicitud' && ((item.id === 'insuficiencia' && disable_52) || (item.id === 'anticipado' && disable_50)) ? true : false
                }) as IGenericRadioButtonOption )

                options['ABO_NAC_tipoSolicitud'] = tipoTermsReintergo 
               
                
                //console.log('emptyObject ', emptyObject)
                let initVal = {...emptyObject, derechoDeduccion: {...EMPTY_Deduccion}} as TDatosIdentificativos
                if( idRef !== null){
                    initVal =await getInitialInfo(idRef, initVal)
                }

                //console.log('options', options)

                if(componentMounted.current){
                    setOptions(options)
                    setDatosIdentificativos(initVal)
                    setEmptyObject({...emptyObject} as TDatosIdentificativos)
                }

            } catch(err){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        variant:'error',
                        message: translate('AbonoNacimiento','ErrorLoadingStep',terms)
                    }
                })
            } finally {
                setLoading(false)
            }
            
        })();

        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
        
    },[idRef, apartado, tipo]);

    useEffect(() => {
        if (tipo === 'reintegro'){
            (async() => {
                if(identificacion_abono && identificacion_abono.idSolicitud){
                    await getInfoSolicitudRel(identificacion_abono.idSolicitud)
                } else {
                    setDatosIdentificativos( curr => ({
                        ...curr,
                        contribuyente: undefined,
                        progenitor: undefined,
                        derechoDeduccion:undefined,
                        familiaMonoparental: undefined
                    }))
                }
                
            })();
        }
    },[identificacion_abono, tipo])


    return (
        <GenericStepSchema
            isFirst={isFirst}
            isLast={isLast}
            onNext={() => {

                // Pedir monoparental en caso 50 y sin otro progenitor 
                // Pedir monoparental en 52 - siempre 
                if(tipo === 'solicitud'){
                    // Pedir monoparental en caso 50 y sin otro progenitor 
                    if (datos_identificativos.tipoSolicitud === 'anticipado'){
                        if (progenitorEval.empty){
                            if(datos_identificativos.familiaMonoparental === false ){setDatosIdentificativos(curr => ({...curr, familiaMonoparental: undefined}));}
                            setShowMonoParental(true)
                        } else {
                            if(progenitorEval.completed){
                                handleNext()
                            } else{
                                alertsDispatch({
                                    type: 'show-alert',
                                    payload:{
                                        message: translate('AbonoNacimiento', 'infoProgenitor_incompleta', terms),
                                        variant:'info'
                                    }
                                })
                            }
                        }
                    } else if(datos_identificativos.tipoSolicitud === 'insuficiencia' ){
                        // Pedir monoparental en caso  52 - siempre
                        if(progenitorEval.empty || progenitorEval.completed){
                            setShowMonoParental(true)
                        } else {
                            alertsDispatch({
                                type: 'show-alert',
                                payload:{
                                    message: translate('AbonoNacimiento', 'infoProgenitor_incompleta', terms),
                                    variant:'info'
                                }
                            })
                        }
                        
                    }

                } else {
                    handleNext() 
                }
                
            }}
            disabledNext={disableNEXT}
            onBack={onBack}
            disabledBack={false}
            onExit={handleOnExit}
            forceExit={forceExit.force} 
            idRef={idRef} 
            withRecoverRefContinuarMasTardeModal={false}
            withContinuarMasTardeModal={true}
            >

            <FullLoading loading={loading}/>
            {/**Componente  */}
            <>
            <Grid container direction="column" alignItems="center" justify="flex-start">
                { tipo === 'reintegro' 
                    ?
                        <Grid item className={[classes.fullWidth, classes.columnLeft].join(' ')}> 
                        {identificacion_abono == null || identificacion_abono.idSolicitud === null ? 
                            <IdentificacionAbono 
                                nif={loggedUser?.nif}
                                tipoSolicitud={datos_identificativos.tipoSolicitud}
                                setInfo={ setIdentificacionAbono}
                                setForceExit={setForceExit}
                            />
                            : <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <img width={35} height={45} className={classes.image} src={imgREF} alt={'imgREF'} />
                                <Typography style={{marginLeft: 10}}>{`${translate('AbonoNacimiento','refSolicitud',terms)}: ${identificacion_abono.ejercicio}/${identificacion_abono.numSolicitud} `}</Typography>
                            </div>
                        }
                        </Grid>
                    : null
                }
                {/** ROW - Selectores de solicitud y modalidad */}
                { options && ( tipo ==='solicitud'|| (tipo === 'reintegro' && identificacion_abono && identificacion_abono.idSolicitud !== null)) &&
                    <Grid item className={[classes.fullWidth, classes.row, classes.spaceBetween].join(' ')}>
                        <GenericRadioButton 
                            key='tipoSolicitud'
                            disabled={tipo === 'reintegro'}
                            componentIG='AbonoNacimiento'
                            labelTerm={tipo === 'solicitud'? 'ABO_NAC_tipoSolicitud':'ABO_NAC_tipoReintegro' }
                            value={datos_identificativos.tipoSolicitud}
                            options={options['ABO_NAC_tipoSolicitud'] } //TipoSolicitud_OPTIONS}
                            onChangeValue={ (v: string) => {
                                setDatosIdentificativos( curr => ({...curr, 
                                    tipoSolicitud: v as TTipoSolicitud, 
                                    derechoDeduccion: curr.derechoDeduccion ? {...curr.derechoDeduccion, fechaNacimiento: undefined}: undefined
                                }))
                                setIdentificacionAbono(null)
                                setForceExit({force: false, cause: undefined})
                                if(idRef && reqCleanDecl === false){ setReqCleanDecl(true) }
                            }}
                            // moreInfo={{
                            //     template: tipo === 'solicitud'? 'ABO_NAC_tipoSolicitud_info': 'ABO_NAC_tipoReintegro_info', size: 'sm'
                            // }}
                        />
                        {reqModalidad ?
                            <GenericRadioButton
                                key='modalidadSolicitud'
                                disabled={tipo === 'reintegro'}
                                componentIG='AbonoNacimiento'
                                labelTerm='ABO_NAC_modalidadSolicitud'
                                value={datos_identificativos.modalidadSolicitud}
                                options={options['ABO_NAC_modalidadSolicitud']} //ModalidadSolicitud_OPTIONS}
                                onChangeValue={ (v: string) => {
                                    setDatosIdentificativos( curr => ({...curr, modalidadSolicitud: v as TModalidadSolicitud}))
                                    if(idRef && reqCleanDecl === false){ setReqCleanDecl(true) }
                                }}
                                // moreInfo={{
                                //     template: tipo === 'solicitud'? 'ABO_NAC_modalidadSolicitud_info': 'ABO_NAC_modalidadReintegro_info', size: 'sm'
                                // }}
                            />
                            : null
                        }
                    </Grid>
                }
                
                { showPanels
                ? 
                    <Grid item className={[classes.fullWidth, classes.column].join(' ')}>
                        <GenericPanelExpand key={'contribuyente'}
                            renderHeader={() =>
                            <div style={{display:'inline-flex', alignItems: 'center', justifyContent:'space-between', width: '100%'}}>
                                { datos_identificativos.tipoSolicitud === 'anticipado' 
                                ? <Typography>{translate('AbonoNacimiento','panel_contribuyente',terms)}</Typography> 
                                : <Typography>{translate('AbonoNacimiento','panel_principal',terms)}</Typography>
                                } 
                                {tipo === 'solicitud' ? 
                                    <Button variant="outlined" color="inherit" 
                                        className={classes.button}
                                        disabled={loggedUser === null || (
                                            datos_identificativos.contribuyente?.nif !== undefined && 
                                            loggedUser.nif === datos_identificativos.contribuyente.nif
                                        )}
                                        onClick={()=> { if(loggedUser) { 
                                            setDatosIdentificativos(curr =>({...curr, contribuyente: loggedUser}))
                                            setContribuyenteErr(curr =>({...curr, nif: {value: false, msg: undefined}}))

                                        }}}
                                    >
                                        {translate('Global','chargeUserLogged', terms)}
                                    </Button>
                                : null
                                }
                                    
                            </div> 
                            }
                            renderContent={() => {
                                return(
                                    <Grid container>
                                        <Identificativo key='contribuyente'
                                            nif={datos_identificativos.contribuyente?.nif}
                                            nombre={datos_identificativos.contribuyente?.nombre}
                                            apellidos={datos_identificativos.contribuyente?.apellidos}
                                            telefono={datos_identificativos.contribuyente?.telefono}
                                            email={datos_identificativos.contribuyente?.email}
                                            parentesco={datos_identificativos.contribuyente?.parentesco}
                                            required
                                            disabled={tipo === 'reintegro'}
                                            errors={contribuyenteErr}
                                            onChange={(key: keyof TIDentifica, value: string | undefined, err:boolean) =>{
                                                if(key === 'nif' && value !== undefined && !err){
                                                    setContribuyenteErr(prev => ({...prev,
                                                        nif: {
                                                            value: loggedUser && value !== loggedUser.nif ? true : false,
                                                            msg: translate('AbonoNacimiento','notLoggedNif', terms)  
                                                        }
                                                    }))
                                                } else {
                                                    setContribuyenteErr(curr => ({...curr, [key]: {...curr[key],value: err}}))
                                                }
                                                if(forceExit.force === true){setForceExit({force:false, cause:undefined})}
                                                setDatosIdentificativos(curr=> ({
                                                    ...curr,
                                                    contribuyente: {
                                                        ...curr.contribuyente, 
                                                        [key]: value, 
                                                    } as TIDentifica
                                                }))
                                            }}
                                        />
                                    </Grid>
                                )
                            }}
                            expandable={false}
                            initialPosition='expanded'
                            
                            // withDelete={true}
                            // onDelete= {() => console.log('delete')}
                            // color?
                        />

                       { tipo === 'reintegro' && datos_identificativos.tipoSolicitud === 'anticipado'
                         ?  null
                         :  <GenericPanelExpand key={'progenitor'}
                                renderHeader={() => <Typography>{translate('AbonoNacimiento','panel_progenitor',terms)}</Typography> }
                                renderContent={() => {
                                    return(
                                    <Grid container>
                                        <Identificativo key='progenitor'
                                            nif={datos_identificativos.progenitor?.nif}
                                            nombre={datos_identificativos.progenitor?.nombre}
                                            apellidos={datos_identificativos.progenitor?.apellidos}
                                            telefono={datos_identificativos.progenitor?.telefono}
                                            email={datos_identificativos.progenitor?.email}
                                            parentesco={datos_identificativos.progenitor?.parentesco}
                                            required={datos_identificativos.modalidadSolicitud === 'conjunta'}
                                            disabled={tipo == 'reintegro'}
                                            errors={progenitorErr}
                                            onChange={(key: keyof TIDentifica, value: string | undefined, err:boolean) => {
                                                if(key === 'nif' && value !== undefined && datos_identificativos.contribuyente?.nif !== undefined && !err){
                                                    setProgenitorErr(prev => ({...prev,
                                                        nif: {
                                                            value: value === datos_identificativos.contribuyente?.nif ? true : false,
                                                            msg: translate('AbonoNacimiento','sameNif', terms) 
                                                        }
                                                    }))
                                                } else {
                                                    setProgenitorErr(curr => ({...curr, [key]: {...curr[key],value: err}}))
                                                }
                                         
                                                if(idRef && reqCleanDecl === false){ setReqCleanDecl(true) }
                                                if(forceExit){setForceExit({force:false, cause:undefined})}
                                                setDatosIdentificativos(curr=> ({
                                                    ...curr,
                                                    progenitor: {
                                                        ...curr.progenitor, 
                                                        [key]: value, 
                                                    } as TProgenitor
                                                }))
                                            }}
                                        />
                                        {tipo == 'solicitud' && datos_identificativos.tipoSolicitud === 'anticipado' &&
                                            <GenericBooleanRadioButton
                                                labelInRow={true}
                                                optionsInRow={true}
                                                componentIG='AbonoNacimiento'
                                                labelTerm='ABO_NAC_pregunta_solicita'
                                                value={datos_identificativos.progenitor?.solicita}
                                                options={BOOL_OPTIONS}
                                                onChangeValue={ (v: boolean, err: boolean) =>  {
                                                    setDatosIdentificativos(curr=> ({
                                                        ...curr,
                                                        progenitor: {
                                                            ...curr.progenitor, 
                                                            solicita: v 
                                                        } as TProgenitor
                                                    }))
                                                }}
                                                required={datos_identificativos.modalidadSolicitud === 'conjunta'}
                                            />
                                        }
                                    </Grid>
                                )
                            }}
                            expandable={false}
                            initialPosition='expanded'
                            withDelete={ tipo === 'reintegro' ? false : true}
                            onDelete= {() => {
                                setDatosIdentificativos(curr => ({...curr, progenitor: emptyObject.progenitor}))
                                setProgenitorErr(EMPTY_IDENT_ERROR)
                                if(idRef && reqCleanDecl === false){ setReqCleanDecl(true) }
                                if(forceExit){setForceExit({force:false, cause:undefined})}
                            }}
                                //color?
                            />
                        }
                        <GenericPanelExpand key={'derecho-derechoDeduccion'}
                            renderHeader={() => <Typography>{translate('AbonoNacimiento','panel_derecho_deduccion',terms)}</Typography> }
                            renderContent={() => (
                                <Grid container >
                                    <DerechoDeduccion
                                        tipo={tipo}
                                        tipoSolicitud={datos_identificativos.tipoSolicitud}
                                        deduccion={ datos_identificativos.derechoDeduccion }
                                        onChange={(newV: TDeduccion) => { 
                                            setDatosIdentificativos(curr=> ({
                                                ...curr,
                                                derechoDeduccion: {...newV}

                                            }))
                                            if(forceExit.force){setForceExit({force:false, cause:undefined})}
                                        }}
                                        onForceExit= {(v: boolean) =>{ setForceExit({force:v, cause: v== true ? 'PlazosFechaNacimiento': undefined}) }}
                                    />

                                </Grid>
                            )}
                            expandable={false}
                            initialPosition='expanded'
                            // withDelete={true}
                            // onDelete= {() => console.log('delete')}
                            //color?
                        />
                    </Grid>
                : null}

            </Grid>

            </>

            <GenericModal open={showMonoParental}>
                <>
                    <Typography variant="h6">{translate('AbonoNacimiento','askMonoParental' ,terms)}</Typography>
                    <GenericBooleanRadioButton
                        optionsInRow={true}
                        componentIG='AbonoNacimiento'
                        labelTerm=''
                        value={datos_identificativos.familiaMonoparental}
                        options={BOOL_OPTIONS}
                        onChangeValue={(v: boolean ,err: boolean) => {
                            setDatosIdentificativos(curr=> ({...curr, familiaMonoparental: v}))
                            if(idRef && reqCleanDecl === false){ setReqCleanDecl(true) }
                        }}
                    />
                    {/**Si NO-Monoparental es preciso tener progenitor y datos completos */}
                    {datos_identificativos.familiaMonoparental === false && //progenitorEval.empty && datos_identificativos.tipoSolicitud === 'anticipado' && 
                    (progenitorEval.empty || !progenitorEval.completed) &&
                        <Typography  style={{marginTop: 10, marginBottom: 20,fontWeight: 'bolder'}}>
                            {translate('AbonoNacimiento',progenitorEval.empty ? 'infoRequiredProgenitor2': 'infoProgenitor_incompleta' ,terms)}
                            {/* {translate('AbonoNacimiento','infoRequiredProgenitor2' ,terms)} */}

                        </Typography>
                    }
                
                    <Button 
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            {/** Continuar si es MONOPARENTAL o NO-Monoparental+Progenitores */}
                            if( //(datos_identificativos.tipoSolicitud === 'anticipado'  && 
                                    (datos_identificativos.familiaMonoparental === true || (datos_identificativos.familiaMonoparental === false && progenitorEval.completed ) )
                                //)|| datos_identificativos.tipoSolicitud === 'insuficiencia' 
                            ){
                                handleNext();
                            }
                            setShowMonoParental(false)
                        }}
                        disabled={datos_identificativos.familiaMonoparental === undefined}
                    >
                        {/** Gestión LABEL - Continuar: si es MONOPARENTAL o NO-Monoparental+Progenitores, Completar: si no cumple */}
                        {translate(
                            'Global'
                            , (
                                //(datos_identificativos.tipoSolicitud === 'anticipado'  && 
                                    (datos_identificativos.familiaMonoparental === true || (datos_identificativos.familiaMonoparental === false && progenitorEval.completed ) )
                                //)|| datos_identificativos.tipoSolicitud === 'insuficiencia' 
                              ) ? 'Continuar' : 'btnComplete'
                            ,terms)}
                    </Button>
                </>
            </GenericModal> 


        </GenericStepSchema>
        
    )
}


const styles = (theme: Theme): StyleRules => ({
    fullWidth: {
        width: "100%"
    },
    row: {
        display: 'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    column: {
        display: 'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    columnLeft: {
        display: 'flex',
        flexDirection:'column',
        alignItems:'flex-start'
    },
    spaceBetween:{
        justifyContent:'space-between'
    },
    button: {
        "&:disabled":{ color: '#d3d3d3', borderColor:'#d3d3d3'}
    }
});

export default withLiterals(['Global', 'Tramites','AbonoNacimiento' ])(withStyles(styles)(DatosIdentificativos))

