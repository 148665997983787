import { Checkbox, FormControl, FormControlLabel,FormHelperText, Typography, withStyles } from "@material-ui/core";
import React, {CSSProperties, FC, useContext} from "react"
import LabelWithInfo from "./label-with-info";
import duti_styles from "containers/DUTI/styles";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { translate } from "utils/i18n";
import { TInfoTemplateSize } from "contexts/more_info.reducer";

interface ICheckBox {
    fieldTerm: string;
    value: boolean;
    onChangeValue: (v:boolean) => void
    moreInfo?: {template?: string, size?: TInfoTemplateSize},
    error?:boolean,
    errorMsg?:string,
    addToLabel?: string
    disabled?: boolean,
    boldLabel?: boolean,
    style?: CSSProperties,
    componentIG?:string
}

const CheckBox: FC<ICheckBox> = ({value, onChangeValue, fieldTerm, moreInfo, error ,errorMsg, addToLabel, disabled, style, boldLabel=false, componentIG })=>{
    const terms = useContext(LiteralsContext);
    
    return (
        <FormControl component="fieldset" style={{paddingTop: 5 /*, paddingBottom: 10*/}} >
            
            <div style={{...style, display:'flex', flexDirection: 'row', alignItems:'center', padding:0}}>
                <FormControlLabel 
                    disabled={disabled}
                    control={
                        <Checkbox style={{alignSelf: 'flex-start', paddingRight: 10,  paddingLeft: 10, paddingTop: 0, paddingBottom: 0}}
                            checked={value}
                            onChange={(ev, checked) =>{ onChangeValue(checked) }}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label={
                        <Typography style={{fontWeight: boldLabel ? 'bold': 'normal',margin:0}}>
                            { translate(componentIG ?? 'DUTI',fieldTerm,terms).concat(addToLabel ?? '')}
                        </Typography>
                    }
                />
                <LabelWithInfo type="field" fieldTerm={""} moreInfo={moreInfo} />
            </div>
            {error && <FormHelperText style={{paddingLeft:33}} error={true} >{errorMsg}</FormHelperText> }
            
        </FormControl>
    )
}

export default withLiterals(['DUTI', 'Global'])(withStyles(duti_styles)(CheckBox));
      