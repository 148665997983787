import { Card, CardContent, Collapse, Divider, Grid, IconButton, Tooltip, makeStyles, useTheme } from '@material-ui/core';
import { Icon } from 'atomic/atoms/icon/icon';
import Term from 'components/term';
import { getImgCategory } from 'containers/faqs/categorias-faqs';
import React, { useCallback, useState } from 'react';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        zIndex: 99,
        backbroundColor: '#000',
        //borderRadius: 0,
        marginLeft: 10,
        position:'fixed', 
        width: 'calc(100% - 340px)',
        [theme.breakpoints.down('md')]:{
            width:  'calc(100% - 40px)',
        }

    },
    cardelemento: {
        display: 'flex',
        alignItems: 'center',
    },
    textoCard: {
        flex: 1,
        fontSize: 20,
        marginLeft: '1.5rem',
        color: '#004f84',
    },
    collapseSubCategory: {
        padding: '20px'
    },    
    divIconSubCategori: {
        display: 'flex',
        flexDirection: 'row-reverse',
        padding: '10px'
    },
    cursor: {
        cursor: 'pointer'
    },
    textoSubCategory:{
        color: '#004f84',
        fontSize: '16px',
        marginRight: '10px'
    },
   
}));


export interface ICategory {
    id: number,
    categoryName: string,
    iconPath: string,
    subCategories: ICategory[],
    idParent?: number | null
}

interface IProps {
    activeCategory: number;
    listCategorias: ICategory[];
    handleNavigate: (id: number) => void;
    literalsComponent: string;
}

export function getIconFromCategory(categoryId: number, categories: ICategory[]) {
    return categories.find((x) => x.id === categoryId)?.iconPath;
}

export function getNameFromCategory(categoryId: number, categories: ICategory[]) {
    return categories.find((x) => x.id === categoryId)?.categoryName;
}

export const MenuIconCategories: React.FC<IProps> = ({activeCategory, listCategorias, handleNavigate, literalsComponent }) => {
    const theme= useTheme();
    const classes = useStyles(theme);
    const [collapse, setCollapse] = useState<any>([]);

    return (
        <Card className={classes.container}>
            <Grid container direction='row' /*justify='space-around'*/ >
            {   listCategorias.map((x) => {
                    const imgIcon = getImgCategory(x.iconPath)
                    return(
                        <Grid item style={{ 
                            flex: 1, 
                            textAlign:'center',
                            borderBottom: 'solid', 
                            borderColor: activeCategory === x.id ?"#004f82": 'white',
                            backgroundColor: activeCategory === x.id ? "#c5def1": 'white',
                            }} >
                        <Tooltip title={ <p style={{fontSize: 15, margin: 5}}><Term component={literalsComponent} text={x.categoryName}/></p>} placement='top' >
                            <IconButton onClick={() => handleNavigate(x.id)} >
                                
                                { imgIcon 
                                    ? <img src={imgIcon} alt={x.iconPath} width={50} height={50}/>
                                    : <Icon name={x.iconPath as any} size={2} color="#004f82"/>
                                }
                                
                                
                            </IconButton>
                        </Tooltip>
                        </Grid>
                       
                )})
            }

            </Grid>
            
        </Card>
    )
}

/* <Card key={`Categoria_${x.id}_${x.categoryName}`} className={classes.cursor} 
    style={
        x.id === 10 ? { // FAQs destacada
            fontWeight: 550,
            borderColor: "#004f82",
            borderWidth: '1px',
            borderStyle: 'solid',
            backgroundColor:'#c2ddf2'
            }: undefined
        } 
    >
    <CardContent onClick={() => handleNavigate(x.id)}>
        <div className={classes.cardelemento}>
            <Icon name={x.iconPath as any} size={2} color="#004f82"></Icon>
            <div className={classes.textoCard}>
                <Term component={literalsComponent} text={x.categoryName}></Term>
            </div>
        </div>
    </CardContent>
    {
        x.subCategories && x.subCategories.length > 0 &&
        <>
            <div onClick={() => toggleCollapse(`Categoria_${x.id}_${x.categoryName}`)} className={classes.divIconSubCategori}>
                <Icon name={'magnify-plus-outline'} size={1} color="#004f82"></Icon>
                <span className={classes.textoSubCategory}>ver subcategorias</span>
            </div>
            <Divider />
        {
            x.subCategories.map((z) => (
                <Collapse in={collapse[`Categoria_${x.id}_${x.categoryName}`]} timeout="auto" unmountOnExit  >
                    <div className={classes.collapseSubCategory} onClick={() => handleNavigate(z.id)}>
                        <Term component={literalsComponent} text={z.categoryName}></Term>
                    </div>
                </Collapse>
            ))
        }
            
                
        </>
    }

    </Card>*/