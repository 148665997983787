import './string';

export interface IFormatNumberOptions {
    allowEmpty?: boolean
    isMoney?: boolean;
    thousandSeparator?: string;
    decimalSeparator?: string;
    minDecimalLength?: number;
    maxDecimalLength?: number;
};

const defaultFormatNumberOptions: IFormatNumberOptions = {
    allowEmpty: false,
    isMoney: false,
    thousandSeparator: '.',
    decimalSeparator: ',',
    minDecimalLength: 0,
    maxDecimalLength: 0
};

function castToNumber(value: any): number {
    let num: number = 0;

    if (typeof value === 'string') {
        num = parseFloat(value);
    } else if (typeof value === 'number') {
        num = value;
    } else {
        throw new Error('Unsupported type: ' + typeof value);
    }

    return num;
}

function formatIntPart(integer: string, options: IFormatNumberOptions): string {
    const intParts: string[] = [];
    for (let i = 0; i < integer.length; i += 3) {
        const from = integer.length - i - 3;
        const extra = from < 0 ? Math.abs(from) : 0;
        const length = 3 - extra;
        intParts.push(integer.substr(from + extra, length));
    }

    return intParts.reverse().join(options.thousandSeparator);
}

function formatDecPart(decimal: string, options: IFormatNumberOptions): string {
    let output: string = '';

    if (decimal !== undefined) {
        output = options.decimalSeparator + decimal;
    } else {
        return options.minDecimalLength
            ? options.decimalSeparator + ''.padEnd(options.minDecimalLength, '0')
            : '';
    }

    if (options.minDecimalLength !== undefined && options.minDecimalLength > 0) {
        const extraLength = options.minDecimalLength - decimal.length;
        if (extraLength > 0) {
            output = output.padEnd(decimal.length + extraLength + 1, '0');
        }
    }

    if (options.maxDecimalLength !== undefined && options.maxDecimalLength > 0) {
        if (output.length - 1 > options.maxDecimalLength) {
            output = output.substring(0, options.maxDecimalLength + 1);
        }
    }

    return output;
}

function formatMoney(value: string, options: IFormatNumberOptions): string {
    let output = value;

    if (options.isMoney) {
        output += '€';
    }

    return output;
}

export function formatNumber(value: number | string | null | undefined, options?: IFormatNumberOptions): string {
    options = Object.assign({}, defaultFormatNumberOptions, options);

    if (value === null || value === undefined) {
        return options.allowEmpty ? '' : '0';
    }

    const num: number = castToNumber(value);
    const [integer, decimal] = num.toString().split('.');

    let output = formatIntPart(integer, options)
        + formatDecPart(decimal, options);

    output = formatMoney(output, options);

    return output;
}