import {FC, useCallback, useContext, useEffect, useState} from "react";

import { IconButton, withStyles, WithStyles, TextField, TextFieldProps, Button } from "@material-ui/core";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { Icon } from 'atomic/atoms/icon/icon';
import duti_styles, { duti_colors } from "containers/DUTI/styles";
import { MoreInfoContext } from "contexts/more_info.context";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import { TExtraValidation } from "./types";


type TInput = Pick<TextFieldProps, 'className' |'disabled'|'helperText'|'variant' | 'size' | 'margin'|'error'> & WithStyles
interface IInputSearch extends TInput {
    fieldTerm: string,
    value: string | undefined,
    required: boolean,
    onChangeValue: (v:string | undefined, err: boolean) => void,
    onSearch: () => void,
    moreInfo?: {
        template: string, size?: TInfoTemplateSize
    },
    extraValidation?: (v: string) => TExtraValidation
    forceUpperCase?:boolean,
    disabledOnlyInput?:boolean,
    errorMsgExterno?: string,
};

type TMSGError = 'err_required_input_search'| undefined

const InputSearch : FC<IInputSearch>= ({
    classes, fieldTerm, value, required, onChangeValue,onSearch, moreInfo, extraValidation, error, errorMsgExterno,
    variant = 'outlined', size="small", margin="dense",helperText, className, forceUpperCase=false,
    disabled, disabledOnlyInput
}) => {
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);
    
    const [err, setErr] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState< string|undefined>(undefined)

    const validate = (value: string | undefined) => {
        let error = false;
        let msg: TMSGError;
        let msgExtraValidation: string | undefined = undefined;

        if(!disabledOnlyInput) {
            if(required && (value === undefined || value === '')){
                error=true
                msg='err_required_input_search'
               
            } 
            
            if(extraValidation && !error && value){
                const result = extraValidation(value)
                error= result.error
                msgExtraValidation = result.error_msg
            }
            
        }
        
        setErr(error)
        setErrMsg(msg ? translate('DUTI',msg, terms) : msgExtraValidation)
        return error ? false : true
    }

    const handleShowMoreInfo = useCallback(() => {
        if (moreInfo) {
            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: moreInfo.template ?? '',
                    size:moreInfo?.size ?? undefined,
                }
            });
        }
    }, [infoDispatch])

    useEffect(() => {
        // setea el error que me envían desde fuera
        if(error !== undefined){ 
            // if(error === false){
            //     setErr(error)
            // } else {
            //     const hasError = validate(value, true)
            // }
            setErr(error)
            if(errorMsgExterno && error ===true){ setErrMsg(errorMsgExterno)}
            if(error ===false){ setErr(false) }
        }
        if(value){validate(value)}
    }, [error, errorMsgExterno, value])

    return (
        <div style={{display:'flex', flexDirection: 'row', marginRight: 10}} >
            <TextField 
                size={size}
                disabled={disabled || disabledOnlyInput}
                variant={variant}
                label={fieldTerm ? translate('DUTI',fieldTerm, terms).concat(required? '*':'') : ''} 
                value={value ?? ''} 
                onChange={(e) =>{ 
                    onChangeValue( forceUpperCase ? e.target.value.toUpperCase() : e.target.value, false)
                    setErr(false)
                }} 
                margin={margin}
                className={className ?? classes.inputDefault}
                InputLabelProps={{classes: {
                    root: classes.inputLabel,
                }}}
                error={err}
                helperText={ err ? (errMsg ?? ' ') : ( (( !value || value.length === 0 )&& helperText )? helperText: ' ')}
                onBlur={() => {
                    const isValid = validate(value)
                    onChangeValue(value, !isValid)
                }}
            />

            <div className={ (size==='small' && margin==="dense") ? classes.input_small_dense : undefined } >
                {/* <IconButton key={'search'} style={{marginTop: 2}}
                    disabled= {true}
                    onClick={() => {
                        const isValid = validate(value)
                        onChangeValue(value, !isValid)
                        isValid && onSearch()
                    }} 
                    classes={{root: classes.inputSearch_btn}}  
                >
                    <Icon name="search" size={1}  color={duti_colors.white} />
                </IconButton> */}

                <Button 
                    classes={{root: classes.inputSearch_btn}} 
                    disabled= {disabled}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        const isValid = validate(value)
                        onChangeValue(value, !isValid)
                        isValid && onSearch()
                    }} 
                >
                    <Icon name="search" size={1}  color={duti_colors.white} />
                </Button>

                {moreInfo && 
                    <IconButton onClick={handleShowMoreInfo} disabled={disabled}>
                        <Icon name={'info-outline'} size={1} color={duti_colors.blueIconInfo}/>
                    </IconButton>
                }

            </div>
            
        </div>
           
    )
}

export default withLiterals(['DUTI'])(withStyles(duti_styles)(InputSearch));