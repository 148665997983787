import { Card, CardContent, CircularProgress, Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import { styles } from "./../styles";
import Term from "components/term";
import { IDocListRN, IDocResponse } from "../../cid.interface";
import DocListItem from "./doc-list-item";
import { useEffect } from "react";




interface IProps extends WithStyles<typeof styles> {
    isLoading: Boolean,
    items: IDocListRN[],
    documento: IDocResponse,
    handleShowInfo: (item: IDocListRN) => void
}


const DocumentList: React.FC<IProps> = ({ classes, isLoading, items, documento, handleShowInfo }) => {

    return (

        <Card style={{ margin: 10, marginRight: 5, display: 'flex', flex: 2 }}>
            {isLoading
                ?
                <div className={classes.centerContent} >
                    <CircularProgress />
                </div>
                :

                <CardContent style={{width:'100%'}}>
                    <div className={classes.cardTitle}>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                                <Term component="Tramites" text="descripcionDocumento"></Term>
                            </Typography>
                            <Typography >
                                <Term component="Tramites" text="numExpediente"></Term>
                                {`: ${documento.codigo}`}
                            </Typography>

                        </div>
                    </div>
                    <div className={classes.cardDescription}>
                        <div className={classes.cardFields}>
                            <Term component="Tramites" text="fechaResultado"></Term>
                            <Typography style={{ fontWeight: 'bold', fontSize: 13 }}>
                                {`: ${documento && documento.docRN && documento.docRN.FechaResultado
                                    ? documento.docRN.FechaResultado
                                    : "--"}`}
                            </Typography>
                        </div>
                        <div className={classes.cardFields}>
                            <Term component="Tramites" text="resultadoNotificacion"></Term>
                            <Typography style={{ fontWeight: 'bold', fontSize: 13 }}>
                                {`: ${documento && documento.docRN && documento.docRN.Resultado
                                    ? documento.docRN.Resultado
                                    : "--"}`}
                            </Typography>
                        </div>
                        <div className={classes.cardFields}>
                            <Term component="Tramites" text="direccionNotificacion"></Term>
                            <Typography style={{ fontWeight: 'bold', fontSize: 13 }}>
                                {`: ${documento && documento.docRN && documento.docRN.DireccionEnvio
                                    ? documento.docRN.DireccionEnvio
                                    : "--"}`}
                            </Typography>
                        </div>
                    </div >
                    <div className={classes.cardBody}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={8} sm={2} style={{marginRight: 55}}>
                                <Typography style={{ fontSize: 12 }}>
                                    <Term component="Tramites" text="referenciaRecibo" ></Term>
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={1} style={{ marginRight: '8px' }}>
                                <Typography style={{ fontSize: 12 }}>
                                    <Term text="N.I.F"></Term>
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={2}>
                                <Typography style={{ fontSize: 12 }}>
                                    <Term component="Tramites" text="Nombre"></Term>
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={2}>
                                <Typography style={{ fontSize: 12 }}>
                                    <Term component="Tramites" text="Concepto"></Term>
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={1}>
                                <Typography style={{ fontSize: 12 }}>
                                    <Term component="Tramites" text="Importe"></Term>
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={1} >
                                <Typography style={{ fontSize: 12 }}>
                                    <Term component="Tramites" text="Estado"></Term>
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={1}>
                                <Typography style={{ fontSize: 12 }}>
                                    <Term component="Tramites" text="ejercicio"></Term>
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={1}>
                                <Typography style={{ fontSize: 12 }}>
                                    <Term text="+ info"></Term>
                                </Typography>
                            </Grid>
                        </Grid>
                        <div style={{
                            height: "2px",
                            backgroundColor: "black"
                        }} />
                        <div className={classes.scrolleAble}>
                            {items.map((item: IDocListRN, i: number) => {
                                return (
                                    <DocListItem item={item} key={i} handleMoreInfo={() => handleShowInfo(item)} />
                                );
                            })}
                        </div>


                    </div>

                </CardContent >
            }
        </Card >
    );
}
export default withStyles(styles)(DocumentList);