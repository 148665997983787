import { REGEX_CP } from "containers/DUTI/utils"
import { translate } from "./i18n"
import { TExtraValidation } from "components/DUTI/types"

type CPResponse = { error: boolean, msg: 'dir_cp_error'|'dir_cp_prov_error'| undefined}
export const validateCP = (cp:string, idPais: string,idProvincia?: string): CPResponse => {
    // console.log('validateCP', cp,idPais, idProvincia)
    if(idPais === "00"){
        let isOK = true
        isOK = idPais === "00" ? REGEX_CP.test(cp) : true
        if(!isOK){ 
            return({error: true, msg: 'dir_cp_error'})
        }
        if(idProvincia && idProvincia !== cp.substring(0,2)){
            return({error: true, msg:'dir_cp_prov_error'})
        }
    }   
    return({error: false, msg: undefined})
}