import React, { FC, useContext } from "react";
import Term from "components/term";
import { FormControl, InputLabel, makeStyles, TextField } from "@material-ui/core";
import { FiltersContext } from "./filters.context";
import { FilterDataContext } from "./filter-data.context";
import SelectMultiple from "components/select-multiple";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    formControl: {
        margin: `30px 0`,
        [theme.breakpoints.up('md')]: {
            margin: `10px 5px`,
        }
    },
    formDateControl: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.up('md')]: {
            margin: `10px 5px`,
        }
    },
    group: {
        margin: `10px 0`,
    },
    block: {
        display: `inline-block`
    },
    hidden: {
        display: `none`
    },
    middle: {
        verticalAlign: `middle`
    },
}));

export type ScreenType = 'censo';

export interface IFiltersFormProps {
    screen: ScreenType;
}

const FiltersForm: FC<IFiltersFormProps> = ({ screen }) => {

    const [filters, filtersDispatch] = useContext(FiltersContext);
    const filterData = useContext(FilterDataContext);

    const handleMatriculaChange = (event: React.ChangeEvent<HTMLInputElement>) => filtersDispatch({ type: 'update', filter: 'matricula', value: event.target.value ? event.target.value.toUpperCase() : null });
    const handleLocalizadorChange = (event: React.ChangeEvent<HTMLInputElement>) => filtersDispatch({ type: 'update', filter: 'localizador', value: event.target.value ? event.target.value.toUpperCase().replace(/\D/g, '') : null });
    const handleTipoEstablecimientoChange = (est: string[]) => { filtersDispatch({ type: 'update', filter: 'tipoEstablecimiento', value: est }) };
    const handleEjercicioChange = (ejercicio: string[]) => { filtersDispatch({ type: 'update', filter: 'ejercicio', value: ejercicio }) };
    const handleReferenciaCatastralChange = (event: React.ChangeEvent<HTMLInputElement>) => filtersDispatch({ type: 'update', filter: 'referenciaCatastral', value: event.target.value ? event.target.value.toUpperCase() : null });



    const classes = useStyles();

    return (
        <div className={classes.root} style={{ display: 'contents' }}>
            <FormControl key='matricula' className={classes.formControl} fullWidth>
                <TextField
                    key='matricula'
                    value={filters.matricula || ''}
                    label={<Term component="Global" text="Matricula" />}
                    onChange={handleMatriculaChange}
                />
            </FormControl>
            {/* 
            * SE DEJA EL LOCALIZADOR EN CASO DE QUE EN UN FUTURO SE PIDA LA IMPLEMENTACIÓN. 
            * el localizador representa las remesas de ejercicios en los que se tributaron cada uno de los establecimientos, para mayor facilidad en el filtrado
            * se ha incluido el campo en cada uno de los establecimientos.
            */}
            {/* <FormControl key='localizador' className={classes.formControl} fullWidth>
                <TextField
                    key='localizador'
                    // value={filters.localizador || ''}
                    label={<Term component="Tramites" text="localizador" />}
                    onChange={handleLocalizadorChange}
                />
            </FormControl> */}
            <FormControl key='tipoEstablecimiento' className={filterData && filterData.tipoEstablecimiento ? classes.formControl : classes.hidden} fullWidth>
                <InputLabel key='label-estado' htmlFor="select-multiple-chip">
                    <Term component="Tramites" text="TipoEstablecimiento" />
                </InputLabel>
                <SelectMultiple
                    key='tipoEstablecimientos'
                    options={filterData && filterData.tipoEstablecimiento ? filterData.tipoEstablecimiento : []} value={filters.tipoEstablecimiento || []}
                    onChange={(values: any) => handleTipoEstablecimientoChange(values)}
                />
            </FormControl>
            <FormControl key='ejercicio' className={filterData && filterData.ejercicio ? classes.formControl : classes.hidden} fullWidth>
                <InputLabel key='label-estado' htmlFor="select-multiple-chip">
                    <Term component="Tramites" text="ejercicio" />
                </InputLabel>
                <SelectMultiple
                    key='ejercicio'
                    options={filterData && filterData.ejercicio ? filterData.ejercicio : []} value={filters.ejercicio || []}
                    onChange={(values: any) => handleEjercicioChange(values)}
                />
            </FormControl>
            <FormControl key='referenciaCatastral' className={classes.formControl} fullWidth>
                <TextField
                    key='referenciaCatastral'
                    value={filters.referenciaCatastral || ''}
                    label={<Term component="Tramites" text="ReferenciaCatastral" />}
                    onChange={handleReferenciaCatastralChange}
                />
            </FormControl>

        </div>
    );
}

export default FiltersForm;