import React, { FC, useContext, useCallback } from "react";
import Term from "components/term";
import { FormControl, InputLabel, makeStyles, Select, MenuItem, TextField, ListItemText } from "@material-ui/core";
import { FiltersContext } from "./filters.context";
import { FilterDataContext } from "./filter-data.context";
import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    formControl: {
        margin: `30px 0`,
        [theme.breakpoints.up('md')]: {
            margin: `10px 5px`,
        }
    },
    group: {
        margin: `10px 0`,
    },
    block: {
        display: `inline-block`
    },
    hidden: {
        display: `none`
    },
    middle: {
        verticalAlign: `middle`
    },
}));

export type ScreenType = 'procedimientos';

export interface IFiltersFormProps {
    screen: ScreenType;
}

const FiltersForm: FC<IFiltersFormProps> = ({ screen }) => {
    const classes = useStyles();

    const [filters, filtersDispatch] = useContext(FiltersContext);
    const filterData = useContext(FilterDataContext);

    const handleFamiliaChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        filtersDispatch({ type: 'update', filter: 'familia', value: event.target.value as string });
    }, [filtersDispatch]);

    const handleCodigoSIAChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        filtersDispatch({ type: 'update', filter: 'codigoSIA', value: event.target.value });
    }, [filtersDispatch]);

    const delayed = debounce((q:any) => filtersDispatch({ type: 'update', filter: 'nombre', value: q }), 100);

    const handleNombreChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        delayed(event.target.value);
    }, [delayed]);


    return (
        <div className={classes.root}>
            <FormControl key='familia' className={filterData && filterData.familia ? classes.formControl : classes.hidden} fullWidth>
                <InputLabel key='label-familia' htmlFor="select-multiple-chip">
                    <Term component="Tramites" text="Familia" />
                </InputLabel>
                <Select
                    value={filters.familia}
                    autoWidth={true}
                    onChange={handleFamiliaChange}
                >
                    <MenuItem value={undefined}>
                        <ListItemText primary={<Term component="Tramites" text='TODOS' />} />
                    </MenuItem>
                    {(filterData && filterData.familia ? filterData.familia : []).map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            <ListItemText primary={option.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl key='codigoSIA' className={classes.formControl} fullWidth>
                <TextField
                    key='codigoSIA'
                    value={filters.codigoSIA || ''}
                    label={<Term component="Tramites" text="CodigoSIA" />}
                    onChange={handleCodigoSIAChange}
                />
            </FormControl>
            <FormControl key='nombreProcedimiento' className={classes.formControl} fullWidth>
                <TextField
                    key='nombreProcedimiento'
                    value={filters.nombre || ''}
                    label={<Term component="Tramites" text="NombreProcedimiento" />}
                    onChange={handleNombreChange}
                />
            </FormControl>
        </div>
    );
}

export default FiltersForm;