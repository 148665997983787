import { AxiosError } from 'axios';
import { translate } from './i18n';

interface IValidationMessage {
    property: string;
    constraints: { [key: string]: string };
}

export function generateConstraintsMessage(message: IValidationMessage): string[] {
    const terms = getLiteralsFromCache();
    const output: string[] = [];

    for (const ckey in message.constraints) {
        if (Object.prototype.hasOwnProperty.call(message.constraints, ckey)) {
            switch (ckey) {
                case 'isNotEmpty':
                    output.push(translate('Global', message.property, terms) + ' ' + translate('Global', 'no puede estar vacio', terms));
                    break;
                default:
                    output.push(message.constraints[ckey]);
            }
        }
    }

    return output;
}

export function createValidationError(error: AxiosError): Error {
    const terms = getLiteralsFromCache();

    // Request failed with status code 404
    if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.message instanceof Array) {
            const output: string[] = [];
            for (const message of error.response.data.message) {
                if (typeof message === 'string') {
                    output.push(translate('Global', message, terms));
                } else if ('constraints' in message) {
                    const r = generateConstraintsMessage(message);
                    for (const m of r) {
                        output.push(m);
                    }
                } else {
                    output.push(message.toString());
                }
            }
            const andTranslated = ' ' + translate('Global', 'y', terms) + ' ';
            throw new Error(output.map((msg, index, arr) => (arr.length - 1 === index ? andTranslated : (index === 0 ? '' : ', ')) + msg).join('') + '.');
        }
        throw new Error(translate('Global', error.response.data.message, terms));
    }
    else if (error.message === 'Network Error') {
        sessionStorage.setItem('errorConnectionApi', error.message);
        window.dispatchEvent(new Event("storage"));
    }

    return error;
}

export function transformNullForEmptyStrings<T extends { [key: string]: any }>(object: T): T {
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key) && (object[key] === null || object[key] === undefined)) {
            if (key !== 'km') {
                (object as any)[key as string] = '';
            }
        }
    }
    return object;
}

export const getLiteralsFromCache = () => {
    const lang = localStorage.getItem('lang') || 'es';
    const terms: any = {};
    for (let i = 0; i < sessionStorage.length; i++) {
        const storageKey = sessionStorage.key(i);
        const preffix = `norm_literals.${lang}.`;

        if (storageKey && storageKey.indexOf(preffix) === 0) {
            const component = storageKey.replace(preffix, '');
            try {
                const data = sessionStorage.getItem(storageKey);
                if (data) {
                    terms[component] = JSON.parse(data);
                }
            // tslint:disable-next-line: no-empty
            } catch (e) {
            }
        }
    }

    return terms;
}