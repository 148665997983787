import React, { FC } from 'react'
import usePage, { IPageNotificationWithOpen } from "hooks/page.hook";
import SnackbarCustomized from "./snackbar.customized";

const WrapSnackbarCustomized: FC<{
    notification: IPageNotificationWithOpen,
    onClose: (notification: IPageNotificationWithOpen) => void
}> = ({ notification, onClose }) => {

    const handleClose = () => {
        onClose(notification);
    }

    return (<SnackbarCustomized
        key="wrap_notification"
        open={notification.open}
        message={notification.message}
        autoHideDuration={notification.autoHideDuration}
        onClose={handleClose}
        variant={notification.variant}
    />);
}

const Notifications: FC = () => {
    const [page, pageDispatcher] = usePage();

    const handleClose = (x: IPageNotificationWithOpen) => {
        pageDispatcher({ type: 'hide-notification', payload: x })
        setTimeout(() => pageDispatcher({ type: 'remove-notification', payload: x }), 1000);
    };

    return (
        <div>
            {page.notifications.map((x: IPageNotificationWithOpen, index: number) => (
                <WrapSnackbarCustomized
                    key={'notification' +  index}
                    notification={x}
                    onClose={handleClose}
                />
            ))}
        </div>
    );
};

export default Notifications;