import { FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import { Grid, withStyles, WithStyles } from "@material-ui/core";
import { TPreguntasPlusvalia, TTipoTransmPLV} from "./types";
import plv_styles from './styles';
import StepSchema from "../shared/stepSchema";
import { IParamStepper } from "../shared/types";
import IoC from "contexts/ioc.context";
import ModelGateway  from "gateways/model.new.gateway";

import EndProcessMSG from "../../../../components/Modelos/componentes/endProcessMSG";
import { formatSelectOptions, TSelect } from "containers/DUTI/utils";
import { IMunicipioPLV, TTipoTransmision } from "gateways/model.new.interface";
import CatastroGateway from "gateways/catastro.gateway";
import { AlertsContext } from "contexts/alerts.context";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import MuniAndRefCatInfo from "components/Modelos/componentes/muniAndRefCatInfo";
import { TInmuebleCatastro } from "gateways/catastro.interface";
import TipoTransmisionBonificacion, { TTipoTransErr } from "components/Modelos/componentes/tipoTransmisionBonificacion";
import ActuaRepresentacion from "components/Modelos/componentes/actuaRepresentacion";
import { TRepresentante } from "containers/DUTI/steps/types";
import { translate } from "utils/i18n";
import LabelWithInfo from "components/DUTI/label-with-info";
import BooleanRadioButton from "components/DUTI/boolean-radio-button";
import RadioButton from "components/DUTI/radio-button";
import { useRouteMatch } from "react-router";
import { IModeloAsistenteParams } from "containers/tributos-autonomicos-locales/modelos-tributos-asistente";
import usePage from "hooks/page.hook";
import { ContenidosGateway } from "gateways/contenido.gateway";


export interface IPreguntasPlusvaliaProps extends IParamStepper, WithStyles<typeof plv_styles> {
    apartado: string;
    orden:number;
}

const PREGUNTA_RESIDENTE: TTipoTransmision[]= ['DO','DD','SH'];

const PreguntasPlusvalia: FC<IPreguntasPlusvaliaProps> = ({ 
    classes, apartado, orden,idRef ,isFirst,isLast, handleBack,handleExit,handleNext,setHeader
}) => {
   
    const componentMounted = useRef(true);

    //Gateways
    const ioc = useContext(IoC);
    const modelosGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
   
    // Contexts
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const match = useRouteMatch<IModeloAsistenteParams>()
    
    
    // Local
    const [loading, setLoading]= useState(false)
    const [municipios, setMunicipios] = useState([] as TSelect[]);
    const [munis081, setMunis081]= useState([] as string[])
    const [preguntas, setPreguntas]=useState<TPreguntasPlusvalia>({} as TPreguntasPlusvalia)
    // Errors
    const [errMuniAndRefCatInfo, setErrMuniAndRefCatInfo] = useState<[
        {errSelect: boolean},
        {errDiffMuni: boolean, msgTerm: string},
        {errRefCat:boolean}
    ]>([
        {errSelect: false},
        {errDiffMuni: false, msgTerm: 'plusvalias_error_diffMunicipio'}, // content
        {errRefCat: false}
    ])
    const [tipoTransmErr, setTipoTransmErr] = useState(false)
    const [actuaNomPropErr, setActuaNomPropErr] = useState(false)

    const [haveChanges, setHaveChanges] = useState(false)

    const [msgModalBoni, setMsgModalBoni] = useState<string | undefined>(undefined)

    /** MEMOS ------------------------------------------------------------------------------------------------*/
    const TipoPlusvalModeloOPTIONS = useMemo(() =>{
        if(munis081.length>0 && preguntas.municipio?.id){
            const OPT = [
                {id:'080', term: 'tipoPLV080'},
                {id:'082', term: 'tipoPLV082'}
            ]
            const opt081={id: '081', term: 'tipoPLV081'}
            if(munis081.includes(preguntas.municipio?.id)){
                OPT.unshift(opt081)
            }
            return OPT
        } else{
            return [] 
        }
    }, [preguntas.municipio, munis081])

    // Deshabilita el botón de continuar - Si algun campo obligatorio no esta o hay algún error
    const disabledContinuar= useMemo(()=> {
        const errors = ( 
            errMuniAndRefCatInfo[0].errSelect || errMuniAndRefCatInfo[1].errDiffMuni ||errMuniAndRefCatInfo[2].errRefCat
            || tipoTransmErr
            || actuaNomPropErr
        );
        const allRequired = preguntas.municipio?.id && preguntas.inmueble && preguntas.tipoTransmision!== undefined 
            && preguntas.actuaNombrePropio!== undefined && preguntas.sujetoPasivo!== undefined 
            && preguntas.tipoPlusvaliaModelo !== undefined ;

        return allRequired && !errors ? false : true
    },[
        preguntas,
        errMuniAndRefCatInfo[0].errSelect, errMuniAndRefCatInfo[1].errDiffMuni, errMuniAndRefCatInfo[2].errRefCat,
        tipoTransmErr,
        actuaNomPropErr
    ])

    /** ------------------------------------------------------------------------------------------------------*/


    /** GESTIÓN NAV Step -----------------------------------------------------------------------------------------*/
    const onExit = (cause:'continuar-mas-tarde'|'force')  => {
        // validate if pending changes - MSG - save info ¿?
        handleExit()
    }

    const onBack = () => {
        handleBack()
    }

    const onNext = async () => {
        try {
            setLoading(true)          
            // guardar el apartado
            const result = haveChanges ? await modelosGateway.saveStepAsistente(idRef, apartado, orden, preguntas): {ok: true, idRef: idRef}
            if(result.ok){
                setLoading(false)
                setHaveChanges(false)
                // si todo OK
                if(match.params.action === 'nuevo'){
                    console.log('goNext & setRef ---- > from PreguntasPlusvalia')
                    handleNext(result.idRef)
                } else {
                    console.log('goNext ---- > from PreguntasPlusvalia')
                    handleNext()
                }
            }  
        } catch(err) {
            setLoading(false)
            const error = err as Error
            const errMSG = translate('Plusvalias', error.message, terms);
            const cause= error.cause ?? "";
            alertsDispatch({
                type: "show-alert",
                payload: {
                  message: errMSG.concat(`.${cause}`),
                  variant: "error",
                },
            });
        }
    }

    const boniOK = async() => {
        try {
            setLoading(true)
            let isChecked = true
            if(preguntas.tipoTransmision && preguntas.tipoTransmision.bonificacion && preguntas.tipoTransmision.bonificacion.idTarifa && preguntas.inmueble){
                const boniResponse = await modelosGateway.checkBonificacion(preguntas.tipoTransmision.bonificacion, preguntas.inmueble)
                isChecked= boniResponse
            }
            return isChecked;
        } catch (error) {
            return false
        } finally {
            setLoading(false)
        }
        
    }

    /** ------------------------------------------------------------------------------------------------------*/

    /** FUNCIONES --------------------------------------------------------------------------------------------*/
    
    /** ------------------------------------------------------------------------------------------------------*/

    /** EFFECTS ----------------------------------------------------------------------------------------------*/
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                const prevInfo = idRef ? await modelosGateway.getAllApartadosModeloAsistente('PLV',idRef) : undefined
                const municipios =  await modelosGateway.getMunicipiosPLV('No se ha podido recuperar los municipios')
                const munis081 =  await modelosGateway.getIdMunisPermiten081PLV('No se ha podido recuperar los municipios que permiten 081')
                const boniMSG = await contenidoGateway.getContent(
                    'plusvalias_info_modal_warn_bonificacion',
                    {}
                );
                if(componentMounted.current){
                    setMunicipios(formatSelectOptions<IMunicipioPLV>('idMunicipio', 'Nombre', municipios))
                    setMunis081(munis081)
                    if(prevInfo && prevInfo.preguntasPlusvalia){
                        setPreguntas(prevInfo.preguntasPlusvalia)
                    }
                    setMsgModalBoni(boniMSG[0].contenido)
                }
            } catch (err){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: err,
                        variant: "error"
                    }
                });
            } finally {
                setLoading(false)
            }
            
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
            
        }
    },[])

    useEffect(()=> {
        setHeader(
            preguntas.inmueble && preguntas.municipio ?
            {
                dir: preguntas.inmueble.direccion ?? " - ",
                muni: preguntas.municipio.id,
                refCat: preguntas.inmueble.refCatastral,
                urlCatastro: preguntas.inmueble.urlCatastro
            }: null
        )
    },[preguntas.municipio, preguntas.inmueble])
    /** ------------------------------------------------------------------------------------------------------*/

    return(
        <StepSchema idRef={idRef} isFirst={isFirst} isLast={isLast} 
            onNext={async() =>{ 
                if(await boniOK() || !haveChanges){ 
                    onNext() 
                } else { 
                    alertsDispatch({ 
                        type: 'show-alert',
                        payload: {
                            message: msgModalBoni,
                            variant: "warning",
                            hasCustomAction: true,
                            handleCustomAction: () => { 
                                alertsDispatch({ type: 'hide-alert' })
                                onNext();
                             },
                            actionTemplateName:  translate('DUTI','btn_continuar',terms),
                            defaultCloseActionTemplateName: translate('Plusvalias','btn_change_boni',terms),
                        }
                    })
                } 
            }} 
            disabledNext={disabledContinuar} 
            onBack={onBack} 
            onExit={onExit}
        >
            <>
            <MuniAndRefCatInfo
                labelWithInfo={{
                    municipio:{componenteIG:'Plusvalias', labelTermIg:'municipioLabel', infoContent:'plusvalias_info_field_municipio'},
                    refCatastral:{componenteIG:'Plusvalias', labelTermIg:'refCatastralLabel',infoContent: 'plusvalias_info_field_refCatastral'},
                }}
                selectedMuni={preguntas.municipio} 
                muniOptions={municipios} 
                onChangeMuni={(val: TSelect|undefined,err:boolean) => {
                    setPreguntas(curr => ({...curr, 
                        municipio: val, 
                        inmueble: null,
                        tipoTransmision: {
                            tipo: undefined, bonificacion: undefined,tipoTransmisionDescripcion: undefined
                        },
                        actuaNombrePropio: undefined,
                        checkRepresentacion: false,
                        representante: undefined,
                        sujetoPasivo: undefined,
                        tipoPlusvaliaModelo: undefined,
                    }))
                    setHaveChanges(true)
                    setErrMuniAndRefCatInfo( curr =>([
                        {...curr[0], errSelect: err},
                        {...curr[1]},
                        {...curr[2]}
                    ]))
                }} 
                inmueble={preguntas.inmueble} 
                onChangeInmueble={(inm: TInmuebleCatastro | null) => {
                    setPreguntas(curr => ({...curr, 
                        inmueble: inm,
                        tipoTransmision: {
                            tipo: undefined, bonificacion: undefined, tipoTransmisionDescripcion: undefined
                        },
                        actuaNombrePropio: undefined,
                        checkRepresentacion: false,
                        representante: undefined,
                        sujetoPasivo: undefined,
                        tipoPlusvaliaModelo: undefined
                    }))
                    setHaveChanges(true)
                    if(inm!== null && preguntas.municipio){
                        setHeader({
                            dir: inm.direccion ?? " - ",
                            muni: preguntas.municipio.id,
                            refCat: inm.refCatastral,
                            urlCatastro: inm.urlCatastro
                        })
                    } else {
                        setHeader(null)
                    }
                }}
                getErrRefCat={(v: boolean) => {
                    setErrMuniAndRefCatInfo( curr =>([{...curr[0]}, {...curr[1]},{...curr[2], errRefCat: v}]))
                }}
                getErrDiffMunis={(v: boolean) =>{
                    if(v){setHeader(null)}
                    setErrMuniAndRefCatInfo( curr =>([{...curr[0]},{...curr[1], errDiffMuni:v},{...curr[2]}]))
                }}
            />

            {preguntas.municipio && preguntas.inmueble &&
                <Grid container direction='column'>
                    <TipoTransmisionBonificacion
                        idMunicipio={preguntas.municipio.id}
                        nombreMuni={preguntas.municipio.nombre}
                        tipoTransmision={preguntas.tipoTransmision}
                        onChangeTipoTransmisiones={(v: TTipoTransmPLV, err: TTipoTransErr) => {
                            setPreguntas(curr => ({
                                ...curr, 
                                tipoTransmision: v,
                                actuaNombrePropio: undefined,
                                checkRepresentacion: false,
                                representante: undefined,
                                sujetoPasivo: undefined,
                                tipoPlusvaliaModelo: undefined
                            }))
                            setHaveChanges(true)
                            setTipoTransmErr(Object.values(err).includes(true) ? true: false)
                        }}
    
                    />
                    { preguntas.tipoTransmision && preguntas.tipoTransmision.tipo?.id &&
                    <>
                        <ActuaRepresentacion
                            actuaNombrePropio={preguntas.actuaNombrePropio}
                            checkRepresentacion={preguntas.checkRepresentacion}
                            representante={preguntas.representante}
                            onChange={(actuaNomP:boolean, repre:TRepresentante|undefined, err:boolean, check: boolean) => {
                                console.log('preguntas onChange', actuaNomP,repre,err)
                                let sujPas: undefined|boolean= undefined;
                                if(preguntas.tipoTransmision.tipo && PREGUNTA_RESIDENTE.includes(preguntas.tipoTransmision.tipo.id as TTipoTransmision)){
                                    sujPas= false
                                }
                                setPreguntas( curr => ({
                                    ...curr,
                                    actuaNombrePropio: actuaNomP,
                                    checkRepresentacion: check,
                                    representante: repre ?  {...repre}: undefined,
                                    sujetoPasivo: sujPas,
                                    tipoPlusvaliaModelo: undefined
                                }))
                                setHaveChanges(true)
                                setActuaNomPropErr(err)
                            }}
                            moreInfoActuaNombrePropio={{template: 'plusvalias_info_field_actua_nom_prop', size: 'xs'}}
                        />

                        { (preguntas.actuaNombrePropio !== undefined  && !actuaNomPropErr) && 
                        <>
                            <div className={classes.row} style={{marginTop:15}}>   
                            {!PREGUNTA_RESIDENTE.includes(preguntas.tipoTransmision.tipo.id as TTipoTransmision) 
                                ?
                                    <BooleanRadioButton 
                                        componentIG="Plusvalias"
                                        fieldTerm={'sujetoPasivo'}
                                        //labelAs="notLabel"
                                        row
                                        value={preguntas.sujetoPasivo}
                                        onChangeValue={ (v: boolean) =>{ 
                                            setPreguntas(curr => ({...curr, sujetoPasivo: v}))
                                            setHaveChanges(true)
                                        }}
                                        options={[
                                            {id: true , term:'si'}, 
                                            {id: false , term:'no'}
                                        ]}
                                    />
                                : null}
                            </div>
                            { preguntas.sujetoPasivo !== undefined && 
                                <RadioButton 
                                    componentIG='Plusvalias'
                                    fieldTerm={'tipoPlusvaliaModelo'}
                                    moreInfo={{template: 'plusvalias_info_field_tipo_plv', size: 'md'}}
                                    row
                                    value={preguntas.tipoPlusvaliaModelo?.id}
                                    onChangeValue={ (v: string)=> { 
                                        const opt = TipoPlusvalModeloOPTIONS.find(op => op.id === v)
                                        setPreguntas( curr =>({
                                            ...curr,
                                            tipoPlusvaliaModelo: opt
                                        }))
                                        setHaveChanges(true)
                                    }}
                                    options={TipoPlusvalModeloOPTIONS}
                                />
                            }
                        </>
                        }
                    </>
                    }
                </Grid>
            }
         
            {/**Mensajes Error tipo card*/}
            {errMuniAndRefCatInfo[1].errDiffMuni && 
                <EndProcessMSG 
                    content={errMuniAndRefCatInfo[1].msgTerm} 
                    paramContent={ errMuniAndRefCatInfo[1].errDiffMuni ? {muni: preguntas.municipio?.nombre ?? ""} : {} }
                />
            }
        
            </>
        </StepSchema>

    )
}

export default withLiterals(["Plusvalias","Global"])(withStyles(plv_styles)(PreguntasPlusvalia));