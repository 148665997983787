import { StyleRules } from "@material-ui/core";

const styles = (): StyleRules => ({
    // FLEX
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexColumn: {

    },
    justifySpaceBetween: {
        justifyContent: 'space-between'
    },
    gap: {
        marginTop: 10,
        gap: '1rem'
    },
    // PADDINGS & MARGINS
    padding: {
        paddingTop: 10,
        paddingBottom: 0,
        paddingLeft: 0, 
        paddingRight:0
    },
    marginDefault: {
        margin: 0,
    }
});

export default styles;