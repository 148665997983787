import React, { useState, useContext } from 'react';
import { IconButton, InputLabel, FormControl, Grid, Input, InputAdornment, FormHelperText, Button, makeStyles, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import Term from '../../../components/term';
import usePage from 'hooks/page.hook';
import IoC from 'contexts/ioc.context';
import { SujetosGateway } from 'gateways/sujetos.gateway';
import { translate } from 'utils/i18n';
import { LiteralsContext } from 'containers/shared/literals';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import { Icon } from '@mdi/react';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: 10,
        display: 'block',
        '& > div': {
            width: '100%',
        },
        '& > button': {
            width: '100%',
        }
    },
    [theme.breakpoints.up('sm')]: {
        formContainer: {
            '& > .MuiGrid-item': {
                padding: '0 20px'
            },
        },
        formContainerWithoutGrid: {
            '& > .MuiFormControl-root': {
                padding: '0 20px'
            }
        }
    },
}));

interface IProps {
    open: boolean;
    onClose: () => void;
}

function PasswordDialog(props: IProps) {
    const { onClose, open, } = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        onClose();
    }
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();

    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');
    const [textErrorPassword, setTextErrorPassword] = useState<string | null>(null);
    const [textErrorRepeatedPassword, setTextErrorRepeatedPassword] = useState<string | null>(null);

    const ioc = useContext(IoC);
    const perfilG = ioc.get(SujetosGateway) as SujetosGateway;

    const validatePassword = (passwordLocal: string, repeatedPasswordLocal: string) => {
        let textErrorPasswordLocal: string | null = null;
        let textRepeatedPassword: string | null = null;

        if (passwordLocal === '') {
            textErrorPasswordLocal = 'El campo no puede estar vacio';
        }
        if (passwordLocal !== '' && (passwordLocal.length < 6 || passwordLocal.length > 18)) {
            textErrorPasswordLocal = 'La contraseña debe tener entre 6 y 18 caracteres';
        }
        if (passwordLocal !== '' && (!new RegExp(/([A-Z])/).test(passwordLocal) || !new RegExp(/[a-z]/).test(passwordLocal) || !new RegExp(/\d/).test(passwordLocal))) {
            textErrorPasswordLocal = 'La contraseña debe contener almenos una minuscula, una mayúscula y un número';
        }
        if (repeatedPasswordLocal !== '' && (passwordLocal.length >= 6 || passwordLocal.length <= 18)) {

            if ((passwordLocal !== repeatedPasswordLocal)) {
                textRepeatedPassword = 'Las contraseñas no coinciden';
            }
        }

        setTextErrorPassword(textErrorPasswordLocal);
        setTextErrorRepeatedPassword(textRepeatedPassword);

        return !textErrorPasswordLocal && !textRepeatedPassword;
    }

    const handlePassword = (inputPassword: string) => {
        setPassword(inputPassword);
        validatePassword(inputPassword, repeatedPassword)
    }

    const handleRepatedPassword = (inputPassword: string) => {
        setRepeatedPassword(inputPassword);
        validatePassword(password, inputPassword)
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleClickShowRepeatedPassword = () => {
        setShowRepeatedPassword(!showRepeatedPassword);
    }


    const handlerChangePassword = async () => {

        if (validatePassword(password, repeatedPassword)) {
            try {
                await perfilG.changePassword(password)
                
                setShowPassword(false);
                setShowRepeatedPassword(false);
                setPassword('');
                setRepeatedPassword('');
                handleClose();

                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Perfil', 'La contraseña se ha guardado correctamente', terms),
                        variant: 'success',
                    }
                })
            } catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Perfil', e.toString(), terms),
                        variant: 'error',
                    }
                })
            }
        }
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Term component="Perfil" text="Cambiar contrasenya" />
                </DialogTitle>

                <DialogContent>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="adornment-password" error={textErrorPassword !== null}>
                                <Term component="Perfil" text="Nueva contraseña" />
                            </InputLabel>
                            <Input
                                id="adornment-password"
                                value={password}
                                error={textErrorPassword !== null}
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => handlePassword(e.target.value)}
                                inputProps={{
                                    tabIndex: 6,
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword}>
                                            <Icon path={showPassword ? mdiEye : mdiEyeOff}
                                                title="Password"
                                                size={1}
                                                color="dimgrey"
                                                className={classes.rightIcon}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {
                                textErrorPassword !== null
                                    ? <FormHelperText id="password-helper-text" error={textErrorPassword !== null}>
                                        <Term component="Perfil" text={textErrorPassword} />
                                    </FormHelperText>
                                    : null
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="adornment-repeated-password" error={textErrorRepeatedPassword !== null}>
                                <Term component="Global" text="Repetir contraseña" />
                            </InputLabel>
                            <Input
                                id="adornment-repeated-password"
                                type={showRepeatedPassword ? 'text' : 'password'}
                                value={repeatedPassword}
                                error={textErrorRepeatedPassword !== null}
                                onChange={(e) => handleRepatedPassword(e.target.value)}
                                inputProps={{
                                    tabIndex: 7,
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="Toggle repated password visibility" onClick={handleClickShowRepeatedPassword}>
                                            <Icon path={showRepeatedPassword ? mdiEye : mdiEyeOff}
                                                title="Password"
                                                size={1}
                                                color="dimgrey"
                                                className={classes.rightIcon}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {
                                textErrorRepeatedPassword != null
                                    ? <FormHelperText id="email-helper-text" error={textErrorRepeatedPassword !== null}>
                                        <Term component="Perfil" text={textErrorRepeatedPassword} />
                                    </FormHelperText>
                                    : null
                            }
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button color="default" onClick={handleClose}>
                            <Term component="Global" text="Cerrar" />
                        </Button>
                        <Button color="primary" onClick={handlerChangePassword}>
                            <Term component="Perfil" text="Guardar" />
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default PasswordDialog;