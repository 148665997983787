import React, { FC, useEffect, useCallback, useMemo, useState, useContext } from 'react';
import { useTheme, makeStyles } from "@material-ui/core";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import usePage from 'hooks/page.hook';
import Term from './term';

import { Icon } from '@mdi/react'
import { mdiHome, mdiLaptop, mdiShieldAccount, mdiCalendarClock, mdiBell, mdiWalletTravel, mdiFileAccount, mdiFrequentlyAskedQuestions, mdiHomeAccount, mdiOfficeBuilding, mdiSitemap } from '@mdi/js'

import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Hidden, Drawer } from '@material-ui/core';

import { LiteralsContext, withLiterals } from 'containers/shared/literals';

import logoAtibMenuLateral from '../resources/logoATIBMenuLateral.png';
import { translate } from 'utils/i18n';

const useStyles = makeStyles((theme) => ({
    list: {
        width: 'auto',
        marginRight:1
    },
    spacingLogo: {
        marginTop: 25,
        marginBottom: 10,
        textAlign: 'center',
    },
    drawer: {
        zIndex: 900,
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
            width: 300,
            marginTop: 230,
        },
    },
    drawerPaper: {
        top: 230,
        width: 300,
        borderRight: 'none',
        paddingTop: 25,
    },
    bold: {
        fontWeight: 'bold',
        fontSize: 15,
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    textLogo: {
        marginRight: '55px',
        color: '#004F84',
        marginBottom: '25px',
    },
    logoMenuLateral: {
        marginBottom: '-23px',
        marginTop: '15px',
        width: '60%'
    },
    [theme.breakpoints.up('md')]: {
        // subMenu: {
        //     '& a, button': {
        //         paddingLeft: 40,
        //     }
        // },
        list: {
            width: 'auto',
        },
        drawerPaper: {
            top: 230,
            height: 'calc(100% - 230px)',
            width: 300,
            borderTopRightRadius: 8
        },
        hideInDesk: {
            display: 'none',
        },
    },
    // Menu items
    listItemActive: {
        background: 'linear-gradient(to right, #004f82 10%, #dee2e6 10% )',
        [theme.breakpoints.up('md')]: {
            background: 'linear-gradient(to right, #004f82 20%, #dee2e6 20% )',
        }
    }
}));
type IProps = RouteComponentProps;

const AsLink = (to: string) => React.forwardRef((props, ref: any) => <Link {...props} style={{ textTransform: 'unset', textDecoration: 'none'  }} to={to} ref={ref as any} />) as any;
// const AsLinkBlank = (to: string) => React.forwardRef((props, ref: any) => <Button {...props} style={{ textTransform: 'unset' }} href={to} target="_blank" ref={ref as any} />) as any;
// const AsButton = (callback: (event: React.MouseEvent<HTMLButtonElement>) => void) => React.forwardRef((props, ref: any) => <Button style={{ textTransform: 'unset' }} {...props} onClick={callback} ref={ref as any} />) as any;

const SideNavLeft: FC<IProps > = ({ history }) => {
    // Gloabal States
    const theme = useTheme();
    const classes = useStyles(theme);
    const terms= useContext(LiteralsContext)
    const [pageState, pageDispatcher] = usePage();
    const [activeItem, setActiveItem] = useState(history.location.pathname)
    const isMobile = window.outerWidth <= 950;
    // Services 
    const handleClose = useCallback(() => pageDispatcher({ type: 'close-nav-left' }), [pageDispatcher]);
    const handleOpen = useCallback(() => pageDispatcher({ type: 'open-nav-left' }), [pageDispatcher]);
    const ROUTES= [
        {route: '/', icon:  mdiHome ,  term: {component:"Global", text:"INICIO"}},
        {route: '/perfil', icon: mdiShieldAccount, term: {component:"Global", text:"AreaPersonalNotificaciones"}},
        {route: '/cartero-virtual', icon:mdiLaptop,  term: {component:"Global", text:"CarpetaVirtual"}},
        {route: '/tributos', icon: mdiFileAccount , term: {component:"Global", text:"TRIBUTOS"}},
        {route: '/tramites', icon: mdiWalletTravel , term: {component:"Global", text:"TRAMITES"}},
        {route: '/bienes-sujeto', icon: mdiHomeAccount, term: {component:"Global", text:"BienesSujeto"}},  
        {route: '/cita-previa', icon: mdiCalendarClock ,term: {component:"Global", text:"Cita Previa"}},
        {route: '/oficinas', icon: mdiOfficeBuilding, term: {component:"Global", text:"Oficinas y servicios"}},
        {route: '/faqs', icon:mdiFrequentlyAskedQuestions, term: {component:"Global", text: "preguntasFrecuentes"}},
        {route: '/mapa-web', icon: mdiSitemap, term: {component:"Global", text: "MapaWeb"}},
    ]

    const sideList = useMemo(() => (
        <div className={classes.list}>
            <List component="div" disablePadding= {true}>
                <div className={[classes.hideInDesk, classes.spacingLogo].join(' ')} >
                    <a href="https://www.atib.es" style={{textDecoration: 'none'}}>
                        <img src={logoAtibMenuLateral} alt={"logo sede"} className={classes.logoMenuLateral} />
                        <div className={classes.textLogo}>
                            <Term component="Global" text="Sede Electrónica" />
                        </div>
                    </a>
                </div>
                {ROUTES.map( r => (
                    <ListItem button key={r.route}
                        component={AsLink(r.route)}
                        className={
                            (activeItem.length>1 && activeItem.includes(r.route) && r.route!== '/' )   
                            ? classes.listItemActive
                            : undefined 
                        }
                    >
                        <ListItemIcon >
                            <Icon path={r.icon}
                                title={translate(r.term.component,r.term.text, terms )}
                                size={1}
                                color={ 
                                    (activeItem.length>1 && activeItem.includes(r.route) && r.route!== '/' ) 
                                    ? "white" 
                                    : "dimgrey"
                                }
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <span className={ [classes.bold, classes.uppercase].join(' ') }>
                                    <Term component={r.term.component} text={r.term.text} />
                                </span>
                            } 
                        />
                    </ListItem>
                ))}
            </List>
        </div>
    ), [ classes, activeItem ]);

    useEffect(() => {
        const unregisterCallback = history.listen((location) => {
            handleClose();
            setActiveItem(location.pathname)
        });

        return () => {
            unregisterCallback();
        }
    }, [history, handleClose]);

    return (
        pageState.menu || ( !pageState.menu && isMobile ) ?
        <nav className={classes.drawer} style= { activeItem !== '/' ? {}: {display: 'none'}}>
            <Hidden mdUp implementation="css">
                <SwipeableDrawer
                    open={pageState.navLeftOpen}
                    onClose={handleClose}
                    onOpen={handleOpen}
                >
                    {sideList}
                </SwipeableDrawer>
            </Hidden>
            
            <Hidden smDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open>
                    {sideList}
                </Drawer>
            </Hidden>
        </nav>
        : null
        
    );
};

export default withRouter(withLiterals(['Global', 'Representaciones', 'CitaPrevia'])(SideNavLeft));