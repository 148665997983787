import React, { FC, useState } from 'react';
import { IconButton, makeStyles, useTheme } from '@material-ui/core';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import ModalImage from "react-modal-image";
import { Lightbox } from "react-modal-image";

const useStyles = makeStyles((theme) => ({
    plusIcon: {
        width: 35,
        bottom: 5,
        position: "absolute",
        cursor: "pointer",
        display: "block",
    },
    imagePlusContainer: {
        position: "relative",
        width: "fit-content",
        marginInline: "auto",
    },
}));

interface IProps {
    className?: string;
    imagenSmall: string;
    imagenLarge: string;
}

const ImagePlus: FC<IProps> = ({ className, imagenSmall, imagenLarge }) => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const [state, setState] = useState(false);

    const closeLightbox = () => {
        setState(false);
    }

    const openLightbox = () => {
        setState(true);
    }

    return (
        <div className={classes.imagePlusContainer}>
            <ModalImage
                small={imagenSmall}
                large={imagenLarge}
                className={className}
                alt="Imagen Oficina"
                hideDownload={true}
                hideZoom={true}
            />
            {state &&
                <>
                    <Lightbox
                        large={imagenLarge}
                        alt="Imagen Oficina"
                        hideDownload={true}
                        hideZoom={true}
                        onClose={closeLightbox}
                    />
                </>}
            <IconButton 
            className={classes.plusIcon} 
            onClick={openLightbox}
            >
                <Icon
                    path={mdiPlus}
                    color="white"
                />
            </IconButton>
        </div>
    );
}

export default ImagePlus;

































