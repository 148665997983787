import React, { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import generic_styles from './styles';

import { Button, Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import { TResumenJustif } from "./types";
import { IParamStepper } from "../shared/types";
import StepSchema from "../shared/stepSchema";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import ResumenPLV from "./resumenComponentes/resumenPLV";
import Modal from "components/modal";
import { translate } from "utils/i18n";
import CheckBox from "components/DUTI/check-box";
import ContentConformidadPLV from "./resumenComponentes/contentConformidadPLV";
import ModelGateway from "gateways/model.new.gateway";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import { TInfoResumen } from "../plusvalias/types";
import FullLoading from "components/full-loading";
import { TipoAsistente } from "containers/tributos-autonomicos-locales/utils";
import EndProcessMSG from "components/Modelos/componentes/endProcessMSG";



interface IProps extends IParamStepper, WithStyles<typeof generic_styles> {
    apartado: string;
    orden:number;
}
const InfoResumen: FC<IProps> = ({
    classes, apartado, orden,tipoAsistente,idRef,isFirst,isLast, handleBack,handleNext,handleExit
}) => {
    const componentMounted = useRef(true);
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelosGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);

    const[loading, setLoading] = useState(false)
    const[showModal, setShowModal] = useState(false)
    const[conforme, setConforme] = useState(false)
    const[showCancelProcess, setShowCancelProcess] = useState(false)
    const[disabledNext, setDisabledNext] = useState(false)
    const[isDone, setIsDone] = useState(false)


    
    const onExit = (cause:'continuar-mas-tarde'|'force' ) => {
        // validate if pending changes - MSG - save info ¿?
        handleExit()
    }

    const onBack = () => {
        handleBack()
    }

    const onNext = async () => {
        try {
            console.log('onNext ', isDone)
            setLoading(true)
            // validate required data
            if(isDone){
                handleNext();
            }
            if(conforme){
                const result = await modelosGateway.finalizarPlusvalia(idRef)
                const data: TInfoResumen = {
                    status: result.ok ? "FIN" : 'ERR',
                    localizadores:result.localizadores,
                    pago: result.pago
                }
                const resultS = await modelosGateway.saveStepAsistente(idRef,apartado,orden, data)
                if(resultS.ok && result.ok){
                    console.log('goNext from resumJustif')
                    handleNext();
                } else {
                    setShowCancelProcess(true)
                    setDisabledNext(true)

                }
                
            } else {
                setShowModal(true)
            }
        } catch (err) {
            const error = err as Error
            const errMSG = translate('Plusvalias', error.message, terms);
            const cause= error.cause ?? "";
            alertsDispatch({
                type: "show-alert",
                payload: {
                    message: errMSG.concat(`. ${cause}`),
                    variant: "error",
                },
            });
            
        } finally {
            setLoading(false)
        }
        
    }

    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                const prevInfo = await modelosGateway.getAllApartadosModeloAsistente('PLV',idRef)
                if(componentMounted.current){
                    //setters
                    if(prevInfo && prevInfo.infoResumen && prevInfo.infoResumen.status){
                        if(prevInfo.infoResumen.status === "FIN"){
                            setDisabledNext(false)
                        } else{
                            setDisabledNext(true)
                            setShowCancelProcess(true)
                        }
                        setIsDone(true)
                    } else {
                        setIsDone(false)
                    }
                }
            } catch (err){
                const error = err as Error
                const errMSG = translate('Plusvalias', error.message, terms);
                const cause= error.cause ?? "";
                alertsDispatch({
                    type: "show-alert",
                    payload: {
                    message: errMSG.concat(`. ${cause}`),
                    variant: "error",
                    },
                });
            } finally {
                setLoading(false)
            }
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    },[])

    return(
        <StepSchema idRef={idRef} isFirst={isFirst} isLast={isLast} onNext={onNext} onBack={onBack} onExit={onExit} disabledBack={isDone} disabledNext={disabledNext} forceExit={showCancelProcess} >
            <>
            <FullLoading loading={loading} />

            {/**Ampliar según incrementen los tipos de Asistentes */}
            {tipoAsistente === 'plusvalia' && 
                <ResumenPLV idReferencia={idRef} />
            }
            {showCancelProcess && 
                <EndProcessMSG componentIG="Tributos" termIG="error_generar_modelo"/>
            }

            {/**Todos tienen modal conformidad */}
            <Modal 
                fullWidth
                maxWidth='lg'
                open={showModal}
                title={translate('Global',"Usted va a enviar la siguiente información",terms)}
                renderContent={()=> (
                    <div>
                        <div style={{ display: 'flex', flexGrow:1, overflowX: 'hidden', overflowY:'auto', backgroundColor: '#e0eaf4', minHeight: 150, maxHeight: 600, paddingLeft: 10, paddingRight:10}}>
                            {/**Ampliar según incrementen los tipos de Asistentes */}
                            {tipoAsistente ==='plusvalia' && <ContentConformidadPLV idReferencia={idRef}/>}
                        </div>
                        
                        <Typography style={{marginTop: 20, marginBottom: 15}} >
                            {translate("DUTI","UstedEnviaLaSiguienteInformacionReglamento", terms)}
                        </Typography>
                        <Typography style={{marginBottom: 10}} >
                            {translate("DUTI","UstedEnviaLaSiguienteInformacionConformidad",terms)}
                        </Typography>                        
                    </div>
                )}
                renderActions={() => (
                    <Grid container direction='row' alignItems="flex-end" justify="space-between" style={{paddingRight:24, paddingLeft:14, marginBottom: 10}}>
                        <Grid item >
                            <CheckBox 
                                value={conforme} 
                                fieldTerm={'conforme'} 
                                onChangeValue={ (v:boolean) => {
                                    setConforme(v); 
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained"
                                color="inherit" style={{marginRight: 10}}
                                onClick={()=> setShowModal(false)}>
                                {translate('Global','Cerrar', terms)}
                            </Button>
                            <Button 
                                variant="contained"
                                color="primary"
                                disabled={!conforme}
                                onClick={()=> {
                                    setShowModal(false)
                                    onNext()
                                }}
                            >
                                {translate('Global','Finalizar', terms)}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            />
            </>
        </StepSchema>

    )
}

export default withLiterals(["Plusvalias","Global"])(withStyles(generic_styles)(InfoResumen))