import { useMemo } from "react";

export const useDebounce = <T extends any[]>(callback: (...args: T) => void, delay: number): ((...args: T) => void) => {

    const handler = useMemo(() => {
        let timeoutId: number | null = null;
        return function () {
            const args = arguments;
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                callback(...(args as unknown as T));
                timeoutId = null;
            }, delay);
        }

    }, [callback, delay]);

    return handler;
}