import React, { FC, useEffect, useState, useContext, FormEventHandler } from "react";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";
import { TextField, Theme, CircularProgress, Button, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import usePage from "hooks/page.hook";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import { SujetosGateway } from "gateways/sujetos.gateway";
import IoC from "contexts/ioc.context";
import Term from "components/term";
import Background from 'resources/background-login.jpg';
import PageFooter from '../../components/page-footer';
import imageLogoAtib from "./../../resources/Logoatib.png";

const styles = (theme: Theme): StyleRules => ({
    root: {
        flexGrow: 1,
    },
    textWarning: {
        color: 'black',
    },
    colorWhite: {
        color: 'white',
    },
    spaceText: {
        marginLeft: 10,
    },
    nifLabel: {
        textAlign: 'left',
        cursor: 'pointer',
        fontSize: 20
    },
    alignCenter: {
        textAlign: 'center',
    },
    noSoyYo: {
        textAlign: 'center',
        cursor: 'pointer',
        fontSize: 20,
        marginBottom: '1rem'
    },
    loginBox: {
        width: '100%',
        height: '100%',
        margin: '0 auto',
        background: 'rgba(255, 255, 255, 1)',
        padding: 40,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.up('md')]:
        {
            borderRadius: 20,
            width: 480,
            height: 'auto',
        }
    },
    inputColor: {
        color: 'black',
        borderBottom: '1px solid black',
        '&:after': {
            borderBottom: '1px solid #3f51b5',
        },
    },
    media: {
        height: 395,
        width: 385
    },
    rowClave: {
        display: 'flex',
        marginBottom: 15,
    },
    logoClave: {
        background: 'rgba(0, 43, 90, 0.6)',
        flex: 0.85,
        borderRadius: '20px 0px 0px 20px',
    },
    botonInfoClave: {
        background: 'rgba(0, 43, 90, 0.6)',
        borderRadius: '0px 20px 20px 0px',
        marginLeft: 3,
        flex: 0.15,
        paddingTop: 5,
        textAlign: 'center',
        paddingRight: 10,
    },
    flex: {
        flex: 1,
    },
    buttonForm: {
        textAlign: 'center',
        marginTop: 30,
    },
    button: {
        marginTop: 5,
        backgroundColor: 'rgba(140,145,155,1)',
        marginBottom: 5,
        border: '1px solid rgba(140,145,155,1)',
        '&:after': {
            border: '1px solid rgba(140,145,155,1)',
        },
        '&:hover': {
            backgroundColor: 'rgba(140,145,155,0.7)',
        },
        borderRadius: 20,
    },
    buttonLink: {
        fontWeight: 'lighter',
        letterSpacing: 1,
        color: '#616161',
    },
    listLinks: {
        flex: 1,
        marginTop: 30,
        marginBottom: 50,
    },
    imageFullWidth: {
        backgroundSize: 'cover',
        backgroundColor: 'white!important',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '-34px',
        [theme.breakpoints.up('md')]:
        {
            backgroundColor: 'auto',
            backgroundImage: `url(${Background})`,
            width: 'auto',
            height: 'auto',
        }
    },
    form: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('md')]:
        {
            width: 'auto',
            height: 'auto',
            marginLeft: 20,
            marginRight: 20,
        }
    },
    imagenMargin: {
        marginBottom: 60,
        display: 'none',
        [theme.breakpoints.up('md')]:
        {
            display: 'block'
        }
    },
    hidden: {
        display: 'none',
    },
});

interface IProps extends WithStyles<typeof styles> {

}
const RecuperacionContrasenya: FC<IProps> = ({ classes }) => {
    const [, pageDispatcher] = usePage();
    const terms = useContext(LiteralsContext);
    const [loading, setLoading] = useState(false);
    const [correoEnviado, setCorreoEnviado] = useState(false);

    const [nif, setNif] = useState("");
    const [mail, setMail] = useState("");

    const [validationNif, setValidationNif] = useState<string | null>(null)
    const [validationMail, setValidationMail] = useState<string | null>(null)

    const [error, setError] = useState(false);

    const ioc = useContext(IoC);
    const sujetoG = ioc.get(SujetosGateway) as SujetosGateway;

    const validarDatos = () => {
        let errorNif = "";
        let errorMail = "";

        if (nif === "" || nif === null) {
            errorNif = translate('Acceso', 'El campo nif no puede estar vacio', terms);
            setValidationNif(errorNif);
        }
        if (mail === "" || mail === null) {
            errorMail = translate('Acceso', 'El campo correo electrónico no puede estar vacio', terms);
            setValidationMail(errorMail);
        }
        return !errorNif && !errorMail;
    }

    const handleSubmitForm: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        enviarMail();
    }

    const enviarMail = async () => {
        if (validarDatos()) {
            setError(false);
            try {
                setLoading(true);
                await sujetoG.sendMailSolicitudRecuperarContrasenya(nif, mail);
                pageDispatcher({
                    type: 'show-notification', payload: {
                        message: translate('Acceso', 'Se le ha enviado el correo correctamente', terms),
                        variant: 'success',
                    }
                });
                setCorreoEnviado(true);
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        }
    }

    const handleNif = (value: string) => {
        setNif(value);
    }
    const handleMail = (value: string) => {
        setMail(value);
    }

    const info = translate('Acceso', 'textoAclarioRecuperarContrasenya', terms);

    const formLabelsTheme = createMuiTheme({
        overrides: {
            MuiInputBase: {
                input: {
                    fontSize: 24,
                    textAlign: 'center',
                }
            }
        }
    })

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: null,
            menu: false,
        });
    }, [pageDispatcher]);
    return (
        <>
            <div className={classes.imageFullWidth}>

                <MuiThemeProvider theme={formLabelsTheme}>

                    <form className={classes.form} onSubmit={handleSubmitForm}>
                        <div className={[classes.loginBox].join(' ')}>
                            <div>
                                <img alt="Logo sede" className={classes.imagenMargin} src={imageLogoAtib} />
                            </div>
                            <div className={classes.root}>
                                {
                                    !correoEnviado ?
                                        <>
                                            <div className={classes.alignCenter} dangerouslySetInnerHTML={{ __html: info }} />
                                            <TextField
                                                label={<span><Term component="Global" text="DNI/NIF/CIF" /></span>}
                                                value={nif}
                                                fullWidth
                                                type="text"
                                                margin="normal"
                                                error={validationNif !== null}
                                                helperText={validationNif}
                                                InputProps={{
                                                    className: classes.inputColor
                                                }}
                                                onChange={(e) => handleNif(e.target.value)}
                                                required
                                            />
                                            <TextField
                                                label={<span><Term component="Global" text="Correo electronico" /></span>}
                                                value={mail}
                                                fullWidth
                                                margin="normal"
                                                error={validationMail !== null}
                                                helperText={validationMail}
                                                onChange={(e) => handleMail(e.target.value)}
                                                required
                                                InputProps={{
                                                    className: classes.inputColor
                                                }}
                                            />
                                            <div className={classes.buttonForm}>
                                                <Button variant="outlined" color="primary" className={classes.button} type="submit" size="large" disabled={loading}>
                                                    <span className={classes.colorWhite}>
                                                        <Term component="Global" text="Recuperar contraseña" />
                                                        {
                                                            loading ?
                                                                <CircularProgress size={20} />
                                                                :
                                                                null
                                                        }
                                                    </span>
                                                </Button>
                                                {
                                                    error &&
                                                    <p style={{ color: 'red' }}><Term component="global" text="el email o el nif no son correctos" /></p>
                                                }
                                            </div>
                                        </>
                                        :
                                        <h2> <Term component="Acceso" text="Le hemos enviado un nuevo correo a su dirección de correo electrónico" /></h2>
                                }

                            </div>
                        </div>
                    </form>
                    <PageFooter />
                </MuiThemeProvider>
            </div>
        </>
    );
}

export default withStyles(styles)(RecuperacionContrasenya);
