import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { createValidationError } from "utils/response";
import { IProteccionDatos, IProteccionDatosRequest } from "./proteccion-datos.interface";


@inject('axios')
export class ProteccionDatosGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async getSelfProteccionDatos(): Promise<IProteccionDatos | null> {
        try {
            const response = await this.axios.get(`proteccion-datos/self`);

            return response.data;
        } catch (e) {
            throw createValidationError(e as any);
        }
    }

    public async postProteccionDatos(proteccionDatos: IProteccionDatosRequest): Promise<any> {
        try {
            const response = await this.axios.post(`proteccion-datos/createProteccionDatos`, proteccionDatos);
            return response.data;
        }
        catch (e) {
            throw createValidationError(e as any);
        }
    }
}