import { Reducer } from "react";

export interface IPopUpsState {
    popUp: IPopUpWithOpen | null;
}

export interface IPopUp {
    texts: string[];
}

export interface IPopUpWithOpen extends IPopUp {
    open: boolean;
}

export type PopUpsAction =
    | { type: 'hide-popUp' }
    | { type: 'show-popUp', payload: IPopUp }

const PopUpsReducer: Reducer<IPopUpsState, PopUpsAction> = (state, action) => {
    switch (action.type) {
        case 'hide-popUp': return { ...state, popUp: { ...state.popUp as any, open: false } };
        case 'show-popUp': return { ...state, popUp: { ...action.payload, open: true } };
        default: throw new Error('Unexpected action');
    }
};

export default PopUpsReducer;