import React, { FC, useContext, useEffect, useMemo, useRef, useState } from "react";

import { Button, Typography, WithStyles, withStyles } from "@material-ui/core";
import  WarningIcon  from "@material-ui/icons/Warning";

import IoC from "contexts/ioc.context";
import usePage from "hooks/page.hook";
import { translate } from "utils/i18n";
import { AlertsContext } from "contexts/alerts.context";
import ModelGateway from "gateways/model.new.gateway";
import { ISujeto } from "gateways/perfil.interfaces";
import { IParamStepper, TSujeto } from "../shared/types";

import plv_styles from './styles';
import { TComplementariaComprador, TComprador, TPreguntasPlusvalia } from "./types";
import StepSchema from "../shared/stepSchema";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import SujetoForm, { AdditionalInfoRenderProps } from "components/Modelos/componentes/sujetoForm";
import ExpandPanel from "components/DUTI/expand-panel";
import {  cloneDeep } from "lodash";
import ExtraFieldsComprador from "components/Modelos/componentes/extraFieldsComprador";
import Modal from "components/modal";
import { IPreguntasPlusvaliaProps } from "./preguntasPlusvalia";
import FullLoading from "components/full-loading";




interface ICompradoresProps extends IParamStepper, WithStyles<typeof plv_styles> {
    apartado: string;
    orden:number;
}
const Compradores: FC<ICompradoresProps> = ({
    classes, apartado, orden,idRef,isFirst,isLast, handleBack,handleExit,handleNext
}) => {
    const componentMounted = useRef(true);

    const terms = useContext(LiteralsContext);
    const [pageState, pageDispatch] = usePage()
    const [, alertsDispatch] = useContext(AlertsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelosGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);

    const [compradores, setCompradores]= useState<TComprador[]>([])
    const [nifExclusivoVendedor, setNifExclusivoVendedor] = useState<string| undefined>(undefined)
    const [vendAsNotAllowedNIFs, setVendAsNotAllowedNIFs] = useState([] as string[])
    const [loading, setLoading] = useState(false)
    const [onEditComprIdx, setOnEditComprIdx] = useState(-1);
    const [ extraInfo, setExtraInfo] = useState<TComplementariaComprador | undefined>()

    const [showModal, setShowModal] = useState(false);
    const [haveChanges, setHaveChanges] = useState(false);

   
    // MEMOS ------------------------------------------------------------------------------------------------------------
    const compradoresNIFs = useMemo(() => {
        const nifs = compradores.length>0 ?compradores.map(c => c.nif): []
        return nifs
    },[compradores])

    const disabledAdd = useMemo(() => {
        const suma = compradores.reduce((acumulador, compr) => acumulador + compr.porcentaje, 0);
        return ( suma < 100 ) ? false : true
    },[compradores])

    const disabledNext = useMemo(() => {
        // Si no hay compradores o no suman 100%
        return (compradores.length=== 0 || !disabledAdd ) ? true : false
    },[compradores, disabledAdd])
    // ------------------------------------------------------------------------------------------------------------------
    
    // ------------------------------------------------------------------------------------------------------------------
    // GESTION STEPPER --------------------------------------------------------------------------------------------------
    const onExit = async  (cause:'continuar-mas-tarde'|'force')  => {
        try {
            // validate if pending changes - MSG - save info ¿?
            if(haveChanges && cause === 'continuar-mas-tarde'){
                await modelosGateway.saveStepAsistente(idRef, apartado, orden, {listCompradores: compradores})              
            }
            handleExit()
        } catch(err) {

            alertsDispatch({
                type: "show-alert",
                payload: {
                    message: translate('Plusvalias','error_save_continuar_mas_tarde', terms),
                    variant: "warning",
                    hasCustomAction: true,
                    defaultCloseActionTemplateName: translate('Global','no',terms),
                    actionTemplateName: translate('Global','si',terms),
                    handleCustomAction: () => {
                        alertsDispatch({ type: 'hide-alert' })
                        handleExit()
                    }
                },
            });
        }
    }

    const onBack = () => {
        handleBack()
    }

    const onNext = async () => {
        try {
            setLoading(true)              
            const result = haveChanges ? await modelosGateway.saveStepAsistente(idRef, apartado, orden, {listCompradores: compradores}) : {ok:true, idRef: idRef}
            if(result.ok){
                setLoading(false)
                setHaveChanges(false)
                // si todo OK
                //console.log('goNext ---- > from Compradores')
                handleNext()
            }  
        } catch(err) {
            setLoading(false)
            const error = err as Error
            const errMSG = translate('Plusvalias', error.message, terms);
            const cause= error.cause ?? "";
            alertsDispatch({
                type: "show-alert",
                payload: {
                message: errMSG.concat(`.${cause}`),
                variant: "error",
                },
            });
        }

    }
    // ------------------------------------------------------------------------------------------------------------------
    // FUNCIONES --------------------------------------------------------------------------------------------------------
    const validarExtraFields = (info: TComplementariaComprador|undefined) => {
        if(info !== undefined){
            const err = (info.porcentaje ===0 ) 
            const msg =  (info.porcentaje ===0 ) 
                ? translate('Plusvalias','err_porcentaje', terms) 
                : ''
            return ({err:err , msg:msg})
        } else {
            return ({err: true, msg: translate('Global','err_check_required_fields',terms) })
        }
        
    }
    const addEditSujeto = (action: 'new' | 'edit', item: TSujeto, extraInfo: TComplementariaComprador) => {
        let out: {ok: boolean, msgErr: undefined|string}={ok: true, msgErr: undefined}
        const c = cloneDeep(compradores)
        if(action === 'new'){
            c.push({...item, ...extraInfo})
        } else {
            c[onEditComprIdx]={...item, ...extraInfo}
        }

        //console.log(c)
        const sumaPorcentaje = c.reduce((acumulador, compr) => (acumulador + compr.porcentaje), 0)
        //console.log(sumaPorcentaje)
        if( sumaPorcentaje > 100){
            out={ ok:false, msgErr: translate('Plusvalias','err_suma_porcentaje', terms) }
        }

        if(out.ok){
            setCompradores(c)
            setHaveChanges(true)
        }

        return out
    }

    
    // ------------------------------------------------------------------------------------------------------------------


    useEffect(() => {

        (async()=> {
            try{
                // Calls - await 
                setLoading(true)
                const prevInfo = await modelosGateway.getAllApartadosModeloAsistente('PLV',idRef)
                if(componentMounted.current){
                    //setters
                    if(prevInfo?.compradores){ 
                        setCompradores(prevInfo?.compradores) 
                    }
                    let exclusiveVend: string | undefined= undefined
                    if(prevInfo.preguntasPlusvalia && prevInfo.preguntasPlusvalia.actuaNombrePropio !== undefined && pageState.jwp!== null){
                        if(prevInfo.preguntasPlusvalia.actuaNombrePropio === true ){
                            if(pageState.jwp.representante){ 
                                // si esta representando
                                exclusiveVend=pageState.jwp.nifRepresentante
                            } else {  // si esta representando
                                exclusiveVend=pageState.jwp.nif
                            }
                        } else {
                            exclusiveVend=pageState.jwp.nif
                        }
                        setNifExclusivoVendedor(exclusiveVend)
                    }
                    if(prevInfo.vendedores){
                        const nifs = prevInfo.vendedores.length>0 ? prevInfo.vendedores.map(c => c.nif): []
                        if(exclusiveVend !== undefined){
                            nifs.push(exclusiveVend);
                        }
                        setVendAsNotAllowedNIFs(nifs)
                    }
                    
                }

            } catch (err){
                const msg= (err as Error).message
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Plusvalias', msg, terms),
                        variant: 'error',
                    }
                });
            } finally {
                setLoading(false)
            }

        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    },[])

    return(
        <StepSchema idRef={idRef} isFirst={isFirst} isLast={isLast} onNext={onNext} onBack={onBack} onExit={onExit} disabledNext={disabledNext}>
            <FullLoading loading={loading}/>
            {compradores.length>0 && 
                <>
                    {compradores.map(
                        (comp, idx) => {
                            return( 
                                <ExpandPanel
                                    key={comp.nif} 
                                    withDelete={true}
                                    onDelete={() => {
                                        const c = compradores.filter(cmp => cmp.nif !== comp.nif)
                                        setCompradores(c)
                                        onEditComprIdx === idx && setOnEditComprIdx(-1)
                                        setHaveChanges(true)
                                    }}
                                    renderHeader={() => (
                                        <div className={classes.row} style={{flexGrow: 1, alignItems:'center', justifyItems:'space-between'}}>
                                            <div className={classes.row} style={{flexGrow: 1}} >
                                                {/* <Typography style={{marginRight: 10, marginLeft: 5}}>{translate('Plusvalias',`comprador`,terms)+' '+ (idx+1)+ ': '}</Typography> */}
                                                <Typography style={{marginLeft: 10}}>{idx+1}</Typography>
                                                <Typography style={{marginRight: 5, marginLeft: 5}}>{'-'}</Typography>
                                                <Typography>{comp.nomApe}</Typography>
                                                <Typography style={{marginRight: 5, marginLeft: 5}}>{'-'}</Typography>
                                                <Typography>{comp.nif}</Typography>
                                            </div>
                                            { onEditComprIdx === idx  &&
                                                <div style={{marginRight:20, marginLeft:20}}>
                                                    <WarningIcon/>
                                                </div>
                                            }
                                        </div>
                                    )}
                                    renderContent={() =>{ 
                                        const suj: TSujeto={
                                            contacto: {...comp.contacto},
                                            direccion: {...comp.direccion},
                                            nif: comp.nif,
                                            nomApe: comp.nomApe,
                                            personaFisica: comp.personaFisica

                                        }
                                        const addInfo: TComplementariaComprador={
                                            porcentaje: comp.porcentaje,
                                        }
                                        return(
                                        <SujetoForm
                                            renderType={onEditComprIdx === idx ? 'form': 'detail'}
                                            action='edit'
                                            allowChargeLogged={false} 
                                            allowChargeRepresentanteLoggued = {false} 
                                            currentInListNIFs={compradoresNIFs}
                                            repeatedMsgErr={translate('Plusvalias','sujeto_repetido',terms)}
                                            notAllowedNIFs={vendAsNotAllowedNIFs} 
                                            notAllowedNIFsMsgErr={translate('Plusvalias','sujeto_mustbe_or_included_vendedor',terms)} 
                                            initialInfo={suj}
                                            onSave={addEditSujeto}
                                            onEdit={() => setOnEditComprIdx(idx)}
                                            onEnd={()=> {
                                                setOnEditComprIdx(-1)
                                            }} // id modal close modal
                                            additionalInfo={addInfo}
                                            AdditionalInfoRender={ExtraFieldsComprador}
                                            validateAditionalInfo={(info:TComplementariaComprador) => validarExtraFields(info)}
                                        />
                                    )}} 
                                />
                            )
                        }
                    )}

                    <Button 
                        color="primary"
                        variant="contained"
                        onClick={()=> {
                            setShowModal(true)
                        }}
                        disabled={disabledAdd}
                    >
                        {translate('Global', 'btnAdd', terms)}
                    </Button>
                    <Modal fullWidth maxWidth='md'
                        title={translate('Plusvalias', 'comprador', terms)}
                        open={showModal}
                        renderContent={ 
                            () => (
                                <SujetoForm
                                    renderType='modal'
                                    action='new'
                                    allowChargeLogged={false}
                                    allowChargeRepresentanteLoggued = {false} 
                                    currentInListNIFs={compradoresNIFs}
                                    repeatedMsgErr={translate('Plusvalias','sujeto_repetido',terms)}
                                    notAllowedNIFs={vendAsNotAllowedNIFs} 
                                    notAllowedNIFsMsgErr={translate('Plusvalias','sujeto_mustbe_or_included_vendedor',terms)} 
                                    initialInfo={undefined}
                                    onSave={addEditSujeto}
                                    onEnd={() => setShowModal(false)} 
                                    additionalInfo={undefined}
                                    AdditionalInfoRender={ExtraFieldsComprador}
                                    validateAditionalInfo={(info:TComplementariaComprador) =>validarExtraFields(info)}
                                />
                            )
                        }   
                    />
                </>
                
            }
            
            {compradores.length===0  && 
                <SujetoForm
                    renderType='form'
                    action='new'
                    allowChargeLogged={false} 
                    allowChargeRepresentanteLoggued = {false} 
                    currentInListNIFs={compradoresNIFs}
                    repeatedMsgErr={translate('Plusvalias','sujeto_repetido',terms)}
                    notAllowedNIFs={vendAsNotAllowedNIFs} 
                    notAllowedNIFsMsgErr={translate('Plusvalias','sujeto_mustbe_or_included_vendedor',terms)} 
                    initialInfo={undefined}
                    onSave={addEditSujeto}
                    onEnd={ ()=> {} } 
                    additionalInfo={undefined}
                    AdditionalInfoRender={ExtraFieldsComprador}
                    validateAditionalInfo={(info: TComplementariaComprador) =>validarExtraFields(info)}
                />
            }

            
        </StepSchema>

    )
}

export default withLiterals(["Plusvalias","Global"])(withStyles(plv_styles)(Compradores))