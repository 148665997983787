import React, { FC, useContext, useMemo } from 'react';
import { Grid, Typography, ButtonBase, Button, makeStyles, StyleRules, Theme, Card } from "@material-ui/core";
import Term from '../../components/term';
import TextNumber from '../../components/text-number';
import moment from 'moment';
import { Icon } from '@mdi/react';
import { mdiCheck } from '@mdi/js';
import { esConceptoVivienda } from 'utils/concepto.vivienda';
import { Alert } from '@material-ui/lab';
import { RouteComponentProps } from 'react-router-dom';
import IoC from 'contexts/ioc.context';
import { PaymentGateway } from 'gateways/payment.gateway';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext } from 'containers/shared/literals';
import { IDeclaracion, TEstadoDeclaracion } from 'gateways/duti.interface';
import anuladaImg from 'resources/dusti/anulada.png'
import canceladaImg from 'resources/dusti/cancelada.png'
import pendientePagoImg from 'resources/dusti/pendientePago.png'

import finalizadaImg from 'resources/dusti/finalizada.png'
import iniciadaImg from 'resources/dusti/iniciada.png'
import pendienteImg from 'resources/dusti/pendiente.png'
import caducadaImg from 'resources/dusti/caducada.png'
import { TDTInfoCatastral } from 'containers/DUTI/steps/types';
import { translate } from 'utils/i18n';
import dayjs from 'dayjs';
import { idDeclaracionAsRefDusti } from 'containers/DUTI/utils';


const styles = (theme: Theme): StyleRules => ({
    root: {
        display:'flex',
        flexDirection: 'row'
    },
    rootCard: {
        marginTop:10,
        marginBottom:10,
        padding: 10,
    },
    rootContainer: {
        display:'flex',
        flexDirection: 'column',
        //flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
    itemCenterDetail: {
        display:'flex',
        paddingLeft: 20,
        paddingTop: 10,
        paddingBottom: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentRoot:{
        display:'flex',
        flexDirection:'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buton: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 10,
    },
    imagenContainer: {
        width: 80,
        height: 80,
        borderRadius: '50%',
        backgroundColor: '#8A829B',
        alignSelf: 'center'
    },
    [theme.breakpoints.up('sm')]: {
        contentRoot:{
            display:'flex',
            flexDirection:'row'
        },
    },
    [theme.breakpoints.up('md')]: {
        imagenContainer: {
            borderRadius: '50%',
            backgroundColor: '#8A829B',
            alignSelf: 'center'
        },
        buton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },
    imagen: {
        width: '100%'
    },
    grow: {
        flexGrow: 1,
    },
    imageGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 10,
    },
    detailText: {
        color: '#000',
        fontSize: 12,
        marginRight: 5
    },
    detailTextBold: {
        color: '#000',
        fontSize: 12,
        marginRight: 5,
        fontWeight: 'bold'
    },
    detailTextWarning: {
        color: '#ff0000',
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5
    },
    itemRow: {
        display: 'flex',
        flexDirection: 'row'
    }
});

export type TDustiConsultaAction = 'continuar' | 'reiniciar' | 'consultar';
interface IProps {
    dusti: IDeclaracion;
    handleAction: (item: IDeclaracion, action:TDustiConsultaAction) => void;
}
const getImageStatus = ( codigo: TEstadoDeclaracion) =>{
    switch(codigo){
        case 'CAN':
            return anuladaImg;
        case 'CAD':
            return caducadaImg;
        case 'FIN':
            return finalizadaImg;
        case 'INI':
            return iniciadaImg;
        case 'PEN':
            return pendienteImg;
        case 'PPA':
            return pendientePagoImg;
    }
}
const useStyles = makeStyles(styles);

// Función para extraer una tag especifico del xml previo, el cual tiene un formato peculiar (000_A.1)
export const extractFieldFromXml = (xml: string, field: string) => {
    const regex = new RegExp(`\<${field}>(.+?)\<\/${field}\>`, "gi");
    const found = xml.replace(/(\r\n|\n|\r)/gm, "").match(regex);
    const outValue = found && found[0] ? found[0].replace(`<${field}>`,'').replace(`</${field}>`,'') : null
    return outValue;
};

/** No permite selección múltiple */

const DustiItemList: FC<IProps> = ({ dusti, handleAction }) => {
    // services
    const ioc = useContext(IoC);
    const paymentGateway = ioc.get(PaymentGateway) as PaymentGateway;
    // Global states
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const classes= useStyles(styles);
 
    const dustiImgStatus = useMemo(() => getImageStatus(dusti.estado.codigo) , [dusti])
    const selectable = useMemo(()=>{
        return true
    }, [dusti])
    const tags : (keyof TDTInfoCatastral)[] = ['refCatastral','direccion']

    const infoXml: Record< keyof TDTInfoCatastral, string|null > = useMemo(() => {
        const xml = extractFieldFromXml(dusti.DatosXML,'infoCatastral')
        const info= tags.reduce((obj, tag) => {
            const extractedValue = xml ? extractFieldFromXml(xml,tag) : null;
            obj = {...obj,
                [tag]: extractedValue
            }
            return obj;
        }, {} as Record< keyof TDTInfoCatastral, string|null >);
    
        return info
    },[dusti])

    




    return (
        <Card className={classes.rootCard}>
            <Grid container className={[classes.contentRoot, classes.grow].join(' ')} spacing={2}>
                
                {/* <Grid item className={[classes.contentRoot, classes.grow].join(' ')}></Grid> */}
                    {/** IMG o Icon Botón seleccionable - izq */}
                    <Grid item xs={12} sm={2} md={2} xl={1} className={classes.imageGrid}>
                        {
                            // selectable
                            //     ? (
                            //         <ButtonBase
                            //             onClick={handleSelect}
                            //             className={[classes.imagenContainer, selected ? classes.checked : ''].join(' ')}>
                            //             {selected 
                            //             ?   <Icon path={mdiCheck} title="Seleccionado" size={1} /> 
                            //             : 
                            //                 <div className={classes.imagenContainer}>
                            //                     <img src={dustiImgStatus} className={classes.imagen} alt="deuda" />
                            //                 </div>
                            //             }
                            //         </ButtonBase>
                            //     ) : (
                                    <div className={classes.imagenContainer}>
                                        <img src={dustiImgStatus} className={classes.imagen} alt="deuda" />
                                    </div>
                            //)
                        }
                    </Grid>

                    {/** Detalle - centro */}
                    <Grid item container xs={12} sm={10}  md={7} xl={9} direction='column' justify='flex-start' className={[classes.itemCenterDetail, classes.grow].join(' ')} spacing={2}>
                        <Grid item container direction='row' justify='space-between'>
                            <Grid item>
                                <Grid item container direction='column' >
                                
                                    <Typography className={classes.detailTextBold}>
                                        {translate('DUTI', 'item_list_declaracion', terms).concat(` ${dusti.estado.nombre}`)}
                                    </Typography>
                                    
                                    <div className={classes.itemRow}>
                                        <Typography className={classes.detailText}>
                                            {translate('DUTI', `item_list_text_${dusti.estado.nombre.toLowerCase()}`, terms)}
                                        </Typography>
                                        {  dusti.estado.codigo === 'INI' &&
                                            <>
                                                <Typography className={classes.detailTextBold}>
                                                    {dayjs(dusti.FechaAlta).format('DD/MM/YYYY')}
                                                </Typography>
                                                <Typography className={classes.detailText}>
                                                    {translate('DUTI','a_las',terms)}
                                                </Typography>
                                                <Typography className={classes.detailTextBold}>
                                                    {moment.utc(dusti.FechaAlta).format('HH:mm')}h
                                                </Typography>
                                            </>
                                        }
                                        {  dusti.estado.codigo === 'CAD' &&
                                            <Typography className={classes.detailTextBold}>
                                                {dayjs(dusti.FechaCaducidad).format('DD/MM/YYYY')}
                                            </Typography>
                                        }
                                        {  (dusti.estado.codigo === 'FIN' || dusti.estado.codigo === 'CAN' ) && dusti.FechaFinalizacion && 
                                            <>
                                                <Typography className={classes.detailTextBold}>
                                                    {dayjs(dusti.FechaFinalizacion).format('DD/MM/YYYY')}
                                                </Typography>
                                                <Typography className={classes.detailText}>
                                                    {translate('DUTI','a_las',terms)}
                                                </Typography>
                                                <Typography className={classes.detailTextBold}>
                                                    {moment.utc(dusti.FechaFinalizacion).format('HH:mm')}h
                                                </Typography>
                                            </>
                                        }
                                    </div>
                                    {dusti.estado.codigo === 'CAN' && dusti.Observaciones && 
                                        <div className={classes.itemRow}>
                                            <Typography className={classes.detailText}>
                                                {translate('DUTI', 'item_list_text_motivo', terms)}
                                            </Typography>
                                            <Typography className={classes.detailTextBold}>
                                                {translate('DUTI', `motivoANU_${dusti.Observaciones.replaceAll('-', '_')}`, terms)}
                                            </Typography>
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item >
                                <Grid item container direction='column'>
                                    <div className={classes.itemRow}>
                                        <Typography className={classes.detailText}>
                                            {translate('DUTI', 'idDeclaracion', terms)}:
                                        </Typography>
                                        <Typography className={classes.detailTextBold}>
                                        {idDeclaracionAsRefDusti(dusti.id)}
                                        </Typography>
                                    </div>
                                    <div className={classes.itemRow}>
                                        <Typography className={classes.detailText}>
                                            {translate('DUTI','fechaAlta',terms)}:
                                        </Typography>
                                        <Typography className={classes.detailTextBold}>
                                            {dayjs(dusti.FechaAlta).format('DD/MM/YYYY')}
                                        </Typography>
                                        <Typography className={classes.detailTextBold}>
                                            {moment.utc(dusti.FechaAlta).format('HH:mm')}h
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        

                        <Grid item container direction='column'>
                            {/* <div className={classes.itemRow}>
                                <Typography className={classes.detailText}>
                                    {translate('DUTI', 'idDeclaracion', terms)}:
                                </Typography>
                                <Typography className={classes.detailTextBold}>
                                   {idDeclaracionAsRefDusti(dusti.id)}
                                </Typography>
                            </div> */}
                            <div className={classes.itemRow}>
                                <Typography className={classes.detailText}>
                                    {infoXml.refCatastral}
                                </Typography>
                            </div>
                            <div className={classes.itemRow}>
                                <Typography className={classes.detailTextBold}>
                                    {infoXml.direccion}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    {/** Botones accion - drch */}
                    <Grid item xs={12} sm={12} md={3} xl={2} className={classes.buton}>
                        <div>
                        {  dusti.estado.codigo === 'INI' &&
                            <Button variant="contained" color="primary" size="small" onClick={() => handleAction(dusti, 'continuar')}>
                                {translate("DUTI",'item_list_btn_continuar',terms)}
                            </Button>
                        }
                        {  dusti.estado.codigo === 'PPA' &&
                            <Button variant="contained" color="primary" size="small" onClick={() => handleAction(dusti, 'reiniciar')}>
                            {translate("DUTI",'item_list_btn_continuar_pago',terms)}
                            </Button>
                        }
                        {  dusti.estado.codigo === 'CAD' &&
                            <Button variant="contained" color="primary" size="small" onClick={() => handleAction(dusti, 'consultar')}>
                            {translate("DUTI",'item_list_btn_consultar_modelos',terms)}
                            </Button>
                        }
                        {  dusti.estado.codigo === 'FIN' &&
                            <Button variant="contained" color="primary" size="small" onClick={() => handleAction(dusti, 'consultar')}>
                            {translate("DUTI",'item_list_btn_consultar',terms)}
                            </Button>
                        }
                        {  dusti.estado.codigo === 'PEN' &&
                            <Button variant="contained" color="primary" size="small" onClick={() => handleAction(dusti, 'reiniciar')}>
                            {translate("DUTI",'item_list_btn_continuar_pago',terms)}
                            </Button>
                        }
                        </div>
                    </Grid>

                
            
            </Grid>
        </Card>
    );
}

export default DustiItemList;