import { TDUSTIInfo, TPasoDatosTransmision } from "containers/DUTI/steps/types"
import { TJustificanteIBI } from "./justificante_ibi";
import { TJustificanteAEAT } from "./justificante_aeat";
import { TJustificanteTASAS } from "./justificante_tasas";
import moment from "moment";

export const extraerBorradorJIBI=(datos: TPasoDatosTransmision, terms:any) => {
    const tipoBien = datos.datosPrevios.infoCatastral?.tipoBien
        ? datos.datosPrevios.infoCatastral?.tipoBien === 'UR' 
            ? 'Urbana'
            : datos.datosPrevios.infoCatastral?.tipoBien === 'RU'? 'Rústica': 'BICES'
        : undefined;

    return({
        APART_1: [
           { term: 'acuerdoCambioTitularidad', value: [datos.infoTransmision.notario, `${datos.infoTransmision.numeroProtocolo}/${datos.infoTransmision.anyo}`]},
        ],
        APART_2: [
            { term: 'justif_fechaDevengo', value: moment(datos.infoTransmision.fecha).format('DD/MM/YYYY')},
            { term: 'justif_tipoTransmision', value: datos.infoTransmision.tipoTransmision?.nombre},
            { term: 'justif_derechoTransmision', value: datos.infoTransmision.derechoTransmision?.nombre},
            { term: 'justif_porcentaje', value: datos.infoTransmision.porcentaje+' %' },
            { term: 'justif_notario', value: datos.infoTransmision.notario},
            // { term: 'MXX_nifNotario', value: datos.infoTransmision.nifNotario},
            { term: 'justif_anyo', value: datos.infoTransmision.anyo},
            { term: 'justif_protocolo', value: datos.infoTransmision.numeroProtocolo},

        ],
        APART_3: [
            {term: 'MXX_refCatastral', value: datos.datosPrevios.infoCatastral?.refCatastral, fullWidth: true},
            {term: 'justif_direccion', value: datos.datosPrevios.infoCatastral?.direccion, fullWidth: true},
            {term: 'justif_tipoBien', value: tipoBien, fullWidth: true},
            {term: 'justif_uso', value:datos.datosPrevios.infoCatastral?.datosEconomicos?.uso },
            {term: 'justif_superficie', value: datos.datosPrevios.infoCatastral?.datosEconomicos?.superficieConstruida , fullWidth: true},
        ],
        APART_4: {
            titulares: 
                datos.datosComprador.map( c => {
                    return([
                        {term: 'MXX_nif', value: c.nif },
                        {term: 'MXX_nomApe', value: c.nomApe },
                        {term: 'justif_porcentajeTitular', value: c.porcentaje+' %' }
                    ])
                }),
        },
        APART_5:{
            titulares: 
            datos.datosVendedor.map( v => {
                return([
                    {term: 'MXX_nif', value: v.nif },
                    {term: 'MXX_nomApe', value: v.nomApe },
                    {term: 'justif_porcentajeTitular', value: v.porcentaje+' %' }
                ])
            })
        }
    } as TJustificanteIBI)

}
export const extraerBorradorJAEAT=(datos: TPasoDatosTransmision, terms:any) => {
    const suj = datos.datosPrevios.tipoTransmitente === 'comprador'
        ? datos.datosComprador.find(c => c.nif === datos.datosPrevios.nifSujeto)
        : datos.datosVendedor.find(v => v.nif === datos.datosPrevios.nifSujeto);

    return({
        APART_1: [
            { term: 'acuerdoCambioTitularidad', value: []},
        ],
        APART_2: [
           { term: 'MXX_nif', value: datos.datosPrevios.nifSujeto },
           { term: 'MXX_nomApe', value: suj?.nomApe },
        ],
        APART_3: [
            { term: 'MXX_direccion', value: suj?.direccionFiscal?.direccion },
        ]
    } as TJustificanteAEAT)
}
export const extraerBorradorJTASAS=(datos: TPasoDatosTransmision, terms:any) => {
    const tipoBien = datos.datosPrevios.infoCatastral?.tipoBien
        ? datos.datosPrevios.infoCatastral?.tipoBien === 'UR' 
            ? 'Urbana'
            : datos.datosPrevios.infoCatastral?.tipoBien === 'RU'? 'Rústica': 'BICES'
        : undefined;

    return ({
        APART_1: [
            { term: 'acuerdoCambioTitularidad', value: []},
        ],
        APART_2: [
            { term: 'justif_fechaDevengo', value: moment(datos.infoTransmision.fecha).format('DD/MM/YYYY')},
            { term: 'justif_tipoTransmision', value: datos.infoTransmision.tipoTransmision?.nombre},
            { term: 'justif_derechoTransmision', value: datos.infoTransmision.derechoTransmision?.nombre},
            { term: 'justif_porcentaje', value: datos.infoTransmision.porcentaje+' %'},
            { term: 'justif_notario', value: datos.infoTransmision.notario},
            { term: 'justif_anyo', value: datos.infoTransmision.anyo},
            { term: 'justif_protocolo', value: datos.infoTransmision.numeroProtocolo},
        ],
        APART_3: [
            {term: 'MXX_refCatastral', value: datos.datosPrevios.infoCatastral?.refCatastral, fullWidth: true},
            {term: 'justif_direccion', value: datos.datosPrevios.infoCatastral?.direccion, fullWidth: true},
            {term: 'justif_tipoBien', value: tipoBien, fullWidth: true},
            {term: 'justif_uso', value:datos.datosPrevios.infoCatastral?.datosEconomicos?.uso },
            {term: 'justif_superficie', value: datos.datosPrevios.infoCatastral?.datosEconomicos?.superficieConstruida , fullWidth: true},
        ],
        APART_4: {
            titulares: 
                datos.datosComprador.map( c => {
                    return([
                        {term: 'MXX_nif', value: c.nif },
                        {term: 'MXX_nomApe', value: c.nomApe },
                        {term: 'justif_porcentajeTitular', value: c.porcentaje+' %' }
                    ])
                }),
        },
        APART_5:{
            titulares: 
            datos.datosVendedor.map( v => {
                return([
                    {term: 'MXX_nif', value: v.nif },
                    {term: 'MXX_nomApe', value: v.nomApe },
                    {term: 'justif_porcentajeTitular', value: v.porcentaje+' %' }
                ])
            })
        },
        APART_6 :[]
    } as TJustificanteTASAS)
}
export const extraerBorradorJDUSTI= (datos: TDUSTIInfo, terms:any) => {}