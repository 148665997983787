import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from "react";
import { mdiEyeCircleOutline, mdiPaperclip, mdiShieldAccount, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";

import { Button, Card, CardActionArea, CardContent, CircularProgress, Grid, IconButton, MenuItem, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";

import LabelWithInfo from "components/DUTI/label-with-info";
import ContactForm, { TContacto, TContactoFlags } from "components/DUTI/contact-form";
import InputText from "components/DUTI/input-text";
import { TExtraValidation } from "components/DUTI/types";
import InputSelect from "components/DUTI/input-select";
import { TSelect } from "containers/DUTI/utils";
import Term from "components/term";

import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import { BLUE } from "containers/shared/colors";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import usePage from "hooks/page.hook";

import { MoreInfoContext } from "contexts/more_info.context";

import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { validateNif } from "utils/validateNif";
import { DocumentsUtils } from "utils/documents";

import { DocumentosGateway } from "gateways/documentos.gateway";
import { SolicitudesGateway } from 'gateways/solicitudes.gateway';
import { IAttachedDoc } from "gateways/documentos.interface";
import FullLoading from "components/full-loading";
import ModalConformidad from "components/modal-conformidad";


const NOT_ALLOWED_EXTENSIONS = [
    ".ADE", ".ADP", ".APK", ".BAT", ".CHM", ".CMD", ".COM", ".CPL", ".DLL", ".DMG", ".EXE", ".HTA", ".INS", ".ISP",".JAR", ".JS", ".JSE", 
    ".LIB", ".LNK",".LINK", ".MDE", ".MSC", ".MSI", ".MSP", ".MST", ".NSH", ".PIF", ".SCR", ".SCT",".SHB", ".SYS", ".VB", ".VBE", ".VBS", ".VXD", 
    ".WSC", ".WSF", ".WSH", ".CAB"
]

const styles = (theme: Theme): StyleRules => ({
    root: {
        width: '100%',
        marginRight: 15,
        marginLeft: 15,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column'
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom:20
    },
    text: {
        marginBottom:40
    },
    row: {
        display:'flex',
        flexDirection: 'row',
        alignItems:'center'
    },
    input: {width: 360},
    inputFullWidth: { // Small: puerta, esc
        width: '100%'
    },
});


interface IProps extends WithStyles<typeof styles> {

}

const AtPersonalizadaConsultas: FC<IProps> = ({classes}) => {
    const [, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [,alertsDispatch] = useContext(AlertsContext)
    const terms = useContext(LiteralsContext);
    
    const ioc = useContext(IoC);
    const documentGateway = ioc.get(DocumentosGateway) as DocumentosGateway;
    const solicitudesGateway = ioc.get(SolicitudesGateway) as SolicitudesGateway;

    const [loading, setLoading] = useState(false);
    const [asuntOpt, setAsuntoOpt] = useState<TSelect[]>([
        { nombre: translate('Perfil','consultas_subject_querie', terms), id: 'consulta' },
        { nombre: translate('Perfil','consultas_subject_suggestion', terms), id: 'sugerencia' },
        { nombre: translate('Perfil','consultas_subject_incident', terms), id: 'incidencia' },
    ])
    const [asunto, setAsunto] = useState<TSelect| undefined>(undefined)
    const [asuntoError, setAsuntoError] = useState(false)

    const [nif, setNif]= useState<string|undefined>(undefined)
    const [nifError, setNifError]= useState(false)
    const [nomApe, setNomApe]= useState<string|undefined>(undefined)
    const [nomApeError, setNomApeError]= useState(false)
    const [contacto, setContacto] = useState<TContacto>({telefono: undefined, email:''});
    const [contactoErr, setContactoErr] = useState<TContactoFlags>({telefono: false, email:false});
    const [motivo, setMotivo]= useState<string|undefined>(undefined)
    const [motivoError, setMotivoError]= useState(false)
    const [attachedDoc, setAttachedDoc]= useState<IAttachedDoc | null>(null)
    
    const [showConformidad, setShowConformidad]= useState(false);



    const validateNIF = (nif: string) => {
        const isValid = validateNif(nif)
        //console.log('validateNIF ', nif, isValid)
        setNifError(!isValid)
        const result: TExtraValidation={ 
            error: isValid ? false: true, 
            error_msg: translate('DUTI', 'nif_error_format',terms)
        }
        return result
    }

    const handleAttach = async (files: FileList) => {
        try {
            setLoading(true);
            const extension = files[0].name.substring(files[0].name.lastIndexOf(".")).toUpperCase() 
            if(NOT_ALLOWED_EXTENSIONS.includes(extension)){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Perfil', 'extension_not_allow', terms),
                        variant: 'error',
                    }
                });

            } else {
                const guids = await documentGateway.subirDocumentos(files, 'consultas-sugerencias');
                setAttachedDoc({
                    id: guids[0], 
                    name: files[0].name, 
                    size: files[0].size, 
                    type: files[0].type, 
                })
            }
           
        } catch (err) { 
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI', 'can_not_attach_doc', terms),
                    variant: 'error',
                }
            });
        }
        finally {
            setLoading(false);
        }
    }

    const showDoc = async() => {
        if (attachedDoc && attachedDoc.id!== undefined) {
            try {
                setLoading(true)
                DocumentsUtils.downloadDoc(attachedDoc.id);
                setLoading(false)
            } catch (error) {
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI', 'can_not_show_attached_doc', terms),
                        variant: 'error',
                    }
                });
            }
        }        
    }

    const handleValidate = () => {
        if(nifError || asuntoError || motivoError || Object.values(contactoErr).includes(true)) {
            return false
        }
        
        if(!asunto || (asunto && asunto.id === '') || 
            //!nif || nif.length===0 ||
            !nomApe || nomApe.length === 0 ||
            !contacto || !contacto.telefono || !contacto.email || contacto.email.length ===0 ||
            !motivo || motivo.length === 0
        ){
            //setNifError(!nif || nif.length===0)
            setNomApeError(!nomApe || nomApe.length === 0 )
            setAsuntoError(!asunto || (asunto && asunto.id === ''))
            setContactoErr({ 
                email:!contacto || contacto.email.length ===0 , 
                telefono: !contacto || !contacto.telefono 
            })
            setMotivoError(!motivo || motivo.length === 0)
            return false
        }
            
        return true
        
    }

    const cleanLocalData= () => {
        setNif(undefined); 
        setNifError(false);
        setNomApe(undefined);
        setNomApeError(false);
        setContacto({telefono: undefined, email:''}); 
        setContactoErr({telefono: false, email:false});
        setAsunto(undefined); 
        setAsuntoError(false);
        setAttachedDoc(null);
        setMotivo(undefined);
        setMotivoError(false);
    }

    const handleEnviar = async () => {
        setLoading(true);
        try {
            await solicitudesGateway.enviarConsulta({
                asunto: asunto ? asunto.id : '',
                nif: nif ?? '',
                nombre: nomApe ?? '',
                email: contacto.email,
                telefono: contacto.telefono?.toString() ?? '',
                contenido: motivo ?? '',
                files: attachedDoc ? [attachedDoc.id] : null,
            });
            setLoading(false);
            cleanLocalData();
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Perfil', 'success_enviar_consula', terms),
                    variant: 'success',
                }
            });
        } catch (error) {
            setLoading(false);
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Perfil', 'error_enviar_consula', terms),
                    variant: 'error',
                }
            });
        }
    }


    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch]);
    
    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiShieldAccount,
                title: translate("Perfil","ConsultaSugerencias",terms ),
                moreInfoTemplate: 'mas_info_consultas_y_sugerencias',
                right: (
                    <>
                        <MenuItem button onClick={() => handleShowMasInfo('mas_info_consultas_y_sugerencias')}>
                            <Term component="Global" text="Mas informacion" />
                        </MenuItem>
                    </>
                )
            },
            menu: true,
            footer: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    return (
        <div className={classes.root}>
            <FullLoading loading={loading} />
            <Card >
                <CardContent>
                    <div className={classes.content}>
                        <Typography className={classes.title}>
                            {translate('Perfil','ConsultaConNosotros', terms)}
                        </Typography>
                        <Typography className={classes.text}>
                            {translate('Perfil','ConsultaConNosotrosTexto', terms)}
                        </Typography>
                        <Grid container direction="column" alignItems="flex-start" justify="flex-start">
                            <Grid item className={classes.inputFullWidth}>
                                <InputSelect
                                    fieldTerm ='asunto'
                                    componentIG= 'Perfil'
                                    value={asunto}
                                    options={asuntOpt}
                                    onChangeValue={ (v: TSelect|undefined, err: boolean) => {
                                        setAsunto(v)
                                        setAsuntoError(err)
                                    }}
                                    required
                                    error={asuntoError}
                                    className={classes.inputFullWidth}
                                />
                            </Grid>
                            <Grid item className={classes.row}>
                                <InputText 
                                    fieldTerm='nif'
                                    value={nif}
                                    onChangeValue={(v:string|undefined, err: boolean) => {
                                        setNif(v)
                                        setNifError(err)
                                    }}
                                    required={false}
                                    forceUpperCase={true}
                                    error={nifError}
                                    extraValidation={validateNIF}
                                />

                                <InputText 
                                    fieldTerm='nomApe'
                                    value={nomApe}
                                    onChangeValue={(v:string|undefined, err: boolean) => {
                                        setNomApe(v)
                                        setNomApeError(err)
                                    }}
                                    forceUpperCase={true}
                                    required
                                    error={nomApeError}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item>
                                <ContactForm
                                    contact= {contacto}
                                    setContact={(c:TContacto) => setContacto(c)}
                                    contactErrors={contactoErr}
                                    setContactErrors={(err:TContactoFlags) => setContactoErr(err)}
                                    required={{telefono:true, email:true }}
                                />
                            </Grid>
                            <Grid item className={classes.inputFullWidth}>
                                <InputText 
                                    componentIG="Perfil"
                                    fieldTerm={'motivoConsulta'}
                                    InputProps={{startAdornment: <></>}}
                                    placeholder={ translate('Perfil','motivoConsultaPlaceholder', terms) }
                                    value={motivo}
                                    onChangeValue={(v:string|undefined, err: boolean) => {
                                        setMotivo(v)
                                        setMotivoError(err)
                                    }}
                                    required
                                    error={motivoError}
                                    multiline
                                    rows={4}
                                    className={classes.inputFullWidth}
                                />
                            </Grid>

                        </Grid>
                        <LabelWithInfo type='text' fieldTerm={'SiLoDeseaAdjunteDocTexto'} moreInfo={{ template: 'more_info_extensiones_no_admitidas'}} component="Perfil" />
                        <div className={classes.row} style={{alignItems: 'center', minHeight: 48}}>
                            <Typography variant="body2" style={{marginRight: 30, fontWeight: 'bolder'}}>{translate('Perfil','attachDoc',terms).toUpperCase()}</Typography>
                            {
                                attachedDoc ?
                                    <>
                                        <>
                                            <Typography variant="body1" style={{marginRight: 20}}>{attachedDoc.name}</Typography>
                                            {attachedDoc.id && 
                                                <IconButton onClick={showDoc}>
                                                    <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} />
                                                </IconButton>
                                            }
                                            <IconButton onClick={() =>{ 
                                                setAttachedDoc(null); 
                                            }}
                                            >
                                                <Icon path={mdiTrashCan} size={1} color='red'  />
                                            </IconButton>
                                        </>
                                        
                                    </>
                                :
                                    <>
                                        <input
                                            accept=".pdf"
                                            style={{ display: 'none' }}
                                            id={`file-field`}
                                            type="file"
                                            multiple={false}
                                            onChange={ (event: ChangeEvent<HTMLInputElement>) => event.target.files && event.target.files.length>0 && handleAttach(event.target.files) }
                                        />
                                        <label htmlFor={`file-field`}>
                                                <Button component="span" variant="outlined" 
                                                    color='primary'
                                                    size="small"
                                                    classes={{startIcon: classes.starIcon}}
                                                    style={{marginRight:0}}
                                                    startIcon={ <Icon path={mdiPaperclip} size={1} color={BLUE} /> }
                                            >
                                                {translate('DUTI','btn_attach',terms)}
                                            </Button>
                                        </label>    
                                    </>
                            }
                        </div>
                    </div>
                    <div className={classes.row} style={{alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Button color="primary" variant='contained'
                            disabled={nifError || asuntoError || motivoError || Object.values(contactoErr).includes(true)}
                            onClick={() => {
                                if(handleValidate()){
                                    setShowConformidad(true)
                                } 
                            }}
                        >
                            {translate("Global","Enviar", terms)}
                        </Button>
                    </div>
                </CardContent>
            </Card>

            <ModalConformidad
                open={showConformidad}
                renderContent={() => (
                    <Grid container direction="column">
                        <Grid item>
                            <Typography>{translate('DUTI','nomApe',terms)}: <b>{nomApe}</b></Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{translate('DUTI','nif',terms)}: <b>{nif ?? ''}</b></Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{translate('Perfil','asunto',terms)}: <b>{asunto?.nombre}</b></Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{translate('Perfil','motivoConsulta',terms)}: <b>{motivo}</b></Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{translate('Perfil','attachDoc',terms)}: <b>{attachedDoc && attachedDoc.id ? attachedDoc.name : ''}</b></Typography>
                        </Grid>

                    </Grid>
                )}
                onClose={() => setShowConformidad(false)}
                onSave={handleEnviar}
            />
        </div>
    )
}

export default withStyles(styles)(AtPersonalizadaConsultas);