import React, { FC, useCallback, useEffect, useState } from "react";
import { mdiLockOpen, mdiOpenInNew, mdiShieldAccount } from "@mdi/js";
import Term from "components/term";
import usePage from "hooks/page.hook";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Card, Divider, Grid, makeStyles } from "@material-ui/core";

import AreaPersonaIcon from '../../resources/home/AreaPersonal2.png';
import AtencionPersonalizadaIcon from '../../resources/home/AtencionPersonalizada.png';
import { TMenuItem } from "containers/shared/menu-item-types";
import BoxMenuItem from "components/box-menu-item";
import styles from "containers/shared/container-home";
import Icon from "@mdi/react";
import ModalAcceso from "components/modal-acceso";

import AltaUsuarioIcon from '../../resources/home/notificaciones/AltaUsuario.png'
import DiasCortesiaIcon from '../../resources/home/notificaciones/DiasCortesia.png'
import MisNotificaIcon from '../../resources/home/notificaciones/MisNotifica.png'
import MasInfoIcon from '../../resources/home/notificaciones/info.png'
import RepresentacionesIMG from '../../resources/home/tramites/Representaciones.png'



interface IRouteParams { }
type Props = RouteComponentProps<IRouteParams>;

const useStyles = makeStyles(styles);


const TributosHome: FC<Props> = ({ match, history }) => {
    const classes = useStyles()
    const [pageState, pageDispatcher] = usePage();
    const [showLogin, setShowLogin] = useState(false);
    const isMobile = window.innerWidth <= 590;

    const handleOpenModalRepresentar = useCallback(() => {
        pageDispatcher({ type: 'open-modal-representar' })
    }, [pageDispatcher]);

    const MENU_ITEMS: TMenuItem[] = [
        {
            title: { component: "Perfil", text: "MisDatos" }, img: AreaPersonaIcon, children: [
                { title: { component: "Perfil", text: "ConsultaDatos" }, link: 'perfil/mi-perfil', protected: 1, idModulo: 18 },
            ]
        },
        {
            title: { component: "Global", text: "Mis notificaciones" }, img: MisNotificaIcon, children: [
                { title: { component: "Global", text: "mis_notificaciones_descripcion" }, link: 'perfil/notificaciones/mis-notificaciones', protected: 2, idModulo: 6 },
                { title: { component: "Global", text: "dias_cortesia_descripcion" }, link: 'perfil/notificaciones/dias-cortesia', protected: 2, idModulo: 7 },
                { title: { component: "Global", text: "mas_info_descripcion" }, link: 'perfil/contenidos', protected: 0 },
            ]
        },
        {
            title: { component: "Global", text: "Representaciones" }, img: RepresentacionesIMG, children: [
                { title: { component: "Perfil", text: "Representar" }, link: '', protected: 2, onClick: handleOpenModalRepresentar },
                { title: { component: "Perfil", text: "ConsultaRepresentantes" }, link: 'perfil/representaciones/gestion-de-representaciones', protected: 1, idModulo: 10 },
            ]
        },
        {
            title: { component: "Perfil", text: "AtencionPersonalizada" }, img: AtencionPersonalizadaIcon, children: [
                { title: { component: "Perfil", text: "ConsultaSugerencias"}, link: 'perfil/atencion-personalizada/consultas',  protected: 0 },
            ]
        },
    ]

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiShieldAccount,
                title: <Term component="Global" text="AreaPersonalNotificaciones" />,
                text: <Term component="Global" text="AreaPersonalNotificacionesDescripcion" />,
            },
            menu: true,
            footer: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    return (
        <>
            <Card className={classes.root}>
                <Grid container direction="row" justify="flex-start">
                    {
                        MENU_ITEMS.map((menuitem, idx) => (
                            <Grid className={classes.widget} item xs={12} md={6} xl={4} key={`area-personal-${idx}`}>
                                <BoxMenuItem
                                    item={menuitem}
                                    onLogin={(accessLevel) => {
                                        if (isMobile) { history.push('/acceso', { goBack: history.location.pathname }) }
                                        else {
                                            if (accessLevel === 2) { history.push('/acceso/clave', { goBack: history.location.pathname }) }
                                            if (accessLevel === 1) { setShowLogin(true) }
                                        }
                                    }}
                                />
                            </Grid>

                        ))
                    }
                </Grid>
            </Card>
            <ModalAcceso openModalAcceso={showLogin} setOpenModalAcceso={setShowLogin} history={history} />
        </>
    )
}

export default withRouter(TributosHome);

