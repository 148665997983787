import { IDomiVoluntaria } from "gateways/datos.domiciliacion.interfaces";
import { IDiaCortesiaDetalle, IDiasCortesia } from "gateways/notificaciones.interfaces";
import { dateFormatToUTC, getMomentFromDate } from "./dates";
import moment from "moment";

function handleIsModificable(domiVoluntaria: IDomiVoluntaria, fecha: string, fechaDomiciliacionIncompatible: string | null) {
    const dateModificable = domiVoluntaria.fechasVoluntaria.find((dateVoluntaria) =>
        dateFormatToUTC(dateVoluntaria, 'YYYY-MM-DD') === dateFormatToUTC(fecha, 'YYYY-MM-DD')
    );

    if (!dateModificable) return false;
    if (!fechaDomiciliacionIncompatible) return true;

    return moment(dateModificable).isAfter(getMomentFromDate(fechaDomiciliacionIncompatible))
        ? false
        : true;
}

export function sanitizeCalendarProps(domiVoluntaria: IDomiVoluntaria, allDates: string[], fechaDomiciliacionIncompatible: string | null, maxDiasCortesia: number) {
    const diasVoluntariaDetalle: IDiaCortesiaDetalle[] = allDates.map(fecha => {
        return {
            idSujeto: domiVoluntaria.idSujeto,
            ejercicio: new Date(fecha).getFullYear().toString(),
            fecha: dateFormatToUTC(fecha, 'YYYY-MM-DD'),
            seleccionada: false,
            modificable: handleIsModificable(domiVoluntaria, fecha, fechaDomiciliacionIncompatible)
        }
    });

    const voluntaria: IDiasCortesia = {
        idSujeto: domiVoluntaria.idSujeto,
        nif: "",
        maxDiasCortesia: maxDiasCortesia,
        diasSolicitados: 0,
        diasSolicitadosNoModificables: 0,
        diasSolicitadosModificables: 0,
        diasPendientes: maxDiasCortesia,
        dias: diasVoluntariaDetalle
    }


    return voluntaria;
}
