import React from 'react'; //

interface IProps { }

const BizumForm: React.FC<IProps> = (props) => {

    return (
        <form method="post" id="Ds_form" name="Ds_form">
            <input type="hidden" name="Ds_MerchantParameters" id="Ds_MerchantParameters" />
            <input type="hidden" name="Ds_Signature" id="Ds_Signature" />
            <input type="hidden" name="Ds_SignatureVersion" id="Ds_SignatureVersion" />
        </form> 
    )
}

export default BizumForm;