import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";

import { Grid, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { LiteralsContext, withLiterals } from "containers/shared/literals";

import { TDeduccion, TTipoSolicitud } from "../types";
import GenericInputText from "components/generic-input-text";
import GenericInputDate, { TExtraValidation } from "components/generic-input-date";
import IoC from "contexts/ioc.context";
import { SujetosGateway } from "gateways/sujetos.gateway";
import { Municipio, Provincia } from "gateways/geo.interface";
import { formatSelectOptions } from "containers/DUTI/utils";
import GenericInputSelect, { TSelect } from "components/generic-input-select";
import { IPais } from "gateways/pais.interface";
import { FECHA_PUESTA_MARCHA } from "constants/abono-nacimiento";
import moment from "moment";
import { translate } from "utils/i18n";
import { AlertsContext } from "contexts/alerts.context";
import { ContenidosGateway } from "gateways/contenido.gateway";
import FullLoading from "components/full-loading";
import { AbonoNacimientoGateway } from "gateways/tramites/abonoNacimiento.gateway";


interface DerechpDeduccionProps extends WithStyles<typeof styles> {
    deduccion: TDeduccion;
    tipo: "solicitud" | "reintegro";
    tipoSolicitud : TTipoSolicitud | undefined;
    onChange: (v: TDeduccion) => void 
    onForceExit: (v: boolean) => void 
    
};

const DerechoDeduccion: FC<DerechpDeduccionProps> = ({classes, deduccion,tipo,tipoSolicitud, onChange, onForceExit}) => {
    const terms =useContext(LiteralsContext);
    const componentMounted = useRef(true);
    const [,alertsDispatch] = useContext(AlertsContext);
    // const [minDate, setMinDate]= useState<Date|undefined>(undefined)
    const ioc = useContext(IoC);
    const sujetosGateway = useMemo(() => ioc.get(SujetosGateway) as SujetosGateway, [ioc]);
    const contenidosGateway = useMemo(() => ioc.get(ContenidosGateway) as ContenidosGateway, [ioc]);
    const abonoNacimientoGateway = useMemo(() => ioc.get(AbonoNacimientoGateway) as AbonoNacimientoGateway, [ioc]);

    const [loading, setLoading] = useState(false)

    const [municipios, setMunicipios]=useState<TSelect[]>([])
    const [infoPlazo, setInfoPlazo]=useState<string>('')

    const [paises, setPaises]=useState<TSelect[]>([])
    const [provincias, setProvincias]=useState<TSelect[]>([])

    const [dateRefs, setRefDates] = useState<{monthLater: Date| null, back15Days: Date | null}>({
        monthLater: null,
        back15Days: null
    })

    // Funciones -------------------------------------------------------------------------------------------------------------
    const dateLimits = useMemo(() => {
        const ejercicio =new Date().getFullYear()
        const prevEjercicio = ejercicio - 1;
        if(!tipoSolicitud || tipoSolicitud === 'anticipado'){ 
            return {max: new Date(), min: new Date(`${ejercicio}-01-01`)}
        } else {
            //console.log({max: new Date(`${prevEjercicio}-12-31`), min: new Date(`${prevEjercicio}-01-01`)})
            return {max: new Date(`${prevEjercicio}-12-31`), min: new Date(`${prevEjercicio}-01-01`)}
        }

    },[tipoSolicitud])

    const checkDate =  (dateNAC: Date|null|undefined) => {
        if(loading){return}
        let out: TExtraValidation= {error: (dateRefs.monthLater && dateRefs.back15Days) ? false: true, error_msg: 'date_not_checked'}
        const fechaRef = new Date(FECHA_PUESTA_MARCHA);
        if(dateNAC && tipo === 'solicitud' && tipoSolicitud === 'anticipado' && dateRefs.monthLater && dateRefs.back15Days){
            const nacimTime = new Date(moment(dateNAC).format('YYYY-MM-DD')).getTime()
            const refTime = fechaRef.getTime()
            if(nacimTime < refTime){
                const now = new Date().getTime()
                if(now > dateRefs.monthLater.getTime()){
                    out={ error: true, error_msg: 'error_masDeUnMes', componentIG: 'AbonoNacimiento'}
                }
            } else {       
                if(nacimTime < dateRefs.back15Days.getTime() ){
                    out={ error: true, error_msg: 'error_masDe15dias', componentIG: 'AbonoNacimiento'}

                }
            }
        }

        if(!out.error){onForceExit(false)}
        else{
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    variant: 'error',
                    isHtml: (!dateRefs.monthLater || !dateRefs.back15Days) ? false : true,
                    message: (!dateRefs.monthLater || !dateRefs.back15Days) ? translate('AbonoNacimiento','date_not_checked', terms) :infoPlazo
                }
            })
            onForceExit(true);
        }
        setLoading(false)
        return out
       
       

    }

    // Effects -------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if(deduccion.provinciaId){
            (async()=>{
                const optionsMunicipios= await sujetosGateway.getMunicipios(deduccion.provinciaId ?? '')
                setMunicipios(formatSelectOptions<Municipio>('idMunicipio','nombre',optionsMunicipios))
            })()
        }
    },[ deduccion.provinciaId ])

    useEffect(() => {
        (async () => {
        setLoading(true)
           const contentMSG= await contenidosGateway.getContent('ABO_NAC_info_plazo',{})
            const optionsPaises = await sujetosGateway.getPaises()
            const PAIS = optionsPaises.find(p =>( deduccion.paisId !== undefined ? p.idPais === deduccion.paisId : p.idPais === '00'))
            const optionsProvincias = await sujetosGateway.getProvincias('00') //(PAIS && PAIS.idPais ==='00' ) ? await sujetosGateway.getProvincias(PAIS.idPais): []
            
            const PROV = deduccion.paisId && deduccion.paisId === '00' 
                ? optionsProvincias.find(p => ( deduccion.provinciaId !== undefined ?  p.idProvincia === deduccion.provinciaId : p.idProvincia === '07'))
                : undefined;
            const optionsMunicipios= PROV ? await sujetosGateway.getMunicipios(PROV.idProvincia): []
            // Fechas
            const monthLater= await abonoNacimientoGateway.getRefDate('month',1,FECHA_PUESTA_MARCHA)
            const back15days = await abonoNacimientoGateway.getRefDate('day',-15, moment().format('YYYY-MM-DD'))
            //console.log(' onChange ', PAIS, PROV)
            if(componentMounted.current){
                setMunicipios(formatSelectOptions<Municipio>('idMunicipio','nombre',optionsMunicipios))
                setPaises(formatSelectOptions<IPais>('idPais','nombre',optionsPaises))
                setProvincias(formatSelectOptions<Provincia>('idProvincia','nombre',optionsProvincias))
                setInfoPlazo(contentMSG[0].contenido ?? 'Oooops !! not found message')
                setRefDates({
                    monthLater: new Date(monthLater),
                    back15Days: new Date(back15days)
                })
            }
            setLoading(false)
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }

    }, [])


    
    return (
        <>
        <FullLoading loading={loading} />
        {!loading && 
            <Grid container direction="column" alignItems="flex-start" justify="space-between">
                
                <Grid item className={classes.row} style={{marginBottom: 20, borderBottom: 'solid 1px #d3d3d3', flexGrow:1, width: '100%'}}>
                    <Typography style={{fontWeight: 'bolder'}}>{translate('AbonoNacimiento','AbonoNacimiento_IdentificacionNacidoCertificadoNacimiento',terms)}</Typography>
                </Grid>
                <Grid item className={classes.row}>
                    <GenericInputText
                        componentIG='GenericComponents'
                        labelTerm='nombre'
                        value={deduccion?.nombre}
                        required
                        disabled={tipo === 'reintegro' }
                        forceUpperCase
                        onChangeValue={(v: string|undefined, err: boolean) =>  onChange({...deduccion, nombre: v})}
                        className={classes.input360}
                    />
                    <GenericInputText
                        componentIG='GenericComponents'
                        labelTerm='apellidos'
                        value={deduccion?.apellidos}
                        required
                        disabled={tipo === 'reintegro' }
                        forceUpperCase
                        onChangeValue={(v: string|undefined, err: boolean) =>  onChange({...deduccion, apellidos: v}) }
                        className={classes.inputDouble360}
                    />
                    <GenericInputDate
                        //className={classes.input360}
                        componentIG="AbonoNacimiento"
                        labelTerm='fechaNacimiento' 
                        value={deduccion?.fechaNacimiento}
                        onChangeValue={ (v:Date | undefined, err:boolean) => {
                            onChange({...deduccion, fechaNacimiento: v})
                            onForceExit(err)
                        }} // onChangeDate('fechaNacimiento', v, err)}
                        required
                        disabled={tipo === 'reintegro' }
                        maxDate={dateLimits.max}
                        minDate={dateLimits.min}
                        extraValidation={checkDate}
                        moreInfo={
                            tipoSolicitud === 'insuficiencia' 
                            ? {template: 'ABO_NAC_fechaNacido_insuficiencia_info',size: 'md'}
                            : undefined
                        }
                        customMaxDateError={tipoSolicitud === 'insuficiencia' ? {
                            msg: 'dateRangeInsuficiencia', 
                            componentIG: 'AbonoNacimiento',
                            params: [moment(dateLimits.min).format('DD/MM/YYYY'), moment(dateLimits.max).format('DD/MM/YYYY')]
                            } : undefined}
                        customMinDateError={tipoSolicitud === 'insuficiencia' ? {
                            msg: 'dateRangeInsuficiencia', 
                            componentIG: 'AbonoNacimiento',
                            params: [moment(dateLimits.min).format('DD/MM/YYYY'), moment(dateLimits.max).format('DD/MM/YYYY')]
                            }  : undefined}

                    />
                </Grid>
                <Grid item className={classes.row} style={{marginBottom: 20, borderBottom: 'solid 1px #d3d3d3', flexGrow:1, width: '100%'}}>
                    <Typography style={{fontWeight: 'bolder'}}>{translate('AbonoNacimiento','lugarNacimiento',terms)}</Typography>
                </Grid>
                
                <Grid item className={classes.row}>
                    <GenericInputSelect 
                        componentIG={"GenericComponents"}
                        labelTerm='dir_pais'
                        value={ deduccion.paisId ? {id: deduccion.paisId, nombre: deduccion.paisNombre}: undefined}
                        options={paises}
                        onChangeValue={(v: TSelect | undefined,err: boolean) => { 
                            onChange({...deduccion,
                                paisId: v?.id, paisNombre: v?.nombre, 
                                provinciaId: undefined, provinciaNombre: undefined, 
                                municipioId: undefined, municipioNombre: undefined
                            })
                        }}
                        required={true}
                        disabled={tipo === 'reintegro' }
                        className={classes.input360}
                        //disabled={true}
                    />
                    {deduccion.paisId === '00'  ?
                        <>
                            <GenericInputSelect 
                                componentIG={"GenericComponents"}
                                labelTerm='dir_provincia'
                                value={deduccion.provinciaId ? {id: deduccion.provinciaId, nombre: deduccion.provinciaNombre}: undefined}
                                options={provincias}
                                onChangeValue={(v: TSelect | undefined,err: boolean) => { 
                                    onChange({...deduccion, 
                                        provinciaId: v?.id, provinciaNombre: v?.nombre,
                                        municipioId: undefined, municipioNombre: undefined
                                    })
                                }}
                                required={true}
                                disabled={deduccion.paisNombre === undefined  || (tipo === 'reintegro' )}
                                className={classes.input360}
                            />
                            <GenericInputSelect 
                                componentIG={"GenericComponents"}
                                labelTerm='dir_municipio'
                                value={deduccion.municipioId ? {id: deduccion.municipioId ,nombre:deduccion.municipioNombre} : undefined}
                                options={municipios}
                                onChangeValue={(v: TSelect | undefined,err: boolean) => { 
                                    onChange({...deduccion, 
                                        municipioId: v?.id, municipioNombre: v?.nombre
                                    })
                                }}
                                required={true}
                                disabled={deduccion.provinciaNombre === undefined  || (tipo === 'reintegro' )}
                                className={classes.input360}
                
                            />
                        </>
                    :
                        <>
                            <GenericInputText
                                componentIG={"GenericComponents"}
                                labelTerm='dir_provincia'
                                disabled={!deduccion.paisNombre || (tipo === 'reintegro' )}
                                value={deduccion.provinciaNombre}
                                forceUpperCase
                                onChangeValue={(v: string | undefined,err: boolean) => { 
                                    onChange({...deduccion,
                                        provinciaId: undefined, provinciaNombre: v, 
                                        municipioId: undefined, municipioNombre: undefined 
                                    })
                                }}
                                required={true}
                                className={classes.input360}
                            />
                            <GenericInputText
                                componentIG={"GenericComponents"}
                                labelTerm='dir_municipio'
                                disabled={!deduccion.provinciaNombre || (tipo === 'reintegro' )}
                                value={deduccion.municipioNombre}
                                forceUpperCase
                                onChangeValue={(v: string | undefined,err: boolean) => { 
                                    onChange({...deduccion, 
                                        municipioId: undefined, municipioNombre: v 
                                    })
                                }}
                                required={true}
                                className={classes.input360}
                            />
                        </>
                    }

                </Grid>
                
            </Grid>
        }
        </>

    )
};

const styles = (theme: Theme): StyleRules => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap:'wrap'
    },
    input360: {
        width: 360
    },
    inputDouble360: { // input360 + inputDefault
        width: 360+10 + 260
    }
});
export default withLiterals(['Global', 'Tramites','AbonoNacimiento','GenericComponents' ])(withStyles(styles)(DerechoDeduccion));