import React from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import OficinaDetalle from './oficina.detail';
import OficinaList from './oficina.list';
import { withLiterals } from 'containers/shared/literals';


const OficinasIndex: React.FC<RouteComponentProps> = ({ match }) => {
    return (
        <>
            <Route path={`${match.path}/`} exact component={OficinaList} />
            <Route path={`${match.path}/:idOficina`} exact component={OficinaDetalle} />
        </>
    );
}

export default withLiterals(['Global','Oficinas'])(withRouter(OficinasIndex));
