type TExtraValidation = {
    error:boolean, 
    error_msg: 'email_format_error' | undefined
    componentIG?:string
}
const REGEX_EMAIL = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) 
export const validateEmail = (email:string) => {
    const isOK = REGEX_EMAIL.test(email)
    return({
        error: !isOK, 
        error_msg: isOK? undefined: 'email_format_error'
    } as TExtraValidation)
}