// LIBRARY IMPORTS
import { FC } from "react";
import { Checkbox, Typography, makeStyles, useTheme } from "@material-ui/core";
// LOCAL IMPORTS
import { ITributoRelacionado } from "gateways/tributos.interfaces";
// STYLES IMPORTS
import styles from './styles';

const useStyles = makeStyles(styles);

interface IProps {
    tributoRelacionado: ITributoRelacionado;
    tributoRelacionadoSelected: ITributoRelacionado[];

    onCheck: (tributo: ITributoRelacionado) => void;
}

const PagoCartaTributoAdherible: FC<IProps> = (props) => {
    const { tributoRelacionado, tributoRelacionadoSelected, onCheck } = props;
    // HOOKS
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <div
            key={tributoRelacionado.idRecibo}
            className={[
                classes.flexRow,
                classes.alignCenter,
                classes.gap20,
                classes.padding20
            ].join(' ')}
        >
            <Checkbox
                color="primary"
                checked={tributoRelacionadoSelected.includes(tributoRelacionado)}
                onChange={() => onCheck(tributoRelacionado)}
            />
            <div className={classes.flexColumn}>
                <Typography>{tributoRelacionado.muni}</Typography>
                <Typography>{tributoRelacionado.descri}</Typography>
                <Typography>{tributoRelacionado.Obj}</Typography>
                <Typography>{tributoRelacionado.DireccionTributaria}</Typography>
            </div>
        </div>
    )
}

export { PagoCartaTributoAdherible };
