import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import qs from "qs";
import { ISolicitudRepresentacion, ISolicitudAltaRepresentacionDto, ISolicitudAltaRepresentacionResponse, ICambioEstadoSolicitudRepresentacionResponse, IEstadoSolicitud, IProcedimiento, IRepresentado } from "./representaciones.interfaces";

@inject("axios")
export class RepresentacionesGateway {
  constructor(private axios: AxiosInstance) { }

  public async actualizaEstado(idSolicitud: number, idEstado: number) {
    const response = await this.axios.put<ICambioEstadoSolicitudRepresentacionResponse>("apoderamientos", {
      idSolicitud,
      idEstado
    });
    return response.data;
  }

  public async getListado(idTramite: number | null): Promise<ISolicitudRepresentacion[]> {
    const response = await this.axios.get<ISolicitudRepresentacion[]>(`apoderamientos?idTramite=${idTramite}`);

    if (response.status !== 200) {
      throw new Error("Bad response status: " + response.statusText);
    }

    return response.data;
  }

  public async getRepresentados(): Promise<IRepresentado[]> {
    const response = await this.axios.get<IRepresentado[]>(`apoderamientos/representados`);

    if (response.status !== 200) {
      throw new Error("Bad response status: " + response.statusText);
    }

    return response.data;
  }

  public async getEstados(): Promise<IEstadoSolicitud[]> {
    const response = await this.axios.get<IEstadoSolicitud[]>("apoderamientos/estados");

    if (response.status !== 200) {
      throw new Error("Bad response status: " + response.statusText);
    }

    return response.data;
  }

  public async getProcedimientos(): Promise<IProcedimiento[]> {
    const response = await this.axios.get<IProcedimiento[]>("apoderamientos/procedimientos");

    if (response.status !== 200) {
      throw new Error("Bad response status: " + response.statusText);
    }

    return response.data;
  }

  public async getOne(id: number): Promise<ISolicitudRepresentacion> {
    const params = qs.stringify({
      idSolicitud: id,
    });
    const response = await this.axios.get<ISolicitudRepresentacion[]>("apoderamientos/solicitud?" + params, {});

    if (response.status !== 200) {
      throw new Error("Bad response status: " + response.statusText);
    }
    return response.data[0];

  }
  public async solicitudAltaPoder(dto: ISolicitudAltaRepresentacionDto) {
    const response = await this.axios.post<ISolicitudAltaRepresentacionResponse>("apoderamientos", dto);
    return response.data;
  }
}
