import { StyleRules } from "@material-ui/core";
import { Theme } from "@material-ui/core";


const styles = (theme: Theme): StyleRules => ({
    width85: {
        width: '85%'
    },
    // FLEX
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    alignCenter: {
        alignItems: 'center'
    },
    gap: {
        gap: '1rem'
    },
    smallGap: {
        gap: '0.5rem'
    },
    // OTHERS
    bold: {
        fontWeight: 'bold'
    },
    // SPECIFIC
    // containerDomisComp: {
    //     overflowY: 'scroll', 
    //     maxHeight: 300
    // },
    domiComp: {
        display: "flex",
        paddingTop: 25,
        paddingBottom: 25,
        alignItems: "center",
        gap: '1rem',
    },
    progressContainer: {
        display: "flex",
        justifyContent: "center",
        margin: '1rem'
    },
    imagenContainer: {
        width: 40,
        height: 40,
        backgroundColor: '#92b58e',
        borderRadius: '50%',
        alignSelf: 'center',
        [theme.breakpoints.up('md')]: {
            width: 70,
            height: 65,
        }
    },
    titleText:{
        fontSize: '1.5rem',
        fontFamily: "Roboto",
        fontWeight: 400,
        lineHeight: 1.334,
        letterSpacing: '0em',
    },
    marginB15: {
        marginBottom: 15
    }
});

export default styles;