import { Dialog, DialogActions, DialogContentText, DialogTitle, makeStyles, useMediaQuery, Button, Typography, DialogContent } from "@material-ui/core";
import Term from "components/term";
import { FC } from "react";
import { useTheme } from '@material-ui/core';
import styles from "./styles";

const useStyles = makeStyles(styles);

interface IGenericModal {
    children: React.ReactNode;
    open: boolean;
    title?: string;
    componentTitleTranslation?: string;
    isTitleHtml?: boolean;
    disabledCloseBtn?: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
    onClose?: () => void;
}

const GenericModal: FC<IGenericModal> = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        children,
        open,
        title,
        componentTitleTranslation,
        isTitleHtml,
        disabledCloseBtn,
        maxWidth = "md",
        onClose
    } = props;

    const handleTitle = () => {
        if (!title) return;
        else if (isTitleHtml) return <DialogContentText className={classes.titleText} dangerouslySetInnerHTML={{ __html: title }} />;
        else if (title && componentTitleTranslation) return (
                <Typography className={classes.titleText}>
                    <Term component={componentTitleTranslation} text={title} />
                </Typography>
            )
        return;
    };

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                maxWidth={maxWidth}
                fullWidth={true}
                classes={{ paper: classes.paddingRootContainer }}
            >
                {handleTitle()}
                <DialogContent>
                    {/* REMINDER: si necesitamos más botones o no queremos titulo, etc. 
                    No necesitamos aplicarlos, si no pasamos las propiedades indicadas no se pintaran y podemos generar la 
                    modal deseada a partir de la propiedad children */}
                    {children}
                </DialogContent>
                {
                    onClose &&
                    <DialogActions style={{padding: 0}}>
                        <Button
                            onClick={onClose}
                            color="default"
                            variant="outlined"
                            disabled={disabledCloseBtn}
                        >
                            <Term component="Global" text="Cerrar" />
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    );
}

export { GenericModal };