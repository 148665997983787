export function isOnlyNumbers(str: any): boolean {
    if (typeof str == "string")
        return /^[0-9]+$/.test(str);
    return true
}

export function isNumberOrString(value: number | string | undefined) {
    if (typeof value === 'number') {
        return Number.isInteger(value);
    } else if (typeof value === 'string') {
        // Comprueba si el string contiene solo letras
        let isOnlyLetters = /^[A-Za-z ]+$/.test(value);
        // Comprueba si el string contiene solo números
        let isOnlyNumbers = /^[0-9 ]+$/.test(value);
        return isOnlyLetters || isOnlyNumbers;
    }
    return false;
}


export function isAlphanumeric(value: string | undefined): boolean {
    if (value) {
        // Expresión regular para comprobar que la cadena solo contiene letras y/o números
        const alphanumericRegex = /^[A-Za-z0-9 ]+$/;
        return alphanumericRegex.test(value);
    }
    return false;
}


