import { FC, useEffect, useContext, useState, useCallback } from 'react';
import { Dialog, Button, DialogActions, DialogContent, DialogTitle, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import Term from './term';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import IoC from 'contexts/ioc.context';
import imageMensajesAviso from '../resources/MENSAJES_AVISO.png';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    content: {
        margin: '0 20px',
        textAlign: 'justify',
    },
    title: {
        color: '#004f84',
        fontSize: 32,
        fontWeight: 'lighter'
    }
}));

interface IProps {
    open: boolean;
    onClose: () => void;
}


const AvisoAltaDialog: FC<IProps> = ({ open, onClose }) => {
    const ioc = useContext(IoC);
    const [body, setBody] = useState('');
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        onClose();
    };


    const loadTemplate = useCallback(async () => {
        const bodyAux = await contenidoGateway.getContent(
            'recordatorio-alta',
            {}
        );
        setBody(bodyAux[0].contenido);

    }, [contenidoGateway]);

    useEffect(() => {
        loadTemplate();
    }, [loadTemplate]);
    
    return (
        <>
            <Dialog
                open={open}
                fullScreen={fullScreen}
                aria-labelledby="info-dialog-title"
                aria-describedby="info-dialog-description"
            >
                <DialogTitle id="responsive-dialog-title" style={{ marginTop: 20 }} className={classes.content}>
                    <span className={classes.title}><Term component="Global" text="Informacion" /></span>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <img src={imageMensajesAviso} alt="imagenAviso" />
                    <div dangerouslySetInnerHTML={{ __html: body }} />
                </DialogContent>
                <DialogActions className={classes.content} style={{ marginBottom: 20 }}>
                    <Button
                        onClick={handleClose}
                        color="default"
                    >
                        <Term component={'Global'} text={'Cerrar'} />
                    </Button>
                    <Button
                        href="perfil"
                        color="primary"
                    >
                        <Term component={'Global'} text={'Alta'} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default AvisoAltaDialog;