import React, { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo, IConcepto } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent, InputAdornment, TextField} from '@material-ui/core';
import { Grid, Typography, List, Button, Paper, makeStyles } from '@material-ui/core';
import styles from './styles';
import SearchIcon from '@material-ui/icons/Search'
import EditIcon from '@material-ui/icons/Edit'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'

import { conceptoFormatter, evaluateDescripcion, formatXMLtoObject, GenericInitialObject, getEmptyObject, getKeysRequiredCampos, renderCampos, renderConceptos } from '../utils';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { Autocomplete } from '@material-ui/lab';

const EMPTY_CONCEPTO = {
    impUnidad: undefined,
    concepto: undefined,
    descripcio: undefined,
    unidades: undefined,
    impSinIVA: undefined,
}
const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXml: string | null,
    datosXmlFromModelosAction: string | null,
    setFinished: (value: boolean) => void,
    handleSave: (data: GenericInitialObject) => Promise<boolean>,
    labelBtn?: string,
    // indica si se renderiza como selector de concepto previo a inicio de modelo (DWA-15978)
    isDatosPrevios?: boolean; 
    // extrae concepto por si hay que renderizar logos en datos previos (DWA-15978)
    getConcepto?: (v:number|undefined) => void 
}


const Conceptos: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXml,
        datosXmlFromModelosAction,
        setFinished,
        handleSave,
        labelBtn,
        isDatosPrevios=false,
        getConcepto
    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes, setChanges] = useState(false);
    const [campos, setCampos] = useState<ICampo[] | null>(null);
    const [showDatosPrevios, setShowDatosPrevios] = useState(false);
    const [list, setList] = useState<IConcepto[]>([]);
    const [selected, setSelected] = useState<IConcepto|null>(null);
    const [hiddenTree, setHiddenTree] = useState(false);
    const [selectedOptions, setSelectedOptions]= useState<{idPadre: number | null |undefined, id: number, label: string}[]>([]);
    const [EMPTY_CONCEPTO, setEmptyConcepto] = useState<GenericInitialObject| null>(null)
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]>([]);
    const [search, setSearch] = useState<string | undefined>(undefined);
    const [allConcepts, setAllConcepts] = useState<IConcepto[]>([]);
    const [conceptoPadre, setConceptoPadre] = useState<IConcepto| null>(null);

    const [infoAdicional, setInfoAdicional]=useState('')
    
    /** Modelos en los que puede venir una casilla de tipo SoloLectura pero ir ligada
     * a un CONCEPTO de valor variable. Ej: 046 en general son valores de importe por unidad
     * fijo (C23) a excepción de alguna TASA que tiene el falg editableWeb==1 
     * 
     */
    const MODELOS_EVAL_EDITABLE = ['046']
    /**Function to eval C23 */
    const updateCampos = (campos: ICampo[], concepto: IConcepto) => {
        //console.log('update campos')
        const newCampos = campos.map( c => (c.codigo === 'impUnidad' ? {...c, soloLectura: !concepto.editable} : {...c}))
        return newCampos
    }

    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData = ( name: string, value: any) => {
        setChanges(true)
        EMPTY_CONCEPTO && setData(
            data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY_CONCEPTO,[name]: {...EMPTY_CONCEPTO[name], value: value }}
        ) 
    }

    const getTree = async (id: number) => {
        let idToSearch = id;
        const out :{idPadre: number | null |undefined, id: number, label: string}[] = []
        do {
            const c= await modelGateway.getConceptoById(idTipoModelo, idToSearch, translate('Tributos', 'GetConceptoByIdError', terms));
            out.push({idPadre: c.idPadre, id: c.id,label: c.texto});
            if(!c.idPadre){ setConceptoPadre(c) }
            idToSearch = c.idPadre ? c.idPadre : -1;
        } while(idToSearch !== -1)
        //console.log('out',out)
        const end =out.slice().reverse();

        /**Particularidad 060: Guardar el municipio para poder cargar escudos en el report 
         * Si cargamos un árbol con un concepto por defecto hay que verificar que no nos cmabie
         * el municipio. Mirar si los datos tienen el municipio del concepto padre (1º del árbol)
        */
        if(idTipoModelo === '060' && data && 
            (data.locaMuni.value === ''|| !data.locaMuni.value || data.locaMuni.value === end[0].label)
        ){
            updateData('locaMuni',end[0].label)
        }
        
        return end

    }

    const getConceptos = async (modelo: string, idPadre : number | null | undefined) => {
        try {
            setLoading(true)
            let conceptos = []
            if(idPadre){
                // Get conceptos hijos
                conceptos = await modelGateway.getConceptosHijos(modelo, idPadre, translate('Tributos','GetConceptosHijosError', terms), translate('Tributos','concepto' , terms)); 
            } else{
                // Get conceptos padre
                conceptos = await modelGateway.getConceptosPadre(modelo, translate('Tributos','GetConceptosPadreError', terms));
            }
            setLoading(false)
            return conceptos;

        } catch (error) {
            //console.log(' ERROR - getConceptos ---> ', error)  
            const result = (error as Error).message;
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: result, //,
                    variant: 'error',
                }
            });   
            setLoading(false)
            return []
        }
    }

    const updateSelectedOptions = async (item: {idPadre: number | null |undefined, id: number, label: string}, itemIndex: number) => {
        try {
            setLoading(true)
            const newSO = itemIndex > 0 ? selectedOptions.slice(0, itemIndex): []
            setSelectedOptions(newSO)          
            const conceptos = await getConceptos(idTipoModelo, item.idPadre);
            setShowDatosPrevios(false);
            setList(conceptos);
            setSelected(null);
            setChanges(true);
            setLoading(false);

        } catch (error) {
            //console.log(' ERROR - getConceptos ---> ', error)  
            const result = (error as Error).message;
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: result, //translate('Tributos', result , terms),
                    variant: 'error',
                }
            });   
            setLoading(false)
        }
        
    }

    const updateSelectedOptionsFromSearch = (concepto: IConcepto) => {
        (async ()=> {
            // getconceptos padre
            setLoading(true);
            const conceptosPadres = await getTree(concepto.id)
            setSelectedOptions(conceptosPadres);
            const conceptosHijos = await getConceptos(idTipoModelo, concepto.id);
            if (conceptosHijos.length === 0){
                setSelected(concepto)
                setShowDatosPrevios(true)
            }else{
                setSelected(null);
            }
            setList(conceptosHijos);
            setSearch(undefined)
            setLoading(false); 
        })();

    }

    const addSelectedOptions = async (item: IConcepto) => {
        try {
            setLoading(true)
            selectedOptions.push({id: item.id, idPadre: item.idPadre,label: item.texto});
            /**Particularidad 060: Guardar el municipio para poder cargar escudos en el report 
             * El concepto padre lleva esa información 1er concepto seleccionado
            */
            if(selectedOptions.length===1 ){
                setConceptoPadre(item)
                if(idTipoModelo === '060'){updateData('locaMuni',item.texto)} 
            }

            const conceptos = await getConceptos(idTipoModelo, item.id);
            if (conceptos.length === 0){
                setShowDatosPrevios(true);
            }else{
                setSelected(null);
            }
            setList(conceptos);
            setLoading(false);

        } catch (error) {
            //console.log(' ERROR - getConceptos ---> ', error)  
            const result = (error as Error).message;
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: result, //translate('Tributos', result , terms),
                    variant: 'error',
                }
            });   
            setLoading(false)
        }

    }

    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            if(camposRequiredKeys.length>0 ){
                if(data){
                    camposRequiredKeys.forEach( element => {
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element] || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })

                } else {
                    valid = false;
                }
            }

            if(valid && data){
                //Concatenar info adicional en campo descripción(046-060)
                let finalData: GenericInitialObject;
                if(idTipoModelo === '600' && infoAdicional !== ''){
                    finalData = {
                        ...data,
                        nomConcept: {...data.nomConcept, value: [data.nomConcept.value, infoAdicional].join(' \> ') }
                    }
                } else if((idTipoModelo === '060' ||idTipoModelo === '046')&& infoAdicional !== ''){
                    finalData = {
                        ...data,
                        descripcio: {...data.descripcio, value: [data.descripcio.value, infoAdicional].join(' \> ') }
                    }
                }else{
                    finalData={...data}
                }
                //Limitar decimal2 --> 2 decimales
                for (const key in finalData) {
                    const element = finalData[key];
                    if(element.tipo === 'decimal2'){
                        finalData = {
                            ...finalData,
                            [key]: {...finalData[key], value: Number(finalData[key].value).toFixed(2)}
                        }
                    }
                    if(element.tipo === 'decimal3'){
                        finalData = {
                            ...finalData,
                            [key]: {...finalData[key], value: Number(finalData[key].value).toFixed(3)}
                        }
                    }
                }
                //console.log('finalData', finalData)
                const saved = await handleSave(finalData); 
                setChanges(saved ? false: true);
                //saved && setInfoAdicional('');

            } else {

                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos','FormularioError', terms),
                        variant: 'error',
                    }
                });   
            }
            setLoading(false);

        })();
    }
    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if(idTipoApartado!=='' && idTipoModelo!==''){
            (async()=> {
                try{
                    setLoading(true)
                    const allConcepts = await modelGateway.getAllConcepts(idTipoModelo)
                    setAllConcepts(allConcepts)
                    const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                    //await getCamposApartado(idTipoApartado, idTipoModelo, modelGateway, alertsDispatch, translate('Tributos', 'GetCamposApartadoError', terms))
                    //const camposApartado = camposApartado? camposApartado.sort( (a,b) => a.casilla>b.casilla? 1: -1 ): null
                    
                    let emptyObjt = null;
                    let newData: GenericInitialObject | null = null;
                    if(camposApartado && camposApartado?.length>0){
                        emptyObjt= getEmptyObject(camposApartado)
                        //console.log('emptyObjt', emptyObjt)
                        setEmptyConcepto(emptyObjt);
                        const keys = getKeysRequiredCampos(camposApartado);
                        setCamposRequiredKeys(keys)
                        if(datosXml){ 
                            //console.log('USE datosXML')
                            newData = formatXMLtoObject(datosXml,emptyObjt);
                            const tree = await getTree(newData.IdConcepto.value)
                            // Evaluar campo descripción
                            const name= idTipoModelo === '600' ? 'nomConcept':'descripcio';
                            const evaluateDescrip = evaluateDescripcion(newData, name ,tree.length);
                            newData= evaluateDescrip.data;
                            setInfoAdicional(evaluateDescrip.infoAdiocinal);
                            setList([]);
                            setSelectedOptions(tree);
                            setShowDatosPrevios(true);
                            setHiddenTree(true);
                            setChanges(false);
                            setFinished(true);
                        } else {
                            if(datosXmlFromModelosAction){
                                //console.log('USE datosXML Action', datosXmlFromModelosAction)
                                newData = formatXMLtoObject(datosXmlFromModelosAction,emptyObjt);

                                const tree = await getTree(newData.IdConcepto.value)
                                // Evaluar campo descripción
                                const name= idTipoModelo === '600' ? 'nomConcept':'descripcio';
                                const evaluateDescrip = evaluateDescripcion(newData, name ,tree.length);
                                newData= evaluateDescrip.data;
                                setInfoAdicional(evaluateDescrip.infoAdiocinal);
                                setList([]);
                                setSelectedOptions(tree);
                                setShowDatosPrevios(true);
                                setHiddenTree(true);
                            } else { 
                                setList(await getConceptos(idTipoModelo, null));
                                setSelectedOptions([]);
                                setShowDatosPrevios(false);
                                setHiddenTree(false);
                                newData = emptyObjt;
                            }

                            setChanges(true);
                        }
                    }

                    let myConcept: IConcepto| null| undefined = null
                    if(newData && newData['IdConcepto']){
                        const concept = newData['IdConcepto']
                        myConcept = allConcepts.find( c => c.id === Number(concept.value) );
                    }
                    if(MODELOS_EVAL_EDITABLE.includes(idTipoModelo) && camposApartado && myConcept){
                        const campos = updateCampos(camposApartado,myConcept)
                        setCampos(campos)
                    } else {
                        setCampos(camposApartado);
                    }
                    
                    setData(newData); 
                    setLoading(false)
                } catch (error) {
                    const result = (error as Error).message;
                    setLoading(false)
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: result,
                            variant: 'error',
                        }
                    }); 
                }
            })();
        }
    },[idTipoApartado, idTipoModelo, datosXml]);

    useEffect(()=>{
        // DWA-15978: Extraccion de concepto
        if(isDatosPrevios && getConcepto){
            console.log('isDatosPrevios',isDatosPrevios,'data',data)
            // DWA-15978: Solo cuando es seleccion previa de concepto Extraer idConcepto para buscar conselleria
            if(data && data.IdConcepto?.value && hiddenTree ){
                getConcepto(Number(data.IdConcepto.value))
            }else{
                getConcepto(undefined)
            }
        }
    },[data, isDatosPrevios, hiddenTree])

    useEffect(() => {
        if(showDatosPrevios && selected && conceptoPadre){
            if(campos && selected && MODELOS_EVAL_EDITABLE.includes(idTipoModelo)){
                const newCampos= updateCampos(campos,selected);
                setCampos(newCampos); 
            }
            let newData = conceptoFormatter(data ? data: {}, selected, conceptoPadre, idTipoModelo)
            // En 046 y 060 la casilla 22 es una descripción del concepto y su árbol (descripcio)
            // En 600 la casilla 58 es una descripción del concepto y NO tiene su árbol (nomConcept)
            const descripcionSelected = selectedOptions.map(item => item.label)
            //if( idTipoModelo === '600'){
            // newData = {
            //     ...newData,
            //     nomConcept: {...newData.nomConcept, value: descripcionSelected.join(' \> ') }
            // }
            //}
            if(idTipoModelo === '060' || idTipoModelo === '046'){
                newData = {
                    ...newData,
                    descripcio: {...newData.descripcio, value: descripcionSelected.join(' \> ').replace(/(\r\n|\n|\r)/gm, "")} // eliminar saltos de linea 
                }
            }
            setData(newData);
            setSelected(null);
            setHiddenTree(true);           
        }else{
            setFinished(false);
            //setShowSave(false);
        }
    },[showDatosPrevios, selected, conceptoPadre])

    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
    },[changes])

    return(
        <div> 

            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>

            <div>
                {selectedOptions && selectedOptions.length>0 && !hiddenTree &&
                    selectedOptions.map( (item, index) => (
                        <div key={'txt'+ item.id} className={classes.row} style={{ marginLeft: 10*(index+1), marginBottom:10, alignItems: 'flex-start'}}>
                            {index>0 && <SubdirectoryArrowRightIcon></SubdirectoryArrowRightIcon>}
                            <Typography>
                                {item.label} 
                                <Button 
                                    className={classes.outlinedButtonIcon}
                                    variant='text' 
                                    onClick={() => updateSelectedOptions(item, index)}
                                ><EditIcon htmlColor='#004f84' fontSize='small'></EditIcon></Button>
                            </Typography>
                            
                        </div>
                    )) 
                }
            </div>

            {list && list.length>0 &&
                <Autocomplete
                    freeSolo
                    options={allConcepts}
                    getOptionLabel={(option) => {
                        if(option.texto){
                            if(option.codigo && option.identificador && option.idPadre!==null){
                                const importe = option.importe? option.importe+'€' : '0.00€'
                                return option.texto.concat('\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0',option.identificador,'\xa0\xa0\xa0\xa0\xa0\xa0',option.codigo,'\xa0\xa0\xa0\xa0\xa0\xa0',importe)
                            }else{
                                return option.texto
                            }
                        } else{
                            return ""
                        }
                        }}
                    onChange={(e,selected) =>  selected && typeof selected!== 'string' && updateSelectedOptionsFromSearch(selected) }
                    onInputChange={ (e, value) => setSearch(value) }
                    inputValue= {search || ""}
                    renderInput={(params) => (
                        <TextField {...params} 
                            key='infoAdicional'
                            label={translate('Tributos','btnSearch',terms)}  
                            margin="dense" 
                            variant="outlined" 
                            InputProps={{
                                ...params.InputProps, 
                                type: 'search',
                                endAdornment: <InputAdornment position="end"><SearchIcon htmlColor='grey'/></InputAdornment>,
                            }}
                        />
                    )}
                />
            }
            
            {list && list.length>0 &&
                <Paper style={{ boxShadow:'none' }}>
                    <List style={{overflowY: 'auto', maxHeight: 450, width:'auto'}}  >
                        {renderConceptos(list, setSelected, classes, addSelectedOptions, terms, /*idTipoModelo,*/ selected?.id )}
                    </List>
                </Paper>
            }
       
            { showDatosPrevios && data &&
                <>
                    {data.concepto.value ?
                        <>
                            {campos !== null && campos.length>0 && data 
                                ? renderCampos(campos,data, updateData, '', idTipoApartado, undefined, undefined,classes )
                                
                                : <p>NO data</p>//<Term component='Tributos' text='NoDatosPrevios' />
                                
                            }
                            {/**Campo descripción adicional */}
                            <TextField
                                className={ classes['big']  }
                                //style={undefined}
                                //disabled={campo.soloLectura}
                                multiline={true}
                                variant={'outlined'}
                                type={'text'}
                                classes={{
                                    root: classes.root
                                }}
                                //InputProps={undefined}        
                                label={translate('Tributos', `infoAdicional${idTipoModelo}`,terms)}
                                //helperText={""}
                                //error={err || errRegExp || errNif}
                                required={false}
                                name={'infoAdicional'}
                                value={ infoAdicional } 
                                onChange={ (event) => {
                                    setChanges(true)
                                    setInfoAdicional(event.target.value)
                                }}
                            />
                        </>
                    : 
                        data && 
                        <Grid container className={classes.outlinedGrid}>
                            <Typography>{ data.descripcio.value}</Typography>
                        </Grid>
                    }
                        
                    <div className={classes.rowAlignSpaceBetween}>
                        
                        {hiddenTree && 
                            <Button 
                                style={{marginLeft: 10}}
                                color='primary'
                                variant='contained' 
                                onClick={() => {
                                    const index = selectedOptions.length-1
                                    const lastItem = selectedOptions[index]
                                    updateSelectedOptions(lastItem,index)
                                    setHiddenTree(false); 
                                    setShowDatosPrevios(false);
                                    setInfoAdicional('');
                                }}
                            >
                                {translate('Tributos',"btnChangeConcept",terms)}
                            </Button>
                        }
                        {data.concepto.value && 
                            <Button 
                                style={{marginLeft: 20}}
                                color='primary'
                                variant='contained' 
                                disabled={!changes}
                                onClick={onSave}
                            >
                                {translate('Tributos',labelBtn ? labelBtn: "btnSave",terms)}
                            </Button>
                        }
                    </div>
                </>
                
                
            }
    
            {/*showSave && */}
        </div>
    )
}
export default withLiterals(['Tributos'])(Conceptos);
