// LIBRARY IMPORTS
import { useState, FC, useEffect } from 'react';
import { makeStyles, DialogContent, useTheme, DialogActions, Button, CircularProgress, DialogContentText, Typography, ButtonBase } from '@material-ui/core';
import { mdiCheck } from '@mdi/js';
import { Icon } from '@mdi/react';
// LOCAL IMPORTS
import Term from 'components/term';
import { IDomiciliacionCompFVol } from 'gateways/tributos.interfaces';
import ConceptoIcon from 'components/concepto.icon';
import { dateFormat } from 'utils/dates';
import { GenericModal } from 'components/generic-modal';
// STYLES IMPORTS
import styles from './styles';

const useStyles = makeStyles(styles);

interface ISeleccionRegistrosPorFechaVoluntaria {
  domiciliacionesCompatibles: IDomiciliacionCompFVol[];
  loadingDomiciliacionesCompatibles: boolean;
  open: boolean;

  onContinuar: (arrayDomis: IDomiciliacionCompFVol[]) => void;
  onClose: () => void;
}

const DialogSeleccionRegistrosPorFechaVoluntaria: FC<ISeleccionRegistrosPorFechaVoluntaria> = (props) => {
  const { domiciliacionesCompatibles, loadingDomiciliacionesCompatibles, open, onContinuar, onClose } = props;
  // Constantes
  const theme = useTheme();
  const classes = useStyles(theme);
  // States
  const [loading, setLoading] = useState(false);
  const [selectedDomisCompatibles, setSelectedDomisCompatibles] = useState<IDomiciliacionCompFVol[]>([]);

  useEffect(() => {
    setLoading(loadingDomiciliacionesCompatibles);
  }, [loadingDomiciliacionesCompatibles]);

  const handleCheck = (domiCompatible: IDomiciliacionCompFVol) => {
    if (selectedDomisCompatibles.includes(domiCompatible)) {
      setSelectedDomisCompatibles((selectedDomisCompatibles) => selectedDomisCompatibles.filter(domiciliacion => domiciliacion !== domiCompatible));
      return;
    }

    setSelectedDomisCompatibles([...selectedDomisCompatibles, domiCompatible]);
  }

  const handleContinuar = () => {
    onContinuar(selectedDomisCompatibles);
  }

  return (

    <GenericModal
      componentTitleTranslation='Domiciliaciones'
      title="Selección registros por día voluntaria"
      open={open}
    >
      <DialogContentText >
        <Term component="Domiciliaciones" text="Texto info selección registros por día voluntaria" />
      </DialogContentText>

      <DialogContent>
        {loading
          ?
          <div key="infiniteProgressDiasCortesia" className={classes.progressContainer}>
            <CircularProgress size={60} />
          </div>
          :
          domiciliacionesCompatibles.length > 0
            ?
            <div>
              {
                domiciliacionesCompatibles.map((domiComp: IDomiciliacionCompFVol, i: number) => {
                  return (
                    <div key={`${domiComp.idRecibo}${i}`}>
                      <div className={classes.domiComp}>
                        <ButtonBase
                          onClick={() => handleCheck(domiComp)}
                          // TODO: revisar checked style class
                          className={[classes.imagenContainer, selectedDomisCompatibles.includes(domiComp)].join(' ')}
                        >
                          {
                            selectedDomisCompatibles.includes(domiComp)
                              ? <Icon path={mdiCheck} title="Seleccionado" size={1} />
                              : <ConceptoIcon concepto={parseInt(domiComp.cIdTipo)} />
                          }
                        </ButtonBase>

                        <div className={[classes.flexColumn, classes.gap].join(' ')} style={{ width: '90%' }}>
                          <div className={[classes.flexRow, classes.gap].join(' ')}>
                            {/* Descripción */}
                            <div className={[classes.flexRow, classes.alignCenter, classes.smallGap].join(' ')}>
                              <div className={classes.bold}>
                                <Term component="CarteroVirtual" text={domiComp.cDescri} />
                              </div>
                              {domiComp.poblacion &&
                                <Typography className={classes.bold}> - {domiComp.poblacion}</Typography>
                              }
                            </div>
                          </div>

                          <div className={[classes.flexRow, classes.gap].join(' ')}>
                            {/* Nombre */}
                            <div className={[classes.flexRow, classes.alignCenter, classes.smallGap].join(' ')}>
                              <div className={classes.bold}>
                                <Term component='Global' text='Nombre' />:
                              </div>
                              <Typography>{domiComp.nombre}</Typography>
                            </div>

                            {/* NIF */}
                            <div className={[classes.flexRow, classes.alignCenter, classes.smallGap].join(' ')}>
                              <div className={classes.bold}>
                                <Term component='Domiciliaciones' text='NIF' />:
                              </div>
                              <Typography>{domiComp.nif}</Typography>
                            </div>

                            {/* Referencia */}
                            <div className={[classes.flexRow, classes.alignCenter, classes.smallGap].join(' ')}>
                              <div className={classes.bold}>
                                <Term component='Global' text='Referencia' />:
                              </div>
                              <Typography>{domiComp.ref}</Typography>
                            </div>
                          </div>

                          <div className={[classes.flexRow, classes.gap].join(' ')}>
                            {domiComp.dom &&
                              <>
                                {/* Dirección */}
                                <div className={[classes.flexRow, classes.alignCenter, classes.smallGap].join(' ')}>
                                  <div className={classes.bold}>
                                    <Term component='Domiciliaciones' text='Dirección' />:
                                  </div>
                                  <Typography>{domiComp.dom}</Typography>
                                </div>
                              </>
                            }

                            {/* Objeto */}
                            <div className={[classes.flexRow, classes.alignCenter, classes.smallGap].join(' ')}>
                              <div className={classes.bold}>
                                <Term component='Domiciliaciones' text='Objeto' />:
                              </div>
                              <Typography>{domiComp.obj}</Typography>
                            </div>

                            {/* Importe */}
                            <div className={[classes.flexRow, classes.alignCenter, classes.smallGap].join(' ')}>
                              <div className={classes.bold}>
                                <Term component='Domiciliaciones' text='Importe' />:
                              </div>
                              <Typography>{domiComp.importe}</Typography>
                            </div>
                          </div>

                          <div className={[classes.flexRow, classes.gap].join(' ')}>
                            {/* Fecha Inicio */}
                            <div className={[classes.flexRow, classes.alignCenter, classes.smallGap].join(' ')}>
                              <div className={classes.bold}>
                                <Term component='Global' text='Fecha inicio' />:
                              </div>
                              <Typography>{dateFormat(domiComp.fecIni)}</Typography>
                            </div>

                            {/* Fecha Fin */}
                            <div className={[classes.flexRow, classes.alignCenter, classes.smallGap].join(' ')}>
                              <div className={classes.bold}>
                                <Term component='Domiciliaciones' text='Fecha fin' />:
                              </div>
                              <Typography>{dateFormat(domiComp.fecFin)}</Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className={classes.width85} />
                    </div>
                  );
                })}
            </div>
            :
            <Term component='Domiciliaciones' text='Domi voluntaria sin registros compatibles' />
        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="default"
          disabled={loading}
        >
          <Term component="Global" text="Cerrar" />
        </Button>

        <Button variant="outlined" onClick={handleContinuar} color="primary" disabled={loading}>
          {<Term component="Tramites" text="Siguiente" />}
        </Button>
      </DialogActions>
    </GenericModal>
  );
}

export { DialogSeleccionRegistrosPorFechaVoluntaria };