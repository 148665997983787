import React, { FC, useEffect, useState } from "react";
import { Route, RouteProps, RouteComponentProps, withRouter } from "react-router-dom";
import usePage from "hooks/page.hook";
import ProtectedPage from "containers/acceso/protected";
import { tryReadStorageAccesoModulo } from "utils/storage";

interface IProps {
    lockComponent?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    accessLevel?: number;
    idModulos?: number[];
    suplantado?: boolean | null;
}

const ProtectedRoute: FC<IProps & RouteProps & RouteComponentProps> = ({ component, lockComponent, history, accessLevel = 1, idModulos, suplantado, ...rest }) => {
    const [pageState] = usePage();
    const [accessProcedures, setAccessProcedures] = useState<string[] | null>(null);

    useEffect(() => {
        if (idModulos) {
            const accesoModulos = tryReadStorageAccesoModulo("acceso.modulos", localStorage);
            // console.log(idModulos, accesoModulos)
            if (accesoModulos) {
                const modulos = accesoModulos.filter(modulo => idModulos.includes(modulo.idModulo));            
                if (modulos && modulos.length > 0) {
                    const procedimientos = modulos.map(modulo => modulo.procedimientos).flat();
                    setAccessProcedures(procedimientos);
                } else {
                    setAccessProcedures([]);
                }
            }
            
        }
    }, [idModulos]);

    if (accessProcedures === null) 
        return null;

    return (
        <Route
            {...rest}
            render={(props) => {
                if (pageState.jwp === null) {
                    if (accessLevel > 1) {
                        const returnPath = document.location.pathname + document.location.search + document.location.hash;
                        if (pageState.logout)
                        {
                            pageState.logout = false;
                            history.push('/');
                        } else {
                            const clavePath = '/acceso/clave?returnUrl=' + encodeURIComponent(returnPath);
                            history.push(clavePath);
                        }
                        return lockComponent;
                    } else {
                        const returnPath = document.location.pathname + document.location.search + document.location.hash;
                        const loginPath = '/acceso?return=' + encodeURIComponent(returnPath);
                        history.push(loginPath);
                        return lockComponent;
                    }
                } else if (
                    pageState.jwp.representante &&
                    pageState.jwp.procedimientos &&
                    !pageState.jwp.procedimientos.some(procedimiento => accessProcedures.includes(procedimiento))) {
                    history.push('/');
                } else if (pageState.jwp.level < accessLevel) {
                    const returnPath = document.location.pathname + document.location.search + document.location.hash;
                    const clavePath = '/acceso/clave?returnUrl=' + encodeURIComponent(returnPath);
                    history.push(clavePath);
                    return lockComponent;
                } else if (suplantado) {
                    history.push('/');
                }

                return pageState.jwp !== null
                    ? React.createElement(component as any, props)
                    : (lockComponent ? lockComponent : ProtectedPage);
            }} />
    );
};

export default withRouter(ProtectedRoute);