import { FC, useContext } from "react";
import Term from "components/term";
import SelectMultiple from '../../components/select-multiple';
import { FormControl, InputLabel, makeStyles, useTheme, TextField } from "@material-ui/core";
import { FiltersContext } from "./filters.context";
import { FilterDataContext } from "./filter.data.context";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    formControl: {
        margin: `30px 0`,
        [theme.breakpoints.up('md')]: {
            margin: `10px 5px`,
        }
    },
    group: {
        margin: `10px 0`,
    },
    block: {
        display: `inline-block`
    },
    hidden: {
        display: `none`
    },
    middle: {
        verticalAlign: `middle`
    },
}));

export type ScreenType = 'inmuebles' |  'vehiculos' ;

export interface IFiltersFormProps {
    screen: ScreenType;
}

const FiltersForm: FC<IFiltersFormProps> = ({ screen }) => {

    const [filters, filtersDispatch] = useContext(FiltersContext);
    const filterData = useContext(FilterDataContext);

    const handleMatriculaChange = (matricula: string) => filtersDispatch({ type: 'update', filter: 'matricula', value: matricula });
    const handleTipoInmuebles = (tipoInmueble: string[]) => filtersDispatch({ type: 'update', filter: 'tipoInmueble', value: tipoInmueble });
    const handleRefCatastral = (refCatastral: string) => filtersDispatch({ type: 'update', filter: 'refCatastral', value: refCatastral });

    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <div className={classes.root}>
        {
            screen === 'inmuebles'
                ?
                <>
                    <FormControl key='ejercicio' className={filterData && filterData.tipoInmueble ? classes.formControl : classes.hidden} fullWidth>
                        <InputLabel key='label-tipo-inmueble' htmlFor="select-multiple-chip">
                            <Term component="BienesSujeto" text="tipoInmueble" />
                        </InputLabel>
                        <SelectMultiple
                            key='tipoInmueble'
                            className={filterData && filterData.tipoInmueble ? '' : classes.hidden}
                            options={filterData && filterData.tipoInmueble ? filterData.tipoInmueble : []} 
                            value={filters.tipoInmueble || []}
                            onChange={(values: any) => handleTipoInmuebles(values)}
                        />
                    </FormControl>
                    <FormControl key="refCatastral" className={classes.formControl} fullWidth>
                        <TextField
                            key="refCatastral"
                            value={filters.refCatastral || ""}
                            label={<Term component="BienesSujeto" text="refCatastral" />}
                            onChange={(evt) => handleRefCatastral(evt.target.value)}
                        />
                    </FormControl>
                </>
                : 
                <FormControl key="matricula" className={classes.formControl} fullWidth>
                    <TextField
                        key="matricula"
                        value={filters.matricula || ""}
                        label={<Term component="BienesSujeto" text="matricula" />}
                        onChange={(evt) => handleMatriculaChange(evt.target.value)}
                    />
                </FormControl>
            }
        </div>
    );
}

export default FiltersForm;