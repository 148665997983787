import { withStyles, StyleRules, WithStyles } from "@material-ui/styles";
import React from 'react';
import { Theme } from "@material-ui/core/styles";
import Term from "./term";

const styles = (theme: Theme): StyleRules => ({
    text: {
        fontSize: 16,
        textAlign: 'left',
        marginLeft: 30,
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            marginLeft: 0
        }
    },
    imagen: {
        width: 240,
        marginTop: 30
    },
    content: {
        marginTop: 0,
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            marginTop: 15
        }

    },

    background: {
        backgroundColor: 'white',
        paddingTop: 30,
        paddingBottom: 30,
        borderRadius: 8

    },

    hidden: {
        display: 'none',
    },
    [theme.breakpoints.up('md')]: {
        imagen: {
            width: 500,
        },
    }
});

export interface IProps extends WithStyles<typeof styles> {
    component?: string | null;
    text?: string | null;
    image?: any;
    visible: boolean;
}

function NoContent(props: IProps & WithStyles<typeof styles>) {
    const { classes, component, text, image, visible } = props;

    return (
        <>
            <div className={visible ? classes.content : classes.hidden}>
                <div className={classes.background}>
                    <p className={classes.text}>
                        {
                            // component ? text : 'No hay contenido que mostrar'
                            component && text ? <Term component={component} text={text} /> : 'No hay contenido que mostrar'
                        }
                    </p>
                </div>
                {image &&
                    <img className={classes.imagen} src={image} alt="imagen pantalla" />
                }
            </div>

        </>
    )
}

export default withStyles(styles)(NoContent);