import React, { useCallback, useState } from 'react'

type RippleInfo = {
    key: number;
    style: Record<string, unknown>;
}

export default function useRipple(style?: Record<string, unknown>) {
    const [ripples, setRipples] = useState<RippleInfo[]>([])

    const showRipple: React.MouseEventHandler = useCallback((event) => {
        const { left, top } = event.currentTarget.getBoundingClientRect()
        const x = event.clientX - left
        const y = event.clientY - top
        const rippleSize = Math.min(event.currentTarget.clientHeight, event.currentTarget.clientWidth)

        const newRipple = {
            key: event.timeStamp,
            style: {
                display: 'block',
                width: rippleSize,
                height: rippleSize,
                position: 'absolute',
                left: x - rippleSize / 4,
                top: y - rippleSize / 4,
                background: 'currentColor',
                borderRadius: '50%',
                opacity: 0.4,
                pointerEvents: 'none',
                animationName: 'useRippleAnimation',
                animationDuration: '0.7s',
                ...style
            }
        }

        setRipples(state => [...state, newRipple])
    }, [style]);

    const ripplesArray = ripples.map(currentRipple => {
        const handleAnimationEnd = () => {
            setRipples(state => state.filter(previousRipple => previousRipple.key !== currentRipple.key))
        }

        return <span {...currentRipple} onAnimationEnd={handleAnimationEnd} />
    })

    return [showRipple, ripplesArray] as [
        React.MouseEventHandler,
        JSX.Element[]
    ];
}