import React from "react"
import imageLogo from '../../resources/logo.png';
import imageFIN from '../../resources/dusti/finalizada.png';

import { translate } from "utils/i18n"
import { Paper, Grid, Typography } from "@material-ui/core"
import { BLUE_BORRADOR } from "containers/shared/colors"
import { renderApartado, renderApartadoText } from "./shared";
import HeaderJustificanteBorrador from "components/DUTI/header-justificante-borrador";


type TValuesJxx =  {term: string, value: any, fullWidth?:boolean}[]
export type TJustificanteAEAT = Record<'APART_1'|'APART_2'| 'APART_3', TValuesJxx> 


export const renderJAEAT = (
    justificante: TJustificanteAEAT, 
    terms:any, 
    componentTerms: string 
) => {

    return (
        <Paper elevation={5} style={{margin: 20, minHeight: 1500}}>
            <Grid container direction='column'  style={{padding: 20}}>
                <HeaderJustificanteBorrador
                    componentTerms={componentTerms}
                    docTypeTerm="borr_justif_dusti"
                    terms={terms}
                    titleTerm={`borr_aeat_title`}
                />
                
                <Grid item container direction='column'>
                    {(Object.keys(justificante) as (keyof TJustificanteAEAT)[]).map( (apartado) => {  
                        return(
                            <Grid item>
                                { justificante[apartado] !== null &&
                                    <Typography style={{backgroundColor: BLUE_BORRADOR, color: 'white',marginTop: 10, padding: '2px 5px 2px 5px',display:'flex', flex:1}}>
                                        {translate(componentTerms,`aeat_${apartado}_title`,terms).toUpperCase()}
                                    </Typography>
                                }
                                <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap'}} >
                                    {apartado=== "APART_1" ?
                                        renderApartadoText(justificante[apartado], 'aeat', terms, componentTerms)
                                    :   
                                        <Grid container direction="row" >
                                            <Grid item xs={1} style={{padding: 10}} >
                                                {apartado === 'APART_2' && <img width={70}  height={70} src={imageFIN} alt='logo' />}
                                            </Grid>
                                            <Grid item xs={11} style={{padding: 10}}>
                                                {renderApartado(justificante[apartado] as {term: string, value:any,}[], 'aeat', terms, componentTerms)}
                                            </Grid>
                                        </Grid> 
                                    }
                                    
                                </div>
                            </Grid>
                        )
                    })}
                    
                </Grid>
            </Grid>
        </Paper>
)
}