import React, { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning"

import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import ModelGateway from "gateways/model.new.gateway";
import DUTIGateway from "gateways/duti.gateway";

import INIimg from 'resources/dusti/iniciada.png';
import FINimg from 'resources/dusti/finalizada.png';

import plv_styles from './styles';
import StepSchema from "../shared/stepSchema";
import { IParamStepper } from "../shared/types";
import { TCalcPlusvalia, TComprador, TOperacion, TTipoModeloPLV, TVendedor } from "./types";
import CalcPlusvaliaForm from "components/Modelos/componentes/calcPlusvaliaForm";
import { TSelect } from "containers/DUTI/utils";
import ExpandPanel from "components/DUTI/expand-panel";
import { cloneDeep } from "lodash";
import EndProcessMSG from "components/Modelos/componentes/endProcessMSG";
import { ContenidosGateway } from "gateways/contenido.gateway";
import { TTipoInmueble } from "gateways/catastro.interface";


const getInitialCalcPlusvalia = (vend: TVendedor[], condicionSujPasivo: boolean, compr: TComprador[] ) => {
    
    const nifNoCondSuj= compr.length === 1 ? compr[0].nif : undefined
    const out = vend.map( v=> {
        return ({
            nifVendedor: v.nif,
            sujetoPasivo: condicionSujPasivo,
            liquidacionMultiple: false,
            calcPLV:[ 
                {   nifSujetoPasivo: condicionSujPasivo === true ? v.nif : nifNoCondSuj,
                    calculos: []
                }
            ]
        } as TCalcPlusvalia)
    })
    return out
}



interface ICalcPlusvaliaProps extends IParamStepper, WithStyles<typeof plv_styles> {
    apartado: string;
    orden:number;
}
const CalcPlusvalia: FC<ICalcPlusvaliaProps> = ({
    classes, apartado, orden, idRef, isFirst,isLast, handleBack,handleExit,handleNext
}) => {
    const componentMounted = useRef(true);
    const terms = useContext(LiteralsContext);
    // const [pageState, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    
    //Gateways
    const ioc = useContext(IoC);
    const modelosGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const contenidoGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);

    //Locals
    const [loading, setLoading] = useState(false)
    const [haveChanges, setHaveChanges] = useState(false)

    const [compradoresList,setCompradoresList] = useState<TSelect[]>([])
    const [vendedores,setVendedores] = useState<TVendedor[]>([])
    const [tipoModelo,setTipoModelo]=useState<TTipoModeloPLV| undefined>(undefined)

    const [calcPlusval, setCalcPlusval] = useState([] as TCalcPlusvalia[])
    const [fechaTransmision, setFechaTransmision] = useState(new Date())
    const [valorDeclarado,setValorDeclarado]= useState<number| undefined>(undefined)

    const [onEditIdx, setOnEditIdx]= useState(-1)

    const [tipoBien, setTipoBien]= useState<TTipoInmueble|undefined>(undefined)
    const [MSGtipoBien_RU, setMSGtipoBien_RU]= useState<string|undefined>(undefined)
    // MEMOS -------------------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------------------------
    
   // Validaciones a REVISAR  
   // (tipoBien === "RU" && element.tipoDerecho !== undefined))


    // FUNCIONES----------------------------------------------------------------------------------------------
    const disabledNext = useMemo(() => {
        const allOK = calcPlusval.map(element => {
            const allNifs= element.calcPLV.map( c => c.nifSujetoPasivo!== undefined)
            const allCalculos= element.calcPLV.map( c => c.calculos.length >0)
            return (
                !allNifs.includes(false) && !allCalculos.includes(false)
            )
               
        });
        return allOK.includes(false) || tipoModelo === undefined
    },[calcPlusval,tipoModelo])
    // -------------------------------------------------------------------------------------------------------


   
    // GESTIÓN NAV Step --------------------------------------------------------------------------------------
    const onExit = async  (cause:'continuar-mas-tarde'|'force')  => {
        try {
            // validate if pending changes - MSG - save info ¿?
            if(haveChanges && cause === 'continuar-mas-tarde'){
                await modelosGateway.saveStepAsistente(idRef, apartado, orden, {listCalcPlusval: calcPlusval})              
            }
            handleExit()
        } catch(err) {
            alertsDispatch({
                type: "show-alert",
                payload: {
                    message: translate('Plusvalias','error_save_continuar_mas_tarde', terms),
                    variant: "warning",
                    hasCustomAction: true,
                    defaultCloseActionTemplateName: translate('Global','no',terms),
                    actionTemplateName: translate('Global','si',terms),
                    handleCustomAction: () => {
                        alertsDispatch({ type: 'hide-alert' })
                        handleExit()
                    }
                },
            });
        }
    }

    const onBack = () => {
        handleBack()
    }

    const onNext = async() => {
        try {
            setLoading(true)              
            const result = haveChanges ? await modelosGateway.saveStepAsistente(idRef, apartado, orden,{listCalcPlusval: calcPlusval}) : {ok:true, idRef: idRef}
            if(result.ok){
                setLoading(false)
                setHaveChanges(false)
                // si todo OK
                //console.log('goNext ---- > from CalcPlusvalias')
                handleNext()
            }  
        } catch(err) {
            setLoading(false)
            const error = err as Error
            const errMSG = translate('Plusvalias', error.message, terms);
            const cause= error.cause ?? "";
            alertsDispatch({
                type: "show-alert",
                payload: {
                message: errMSG.concat(`. ${cause}`),
                variant: "error",
                },
            });
        }
    }
    // -------------------------------------------------------------------------------------------------------


    // EFECTS ------------------------------------------------------------------------------------------------

    useEffect(() => {
        (async()=> {
            try {
                setLoading(true)
                const prevInfo = await modelosGateway.getAllApartadosModeloAsistente('PLV',idRef)
                const MSGtipoBien_RU = await contenidoGateway.getContent('plusvalias_exencion_RU', {})
                const condSujPasivo = prevInfo.preguntasPlusvalia ? (prevInfo.preguntasPlusvalia.sujetoPasivo ?? true) : true
                // Calls - await 
                if(componentMounted.current){
                    //setters
                    setMSGtipoBien_RU(MSGtipoBien_RU[0].contenido)
                    setCompradoresList(prevInfo.compradores && condSujPasivo === false 
                        ? prevInfo.compradores.map(c => ({id: c.nif, nombre: `${c.nomApe} - ${c.nif}`})) 
                        : [])
                    setVendedores(prevInfo.vendedores? prevInfo.vendedores : [])
                    const tipoMod=prevInfo.preguntasPlusvalia && prevInfo.preguntasPlusvalia.tipoPlusvaliaModelo ? prevInfo.preguntasPlusvalia.tipoPlusvaliaModelo.id as TTipoModeloPLV : undefined
                    setTipoModelo(tipoMod)
                    setTipoBien(prevInfo.preguntasPlusvalia?.inmueble?.tipoBien)
                    if(prevInfo && prevInfo.infoTransmPlusvalia && prevInfo.infoTransmPlusvalia.fecha){
                        setFechaTransmision(prevInfo.infoTransmPlusvalia.fecha)
                        setValorDeclarado(prevInfo.infoTransmPlusvalia.valorDeclarado)

                    }   
                    if(prevInfo && prevInfo.calcPlusvalia){
                        setCalcPlusval(prevInfo.calcPlusvalia)
                        setHaveChanges(false)
                    } else {
                        setCalcPlusval(getInitialCalcPlusvalia(prevInfo?.vendedores ?? [], condSujPasivo, prevInfo.compradores ?? [] ))
                        setHaveChanges(true)
                    }
                    
                    
                }

            } catch (err){
                const msg= (err as Error).message
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Plusvalias', msg, terms),
                        variant: 'error',
                    }
                });
            } finally {
                setLoading(false)
            }
        })();

        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    },[])

    return (
        <StepSchema idRef={idRef} isFirst={isFirst} isLast={isLast} onNext={onNext} onBack={onBack} onExit={onExit} disabledNext={disabledNext}>
            {tipoModelo === undefined && !loading?
                <EndProcessMSG componentIG="Plusvalias" termIG="lost_tipo_modelo"/>
            :
            <>
                {calcPlusval.map( (item,idx) => {
                    const allNifs= item.calcPLV.map( c => c.nifSujetoPasivo!== undefined)
                    const allCalculos= item.calcPLV.map( c => c.calculos.length >0)
                    const estado = !allNifs.includes(false) && !allCalculos.includes(false) && idx !== onEditIdx ? 'FIN' : 'PEN'
                    const vendedor = vendedores.find(vend => vend.nif === item.nifVendedor);
                    return (
                        <ExpandPanel
                            key={item.nifVendedor} 
                            withDelete={false}
                            initialExpand={(idx === 0) || (idx===onEditIdx)}
                            onDelete={() => {}}
                            renderHeader={() => (
                                <Grid item container direction="row" justify="space-between" alignItems="center" style={{marginLeft:10}} >
                                    <Grid item md={6} lg={7} className={classes.row} >
                                        <Typography>{item.nifVendedor}</Typography>
                                        <Typography style={{marginRight: 10, marginLeft: 10}}>{'-'}</Typography>
                                        <Typography>{vendedor?.nomApe}</Typography>
                                    </Grid>
                                    
                                    <Grid item md={3} lg={3} className={classes.row} style={{alignItems: 'center', justifyContent: 'flex-end'}}>
                                        <Typography style={{marginRight: 10}}>{translate('DUTI',`plusval_vend_datos_${estado}`,terms)}</Typography>
                                        <img width={30} height={30} className={classes.image} alt={'estado'} 
                                            src={estado==='FIN' ? FINimg : INIimg}
                                        />
                                        {  onEditIdx === idx && 
                                            <div style={{ marginLeft:10}}>
                                                <WarningIcon/> 
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            )}
                            renderContent={() => { 
                                return (
                                    <>
                                        <CalcPlusvaliaForm
                                            renderType= {onEditIdx === idx ? 'form' : 'detail' }
                                            initialValue={item}
                                            onChange={ (v: TCalcPlusvalia, action: 'save'|'cancel') => {
                                                if(action == 'save'){
                                                    const newV = cloneDeep(calcPlusval)
                                                    newV[idx]=v 
                                                    setCalcPlusval(newV)
                                                    setHaveChanges(true)
                                                    //console.log('CalcPlusvaliaForm -> Have changes onChange')
                                                }
                                                //console.log('setIDX -1')
                                                setOnEditIdx(-1)
                                            }}
                                            idRef={idRef}
                                            apartado={apartado}
                                            infoVend={{
                                                nif: vendedor?.nif ?? "", 
                                                nomApe: vendedor?.nomApe ??""
                                            }}
                                            fechaTransmision={fechaTransmision}
                                            valorDeclarado={valorDeclarado}
                                            compradoresList={compradoresList}
                                            tipoModelo={tipoModelo}
                                            tipoBien={tipoBien}
                                            tipoBienMSG={MSGtipoBien_RU}
                                        />
                                        {onEditIdx !== idx &&
                                            <div>
                                                <Button variant="contained" color='primary' 
                                                    onClick={() =>{
                                                        if(onEditIdx !== -1){
                                                            const infoV= vendedores.find(v=> v.nif ===calcPlusval[onEditIdx].nifVendedor)
                                                            alertsDispatch({
                                                                type: 'show-alert',
                                                                payload: {
                                                                    message: translate('Plusvalias', 'have_not_finish_calc_plusv', terms,[`${infoV?.nif} - ${infoV?.nomApe}`]),
                                                                    variant: 'warning',
                                                                }
                                                            });

                                                        } else {
                                                            setOnEditIdx(idx)
                                                        }
                                                    }}
                                                >
                                                    {translate('Global', estado==='PEN' ? 'btnComplete' : 'btnEdit', terms)}
                                                </Button>
                                            </div>
                                        }
                                    </>
                                )
                            }}
                        />
                        
                    )
                })}
            </>
            }
        </StepSchema>
    )
}

export default withLiterals(["Plusvalias","Global"])(withStyles(plv_styles)(CalcPlusvalia))