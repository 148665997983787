import { FC, useContext, useEffect, useMemo, useState } from "react";

import IoC from "contexts/ioc.context";
import { GeoGateway } from "gateways/geo.gateway";

import { LiteralsContext } from "containers/shared/literals";
import {  Grid, WithStyles, withStyles } from "@material-ui/core";
import InputText from "./input-text";
import duti_styles from "containers/DUTI/styles";
import InputNumber from "./input-number";


import { Provincia, Sigla} from 'gateways/geo.interface';
import { REGEX_CP, TSelect, TSelectDUTI, formatSelectDUTIOptions, formatSelectOptions } from "containers/DUTI/utils";



import InputSelect from "./input-select";
import { IDUTIMunicipio } from "gateways/duti.interface";
import DUTIGateway from "gateways/duti.gateway";
import { translate } from "utils/i18n";
import { TExtraValidation } from "./types";
import { TDirectionATIB } from "./direction-form-ATIB";
import { CustomError } from "utils/custom.error";
import { AlertsContext } from "contexts/alerts.context";
import usePage from "hooks/page.hook";

export type TDirectionFlags = Record<keyof TDirection, boolean>
export type TDirection = {
    provincia: TSelect | undefined,
    municipio: TSelectDUTI | undefined,
    cp: string | undefined,
    tipoVia: TSelect| undefined,
    nombreVia: string| undefined,
    numero: number| undefined,
    letra: string| undefined,
    escalera: string| undefined,
    piso: string| undefined,
    puerta: string| undefined,
}

export const EMPTY_DIRECTION: TDirection| TDirectionATIB = {
    cp:undefined,
    escalera:undefined,
    letra:undefined,
    municipio:undefined,
    nombreVia:undefined,
    numero:undefined,
    piso:undefined,
    provincia: undefined, 
    puerta:undefined,
    tipoVia:undefined
}

export const EMPTY_DIRECTION_ERRORs: TDirectionFlags = {
    cp:false,escalera:false,letra:false,municipio:false,nombreVia:false,numero:false,piso:false,provincia:false,puerta:false,tipoVia:false
}


type ObtainKeys<T, V> = keyof {
    [K in keyof T as T[K] extends V ? K : never]: any
}
type ObtainKeysSelect = 'provincia' | 'municipio'| 'tipoVia'

export const ALL_DIRECTION_DISABLED: Record<keyof TDirection, boolean>= {
    cp: true,
    provincia: true,
    municipio: true,
    tipoVia: true,
    nombreVia: true,
    numero: true,
    letra: true,
    escalera: true,
    piso: true,
    puerta: true
}
export const DEFAULT_REQUIRED_DIRECTION: TDirectionFlags = {
    provincia:true,
    municipio:true,
    tipoVia:true,
    nombreVia:true,
    numero:true,
    piso:false,
    puerta:false,
    escalera:false,
    letra:false,
    cp:true,
}

export const EMPTY_DISABLED: TDirectionFlags= {
    cp: false,
    provincia: false,
    municipio: false,
    tipoVia: false,
    nombreVia: false,
    numero: false,
    letra: false,
    escalera: false,
    piso: false,
    puerta: false
}
interface IDirectionForm extends WithStyles {
    direction: TDirection;    
    dirErrors: TDirectionFlags;    
    disabled?: TDirectionFlags;
    required?: TDirectionFlags;
    setDirection: (d:TDirection) => void;
    setDirErrors: (dErr: TDirectionFlags) => void;
    setShouldBlock: (v: boolean) => void;
};

const DirectionForm : FC<IDirectionForm> = ({classes, direction, dirErrors, disabled, required={...DEFAULT_REQUIRED_DIRECTION}, setDirection, setDirErrors, setShouldBlock}) => {
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    //Gateways
    const ioc = useContext(IoC);
    const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);

    // Local 
    const [, setUpdateMunicipiosList] = useState(false);

    const [options, setOptions] = useState<Record<ObtainKeysSelect,TSelect[] | TSelectDUTI[]>>({
        municipio: [],
        provincia: [],
        tipoVia: []
    })

    
    const handleChangeTextInput = (value: string| undefined, field: ObtainKeys<TDirection, string | undefined>, err:boolean) => {
        //console.log('handleChangeTextInput => ', field, ': ', value, ' ERR:', err)
        setDirErrors({...dirErrors, [field]: err})
        setDirection({...direction, [field]: value});
    }

    const handleChangeNumberInput = (value: number| undefined, field: ObtainKeys<TDirection, number | undefined>, err:boolean) => {
        //console.log('handleChangeNumberInput => ', field, ': ', value, ' ERR:', err)
       
        setDirErrors({...dirErrors, [field]: err})
        setDirection({...direction, [field]: value});
    }

    const handleChangeSelectInput = (value: TSelect | undefined, field: ObtainKeysSelect, err:boolean) => {
        setDirErrors({...dirErrors, [field]: err})
        setDirection({...direction, [field]: {...value}});
        if(field === 'provincia' && direction.provincia?.id !== value?.id){
            setUpdateMunicipiosList(true);
        }
    }

    const validateCP = (cp:string) => {
        const isOK = REGEX_CP.test(cp)
        return({error: isOK ? false: true, error_msg: translate('DUTI','dir_cp_error', terms)} as TExtraValidation)
    }
    
    useEffect(() => {
        (async () => {
            try {
                const optionsProvincias = await geoGateway.getProvincias('')
                const IB = optionsProvincias.find(p => p.idProvincia === '07') ?? { idProvincia: '07', nombre: 'ILLES BALEARS'}
                const optionsTipoViaBBDD= await geoGateway.getSiglas()
                const optionsTipoVia= optionsTipoViaBBDD.map(item => ({...item, nombre: translate('GLOBAL',item.nombre,terms)}))

                const optionsMunicipios= await dutiGateway.getAllMunicipios()
                setOptions(opt => ({...opt, 
                    provincia: formatSelectOptions<Provincia>('idProvincia','nombre',[IB]), // SOLO admite Illes balears
                    municipio: formatSelectDUTIOptions<IDUTIMunicipio>('id','Codigo','Nombre',optionsMunicipios),
                    tipoVia: formatSelectOptions<Sigla>('idSiglas','nombre', optionsTipoVia)
                }));

                if(direction.provincia === undefined){
                    setDirection({...direction, provincia: IB ? {id: IB.idProvincia, nombre: IB.nombre}: undefined});
                }
            } catch (error) {
                if (error instanceof CustomError && error.code === 403) {
                    setShouldBlock(false);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Global', 'sesion_caducada', terms),
                            variant: 'warning',
                            hasCustomAction: true,
                            handleCustomAction: () => { 
                                pageDispatcher({ type: "logout" });
                            }
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('DUTI','error_dusti', terms),
                            variant: "error"
                        }
                    });
                }
            }
        })();
    }, [])
    
    /** En la DUSTI No aplica ya que es un listado fijo de municipio */
    // useEffect(() => {
    //     if(updateMunicipiosList && direction.provincia !== undefined){
    //         //const provinciaID = direction.provincia.id || '07'; // 07 => Baleares
    //         (async () => {
    //             const optionsMunicipios= await dutiGateway.getAllMunicipios()
    
    //             setOptions({...options, 
    //                 municipio: formatSelectDUTIOptions<IDUTIMunicipio>('id','Codigo','Nombre',optionsMunicipios)
    //             })
    //             setUpdateMunicipiosList(false)
                    
    //         })();
    //     }
    // }, [updateMunicipiosList, direction.provincia])


    return(
        <Grid container direction="column" spacing={0}>
            <Grid item container direction="row">
                <InputSelect 
                    fieldTerm='dir_provincia'
                    value={direction.provincia}
                    options={options.provincia}
                    onChangeValue={(v: TSelect | undefined,err: boolean) => handleChangeSelectInput(v, 'provincia', err)}
                    required={required.provincia}
                    disabled={disabled?.provincia}
                    className={classes.input360}
                    error={dirErrors.provincia}
                />

                <InputSelect 
                    disabled={!direction.provincia || disabled?.municipio}
                    fieldTerm='dir_municipio'
                    value={direction.municipio}
                    options={options.municipio}
                    onChangeValue={(v: TSelect | undefined,err: boolean) => handleChangeSelectInput(v, 'municipio', err)}
                    required={required.municipio}
                    error={dirErrors.municipio}
                />

            </Grid>
            <Grid item container direction="row" alignItems="flex-start">
                <InputSelect 
                    fieldTerm='dir_tipoVia'
                    value={direction.tipoVia}
                    options={options.tipoVia}
                    onChangeValue={(v: TSelect | undefined,err: boolean) => handleChangeSelectInput(v, 'tipoVia', err)}
                    //className={classes.input160}
                    disabled={disabled?.tipoVia}
                    error={dirErrors.tipoVia}
                    required={required.tipoVia}
                />
                <InputText 
                    fieldTerm='dir_nombreVia' 
                    value={direction.nombreVia} 
                    onChangeValue={(v: string, err: boolean) => handleChangeTextInput(v,'nombreVia',err)} 
                    required={required.nombreVia}
                    disabled={disabled?.nombreVia}
                    className={classes.input360}
                    error={dirErrors.nombreVia}
                    forceUpperCase={true}
                />
                <InputNumber 
                    fieldTerm='dir_numero' 
                    value={direction.numero} 
                    onChangeValue={(v: number, err:boolean) => handleChangeNumberInput(v,'numero',err)} 
                    required={required.numero}
                    disabled={disabled?.numero}
                    min={0}
                    maxDecimal={0}
                    className={classes.input100}
                    error={dirErrors.numero}
                />
                <InputText 
                    fieldTerm='dir_escalera' 
                    value={direction.escalera} 
                    onChangeValue={(v: string, err: boolean) => handleChangeTextInput(v,'escalera',err)} 
                    disabled={disabled?.escalera}
                    className={classes.input100}
                    error={dirErrors.escalera}
                    required={required.escalera}
                />
                <InputText 
                    fieldTerm='dir_letra' 
                    value={direction.letra} 
                    onChangeValue={(v: string, err: boolean) => handleChangeTextInput(v,'letra',err)} 
                    disabled={disabled?.letra}
                    className={classes.input70}
                    error={dirErrors.letra}
                    required={required.letra}
                />
                <InputText 
                    fieldTerm='dir_piso' 
                    value={direction.piso} 
                    onChangeValue={(v: string, err: boolean) => handleChangeTextInput(v,'piso',err)} 
                    disabled={disabled?.piso}
                    className={classes.input70}
                    error={dirErrors.piso}
                    required={required.piso}
                />
                <InputText 
                    fieldTerm='dir_puerta' 
                    value={direction.puerta} 
                    onChangeValue={(v: string, err: boolean) => handleChangeTextInput(v,'puerta',err)} 
                    disabled={disabled?.puerta}
                    className={classes.input100}
                    error={dirErrors.puerta}
                    required={required.puerta}
                />

                <InputText 
                    fieldTerm='dir_cp' 
                    value={direction.cp} 
                    onChangeValue={(v: string, err: boolean) => handleChangeTextInput(v,'cp',err)} 
                    disabled={disabled?.cp}
                    className={classes.input160}
                    extraValidation={validateCP}
                    error={dirErrors.cp}
                    required={required.cp}
                />
                
                
            </Grid>

        </Grid>
    );

}

export default withStyles(duti_styles)(DirectionForm)