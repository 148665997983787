import React, { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { mdiWalletTravel } from "@mdi/js";
import { Card, CardContent, WithStyles, withStyles } from "@material-ui/core";

import styles from "./styles";
import usePage from "hooks/page.hook";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import FullLoading from "components/full-loading";
import IoC from "contexts/ioc.context";
import { AbonoNacimientoGateway } from "gateways/tramites/abonoNacimiento.gateway";
import imageNoHay from '../../../resources/no-deuda.png';
import NoContent from "components/no-content";
import { ISolicitud } from "gateways/tramites/abonoNacimiento.interfaces";
import InfiniteScrollLoader from "components/infinite-scroll-loader";
import InfiniteScroll from "react-infinite-scroller";

import { AlertsContext } from "contexts/alerts.context";
import CardSolicitudListItem from "./cards/card-solicitud-list-item";
import { RouteComponentProps } from "react-router-dom";
import ModalFirmarSolicitud from "./modals/modal-firmar-solicitud";
import ModalPagarSolicitud from "./modals/modal-pagar-solicitud";
import { download } from "utils/download";
import justificante from "containers/cita-previa/justificante";



type IProps = RouteComponentProps & WithStyles<typeof styles>  &  {} 

const AbonoNacimientoConsulta : FC<IProps> = ({classes, history}) => {

    const terms = useContext(LiteralsContext);
    const componentMounted = useRef(true);

    // Gateways
    const ioc = useContext(IoC);
    const abonoNacimientoGateway: AbonoNacimientoGateway = useMemo(() => ioc.get(AbonoNacimientoGateway), [ioc]);   
    
    const [pageState, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    
    const [loading, setLoading] = useState(false);
    const [solicitudes, setSolicitudes] = useState<ISolicitud[]>([]);
    // declaraciones que se muestran 
    const [, setSolicitudesList] = useState<ISolicitud[]>([]);
    // totalCount
    const solicitudesCount = useMemo(() => solicitudes.length,[solicitudes])
    const [page,setPage] = useState(0);
    const numberElementsPerPage = 50;
    const hasMore = useMemo(() => (
        solicitudesCount > (page === 0 ? (page + 1) : page) * numberElementsPerPage
    ), [page, solicitudesCount]);

    const [showFirma, setShowFirma] = useState(false)
    const [infoFirma, setInfoFirma] = useState<ISolicitud | null>(null)
    // const [infoPagar, setInfoPagar] = useState<ISolicitud | null>(null)



    // Functions --------------------------------------------------------------------------
    const cargarListaSolicitudes = async (nif: string | undefined) => {
        try {
            setLoading(true)
            let list: ISolicitud[] = []
            if(nif){
                list = await abonoNacimientoGateway.getSolicitudesByNif()
            }
            setSolicitudes(list)
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('AbonoNacimiento','errorSolicitudesList', terms),
                    variant: "error"
                }
            });
        } finally {
            setLoading(false)
        }
    }

    // Futurible integración - firma ClaveFirma ---- 
    const handleFirma = (info: ISolicitud) => {
        setInfoFirma(info)
        setShowFirma(true)
    }

    const onCloseFirma =async  (info: ISolicitud | null, updated:boolean)  => {
        if(updated){
            await cargarListaSolicitudes(pageState.jwp?.nif)
        }
        setInfoFirma(null);
        setShowFirma(false)
    }

    const handlePrint= async(info: ISolicitud, tipo: 'justificante' | 'resolucion') => {
        if(tipo === 'justificante'){
            await handlePrintJustificante(info)
        } else{
            await handlePrintResolucion(info)
        }
    }
    /** Firma y descraga el documento+Modelo */
    const handlePrintJustificante = async (info: ISolicitud) => {
        try {
            setLoading(true);
            const result = await abonoNacimientoGateway.getJustificantSolicitud(info.idSolicitud);
            const ok = download(result, alertsDispatch,
                translate('AbonoNacimiento', 'No es posible descargar el justificante de la solicitud', terms),
                translate('Global', 'BloqueoPantalla', terms));
            if(ok){
                await cargarListaSolicitudes(pageState.jwp?.nif)
            }
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload:{
                    message: translate('AbonoNacimiento', 'No es posible descargar el justificante de la solicitud', terms),
                    variant:'error'
                }
            })
        } finally {
            setLoading(false);
        }
        
    };

    const handlePrintResolucion = async (info: ISolicitud) => {
        try {
            setLoading(true);
            const result = await abonoNacimientoGateway.getResolucionDegacionSolicitud(info.idSolicitud);
            const ok = download(result, alertsDispatch,
                translate('AbonoNacimiento', 'No es posible descargar el justificante de la solicitud', terms),
                translate('Global', 'BloqueoPantalla', terms));
           
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload:{
                    message: translate('AbonoNacimiento', 'No es posible descargar el documento de resolución', terms),
                    variant:'error'
                }
            })
        } finally {
            setLoading(false);
        }
        
    };

    const handlePagar = async (info: ISolicitud) => {
        try{
            setLoading(true)
            const tokenPago = await abonoNacimientoGateway.getURLPago(info.idSolicitud, info.idModelo)
            console.log('tokenPago' , tokenPago)
            if(tokenPago !== null){
                history.push(`/pasarela-pago/pago/${tokenPago}`, {from: 'ABO_NAC', idSolicitud: info.idSolicitud});
            }
            
        } catch (err){
            alertsDispatch({
                type: 'show-alert',
                payload:{
                    message: translate('AbonoNacimiento', 'ErrorTokenPago', terms),
                    variant:'error'
                }
            })
        } finally{
            setLoading(false)
        }
    }

    


    // Gestion Infinite scroll -------------------------------------------------------
    const loadPageDeclaraciones = (filters: undefined, page:number) => {
        const calcInitIndex = page * numberElementsPerPage
        const declaracionesNextPage = solicitudes.slice(calcInitIndex, numberElementsPerPage)
        setSolicitudes(declaracionesNextPage)
    }
    const loadMore = (page: number)=> {
        if(hasMore){
            const nextPage= page+1
            setPage(nextPage)
            loadPageDeclaraciones(undefined, nextPage)
        }
    }
    // End Infinite scroll -----------------------------------------------------------

  
    // Effects ----------------------------------------------------------------------
    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiWalletTravel,
                title: translate("Global","AbonoNacimiento", terms),
                text: translate("Global","AbonoIRPFNacimientoIBConsulta_DESCRIPCION", terms),
                moreInfoTemplate: 'ABO_NAC_consulta_mas_info',
                moreInfoTemplateSize: 'lg'
            },
            menu: true,
        });
    }, [pageDispatcher]);


    useEffect(() => {
        (async() => {
            await cargarListaSolicitudes(pageState.jwp?.nif)
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    }, [pageState])


    return (
        <div className={classes.root}>
            <FullLoading loading={loading} />
            <NoContent
                image={imageNoHay}
                visible={solicitudesCount === 0 }
                component='AbonoNacimiento'
                text={'tituloCardConsulta_NoContent'}
            />
            { solicitudesCount > 0 && 
            <div style={{display:'flex', flexDirection: 'column', flexGrow:1}}>
                <Card className={classes.cardContadorContainer}>   
                    <CardContent className={classes.cardContador} >
                        <span className={classes.titleCardContador}>
                            {translate("AbonoNacimiento" ,'tituloCardConsulta', terms ,[solicitudesCount])}
                        </span>
                    </CardContent>
                </Card>
                
                <div style={{marginTop: 10}}>
                    <InfiniteScroll 
                        pageStart={0}
                        initialLoad={false}
                        loadMore={loadMore}
                        hasMore={hasMore}
                        loader={ <InfiniteScrollLoader show={hasMore}/> }                     
                    >
                        {solicitudes.map(sol => (
                            <CardSolicitudListItem info={sol} handlePrint={handlePrint} handleFirma={handleFirma} handlePagar={handlePagar}/>
                        ))}
                        
                        
                    </InfiniteScroll>
                </div>
            </div> 
            }
            
            {/**TODO refresh lista */}
            {/* <ModalFirmarSolicitud show={showFirma} info={infoFirma} onClose={onCloseFirma}/> */}


        </div>
    )
}

export default  withLiterals(['Global','Tramites','AbonoNacimiento'])(withStyles(styles)(AbonoNacimientoConsulta)); 
