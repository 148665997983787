import React, { useMemo, useState, useContext, useCallback, ChangeEvent } from 'react';
import { Button, Grid, IconButton, InputLabel, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ICajaProps } from './caja.props';
import Term from 'components/term';
import DocumentoNotarioDialog from './documentacionNotario-modal';
import { IDatosDocumentoNotario } from 'gateways/tramites.interfaces';
import LabelField from 'components/label-field';
import IoC from 'contexts/ioc.context';
import { TramitesGateway } from 'gateways/tramites.gateway';
import usePage from 'hooks/page.hook';
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import { DocumentsUtils } from 'utils/documents';
import { AlertsContext } from 'contexts/alerts.context';
import moment from 'moment';
import { MoreInfoContext } from 'contexts/more_info.context';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';
import { height } from '@fortawesome/free-solid-svg-icons/faFilter';
import Icon from '@mdi/react';
import { mdiEyeCircleOutline, mdiPlus, mdiTrashCan, mdiPaperclip } from '@mdi/js';
import { validateNif } from "utils/validateNif";

const useStyles = makeStyles({
    root: {
        marginLeft: 45,
    },
    rootDusti:{
        marginLeft: 0,
    },
    documentoEncontrado: {
        marginLeft: 45,
    },
    documentoEncontradoDusti: {
        marginLeft: 0,
    },
    buttonList: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 15
    },
    button: {
        width: 200
    },
    marginButton: {
        marginRight: 15,
        marginLeft: 20,
        height: 'fit-content'
    },
    formAttachFiles : {
        marginTop: 50,
        marginLeft: 45
    },
    marginTop: {
        marginTop: 10
    },
    inlineFlex: {
        display: 'inline-flex',
        alignItems: 'center',
        marginTop: 20
    },
    italicText: {
        fontSize:'0.875rem', 
        paddingRight: 5, 
        fontStyle: 'italic'
    },
});
const PRIMARY_BLUE = '#2196f3';

export interface IFileAttachDto {
    protocolo: string | undefined,
    ejercicio: string | undefined,
    fechaOtorgamiento: Date | undefined,
    nifNotario: string | undefined,
    nombreNotario: string | undefined,
    descripcion: string | undefined
};

export interface IFileAttachErrorDto {
    protocolo: string | undefined,
    ejercicio: string | undefined,
    fechaOtorgamiento: string | undefined,
    nifNotario: string | undefined,
    nombreNotario: string | undefined,
    descripcion: string | undefined
}

const DocumentacionNotario: React.FC<ICajaProps> = ({ onChange, detalle }) => {
    const classes = useStyles();
    console.log(detalle)
    const [documento, setDocumento] = useState<IDatosDocumentoNotario | null>(detalle != null ? detalle.escrituraPublica : null);
    const [open, setOpen] = useState(false);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [busquedaNRD, setBusquedaNRD] = useState(true);
    const showSearcher = useMemo(() => !!!detalle || !!!detalle.escrituraPublica || !!!detalle.escrituraPublica.nrd, [detalle]);
    const isDUSTI = useMemo(() => detalle.isDUSTI, [detalle]);

    const ioc = useContext(IoC);
    const gateway = ioc.get(TramitesGateway) as TramitesGateway;
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [fileAttachDto, setFileAttachDto] = useState<IFileAttachDto>({
        protocolo: undefined,
        ejercicio: undefined,
        fechaOtorgamiento: new Date(),
        nifNotario: undefined,
        nombreNotario: undefined,
        descripcion: undefined
    });
    const [fileAttachErrorDto, setFileAttachErrorDto] = useState<IFileAttachErrorDto>({
        protocolo: undefined,
        ejercicio: undefined,
        fechaOtorgamiento: undefined,
        nifNotario: undefined,
        nombreNotario: undefined,
        descripcion: undefined
    }) 
    const [loadingFile, setLoadingFile] = useState(false);

    const handleChangeDocumento = (documentoAux: IDatosDocumentoNotario) => {
        setDocumento(documentoAux);
        onChange({ ...detalle, escrituraPublica: documentoAux })
    }

    const handleDesvincular = async () => {
        if (detalle !== null && detalle.idExpediente != null ) {
            try {
                await gateway.desvincularEscrituraPublica(detalle.idExpediente);
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Tramites', 'Se ha desvinculado el documento', terms),
                        variant: 'success',
                    }
                });
                setDocumento(null);
                onChange({
                    ...detalle, escrituraPublica: {
                        ...detalle.escrituraPublica, nrd: null
                    }
                })
            } catch (error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tramites', (error as any).message, terms),
                        variant: 'error',
                    }
                });
            }
        }
    }

    const handleVerDocumento = async () => {
        if (documento !== null && documento.documento != null) {
            try {
                DocumentsUtils.downloadDoc(documento.documento.idDocumento);
            } catch (error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tramites', (error as any).message, terms),
                        variant: 'error',
                    }
                });
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpenNRD = () => {
        setBusquedaNRD(true);
        setOpen(true);
    }

    const handleOpenDocumento = () => {
        setBusquedaNRD(false);
        setOpen(true);
    }

    const handleShowMasInfo = useCallback(() => {
        handleInfoDispatch(infoDispatch, 'show-info', 'mas_info_escritura_publica' )
    },[infoDispatch]);

    const handleChangeFileToAttachData = (property:string, value: any) => {
        setFileAttachDto((prev: any) => ({
            ...prev,
            [property]: value
        }))
        setFileAttachErrorDto((prev: any) =>({
            ...prev,
            [property]: undefined
        }));
        if (property == 'nifNotario') {
            setFileAttachDto((prev: any) =>({
                ...prev,
                ['nombreNotario']: undefined
            }));
        }
    };

    const handleAddDocument = async (event: ChangeEvent<HTMLInputElement>) => {
        setLoadingFile(true);
        try {
            const id = await gateway.subirDocumentos(event.target.files);
            const response: IDatosDocumentoNotario = await gateway.asociarNuevoDocumentoToExpediente(id, fileAttachDto.descripcion||'', detalle.idExpediente, fileAttachDto);
            setDocumento({...response});
            onChange({ ...detalle, escrituraPublica: {...response} });
            setFileAttachDto((prev) => ({ ...prev,
                protocolo: undefined,
                ejercicio: undefined,
                fechaOtorgamiento: new Date(),
                nifNotario: undefined,
                nombreNotario: undefined,
                descripcion: undefined
            }));
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Tramites', (error as any).message, terms),
                    variant: 'error',
                }
            });
        } finally {
            setLoadingFile(false);
        }
    }

    const handleFileAttachDtoDataIsValid = (event: any) => {
        if (!fileAttachDtoDataIsValid()) {
            event.preventDefault();
        }
    }
    const fileAttachDtoDataIsValid = ():boolean => {
        let isValid = true;
        setFileAttachErrorDto({
            protocolo: undefined,
            ejercicio: undefined,
            fechaOtorgamiento: undefined,
            nifNotario: undefined,
            nombreNotario: undefined,
            descripcion: undefined
        });
        if (fileAttachDto) {
            if (!fileAttachDto.protocolo) {
                isValid = false;
                handleChangeFileToAttachErrorData('protocolo', translate('Tramites', 'errorDatoRequerido', terms))
            } else if (isNaN(+fileAttachDto.protocolo)) {
                isValid = false;
                handleChangeFileToAttachErrorData('protocolo', translate('Tramites', 'errorDatoIncorrecto', terms))
            }
            if (!fileAttachDto.ejercicio) {
                isValid = false;
                handleChangeFileToAttachErrorData('ejercicio', translate('Tramites', 'errorDatoRequerido', terms))
            } else if (isNaN(+fileAttachDto.ejercicio) || fileAttachDto.ejercicio.length != 4) {
                isValid = false;
                handleChangeFileToAttachErrorData('ejercicio', translate('Tramites', 'errorDatoIncorrecto', terms))
            }
            if (!fileAttachDto.fechaOtorgamiento) {
                isValid = false;
                handleChangeFileToAttachErrorData('fechaOtorgamiento', translate('Tramites', 'errorDatoRequerido', terms))
            }
            if (!fileAttachDto.nifNotario) {
                isValid = false;
                handleChangeFileToAttachErrorData('nifNotario', translate('Tramites', 'errorDatoRequerido', terms))
            } else if (!validateNif(fileAttachDto.nifNotario)) {
                isValid = false;
                handleChangeFileToAttachErrorData('nifNotario', translate('Tramites', 'errorDatoIncorrecto', terms))
            }
            if (!fileAttachDto.nombreNotario) {
                isValid = false;
                handleChangeFileToAttachErrorData('nombreNotario', translate('Tramites', 'errorDatoRequerido', terms))
            }
            if (!fileAttachDto.descripcion) {
                isValid = false;
                handleChangeFileToAttachErrorData('descripcion', translate('Tramites', 'errorDatoRequerido', terms))
            }
        } else {
            isValid = false;
        }
        return isValid;
    }

    const handleChangeFileToAttachErrorData = (property:string, value: any) => {
        setFileAttachErrorDto((prev: any) => ({
            ...prev,
            [property]: value
        }))
    };

    const handleCheckIfNotarioExist = async(value: string) => {
        const atibUsuario = await gateway.getNotarioByNif(value);
        if (atibUsuario && atibUsuario.idUsuario != '') {
            handleChangeFileToAttachData('nombreNotario', atibUsuario.nombreNotario)
        }
    }
    return (
        <>
        {
            showSearcher
            ?
            <>
                <div className={isDUSTI ? classes.rootDusti: classes.root}>
                    <small>
                        <b>
                            <Term component='Tramites' text='elegirBusquedaDocumento' />
                        </b>
                    </small>
                    <div className={classes.buttonList}>
                        <Button disabled={detalle.estado.id === 'ANU'} variant="outlined" color="primary" size="small" className={classes.button} onClick={handleOpenDocumento}>
                            <Term component='Tramites' text='datosDocumento' />
                        </Button>
                        <Button disabled={detalle.estado.id === 'ANU'} variant="outlined" color="primary" size="small" className={[classes.button, classes.marginButton].join(' ')} onClick={handleOpenNRD}>
                            <Term component='Tramites' text='introducirNrd' />
                        </Button>
                    </div>
                </div>
                <DocumentoNotarioDialog
                    idExpediente={detalle.idExpediente}
                    onChange={handleChangeDocumento}
                    onClose={handleClose}
                    open={open}
                    busquedaNRD={busquedaNRD}
                />
            </>
            :
            (
                <>
                    {
                        documento !== null
                            ?
                            <div className={ isDUSTI?  classes.documentoEncontradoDusti: classes.documentoEncontrado}>
                                <Grid container>
                                    <Grid item xs={12} md={2}>
                                        <LabelField
                                            label={<Term component="Tramites" text="NRD" />}
                                            text={documento.nrd}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <LabelField
                                            label={<Term component="Tramites" text="numProtocolo" />}
                                            text={documento.protocolo}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <LabelField
                                            label={<Term component="Tramites" text="ejercicio" />}
                                            text={documento.ejercicio}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <LabelField
                                            label={<Term component="Tramites" text="fecha" />}
                                            text={documento.fechaOtorgamiento ? moment(documento.fechaOtorgamiento).format('DD/MM/YYYY') : ''}
                                        />
                                    </Grid>
                                    {
                                        documento.notario != null
                                            ?
                                            <>
                                                { documento.notario.nif != '00000000T' &&
                                                <Grid item xs={12} md={2}>
                                                    <LabelField
                                                        label={<Term component="Tramites" text="nifNotario" />}
                                                        text={documento.notario.nif}
                                                    />
                                                </Grid>
                                                }
                                                <Grid item xs={12} md={2}>
                                                    <LabelField
                                                        label={<Term component="Tramites" text="nombreNotario" />}
                                                        text={documento.notario.nombre}
                                                    />
                                                </Grid>
                                            </>
                                            :
                                            null
                                    }
                                </Grid>
                               { !isDUSTI ?
                                <div className={classes.buttonList}>
                                        {
                                            detalle.estado.id !== 'PRE'
                                                ?
                                                <Button variant="outlined" color="primary" size="small" className={classes.button} onClick={handleDesvincular}>
                                                    <Term component='Tramites' text='desvincular' />
                                                </Button>
                                                :
                                                null
                                        }
                                        <Button variant="outlined" color="primary" size="small" className={[classes.button, classes.marginButton].join(' ')} onClick={handleVerDocumento}>
                                            <Term component='Tramites' text='verDocumento' />
                                        </Button>
                                    </div>
                                    : null
                                }
                            </div>
                            :
                            null
                    }
                </>
            )
        }
        {/* Comentado por el hotfix DWA-14907*/}
        {/*detalle.estado.id != 'PRE' &&
            <div className={classes.formAttachFiles}>
                <div className={classes.inlineFlex}>
                    <small>
                        <b>
                            <Term component="Tramites" text="nrdCaducadoyOtros" />
                        </b>
                    </small>
                    <Button variant="outlined" color="primary" size="small" id="masInfo" className={[classes.button, classes.marginButton].join(' ')} onClick={handleShowMasInfo}>
                        <Term component='Global' text='Mas informacion' />
                    </Button>
                </div>
                <div  className={classes.marginTop}>
                    <Grid container spacing={1} className={classes.inlineFlex}>
                        <Grid item xs={12} md={2}>
                            <TextField
                                required
                                value={fileAttachDto.protocolo ?? ''}
                                id="protocolo"
                                label={<Term component="Tramites" text="numProtocolo" />}
                                style={{ margin: 8 }}
                                fullWidth
                                margin="normal"
                                onChange={(event) => {handleChangeFileToAttachData('protocolo',event.target.value)}}
                                error={!!fileAttachErrorDto.protocolo}
                                helperText={fileAttachErrorDto.protocolo}
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <TextField
                                required
                                value={fileAttachDto.ejercicio ?? ''}
                                id="ejercicio"
                                label={<Term component="Tramites" text="Ejercicio" />}
                                style={{ margin: 8 }}
                                fullWidth
                                margin="normal"
                                onChange={(event) => {handleChangeFileToAttachData('ejercicio',event.target.value)}}
                                error={!!fileAttachErrorDto.ejercicio}
                                helperText={fileAttachErrorDto.ejercicio}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <KeyboardDatePicker
                                required
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                style={{ margin: 8 }}
                                id="fechaOtorgamiento"
                                label={<Term component="Tramites" text="fechaOtorgamiento" />}
                                value={fileAttachDto.fechaOtorgamiento ?? undefined}
                                onChange={(event) => {handleChangeFileToAttachData('fechaOtorgamiento',event)}}
                                error={!!fileAttachErrorDto.fechaOtorgamiento}
                                helperText={fileAttachErrorDto.fechaOtorgamiento}
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <TextField
                                required
                                value={fileAttachDto.nifNotario ?? ''}
                                id="nifNotario"
                                label={<Term component="Tramites" text="nifNotario" />}
                                style={{ margin: 8 }}
                                fullWidth
                                margin="normal"
                                onChange={(event) => {handleChangeFileToAttachData('nifNotario',event.target.value.toString().toUpperCase())}}
                                onBlur={(event) => {handleCheckIfNotarioExist(event.target.value.toString().toUpperCase())}}
                                error={!!fileAttachErrorDto.nifNotario}
                                helperText={fileAttachErrorDto.nifNotario}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                required
                                value={fileAttachDto.nombreNotario ?? ''}
                                id="nombreNotario"
                                label={<Term component="Tramites" text="nombreNotario" />}
                                style={{ margin: 8 }}
                                fullWidth
                                margin="normal"
                                onChange={(event) => {handleChangeFileToAttachData('nombreNotario',event.target.value.toString().toUpperCase())}}
                                error={!!fileAttachErrorDto.nombreNotario}
                                helperText={fileAttachErrorDto.nombreNotario}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                required
                                value={fileAttachDto.descripcion ?? ''}
                                id="descripcion"
                                label={<Term component="Tramites" text="descripcion" />}
                                style={{ margin: 8 }}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 115 }}
                                onChange={(event) => {handleChangeFileToAttachData('descripcion',event.target.value)}}
                                error={!!fileAttachErrorDto.descripcion}
                                helperText={fileAttachErrorDto.descripcion}
                            />
                        </Grid>
                        <>
                            <input
                                accept=".pdf"
                                style={{ display: 'none' }}
                                id={'file-field'}
                                type="file"
                                onClick={(e) => handleFileAttachDtoDataIsValid(e)}
                                onChange={(e) => handleAddDocument(e)}
                            />
                            <label htmlFor={'file-field'}>
                                <Button component="span" variant="outlined" 
                                    color="primary" size="small" 
                                    className={[classes.button, classes.marginButton].join(' ')}
                                    startIcon={ <Icon path={mdiPaperclip} size={0.8} color={PRIMARY_BLUE} /> }>
                                    <Term component='Tramites' text='adjuntar' />
                                </Button>
                            </label>
                        </> 
                    </Grid>
                </div>
            </div>
        */}
        </>
    );
}

export default DocumentacionNotario;