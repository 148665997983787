export interface ISelectionState<TItemType> {
    selected: TItemType[];
}

export type SelectionAction<TItemType> =
    | { type: 'clear' }
    | { type: 'select' | 'unselect', item: TItemType | TItemType[] }

export interface ISelectionReducerConfig<TItemType> { isSelectable?: (state: ISelectionState<TItemType>, itemOrItems: TItemType | TItemType[]) => TItemType | TItemType[] };

export type ISelectionReducerType<TItemType> = (state: ISelectionState<TItemType>, action: SelectionAction<TItemType>) => ISelectionState<TItemType>;

export type ISelectionReducerConfigType<TItemType> = (config: ISelectionReducerConfig<TItemType>) => ISelectionReducerType<TItemType>;

function SelectionReducer<TItemType>(config: ISelectionReducerConfig<TItemType>) {

    const reducer: ISelectionReducerType<TItemType> = (state: ISelectionState<TItemType>, action: SelectionAction<TItemType>) => {
        switch (action.type) {
            case 'select':
                const selectable = config.isSelectable ? config.isSelectable(state, action.item) : action.item;
                return selectable ? { ...state, selected: state.selected.concat(selectable instanceof Array ? selectable : [selectable]) } : state;
            case 'unselect':
                return { ...state, selected: state.selected.filter(x => 'splice' in action.item ? action.item.findIndex(z => z !== x) >= 0 : x !== action.item) }
            case 'clear':
                return { ...state, selected: [] };
            default: throw new Error('Unexpected action');
        }
    }

    return reducer;
};

export default SelectionReducer;