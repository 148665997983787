import React, { useState, useContext, useEffect, ChangeEvent, useMemo } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, useTheme, makeStyles, List, ListItem, ListItemText, ListItemSecondaryAction, CircularProgress, Button, Paper, TableContainer, Typography, Grid } from '@material-ui/core';
import { ICajaProps } from './caja.props';
import Term from 'components/term';
import { IDocumentoTipo, IComponenteTipoExpediente } from 'gateways/tramites.interfaces';
import IoC from 'contexts/ioc.context';
import { TramitesGateway } from 'gateways/tramites.gateway';
import usePage from 'hooks/page.hook';
import { LiteralsContext } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import { mdiEyeCircleOutline, mdiPlus, mdiTrashCan, mdiPaperclip } from '@mdi/js';
import Icon from '@mdi/react';
import { DocumentsUtils } from 'utils/documents';
import { AlertsContext } from 'contexts/alerts.context';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 45,
    },
    rootDusti: {
        marginLeft: 0,
    },
    cardDocumentos: {
        display: 'flex'
    },
    starIcon: { 
        marginRight: 2
    },
    text: {
        fontSize:'0.875rem', 
        paddingRight: 5
    },
    italicText: {
        fontSize:'0.875rem', 
        paddingRight: 5, 
        fontStyle: 'italic'
    },
    inlineFlex: {
        display: "inline-flex", 
        alignItems: 'center'
    }
}));
const PRIMARY_BLUE = '#2196f3';


const DocumentosAdicionales: React.FC<ICajaProps> = ({ onChange, detalle, tipo }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const ioc = useContext(IoC);
    const gateway = ioc.get(TramitesGateway) as TramitesGateway;
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [subiendoFichero, setSubiendoFichero] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const isDUSTI = useMemo(() => detalle.isDUSTI ,[detalle])

    const handleAddDocument = async (event: ChangeEvent<HTMLInputElement>, nombre: string) => {
        setSubiendoFichero(true);
        try {
            const ids = await gateway.subirDocumentos(event.target.files);
            const response = await gateway.asociarDocumento(ids, nombre, detalle.idExpediente);
            for (const x of response) {
                detalle.documentos.push(x);
            }
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: translate('Tramites', 'Se han subido correctamente los documento', terms),
                    variant: 'success',
                }
            });
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Tramites', (error as any).message, terms),
                    variant: 'error',
                }
            });
        } finally {
            setSubiendoFichero(false);
        }

    }
    const [listadoTipoComponentes, setListadoTipoComponentes] = useState<IComponenteTipoExpediente>();
    useEffect(() => {
        const t = tipo.componentes.find(x => x.tipo.nombre === 'docs_adicionales');
        if (t) {
            setListadoTipoComponentes(t);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDesvincular = async (idDocumento: string) => {
        if (detalle !== null) {
            setLoadingDelete(true);
            try {
                await gateway.desvincularDocumentos(detalle.idExpediente, idDocumento);
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: translate('Tramites', 'Se ha desvinculado el documento', terms),
                        variant: 'success',
                    }
                });
                onChange({ ...detalle, documentos: detalle.documentos.filter((m: IDocumentoTipo) => m.documento.idDocumento !== idDocumento) })
                setLoadingDelete(false);
            } catch (error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tramites', (error as any).message, terms),
                        variant: 'error',
                    }
                });
                setLoadingDelete(false);
            }
        }
    }

    const handleVerDocumento = async (idDocumento: string) => {
        if (idDocumento !== null) {
            try {
                DocumentsUtils.downloadDoc(idDocumento);
            } catch (error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tramites', (error as any).message, terms),
                        variant: 'error',
                    }
                });
            }
        }
    }

    return (
        <>
            <div className={ isDUSTI ? classes.rootDusti  : classes.root }>
                {
                    loadingDelete || subiendoFichero
                        ?
                        <CircularProgress size={25} />
                        :
                        null
                }
                <div className={classes.cardDocumentos}>
                    {
                        detalle.estado.id !== 'PRE'
                            ?
                            <>
                            {listadoTipoComponentes !== undefined
                                ?
                                <Grid container direction='column' >
                                    {listadoTipoComponentes.documentos.map((tipos: any, key: number) => {
                                        const attached = detalle.documentos.find(d => d.tipo === tipos.nombre)
                                        console.log('attached: ', attached)
                                        return (
                                            <Grid 
                                                key={key + '_' + tipos.idTipoExpedienteComponenteDocumento} 
                                                item 
                                                container 
                                                direction='row' 
                                                alignItems='center'
                                                style={{minHeight: 41}}
                                            >
                                                <Grid item style={{ paddingTop: 12, paddingBottom: 12, paddingRight: 12, paddingLeft: isDUSTI ? 0 : 12 }}>
                                                    <Typography className={classes.text}>
                                                        {<Term component="Tramites" text={tipos.nombre} />}
                                                    </Typography>
                                                </Grid>
                                                { attached 
                                                    ?   
                                                    <Grid item className={classes.inlineFlex} >
                                                        <Typography className={classes.italicText}>
                                                            {attached.documento.nombre}
                                                        </Typography>
                                                        <IconButton 
                                                            aria-label="ver" 
                                                            disabled={loadingDelete} 
                                                            onClick={() => handleVerDocumento(attached.documento.idDocumento)}
                                                        >
                                                            <Icon path={mdiEyeCircleOutline} size={1} color={PRIMARY_BLUE} />
                                                        </IconButton>
                                                        <IconButton 
                                                            aria-label="delete" 
                                                            disabled={loadingDelete} 
                                                            onClick={() => handleDesvincular(attached.documento.idDocumento)}
                                                        >
                                                            <Icon path={mdiTrashCan} size={1} color='red' />
                                                        </IconButton>
                                                    </Grid>
                                                    :
                                                    <Grid item>
                                                        <input
                                                            accept="image/*,.pdf"
                                                            style={{ display: 'none' }}
                                                            id={`file-field${tipos.idTipoExpedienteComponenteDocumento}`}
                                                            type="file"
                                                            multiple
                                                            onChange={(e) => handleAddDocument(e, tipos.nombre)}
                                                        />
                                                        <label htmlFor={`file-field${tipos.idTipoExpedienteComponenteDocumento}`}>
                                                            <Button component="span" variant="outlined" 
                                                                color='primary'
                                                                size="small"
                                                                classes={{startIcon: classes.starIcon}}
                                                                style={{marginRight:0}}
                                                                startIcon={ <Icon path={mdiPaperclip} size={0.8} color={PRIMARY_BLUE} /> }
                                                            >
                                                                {<Term component="Tramites" text="adjuntar " />}
                                                            </Button>
                                                        </label>
                                                    </Grid>
                                                }
                                            </Grid>
                                        )})
                                    }
                                    </Grid>
                                : null
                            }
                            </>
                            :
                            <Table aria-label="simple table" size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left"><Term component="Tramites" text="Nombre" /></TableCell>
                                        <TableCell align="left"><Term component="Tramites" text="Tipo" /></TableCell>
                                        <TableCell align="left" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>{
                                    detalle.documentos.map((documento: IDocumentoTipo, key: number) => (
                                        <TableRow key={key + '_' + documento.idExpedienteDocumento}>
                                            <TableCell align="left">{documento.documento.nombre}</TableCell>
                                            <TableCell align="left">{documento.tipo}</TableCell>
                                            <TableCell align="left">
                                                <IconButton aria-label="ver" onClick={() => handleVerDocumento(documento.documento.idDocumento)}>
                                                    <Icon path={mdiEyeCircleOutline}
                                                        size={1}
                                                        color={PRIMARY_BLUE} //'blue'
                                                    />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                    }

                </div>
            </div>
        </>
    );
}

export default DocumentosAdicionales;