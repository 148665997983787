import {FC, useCallback, useContext, useEffect, useState} from "react";

import { IconButton, withStyles, WithStyles, TextField, TextFieldProps } from "@material-ui/core";
import { translate } from "utils/i18n";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import { Icon } from 'atomic/atoms/icon/icon';
import styles,{ colors } from "./styles";
import { MoreInfoContext } from "contexts/more_info.context";
import { TInfoTemplateSize } from "contexts/more_info.reducer";


export type TExtraValidation = {
    error:boolean, 
    error_msg: string | undefined,
    componentIG?:string
}
type TMSGError = 'err_required' 

type TInput = Pick<TextFieldProps, 'className' | 'variant' | 'size' | 'margin'|'error' | 'multiline'|'rows' | 'rowsMax' |'placeholder'> & WithStyles
interface IGenericInputText extends TInput {
    labelTerm: string,
    value: string,
    onChangeValue: (v: string|undefined, err: boolean) => void,
    required: boolean | undefined,
    disabled?:boolean
    extraValidation?: (v: string|undefined) => TExtraValidation,
    forceUpperCase?:boolean,
    fullWidth?:boolean,
    errorMsgExterno?:string,
    moreInfo?: {
        template: string, size?: TInfoTemplateSize
    },
    componentIG:string
};


const GenericInputText : FC<IGenericInputText>= ({
    classes, labelTerm, value, onChangeValue,
    required = false,
    disabled,
    extraValidation,
    error,errorMsgExterno,
    variant='outlined', size='small', margin='dense',className , forceUpperCase=false,fullWidth=false,multiline=false,
    moreInfo,componentIG,
    ...props
}) => {
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [err, setErr] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState< string|undefined>(undefined)

    const validate = (value: string | undefined, withExtravalidation:boolean) => {
        let msg : TMSGError|undefined = undefined
        
        const requiredErr = required && (value === undefined || value === '' ||(value && value.replace(/\s+/g, '').length === 0)) ? true : false
        if(requiredErr) msg='err_required'
        let result: TExtraValidation= {error: false,error_msg: undefined,}
        if( !requiredErr &&  extraValidation && withExtravalidation && value !== undefined && value !== ''){
            result = extraValidation(value)
        }


        setErrMsg(msg ? translate("GenericComponents", msg,terms) : translate(result.componentIG ?? "GenericComponents", result.error_msg ?? 'Ooops !!',terms)  )
        setErr( requiredErr || result.error )
        return  (requiredErr || result.error )
    }

    useEffect(() => {
        // setea el error que me envían desde fuera
        if(error !== undefined){ 
            setErr(error)
            if(errorMsgExterno && error ===true){ setErrMsg(errorMsgExterno)}
            if(error ===false){ setErr(false) }
        }
        if(value){validate(value,true)}
    }, [error, errorMsgExterno, value])

    const handleShowMoreInfo = useCallback(() => {
        if (moreInfo) {
            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: moreInfo.template ?? '',
                    size:moreInfo?.size ?? undefined,
                }
            });
        }
    }, [infoDispatch])
    
    return (
        <div style={{display:'flex', flexDirection: 'row', alignItems:'flex-start', marginRight: 10, flexGrow: fullWidth ? 1: undefined, width: fullWidth ? '100%':'default' }} >
            <TextField {...props}
                className={ fullWidth ? classes.inputFullWidth : (className ?? classes.inputDefault)}
                size={size}
                margin={margin}
                variant={variant}
                type={'text'}
                multiline={multiline}
                label={translate(componentIG,labelTerm, terms).concat(required? '*':'')} 
                disabled={disabled}
                value={value ?? ''} 
                onChange={(e) => { 
                    const newVal = forceUpperCase && e.currentTarget.value 
                        ? e.currentTarget.value.toUpperCase()
                        : e.currentTarget.value 
                    const err = validate(newVal,false)
                    onChangeValue(newVal, err)
                }} 
                error={err}
                helperText={ err ? (errMsg ?? ' ') : ' '}
                
                onBlur={() => {
                    const err = validate(value, true)
                    err && onChangeValue(value, err)
                }}
                InputLabelProps={{classes: {
                    root: classes.inputLabel,
                }}}
            />

            {moreInfo && 
                <IconButton onClick={handleShowMoreInfo}>
                    <Icon name={'info-outline'} size={1} color={colors.blueIconInfo}/>
                </IconButton>
            }
            
        </div>
           
    )
}

export default withLiterals(ALL_LITERALS)(withStyles(styles)(GenericInputText));