import { Card, Collapse, Divider, makeStyles } from '@material-ui/core';
import Icon from 'atomic/atoms/icon';
import React, { useCallback, useState } from 'react';
import { IFAQ } from './detail-faqs';

const useStyles = makeStyles(() => ({
    pregunta: {
        padding: 10,
        marginRight: 10,
        marginLeft: 10
    },
    negrita: {
        fontWeight: 'bold',
        fontSize: 15,
        '& p': { marginTop: 5, marginBottom: 5}
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    fullflex: {
        flex: 1
    }
}));

interface IProps extends IFAQ {
    icon?: string,
    image?:string,
}

export const Pregunta: React.FC<IProps> = ({ question, answer, icon, image }) => {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const handleOpenAnswer = useCallback(() => {
        setShow(!show)
    }, [show])

    return (
        <Card className={classes.pregunta} onClick={() => handleOpenAnswer()}>
            <div className={classes.flex}>
                <div className={[classes.negrita, classes.fullflex].join(' ')} dangerouslySetInnerHTML={{ __html: question }} />
                {
                    image 
                    ? <img src={image} alt={icon} style={{width: 30, height: 30}}/>
                    : icon  
                        ? <Icon name={icon as any} size={1} color="#004f82"/>
                        : null
                }
            </div>
            <Collapse in={show} timeout="auto" unmountOnExit >
                <Divider style={{marginBottom: 10}} />
                <div dangerouslySetInnerHTML={{ __html: answer }} />
            </Collapse>
        </Card>
    )
}