import { Container } from 'aurelia-dependency-injection';
import { createContext } from 'react';
import axios, { AxiosError } from 'axios';
import { getAccessToken, setAccessToken } from 'hooks/page.hook';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AuthGateway } from 'gateways/auth.gateway';
import env from '../env';

const container = new Container();

/** Event Aggregator Configuration */
(function EAConfig(containerLocal: Container) {
    const ea = new EventAggregator();
    containerLocal.registerInstance(EventAggregator, ea);
})(container);

/** Axios Configuration */
(function AxiosConfiguration(containerLocal: Container) {
    const axiosInstance = axios.create({
        baseURL: env.API_URL,
    });

    axiosInstance.interceptors.request.use((config) => {
        const accessToken = getAccessToken();
        
        if (!!accessToken) {
            config.headers.Authorization = 'Bearer ' + accessToken;
        }
        const lang = localStorage.getItem('lang');
        if (lang) {
            config.headers['X-Language'] = lang;
        }
        return config;
    });

    axiosInstance.interceptors.response.use((response) => {
        return response;
    }, async (error: AxiosError) => {
        const response = error.response;
        const rqHeaders = error.config.headers;

        if (response) {
            const ea: EventAggregator = containerLocal.get(EventAggregator);
            if (response.status === 401) {
                const jwt: string = rqHeaders && rqHeaders.Authorization ? rqHeaders.Authorization.split(' ')[1] : null;
                const isWithRefresh = rqHeaders && rqHeaders['X-With-Refresh'] === '1';
                const authGateway: AuthGateway = containerLocal.get(AuthGateway);
                
                if (isWithRefresh) {
                    if (ea) {
                        ea.publish('auth.jwt.expired');
                    }
                    return response;
                }

                if (authGateway) {
                    try {
                        const result = await authGateway.refreshToken(jwt);
                        if (result && result.accessToken !== '') {
                            setAccessToken(result.accessToken);
                            const newRqConfig = error.config;
                            newRqConfig.headers.Authorization = result.accessToken;
                            return await axiosInstance.request(newRqConfig);
                        }
                    } catch (e) {
                        throw e;
                    }
                }

                if (ea) {
                    ea.publish('auth.jwt.expired');
                }

                return response;
            } else if (response.status === 403) {
                if (ea) {
                    ea.publish('auth.jwt.expired');
                }
                return response;
            }
        }
        throw error;
    });

    containerLocal.registerInstance('axios', axiosInstance);
})(container);

const IoC = createContext(container);

export default IoC;
