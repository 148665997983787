import { FC,  useContext,  useEffect, useMemo, useRef, useState } from "react";
import { StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { StepComponentProps } from "components/generic-stepper/generic-step-component";
import { LiteralsContext, withLiterals } from "containers/shared/literals";

import { translate } from "utils/i18n";

import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import { AbonoNacimientoGateway } from "gateways/tramites/abonoNacimiento.gateway";

import { camposToObject } from "./../utils";
import useCommonStyles from "./styles";
import { TAutorizacion, TDeclaracionResponsable50, TTipo } from "./types";
import FullLoading from "components/full-loading";

import GenericStepSchema from "components/generic-stepper/generic-step-schema";
import GenericBooleanRadioButton, { BOOL_OPTIONS } from "components/generic-radio-button/generic-boolean-radio-button";
import { IDocumento, ISaveStepResponse } from "gateways/tramites/abonoNacimiento.interfaces";
import AttachFileComponent from "components/attach-file-component";
import { IDocumentoAtib } from "gateways/documentos.interface";
import { DocumentosGateway } from "gateways/documentos.gateway";
import GenericCheckbox from "components/generic-checkbox";
import { useLocation } from "react-router";


type TFiles = {
    declIRPFAnterior: IDocumento[];
    certifImputacionFiscal: IDocumento[];
    certifResidenciaFiscal: IDocumento[];
    certifNacimiento:  IDocumento[];
}
const EMPTY_LOCAL_FILES: TFiles ={
    declIRPFAnterior: [],
    certifImputacionFiscal: [],
    certifResidenciaFiscal: [],
    certifNacimiento: []
}

type AutorizacionProps = StepComponentProps & WithStyles<typeof styles> 


const Autorizacion: FC<AutorizacionProps> = ({classes, idRef,apartado, onBack,onNext,onCancel, isFirst, isLast}) => { 
    const CommonClasses= useCommonStyles()
    const terms=useContext(LiteralsContext)
    const [,alertsDispatch] = useContext(AlertsContext);
    const componentMounted = useRef(true);
    const location = useLocation();
    
    // Gateways
    const ioc = useContext(IoC);
    const abonoNacimientoGateway: AbonoNacimientoGateway = useMemo(() => ioc.get(AbonoNacimientoGateway), [ioc]);
    const documentosGateway: DocumentosGateway = useMemo(() => ioc.get(DocumentosGateway), [ioc]);

    const [loading, setLoading]= useState(false);
    const [idsToDelete, setIdsToDelete] = useState([] as string[])

    const [datos_autorizacion,setDatosAutorizacion] = useState<TAutorizacion | null>(null);
    const [localFiles,setLocalFiles]= useState<TFiles| undefined>(undefined)

    const [docGenera,setDocGenera] = useState(50)
    const [presentaDecl,setPresentaDecl] = useState<boolean | undefined>(false)



    // -- Memos    ---------------------------------------------------------------------------------------------------------------------------
    const tipo = useMemo(() => ( location.pathname.includes('alta-solicitud') ? 'solicitud' : 'reintegro'),[location.pathname])
    const requiredAdjuntos= useMemo(() => (
        datos_autorizacion && datos_autorizacion.autorizaConsultaDatos === true 
        ? false 
        : tipo === 'solicitud' ? true: false
    ), [datos_autorizacion, tipo]);


    const disableNEXT = useMemo(() => {
        // Validacion Fin
        if(tipo === 'reintegro'){
            return false;
        } else {
            const isOk =  
                requiredAdjuntos 
                    ? datos_autorizacion 
                        && typeof datos_autorizacion.certifNacimiento === 'string'
                        && //localFiles.certifNacimiento !== null && 
                        ((
                            datos_autorizacion.presentaDeclIRPFAnterior === true 
                            && typeof datos_autorizacion.declIRPFAnterior === 'string'
                            //&& localFiles.declIRPFAnterior !== null
                        ) || (
                            datos_autorizacion.presentaDeclIRPFAnterior === false
                            && typeof datos_autorizacion.certifImputacionFiscal === 'string'
                            //&& localFiles.certifImputacionFiscal !== null
                            &&  typeof datos_autorizacion.certifResidenciaFiscal === 'string'
                            //&& localFiles.certifResidenciaFiscal !== null
                        )) ? true : false
                    : true
                
            return  datos_autorizacion && isOk ? false : true
        }
    },[tipo, datos_autorizacion]);

    // -- Functions --------------------------------------------------------------------------------------------------------------------------
    const addUniqueId = (existingStrings: string[], newStrings: string[]) => {
        const uniqueNewStrings = newStrings.filter(str => !existingStrings.includes(str));
        return [...existingStrings, ...uniqueNewStrings];
    }

    const deleteDocs = async( idDocs: string[]) => {
        //console.log('delete docs ', idDocs.length )
        const deleted = await Promise.all(
            idDocs.map( async id => await documentosGateway.deleteDocAtib(id))
        )
        return !deleted.includes(false)
    }

    const updateDocs = (keyValue: keyof TAutorizacion, docs: IDocumento[]) => {
        console.log('updateDocs', keyValue, localFiles)
        setDatosAutorizacion(curr => curr && ({...curr,
            [keyValue]: docs && docs[0] ? docs[0].IdDocumento : false
        }))
        setLocalFiles(curr => curr &&  ({...curr,[keyValue]: docs}))
    }

    const handleBack = () => {
        (async () => {
            const response = await save();
            onBack()
        })();
    }
    
    const handleNext = () => {
        (async () => {
            try {
                setLoading(false)

                const response = await save();
                if(response.saved){
                    onNext(response.referencia)
                } else{
                    alertsDispatch({
                        type:'show-alert',
                        payload: {
                            variant:'error',
                            message: translate('AbonoNacimiento', response.error ?? 'ErrorSaveStep',terms) + translate('AbonoNacimiento','autorizacion',terms)
                        }
                    })
                }

            } catch (error) {
                alertsDispatch({
                    type:'show-alert',
                    payload: {
                        variant:'error',
                        message: translate('AbonoNacimiento', (error as Error).message ?? 'ErrorSaveStep',terms) + translate('AbonoNacimiento','autorizacion',terms)
                    }
                })
                
            } finally {
                setLoading(false)
            }
        })()
    }

    const save  = async (): Promise<ISaveStepResponse> => {
        try {
            setLoading(false)
            const toSave = tipo === 'solicitud' ? {...datos_autorizacion, tipoNotificacion: 'electronica'}: {...datos_autorizacion}
            const response = await abonoNacimientoGateway.saveStep(idRef,'autorizacion', toSave )
            if(response.saved){
                if(idsToDelete){
                    const done = await deleteDocs(idsToDelete)
                    if(!done){throw new Error('ErrorDeleteDocs')}
                }
                return response
            }
            return response

        } catch (error) {
            return ({saved: false, referencia: '', error: undefined})
        } finally{
            setLoading(false)
        }
    }
        
    // -- Effects  ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                //  INICIALIZACIÓN
                if(!idRef ){ throw new Error('not-idRef')}
                const campos =  await abonoNacimientoGateway.getCamposStep('finalizacion_justificante')
                let emptyObject= camposToObject(campos)

                const response = tipo === 'solicitud' 
                    ? await abonoNacimientoGateway.getApartadosByRef<'solicitud'>(idRef)
                    : await abonoNacimientoGateway.getApartadosByRef<'reintegro'>(idRef);
                //console.log('response', response)
                const doc = response.declaracion_responsable?.tipoDocGenera ?? 50
                const declIRPF = doc === 50 
                    ?   (response.declaracion_responsable?.declResponsable as TDeclaracionResponsable50).baseImponible_valor.baseImponible_valor2 !== undefined 
                        && (response.declaracion_responsable?.declResponsable as TDeclaracionResponsable50).baseImponible_valor.baseImponible_valor1 !== '17' // id 17 : AbonoNacimientos.dbo.LimitesBaseImponible
                    : true

                let initVal =  {...emptyObject, autorizaConsultaDatos: tipo==='reintegro' ? true : undefined} as TAutorizacion
                
                let local_files = {...EMPTY_LOCAL_FILES}
                const idsDelete : string[]= []
                console.log('useEffect response', response.autorizacion)
                if(response.autorizacion){
                    if( response.autorizacion.presentaDeclIRPFAnterior && declIRPF === response.autorizacion.presentaDeclIRPFAnterior){
                        local_files = {...EMPTY_LOCAL_FILES}
                        if(Object.values(response.autorizacion).length>0){
                            for (const key in response.autorizacion) {
                                if(['certifImputacionFiscal','certifNacimiento','certifResidenciaFiscal','declIRPFAnterior']. includes(key)){
                                    const element = response.autorizacion[key as keyof TAutorizacion];
                                    if(typeof element === 'string') {
                                        const doc = await abonoNacimientoGateway.getDoc(element)
                                        //console.log(doc, key)
                                        local_files = {...local_files, [key as keyof TFiles] : [doc]}
                                    }
                                }
                            }
                        }
    
                        initVal = {...response.autorizacion} 
                    } else {
                        //console.log('cambio en respuesta presneta decl - limpiados docs')
                        if(Object.values(response.autorizacion).length>0){
                            for (const key in response.autorizacion) {
                                if(['certifImputacionFiscal','certifNacimiento','certifResidenciaFiscal','declIRPFAnterior'].includes(key)){
                                    const element = response.autorizacion[key as keyof TAutorizacion];
                                    if(typeof element === 'string') {
                                        await abonoNacimientoGateway.deleteDoc(element)
                                    }
                                }
                            }
                            
                        }
                        initVal = {...initVal, presentaDeclIRPFAnterior: declIRPF} as TAutorizacion
                        
                    }

                }
                
 
                if(componentMounted.current){
                    setDatosAutorizacion({...initVal})// as TFinMap[typeof doc])
                    setLocalFiles(local_files)
                    setDocGenera(doc)
                    setPresentaDecl(declIRPF)
                    setIdsToDelete(idsDelete)
                }

            } catch(err){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        variant:'error',
                        message: translate('AbonoNacimiento','ErrorLoadingStep',terms)
                    }
                })
            } finally {
                setLoading(false)
            }
            
        })();

        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
        
    },[idRef, apartado, tipo]);

    // console.log('local files', localFiles)
    return (

        <GenericStepSchema
            isFirst={isFirst}
            isLast={isLast}
            onNext={handleNext}
            disabledNext={disableNEXT}
            onBack={handleBack}
            disabledBack={false}
            onExit={onCancel}
            forceExit={false} 
            idRef={idRef} 
            allowContinuarMasTarde={false}
            withRecoverRefContinuarMasTardeModal={false}
            withContinuarMasTardeModal={true}
        >
            {/**Componente */}
            <>
                <FullLoading loading={loading}/>
                {datos_autorizacion && 
                    <div className={classes.textContainer}>
                        {tipo === 'solicitud' ?
                            <>
                                <GenericBooleanRadioButton
                                    labelInRow={false}
                                    optionsInRow={true}
                                    componentIG='AbonoNacimiento'
                                    labelTerm='5x_autorizaConsultaDatos'
                                    typeLabel={'text'}
                                    value={datos_autorizacion.autorizaConsultaDatos}
                                    options={BOOL_OPTIONS}
                                    onChangeValue={ (v: boolean, err: boolean) =>  {
                                        setDatosAutorizacion(curr=> curr && ({
                                            ...curr,
                                            autorizaConsultaDatos: v,
                                            presentaDeclIRPFAnterior: presentaDecl,
                                            declIRPFAnterior: false,
                                            certifImputacionFiscal: false,
                                            certifResidenciaFiscal: false,
                                            certifNacimiento:false
                                        }))
                                        if(v === true){
                                            const ids=[] as string[]
                                            for (const key in datos_autorizacion) {
                                                if(['certifImputacionFiscal','certifNacimiento','certifResidenciaFiscal','declIRPFAnterior']. includes(key)){
                                                    const element = datos_autorizacion[key as keyof TAutorizacion];
                                                    if(typeof element === 'string') {
                                                        ids.push(element)
                                                    }
                                                }
                                            }
                                            setIdsToDelete(curr => addUniqueId(curr,ids))
                                        }
                                        setLocalFiles(EMPTY_LOCAL_FILES)
                                    }}
                                />

                                {datos_autorizacion.autorizaConsultaDatos !== undefined  && localFiles && //datos_autorizacion.autorizaConsultaDatos === false &&
                                    <>
                                        <Typography style={{marginTop: 15}}>{translate('AbonoNacimiento', datos_autorizacion.autorizaConsultaDatos === true ?'avisoAutoriza':'avisoNoAutoriza',terms)}</Typography>
                                        { requiredAdjuntos && 
                                            <div className={classes.column} >
                                                
                                                {docGenera === 50 && 
                                                    <GenericBooleanRadioButton
                                                        labelInRow={true}
                                                        optionsInRow={true}
                                                        componentIG='AbonoNacimiento'
                                                        labelTerm='presentaDeclIRPFAnterior'
                                                        value={datos_autorizacion.presentaDeclIRPFAnterior}
                                                        disabled
                                                        options={BOOL_OPTIONS}
                                                        onChangeValue={ (v: boolean, err: boolean) =>  {
       
                                                        }}
                                                        required
                                                    />
                                                }

                                                {datos_autorizacion.presentaDeclIRPFAnterior !== undefined && 
                                                    <div>
                                                        {datos_autorizacion.presentaDeclIRPFAnterior === true &&  
                                                            <div>
                                                                <AttachFileComponent
                                                                    keyDoc='declIRPFAnterior'
                                                                    label={translate('AbonoNacimiento','declIRPFAnterior', terms)}
                                                                    docs={localFiles.declIRPFAnterior}
                                                                    saveDocsAttached={ (docs: IDocumentoAtib[]) => updateDocs('declIRPFAnterior', docs)}
                                                                    multiple={false}
                                                                    allowDelete = {true}
                                                                    allowDownload = {false}
                                                                    docSource='abono-nacimientos'
                                                                    labelType='text'
                                                                    allowedExtensions={['.pdf']}
                                                                />  
                                                            </div>
                                                        }

                                                        { datos_autorizacion.presentaDeclIRPFAnterior ===false &&
                                                        <>
                                                            <div>
                                                                <AttachFileComponent
                                                                    keyDoc='certifImputacionFiscal'
                                                                    label={translate('AbonoNacimiento','certifImputacionFiscal', terms)}
                                                                    docs={localFiles.certifImputacionFiscal}
                                                                    saveDocsAttached={ (docs: IDocumentoAtib[]) => {updateDocs('certifImputacionFiscal', docs) }}
                                                                    multiple={false}
                                                                    allowDelete = {true}
                                                                    allowDownload = {false}
                                                                    docSource='abono-nacimientos'
                                                                    labelType='text'
                                                                    allowedExtensions={['.pdf']}
                                                                />  
                                                            </div>
                                                            <div>
                                                                <AttachFileComponent
                                                                    keyDoc='certifResidenciaFiscal'
                                                                    label={translate('AbonoNacimiento','certifResidenciaFiscal', terms)}
                                                                    docs={localFiles.certifResidenciaFiscal }
                                                                    saveDocsAttached={(docs: IDocumentoAtib[]) => {updateDocs('certifResidenciaFiscal', docs) }}
                                                                    multiple={false}
                                                                    allowDelete = {true}
                                                                    allowDownload = {false}
                                                                    docSource='abono-nacimientos'
                                                                    labelType='text'
                                                                    allowedExtensions={['.pdf']}
                                                                /> 
                                                            </div>
                                                        
                                                        </>
                                                    }
                                                    <div>
                                                        <AttachFileComponent
                                                            keyDoc={'certifNacimiento'}
                                                            label={translate('AbonoNacimiento','certifNacimiento', terms)}
                                                            docs={localFiles.certifNacimiento }
                                                            saveDocsAttached={(docs: IDocumentoAtib[]) => {  updateDocs('certifNacimiento', docs)}}
                                                            multiple={false}
                                                            allowDelete = {true}
                                                            allowDownload = {false}
                                                            docSource='abono-nacimientos'
                                                            labelType='text'
                                                            allowedExtensions={['.pdf']}
                                                        />
                                                    </div>
                                                            
                                                </div>
                                                }
                                            </div>
                                        }
                                    </>
                                    
                                }
                                

                                
                            </>
                        :
                            <>
                                <Typography style={{marginBottom:10}}>{translate('AbonoNacimiento', '5x_autorizaConsultaDatos',terms)}</Typography>
                                <Typography style={{marginBottom:5}}>{translate('AbonoNacimiento', 'avisoAutoriza',terms)}</Typography>
                                
                                <GenericCheckbox
                                    value= {datos_autorizacion.autorizaConsultaDatos !== true }
                                    onChangeValue= {(checked: boolean) => setDatosAutorizacion(curr => curr &&  ({...curr, autorizaConsultaDatos: !checked}))}
                                    labelTerm= {'checkNoAutoriza'}
                                    componentIG= {'AbonoNacimiento'}
                                />
                            </>}
                            
                        
                        
                        

                                           
                    </div>
                }


            </>
            </GenericStepSchema>
   
        

    )
}


const styles = (theme: Theme): StyleRules => ({
    textContainer:{
        padding: '0px 10px 0px 10px'
    },
    infoContentContainer:{

    },
    row:{
        display:'flex',
        flexDirection: 'row'
    },
    column:{
        display:'flex',
        flexDirection: 'column'
    },

    bold: {
        fontWeight: 'bolder'
    }
});

export default withLiterals(['Global', 'Tramites','AbonoNacimiento' ])(withStyles(styles)(Autorizacion))
