import { StyleRules } from "@material-ui/core";

const styles = (): StyleRules => ({
    // PADDINGS & MARGINS
    padding: {
        padding: '8px 24px'
    },
    marginDefault: {
        margin: 0,
    }
});

export default styles;