import React  from 'react';
import { WithStyles, withStyles, Typography, Grid, Button } from "@material-ui/core";
import styles from '../shared/notificaciones.styles';
import { IDocumento, INotificacion } from "gateways/notificaciones.interfaces";
import { FC, useContext, useMemo } from "react";
import IoC from "contexts/ioc.context";
import { translate } from 'utils/i18n';
import { NotificacionesGateway } from "gateways/notificaciones.gateway";
import { LiteralsContext } from 'containers/shared/literals';
import { download } from 'utils/download';
import { AlertsContext } from 'contexts/alerts.context';
import imageAdjuntoPDF from '../../resources/AdjuntoPDF.png';

interface IProps extends WithStyles<typeof styles> {
    notificacion: INotificacion;
    documentos: IDocumento[];
    setLoading: (loading: boolean) => void;
    setNotificacionState: (notificacion: INotificacion) => void;
}

const Documentos: FC<IProps> = ({ notificacion, documentos, classes, setLoading, setNotificacionState }) => {
    // Global states
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);

    // Services 
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(NotificacionesGateway) as NotificacionesGateway, [ioc]);        

    const handlePrint = async (documento: IDocumento) => {  
        setLoading(true);
        const result = await gateway.getDocumento(documento.idDocumento);

        download(result, alertsDispatch,
            translate('Global', 'No es posible descargar el documento', terms),
            translate('Global', 'BloqueoPantalla', terms));

        setLoading(false);
    };

    return (
        <Grid container>
            {
                documentos && documentos.length > 0
                ?
                documentos.map((documento: IDocumento) => {
                    return (
                        <div key={documento.idDocumento} className={classes.documentContainer}>
                            <Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={
                                    (e) => { handlePrint(documento) }
                                }>
                                <img src={imageAdjuntoPDF} alt="notificacion" />
                            </Button>
                            </Typography>
                            <Typography>
                                {documento.nombre}
                            </Typography>
                        </div>
                    )
                })
                :
                null
            }
        </Grid>
    )
}

export default withStyles(styles)(Documentos);