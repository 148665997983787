import { typeActions } from "contexts/actions";
import { MoreInfoDispatchAction } from "contexts/more_info.context";

export const handleInfoDispatch = (infoDispatch: MoreInfoDispatchAction, actionType: typeActions, templateName: string) => {
    infoDispatch({
        type: 'show-info',
        payload: {
            templateName: templateName,
        }
    });
}
