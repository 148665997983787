
import { translate } from "utils/i18n"
import { TCalculoError, TCalculoSimulador, TData, TDatosPrevios, TOperacionSimulador } from "./types"
import { useStyles } from "./styles"
import { FC, useContext } from "react"
import { LiteralsContext, withLiterals } from "containers/shared/literals"
import moment from "moment"
import { Button, Grid, Typography } from "@material-ui/core"
import { formatNumStr } from "components/generic-sujeto/utils"
import InmuebleInfo from "components/DUTI/inmueble-info"

export const EMPTY_CALCULO: TCalculoSimulador = {
    tipoDerecho: undefined,
    edadUsufructuaria: undefined,
    porcentaje: 0,
    numOperaciones: 0,
    operaciones: [],
    modelo: undefined,
}
export const EMPTY_CALCULO_ERR: TCalculoError = {
    tipoDerecho: false,
    edadUsufructuaria: false,
    porcentaje: false,
    numOperaciones: false,
    operaciones: false,
    modelo: false,
}
export const EMPTY_OPERACION: TOperacionSimulador = {
    fechaAnteriorAdquisicion: undefined,
    porcentajeAnteriorAdquisicion: 0,
    importeAnteriorAdquisicion: undefined,
}
export const EmptyData: TData = {
    municipio: undefined, inmueble: null,
    tipoTransmision: {
        tipo: undefined,
        bonificacion: undefined,
    },
    fechaTransmision: undefined,
    porcentaje: undefined,
    valorDeclarado: undefined,
    calculos: []
}
 const RenderShortDetail: FC<{info: TDatosPrevios, onEdit: () => void}> = ( ({info, onEdit}) => {
    const classes= useStyles()
    const terms = useContext(LiteralsContext)

    const {municipio,fechaTransmision,inmueble,porcentaje,tipoTransmision,valorDeclarado}= info;

    const render = (text: string, value: any, addValue?: string) => {
        return (
            <div className={classes.textRow}>
                <Typography className={classes.text}>{text}:</Typography>
                <Typography className={classes.textValue}>{value}</Typography>
                { addValue &&  <Typography className={classes.textValue}>{addValue}</Typography>}
            </div>
        )
    }

    return (
        <Grid container direction="row">
            <Grid item xs={7}>
                <Typography className={[classes.titleBlue, classes.marginBottom].join(' ')}>{translate('Plusvalias','inmuebleTitle_simu',terms).toUpperCase()}</Typography>
                {/* <div className={classes.row}>
                    {render(translate('Global','municipioSelector',terms), municipio?.nombre)}
                    {render(translate('DUTI','refCatastral',terms),inmueble?.refCatastral)}
                </div> */}
                <Typography className={classes.titleGray}>{translate('Plusvalias','causaTransmisionTitle_simu',terms).toUpperCase()}</Typography>
                <div className={[classes.row, classes.marginBottom].join(' ')}>
                    {render(translate('Plusvalias','tipoTransmision',terms),tipoTransmision.tipo?.nombre)}
                    {tipoTransmision.bonificacion  && render(translate('Plusvalias','bonificacion',terms),tipoTransmision.bonificacion.valor ,'%')}
                </div>
                <Typography className={classes.titleGray}>{translate('Plusvalias','infoTransmTitle_simu',terms).toUpperCase()} </Typography>
                <div className={[classes.row, classes.marginBottom].join(' ') }>
                    {render(translate('Plusvalias','fecha',terms),moment(fechaTransmision).format('DD/MM/YYYY'))}
                    {render(translate('DUTI','porcentajeTransmision',terms),porcentaje, "%")}
                    {render(translate('Plusvalias','importeTransmision',terms), valorDeclarado ? formatNumStr(valorDeclarado): '', "€")}
                </div>
                <div style={{paddingTop:15}}>
                    <Button 
                        style={{alignSelf: 'flex-end'}}
                        variant="contained"
                        color='primary'
                        onClick={onEdit}
                    >
                        {translate('Global', 'Editar',terms)}
                    </Button>
                </div>
                
                    
            </Grid>
            <Grid item xs={5}>
                {inmueble && 
                    <InmuebleInfo
                        refCatastral={inmueble.refCatastral} 
                        urlCatastro={inmueble.urlCatastro}
                        urlGoogle={inmueble.urlGoogle}
                        domicilio={inmueble.direccion}
                        tipoBien={inmueble.tipoBien}
                        uso={inmueble.datosEconomicos?.uso}
                    />
                }
            </Grid>   
        </Grid>       
    )
})

export default withLiterals(["Plusvalias", "DUTI", "Global"])(RenderShortDetail)