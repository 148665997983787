import { StyleRules } from "@material-ui/core";
import { Theme } from "@material-ui/core";


const styles =  (theme: Theme): StyleRules => ({
    paddingRootContainer: {
        padding: 10,
        marginBottom: 10
    },
    div: {
        display: 'flex',
        alignItems:'center',
        flexGrow:1
    },
    withLeftIcon: {
        display: 'flex',
        flexDirection:'row',
        alignItems:'center'
    }

})

export default styles