import { FC, useContext, Fragment } from "react";
import { makeStyles, Grid, FormControl } from "@material-ui/core";
import { Establecimiento, EstablecimientoWithEjercicio, IMultas, Modelo017 } from "gateways/tramites.interfaces";
import LabelField from 'components/label-field';
import Term from "components/term";
import { dateFormatToUTC } from "utils/dates";
import EstablecimientoGrid from "./establecimiento-grid";
import { styles } from "../../styles";
import { Categoria, DiasExplotacion } from "../../values.enum";

const useStyles = makeStyles(styles);

interface IEstablecimientoCardProps {
    establecimiento: EstablecimientoWithEjercicio;
}

const EstablecimientoCard: FC<IEstablecimientoCardProps> = ({ establecimiento }) => {
    const classes = useStyles();

    const handleDireccion = (establecimiento: Establecimiento | EstablecimientoWithEjercicio): string => {
        let result = `${establecimiento.siglas} ${establecimiento.calle} nº${establecimiento.numero}. ${establecimiento.cp}, ${establecimiento.municipioName ? establecimiento.municipioName : ""}`;
        return result
    }

    const handleDEEnum = (key: number) => {
        let tmpKey: string = "DE" + key;
        if (tmpKey in DiasExplotacion) {
            return DiasExplotacion[tmpKey as keyof typeof DiasExplotacion];
        }
    }

    const handleCatEnum = (key: number) => {
        let tmpKey: string = "C" + key;
        if (tmpKey in Categoria) {
            return Categoria[tmpKey as keyof typeof Categoria];
        }
    }

    return (
        <Fragment>
            <EstablecimientoGrid
                establecimiento={establecimiento}

                children={
                    <Grid container
                        spacing={1}
                        className={[classes.mainButton, classes.recibo, classes.pointer].join(' ')}>
                        <Grid item xs={12} sm={4}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="Dirección" />}
                                    text={handleDireccion(establecimiento)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="DíasDeExplotación" />}
                                    text={establecimiento.diasExplotacion === 0 ? "--" : handleDEEnum(establecimiento.diasExplotacion)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="BaseImponible" />}
                                    text={establecimiento.metodoDeterminacion}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="Categoría" />}
                                    text={establecimiento.categoriaEstablecimiento === 0 ? "--" : handleCatEnum(establecimiento.categoriaEstablecimiento)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="ReferenciaCatastral" />}
                                    text={establecimiento.referenciaCatastral}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="NoParcelas" />}
                                    text={establecimiento.numeroParcelas}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="ActividadDeTemporada" />}
                                    text={establecimiento.actividadTemporada ? "Si" : "No"}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>
                }></EstablecimientoGrid>


        </Fragment>
    );
};


export default EstablecimientoCard;