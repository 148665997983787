import React, { FC } from "react";
import Lottie from 'react-lottie';
import CalcularLottie from "animations/lotties/calcular.json"
import DUSTI_gif from "animations/intricom/DUSTI.gif"

import { CircularProgress, Dialog, Paper, Typography } from "@material-ui/core";
type TAnimation={type: 'lottie', name: 'calcular'} | {type: 'intricom', name: 'DUSTI'}
interface IProps {
    loading: boolean,
    msg?:string;
    animation?: TAnimation
}
const FullLoading: FC<IProps> = ({loading, msg, animation}) => {
    const getAnimationData = (type: string)=> {
        switch (type) {
            case 'calcular':
                return CalcularLottie;
            default:
                break;
        }
    }

    const getIntriGif = (type: string)=> {
        switch (type) {
            case "DUSTI":
                return DUSTI_gif
            default:
                break;
        }
    }
    return (
        <Dialog style={{zIndex: 9999}} open = {loading}>
            <Paper style={{padding:25, display:'flex', alignItems: 'center', flexDirection: 'column'}}>
                {animation
                    ? animation.type === 'lottie' 
                        ?   <Lottie
                                height= '150px'
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: getAnimationData(animation.name),
                                    rendererSettings: {
                                        scaleMode:'resize',
                                        preserveAspectRatio: "xMidYMid meet" // slice
                                    }
                                }}
                            />
                        : <img src={getIntriGif(animation.name)} />
                    : <CircularProgress size={50} />
                }
                { msg && 
                    <Typography style={{marginTop: 20}}>{msg}</Typography>
                }
            </Paper>
        </Dialog>
    )
}

export default FullLoading;