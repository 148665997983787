import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";

@inject("axios")
export class RecaptchaGateway {
  constructor(private axios: AxiosInstance) {}
  public async checkIP(): Promise<any> {
    const response = await this.axios.get("recaptcha/check-ip");

    if (response.status !== 200) {
      return { useRecaptcha: true, ipAddress: "" };
    }

    return response.data;
  }
}
