import { Typography, Grid } from "@material-ui/core"
import { translate } from "utils/i18n";
import { TSujeto } from "../../shared/types";
import { TContacto } from "components/DUTI/contact-form";
import { TDirectionATIB } from "components/DUTI/direction-form-ATIB";
import { TSelect, direccionToText } from "containers/DUTI/utils";

export const renderTitle = (term: string ,terms:any, componentIG: string) => (
    <Typography style={{fontWeight:'bolder', borderBottom:'solid', borderBottomWidth: 1,borderBottomColor: '#d3d3d3', paddingTop:15, width: '100%'}}>
        {translate(componentIG,term,terms).toUpperCase()}
    </Typography>
)

export const renderField = (term:string, value: string|undefined ,terms:any, componentIG: string,addPadding?:boolean, concatTerm?: string)=>(
    <Grid item container direction="row" alignItems="flex-start" spacing={1}>
        <Grid item xs={4}>
            <Typography style={{paddingLeft: addPadding ? 10: 0}}>{translate(componentIG, term, terms).concat(concatTerm ?? '') }:</Typography>
        </Grid>
        <Grid item xs={8}>
            <Typography style={{fontWeight: 450}}>{value}</Typography>
        </Grid>
    </Grid>
);

export const renderSujeto = (v:TSujeto, terms:any, componentIG: string) => {
    const keys= Object.keys(v) as (keyof TSujeto)[]
    return (
        <>
            {keys.map((k)=> { 
                if(k === "contacto"){   
                    return (
                        <>
                            {renderField('email', v['contacto'].email ?? "", terms, componentIG, true)}
                            {renderField('telefono', v['contacto'].telefono?.toString() ?? "",terms,  componentIG, true)}
                        </>
                    )
                } else if(k === "direccion"){
                    return  renderField('direccion', direccionToText(v['direccion'], terms), terms, componentIG, true)
                }else if(k === "personaFisica"){
                    return renderField(k, v[k] ? 'Sí': 'No',terms, 'Plusvalias', true)
                } else {
                    return renderField(k, v[k],terms, componentIG, true)
                }
                
            })}
        </>
    )
}
