import { FC } from 'react';
import { WithStyles, Button, withStyles, Hidden, CircularProgress, Grid, CardContent, Card, Typography } from '@material-ui/core';
import Term from 'components/term';
import { IPaymentMethod } from 'gateways/payment.method.interfaces';
import { IModelGateway } from 'gateways/modelGateway.interface';
import imagetarjeta from '../resources/payment/tarjeta.png';
import imagebancaElectronica from '../resources/payment/bancaElectronica.png';
import imageBizum from '../resources/payment/Bizum2.png';
import imageimprimir from '../resources/payment/imprimir.png';
import BizumForm from 'components/bizum-form';
import styles from 'containers/pasarela-pago/pago.styles';
import DeudaDialog from 'containers/pasarela-pago/deuda.dialog';
import TotalPago from 'containers/pasarela-pago/total.pago';
import PanelDeuda from 'containers/pasarela-pago/panel.deuda.drawer';

interface IProps {
    creditCardMethod: IPaymentMethod | undefined;
    bizumMethod: IPaymentMethod | undefined;
    modelos: IModelGateway[];
    showDeuda: boolean;
    showModeloCaducado: boolean;
    permitirPagoBanca: boolean;
    permitirDocIngresoI: boolean;
    permitirDocIngresoG: boolean;
    loading: boolean;
    showTotalPagar: boolean;    
    handlePayCreditCard: () => void;
    handlePayBank: () => void;
    handlePayBizum: (modelos: IModelGateway[]) => void;
    setShowDeuda?: (show: boolean) => void;
    handlePrint?: (individual: boolean) => void;
}

const MetodosPago: FC<WithStyles<typeof styles> & IProps> = (
    { 
        classes,
        creditCardMethod,
        bizumMethod,
        modelos,
        showDeuda,
        showModeloCaducado,
        permitirDocIngresoG,
        permitirPagoBanca,
        permitirDocIngresoI,
        loading,
        showTotalPagar,
        setShowDeuda,
        handlePayCreditCard,
        handlePayBank,
        handlePrint,
        handlePayBizum
    }) => {
    return (
        <>  
            <div className={classes.root}>
                <Grid className={setShowDeuda ? classes.mainCard : ''}>
                    <Card className={[classes.modeloCaducado, showModeloCaducado ? '' : classes.hidden].join(' ')}>
                        <CardContent>
                            <Typography component="div" className={[classes.modeloCaducado, showModeloCaducado ? '' : classes.hidden].join(' ')}>
                                <Typography variant="h5">
                                    <Term component="PasarelaPago" text="EL PLAZO PARA PAGAR HA CADUCADO" />
                                </Typography>
                            </Typography>
                        </CardContent>
                    </Card>
                    <Grid className={[classes.formasPago, showModeloCaducado ? classes.hidden : ''].join(' ')}>
                        <Grid container className={classes.cardSeparate}>
                            <Card className={classes.fullWidth}>
                                <CardContent>
                                    <Grid container className={classes.rootGrid}>
                                        <Grid item className={classes.iconoContainer}>
                                            <img src={imagetarjeta} alt='tarjeta de crédito' className={classes.iconoImage} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6">
                                                <Term component="PasarelaPago" text="Tarjeta Bancaria" />
                                            </Typography>
                                            <Typography component="p" className={classes.fontSizeP}>
                                                <Term component="PasarelaPago" text="PagoTarjeta" />
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.paymentMethodButton}>
                                            <Typography component="div" className={classes.wrapper}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.button}
                                                    disabled={!creditCardMethod?.activa || loading}
                                                    onClick={handlePayCreditCard}>
                                                    <Term component="PasarelaPago" text="PAGAR CON TARJETA" />
                                                </Button>
                                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid container className={classes.cardSeparate}>
                            <Card className={classes.fullWidth}>
                                <CardContent>
                                    <Grid container className={classes.rootGrid}>
                                        <Grid item className={classes.iconoContainer}>
                                            <img src={imagebancaElectronica} alt='banca electrónica' className={classes.iconoImage} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6">
                                                <Term component="PasarelaPago" text="Banca Electrónica" />
                                            </Typography>
                                            <Typography component="p" className={classes.fontSizeP}>
                                                <Term component="PasarelaPago" text="OpcionPago" />
                                            </Typography>
                                            <Typography component="p" className={[classes.fontSizeP, classes.red, classes.priceBold, permitirPagoBanca ? classes.hidden : ''].join(' ')}>
                                                <Term component="PasarelaPago" text="ModalidadPago" />
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.paymentMethodButton}>
                                            <Typography component="div" className={classes.wrapper}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.button}
                                                    disabled={loading || !permitirPagoBanca}
                                                    onClick={handlePayBank}>
                                                    <Term component="PasarelaPago" text="ELEGIR ENTIDAD" />
                                                </Button>
                                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        { handlePrint &&
                        <Grid container className={classes.cardSeparate}>
                            <Card className={classes.fullWidth}>
                                <CardContent>
                                    <Grid container className={classes.rootGrid}>
                                        <Grid item className={classes.iconoContainer}>
                                            <img src={imageimprimir} alt='imprimir carta de pago' className={classes.iconoImage} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6">
                                                <Term component="PasarelaPago" text="Imprimir para pagar en banco" />
                                            </Typography>
                                            <Typography component="p" className={classes.fontSizeP}>
                                                <Term component="PasarelaPago" text="Obtener carta de pago para el pago presencial" />
                                            </Typography>
                                            <Typography component="p" className={[classes.fontSizeP, classes.red, classes.priceBold, (permitirDocIngresoI && permitirDocIngresoG) || modelos.length === 1 ? classes.hidden : ''].join(' ')}>
                                                {permitirDocIngresoI && !permitirDocIngresoG ? <Term component="PasarelaPago" text="ModalidadPagoIT" /> : <Term component="PasarelaPago" text="ModalidadPago" />}
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.paymentMethodButton}>
                                            <Typography component="div" className={classes.wrapper}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.button}
                                                    disabled={loading || !permitirDocIngresoI}
                                                    onClick={
                                                        (e) => { handlePrint(true) }
                                                    }>
                                                    <Term component="PasarelaPago" text={modelos.length > 0 && modelos[0].modelo.idTipoModelo === "081" ? "Imprimir" : "Imprimir individual"} />
                                                </Button>
                                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}

                                                { modelos.length > 1 && modelos[0].modelo.idTipoModelo !== "081" &&
                                                    <>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            className={classes.button}
                                                            disabled={loading || !permitirDocIngresoG}
                                                            onClick={
                                                                (e) => { handlePrint(false) }
                                                            }>
                                                            <Term component="PasarelaPago" text="Imprimir agrupado" />
                                                        </Button>
                                                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                    </>
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        }
                        <Grid container className={classes.cardSeparate}>
                            <Card className={classes.fullWidth}>
                                <CardContent>
                                    <Grid container className={classes.rootGrid}>
                                        <Grid item className={classes.iconoContainer}>
                                            <img src={imageBizum} alt='Bizum' className={classes.iconoImage} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6">
                                                <Term component="PasarelaPago" text="Bizum" />
                                            </Typography>
                                            <Typography component="p" className={classes.fontSizeP}>
                                                <Term component="PasarelaPago" text="PagoBizum" />
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item className={classes.paymentMethodButton}>
                                            <Typography component="div" className={classes.wrapper}>
                                                <Button
                                                    type='submit'
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.button}
                                                    disabled={!bizumMethod?.activa || loading}
                                                    onClick={() => handlePayBizum(modelos)}>
                                                    <Term component="PasarelaPago" text="PAGAR CON BIZUM" />
                                                </Button>
                                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </Typography>
                                        </Grid>
                                       
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                { showTotalPagar &&
                <div className={classes.fixedBottom}>
                    <TotalPago items={modelos} visible={!showModeloCaducado} />
                </div>
                }
            </div>
            { setShowDeuda &&
            <div className={classes.drawer}>
                <Hidden mdUp implementation="css">
                    <DeudaDialog
                        open={showDeuda}
                        onClose={() => setShowDeuda(false)}
                        modelos={modelos}
                    />
                </Hidden>
                <Hidden smDown implementation="css">
                    <PanelDeuda modelos={modelos} />
                </Hidden>
            </div>
            }
            <BizumForm />
        </>
    );
}

export default withStyles(styles)(MetodosPago);
