import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import CarrouselButtons from "atomic/atoms/carrousel-buttons/carrousel-buttons";
import Term from "components/term";
import { PopUpContext } from "contexts/pop-up.context";
import { FC, useCallback, useContext, useState } from "react";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    position: "relative",
    backgroundColor: "#2589A4",
    opacity: 20,
  },
  content: {
    margin: "0 20px",
    textAlign: "justify",
  },
  title: {
    color: "#004f84",
    fontSize: 32,
    fontWeight: "lighter",
  },
  centerContent: {
    textAlign: "center",
    padding: 50,
  },
  actions: {
    display: "grid",
    gridTemplateRows: "50px 50px",
    gridTemplateColumns: "100%",
    width: "100%",
  },
}));

const PopUpDialog: FC = () => {
  const classes = useStyles();
  const [popUps, popUpsDispatcher] = useContext(PopUpContext);
  const [selected, setSelected] = useState(0);
  const handleClose = useCallback(() => {
    popUpsDispatcher({ type: "hide-popUp" });
    setSelected(0)
  }, [popUpsDispatcher]);

  return popUps.popUp ? (
    <>
      <Dialog
        open={popUps.popUp.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{ marginTop: 20 }}
          className={classes.content}
        >
          <span className={classes.title}>
            <Term component="Global" text="Informacion" />
          </span>
        </DialogTitle>
        <DialogContent className={classes.content}>
            <DialogContentText
              dangerouslySetInnerHTML={{ __html: popUps.popUp.texts[selected] }}
            />
        </DialogContent>
        <DialogActions className={classes.actions}>
          {popUps.popUp && popUps.popUp?.texts.length > 1 ? (
            <CarrouselButtons
              amount={popUps.popUp?.texts.length}
              style={{ justifyContent: "center" }}
              selected={selected}
              setSelected={setSelected}
            ></CarrouselButtons>
          ) : (
            <></>
          )}
          <div
            style={{
              gridRow: 2,
              display: "flex",
              justifyContent: selected > 0 ? "space-between" : "end",
            }}
          >
            {selected > 0 ? (
              <Button
                onClick={() => setSelected(selected - 1)}
                color="primary"
                autoFocus
                style={{ width: "100px" }}
              >
                <Term component={"Global"} text={"Atras"} />
              </Button>
            ) : (
              <></>
            )}
            {popUps.popUp?.texts.length &&
            selected === popUps.popUp?.texts.length - 1 ? (
              <Button
                onClick={handleClose}
                color="primary"
                autoFocus
                style={{ width: "100px" }}
              >
                <Term component={"Global"} text={"Cerrar"} />
              </Button>
            ) : (
              <Button
                onClick={() => setSelected(selected + 1)}
                color="primary"
                autoFocus
                style={{ width: "100px" }}
              >
                <Term component={"Global"} text={"Siguiente"} />
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export default PopUpDialog;
