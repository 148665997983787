
import {FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import { Grid } from "@material-ui/core";
import { TSelect, formatSelectOptions } from "containers/DUTI/utils";
import { IBonificacion, TTipoTransmision } from "gateways/model.new.interface";
import ModelGateway from "gateways/model.new.gateway"
import IoC from "contexts/ioc.context";
import InputSelect from "components/DUTI/input-select";
import LabelWithInfo from "components/DUTI/label-with-info";
import { getTiposTransmsisionByTipoAsistente } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/utils";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { ContenidosGateway } from "gateways/contenido.gateway";
import FullLoading from "components/full-loading";
import { TTipoTransmSimuladorPLV } from "./types";


export type TTipoTransErr = Record<keyof TTipoTransmSimuladorPLV, boolean>

interface IProps {
    idMunicipio: string;
    nombreMuni: string,
    tipoTransmision: TTipoTransmSimuladorPLV;
    onChangeTipoTransmisiones: (v:TTipoTransmSimuladorPLV, err:TTipoTransErr) => void;
}
const TipoTansmisionBonificacionSimulador: FC<IProps> = ({idMunicipio,nombreMuni, tipoTransmision,onChangeTipoTransmisiones}) => {
    
    const componentMounted = useRef(true);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway),[ioc]);
    const contenidoGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway),[ioc]);

    const terms = useContext(LiteralsContext);
    const [loading,setLoading]=useState(false);
    const TIPOS_BONIFICADOS: TTipoTransmision[]= ['SH', 'DD']
    const [tiposTransmision, setTiposTransmision] = useState([] as TSelect[]);
    const [bonificaciones, setBonificaciones]= useState<IBonificacion[] | null>(null);
    const [bonificacionesSelect, setBonificacionesSelect]= useState<TSelect[] >([]);

    const [MSGCommonBoni, setMSGCommonBoni]= useState<string>("")
    const [MSGMuniBoni, setMSGMuniBoni]= useState<string[]>([] as string[]);
    const [tipoT, setTipoT]=useState<TSelect|undefined>(undefined)
    const [tipoTErr, setTipoTErr]=useState(false)
    const [boni, setBoni]=useState<TSelect|undefined>(undefined)
    const [boniErr, setBoniErr]=useState(false)



    const MoreInfo = useMemo(() => {
    //    if( MSGMuniBoni.length>0){
    //         const msgMuni= MSGMuniBoni.map((msg, idx) => (`<p>${MSGMuniBoni.length>1 ? idx+' - '+msg : msg}</p>`))
    //         const msgStr = msgMuni.join(' ')
        return( MSGCommonBoni !== "" ?
            {
                template: MSGCommonBoni, // `${MSGCommonBoni}${msgStr}`,
                size: 'md',
                asMessage: true
            }: undefined
        )
    //    } else {
    //     return undefined
    //    }

    },[MSGCommonBoni])// MSGMuniBoni])

    const getBonificacion = async (id:string, tt: string | undefined) => {
        setLoading(true);
        if(tt && TIPOS_BONIFICADOS.includes(tt as TTipoTransmision)){
            const bonificaciones = await modelGateway.getBoniTipoTransmByMuni(id, 'Error consulta bonificacion', tt);
            setBonificaciones(bonificaciones.length > 0 ? bonificaciones :null);
            setBonificacionesSelect(
                bonificaciones.length>0 ?
                formatSelectOptions<IBonificacion>("idTarifa", "valor", bonificaciones)
                :[]
            );
            // setMSGMuniBoni(MSG);
        } else {
            setBonificaciones(null)
            setBonificacionesSelect([])
            //setMSGMuniBoni([])
        }
        setLoading(false)
    }


    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                const nomMuni=nombreMuni.toLowerCase().split(' ')
                const boniMSGCommon = await contenidoGateway.getContent( `plusvalias_more_info_bonificacion_${nomMuni.join('_')}`,{});
                await getBonificacion(idMunicipio, tipoTransmision?.tipo?.id ?? undefined)
                
                if(componentMounted.current){
                    if(tipoTransmision){
                        setTipoT(tipoTransmision.tipo)
                        setBoni( tipoTransmision.bonificacion ? 
                            {id:tipoTransmision.bonificacion.idTarifa.toString() ,nombre:tipoTransmision.bonificacion.valor.toString()}
                            : undefined
                        )
                    }
                    const tiposTransmision = await modelGateway.getTiposTransmision();
                    setTiposTransmision(getTiposTransmsisionByTipoAsistente(tiposTransmision, terms))
                    setMSGCommonBoni(boniMSGCommon[0].contenido)
                }
            } catch (err){
                console.log('error ', err)
            } finally {
                setLoading(false)
            }
            
        })()
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    },[])

    useEffect(() => {
        (async() => {
            const nomMuni=nombreMuni.toLowerCase().split(' ')
            const boniMSGCommon = await contenidoGateway.getContent( `plusvalias_more_info_bonificacion_${nomMuni.join('_')}`,{})
            setMSGCommonBoni(boniMSGCommon[0].contenido)
        })();
    },[nombreMuni])

    useEffect(() => {
        if(tipoTransmision){
            setTipoT(tipoTransmision.tipo)
            setBoni( tipoTransmision.bonificacion ? 
                {id:tipoTransmision.bonificacion.idTarifa.toString() ,nombre:tipoTransmision.bonificacion.valor.toString()}
                : undefined
            )
        }
    },[tipoTransmision])




    return(
        <Grid container direction='row' alignItems="flex-start" spacing={2}> 
            <FullLoading loading={loading}/>
            <Grid item>
                <LabelWithInfo componentIG={'Plusvalias'} fieldTerm={'tipoTransmisionLabel'} />
                <InputSelect 
                    componenteIG='Plusvalias' 
                    fieldTerm='tipoTransmision'
                    options={tiposTransmision}
                    value={tipoTransmision.tipo}
                    error={tipoTErr}
                    required
                    onChangeValue={async (v: TSelect|undefined,err:boolean)=> {
                        // setTipoT(v);
                        setTipoTErr(err);
                        onChangeTipoTransmisiones(
                            {tipo: v, bonificacion: tipoTransmision.bonificacion},
                            {tipo: err, bonificacion:boniErr}
                        )
                        await getBonificacion(idMunicipio, v?.id ?? undefined)
                    }}
                />
            </Grid>

            {bonificaciones && bonificaciones?.length>0 &&
                <Grid item>
                    <LabelWithInfo componentIG={'Plusvalias'} fieldTerm={'bonificacionLabel'} moreInfo={MoreInfo}/>
                    <InputSelect 
                        componentIG='Plusvalias' 
                        fieldTerm='bonificacion'
                        options={bonificacionesSelect}
                        value={tipoTransmision.bonificacion 
                            ? {id: String(tipoTransmision.bonificacion.idTarifa), nombre: String(tipoTransmision.bonificacion.valor)}
                            : undefined}
                        onChangeValue={ (v: TSelect|undefined,err:boolean) => {
                            //setBoni(v);
                            setBoniErr(err)
                            onChangeTipoTransmisiones(
                                {tipo: tipoTransmision.tipo, bonificacion: v? {idTarifa: Number(v.id), valor:Number(v.nombre)}: undefined },
                                {tipo: tipoTErr, bonificacion: boniErr}
                            )
                        }}
                    
                    />
                    
                </Grid>
            }

            

        </Grid>
    );
}

export default withLiterals(["Plusvalias","Global"])(TipoTansmisionBonificacionSimulador);