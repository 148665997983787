import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { ILanding } from "./landing.interfaces";

@inject('axios')
export class ContenidosGateway {

    constructor(
        private axios: AxiosInstance
    ) { }

    public async getLanding(landingName: string, idioma: string): Promise<ILanding> {
        const response = await this.axios.get('contenidos/getLanding/' + landingName + '/' + idioma);

        if (!response.data.ok) {
            return {} as ILanding;
        } else if (response.status !== 200 && response.status !== 403) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data.entidad;
    }

    public async getLandingParams(landingName: string, params: any) {
        const response = await this.axios.post('contenidos/getLanding/', {
            landingName,
            params,
        });

        return response.data;
    }

    public async getContent(landingName: string, params: any) {

        const response = await this.axios.post('contenidos/getContent/', {
            landingName,
            params,
        });
        return response.data;
    }
}
