// LIBRARY IMPORTS
import React, { FC, useEffect } from 'react';
import { Grid, Typography, ButtonBase, Button, makeStyles, useTheme } from "@material-ui/core";
import { Icon } from '@mdi/react';
import { mdiCheck } from '@mdi/js';
// LOCAL IMPORTS
import { IFilterData } from 'gateways/recibo.interfaces';
import { ITributo } from 'gateways/tributos.interfaces';
import { IFilterOption } from 'utils/interfaces';
import { ScreenType } from 'containers/cartero-virtual/filters.form';
import { dateFormat } from 'utils/dates';
import ConceptoIcon from 'components/concepto.icon';
import Term from 'components/term';
// STYLES IMPORTS
import stylesLocal from './styles';
import stylesShared from './../../../shared/tributos.grid.styles';

const useStylesLocal = makeStyles(stylesLocal);
const useStylesShared = makeStyles(stylesShared);

interface IProps {
    selectable: boolean;
    tributo: ITributo;
    selected: boolean;
    visibleButton: boolean;
    filterData: IFilterData | null;
    screen: ScreenType;
    domiciliables?: boolean;
    multiplesRegistrosSeleccionados?: boolean;
    onlyDomiciliados?: boolean;
    onlyPagoCarta?: boolean;

    onUnselect: (tributo: ITributo) => void;
    onSelect: (tributo: ITributo) => void;
    moreInfo: (tributo: ITributo) => void;
    gestionDomiciliacion: (tributo: ITributo) => void;
    pagoCarta?: (tributo: ITributo) => void;
}

const DomiciliacionGrid: FC<IProps> = (props) => {
    const {
        selectable,
        tributo,
        selected,
        visibleButton,
        filterData,
        screen,
        domiciliables,
        multiplesRegistrosSeleccionados,
        onlyDomiciliados,
        onlyPagoCarta,

        onUnselect,
        onSelect,
        moreInfo,
        gestionDomiciliacion,
        pagoCarta,
    } = props;
    // HOOKS
    const theme = useTheme();
    const classesShared = useStylesShared(theme);
    const classesLocal = useStylesLocal(theme);

    const municipio = filterData && filterData.organismos ? filterData.organismos.find((x: IFilterOption<string>) => x.value === tributo.organismo.codigo) : null;
    const handleSelect = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        (selected ? onUnselect : onSelect)(tributo);
    };

    const handleMoreInfo = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        moreInfo(tributo);
    };

    const handleGestion = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        gestionDomiciliacion(tributo);
    };

    // const handleButtonsDomiciliaciones = () => {
    //     if (!visibleButton) return null;
    //     if (selectable && screen === 'domiciliaciones') return (
    //         <Button variant="contained" color="primary" size="small" className={[classesShared.minWidth90, classesShared.btnAddPay, classesShared.widthBtnDomiGrid205].join(' ')} onClick={handleSelect}>
    //             <Term component="Domiciliaciones" text={selected ? "Quitar" : "Domiciliar"} />
    //         </Button>
    //     );
    //     if (tributo.pagoCarta && tributo.pagoCarta.DomiciliadoPC && pagoCarta) return (
    //         <Button variant="contained" color="secondary" size="small" disabled={onlyDomiciliados} 
    //             className={[classesShared.minWidth90, classesShared.btnPagoCarta].join(' ')} 
    //             onClick={handleSelect}>
    //             {
    //                 <Term component="Domiciliaciones" text={selected ? "Quitar" : "Modificar cuenta"} />
    //             }
    //         </Button>
    //     );
    // }

    return (
        <Grid container className={classesShared.root}>
            <Grid item className={classesShared.imageGrid}>
                {
                    <ButtonBase
                        onClick={handleSelect}
                        disabled={domiciliables ? false : tributo.pagoCarta?.DomiciliadoPC ? onlyDomiciliados : onlyPagoCarta} // TODO: mejorar legibilidad check
                        className={[classesShared.imagenContainer, selected ? classesShared.checked : ''].join(' ')}>
                        {
                            selected
                                ? <Icon path={mdiCheck} title="Seleccionado" size={1} />
                                : <ConceptoIcon concepto={tributo.concepto.idTipo} />
                        }
                    </ButtonBase>
                }
            </Grid>
            <Grid item className={[classesShared.grow, classesLocal.flexRow, classesLocal.alignCenter].join(' ')}>
                <Grid container className={classesShared.contentRoot}>
                    <div className={classesShared.gridDomi}>
                        <div>
                            {/* Municipio */}
                            <Typography className={classesShared.highlightText}>
                                {municipio ? municipio.label : ''}
                            </Typography>

                            {/* Nombre Concepto */}
                            <Typography className={classesShared.highlightText}>
                                <Term component="CarteroVirtual" text={tributo.concepto.nombre} />
                            </Typography>

                            {/* Objeto */}
                            <Typography className={classesShared.detailText}>
                                {tributo.objeto}
                            </Typography>

                            {/* Dirección Tributaria */}
                            {
                                tributo.concepto.idTipo === 1 &&
                                <Typography className={classesShared.detailText} >
                                    {tributo.direccionTributaria}
                                </Typography>
                            }

                            {/* Fecha Previsto Cargo en Cuenta */}
                            {/* {
                                (
                                    tributo.domiciliacion
                                    && (tributo.domiciliacion.fechaPrevistaCargo || tributo.domiciliacion.fechaPrevistaEnvio)
                                )
                                && (
                                    screen === 'domiciliados'
                                    || window.location.pathname.includes('confirmacion')
                                )
                                && tributo.tipo !== "L" &&
                                <div className={classesLocal.marginBottom5}>
                                    <Term
                                        component='Domiciliaciones'
                                        text='Fecha cargo cuenta'
                                        params={[
                                            new Date(tributo.domiciliacion.fechaPrevistaEnvio).getFullYear() >= new Date().getFullYear()
                                                ? dateFormat(tributo.domiciliacion.fechaPrevistaEnvio)
                                                : dateFormat(tributo.domiciliacion.fechaPrevistaCargo)
                                        ]}
                                    />
                                </div>
                            } */}
                            <div style={{ marginTop: 10, marginRight: 10 }}>
                                {/* Label Domiciliado y Liquidación */}
                                {
                                    tributo.domiciliacion
                                    //&& tributo.domiciliacion.vigencia
                                    && tributo.domiciliacion.activa
                                    &&
                                    <>
                                        {
                                            <Typography className={classesShared.tagDomiciliados}>
                                                <Term component="Domiciliaciones" text="Domiciliado" />
                                            </Typography>
                                        }
                                        {
                                            tributo.tipo === 'L' && !tributo.pagoCarta &&
                                            <Typography className={classesShared.detailTextWarning}>
                                                <Term component="Domiciliaciones" text="VigenciaDomiciliacionLiquidacion" />
                                            </Typography>
                                        }
                                    </>
                                }
                                {/* Label Pago a la Carta */}
                                {
                                    tributo.pagoCarta && tributo.pagoCarta.DomiciliadoPC &&
                                    <Typography className={classesShared.tagPagoCarta}>
                                        <Term component="Domiciliaciones" text="Pago a la carta" />
                                    </Typography>
                                }
                            </div>
                        </div>

                        {/* Button +INFO */}
                        <div className={classesShared.bannerBtn} style={{ flexDirection: 'column-reverse', alignItems: 'flex-end', justifyContent: 'flex-start' }}>
                            <div className={[classesLocal.flexRow, classesLocal.alignEnd].join(' ')}>
                                <Button variant="contained" color="default" size="small" onClick={handleMoreInfo}>
                                    <Term component="Domiciliaciones" text="+ INFO" />
                                </Button>
                                {selectable && screen === 'domiciliaciones' && domiciliables === true ?
                                    <Button
                                        variant="contained"
                                        color="primary" size="small"
                                        className={[classesShared.minWidth90, classesShared.btnAddPay, classesShared.widthBtnDomiGrid205, classesShared.marginLeft10].join(' ')}
                                        onClick={handleSelect}>
                                        <Term component="Domiciliaciones" text={selected ? "Quitar" : "Domiciliar"} />
                                    </Button>
                                    :
                                    <Button variant="contained" size="small"
                                        color={screen === 'pago-carta' ? "secondary" : "primary"}
                                        className={
                                            screen === 'pago-carta'
                                                ? [classesShared.marginLeft10, classesShared.btnPagoCarta2].join(' ')
                                                : [classesShared.marginLeft10, classesShared.btnPagoCarta].join(' ')
                                        }
                                        onClick={handleGestion}
                                    >
                                        <Term component="Domiciliaciones" text={screen === 'pago-carta' ? "btn_gestionPagoCarta" : "btn_gestionDomi"} />
                                    </Button>
                                }

                            </div>

                            {/* Button Pago a la Carta */}
                            <div className={classesShared.containerBtnPagoCarta}>
                                {
                                    screen !== 'pago-carta' && pagoCarta && tributo.pagoCarta && tributo.pagoCarta.DomiciliablePC &&
                                    <Button variant="contained" color="secondary"
                                        size="small" disabled={multiplesRegistrosSeleccionados}
                                        className={[classesShared.minWidth90, classesShared.btnPagoCarta].join(' ')}
                                        onClick={() => pagoCarta(tributo)}>
                                        {
                                            <Term component="Global" text={"Pago a la carta"} />
                                        }
                                    </Button>
                                }
                                {/* Button Domiciliaciones */}
                                {/* {
                                    selectable && screen === 'domiciliaciones' && 
                                    <Button variant="contained" color="primary" size="small" className={[classesShared.minWidth90, classesShared.btnAddPay, classesShared.widthBtnDomiGrid205].join(' ')} onClick={handleSelect}>
                                        <Term component="Domiciliaciones" text={selected ? "Quitar" : "Domiciliar"} />
                                    </Button>
                                } */}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

export { DomiciliacionGrid };