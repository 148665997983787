// LIBRARY IMPORTS
import { useContext, useEffect, useMemo, useState } from "react";
// LOCAL IMPORTS
import { ITributo } from "gateways/tributos.interfaces";
import { TributosGateway } from "gateways/tributos.gateway";
import { getMomentFromDate } from "utils/dates";
import IoC from "contexts/ioc.context";

interface IProps {
    tributos: ITributo[];
}

const useLoadFechasVoluntariaIncompatibles = (props: IProps) => {
    const { tributos } = props;
    // Services 
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(TributosGateway) as TributosGateway, [ioc]);
    // States
    const [isLiquidacionInTributos, setLiquidacionInTributos] = useState<boolean>(false);
    const [fechaDomiciliacionIncompatible, setFechaDomiciliacionIncompatible] = useState<string | null>(null);

    useEffect(() => {
        tributos.forEach(async (tributo: ITributo) => {
            if (tributo.tipo === 'L') setLiquidacionInTributos(true);
            else setLiquidacionInTributos(false);

            const fechaDomiciliacionIncompatible: string | null = await gateway.getFechaDomiciliacionIncompatible(tributo.organismo.codigo);
            setFechaDomiciliacionIncompatible(prevFecha => {
                if (!prevFecha) return fechaDomiciliacionIncompatible;
                else if (!fechaDomiciliacionIncompatible) return prevFecha;

                return getMomentFromDate(prevFecha).isAfter(getMomentFromDate(fechaDomiciliacionIncompatible))
                    ? fechaDomiciliacionIncompatible
                    : prevFecha;
            });
        });
    }, []);

    return {
        isLiquidacionInTributos,
        fechaDomiciliacionIncompatible
    }
}

export default useLoadFechasVoluntariaIncompatibles;
