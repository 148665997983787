import {FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import { Grid, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";

import { BLUE } from "containers/shared/colors";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { IInfoRegistral, IInfoTransmision } from "containers/tributos-autonomicos-locales/componentesAsistente/shared/types";

import { translate } from "utils/i18n";
import IoC from "contexts/ioc.context";
import { AlertsContext } from "contexts/alerts.context";

import  DUTIGateway  from "gateways/duti.gateway";
import { IOficina } from "gateways/duti.interface";

import FullLoading from "components/full-loading";
import InputSelect from "components/DUTI/input-select";
import InputText from "components/DUTI/input-text";
import { TSelect } from "containers/DUTI/utils";


interface IInfoRegistralFormProps extends WithStyles<typeof styles> {
    initialValues: IInfoRegistral;
    onChange:(value: IInfoRegistral) => void;
}
const InfoRegistralForm: FC<IInfoRegistralFormProps> = ({ 
   classes, initialValues, onChange
}) => {

    const componentMounted = useRef(true)

    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);

    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const [loading, setLoading] = useState(false);
    const [oficinas, setOficinas]= useState<IOficina[]>([])
    const [oficina, setOficina]= useState<TSelect|undefined>(undefined)

    const updateField =  <K extends keyof IInfoRegistral>(key: K, value: IInfoRegistral[K]) => {
        const newValue = {...initialValues, [key]: value}
        onChange(newValue)
    };

    useEffect(() => {
        // inicializamos componente
        (async () => {
            setLoading(true);
            try {
                const oficinas = await dutiGateway.getOficinas();
                if(componentMounted.current){
                    setOficinas(oficinas)
                }
            } catch (error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Plusvalias','error_get_oficinas', terms),
                        variant: "error"
                    }
                });
            } finally {
                setLoading(false);
            }

            
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    }, [])

    useEffect(() => {
        if(initialValues && initialValues.oficina){
            const ofiFound = oficinas.find(ofi => ofi.Descrip === initialValues?.oficina)
            setOficina(ofiFound && ofiFound.Codigo ? {id: ofiFound.Codigo.toString(), nombre: ofiFound.Descrip}: undefined)
        }
    },[oficinas])

    return (
        <>
            <FullLoading loading={loading}/>
        
            <Grid item container direction="row" alignItems="center" className={classes.borderPlusval} style={{marginTop: 30}}>
                <div className={classes.divTextBorderPlusval}>
                    <Typography className={classes.textBorderPlusval}>{translate('DUTI','title_fields_plusval',terms)}</Typography>
                </div>                       
                <Grid item  xs={6} md={3}>
                    <InputText
                        className={classes.inputFullWidth}
                        fieldTerm='tomo'
                        value={initialValues?.tomo}
                        onChangeValue={(v:string|undefined, err: boolean) => {
                            updateField('tomo', v)
                        }}
                    />
                </Grid>
                <Grid item  xs={6} md={3}>
                    <InputText
                        className={classes.inputFullWidth}
                        fieldTerm='libro'
                        value={initialValues?.libro}
                        onChangeValue={(v:string|undefined, err: boolean) => {
                            updateField('libro', v)
                        }}
                    />
                </Grid>
                <Grid item  xs={6} md={3}>
                    <InputText
                        className={classes.inputFullWidth}
                        fieldTerm='folio'
                        value={initialValues?.folio}
                        onChangeValue={(v:string|undefined, err: boolean) => {
                            updateField('folio',v)
                        }}
                    />
                </Grid>
                <Grid item  xs={6} md={3}>
                    <InputText
                        className={classes.inputFullWidth}
                        fieldTerm='finca'
                        value={initialValues?.finca}
                        onChangeValue={(v:string|undefined, err: boolean) => {
                            updateField('finca', v )
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputSelect 
                        className={classes.inputFullWidth}
                        fieldTerm='oficina'
                        value={oficina}
                        options={oficinas.map(ofi => ({id: ofi.Codigo, nombre: ofi.Descrip}))}
                        onChangeValue={(v: TSelect | undefined, err: boolean) => {
                            setOficina(v)
                            updateField('oficina',   v?.nombre ?? undefined)
                        }}
                        disabled={ oficinas.length === 0}
                    />
                </Grid>
            </Grid>
        </>
    )
}

const styles =  (theme: Theme): StyleRules => ({
    column: {
        display: 'flex',
        flexDirection:  'column',
        alignItems: 'center',
        justifyItems: 'flex-start'
    },
    row:{
        display:' flex',
        flexDirection:'row', 
        alignItems: 'center',
    },
    inputFullWidth: { // Small: puerta, esc
        width: '100%'
    },
    borderPlusval: {
        border:'solid',
        borderWidth: '1px',
        borderRadius: 10,
        borderColor: BLUE,
        padding: '20px 10px 10px 10px',
        marginBottom: 10,
        position: 'relative'
    },
    divTextBorderPlusval: {
        display: 'flex',        /* Activa Flexbox */
        justifyContent: 'flex-end', /* Alinea el contenido al final (derecha) */
        width: '100%',         /* Asegura que ocupe todo el ancho del Grid */
        position: 'absolute',   /* Posicionamiento absoluto respecto a su contenedor relativo */
        right: 20,            /* Alinea a la derecha */
        top: -20,              /* Alinea al borde superior */
    },
    textBorderPlusval: {
        padding: 10,
        color: BLUE,
        backgroundColor: 'white'
    },

})

export default withLiterals(['Global','DUTI','Plusvalias'])(withStyles(styles)(InfoRegistralForm));

