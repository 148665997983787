import React from 'react'; //

interface IProps { }

const ClaveForm: React.FC<IProps> = (props) => {

    return (
        <form id="claveForm" method="post" className="hidden">         
            <input type="hidden" id="country" name="country" value="ES" />
            <input type="hidden" id="RelayState" name="RelayState" />   
            <input type="hidden" id="SAMLRequest" name="SAMLRequest" />            
        </form>
    )
}

export default ClaveForm;